import {PreviewElementProps} from "../../../model";
import {SimpleTable} from "./SimpleTable";
import {Box} from "@mui/material";
import {TableElementValue} from "../../../../../../../shared/definitions/elements/table/model";
import {useTablePreview} from "./useTablePreview";
import { CommentState } from "../../../../../hooks/useCommentState";
import { HighlightWrapper } from "../../../../../../../shared/components/input/highlight/HighlightWrapper";
import { CommentModalProps } from "../../../../../../../shared/definitions/highlight/CommentModal.props";
import CommentModal from "../../../../modals/documentComments/CommentModal";
import {isInReview} from "../../../../../../../shared/interfaces/WorkflowState";

const PreviewView = (props: PreviewElementProps) => {
	const dataInfo = props.element.data as TableElementValue;
	const tablePreview = useTablePreview(dataInfo, props.variables);

	const commentsManager = CommentState.useContainer();
	const highlightComments = commentsManager.highlightedCommentsByElementId(props.element.id);

	return <Box sx={{ mt: 2 }}>
		{dataInfo.title && <HighlightWrapper
			enabled={isInReview(props.state)}
			elementId={props.element.id}
			highlightComments={highlightComments}
			value={dataInfo.title}
			componentToWrap={(highlightedText) => <h2 dangerouslySetInnerHTML={{ __html: highlightedText }} />}
			renderCommentModal={ (values: CommentModalProps) =>
				<CommentModal
					onClose={values.onClose}
					addEditComment={"Add"}
					showOpen={values.showOpen}
					elementId={values.elementId}
					selectedText={values.selectedText}
					textProperties={values.textProperties}
					documentId={commentsManager.documentId}
				/>
			}
		/>}
		{tablePreview.data?.map(data => <SimpleTable dataType={data.type} data={data.data} name={dataInfo.datasource} footNotes={data.footNotes} />)}
	</Box>
}

export { PreviewView };