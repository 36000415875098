import {TypedStyleView, TypedViewProps} from "./model";
import {PropertyList} from "../../../PropertyList";

type StyledElementType = {
	element_type: string
}


const View = (props: TypedViewProps) => {
	const value = props.value as StyledElementType;
	return <PropertyList items={[{label: "Element type", value: value.element_type}]} />
}

const StyledElementView: TypedStyleView = {
	type: "StyleElement",
	view: View
}
export {StyledElementView}