import { styled, Switch as MuiSwitch, SwitchProps } from "@mui/material";

const SwitchTitle = styled('p')(({theme}) => ({
	fontWeight: 600,
	fontSize: "0.8rem",
	margin: ".7rem 0 0 0",
	color: theme.palette.dashboard?.contrastText,
}))

const Switch = styled((props: SwitchProps) => (
	<MuiSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	// this is literally just to remove the hover ripple.
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 9,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			border: '6px solid #fff',
		},
	},
	'& .MuiSwitch-thumb': {
		backgroundColor: theme.palette.tabs?.border,
	},
}));

export {SwitchTitle, Switch}