import { definitionForElementType } from "../../..";
import {
	ReferenceElementContents,
	ReferenceVariableContents,
	ReferenceVariableValue
} from "../../../../../../../shared/definitions/elements/reference/model";
import { findElementsInBody } from "../../../utilities/findElementsInBody";
import {formatReference} from "./formatReference";
import {renderMissingVariable} from "./renderMissingVariable";

function mapReferenceElements(body: KaiAlphaTemplateBody | undefined) {
	const refsArray: ReferenceVariableValue[] = [];
	body?.forEach((value) => {
		const [valueKey,] = Object.keys(value);
		const type = value[valueKey].type as string;
		if (type === 'reference') {
			// find first occurence referenced element in body
			const [element,] = findElementsInBody(body as KaiAlphaBody, (value[valueKey].value as ReferenceElementContents).element_id);
			const elementDefinition = definitionForElementType(element?.type);

			refsArray.push({
				name: value[valueKey].name as string,
				value: {
					...value[valueKey].value as ReferenceElementContents,
					name: elementDefinition?.toString(element) ?? "Undefined Element",
					value: elementDefinition?.contentDescription(element)?? "Undefined Element",
				} as ReferenceVariableContents
			});
		}
	});

	return refsArray.reduce((lookup, ref) => {
		if (ref.value === undefined) {
			lookup[ref.name] = renderMissingVariable(ref.name);
			return lookup;
		}

		// prevent click from actually sending user anywhere with
		// onclick="return false;"
		lookup[ref.name] = formatReference(ref);

		return lookup;
	}, {});
}

export {mapReferenceElements}