import {NunjucksEnvironmentOptions} from "../models/NunjucksEnvironmentOptions";
import {ensureArgs} from "./filterUtilities";
import {isString} from "../../guards/isString";
import {NunjucksFilterDependencies} from "./model";

export function incrementInput(options: NunjucksEnvironmentOptions, ...args: unknown[]): number | undefined {
	if (!ensureArgs(args) && !isString(args[0])) {
		return undefined;
	}

	const key = args[0] as string;
	const sharedContext = options.sharedContext;
	if (sharedContext.incrCounters[key] === undefined) {
		sharedContext.incrCounters[key] = -1;
	}

	sharedContext.incrCounters[key]++;

	return sharedContext.incrCounters[key];
}

export function incrementId(options: NunjucksEnvironmentOptions, ...args: unknown[]): number | undefined {
	if (!ensureArgs(args, 2) && !isString(args[0]) && !isString(args[1])) {
		return undefined;
	}

	const sharedContext = options.sharedContext;
	const key = args[0] as string;
	const type = args[1] as string;

	if (sharedContext.counters[type] === undefined) {
		sharedContext.counters[type] = {
			current: 0,
			entries: {}
		}
	}

	if (sharedContext.counters[type].entries[key] === undefined) {
		sharedContext.counters[type].current++;
		sharedContext.counters[type].entries[key] = sharedContext.counters[type].current;
	}

	return sharedContext.counters[type].entries[key];
}

export function getIds(options: NunjucksEnvironmentOptions, ...args: unknown[]): {[k: string]: number} | undefined {
	if (!ensureArgs(args) && !isString(args[0])) {
		return undefined;
	}

	const type = args[0] as string;
	const sharedContext = options.sharedContext;

	return sharedContext.counters[type]?.entries ?? {};
}

export function defaultDependenciesFactory(): NunjucksFilterDependencies {
	return ({
		incrementId,
		incrementInput,
		getIds
	})
}