import {S3FileUploadProps, S3Payload} from "./models";


function useCreatePayload() {
	return ({file, type, presignedUrl}: S3FileUploadProps): S3Payload => {
		const {fields} = presignedUrl!;
		const body = new FormData();
		Object.keys(fields).forEach(key => body.append(key, fields[key]));
		body.append(type, file);

		/* Make a POST request to upload the image to S3 */
		return {
			method: 'POST',
			body
		}
	}
}

export {useCreatePayload};