import {Box, Paper, TableContainer, Table, TableRow, TableCell as MuiTableCell, TableBody, styled, TableHead} from "@mui/material";
import {TableDataType} from "./useTablePreview";
import {TableElementFootnote} from "../../../../../../../shared/definitions/elements/table/model";
import {useMousePosition} from "../../../../../../../shared/hooks/useMousePosition";
import {useState} from "react";

import {ContextMenu, ContextMenuItemType} from "../../../../../../../shared/components/menu/contextMenu";
import {ContentPaste, Menu} from "@mui/icons-material";
import useCopyToClipboard from "../../../../../../../shared/hooks/useCopyToClipboard";
import {DataSourceMapType} from "../../../../../../../shared/definitions/elements/variable/model";
import {
	TableCellEvent,
	TableCellReference,
	TableCellType,
	TableColumnType
} from "../../../../../../../shared/interfaces/DataSource";

export interface TableProps {
	data: TableDataType,
	name: string,
	dataType: DataSourceMapType
	footNotes?: TableElementFootnote[]
}

const EmptyTableBody = styled(Box)({
	display: "flex",
	width: "100%",
	alignItems: "center",
	justifyContent: "center",
	height: "200px"
});

const FootnoteList = styled("ol")({
	margin: "0",
	padding: "1rem 1rem 0",
	lineHeight: "1.2rem",
	fontSize: ".8rem",
	"& li": {
		paddingLeft: ".25rem"
	}
});

const TableCell = styled(MuiTableCell)({
	border: "1px solid black",
	cursor: "pointer"
})


const contextMenuItems: ContextMenuItemType<TableCellReference>[] = [{
	label: "Absolute Reference",
	value: "abs",
	icon: ContentPaste
}, {
	label: "Cell reference (named cell)",
	value: "named",
	icon: ContentPaste
}, {
	label: "Find matching rows",
	value: "row",
	icon: ContentPaste
}, {
	label: "Find matching columns",
	value: "col",
	icon: ContentPaste
}]

const SimpleTable = (props: TableProps) => {
	const mousePosition = useMousePosition();
	const clipBoard = useCopyToClipboard();
	const [selection, setSelection] = useState<{row: TableCellType, columns: TableColumnType[], rowIndex: number, x: number, y: number} | undefined>(undefined);
	const [selectedColumn, setSelectedColumn] = useState<{column: TableColumnType, x: number, y: number} | undefined>(undefined);

	const getFootnotes = (column?: number, row?: number) => (props.footNotes ?? [])
		.filter(note => note.cellRef?.column === column && note.cellRef?.row === row)
		.map(note => props.footNotes!.findIndex(n => n.id === note.id) + 1)
		.filter(index => index > 0);

	const cellClickFactory = (row: TableCellType, column: TableColumnType, rowIndex: number) => () => setSelection({row, columns: [column], x: mousePosition.x, y: mousePosition.y, rowIndex});
	const columnClickFactory = (column: TableColumnType) => () => setSelectedColumn({column, x: mousePosition.x, y: mousePosition.y});
	const onContextMenuItemClick = (menuItem: ContextMenuItemType<TableCellReference>) => {
		const {row, columns, rowIndex} = selection!
		const selectedColumns = columns.map(col => ({index: props.data.columns.findIndex(column => column.field === col.field), ...col}));
		copyReferenceToClipboard({columns: selectedColumns, row}, menuItem.value, rowIndex);
		setSelection(undefined);
	}

	const onColumnReferenceClick = () => {
		if (selectedColumn !== undefined) {
			clipBoard.copy(`{{${props.name}.data | column('${selectedColumn.column.field}')}}`);
			onResetSelection();
		}
	}

	const onResetSelection = () => {
		setSelection(undefined);
		setSelectedColumn(undefined);
	}

	const onRowClick = (row: TableCellType, rowIndex: number) => setSelection({row, columns: props.data.columns, x: mousePosition.x, y: mousePosition.y, rowIndex});

	const copyReferenceToClipboard = (event: TableCellEvent, type: TableCellReference, rowIndex: number) => {
		let cellReference = "";

		if (event.columns.length > 1) {
			cellReference = `{{${props.name}.data | row('${rowIndex}')}}`;
		} else {
			switch (type) {
				case "abs":
					cellReference = event.columns.map(column => `{{${props.name} | absolute('${rowIndex + 1}', '${column.index}')}}`).join(", ");
					break;
				case "row":
					cellReference = event.columns.map(column => `{{${props.name} | find_rows('${column.field}','${event.row[column.field]}')}}`).join(", ");
					break;
				case "col":
					cellReference = event.columns.map(column => `{{${props.name} | find_columns('${rowIndex}','${event.row[column.field]}')`).join(", ");
					break;
				case "named":
					if (props.dataType === "columns-rows") {
						cellReference = event.columns.map(column => `{{${props.name}.data['${column.field}']['${rowIndex}']}}`).join(", ");
					} else {
						cellReference = event.columns.map(column => `{{${props.name}.data['${rowIndex}']['${column.field}']}}`).join(", ");
					}
			}
		}

		clipBoard.copy(cellReference);
	}

	return (
		<Box sx={{mt: 3, mb: 3}}>
			<Paper elevation={0}>
				<TableContainer>
					<Table aria-label="simple table" size={"medium"}>
						<TableHead>
							<TableRow>
								<TableCell />
								{props.data.columns.map((column, columnIndex) => <TableCell onClick={columnClickFactory(column)} sx={{fontWeight: 700}} key={column.field}>
									{column.displayText}<sup>{getFootnotes(columnIndex).join(",")}</sup>
								</TableCell>)}
							</TableRow>
						</TableHead>
						{props.data.rows.length > 0 && <TableBody>
							{props.data.rows.map((row, rowIndex) => (
								<TableRow key={row.id}>
									<MuiTableCell>
										<Menu fontSize={"small"} sx={{cursor: "pointer"}} onClick={() => onRowClick(row, rowIndex)} />
									</MuiTableCell>
									{props.data.columns.map((column, columnIndex) => <TableCell onClick={cellClickFactory(row, column, rowIndex)}
										key={`${row.id}-${column.field}`}
									>{row[column.field] ?? "Not defined"}<sup>{getFootnotes(columnIndex, rowIndex).join(",")}</sup></TableCell>)}
								</TableRow>
							))}
						</TableBody>}
					</Table>
				</TableContainer>
				{props.data.rows.length === 0 && <EmptyTableBody>No results</EmptyTableBody>}
				{props.footNotes && <FootnoteList>
					{props.footNotes.map(note => <li key={note.id}>{note.name}</li>)}
				</FootnoteList>}
			</Paper>
			<ContextMenu
				menuItems={contextMenuItems}
				open={selection !== undefined && selection.columns.length === 1}
				onClose={onResetSelection} position={{x: selection?.x ?? 0, y: selection?.y ?? 0}}
				onMenuItemSelect={onContextMenuItemClick} />

			<ContextMenu
				menuItems={[{
					label: "Row reference",
					value: "named",
					icon: ContentPaste
				}]}
				open={selection !== undefined && selection.columns.length > 1}
				onClose={onResetSelection} position={{x: selection?.x ?? 0, y: selection?.y ?? 0}}
				onMenuItemSelect={onContextMenuItemClick} />

			<ContextMenu
				menuItems={[{
					label: "Column reference",
					value: "named",
					icon: ContentPaste
				}]}
				open={selectedColumn !== undefined}
				onClose={onResetSelection} position={{x: selectedColumn?.x ?? 0, y: selectedColumn?.y ?? 0}}
				onMenuItemSelect={onColumnReferenceClick} />
		</Box>
	);
}

export {SimpleTable}