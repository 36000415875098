import {DataSourceMapType} from "../../interfaces/DataSource";

export type NunjucksDataSourceType = {
	data: any,
	type: DataSourceMapType,
}

export function isNunjucksDataSource(obj: unknown): obj is NunjucksDataSourceType {
	if (obj === undefined) {
		return false;
	}

	const dataSource = obj as NunjucksDataSourceType;
	return dataSource.data !== undefined && dataSource.type !== undefined;
}