type DownloadFileProps = {
	path: string,
	fileName: string
}
async function downloadFile(props: DownloadFileProps): Promise<{success: boolean, error: Error | string | undefined}> {
	try {
		const response = await fetch(props.path, {
			method: "GET",
			headers: {
				"Content-Type": "application/zip"
			}
		})

		const blob = await response.blob();
		const url = window.URL.createObjectURL(
			new Blob([blob]),
		);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute(
			'download',
			`${props.fileName}.zip`,
		);

		// Append to html link element page
		document.body.appendChild(link);

		// Start download
		link.click();

		// Clean up and remove the link
		link.parentNode?.removeChild(link);
		return {success: true, error: undefined};
	} catch(e: any) {
		return {success: false, error: e};
	}
}

export {downloadFile}