import { styled } from "@mui/styles";
import { EditableControlBox, FlexBox } from "../../../../shared/components";
import { Variable } from "../../../../shared/interfaces/Variable";

type VariableEvent = (variable: Variable) => void

export interface VariableListProps {
	onEdit?: VariableEvent,
	variables: Variable[]
}

const VariableInfoBox = styled(FlexBox)(({ theme }) => ({
	fontSize: ".875rem",
	color: "#333333B3",
	justifyContent: "space-between",
	alignItems: "center",
	"& .type": {
		color: "#333333B3"
	},
	"& .namespace": {
		color: "#426AB9"
	}

}))

const VariableList = (props: VariableListProps) => {

	const onEditFactory = (variable: Variable) => props.onEdit !== undefined ? () => props.onEdit!(variable) : undefined;
	return <FlexBox direction={"column"}>
		{props.variables.filter(v => v.type !== "system").map(variable => <EditableControlBox
			onEdit={onEditFactory(variable)}
			key={variable.namespace}
			body={<FlexBox align={"center"} className="title_text">
				{<VariableInfoBox>
					<FlexBox align={"center"} style={{ marginRight: "5px" }} title={variable.description}>
						{variable.name}
					</FlexBox>
				</VariableInfoBox>}
			</FlexBox>}
			footer={
				<VariableInfoBox>
					<span className={"namespace"}>{variable?.namespace?.replace("." + variable!.name, "") ?? "global"}</span>
					<span className={"type"}>
						{variable?.type}
					</span>
				</VariableInfoBox>
			}
		/>)}
	</FlexBox>;
}

export { VariableList };