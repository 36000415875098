import "./styles.scss";
import { FlexBox } from "../../..";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {CheckBoxRounded, CheckBoxOutlineBlankRounded} from "@mui/icons-material";
import {CSSProperties, useRef, useState} from "react";
import {DropDownItem} from "../index";
import {colors} from "../../../../../theme";
import {FixedPositionedBox} from "../../../layout/box/FixedPositionedBox";

export type MultiSelectDropDownProps<T extends (string | number)> = {
	width?: string,
	label?: string,
	items: DropDownItem<T>[],
	value: T[],
	valueTextLimit?: number,
	placeholder?: string,
	disabled?: boolean
	onToggleSelection: (item: T) => void,
	sx?: CSSProperties
}

const MaxNumberSelectedToShow = 5;
const MultiSelectDropDown = <T extends (string | number)>(props: MultiSelectDropDownProps<T>) => {
	const [showOptions, setShowOptions] = useState(false);
	const inputRef = useRef<HTMLDivElement>(null);
	const onToggleOptions = () => setShowOptions(!showOptions && !(props.disabled ?? false));
	const onToggleItem = (item: DropDownItem<T>) => props.onToggleSelection(item.value);

	const generateTitle = () => {
		if (props.value.length === 0) {
			return props.placeholder;
		}

		const valueLimit = props.valueTextLimit ?? MaxNumberSelectedToShow;
		return props.value.length > valueLimit ? props.value.slice(0, valueLimit).join(", ") + `, +${props.value.length - valueLimit}` :
			props.value.join(", ");
	}

	return <div className={"sci__dms"} style={{width: props.width ?? "100%", ...(props.sx ?? {})}}>
		<div className={"sci__dms--input"} onClick={onToggleOptions} ref={inputRef}>
			{props.label && <label>{props.label}</label>}
			<FlexBox align={"center"} justify={"space-between"}>
				<p>{generateTitle()}</p>
				<ExpandMoreIcon fontSize={"small"} sx={{color: colors.mineShaft}} />
			</FlexBox>
		</div>
		{showOptions && <FixedPositionedBox attachToElement={inputRef} style={{border: "1px solid rgba(77, 114, 189, 0.5019607843)", borderTop: "none",
			borderBottomLeftRadius: "4px",
			borderBottomRightRadius: "4px", boxShadow: "rgb(0 0 0 / 24%) 0 3px 8px"}}>
			<ul className={"sci__dms--options"}>
				{props.items.map(item => <li onClick={() => onToggleItem(item)}>
					{props.value.includes(item.value) ? <CheckBoxRounded fontSize={"small"} sx={{color: colors.mountainMeadow}} /> : <CheckBoxOutlineBlankRounded fontSize={"small"} sx={{color: colors.mountainMeadow}} />}
					{item.title} {props.value.includes(item.value) ? "(selected)" : ""}
				</li>)}
			</ul>
		</FixedPositionedBox>}
	</div>
}

export {MultiSelectDropDown}