import { Box, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles'; // LEGACY
import Typography from '@mui/material/Typography';
import React from 'react';

function styles(theme) {
	return({
		numberIcon: {
			borderRadius: '4px',
			fontSize: theme.typography.fontSize,
			color: theme.palette.primary.main,
			margin: '5px',
		},
		selected: {
			backgroundColor: theme.palette.fancyTable_pagination.main,
		}
	});
}

const defaultRowsPerPageOptions = [10, 20, 50, 100];

function FancyTablePaginationRowsPerPage(props) {
	const {
		classes,
		onChangeRowsPerPage,
		rowsPerPage,
		rowsPerPageOptions = defaultRowsPerPageOptions,
	} = props;

	return(
		<Box style={{flexGrow: 1}}>
			<Typography variant="body2" component="span">
				{"Items per page:"}
			</Typography>
			{
				rowsPerPageOptions.map(rowsPerPageOption => (
					<IconButton
						key={rowsPerPageOption}
						className={`${classes.numberIcon} ${rowsPerPageOption === rowsPerPage ? classes.selected : ''}`}
						disableRipple
						onClick={() => {
							onChangeRowsPerPage({ target: { value: rowsPerPageOption } });
						}}
					>
						{rowsPerPageOption}
					</IconButton>
				))
			}
		</Box>
	);
}

export default withStyles(styles)(FancyTablePaginationRowsPerPage);