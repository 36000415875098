import { DocumentState } from "./useDocumentState";


// TODO DOCEDITOR: this should likely just be moved to useDocumentState?
function useDocumentService() {

	const documentManager = DocumentState.useContainer();

	const updateActiveSection = (sectionId: string) => {
		if (documentManager.activeSectionId === sectionId) {
			documentManager.setActiveSectionId(null)
			return;
		}

		// TODO DOCEDITOR: this is unneeded, as the initiatior of this event is always a valid section.
		// if (!IsValidSectionID(sectionId, documentManager.sectionIds!)) {
		// 	return;
		// }

		// set section as active
		documentManager.setActiveSectionId(sectionId);

		// update window section, if it returns true then set the window section to the sectionId
		// const updateWindowResponse = UpdateWindowSection(
		// 	sectionId
		// );

		documentManager.setWindow(
			sectionId,
			sectionId,
			sectionId ? sectionId : null
		);
	};

	// const IsValidSectionID = (sectionId: string, sectionIds: string[]) => {
	// 	return sectionIds.includes(sectionId);
	// };

	// const UpdateWindowSection = (sectionId: string) => {
	// 	if (!IsValidSectionID(sectionId, documentManager.sectionIds!) ||
	// 		documentManager.scrollingIntoActiveSection) {
	// 		return false;
	// 	}

	// 	return true;
	// };

	return {
		updateActiveSection
	}
}

export {useDocumentService}