import {ModuleLayout} from "../../../../shared/components/layout/ModuleLayout";
import {FlexBox} from "../../../../shared/components";
import Masthead from "../../../../shared/components/navigation/masthead";
import {ContextButtonFactory, DefaultContextButtons} from "../../../../shared/components/buttons/theme";
import {LeftPane} from "./layout/left";
import {CenterPane} from "./layout/center";
import {moduleLinkGenerator} from "../../../moduleNavigation";
import {useCreate} from "./useCreate";

const CreatePage = () => {
	useCreate();

	const onContextButtonClick = (key: string) => {
	}

	return <FlexBox direction={"column"} height={"100%"}>
		<Masthead
			contextButtons={ContextButtonFactory(
				[DefaultContextButtons.New],
				onContextButtonClick)}
			breadcrumbs={[{title: "Submissions", url: moduleLinkGenerator("submission", "list")}, {title: "Create"}]}
		/>
		<ModuleLayout center={<CenterPane />} left={<LeftPane />}/>
	</FlexBox>

}

export {CreatePage};