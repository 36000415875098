import {PreviewElementProps} from "../../../model";
import {StyleElementValue} from "../../../../../../../shared/definitions/elements/style/model";
import {factoryForStyleType} from "./factories";
import ReactDOM from "react-dom";


const PreviewView = (props: PreviewElementProps) => {
	const styleInfo = props.element.data as StyleElementValue;
	const cssFactory = factoryForStyleType(styleInfo.option);
	const styleSheet = cssFactory?.valueFactory(styleInfo.value);

	return ReactDOM.createPortal(<style>{styleSheet}</style>, document.head);
}

export {PreviewView}