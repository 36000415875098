import {SharedContext, sharedContextFactory} from "./SharedContext";

export type NunjucksEnvironmentOptions = {
	sharedContext: SharedContext,
	parseOnly: boolean
}

export function isNunjucksEnvironmentOptions(obj: unknown): obj is NunjucksEnvironmentOptions {
	return obj !== undefined && (obj as NunjucksEnvironmentOptions).sharedContext !== undefined;
}

export function environmentOptionsFactory(context?: SharedContext, parseOnly?: boolean): NunjucksEnvironmentOptions {
	return ({
		sharedContext: context ?? sharedContextFactory(),
		parseOnly: parseOnly ?? false
	})
}

export const defaultEnvironmentOptions = environmentOptionsFactory();

export function resetNunjucksEnvironment() {
	const newContext = sharedContextFactory();
	defaultEnvironmentOptions.sharedContext.abbreviations = {...newContext.abbreviations}
}