import {EctdModule} from "../../../../../../shared/interfaces/Module";
import {EmptyGroup} from "../../../../../../shared/components/layout/groups/EmptyGroup";
import "./styles.scss";
import {ExpandablePane} from "../../../../../../shared/components/layout/pane/ExpandablePane";
import {Switch} from "../../../../../../shared/components/input/toggle/Switch";

export type LeftPaneProps = {
	selectedModule?: EctdModule,
	selectedSection?: EctdModule,
	onSelectSection: (module: EctdModule) => void,
	onToggleSectionActive: (module: EctdModule) => void,
}
const LeftPane = (props: LeftPaneProps) => {
	const onToggleActiveStateFactory = (module: EctdModule) => () => props.onToggleSectionActive(module);
	const onSelectSectionFactory = (module: EctdModule) => () => props.onSelectSection(module);

	return <ExpandablePane
		orientation={"left"}
		titleComponent={<span className={"ectd__tblp--moduleTitle"}>Module {props.selectedModule?.moduleNumber}</span>}
		contents={(expanded) => expanded ? <div className={"ectd__tblp"}>
			{props.selectedModule && <>
				<h2>{props.selectedModule.moduleTitle}</h2>
				<ul>
					{(props.selectedModule.modules ?? []).map(module =>
						<li key={module.moduleNumber} onClick={onSelectSectionFactory(module)} className={props.selectedSection && props.selectedSection.moduleNumber === module.moduleNumber ? "active" : ""}>
							<span>{module.moduleNumber}</span>
							<span>{module.moduleTitle}</span>
							<span><Switch size={"large"} value={module.active ?? false} onValueChange={onToggleActiveStateFactory(module)} /></span>
						</li>)
					}
				</ul>
			</>}
			{!props.selectedModule && <EmptyGroup title={"No module"} />}
		</div> : null} />
}

export {LeftPane}