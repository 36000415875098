import { styled } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { CenteredContainer } from "../../CenteredContainer";
import {ButtonProps} from "./Button.model";
import {ButtonBase} from "./ButtonBase";

const ArrowButtonContainer = styled(ButtonBase)(({theme}) => ({
	backgroundColor: theme.palette.tabs?.border,
	color: theme.palette.primary.main,
	"&:hover": {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.tabs?.border,
	}
}));

const ArrowButton = ({onClick, direction, disabled}: ButtonProps & {direction: "left" | "right", disabled: boolean}) => <ArrowButtonContainer onClick={onClick} disabled={disabled}>
	<CenteredContainer>
		{direction === "left" ? <KeyboardArrowLeft/> : <KeyboardArrowRight/> }
	</CenteredContainer>
</ArrowButtonContainer>

export {ArrowButton}