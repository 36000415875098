import {useGetTemplatesListChildren} from "./useGetTemplatesListChildren";
import {ColumnDefinition} from "../../../../../shared/components/layout/table";
import {
	BaseTableCell,
	BaseTableRow,
	ExpandableRow
} from "../../../../../shared/components/layout/table/styles";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {CenteredContainer} from "../../../../../shared/components";
import {useState} from "react";
import {BarLoader} from "react-spinners";
import {TemplateListAction, TemplateListNode} from "./models";
import {cellViewForColumn} from "./columns";

export interface TemplateListRowProps {
	depth: number,
	item: unknown,
	columns: ColumnDefinition[],
	onSelected: (item: unknown) => void,
	selected: string | null,
	onViewTasks: (template: TemplateListNode) => void,
	onDeleteTemplate: (template: TemplateListNode) => void,
	onError: (message: string) => void,
}

const TemplateListRow = ({item, depth, columns, onSelected, selected, onError, onDeleteTemplate, onViewTasks}: TemplateListRowProps) => {
	const template = item as TemplateListNode;
	const [expanded, setExpanded] = useState(template.children.length > 0);
	const getChildren = useGetTemplatesListChildren();

	useRunWhenValueChange(() => {
		if (expanded && (template.children.length === 0)) {
			getChildren.execute(template.childrenIds);
		}
	}, expanded);

	// add your children to your own array, and pass up yourself
	useRunWhenValueChange(() => {
		if (getChildren.error) {
			onError(getChildren.error!);
		}
	}, getChildren.error);


	const onCellAction = (action: unknown, column: ColumnDefinition) => {
		const cellAction = action as TemplateListAction;
		switch (cellAction) {
			case "expand":
				setExpanded(!expanded);
				break;
			case "view":
				if (column.field === "name") {
					onSelected(template);
				} else {
					onViewTasks(template);
				}
				break;
			case "delete":
				onDeleteTemplate(template);
				break;
		}
	}

	const onRowClicked = () => {
		onSelected(item);
	}

	const onRenderTableCell = (column: ColumnDefinition) => {
		const Cell = cellViewForColumn(column.field);
		return Cell ? <Cell
			key={`${column.field}-${template.id}`}
			item={{...template, expanded}}
			depth={depth}
			column={column}
			onAction={onCellAction} /> : template[column.field];
	}

	return (
		<>
			<ExpandableRow depth={depth} selected={selected === template.id} onClick={onRowClicked}>
				{columns.map((column) => onRenderTableCell(column))}
			</ExpandableRow>
			{expanded && <>
				{getChildren.isLoading && <BaseTableRow depth={0}>
					<BaseTableCell colSpan={columns.length + 1}>
						<CenteredContainer>
							<BarLoader/>
						</CenteredContainer>
					</BaseTableCell>
				</BaseTableRow>}
				{getChildren.value && getChildren.value.map((child) =>
					<TemplateListRow
						key={child.id}
						depth={depth + 1}
						item={child}
						columns={columns}
						onError={onError}
						selected={selected}
						onSelected={onSelected}
						onViewTasks={onViewTasks}
						onDeleteTemplate={onDeleteTemplate}
					/>)}
			</>
			}
		</>
	)

}

export {TemplateListRow}