import {TemplateElementDefinition} from "../model";
import {LoopIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {PropertyEditor} from "./views/PropertyEditor";
import {
	loopDefinition as baseLoopDefinition
} from "../../../../../shared/definitions/elements/loop/model";
import {PreviewView} from "./views/PreviewView";

const loopDefinition: TemplateElementDefinition = {
	...baseLoopDefinition,
	icon: LoopIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		propertyEditor: {
			view: PropertyEditor,
			requiredOnCreate: true
		},
		preview: PreviewView
	},
} as const

export {loopDefinition};