import { useAsync } from "../../../../shared/hooks";
import lib_document from "../../../../api/document";
import lib_template from "../../../../api/template";

function useImportTemplate() {

	const importTemplate = async (file: File): Promise<string> => {
		let uploadKey = "";
		// upload file to s3
		const presigned_post_data = await lib_document.create_presigned_post("temporary_upload");
		const key = presigned_post_data.fields.Key;
		const url = presigned_post_data.url;
		const fields = presigned_post_data.fields;
		const form_data = new FormData();

		Object.keys(fields).forEach(key => form_data.append(key, fields[key]));
		form_data.append('file', file);

		const config = {
			method: 'POST',
			body: form_data
		};

		let response;
		try {
			response = await fetch(url, config);
			if (response) {
				uploadKey = key.split('/').splice(-1)[0];
			}
		} catch (e) {
			throw(new Error(`Failed to upload File to S3: ${e}`));
		}

		const { type } = file;
		try {
			return (await lib_template.convertUploadedDocToTemplate(uploadKey, { type })).id;
		} catch (e) {
			throw(new Error( `Failed to convert: ${e}`))
		}
	};


	return useAsync<string>(importTemplate, false);
}

export {useImportTemplate};
