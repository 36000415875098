import {EctdModule} from "../../../../../shared/interfaces/Module";
import "./styles.scss"
export type ModuleNavigationProps = {
	modules: EctdModule[],
	selectedModule?: EctdModule,
	onSelectModule: (module: EctdModule) => void
}
const ModuleNavigation = (props: ModuleNavigationProps) => {
	const isSelected = (module: EctdModule) => props.selectedModule && props.selectedModule?.moduleNumber === module.moduleNumber;
	const onSelectModuleFactory = (module: EctdModule) => () => props.onSelectModule(module);
	return <ul className={"ectd__list--moduleNavigation"}>
		{props.modules.map(module => <li className={isSelected(module) ? "active" : ""} onClick={isSelected(module) ? undefined : onSelectModuleFactory(module)} key={`module-${module.moduleNumber}`}>Module {module.moduleNumber}</li>)}
	</ul>
}

export {ModuleNavigation}