import {Variable} from "../../../../../../../shared/interfaces/Variable";
import {VariableMapper} from "./model";
import {renderMissingVariable} from "../../../utilities/renderMissingVariable";

function mapper(variable: Variable): Promise<unknown> {
	const items = variable.value as (string[] | undefined) ?? [];
	return Promise.resolve(items.length === 0 ? renderMissingVariable(variable.name) : items.join(", "));
}

const CheckboxMapper: VariableMapper = {
	type: "checkbox",
	mapper
}

export {CheckboxMapper};