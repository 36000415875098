import {Column, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {StatusCell} from "../../../../../../shared/components/layout/table/cells/StatusCell";
import {ContentNode} from "../../../../models/content";

const Cell = (props: ColumnViewProps) => {
	const item = props.item as ContentNode;
	return <StatusCell column={props.column} id={item.id} state={item.state} />
}

const Status: Column = ({
	field: "state",
	title: "Status",
	headerAlign: "left",
	itemAlign: "left",
	width: "15%",
	sortable: true,
	searchable: true,
	views: {
		cell: Cell
	}
})

export {Status};