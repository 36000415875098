import {SubmissionState} from "../../hooks/useSubmissionState";
import {useRunOnce} from "../../../../shared/hooks";

function useCreate() {
	const submissionState = SubmissionState.useContainer();

	useRunOnce(() => {
		submissionState.reset();
	});

	return {
		submission: submissionState.submission
	}
}

export {useCreate};