import {
	ElasticBoolQuery, ElasticFuzzyMatch,
	ElasticMatch,
	ElasticMatchAll, ElasticNestableQueries, ElasticQueries,
	ElasticQuery,
	ElasticSort, ExistsQuery, MustNotQuery,
	MustQuery,
	ShouldQuery
} from "./QueryRequest";

function mustQuery(...predicate: ElasticNestableQueries[]): ElasticBoolQuery<MustQuery> {
	return {
		bool: {
			must: predicate ? predicate : []
		}
	}
}

function mustNotQuery(...predicate: ElasticNestableQueries[]): ElasticBoolQuery<MustNotQuery> {
	return {
		bool: {
			must_not: predicate ? predicate : []
		}
	}
}

function shouldQuery(minimumToMatch: number, ...predicate: ElasticNestableQueries[]): ElasticBoolQuery<ShouldQuery> {
	return {
		bool: {
			should: predicate ? predicate : [],
			minimum_should_match: minimumToMatch
		}
	}
}

function matchQuery(field: string, value: string): ElasticMatch {
	return {
		match: {
			[field]: value
		}
	}
}

function existsQuery(field: string): ExistsQuery {
	return {
		exists: {
			field
		}
	}
}

function matchAllQuery(): ElasticMatchAll {
	return {}
}

function fuzzyMatchQuery(field: string, value: string): ElasticFuzzyMatch {
	return {
		wildcard: {
			[field]: {
				value: `*${value}*`
			}
		}
	}
}

function buildBody(query: ElasticQueries, sort: ElasticSort, size?: number, after?: string[] | number[]): ElasticQuery {
	return {
		query,
		sort,
		size,
		search_after: after
	}
}

function sortQuery({field, direction}: {field: string, direction: "asc" | "desc"}): ElasticSort {
	return {
		[field]: {
			order: direction
		}
	}
}

export const ElasticHelper = {
	match: matchQuery,
	matchAll: matchAllQuery,
	fuzzyMatch: fuzzyMatchQuery,
	must: mustQuery,
	exists: existsQuery,
	mustNot: mustNotQuery,
	should: shouldQuery,
	sort: sortQuery,
	build: buildBody
}
