import {Variable} from "../../../../../../../shared/interfaces/Variable";
import {VariableMapper} from "./model";

function mapper(variable: Variable) {
	const dataSources = variable.dataSources;
	return Promise.resolve(dataSources?.length === 1 ? dataSources[0]!.data : dataSources?.reduce((lookup, current) => {
		if (current?.name === undefined) {
			return lookup;
		}
		lookup[current.name] = current.data;
		return lookup;
	}, {}));
}

const DataSourceMapper: VariableMapper = {
	type: "datasource",
	mapper
}

export {DataSourceMapper};