import {Button, SlideOver, Toolbar, ToolBarToggleButton} from "../../../../../../shared/components";
import {FilterAltRounded, PictureAsPdf, DataObjectRounded} from "@mui/icons-material";
import {useState} from "react";
import {InternalContentPicker} from "./InternalContentPicker";
import {SubmissionContent} from "../../../../../../shared/interfaces/SubmissionContent";
import {useDebounce, useRunWhenValueChange} from "../../../../../../shared/hooks";
import {EctdMetaData} from "../../../../models/MetaData";
import {ContentPickerFilter} from "./models";
import {ExternalContentPicker} from "./ExternalContentPicker";


type ContentPickerProps = {
	open: boolean,
	onClose: () => void,
	metaData: EctdMetaData,
	selectedContent: SubmissionContent[],
	onToggleContentSelection: (content: SubmissionContent) => void
}

type State = {
	filterActive: boolean,
	searchText?: string,
	internalActive: boolean
}
const ContentPicker = (props: ContentPickerProps) => {
	const [state, setState] = useState<State>({filterActive: true, internalActive: true, searchText: undefined});
	const debouncedSearchText = useDebounce(state.searchText, 300);
	const onToggleFilter = (filterActive: boolean) => setState(s => ({...s, filterActive, searchText: undefined}));
	const onToggleInternal = (internalActive: boolean) => setState(s => ({...s, internalActive, searchText: undefined}));
	const onToggleExternal = (externalActive: boolean) => setState(s => ({...s, internalActive: !externalActive, searchText: undefined}));

	useRunWhenValueChange(() => {
		setState(s => ({...s, selectedContent: []}));
	}, props.open)

	const onClose = () => props.onClose();
	const onSearchChange = (text: string) => setState(s => ({...s, searchText: text}));

	const searchFilters: ContentPickerFilter = {
		tags: state.filterActive ? props.metaData : undefined,
		searchText: debouncedSearchText
	};


	return <SlideOver
		open={props.open}
		onClose={onClose}
		renderActions={() => <Button buttonType={"plain"} text={"Close"} onClick={onClose} />}
		size={"medium"}>
		<Toolbar onSearchChange={onSearchChange} searchText={state.searchText ?? ""} searchTextUpdateDelay={0}>
			<ToolBarToggleButton onToggle={onToggleFilter} active={state.filterActive} disabled={!state.internalActive} icon={FilterAltRounded} text={"Filter to submission"} />
			<ToolBarToggleButton onToggle={onToggleInternal} active={state.internalActive} icon={PictureAsPdf} text={"Internal content"} />
			<ToolBarToggleButton onToggle={onToggleExternal} active={!state.internalActive} icon={DataObjectRounded} text={"External content"} />
		</Toolbar>
		{state.internalActive && <InternalContentPicker filters={searchFilters} onContentSelectionToggle={props.onToggleContentSelection} selectedItems={props.selectedContent} />}
		{!state.internalActive && <ExternalContentPicker filters={searchFilters} onContentSelectionToggle={props.onToggleContentSelection} selectedItems={props.selectedContent} /> }
	</SlideOver>
}

export {ContentPicker}