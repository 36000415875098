import { styled } from "@mui/material";
import { FlexBox} from "../../../layout";

const FilterLayout = styled(FlexBox)(({theme}) => ({
	transition: "all .2s ease",
	color: theme.palette.tabs?.hover,
	cursor: "pointer",
	userSelect: "none",
	'& .icon': {
		fontSize: "1.1rem",
	},
	'& .filter_title': {
		fontWeight: 600,
	},
	'& .active': {
		color: theme.palette.primary.main,
	}
}));

export {FilterLayout};