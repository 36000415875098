import {Variable} from "../../../../../../../shared/interfaces/Variable";
import { VariableMapper } from "./model";

function mapper(variable: Variable) {
	return variable.value ? Promise.resolve(`<div class="var__value-rte">${variable.value}</div>`) : Promise.resolve(``);
}

const RichTextMapper: VariableMapper = {
	type: "richtextarea",
	mapper
}

export {RichTextMapper}