import {TemplateElementDefinition} from "../../model";
import {MetaElseIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {
	metaElseDefinition as baseMetaElseDefinition
} from "../../../../../../shared/definitions/elements/meta/else/model";

const metaElseDefinition: TemplateElementDefinition = {
	...baseMetaElseDefinition,
	icon: MetaElseIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView
	},
} as const

export {metaElseDefinition};