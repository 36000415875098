import '../style.scss'
import { PreviewElementProps } from "../../model";
import {
	InTextTableDataValue,
	InTextTableElementValue
} from '../../../../../../shared/definitions/elements/inTextTable/model';
import {getInTextTableApi} from '../../../../hooks/api/useGetIttTable';
import {useRunWhenValueChange} from "../../../../../../shared/hooks";
import {AsyncComponent} from "../../../../../../shared/components";
import {Variable} from "../../../../../../shared/interfaces/Variable";
import {IttDataPromise, IttPromiseQueue} from "../../../../hooks/useIttPromiseQueue";

async function wrapGetTablePromise(tableName: string, studyId: string, source?: string): Promise<IttDataPromise> {
	try {
		const html = await getInTextTableApi({tableName, studyId});
		return {tableName, studyId, table: html.table, source};
	} catch (e: any) {
		return {tableName, studyId, table: "<div>Error loading table</div>", source}
	}
}
const PreviewView = (props: PreviewElementProps) => {
	const data = props.element.data as InTextTableElementValue;
	const studyId = props.metaData?.user?.StudyId;
	const promiseQueue = IttPromiseQueue.useContainer();
	const isLoadingVariables = props.isLoadingVariables ?? true;

	const variableName = `${data.tableName?.replace(/_/g, "")}_${studyId?.replace(/-/g, "")}`;
	const tableDataVariable: Variable | undefined =
        props.variables?.find(variable => variable.name === variableName && variable.type === "intexttable");

	useRunWhenValueChange(() => {
		if (studyId && data.tableName && (tableDataVariable === undefined && !isLoadingVariables)) {
			promiseQueue.enqueue(wrapGetTablePromise(data.tableName, studyId, props.element.source), {table: "", tableName: data.tableName, studyId});
		}
	}, [studyId, data.tableName]);


	const dataVariable: InTextTableDataValue | null = tableDataVariable?.value as InTextTableDataValue;

	return <AsyncComponent
		isLoading={promiseQueue.isLoading || isLoadingVariables}
		loadingMessage={"Loading table"}
		component={<div>
			<div className={'ittTableName'}>Table Name: {data.tableName}</div>
			<div className={'ittTable'}>
				{dataVariable?.table && <div dangerouslySetInnerHTML={{__html: dataVariable!.table}}></div>}
				{studyId === undefined && <div className={"ittTableLoading"}>Please add a Study ID</div>}
			</div>
		</div>}/>
}

export default PreviewView;