import { TreeNode } from "../../../components/list/tree/TreeList.models";
import { BaseElement } from "../../../interfaces/BaseElement";

const tableDefinition: BaseElement = {
	name: 'Template',
	type: 'table',
	displayName: 'Table',
	description: 'Place Sub-Templates',
	allowsChildren: false,
	longDescription: 'Template elements may be used to include another template at the position where this element is placed',
	contentDescription: () => "",
	toString: (contents) => {
		if ('data' in (contents as TreeNode)) {
			return (contents as TreeNode).data?.title ?? "Untitled Table";
		}
		return (contents as TableElementValue)?.title ?? "Untitled Table";
	}
} as const

export type TableElementFootnote = {
	value?: string,
	name?: string,
	id?: string,
	variable_name?: string,
	cellRef?: {row?: number, column?: number}
}

export type TableElementValue = {
	title?: string,
	datasource: string,
	column_headers?: string,
	row_headers?: string
	footnotes?: TableElementFootnote[];
}

export {tableDefinition};