import './style.scss'
import { PreviewElementProps } from "../../../model";
import { Label } from '../../../../../../../shared/components';
import { LabelValue } from '../../../../../../../shared/components/labels/LabelValue';
import { abbreviationsValue } from '../../model';

const PreviewView = (props: PreviewElementProps) => {
	return <div className={"abbreviations-preview"}>
		<Label style={{ color: "#333333" }}>{abbreviationsValue.label}</Label>
		<LabelValue>{abbreviationsValue.labelValue}</LabelValue>
	</div>
}

export default PreviewView;
