import { isJSONObject } from "../utilities/isJSONObject";
import { SYSTEM_EVERYONE_ID, SYSTEM_NOBODY_ID, SYSTEM_USER_ID } from "../../modules/task/constants/constants";

export const verifyPermissionsExpandUserIdList = (
	listToExpand: string[],
	itemPermissions = {},
	userIdsToExclude: any[] = []
) => {
	listToExpand = listToExpand || [];
	const userIdListExpanded: any[] = [];

	for (let item of listToExpand) {
		/**
		 * Some legacy roles like _save_workflow_set_ui_action_review
		 * contain stringified permission object as a backup for original permissions.
		 * They should not be used in acl directly, hence we dont need expand those roles.
		 */
		if (!item ||
			typeof item !== 'string' ||
			isJSONObject(item)) {
			continue;
		}

		const isNegativePermission = item[0] === '!';
		if (isNegativePermission) {
			item = item.slice(1);
		}

		// system users will also have '@' prefix similar to roles and groups, so check this first before checking `is_single_user_id`
		if ([SYSTEM_USER_ID, SYSTEM_EVERYONE_ID, SYSTEM_NOBODY_ID].includes(item)) {
			if (isNegativePermission) {
				userIdsToExclude.push(item);
			} else {
				userIdListExpanded.push(item);
			}
			continue;
		}

		/**
		 * if here, this is not a system user
		 * so if there is '@' in the user_id, it is not a single user id
		 * as user ids are just GUIDs
		 */
		if (!item.includes('@')) {
			if (isNegativePermission) {
				userIdsToExclude.push(item);
			} else {
				userIdListExpanded.push(item);
			}
			continue;
		}

		// if here, this is a role, user group or some other known ACL type
		const type = item.split(':')[0].slice(1);
		switch (type) {
			case 'owners':
				if (isNegativePermission) {
					userIdsToExclude.push(...itemPermissions['owners']);
				} else {
					userIdListExpanded.push(...itemPermissions['owners']);
				}
				break;
			case 'role':
				if (!itemPermissions['roles']) {
					break;
				}

				{
					const role_name = item.split(':').slice(1).join(':');
					const role_users = itemPermissions['roles'][role_name];
					const sub_user_id_list = verifyPermissionsExpandUserIdList(
						role_users,
						itemPermissions,
						userIdsToExclude
					);
					if (isNegativePermission) {
						userIdsToExclude.push(...sub_user_id_list);
					} else {
						userIdListExpanded.push(...sub_user_id_list);
					}
				}
				break;
			case 'group':
				console.log(`[WARNING] Groups are not yet supported when processing ${item}`);
				break;
			default:
				throw(new Error(`Unknown ACL Type: ${type} in ${item}`));
		}

	}

	// remove any excluded users that were added to this list prior
	// to processing negative permissions
	const userIdListExcluded = userIdListExpanded.filter(
		user_id => !userIdsToExclude.includes(user_id)
	);

	// remove any duplicates
	return([...(new Set([...userIdListExcluded]))]);
}