import {useAsync} from "../../../../shared/hooks";
import {DataSourceOptions, getDataSource} from "../../../../shared/hooks/api/data/useGetDataSource";
import {DataSource} from "../../../../shared/interfaces/DataSource";

export function getAllDataSources(sources: DataSourceOptions[]): Promise<(DataSource[] | null)[]> {
	return Promise.all(sources.map(options => getDataSource(options)));
}
function useGetDataSources() {
	return useAsync(getAllDataSources, false);
}

export {useGetDataSources};