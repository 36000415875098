import Icon from "../Icon";

export type IconVariants = "special" | "outlined" | "special_outlined" | "rounded" | "special_rounded";
export const IconVariantLookup: Record<IconVariants, string> = {
	'special': 'Special',
	'outlined': 'Outlined',
	'special_outlined': 'SpecialOutlined',
	'rounded': 'Rounded',
	'special_rounded': 'SpecialRounded'
}
export function iconForWorkflow(workflow: string, variant?: IconVariants) {
	workflow = workflow.toLowerCase().replace(/ /g,'_');

	switch (workflow) {
		case 'resume':
			return Icon.withName('PlayArrow', variant);
		case 'set_owner':
			return Icon.withName('PersonPin', variant);
		case 'set_ui_action_review':
			return Icon.withName('RateReview', variant);
		case 'set_ui_action_prompts':
			return Icon.withName('Announcement', variant);
		case 'if':
			return Icon.withName('AccountTree', variant);
		case 'set_ui_counter_button':
			return Icon.withName('PlusOne', variant);
		case 'named_code_block':
			return Icon.withName('Code', variant);
		case 'instantiate_template':
			return Icon.withName('FileCopy', variant);
		case 'plus':
			return Icon.withName('Add', variant);
		case 'minus':
			return Icon.withName('Remove', variant);
		case 'done':
			return Icon.withName('Done', variant);
		case 'request_review':
			return Icon.withName('Pageview', variant)
		case 'cancel_review_cycle':
			return Icon.withName('Cancel', variant)
		case 'complete_review':
			return Icon.withName('CheckCircle', variant)
		case 'final_review_approve':
			return Icon.withName('ThumbUp', variant)
		case 'final_review_reject':
			return Icon.withName('ThumbDown', variant)
		case 'cancel_approval_process':
			return Icon.withName('CancelScheduleSend', variant)
		case 'request_approval':
			return Icon.withName('Explore', variant)
		default:
			console.error('No known icon for workflow', workflow);
			return(Icon.notFound());
	}
}