import {Column, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {FlexBox} from "../../../../../../shared/components";
import {modelDocumentMetadataKey, SortableColumn, TemplateListNode} from "../models";
import {MetadataDisplay} from "../styles";
import {Tooltip} from "../../../../../../shared/components/labels/Tooltip";
import {DataTableCell} from "../../../../../../shared/components/layout/table/styles";
import {FilterState} from "../../../../../../shared/components/input/filter/sort/models";

const ModelDocumentCell = (props: ColumnViewProps) => {
	const item = props.item as TemplateListNode
	const column = props.column;
	const metadataList = Object.keys(item.metadata).map((meta) => {
		return `${meta}: ${item.metadata[meta]}`;
	}).join(", ");

	return <DataTableCell
		align={column.itemAlign}
		width={column.width}
		key={`${column.title}-${item.id}`}
		// for non-data displaying cells, allow full width of cell
		sx={{...(!column.field && {paddingLeft: 0})}}
	>
		<FlexBox direction="column">
			<Tooltip title={`${item.model_document}`} placement="right">
				<MetadataDisplay>{item.model_document}</MetadataDisplay>
			</Tooltip>
			{metadataList.length > 0 && <Tooltip title={`${metadataList}`} placement="right">
				<MetadataDisplay>{metadataList}</MetadataDisplay>
			</Tooltip>}
		</FlexBox>
	</DataTableCell>
}

const ModelDocumentSort = (items: TemplateListNode[], state: FilterState) => {
	return items.sort((a, b) => {
		if (state === "ascending") {
			// sort by model document.
			if (a.metadata?.[modelDocumentMetadataKey] < b.metadata?.[modelDocumentMetadataKey]) {
				return -1;
			}
			if (a.metadata?.[modelDocumentMetadataKey] > b.metadata?.[modelDocumentMetadataKey]) {
				return 1;
			}
			return 0;
		} else {
			if (a.metadata?.[modelDocumentMetadataKey] > b.metadata?.[modelDocumentMetadataKey]) {
				return -1;
			}
			if (a.metadata?.[modelDocumentMetadataKey] < b.metadata?.[modelDocumentMetadataKey]) {
				return 1;
			}
			return 0;
		}
	});
}

const ModelDocument: Column = ({
	field: "metadata",
	title: "Model Document",
	headerAlign: "left",
	itemAlign: "left",
	width: "15%",
	sortable: true,
	searchable: true,
	views: {
		cell: ModelDocumentCell
	}
});

const ModelDocumentSorter: SortableColumn = {
	field: "metadata",
	sorter: ModelDocumentSort
}

export {ModelDocument, ModelDocumentSorter};