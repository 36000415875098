import {NumberedControlBox, Button, FlexBox, Label} from "../../../../../../shared/components";
import {Add, CheckCircleRounded} from "@mui/icons-material";
import {SubmissionState} from "../../../../hooks/useSubmissionState";
import "./styles.scss";
import {colors} from "../../../../../../theme";
import {EditButton} from "../../../../../../shared/components/layout/box/editControlBox/EditButton";
import {DeleteButton} from "../../../../../../shared/components/layout/box/editControlBox/DeleteButton";

export type AssignCompoundBoxProps = {
	onAddCompoundClick: () => void,
	onRemoveCompound: () => void,
	onEditCompound: () => void
}
const AssignCompoundBox = (props: AssignCompoundBoxProps) => {
	const {submission} = SubmissionState.useContainer();
	return <NumberedControlBox number={1} title={"Assign Compound"} enabled={true}>
		<FlexBox align={"center"} justify={"center"} sx={{margin: "1rem 0 3rem 0"}} className={"ectd__acb"}>
			{!submission.compound && <Button buttonType="default" icon={<Add fontSize="small" key={"select-compound"}/>} text="Select from Compound List" onClick={props.onAddCompoundClick} />}
			{submission.compound && <FlexBox justify={"space-between"} align={"center"}>
				<FlexBox align={"center"}>
					<CheckCircleRounded fontSize={"small"} style={{color: colors.mountainMeadow}} />
					<p>{submission.compound}</p>
				</FlexBox>
				<FlexBox justify={"flex-end"} align={"center"}>
					<Label>Indications</Label>
					{submission.indications && <ul>
						{submission.indications.map(indication => <li key={indication}>{indication}</li>)}
					</ul>}
					<EditButton onClick={props.onEditCompound} />
					<DeleteButton onClick={props.onRemoveCompound} />
				</FlexBox>
			</FlexBox>}
		</FlexBox>
	</NumberedControlBox>
}

export {AssignCompoundBox}