import {
	Paper,
	TableContainer,
	TableBody,
	Table as MuiTable,
	TablePagination, Box
} from "@mui/material";
import React, {useMemo, useState} from "react";
import {TableHead} from "./TableHead";
import {sort, Order, search} from "./tableSort";
import {TableTitle} from "./TableTitle";
import {
	TableCellEvent,
	TableCellReference,
	TableCellType,
	TableColumnsType,
	TableColumnType,
	TableRowCells
} from "./TableRowCells";
import {DataSourceMapType} from "../../elements/variable/views/editor/types/models";
import useCopyToClipboard from "../../../../../shared/hooks/useCopyToClipboard";
import {EmptyTableBody} from "./EmptyTableBody";


export type TableDataType = {
	columns: TableColumnsType,
	rows: TableCellType[]
}
export type TableDataSourceInfo = {
	name: string,
	type: string
}
export interface TableProps {
	data: TableDataType,
	name: string,
	pageSize: number,
	type: DataSourceMapType | ""
}

const Table = (props: TableProps) => {
	const clipBoard = useCopyToClipboard();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(props.pageSize);
	const [searchText, setSearchText] = useState("");
	const [orderBy, setOrderBy] = useState<string|undefined>(undefined);
	const [order, setOrder] = React.useState<Order>('asc');

	const onPageChange = (event: unknown, newPage: number) => setPage(newPage);
	const onRowSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	const onSort = (event: React.MouseEvent<unknown>, property: string) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	}

	const onResetSort = () => {
		setOrderBy(undefined);
	}

	const cellReferenceHandlerFactory = (rowIndex: number) => (event: TableCellEvent, type: TableCellReference) => {
		let cellReference = "";

		if (event.columns.length > 1) {
			cellReference = `{{${props.name}.data | row('${rowIndex}')}}`;
		} else {
			switch (type) {
				case "abs":
					cellReference = event.columns.map(column => `{{${props.name} | absolute('${rowIndex + 1}', '${column.index}')}}`).join(", ");
					break;
				case "row":
					cellReference = event.columns.map(column => `{{${props.name} | find_rows('${column.field}','${event.row[column.field]}')}}`).join(", ");
					break;
				case "col":
					cellReference = event.columns.map(column => `{{${props.name} | find_columns('${rowIndex}','${event.row[column.field]}')`).join(", ");
					break;
				case "named":
					if (props.type === "columns-rows") {
						cellReference = event.columns.map(column => `{{${props.name}.data['${column.field}']['${rowIndex}']}}`).join(", ");
					} else {
						cellReference = event.columns.map(column => `{{${props.name}.data['${rowIndex}']['${column.field}']}}`).join(", ");
					}
			}
		}

		clipBoard.copy(cellReference);
	}

	const onColumnReferenceClick = (column: TableColumnType) => {
		clipBoard.copy(`{{${props.name}.data | column('${column.field}')}}`);
	}

	const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value);
	}

	const data = useMemo(() => {
		return sort(search(props.data.rows, searchText), order, orderBy);
	}, [props.data.rows, searchText, order, orderBy])

	return (
		<Box sx={{p: 2, backgroundColor: "rgba(0,0,0,0.08)", mb: 2, mt: 2}}>
			<Paper>
				<TableTitle searchText={searchText} onResetSort={onResetSort} onSearchChange={onSearchChange} title={"Datasource Preview"} isSorting={orderBy !== undefined}/>
				<TableContainer>
					<MuiTable aria-label="simple table" size={"small"}>
						<TableHead
							columns={props.data.columns}
							onRequestSort={onSort}
							orderBy={orderBy}
							order={order}
							renderContextMenu={data.length > 0}
							onCellReferenceClick={onColumnReferenceClick}
						/>
						{data.length > 0 && <TableBody>
							{data.slice(page*rowsPerPage, page*rowsPerPage + rowsPerPage)
								.map((row, rowIndex) => (
									<TableRowCells row={row} columns={props.data.columns} onCellReferenceSelected={cellReferenceHandlerFactory(rowIndex)}  />
								))}
						</TableBody>}
					</MuiTable>
				</TableContainer>
				{data.length === 0 && <EmptyTableBody>No results</EmptyTableBody>}
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={data.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={onPageChange}
					onRowsPerPageChange={onRowSizeChange}
				/>
			</Paper>
		</Box>
	);

}

export {Table}