import {MetaDataValueEditor, ValueEditorProps} from "./model";
import {Button, FlexBox} from "../../../../../../../shared/components";
import {BaseModal} from "../../../../../../../shared/components/modals/BaseModal";
import {ChangeEvent, useState} from "react";
import {TextField} from "../../../../../../../shared/components/input";
import {Create} from "@mui/icons-material";
import {colors} from "../../../../../../../theme";

type Substance = {
	name: string,
	manufacturer: string
}

const Editor = (props: ValueEditorProps) => {
	const substance: Substance = JSON.parse(props.data.value);
	const [addSubstance, setAddSubstance] = useState(false);

	const [name, setName] = useState(substance.name);
	const [manufacturer, setManufacturer] = useState(substance.manufacturer);

	const onAddSubstance = () => setAddSubstance(true)
	const onCancelAdd = () => setAddSubstance(false);

	const onSetName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setName(e.target.value);
	const onSetManufacturer = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setManufacturer(e.target.value);

	const onSave = () => {
		setAddSubstance(false);
		props.onChange(JSON.stringify({name, manufacturer}));
	}

	const hasValue = (substance.name?.length ?? 0) > 0 && (substance.manufacturer?.length ?? 0) > 0;
	return <>
		{hasValue && <FlexBox
			onClick={onAddSubstance}
			align={"center"}
			sx={{...(props.sx ?? {}), cursor: "pointer"}}>
			<span>{substance.name} ({substance.manufacturer})</span>
			<Create fontSize={"small"} style={{color: colors.trueBlue, marginLeft: ".25rem"}} />
		</FlexBox>}
		{!hasValue && <Button text={"Add"} onClick={onAddSubstance} />}
		<BaseModal
			visible={addSubstance}
			onClose={onCancelAdd}
			title={"Substance"}
			buttonRow={<>
				<Button text={"Cancel"} styling={"cancel"} onClick={onCancelAdd} />
				<Button buttonType={"default"} text={"Save"} onClick={onSave} />
			</>}
			content={<FlexBox direction={"column"}>
				<TextField value={name} label={"Name"} onChange={onSetName} />
				<TextField value={manufacturer} sx={{marginTop: "1rem"}} label={"Manufacturer"} onChange={onSetManufacturer} />
			</FlexBox>}
		/>
	</>
}

export const SubstanceEditor: MetaDataValueEditor = {
	view: Editor,
	displayTextGenerator: (value: string) => {
		const substance = JSON.parse(value) as Substance;
		return `${substance.name} (${substance.manufacturer})`;
	},
	defaultValueFactory: () => JSON.stringify({name: "", manufacturer: ""})
}