import { useStatusBar } from "../../../../../shared/components";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import { CommentPayload } from "../../../components/modals/documentComments/CommentModal.models";
import { useAddComment } from "../../api/comments/useAddComment";
import { CommentState, DocumentComment } from "../../useCommentState";
import { DocumentState } from "../../useDocumentState";
import { addCommentWithCollection } from "../";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

function useAddCommentAction(): TypedAction<CommentPayload> {

	const commentsManager = CommentState.useContainer();
	const documentManager = DocumentState.useContainer();
	const addComment = useAddComment();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error saving comment", detail: addComment.error});
	}, addComment.error);

	useRunWhenValueChange(() => {
		if(addComment.status === "success"){
			statusBar.sendSuccessNotification({message: "Comment saved"});
		}
	}, addComment.value);

	const run = async (commentPayload: CommentPayload) => {
		// have to do the await here because the elementId is not returned in the body
		// only the comment payload fields are returned
		// this is the only place where all the data is in one place
		// so using await here
		const response = await addComment.execute(commentPayload.documentId, commentPayload);

		const savedComment: DocumentComment = {
			...response,
			elementId: commentPayload.elementId,
			sourceDocumentId: commentPayload.documentId
		}

		const comments = addCommentWithCollection(savedComment, commentsManager!.comments!, documentManager!.document!.id);

		commentsManager.setComments(comments);

	}

	return {run, isRunning: addComment.isLoading};
}

export {useAddCommentAction};