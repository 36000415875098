import {ExternalContentApiResponse} from "../../interfaces/ExternalFile";
import {getDataApi} from "../../hooks/api/data/getDataApi";
import {globToRegex} from "../globToRegex";
import {TryAsync} from "../../interfaces/Either";

export type GetDataSourceMatchesDependencies = {
	getData: () => Promise<ExternalContentApiResponse>
}

export type GetDataSourceMatchesReturn = {
	url: string,
	shortName: string
}
export async function getDataSourceMatches(source: string, options?: GetDataSourceMatchesDependencies): Promise<GetDataSourceMatchesReturn[]> {
	if (source.length === 0) {
		return [];
	}

	const getDataAsync = options?.getData ?? getDataApi;
	const dataSourceItemsEither = await TryAsync(() => getDataAsync());
	const dataSourceItems = dataSourceItemsEither.matchWith({left: _ => [], right: v => v.items});
	if (dataSourceItems.length === 0) {
		return [];
	}

	const starPosition = source.indexOf('*');
	const trimLeft = source.lastIndexOf('/', starPosition) + 1;
	const trimRight = (source.length - starPosition - 1) * -1;

	const sourceRegex = globToRegex({
		glob: source, options: {
			extended: true,
			globstar: true,
			flags: ""
		}
	});

	/* Loop through datasource items and check if url matches source */
	return dataSourceItems.reduce((matches, item) => {
		const {url} = item;
		if (url.match(sourceRegex) === null) {
			return matches;
		}

		matches.push({
			url,
			shortName: trimLeft > trimRight ? url.slice(trimLeft) : url.slice(trimLeft, trimRight)
		})

		return matches;
	}, [] as GetDataSourceMatchesReturn[]);

}