import {Popper} from "@mui/material";
import { RequestEditPopupContainer } from "./RequestEditPopup.style";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {useState} from 'react';
import { Button, FlexBox } from "../../../../../shared/components";
import { TextField } from "../../../../../shared/components/input";

const RequestEditPopup = ({open, anchorEl, onSubmit, onCancel}) => {
	const [requestMessage, setRequestMessage] = useState<string>("");

	const onTextFieldChange = (e) => {
		const { value } = e.target;
		setRequestMessage(value);
	}

	const onRequestEditClicked = () => {
		onSubmit(requestMessage);
	}

	return <Popper
		id={"request-edit-popup"}
		open={open}
		anchorEl={anchorEl}
		placement="right"
		popperOptions={{
			modifiers: [{
				name: "offset",
				options: {
					offset: [120, -75],
				},
			}],
		}}
	>
		<ClickAwayListener onClickAway={onCancel}>
			<RequestEditPopupContainer direction="column">
				<h3>Request Edit</h3>
				<p>Please provide a description of the edit you would like made:</p>
				<TextField id="requestMessage" label="Request Notes" value={requestMessage} variant="outlined" onChange={onTextFieldChange} />
				<FlexBox align="center" justify="flex-end" gap={"5px"} marginTop="20px">
					<Button text="Request Edit" disabled={requestMessage === ""} onClick={onRequestEditClicked}/>
					<Button styling="cancel" text="Cancel" onClick={onCancel} />
				</FlexBox>
			</RequestEditPopupContainer>
		</ClickAwayListener>
	</Popper>
}

export {RequestEditPopup}