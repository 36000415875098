import {EctdModule} from "../../../shared/interfaces/Module";

export function recursivelyMap<T>(modules: EctdModule[], map: (module: EctdModule) => T): T[] {
	const items = modules.map(map);
	const childModules = modules.reduce((childModules, module) => childModules.concat(module.modules), [] as EctdModule[]);
	if (childModules.length === 0) {
		return items;
	}

	const childItems = recursivelyMap(childModules, map);
	return [...items, ...childItems];
}