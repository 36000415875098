import {useAsync} from "../../index";
import {DataSource} from "../../../interfaces/DataSource";
import {getDataSourceMatches, GetDataSourceMatchesReturn} from "../../../utilities/data/getDataSourceMatches";
import {FetchDataSourceProps, fetchSource} from "./fetchSource";

export type DataSourceOptions = {
	type?: string,
	column_headers?: string[],
	row_headers?: string[],
	name?: string,
	id?: string,
	source?: string
}

export type FetchDataSourceFunc = (source: string, options: DataSourceOptions) => Promise<DataSource | null>;
export type GetDataSourceMatchesFunc = (source?: string) => Promise<GetDataSourceMatchesReturn[]>
type GetDataSourceDependencies = {
	fetchDataSource: FetchDataSourceFunc,
	getDataSourceMatch: GetDataSourceMatchesFunc
}

export async function getDataSource(options: DataSourceOptions, dependencies?: Partial<GetDataSourceDependencies>): Promise<DataSource[] | null> {
	const fetchDataSourceFunc = dependencies?.fetchDataSource ?? fetchDataSource;
	const getDataSourceMatchesFunc = dependencies?.getDataSourceMatch ?? getDataSourceMatches;

	if (options.source?.includes("*") ?? false) {
		const matches = await getDataSourceMatchesFunc(options.source ?? "");
		const sources = await Promise.all(matches.filter(match => match?.url !== undefined).map(async match => {
			const data = await fetchDataSourceFunc(match.url, options);
			return data === null ? null : {...data, name: match.shortName};
		}));

		return sources.filter(source => source !== null);
	} else if(options.source) {
		const data = await fetchDataSourceFunc(options.source!, options);
		return data === null ? [] : [{...data, name: options.name}];
	}

	return null;
}

export type FetchDataSourceDependencies = Partial<{
	fetchData: (props: FetchDataSourceProps) => Promise<any>
}>
export async function fetchDataSource(source?: string, options?: DataSourceOptions, dependencies?: FetchDataSourceDependencies): Promise<DataSource | null> {
	if (source === undefined || options === undefined) {
		return null;
	}

	const fetchDataFunc = dependencies?.fetchData ?? fetchSource;
	const datasource = await fetchDataFunc({url: source, options});

	const data = datasource.data;

	return {
		name: options.name,
		data,
		id: options.id,
		type: datasource.type
	}
}

export function useGetDataSource() {
	return useAsync(getDataSource, false);
}