import {Variable} from "../../../../../../../../shared/interfaces/Variable";
import {ReferenceElementContents, ReferenceVariableValue} from "../../../../../../../../shared/definitions/elements/reference/model";
import {formatReference} from "../formatReference";
import {VariableMapper} from "./model";
import { definitionForElementType } from "../../../..";
import { findElementsInBody } from "../../../../utilities/findElementsInBody";

function mapper(variable: Variable, body?: KaiAlphaBody) {
	const referencedElement = variable.value as ReferenceElementContents;
	const [element,] = findElementsInBody(body ?? [], referencedElement.element_id);
	const elementDefinition = definitionForElementType(element?.type);
	const reference = {
		...variable,
		value: {
			...variable.value as ReferenceElementContents,
			name: elementDefinition?.toString(element) ?? "No Element Name",
			value: elementDefinition?.contentDescription(element) ?? "Value Unavailable",
		}
	} as ReferenceVariableValue;

	return formatReference(reference);
}

const ReferenceMapper: VariableMapper = {
	type: "reference",
	mapper
};

export {ReferenceMapper}