import Box from "@mui/material/Box";
import { styled } from "@mui/styles";
import { useState } from "react";
import { FlexBox } from "../../../../shared/components";
import { CommentButton } from "../../../../shared/components/buttons/CommentButton";
import { DocumentState } from "../../hooks/useDocumentState";
import CommentModal from "../modals/documentComments/CommentModal";
import { documentViewForElementType, PreviewElementProps } from "./model";

export type PreviewViewProps = PreviewElementProps & { noMargin?: boolean }

const Container = styled(FlexBox)({
	"& .buttons": {
		display: "none",
	},
	"&.element-wrapper:hover > .button-container > .buttons": {
		display: "flex"
	}
})

const elementTypesWithoutContainers: string[] = ["template", "switch", "loop"];

const PreviewViewFactory = (props: PreviewViewProps) => {

	const documentManager = DocumentState.useContainer();

	const documentId = documentManager.documentCollection.find(doc => doc.template?.id === props.element.source)?.id;


	const [showModal, setShowModal] = useState(false);

	const handleCommentClick = () => {
		setShowModal(true);
	}

	const handleCommentModalClose = () => {
		setShowModal(false);
	}


	const View = documentViewForElementType(props.element.type);
	if (View && elementTypesWithoutContainers.includes(props.element.type)) {
		return <View {...props} />
	}

	return View ? <div className={"preview__element"} data-type={props.element.type} id={props.element.id} style={{
		margin: props.noMargin ? 0 : "10px",
		width: `calc(100% - ${props.noMargin ? "0px" : "20px"})`, boxSizing: "border-box"
	}}>
		<Container direction={"column"} sx={{ boxSizing: "border-box", width: "100%" }} className={props.element.type !== 'template' ? "element-wrapper" : ""}>
			{props.element.type !== "template" && documentManager.commentable && <FlexBox justify={"flex-end"} sx={{ mb: ".5rem" }} className={"button-container"}>
				<Box className={"buttons"}>
					<CommentButton onClick={handleCommentClick} />
				</Box>
			</FlexBox>
			}
			<View {...props} />
			{
				showModal &&
				<CommentModal
					onClose={handleCommentModalClose}
					addEditComment={"Add"}
					showOpen={showModal}
					elementId={props.element.id}
					documentId={documentId}
				/>
			}
		</Container>
	</div> : null
}

export { PreviewViewFactory }