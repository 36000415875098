import {styled} from "@mui/material";
import { FlexBox } from "../../FlexBox";


const MetadataListContainer = styled(FlexBox)(({theme}) => ({
	'& .title_text': {
		color: theme?.palette?.variable?.main,
		fontWeight: 'bold',
		fontSize: '15px',
		paddingTop: "2px"
	},
	'& .value_text': {
		color: theme?.palette?.variable?.main,
		fontSize: '13px',
	}
}));

export {MetadataListContainer};