import {ListItemTitle} from "./ListItemTitle";
import {MajorVersionLookupType, VersionType} from "./models";

export interface ListItemProps {
	version: MajorVersionLookupType,
	onSelect: (version: VersionType) => void
}
const MajorVersion = ({version, onSelect}: ListItemProps) => (<li key={version.versionNumber}>
	<ListItemTitle onClick={onSelect} version={version.majorVersion} majorVersionNumber={version.versionNumber} minorVersionNumber={0} />
	<ul>
		<MinorVersions version={version} onSelect={onSelect} />
	</ul>
</li>);

const MinorVersions = ({version, onSelect}: ListItemProps) => <>
	{version.minorVersions.map((minorVersion, idx) => <li key={minorVersion.version}>
		<ListItemTitle onClick={onSelect} version={minorVersion} majorVersionNumber={version.versionNumber - 1} minorVersionNumber={version.minorVersions.length - idx} />
	</li>)}
</>

const ListItem = ({version, onSelect}: ListItemProps) => version.majorVersion ?
	<MajorVersion version={version} onSelect={onSelect} /> :
	<MinorVersions version={version} onSelect={onSelect} />

export {ListItem};