import { searchTemplatesApi } from "../../../shared/hooks/api/useGetTemplates";

export interface SuperTemplate
	{ id: string,
		version: string,
		metadata: {
			system: {[k: string]: string},
			user?: {[k: string]: string}
		}
	;}

async function getTemplateSupers(templateId: string): Promise<SuperTemplate[]> {
	try {
		const templates = await searchTemplatesApi({
			filters: [{field: "@subtemplates", value: templateId, expression: "="}]});

		return templates.results.reduce(
			(superResponses: SuperTemplate[], templateInfo) =>
				templateInfo !== undefined ?
					superResponses.concat(
						{
							id: templateInfo.id!,
							version: templateInfo.version!,
							metadata: templateInfo.metadata!
						}) :
					superResponses, []);
	} catch(error) {
		throw new Error(`get template supers failed on searchTemplatesApi call`);
	}
}

export {getTemplateSupers};