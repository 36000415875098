import {TypedVariableView, TypedVariableViewProps} from "./model";
import {FlexBox, Label} from "../../../../../../../shared/components";
import {LabelValue} from "../../../../../../../shared/components/labels/LabelValue";

type DropDownValueType = {
	items: string[]
}

const DropDownView = (props: TypedVariableViewProps) => {
	const data = props.options as DropDownValueType;
	return <FlexBox>
		<Label>Dropdown items:</Label>
		<LabelValue>{data?.items?.join(", ") ?? "No options set"}</LabelValue>
	</FlexBox>
};

const DropDownVariable: TypedVariableView = {
	type: "dropdown",
	view: DropDownView
}
export {DropDownVariable}