import {Visibility} from "@mui/icons-material";
import {styled} from "@mui/material";
import {VersionType} from "../../input/dropdown/versionSelector";

const SnackBarVersion = styled("span")(({theme}) => ({
	color: theme.palette.documentState?.inProcess.main,
	pointerEvents: 'none',
	padding: "11px 8px 8px",
	fontWeight: 700
}));

export interface VersionNotificationProps {
	version?: VersionType
}

const VersionNotification = ({version}: VersionNotificationProps) => {
	return <>
		<Visibility />
		Viewing: <SnackBarVersion>
		Version {version?.number}
		</SnackBarVersion>
	</>
}

export {VersionNotification};