import {styled, Autocomplete as MuiAutocomplete} from "@mui/material";
import {Cancel as MuiCancel, CheckCircle as MuiCheckCircle} from  "@mui/icons-material";

const Container = styled("div")(({theme}) => ({
	margin: "1rem 0",
	fontSize: ".875rem",
	width: "100%",
	"& span.surTitle": {
		fontSize: ".75rem",
		paddingBottom: ".3rem",
		color: theme.palette.text.secondary
	},
	"& label.templateTitle": {
		paddingBottom: "1rem"
	},
	"& .MuiTextField-root, & .MuiFormControlLabel-root": {
		marginBottom: "1rem"
	},
	"& .check_label": {
		fontSize: ".85rem",
		padding: "0 .5rem",
		boxSizing: "border-box"
	}
}));

const AutocompleteOption = styled('li')(({
	fontSize: "0.875rem",
}));

const Cancel = styled(MuiCancel)(({theme}) => ({
	fontSize: "1.2rem",
	color: theme.palette.statusNotifications?.error,
}));

const CheckCircle = styled(MuiCheckCircle)(({theme}) => ({
	fontSize: "1.2rem",
	color: theme.palette.statusNotifications?.success,
}));

const Autocomplete = styled(MuiAutocomplete)(({
	margin: "1rem 0 0",
	boxSizing: "border-box",
}));

export {Autocomplete, Container, AutocompleteOption, Cancel, CheckCircle}