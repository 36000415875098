import {ValueEditorProps} from "./model";
import {DropDownItem} from "../../../../../../../shared/components/input/dropdown";
import {
	MultiSelectDropDown
} from "../../../../../../../shared/components/input/dropdown/multiSelect/MultiSelectDropDown";

type MultiSelectValueEditorOptions = {
	items: DropDownItem<string>[]
}

const MultiSelectValueEditor = (props: ValueEditorProps) => {
	const value = JSON.parse(props.data.value) as string[] ?? [];
	const options = props.data.options as MultiSelectValueEditorOptions

	const onOptionSelect = (option: string) => props.onChange(JSON.stringify(value.includes(option) ? value.filter(v => v !== option) : [...value, option]));
	return <MultiSelectDropDown sx={{maxWidth: "180px"}} valueTextLimit={1} items={options.items} onToggleSelection={onOptionSelect} value={value} />
}

export {MultiSelectValueEditor}