import { BaseElement } from "../../../interfaces/BaseElement";

const imageDefinition: BaseElement = {
	name: 'Template',
	type: 'image',
	displayName: 'Image',
	description: '',
	allowsChildren: false,
	longDescription: '',
	contentDescription:  (contents) => {
		return (contents as ImageElementValue)?.key ?? "";
	},
	toString: () => "(Preview Unavailable)"
} as const

export type ImageElementValue = {
	type?: string,
	key?: string,
}

export {imageDefinition};