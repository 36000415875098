import {useAsync} from "../../../../shared/hooks";
import {getDocumentTasksApi} from "./getDocumentTasksApi";


function useGetDocumentTasks() {
	const api = useAsync(getDocumentTasksApi, false);
	const loadTasks = (documentId?: string) => api.execute(documentId);

	return {
		loadTasks,
		isLoading: api.isLoading,
		value: api.value,
		error: api.error
	}
}

export {useGetDocumentTasks}