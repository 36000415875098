import {useGetInternalContent} from "../../../../hooks/api/useGetInternalContent";
import {useRunWhenValueChange} from "../../../../../../shared/hooks";
import {AsyncComponent, FlexBox} from "../../../../../../shared/components";
import "./styles.scss";
import {ArrowBackIosNewRounded, ArrowForwardIosRounded} from "@mui/icons-material";
import {useState} from "react";
import {EmptyGroup} from "../../../../../../shared/components/layout/groups/EmptyGroup";
import {colors} from "../../../../../../theme";
import {EntityMetaData} from "../../../../../../shared/interfaces/EntityMetaData";
import {ContentPickerProps} from "./models";
import {ContentListItem} from "./ContentListItem";
import {isInternalContent} from "../../../../../../shared/interfaces/SubmissionContent";

const pageSize = 25;

const InternalContentPicker = (props: ContentPickerProps) => {
	const [contentList, setContentList] = useState<KaiAlphaDocument[]>([]);
	const [nextResult, setNextResult] = useState<string[] | number[] | undefined>(undefined);
	const [page, setPage] = useState(0);
	const contentLoader = useGetInternalContent(pageSize);

	useRunWhenValueChange(() => {
		contentLoader.execute(props.filters);
		setPage(0);
		setNextResult(undefined);
	}, props.filters)

	useRunWhenValueChange((prevPage) => {
		if ((prevPage ?? 0) < page && contentList.length <= (page * pageSize)) {
			setNextResult(contentLoader.value?.next);
		}
	}, page);

	useRunWhenValueChange(() => {
		contentLoader.execute({...(props.filters ?? {}), after: nextResult});
	}, nextResult)

	useRunWhenValueChange(() => {
		setContentList(page === 0 ? contentLoader.value!.results : [...contentList, ...contentLoader.value!.results]);
	}, contentLoader.value);

	const onToggleSelectionFactory = (content: KaiAlphaDocument) => () => props.onContentSelectionToggle({id: content.id, version: content.version, versionNumber: content.metadata?.system?.versionNumber, status_when_added: content.state ?? "In Process", name: content.name ?? "Untitled", internal: true})

	const onNext = () => setPage(page+1);
	const onPrevious = () => setPage(page-1);

	const contentIsSelected = (content: KaiAlphaDocument) => props.selectedItems.some(item => isInternalContent(item) && item.id === content.id && item.version === content.version);

	const hasNextResult = () => ((page+1) * pageSize) < (contentLoader.value?.total ?? 0);

	const getMetaData = (document: KaiAlphaDocument) => document.metadata as EntityMetaData;

	const currentPageLimit = (page + 1) * pageSize > (contentLoader.value?.total ?? 0) ? (contentLoader.value?.total ?? 0) : (page + 1) * pageSize;
	return <AsyncComponent
		isLoading={contentLoader.isLoading}
		loadingMessage={"Loading content..."}
		component={<div className={"ectd__modal--contentpicker-internal"}>
			<ul className={"ectd__modal--contentpicker-list"}>
				{contentList.length > 0 ? contentList.slice(page * pageSize, (page * pageSize) + pageSize)
					.map(document => <ContentListItem
						id={document.id}
						name={document.name ?? "Untitled Content"}
						isSelected={contentIsSelected(document)}
						onToggleSelection={onToggleSelectionFactory(document)}
						version={document.metadata?.system?.versionNumber}
						state={document.state}
						metaData={getMetaData(document)}
					/> ) : <EmptyGroup title={"No content"} />}
			</ul>
			{(contentLoader.value?.total ?? 0) > 0 && <FlexBox justify={"space-between"}>
				<FlexBox width={"auto"}>
					<ArrowBackIosNewRounded onClick={page === 0 ? undefined : onPrevious} fontSize={"small"} sx={{color: page === 0 ? colors.mintDark : "rgba(0, 0, 0, 0.5)", cursor: "pointer", marginRight: ".5rem"}} />
					<ArrowForwardIosRounded onClick={!hasNextResult() ? undefined : onNext} fontSize={"small"} sx={{color: hasNextResult() ? "rgba(0, 0, 0, 0.5)" : colors.mintDark, cursor: "pointer", marginLeft: ".5rem"}} />
				</FlexBox>
				{contentLoader.value?.total && <span style={{color: "rgba(0, 0, 0, 0.5)"}}>{(page * pageSize) + 1} to {currentPageLimit} of {contentLoader.value.total} {(contentLoader.value.total === 1 ? "item" : "items")}</span>}
			</FlexBox>}
		</div>} />
}

export {InternalContentPicker}