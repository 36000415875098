import { UserTask } from "../../../shared/interfaces/task";
import { getSystemTaskList } from "../../../shared/tasks/getTaskList";
import { getTemplateTasksByTemplateId } from "../../../shared/tasks/getTemplateTasksByTemplateId";

const getTemplateTasks = async (templateId: string) => {
	const templates = await getTemplateTasksByTemplateId(templateId);
	const templateTasks = templates.results.map(template => {
		const templateObject = template as Object;
		return {
			...templateObject,
			type: 'template',
		};
	});


	return getSystemTaskList(templateTasks as UserTask[]);
}

export {getTemplateTasks}