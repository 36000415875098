import {TemplateState} from "../../useTemplateState";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {useUpdatePermissions} from "../../api/useUpdatePermissions";
import {useStatusBar} from "../../../../../shared/components";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

type RunProps = {
	templateId: string,
	permissions: KaiAlphaTemplatePermissions,
	lastupdated?: number
}
function useSetPermissionsAction(): TypedAction<RunProps> {
	const templateState = TemplateState.useContainer();
	const updatePermissions = useUpdatePermissions();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		const res = updatePermissions.value;
		if (res?.permissions && res.version) {
			templateState.setTemplate(s => ({
				...s,
				lastupdated: res.lastupdated,
				permissions: res.permissions,
				version: res.version,
			}));

			statusBar.sendSuccessNotification({message: "Permissions updated"});
		}

	}, updatePermissions.value);

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error updating permissions", detail: updatePermissions.error})
	}, updatePermissions.error)

	const run = ({templateId, permissions, lastupdated}: RunProps) =>
		updatePermissions.execute(templateId, permissions, lastupdated);

	return {run, isRunning: updatePermissions.isLoading};
}

export {useSetPermissionsAction}