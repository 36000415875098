import {ensureArgs} from "../filterUtilities";
import {isArray} from "../../../guards/isArray";
import {NunjucksFilterFactoryProps} from "../model";

function flatten(...args: unknown[]): unknown {
	if (!ensureArgs(args)) {
		return [];
	}

	if (isArray(args[0])) {
		return args[0].reduce((all, current) => all.concat(current), []);
	} else if(args[0] instanceof Object && args[0] !== undefined) {
		return Object.values(args[0])
	}

	return [];
}

export function flattenFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "flatten",
		help: "Filter that accepts an array or object and produces only the values",
		example: "var | flatten",
		execute: flatten
	})
}