import {ElementIconProps, IconDefaults} from "../model";

const ImageIcon = ({color}: ElementIconProps) => {
	const style = {
		fill: "none",
		stroke: color ?? IconDefaults.color!,
		"stroke-linecap": "round",
		"stroke-line-join": "round",
		"stroke-width": "1.5px"
	}

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17.811" height="17.811" viewBox="0 0 17.811 17.811">
			<g transform="translate(-3.75 -3.75)">
				<path style={style} d="M6.278,4.5H18.722A1.778,1.778,0,0,1,20.5,6.278V18.722A1.778,1.778,0,0,1,18.722,20.5H6.278A1.778,1.778,0,0,1,4.5,18.722V6.278A1.778,1.778,0,0,1,6.278,4.5Z"/>
				<path style={style} d="M13.167,11.833A1.333,1.333,0,1,1,11.833,10.5,1.333,1.333,0,0,1,13.167,11.833Z" transform="translate(-2.444 -2.444)"/>
				<path style={style} d="M21.722,19.444,17.278,15,7.5,24.778" transform="translate(-1.222 -4.278)"/>
			</g>
		</svg>
	)
}

export {ImageIcon}