import {useTypedAsync} from "..";
import {searchApi, SearchApiProps, SearchApiResponse} from "./searchApi";
import {SearchResult} from "../../interfaces/SearchResult";
import {getTemplateFromSearchResult} from "../../utilities/data/getTemplateFromSearchResult";
import {QueryResponse} from "../../interfaces";

export type SearchTemplatesQuery = Omit<SearchApiProps, "searchType" | "fields">
export async function searchTemplatesApi(queryParams: SearchTemplatesQuery): Promise<SearchApiResponse<QueryResponse<KaiAlphaTemplate>>> {
	const searchResponse = await searchApi<SearchResult & {"@subtemplates": string[]}>({...queryParams, searchType: "template",
		fields: [
			"_external_data",
			"_s3Metadata",
			"@subtemplates",
			'id',
			'version',
			'state',
			'name',
			'workflow',
			'permissions',
			'_cached_canonical_permissions',
			'@workflows_waiting'
		]});
	const results = searchResponse.results
		.map(getTemplateFromSearchResult)
		.filter(e => e.isRight)
		.map(e => e.value as QueryResponse<KaiAlphaTemplate>);

	return {
		...searchResponse,
		results
	}
}
// gets all templates visible to the user, filtered by the provided query parameters.
const useGetTemplates = () => {
	return useTypedAsync(searchTemplatesApi, false);
}

export {useGetTemplates};