import api from '../../../../../api/api';
import {useAsync} from "../../../../../shared/hooks";
import { CommentPayload } from '../../../components/modals/documentComments/CommentModal.models';

/**
 * Create a comment
 * requires Document ID and payload with comment information
 * Element ID field is optional within the KaiAlphaCommentEntry in the payload
 * comments can either be
 * (1) at the document level (top level only and not specific to elements)
 * (2) at a specific element
 * (3) TODO find out if they apply to variables
 * @returns
 */
const useAddComment = () => {
	const addComment = async (documentId: string, commentPayload: CommentPayload): Promise<any | null> => {
		return api.call('POST', `comment/document/${documentId}`, commentPayload);
	}
	return useAsync<any>(addComment, false);
}

export {useAddComment};