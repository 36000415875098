import {PaneContent} from "./PaneContent";
import {FlexBox} from "../FlexBox";
import {Button} from "../../buttons";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ExpandKeyboardTabIcon} from "./ExpandKeyboardTabIcon";

export interface ExpandablePaneProps {
	orientation: "left" | "right",
	contents: (expanded: boolean) => React.ReactElement | null,
	title?: string,
	titleComponent?: React.ReactElement
}

const ExpandablePane = ({orientation, contents, title, titleComponent}: ExpandablePaneProps) => {
	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
	const [expanded, setExpanded] = useState(isLargeScreen);
	const onToggleExpansion = () => setExpanded(!expanded);
	useEffect(() => {
		setExpanded(isLargeScreen);
	}, [isLargeScreen]);

	return <PaneContent expanded={expanded}>
		<FlexBox direction={expanded ? "row" : "column"} align="center" justify={expanded ? "flex-start" : "center"}>
			{((!expanded && orientation === "left") || (orientation === "right")) &&
				<Button buttonType="chromeless" icon={<ExpandKeyboardTabIcon rotated={((orientation === "right") ? !expanded : expanded)} />} onClick={onToggleExpansion} />
			}
			{ title && expanded && <div style={{flex: 1}}>{title}</div>}
			{ (!title && titleComponent) && expanded && <div style={{flex: 1}}>{titleComponent}</div>}
			{(expanded && orientation === "left") &&
				<Button buttonType="chromeless" icon={<ExpandKeyboardTabIcon rotated={true} />} onClick={onToggleExpansion} />
			}
		</FlexBox>
		<Box overflow="hidden auto" height="calc(100% - 40px)" width="100%">
			{contents(expanded)}
		</Box>
	</PaneContent>
}

export {ExpandablePane}