export function computeExpressionFactory(expression: string): string {
	/*
	 * If the expression is wrapped in Nunjucks expansion operator, remove
	 * that wrapping before evaluating (since we will add it back).
	 */
	if (expression.slice(0, 2) === '{{' && expression.slice(-2) === '}}') {
		expression = expression.slice(2, -2);
	}

	return expression;
}