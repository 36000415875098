import {AutoCompleteConfig, AutoCompleteConfigGenerator} from "../interfaces/AutoCompleteConfig";
import {TypeAsyncReturn, useTypedAsync} from "./useAsync";

async function generateAutoCompleteConfig(configGenerators: AutoCompleteConfigGenerator[]): Promise<AutoCompleteConfig[]> {
	try {
		const configCollections = await Promise.all(configGenerators.map(generator => generator()));
		return configCollections.reduce((config, currentCollection) => config.concat(currentCollection), []);
	} catch {
		return [];
	}
}
export function useAutoCompleteConfigGenerator(): TypeAsyncReturn<AutoCompleteConfigGenerator[], AutoCompleteConfig[]> {
	return useTypedAsync(generateAutoCompleteConfig, false)
}