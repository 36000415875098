import { BaseModal } from "../BaseModal";
import { UserList, useUserList } from "../../../hooks/useUserList";
import { useRunOnce, useRunWhenValueChange } from "../../../hooks";
import { UserSelector } from "./UserSelector";
import { useState } from "react";
import { Button } from "../../buttons";
import { getAclRole } from "../../../interfaces/ACLRoles";
import { aclEditorRoles, AclEditorContent, AclEditorRoleTypes,getAclEditorRoles} from "./models";
import { User, allUsers } from "../../../interfaces/User";

const initialState = {
	owners: [],
	authors: [],
	reviewers: [],
	approvers: [],
	readers: [],
}
export interface AclEditorProps {
	visible: boolean,
	// a list of visible roles
	visibleRoles?: AclEditorRoleTypes[],
	permissions: KaiAlphaTemplatePermissions | KaiAlphaDocumentPermissions | null,
	onSave: (newPermissions: KaiAlphaTemplatePermissions | KaiAlphaDocumentPermissions) => void,
	onCancel: () => void,
	contentState?: string
}

const AclEditor = ({ visible, permissions, onCancel, onSave, visibleRoles,contentState }: AclEditorProps) => {
	const userList = useUserList();
	const [list, setUserList] = useState<UserList>({ users: [], map: {}, child_resources: [] });
	const [userSelectorContent, setUserSelectorContent] = useState<AclEditorContent>(initialState);

	useRunOnce(() => userList.execute(['name', 'display_name']));

	useRunWhenValueChange(() => {
		if (userList.status === "success" && userList.value) {
			const { map, child_resources, users } = userList.value;
			setUserList({
				map,
				child_resources,
				users: [
					...users,
					allUsers,
				]
			});
		}
	}, userList.status);

	const getUserObject = (id: string) => {
		if (id === allUsers.id) {
			return allUsers;
		}
		return list.map[id];
	};

	useRunWhenValueChange(() => {
		if (permissions?.roles !== undefined && permissions?.roles !== null) {
			setUserSelectorContent({
				owners: permissions.owners !== undefined ? permissions?.owners.map((id) => getUserObject(id)) ?? [] : [],
				authors: permissions.roles.authors !== undefined ? permissions?.roles?.authors?.map((id) => getUserObject(id)) ?? [] : [],
				reviewers: permissions.roles.reviewers !== undefined ? permissions?.roles?.reviewers?.map((id) => getUserObject(id)) ?? [] : [],
				approvers: permissions.roles.approvers !== undefined ? permissions?.roles?.approvers?.map((id) => getUserObject(id)) ?? [] : [],
				readers: permissions.roles.readers !== undefined ? permissions?.roles?.readers?.map((id) => getUserObject(id)) ?? [] : [],
			})
		}
	}, [visible, permissions, list])

	const updateRoleArray = (key: string, roleArray: User[]) => setUserSelectorContent(s => ({
		...s,
		[key]: roleArray,
	}));

	const onSavePermissions = () => {
		const newPermissions: KaiAlphaTemplatePermissions = {
			...permissions,
			owners: userSelectorContent.owners.map((user) => user.id),
			roles: {
				authors: userSelectorContent.authors.map((user) => user.id),
				reviewers: userSelectorContent.reviewers.map((user) => user.id),
				approvers: userSelectorContent.approvers.map((user) => user.id),
				readers: userSelectorContent.readers.map((user) => user.id),
			}
		}
		onSave(newPermissions);
	};

	const setValidation = (role) =>{
		if(role === getAclEditorRoles("owners")){
			return {
				condition: userSelectorContent.owners?.length < 1,
				text: "At least one owner is required.",
			}
		}else{
			return undefined;
		}
	}

	const setIsDisabled = (role) => {
		if(role === getAclEditorRoles("reviewers")){
			return userSelectorContent.reviewers?.length <= 1 && (contentState === "Review" || contentState === "Final Review");
		}else{
			return false;
		}
	}

	return (
		<BaseModal
			visible={visible}
			onClose={onCancel}
			loading={userList.isLoading}
			error={{
				condition: userList.status === "error",
				message: "Error loading users. Try refreshing the page!"
			}}
			title="Assign Users"
			content={
				<>{
					aclEditorRoles.map(role => {
						// if visibleRoles was passed in, and doesnt include this role
						if (visibleRoles && !visibleRoles?.includes(role)) {
							return null;
						}

						return <UserSelector
							validation={setValidation(role)}
							isDisabled={setIsDisabled(role)}
							key={role}
							id={role}
							label={getAclRole(role)}
							selected={userSelectorContent[role] ?? []}
							options={list.users ?? []}
							onSelection={updateRoleArray}
						/>
					})
				}</>
			}
			buttonRow={
				<>
					<Button styling="cancel" text="Cancel" onClick={onCancel} />
					<Button buttonType={"default"} text={"Save"} onClick={onSavePermissions} disabled={userSelectorContent.owners?.length < 1} />
				</>
			}
		/>
	);
}

export { AclEditor }