import {useTypedAsync} from "../../../../shared/hooks";
import {
	searchSubmissionsApi,
	SearchSubmissionsApiFunc,
	SubmissionSearchItem
} from "./searchSubmissionsApi";
import {SearchApiResponse, SearchFilter, SearchSort} from "../../../../shared/hooks/api/searchApi";
import {isSubmission, Submission} from "../../models/Submission";
import {mapSearchItemToSubmission} from "../../utilities/mapSearchItemToSubmission";

type GetSubmissionParams = {
	filters: SearchFilter[],
	sort: SearchSort,
	pageSize: number,
	after: string[] | number[]
}
export type GetSubmissionsFunction<T> = (props?: Partial<GetSubmissionParams>) => T;
export type MapSubmissionSearchItemFunc = (searchItem: SubmissionSearchItem) => Submission | undefined;
type UseGetAllSubmissionsDependencies = {
	searchApi?: SearchSubmissionsApiFunc,
	searchItemMapper?: MapSubmissionSearchItemFunc
}
export type UseGetAllSubmissionsProps = {
	dependencies?: UseGetAllSubmissionsDependencies
}

const defaultDependencies: UseGetAllSubmissionsDependencies = {
	searchApi: searchSubmissionsApi,
	searchItemMapper: mapSearchItemToSubmission
}

function useGetAllSubmissions(props?: UseGetAllSubmissionsProps) {
	const searchApi = props?.dependencies?.searchApi ?? defaultDependencies.searchApi!;
	const searchItemMapper = props?.dependencies?.searchItemMapper ?? defaultDependencies.searchItemMapper!;
	const loadSubmissions: GetSubmissionsFunction<Promise<SearchApiResponse<Submission>>> = async (props?: Partial<GetSubmissionParams>) => {
		const response = await searchApi({filters: props?.filters, sort: props?.sort, pageSize: props?.pageSize, after: props?.after});
		return {
			...response,
			results: (response.results ?? []).map(searchItemMapper).filter(isSubmission)
		}
	}

	return useTypedAsync(loadSubmissions, false);
}

export {useGetAllSubmissions}