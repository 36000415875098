import {NunjucksFilter, NunjucksFilterFactoryProps} from "../model";
import {ensureArgs} from "../filterUtilities";
import {isNunjucksDataSource, NunjucksDataSourceType} from "../../models/NunjucksDataSourceType";
import {isNumberOrString} from "../../../guards/isNumberOrString";
import {isString} from "../../../guards/isString";

function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args, 3) || !isNunjucksDataSource(args[0]) || !isNumberOrString(args[1]) || !isNumberOrString(args[2])) {
		return [];
	}

	return absoluteFilter({base: args[0], row: args[1], column: args[2]});
}

type AbsoluteFilterProps = {
	base: NunjucksDataSourceType,
	row: string | number,
	column: string | number
}

function absoluteFilter({base, row, column}: AbsoluteFilterProps): any[] {

	const rowNumber = isString(row) ? Number(row) : row;
	const columnNumber = isString(column) ? Number(column) : column;

	const rowIndex = rowNumber < 0 ? 0 : rowNumber;
	const columnIndex = columnNumber < 0 ? 0 : columnNumber;

	if (base.type === 'columns-rows') {
		/* Eg: Column-rows
			data : {a : {i : 1, ii:2}, b:{i:3, ii:4}}
			*/
		// if rowNumber is zero, we are going to return the column title (row zero is the column headers)
		const columnHeaders = Object.keys(base.data);
		if (rowIndex === 0) {
			/* As data is structured in a different way, used Object.keys to generate header elements */
			// column header index starts from 1 because index 0 is the row label
			// rowNumber = 0 and columnNumber = 0 the return is an empty string as that is an empty cell
			if (columnIndex === 0) {
				return [""];
			}
			const value = columnHeaders[columnIndex - 1];
			return [value];

		} else if (columnNumber === 0) {
			// row index is greater than 0 but because column index is 0 we are looking for the row label
			const columnObject = base.data[columnHeaders[0]];
			const value = Object.keys(columnObject)[rowIndex - 1];
			return [value];

		}

		/* To get the specified column object, first get the specified column object
				eg : {i:3, ii:4}, in that get the value of the that particular row (ie. i or ii);
			*/
		const columnObject = base.data[columnHeaders[columnIndex - 1]];
		const key = Object.keys(columnObject)[rowIndex - 1];
		const value = columnObject[key];
		return [value];

	} else if (base.type === 'columns') {/*
				Eg: data :[{A:1, B:2, C:3}];
			*/
		if (rowIndex === 0) {
			const value = Object.keys(base.data[0])[columnIndex];
			return [value];
		} else {
			const value = base.data[rowIndex - 1][Object.keys(base.data[rowIndex - 1])[columnIndex]];
			return [value];
		}
	} else {
		throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
	}

}

export function absoluteFilterFactory(props?: NunjucksFilterFactoryProps): NunjucksFilter {
	return ({
		name: "absolute",
		help: "Get values cell values from data passed in as JSON",
		example: 'var | absolute("rowNum","colNum")',
		execute
	})
}
