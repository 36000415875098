import { styled } from "@mui/material";
import { Launch } from "@mui/icons-material";
import { Pane } from "../../../shared/components/layout/pane/Pane";
import { FlexBox } from "../../../shared/components";

const DashboardTitle = styled('span')(({theme}) => ({
	color: theme.palette.secondary.contrastText,
	fontSize: "1.2rem",
	fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
	fontWeight: 500,
}));

const DashboardCardHeader = styled(FlexBox)(({theme}) => ({
	padding: "0 10px 20px",
	boxSizing: 'border-box'
}));

const LaunchIcon = styled(Launch)(({theme}) => ({
	color: theme?.palette?.dashboard?.contrastText,
	fontSize: "1.4rem",
}));

const DashboardCardContainer = styled(Pane)({
	padding: "30px 30px",
	flex: '1 1 auto',
	width: "auto",
	margin: "0 15px 15px 15px",
});

export {
	DashboardTitle,
	DashboardCardHeader,
	LaunchIcon,
	DashboardCardContainer
}