import {EditableElementProps, ElementPropertyData} from "../../model";
import {useVariables} from "../../../../hooks/useVariables";
import { HtmlElementValue } from "../../../../../../shared/definitions/elements/html/model";
import {useState} from "react";
import {useDebounce, useRunWhenValueChange} from "../../../../../../shared/hooks";
import {DebounceDelay} from "../../utilities/editSettings";
import { RichTextEditor } from "../../../../../../shared/components/input/editor";
import { TemplateState } from "../../../../hooks";

const EditableView = (props: EditableElementProps) => {
	const value = props.data as HtmlElementValue;
	const [html, setHtml] = useState(value.text);
	const debouncedHtml = useDebounce(html, DebounceDelay);

	useRunWhenValueChange(() => {
		props.onChange({
			...(props as ElementPropertyData),
			data: {...value, text: debouncedHtml}
		})
	}, debouncedHtml);

	const onChange = (event: {target: {value: string}}) => {
		setHtml(event.target.value);
	}

	const variableManager = useVariables();
	const {computedElements} = TemplateState.useContainer();

	const references = computedElements
		.filter((element) => element.type === 'reference')
		.reduce((obj, reference) => {
			if (reference.contents.name) {
				obj[reference.contents.name] = reference;
			}
			return obj;
		}, {});

	return <RichTextEditor
		placeholder="Type here"
		onChange={onChange}
		defaultValue={html}
		variables={{...variableManager.allLocalVariables, ...references}}
	/>
}

export {EditableView}