import {ExternalContent} from "../../../../hooks/api/useGetExternalContent";
import {ContextMenu, ContextMenuItemType} from "../../../../../../shared/components/menu/contextMenu";
import {useState} from "react";
import {useMousePosition} from "../../../../../../shared/hooks/useMousePosition";
import "./styles.scss";
import {KeyboardArrowDownRounded, KeyboardArrowRightRounded} from "@mui/icons-material";

type ExternalDirectoryBreadcrumbsProps = {
	paths: string[],
	selectedFolder: ExternalContent | null,
	onSelectFolderName: (folderName: string) => void
}
const ExternalDirectoryBreadcrumbs = (props: ExternalDirectoryBreadcrumbsProps) => {
	const [showContextMenu, setShowContextMenu] = useState(false);
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const mouseManager = useMousePosition();
	const onCloseContextMenu = () => setShowContextMenu(false);
	const onShowContextMenu = () => {
		setShowContextMenu(true);
		setMousePosition({x: mouseManager.x, y: mouseManager.y});
	}

	const onFolderSelect = (folder: ContextMenuItemType<string>) => {
		props.onSelectFolderName(folder.value);
		setShowContextMenu(false);
	}

	const onPathSelectFactory = (path: string) => () => props.onSelectFolderName(path);
	const formatPathName = (path: string) => path.length < 12 ? path : `${path.substring(0, 8)}...`;
	const folderOptions: ContextMenuItemType<string>[] = props.selectedFolder?.folders?.map(folder => ({label: folder.name, value: folder.name})) ?? [];
	return <div className={"ectd__modal--contentpicker-external--breadcrumbs"}>
		<ul>
			{props.paths.map((path, pathIndex) => <li onClick={onPathSelectFactory(path)} key={`${path}-${pathIndex}`}><span title={"path"}>{formatPathName(path)}</span><KeyboardArrowRightRounded fontSize={"small"} /></li>)}
			{((props.selectedFolder?.folders.length ?? 0) > 0) && <li onClick={onShowContextMenu}>
				<span>Select folder</span>
				<KeyboardArrowDownRounded fontSize={"small"} />
			</li>}
		</ul>
		<ContextMenu menuItems={folderOptions} open={showContextMenu} onClose={onCloseContextMenu} position={mousePosition} onMenuItemSelect={onFolderSelect} />
	</div>
}

export {ExternalDirectoryBreadcrumbs}