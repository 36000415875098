/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/lib/generator_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
// @ts-check-strict

const _testing = undefined;
const path = require('path');
const uuid = require('uuid');

async function jsObject_mapping_function(element_type, element_id, element, generate_options) {
	const retval = [];
	const _random_id = function(prefix = 'tmp') {
		return(`__${prefix}_${uuid.v4().replace(/-/g, '')}`);
	}

	/*
	 * If a suffix is given, add it to all elements
	 */
	const element_id_var = _random_id('element_id');
	if (element_id) {
		retval.push(kaialpha.lib.nunjucks_utils.set_variable_object_generator(element_id_var, element_id));

		if (generate_options.element_id_prefix) {
			retval.push(`{%- set ${element_id_var} = ${generate_options.element_id_prefix(element_id_var)} + "@" + ${element_id_var} -%}`);
		}
	}

	/*
	 * Attributes on each kind of element to perform Nunjucks evaluation
	 */
	const expand_element_attrs = {
		'html': ['text'],
		'section': ['name']
	};

	let accumulator = generate_options.accumulator;
	if (!accumulator) {
		accumulator = '__final_output';
	}

	const _push = function(varName, element) {
		return(`{%- set ${varName} = (${varName}.push(${element}), ${varName}) -%}`);
	}

	const _push_array = function(varName, array, each_element = undefined) {
		const iterator_id = _random_id('iterator');
		const tmp_var = _random_id('tmp_var');

		const retval = []
		retval.push(`{%- set ${tmp_var} = ${array} -%}`);
		retval.push(`{%- for ${iterator_id} in ${tmp_var} -%}`);
		if (each_element) {
			retval.push(...each_element(iterator_id));
		}
		retval.push(`${_push(varName, iterator_id)}`);
		retval.push(`{%- endfor -%}`);

		return(retval);
	}

	const _macro_encode = function(body) {
		const macro_retval = [];

		const function_id = _random_id('func');
		macro_retval.push(`{%- macro ${function_id}(__current) -%}`);
		macro_retval.push(...body);
		macro_retval.push('{%- endmacro -%}');

		return({
			macro: macro_retval,
			name: function_id
		});
	}

	const _push_nunjucks_body = function(into, body, each_element) {
		const { name: function_id, macro: macro_body } = _macro_encode(body);
		const retval = [];
		retval.push(...macro_body);
		retval.push(..._push_array(into, `${function_id}(__current) | json_parse`, each_element));
		return(retval);
	}

	/**
	 * Define an Element as a Nunjucks object, expanding attributes as
	 * needed
	 *
	 * @param {Object} element - Element to process
	 * @param {string} element_type - Type of element
	 * @returns {{macro: string[], new_element_ref: string}} Object containing members to access new element
	 */
	const _define_element_ref = function(element, element_type) {
		const macro = [];

		/*
		 * If there is nothing to expand, only make an instance of the
		 * element
		 */
		const expand_attrs = expand_element_attrs[element_type];

		let should_expand = false;
		if (expand_attrs) {
			for (const attr of expand_attrs) {
				if (attr in element) {
					should_expand = true;
					break;
				}
			}
		}

		if (!should_expand) {
			const element_ref = _random_id(`${element_type}_element`);
			macro.push(kaialpha.lib.nunjucks_utils.set_variable_object_generator(element_ref, element));
			return({
				macro: macro,
				new_element_ref: element_ref
			});
		}

		/*
		 * Store the original attributes from the element as "$orig_<id>"
		 */
		const tmp_element = _random_id('expand_element');
		const tmp_element_overrides = {};
		for (const attr of expand_attrs) {
			tmp_element_overrides[`$orig_${attr}`] = element[attr];
		}

		macro.push(kaialpha.lib.nunjucks_utils.set_variable_object_generator(tmp_element, {
			...element,
			...tmp_element_overrides
		}));

		/*
		 * Replace the attributes with the evaluated versions
		 */
		for (const attr of expand_attrs) {
			const tmp_attr_var = _random_id(`expand_element_attr_${attr}`);
			let tmp_attr_var_with_filters = `${tmp_attr_var} | __ka_map_abbrevations`;
			if (element.merge) {
				const { start, end, tables } = element.merge;
				tmp_attr_var_with_filters = tmp_attr_var_with_filters + ` | __ka_apply_merge_options(start=${start}, end=${end}, tables=${tables})`;
			}
			macro.push(`{%- set ${tmp_attr_var} -%}${element[attr]}{%- endset -%}`);
			macro.push(kaialpha.lib.nunjucks_utils.set_variable_generator(tmp_element, `${tmp_element} | __ka_setattr("${attr}", ${tmp_attr_var_with_filters})`, true));
		}

		return({
			macro: macro,
			new_element_ref: tmp_element
		});
	}

	let already_written_element = false;

	switch (element_type) {
		case 'VALIDATE_BEGIN':
		case 'FILE_BEGIN':
			retval.push(`{%- set ${accumulator} = [] -%}`);

			already_written_element = true;
			break;
		case 'FILE_END':
			retval.push(`{{ ${accumulator} | stringify }}`);

			already_written_element = true;
			break;
		case 'variable':
			already_written_element = true;

			if (expand_element_attrs[element_type]) {
				throw(new Error(`internal error: We do not handle expanding element attributes in the ${element_type} handler`));
			}

			{
				const variable_info = element['$variable_descriptor'];

				if (variable_info === undefined) {
					return([]);
				}

				if (variable_info.type !== 'expression') {
					return([]);
				}

				if (variable_info.options === undefined || variable_info.options.expression === undefined) {
					return([]);
				}

				if (!kaialpha.lib.nunjucks_utils.valid_identifier(element.name)) {
					throw(new kaialpha.UserError(`Error while processing Set Variable statement ${element_id}, invalid Name (${element.name})`));
				}

				/**
				 * set expression variables inline, so they can hold references to js objects,
				 * else this will be treated as a macro and the result will be stringified.
				 */
				retval.push(kaialpha.lib.nunjucks_utils.set_variable_generator(element.name, variable_info.options.expression, true));
			}
			break;
		case 'switch':
			if (expand_element_attrs[element_type]) {
				throw(new Error(`internal error: We do not handle expanding element attributes in the ${element_type} handler`));
			}

			/*
			 * Create an if/then/else structure within the document.
			 *
			 * To make this a bit easier, start with an always-false branch
			 * that way the rest of the structure is regular.
			 */
			retval.push(kaialpha.lib.nunjucks_utils.set_variable_generator(element.name, element.expression));

			retval.push('{%- if false -%}');

			if (element.values instanceof Object) {
				for (const element_value in element.values) {
					const element_value_body = element.values[element_value];

					const template_info = kaialpha.lib.nunjucks_utils.compare_expressions_generator({
						variable: element.name,
						rhs: element_value,
						bare_if: true,
						if_statement: 'elif',
						rhs_quote_default: true
					});

					retval.push(template_info.rhs_template);

					const case_accumulator = _random_id('__switch_case_output');
					const nunjucks_body = await generateNunjucksValueFromBody(element_value_body.body, {
						...generate_options,
						accumulator: case_accumulator
					});

					retval.push(..._push_nunjucks_body(accumulator, nunjucks_body));
				}
			}

			if (element.default && element.default.body) {
				retval.push('{%- else -%}');
				const case_accumulator = _random_id('__switch_else_output');
				const nunjucks_body = await generateNunjucksValueFromBody(element.default.body, {
					...generate_options,
					accumulator: case_accumulator
				});

				retval.push(..._push_nunjucks_body(accumulator, nunjucks_body));
			}

			retval.push('{%- endif -%}');

			already_written_element = true;
			break;
		case 'loop':
			if (expand_element_attrs[element_type]) {
				throw(new Error(`internal error: We do not handle expanding element attributes in the ${element_type} handler`));
			}

			{
				if (!kaialpha.lib.nunjucks_utils.valid_identifier(element.name)) {
					throw(new kaialpha.UserError(`Error while processing Loop statement ${element_id}, invalid Name (${element.name})`));
				}

				if (element.expression === undefined || element.expression === '') {
					element.expression = [];
				}

				const tmp_accumulator = _random_id('__loop_output');
				const current_element_index = _random_id('rewrite_loop_element_index');

				retval.push(`{%- set ${current_element_index} = -1 -%}`);
				retval.push(`{%- for ${element.name.toLowerCase()} in ${element.expression} -%}`)
				retval.push(`{%- set ${current_element_index} = ${current_element_index} + 1 -%}`);
				retval.push(`{%- set __current = __current | __ka_setattr("${element.name.toLowerCase()}", ${element.name}) -%}`); /* XXX:TODO: This can probably be removed once variable lookups work */
				if (element.body) {
					const nunjucks_body = await generateNunjucksValueFromBody(element.body, {
						...generate_options,
						accumulator: tmp_accumulator,
						element_id_prefix: function(element_id_var) {
							return(`"loop-" + (${element_id_var} | __ka_incr_input())`)
						}
					});

					retval.push(..._push_nunjucks_body(accumulator, nunjucks_body));
				}

				if (element.else && element.else.body) {
					retval.push('{%- else -%}');
					const nunjucks_body = await generateNunjucksValueFromBody(element.else.body, {
						...generate_options,
						accumulator: tmp_accumulator
					});

					retval.push(..._push_nunjucks_body(accumulator, nunjucks_body));
				}

				retval.push('{%- endfor -%}');
			}

			already_written_element = true;
			break;
		case 'template':
		case 'section':
			if (!element.body) {
				if (element_type === 'template') {
					if (element.id || element.expression) {
						throw(new Error('we currently do not support fetching subtemplates'));
					}
				}

				break;
			}

			{
				const part_retval = [];

				const tmp_accumulator = _random_id(`${element_type}_output`);
				const nunjucks_body = await generateNunjucksValueFromBody(element.body, {
					...generate_options,
					accumulator: tmp_accumulator
				});

				/*
				 * Expand the element if needed
				 */
				const { macro: expanded_element_macro_body, new_element_ref: tmp_element } = _define_element_ref(element, element_type);
				part_retval.push(...expanded_element_macro_body);

				const { name: function_id, macro: macro_body } = _macro_encode(nunjucks_body);
				part_retval.push(...macro_body);

				let variable_namespace = '__current';
				if (element_type === 'template') {
					const subtemplate_name_var = _random_id('subtemplate_name');
					part_retval.push(`{%- set ${subtemplate_name_var} = ${JSON.stringify(JSON.stringify(element.name.toLowerCase()))} | json_parse -%}`);
					variable_namespace += `[${subtemplate_name_var}]`;
				}

				part_retval.push(kaialpha.lib.nunjucks_utils.set_variable_generator(tmp_element, `${tmp_element} | __ka_setattr("body", ${function_id}(${variable_namespace}) | json_parse)`, true));

				const tmp_element_wrapped = _random_id(`${element_type}_element_wrapper`);
				part_retval.push(kaialpha.lib.nunjucks_utils.set_variable_generator(tmp_element_wrapped, '"{}" | json_parse', true));
				part_retval.push(kaialpha.lib.nunjucks_utils.set_variable_generator(tmp_element_wrapped, `${tmp_element_wrapped} | __ka_setattr(${element_id_var}, ${tmp_element})`, true));

				part_retval.push(_push(accumulator, tmp_element_wrapped));

				retval.push(...part_retval);
			}

			already_written_element = true;
			break;
		default:
			/* Nothing to do for most elements */
			break;
	}

	if (element_id) {
		if (!already_written_element) {
			const { macro: macro_body, new_element_ref: tmp_element } = _define_element_ref(element, element_type);
			retval.push(...macro_body);

			const tmp_element_wrapped = _random_id(`${element_type}_element_wrapper`);
			retval.push(kaialpha.lib.nunjucks_utils.set_variable_generator(tmp_element_wrapped, '"{}" | json_parse', true));
			retval.push(kaialpha.lib.nunjucks_utils.set_variable_generator(tmp_element_wrapped, `${tmp_element_wrapped} | __ka_setattr(${element_id_var}, ${tmp_element})`, true));
			retval.push(_push(accumulator, tmp_element_wrapped));
		}
	}

	return(retval);
}

/*
 * XXX:TODO
 *
 * "mapping_callback" and "toAddPath" should be options, not positional parameters
 * This was a poor design, but fixing it will require work
 *
 * XXX:TODO
 */
async function generateNunjucksValueFromBody(body, options, mapping_callback, toAddPath = true) {
	if (options === undefined) {
		options = {}
	}

	options = {
		validate_elements: true,
		mapping_function: mapping_callback,
		element_callback: undefined,
		element_blacklist: [],
		generator_cache_function: async function(ignored_key, lambda) {
			/*
			 * The default caching function doesn't cache
			 */
			return(await lambda());
		},
		get_user_document: async function(document_id, document_version) {
			const key = ['document', this.user_id, document_id, document_version];
			return(await this.generator_cache_function(key, async () => {
				return(await kaialpha.lib.document.get_user_document(this.user_id, document_id, document_version));
			}));
		},
		get_user_template: async function(template_id, template_version) {
			const key = ['template', this.user_id, template_id, template_version];
			return(await this.generator_cache_function(key, async () => {
				return(await kaialpha.lib.template.get_user_template(this.user_id, template_id, template_version));
			}));
		},
		get_user_templates: async function(filter) {
			return(kaialpha.lib.template.get_user_templates(this.user_id, filter));
		},
		process_document_variables: async function(document_id) {
			return(kaialpha.lib.document_utils.process_document_variables(this.user_id, {
				document_id: document_id,
				version_id: 'HEAD'
			}));
		},
		get_user_list_entries: async function(list_type, list_id, list_version) {
			const key = ['list', this.user_id, list_type, list_id, list_version];
			return(await this.generator_cache_function(key, async () => {
				return(await kaialpha.lib.list_utils.get_user_list_entries(this.user_id, list_type, list_id, list_version));
			}));
		},
		element_error_callback: function(error_message) {
			return([error_message]);
		},
		recurse_into_templates: true,
		include_document_begin_end: true,
		templatedir: kaialpha.workdir + '/njk_templates',
		...options
	};

	const output = [];

	/*
	 * Add header from the BEGIN synthetic element
	 */
	if (options.include_document_begin_end) {
		/*
		 * Create the document header
		 */
		if (toAddPath) {
			if (kaialpha.server_options) {
				const kalib_path = path.join(kaialpha.server_options.root_directory, '/resources/nunjucks/lib/kalib.njk');
				options.kalib_path = kalib_path;
			}
		}

		output.push(...(await options.mapping_function('DOCUMENT_BEGIN', null, {}, options)));
	}

	output.push(...(await options.mapping_function('FILE_BEGIN', null, {}, options)));

	let validate_header, validate_footer;
	if (options.validate_elements === true) {
		validate_header = await options.mapping_function('VALIDATE_BEGIN', null, {}, options);
		validate_footer = await options.mapping_function('VALIDATE_END', null, {}, options);
	}

	// Ensure that references are set first by moving them to the front of the array
	body.sort((x, y) => {
		return x[Object.keys(x)[0]].type === 'reference' ? -1 : y[Object.keys(y)[0]].type === 'reference' ? 1 : 0;
	});

	let previous_element_type = undefined;
	for (const element of body) {
		const element_id = Object.keys(element)[0];
		const element_body = element[element_id];
		const element_type = element_body.type;
		/*
		 * loop through each object in body and generate nunjucks output based on type
		 */
		const element_options = {
			...options,
			include_document_begin_end: false
		};

		/*
		 * If this element is blacklisted, skip it
		 */
		if (options.element_blacklist !== undefined) {
			if (options.element_blacklist.includes(element_id)) {
				continue;
			}
		}

		if (options.element_callback) {
			options.element_callback(element_type, element_id, element_body);
		}

		if (previous_element_type === 'title') {
			element_options.last_element_is_title = true;
		}

		let output_elements = [];
		try {
			output_elements = await options.mapping_function(element_type, element_id, element_body, element_options);
		} catch (map_function_error) {
			output_elements = [];
			output_elements.push(...options.element_error_callback(`Failed to map/render element ${element_type}/${element_id} (full element: ${JSON.stringify(element_body)}: ${map_function_error}`));
		}

		if (options.validate_elements === true) {
			const output_elements_str = [...validate_header, ...output_elements, ...validate_footer].join('\n');
			if (!kaialpha.lib.nunjucks_utils.validateString(output_elements_str)) {
				output_elements = options.element_error_callback(`Failed to render element ${element_type}/${element_id}`);
			}
		}

		output.push(...output_elements);
		previous_element_type = element_type;
	}

	/*
	 * Add footer from the END synthetic element
	 */
	output.push(...(await options.mapping_function('FILE_END', null, {}, options)));
	if (options.include_document_begin_end) {
		output.push(...(await options.mapping_function('DOCUMENT_END', null, {}, options)));
	}

	return(output);
}

if (_testing) {
	_testing.jsObject_mapping_function = async function() {
		const variables = {
			knownvar: 'KnownVarValue',
			switchvar1: '1',
			subtempl1: {
				knownvar: 'SubTempl1KnownVarValue',
				othervar1: 'Only in SubTempl1',
				subsubtempl1: {
					knownvar: 'SubSubTempl1KnownVarValue',
					othervar2: 'Only in SubSubTempl1'
				}
			},
			subtempl2: {
				knownvar: 'SubTempl2KnownVarValue',
				othervar3: 'Only in SubTempl2'
			}
		};
		variables.global = variables;
		variables.parent = variables;
		variables.__current = variables;
		variables.subtempl1.global = variables;
		variables.subtempl1.parent = variables;
		variables.subtempl1.__current = variables.subtempl1;
		variables.subtempl2.global = variables;
		variables.subtempl2.parent = variables;
		variables.subtempl2.__current = variables.subtempl2;
		variables.subtempl1.subsubtempl1.global = variables;
		variables.subtempl1.subsubtempl1.parent = variables.subtempl1;
		variables.subtempl1.subsubtempl1.__current = variables.subtempl1.subsubtempl1;

		const assert_body_no_dupe_ids = function(body, body_template) {
			const flat_body = kaialpha.lib.document_utils.get_body_sortly_items(body);

			const seen_element_ids = {};
			for (const element of flat_body) {
				if (seen_element_ids[element.id]) {
					throw(new Error(`Duplicate element IDs detected in output at element ID ${element.id}: ${JSON.stringify(body, undefined, 4)} generated from ${body_template}`));
				}
				seen_element_ids[element.id] = true;
			}

			return(true);
		};

		const checks = [
			{
				in: []
			},
			{
				in: [
					{'element1': {
						type: 'title',
						title: 'Basic title'
					}}
				]
			},
			{
				in: [
					{'element1': {
						type: 'title',
						title: 'Basic title'
					}},
					{'section1': {
						type: 'section'
					}},
					{'html1': {
						type: 'html',
						text: 'test',
						'$orig_text': 'test'
					}}
				]
			},
			{
				in: [
					{'element1': {
						type: 'title',
						title: 'Basic title'
					}},
					{'section1': {
						type: 'section',
						name: 'Section 1',
						'$orig_name': 'Section 1',
						body: [
							{'html1.0': {
								type: 'html',
								text: 'test',
								'$orig_text': 'test'
							}},
							{'section1.0': {
								name: 'Section 1.0',
								'$orig_name': 'Section 1.0',
								type: 'section',
								body: [
									{'title1': {
										type: 'title',
										title: 'Section 1.0 title'
									}},
									{'comment1': {
										type: 'comment',
										comment: 'text'
									}}
								]
							}}
						]
					}}
				]
			},
			{
				in: [
					{'html1': {
						type: 'html',
						text: 'UnknownVar = {{UnknownVar}}',
					}},
					{'html2': {
						type: 'html',
						text: 'KnownVar = {{KnownVar}}',
					}}
				],
				out: [
					{'html1': {
						type: 'html',
						text: 'UnknownVar = ',
						'$orig_text': 'UnknownVar = {{UnknownVar}}'
					}},
					{'html2': {
						type: 'html',
						text: 'KnownVar = KnownVarValue',
						'$orig_text': 'KnownVar = {{KnownVar}}'
					}}
				]
			},
			{
				in: [
					{'switch1': {
						type: 'switch',
						name: 'CheckVar',
						expression: 'SwitchVar0',
						values: {
							'1': {
								body: [
									{'html1': {
										type: 'html',
										text: 'SwitchVar is 1 see: {{CheckVar}}'
									}}
								]
							}
						},
						default: {
							body: [
								{'html2': {
									type: 'html',
									text: 'SwitchVar is not 1, it is {{CheckVar}}'
								}}
							]
						}
					}},
					{'html3': {
						type: 'html',
						text: 'Done with switch {{CheckVar}}/{{SwitchVar0}}'
					}}
				],
				out: [
					{'html2': {
						type: 'html',
						text: 'SwitchVar is not 1, it is ',
						'$orig_text': 'SwitchVar is not 1, it is {{CheckVar}}'
					}},
					{'html3': {
						type: 'html',
						text: 'Done with switch /',
						'$orig_text': 'Done with switch {{CheckVar}}/{{SwitchVar0}}'
					}}
				]
			},
			{
				in: [
					{'switch1': {
						type: 'switch',
						name: 'CheckVar',
						expression: 'SwitchVar1',
						values: {
							'1': {
								body: [
									{'html1': {
										type: 'html',
										text: 'SwitchVar is 1 see: {{CheckVar}}'
									}}
								]
							}
						},
						default: {
							body: [
								{'html2': {
									type: 'html',
									text: 'SwitchVar is not 1, it is {{CheckVar}}'
								}}
							]
						}
					}},
					{'html3': {
						type: 'html',
						text: 'Done with switch {{CheckVar}}/{{SwitchVar1}}'
					}}
				],
				out: [
					{'html1': {
						type: 'html',
						text: 'SwitchVar is 1 see: 1',
						'$orig_text': 'SwitchVar is 1 see: {{CheckVar}}'
					}},
					{'html3': {
						type: 'html',
						text: 'Done with switch 1/1',
						'$orig_text': 'Done with switch {{CheckVar}}/{{SwitchVar1}}'
					}}
				]
			},
			{
				in: [
					{'var1': {
						type: 'variable',
						name: 'CheckVar',
						'$variable_descriptor': {
							type: 'expression',
							options: {
								expression: 'SwitchVar1',
							}
						}
					}},
					{'html3': {
						type: 'html',
						text: 'Done with VarExpr {{CheckVar}}/{{SwitchVar1}}'
					}}
				],
				out: [
					{'html3': {
						type: 'html',
						text: 'Done with VarExpr 1/1',
						'$orig_text': 'Done with VarExpr {{CheckVar}}/{{SwitchVar1}}'
					}}
				]
			},
			{
				in: [
					{'loop1': {
						type: 'loop',
						name: 'iteration',
						expression: '[1,2,3]',
						body: [
							{'html1.0': {
								type: 'html',
								text: 'Loop through {{iteration}} (part 1)'
							}},
							{'html1.1': {
								type: 'html',
								text: 'Loop through {{iteration}} (part 2)'
							}}
						]
					}},
					{'html2': {
						type: 'html',
						text: 'KnownVar = {{KnownVar}}, Iteration = {{iteration}}'
					}}
				],
				check: function(_ignored_in, out) {
					/*
					 * Verify that there are the right number of elements
					 */
					const flat_body = kaialpha.lib.document_utils.get_body_sortly_items(out);
					if (flat_body.length !== 7) {
						return([`Expected 7 elements but got ${flat_body.length}`]);
					}

					return([]);
				}
			},
			{
				in: [
					{'html1': {
						type: 'html',
						text: 'This is in the top-level: {{KnownVar}}'
					}},
					{'template1': {
						type: 'template',
						name: 'subtempl1',
						id: 'ignored',
						version: 'ignored',
						body: [
							{'st1_html1': {
								type: 'html',
								text: 'This is in SubTempl1: {{KnownVar}}/{{OtherVar1}}'
							}},
							{'subtemplate1': {
								type: 'template',
								name: 'subsubtempl1',
								id: 'ignored',
								version: 'ignored',
								body: [
									{'stst1_html1': {
										type: 'html',
										text: 'This is in SubSubTempl1: {{KnownVar}}/{{OtherVar2}}'
									}},
									{'stst1_html2': {
										type: 'html',
										text: 'This is in Empty: {{OtherVar1}}'
									}},
								]
							}}
						]
					}},
					{'template2': {
						type: 'template',
						name: 'subtempl2',
						id: 'ignored',
						version: 'ignored',
						body: [
							{'st2_html1': {
								type: 'html',
								text: 'This is in SubTempl2: {{KnownVar}}/{{OtherVar3}}'
							}},
							{'st2_html2': {
								type: 'html',
								text: 'This is in Empty: {{OtherVar1}}{{OtherVar2}}'
							}},
						]
					}}
				],
				out: [
					{'html1': {
						type: "html",
						text: "This is in the top-level: KnownVarValue",
						"$orig_text": "This is in the top-level: {{KnownVar}}"
					}},
					{'template1': {
						type: "template",
						name: "subtempl1",
						id: "ignored",
						version: "ignored",
						body: [
							{'st1_html1': {
								type: "html",
								text: "This is in SubTempl1: SubTempl1KnownVarValue/Only in SubTempl1",
								"$orig_text": "This is in SubTempl1: {{KnownVar}}/{{OtherVar1}}"
							}},
							{'subtemplate1': {
								type: "template",
								name: "subsubtempl1",
								id: "ignored",
								version: "ignored",
								body: [
									{'stst1_html1': {
										type: "html",
										text: "This is in SubSubTempl1: SubSubTempl1KnownVarValue/Only in SubSubTempl1",
										"$orig_text": "This is in SubSubTempl1: {{KnownVar}}/{{OtherVar2}}"
									}},
									{'stst1_html2': {
										type: "html",
										text: "This is in Empty: ",
										"$orig_text": "This is in Empty: {{OtherVar1}}"
									}}
								]
							}}
						]
					}},
					{'template2': {
						type: "template",
						name: "subtempl2",
						id: "ignored",
						version: "ignored",
						body: [
							{'st2_html1': {
								type: "html",
								text: "This is in SubTempl2: SubTempl2KnownVarValue/Only in SubTempl2",
								"$orig_text": "This is in SubTempl2: {{KnownVar}}/{{OtherVar3}}"
							}},
							{'st2_html2': {
								type: "html",
								text: "This is in Empty: ",
								"$orig_text": "This is in Empty: {{OtherVar1}}{{OtherVar2}}"
							}}
						]
					}}
				]
			},
			{
				in: [
					{'loop1': {
						type: 'loop',
						name: 'loop1',
						expression: '[1,2,3]',
						body: [
							{'template1': {
								type: 'template',
								name: 'subtempl1',
								id: 'ignored',
								version: 'ignored',
								body: [
									{'st1_html1': {
										type: 'html',
										text: 'This is in SubTempl1: {{KnownVar}}/{{OtherVar1}}/{{loop1}}'
									}},
								]
							}}
						]
					}},
					{'loop2': {
						type: 'loop',
						name: 'loop2',
						expression: '[1,2,3]',
						body: [
							{'template2': {
								type: 'template',
								name: 'subtempl1',
								id: 'ignored',
								version: 'ignored',
								body: [
									{'st2_html1': {
										type: 'html',
										text: 'This is in SubTempl1: {{KnownVar}}/{{OtherVar1}}/{{loop2}}'
									}},
								]
							}}
						]
					}}
				],
				check: function(_ignored_in, _ignored_out) {
					/* We just want to run basic sanity checks */
					return([]);
				}
			},
			{
				in: [
					{'outerloop': {
						type: 'loop',
						name: 'OuterLoop',
						expression: '[1,2,3]',
						body: [
							{'innerloop': {
								type: 'loop',
								name: 'InnerLoop',
								expression: '[7,8,9]',
								body: [
									{'repeatedHtml': {
										type: 'html',
										text: 'Loop {{OuterLoop}}x{{InnerLoop}}'
									}}
								]
							}}
						]
					}}
				],
				check: function(_ignored_in, out) {
					/*
					 * Verify that there are the right number of elements
					 */
					const flat_body = kaialpha.lib.document_utils.get_body_sortly_items(out);
					if (flat_body.length !== 9) {
						return([`Expected 9 elements, but got ${flat_body.length}`]);
					}

					const check_element_wrapper = out[0];
					const check_element_id = Object.keys(check_element_wrapper)[0];
					const check_element = check_element_wrapper[check_element_id];
					if (check_element.text !== 'Loop 1x7') {
						return([`Expected text for the first element to be "Loop 1x7", but got: ${check_element.text}`]);
					}

					return([]);
				}
			},
			{
				in: [
					{'section1': {
						type: 'section',
						name: 'Section {{KnownVar}}',
						body: [
							{'html1': {
								type: 'html',
								text: 'UnknownVar = {{UnknownVar}}'
							}}
						]
					}},
					{'html2': {
						type: 'html',
						text: 'KnownVar = {{KnownVar}}',
					}}
				],
				out: [
					{'section1': {
						type: 'section',
						name: 'Section KnownVarValue',
						'$orig_name': 'Section {{KnownVar}}',
						body: [
							{'html1': {
								type: 'html',
								text: 'UnknownVar = ',
								'$orig_text': 'UnknownVar = {{UnknownVar}}'
							}}
						]
					}},
					{'html2': {
						type: 'html',
						text: 'KnownVar = KnownVarValue',
						'$orig_text': 'KnownVar = {{KnownVar}}'
					}}
				]
			}
		];

		for (const check of checks) {
			const body_in = check.in;
			const body_in_duplicate = kaialpha.lib.object_utils.copy_object(body_in);
			let body_out_check = check.out;
			let body_out_check_function;
			if (!body_out_check) {
				if (check.check) {
					body_out_check_function = check.check;
				} else {
					/*
					 * Ensure we get exactly the same input as output if no output was specified
					 */
					body_out_check = kaialpha.lib.object_utils.copy_object(check.in);
				}
			}

			const body_out_template = (await generateNunjucksValueFromBody(body_in, {
				validate_elements: false,
				mapping_function: jsObject_mapping_function
			})).join('\n');

			let body_out;
			try {
				const body_out_str = kaialpha.lib.nunjucks_utils.renderString(body_out_template, variables);
				body_out = JSON.parse(body_out_str);
			} catch (render_error) {
				throw(new Error(`Failed while rendering template ${body_out_template}: ${render_error}`));
			}

			/*
			 * Run basic sanity checks on the output
			 */
			assert_body_no_dupe_ids(body_out, body_out_template);

			/*
			 * Ensure that the output is what is expected
			 */
			if (body_out_check) {
				kaialpha.lib.testing_utils.assert.object_equals(body_out_check, body_out, `body_out_check != body_out :: template = ${body_out_template}`);
			} else {
				const check_function_results = body_out_check_function(body_in, body_out);
				if (check_function_results.length !== 0) {
					throw(new Error(`Failed to check ${JSON.stringify(body_in, undefined, 4)} which produced ${JSON.stringify(body_out, undefined, 4)} via ${body_out_template} error: ${check_function_results.join("\n")}`));
				}
			}

			/* Ensure that nothing mutated the input */
			kaialpha.lib.testing_utils.assert.object_equals(body_in, body_in_duplicate, 'body_in modified !');
		}

		return(true);
	};
}

const _to_export_auto = {
	jsObject_mapping_function,
	generateNunjucksValueFromBody,
	_testing
}
export default _to_export_auto;
