import api from '../../../../api/api';
import {useAsync} from "../../../../shared/hooks";
export interface GetTemplateExportResponse {
    link: string
}

// Get Template JSON for Export Render
function useGetExport() {
	// get the version template JSON
	const getExport = async (id: string, version: string):
    Promise<GetTemplateExportResponse> => {
		return api.call("GET", `template/${id}/versions/${version}/render/json`)
	}
	return useAsync<GetTemplateExportResponse>(getExport, false);
}

export {useGetExport};