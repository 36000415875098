import { DropDownItem } from "../../../components/input/dropdown";

const formatOptions: DropDownItem<string>[] = [{
	title: "{{type}} {{value}}",
	value: "{{type}} {{value}}"
}, {
	title: "{{link}}",
	value: "{{link}}"
}, {
	title: "{{value}}",
	value: "{{value}}"
}]

export {formatOptions};