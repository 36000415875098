import {Action} from "../../../../../shared/interfaces/ActionRun";
import {useDownloadZipApi} from "../../api/useDownloadZipApi";
import {SubmissionState} from "../../useSubmissionState";
import {useRunWhenValueChange, useTypedAsync} from "../../../../../shared/hooks";
import {useStatusBar} from "../../../../../shared/components";
import {downloadFile} from "./downloadZip";


function useDownloadSubmissionAction(): Action {
	const downloadZipApi = useDownloadZipApi();
	const downloadFileAsync = useTypedAsync(downloadFile, false);
	const {submission} = SubmissionState.useContainer();
	const statusBar = useStatusBar();
	useRunWhenValueChange(() => {
		if (downloadZipApi.value) {
			downloadFileAsync.execute({path: downloadZipApi.value.link, fileName: `${submission.name.replace(/\W/gmi, "_")}`});
		}

	}, downloadZipApi.value);

	useRunWhenValueChange(() => {
		if (downloadFileAsync.value) {
			if (downloadFileAsync.value.success) {
				statusBar.sendSuccessNotification({message: "Submission downloaded"});
			} else {
				statusBar.sendErrorNotification({message: "Submission downloaded", detail: downloadFileAsync.value.error});
			}
		}
	}, downloadFileAsync.value)
	const run = () => downloadZipApi.execute({id: submission.id, version: submission.version});

	return {
		run,
		isRunning: downloadZipApi.isLoading || downloadFileAsync.isLoading
	}
}

export {useDownloadSubmissionAction}