import {Column, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {SubmissionNode} from "../../../../models";
import {CompoundLabel} from "./styles";
import {BaseTableCell} from "../../../../../../shared/components/layout/table/styles";

const CompletionStatusCell = (props: ColumnViewProps) => {
	const item = props.item as SubmissionNode;

	return (<BaseTableCell>
		<CompoundLabel>{item[props.column.field]}</CompoundLabel>
	</BaseTableCell>)
}

const CompletionStatus: Column = ({
	field: 'completionStatus',
	title: "Completion Status",
	itemAlign: "left",
	width: "25%",
	headerAlign: "left",
	searchable: false,
	sortable: false,
	views: {
		cell: CompletionStatusCell
	}
})

export {CompletionStatus};