import {TabbedPane} from "../../../../../../shared/components/layout/pane/TabbedPane";
import {TabDefinition} from "../../../../../../shared/components/layout/tabs/tabDefinition";
import {PropertiesTab} from "../../tabs/PropertiesTab";

const tabs: TabDefinition[] = [PropertiesTab];

const LeftPane = () => {
	return <TabbedPane orientation={"left"} tabs={tabs} />
}

export {LeftPane}