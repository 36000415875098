import api from "../../../../api/api";
import {useTypedAsync} from "../../../../shared/hooks";
import {SaveSubmissionPayload} from "./createSubmissionPayload";
import {EntityRecord} from "../../../../shared/interfaces/Entity";

type LegacySaveResponse = {contentId: string, versionId: string, lastupdated?: number}
type SaveResponse = Required<EntityRecord> & {lastupdated?: number}

export function isLegacySaveResponse(obj: LegacySaveResponse | SaveResponse): obj is LegacySaveResponse {
	return (obj as LegacySaveResponse).contentId !== undefined && (obj as LegacySaveResponse).versionId !== undefined;
}

export function isEctdSaveResponse(obj: LegacySaveResponse | SaveResponse): obj is SaveResponse {
	return (obj as SaveResponse).id !== undefined && (obj as SaveResponse).version !== undefined;
}

function saveSubmissionApi(submission: SaveSubmissionPayload): Promise<LegacySaveResponse | SaveResponse> {
	if (submission.id === undefined || submission.version === undefined) {
		throw new Error("id and version are required");
	}
	return api.call("PUT", `ectd/submission/${submission.id}/${submission.version}`, submission);
}
function useSaveSubmission() {
	return useTypedAsync(saveSubmissionApi, false);
}

export {useSaveSubmission};