import { DocumentState } from "../../useDocumentState";

function useSetBufferFromTempBuffer() {
	const state = DocumentState.useContainer();
	// remove values that are not present on the buffer model.
	return (document?: KaiAlphaDocument) => {
		const buffer = {...(document ?? state.tempBuffer!)};
		if (buffer.workflow) {
			delete buffer.workflow
		}

		if (buffer.previous_version) {
			delete buffer.previous_version
		}

		state.setBuffer(buffer);
	};

}

export {useSetBufferFromTempBuffer};