import api from '../../../../../api/api';
import {useAsync} from "../../../../../shared/hooks";

/**
 * get all comments based on a templateId
 * the return value is of KaiAlphaTemplateComments type
 * @returns
 */
const useGetAllComments = () => {
	const getAllComments = async (templateId: string, versionId?: string): Promise<KaiAlphaCommentsTemplate  | null> => {
		const path = versionId === undefined ? `comment/template/${templateId}` : `comment/template/${templateId}/version/${versionId}`
		return api.call('GET', path);
	}
	return useAsync<KaiAlphaCommentsTemplate>(getAllComments, false);
}

export {useGetAllComments};