import { useState } from "react";
import { ExpressionBuilder } from "../../../../../../shared/components/input/expressionBuilder";
import { AutocompleteConfig } from "../../../../../../shared/components/input/expressionBuilder/models";
import { DialogProps } from "../../../../models/elements";
import { useVariables } from "../../../../hooks/useVariables";
import { TemplateList } from "../../../modals";
import { computeElements } from "../../../../utilities/elementsFactory";
import { ElementCollectionPreview } from "./ElementCollectionPreview";
import { TemplateElementValue } from "../../../../../../shared/definitions/elements/template/model";
import { BaseModal } from "../../../../../../shared/components/modals/BaseModal";
import { Button, FlexBox } from "../../../../../../shared/components";
import { TemplateInputSwitch } from "./TemplateInputSwitch";
import { Box } from "@mui/material";
import { EmptyGroup } from "../../../../../../shared/components/layout/groups/EmptyGroup";
import { nunjucksConstants } from "../../../../../../shared/nunjucks/models/NunjucksConstants";
import { parseTemplateElements } from "../../../../../../shared/utilities/parseTemplateElements";

interface DialogViewState {
	showExpressionFilter: boolean,
	selectedTemplate: TemplateElementValue | null,
}

//const formatTemplateName = (name: string) => name?.replace(/\W+/gm, "_") ?? "";

const PropertyEditor = (props: DialogProps) => {
	const value = props.value.data as TemplateElementValue;
	const [state, setState] = useState<DialogViewState>({
		showExpressionFilter: false,
		selectedTemplate: Object.keys(value ?? {}).length > 0 ? value : null,
	});

	const variableUtils = useVariables();
	const autoCompleteConfiguration: AutocompleteConfig[] = [
		{
			trigger: '|',
			options: nunjucksConstants.filters
		},
		{
			trigger: ' ',
			options: nunjucksConstants.operators,
			includePredecessors: props.variables.map((variable) => {
				return `{{${variable.contents.name}}}`
			}),
			excludePredecessors: [...nunjucksConstants.operators || [], undefined]
		},
		{
			trigger: ' ',
			options: ['='],
			excludePredecessors: ['=', undefined]
		},
		{
			trigger: nunjucksConstants.any,
			options: variableUtils.namespacedVariableNames()
		}
	]

	const onClose = () => {
		if (props.onClose) {
			props.onClose();
		}
	}

	const onApply = () => {
		const { id, name, expression, type, version } = state.selectedTemplate!;
		let newValue: TemplateElementValue;
		if (state.showExpressionFilter) {
			newValue = { expression, name, type, id: undefined, version: undefined };
		} else {
			newValue = { id, name, type, expression: undefined, version, state: state.selectedTemplate?.state };
		}
		if (props.onApply) {
			props.onApply({ ...props.value, data: { ...value, ...newValue } });
		}
	}

	const onToggleExpressionFilter = () => setState(s => ({ ...s, showExpressionFilter: !s.showExpressionFilter }));

	const onTemplateSelect = (template: KaiAlphaTemplate) => {
		const { id, version, name, state } = template;
		setState(s => ({
			...s,
			selectedTemplate: {
				id,
				version,
				state,
				type: s.selectedTemplate?.type ?? "template",
				name: name ?? ""
			}
		}));
	}

	const onExpressionChange = (value: string) => setState(s => (
		{
			...s,
			selectedTemplate: {
				...s.selectedTemplate,
				name: s.selectedTemplate?.name ?? "Subtemplate",
				type: s.selectedTemplate?.type ?? "template",
				expression: value
			}
		})
	);

	const onRenderTemplatePreview = (template: KaiAlphaTemplate | null) => {
		if (template === null) {
			return <EmptyGroup title={"Preview unavailable"} />;
		}

		const elements = parseTemplateElements(template);
		const computedElements = computeElements(elements);
		return <ElementCollectionPreview elements={computedElements} />
	}

	return <BaseModal
		title="Add Element: Template"
		visible
		wide
		onClose={onClose}
		content={
			<FlexBox direction="column">
				<TemplateInputSwitch checked={state.showExpressionFilter} onChange={onToggleExpressionFilter} />
				{state.showExpressionFilter && <Box minWidth="100%">
					<ExpressionBuilder
						className='editor-item-autocomplete-input'
						value={state.selectedTemplate?.expression}
						autoCompleteConfig={autoCompleteConfiguration}
						onChange={(changeEvent) => onExpressionChange(changeEvent.target.value)}
						onBlur={onExpressionChange}
					/>
				</Box>}
				{!state.showExpressionFilter &&
					<TemplateList
						onTemplateSelect={onTemplateSelect}
						onRenderPreview={onRenderTemplatePreview}
						selectedTemplate={state.selectedTemplate}
					/>}

			</FlexBox>
		}
		buttonRow={<>
			<Button styling="cancel" text="Cancel" onClick={onClose} />
			<Button buttonType={"default"} text={"Add"} onClick={onApply} disabled={state.selectedTemplate === null} />
		</>}
	/>
}

export { PropertyEditor };