import { User } from '../interfaces/User';
import api from "../../api/api";
import {useCache} from "./cache/useCache";
import {useTypedAsync} from "./useAsync";

export interface UserList {
	child_resources: string[],
	users: User[],
	map: {
		[key: string]: User,
	}
}

export function getUserListApi(fields: string[]): Promise<UserList> {
	const query = fields.length > 0 ? `?fields=${fields.join(",")}` : "";
	return api.call("GET", `users${query}`);
}

/**
 * This hook enables the request/caching of a list of users within the current users cognito pool.
 */
function useUserList() {
	const cache = useCache(5);
	const getUserList = (fields: string[]): Promise<UserList | null> => {
		const cacheKey = JSON.stringify(fields);
		return cache.getItem(cacheKey, () => getUserListApi(fields));
	}
	const getUserNames = () => getUserList(["name", "display_name"]);

	const loadUserList = useTypedAsync(getUserList, false);

	return {
		getUserList,
		getUserNames,
		...loadUserList,
	}
}

export {useUserList};