import { useRunWhenValueChange } from "../../../../../shared/hooks";
import { useStatusBar } from "../../../../../shared/components";
import { CommentState } from "../../useCommentState";
import { CommentPayload, CommentStates } from "../../../components/modals/templateComments/CommentModal.models";
import { useUpdateComment } from "../../api/comments/useUpdateComment";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

type EditResolve = 'EDIT' | 'RESOLVE';
type RunProps = {
	editResolve: EditResolve,
	commentPayload: CommentPayload
}
function useUpdateCommentAction(): TypedAction<RunProps> {

	const commentsManager = CommentState.useContainer();
	const updateComment = useUpdateComment();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error updating comment", detail: updateComment.error});
	}, updateComment.error);

	useRunWhenValueChange(() => {
		if(updateComment.status === "success"){
			// when it completes successfully the return object is a full GET call of all of the comments
			const commentsResponse = updateComment.value as KaiAlphaCommentsTemplate;

			commentsManager.setComments(commentsResponse.filter((value) => !value.deleted));
			statusBar.sendSuccessNotification({message: "Comment updated"});
		}
	}, updateComment.value);

	const run = ({editResolve, commentPayload}: RunProps) => {
		if(editResolve === 'RESOLVE') {
			commentPayload.comment.state = CommentStates.RESOLVED;
			updateComment.execute(commentsManager.templateId, commentPayload.comment);
		} else if (editResolve === 'EDIT') {
			updateComment.execute(commentsManager.templateId, commentPayload.comment);
		}
	}

	return {run, isRunning: updateComment.isLoading};
}

export {useUpdateCommentAction};