import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../../../theme';

const useStyles = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiListItem-root': {
			paddingTop: 3,
			paddingBottom: 3,
		}
	},
	expandedContainer: {
		width: '100%',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		justifyContents: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	tocHeader: {
		fontSize: theme.typography.h5.fontSize,
	},
	tocCollapsedHeader: {
		fontSize: theme.typography.subtitle1.fontSize,
	},
	collapseIconContainer: {
		display: 'flex',
		top: '50%',
		position: 'absolute',
		right: 0,
		alignItems: 'center',
		cursor: 'pointer',
	},
	collapseIconSize: {
		height: '1.5rem',
		width: '1.5rem',
		paddingTop: '5px'
	},
	sectionItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContents: 'flex-start',
		paddingLeft: 0,
		paddingRight: 0,
	},
	sectionNumber: {
		marginRight: 15,
	},
	sectionTitleContainer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	sectionTitle: {
		display: 'flex',
		flexDirection: 'row',
		cursor: 'pointer',
		width: '100%',
		padding: 5,
		fontSize: ".875rem",
	},
	sectionCommentIcon: {
		height: 15,
		width: 15,
		marginLeft: '0.5em'
	},
	activeSection: {
		color: theme.palette.secondary.main,
	},
	windowSection: {
		borderLeft: `${colors.black} 2px solid;`,
		background: `${theme.palette.background.level5} 0% 0% no-repeat padding-box;`,
	},
	collapseSectionIconContainer: {
		backgroundColor: colors.orangeHighlight,
		marginRight: 5,
		borderRadius: 4,
	},
	collapseSectionIcon: {
		color: theme.palette.secondary.main,
	},
	subsectionContainer: {
		background: `${colors.white} 0% 0% no-repeat padding-box`,
		marginTop: 10,
		marginLeft: 5,
		width: '100%',
		overflow: 'hidden',
	},
	subsectionItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		width: "100%",
		cursor: 'pointer',
	},
	subSectionTitle: {
		cursor: 'pointer',
		fontSize: ".875rem",
		width: '100%',
		padding: 5,
		display: "flex"
	},
});

export { useStyles };
