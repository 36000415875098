import {ensureArgs} from "../filterUtilities";
import {isString} from "../../../guards/isString";
import {isObject} from "../../../guards/isObject";
import {isNunjucksDataSource, NunjucksDataSourceType} from "../../models/NunjucksDataSourceType";
import {NunjucksFilterFactoryProps} from "../model";

function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args, 2)) {
		return {}
	}

	if (!isString(args[1]) && !isObject(args[0])) {
		return {};
	}

	return isNunjucksDataSource(args[0]) ? getColumn({...args[0], columnName: args[1] as string}) : {}
}

type GetColumnProps = NunjucksDataSourceType & {
	columnName: string
}
function getColumn(props: GetColumnProps): unknown {
	const retval = {};

	const data = props.data;

	switch(props.type) {
		case 'columns-rows':
			Object.assign(retval, {
				...data[props.columnName]
			});
			break;
		case 'columns':
		{
			let row_index = -1;
			for (const row of data) {
				row_index++;
				retval[row_index] = row[props.columnName];
			}
		}
			break;
		default:
			throw(new kaialpha.UserError(`Data Type "${props.type}" not supported`));
	}
	return(retval);
}

export function getColumnFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "get_column",
		help: "Filter that accepts a datasource and produces a new object which contains the values from the named column",
		example: "var | get_column('col')",
		execute: execute
	})
}