import { User } from "../../../shared/interfaces/User";
import { getUserTaskList } from "../../../shared/tasks/getTaskList";
import { getDocumentTasksByUserId } from "../../../shared/tasks/getDocumentTasksByUserId";
import { getTemplateTasksByUserId } from "../../../shared/tasks/getTemplateTasksByUserId";

const getUserTasks = async (user: User) => {
	const [documentTasks, templateTasks] = await Promise.all([getDocumentTasksByUserId(user), getTemplateTasksByUserId(user)]);

	const templates = templateTasks.results.map(template => {
		const templateObject = template as Object;
		return {
			...templateObject,
			type: 'template',
		};
	});
	const documents = documentTasks.results.map(document => {
		const documentObject = document as Object;
		return {
			...documentObject,
			type: 'document',
		};
	});
	const combinedUserTasks = [...templates, ...documents,];

	const possibleUserTasks = combinedUserTasks?.map(item => {
		if(item.type === 'document') {
			// when document pull out cache value
			const fullItem = JSON.parse(item['_external_data']['cache']);
			fullItem.permissions = item['_cached_canonical_permissions'] || item['permissions'];
			fullItem['@workflows_waiting'] = item['@workflows_waiting'];
			fullItem.type = item.type;
			return fullItem;
		} else {
			// when type is template, the function getTemplateFromSearchResult already pulls the value out of the cache so no need to do that
			return item;
		}
	}).filter(x => !!x);

	return getUserTaskList(possibleUserTasks, user);
}

export {getUserTasks}