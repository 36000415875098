import {useCreatePresignedPost} from "./useCreatePresignedPost";
import {useRunWhenValueChange} from "../../useRunWhenValueChange";
import {useUploadToS3} from "./useUploadToS3";
import {AsyncState} from "../../useAsync";
import {useState} from "react";
import {useCreatePayload} from "./useCreatePayload";
import {PostFileToS3State, S3PostFileValue, S3PresignedUrl, UsePostImageProps} from "./models";

function usePostFileToS3(fileType: string | undefined): AsyncState<S3PostFileValue | null> & {execute: (props: UsePostImageProps) => void} {
	const [state, setState] = useState<PostFileToS3State>({
		file: undefined,
		type: undefined,
		presignedUrl: undefined,
		result: null
	});

	const presignedUrlHandler = useCreatePresignedPost({type: fileType});
	const uploader = useUploadToS3();
	const s3PayloadFactory = useCreatePayload()


	useRunWhenValueChange(() => {
		if (state.file && state.type) {
			presignedUrlHandler.execute();
		}
	}, state.file);

	useRunWhenValueChange(() => {
		if (presignedUrlHandler.value) {
			const s3Value = presignedUrlHandler.value! as S3PresignedUrl;
			setState(s => ({...s, presignedUrl: s3Value}));
		}
	}, presignedUrlHandler.value);

	useRunWhenValueChange(() => {
		if (state.presignedUrl) {
			postFile();
		}
	}, state.presignedUrl);

	useRunWhenValueChange(() => {
		if (uploader.value) {
			setState(s => ({...s, file: undefined, result:{key: state.presignedUrl!.fields.Key, type: state.type!}}));
		}
	}, uploader.value);

	const postFile = () => {
		const payload = s3PayloadFactory({
			file: state.file!,
			type: state.type!,
			presignedUrl: state.presignedUrl!
		});

		uploader.execute({url: state.presignedUrl!.url, payload});
	}

	const execute = ({file, type}: UsePostImageProps) => setState(s => ({...s, file, type, presignedUrl: undefined}));
	return {
		execute,
		isLoading: presignedUrlHandler.isLoading || uploader.isLoading,
		error: presignedUrlHandler.error || uploader.error,
		status: presignedUrlHandler.isLoading ? presignedUrlHandler.status : uploader.status,
		value: state.result
	}

}

export {usePostFileToS3};