import {ElementIconProps, IconDefaults} from "../../model";

const MetaElseIcon = ({color}: ElementIconProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
		<path style={{fill: "none"}} d="M0,0H20V20H0Z"/>
		<path style={{fill:color ?? IconDefaults.color}} d="M11.5,4l1.717,1.718-2.16,2.16,1.065,1.065,2.16-2.16L16,8.5V4Zm-3,0H4V8.5L5.718,6.782,9.25,10.307V16h1.5V9.693L6.782,5.718Z"/>
	</svg>

)

export {MetaElseIcon}