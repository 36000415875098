import { SectionElementPreviewValue } from "../section/model";
import { DocumentTreeElement } from "../../../models/element";

type TocItemEvents = {
	onClick: (itemId: string) => void;
}
export type TocItemProps = DocumentTreeElement & TocItemEvents;

const TocItem = (props: TocItemProps) => {
	const data = props.data as SectionElementPreviewValue;

	const onSectionClick = () => props.onClick(props.id);

	return data.name ? <li key={props.id} onClick={onSectionClick} style={{cursor: "pointer"}}>
		{data.name}
	</li> : null
}

export { TocItem }
