import {Submission} from "../../models/Submission";
import api from "../../../../api/api";
import {useAsync} from "../../../../shared/hooks";

function getSubmissionApi(contentId: string, versionId?: string): Promise<Submission> {
	const path = versionId ? `ectd/submission/${contentId}/${versionId}` : `ectd/submission/${contentId}/HEAD`;
	return api.call("GET", path);
}
function useGetSubmission() {
	return useAsync(getSubmissionApi, false);
}

export {useGetSubmission}