import { definitionForElementType } from "../index";
import {
	ReferenceElementValue,
	ReferenceVariableContents
} from "../../../../../shared/definitions/elements/reference/model";
import { findElementsInBody } from "./findElementsInBody";
import {formatReference} from "./formatReference";
import {renderMissingVariable} from "./renderMissingVariable";
import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";

function recursivelyFindReferenceElements(elements: ElementValueType[]) {
	return elements
		.filter(element => element.type === "reference")
		.concat(elements.map(element => recursivelyFindReferenceElements(element.children ?? []))
			.reduce((allReferences, current) => allReferences.concat(current), [] as ElementValueType[]));

}
function mapReferenceElements(body?: ElementValueType[]) {
	const bodyElements = body ?? [];
	const refs = recursivelyFindReferenceElements(bodyElements).map(value => {
		// find first occurrence referenced element in body
		const referenceData = value.data as ReferenceElementValue
		try {
			const element = findElementsInBody(body as ElementValueType[], referenceData.value.element_id);
			const elementDefinition = definitionForElementType(element?.type);
			return ({
				name: referenceData.name,
				value: {
					...(value.data as ReferenceElementValue).value,
					name: elementDefinition?.toString(element) ?? "Undefined Element",
					value: elementDefinition?.contentDescription(element) ?? "Undefined Element",
				} as ReferenceVariableContents
			});
		} catch(e) {
			return undefined
		}
	});


	return refs.reduce((lookup, ref) => {
		if (ref === undefined) {
			return lookup;
		}

		if (ref.value === undefined) {
			lookup[ref.name] = renderMissingVariable(ref.name);
			return lookup;
		}

		// prevent click from actually sending user anywhere with
		// onclick="return false;"
		lookup[ref.name] = formatReference(ref);

		return lookup;
	}, {});
}

export {mapReferenceElements}