import {useParams} from "react-router-dom";
import {useRunWhenValueChange} from "../../../../shared/hooks";
import {useLoadSubmissionAction} from "../../hooks/actions/load/useLoadSubmissionAction";
import {SubmissionState} from "../../hooks";
import {useState} from "react";
import {EctdModule} from "../../../../shared/interfaces/Module";
import {recursivelyFindModule} from "../../utilities/recursivelyFindModule";
import {useExtractAction} from "../../hooks/actions/data/useExtractAction";
import {useSaveSubmissionAction} from "../../hooks/actions/update/useSaveSubmissionAction";

function useEditor() {
	const submissionManager = SubmissionState.useContainer();
	const {submission, setSubmission} = submissionManager;
	const [selectedModule, setSelectedModule] = useState<EctdModule | undefined>(undefined);
	const {contentId, versionId} = useParams();

	// actions
	const loadSubmissionAction = useLoadSubmissionAction();
	const extractSubmissionAction = useExtractAction();
	const saveSubmissionAction = useSaveSubmissionAction();

	useRunWhenValueChange(() => {
		submissionManager.reset();
	}, [contentId, versionId]);

	useRunWhenValueChange(() => {
		if (submissionManager.didReset && contentId) {
			setSelectedModule(undefined);
			loadSubmissionAction.run({id: contentId, version: versionId});
		}
	}, submissionManager.didReset);

	useRunWhenValueChange(() => {
		if (submission.ectdModules.length > 0 && selectedModule === undefined) {
			setSelectedModule(submission.ectdModules[0]);
		}
	}, submission);


	// when module changes check to make sure we have selected a section within that module
	useRunWhenValueChange(() => {
		if (selectedModule !== undefined) {
			if (submissionManager.selectedModule === undefined || submissionManager.selectedModule.moduleNumber.split(".")[0] !== selectedModule.moduleNumber) {
				selectFirstSection(selectedModule);
			}
		}
	}, selectedModule);

	useRunWhenValueChange(() => {
		if (submissionManager.selectedModule) {
			// refresh selected section
			const newSelectedSection = recursivelyFindModule(submission.ectdModules, m => m.moduleNumber === submissionManager.selectedModule!.moduleNumber);
			submissionManager.setSelectedModule(newSelectedSection);
		}
	}, submission.ectdModules)

	// when section changes make sure parent module is also selected
	useRunWhenValueChange(() => {
		if (submissionManager.selectedModule !== undefined) {
			const parentModuleNumber = submissionManager.selectedModule.moduleNumber.split(".")[0];
			const parentModule = submission.ectdModules.find(module => module.moduleNumber === parentModuleNumber);
			if (parentModule !== undefined && parentModule.moduleNumber !== selectedModule?.moduleNumber) {
				setSelectedModule(parentModule);
			}
		}

	}, submissionManager.selectedModule)

	const selectFirstSection = (module: EctdModule) => {
		if ((module.modules ?? []).length === 0) {
			return;
		}
		selectSection(module.modules[0]);
	}

	const selectSection = (module: EctdModule) => submissionManager.setSelectedModule(module);

	const selectModule = (module: EctdModule) => {
		setSelectedModule(module);
		selectFirstSection(module);
	}

	return {
		submission,
		selectedModule,
		selectedSection: submissionManager.selectedModule,
		selectModule,
		extract: extractSubmissionAction.run,
		save: saveSubmissionAction.run,
		selectSection,
		setSubmission,
		isLoading: loadSubmissionAction.isRunning
	}
}

export {useEditor};