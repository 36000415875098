import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Button } from "../";
import { CenteredContainer } from "../../layout";
import { ThemeButtonProps } from "./ThemeButton.models";

interface GroupExpand {
	rotated: boolean,
}

export interface GroupExpandButtonProps extends ThemeButtonProps, GroupExpand {}

const ExpandKeyboardArrowDown = styled(KeyboardArrowDown, {shouldForwardProp: (prop) => prop !== "rotated"})<GroupExpand>((props) => ({
	transform: props.rotated ? 'rotate(180deg)' : '',
	transition: 'transform 281ms ease',
	color: props.rotated ? props.theme.palette.primary.main : props.theme.palette.plain?.contrastText
}));

const GroupExpandButton = ({rotated, onClick}: GroupExpandButtonProps) => <Box marginLeft="5px">
	<Button onClick={onClick} buttonType={"plain"}>
		<CenteredContainer>
			<ExpandKeyboardArrowDown rotated={rotated} style={{fontSize: '20px'}}/>
		</CenteredContainer>
	</Button>
</Box>

export {GroupExpandButton}
