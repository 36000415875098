import {Dialog, DialogProps} from "@mui/material";

interface BasePopupProps {
	wide?: boolean,
}

const BasePopup = (props: DialogProps & BasePopupProps) => <Dialog {...props}
	BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.15)" } }}
	PaperProps={{ style: { boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", borderRadius: '12px', minWidth: `${props.wide ? "80%" : "60%"}` } }}
/>

export {BasePopup}