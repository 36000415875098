import { BaseElement } from "../../../interfaces/BaseElement";

const styleDefinition: BaseElement = {
	name: 'style',
	type: 'style',
	displayName: 'Style',
	description: 'Place Sub-Templates',
	allowsChildren: false,
	longDescription: 'Template elements may be used to include another template at the position where this element is placed',
	contentDescription: () => "",
	toString: () => "Undefined element type",
} as const

export type StyleElementValue = {
	option?: StyleType,
	value: unknown,
	type: "style"
}

export const StyleOptionValues = {
	Unknown: 0,
	StyleElement: 1,
	AddStyle: 2,
	AddSymbol: 3,
	AddSectionNumbering: 4
}
export type StyleType = keyof typeof StyleOptionValues;

export {styleDefinition};