import {useElements} from "../../../../../hooks";
import {TemplateState} from "../../../../../hooks";
import {useState, useMemo} from "react";
import {useRunWhenValueChange} from "../../../../../../../shared/hooks";
import { TreeNode } from "../../../../../../../shared/components/list/tree/TreeList.models";
import { search, sortByType } from "../../../../../../../shared/components/list/tree/TreeList.utils";
import { FilterState } from "../../../../../../../shared/components/input/filter/sort/models";
import { mapTemplateElementToTreeNode } from "../../../../../../../shared/components/list/tree/mapTemplateElementsToTreeNode";
import {ReferenceNodeData} from "../../../../../../../shared/interfaces/ReferenceNodeData";
const clone = require("rfdc/default");

// the elements available to choose from to create a reference.
interface ReferenceState {
	references: TreeNode[],
	searchValue: string,
	sortState: FilterState,
}

const initialState: ReferenceState = {
	references: [],
	searchValue: "",
	sortState: "none",
}

// simplifies readability of DialogView (the modal for selecting references) for reference elements by breaking out
// data required to operate the reference modal and ListView.
const useReference = () => {
	const elementsManager = useElements();
	const [state, setState] = useState(initialState);
	const templateState = TemplateState.useContainer();

	const searchedReferences = useMemo(() => state.searchValue === "" ?
		(state.references ?? []) : search(state.searchValue.toLowerCase().trim(), clone(state.references)),
	[state.searchValue, state.references]);

	const filteredReferences = useMemo(() => state.sortState === "none" ?
		(searchedReferences ?? []) : sortByType(state.sortState, clone(searchedReferences)),
	[state.sortState, searchedReferences]);

	// when any elements that are part of this template change, add them as references.
	// elementsManager gets these elements from templateState.
	useRunWhenValueChange(() => {
		// remove references and variables (references cannot be referenced)
		const topLevelReferences =  mapTemplateElementToTreeNode(
			(elementsManager.elements ?? []).filter(element => element.type !== 'reference' && element.type !== 'variable'),
			{template_id: templateState.template?.id, template_version: templateState.template?.version} as ReferenceNodeData,
		);
		setState(s => ({...s, references: topLevelReferences}));
	}, elementsManager.elements);

	const updateReference = (childReference: TreeNode) => {
		const childIndex = state.references.findIndex(node => node.id === childReference.id);
		state.references[childIndex] = childReference;
		setState(s => ({...s, references: state.references}));
	}

	const setSearchValue = (searchValue: string) => {
		setState(s => ({...s, searchValue}));
	}

	const setSortState = (sortState: FilterState) => {
		setState(s => ({...s, sortState}));
	}

	return {
		updateReference,
		filteredReferences,
		setSearchValue,
		setSortState,
		...state
	}
}

export {useReference}