import {MetaDataChangeEvent, MetadataInput} from "./MetadataInput";
import React, {useState, Fragment, useMemo} from "react";
import {Box} from "@mui/material";
import {MetadataListContainer} from "./MetadataList.style";
import { SearchBar } from "../../../input/search/SearchBar";
import { EditableControlBox, FlexBox } from "../../..";
import { EmptyGroup } from "../EmptyGroup";
import {MetadataItem} from "../../../../interfaces/Metadata";

type SelectedMetadata = string | null;

interface FilteredMetadata {
	searchValue: string,
}

export type RenderEditorEvent = {
	metadata: MetadataItem,
	onCancel: () => void,
	onSave: (event: MetaDataChangeEvent) => void
}

interface MetadataListProps {
	editable: boolean,
	onEdit?: (event: MetaDataChangeEvent) => void,
	onDelete?: (key: string) => void,
	metadata: object,
	renderValue?: (item: MetadataItem) => string,
	renderEditorForMetadata?: (event: RenderEditorEvent) => React.ReactNode
}

const search = (searchValue: string, metadata: object): object => {
	if (searchValue === "") {
		return metadata;
	}

	const substring = searchValue.toLowerCase().trim();
	const result = {};
	Object.keys(metadata).forEach((key) => {
		if (key.toLowerCase().includes(substring) || metadata[key]?.toLowerCase().includes(substring)) {
			result[key] = metadata[key];
		}
	})
	return result;
}

/**
 * This component holds all metadata in a list, allowing for search functionality.
 * @constructor
 */
const MetadataList = ({editable, onEdit, onDelete, metadata, renderValue, renderEditorForMetadata}: MetadataListProps) => {

	// currently clicked on / focused metadata item.
	const [selectedMetadata, setSelectedMetadata] = useState<SelectedMetadata>(null);

	// subset of provided metadata that contains a key/value with the searched substring.
	const [filteredMetadataState, setFilteredMetadataState] = useState<FilteredMetadata>({
		searchValue: '',
	});

	const onEditClicked = (id: string) => () => {
		setSelectedMetadata(id);
	}

	const onMetadataEdited = (event: MetaDataChangeEvent) => {
		onEdit!(event);
		setSelectedMetadata(null);
	}

	const onSearchValueChange = (searchValue?: string) => {
		setFilteredMetadataState(s => ({...s, searchValue: searchValue ?? ""}));
	}

	const onDeleteFactory = (key: string) => () => {
		if (onDelete) {
			onDelete(key);
		}
	}

	const filteredMetadata = useMemo(() => {
		return search(filteredMetadataState.searchValue, metadata ?? {})
	}, [filteredMetadataState.searchValue, metadata]);

	return <>
		<SearchBar placeholder="Search metadata" onChange={onSearchValueChange}/>
		<MetadataListContainer direction="column" maxHeight="450px" overflow="auto">
			{
				Object.keys(filteredMetadata).length > 0 ?
					Object.keys(filteredMetadata).map(
						(key) =>
							<Fragment key={`${key}-metadata-control-box`}>
								{selectedMetadata !== key && <EditableControlBox
									onEdit={editable && (onEdit || renderEditorForMetadata) ? onEditClicked(key) : undefined}
									onDelete={editable && onDelete ? onDeleteFactory(key) : undefined}
									footer={
										<FlexBox align={"center"}>
											<Box className="value_text">
												{renderValue && renderValue({key, value: filteredMetadata[key]})}
												{!renderValue && filteredMetadata[key]}
											</Box>
										</FlexBox>
									}
									body={
										<FlexBox align={"center"} className="title_text">
											{key}
										</FlexBox>
									} />}
								{selectedMetadata === key && !renderEditorForMetadata &&
									<MetadataInput
										onCancel={() => setSelectedMetadata(null)}
										onSave={onMetadataEdited}
										initial={{
											key,
											value: filteredMetadata[key]
										}}
									/>
								}
								{selectedMetadata === key && renderEditorForMetadata && renderEditorForMetadata({
									metadata: {key, value: filteredMetadata[key]},
									onCancel: () => setSelectedMetadata(null),
									onSave: onMetadataEdited
								})}
							</Fragment>
					) : <EmptyGroup title="No metadata matches your search"></EmptyGroup>
			}
		</MetadataListContainer>
	</>
}

export {MetadataList}