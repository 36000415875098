import {Avatar, styled} from "@mui/material";
import { Tooltip } from "./Tooltip";
const stc = require('string-to-color');

export type NameBadgeContainerProps = {
	color?: string,
	size?: "small"
};

export type NameBadgeProps = {
	name: string,
	size?: "small",
};


const NameBadgeContainer = styled(Avatar)<NameBadgeContainerProps>(({theme, color, size}) => ({
	backgroundColor: color ?? theme.palette.plain?.hover,
	color: "white",
	fontSize: size === 'small' ? ".7rem" : '0.875rem',
	width: size === 'small' ? "1.8rem" : "2.25rem",
	height: size === 'small' ? "1.8rem" : "2.25rem",
	margin: "0 .25rem 0 0",
	position: "unset"
}))

// for keeping track of colors in the app.
const colorMap: {[id: string]: string} = {};

const NameBadge = ({name, size}: NameBadgeProps) => {

	const colorForName = (owner: string) => {
		const color = colorMap[owner] ?? stc(owner);
		colorMap[owner] = color;
		return color;
	}

	const initialsForName = (owner: string) => owner.split(" ")
		.map(word => word.slice(0, 1).toUpperCase())
		.join("");

	return <Tooltip title={name} placement="top">
		<NameBadgeContainer size={size} color={colorForName(name)}>
			{initialsForName(name)}
		</NameBadgeContainer>
	</Tooltip>
}

export {NameBadge}