import { useStatusBar } from "../../../../../shared/components";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import { UserManager } from "../../../../../shared/hooks/useUser";
import { useCreateDocument } from "../../api/useCreateDocument";
import { DocumentState } from "../../useDocumentState";
import {EntityRecord} from "../../../../../shared/interfaces/Entity";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

type RunProps = {
	documentName: string,
	currentUser: string,
	template: EntityRecord
}
function useCreateDocumentAction(): TypedAction<RunProps> {
	const documentManager = DocumentState.useContainer();
	const createDocument = useCreateDocument();
	const user = UserManager.useContainer();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		// when document is first created set the permissions to the logged in user with the document
		const initialPermissions: KaiAlphaDocumentPermissions = {
			owners: [user.id],
			roles: {
				authors: [],
				reviewers: [],
				approvers: [],
				readers: []
			},
			acl: {
				read: [],
				write: [],
				'comments:write': []
			}
		}

		documentManager.setDocument({
			...documentManager.document,
			permissions: initialPermissions,
			id: createDocument.value.id,
			version: createDocument.value.version});

		statusBar.sendSuccessNotification({message: "Content Created"});
	}, createDocument.value);

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: `Error creating content ${createDocument.error}`});
	}, createDocument.error)

	const run = ({documentName, currentUser, template}: RunProps) =>
		createDocument.execute(documentName, currentUser, template);

	return {run, isRunning: createDocument.isLoading};
}

export {useCreateDocumentAction}