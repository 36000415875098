import {EditorModalDefinition, EditorModalProps} from "./index";
import {AsyncComponent, Button, FlexBox, useStatusBar} from "../../../../../../shared/components";
import {BaseModal} from "../../../../../../shared/components/modals/BaseModal";
import {useGetDataSource} from "../../../../../../shared/hooks/api/data/useGetDataSource";
import {useRunWhenValueChange} from "../../../../../../shared/hooks";

const Modal = (props: EditorModalProps) => {
	const getDatasource = useGetDataSource();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if (getDatasource.status === "success" && getDatasource.value) {
			statusBar.sendSuccessNotification({message: `${props.variable.name} updated`});
			props.onSubmit(getDatasource.value!)
		}

		if (getDatasource.status === "error") {
			statusBar.sendErrorNotification({message: `Error updating ${props.variable.name}`, detail: getDatasource.error});
		}
	}, getDatasource.status);

	const onGetLatestData = () => getDatasource.execute(props.variable.options);

	const {Component} = props;

	return <BaseModal visible={true} onClose={props.onClose} title={"Edit DataSource"} buttonRow={<>
		<Button text={"Cancel"} styling={"cancel"} onClick={props.onClose} />
		<Button buttonType={"default"} text={"Get latest data"} onClick={onGetLatestData} />
	</>} content={<AsyncComponent isLoading={getDatasource.isLoading} loadingMessage={"Updating datasource"} component={<FlexBox direction={"column"} width={"100%"}>
		{Component ? <Component variable={props.variable} onClose={props.onClose} /> : null}
	</FlexBox>}/>} />
}

const DataSourceEditorModal: EditorModalDefinition = {
	type: "datasource",
	view: Modal
}

export {DataSourceEditorModal}