import { useState } from "react";
import { useStatusBar } from "../../../../../shared/components";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import { useDeleteComment } from "../../api/comments/useDeleteComment";
import { CommentState, DocumentComment } from "../../useCommentState";
import { sortComments } from "../";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

const formatPayload = (documentComment: DocumentComment) => {
	const formatted: KaiAlphaCommentEntry = {
		id: documentComment.id,
		version: documentComment.version,
		author: documentComment.author,
		date: documentComment.date,
		state: documentComment.state,
		comment: documentComment.comment,
		text: documentComment.text,
		text_properties: documentComment.text_properties,
		lastupdated: documentComment.lastupdated,
		column: documentComment.column,
		row: documentComment.row,
		tag: documentComment.tag,
		document_wide: documentComment.document_wide,
		subaddress: documentComment.subaddress,
		deleted: documentComment.deleted,
		elementId: documentComment.elementId,
		parentId: documentComment.parentId
	}

	return formatted;
}

type RunProps = {
	documentId: string,
	commentPayload: DocumentComment
}
const useDeleteCommentAction = (): TypedAction<RunProps> => {
	const [commentToDelete, setCommentToDelete] = useState<DocumentComment|undefined>(undefined);
	const deleteCommentApi = useDeleteComment();
	const commentManager = CommentState.useContainer();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error deleteing comment", detail: deleteCommentApi.error});
	}, deleteCommentApi.error);

	useRunWhenValueChange(async () => {
		const modifiedComments: DocumentComment[] = commentManager!.comments!.filter((commentValue) =>
			commentValue.id !== commentToDelete!.id
		);
		commentManager.setComments(sortComments(modifiedComments));

		statusBar.sendSuccessNotification({message: "Comment deleted"});
	}, deleteCommentApi.value);

	const run = async ({documentId, commentPayload}: RunProps) => {
		setCommentToDelete(commentPayload);
		deleteCommentApi.execute(documentId, formatPayload(commentPayload));
	}

	return {run, isRunning: deleteCommentApi.isLoading};

}

export {useDeleteCommentAction, formatPayload}