import { styled } from "@mui/material/";
import { BaseButton } from "./BaseButton";

const DestructiveButton = styled(BaseButton)(({theme}) => ({
	backgroundColor: theme.palette.destructive?.main,
	color: theme.palette.primary?.contrastText,
	fontWeight: "bold",
	padding: "4px 12px",
	textTransform: "none",
	'&:hover': {
		backgroundColor: theme.palette.destructive?.contrastText,
		color: theme.palette.destructive?.main,
	}
}));

export {DestructiveButton};