import { User } from "../interfaces/User";
import { SYSTEM_EVERYONE_ID } from "../../modules/task/constants/constants";
import { WorkflowButton } from "../../modules/task/models/workflow";
import { canonicalizePermissions } from "../permissions/canonicalizePermissions";

const filterButtonsWorkflowUI = (
	user: User,
	buttons: WorkflowButton[],
	itemPermissions: KaiAlphaDocumentPermissions
) => {
	const response: unknown[] = [];

	if (!buttons) {
		return (response);
	}

	for (const button of buttons) {
		if (itemPermissions === undefined || itemPermissions.owners.length === 0) {
			throw (new Error('Missing permissions'));
		}
		const buttonPermissionsStatement = {
			...itemPermissions,
			acl: button.permissions.acl
		};

		const buttonPermissions = canonicalizePermissions(buttonPermissionsStatement);

		let writePermissionsInsideGroup = false;
		for (const group of user.groups) {
			if (buttonPermissions.acl &&
					buttonPermissions.acl.write.includes(group)) {
				writePermissionsInsideGroup = true;
				break;
			}
		}

		const condition1 = (buttonPermissions.acl &&
			buttonPermissions.acl.write.includes(user.id));
		const condition2 = buttonPermissions.acl &&
			buttonPermissions.acl.write.includes(SYSTEM_EVERYONE_ID);

		if (writePermissionsInsideGroup || condition1 || condition2) {
			response.push({
				...button,
				permissions: buttonPermissions
			});
		}
	}

	return (response);
}

export {filterButtonsWorkflowUI}