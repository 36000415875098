import {FlexBox, NumberedControlBox} from "../../../../../shared/components";
import {MultiSelectDropDown} from "../../../../../shared/components/input/dropdown/multiSelect/MultiSelectDropDown";
import {useGetRegulators} from "../../../hooks/api/useGetRegulators";
import {useRunOnce} from "../../../../../shared/hooks";

export type RegulatoryBodyBoxProps = {
	enabled: boolean,
	onToggleRegulator: (regulator: string) => void,
	regulators: string[]
}
const RegulatoryBodyBox = (props: RegulatoryBodyBoxProps) => {
	const getRegulators = useGetRegulators();
	useRunOnce(() => {
		getRegulators.execute();
	});


	const regulatorItems = (getRegulators.value ?? []).map(regulator => ({title: regulator.country, value: regulator.country}));

	return <NumberedControlBox number={2} title={"Regulatory Body (Country)"} enabled={props.enabled}>
		<FlexBox align={"center"} justify={"flex-start"} sx={{margin: "1rem 2rem 3rem 2rem"}}>
			<MultiSelectDropDown
				value={props.regulators}
				label={"Select Regulatory Body"}
				placeholder={"Search country or regulatory body..."}
				width={"calc(100% - 4rem)"}
				disabled={!props.enabled}
				items={regulatorItems} onToggleSelection={props.onToggleRegulator}/>
		</FlexBox>
	</NumberedControlBox>
}

export {RegulatoryBodyBox}