import { FlexBox } from "../FlexBox";
import React from "react";

export interface PaneContentProps {
	expanded: boolean,
	children: React.ReactNode
}
const PaneContent = ({expanded, children}: PaneContentProps) => {

	return expanded ? <FlexBox
		flexWrap="wrap" overflow="hidden" height="100%"
		style={{minWidth: "20vw"}}>
		{children}
	</FlexBox> : <FlexBox
		height="100%"
		direction="column"
		justifyContent="center"
		alignItems="center"
	>{children}</FlexBox>

}

export {PaneContent};