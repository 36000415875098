import {useAsync} from "../../../../shared/hooks";
import { useGetDocument } from "./useGetDocument";
import { useGetDocumentBuffer } from "./useGetDocumentBuffer";

export interface DocumentWithBuffer {
	document: KaiAlphaDocument,
	buffer: KaiAlphaDocument | null
}

// gets a document (and the user's associated buffer) by id
function useGetDocumentAndBuffer() {

	const getDocument = useGetDocument();
	const getBuffer = useGetDocumentBuffer();
	const getDocumentAndBuffer = async (id: string, version?: string): Promise<DocumentWithBuffer> => {
		const document = await getDocument.execute({id, version});
		if (!document) {
			throw new Error('No document with the provided id found');
		}
		const buffer = await getBuffer.execute(document?.id, document?.version);

		return {buffer, document};
	};

	return useAsync<DocumentWithBuffer>(getDocumentAndBuffer, false);
}

export {useGetDocumentAndBuffer};