import {useAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";

function useCreateDocument() {
	const createDocument = async (
		documentName: string,
		currentUser: any,
		template: any
	): Promise<any> => {
		const payload = {
			name: documentName,
			state: '',
			template: {
				id: template.id,
				version: template.version
			},
			subdocuments: {},
			variables: {}
		};

		return api.call('POST', `document`, payload);
	}

	return useAsync<any>(createDocument, false);
}

export {useCreateDocument}