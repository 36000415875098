import {Either, Try} from "../../interfaces/Either";
import {SearchResult} from "../../interfaces/SearchResult";
import {QueryResponse} from "../../interfaces";

export function getTemplateFromSearchResult(searchResult: SearchResult & {"@subtemplates": string[]}): Either<QueryResponse<KaiAlphaTemplate>> {
	return Try(() => {
		const template = JSON.parse(searchResult._external_data.cache) as KaiAlphaTemplate;
		return {
			...template,
			"@date": searchResult._s3Metadata.date,
			"@subtemplates": searchResult["@subtemplates"],
			"@workflows_waiting": searchResult["@workflows_waiting"]
		}
	});
}