export type UserGroupsListAction = "view" | "delete";
export type UserGroupsListActionLookup = Record<UserGroupsListAction, UserGroupsListAction>;

export type ExtractionStatusLabel = "in process" | "error" | "complete";
export type ExtractionStatus = {
	label: ExtractionStatusLabel,
	action: string
}
export type UserGroupNode = {
	groups: []
}

export type UserGroupUser = {
	id: string,
	name: string
}

export type UserGroupsNode = {
	groupName: string,
	description: string,
	id: string,
	users: UserGroupUser[]
}


export type UserGroupValidation = {
	isGroupNameValid: boolean
}

export const userGroupsNodeActions: UserGroupsListActionLookup = {
	view: "view",
	delete: "delete"
}