import './style.scss'
import { PreviewElementProps } from "../model";
import {AsyncComponent} from '../../../../../shared/components';
import {useGetList} from "../../../../../shared/hooks/api/useGetList";
import {useEventListener, useRunOnce, useRunWhenValueChange} from "../../../../../shared/hooks";
import {renderString} from "../../../../../shared/nunjucks/renderString";
import {useState} from "react";
import {NunjucksEventTypes} from "../../../../../shared/nunjucks/nunjucksEvents";

type Abbreviation = {
	key: string,
	value: string,
	lowerCaseKey: string
}

const PreviewView = (props: PreviewElementProps) => {
	const getAbbreviationList = useGetList("abbreviation");
	const [abbreviations, setAbbreviations] = useState<Abbreviation[]>([])
	useRunOnce(() => {
		getAbbreviationList.execute(undefined)
	});

	const parseAbbreviationList = () => {
		const abbreviationList = JSON.parse(renderString({template: "{{__abbreviationlist()}}", variables: {}}));
		const abbreviationKeys = Object.keys(abbreviationList);
		setAbbreviations((getAbbreviationList.value?.entries ?? []).filter(entry => abbreviationKeys.includes(entry.key.replace(/\s/gm, "")))
			.map(entry => {
				const lowerCaseKey = entry.key.replace(/\s/gm, "").toLowerCase();
				return ({...entry, lowerCaseKey}) as Abbreviation
			}).sort((a, b) => {
				if (a.key > b.key) {
					return 1
				} else if (a.key < b.key) {
					return -1;
				}

				return 0;
			}));
	}

	useRunWhenValueChange(() => {
		parseAbbreviationList();
	}, props.element.data);

	useRunWhenValueChange(() => {
		parseAbbreviationList();
	}, props.variables);

	useRunWhenValueChange(() => {
		parseAbbreviationList();
	}, getAbbreviationList.value);

	useEventListener(NunjucksEventTypes.abbreviationUpdate, (e: CustomEventInit) => {
		parseAbbreviationList();
	})

	return <AsyncComponent
		isLoading={getAbbreviationList.isLoading}
		loadingMessage={"Loading abbreviations..."}
		component={<>
			<table>
				{abbreviations.length > 0 && <thead>
					<tr>
						<th style={{"textAlign": "left"}}>Abbreviation</th>
						<th style={{"textAlign": "left"}}>Meaning</th>
					</tr>
				</thead>}
				<tbody>
					{abbreviations.map(entry => <tr id={`abbreviation_${entry.lowerCaseKey}`} key={entry.key}>
						<td>{entry.key}</td>
						<td><em>{entry.value}</em></td>
					</tr>)}
				</tbody>
			</table>
		</>} />
}

export default PreviewView;
