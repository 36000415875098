import { styled } from "@mui/material";
import { CenteredContainer } from "../../CenteredContainer";
import {ButtonProps} from "./Button.model";
import {ButtonBase} from "./ButtonBase";
import { KeyboardArrowDown } from "@mui/icons-material";

interface Expand {
	expanded: boolean,
}

export interface ExpandButtonProps extends ButtonProps, Expand {}

const ExpandKeyboardArrowDown = styled(KeyboardArrowDown, {shouldForwardProp: (prop) => prop !== "expanded"})<Expand>(({theme, expanded}) => ({
	transform: expanded ? 'rotate(180deg)' : '',
	transition: 'transform 281ms ease',
	color: expanded ?  theme.palette.table?.contrastText : theme.palette.secondary?.contrastText
}));

const ExpandButtonContainer = styled(ButtonBase, {shouldForwardProp: (prop) => prop !== "expanded"})<Expand>(({theme, expanded}) => ({
	backgroundColor: expanded ? "transparent" : theme.palette.plain?.main,
	"&:hover": {
		backgroundColor: theme.palette.plain?.hover,
		color: theme.palette.primary.main
	}
}));

const ExpandButton = ({onClick, expanded}: ExpandButtonProps) => <ExpandButtonContainer onClick={onClick} expanded={expanded}>
	<CenteredContainer>
		<ExpandKeyboardArrowDown expanded={expanded} style={{fontSize: '19px'}}/>
	</CenteredContainer>
</ExpandButtonContainer>

export {ExpandButton}