import {TypedVariableView, TypedVariableViewProps} from "./model";
import {FlexBox, Label} from "../../../../../../../shared/components";
import {LabelValue} from "../../../../../../../shared/components/labels/LabelValue";

export type ExpressionValueObject = {expression: string}
type ExpressionValueType = string | ExpressionValueObject

const ExpressionView = (props: TypedVariableViewProps) => {
	const data = props.options as ExpressionValueType;
	return <FlexBox>
		<Label>Expression:</Label>
		<LabelValue>{data === undefined ? "Not set" : (typeof data === "string" ? data : (data as ExpressionValueObject).expression)}</LabelValue>
	</FlexBox>
};

const ExpressionVariable: TypedVariableView = {
	type: "expression",
	view: ExpressionView
}
export {ExpressionVariable}