import React from "react";
import {Popover} from "@mui/material";
import {ContextMenuItem} from "./ContextMenuItem";

export type ContextMenuItemType<T> = {
	value: T,
	label: string,
	icon?: React.ElementType
}

export type ContextMenuPosition = {
	x: number,
	y: number
}

export type ContextMenuOrigin = {
	vertical: "top" | "bottom" | "center",
	horizontal: "left" | "center" | "right"
}

export type ContextMenuProps<T> = {
	menuItems: ContextMenuItemType<T>[],
	open: boolean,
	onClose: () => void,
	position: ContextMenuPosition,
	origin?: ContextMenuOrigin,
	onMenuItemSelect: (item: ContextMenuItemType<T>) => void
}

const defaultOrigin: ContextMenuOrigin = {
	vertical: "top",
	horizontal: "left"
}

const ContextMenu = <T extends string>(props: ContextMenuProps<T>) => {
	const contextMenuClickFactory = (item: ContextMenuItemType<T>) => () => props.onMenuItemSelect(item);
	return <Popover
		open={props.open}
		onClose={props.onClose}
		anchorOrigin={{
			vertical: props.position.y,
			horizontal: props.position.x
		}}
		transformOrigin={props.origin ?? defaultOrigin}
	>

		{props.menuItems.map((item) => <ContextMenuItem key={item.value} onClick={contextMenuClickFactory(item)}>{item.label}{item.icon && <item.icon />}</ContextMenuItem>)}

	</Popover>
}

export {ContextMenu}