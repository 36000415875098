import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";
import {mapBodyToElementValue} from "../../../../../shared/utilities/data/mapBodyToElementValue";
import {useLoadNestedElements} from "../../../../../shared/hooks/useLoadNestedElements";
import {TypedActionWithValue} from "../../../../../shared/interfaces/ActionRun";

function useLoadNestedElementsAction(elementType: string): TypedActionWithValue<KaiAlphaTemplateBody,ElementValueType[] | null> {

	const loadNestedElements = useLoadNestedElements(elementType);
	const run = (body?: KaiAlphaTemplateBody) => {
		const elements = mapBodyToElementValue(body);
		loadNestedElements.run(elements);
	}

	return {
		run,
		isRunning: loadNestedElements.isRunning,
		value: loadNestedElements.value
	}
}

export {useLoadNestedElementsAction}