import {TextField} from "../../../../lib/ui";
import Autocomplete from "@mui/lab/Autocomplete";
import {ChangeEvent, useState} from "react";
import {useExpressionBuilder} from "./useExpressionBuilder";
import {ExpressionBuilderProps} from "./models";
import {nunjucksConstants} from "../../../nunjucks/models/NunjucksConstants";

interface ExpressionBuilderState {
	isOpen: boolean,
	fakeValue: string,
	inputValue: string
	className?: string
}

const initialState: ExpressionBuilderState = {
	isOpen: false,
	fakeValue: "",
	inputValue: ""
}

const ExpressionBuilder = (props: ExpressionBuilderProps) => {
	const [state, setState] = useState<ExpressionBuilderState>({...initialState, inputValue: props.value ?? ""});
	const {filterOptions} = useExpressionBuilder(props);

	/**
	 * GetOptionalLabelText
	 * Get help string for Custom Filters
	 * @param event
	 * @returns {string|*}
	 */
	const getOptionalLabelText = (event: string): string => {
		let optionalLabel;
		// Find Reference
		const helpObject = nunjucksConstants.help.filters[event]

		// Determine if Helper exists
		if (helpObject) {
			optionalLabel = helpObject?.example ? ` ${helpObject.example}` : ` ${helpObject.help}`
		}

		// Attach Helper Text label or Return Event String
		return optionalLabel ? `${event} : ${optionalLabel}` : event
	}

	const onClose = () => setState(s => ({...s, isOpen: false}));
	const onOpen = () => setState(s => ({...s, isOpen: true}));
	const onChange = (_, newInputValue) => {
		/**
		 * This event is triggered when the user presses enter,
		 * so append the chosen string to the existing input,
		 * instead of replacing it, only if the popup is open.
		 */
		if (state.isOpen) {
			const words = state.inputValue.split(" ");
			words.pop();
			words.push(newInputValue);
			const newWord = words.join(" ");
			setState(s => ({...s, inputValue: newWord}));
		}
	}

	const onTextChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const inputValue = e.target.value;
		setState(s => ({...s, inputValue, fakeValue: inputValue === "" ? "" : s.fakeValue}));
	}

	const onBlur = () => {
		const changeEvent = {
			target: {
				value: state.inputValue
			}
		}
		/*
		 * update parent state only on onBlur,
		 * otherwise just maintain state locally,
		 */
		props.onChange(changeEvent);
		if (props.onBlur) {
			props.onBlur(state.inputValue);
		}
	}

	return (<Autocomplete
		id="expression-builder"
		disableClearable
		className={props.className}
		options={nunjucksConstants.filters}
		getOptionLabel={getOptionalLabelText}
		autoHighlight={true}
		value={state.fakeValue}
		inputValue={state.inputValue}
		filterOptions={filterOptions}
		onOpen={onOpen}
		onClose={onClose}
		onChange={onChange}
		freeSolo={true}
		renderInput={(params) => {
			return <TextField {...params} label='Expression' value={props.value} onChange={onTextChange} onBlur={onBlur}/>
		}}
	/>)
}

export {ExpressionBuilder};