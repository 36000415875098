/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/client/comment.mjs
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../lib/kaialpha';
import api from './api';
export async function getAllComments(type, id, version){

	if (version){
		return await api.call('GET',`comment/${type}/${id}/version/${version}`);
	}else{
		return await api.call('GET',`comment/${type}/${id}`)
	}

}

export async function addComment(type, id, payload){

	const retval =  await api.call('POST',`comment/${type}/${id}`, payload);
	return retval;

}

export async function updateComment(type, id, payload){
	await api.call('PUT',`comment/${type}/${id}`, payload);
}

export async function deleteComment(type, id, payload){
	await api.call('DELETE',`comment/${type}/${id}/${payload.commentId}`);
}

const _to_export = {
	getAllComments,
	addComment,
	updateComment,
	deleteComment
}
export default _to_export;
