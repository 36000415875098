import {ToggleChildrenButton} from "./ToggleChildrenButton";
import {FlexBox} from "../..";
import {BarLoader} from "react-spinners";
import {TreeListRow} from './TreeListRow';
import { Tooltip } from "../../labels/Tooltip";
export interface TreeListItemProps {
	loading?: boolean,
	expandable?: {
        onToggleExpansion: () => void,
		expanded: boolean,
        children: JSX.Element[],
    },
	onTitleClick?: () => void,
    onSelected?: () => void,
    title: string,
    description?: string,
    depth: number,
	badge?: JSX.Element,
	tooltip?: string
}

// style / formatting component for TreeLists
// all state is handled by the wrapping implementation of the ListItem. see 'ReferenceListItem' for detail
const TreeListItem = ({title, description, loading, expandable, onTitleClick, onSelected, depth, tooltip, badge}: TreeListItemProps) => {
	return <>
		<TreeListRow align={"center"}
			onClick={onSelected}
			depth={depth}
			expandable={expandable ? true : false }
		>
			{!loading && <FlexBox boxSizing="border-box" justify="space-between" align="center">
				{/* when more columns are needed, flex values will be calculated based upon the number of columns */}
				<FlexBox boxSizing="border-box" flex="0.7" align="center" className="row_primary">
					{expandable && !loading && <ToggleChildrenButton expanded={expandable?.expanded} onToggle={expandable.onToggleExpansion} />}
					{ tooltip ? <Tooltip title={tooltip} placement={"right"}>
						<span className={'row_title'} onClick={onTitleClick}>
							{title}
						</span>
					</Tooltip> :
						<span className={'row_title'} onClick={onTitleClick}>{title}</span>
					}
					{badge && badge}
				</FlexBox>
				<FlexBox justify="center" align="center" width="auto" flex="0.3" boxSizing="border-box">
					<span className={'row_description'}>{description}</span>
				</FlexBox>
			</FlexBox>}
			{expandable?.expanded && loading && <FlexBox paddingLeft="20px" align="stretch"><BarLoader/></FlexBox>}
		</TreeListRow>
		{expandable?.expanded && !loading && ((expandable?.children.length ?? 0) > 0) && expandable?.children}
	</>
}

export {TreeListItem};