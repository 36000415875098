import {EctdModule} from "../../../shared/interfaces/Module";

export function recursivelyFindModule(modules: EctdModule[], matcher: (module: EctdModule) => boolean): EctdModule | undefined {
	const matchingModule = modules.find(matcher);
	if (matchingModule !== undefined) {
		return matchingModule;
	}

	const children = modules.reduce((childCollection, module) => childCollection.concat(module.modules), [] as EctdModule[]);
	return children.length === 0 ? undefined : recursivelyFindModule(children, matcher);
}
