import { ReferenceElementValue } from "../../../../../../shared/definitions/elements/reference/model";
import {ReadonlyElementProps} from "../../model";
import {PropertyList} from "../../PropertyList";

const ReadonlyView = (props: ReadonlyElementProps) => {
	const data = props.data as ReferenceElementValue;
	const properties = [{label: "Name", value: data.name}, {label: "Referenced Element Type:", value: data.value?.type}, {label: "Format", value: data.value?.format}];
	return <PropertyList items={properties} />
}

export {ReadonlyView}