import {ensureArgs} from "../filterUtilities";
import {isString} from "../../../guards/isString";
import {renderString} from "../../renderString";
import {NunjucksVariables} from "../../../interfaces/Nunjucks";
import {NunjucksEnvironmentOptions} from "../../models/NunjucksEnvironmentOptions";
import {NunjucksFilterFactoryProps} from "../model";

function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args, 2) || !isString(args[0])) {
		return undefined;
	}

	const variables = (args[1] ?? {}) as NunjucksVariables;
	const options = (args[2] ?? {parseOnly: false, sharedContext: {counters: {}, incrCounters: {}}}) as NunjucksEnvironmentOptions;
	return options.parseOnly ? "" : renderString({template: args[0], variables, options});
}

export function evalFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "eval",
		help: "Renders a string with variable values",
		example: "var | eval",
		execute: (...args: unknown[]) => execute((args.length > 0 ? args[0] : undefined), props?.variables, props?.options)
	})
}