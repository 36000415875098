import {DragDropResult} from "../../../shared/components";
import {TemplateState} from "./useTemplateState";
import {ElementPropertyData} from "../components/elements/model";
import {displayNameForElementType} from "../../../shared/definitions/elements";
import { TemplateElement } from "../../../shared/interfaces/TemplateElement";
import {variableDefinition} from "../../../shared/definitions/elements/variable/model";
const clone = require("rfdc/default");

const uuid = require("uuid");

/**
 * This was lifted from the old code
 * @param type {string}
 */
function defaultContentsFactory(type: string) {
	const defaultContents = {
		type
	};

	/*
	 * Set default values based on the type of element
	 */
	switch (type) {
		case 'title':
			defaultContents['title'] = '';
			break;
		case 'variable':
			/*
			 * Most kinds of elements have their inner type set to
			 * their outer types, variables do not though
			 */
			defaultContents['type'] = '';

			break;
		default:
			console.debug('[XXX:TODO] default for', type);
			break;
	}

	return defaultContents;
}

const searchMatch = (element: TemplateElement, substring: string): boolean => {
	if (displayNameForElementType(element.type).toLowerCase().includes(substring) ||
		(element.contents?.title?.toLowerCase().includes(substring) ?? false) ||
		element.contents?.name?.toLowerCase().includes(substring)) {
		return true;
	}
	return false;
}

// find elements in nested, expandable structure
// upon finding an element that matches, also provide its parents so that the element can be accessed.
const search = (searchValue: string, elements: TemplateElement[]): TemplateElement[] => {
	if (searchValue === "") {
		return elements;
	}
	const substring = searchValue.toLowerCase().trim();

	const map = elements.reduce((values, element) => {
		if (!searchMatch(element, substring)) {
			return values;
		}
		values[element.id] = element;
		let currentElement = element;

		// until we reach the top level element
		while (currentElement.parentId) {
			// eslint-disable-next-line no-loop-func
			const parent = elements.find(e => e.id === currentElement.parentId)!;
			if (values[parent.id]) {
				// parent structure has already been traversed
				break;
			}
			values[parent.id] = parent;
			currentElement = parent;
		}
		values[currentElement.id] = currentElement;
		return values;
	}, {})

	// sort by base array index
	return (Object.values(map) as TemplateElement[]).sort((a, b) => a.index - b.index);
}

const elementTypesWithChildren = ["section", "loop"];

function recursivelyGetNextIndex(parentElement: TemplateElement, allElements: TemplateElement[]) {
	const children = allElements.filter(e => e.parentId === parentElement.id);
	if (children.length === 0) {
		return parentElement.index+1;
	}

	children.sort((c1, c2) => c2.index - c1.index);
	const lastElement = children[0];
	return recursivelyGetNextIndex(lastElement, allElements)
}

const useElements = () => {
	const templateState = TemplateState.useContainer();


	const changeElement = (updatedContent: ElementPropertyData, elementId: string) => {
		if(elementId !== null) {
			if (templateState.elements !== null) {
				const findElement = templateState.elements.findIndex((value) => value.id === elementId);
				if (findElement > -1) {
					const totalElements = [...templateState.elements];
					totalElements[findElement].contents = clone(updatedContent.data);
					totalElements[findElement].type = updatedContent.elementInformation?.type ?? totalElements[findElement].type;
					templateState.setElements(totalElements);
				}

				// also check if a variable needs updating
				if(updatedContent.elementInformation?.type === variableDefinition.type) {
					const findVariable = templateState.variables.findIndex((value) => value.id === elementId);
					if (findVariable !== -1) {
						const totalVariables = templateState.variables;
						totalVariables[findVariable].contents = clone(updatedContent.data);
						totalVariables[findVariable].type = updatedContent.elementInformation?.type ?? totalVariables[findVariable].type;
						templateState.setVariables(totalVariables);
					}
				}
			}
		}
	};

	// add element to the tree. This is almost lifted verbatim from the old code
	const addElement = (element: TemplateElement, asChildOfElementId: string | null) => {

		let {contents} = element;
		const {type} = element;
		const defaultContents = defaultContentsFactory(type)

		/*
		 * Merge the defaults and user-specified values
		 */
		contents = Object.assign(defaultContents, contents);
		/*
		* Insert the item.  This currently adds the item at the end, but
		* it may need to insert at some other location (XXX:TODO).
		*/
		const newItem: any = {
			id: uuid.v4(),
			type,
			contents,
		}

		const items = [...(templateState.elements ?? [])];
		/* Find index to insert item */
		if (asChildOfElementId) {

			if (element.id !== "") {
				asChildOfElementId = element.id;
			}

			if (asChildOfElementId) {
				const itemInfo = items.find(element => element.id === asChildOfElementId);
				if (!itemInfo) {
					return;
				}

				const index = recursivelyGetNextIndex(itemInfo, items);
				const depth = itemInfo!.depth;

				if (depth !== undefined){
					newItem['depth'] = depth + 1;
					newItem.parentId = itemInfo.id;
					newItem.index = index;
					/*
						Insert item at proper index
					*/
					if (index >= items.length) {
						items.push(newItem);
					} else {
						items.splice(index, 0, newItem);
					}
				}


			}

		} else {
			newItem['depth'] = 0;
			newItem.index = items.length;
			items.push(newItem);
		}

		if(type === 'variable') {
			// TODO do we need a different UUID for the variable array?
			const variables = [...(templateState.variables ?? [])];
			newItem.name = newItem.contents.name;
			newItem.template = undefined;
			variables.splice(0, 0, newItem);
			templateState.setVariables(variables);
		}

		templateState.setElements(items);

		return newItem;
	}

	/**
	 * Compute the drag and drop result, reorder the existing elements array
	 * @param result
	 * the DnD source / destination
	 * @param displayedElements
	 * the elements that are displayed in the view
	 * (used so that the DnD's indexes can be mapped to actual element indices)
	 */
	const onDragDrop = (result: DragDropResult, displayedElements: TemplateElement[]) => {
		if (result.destination === null || result.source === null) {
			return;
		}

		// map DnD element to actual element (and its index in the elements list)
		//created cloned object for referencing source and destination element
		const sourceElement = {...displayedElements[result.source.index]};
		const destinationElement = {...displayedElements[result.destination.index]};

		// if the drag drop did not cause any changes
		if ((result.source.index === result.destination.index) && result.destination.index !== 0) {
			const previousElement = displayedElements[result.destination.index - 1];
			let newDepth = 0;

			// if the element is a child, but cant have children of its own
			if (previousElement.isChild && !elementTypesWithChildren.includes(previousElement.type)) {
				newDepth = previousElement.depth;
			// if its an element that can have children, put it inside of that element.
			} else if (elementTypesWithChildren.includes(previousElement.type)) {
				newDepth = previousElement.depth + 1;
				templateState.computedElements[sourceElement.index].parentId = previousElement.id;
				// increment the depth by to all of is children
				if(templateState.computedElements[sourceElement.index].hasChild){
					const children = getAllChildren(sourceElement);
					children.forEach(child => {
						child.depth += 1
						templateState.computedElements[child.index] = child
					});
				}
			} else {
				// there is no change to be made to element depth.
				return;
			}
			templateState.computedElements[sourceElement.index].depth = newDepth;
			templateState.setElements(templateState.computedElements);
			return;
		}

		const body = templateState.computedElements;
		let reorderedItems: TemplateElement[] = [];

		// if the dragged element had any children
		if (sourceElement.hasChild) {
			const children = getAllChildren(sourceElement);

			if (children.find((element) => destinationElement.id === element.id)) {
				// do not allow dragging into your own child.
				return;
			}
			// remove the item and all nested items.
			reorderedItems = body!.splice(sourceElement.index, children.length + 1);
		} else {
			reorderedItems = body!.splice(sourceElement.index, 1);
		}


		// change the depth of each element that is to be reordered
		reorderedItems.forEach((item) => {
			if (destinationElement.depth === 0) {
				item.depth -= sourceElement.depth;
			} else if (sourceElement.depth > destinationElement.depth) {
				item.depth -= destinationElement.depth;
			} else if (sourceElement.depth < destinationElement.depth) {
				item.depth += (destinationElement.depth - sourceElement.depth);
			}
		});

		// drop elements at destinations index

		// find new index of destination element after splice
		// TODO: test using old computedElements instead of relooping through
		let destinationIndex = body.findIndex((element) => {
			if (destinationElement.id === element.id) {
				return true;
			}
			return false;
		});
		// if it has children and you are dragging below it, you need to calculate its children.
		if (destinationElement.hasChild && result.source.index < result.destination.index) {
			destinationIndex += getAllChildren(destinationElement).length;
		}

		reorderedItems[0].parentId = destinationElement.parentId;
		if (destinationIndex >= body.length) {
			body.push(...reorderedItems);
		} else if (destinationIndex === 0){
			body!.splice(destinationIndex, 0, ...reorderedItems);
		} else {
			// if dragging up (the animation shows the element moving below it)
			if (result.source.index > result.destination.index) {
				// put the element above the destination element
				body!.splice(destinationIndex, 0, ...reorderedItems);
			} else {
				// dragging down, put it below the destination element
				body!.splice(destinationIndex + 1, 0, ...reorderedItems);
			}
		}

		templateState.setElements(body!);
	}

	// grab a list of child elements, this is recursive!
	const getAllChildren = (rootElement: TemplateElement) => {
		const childElements = templateState.computedElements!.filter(item => item.parentId === rootElement.id);
		return [
			...childElements,
			...childElements.map(getAllChildren).reduce((all, current) => all.concat(current), [])];
	}

	// removing an element also removes its children
	const onRemoveElement = (element: TemplateElement) => {
		const elementsToRemove = [element, ...getAllChildren(element)].map(item => item.id);
		const filteredElements = templateState.elements!.filter(item => !elementsToRemove.includes(item.id));
		templateState.setElements(filteredElements);

		if(element.type === 'variable') {
			const filteredVariables = templateState.variables!.filter(item => item.id !== element.id);
			templateState.setVariables(filteredVariables);
		}

	}

	const onElementClicked = (element: TemplateElement) => {
		templateState.setSelectedElementById({id: element.id});
	}

	// apply search term
	const filterElements = (searchValue: string) => search(searchValue, templateState.computedElements);
	const findById = (id: string) => templateState.elements?.find(element => element.id === id);
	const findByType = (type: string) => templateState.elements?.filter(element => element.type === type);
	const setElements = (elements: TemplateElement[]) => templateState.setElements(elements);

	const childrenForElementWithId = (elementId: string): TemplateElement[] => {
		const elementIndex = templateState.elements?.findIndex(element => element.id === elementId) ?? -1;
		if (elementIndex < 0) {
			return [];
		}

		const element = templateState.elements![elementIndex];
		const childElements: TemplateElement[] = [];
		let index = elementIndex + 1;
		while(index < templateState.elements!.length && templateState.elements![index].depth > element.depth) {
			childElements.push(templateState.elements![index])
			index++;
		}

		return childElements
	}

	const createElement = (type: string): TemplateElement => {
		return {
			id: uuid.v4(),
			contents: {
			},
			type,
			index: 0,
			depth: 0
		}
	}

	const hasChildren = (element: TemplateElement) => templateState.elements?.some(e => e.parentId === element.id) ?? false;
	const isChild = (element: TemplateElement) => templateState.elements?.some(e => e.id === element.parentId) ?? false;
	return {
		addElement,
		changeElement,
		onRemoveElement,
		onElementClicked,
		onDragDrop,
		createElement,
		filterElements,
		hasChildren,
		isChild,
		getAllChildren,
		childrenForElementWithId,
		findById,
		findByType,
		elements: templateState.computedElements,
		setElements,
	}
}

export {useElements}