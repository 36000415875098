import { AppChromeContainer } from "./AppChrome.style";
import Masthead from "../../../shared/components/navigation/masthead";

export interface AppChromeProps {
	leftPane?: JSX.Element,
	center: JSX.Element,
	mastHeadComponent?: JSX.Element,
	mastHeadContextButtons?: JSX.Element,
	rightPane?: JSX.Element
}

const AppChrome = ({leftPane, center, rightPane, mastHeadComponent, mastHeadContextButtons} : AppChromeProps) => {
	return <>
		<Masthead customComponent={mastHeadComponent} contextButtons={mastHeadContextButtons}/>
		<AppChromeContainer>
			{leftPane && <div className='left_pane'>{leftPane}</div>}
			<div className="content_pane">
				{center}
			</div>
			{rightPane && <div className='right_pane'>{rightPane}</div>}
		</AppChromeContainer>
	</>
}

export {AppChrome};
