import {useTypedAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";
import {EntityRecord} from "../../../../shared/interfaces/Entity";

export type ZipFileLink = {
	link: string
}
function downloadZipApi(entity: EntityRecord): Promise<ZipFileLink> {
	return api.call("GET", `ectd/submission/${entity.id}/${entity.version ?? "HEAD"}/zip`);
}
function useDownloadZipApi() {
	return useTypedAsync(downloadZipApi, false);
}

export {useDownloadZipApi}