import { useAsync } from "../../../../shared/hooks";
import api from "../../../../api/api";
import { getTemplateSupers, SuperTemplate } from "../../utilities/getTemplateSupers";

function useDeleteTemplate() {

	const deleteTemplate = async (id: string): Promise<string> => {
		const supertemplates: SuperTemplate[] = await getTemplateSupers(id);
		if (supertemplates.length > 1) {
			throw(new Error('Unable to delete the selected template since it is included by more than one template.'));
		}

		await api.call('DELETE', `template/${id}`);
		// return the id so that deletion from local list is event driven
		return id;
	};


	return useAsync<string>(deleteTemplate, false);
}

export {useDeleteTemplate};
