import {TemplateElementDefinition} from "../model";
import {AbbreviationsIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import PreviewView from "./views/preview/PreviewView";
import {ReadonlyView} from "./views/ReadonlyView";
import {
	abbreviationsDefinition as baseAbbreviationsDefinition
} from "../../../../../shared/definitions/elements/abbreviations/model";


const abbreviationsDefinition: TemplateElementDefinition = {
	...baseAbbreviationsDefinition,
	icon: AbbreviationsIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		preview: PreviewView
	},
} as const

const abbreviationsValue = {
	label: "List",
	labelValue: "Default Abbreviations"
}

export {abbreviationsDefinition, abbreviationsValue};