import {ChangeEvent} from "react";
import {TextField} from "../../../../../shared/components/input";
import {colors} from "../../../../../theme";

export type SubmissionDetailsProps = {
	name: string,
	onNameChange: (name: string) => void
}

const SubmissionName = (props: SubmissionDetailsProps) => {
	const onChangeName = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => props.onNameChange(e.target.value);
	return <TextField InputProps={{style: {background: colors.paperWhite, borderColor: colors.fadedGrey}}} label={"Submission Name"} onChange={onChangeName} value={props.name} sx={{marginTop: "1rem"}} />
}

export {SubmissionName}