import {Box} from "@mui/material";
import {BarLoader} from "./BarLoader";

export interface AsyncComponentProps {
	isLoading: boolean,
	component: JSX.Element,
	loadingMessage?: string
	fullScreen?: boolean
}

const AsyncComponent = (props: AsyncComponentProps) => !props.isLoading ? props.component : <Box position={"relative"} height={"100%"} width={"100%"}>
	{props.component}
	{props.isLoading && <BarLoader fullScreen={props.fullScreen} message={props.loadingMessage}/>}
</Box>

export {AsyncComponent};