import { BaseElement } from "../../../interfaces/BaseElement";

const abbreviationsDefinition: BaseElement = {
	name: 'Template',
	displayName: 'Abbreviations List',
	type: 'abbreviations_list',
	description: 'Place Sub-Templates',
	contentDescription: () => '',
	allowsChildren: false,
	longDescription: '',
	toString: () => "(Preview Unavailable)"
} as const

export {abbreviationsDefinition};