// import CenterPane from '../list/centerPane/CenterPane';
import "./styles.scss";
import { AsyncComponent, FlexBox, MessageBox, MessageBoxActions, useStatusBar } from "../../../../shared/components";
import { ModuleLayout } from "../../../../shared/components/layout/ModuleLayout";
import { useEditor } from "./useEditor";
import { StatusLabel } from "../../../../shared/components/labels/StatusLabel";
import { VersionSelector } from "../../../../shared/components/menu/versionSelector";
import { EditorRightPane } from "../../components/panes/EditorRightPane";
import { EditorLeftPane } from "../../components/panes/EditorLeftPane";
import { moduleLinkGenerator } from "../../../moduleNavigation";
import { ContextButtonFactory, DefaultContextButtons } from "../../../../shared/components/buttons/theme";
import Masthead from "../../../../shared/components/navigation/masthead";
import { BufferNavigationState } from "../../../../shared/interfaces/BufferNavigationState";
import { useLocation } from "react-router-dom";
import { MessageBoxActionType } from "../../../../shared/components/modals/messageBox/models";
import { useRunWhenValueChange } from "../../../../shared/hooks";
import { EditorCenterPane } from "../../components/panes/EditorCenterPane";
import {useVariables} from "../../hooks/useVariables";
import {useState} from "react";
import {RequiredVariablesModal} from "../../components/tabs/variables/modals/RequiredVariablesModal";
import {Variable} from "../../../../shared/interfaces/Variable";
import {WorkflowCommentDialog,CancelRejectModal} from "../../../../shared/components/modals/WorkflowCommentDialog";
import {IttPromiseQueue} from "../../hooks/useIttPromiseQueue";

const DocumentEditor = (props: any) => {
	const [state, setState] = useState({showRequiredVariablesModal: false, hasShownRequiredVariablesModal: false, showAddVariablesMessage: false, shouldPublishOnAddVariables: false, hasShownTemplateUpgradeMessage: false});
	const pageManager = useEditor();
	const location = useLocation();
	const statusBar = useStatusBar();
	const variablesManager = useVariables();

	const navigationState = location.state as BufferNavigationState;

	const [cancelRejectModalState,setCancelRejectModalState] = useState<CancelRejectModal>({buttonTitle:"Default button",
		modalTitle:"Default modal"})

	useRunWhenValueChange(() => {
		if (pageManager.error) {
			statusBar.sendErrorNotification({ message: pageManager.error });
		}
	}, pageManager.error);

	useRunWhenValueChange(() => {
		if (!state.hasShownRequiredVariablesModal) {
			const requiredVariables = variablesManager.requiredVariablesWithNoValue();
			setState(s => ({...s, showRequiredVariablesModal: requiredVariables.length > 0}));
		}

		if (state.shouldPublishOnAddVariables) {
			pageManager.publishDocument();
			setState(s => ({...s, shouldPublishOnAddVariables: false}));
		}
	}, variablesManager.allVariables);


	const invertBufferMessageBox = () => pageManager.setBufferMessageBoxDisplay(s => !s);
	const invertWorkflowCommentModal = () => pageManager.setWorkflowCommentModalState(s => ({ ...s, display: !s.display }));
	const onWorkflowCommentModalAction = ({ comment }) => {
		pageManager.onWorkflowUpdate({ id: pageManager.workflowCommentModalState.pendingEventId, comment });
		invertWorkflowCommentModal();
	}

	const onBufferMessageBoxAction = (action: MessageBoxActionType) => {
		if (action === MessageBoxActions.Primary) {
			pageManager.setBufferFromDocument();
		}
		invertBufferMessageBox();
	}
	const onCloseAddVariablesMessage = () => setState(s => ({...s, showAddVariablesMessage: false}))
	const onOkAddVariablesMessageAction = (action: MessageBoxActionType) => setState(s => ({...s, showAddVariablesMessage: false, hasShownRequiredVariablesModal: action === "secondary", showRequiredVariablesModal: action === "primary", shouldPublishOnAddVariables: action === "primary"}))

	const onCloseRequiredVariablesModal = () => {
		setState(s => ({...s, showRequiredVariablesModal: false, hasShownRequiredVariablesModal: true, shouldPublishOnAddVariables: false}));
	}

	const onUpdateVariables = (variables: Variable[]) => {
		variablesManager.bulkUpdateVariables(variables);
		setState(s => ({...s, showRequiredVariablesModal: false, hasShownRequiredVariablesModal: true, shouldPublishOnAddVariables: false}));
	}

	// Upgrade Template message box handlers

	const onOkUpgradeTemplateMessageAction = (action: MessageBoxActionType) => {
		if (action === MessageBoxActions.Primary) {
			// User wants to upgrade

			pageManager.templateUpgrade();
		} else if (action === MessageBoxActions.Secondary) {
			// User canceled, don't show message box again

			setState({...state, hasShownTemplateUpgradeMessage: true});
		}

		// Always close message box

		onCloseUpgradeTemplateMessage();
	};

	const onCloseUpgradeTemplateMessage = () => {
		pageManager.setDisplayUpgradeTemplateMessageBox(false);
	};

	const onContextButtonClick = async (buttonKey: string) => {
		switch (buttonKey) {
			case DefaultContextButtons.Publish.key:
				if (variablesManager.requiredVariablesWithNoValue().length > 0) {
					setState(s => ({...s, showAddVariablesMessage: true}));
				} else {
					pageManager.publishDocument();
				}
				break;
			case DefaultContextButtons.Save.key:
				pageManager.saveBuffer();
				break;
			case DefaultContextButtons.View.key:
				await pageManager.downloadPDF();
				break;
			case DefaultContextButtons.Update.key:
				pageManager.templateUpgrade();
				break;
			case "fetch":
				pageManager.fetchDataSources();
				break;
			case DefaultContextButtons.RequestReview.key:
				pageManager.updateWorkflowIfRolePresent(
					pageManager.document?.permissions?.roles?.reviewers ?? [],
					'Please add reviewers to the content.',
					buttonKey
				);
				break;
			case DefaultContextButtons.CancelReview.key:
				pageManager.onWorkflowUpdate({ id: buttonKey });
				break;
			case DefaultContextButtons.CompleteReview.key:
				pageManager.onWorkflowUpdate({ id: buttonKey });
				break;
			case DefaultContextButtons.CancelApproval.key:
				setCancelRejectModalState({buttonTitle:"CANCEL & COMMENT",modalTitle:"Cancel Approval Workflow",placeholderTitle:"Reason For Cancellation"})
				pageManager.setWorkflowCommentModalState({ display: true, pendingEventId: buttonKey });
				break;
			case DefaultContextButtons.Reject.key:
				setCancelRejectModalState({buttonTitle:"REJECT & COMMENT",modalTitle:"Reject Approval Workflow",placeholderTitle:"Reason For Rejection"})
				pageManager.setWorkflowCommentModalState({ display: true, pendingEventId: buttonKey });
				break;
			case DefaultContextButtons.Approve.key:
				pageManager.onWorkflowUpdate({ id: buttonKey });
				break;
			case DefaultContextButtons.RequestApproval.key:
				pageManager.updateWorkflowIfRolePresent(
					pageManager.document?.permissions?.roles?.approvers ?? [],
					'Please add approvers to the content.',
					buttonKey
				);
				break;
		}
	}

	return (
		<AsyncComponent
			isLoading={pageManager.isLoading}
			loadingMessage={"Loading Content"}
			fullScreen
			component={<IttPromiseQueue.Provider>
				<FlexBox direction={"column"} height={"100%"} style={{ overflow: "auto" }}>
					<Masthead
						contextButtons={ContextButtonFactory(
							[...pageManager.contextButtons],
							onContextButtonClick)}
						customComponent={pageManager.document?.id && pageManager.document?.version ? <>
							<StatusLabel status={pageManager.document?.state ?? "In Process"} sx={{ mr: "10px" }} />
							<VersionSelector onSelectVersion={pageManager.onNavigateToVersion} type='document' id={pageManager.location.id} loaded={!pageManager.isLoading} versionId={pageManager.location.version ?? pageManager.document?.version} />
						</> : undefined}
						breadcrumbs={[{ title: "Content", url: moduleLinkGenerator("content", "list") }]}
					/>
					<ModuleLayout
						center={<AsyncComponent isLoading={pageManager.isUpdating} loadingMessage={"Updating content"} component={<EditorCenterPane />} />}
						left={<AsyncComponent isLoading={pageManager.isUpdating} loadingMessage={"Loading..."} component={<EditorLeftPane />} />}
						right={<AsyncComponent isLoading={pageManager.isUpdating} loadingMessage={"Loading..."} component={<EditorRightPane />} />} />
				</FlexBox>
				<MessageBox
					visible={pageManager.displayBufferMessageBox && !(navigationState?.isBuffer ?? false)}
					message={<>Unpublished changes were found for <b>{pageManager.document?.name}</b>. Do you want to resume where you left off?</>}
					title={"Hey! You've got changes."}
					note={'If you decline, saving new edits to this content will cause your old changes to be lost.'}
					primaryAction={"Yes, keep my changes"}
					secondaryAction={"No, start over"}
					onActionClick={onBufferMessageBoxAction}
					onClose={invertBufferMessageBox}
				/>
				<MessageBox
					visible={state.showAddVariablesMessage}
					message={<>There are required variables that don't yet have values. Please add values to those variables before publishing</>}
					title={"Hey! You've got missing variable values."}
					primaryAction={"OK, let me add them"}
					secondaryAction={"Cancel, I'll publish later"}
					onActionClick={onOkAddVariablesMessageAction}
					onClose={onCloseAddVariablesMessage}
				/>
				<MessageBox
					visible={!state.hasShownTemplateUpgradeMessage && pageManager.displayUpgradeTemplateMessageBox}
					message={<>An updated version of the template this content uses has been published. Do you want to upgrade to the new template?</>}
					title={"Hey! A new template version is available."}
					primaryAction={"OK, upgrade the template"}
					secondaryAction={"Cancel, I'll upgrade later"}
					onActionClick={onOkUpgradeTemplateMessageAction}
					onClose={onCloseUpgradeTemplateMessage}
				/>
				<WorkflowCommentDialog open={pageManager.workflowCommentModalState.display} onClose={invertWorkflowCommentModal} onConfirm={onWorkflowCommentModalAction} workflowButton={cancelRejectModalState} />

				{state.showRequiredVariablesModal && !state.hasShownRequiredVariablesModal && !pageManager.displayBufferMessageBox &&
					<RequiredVariablesModal variables={variablesManager.requiredVariablesWithNoValue()} onUpdateVariables={onUpdateVariables} onClose={onCloseRequiredVariablesModal} />}
			</IttPromiseQueue.Provider>}
		/>
	);

}

export { DocumentEditor };
