import { ContextButtonFactory, DefaultContextButtons } from '../../../shared/components/buttons/theme'
import { ModuleLayout } from "../../../shared/components/layout/ModuleLayout";
import { EditorLeftPane } from "../components/panes/EditorLeftPane";
import { EditorRightPane } from "../components/panes/EditorRightPane";
import { FlexBox, MessageBox, MessageBoxActions } from "../../../shared/components";
import { EditorCenterPane } from "../components/panes/center";
import { AsyncComponent } from "../../../shared/components";
import { useEditor } from "./useEditor";
import { useRunWhenValueChange } from "../../../shared/hooks";
import Masthead from "../../../shared/components/navigation/masthead";
import { VersionSelector } from "../../../shared/components/menu/versionSelector";
import { StatusLabel } from "../../../shared/components/labels/StatusLabel";
import { useStatusBar } from "../../../shared/components";
import { moduleLinkGenerator } from "../../moduleNavigation";
import { useState } from "react";
import { PreviewSlideOver } from "../components/modals/preview/PreviewSlideOver";
import { useElements } from "../hooks";
import { useVariables } from "../hooks/useVariables";
import { MessageBoxActionType } from "../../../shared/components/modals/messageBox/models";
import { useLocation } from "react-router-dom";
import { BufferNavigationState } from "../../../shared/interfaces/BufferNavigationState";
import { Variable } from "../../../shared/interfaces/Variable";
import {CancelRejectModal, WorkflowCommentDialog} from "../../../shared/components/modals/WorkflowCommentDialog";

const Editor = () => {
	const [isPreviewing, setIsPreviewing] = useState(false);
	const pageManager = useEditor();
	const elementsManager = useElements();
	const variablesManager = useVariables();
	const [confirmEditMode, setConfirmEditMode] = useState(false);
	const statusBarManager = useStatusBar();
	const location = useLocation();

	const navigationState = location.state as BufferNavigationState;

	const [cancelRejectModalState, setCancelRejectModalState] = useState<CancelRejectModal>({buttonTitle:"Default button",
		modalTitle:"Default modal"})

	useRunWhenValueChange(() => {
		if (pageManager.error) {
			statusBarManager.sendErrorNotification({ message: pageManager.error });
		}
	}, pageManager.error);

	const invertBufferMessageBox = () => pageManager.setBufferMessageBoxDisplay(s => !s);
	const invertPublishMessageBox = () => pageManager.setPublishMessageBoxDisplay(s => !s);
	const invertEditMessageBox = () => setConfirmEditMode(false);
	const invertWorkflowCommentModal = () => pageManager.setWorkflowCommentModalState(s => ({ ...s, display: !s.display }));
	const onWorkflowCommentModalAction = ({ comment }) => {
		pageManager.onWorkflowUpdate({ id: pageManager.workflowCommentModalState.pendingEventId, comment });
		invertWorkflowCommentModal();
	}

	const onBufferMessageBoxAction = (action: MessageBoxActionType) => {
		if (action === MessageBoxActions.Secondary) {
			pageManager.setBufferFromTemplate()
		}
		invertBufferMessageBox();
	}

	const onPublishMessageBoxAction = (action: MessageBoxActionType) => {
		if (action === MessageBoxActions.Primary) {
			pageManager.publish();
		}
		invertPublishMessageBox();
	}

	const onEditTemplateAction = (action: MessageBoxActionType) => {
		if (action === MessageBoxActions.Primary) {
			pageManager.onToggleEditMode();
		}

		invertEditMessageBox();
	}

	const onContextButtonClick = (id: string) => {
		switch (id) {
			case DefaultContextButtons.Save.key:
				pageManager.saveBuffer();
				break;
			case DefaultContextButtons.Publish.key:
				if (pageManager.isInReview || pageManager.hasBeenReviewed) {
					invertPublishMessageBox();
				} else {
					pageManager.publish();
				}
				break;
			case DefaultContextButtons.Edit.key:
				if (pageManager.isInEditMode) {
					pageManager.onToggleEditMode();
				} else {
					setConfirmEditMode(true);
				}
				break;
			case DefaultContextButtons.RequestReview.key:
				pageManager.updateWorkflowIfRolePresent(
					pageManager.template?.permissions?.roles?.reviewers ?? [],
					'Please add reviewers to the template.',
					id,
				);
				break;
			case DefaultContextButtons.Export.key:
				pageManager.export();
				break;
			case DefaultContextButtons.RequestApproval.key:
				pageManager.updateWorkflowIfRolePresent(
					pageManager.template?.permissions?.roles?.approvers ?? [],
					'Please add approvers to the template.',
					id,
				);
				break;
			case DefaultContextButtons.Approve.key:
			case DefaultContextButtons.CancelReview.key:
			case DefaultContextButtons.CompleteReview.key:
				pageManager.onWorkflowUpdate({ id });
				break;
			case DefaultContextButtons.CancelApproval.key:
				setCancelRejectModalState({ buttonTitle: "CANCEL & COMMENT", modalTitle: "Cancel Approval Workflow", placeholderTitle: "Reason For Cancellation" })
				pageManager.setWorkflowCommentModalState({ display: true, pendingEventId: id });
				break;
			case DefaultContextButtons.Reject.key:
				setCancelRejectModalState({ buttonTitle: "REJECT & COMMENT", modalTitle: "Reject Approval Workflow", placeholderTitle: "Reason For Rejection" })
				pageManager.setWorkflowCommentModalState({ display: true, pendingEventId: id });
				break;
			case 'preview':
				setIsPreviewing(!isPreviewing);
				break;
		}
	}

	const onClosePreview = () => setIsPreviewing(false);

	return <>
		<AsyncComponent
			fullScreen
			isLoading={pageManager.isLoading}
			loadingMessage={"Loading template"}
			component={<FlexBox direction={"column"} height={"100%"}>
				<Masthead
					contextButtons={ContextButtonFactory(
						pageManager.contextButtons,
						onContextButtonClick)}
					customComponent={pageManager.template?.id && pageManager.template?.version ? <>
						<StatusLabel status={pageManager.template?.state ?? "in process"} sx={{ mr: "10px" }} />
						<VersionSelector onSelectVersion={pageManager.onNavigateToVersion} type='template' id={pageManager.location.id} loaded={!pageManager.isLoading} versionId={pageManager.location.version ?? pageManager.template?.version} />
					</> : undefined}
					breadcrumbs={[{ title: "Templates", url: moduleLinkGenerator("template", "list") }, { title: pageManager.isInReview ? "Review Template" : "Edit Template" }]}
				/>
				<ModuleLayout
					center={<AsyncComponent isLoading={pageManager.isUpdating} loadingMessage={"Updating template"} component={<EditorCenterPane />} />}
					left={<AsyncComponent isLoading={pageManager.isUpdating} loadingMessage={"Loading..."} component={<EditorLeftPane />} />}
					right={<AsyncComponent isLoading={pageManager.isLoadingRightPane || pageManager.isUpdating} loadingMessage={"Loading..."} component={<EditorRightPane />} />} />
				<PreviewSlideOver
					elements={elementsManager.elements}
					variables={variablesManager.allVariables.map(v => ({...v.contents, id: v.id, namespace: v.template, templateId: v.templateId, description: v.description})) as Variable[]}
					open={isPreviewing}
					onClose={onClosePreview}
				/>
			</FlexBox>} />
		<MessageBox
			visible={pageManager.displayBufferMessageBox && !(navigationState?.isBuffer ?? false)}
			message={<>Unpublished changes were found for <b>{pageManager.template?.name}</b>. Do you want to resume where you left off?</>}
			title={"Hey! You've got changes."}
			note={'If you decline, saving new edits to this template will cause your old changes to be lost.'}
			primaryAction={"Yes, keep my changes"}
			secondaryAction={"No, start over"}
			onActionClick={onBufferMessageBoxAction}
			onClose={invertBufferMessageBox} />
		<MessageBox
			visible={pageManager.displayPublishMessageBox}
			message={<>Are you sure you want to publish a new version of <b>{pageManager.template?.name}</b>?</>}
			title={"Publish Template"}
			note={<><b>Note: </b>This will notify all contributors working on this template of your new changes.</>}
			primaryAction={"Publish my changes"}
			secondaryAction={"Keep editing"}
			onActionClick={onPublishMessageBoxAction}
			onClose={invertPublishMessageBox} />
		<MessageBox
			visible={confirmEditMode}
			message={<>Are you sure you want to edit <b>{pageManager.template?.name}</b>?</>}
			title={"Publish Template"}
			note={<><b>Note: </b>While in edit mode you will be unable to add comments.</>}
			primaryAction={"Yes, edit template"}
			secondaryAction={"Keep Viewing"}
			onActionClick={onEditTemplateAction}
			onClose={invertEditMessageBox} />
		<WorkflowCommentDialog open={pageManager.workflowCommentModalState.display} onClose={invertWorkflowCommentModal} onConfirm={onWorkflowCommentModalAction} workflowButton={cancelRejectModalState} />
	</>

}

export { Editor };