import { useState } from "react";
import { Button, FlexBox } from "../../../../../../../shared/components";
import { Variable } from "../../../../../../../shared/interfaces/Variable";
import { TemplateState } from "../../../../../hooks";
import { PropertyEditor } from "../../../../elements/reference/views/editor/PropertyEditor";
import { VariableEditor } from "../model";
import {ReferenceElementContents} from "../../../../../../../shared/definitions/elements/reference/model";
import {ElementPropertyData} from "../../../../elements/model";
import {displayNameForElementType} from "../../../../../../../shared/definitions/elements";

interface State {
	openModal: boolean,
	editItemProps: any,
	selectedReference: any
}
const initialState: State = {
	openModal: false,
	editItemProps: {},
	selectedReference: {}
}

interface EditorProps {
	variable: Variable,
	onChange: (variable: Variable, element: any) => void
}

const Editor = (props: EditorProps) => {
	// const referenceManager = useReference();
	const reference = props.variable.value as (ReferenceElementContents | undefined);
	const [state, setState] = useState<State>(initialState);
	const templateManager = TemplateState.useContainer();

	// event is the payload returned from the Modal that shows the elements users want to select for references
	const onApply = (event) => {
		props.onChange(props.variable, event.data.value);
		setState(s => ({ ...s, openModal: false }));
	}

	const clickOpen = () => {
		setState(s => ({ ...s, openModal: true }));
	}

	const closeDialog = () => {
		setState(s => ({ ...s, openModal: false }));
	}

	return (
		<>
			<FlexBox direction={"column"} className={"tm__preview--editors"}>
				<label className={"label"}>{props?.variable?.name}</label>
				{reference && <p className={"preview-value"}>Referenced Element Type: {reference.type ? displayNameForElementType(reference.type) : ""}</p>}
				<Button buttonType={"default"} text={"Choose Reference"} onClick={clickOpen} />
			</FlexBox>
			{state.openModal &&
				<PropertyEditor
					value={{data: props.variable} as ElementPropertyData}
					onClose={closeDialog}
					onApply={onApply}
					type={'reference'}
					variables={templateManager.variables}
					dataSourcesList={templateManager.datasources?.items} />
			}
		</>
	)

}

const ReferenceEditor: VariableEditor = {
	type: "reference",
	view: Editor
};

export { ReferenceEditor }