import { useStatusBar } from "../../../../../shared/components";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";
import {EntityRecord} from "../../../../../shared/interfaces/Entity";
import { useGetDocumentRenderPDF } from "../../api/useGetDocumentRenderPDF";

function useGetDocumentRenderPDFAction(): TypedAction<Required<EntityRecord>> {
	const getDocumentRenderPDF = useGetDocumentRenderPDF();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if(getDocumentRenderPDF.value !== null) {
			const link = document.createElement('a');
			link.href = getDocumentRenderPDF.value.link;
			link.download = "doc.pdf";
			link.click();

			statusBar.sendSuccessNotification({message: "Content PDF View Successful"});
		}
	}, getDocumentRenderPDF.value);

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification(
			{message: `Error creating PDF Content PDF View ${getDocumentRenderPDF.error}`});
	}, getDocumentRenderPDF.error)

	const run = ({id, version}: Required<EntityRecord>) =>
		getDocumentRenderPDF.execute({id, version});

	return {run, isRunning: getDocumentRenderPDF.isLoading};
}

export {useGetDocumentRenderPDFAction}