import { PreviewElementProps } from "../../model";
import { useGetTemplate } from "../../../../hooks/api/useGetTemplate";
import { useRunWhenValueChange } from "../../../../../../shared/hooks";
import { useMemo } from "react";
import { PreviewView as PreviewViewFactory } from "./../../PreviewView"
import { LoadingView } from "../../LoadingView";
import { TemplateElementValue } from "../../../../../../shared/definitions/elements/template/model";
import {mapBodyToElementValue} from "../../../../../../shared/utilities/data/mapBodyToElementValue";

const PreviewView = (props: PreviewElementProps) => {
	const getTemplate = useGetTemplate();
	const data = props.data as TemplateElementValue;
	useRunWhenValueChange(() => {
		if (data.id) {
			getTemplate.execute({id:data.id});
		}
	}, data.id);

	const previewItems = useMemo((): PreviewElementProps[] => {
		return getTemplate.value?.body ? mapBodyToElementValue(getTemplate.value!.body) : [];
	}, [getTemplate.value])

	return getTemplate.isLoading ?
		<LoadingView message={"Loading template element..."} /> :
		<>{previewItems.map(element => <PreviewViewFactory key={element.id} templateId={getTemplate.value?.id} noMargin={true} body={props.body ?? []} variables={props.variables} id={element.id} type={element.type} data={element.data} />)}</>
}

export { PreviewView };