import { useStatusBar } from "../components";
import { Action } from "../interfaces/ActionRun";
import { useInterval } from "./useInterval";
import { useRunWhenValueChange } from "./useRunWhenValueChange";

/**
 * Runs an action (auto save) on a 60 second interval
 * @param saveActionCallback: the save Action to run on an interval
 * @param shouldRun: function to determine if auto save should run
 */
function useEntityAutoSave(saveActionCallback: () => Action, shouldRun: () => boolean, interval = 60000) {
	const saveAction = saveActionCallback();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if (saveAction.isRunning) {
			statusBar.sendInfoNotification({ message: "Auto saving..." });
		}
	}, saveAction.isRunning);

	return useInterval(saveAction.run, interval, shouldRun);
}

export { useEntityAutoSave };
