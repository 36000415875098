import {Theme} from "@mui/material";
import {CustomPaletteColorOptions} from "../../theme/material-ui";

type InProcess = "in process";
type Review = "review";
type FinalReview = "final review";
type Approved = "approved";

export type WorkflowState = InProcess | Review | FinalReview | Approved;

type WorkflowStatesType = {
	InProcess: InProcess,
	Review: Review,
	FinalReview: FinalReview,
	Approved: Approved
}

export const WorkflowStates: WorkflowStatesType = {
	InProcess: "in process",
	Review: "review",
	FinalReview: "final review",
	Approved: "approved"
}

export function matchesWorkflows(workFlowState: string, statesToMatch: WorkflowState[]): boolean {
	if(!workFlowState) {
		return false;
	}

	const lowerCaseWorkFlowState = workFlowState.toLowerCase();
	return statesToMatch.some(state => state === lowerCaseWorkFlowState);
}

export function isInReview(workFlowState?: string): boolean {
	return matchesWorkflows(workFlowState ?? "", [WorkflowStates.Review, WorkflowStates.FinalReview]);
}

export function isApproved(workFlowState?: string): boolean {
	return matchesWorkflows(workFlowState ?? "", [WorkflowStates.Approved]);
}

export function paletteForWorkflowState(workFlowState: string, theme: Theme): CustomPaletteColorOptions | undefined {
	switch (workFlowState.toLowerCase()) {
		case WorkflowStates.InProcess:
			return theme.palette.documentState?.inProcess;
		case WorkflowStates.Review:
		case WorkflowStates.FinalReview:
			return theme.palette.documentState?.inReview;
		case WorkflowStates.Approved:
			return theme.palette.documentState?.approved;
		default:
			return theme.palette.documentState?.default;
	}
}