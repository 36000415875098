/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/client/data.mjs
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../lib/kaialpha';
import api from './api';
import cache_utils from '../lib/utils/cache_utils';
// @ts-check

/**
 * Get a list of available data "below" a certain path
 *
 * @param {KaiAlphaUserID} user_id? - User ID performing the fetch
 * @param {string} data_path? - Path to search
 * @returns {Promise<{items: { name: string, url: string}[] }>} Name URL to every datasource in the specified "data_path"
 */
export async function list_data(user_id = null, data_path = '/') {
	const results = await cache_utils.cache_promise('cache_data', data_path, async function() {
		const results = await api.call('GET', 'data');
		return(results);
	}, {
		cache_expires: 60 * 1000,
	});

	return(results);
}

const _to_export = {
	list_data
};
export default _to_export;
