import { Box, styled } from "@mui/material/";

export interface FlexBoxProps {
	direction?: "row" | "column" | "row-reverse",
	align?: "center" | "flex-start" | "flex-end" | "baseline" | "stretch",
	justify?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly"
	width?: string,
}

const FlexBox = styled(Box)(({align, justify, direction, width}: FlexBoxProps) => ({
	display: "flex",
	width: width ?? "100%",
	flexDirection: direction ?? "row",
	alignItems: align ?? "flex-start",
	justifyContent: justify ?? "flex-start"
}));

export {FlexBox};