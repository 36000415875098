import {useEntityStore} from "../../../shared/hooks/persistence/useEntityStore";
import {getTemplateApi} from "../../../shared/hooks/api/getTemplateApi";

export function useTemplateStore() {
	return useEntityStore<KaiAlphaTemplate>({
		cacheKey: "template-templatestore",
		cacheExpiryMinutes: 5,
		dependencies: {
			getEntity: getTemplateApi
		}
	})
}