import { TreeNode } from "../../../components/list/tree/TreeList.models";
import { BaseElement } from "../../../interfaces/BaseElement";

const footerDefinition: BaseElement = {
	name: 'Template',
	type: 'footer',
	displayName: 'Footer',
	description: '',
	allowsChildren: false,
	longDescription: '',
	contentDescription: (contents) => {
		if ('data' in (contents as TreeNode)) {
			return (contents as TreeNode).data?.value ?? "";
		}
		return (contents as FooterElementValue)?.value ?? "";
	},
	toString: (contents) => {
		if ('data' in (contents as TreeNode)) {
			return (contents as TreeNode).data?.value ?? "Empty Footer";
		}
		return (contents as FooterElementValue)?.value ?? "Empty Footer";
	}
} as const

export type FooterElementValue = {
	value?: string
}

export {footerDefinition};