import {VariableEditor, VariableEditorProps} from "./model";
import {RichTextEditor as RteEditor} from "../../../../../../shared/components/input/editor";
import {FlexBox} from "../../../../../../shared/components";
import "./style.scss";
import {Description} from "./DescriptionLabel";

const Editor = (props: VariableEditorProps) => {
	const value = props.variable.value as (string | undefined);
	const onChange = (event: {target: {value: string}}) => {
		if (props.onChange) {
			props.onChange(event.target.value);
		}
	}
	return <FlexBox direction={"column"} className={"cm__preview--editors"}>
		{props.variable.name && <label className={"label"}>{props.label ?? props.variable.name}</label>}
		<div className={"rte__wrapper"}>
			<RteEditor
				placeholder="Type here"
				onChange={onChange}
				defaultValue={value}
				variables={null}
			/>
		</div>
		<Description text={props.variable.description} />
	</FlexBox>
}

const RichTextEditor: VariableEditor = {
	type: "richtextarea",
	view: Editor
};

export {RichTextEditor};