import {TableElementValue} from "../../../../../../../shared/definitions/elements/table/model";
import {Variable} from "../../../../../../../shared/interfaces/Variable";
import {useState} from "react";
import {useRunWhenValueChange} from "../../../../../../../shared/hooks";
import {ConsumableDataSource} from "../../../../../../../shared/interfaces/DataSource";
import {getConsumableDataSources} from "../../../../../../../shared/utilities/data/getConsumableDataSources";


function useTablePreview(tableValue: TableElementValue, variables?: Variable[]) {
	const [data, setData] = useState<ConsumableDataSource[]>([])

	useRunWhenValueChange(() => {
		if(!variables) {
			return;
		}

		const consumableDataSources = getConsumableDataSources(tableValue.datasource, tableValue.footnotes ?? [], variables);
		setData(consumableDataSources);

	}, [tableValue, variables]);


	return {
		data
	}
}

export {useTablePreview};