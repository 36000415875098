import {VariableEditor, VariableEditorProps} from "../model";
import React from "react";
import {FlexBox} from "../../../../../../../shared/components";
import {TextArea} from "../../../../../../../shared/components/input/TextArea";

const Editor = (props: VariableEditorProps) => {
	const value = props.variable.value as (string | undefined);
	const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		props.onChange(props.variable, event.target.value);
	}
	return <FlexBox direction={"column"}>
		<TextArea label={props.variable.name} value={value ?? ""} onChange={onChange} />
		{props.variable.description && <label className={"description"}>{props.variable.description}</label>}
	</FlexBox>
}

const TextAreaEditor: VariableEditor = {
	type: "textarea",
	view: Editor
}

export {TextAreaEditor};