/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/lib/nunjucks_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const nunjucks = require('nunjucks');
const uuid = require('uuid');
const escape_string_regexp = require('escape-string-regexp');
const _testing = undefined;

// eslint-disable-next-line
let jsdom;

/** @type {null | {operators_help?: Object, operators?: string[], custom_filters?: string[], filters_help?: Object, filters?: string[], any?: string}} */
let constants = null;

/*
 * Idempotent filters can be re-used
 */
const filters = {
	keys: function(object) {
		if (object instanceof Object) {
			return(Object.keys(object));
		}

		return([]);
	},
	normalize_html_id: function(str) {
		return(str.replace(/[^A-Za-z0-9-]/g, '_'));
	},
	merge_datasources: function(tuple) {
		const a = tuple[0];
		const b = tuple[1];

		if (a.type !== b.type) {
			throw(new Error(`Unable to merge datasources of different types: ${a.type}, ${b.type}`));
		}

		const retval = {
			type: a.type
		}

		switch (a.type) {
			case 'columns-rows':
				{
					retval['data'] = {};

					const keys = new Set([...Object.keys(a.data), ...Object.keys(b.data)]);
					for (const key of keys) {
						retval.data[key] = {
							...a.data[key],
							...b.data[key]
						};
					}
				}
				break;
			case 'columns':
				retval.data = [
					...a.data,
					...b.data
				];
				break;
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${a.type}" not supported`));
		}

		return(retval);
	}
};

const map_abbreviations = function(text, shared_context, options) {
	if (!text) {
		return;
	}

	if (!options) {
		return(text);
	}

	if (!shared_context._abbreviations_state) {
		shared_context._abbreviations_state = {
			seen: {}
		};
	}

	const state = shared_context._abbreviations_state;

	const list_entries = options.abbr_list_entries;
	if (!list_entries) {
		return(text);
	}

	for (const {key, value} of list_entries) {
		/*
		 * Determine if this is the first use of the abbreviated words
		 */
		let first_use = true;
		if (state.seen[key] !== undefined) {
			first_use = false;
		}

		const value_re = new RegExp(`\\b${escape_string_regexp(value)}\\b`, 'gi');
		text = text.replace(value_re, `${key}`);

		/*
		 * If we have already seen the short name, do not bother
		 * trying to replace it.
		 */
		if (!first_use) {
			continue;
		}

		const key_re = new RegExp(`\\b${key}\\b`);

		const check_text_key_replace = text.replace(key_re, `${value} (${key})`);
		if (check_text_key_replace === text) {
			continue;
		}

		state.seen[key] = value;
		text = check_text_key_replace;
	}

	return(text);
}

/**
 * Returns a HTML DOM object for given html string
 * @param {string} html_string HTML string
 * @returns {Document} HTML Document
 */
function get_dom_from_string(html_string) {
	let document;
	if (jsdom) {
		// if here, this is running in nodejs during html/pdf generation
		document = new jsdom.JSDOM(`<!DOCTYPE html>${html_string}`).window.document;
	} else {
		// if here, this is running in browser during document rendering
		// we can use browser's native dom parser instead of 3rd party npm package
		const parser = new DOMParser();
		document = parser.parseFromString(html_string, "text/html");
	}
	return document;
}

/**
 * Trim leading and trailing HTML line breaks and empty block elements.
 * This is similar to String.trim(), but for html tags.
 * This is used to show HTML element merged with next element.
 * @param {string} html_string
 * @returns {string} trimmed HTML string
 */
function trim_html(html_string) {
	if (!html_string) {
		return html_string;
	}

	const document = get_dom_from_string(html_string);

	const child_elements = document.body.children;

	if (!child_elements?.length) {
		// if here this element may only have text and no html tags
		return html_string;
	}

	// remove leading empty spaces
	for (let i = 0; i < child_elements.length; i++) {
		const child_element = child_elements[i];
		// remove this element if there is no text or only has line breaks
		if (!child_element.innerHTML || /^<br[^>]*>$/.test(child_element.innerHTML)) {
			child_element.remove();
		} else {
			// if here, we removed all leading empty elements
			break;
		}
	}

	// remove trailing empty spaces
	for (let i = child_elements.length - 1; i >= 0; i--) {
		const child_element = child_elements[i];
		// remove this element if there is no text or only has line breaks
		if (!child_element.innerHTML || /^<br[^>]*>$/.test(child_element.innerHTML)) {
			child_element.remove();
		} else {
			// if here, we removed all trailing empty elements
			break;
		}
	}

	// return trimmed html
	return document.body.innerHTML;
}

/**
 * Merge <table> elements in given html string
 * @param {string} html_string
 * @returns {string} merged <table> html string
 */
function merge_table_tags(html_string) {
	if (!html_string) {
		return html_string;
	}

	const document = get_dom_from_string(html_string);

	const tables = document.querySelectorAll('table');

	if (!tables?.length) {
		// if here this element doesn't have any table tags
		return html_string;
	}

	let merged_table_bodies = '';
	for (let i = 0; i < tables.length; i++) {
		const table = tables[i];
		merged_table_bodies += table.innerHTML;
	}

	const first_table = tables[0];
	first_table.innerHTML = merged_table_bodies;
	return first_table.outerHTML;
}

// Adds all additional KaiAlpha filters
function addKaFilters(env, variables, options = {}) {
	options = {
		parseOnly: false,
		...options
	};

	/*
	 * The "shared context" is accessible to all filters (as a closure),
	 * but is not shared between renders of different documents.  This
	 * means it is an ideal place to stash data which must be slowly
	 * accumulated.
	 */
	let shared_context;
	if (options.shared_context !== undefined) {
		shared_context = options.shared_context;

	} else {
		shared_context = {};
	}

	if (shared_context.counters === undefined) {
		shared_context.counters = {};
	}

	if (shared_context.incr_counters === undefined) {
		shared_context.incr_counters = {};
	}

	if (shared_context.last_processed === undefined) {
		shared_context.last_processed = [];
	}

	const __ka_incr_input = function(key) {
		if (shared_context.incr_counters[key] === undefined) {
			shared_context.incr_counters[key] = -1;
		}

		shared_context.incr_counters[key]++;

		return(shared_context.incr_counters[key]);
	}

	const __ka_incr_id = function(key, type) {
		if (shared_context.counters[type] === undefined) {
			shared_context.counters[type] = {
				current: 0,
				entries: {}
			}
		}

		if (shared_context.counters[type].entries[key] === undefined) {
			shared_context.counters[type].current++;
			shared_context.counters[type].entries[key] = shared_context.counters[type].current;
		}

		return(shared_context.counters[type].entries[key]);
	};

	const local_filters = {};
	const local_globals = {};
	const local_functions = {};

	const addFilter = function(name, help, ...args) {
		if (name[0] !== '_') {
			local_filters[name] = help;
		}

		if (!env) {
			return;
		}

		return(env.addFilter(name, ...args));
	}

	const addGlobal = function(name, help, global, ...args) {
		if (name[0] !== '_') {
			if (global instanceof Function) {
				local_functions[name] = help;
			} else {
				local_globals[name] = help;
			}
		}

		if (!env) {
			return;
		}

		return(env.addGlobal(name, global, ...args));
	}

	// print string to the console - can be used for debugging template generation
	// ie    {{ x | console }}
	addFilter('__ka_console', 'Internal', function(...args) {
		kaialpha.log.log(...args)
	});

	addFilter('normalize_html_id', { help: 'Internal use only', example: ''}, filters.normalize_html_id);
	addFilter('__ka_incr_id', {help: 'Internal', example: ''}, __ka_incr_id);
	addFilter('__ka_incr_input', {help: 'Internal', example: ''}, __ka_incr_input);

	addGlobal('cite', {help: `Create a citation markup (e.g. cite('AA 2015')`, example: 'var | cite(var)'}, function(key) {
		const normalized_key = filters.normalize_html_id(key);
		const citation_id = __ka_incr_id(key, 'citation');
		return(`<sup><a href='#cite_${normalized_key}'>${citation_id}</a></sup>`);
	});

	addFilter('__ka_last_processed_push', {help: 'Internal', example: ''}, function(_ignored, element_id, element_body_b64) {
		shared_context.last_processed.push({
			element_id: element_id,
			element: JSON.parse(String(Buffer.from(element_body_b64, 'base64')))
		});
	});

	addFilter('__ka_last_processed_pop', {help: 'Internal', example: ''}, function(_ignored, _ignored_2) {
		/* XXX:TODO: Validate element ID */
		shared_context.last_processed.pop();
	});

	addGlobal('__ka_last_processed', {help: 'Internal', example: ''}, function() {
		const last = shared_context.last_processed.slice(-1)[0];
		return(JSON.stringify(last));
	});

	addGlobal('__ka_abbreviations_used', {help: 'Internal', example: ''}, function() {
		return(options.abbr_list_entries ? options.abbr_list_entries: []);
	})

	addGlobal('__ka_citations_used', 'Internal', function() {
		if (!shared_context.citation_map) {
			return([]);
		}

		const citation_map = shared_context.citation_map;

		const retval = [];
		for (const citation in citation_map) {
			retval.push({
				key: citation,
				value: citation_map[citation]
			});
		}

		return(retval);
	});

	addGlobal('__ka_load_citations', 'Internal', function(entries) {
		shared_context.citation_map = {};

		if (Object.keys(entries).length === 0) {
			return('');
		}

		for (const {key, value} of entries) {
			shared_context.citation_map[key] = value;
		}

		return('');
	});

	addFilter('__ka_map_abbrevations', 'Internal', function(input_text) {
		return(map_abbreviations(input_text, shared_context, options));
	});

	/**
	 * Applies `merge` options on HTML text
	 */
	addFilter('__ka_apply_merge_options', 'Internal', function (html_string, merge_options) {
		if (!html_string || !merge_options) {
			return html_string;
		}

		const { start, end, tables } = merge_options;

		// if no merge is required, return as is
		if (!start && !end && !tables) {
			return html_string;
		}

		// remove leading and trailing empty gaps in html
		html_string = trim_html(html_string);

		// merge all <table> html elements to single <table>
		if (tables === true) {
			html_string = merge_table_tags(html_string);
		}

		// if this element shouldn't be merged with previous element
		if (start !== true) {
			html_string = '<br/>' + html_string;
		}

		// if this element shouldn't be merged with next element
		if (end !== true) {
			html_string = html_string + '<br/>';
		}

		// return processed input text
		return html_string;
	});

	// Turn JSON object into string
	// Can be used for debugging, or doing things like adding extra info
	// in HTML comments, ie
	//    <!-- {{ myJsonObj }} -->
	// would include a dump of the JSON object  within a HTML page
	addFilter('stringify', {help: 'Convert an object into its JSON string representation', example: 'var | stringify'}, function (obj) {
		return JSON.stringify(obj);
	});

	if (options.parseOnly === true) {
		/* Dummy JSON parse which does not really care if the input is JSON */
		env.addFilter('json_parse', function (str) {
			if (str === undefined) {
				return([]);
			}

			if (str[0] === '[') {
				return([]);
			}

			if (str[0] === '{') {
				return({});
			}

			return({});
		});
	} else {
		addFilter('json_parse', {help: 'Parse a JSON string into an object', example: 'var | json_parse'}, function (str) {
			return JSON.parse(str)
		});
	}

	addFilter('reject', {help: 'Filter that removes all false-y values', example: ''}, function(items) {
		if (!items.filter) {
			return([]);
		}

		const retval = items.filter(function(item) {
			return !!item;
		});
		return(retval);
	});

	addFilter('__ka_setattr', 'Internal', function(object, key, value) {
		if (object !== undefined && object !== null) {
			object[key] = value;
		}
		return(object);
	});

	addFilter('keys', {help: 'Filter that takes in an object and converts it to an array of strings representing the names of the properties', example: '{var} | keys'}, filters.keys);

	addFilter('column', {help: 'Filter that gets all the values from a specific column', example: 'var | column("colName")'}, function (object, column) {
		if (object === undefined) {
			return([]);
		}

		if (Array.isArray(object)) {
			const values = object.map((el) => el[column])
			return(values);
		} else {
			const keys = Object.keys(object[column]);
			const result = keys.map((key) => object[column][key])
			return(result);
		}
	});

	addFilter('row', {help: 'Filter that gets all the values from a specific row', example: 'var | row("rowNum")'}, function (object, row) {
		if (object === undefined) {
			return([]);
		}

		if (Array.isArray(object)) {
			const keys = Object.keys(object[row]);
			const values = keys.map((key) => {
				return(object[row][key]);
			});
			return(values);
		} else {
			const keys = Object.keys(object);
			const result = keys.map((key) => {
				return(object[key][row]);
			});
			return(result);
		}
	});

	addFilter('extendlen', {help: 'Filter that accepts an array and produces a new array that has at least a certain number of elements', example: '[var] | extendlen'}, function (arr, len, val) {
		var rtn = arr.slice(0)
		for (let i = arr.length; i < len; i++) rtn.push(val)
		return rtn
	});

	addFilter('is_array', {help: 'Filter that returns a boolean if the input is an array', example: 'var | is_array'}, function(obj) {
		return Array.isArray(obj);
	});

	addFilter('flatten', {help: 'Filter that accepts an array or object and produces only the values', example: '[var] | flatten'}, function(base) {
		const retval = [];

		for (const key in base) {
			retval.push(base[key]);
		}

		return(retval);
	});

	addFilter('flatten_datasources', {help: 'Filter that takes in an array of datasources and produces a single combined datasource', example: '[var] | flatten_datasources'}, function(object, filter_type = undefined) {
		let result = {};
		for (const key of filters.keys(object)) {
			const datasource = object[key];

			if (filter_type !== undefined) {
				if (datasource.type !== filter_type) {
					continue;
				}
			}

			if (result.type === undefined) {
				result.type = datasource.type;
			} else {
				if (result.type !== datasource.type) {
					/* XXX:TODO: Continue or throw an error ? */
					continue;
				}
			}

			if (result.data === undefined) {
				result = datasource;
			} else {
				result = filters.merge_datasources([result, datasource]);
			}
		}

		return(result);
	});

	addFilter('array_slice', {help: 'Filter that accepts an array and produces a subset of that array', example: '[var] | array_slice'},  function(base, start = undefined, end = undefined) {
		if (Array.isArray(base)) {
			return(base.slice(start, end));
		}

		const new_array = [];

		for (const key in base) {
			new_array.push(base[key]);
		}

		return(new_array.slice(start, end));
	});

	addFilter('get_column', {help: 'Filter that accepts a datasource and produces a new object which contains the values from the named column', example: 'var | get_column("col")'}, function(base, column_name) {
		const retval = {};
		if (base === undefined) {
			return(retval);
		}

		const data = base.data;
		if (data === undefined) {
			return(retval);
		}

		switch(base.type) {
			case 'columns-rows':
				Object.assign(retval, {
					...data[column_name]
				});
				break;
			case 'columns':
				{
					let row_index = -1;
					for (const row of data) {
						row_index++;
						retval[row_index] = row[column_name];
					}
				}
				break;
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
		}
		return(retval);
	});

	addFilter('__ka_get_headers', 'Internal', function(table) {
		if (table === undefined) {
			return([]);
		}

		if (table['@metadata'] === undefined) {
			if (table.type === "columns" && table.data[0] !== undefined) {
				return Object.keys(table.data[0]);
			} else {
				return([]);
			}
		}

		if (table['@metadata']['headers'] === undefined) {
			return([]);
		}

		const headers = table['@metadata']['headers'];
		const ordered_headers = table['@metadata']['ordered_headers'];
		const original_headers = [];

		if (ordered_headers !== undefined) {
			for (const header of ordered_headers) {
				original_headers.push(headers[header]);
			}
		}

		return(original_headers);
	});

	addFilter('sort_rows', {help: 'Filter that sorts the rows in increasing/acsending or decreasing/descending order', example: 'var | sort_rows("colName", "asc", "int")'}, function(base, column_name, direction = 'asc', mode = 'human') {
		const retval = {
			type: 'columns',
			data: []
		};

		if (base === undefined) {
			return(retval);
		}

		const data = kaialpha.lib.object_utils.copy_object(base.data);
		if (data === undefined) {
			return(retval);
		}

		let reverse;
		switch (direction) {
			case 'asc':
			case 'ascending':
			case 'increasing':
				reverse = false;
				break;
			case 'desc':
			case 'descending':
			case 'decreasing':
				reverse = true;
				break;
			default:
				throw(new kaialpha.UserError(`Unsupported sort direction ${direction}, must be one of ascending, descending`));
		}

		const sort_function = function(a, b) {
			const a_cell = a[column_name];
			const b_cell = b[column_name];

			let a_val, b_val;
			let retval;
			switch(mode) {
				case "human":
					a_val = a_cell;
					b_val = b_cell;
					break;
				case "int":
					a_val = Math.trunc(Number(a_cell));
					b_val = Math.trunc(Number(b_cell));
					break;
				case "float":
					a_val = Number(a_cell);
					b_val = Number(b_cell);
					break;
				default:
					throw(new kaialpha.UserError(`Unsupported sorting mode ${mode}, must be one of human, int, float`));
			}

			if (a_val === b_val) {
				retval = 0;
			} else if (a_val < b_val) {
				retval = -1;
			} else {
				retval = 1;
			}

			if (reverse) {
				retval *= -1;
			}

			return(retval);
		}

		retval.type = base.type;
		switch(base.type) {
			case 'columns':
				retval.data = data.sort(sort_function);
				break;
			case 'columns-rows':
				/* XXX:TODO: Support sorting column*row data */
				// eslint-disable-next-line
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
		}
		return(retval);
	});

	addFilter('pivot_data', {help: "Create a pivoted table", example: "datasource | pivot_data('pivotColumnName', 'rowLabelsColumnName', 'columnLabelsColumnName', 'tupleValueColumnName')"}, function(base, pivotColumn, rowLabelsColumn, columnLabelsColumn, tupleColumn){
		const retval = {
			type: 'columns-rows',
			invalid: true,
			data: []
		};

		if (base === undefined) {
			return(retval);
		}

		const data = base.data ?? base[Object.keys(base)[0]].data;
		if (data === undefined) {
			return(retval);
		}

		const pivotValues = [...new Set(data.map(datum => datum[pivotColumn]))];
		const pivotedData = pivotValues.map(value => ({name: value, data: data.filter(datum => datum[pivotColumn] === value)}));
		const columns = [...new Set(data.map(datum => datum[columnLabelsColumn]))];

		const dataSources = [];
		pivotedData.forEach(dataSet => {
			const rowLabels = [...new Set(dataSet.data.map(datum => datum[rowLabelsColumn]))];
			const rawData = columns.reduce((lookup, current) => {
				lookup[current] = dataSet.data.filter(datum => datum[columnLabelsColumn] === current).map(datum => datum[tupleColumn]);
				return lookup;
			}, {})

			dataSources.push({name:dataSet.name, data: {
				__row: rowLabels,
				...rawData
			}})
		})

		return dataSources.reduce((combined, current) => {
			combined.data = Object.keys(combined.data).length === 0 ? current.data :
				Object.keys(combined.data).map(key => ({key, data: [...combined.data[key], ...current.data[key]]}))
					.reduce((lookup, current) => {
						lookup[current.key] = current.data;
						return lookup;
					}, {});
			return combined;
		}, {data: {}, type: "columns-rows", "@metadata": {ordered_headers: columns}});
	})

	addFilter('find_rows', {help: 'Finds all rows that match at least one of the given values in one of its cells', example: 'var | find_rows("col", ["value", "value1", "value2"])'}, function(base, column_name, column_value) {
		const use_expression_matches = true;

		/** @type {{ type: string, invalid?: boolean, data: any }} */
		const retval = {
			type: 'columns',
			invalid: true,
			data: []
		};

		if (base === undefined) {
			return(retval);
		}

		const data = base.data;
		if (data === undefined) {
			return(retval);
		}

		let cell_value_matches = function(cell_value, check_value) {
			if (String(cell_value) === String(check_value)) {
				return true;
			}

			return false;
		};

		if (use_expression_matches) {
			cell_value_matches = function(cell_value, check_value) {
				const check = compare_expressions('__cell_value', check_value, {
					'__cell_value': cell_value
				}, {
					rhs_quote_default: true
				});

				return(check);
			};
		}

		retval.type = base.type;
		delete retval['invalid'];

		const valuesToMatch = typeof column_value === "string" ? [column_value] : column_value;
		switch(base.type) {
			case 'columns-rows':
				{
					const matching_row_names = [];
					retval.data = {};

					for (const row_name in data[column_name]) {
						if (valuesToMatch.some(value => cell_value_matches(data[column_name][row_name], value))) {
							matching_row_names.push(row_name);
						}
					}

					for (const row_name of matching_row_names) {
						for (const check_column_name in data) {
							const cell_value = data[check_column_name][row_name];
							if (retval.data[check_column_name] === undefined) {
								retval.data[check_column_name] = {};
							}

							retval.data[check_column_name][row_name] = cell_value;
						}
					}
				}
				break;
			case 'columns':
				for (const row of data) {
					if (valuesToMatch.some(value => cell_value_matches(row[column_name], value))) {
						retval.data.push(row);
					}
				}
				break;
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
		}
		return(retval);
	});

	addFilter('includes', {help: 'Checks if a string includes another string as a substring', example: 'var | includes("test",var)'}, function(input, str) {
		if (!input || !input.includes) {
			return false;
		}

		return(input.includes(str));
	});

	addFilter('trim', {help: 'Remove leading or trailing whitespace', example: 'var | trim'}, function(input) {
		if (!input) {
			return(input);
		}

		if (!input.trim) {
			return(input);
		}

		return(input.trim());
	});

	addFilter('unique', {help: 'Returns all unique values of an array', example: '[var] | unique'}, function(input, options = {}) {
		options = {
			nocase: false,
			trim: false,
			noblank: false,
			...options
		};

		if (Array.isArray(input)) {
			const output_normalized= [];
			const output = [];
			for (const value of input) {
				let value_normalized = String(value);

				if (options.nocase) {
					value_normalized = value_normalized.toLowerCase();
				}
				if (options.trim) {
					value_normalized = value_normalized.trim();
				}

				if (options.noblank) {
					if (value_normalized.trim() === '') {
						continue;
					}
				}

				if (output_normalized.includes(value_normalized)) {
					continue;
				}

				output_normalized.push(value_normalized);
				output.push(value);
			}

			return(output);
		}

		/*
		 * For non-arrays, we currently throw an error, to leave open
		 * the option of expanding this in the future.
		 */
		throw(new kaialpha.UserError('Filter "unique" must only be called on arrays'));
	});

	addFilter('__ka_get_footnotes', 'Internal', function(footnotes, current_variables) {
		const footnote_values = [];

		if (!footnotes || !current_variables) {
			return(footnote_values);
		}

		/*
		 * Construct array of all footnote values from
		 * table elements and multi_input variables.
		 */
		for (const footnote of footnotes) {
			if (footnote.variable_name !== undefined && footnote.variable_name !== '') {
				const variable = footnote.variable_name.toLowerCase();

				if (current_variables[variable] !== undefined) {
					const variable_footnotes = current_variables[variable];

					for (const variable_footnote of variable_footnotes) {
						footnote_values.push(variable_footnote);
					}
				}
			} else {
				footnote_values.push(footnote);
			}
		}

		return(footnote_values);
	})

	addFilter('__ka_absolute_to_cell', 'Internal', function(footnote, table, options) {
		/* XXX:TODO: Update the way we are handling cell and absolute footnotes */
		const datasource_name = options.datasource;
		const footnote_value = footnote.value;
		const table_data = table.data;
		const absolute_substring = footnote_value.match(/absolute(.*)\)/);
		const match_coordinates = absolute_substring[0].match('\\(([^\\)]*)');

		let coordinates;
		let cleaned_coordinates;
		if (match_coordinates !== null) {
			coordinates = match_coordinates[1].split(',');

			/* Remove unneeded extra quotes from coordinates */
			coordinates[0] = coordinates[0].replace(/['']/g, '');
			coordinates[1] = coordinates[1].replace(/['']/g, '');

			/*
			 * Converted cleaned coordinates from String to Int and
			 * store them in an array
			 */
			cleaned_coordinates = [parseInt(coordinates[0]), parseInt(coordinates[1])]
		}

		/*
		 * Convert absolute references to cell references based
		 * on table type
		 */
		const updated_reference = footnote;
		let construct_value = footnote_value;
		if (table.type === 'columns-rows') {
			let row_coordinate = cleaned_coordinates[0];
			let column_coordinate = cleaned_coordinates[1];

			if (row_coordinate === 0) {
				column_coordinate = column_coordinate - 1;
				const cell_column = Object.keys(table_data)[column_coordinate];
				construct_value = `{{${datasource_name}.data | column('${cell_column}')}}`;
			} else if (column_coordinate === 0) {
				row_coordinate = row_coordinate - 1;
				const column_header = Object.keys(table_data)[0];
				const column = table_data[column_header];
				const cell_row = Object.keys(column)[row_coordinate];
				construct_value = `{{${datasource_name}.data | row('${cell_row}')}}`;
			} else {
				row_coordinate = row_coordinate - 1;
				column_coordinate = column_coordinate - 1;
				const cell_row = Object.keys(table_data)[row_coordinate];
				const selected_column = table_data[cell_row];
				const cell_column = Object.keys(selected_column)[column_coordinate];
				construct_value = `{{${datasource_name}.data['${cell_row}']['${cell_column}']}}`;
			}
		}

		if (table.type === 'columns') {
			let row_coordinate = cleaned_coordinates[0];

			if (row_coordinate === 0) {
				const selected_column = table_data[row_coordinate];
				const column_coordinate = cleaned_coordinates[1];
				const cell_column = Object.keys(selected_column)[column_coordinate];
				construct_value = `{{${datasource_name}.data | column('${cell_column}')}}`
			} else {
				row_coordinate = row_coordinate - 1;
				const selected_column = table_data[row_coordinate];
				const cell_column = Object.keys(selected_column)[row_coordinate];
				construct_value = `{{${datasource_name}.data['${row_coordinate}']['${cell_column}']}}`;
			}
		}

		/* Not implemented yet */
		if (table.type === 'rows') {
			throw(new Error('Unsupported type: rows'));
		}

		updated_reference.value = construct_value;
		return(updated_reference);
	});

	addFilter('absolute', {help: 'Get values cell values from data passed in as JSON', example: 'var | absolute("rowNum","colNum")'}, function(base, row, column) {
		const retval = [];

		if (!(base && base.data && base.type && row && column)) {
			return retval;
		}

		switch (base.type) {
			case 'columns-rows':
				/* Eg: Column-rows
				data : {a : {i : 1, ii:2}, b:{i:3, ii:4}}
				*/
				if (String(row) === '0') {
					/* As data is structured in a diffrent way, used Object.keys to generate header elements */
					const value = Object.keys(base.data)[column - 1];
					if (value) {
						retval.push(value);
					}
				} else if (String(row) !== '0' && String(column) === '0') {
					const column_object = base.data[Object.keys(base.data)[column]];
					const value = Object.keys(column_object)[row - 1];
					if (value) {
						retval.push(value);
					}
				} else {
					/* To get the specified column object, first get the specified column object
						eg : {i:3, ii:4}, in that get the value of the that particular row (ie. i or ii);
					*/
					const column_object = base.data[Object.keys(base.data)[column - 1]];
					const key = Object.keys(column_object)[row - 1];
					const value = column_object[key];
					if (value) {
						retval.push(value);
					}
				}
				break;
			case 'columns':
				/*
					Eg: data :[{A:1, B:2, C:3}];
				*/
				if (String(row) === '0') {
					const value = Object.keys(base.data[0])[column];
					if (value) {
						retval.push(value);
					}
				} else {
					const value = base.data[row - 1][Object.keys(base.data[row - 1])[column]];
					if (value) {
						retval.push(value);
					}
				}
				break
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
		}

		return retval;
	});

	addFilter('find_columns', {help: 'Finds all columns that match at least one of the given values in one of its cells', example: 'var | find_columns("rowName",["rowVal", "rowVal2"])'}, function(base, row_name, row_value) {
		/** @type {{ type: string, invalid?: boolean, data: any }} */
		const retval = {
			type: 'columns',
			data: []
		};

		if (base === undefined) {
			return(retval);
		}

		const data = base.data;
		if (data === undefined) {
			return(retval);
		}

		retval.type = base.type;
		const valuesToMatch = typeof row_value === "string" ? [row_value] : row_value
		switch (base.type) {
			case 'columns-rows':
				{
					const return_data = {};
					const matching_column_names = [];

					for (const column_name in data) {
						if (valuesToMatch.some(value => String(data[column_name][row_name]) === String(value))) {
							matching_column_names.push(column_name);
						}
					}

					for (const column of matching_column_names) {
						for (const column_values in data[column]) {
							if (return_data[column] === undefined) {
								return_data[column] = {};
							}
							return_data[column][column_values] = data[column][column_values];
						}
					}
					retval.data = return_data;
				}
				break;
			case 'columns':
				{
					const matching_column_names = [];
					for (const column_header in data[row_name]) {
						if (valuesToMatch.some(value => String(data[row_name][column_header]) === String(value))) {
							matching_column_names.push(column_header);
						}
					}

					for (const matching_column of matching_column_names) {
						for (const row of data) {
							const cell_value = { [matching_column]: row[matching_column] };
							retval.data.push(cell_value);
						}
					}
				}
				break;
			case 'rows':
				/* XXX:TODO: Support this when we add support for row-lar data */
				// eslint-disable-next-line
			default:
				throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
		}

		return(retval);
	});

	if (options.parseOnly === true) {
		addFilter('eval', {help: 'Renders a string with variable values', example: 'var | eval'}, function(_ignored_template) {
			return('');
		});
	} else {
		addFilter('eval', {help: 'Renders a string with variable values', example: 'var | eval'}, function(template) {
			if (template === undefined || template === null) {
				return(template);
			}

			const result = env.renderString(template, variables);

			return(result);
		});
	}

	return({
		env: env,
		globals: local_globals,
		functions: local_functions,
		filters: local_filters,
		shared_context: shared_context
	});
}

const NullLoader = nunjucks.Loader.extend({
	init: function() {},
	getSource: function() {
		return('');
	}
});

function createEnvironment(variables, options = {}) {
	const env = new nunjucks.Environment(new NullLoader('/'), { autoescape: false });
	addKaFilters(env, variables, options);
	return(env);
}

async function render(file, variables, options = {}) {
	const env = new nunjucks.Environment(new nunjucks.FileSystemLoader('/'), { autoescape: false });

	addKaFilters(env, variables, options);

	let retval;
	try {
		retval = env.render(file, variables);
	} catch (render_error) {
		if (options.parseOnly === true) {
			kaialpha.log.debug('Failed to parse:', file, '; error', render_error);
			return false;
		}

		const error_info_json = env.renderString('{{__ka_last_processed()}}');

		/*
		 * If the error could not be traced to an element, rethrow the error
		 */
		if (error_info_json === '') {
			throw(render_error);
		}

		const error_info = JSON.parse(error_info_json);

		throw(new kaialpha.NunjucksError(error_info, render_error));
	}
	if (options.parseOnly === true) {
		return true;
	}

	return(retval);
}

function renderString(template, variables, options = {}) {
	if (template === undefined || template === null) {
		return(template);
	}

	let env;
	if (options.parseOnly === true) {
		env = new nunjucks.Environment(new nunjucks.FileSystemLoader('/'), { autoescape: false });
	} else {
		env = new nunjucks.Environment(new NullLoader('/'), { autoescape: false });
	}

	addKaFilters(env, variables, options);

	let rendered;
	try {
		rendered = env.renderString(template, variables);
	} catch (render_error) {
		if (options.parseOnly === true) {
			kaialpha.log.debug('Failed to parse:', template, '; error:', render_error);

			return false;
		}

		throw(render_error);
	}

	if (options.parseOnly === true) {
		return true;
	}

	return(rendered);
}

async function parse(file) {
	return(await render(file, {}, {
		parseOnly: true
	}));
}

function validateString(template) {
	try {
		nunjucks.parser.parse(template);
	} catch (parse_error) {
		return false;
	}

	return true;
}

async function parseString(template) {
	return(renderString(template, {}, {
		parseOnly: true
	}));
}

/**
 * Render a string in a new environment without KaiAlpha tools loaded
 *
 * @param  {...any} args - Args (passed to Nunjuck's renderString())
 * @returns {string} Rendered result
 */
function renderStringNoKaiAlpha(...args) {
	const env = new nunjucks.Environment(new NullLoader('/'), { autoescape: false });

	return(env.renderString(...args));
}

function compute_expression_generator(expression) {
	/*
	 * If the expression is wrapped in Nunjucks expansion operator, remove
	 * that wrapping before evaluating (since we will add it back).
	 */
	if (expression.slice(0, 2) === '{{' && expression.slice(-2) === '}}') {
		expression = expression.slice(2, -2);
	}

	return(expression);
}

function compute_expression(expression, variables, options = {}) {
	options = {
		throw_errors: false,
		...options
	};

	expression = compute_expression_generator(expression);

	let result;
	try {
		result = renderString(`{{${expression}}}`, variables);
	} catch (render_error) {
		if (options.throw_errors === true) {
			throw(render_error);
		}

		result = '';
		kaialpha.log.debug('Failed to render expression:', expression, 'error:', render_error);
	}

	return(result);
}

function set_variable_generator(variable_name, expression, inline = false) {
	let retval;
	if (inline) {
		retval = `{%- set ${variable_name.toLowerCase()} = ${compute_expression_generator(expression)} -%}`;
	} else {
		retval = `{%- set ${variable_name.toLowerCase()} -%}{{${compute_expression_generator(expression)}}}{%- endset -%}`;
	}

	/*
	 * Variable names which begin with an underscore are local/private
	 */
	if (variable_name[0] !== '_') {
		retval += `{%- set __current = __current | __ka_setattr("${variable_name.toLowerCase()}", ${variable_name.toLowerCase()}) -%}`
	}

	return(retval);
}

function encode_object_generator(object) {
	return(`{{${JSON.stringify(JSON.stringify(object))} | json_parse}}`);
}

function set_variable_object_generator(variable_name, object) {
	return(set_variable_generator(variable_name, encode_object_generator(object), true));
}

function compare_expressions_generator(options = {}) {
	options = {
		lhs: undefined,
		rhs: undefined,
		rhs_quote_default: false,
		variable: undefined,
		bare_if: false,
		if_statement: 'if',
		...options
	};

	if (options.variable === undefined && options.lhs === undefined) {
		throw(new Error('Both parameters "variable" and "lhs" cannot be undefined, and they are'));
	}

	if (options.rhs === undefined) {
		throw(new Error('Parameter "rhs" must not be undefined'));
	}

	if (options.bare_if === true) {
		if (options.true !== undefined || options.false !== undefined) {
			throw(new Error('Parameters "true" and "false" are meaningless if "bare_if" is true'));
		}
	} else {
		if (options.true === undefined && options.false === undefined) {
			throw(new Error('Parameters "true" and "false" may not both be undefined'));
		}
	}

	const token = uuid.v4().replace(/-/g, "");

	if (options.variable === undefined) {
		options.variable = `__test_${token}`;
	}

	if (options.lhs !== undefined) {
		options.lhs_template = `{%- set ${options.variable} = ${compute_expression_generator(options.lhs)} -%}`;
	}

	const case_types = new RegExp('^(<=|>=|==|!=|~=|<|>)(.*$)');
	const rhs_matched = options.rhs.match(case_types);
	let op = '==';
	if (rhs_matched) {
		op = rhs_matched[1];
		options.rhs = rhs_matched[2];
	}
	options.op = op;

	/*
	 * If quoting is enabled by default and an operation is being performed
	 * where quoting makes sense, quote the RHS to treat it as a string.
	 */
	if (options.rhs_quote_default) {
		switch (op) {
			case '==':
			case '!=':
				/* XXX:TODO: This doesn't deal with RHS that have quotes in them already */
				options.rhs = `"${options.rhs}"`;
				break;
			default:
				/* Nothing to do by default */
				break;
		}
	}

	const rhs_template_parts = [];
	switch (op) {
		case '~=':
			rhs_template_parts.push(`{%- ${options.if_statement} (r/(${options.rhs})/).test(${options.variable}) -%}`);
			break;
		default:
			rhs_template_parts.push(`{%- ${options.if_statement} ${options.variable} ${op} ${options.rhs} -%}`);
			break;
	}

	if (options.bare_if !== true) {
		rhs_template_parts.push(options.true)
		rhs_template_parts.push('{%- else -%}');
		rhs_template_parts.push(options.false)
		rhs_template_parts.push('{%- endif -%}');
	}

	options.rhs_template = rhs_template_parts.join('');

	return(options);
}

function compare_expressions(lhs, rhs, variables, options = {}) {
	/*
	 * Ensure that variables is an object
	 */
	variables = {
		...variables
	};

	const template_info = compare_expressions_generator({
		...options,
		lhs: lhs,
		rhs: rhs,
		true: 'fe946d50-a7e1-48b2-8a4b-6228ed5bdf51'
	});
	const template = `${template_info.lhs_template}${template_info.rhs_template}`

	let result;
	try {
		result = renderString(template, variables);
	} catch (render_error) {
		kaialpha.log.debug('Failed to render expression for comparison:', {lhs, rhs}, 'error:', render_error);
	}

	if (result === 'fe946d50-a7e1-48b2-8a4b-6228ed5bdf51') {
		return true;
	}

	return false;
}

function valid_identifier(id) {
	if (id === undefined) {
		return false;
	}

	if (id === '') {
		return false;
	}

	if (id.match === undefined) {
		return false;
	}

	return id.match(/^\w+$/) !== null;

}

function get_variables_name_from_expression(expression) {
	const parsedExpression = nunjucks.parser.parse(expression);

	const possible_variable_names = _parseNodes(parsedExpression, []);
	const variable_names = possible_variable_names.filter(function(name) {
		return !constants.filters.includes(name);
	});
	return(variable_names);
}

function _parseNodes(node, expressionsArray) {
	if (!expressionsArray) {
		expressionsArray = [];
	}

	if (node.typename === 'LookupVal') {
		const usageExpr = _resolveLookupNode(node);

		expressionsArray.push(usageExpr.join('.'));

		return;
	}

	if (node instanceof nunjucks.nodes.NodeList) {
		nunjucks.lib.each(node.children, function (n) {
			_parseNodes(n, expressionsArray);
		});
	} else if (node instanceof nunjucks.nodes.CallExtension) {
		if (node.args) {
			_parseNodes(node.args, expressionsArray);
		}

		if (node.contentArgs) {
			nunjucks.lib.each(node.contentArgs, function (n) {
				_parseNodes(n, expressionsArray);
			});
		}
	} else if (node instanceof nunjucks.nodes.Value && node.typename === 'Symbol') {
		expressionsArray.push(node.value);
	} else {
		/** @type {null | Object} */
		let nodes = null;

		node.iterFields(function (val, field) {
			if (val instanceof nunjucks.nodes.Node) {
				nodes = nodes || {};
				nodes[field] = val;
			}
		});

		if (nodes) {
			for (const k in nodes) {
				_parseNodes(nodes[k], expressionsArray);
			}
		}
	}

	return expressionsArray;
}

function _resolveLookupNode(node, arr, skip) {
	if (!arr) {
		arr = []
	}

	if (skip === undefined || skip === null) {
		skip = { val: false };
	}

	if (node.typename === 'Symbol' || node.typename === 'Literal') {
		arr.push(node.value);
	} else if (node.typename === 'LookupVal') {
		const subEle = node.val.value;

		if (!skip.val) {
			_resolveLookupNode(node.target, arr, skip);

			if (subEle && !skip.val) {
				arr.push(subEle);
			} else {
				skip.val = true;
			}
		}
	}

	return arr;
}

const customFilters = addKaFilters().filters;

constants = {
	filters_help: {
		'abs' : {
			help: 'Get Absolute Value of an argument',
			example: 'var | abs'
		},
		'batch' : {
			help: 'Return list with given number of items',
			example: '[var] | batch(n)'
		},
		'capitalize' : {
			help: 'Make first letter capital, the rest lower case',
			example: '"var" | capitalize'
		},
		'center' : {
			help: 'Center value in field of a given width',
			example: 'var | center'
		},
		'dictsort' : {
			help: 'Sort a dict and yield (key,value) pairs',
			example: '{var} | dictsort'
		},
		'dump' : {
			help: 'Call stringify on Object and dump results into template',
			example: '[var] | dump'
		},
		'escape' : {
			help: 'Convert special characters in strings to HTML-safe sequences',
			example: '"<html>" | escape'
		},
		'first' : {
			help: 'Get first item in an array or first letter of string ',
			example: '[var] | first'
		},
		'float' : {
			help: 'Convert a value into a floating point number',
			example: 'var | float'
		},
		'indent' : {
			help: 'Indent a string using spaces',
			example: 'var | indent'
		},
		'groupby' : {
			help: 'Group a sequence of objects by a common attribute',
			example: '[{var}] | groupby("key")'
		},
		'int' : {
			help: 'Convert value to integer',
			example: 'var | int'
		},
		'join' : {
			help: 'Return a string which is the concatenation of strings in a sequence',
			example: '[var] | join'
		},
		'last' : {
			help: 'Get last item in array or string',
			example: '[var] | last'
		},
		'length' : {
			help: 'Return the length of an array or string, or number of keys in an object',
			example: '[var] | length'
		},
		'list' : {
			help: 'Convert value into a list',
			example: '"var" | list'
		},
		'lower' : {
			help: 'Convert string to all lower case',
			example: '"var" | lower'
		},
		'nl2br' : {
			help: 'Replace new lines with html br element',
			example: '"var\nvar" | striptags(true) | escape | nl2br'
		},
		'random' : {
			help: 'Select a random value from array',
			example: '[var] | random'
		},
		'reject' : {
			help: 'Filter a sequence applying a test to each object and rejecting the objects with the test succeeding',
			example: '[var] | reject("odd") | join'
		},
		'rejectattr' : {
			help: 'Filter a sequence of objects by applying a test and rejecting the objects with test succeeding',
			example: '[{var}] | rejectattr("test")'
		},
		'replace' : {
			help: 'Replace one item with another.',
			example: 'var | replace("",".")'
		},
		'reverse' : {
			help: 'Reverse a string',
			example: 'var | reverse'
		},
		'round' : {
			help: 'Round a number',
			example: 'var | round'
		},
		'safe' : {
			help: 'Mark value as safe from automatic escapes',
			example: 'var | urlize | safe'
		},
		'select' : {
			help: 'Filter sequence of objects and only selecting objects with test succeeding',
			example: '[var] | select("odd")'
		},
		'selectattr' : {
			help: 'Filter a sequence of objects by applying a test to the specified attribute of each object, selecting the objects with the test succeeding',
			example: '[{var}] | selectattr("test")'
		},
		'slice' : {
			help: 'Slice an iterator and return a list of lists containing items',
			example: '[var] | slice(n)'
		},
		'sort' : {
			help: 'Sort with Javascripts arr.sort function',
			example: 'var | string | list'
		},
		'string' : {
			help: 'Convert an object to a string',
			example: 'var | string'
		},
		'striptags' : {
			help: ' Analog of jinjas striptags',
			example: '[var] | striptags(var,preserve_linebreaks)'
		},
		'sum' : {
			help: 'Output the sum of items in an array',
			example: '[var] | sum'
		},
		'title' : {
			help: 'Make the first letter of the string uppercase',
			example: '"var" | title'
		},
		'trim' : {
			help: 'Strip leading and trailing whitespace',
			example: '" var " | trim'
		},
		'truncate' : {
			help: 'Return a truncated copy of string. Specifying length',
			example: '"var" | truncate(n)'
		},
		'upper' : {
			help: 'Convert the string to upper case',
			example: '"var" | upper'
		},
		'urlencode' : {
			help: 'Escape strings for use in URLS',
			example: '"var" | urlencode'
		},
		'urlize' : {
			help: 'Convert URLs in plain text into clickable links',
			example: '"var" | urlize | safe'
		},
		'wordcount' : {
			help: 'Count and output number of words in a string',
			example: '"var" | wordcount'
		},
		...customFilters
	},
	operators_help: {
		'|': 'The pipe operator passes data from an expression to a filter',
		'+': 'The addition operator will perform concatencation or arithematic addition depending on the type of the operands',
		'-': 'The subtraction operator will perform arithmetic subtraction on two operands',
		'/': 'The division operator will perform arithmetic division on two operands',
		'//': 'The floor division operator will perform arithmetic division on two operands and rounds down to return the whole number value of the result',
		'%': 'The remainder operator returns the integer remainder of dividing two operands',
		'*': 'The multiplication operator performs arithmetic multiplication on two operators',
		'**': 'The expoentation operator calculates the base to the exponent power (base ^ exponent)',
		'==': 'The equal operator returns true if operands are equal',
		'===': 'The strict equal operator returns true if operands are equal and are of the same type',
		'!=': 'The not equal operator returns returns true if operands are not equal',
		'!==': 'The strict not equal operator returns returns true if operands are not equal and are not of the same type',
		'>': 'The greater than operator returns true if the left operand is greater than the right operand',
		'>=': 'The greater than operator returns true if the left operand is greater than or equal to the right operand',
		'<': 'The greater than operator returns true if the left operand is less than the right operand',
		'<=': 'The greater than operator returns true if the left operand is less than or equal to the right operand',
		'~=': 'The regular expression operator returns true if the regular expression in the right operand matches the left operand'
	},
	any: 'any_a3d9a401ff07cff669c0ca18636b3fec'
};

constants.operators = Object.keys(constants.operators_help);
constants.custom_filters = Object.keys(customFilters);
constants.filters = Object.keys(constants.filters_help);

const _to_export_auto = {
	createEnvironment,
	render,
	renderString,
	parse,
	parseString,
	validateString,
	filters,
	noKaiAlpha: {
		renderString: renderStringNoKaiAlpha
	},
	compute_expression,
	compare_expressions,
	compute_expression_generator,
	compare_expressions_generator,
	set_variable_generator,
	set_variable_object_generator,
	encode_object_generator,
	valid_identifier,
	get_variables_name_from_expression,
	map_abbreviations,
	constants,
	_testing
};
export default _to_export_auto;
