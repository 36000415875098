import {EmptyGroupContainer} from "./GroupedContent.style";
import {CenteredContainer} from "../CenteredContainer";

export interface EmptyGroupProps {
	title: string,
}

const EmptyGroup = ({title}: EmptyGroupProps) =>  <EmptyGroupContainer>
	<CenteredContainer>
		<span style={{textAlign: "center"}}>{title}</span>
	</CenteredContainer>
</EmptyGroupContainer>


export {EmptyGroup};