export const measurementMap = {
	sideBarOpenedWidth: '240px',
	sideBarClosedWidth: '65px',
};

const componentMetricsMap = {
	IdentityBar: {
		right: '2rem',
		top: '2rem',
		size: '93px'
	},
	AppFooter: function(theme) {
		return({
			size: '0.5rem',
			padding: '0.5rem'
		});
	},
	AppChrome: function(theme) {
		const idBarMetrics = getComponentMetrics('IdentityBar', theme);
		return({
			innerPadding: theme.typography.pxToRem(20),
			outerPaddingTop: `calc(${idBarMetrics.size} + (2 * ${idBarMetrics.top}))`,
			outerPaddingBottom: theme.typography.pxToRem(33),
			borderRadius: theme.typography.pxToRem(44)
		});
	},
	StatusBar: function(theme) {
		return({
			outerPadding: theme.typography.pxToRem(33),
			spacing: '0.75rem',
			height: '2rem',
			width: '15rem'
		});
	},
	UI_CloseIcon: function(theme) {
		return({
			size: theme.typography.pxToRem(20)
		});
	},
	VersionSelector: function(theme) {
		const idBarMetrics = getComponentMetrics('IdentityBar', theme);
		const id_bar_margin_top = idBarMetrics.top;
		const id_bar_margin_right = idBarMetrics.right;
		const id_bar_width = idBarMetrics.size;
		return({
			position: 'fixed',
			top: `calc(1.5* ${id_bar_margin_top})`,
			right: `calc((2 * ${id_bar_margin_right}) + ${id_bar_width})`,
		});
	},
	StatusContainer: function(theme) {
		const versionSelectorMetrics = getComponentMetrics('VersionSelector', theme);
		const versionSelector_right = versionSelectorMetrics.right;

		return({
			...versionSelectorMetrics,
			right: `calc(3 * ${versionSelector_right})`
		});
	}
};

export function getComponentMetrics(component, theme) {
	let metrics_info = componentMetricsMap[component];

	if (metrics_info instanceof Function) {
		if (theme === undefined) {
			console.debug('No theme provided when looking up component', component, 'metrics, but it is required.  Look up occurs', new Error('here'));
		}

		metrics_info = metrics_info(theme);
	}

	if (!metrics_info) {
		metrics_info = {};
	}

	return(metrics_info);
}

const _to_export = {
	measurementMap,
	getComponentMetrics
};

export default _to_export;
