import {isCreateResponse, isLegacyCreateResponse, useCreateSubmission} from "../../api/useCreateSubmission";
import {Submission} from "../../../models/Submission";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {useStatusBar} from "../../../../../shared/components";
import {SubmissionState} from "../../useSubmissionState";
import {useNavigate} from "react-router-dom";
import {moduleLinkGenerator} from "../../../../moduleNavigation";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

function useCreateSubmissionAction(): TypedAction<Submission> {
	const createSubmissionApi = useCreateSubmission();
	const {submission, setSubmission} = SubmissionState.useContainer();
	const statusBar = useStatusBar();
	const navigate = useNavigate();

	useRunWhenValueChange(() => {
		if (createSubmissionApi.value) {
			statusBar.sendSuccessNotification({message: "Submission created!"});
			const legacyResponse = isLegacyCreateResponse(createSubmissionApi.value!) ? createSubmissionApi.value : null;
			const createResponse = isCreateResponse(createSubmissionApi.value!) ? createSubmissionApi.value : null;

			setSubmission(s => ({...s, id: legacyResponse?.contentId ?? createResponse!.id, version: legacyResponse?.versionId ?? createResponse!.version}))
		}
	}, createSubmissionApi.value);

	useRunWhenValueChange(() => {
		if (submission.id) {
			navigate(moduleLinkGenerator("submission", "edit", submission.id!, submission.version!));
		}
	}, submission?.id)

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error creating submission", detail: createSubmissionApi.error});
	}, createSubmissionApi.error);

	const run = (submission: Submission) => {
		if (submission.name.length === 0) {
			submission.name = "Untitled Submission";
		}

		createSubmissionApi.execute(submission);
	}

	return {
		run,
		isRunning: createSubmissionApi.isLoading
	}
}

export {useCreateSubmissionAction}