import {ensureArgs} from "../filterUtilities";
import {isArrayWithLength} from "../../../guards/isArray";
import {isNumber} from "../../../guards/isNumber";
import {NunjucksFilterFactoryProps} from "../model";

function extendLength(...args: unknown[]): unknown {
	if (!ensureArgs(args, 3)) {
		return [];
	}

	const length = args[1];
	const val = args[2] as any;

	if (!isArrayWithLength(args[0]) || !isNumber(length)) {
		return []
	}

	const newArray = [...args[0]];
	for (let i = newArray.length; i < length; i++) {
		newArray.push(val);
	}
	return newArray;
}

export function extendLengthFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "extendlen",
		help: "Filter that accepts an array and produces a new array that has at least a certain number of elements",
		example: "var | extendlen(5, '')",
		execute: extendLength
	})
}