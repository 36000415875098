import { createContainer } from "unstated-next";
import React, { useEffect, useState } from "react";
import { useRunWhenValueChange } from "../../../shared/hooks";
import { DataSourcesType } from "./api/useGetDataSources";
import { VariableElement } from "../models/elements";
import { UserManager } from "../../../shared/hooks/useUser";
import { isInReview } from "../../../shared/interfaces/WorkflowState";
import { hasAccess, Role, NegatedRole, computeUserRoles, hasRoles, Roles } from "../../../shared/interfaces/Permissions";
import { CommentPayload } from "../components/modals/templateComments/CommentModal.models";
import { TemplateElementValue } from "../../../shared/definitions/elements/template/model";
import {TemplateElement} from "../../../shared/interfaces/TemplateElement";
import { parseTemplateElements } from "../../../shared/utilities/parseTemplateElements";
import {computeElements} from "../utilities/elementsFactory";

interface UseTemplateState {
	template: KaiAlphaTemplate | null,
	buffer: KaiAlphaTemplate | null,
	bufferExists: boolean,
	// the elements parsed from the current buffer.
	elements: TemplateElement[] | null,
	selectedElement: ElementScroll | null,
	variables: VariableElement[],
	computedElements: TemplateElement[],
	templateElements: {
		all: {
			[k: string]: TemplateElementValue,
		}
		upgradable: {
			[k: string]: TemplateElementValue,
		},
	} | null,
	datasources: DataSourcesType | null,
	comments: CommentPayload[] | null,
	computedUserRoles: Role[] | null,
	// computed property to determine if user can
	// edit the template based on their permissions.
	editable: boolean,
	// flag to state whether template is explicitly in an edit mode
	isInEditMode: boolean,
	// computed property to determine if user can
	// comment on the template based on their permissions.
	commentable: boolean,
	didReset: boolean,
	previewMode: boolean
}

export type TemplateSetAction = (templateState: TemplateMutator | KaiAlphaTemplate) => void;
export type BufferSetAction = (templateState: TemplateMutator | KaiAlphaTemplate, isSetFromTemplate?: boolean) => void;
interface ElementScroll {
	id: string
}

const initialState: UseTemplateState = {
	template: null,
	buffer: null,
	bufferExists: false,
	elements: null,
	selectedElement: null,
	variables: [],
	computedElements: [],
	templateElements: null,
	datasources: null,
	computedUserRoles: [],
	editable: false,
	isInEditMode: true,
	commentable: false,
	comments: [],
	didReset: true,
	previewMode: false
};

// template mutator takes a template in and returns a template
type TemplateMutator = (t: KaiAlphaTemplate) => KaiAlphaTemplate

// same as above, but for permissions
type PermissionsMutator = (p: KaiAlphaTemplatePermissions) => KaiAlphaTemplatePermissions

/**
 * This hook enables the loading/updating/creation of templates
 */
function useTemplate() {
	const [state, setTemplateState] = useState(initialState);
	const user = UserManager.useContainer();

	const setState: React.Dispatch<React.SetStateAction<UseTemplateState>> = (value: (((prevState: UseTemplateState) => UseTemplateState) | UseTemplateState)) => {
		if (typeof (value) === "object") {
			setTemplateState(value);
		} else {
			setTemplateState(prevState => ({ ...value(prevState), didReset: false }));
		}
	}

	useRunWhenValueChange(() => {
		if (state.buffer && state.elements === null) {
			const elements = parseTemplateElements(state.buffer);
			//const variables = elements.filter(element => element.type === "variable").map(variable => variable as VariableElement);
			setState(s => ({ ...s, elements }));
		}
	}, state.buffer);

	useEffect(() => {
		if (state.elements) {
			const computedElements = computeElements([...state.elements!]);
			setState(s => ({ ...s, computedElements }));
		}
	}, [state.elements])

	// calculations on processed elements
	// determining if there are templates to upgrade, etc.
	useEffect(() => {
		if (state.computedElements) {
			const templateElements = state.computedElements.reduce((stateObject, element) => {
				if (element.type !== "template") {
					return stateObject;
				}

				const templateContent = element.contents as TemplateElementValue;
				if (templateContent.latest_approved_version && templateContent.latest_approved_version !== templateContent.version) {
					stateObject.upgradable[`${templateContent.id!}-${templateContent.version!}`] = templateContent;
				}
				stateObject.all[`${templateContent.id!}-${templateContent.version!}`] = templateContent;
				return stateObject;
			}, { upgradable: {}, all: {} });

			setState(s => ({ ...s, templateElements }));

		}
	}, [state.computedElements])

	useRunWhenValueChange(() => {
		if (state.template?.state) {
			setState(s => ({ ...s, isInEditMode: !isInReview(state.template!.state) }));
		}
	}, state.template?.state);

	/**
	 * set template state
	 * @param templateState {KaiAlphaTemplate | TemplateMutator}
	 */
	const setTemplate: TemplateSetAction = (templateState: TemplateMutator | KaiAlphaTemplate) => {
		const template = typeof (templateState) === "object" ? templateState : templateState(state.template!);
		if (template) {
			setState(s => ({ ...s, template }));
		}
	}

	/**
	 * set buffer state
	 * @param templateState {KaiAlphaTemplate | TemplateMutator}
	 */
	const setBuffer: BufferSetAction = (templateState: TemplateMutator | KaiAlphaTemplate, isSetFromTemplate: boolean = false) => {
		const buffer = state.buffer ?? state.template;
		const newBuffer = typeof (templateState) === "object" ? templateState : templateState(buffer!);
		if (newBuffer) {
			setState(s => ({ ...s, buffer: newBuffer, bufferExists: !isSetFromTemplate}));
		}
	}

	/**
	 * sets permissions state on buffer
	 * @param permissionsState {KaiAlphaTemplatePermissions | PermissionsMutator}
	 */
	const setPermissions = (permissionsState: PermissionsMutator | KaiAlphaTemplatePermissions) => {
		const buffer = state.buffer;
		if (!buffer) {
			throw new Error('Permissions cannot be set on a null buffer.');
		}
		const permissions = typeof (permissionsState) === "object" ? permissionsState : permissionsState(buffer!.permissions);
		if (permissions) {
			setState(s => ({
				...s,
				buffer: {
					...buffer,
					permissions,
				}
			}));
		}
	}

	const setDatasources = (datasources: DataSourcesType | null) => setState(s => ({ ...s, datasources }));
	// update computed userRoles on permissions change.
	useRunWhenValueChange(() => {
		if (state.template === null) {
			return;
		}
		setState(s => ({
			...s,
			computedUserRoles: computeUserRoles(user, state.template!.permissions),
		}));
	}, state.template?.permissions);

	const setElements = (elements: TemplateElement[] | null) => {
		setState(prevState => ({ ...prevState, elements }))
	}

	const setSelectedElementById = (selectedElement: ElementScroll) => {
		setState(s => ({ ...s, selectedElement }));
	}

	const setVariables = (variables: TemplateElement[]) => {
		setState(prevState => ({ ...prevState, variables }))
	}

	const setPreviewMode = (previewMode: boolean) => {
		setState(prevState => ({ ...prevState, previewMode }));
	}


	// permissions/visibility calculations
	useRunWhenValueChange(() => {
		if (state.computedUserRoles === null) {
			setState(s => ({
				...s,
				editable: false,
				commentable: false,
			}));
			return;
		}
		// if user has general write access
		if(hasAccess(state.computedUserRoles as Role[], state.template?.permissions.acl?.write as (Role & NegatedRole)[] ?? [])|| hasRoles(state.computedUserRoles, [Roles.Owner])) {
			setState(s => ({
				...s,
				editable: state.isInEditMode,
			}));
		} else {
			setState(s => ({
				...s,
				editable: false,
			}));
		}
		// if user has comment write access
		if (hasAccess(state.computedUserRoles as Role[], state.template?.permissions.acl?.["comments:write"] as (Role & NegatedRole)[] ?? [])
			// for case when you are a completed reviewer, but also an owner/author??
			|| hasRoles(state.computedUserRoles, [Roles.Owner, Roles.Author])) {
			setState(s => ({
				...s,
				commentable: !state.isInEditMode,
			}));
		} else {
			setState(s => ({
				...s,
				commentable: false,
			}));
		}
	}, [state.computedUserRoles, state.isInEditMode]);

	const setComments = (comments: CommentPayload[]) => {
		setState(prevState => ({ ...prevState, comments }));
	}

	const reset = () => setTemplateState(s => ({ ...s, template: null, buffer: null, didReset: true, elements: null }));
	const setEditMode = (isEditMode: boolean) => setState(s => ({ ...s, isInEditMode: isEditMode }));
	return {
		...state,
		setTemplate,
		setBuffer,
		setPermissions,
		// when updating the buffers elements or variables,
		// use these two helper functions!
		setElements,
		setVariables,
		setDatasources,
		setComments,
		setEditMode,
		reset,
		isInReview: isInReview(state.template?.state),
		// if a template is designated as top-level AND buildContent is also true.
		canBuildDocuments: ((state.buffer?.metadata?.system?.toplevel ?? "false") === "true") && ((state.buffer?.metadata?.system?.buildContent ?? "false") === "true"),
		isTopLevel: Boolean(state.buffer?.metadata?.system?.toplevel === "true" ? true : false),
		setSelectedElementById,
		setPreviewMode
	}
}

const TemplateState = createContainer(useTemplate);
export { TemplateState };