import {useState} from "react";
import tinymce from "tinymce";
import { useRunOnce, useRunWhenValueChange } from "../../../hooks";
import { Button } from "../../buttons";
import { BaseModal } from "../../modals/BaseModal";
import { tinyMceOptions, temp_element, class_suffix, variableClassNamePrefix } from "./constants";
import { extract_all_variables, is_variable_in_valid_format, set_cursor_in_editor, get_variable_class_name, add_ids_to_html, remove_trigger_keys, replace_non_editable_element } from "./utils";
import {nunjucksConstants} from "../../../nunjucks/models/NunjucksConstants";
import {ExpressionBuilder} from "../expressionBuilder";
import "./style.scss";
import {useGetList} from "../../../hooks/api/useGetList";
import {useAutoCompleteConfigGenerator} from "../../../hooks/useAutoCompleteConfigGenerator";
import {variableAutoCompleteConfigGenerator} from "../../../variables/variableAutoCompleteConfigGenerator";
import {AutoCompleteConfig} from "../../../interfaces/AutoCompleteConfig";
import {AsyncComponent} from "../../progress";
import {systemNamespaces} from "../../../variables/SystemNamespaces";

const uuid = require('uuid');

interface RichTextEditorProps {
	placeholder?: string,
	onChange: (event: {
        target: {
            value: string,
        }
    }) => void,
    onBlur?: (event: {
        target: {
            value: string,
        }
    }) => void,
	defaultValue?: string,
	variables: {
        [key: string]: any;
    } | null,
}

interface ExpressionBuilderState {
    // display the modal enclosing the expression builder
	display: boolean,
    // initial value of expression builder
    value: string,
    // if already an expression, this is the 'node' that the expression
    // is represented by.
    selectedNodeId: string,
    // key that was pressed initially to bring up the expression builder
    pressedKey: string,
    // final value of expression, after fully entered.
    expression: string,
}

function generateBaseAutoCompleteConfig(citationKeys: string[]): Promise<AutoCompleteConfig[]> {
	return Promise.resolve([
		{
			trigger: ' ',
			options: nunjucksConstants.operators,
			excludePredecessors: [...nunjucksConstants.operators ?? [], undefined]
		},
		{
			trigger: '|',
			options: nunjucksConstants.filters
		},
		{
			trigger: 'cite',
			options: citationKeys
		}])
}

const RichTextEditor = ({placeholder, defaultValue, variables, onChange, onBlur} : RichTextEditorProps) => {
	// unique id of the tinymce instance
	const [id] = useState<string>(`tinymce_${uuid.v4()}`);
	const getCitations = useGetList("citation");
	const autoCompleteConfigGenerator = useAutoCompleteConfigGenerator();
	const [expressionBuilderState, setExpressionBuilderState] = useState<ExpressionBuilderState>({
		display: false,
		value: '',
		selectedNodeId: '',
		pressedKey: '',
		expression: '',
	});

	useRunOnce(() => {
		getCitations.execute(undefined);
	})

	useRunOnce(() => {
		tinymce.init({
			...tinyMceOptions,
			selector: 'textarea.' + id,
			setup: (editor) => {
				editor.ui.registry.addContextToolbar('textselection', {
					predicate: function () {
						return !editor.selection.isCollapsed();
					},
					items: 'comment edit del',
					position: 'selection',
					scope: 'node'
				});

				editor.on('input', () => editorUpdated(editor));

				editor.on('change', () => editorUpdated(editor));

				editor.on('Paste', () => {
					setTimeout(() => {
						let content = editor.getContent();
						const variables = extract_all_variables(content);
						variables.forEach(current_variable => {
							content = modify_content_for_variable(content, current_variable);
						});
						content = content + temp_element;
						editor.setContent(content);
						set_cursor_in_editor(editor);
					}, 100);
				});

				// trigger expression builder modal.
				editor.on('keypress', (event) => {
					const key = event.key;

					const editor_range = editor.selection.getRng();
					const node = editor_range.commonAncestorContainer;
					const node_value = node.textContent;
					const node_length = node_value!.length;

					const target_char = node_value!.charAt(node_length - 1);

					if (key === '{' && target_char === '{') {
						editor.execCommand('mceInsertContent', false, key);
						displayExpressionBuilder(null, null, '{{');
					}
				})

				editor.on('mouseup', (event) => {
					const node_id = editor.selection.getNode().id;

					const class_name = event.target.className;
					// variables are applied the class 'mceNonEditable'
					if (class_name.includes('mceNonEditable')) {
						const selected_text = event.target.textContent;
						const trigger_keys = selected_text[0] + selected_text[1];
						displayExpressionBuilder(selected_text, node_id, trigger_keys);
					}
				})
			}
		})
	});

	useRunWhenValueChange(() => {
		const citationKeys = getCitations.value?.entries
			?.map(entry => `("${entry.key.replace(/\s/gm, "")}")`) ?? [];
		autoCompleteConfigGenerator.execute([
			() => generateBaseAutoCompleteConfig(citationKeys),
			() => variableAutoCompleteConfigGenerator({userVariables: variables === null ? null : Object.keys(variables)})])
	}, [variables, getCitations.value])


	useRunWhenValueChange((prevValue) => {
		if (prevValue === true && !expressionBuilderState.display) {
			tinymce.activeEditor?.fire('input');
		}
		validate_variables();
	}, expressionBuilderState.display);

	const editorUpdated = (editor) => {
		const content = editor.getContent();
		const send_event = {
			target: {
				value: add_ids_to_html(content),
			}
		};

		if (onChange) {
			onChange({...send_event});
		}

		if (onBlur) {
			onBlur({...send_event});
		}
		editor.focus();
	}


	const modify_content_for_variable = (content, variable) => {
		/**
         * If the content is not already inside a highlighter span,
         * replace it with the highlighter
         */
		const replace_html_tag = new RegExp(`<span.*?</span>|({{${variable}}})`, 'g');
		content = content.replace(replace_html_tag, (unmatched, group1) => {
			if (!group1) {
				return unmatched;
			} else {
				if (is_existing_variable(variable)) {
					return get_variable_block(variable, 'valid');
				} else {
					return get_variable_block(variable, 'invalid');
				}
			}
		});
		return content;
	}

	const displayExpressionBuilder = (selectedText, selectedNodeId, pressedKey) => {
		/*
		 * Slice opening and closing syntax ('{{' and '}}') from selected
		 * text so only expression displays in ExpressionBuiler
		 * text field.
		 */
		setExpressionBuilderState(s => ({...s, value: selectedText?.slice(2, -2) ?? '', pressedKey, selectedNodeId, display: true}));
	}


	const is_existing_variable = (variable) => {
		return get_variable(variable) !== undefined;
	}

	const get_variable_type = (variable) => {
		return get_variable(variable)?.contents?.type;
	}

	const get_variable = (variable) => {

		if (!variables || !variable) {
			return undefined;
		}

		for (const item of Object.keys(variables)) {
			if (variable.toLowerCase() === item.toLowerCase()) {
				return variables[item];
			}
		}

		return undefined;
	}

	const get_variable_block = (variable, type) => {
		return`<span class="${get_variable_class_name(variable)} ${type}${class_suffix} mceNonEditable" data-variable_name="${getQualifiedVariableName(variable)}">{{${variable}}}</span>`;
	}

	const getCasedVariableName = (variable_name) => {
		const cased_names = Object.keys(variables ?? {});

		return cased_names.find((cased_name) => cased_name.toLowerCase() === variable_name.toLowerCase()) ?? "";
	}

	const getQualifiedVariableName = (variable_name) => {
		const cased_name = getCasedVariableName(variable_name);
		const variable = variables?.[cased_name];

		return variable?.qualified_name || variable_name
	}

	const onExpressionEntered = () => {
		const convert_keys = {
			'{{': '}}',
		}
		const {pressedKey: opening_syntax, expression: currentExpression} = expressionBuilderState;

		const closing_syntax = convert_keys[opening_syntax];
		/*
		 * Generate the CSS classname for the variable
		 * Different variable types display in specific colors in the document editor
		 */
		// we're only interested in the variable name and not any properties

		const variableNameParts = currentExpression.split(".");
		if (!variableNameParts) {
			return;
		}
		const variableType = get_variable_type(variableNameParts[0]) === "datasource" || systemNamespaces.find(namespace => currentExpression.includes(namespace)) ? "datasource" : "default";
		const variableStyleClassName = `${variableClassNamePrefix}_${variableType}`;
		let expression;
		if (is_variable_in_valid_format(currentExpression)) {
			const variable = currentExpression;
			const type = "valid";
			// if (is_existing_variable(variable)) {
			// 	type = 'valid';
			// } else {
			// 	type = 'invalid';
			// }

			const expression_text = `{{${variable.replace(' ', '_')}}}`;

			expression = `<span class="mceNonEditable ${variableStyleClassName}" id="${type}${class_suffix}_${uuid.v4()}" data-variable_name="${getQualifiedVariableName(variable)}">${expression_text}</span>`;
		} else {
			expression = `<span class="mceNonEditable ${variableStyleClassName}" id="${uuid.v4()}">${opening_syntax}${currentExpression}${closing_syntax}</span>`;
		}

		const editor = tinymce.activeEditor;

		/*
		 * If the user edits an element, replace selected element with
		 * new element.
		 *
		 * If the user adds an element, insert it into the Rich Text Editor
		 */
		if (expressionBuilderState.selectedNodeId === null) {
			// remove trigger keys from RTE, they wil be replaced by the expression.
			remove_trigger_keys(editor);
			editor.execCommand('mceInsertContent', false, expression);
		} else {
			replace_non_editable_element(editor, expressionBuilderState.selectedNodeId, expression);
		}
		setExpressionBuilderState(s => ({...s, display: false}));
	}

	const validate_variables = () => {
		/**
		 * Check if each variable are in the right state
		 * if not, toggle their classes to reflect the same
		 */
		const editor = tinymce.activeEditor;
		if (editor !== null) {
			const content = editor.getContent();
			let referencedVariables;
			if (content) {
				referencedVariables = extract_all_variables(content);
			}

			if (referencedVariables && referencedVariables.length > 0) {
				referencedVariables.forEach(variable => {
					try {
						const undefinedVariables = editor.getBody().querySelectorAll(`.${get_variable_class_name(variable)}`);
						for (let i = 0; i < undefinedVariables.length; i++) {
							if (is_existing_variable(variable)) {
								editor.dom.removeClass(undefinedVariables[i], `invalid${class_suffix}`);
								editor.dom.addClass(undefinedVariables[i], `valid${class_suffix}`);
							} else {
								editor.dom.removeClass(undefinedVariables[i], `valid${class_suffix}`);
								editor.dom.addClass(undefinedVariables[i], `invalid${class_suffix}`);
							}
						}
					} catch (err) {
						//skip error for invalid variables selector
					}
				});
			}
		}
	}

	const onChangeExpression = (event: {target: {value: string}}) => {
		let {value} = event.target;
		if (value.startsWith("cite ")) {
			value = value.replace(/cite\s/gm, "cite");
		} else if(value.indexOf(". ") > 0) {
			value = value.replace(/\.\s/gm, ".");
		}
		setExpressionBuilderState(s => ({...s, expression: value}))
	}

	return (
		<div>
			<BaseModal
				visible={expressionBuilderState.display}
				onClose={() => setExpressionBuilderState(s => ({...s, display: false}))}
				title={"Expression Builder"}
				content={
					<AsyncComponent
						isLoading={autoCompleteConfigGenerator.isLoading}
						component={<ExpressionBuilder
							className='editor-item-autocomplete-input'
							value={expressionBuilderState.value}
							// autoCompleteConfig is the prop that supplies the variables to the dropdown.
							autoCompleteConfig={autoCompleteConfigGenerator.value ?? []}
							onChange={onChangeExpression}
						/>} />
				}
				buttonRow={<>
					<Button text={"Enter"} onClick={onExpressionEntered}/>
				</>}
			/>
			<textarea
				defaultValue={defaultValue}
				placeholder={placeholder}
				className={id}
				style={{visibility: 'hidden', border: 'none'}}
			/>
		</div>
	);

}

export {RichTextEditor}