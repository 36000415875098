import {styled} from "@mui/material";

const Container = styled("div")(({theme}) => ({
	margin: "1rem 0",
	fontSize: ".875rem",
	width: "100%",
	"& span.surTitle": {
		fontSize: ".75rem",
		paddingBottom: ".3rem",
		color: theme.palette.text.secondary
	},
	"& label.documentTitle": {
		paddingBottom: "1rem"
	},
	"& label.templateTitle": {
		paddingBottom: "1rem",
		cursor: "pointer",
		color: theme.palette.primary.main,
		textDecoration: "underline",
	},
	"& .MuiTextField-root, & .MuiFormControlLabel-root": {
		marginBottom: "1rem"
	},
	"& .check_label": {
		fontSize: ".85rem",
		padding: "0 .5rem",
		boxSizing: "border-box"
	}
}));

export {Container}