import { MajorVersionLookupType, VersionType } from "../components/input/dropdown/versionSelector";
import { WorkflowStates } from "../interfaces/WorkflowState";
import { AsyncReturn } from "./useAsync";
import { useRunWhenValueChange } from "./useRunWhenValueChange";
import { useCallback, useState } from "react";


/**
 * Sort Document Versioning List and Add Additional Prettified Versioning for Dropdown
 * @param {array} arrayToSort - Array of Document Version Objects
 * @return {array} Sorted Array of Document Objects
 */
export function sortVersionListByDateAndMap(arrayToSort: VersionType[]):  MajorVersionLookupType[] {

	// sort by date, descending
	const sortVersionsByDate = (version1: VersionType, version2: VersionType): number => {
		// @ts-ignore
		return (version2.date - version1.date);
	}

	if (arrayToSort.length === 0) {
		return [];
	}

	arrayToSort = arrayToSort.sort(sortVersionsByDate);
	//this is the most recent, therefore, current version.
	arrayToSort[0].isCurrent = true;

	// mark "major" versions. A major version is any version that has been approved
	// and store the versions array index in the version object
	arrayToSort = arrayToSort
		.map((version, index) => ({ ...version, index, isMajor: version.state?.toLowerCase() === WorkflowStates.Approved, state: version.state ?? "In Process" }));

	// extract a list of "major" versions.  A major version is any version that has been approved
	const approvedVersions = arrayToSort
		.filter(version => version.isMajor);

	// build the initial "major" version lookup
	let versionLookup = approvedVersions
		.map((version, index): MajorVersionLookupType => ({ versionNumber: approvedVersions.length - index, majorVersion: version, minorVersions: [] }));

	// extract a list of "minor" versions. A minor version is any version that hasn't been approved
	arrayToSort.filter(version => !version.isMajor).forEach(version => {
		// get a list of all versions that have been approved since this version was created. If none exists, look for a collection where majorVersion is null.
		const allApprovedVersionsSince = versionLookup.filter(versionEntry => versionEntry.majorVersion === null || versionEntry.majorVersion!.index < version.index);
		// if no version has been approved since this version was created then it exists outside an approved (major) version.
		// create a new version collection where major version is null
		if (allApprovedVersionsSince.length === 0) {
			versionLookup = [{ versionNumber: versionLookup.length + 1, majorVersion: null, minorVersions: [version] }, ...versionLookup];
		} else {
			// push the minor version into the collection
			allApprovedVersionsSince[allApprovedVersionsSince.length - 1].minorVersions.push(version);
		}
	});

	// assign version numbers to all major and minor versions.
	versionLookup.forEach(version => {
		const number = version.versionNumber;
		if (version.majorVersion) {
			version.majorVersion.number = `${number}.0`;
		}

		version.minorVersions = version.minorVersions
			.map((minorVersion, index) => ({
				...minorVersion,
				number: `${(number - 1)}.${version.minorVersions.length - index}`
			}));
	})

	return versionLookup;
}

export function findMatchingVersionFromVersionList(versionId: string, versionList: MajorVersionLookupType[] | null): VersionType | null {
	if (versionList === null) {
		return null;
	}

	// if we don't have a versionId, select the most recent version
	if (!versionId) {
		const mostRecentVersion = versionList[0];

		if (mostRecentVersion) {
			return mostRecentVersion.majorVersion ?? mostRecentVersion.minorVersions[0];
		}
	}

	for (const versionInfo of versionList) {
		if (versionInfo.majorVersion?.version === versionId) {
			return versionInfo.majorVersion;
		}

		for (const minorVersion of versionInfo.minorVersions) {
			if (minorVersion.version === versionId) {
				return minorVersion;
			}
		}
	}

	return null;
}
function useVersions(factory: AsyncReturn<VersionType[]>) {
	const loadMajorVersions = (entityId: string) => factory.execute(entityId);
	const [versionList, setVersionList] = useState<MajorVersionLookupType[]>([]);
	useRunWhenValueChange(() => {
		if (factory.value) {
			setVersionList(sortVersionListByDateAndMap(factory.value));
		}
	}, factory.value);

	/**
	 * Find a version matching versionId
	 */
	const findMatchingVersion = useCallback((versionId) => findMatchingVersionFromVersionList(versionId, versionList), [versionList]);

	return {
		isLoading: factory.isLoading,
		error: factory.isLoading,
		versionList,
		loadMajorVersions,
		findMatchingVersion
	}
}

export { useVersions };