import {Variable, VariableTree} from "../interfaces/Variable";
import {isObject} from "../guards/isObject";
import {DataSource, isDataSource} from "../interfaces/DataSource";
import {isArray} from "../guards/isArray";
import {isInTextTableVariable} from "../definitions/elements/inTextTable/model";
import {isReferenceElementContents, ReferenceElementContents} from "../definitions/elements/reference/model";

export function calculateDataSourceValue(datasources: DataSource[]) {
	if (datasources.length === 1) {
		return datasources[0];
	}

	return datasources.reduce((all, current) => {
		if (current?.name === undefined) {
			return all;
		}
		all[current.name] = current;
		return all;
	}, {})
}
function variableAdapterFactory() {
	const toTree = (variables: Pick<Variable, "name" | "namespace" | "value" | "dataSources">[]): VariableTree => {
		return variables.reduce((lookup, current) => {
			const keyParts = current.namespace?.split(".") ?? [current.name];
			keyParts.reduce((currentObj, currentKey, index) => {
				if (index === keyParts.length - 1) {
					currentObj[currentKey] = current.dataSources !== undefined && current.value === undefined ? calculateDataSourceValue(current.dataSources) : current.value;
					return currentObj;
				} else {
					if (!currentObj[currentKey]) {
						currentObj[currentKey] = {};
					}
					return currentObj[currentKey];
				}
			}, lookup);
			return lookup;
		}, {})
	}

	const flattenTree = (variableTree: VariableTree, parentKey="", seperator = "."): Record<string, string | number | DataSource | ReferenceElementContents> => {
		return Object.keys(variableTree).reduce((lookup, key) => {
			const prefixedKey = parentKey ? `${parentKey}${seperator}${key}` : key;
			if (variableTree[key] !== null &&
				isObject(variableTree[key]) &&
				!isInTextTableVariable(variableTree[key]) &&
				!isDataSource(variableTree[key]) &&
				!isArray(variableTree[key]) &&
				!isReferenceElementContents(variableTree[key])) {
				Object.assign(lookup, flattenTree(variableTree[key] as VariableTree, prefixedKey, seperator));
			} else {
				lookup[prefixedKey] = variableTree[key] as (string | number | DataSource | ReferenceElementContents);
			}
			return lookup;
		}, {} as Record<string, string | number | DataSource | ReferenceElementContents>);
	}

	return {
		toTree,
		flattenTree
	}
}

export const VariableAdapter = variableAdapterFactory();