/**
 * Custom hook that wraps scroll functionality
 * Futuer work can create implementations for other ways to scroll
 * @param fn
 * @returns
 */
import React from "react";

function useScroll<T extends HTMLElement>() {
	const scrollById = (scrollId: string, pageRef?: React.RefObject<T>): void => {
		scrollByQuery(`[id="${scrollId}"]`, pageRef);
	};

	const scrollByQuery = (querySelector: string, pageRef?: React.RefObject<T>): void => {
		const pageElement: HTMLElement | null | undefined = pageRef?.current;
		let element: HTMLElement | null;

		if (!pageElement) {
			element = document.querySelector(querySelector);
		} else {
			element = pageElement.querySelector(querySelector);
		}

		if (element) {
			scrollElementIntoView(element);
		}
	};

	const scrollElementIntoView = (element: HTMLElement): void => {
		element.scrollIntoView({
			behavior: 'auto',
			block: 'start',
			inline: 'nearest',
		});
	}

	const scrollToElementWithQuery = (query: string, pageRef: any) => {
		const page = pageRef.current;
		if (!page) {
			return false;
		}
		const element = page.querySelector(query);
		if (element) {
			// scroll to selected text
			element.scrollIntoView({ behavior: "smooth" });
			return true;
		}
		return false;
	}

	return {
		scrollById,
		scrollByQuery,
		scrollToElementWithQuery
	};
}

export { useScroll };
