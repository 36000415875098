// ACL role keys that map to frontend display.
export enum ACLRoles {
	reviewers = "Reviewer",
	approvers = 'Approver',
	authors = 'Author',
	readers = 'Reader',
	owners = "Owner"
}

export function getAclRole(aclType: string): string {
	return ACLRoles[aclType];
}