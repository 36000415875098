import api from '../../../../../api/api';
import {useAsync} from "../../../../../shared/hooks";

export type UpdateCommentResponse = {
	elements: {
		[k: string]: KaiAlphaCommentEntry[]
	}
}
/**
 * Pass in comment to be updated
 * returns an array of the comment values
 */
const useUpdateComment = () => {
	const updateComment = async (documentId: string, commentEntry: KaiAlphaCommentEntry): Promise<UpdateCommentResponse | null> => {
		const commentBody = {
			comment: {
				author: commentEntry.author,
				version: commentEntry.version,
				state: commentEntry.state,
				deleted: commentEntry.deleted,
				id: commentEntry.id,
				date: commentEntry.date,
				comment: commentEntry.comment,
				lastupdated: commentEntry.lastupdated,
				tag: commentEntry.tag,
				elementId: commentEntry.elementId,
				cmsElementId: documentId
			},
			commentId: commentEntry.id
		};

		return api.call('PUT', `comment/document/${documentId}`, commentBody);
	}
	return useAsync<any>(updateComment, false);
}

export {useUpdateComment};