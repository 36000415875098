import { styled } from "@mui/material";
import { PlaylistAddCheck } from "@mui/icons-material";
import { CenteredContainer } from "../../CenteredContainer";
import {Tooltip} from '../../../labels/Tooltip';
import {ButtonProps} from "./Button.model";
import {ButtonBase} from "./ButtonBase";

const TasksButtonContainer = styled(ButtonBase)<{disabled: boolean}>(({theme, disabled}) => ({
	backgroundColor: disabled ? theme.palette.disabled?.main : theme.palette.tabs?.border,
	color: theme.palette.primary.main,
	"&:hover": {
		backgroundColor: disabled ? theme.palette.disabled?.main : theme.palette.primary.main,
		color: theme.palette.tabs?.border,
	}
}));

const TasksButton = ({onClick, disabled, tooltipText}: Partial<ButtonProps> & {disabled?: boolean, tooltipText?: string}) => <TasksButtonContainer disableRipple disabled={disabled ?? false} onClick={onClick}>
	<CenteredContainer>
		<Tooltip title={tooltipText ?? "View Tasks"} placement="top">
			<PlaylistAddCheck style={{fontSize: '19px'}}/>
		</Tooltip>
	</CenteredContainer>
</TasksButtonContainer>

export {TasksButton}