import {TabDefinition} from "../../../../../shared/components/layout/tabs/tabDefinition";
import {DocumentDetails} from "./documentDetails";
import { Contributors } from "./contributors";
import { Metadata } from "./metadata";
import {FlexBox} from "../../../../../shared/components";

const TabView = () => {
	return <FlexBox direction={"column"}>
		<DocumentDetails />
		<Contributors />
		<Metadata />
	</FlexBox>
}

const PropertiesTab: TabDefinition = {
	name: "Properties",
	content: TabView
}

export {PropertiesTab};