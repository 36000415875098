import { useState } from "react";
import { useTemplateDetail } from "../../../hooks";
import { TemplateListInitialState, TemplateListProps, TemplateListState } from "./TemplateList.models";
import { SelectedTemplateContainer, Hr, TemplatePreviewContainer, HeaderLabel, ScrollablePreview } from "./TemplateList.style";
import { TemplateListItem } from "./TemplateListItem";
import {AsyncComponent, Button, FlexBox, useStatusBar} from "../../../../../shared/components";
import { Box, TextField } from "@mui/material";
import { EmptyGroup } from "../../../../../shared/components/layout/groups/EmptyGroup";
import { List } from "../../../../../shared/components/list/tree/TreeList.style";
import { SortFilter } from "../../../../../shared/components/input/filter/sort";
import { Visibility } from "@mui/icons-material";
import {useDebounce, useRunWhenValueChange} from "../../../../../shared/hooks";
import { FilterState, FilterStates } from "../../../../../shared/components/input/filter/sort/models";
import {SearchTemplatesQuery, useGetTemplates} from "../../../../../shared/hooks/api/useGetTemplates";
import {isEmptyString} from "../../../../../shared/utilities/isEmptyString";

const TemplateList = ({onTemplateSelect, onRenderPreview, selectedTemplate}: TemplateListProps) => {
	const getTemplates = useGetTemplates();
	const getMoreTemplates = useGetTemplates();
	const getTemplateById = useTemplateDetail();
	const [state, setState] = useState<TemplateListState>(TemplateListInitialState);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [sortState, setSortState] = useState<FilterState>(FilterStates.None);
	const statusBar = useStatusBar();
	const debouncedSearchTerm = useDebounce(searchTerm, 500)

	const getQueryOptions = (after?: string[] | number[]): SearchTemplatesQuery => {
		return {
			filters: isEmptyString(searchTerm) ? [{field: 'metadata.system.toplevel', value: "false"}] : [{field: "name", value: searchTerm}, {field: 'metadata.system.toplevel', value: "false"}],
			sort: sortState === FilterStates.None ? undefined : {
				field: "model_document",
				direction: (sortState === FilterStates.Ascending ? 'asc' : 'desc')
			},
			pageSize: 25,
			after
		};
	}

	useRunWhenValueChange(async () => {
		getTemplates.execute(getQueryOptions());

	}, [debouncedSearchTerm, sortState]);

	useRunWhenValueChange(() => {
		if (getTemplates.value) {
			setState(s => ({...s, templates: getTemplates.value!.results ?? []}))
		}
	}, getTemplates.value);

	useRunWhenValueChange(() => {
		if (getMoreTemplates.value) {
			setState(s => ({...s, templates: [...s.templates, ...(getMoreTemplates.value!.results ?? [])]}))
		}
	}, getMoreTemplates.value)

	useRunWhenValueChange(() => {
		if (getTemplates.error || getMoreTemplates.error) {
			statusBar.sendErrorNotification({message: "Error loading templates", detail: getTemplates.error ?? getMoreTemplates.error});
		}
	}, [getTemplates.error, getMoreTemplates.error])

	const onItemSelect = (template: KaiAlphaTemplate) => {
		setState(s => ({...s, templatePreview: null}))
		getTemplateById.execute(template.id);
		onTemplateSelect(template);
	}

	const onLoadMoreTemplates = async () => {
		// get next page using same filter and append them at the end
		getMoreTemplates.execute(getQueryOptions(getTemplates.value?.next));
	}

	const total = getTemplates.value?.total ?? -1;
	const hasMore = total > -1 && total > state.templates.length;

	return <AsyncComponent
		isLoading={getTemplates.isLoading || getMoreTemplates.isLoading}
		component={<FlexBox direction="column">
			<FlexBox>
				<FlexBox direction="column" flex={".6"}>
					<FlexBox justify="space-between" align="stretch">
						<FlexBox boxSizing="border-box" flex="0.7">
							<TextField
								label="Search"
								placeholder="Type to search elements"
								fullWidth
								value={searchTerm}
								variant="outlined"
								onChange={(e) => setSearchTerm(e.target.value)}
							/>
						</FlexBox>
						<FlexBox justify="center" align="center" width="auto" flex="0.3" boxSizing="border-box">
							<SortFilter title="Model Document" onStateChange={setSortState} />
						</FlexBox>
					</FlexBox>
					<Hr />
					{(state.templates?.length ?? 0) > 0 ? <List maxHeight="25rem">
						{state.templates.map((template: KaiAlphaTemplate) =>
							<TemplateListItem
								key={template.id}
								template={template}
								selected={selectedTemplate?.id === template.id}
								onSelect={onItemSelect}
							/>
						)}
					</List> : <EmptyGroup title="No elements found" />}
					{hasMore && <FlexBox align="center" justify="center" boxSizing={"border-box"} padding={"0.5rem"}>
						<Button buttonType={"default"} text={"Load more"} onClick={onLoadMoreTemplates}/>
					</FlexBox>}
				</FlexBox>
				<TemplatePreviewContainer flex={".4"} direction="column" width={"40%"}>
					<FlexBox justify="center" align="center" boxSizing="border-box">
						<HeaderLabel justify="center" align="center">
							<FlexBox direction="column" justify="center" width="auto">
								<Visibility className={`icon`}/>
							</FlexBox>
							<FlexBox direction="column" justify="center" width="auto" marginLeft="0.4rem" boxSizing="border-box">
								<div
									className={`header_title`}
								>Preview</div>
							</FlexBox>
						</HeaderLabel>
					</FlexBox>
					<AsyncComponent
						isLoading={getTemplateById.isLoading}
						loadingMessage={"Loading template preview"}
						component={ selectedTemplate ?
							<ScrollablePreview> {onRenderPreview(getTemplateById.value)} </ScrollablePreview> :
							<EmptyGroup title="Select a template to see a preview"/>
						}
					/>
				</TemplatePreviewContainer>
			</FlexBox>
			<SelectedTemplateContainer>
				<b>Selected Template:</b> {selectedTemplate?.name && <Box marginLeft="0.2rem">{selectedTemplate!.name}</Box>}
			</SelectedTemplateContainer>
		</FlexBox>} />
}

export {TemplateList}