import { SlideOver, SlideOverProps } from "../../../../../shared/components/modals/slideover/SlideOver";
import { useMemo, useState } from "react";
import { buildTemplateBody } from "../../../hooks/actions/update/buildTemplateBody";
import { PreviewElementProps } from "../../elements/model";
import { PreviewView } from "../../elements/PreviewView";
import { BarLoader, Button, FlexBox } from "../../../../../shared/components";
import { useEventListener, useRunWhenValueChange, useScroll } from "../../../../../shared/hooks";
import { Variable } from "../../../../../shared/interfaces/Variable";
import { VariableForm } from "./VariableForm";
import { useLoadDataSources } from "../../../hooks/actions/load/useLoadDataSources";
import { TemplateElement } from "../../../../../shared/interfaces/TemplateElement";
import {DataSourceOptions} from "../../../../../shared/hooks/api/data/useGetDataSource";

export type PreviewSlideOverProps = {
	elements: TemplateElement[],
	variables: Variable[]
} & Omit<SlideOverProps, "children" | "renderActions">;

export function mapBodyToPreviewElementProps(body?: KaiAlphaTemplateBody): PreviewElementProps[] {
	return body?.map(element => {
		const id = Object.keys(element)[0];
		const values = element[id]
		return ({ id, type: values.type as string, data: { ...values } });
	}) ?? [];
}

const PreviewSlideOver = (props: PreviewSlideOverProps) => {
	const [localVariables, setLocalVariables] = useState<Variable[]>([]);
	const [addingVariables, setAddingVariables] = useState(true);

	const loadDataSources = useLoadDataSources();

	const body = useMemo(() => props.open ? buildTemplateBody(props.elements) : [], [props.open, props.elements]);

	const previewItems = useMemo((): PreviewElementProps[] => {
		return mapBodyToPreviewElementProps(body);
	}, [body])


	// custom hook for scrolling
	const previewScroll = useScroll();

	const referenceClick = (event: Event) => {
		if (props.open) {
			if (event.target instanceof HTMLAnchorElement) {
				const elementId = event.target.getAttribute('href');
				if (elementId !== null) {
					previewScroll.scrollById(elementId);
				}
			}
			return false;
		} else {
			return false;
		}
	};


	useEventListener('click', referenceClick);

	useRunWhenValueChange(() => {
		if (props.open) {
			const datasources = props.variables.filter(variable => variable.type === "datasource")
				.map(datasource => ({ namespace: datasource.namespace ?? "", name: datasource.name, ...datasource.options as DataSourceOptions }));

			setAddingVariables(props.variables.length > 0);
			if (datasources.length === 0) {
				setLocalVariables(props.variables);
				return;
			}

			loadDataSources.run({sources: datasources})
		}
	}, [props.variables, props.open]);

	useRunWhenValueChange(() => {
		let variableCollection: Variable[] = [];
		if ((loadDataSources.value?.length ?? 0) > 0) {
			variableCollection = [...props.variables].map(variable => {
				const dataCollection = loadDataSources.value!.find(source =>
					(source.namespace ?? "") === (variable.namespace ?? "") && source.name === variable.name);
				if (dataCollection === undefined) {
					return variable;
				}

				return { ...variable, dataSources: dataCollection!.datasources };
			});
		} else {
			variableCollection = [...props.variables]
		}

		setLocalVariables(variableCollection)

	}, loadDataSources.value)

	const onVariableUpdated = (variable: Variable) => {
		const varIndex = localVariables.findIndex(o => `${o.namespace}.${o.name}` === `${variable.namespace}.${variable.name}`);
		const vars = [...localVariables];
		vars.splice(varIndex, 1, variable);
		setLocalVariables(vars);

	}

	const onFinishAddingVariables = () => setAddingVariables(false);


	const slideOverSize = (localVariables.length > 0 && addingVariables) || !props.open || (loadDataSources.isRunning) ? "medium" : "large"
	return <SlideOver
		open={props.open}
		title={props.title}
		size={slideOverSize}
		onClose={props.onClose}
		renderActions={() => <>
			<Button text={"Close"} onClick={props.onClose} buttonType={"plain"} />
			{(localVariables.length > 0) && addingVariables && <Button sx={{ ml: 1 }} text={"Generate Preview"} onClick={onFinishAddingVariables} />}
		</>}
	>
		{(loadDataSources.isRunning) && <FlexBox>
			<BarLoader message={"Loading..."} />
		</FlexBox>}
		{/* form displayed to collect variables */}
		{(!loadDataSources.isRunning) && addingVariables &&
			<VariableForm variables={localVariables} onVariableChange={onVariableUpdated} />}
		{/* actual preview of template */}
		{(!loadDataSources.isRunning) && !addingVariables &&
			<div className={"document__preview"} style={{ width: "100%" }}>
				{previewItems.map(element => <PreviewView key={element.id} body={body ?? []} variables={localVariables} id={element.id} type={element.type} data={element.data} />)}
			</div>}
	</SlideOver>
}

export { PreviewSlideOver };