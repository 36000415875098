import ExpressionBuilder from '../ExpressionBuilder';
import RichTextEditor from '../RichTextEditor';
import MaterialTableStyled from '../MaterialTableStyled';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import {
	Button,
	TextField
} from '../../lib/ui';
import Grid from '@mui/material/Grid';

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

import nunjucks_utils from '../../lib/utils/nunjucks_utils';

import document_lib from '../../api/document';
import kaialpha from '../../lib/kaialpha';

class SharedItems {

	template_footnotes = {}
	document_footnotes = {}

	lookup_index(values, data) {
		if (!values) {
			return;
		}

		const index = values.findIndex(function(value) {
			for (const check of ['name', 'value', 'variable_name']) {
				if (value[check] !== data[check]) {
					return(false);
				}
			}
			return(true);
		});

		return(index);
	}

	clean_table_data(new_value) {
		const footnotes_values = [];
		for (const value of new_value) {
			footnotes_values.push({
				name: value['name'],
				value: value['value']
			})
		}
		return(footnotes_values);
	}

	add_table_row(data, attribute, id, state_variable, content_type, update_value, table_footnotes) {
		if (id === undefined) {
			return;
		}

		if (attribute === undefined) {
			return;
		}

		this[state_variable] = table_footnotes;

		const footnotes = table_footnotes;
		if (!footnotes[id]) {
			footnotes[id] = [];
		}
		footnotes[id].push(data);
		this[state_variable] = footnotes;

		const new_value = this[state_variable][id];
		if (content_type === 'template') {
			update_value(id, attribute, new_value, state_variable, this[state_variable]);
		}

		if (content_type === 'document') {
			const footnotes_values = this.clean_table_data(new_value);
			update_value(attribute, footnotes_values, state_variable, this[state_variable]);
		}
	}

	update_table_row(data, old_data, attribute, id, state_variable, content_type, update_value, table_footnotes) {
		if (id === undefined) {
			return;
		}

		if (attribute === undefined) {
			return;
		}

		this[state_variable] = table_footnotes;

		const footnotes = this[state_variable];
		const values = footnotes[id];
		const index = this.lookup_index(values, old_data);
		if (index < 0) {
			return;
		}

		values[index] = data;

		const new_value = this[state_variable][id];
		if (content_type === 'template') {
			update_value(id, attribute, new_value, state_variable, this[state_variable]);
		}

		if (content_type === 'document') {
			const footnotes_values = this.clean_table_data(new_value);
			update_value(attribute, footnotes_values, state_variable, this[state_variable]);
		}
	}

	remove_table_row(data, attribute, id, state_variable, content_type, update_value, table_footnotes) {
		if (id === undefined) {
			return;
		}

		const footnotes = table_footnotes;

		const values = footnotes[id];
		const index = this.lookup_index(values, data);
		if (index < 0) {
			return;
		}

		values.splice(index, 1);

		this[state_variable] = footnotes;

		const new_value = this[state_variable][id];
		if (content_type === 'template') {
			update_value(id, attribute, new_value, state_variable, this[state_variable]);
		}

		if (content_type === 'document') {
			const footnotes_values = this.clean_table_data(new_value);
			update_value(attribute, footnotes_values, state_variable, this[state_variable]);
		}
	}

	async upload_image(file, type) {
		const presigned_post_data = await document_lib.create_presigned_post('images');

		/* Construct path to file in S3 bucket and set it as the variable value  */
		const key = presigned_post_data.fields.Key;

		/* Get presigned URL and necessary fields from createPresignedPost() function */
		const url = presigned_post_data.url;
		const fields = presigned_post_data.fields;

		/* Construct a FormData Object to POST */
		const form_data = new FormData();
		Object.keys(fields).forEach(key => form_data.append(key, fields[key]));
		form_data.append('file', file);

		/* Make a POST request to upload the image to S3 */
		const config = {
			method: 'POST',
			body: form_data
		}

		let response;
		try {
			response = await fetch(url, config);
		} catch (fetch_error) {

			throw(new Error('Failed to upload image to S3: ' + fetch_error));
		}

		/* If response failed throw error */
		if (!response.ok || (response.status !== 200 && response.status !== 204)) {
			throw(new Error('Failed to upload image to S3: ' + response.json()));
		}

		return({
			key: key,
			type: type
		});
	}

	variable_options_dialog(item, datasource_items, all_variables) {
		switch (item.type) {
			case '':
				return([]);
			case 'text':
			case 'textarea':
			case 'richtextarea':
			case 'image':
			case 'reference':
			case 'multi_input':
				return([]);
			case 'dropdown':
			case 'list':
			case 'checkbox':
				return([
					{
						type: 'textfield',
						label: 'Items',
						defaultValue: item.items,
						attribute: ['options', 'items'],
						field_overrides: item.field_overrides('items')
					}
				]);
			case 'datasource':
				{
					let datasources = [];
					if (datasource_items?.items?.length > 0) {
						datasources = datasource_items.items;
					}

					return([
						{
							type: 'autocomplete',
							label: 'Source',
							values: datasources,
							attribute: ['options', 'source'],
							param: 'url',
							field_overrides: item.field_overrides('source')
						},
						{
							type: 'textfield',
							label: 'Columns',
							attribute: ['options', 'column_headers'],
							field_overrides: item.field_overrides('column_headers')
						},
						{
							type: 'textfield',
							label: 'Rows',
							attribute: ['options', 'row_headers'],
							field_overrides: item.field_overrides('row_headers')
						},
						{
							type: 'dropdown',
							label: 'type',
							attribute: ['options', 'type'],
							values: [
								{title: "Automatically Detect", value: "auto"},
								{title: "Rows", value: "rows"},
								{title: "Columns", value: "columns"},
								{title: "Columns x Rows", value: "columns-rows"}
							],
							field_overrides: item.field_overrides('type')
						}
					]);
				}
			case 'expression':
				return([
					{
						title: "Expression",
						attribute: ['options', 'expression'],
						type: 'expressionbuilder',
						autoCompleteConfig: [
							{
								trigger: ' ',
								options: nunjucks_utils.constants.operators,
								excludePredecessors: [...nunjucks_utils.constants.operators, undefined]
							},
							{
								trigger: '|',
								options: nunjucks_utils.constants.filters
							},
							{
								trigger: nunjucks_utils.constants.any,
								options: nunjucks_utils.filters.keys(all_variables)
							}
						],
						field_overrides: item.field_overrides('expression')
					}
				]);
			default:
				throw(new Error(`No variable option rendering for variable of type ${item.type}`));
		}
	}

	fixed_variable_options(item, type) {
		if (type !== 'template') {
			/*
			* For documents, prompt for a value to store in the document
			*/

			const variable_name = item.variable_name;
			const current_value = item.current_value;

			let items;
			switch (item.type) {
				case 'dropdown':
				case 'checkbox':
				case 'list':
					items = item.details.items;
					break;
				default:
					/* Nothing to do for items for most things */
					break;
			}

			switch (item.type) {
				case 'text':
					return([
						{
							title: 'Value',
							type: 'text',
							defaultValue: current_value,
							variable_name: variable_name,
							attribute: 'value',
							field_overrides: {
								onChange: (event) => {
									item.field_overrides.onChange(event);
								}
							},
							read_only: false
						}
					]);
				case 'dropdown':
					{
						let values = {};
						if (items) {
							values = items.map(function(item) {
								return({
									value: item,
									title: item
								});
							})
						}
						return([
							{
								title: 'Value',
								attribute: 'value',
								type: 'select',
								variable_name: item.variable_name,
								values: values,
								field_overrides: {
									onChange: (event) => {
										item.field_overrides.onChange(event);
									}
								},
								read_only: false
							},
						]);
					}
				case 'checkbox':
					return([
						{
							title: 'Value',
							attribute: 'value',
							type: 'checkbox',
							variable_name: item.variable_name,
							values: items.map(function(item) {
								return({
									label: item
								})
							}),
							field_overrides: {
								onChange: (event) => {
									const new_value = event.target.value;
									const checkbox_value = current_value ? [...current_value] : [];

									if (checkbox_value.length === 0 || !checkbox_value.includes(new_value)) {
										checkbox_value.push(new_value);
									} else {
										const index_of_value = current_value.indexOf(new_value);
										checkbox_value.splice(index_of_value, 1);
									}


									if ((typeof(item.set_document_variable) === 'function')) {
										item.set_document_variable(variable_name, checkbox_value);
									} else {
										item.field_overrides.onChange(event, checkbox_value)
									}
									if (typeof(item.notify_changed) === 'function') {
										item.notify_changed();
									}
								}
							},
							read_only: false
						}
					]);
				case 'list':
					return([
						{
							title: 'Value',
							attribute: 'value',
							type: 'select',
							variable_name: item.variable_name,
							values: items.map(function(item) {
								return({
									value: item,
									title: item
								});
							}),
							field_overrides: {
								onChange: (event) => {
									item.field_overrides.onChange(event);
								}
							},
							read_only: false
						},
					]);
				case 'multi_input':
					{
						return([
							{
								type: 'editable_table',
								attribute: item.variable_name,
								values: item.values,
								columns: [
									{title: 'Row, Column or Cell', field: 'value'},
									{title: 'Name or Description', field: 'name'},
								],
								content_type: 'document',
								state_variable: 'document_footnotes',
								editable_properties: item.editable_properties
							}
						]);
					}
				case 'datasource':
					{
						/*
						 * There are two kinds of data sources,
						 * one where the source is defined in
						 * the template and one where the
						 * source is left to the document
						 * author to define.  If the data
						 * source is to be defined by the user
						 * they may also just upload data.
						 */
						let get_latest_data;
						if (type === 'workflow') {
							get_latest_data =
								{
									type: 'label',
									value: 'Unable to fetch data before Document creation'
								}
						} else {
							get_latest_data =
								{
									label: "Get Latest Data",
									type: 'button',
									onClick: async () => {
										item.get_latest_data()
									},
									read_only: false
								}
						}
						if (item.user_defined_data_source) {
							/* XXX:TODO */
							console.error('User defined data source detected, but not supported.');
							return([]);
						} else {
							return([
								{
									title: "Source",
									type: "label",
									value: `Source: ${item.details.source}`,
									attribute: ['options', 'source']
								},
								{
									title: "Columns",
									type: "label",
									value: `Columns: ${item.details.column_headers}`,
									attribute: ['options', 'column_headers']
								},
								{
									title: "Rows",
									type: "label",
									value: `Rows: ${item.details.row_headers}`,
									attribute: ['options', 'row_headers']
								},
								{
									title: "Type",
									attribute: ['options', 'type'],
									type: 'select',
									defaultValue: item.details.type,
									read_only: item.read_only,
									values: [
										{title: "Automatically Detect", value: "auto"},
										{title: "Rows", value: "rows"},
										{title: "Columns", value: "columns"},
										{title: "Columns x Rows", value: "columns-rows"}
									]
								},
								get_latest_data
							])
						}
					}
				case 'image':
					/* XXX:TODO: Shouldn't this get used somewhere ? */
					/** XXX:TODO let current_value_set_msg; **/
					if (current_value !== undefined) {
						/** XXX:TODO current_value_set_msg = { **/
						/** XXX:TODO title: 'File has been selected', **/
						/** XXX:TODO } **/
					}
					return([
						{
							title: 'Image',
							type: 'file',
							attribute: 'value',
							variable_name: item.variable_name,
							field_overrides: {
								onChange: async (event) => {
									const file = event.target.files[0];
									const type = file.type;
									const image = await this.upload_image(file, type);

									// retrieve the image from S3 and persist to document, this stops
									// the image from (needlessly) being retrieved each time the document is loaded
									try {
										const imageData = await kaialpha.lib.document.get_image_s3_get(image.key);
										item.field_overrides.onChange({image: `data:${type};base64,${imageData.data}`, type: "image"});
									} catch(err) {
										console.error(err);
										item.field_overrides.onChange(image);
									}
									if (item.request_save_document) {
										await item.request_save_document();
									}
								}
							},
							read_only: false
						}
					]);
				case 'textarea':
					return([
						{
							title: 'Value',
							type: 'textarea',
							attribute: 'value',
							variable_name: item.variable_name,
							field_overrides: {
								onChange: (event) => {
									item.field_overrides.onChange(event);
								}
							},
							read_only: false
						}
					]);
				case 'richtextarea':
					return([
						{
							title: 'Value',
							type: 'richtextarea',
							attribute: 'value',
							variable_name: item.variable_name,
							field_overrides: {
								onChange: (event) => {
									item.field_overrides.onChange(event);
								}
							},
							read_only: false
						}
					]);
				case 'reference':
					{
						const new_value = (current_value === undefined) ? {} : { ...current_value };
						const formats = ['{{value}}', '{{type}} {{value}}', '{{link}}'];
						const selected_element = ((current_value === undefined) ? 'None' : current_value.value);
						const selected_format = ((current_value === undefined) ? '' : current_value.format);
						let get_elements;
						if (type === 'workflow') {
							get_elements = {
								type: 'label',
								value: 'Elements cannot be fetched before Document creation',
							}
						} else {
							get_elements = {
								title: 'Get Elements',
								type: 'button',
								label: 'Get Document Elements',
								onClick: async () => {
									item.get_references_overrides();
								},
								read_only: false
							}
						}

						return([
							{
								title: 'Selected Value',
								type: 'label',
								value: `Selected element: ${selected_element}`
							},
							{
								title: 'Filter',
								placeholder: 'Search for element',
								type: 'text',
								field_overrides: {
									onChange: async (event) => {
										item.reference_overrides.onChange(event);
									}
								},
								read_only: false
							},
							{
								title: 'Formats',
								attribute: ['value', 'format'],
								type: 'select',
								defaultValue: selected_format,
								values: formats.map(function(format) {
									return({
										value: format,
										title: format
									});
								}),
								field_overrides: {
									onChange: (event) => {
										new_value['format'] = event.target.value;

										item.field_overrides.onChange(new_value);
									}
								},
								read_only: false
							},
							get_elements,
							{
								title: 'Values',
								type: 'treeview',
								value: new_value,
								name: variable_name,
								update_reference_tree: item.update_reference_tree,
							}
						]);
					}
				case 'expression':
					{
						let expression;
						if (item.details.expression) {
							expression = item.details.expression;
						}

						return([
							{
								title: 'Expression',
								type: 'label',
								value: expression
							}
						]);
					}
				default:
					throw(new Error(`No variable option rendering for variable of type ${item.type} within fixed element`));
			}
		} else {
			/* For templates, only print out the information */
			return([
				{
					title: 'Options',
					attribute: ['options', 'row_headers']
				}
			])
		}
	}

	grid_item(item, all_variables, options) {
		if (item.type === undefined) {
			item.type = 'text';
		}

		if (item.hide_if_read_only && item.props && item.props.read_only) {
			return;
		}

		/*
		 * Set item to read only if props have read_only set
		 * to true and item's read_only property is not set to false
		 */
		let read_only;
		if (item.props) {
			read_only = item.props.read_only && item.read_only !== false;
		}

		if (item.read_only) {
			read_only = item.read_only;
		}
		const denoteRequired = item.is_element_title && item?.props?.data?.required
		const requiredMarker = denoteRequired ? <span className={'required-marker'}>  (Required) </span>: ''

		switch (item.type) {
			case 'label':
				{
					let type = item.type;

					if (type === '@meta:value') {
						type = '_meta_value';
					}

					if (type === '@meta:default') {
						type = '_meta_default';
					}

					if (type === '@meta:else') {
						type = '_meta_else';
					}

					let attribute = item.attribute;

					if (typeof attribute === 'object') {
						attribute = item.attribute.join('-');
					}

					/* XXX:TODO Class */
					return(<label className={`editor-item-label-${type}-${attribute} ${item.className}`} style={item.style}>{item.value}{requiredMarker}</label>);
				}
			case 'text':
				{
					const element_attrs = item.element_attrs;
					let textValue = element_attrs.defaultValue;

					if (typeof textValue === 'object') {
						textValue = textValue.name;
					}

					let placeholder = 'Enter ' + item.attribute + ' here';

					/*
					 * Override placeholder if one is provided
					 */
					if (item.placeholder !== undefined) {
						placeholder = item.placeholder;
					}

					if (read_only) {
						return(<div>{textValue}{requiredMarker}</div>);
					} else {
						return(<TextField className="editor-item-text-input"
							placeholder={placeholder}
							key={element_attrs.key}
							defaultValue={textValue}
							onBlur={(event, new_value, ...args) => {
								element_attrs.onChange(event, new_value, ...args);
								return(item.force_update_state);
							}}
						/>);
					}
				}
			case 'textarea':
				{
					const element_attrs = item.element_attrs;
					const default_value = element_attrs.defaultValue;

					if (read_only) {
						return(<div>{default_value}</div>);
					} else {
						return(<textarea
							placeholder="Type here"
							key={element_attrs.key}
							onChange={element_attrs.onChange}
							onBlur={element_attrs.onBlur}
							className='editor-item-textarea-input'
						>{default_value}</textarea>);
					}
				}
			case 'expressionbuilder':
				{
					const element_attrs = item.element_attrs;
					const default_value = element_attrs.defaultValue;

					if (read_only) {
						return(<div>{default_value}</div>);
					} else {
						return(
							<ExpressionBuilder
								className='editor-item-autocomplete-input'
								value={default_value}
								variables={item.all_variables}
								autoCompleteConfig={item.autoCompleteConfig}
								contextType={item.contextType}
								onChange={element_attrs.onChange}
								onBlur={element_attrs.onBlur}
								{...element_attrs}
							/>
						);
					}
				}
			case 'richtextarea':
				{
					const element_attrs = item.element_attrs;
					const default_value = element_attrs.defaultValue;

					if (read_only) {
						return(<div className='editor-item-text-input' dangerouslySetInnerHTML={{__html: default_value}}/>);
					} else {
						let valid = true;
						let render_error;

						if (item.parse_check === true) {
							try {
								nunjucks_utils.renderString(default_value);
							} catch (nunjucks_render_error) {
								render_error = nunjucks_render_error;
								valid = false;
							}
						}

						let error_element;
						if (!valid) {
							error_element = (
								<div style={{border: '2px solid red'}}>
									<tt>{render_error.toString()}</tt>
								</div>
							);
						}
						return(
							<>
								{error_element}
								<RichTextEditor
									placeholder="Type here"
									className='editor-item-text-input'
									defaultValue={default_value}
									all_variables={all_variables}
									format={item.format}
									symbol={item.symbol}
									// document workflow prompts do not pass template id
									templateId={item?.props?.data?.template?.id}
									{...element_attrs}
								/>
							</>
						);
					}
				}
			case 'autocomplete':
				{
					const element_options = item.field_overrides;
					const param = item.param;
					const default_value = element_options.defaultValue;

					if (read_only) {
						return(<div className='editor-item-text-input'>{default_value}</div>);
					} else {
						return(
							<Autocomplete
								{...element_options}
								freeSolo={true}
								clearOnBlur={false}
								options={item.values.map(function(item) {
									return(item[param]);
								})}
								getOptionLabel={function(item) {
									return(item);
								}}
								renderInput={(params) => {
									return(<TextField
										onChange={element_options.onChange}
										{...params}
										margin="normal"
									/>)
								}}
							/>
						)
					}
				}
			case 'editable_table':
				{
					let editable_properties;

					/*
					 * If the table cannot be editted (i.e. table element
					 * footnotes in the Document Editor), pass in an
					 * empty object for MaterialTableStyled "editable"
					 */
					if (item.read_only) {
						editable_properties = {};
					} else {
						editable_properties = item.editable_properties;
					}

					let values;
					if (item.values) {
						values = item.values;
					}

					if (item.footnotes) {
						values = item.footnotes;
					}

					// make sure tableData.id prop is set
					values = (values || []).map((value, index) => ({...value, tableData: {id: index}}));

					return(
						<MaterialTableStyled
							columns={item.columns}
							data={values}
							title='Footnotes'
							editable={editable_properties}
						/>
					)
				}
			case 'select':
				{
					const element_attrs = item.element_attrs;
					let default_value;
					if (element_attrs) {
						default_value = element_attrs.defaultValue;
					}
					let default_value_item_found = false;
					let default_option;

					let default_value_item;

					if (item.values.length > 0) {
						default_value_item = item.values.map(function(option) {
							if (option.value === default_value) {
								return(option);
							}
							return(undefined);
						}).find(function(option) {
							if (option === undefined) {
								return(false);
							}
							return(true);
						});
					}

					default_value_item_found = false;
					if (default_value_item !== undefined || default_value === undefined || default_value === null) {
						default_value_item_found = true;
					}

					/*
					 * If value has not been selected, display empty (undefined) option
					 * to indicate variable value is undefined.
					 */
					if (default_value === undefined) {
						default_option = <option value={default_value}></option>;
					}

					if (item.defaultValue !== undefined) {
						default_option = <option value={item.defaultValue}>{item.defaultValue}</option>;
					}

					if (item.defaultValue !== undefined) {
						default_option = <option value={item.defaultValue}></option>;
						default_value = item.defaultValue;
					}

					if (read_only) {
						if (default_value_item_found && default_value_item) {
							return(<div>{default_value_item.title}</div>);
						} else if (typeof(default_value) === 'string' ) { //Only using default value if the value is a string
							return(<div>{default_value}</div>);
						} else {
							return <div></div>
						}
					} else {
						if (item.values.length > 0) {
							if (!default_value_item_found && default_value !== undefined) {
								default_option = <option value={default_value}>Inacessible Item {default_value}</option>
							}
							return(
								<div>
									<span className='editor-item-dropdown-title'>{item.title}: </span>
									<select className='editor-item-text-input' {...element_attrs}>
										{default_option}
										{item.values && item.values.map(function(option) {
											return(<option value={option.value}>{option.title}</option>);
										})}
									</select>
								</div>
							);
						}
						return(<div>Loading...</div>);
					}
				}
			case 'button':
				return(
					<Button onClick={item.onClick}>{item.label}</Button>
				);
			case 'grid':
				return(
					<Grid container spacing={1} alignItems="center">
						{item.grid(item.props, '', item.items, {
							...options,
							depth: options.depth + 1
						})}
					</Grid>
				);
			case 'dialog':
				{
					let show_dialog = false;

					if (item.edit_item_props === undefined || Object.keys(item.edit_item_props).length === 0) {
						show_dialog = true;
					}

					if (item.edit_item_props !== undefined && item.props.id === item.edit_item_props.id) {
						show_dialog = true;
					}

					item.field_overrides = item.element_attrs.onChange;

					return(
						<div>
							{item.state_element_type === item.element_type && show_dialog &&
								item.get_template_element_dialog(item.props, item)
							}
						</div>
					)
				}
			case 'raw':
				return(item.contents);
			case 'title':
			case 'subtitle':
				return(<div className={"editor-item-" + item.type + "-label"}>{item.title}:</div>);
			case 'boolean-display':
				return(<div className={"editor-item-" + item.type + "-label"}>{item.title}: {item.props.data[item.attribute] ? 'Yes' : 'No' }</div>);
			case 'component':
				return(item.body)
			case 'link':
			{
				const element_attrs = item.element_attrs;
				const default_value = element_attrs.defaultValue;
				return(<a href={'#' + item.element_id}>{default_value}</a>)
			}
			case 'file':
				{
					const element_attrs = item.element_attrs;
					return(
						<div>
							<input key={element_attrs.key} onChange={element_attrs.onChange} onBlur={element_attrs.onBlur} className={'editor-item-' + item.type + '-file'} type='file' accept='image/png, image/jpg, image/pdf, image/jpeg'/>
						</div>
					);
				}
			case 'checkbox':
				{
					const item_id = item?.id || item?.props?.id;
					const element_attrs = item.element_attrs;
					let onChange = element_attrs.onChange;

					/*
					* calling notify_changed() immedietely after set_item_attribute in onChange to reflect changes in ui.
					* Or else we see the checkbox selection change only after onBlur
					*/
					if (!item.field_overrides || (item.field_overrides && !item.field_overrides.onChange)) {
						onChange = (event) => {
							item.checkbox_on_change(event.target.checked);
						}
					}

					const values = item.values;

					if (!values) {
						return([]);
					}

					const display_checkbox = [];
					for (const value of values) {
						const checkbox_label = value.label?.trim();
						const checkbox_value = checkbox_label;
						const checkbox_id = `checkbox|${checkbox_value}|${item_id}`;

						let is_checked = false;
						// If the value is boolean set checkbox to checked. If the value is an array, set checkbox if checked item is in the array
						if (element_attrs.defaultValue === true || (element_attrs.defaultValue?.includes && element_attrs.defaultValue?.includes(checkbox_value))) {
							is_checked = true;
						}

						let control;
						if (read_only) {
							control =
								<Checkbox
									disabled
									checked={is_checked}
								/>
						} else {
							control =
								<Checkbox
									checked={is_checked}
									key={`${element_attrs.key}_${value.label}`}
									onChange={(event) => onChange(event)}
									color='secondary'
								/>
						}

						display_checkbox.push(
							<FormControlLabel
								key={checkbox_id}
								id={checkbox_id}
								control={control}
								label={checkbox_label}
								value={checkbox_value}
							/>
						)
					}

					return(display_checkbox)
				}
			case 'treeview':
			{
				const elements = item.elements;

				const renderTree = (nodes) => {
					let children;
					if (Array.isArray(nodes.children)) {
						children = nodes.children.map((node) => renderTree(node));
					} else {
						children = null;
					}

					return(
						<TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name} onBlur={this.notify_changed}
							onLabelClick= {(event) => {
								item.value['value'] = nodes.value;
								item.value['type'] = nodes.type;
								item.value['element_id'] = nodes.id;
								item.update_reference_tree(item.name, item.value);
							}}>
							{children}
						</TreeItem>
					)
				};

				return(
					<TreeView
						className='tree-view'
						defaultCollapseIcon={<ExpandMoreIcon />}
						defaultExpandIcon={<ChevronRightIcon />}
					>
						{renderTree(elements)}
					</TreeView>
				)
			}
			default:
				throw(new Error(`Unable to handle item with type "${item.type}" in grid item`));
		}
	}

	render() {
		return(<div></div>)
	}
}

export default SharedItems;
