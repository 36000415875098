// this is bad code
// was copied from original date_utils add_date function
// forced to use as it formats the date strings across the workflow timeout values
const units = {
	seconds: 1000,
	second: 1000,
	minutes: 60 * 1000,
	minute: 60 * 1000,
	hours: 60 * 60 * 1000,
	hour: 60 * 60 * 1000,
	days: 60 * 60 * 1000 * 24,
	day: 60 * 60 * 1000 * 24,
	weeks: 7 * 60 * 60 * 1000 * 24,
	week: 7 * 60 * 60 * 1000 * 24,
	s: 1000,
	min: 60 * 1000,
	h: 60 * 60 * 1000,
	d: 60 * 60 * 1000 * 24,
	w: 7 * 60 * 60 * 1000 * 24
}

const constructDate = (date: any) => {
	if (date instanceof Date) {
		return(date);
	}

	if (date === undefined) {
		date = new Date();
	} else {
		date = new Date(date);
	}

	if (isNaN(date.valueOf())) {
		throw(new Error(`Invalid date: ${date}`));
	}

	return(date);
}

const addDate = (when: any, base: any = undefined) => {
	base = constructDate(base);

	let newDate = base.valueOf();

	/*
	 * If the input is a simple string, treat it as an array of one item
	 */
	if (!Array.isArray(when)) {
		when = [when];
	}

	/*
	 * Process each part
	 */
	for (const add of when) {
		const work = add.match(/^([0-9.-]+)(.+)$/);
		if (!work) {
			throw(new Error(`Unable to parse string as date: ${add}`));
		}

		const value = Number(work[1]);
		const unit = work[2].trim().toLowerCase();

		/*
		 * Some units are of fixed size, while others vary, process them accordingly
		 */
		switch (unit) {
			case 'mo':
			case 'mon':
			case 'month':
			case 'months':
				{
					const tmp_date = new Date(newDate);
					tmp_date.setUTCMonth(tmp_date.getUTCMonth() + value);
					newDate = tmp_date.valueOf();
				}
				break;
			case 'y':
			case 'year':
			case 'years':
				{
					const tmpDate = new Date(newDate);
					tmpDate.setUTCFullYear(tmpDate.getUTCFullYear() + value);
					newDate = tmpDate.valueOf();
				}
				break;
			default:
				if (units[unit] === undefined) {
					throw(new Error(`Invalid unit ${unit} in when ${add}`));
				}

				newDate += value * units[unit];
				break;
		}
	}

	const retval = new Date(newDate);

	return(retval);
}

export {addDate}