import {EctdModule} from "../../../../../../shared/interfaces/Module";
import {ModuleNavigation} from "../../../../components/list/moduleNavigation";
import {Box} from "@mui/system";
import "./styles.scss";
import {ModuleTable} from "../../../../components/list/moduleTable";
import {NumberColumn, NameColumn, ActiveColumn} from "../../../../components/list/moduleTable/columns";

export type CenterPaneProps = {
	selectedModule?: EctdModule,
	selectedSection?: EctdModule,
	modules: EctdModule[],
	onToggleSectionActive: (module: EctdModule) => void,
	onSelectModule: (module: EctdModule) => void
}

const tableColumns = [NumberColumn, NameColumn, ActiveColumn];
const CenterPane = (props: CenterPaneProps) => {

	const onToggleSectionActive = (module: EctdModule) => props.onToggleSectionActive(module);
	return <Box className={"ectd__tbcp"}>
		<ModuleNavigation onSelectModule={props.onSelectModule} modules={props.modules} selectedModule={props.selectedModule} />
		<Box className={"ectd__tbcp-table"}>
			{props.selectedSection && <ModuleTable onToggleSectionActive={onToggleSectionActive} module={props.selectedSection} columns={tableColumns} />}
		</Box>
	</Box>
}

export {CenterPane}