export interface Colors {
    lightBlue: string;
    trueBlue: string;
    darkBlue: string;
    otherDarkBlue: string;
    bitterSweet: string;
    almostBlack: string;
    richBlack: string;
    white: string;
    black: string;
    whiteLilac: string;
    paperWhite: string;
    cultured: string;
    highlightGrey: string;
    mineShaft: string;
    lemonMeringue: string;
    orangeYellowCrayola: string;
    lightYellow: string;
    maximumGreenYellow: string;
    greenishGrey: string;
    lightGrey: string;
    tabDarkGrey: string;
    tabGrey: string;
    dimGrey: string;
    rainyGrey: string;
    coolGrey: string;
    darkGrey: string;
    softRed: string;
    lavender: string;
    floralWhite: string;
    mistyRose: string;
    lightRed: string;
    ultraLightBlue: string;
    mountainMeadow: string;
    auroraGreen: string;
    whiteGreen: string;
    platonicBlue: string;
    blueWhite: string;
    goldenSprinkles: string;
    whiteGold: string;
    fadedGrey: string;
    mint: string;
    mintFaded: string;
    mintDark: string;
    orangeHighlight: string;
    subtextGrey: string;
    lilacUnderscore: string;
    cancelationText: string;
    fadedBlack: string;
    green: string;
    pastelGrey: string;
    charcoal: string;
	errorRed: string;
	paynesGrey: string;
	ghostWhite: string;
}

const colors: Colors = {
	lightBlue: '#BAC4E3',
	green: '#1EA896',
	trueBlue: '#426AB9',
	darkBlue: '#426BB8',
	otherDarkBlue: '#4D72BD',
	bitterSweet: '#FF715B',
	almostBlack: '#24272B',
	richBlack: '#07070A',
	white: '#FFFFFF',
	black: '#000000',
	whiteLilac: '#F1F3FA4D',
	paperWhite: '#FBFCFE',
	cultured: '#F4F5F7',
	highlightGrey: '#EDF2EF',
	mineShaft: '#333333',
	lemonMeringue: '#FAF0CA',
	orangeYellowCrayola: '#F4D35E',
	lightYellow: '#F3F5D9',
	ghostWhite: '#FCFCFC',
	maximumGreenYellow: '#D1D646',
	greenishGrey: '#eef2ef',
	lightGrey:'#cacaca',
	dimGrey: '#CDCDCE',
	rainyGrey: '#A4A4A4',
	coolGrey: '#585859',
	darkGrey: '#7F7F7F',
	tabDarkGrey: '#333333',
	tabGrey: '#00000066',
	softRed: '#ee7963',
	lavender: '#E7EBF7C1',
	floralWhite: '#FFF7F5',
	mistyRose: '#FCE8E5',
	lightRed: '#FFC8C8',
	ultraLightBlue: '#F4F5F7',
	mountainMeadow: '#1EA896',
	auroraGreen: '#6BD698',
	whiteGreen: "#DAF5E5",
	platonicBlue: '#80CAFF',
	blueWhite: "#DFF2FF",
	goldenSprinkles: '#F4D35E',
	whiteGold: "#FCF4D7",
	fadedGrey: '#7F7F7F',
	mint: '#8ED1C2',
	mintFaded: '#F9FDFC',
	mintDark: '#D6E5DC',
	errorRed: '#ED4428',
	lilacUnderscore: "#bac4e3",
	orangeHighlight: "#FF715B",
	subtextGrey: "#8A8A8A",
	cancelationText: "707070",
	fadedBlack: "#42444E",
	pastelGrey: "#EFEFEF",
	charcoal: "#767676",
	paynesGrey: "#3F3F41",
};

export {colors}