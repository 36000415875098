import {VariableType} from "../../../../../../shared/interfaces/Variable";
import {TextEditor} from "./editors/TextEditor";
import React from "react";
import {VariableEditorProps} from "./model";
import {TextAreaEditor} from "./editors/TextAreaEditor";
import {RichTextEditor} from "./editors/RichTextEditor";
import {ListEditor} from "./editors/ListEditor";
import {DropDownEditor} from "./editors/DropDownEditor";
import { ReferenceEditor } from "./editors/ReferenceEditor";
import {CheckboxEditor} from "./editors/CheckboxEditor";
import {MultiInputEditor} from "./editors/MultiInputEditor";


const editors = [TextEditor, TextAreaEditor, RichTextEditor, ListEditor, DropDownEditor, ReferenceEditor, CheckboxEditor, MultiInputEditor];
function variableEditorForType(type: VariableType): React.FC<VariableEditorProps> | undefined {
	return editors.find(editor => editor.type === type)?.view;
}

export {variableEditorForType}