import { variableClassNamePrefix } from "./constants";

const uuid = require('uuid');

export function extract_string([beg, end]) {
	const matcher = new RegExp(`${beg}(.*?)${end}`, 'gm');
	const normalise = (str) => str.slice(beg.length, end.length * -1);
	return function (str) {
		if (str.match(matcher)) {
			return str.match(matcher).map(normalise);
		}
	}
}

export function is_variable_in_valid_format(variable) {
	/**
     * test if variable contains characters of the invalid format
     * if so return false
     */
	const invalid_variable_format_regexp = /[()'"|[\]]/;
	if (invalid_variable_format_regexp.test(variable) === true) {
		return false;
	} else {
		return true;
	}
}

export function set_cursor_in_editor(editor) {
	/**
     * cursor disappears on inserting non-editable element,
     * hence, introduced a small hack that adds a temp element after each insert
     * and remove it, once cursor is placed after it
     */
	const temp_element = editor.getBody().querySelector(`#remove`);
	editor.selection.select(temp_element, true);
	editor.selection.collapse(false);
	editor.dom.remove(temp_element);
}


export function extract_all_variables(content) {
	const stringExtractor = extract_string(['{{', '}}']);
	let variablesInEditor = stringExtractor(content);
	if (variablesInEditor === undefined) {
		variablesInEditor = [];
	}
	const validVariablesInEditor = variablesInEditor.filter((variable) => {
		return(is_variable_in_valid_format(variable));
	});
	return validVariablesInEditor;
}

export function get_variable_class_name(variable) {
	return `${variableClassNamePrefix}_${variable.toLowerCase()}`;
}


export function add_ids_to_html(html) {
	const doc = new DOMParser().parseFromString(html, 'text/html')
	const elements = doc.getElementsByTagName("*");

	for (let i = 0; i < elements.length; i++) {
		if (!elements[i].id) {
			elements[i].id = uuid.v4();
		}
	}

	return doc.body.innerHTML;
}

export function remove_trigger_keys(editor) {
	const editor_range = editor.selection.getRng();
	const node = editor_range.commonAncestorContainer;
	const range = document.createRange();

	range.selectNodeContents(node);
	range.setStart(node, editor_range.endOffset - 2);

	range.setEnd(node, editor_range.endOffset);
	range.deleteContents();
}

export function replace_non_editable_element(editor, selected_node_id, expression) {
	const selected_element = editor.dom.get(selected_node_id);
	const new_element = document.createElement('span');
	new_element.innerHTML = expression;
	editor.dom.replace(new_element, selected_element, false);
}