import {Task} from "../../interfaces/task";
import {FilterState} from "../../components/input/filter/sort/models";
import {useTaskNodeAdapter} from "./useTaskNodeAdapter";
import {sort} from "../../components/layout/table/utils";
import {useAsync} from "../../hooks";
import {SearchableTaskListFields} from "../model";
import {search} from "../search";
export type TaskFilter = {
	field: string,
	sortDirection: FilterState
}

export function sortAndSearch(tasks?: Task[], sorting?: {field: string, sortDirection: FilterState}, searchValues?: SearchableTaskListFields) {
	const loadedTasks = tasks ?? [];
	let sortedItems = [...loadedTasks];
	if (sorting) {
		try {
			sortedItems = sort(sorting.sortDirection, sorting.field, sortedItems);
		} catch (e) {
			// this only happens when you dont supply a sort method for a complex type.
			throw Error(`column cannot be compared for sort functionality: ${e}`);
		}

	}

	let taskList = [...sortedItems];

	if(searchValues) {
		Object.keys(searchValues).forEach((field) => {
			if ((searchValues[field]?.trim() ?? "").length > 0) {
				const searchValue = searchValues[field].toLowerCase();
				taskList = search(searchValue, field, taskList);
			}
		})
	}
	return taskList
}

function useTaskFilter() {
	const adapter = useTaskNodeAdapter();
	const filter = async (tasks: Task[], filter: TaskFilter) => {
		const adaptedItems = await Promise.all(tasks.map(task => adapter.adapt(task)))
		const filteredItems = sortAndSearch(adaptedItems, filter);
		return Promise.all(filteredItems.map(task => adapter.adapt(task)));
	}

	return useAsync(filter, false);
}

export {useTaskFilter};