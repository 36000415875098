import {BarLoader as BaseBarLoader} from "react-spinners";
import { FlexBox } from "../layout";
import {styled} from "@mui/material";

const Container = styled(FlexBox)<ContainerProps>(({theme, fullScreen}) => ({
	background: "rgba(255,255,255, .9)",
	zIndex: fullScreen ? 3 : 0,
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	position: "absolute",
	"& p": {
		margin: "0 0 .5rem 0"
	}
}))

export interface ContainerProps {
	fullScreen?: boolean
}
export interface BarLoaderProps extends ContainerProps {
	message?: string
}

const BarLoader = ({message, fullScreen}: BarLoaderProps) => {
	return <Container justify={"center"} fullScreen={fullScreen} align={"center"} direction={"column"}>
		<p>{message}</p>
		<BaseBarLoader />
	</Container>
}

export {BarLoader};