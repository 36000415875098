import {NunjucksFilterDocumentation} from "./model";

export const nativeOperatorsDocumentationCollection: readonly NunjucksFilterDocumentation[] = [
	{
		"name": "|",
		"help": "The pipe operator passes data from an expression to a filter",
		"example": ""
	},
	{
		"name": "+",
		"help": "The addition operator will perform concatencation or arithematic addition depending on the type of the operands",
		"example": ""
	},
	{
		"name": "-",
		"help": "The subtraction operator will perform arithmetic subtraction on two operands",
		"example": ""
	},
	{
		"name": "/",
		"help": "The division operator will perform arithmetic division on two operands",
		"example": ""
	},
	{
		"name": "//",
		"help": "The floor division operator will perform arithmetic division on two operands and rounds down to return the whole number value of the result",
		"example": ""
	},
	{
		"name": "%",
		"help": "The remainder operator returns the integer remainder of dividing two operands",
		"example": ""
	},
	{
		"name": "*",
		"help": "The multiplication operator performs arithmetic multiplication on two operators",
		"example": ""
	},
	{
		"name": "**",
		"help": "The expoentation operator calculates the base to the exponent power (base ^ exponent)",
		"example": ""
	},
	{
		"name": "==",
		"help": "The equal operator returns true if operands are equal",
		"example": ""
	},
	{
		"name": "===",
		"help": "The strict equal operator returns true if operands are equal and are of the same type",
		"example": ""
	},
	{
		"name": "!=",
		"help": "The not equal operator returns returns true if operands are not equal",
		"example": ""
	},
	{
		"name": "!==",
		"help": "The strict not equal operator returns returns true if operands are not equal and are not of the same type",
		"example": ""
	},
	{
		"name": ">",
		"help": "The greater than operator returns true if the left operand is greater than the right operand",
		"example": ""
	},
	{
		"name": ">=",
		"help": "The greater than operator returns true if the left operand is greater than or equal to the right operand",
		"example": ""
	},
	{
		"name": "<",
		"help": "The greater than operator returns true if the left operand is less than the right operand",
		"example": ""
	},
	{
		"name": "<=",
		"help": "The greater than operator returns true if the left operand is less than or equal to the right operand",
		"example": ""
	},
	{
		"name": "~=",
		"help": "The regular expression operator returns true if the regular expression in the right operand matches the left operand",
		"example": ""
	}
] as const;