/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/lib/document_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import object_utils from './object_utils';
import data_utils from './data_utils';
import buffer_utils from './buffer_utils';
import testing_utils from './testing_utils';
import general_utils from './general_utils';
// @ts-check

const glob_to_regexp = require('glob-to-regexp');
const uuid = require('uuid');
const fs = require('fs');

const _testing = undefined;

/**
 * Function to take a Document and Template and return the serialized body
 *
 * @param {KaiAlphaDocument} [document] - KaiAlpha Document object
 * @param {KaiAlphaTemplate} [template] - KaiAlpha Template object
 * @param {object} [options] - Options
 * @param {boolean} [options.mutable] - Boolean to return a copy (default) or the same instance
 * @return {KaiAlphaBody} Unified bodies from the Document and Template
 */
function body_serialize(document, template, options = {}) {
	options = {
		mutable: false,
		...options
	};

	const retval = [];

	let body = [];
	let from;

	if (template && Array.isArray(template.body) && document && Array.isArray(document.body_extend)) {
		throw new Error("Ambiguity on Template and Document body elements, as both has body parts and we are not sure to choose which");
	}

	if (template && Array.isArray(template.body)) {
		body = template.body;
		from = 'template';
	} else if (document && Array.isArray(document.body_extend)) {
		body = document.body_extend;
		from = 'document';
	} else {
		kaialpha.log.debug('Body element is missing on both template and document:', { document, template });
	}

	if (!options.mutable) {
		body = object_utils.copy_object(body);
	}

	/*
	 * Indicate the source of this element
	 */
	for (const element_descriptor of body_by_element_tag(null, body)) {
		const element_info = element_descriptor.contents;
		element_info['$from'] = from;

		if (element_descriptor.type === 'variable') {
			const variable_name = element_info.name;
			if (template && template.variables && template.variables[variable_name]) {
				element_info['$variable_descriptor'] = template.variables[variable_name];
			}
		}
	}

	for (const element_info of body) {
		retval.push(element_info);
	}

	/*
	 * XXX:TODO: Parse through the document.body_extend and update the returned value
	 */

	return(retval);
}

/*
 * Function to return an array of all body element IDs (recursively)
 */
/* XXX:TODO: Does this handle switch, loop ? */
function body_element_ids(body) {
	const retval = [];

	for (const element of body) {
		const element_id = Object.keys(element)[0];
		const element_body = element[element_id];

		retval.push(element_id);

		if (element_body.body) {
			retval.push(...body_element_ids(element_body.body));
		}
	}

	return(retval);
}

/**
 * Convert a KaiAlpha body object into a map of element IDs and the element value
 *
 * @param {KaiAlphaBody} body - The KaiAlpha body object
 * @returns {Object.<string?, KaiAlphaElement>} Map of Element IDs to Elements
 */
function body_element_map(body) {
	const body_map = {};
	const body_elements = body_by_element_tag(null, body);

	for (const body_element of body_elements) {
		body_map[body_element.id] = body_element.contents;
	}

	return(body_map);
}

/**
 * Function to take a serialized document body and produce a list of elements
 * with a given tag (such as "template" or "section") and their IDs.
 *
 * This will take into account nested body structures.
 *
 * @param {string | null} element_tag - Limit returned value to the given element types (or all types if null)
 * @param {KaiAlphaBody} body - Body
 * @param {string[]} [container] - Path to append to results
 * @param {Object} [options] - Options
 * @param {boolean} [options.evaluate_expressions] - Evaluate expressions while processing switch statements
 * @param {KaiAlphaVariables} [options.variables] - Variables to use when evaluating expressions
 * @returns {KaiAlphaBodySelection} A selection of the body
 */
function body_by_element_tag(element_tag, body, container = undefined, options = {}) {
	options = {
		evaluate_expressions: false,
		variables: {},
		...options
	};

	if (container === undefined) {
		container = ['Document'];
	}

	const retval = [];

	if (!(body instanceof Array)) {
		return(retval);
	}

	for (const element of body) {
		const element_id = Object.keys(element)[0];
		const element_body = element[element_id];
		const element_type = element_body.type;
		const element_container = [...container];
		element_container.push(`${element_type} ${element_body.name}`);

		if (element_type === element_tag || element_tag === null) {
			if (options.evaluate_expressions) {
				switch (element_type) {
					case 'switch':
					case 'loop':
						if (element_body.expression) {
							element_body.expression_evaluated = kaialpha.lib.nunjucks_utils.compute_expression(element_body.expression, options.variables);
						}
						break;
					default:
						/* Nothing to do for most elements */
						break;
				}
			}

			retval.push(/** @type {KaiAlphaBodySelectionEntry} */ ({
				type: element_type,
				id: element_id,
				contents: element_body,
				container: container
			}));
		}

		switch (element_type) {
			case 'template':
			case 'section':
				if (element_body.body) {
					retval.push(...body_by_element_tag(element_tag, element_body.body, element_container, options));
				}
				break;
			case 'switch':
				{
					const expression_result = {
						valid: false
					};

					if (options.evaluate_expressions === true && element_body.expression) {
						expression_result.value = element_body.expression;
						expression_result.valid = true;
					}

					if (element_body.values instanceof Object) {
						for (const expression_value in element_body.values) {
							if (expression_result.valid === true) {
								/*
								 * If we already matched a value, skip all other values
								 */
								if (expression_result.matched === true) {
									continue;
								}

								if (kaialpha.lib.nunjucks_utils.compare_expressions(expression_result.value, expression_value, options.variables)) {
									expression_result.matched = true;
								} else {
									/*
									 * If this case does not match, skip it
									 */
									continue;
								}
							}

							const value_body = element_body.values[expression_value];
							if (value_body.body) {
								if (element_tag === null) {
									retval.push(/** @type {KaiAlphaBodySelectionEntry} */ ({
										type: '@meta:value',
										id: `${element_id}-value-${expression_value}`,
										contents: /** @type {KaiAlphaBodySelectionMetaElement} */ ({
											type: '@meta:value',
											value: expression_value
										}),
										container: element_container
									}));
								}
								retval.push(...body_by_element_tag(element_tag, value_body.body, [...element_container, `Value ${expression_value}`], options));
							}
						}
					}

					/*
					 * If either there is no valid expression or we have not matched any expression
					 * evaluate the default case
					 */
					if (expression_result.valid !== true || expression_result.matched !== true) {
						if (element_body.default && element_body.default.body) {
							if (element_tag === null) {
								retval.push(/** @type {KaiAlphaBodySelectionEntry} */ ({
									type: '@meta:default',
									id: `${element_id}-default`,
									contents: /** @type {KaiAlphaBodySelectionMetaElement} */ ({
										type: '@meta:default'
									}),
									container: element_container
								}));
							}
							retval.push(...body_by_element_tag(element_tag, element_body.default.body, [...element_container, 'Default'], options));
						}
					}
				}
				break;
			case 'loop':
				/*
				 * TODO: Pre-processor expression evaluation might be needed in the future
				 */
				if (element_body.body) {
					retval.push(...body_by_element_tag(element_tag, element_body.body, element_container, options));
				}

				if (element_body.else && element_body.else.body) {
					if (element_tag === null) {
						retval.push(/** @type {KaiAlphaBodySelectionEntry} */ ({
							type: '@meta:else',
							id: `${element_id}-else`,
							contents: /** @type {KaiAlphaBodySelectionMetaElement} */ ({
								type: '@meta:else'
							}),
							container: element_container
						}));
					}
					retval.push(...body_by_element_tag(element_tag, element_body.else.body, [...element_container, 'Else'], options));
				}
				break;
			default:
				break;
		}
	}

	return(retval);
}

/**
 * Get a list of all template elements within a body, expanding template
 * expressions if possible and needed.
 *
 * @param {KaiAlphaBody} body - KaiAlphaBody object
 * @param {Object} [options] - Options
 * @returns {Promise<KaiAlphaBodySelection>} All template elements, with expressions replaced by (possibly duplicate) entries
 */
async function body_templates(body, options = {}) {
	const template_tags = body_by_element_tag('template', body, options.container);
	/*
	 * XXX: TODO: Template Editor currently corrupts saved
	 *            templates, and fills it in with invalid
	 *            template IDs;  Filter this out now until
	 *            validation can be implemented
	 */
	const retval = [];
	for (const item of template_tags) {
		if (!item.contents.expression && (!item.contents.id || item.contents.id === '')) {
			kaialpha.log.debug('[DEBUG WARNING] Dropping misformatted item:', item);

			continue;
		}

		if(!options.get_templates_callback && options.user_id) {
			options.get_templates_callback = async function(filter) {
				const templates = await kaialpha.lib.template.get_user_templates(options.user_id, filter);
				return templates;
			}
		}
		/**
		 * If there's an element with an expression without callback or variables,
		 * skip it, otherwise loop thro it.
		 */
		if (item.contents.expression && (options.get_templates_callback === undefined || options.variables === undefined)) {
			continue;
		}

		if (item.contents.expression && options.get_templates_callback && options.variables) {
			const metadata_expresssion = kaialpha.lib.nunjucks_utils.renderString(item.contents.expression, options.variables);
			const template_options = { fields: ['version'], filter: `metadata.${metadata_expresssion}` };
			const template_info = await options.get_templates_callback(template_options);
			const templates = template_info.templates;
			/**
			 * sort templates by their id(uuid),
			 * so their order remains consistent
			 */
			templates.sort(function (a, b) {
				if (a.id < b.id) {
					return(-1);
				} else if (a.id > b.id) {
					return(1);
				} else {
					return(0);
				}
			});

			templates.forEach(function (template, index) {
				const template_item = object_utils.copy_object(item);
				template_item.contents.id = template.id;
				template_item.contents.version = template.version;
				const replacement_values = {
					id: template.id.replace(/-/g, ''),
					ordinal: index + 1
				};
				template_item.contents.name = kaialpha.lib.nunjucks_utils.renderString(template_item.contents.name, replacement_values);
				delete template_item.contents.expression;
				retval.push(template_item);
			});
		} else {
			retval.push(item);
		}
	}
	return(retval);
}

/*
 * Sortly<->Body
 */
function get_body_sortly_items(body) {
	const retval = [];

	const flattened = body_by_element_tag(null, body);
	for (const item_master of flattened) {
		const item = object_utils.copy_object(item_master);

		switch (item.type) {
			case 'section':
				delete item.contents['body'];
				break;
			case 'switch':
				delete item.contents['values'];
				delete item.contents['default'];
				break;
			case 'loop':
				delete item.contents['body'];
				delete item.contents['else'];
				break;
			case 'template':
				delete item.contents['body'];
				break;
			default:
				break;
		}

		retval.push({
			id: item.id,
			type: item.type,
			contents: item.contents,
			depth: item.container.length - 1
		});
	}

	return(retval);
}

function assemble_body_sortly_items(items, options = {}) {
	options = {
		pristine: true,
		template_body: false,
		...options
	};

	items = object_utils.copy_object(items);

	let retval = null;

	let last_item_depth = 0;
	const current_items = [];

	/** @type any */
	let current_item = {};

	for (const item of items) {
		if (item.depth > last_item_depth) {
			if ((item.depth - last_item_depth) !== 1) {
				throw(new /*kaialpha.User*/Error(`Skip insert of item detected, last_item_depth was ${last_item_depth}, but this item is ${item.depth}; item = ${JSON.stringify(current_item)}`));
			}

			current_items.push(current_item);

			const current_item_wrapper = current_item['body'].slice(-1)[0];
			current_item = current_item_wrapper[Object.keys(current_item_wrapper)[0]];
		} else if (item.depth < last_item_depth) {
			for (let count = (last_item_depth - item.depth); count > 0; count--) {
				current_item = current_items.pop();
			}
		}

		if (item.type === '@meta:value') {
			if (current_item.type !== 'switch') {
				throw(new /*kaialpha.User*/Error(`Error "value" item may only be a child of a "switch" item; item = ${JSON.stringify(current_item)}`));
			}

			if (current_item['values'] === undefined) {
				current_item['values'] = {};
			}
			current_item['values'][item.contents.value] = {
				body: []
			};

			current_items.push(current_item);
			current_item = current_item['values'][item.contents.value];

			last_item_depth = item.depth + 1;
			continue;
		} else if (item.type === '@meta:default') {
			if (current_item.type !== 'switch') {
				throw(new /*kaialpha.User*/Error(`Error "default" item may only be a child of a "switch" item; item = ${JSON.stringify(current_item)}`));
			}
			current_item['default'] = {
				body: []
			};

			current_items.push(current_item);
			current_item = current_item['default'];

			last_item_depth = item.depth + 1;
			continue;
		} else if (item.type === '@meta:else') {
			if (current_item.type !== 'loop') {
				throw(new /*kaialpha.User*/Error(`Error "else" item may only be a child of a "loop" item; item = ${JSON.stringify(current_item)}`));
			}
			current_item['else'] = {
				body: []
			};

			current_items.push(current_item);
			current_item = current_item['else'];

			last_item_depth = item.depth + 1;
			continue;
		}

		if (!['switch', 'loop', 'section', undefined].includes(current_item.type)) {
			if (!options.template_body || current_item.type !== 'template') {
				throw(new Error(`Attempted to create a child of an element with type ${current_item.type}`));
			}
		}

		if (current_item.type === 'switch') {
			if (item.type.substr(0, 6) !== '@meta:') {
				throw(new Error(`Attempted to create a child of an element with type "${current_item.type}", new type is not "@meta:*" but instead "${item.type}"`));
			}
		}

		/*
		 * XXX:TODO: Should this be done in a separate process ?
		 */
		if (options.pristine === true) {
			delete item.contents['$from'];
			delete item.contents['$variable_descriptor'];
		}

		const body_item = {
			[item.id]: item.contents
		};

		if (current_item['body'] === undefined) {
			current_item['body'] = [];
		}

		current_item['body'].push(body_item);

		if (retval === null) {
			retval = current_item['body'];
		}

		last_item_depth = item.depth;
	}

	if (retval === null) {
		retval = [];
	}

	return(retval);
}

function process_body_expressions(body, variables) {
	const retval = {};

	const body_list = body_by_element_tag('switch', body, undefined, {
		evaluate_expressions: true,
		variables: variables
	});

	for (const element of body_list) {
		if (element.type !== 'switch') {
			continue;
		}

		if (element.contents === undefined) {
			continue;
		}

		const variable_name = element.contents.name;

		if (!variable_name) {
			continue;
		}

		/*
		 * As a consequence of passing "evaluate_expression = true" to
		 * body_by_element_tag, expressions are evaluated
		 */
		const variable_value = element.contents.expression_evaluated;

		retval[variable_name.toLowerCase()] = variable_value;
	}

	return(retval);
}

if (_testing) {
	_testing.process_body_expressions = function() {
		const body = [
			{
				"f1e38294-2a9f-493a-94f6-560b1611d80c": {
					"type": "switch",
					"name": "Var1",
					"expression": "111",
					"values": {
						"111": {
							"body": [
								{
									"7eb7fa69-69a4-4b64-8ff8-3053a139684d": {
										"type": "switch",
										"name": "Var2",
										"expression": "332 + 1",
										"values": {
											"333": {
												"body": [
													{
														"6c378607-e4f8-4c28-98c8-882e191f71ec": {
															"type": "switch",
															"name": "Var3",
															"expression": "\"works\""
														}
													}
												]
											},
											"444": {
												"body": [
													{
														"e20d1151-9938-4024-9660-3b3f0b5eb49d": {
															"type": "switch",
															"name": "Var3",
															"expression": "666"
														}
													}
												]
											}
										},
										"default": {
											"body": [
												{
													"882e7672-6ccf-4889-b32e-9905b6c4c289": {
														"type": "switch",
														"name": "Var3",
														"expression": "777"
													}
												}
											]
										}
									}
								}
							]
						},
						"222": {
							"body": [
								{
									"e2aec282-f005-4d31-a88d-9d563d8493ea": {
										"type": "switch",
										"name": "Var3",
										"expression": "444"
									}
								}
							]
						}
					},
					"default": {
						"body": [
							{
								"a7f272b7-92c0-4191-8cac-a3d1d921d2eb": {
									"type": "switch",
									"name": "Var3",
									"expression": "555"
								}
							}
						]
					}
				}
			}
		];

		const check = process_body_expressions(body, {});
		testing_utils.assert.object_equals(check, {
			"var1": "111",
			"var2": "333",
			"var3": "works"
		});

		return(true);
	};
}

/*
 * TODOC
 */
async function process_document_variables(user_id, document_info, options = {}) {
	/*
	 * Default options
	 */
	options = {
		add_missing_variables: undefined,
		document_variable_callback: undefined,
		allow_empty_values: true,
		_state: {
			include_global: true,
			parent: undefined
		},
		get_document_obj: undefined, /* XXX:TODO: This should be removed */
		get_user_document: kaialpha.lib.document.get_user_document,
		get_user_template_from_document: kaialpha.lib.document.get_user_template_from_document,
		get_toplevel_document: get_toplevel_document,
		...options
	}

	/*
	 * If we want to include the global structure, first get the top-level
	 * document and everything under it, then make '__current' point to
	 * the specified document ID
	 */
	const variables = {};

	if (options._state.include_global) {
		let top_document_info;
		if (options.get_document_obj) {
			top_document_info = options.get_document_obj(document_info.document_id, document_info.version_id);
		}

		if (top_document_info === undefined){
			top_document_info = await options.get_toplevel_document(user_id, document_info.document_id, document_info.version_id);
		}

		variables['__current'] = {};
		variables['global'] = {
			...options.document_style
		};
		const tree = await process_document_variables(user_id, {
			document_id: top_document_info.id,
			version_id: top_document_info.version,
			buffer_id: document_info.buffer_id
		}, {
			...options,
			_state: {
				...options._state,
				include_global: false,
				global: variables['global'],
				set_current: {
					object: variables,
					document_id: document_info.document_id,
					version_id: document_info.version_id
				}
			}
		});
		Object.assign(variables.global, tree);

		variables.global['__add_missing_variables'] = options.add_missing_variables;

		return(variables);
	}

	variables['__current'] = variables;
	variables['global'] = options._state.global;

	if (options._state.set_current && options._state.set_current.document_id === document_info.document_id) {
		options._state.set_current.object['__current'] = variables;
	}

	try {
		let document;
		if (options.get_document_obj) {
			document = options.get_document_obj(document_info.document_id, document_info.version_id);
		}

		if (document === undefined) {
			document = await options.get_user_document(user_id, document_info.document_id, document_info.version_id);
		}

		if (document_info.buffer_id !== undefined &&
					buffer_utils.get_item_id_from_buffer_id(document_info.buffer_id) === document.id) {
			const buffer = await kaialpha.lib.document_buffer.get_user_document_buffer(user_id, document_info.buffer_id);
			document.variables = buffer.variables;
		}
		const template = await options.get_user_template_from_document(user_id, document);
		const body = body_serialize(document, template);
		const body_template_options = {
			get_templates_callback: async function(filter) {
				const templates = await kaialpha.lib.template.get_user_templates(user_id, filter);
				return templates;
			}
		};

		const template_subdocument_mapping = {};

		let element_subdocument_mapping = document.subdocuments;
		if (element_subdocument_mapping === undefined || element_subdocument_mapping === null) {
			element_subdocument_mapping = {};
		}

		/*
		 * Get a map of subdocument and it's template id
		 */
		const run_promises_mappings = [];
		for (const element_id of Object.keys(element_subdocument_mapping)) {
			let subdocument_ids = [];
			if (element_subdocument_mapping[element_id].document_id !== undefined) {
				subdocument_ids = element_subdocument_mapping[element_id].document_id;
			}

			if (typeof subdocument_ids === 'string') {
				subdocument_ids = [subdocument_ids];
			}

			/*
			 * Existing documents could have document_id which are mapped to a string,
			 * so in that case, just converting that to an array before parsing,
			 */
			subdocument_ids.forEach(function(subdocument_id) {
				const run_promise = async function() {
					const document = await options.get_user_document(user_id, subdocument_id, 'HEAD');

					if (document.template) {
						template_subdocument_mapping[document.template.id] = subdocument_id;
					}
				}();
				run_promises_mappings.push(run_promise);
			});
		}
		await Promise.all(run_promises_mappings);

		const subtemplates_info = await body_templates(body, body_template_options);
		const run_promises = [];
		subtemplates_info.forEach(function(subtemplate_info) {
			if (element_subdocument_mapping === undefined) {
				kaialpha.log.error(`When trying to get Subdocument Information on Document ID ${document_info.document_id}/${document_info.version_id} we found that "document.subdocuments" was undefined.  Subtemplate Info =`, subtemplate_info);
				return;
			}

			const run_promise = async function() {
				const subdocument_name = subtemplate_info.contents.name;
				const subdocument_global_name = subtemplate_info.contents.global_name;
				const template_id = subtemplate_info.contents.id;
				const subdocument_id = template_subdocument_mapping[template_id];
				const subdocument_version = 'HEAD';

				if (!subdocument_name) {
					return;
				}

				document_info.document_id = subdocument_id;
				document_info.version_id = subdocument_version;
				variables[subdocument_name.toLowerCase()] = await process_document_variables(user_id, document_info, options);

				if (subdocument_global_name) {
					options._state.global[subdocument_global_name.toLowerCase()] = variables[subdocument_name.toLowerCase()];
				}

				variables[subdocument_name.toLowerCase()]['parent'] = variables;
			}();
			run_promises.push(run_promise);
		});
		await Promise.all(run_promises);
		// for backwards compatibility
		const userMetadata = Object.keys(template.metadata?.system ?? {}).length > 0 ? (template.metadata.user ?? {}) : template.metadata;
		if (userMetadata) {
			Object.entries(userMetadata).forEach(function(template_metadata_info) {
				const template_metadata_name = template_metadata_info[0];
				const template_metadata_value = template_metadata_info[1];

				if (template_metadata_value !== '' || options.allow_empty_values) {
					variables[template_metadata_name.toLowerCase()] = template_metadata_value;
				}
			});
		}

		if (body) {
			const current_template_id = template.id;

			if (current_template_id !== undefined) {
				/* XXX:TODO: Why do these specifically want a template ? */
				const reference_variables = await kaialpha.lib.template_utils.process_template_references(user_id, body, current_template_id, null, options);
				Object.assign(variables, reference_variables);

				const nunjucks_styling = await kaialpha.lib.template_utils.process_template_style(user_id, body, current_template_id, null, options);
				Object.assign(variables, nunjucks_styling);
			}
		}

		// Collect template-level datasource variables.
		if (template.variables) {
			for (const template_metadata of Object.entries(template.variables)) {
				const template_variable_name = template_metadata[0];
				const template_variable = template_metadata[1];
				if (template_variable.type === 'datasource') {
					const dataSource = await data_utils.get_data(user_id, template_variable.options.source.replace(/^ka:\/\//, ""));
					variables[template_variable_name.toLowerCase()] = await data_utils.fetch_data(dataSource.link, template_variable.options);
				}
			}
		}

		if (document.variables) {
			const variable_run_promises = [];
			Object.entries(document.variables).forEach(function(document_variable_info) {
				const run_promise = async function() {
					const document_variable_name = document_variable_info[0];
					let document_variable_value = document_variable_info[1];

					if (document_variable_value !== '' || options.allow_empty_values) {
						if (options.document_variable_callback) {
							document_variable_value = await options.document_variable_callback(document_variable_value, template.variables[document_variable_name]);
						}

						variables[document_variable_name.toLowerCase()] = document_variable_value;
					}
				}();
				variable_run_promises.push(run_promise);
			});
			await Promise.all(variable_run_promises);
		}

		if (body) {
			const evaluated_expressions = process_body_expressions(body, variables);
			Object.assign(variables, evaluated_expressions);
		}
	} catch (err) {
		kaialpha.log.debug('Error in process_document_variables: ', err);
	}

	return(variables);
}

function get_value_from_element(element) {
	const element_type = element.type;

	let value;
	switch (element_type) {
		case 'section':
		case 'variable':
		case 'template':
			value = element.name;
			break;
		case 'header':
		case 'footer':
			value = element.value;
			break;
		case 'title':
		case 'table':
			value = element.title;
			break;
		case 'html':
			value = element.text;
			break;
		case 'table_of_contents':
			value = 'Table of Contents';
			break;
		default:
			value = element.name;
			break;
	}

	if (value === undefined) {
		value = `[UNKNOWN ${JSON.stringify(element.type)}]`;
	}

	return value;
}

async function get_data_source_matches(user_id, source) {
	const datasource_items = (await kaialpha.lib.data_utils.list_data(user_id, '/')).items;

	/*
	 * Compute the portion of the URL that is being matched
	 * against wildcards, to compute a name for this element.
	 */
	const star_position = source.indexOf('*');
	const trim_left = source.lastIndexOf('/', star_position) + 1;
	const trim_right = (source.length - star_position - 1) * -1;

	const source_regex = glob_to_regexp(source, {
		extended: true,
		globstar: true
	});

	/* Loop through datasource items and check if url matches source */
	const matches = [];
	for (const item of datasource_items) {
		const item_url = item.url;
		const item_wildcard_match = item_url.match(source_regex);
		if (item_wildcard_match === null) {
			continue;
		}

		const name = item_url.slice(trim_left, trim_right);
		matches.push({
			url: item_url,
			short_name: name
		});
	}

	return(matches);
}

async function fetch_data_source_value(user_id, source, variable_info = {}) {
	/*
	 * If there's a wildcard, iterate over all the matches and create a
	 * structured object.
	 */
	let data = {};
	if (source.includes('*')) {
		/* Get all data sources that match source url */
		const matches = await get_data_source_matches(user_id, source);

		const num_variables = matches.length;
		for (const item of matches) {
			/* Store data source data and metadata in data object */
			data[item.short_name] = await data_utils.fetch_data(item.url, variable_info);
			data.type = 'multi_data_source';
		}

		if (!data['@metadata']) {
			data['@metadata'] = {};
		}

		data['@metadata']['size'] = num_variables;

	} else {
		data = await data_utils.fetch_data(source, variable_info);
	}

	return(data);
}

/*
 * Searches for variable information in the document with the specified ID. Performs
 * a case-insensitive search on variable name
 * @param {string} user_id - the id of the current user
 * @param {string} document_id - the id of the current document
 * @param {string} version_id - the version id of the current document
 * @param {string} variable_name - the name of the variable.
 */
async function variable_info_from_name(user_id, document_id, version_id, variable_name) {
	/*
	 * Get the document and template
	 */
	const document = await kaialpha.lib.document.get_user_document(user_id, document_id, version_id);
	const template = await kaialpha.lib.document.get_user_template_from_document(user_id, document);

	/* ignore any property specifiers,
	 * e.g. myVar.title - we're only interested in 'myVar' part
	 */
	const name_parts = variable_name.split('.')
	const name = name_parts[name_parts.length - 1];

	/*
	 * Get the variable - case insensitively
	 */
	if (template.variables) {
		const variablekey = Object.keys(template.variables).find(variable => variable.match(new RegExp(name, "i")));
		return variablekey ? {name: variablekey, ...template.variables[variablekey].options} : undefined;
	}

	return undefined;
}

/**
 * @typedef {{source: string, name: string}}  variable_info
 * @param {string} user_id user_id for fetching the document
 * @param {string} document_id document_id for fetching the document
 * @param {string} version_id version_id for fetching the document
 * @param {variable_info} variable_info variable info with source and name at least
 * @returns data fetched from the datasource based on the variable_info provided.
 * If the variable source contains Nunjuck expression, it would be evaluated first
 */
async function fetch_document_data_source_value(user_id, document_id, version_id, variable_info) {
	const source_template = variable_info.source;
	/*
	 * Compute the source -- if there are no variable references
	 * use the value as-is
	 */
	let source;
	if (source_template.includes('{{') || source_template.includes('{%')) {
		const missing_value_sentinel = 'missing_6a4d8535-0eac-426c-b190-484e47c752b0';
		const document_info = {
			document_id: document_id,
			version_id: version_id
		};
		const variables = await process_document_variables(user_id, document_info, {
			add_missing_variables: function() { return(missing_value_sentinel); },
			allow_empty_values: false
		});

		source = kaialpha.lib.nunjucks_utils.noKaiAlpha.renderString(source_template, variables);

		/*
		 * Verify that the source does not rely on any missing values
		 */
		if (source.includes(missing_value_sentinel)) {
			throw(new Error(`Unable to fetch data source for ${variable_info.name} -- its source relies on values that have not been supplied`));
		}
	} else {
		source = source_template;
	}

	const data = await fetch_data_source_value(user_id, source, variable_info);

	data['last_updated'] = Date.now(); /* XXX:TODO: Should we use the user's clock for this ? */

	return(data);
}

/*
 * TODOC
 */
async function get_toplevel_document(user_id, document_id, version_id) {
	const document = await kaialpha.lib.document.get_user_document(user_id, document_id, version_id);

	if (!document) {
		throw(new Error(`Failed to fetch document with id ${document_id} and version ${version_id}`));
	}

	if (!document.superdocument) {
		return({
			id: document_id,
			version: version_id
		});
	}

	return(await get_toplevel_document(user_id, document.superdocument.document_id, 'HEAD'));
}

function get_reference_value(element, element_id, value, replacement_values) {
	let format = element.format;
	if (format === undefined) {
		format = '{{value}}';
	}

	let formatted_value;
	if (format === '{{link}}' || replacement_values.type === 'table') {
		formatted_value = `<a href='#${element_id}'>${value}</a>`;
	} else {
		formatted_value = kaialpha.lib.nunjucks_utils.renderString(format, replacement_values);
	}

	return(formatted_value);
}

async function _get_image_data(image_info, isParsed = true) {
	const s3 = new kaialpha.aws.S3();
	const bucket_path = 'images/';

	let key = image_info.key;
	if (!key.includes(bucket_path)) {
		key = `${bucket_path}${image_info.key}`;
	}

	const file_data = await s3.getObject({
		Bucket: kaialpha.configuration.images_s3_bucket,
		Key: key
	}).promise();

	const file_data_b64 = file_data.Body.toString('base64');

	if (isParsed) {
		return({
			data: 'data:' + image_info.type + ';base64,' + file_data_b64
		});
	}

	return({
		data: file_data_b64
	});
}

function get_image_tag_after_setting_source(source) {
	return(`<img style="max-width: 100%" src="${source}" alt="Unammed Image">`);
}

function check_image_type_and_process_image_object(object) {
	if (object.type && object.type === 'image') {
		const image = get_image_tag_after_setting_source(object.image);
		return image;
	}
	return(JSON.stringify(object));
}

function add_toString_to_all_sub_objects(multi_source_object) {
	for (const image_object in multi_source_object) {
		if (multi_source_object[image_object].type === 'image') {
			multi_source_object[image_object].toString = function () {
				return check_image_type_and_process_image_object(this);
			};
		}
	}
	return multi_source_object;
}

async function render_document_processing_variables(user_id, document_info, document, options = {}) {
	const variables = await process_document_variables(user_id, document_info, {
		document_style: {
			/* This is first place to define document style for PDF/HTML
			 * Additional style can be supplied as options if needed
			 * Supports styling for HTML and buffer HTML both
			 */

			/* XXX:TODO - Add additional styles (like margin and/ line-height) to match Otsuka Style guide
			 *  For keyname (i.e. default_style, html_style) refer styles.njk
			 */

			/* HTML/PDF style information - add/update more style as per Otsuka Style guide */
			default_style:{
				'font_family': '"Times New Roman"',
				'font_size' : '12pt',
				'line_height': '1.25',
				'padding_top': '0pt',
				'padding_bottom': '6pt'
			},
			section_style:{
				'font_family': '"Arial"',
				'font_size': '14pt',
				'font_weight': 'bold',
				'line_height': '1',
				'padding_bottom': '12pt'
			},
			title_style:{
				'font_family': '"Arial"',
				'font_size': '14pt',
				'font_weight': 'bold',
				'padding_bottom': '12pt'
			},
			...options.style /* Pass any additional custom style */
		},
		add_missing_variables: function(variable_name) {
			return(`<span class="missingvalue"><mark>{{${variable_name}}}</mark></span>`);
		},
		document_variable_callback: async function (variable, variable_info) {
			if (!variable_info) {
				kaialpha.log.error(`While processing document ${document_info.document_id}/${document_info.version_id} we came upon a variable ${variable} which is not defined in the template!`);
			}

			if (variable_info && variable_info.type === "image") {
				return(`<img style="max-width: 100%" src="${variable.image}">`);
			}

			if (variable_info && variable_info.type === 'reference') {
				const replacement_values = {
					value: variable.value,
					type: variable.type,
					parent_value: variable.parent
				};

				const reference_value = get_reference_value(variable, variable.element_id, variable.value, replacement_values);

				return(reference_value);
			}

			if (!(variable instanceof Object)) {
				return(variable);
			}

			if (typeof(variable) === 'string') {
				return(variable);
			}

			if (variable_info && variable_info.type === 'datasource') {
				if (variable.type === 'multi_data_source') {
					variable = add_toString_to_all_sub_objects(variable);
				}
				variable.toString = function () {
					return check_image_type_and_process_image_object(this);
				};

				const data = variable.data;
				//Table PDF/HTML styling - remove system added columns
				variable.data = data.map(row => {
					Object.keys(row).filter(cellItem => {
						if (cellItem.includes('__row_header_') || typeof row[cellItem] === 'object') {
							delete row[cellItem];
						}
						return cellItem;
					})
					return row;
				})
			}

			if (variable_info && variable_info.type === 'expression') {
				return(variable.options.expression);
			}

			return(variable);
		}
	});

	const template = await kaialpha.lib.document.get_user_template_from_document(options.user_id, document);
	const templateTree = await getTemplateTree(user_id, template);
	try{

		const nunjucks_file = await kaialpha.lib.generator.generateNunjucksFromDocument(document, {
			user_id: user_id,
			type: 'document',
			section_map: generate_numbering(
				body_serialize(document, template),
				templateTree
			)
		});

		const html_file = await kaialpha.lib.generator.generateHTML(nunjucks_file, variables, {
			get_user_list_entries: async function(list_type, list_id, list_version) {
				return(await kaialpha.lib.list_utils.get_user_list_entries(user_id, list_type, list_id, list_version));
			}
		});
		fs.unlinkSync(nunjucks_file);

		return(html_file);

	} catch(error){
		kaialpha.lib.debug.log('error while generating HTML ', error);
		throw (new Error(`error while generating HTML ', ${error.message}`))
	}
}

/**
 * Get all the contents of a template and it's sub-templates.
 *
 * @param {string} user_id - Current user id
 * @param {any} template - The parent template
 * @param {boolean} isRoot - Flag to indicate that this is the root template for this tree
 * @returns {Promise<any[]>} - Flat array of templates contained within the tree of the passed template
 */
async function getTemplateTree(user_id, template) {

	const templateTree = [template];

	async function getTemplateTree_recursive(body) {
		await Promise.all(body.map(async (bodyEntry) =>{
			const element = Object.values(bodyEntry)[0];
			if (element.type === 'section') {
				if (element.body !== undefined){
					templateTree.concat(await getTemplateTree_recursive(element.body));
				}
			}
			if (element.type === 'template') {
				const subTemplate = await kaialpha.lib.template.get_user_template(user_id, element.id, element.version);
				templateTree.push(subTemplate);
				if (subTemplate.body !== undefined){
					templateTree.concat(await getTemplateTree_recursive(subTemplate.body));
				}
			}
		}));
	}
	if (template.body !== undefined){
		await getTemplateTree_recursive(template.body)
	}
	return templateTree;
}

function generate_numbering(body, templateTree) {

	let counter = '0';
	let section_map = {};
	let sectionDepth = 0;

	/**
	 * Recursive function to generate sequence number
	 * for each section and sub-section by recursively iterating through template and section's body
	 */
	function generate_numbering_recursive(body, templateTree) {
		for (const index in body) {
			const body_element = body[index];
			const body_element_values = Object.values(body_element)[0];

			if (body_element_values.type === 'section') {
				const element_id = Object.keys(body_element)[0];

				if (sectionDepth > 0) {

					const counterAsArray = counter.split('.'); //['2']
					let sectionCounter = sectionDepth; // 1
					while (sectionCounter > 0) {
						if (counterAsArray[sectionCounter] === undefined){
							counterAsArray[sectionCounter] = '0';
						}
						sectionCounter--;
					}
					counter = counterAsArray.join('.');
					// Grab the last number in the section string.
					const last_digit = counter.substring(counter.lastIndexOf('.') + 1);
					const last_digit_as_int = parseInt(last_digit);
					const new_value = last_digit_as_int + 1;
					counter = counter.substring(0, counter.lastIndexOf('.') + 1) + new_value.toString();
					section_map[element_id] = counter;

				} else {
					const first_digit = counter.indexOf('.') > 0 ? counter.substring(0, counter.indexOf('.')) : counter;
					const first_digit_as_int = parseInt(first_digit);
					const new_value = first_digit_as_int + 1;
					counter = new_value.toString();
					section_map[element_id] = counter;
				}

				if (body_element_values.body !== undefined) {
					sectionDepth++;
					const section_body = body_element_values.body;
					section_map = generate_numbering_recursive(section_body, templateTree);
					sectionDepth--;
				}
			}

			if (body_element_values.type === 'template' && templateTree) {
				const subTemplate = templateTree.filter((template) => template.id === body_element_values.id && template.version === body_element_values.version);
				if (subTemplate.length > 0){
					section_map = generate_numbering_recursive(subTemplate[0].body, templateTree);
				}

			}
		}
		return (section_map);
	}

	section_map = generate_numbering_recursive(body, templateTree);

	return(section_map);
}

/*
 * Compare if a given function when converse to another return the original value
 *
 * Return value: boolean
 */
if (_testing) {
	/** @type any[] */
	const body = [
		{
			[uuid.v4()]: {
				type: 'title',
				title: 'A Report for Customer {{CustomerID}}'
			}
		},
		{
			[uuid.v4()]: {
				type: 'variable',
				name: 'CustomerID'
			}
		},
		{
			[uuid.v4()]: {
				type: 'variable',
				name: 'CustomerType'
			}
		},
		{
			[uuid.v4()]: {
				type: 'html',
				text: 'This is a report for Customer {{CustomerID}} ({{CustomerType}})'
			}
		}
	];

	_testing._verify_condition_return_original_input = function () {

		const sortly = get_body_sortly_items(body);
		const body_check = assemble_body_sortly_items(sortly);

		/* istanbul ignore next */
		/* This either yeilds true or throws error which is not needed in coverage report */
		if (kaialpha.lib.object_utils.object_equals(body, body_check)) {
			return(true);
		} else {
			throw(new Error(`When comparing get_body_sortly_items converse to assemble_body_sortly_items got different from origin body  but was expected to be equal`));
		}
	};

	_testing.body_serialize = function () {

		// @ts-expect-error
		let result = body_serialize({ 'body_extend': body });

		/* istanbul ignore if */
		if (!Array.isArray(result)) {
			throw new Error("body_serialize should yeild an array of body_extend from document but found different");
		}

		// @ts-expect-error
		result = body_serialize(undefined, { 'body': body });

		/* istanbul ignore if */
		if (!Array.isArray(result)) {
			throw new Error("body_serialize should yeild an array of body from template but found different");
		}

		return true;
	};

	_testing.body_serialize_with_both_body = function () {
		try {
			// @ts-expect-error
			body_serialize({ 'body_extend': body }, { 'body': body });
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error("Both template and document has body hence this should have thrown error");
	};

	_testing.body_serialize_with_no_body = function () {
		// @ts-expect-error
		const result = body_serialize({ 'body_extend': 'something' }, { 'body': 'something' });

		if (Object.keys(result).length === 0) {
			return(true);
		}

		/* istanbul ignore next */
		throw new Error("Both template and document has no body hence this should returned an empty set");
	};

	_testing.body_element_ids = function () {
		const result = body_element_ids(body);

		/* istanbul ignore if */
		if (result.length !== body.length) {
			throw new Error("Body element ids shoulb match with body elements, but missing here");
		}

		return true;
	};

	_testing.body_element_ids_with_extra_nested_element = function () {
		const newArray = [...body];

		newArray.push({
			[uuid.v4()]: {
				'type': 'section',
				'name': 'Section',
				'body': [body[0]]
			}
		});

		const result = body_element_ids(newArray);

		/* istanbul ignore if */
		if ((result.length !== newArray.length + 1)) {
			throw new Error("Should have the subsection template id, but not found here");
		}

		return true;
	};

	_testing.body_by_element_tag_fallback_test_1 = function () {
		const result = body_by_element_tag(undefined, null);

		/* istanbul ignore if */
		if (result.length !== 0) {
			throw new Error("There is no body element here ");
		}

		return true;
	};

	_testing.body_by_element_tag_fallback_test_2 = function () {
		// @ts-expect-error
		const result = body_by_element_tag('template', { 'test': 'text' });

		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error(`There should not be any result but got this ${JSON.stringify(result)}`);
		}

		return true;
	};

	_testing.body_by_element_tag_fallback_test_3 = function () {
		// @ts-expect-error
		const result = body_by_element_tag('template', { 'test': 'text' }, undefined, { evaluate_expressions: true });

		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error(`There should not be any result but got this ${JSON.stringify(result)}`);
		}

		return true;
	};

	_testing.body_by_element_tag_fallback_test = function () {
		/** @type {KaiAlphaBody} */
		const custom_body = [
			{
				[uuid.v4()]: {
					type: 'section',
					text: 'Section',
					body: [
						{
							[uuid.v4()]: {
								type: 'html',
								text: 'Something'
							}
						}
					]
				}
			}
		];

		const result = body_by_element_tag('template', custom_body);

		/* istanbul ignore if */
		if (result.length !== 0) {
			throw new Error("There is no body element here ");
		}

		return true;
	};

	_testing.body_by_element_tag_fallback_test_with_not_identified_type = function () {
		/** @type {KaiAlphaBody} */
		const custom_body = [
			{
				[uuid.v4()]: {
					type: 'html',
					text: 'OtherSection'
				}
			}
		];

		const expected_result = {
			"contents": { type: "html", text: "OtherSection" }
		};

		const result = body_by_element_tag(null, custom_body, undefined, { evaluate_expressions: true });

		/* istanbul ignore if */
		if (!kaialpha.lib.object_utils.object_equals(result[0].contents, expected_result.contents)) {
			throw new Error(`While doing body_by_element_tag we got ${JSON.stringify(result)} but expected ${JSON.stringify(expected_result)}`);
		}

		return true;
	};

	_testing.body_by_element_tag_switch_type_test_2 = function () {
		/** @type {KaiAlphaBody} */
		const custom_body = [
			{
				[uuid.v4()]: {
					type: 'switch',
					expression: '1 * 1',
					values: {
						'1': {
							body: [{
								[uuid.v4()]: {
									type: 'html',
									text: '<p></p>'
								}
							}]
						}
					}
				}
			}
		];

		const result = body_by_element_tag(null, custom_body);

		/* istanbul ignore if */
		if (result[0].type !== "switch") {
			throw new Error(`While testing body_by_element_tag expected type as switch but found ${result[0].type}`);
		}

		return true;
	};

	_testing.body_by_element_tag_switch_type_test_3 = function () {
		/** @type {KaiAlphaBody} */
		const custom_body = [
			{
				[uuid.v4()]: {
					type: 'loop',
					expression: '2',
					else: {
						body: [{
							[uuid.v4()]: {
								type: 'section',
								body: [{
									[uuid.v4()]: {
										type: 'html',
										text: '</p>'
									}
								}]
							}
						}]
					}
				}
			}
		];

		const result = body_by_element_tag(null, custom_body);

		/* istanbul ignore if */
		if (result[0].type !== 'loop' && result[1].type !== '@meta:else') {
			throw new Error(`While testing body_by_element_tag_switch_type_test_3 with custom body we found ${result[0].type} and ${result[1].type} instead of loop and meta:else`);
		}
		return true;
	};

	_testing.body_templates_fallback = async function () {
		const result = await body_templates(body);

		/* istanbul ignore if */
		if (result.length !== 0) {
			throw new Error(`There is no template here but the result yeilds one, which is wrong: ${JSON.stringify(result)}`);
		}

		return true;
	};

	_testing.body_templates_test = async function () {
		const newArray = [...body];

		newArray.push({
			[uuid.v4()]: {
				'type': 'template',
				'id': 'template-id',
			}
		});

		const result = await body_templates(newArray);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw new Error(`Templates should be present in this function: ${JSON.stringify(result)}`);
		}

		return true;
	};

	_testing.body_templates_test_without_id = async function () {
		const newArray = [...body];

		newArray.push({
			[uuid.v4()]: {
				'type': 'template',
				'id': '',
			}
		});

		const result = await body_templates(newArray);

		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error("body_templates_test_without_id failed ");
		}

		return true;
	};

	_testing.body_element_map_test = function () {
		const result = body_element_map(body);

		/* istanbul ignore if */
		if (Object.keys(result).length !== body.length) {
			throw new Error("Body elements should match with body unpack elements but found different");
		}
		return true;
	};

	_testing.get_body_sortly_items_test1 = function () {
		const custom_body = [
			{
				[uuid.v4()]: {
					type: 'section',
					text: 'Section',
					body: [
						{
							[uuid.v4()]: {
								type: 'html',
								text: 'Something'
							}
						},
						{
							[uuid.v4()]: {
								type: 'loop',
								body:[]
							}
						},
						{
							[uuid.v4()]: {
								type: 'switch',
								values: '1, 2, 3',
								contents: []
							}
						}
					]
				}
			}
		];

		const result = get_body_sortly_items(custom_body);

		/* istanbul ignore if */
		if (result[0].type !== 'section') {
			throw new Error(`As per body items section should have came first`);
		}

		return true;
	};

	_testing.get_assemble_body_sorty_items_loop = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'section',
				contents: { type: 'section', text: 'Section' },
				depth: 0
			},
			{
				id: '6daba29b-5331-4593-b9de-66ca69671388',
				type: 'html',
				contents: { type: 'html', text: 'Something' },
				depth: 1
			}
		];

		const result = assemble_body_sortly_items(body_items);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw new Error(`Expected a single list item but found many ${JSON.stringify(result)}`);
		}

		return true;
	};

	_testing.get_assemble_body_sorty_items_exceptions1 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'section',
				contents: { type: 'section', text: 'Section' },
				depth: 0
			},
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'html',
				contents: { type: 'html', text: 'Section' },
				depth: 2
			}
		];
		try {
			assemble_body_sortly_items(body_items);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected exception as depth is not in proper format but function passed`);
	};

	_testing.get_assemble_body_sorty_items_exceptions2 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:value',
				contents: { type: 'section', text: 'Section' },
				depth: 0
			}

		];
		try {
			assemble_body_sortly_items(body_items);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected exception as depth is not in proper format but function passed`);
	};

	_testing.get_assemble_body_sorty_items_exceptions3 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:default',
				contents: { type: 'section', text: 'Section' },
				depth: 0
			}

		];
		try {
			assemble_body_sortly_items(body_items);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected exception as depth is not in proper format but function passed`);
	};

	_testing.get_assemble_body_sorty_items_exceptions4 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'switch',
				contents: { type: 'switch', values: [1, 2, 3] },
				depth: 0
			},
			{
				id: 'd1cb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:value',
				contents: {value: 1},
				depth: 1
			},
			{
				id: 'd3cb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:default',
				contents: {value: 1},
				depth: 1
			}

		];
		const result = assemble_body_sortly_items(body_items);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw new Error(`Expected a single list item but found many ${JSON.stringify(result)}`);
		}

		return true;
	};

	_testing.get_assemble_body_sorty_items_exceptions5 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'switch',
				contents: { type: 'switch'},
				depth: 0
			},
			{
				id: 'd1cb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:value',
				contents: {value: 1},
				depth: 1
			},
		];
		const result = assemble_body_sortly_items(body_items);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw new Error(`Expected a single list item but found many ${JSON.stringify(result)}`);
		}

		return true;
	};

	_testing.get_assemble_body_sorty_items_loop_exceptions1 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'loop',
				contents: {
					type: 'loop', body: []
				},
				depth: 0
			},
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:else',
				contents: {
					type: 'loop', body: []
				},
				depth: 1
			}
		];
		const result = assemble_body_sortly_items(body_items);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw new Error(`Expected a single list item but found many ${JSON.stringify(result)}`);
		}

		return true;
	};

	_testing.get_assemble_body_sorty_loop_exceptions2 = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: '@meta:else',
				contents: { type: 'section', text: 'Section' },
				depth: 0
			}

		];
		try {
			assemble_body_sortly_items(body_items);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected exception as depth is not in proper format but function passed`);
	};

	_testing.get_assemble_body_without_correct_type = function () {
		const body_items = [
			{
				id: 'dbcb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'switch',
				contents: { type: 'switch'},
				depth: 0
			},
			{
				id: 'd1cb3fc5-f158-45ac-abfb-1efd5ff8e0e2',
				type: 'html',
				contents: {},
				depth: 1
			},
		];
		try {
			assemble_body_sortly_items(body_items);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected exception as depth is not in proper format but function passed`);
	};

	_testing.assemble_body_sortly_items_without_items = function () {
		const result = assemble_body_sortly_items([]);
		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error(`Expected an empty element but found ${JSON.stringify(result)}`);
		}
		return true;
	};

	_testing.get_value_from_element_test1 = function () {
		const elements = [
			{ type: 'section', name: 'Section1', result: 'Section1' },
			{ type: 'header', value: 'Header1', result: 'Header1' },
			{ type: 'title', title: 'Title1', result: 'Title1' },
			{ type: 'html', text: 'HTML', result: 'HTML' },
			{ type: 'default', name: 'Default', result: 'Default' },
			{ type: 'default', result:  `[UNKNOWN ${JSON.stringify('default')}]`}
		];

		for (const element of elements) {
			const result = get_value_from_element(element);

			/* istanbul ignore if */
			if (result !== element.result) {
				throw new Error(`Should have ${element.result} but got ${result}`);
			}
		}

		return true;
	};

}

function _get_workflow_options(options, document_id, version_id) {
	options = {
		slot_name: 'default',
		type: 'document',
		id: document_id,
		version: version_id,
		get_user_template_from_document: async function(user_id, document_info) {
			return(await kaialpha.lib.document.get_user_template_from_document(user_id, document_info));
		},
		get_user_item: async function(user_id, document_id, version_id) {
			return(await kaialpha.lib.document.get_user_document(user_id, document_id, version_id));
		},
		load_workflow_state: async function(user_id) {
			const document_info = await options.get_user_item(user_id, options.id, options.version);
			const slot_name = options.slot_name;

			if (document_info && document_info.workflow && document_info.workflow[slot_name]) {
				if (options.rerun !== true) {
					return(document_info.workflow[slot_name]);
				}
			}
			// Start running the workflow if one is not already running
			const start_slot_name = slot_name === 'default' ? '@default_new_document' : slot_name;
			return await kaialpha.lib.workflow_utils.load_workflow_state(user_id, start_slot_name);
		},
		/**
		 * Applies diff on current document. `options.version` is auto-updated
		 * to reflect latest version. If the options object is cloned before using,
		 * you need to handle the update to cloned options object manually.
		 */
		apply_diff: async function(user_id, summary, diff, _diff_options) {
			const retval = await kaialpha.lib.document.apply_diff_user_document(user_id, options.id, options.version, summary, diff, _diff_options);

			options.update_version(retval);

			return(retval);
		},
		save_workflow_state: async function(user_id, new_state) {
			const slot_name = options.slot_name;

			const summary = 'Updated Workflow State';
			const diff = {
				delete: {
					workflow: {
						[slot_name]: null
					}
				},
				change: {
					workflow: {
						[slot_name]: new_state
					}
				}
			};

			return(await options.apply_diff(user_id, summary, diff, {
				update_item_disable_permissions_check: true
			}));
		},
		update_version: function(new_version) {
			/**
			 * Note: This only updates the current reference to options.
			 * If the options are cloned, the version needs to be updated
			 * in the cloned object as well.
			 */
			options.version = new_version.version;

			return({
				id: options.id,
				version: options.version
			});
		},
		...options
	};

	return(options);
}

/* XXX:TODO: Move post_document_workflow_event to lib/document since it can never be called client side */
async function post_document_workflow_event(user_id, document_id, version_id, event, args = {}, options = {}) {
	options = _get_workflow_options(options, document_id, version_id);

	return(await kaialpha.lib.workflow_utils.post_workflow_event(user_id, event, args, options));
}

/* XXX:TODO: Move complete_document_workflow_event to lib/document since it can never be called client side */
async function complete_document_workflow_event(user_id, document_id, version_id, event, args = {}, options = {}) {
	options = _get_workflow_options(options, document_id, version_id);

	return(await kaialpha.lib.workflow_utils.complete_workflow_event(user_id, event, args, options));
}

async function get_document_workflow_variable(user_id, document_id, version_id, name, options = {}) {
	options = _get_workflow_options(options, document_id, version_id);

	return(await kaialpha.lib.workflow_utils.get_workflow_variable(user_id, name, options));
}

async function get_document_workflow_ui(user_id, document_id, version_id, options = {}) {
	options = _get_workflow_options(options, document_id, version_id);

	return(await kaialpha.lib.workflow_utils.get_workflow_ui(user_id, options));
}

async function update_document_workflow_state(user_id, document_id, version_id, options = {}){
	options = _get_workflow_options(options, document_id, version_id);
	return await kaialpha.lib.workflow_utils.update_document_workflow_state(user_id, options);
}

function compute_content_type(template) {
	if (!template) {
		return('static');
	}

	const template_body = template.body;
	if (!template_body) {
		return('static');
	}

	const body = body_by_element_tag(null, template_body);

	/* Content type is static by default */
	let content_type = 'static';

	for (const element of body) {
		if (element.type === 'table') {
			return('datalinked');
		}
	}

	/* Check for variable types */
	if (template && template.variables) {
		for (const variable_name in template.variables) {
			const variable_info = template.variables[variable_name];
			const variable_type = variable_info.type;

			if (variable_type === 'datasource') {
				content_type = 'datalinked';
				break;
			}

			if (!variable_info.mandatory) {
				content_type = 'user_entered';
			}
		}
	}

	return(content_type);
}

/**
 * Get a document and its template from a document ID and version
 *
 * @param {KaiAlphaUserID} user_id - User ID
 * @param {KaiAlphaDocumentID} id - Document ID
 * @param {KaiAlphaVersionID} version - Document Version
 */
async function get_document_with_template(user_id, id, version = 'HEAD') {
	const document = await kaialpha.lib.document.get_user_document(user_id, id, version);
	const template = await kaialpha.lib.document.get_user_template_from_document(user_id, document);

	return({ document, template });
}

async function document_expand(user_id, document, template = undefined, options = undefined) {
	let templateObject = template;
	if (!templateObject) {
		templateObject = await kaialpha.lib.document.get_user_template_from_document(user_id, document);
	}

	const serializedBody = body_serialize(document, templateObject);
	const document_info = {
		document_id: document.id,
		version_id: document.version
	};
	const variables = await process_document_variables(user_id, document_info);
	const bodyElements = body_by_element_tag(null, serializedBody, undefined, { evaluate_expressions: true, variables });

	const topLevelElements = bodyElements.filter(({ container }) => container.length === 1 && container.includes('Document'));
	const body = topLevelElements.map(({ id, contents }) => {
		return({ [id]: contents });
	});

	return _body_expand(body, { ...options, user_id, documentId: document.id, subdocuments: document.subdocuments });
}

async function _body_expand(body = [], options = { }) {
	const { user_id = null, documentId = '', subdocuments = { }, depth = 0, idSuffix} = options;
	let elements = [];
	for (const element of body) {
		const elementId = Object.keys(element)[0];
		const objectId = idSuffix ? `${documentId}_${elementId}_${idSuffix}` : `${documentId}_${elementId}`;
		const currentObject = element[elementId];
		const { type } = currentObject || { };

		if (type === 'template') {
			if (!subdocuments[elementId]) {
				const template = await kaialpha.lib.template.get_user_template(user_id, currentObject.id, currentObject.version);
				const body = await _body_expand(template.body, options);

				elements = [...elements, ...body ];
				continue;
			}

			const subDocumentIds = subdocuments[elementId].document_id || [];
			const subDocumentPromises = subDocumentIds.map((id) => get_document_with_template(user_id, id));
			const subDocuments = await Promise.all(subDocumentPromises);

			const expandedSubDocumentPromises = subDocuments.map(({ document, template }) => document_expand(user_id, document, template, options));
			const expandedSubDocumentBodys = await Promise.all(expandedSubDocumentPromises);

			for (const expandedSubDocumentBody of expandedSubDocumentBodys) {
				elements = [...elements, ...expandedSubDocumentBody ];
			}
		} else if (type === 'switch' && currentObject && currentObject.values[currentObject.expression_evaluated]) {
			const switchValues = currentObject.values;
			const switchValue = switchValues[currentObject.expression_evaluated];
			const body = await _body_expand(switchValue.body, options);

			elements = [...elements, ...body];
		} else if (type === 'loop' && currentObject && currentObject.expression_evaluated) {
			const loopItems = currentObject.expression_evaluated.split(',');

			for (const item of loopItems) {
				const body = await _body_expand(currentObject.body, { ...options, idSuffix: item});
				const bodyWithIds = body.map((bodyItem) => {
					const bodyItemId = Object.keys(bodyItem)[0];

					return({ [bodyItemId]: { ...bodyItem[bodyItemId], iteration: item } });
				});

				elements = [...elements, ...bodyWithIds];
			}
		} else if (type === 'section' && currentObject) {
			const body = await _body_expand(currentObject.body, { ...options, depth: depth + 1 });

			elements = [...elements, { [objectId]: { documentId, elementId, ...currentObject, body } }];
		} else {
			elements = [...elements, { [objectId]: { documentId, elementId, ...currentObject } }];
		}
	}

	return elements;
}

/**
 * Get differences between two document versions.
 * @param {KaiAlphaDocument} old_version
 * @param {KaiAlphaDocument} new_version
 * @returns {{
 * changed?: Object,
 * added?: Object,
 * deleted?: Object
 * }}
 */
function get_document_diff(old_version, new_version) {
	const diff = kaialpha.lib.diff_utils.diff_objects(old_version, new_version);

	if (!diff.changed) {
		diff.changed = {};
	}
	if (!diff.added) {
		diff.added = {};
	}
	if (!diff.deleted) {
		diff.deleted = {};
	}

	/*
	 * Delete spurious differences
	 */
	delete diff.changed['version'];
	delete diff.changed['previous_version'];

	return (diff);
}

/**
 * Merge two document versions using 3 way merge
 * @param {KaiAlphaDocument} version_1
 * @param {KaiAlphaDocument} version_2
 * @param {KaiAlphaDocument} version_base
 * @returns {KaiAlphaDocument}
 */
function merge_document_objects(version_1, version_2, version_base) {
	version_1 = object_utils.copy_object(version_1);
	version_2 = object_utils.copy_object(version_2);
	version_base = object_utils.copy_object(version_base);

	/**
	 * handle comments merge separately from document to account for each individual comment change
	 * rather than treating to all comments as a single change
	 */
	const merged_comments = kaialpha.lib.diff_utils.merge_objects(
		version_1.comments || {},
		version_2.comments || {},
		version_base.comments || {},
		{
			elaborate_arrays: true,
		}
	);

	// delete unnecessary fields
	['version', 'previous_version', 'child_resources', 'comments'].forEach(function (element) {
		delete version_1[element];
		delete version_2[element];
		delete version_base[element];
	});

	const merged = kaialpha.lib.diff_utils.merge_objects(version_1, version_2, version_base);
	merged.comments = merged_comments;

	return merged;
}

/**
 * Resolve merge conflicts when saving document.
 * This is similar to 3 way merge in git.
 * @param {KaiAlphaDocument} local_document
 * @param {KaiAlphaVersionID} base_version
 * @returns {Promise<KaiAlphaDocument>}
 */
async function resolve_document_merge_conflicts(user_id, local_document, base_version) {
	/*
	 * Get the latest version of the document, as well as the shared ancestor (base)
	 */
	const document_id = local_document.id;
	const [base_document, remote_document] = await Promise.all([
		kaialpha.lib.document.get_user_document(user_id, document_id, base_version),
		kaialpha.lib.document.get_user_document(user_id, document_id, 'HEAD'),
	]);

	/*
	 * If the new document is what we already expected, something else must be wrong, (may be a network error?)
	 * so don't try to merge (it would be pointless).  Instead, re-save without
	 * the merge function being called.
	 */
	if (remote_document.version === base_version) {
		local_document.version = remote_document.version;
		return local_document;
	}

	/*
	 * If the documents don't actually differ, just update our version
	 */
	const diff = get_document_diff(local_document, remote_document);
	if (Object.keys(diff.changed).length === 0 && Object.keys(diff.added).length === 0 && Object.keys(diff.deleted).length === 0) {
		local_document.version = remote_document.version;
		return local_document;
	}

	/*
	 * Construct the new document by merging
	 */
	try {
		const merged_document = merge_document_objects(local_document, remote_document, base_document);
		merged_document.version = remote_document.version;
		return merged_document;
	} catch (merge_error) {
		kaialpha.lib.debug.log('Merge error, when saving document:', JSON.stringify({ merge_error, diff }, null, 2));
		throw new kaialpha.lib.error_utils.VersionMergeError(merge_error);
	}
}

/**
 * @typedef {Object} MergeIndicatorsInputOutput
 * @property {string} type - Type of element
 * @property {object} [merge]
 * @property {boolean} [merge.start]
 * @property {boolean} [merge.end]
 * @property {object} [_merged_with]
 * @property {boolean} [_merged_with.top]
 * @property {boolean} [_merged_with.bottom]
 */
/**
 * Adds merge indicators on HTML elements to apply appropriate styles
 * to show them as merged.
 *
 * @param {{[key: string]: MergeIndicatorsInputOutput}[]} constructed_body_local
 * @returns {{[key: string]: MergeIndicatorsInputOutput}[]}
 *
 * @remarks This is built to support merging complex structured tables
 * created using rich text editor in HTML elements.
 * More work may be need to support merging paragraphs.
 *
 * @remarks During template upgrade any comment found on text that is not
 * present in html element will be considered orphaned and will be auto resolved.
 * To avoid unintentionally auto resolving comments that are left on text
 * of merged elements, the merge is visually represented using css instead of
 * actually merging HTML elements into one.
 * This way the comments will be left on the correct element id.
 */
function get_merge_indicators_for_html_elements(constructed_body_local) {

	return constructed_body_local.map((current_element_map, current_index) => {
		const current_element = Object.values(current_element_map)[0];
		const last_processed = current_index > 0 ? Object.values(constructed_body_local[current_index - 1])[0] : undefined;

		if (current_element.type !== 'html') {
			// if current element cannot be merged
			// return the element as is
			return current_element_map;
		}

		// check if current_element needs to be merged with previous element
		if (current_element.merge?.start && last_processed?.merge?.end) {
			// add merge indicators
			last_processed._merged_with = { bottom: true, top: last_processed._merged_with?.top };
			current_element._merged_with = { top: true, bottom: current_element._merged_with?.bottom };
		}

		return current_element_map;
	});
}

/**
 * KaiAlpha Body Object
 */
class Body {
	/**
	 * @param {KaiAlphaBody | Body} body                          - The body to process, if it is a Body then we just duplicate it into a new instance
	 * @param {{ type: string, container?: any }} bodyFor  - A link to the thing which instantiated this object
	 */
	constructor(body, bodyFor = { type: 'template' }) {
		/**
		 * Recognize that we are processing one of our own
		 *
		 * @private
		 * @type {string}
		 */
		this._typeSignature = '2f5a9b7e-9ac9-4848-944d-130fcb77e0b9';
		if (body && "_typeSignature" in body && body._typeSignature === this._typeSignature) {
			this._value = kaialpha.lib.object_utils.copy_object(body._value);
			this._bodyFor = body._bodyFor;

			return;
		}

		/**
		 * @protected
		 * @type {KaiAlphaBody}
		 */
		this._value = kaialpha.lib.object_utils.copy_object(body);

		/**
		 * @protected
		 * @type {any}
		 */
		this._bodyFor = bodyFor;
	}

	/**
	 * Returns the object containing the body value (a KaiAlpha body) as
	 * its normal nested structure.
	 *
	 * @returns {KaiAlphaBody}
	 */
	get value() {
		return(this._value);
	}

	set value(_ignored_body) {
		throw(new Error('Body may not be modified directly'));
	}

	/**
	 * Returns the object containing the body value (a KaiAlpha body), as
	 * an in-order array of elements (flattened)
	 *
	 * @param {string | null} [type] - Type of element, or all if null
	 * @returns {KaiAlphaBodySelection} An array of all elements, flattened, in-order
	 */
	flat(type = null) {
		const flat_body = body_by_element_tag(type, this.value);

		return(flat_body);
	}

	/**
	 * Callback for processing an element
	 *
	 * @callback elementCallback
	 * @param {KaiAlphaBodySelectionEntry} The KaiAlpha element
	 * @returns {any} Return value
	 */
	/**
	 * Iterate over every element in the body, in order.
	 *
	 * @param {elementCallback} lambda - Callback to invoke
	 * @param {{ type?: null | string }} options - Options, if type is specified select only that type of element
	 * @returns {any[]} Collected return values from lambda
	 */
	forEach(lambda, options = {}) {
		options = {
			type: null,
			...options
		};

		const retval = [];
		for (const element of this.flat(options.type)) {
			retval.push(lambda(element));
		}

		return(retval);
	}

	/**
	 * Find a given element ID in the body
	 *
	 * @param {string} element_id - The ID of the element to locate
	 * @returns {KaiAlphaBodySelectionEntry} The contents of the element
	 */
	findElement(element_id) {
		const flat_body = this.flat();
		const element = flat_body.find(function(check_element) {
			if (check_element.id === element_id) {
				return(true);
			}

			return(false);
		});

		return(element);
	}

	_sections() {
		const insert_section_at = function(address, output, section) {
			const index = address[0];

			if (address.length === 1) {
				if (!output.sections) {
					output.sections = [];
				}

				output.sections[index] = section;

				return;
			}

			const new_output  = output.sections[index];
			const new_address = address.slice(1);

			return(insert_section_at(new_address, new_output, section));
		};

		const flat_retval = [];
		const nested_sections = {};
		const numbering_at_depth = {};
		let max_section_depth = 0;
		let last_section_depth;
		for (const section_element of this.flat('section')) {
			const section_depth = section_element.container.filter(function(part) {
				const item_type = part.split(' ')[0];
				if (item_type === 'section') {
					return(true);
				}
				return(false);
			}).length;

			if (section_depth > max_section_depth) {
				max_section_depth = section_depth;
			}

			if (section_depth < last_section_depth) {
				for (let tmp_depth = section_depth; tmp_depth < max_section_depth; tmp_depth++) {
					delete numbering_at_depth[tmp_depth + 1];
				}
			}
			last_section_depth = section_depth;

			if (numbering_at_depth[section_depth] === undefined) {
				numbering_at_depth[section_depth] = 0;
			}
			numbering_at_depth[section_depth]++;

			const section_number_parts = [];
			for (let tmp_depth = 0; tmp_depth <= section_depth; tmp_depth++) {
				section_number_parts.push(numbering_at_depth[tmp_depth]);
			}

			const section_number = section_number_parts.join('.');
			const section_data = {
				...section_element.contents,
				'$number': section_number,
				'$element_id': section_element.id,
				'$depth': section_depth,
				body: undefined
			};

			/*
			 * Flat in-order structur of sections
			 */
			flat_retval.push(section_data);

			/*
			 * Nested structure
			 */
			const section_indexes = section_number_parts.map(function(section_part) {
				return(section_part -1);
			});
			insert_section_at(section_indexes, nested_sections, {...section_data});
		}

		let nested_retval = nested_sections.sections;
		if (!nested_retval) {
			nested_retval = [];
		}

		return({
			flat: flat_retval,
			nested: nested_retval
		});
	}

	/**
	 * Returns a flat list of sections, with numbering
	 *
	 * @returns {any[]} Section list, in-order
	 */
	sections() {
		return(this._sections().flat);
	}

	/**
	 * Returns an array of sections, each element of which contains an
	 * object which may have a "sections" property, which will be an
	 * array of sections, repeatedly
	 *
	 * @returns {any[]} Section nested list
	 */
	sectionsNested() {
		return(this._sections().nested);
	}

	/**
	 * Mark an element as viewed by user during review.
	 * @param {string} element_id
	 * @param {string} [slot_name]
	 * @returns {boolean} `false` if tracking events cannot be added to workflow
	 */
	trackReview(element_id, slot_name = 'default') {
		const result = this._bodyFor.container.trackReview(element_id, slot_name);
		return(result);
	}
}

/**
 * KaiAlpha Body Object for Documents
 */
class DocumentBody extends Body {
	/**
	 * @param {any[]|Body|DocumentBody} body - Body to initialize from
	 * @param {Document|Documents|SyntheticDocuments} [document] - Document to refer to
	 */
	constructor(body, document) {
		super(body, { type: 'document', container: document });

		/*
		 * "extraComments" are added only by withComments()
		 */
		this.extraComments = undefined;
	}

	/**
	 * Provide a new DocumentBody with section information hydrated into it
	 *
	 * @returns {DocumentBody}
	 */
	withSections() {
		const new_body = new DocumentBody(this);

		const section_list = new_body.sections();
		const element_section_info_map = {};
		for (const section_info of section_list) {
			const element_id = section_info['$element_id'];
			const number = section_info['$number'];
			const depth = section_info['$depth'];

			element_section_info_map[element_id] = {
				number,
				depth,
				section_id: element_id,
			};
		}

		const flat_body = new_body.flat();
		for (const element of flat_body) {
			const element_section_info = element_section_info_map[element.id];
			if (!element_section_info) {
				continue;
			}

			element.contents['$section_number'] = element_section_info.number;
			element.contents['$section_depth'] = element_section_info.depth;
			element.contents['$section_id'] = element_section_info.section_id;

			element.contents['body'] = this._attachSectionDetailsToBody(element_section_info, element.contents.body)
		}

		return(new_body);
	}

	_attachSectionDetailsToBody = (section_info, body = []) => {
		const { number, depth, section_id } = section_info

		return body.map((item) => {
			const element_id = Object.keys(item)[0]
			const element = item[element_id]

			element['$section_number'] = number;
			element['$section_depth'] = depth;
			element['$section_id'] = section_id;

			if (element.body) {
				element.body = this._attachSectionDetailsToBody(section_info, element.body)
			}

			return { [element_id]: element }
		});
	}

	/**
	 * Provide a new DocumentBody with comment information hydrated into it
	 *
	 * @param {Object} options - List of options for placing the comments
	 * @param {boolean} [options.placeUnmatchedLoops] - Specify whether or not unmatched comments on loop elements are placed on the first iteration of the loop (default: true)
	 * @returns {DocumentBody}
	 */
	withComments(options = {}) {
		options = {
			placeUnmatchedLoops: true,
			...options
		};

		const new_body = new DocumentBody(this);
		new_body.extraComments = {};

		const container = new_body._bodyFor.container;
		if (!container || !container.comments) {
			return(new_body);
		}

		const all_comments_parts = container.comments();
		const all_comments = all_comments_parts.elements;

		const flat_body = new_body.flat();

		/*
		 * Try to match every comment on an element to
		 * an element
		 */
		/**
		 ** Keep track of elements which are not able to be matched
		 **/
		const unhandled_comments_elements = {};
		/***
		 *** This will include all document comments (maybe they could
		 *** be attached to the respective "template" elements ?)
		 ***/
		const unhandled_comments_documents = {
			...all_comments_parts.documents
		};

		/**
		 ** Loop over all element comments and try to find a good-enough
		 ** element to attach the comment to
		 **/
		for (const comment_element_id in all_comments) {
			const comments = all_comments[comment_element_id];

			for (const comment of comments) {
				let comment_added = false;
				const try_matches = [];

				if (comment.subaddress) {
					try_matches.push(`${comment.subaddress}@${comment_element_id}`);
				}

				try_matches.push(comment_element_id);

				if (options.placeUnmatchedLoops) {
					/*
					 * If the comment is on just the bare element,
					 * but all we have are loop elements, place it
					 * on the first iteration.  This is also where
					 * they will go if the iteration doesn't exist
					 */
					const match_re = new RegExp(`^loop-[0-9]+@${comment_element_id}$`);
					try_matches.push(match_re);
				}

				for (const try_match of try_matches) {
					for (const element of flat_body) {
						const element_id = element.id;

						if (try_match instanceof RegExp) {
							if (!element_id.match(try_match)) {
								continue;
							}
						} else {
							if (element_id !== try_match) {
								continue;
							}
						}

						if (!element.contents['$comments']) {
							element.contents['$comments'] = [];
						}

						element.contents['$comments'].push(comment);

						comment_added = true;

						break;
					}

					if (comment_added) {
						break;
					}
				}

				if (!comment_added) {
					if (!unhandled_comments_elements[comment_element_id]) {
						unhandled_comments_elements[comment_element_id] = [];
					}

					unhandled_comments_elements[comment_element_id].push(comment);
				}
			}
		}

		new_body.extraComments = {
			elements: unhandled_comments_elements,
			documents: unhandled_comments_documents
		};

		return(new_body);
	}

	/**
	 * Return a new body for a specific section
	 *
	 * @param {KaiAlphaElementID} section_element_id - The element ID of the section
	 * @returns {DocumentBody} A subset of the body for the specified section
	 */
	sectionBody(section_element_id) {
		const element = this.findElement(section_element_id);
		if (!element) {
			throw(new Error(`No element ID found ${section_element_id}`));
		}

		if (element.type !== 'section') {
			throw(new Error(`Found element ID ${section_element_id} but it was not for a section (it was for a ${element.type})`));
		}

		const new_body_entries = [{
			[element.id]: element.contents
		}];

		const new_body_document = this._bodyFor.document;

		const new_body = new DocumentBody(new_body_entries, new_body_document);

		return(new_body);
	}

	/**
	 * Return a list of comments for a given body
	 *
	 * @returns {any} All comments related to this body
	 */
	comments() {
		const commented = this.withComments({
			placeUnmatchedLoops: false
		});

		const element_comments = {};

		commented.forEach(function(element_wrapper) {
			const element_id = element_wrapper.id;
			const element = element_wrapper.contents;

			if (!element['$comments']) {
				return;
			}

			element_comments[element_id] = element['$comments'];
		});

		return({
			elements: element_comments
		});
	}

	/**
	 * Gets the review status of a section for a user
	 * @param {KaiAlphaUserID} user_id - user to check the review status of. Defaults to current user.
	 * @returns {{reviewed: boolean, has_comments: boolean, response_required: boolean}}
	 */
	getUserReviewStatus(user_id) {
		// check if every sub section has been viewed by user
		const reviewed = this._viewedAllSectionsInBody(user_id);

		const { comments_flattened: has_user_comments, comments_by_tag } = this.filterComments({current_user:user_id, tag:'response required'});

		return({
			reviewed,
			has_comments: has_user_comments.length > 0,
			response_required: comments_by_tag.length > 0,
		});
	}
	/**
	 * Fetch all comments on an element
	 * Note: It does not pull comments from element's subsections
	 * @param {KaiAlphaElementID} element_id  - element id
	 * @returns {Array<KaiAlphaCommentEntry>} all comments on element
	 */
	getCommentsOnElement(element_id){
		const element_comments=[];
		if (!element_id){
			console.warn('[Warning]: element_id is missing')
			return element_comments;
		}

		/* Fetch all sections and subsections details */
		const all_elements = this.value;

		/* Fetch element body */
		const element_body_flattened = all_elements.map((item) => item[element_id]['body']).flat();

		/* Fetch comments on element if exist*/
		element_body_flattened.forEach( element_body => {
			const body_element_keys = Object.keys(element_body);
			body_element_keys.forEach(key =>{
				if (element_body[key]['$section_id'] === element_id &&  element_body[key]['$comments'] ){
					element_comments.push( element_body[key]['$comments']);
				}
			})
		});
		return (Object.values(element_comments)).flat();
	}

	/**
	 * Filter comments- filter by user_id, comment tag and comment state are currently supported
	 * @param {object} options
	 * @returns {{ comments_flattened: Array, comments_by_tag: Array,  comments_by_state: Array, unresolved_comments: Array}}
	 */
	filterComments(options = { user_id: null}){
		const { user_id, tag, state, fetch_comment_on_element, elementId} = options;

		/* Fetch all comments on a section or on element */
		let comments_flattened;
		if (fetch_comment_on_element){
			comments_flattened = this.getCommentsOnElement(elementId);
		} else {
			const comments = this.comments().elements;
			comments_flattened = (Object.values(comments)).flat();
		}

		comments_flattened =  comments_flattened.filter((comment_element) => (!comment_element.deleted));

		/* Filter comments by current_user */
		if (user_id !== null) {
			comments_flattened = comments_flattened.filter((comment) => comment.author === user_id);
		}

		/* Filter by tag */
		let comments_by_tag=[];
		if (tag) {
			comments_by_tag = comments_flattened.filter(comment => comment.tag.toLowerCase() === tag.toLowerCase());
		}

		/* Filter by state */
		let comments_by_state=[];
		if (state) {
			comments_by_state = comments_flattened.filter((comment) => comment.state === state);
		}

		/* Unresolved comment list*/
		const unresolved_comments = comments_flattened.filter((comment) => comment.state !== 'RESOLVED');

		return ({
			comments_flattened,
			comments_by_tag,
			comments_by_state,
			unresolved_comments
		})
	}

	/**
	 * Checks if all the sections in body are viewed by the user.
	 * @param {KaiAlphaUserID} user_id - user to check
	 * @returns {boolean}
	 */
	_viewedAllSectionsInBody(user_id) {
		const sections_in_this_body = this.sections().map(section => section['$element_id']);

		// check if there are any sections in this body that are not yet viewed
		const unfinished = sections_in_this_body.some(section_id => {
			const viewed_elements = this._bodyFor.container.getTrackedElementsOfUser(user_id);
			const not_viewed = !viewed_elements.includes(section_id);
			return not_viewed;
		});

		// if there are no unfinished sections, then everything is viewed.
		return(unfinished === false);
	}
}

/**
 * @typedef {Object} VariableDescriptor
 * @property {string} type - Type of variable
 * @property {any} value - Value for the variable
 * @property {boolean} $typeWritable - Whether this type of variable is writable
 */
/**
 * @typedef {Object} GenericDocument
 * @property {function(void): KaiAlphaDocumentID} id - Get the document ID
 * @property {function(void): KaiAlphaVersionID} version - Get the document version
 * @property {function(void): Promise<void>} wait - Wait for initialization to complete
 * @property {function(Object?): Object.<string, VariableDescriptor> | {}} variables - Get variables
 * @property {function(string, object): any} getVariable - Get a specific variable's value
 * @property {function(string, any): any} setVariable - Set a variable's value
 * @property {function(Object?): any} comments - Get a list of all comments
 * @property {function(Object): any[]} getComments - Get all the comments on an element
 * @property {function(Object, Object): Promise<void>} addComment - Add a comment to an element
 * @property {function(KaiAlphaDocumentID): Promise<boolean>} can_user_write_comment - validate document state and user comment write access
 * @property {function(KaiAlphaCommentEntry, Object, Object): Promise<boolean>} isCommentActionAllowed - Validate comment action (Edit/Delete)
 * @property {function(KaiAlphaElementID, Object): Promise<void>} updateComment - Update an existing comment
 * @property {function(KaiAlphaElementID): Promise<void>} deleteComment - Delete an existing comment
 * @property {function(void): boolean} saveRequired - Determine if a save is required
 * @property {function(Object?): Promise<boolean>} save - Perform save, true represents that changes were saved
 * @property {function(void): Promise<boolean>} refresh - Perform refresh, true represents that one or more documents changed
 * @property {function(void | Object?): DocumentBody} body - Get the document body
 */

/**
 * Interface to a Single KaiAlpha Document
 *
 * @implements {GenericDocument}
 */
class Document extends general_utils.GeneralCMS {
	constructor(ka_context, contents, metadata) {
		super('document', ka_context, contents, metadata);
		this._documentCheckInterval = undefined;
		this._templateCheckInterval = undefined;
		/**
		 * Buffer to store tracking events of workflow slots
		 * @type {{ [slot_name: string]: string[]; }}
		 */
		this._trackingEventsBuffer = {};

		/** @type {KaiAlphaCMSItemPermissions} cache of canonical acl permissions and roles */
		this._canonicalPermissions = undefined;

		if (contents && contents.version && contents.version.substr(0, 6) === '@date:') {
			this._constrain_children_versions = contents.version;
		} else if (contents && contents.version && contents.version !== 'HEAD') {
			/*
			 * XXX:TODO: If a concrete version was passed in, we
			 * should resolve that to a date, and then use that
			 * date
			 */
			console.warn(`[WARNING] A concrete version (${contents.version}) was passed in, but this does NOT constrain children yet (TODO)`);
		}

		this.selectedVersion = contents.selectedVersion;
		if (this._new) {
			if (!contents || !contents.template) {
				throw(new Error(`Cannot construct new document unless a template is specified`));
			}
		}
	}

	async refresh() {

		if (!this._writable) {
			// we should not update the content if user is looking at a specific (or old) version
			return false;
		}

		this._contents = await kaialpha.lib.document.get_user_document(this._user_id, this.id(), 'HEAD');
		this._version = this._contents.version;

		const changed = await super.refresh();
		//
		// await this.resolveConflictsAndRun(options);

		if (changed) {
			// clear cached permissions
			this._canonicalPermissions = undefined;
		}

		return changed;
	}

	async __fetchComments(){
		this._comments = await kaialpha.lib.commentAPI.getAllComments('document', this.id(),  this.selectedVersion);
	}

	destroy() {
		this.clearDocumentCheckInterval();
		this.clearTemplateCheckInterval();

		// clear cached permissions
		this._canonicalPermissions = undefined;

		return(super.destroy());
	}

	_assert_document(options = {}) {
		return(this._assert_contents(options));
	}

	_element(element_id) {
		return(this.body().findElement(element_id));
	}

	async wait() {
		await super.wait();
		await this.__fetchComments();
		/*
		 * Try to get a template if we have not already, this may
		 * instantiate a Template object for us to wait for
		 */
		this.template();
		if (this._template) {
			await this._template.wait();
		}
	}

	clearDocumentCheckInterval() {
		if (!this._documentCheckInterval) {
			return;
		}

		clearInterval(this._documentCheckInterval);
		delete this['_documentCheckInterval'];
	}

	setDocumentCheckInterval(onDetect, frequency) {
		if (frequency === undefined || frequency <= 0) {
			return(this.clearDocumentCheckInterval());
		}

		this._documentCheckInterval = setInterval(async () => {
			if (this._documentCheckInProgress === true) {
				return;
			}
			try {
				this._documentCheckInProgress = true;

				const id = this.id();
				if (id === '@new') {
					return;
				}

				const current_version = this.version();
				const base_version = this.document().version;

				const new_document = await kaialpha.lib.document.get_user_document(this._user_id, id);
				const new_version = new_document.version;

				if (new_version === base_version) {
					return;
				}

				// eslint-disable-next-line
				const action = await onDetect(id, base_version, current_version, new_version);

				/* XXX:TODO: Do something with action */
			} finally {
				this._documentCheckInProgress = false;
			}
		}, frequency);
	}

	clearTemplateCheckInterval() {
		if (!this._templateCheckInterval) {
			return;
		}

		clearInterval(this._templateCheckInterval);
		delete this['_templateCheckInterval'];
	}

	setTemplateCheckInterval(_ignored_onDetect, _ignored_frequency) {
		throw(new Error('not implemented'));
	}

	document(options = {}) {
		options = {
			mutable: true,
			...options
		};

		const document = this._assert_document(options);

		return(document);
	}

	template() {
		const document = this.document();

		if (!document.template) {
			return(undefined);
		}

		const template_id = document.template.id;
		const template_version = document.template.version;

		if (this._template) {
			const check_template_id = this._template.id();
			const check_template_version = this._template.version();

			if (template_id !== check_template_id || template_version !== check_template_version) {
				this._template = undefined;
			}
		}

		if (!this._template) {
			this._template = new kaialpha.lib.template_utils.Template(this._ka_context, {
				id: template_id,
				version: template_version
			}, {
				document_id: this.id(),
				document_version: this.version()
			});
		}

		return(this._template);
	}

	children() {
		const retval = [];
		const document = this.document();

		if (!document.subdocuments) {
			return(retval);
		}

		const our_id = this.id();

		for (const element_id in document.subdocuments) {
			const subdocument_info = document.subdocuments[element_id];

			for (const document_id of subdocument_info.document_id) {
				if (!this._children_ids) {
					this._children_ids = {};
				}

				if (!this._children_ids[document_id]) {
					const element_info = this._element(element_id);
					let parent_element;
					if (element_info && element_info.contents) {
						parent_element = element_info.contents;
					}

					this._children_ids[document_id] = new Document(this._ka_context, {
						id: document_id,
						version: this._constrain_children_versions
					}, {
						parent: our_id,
						parent_element_id: element_id,
						parent_element: parent_element
					});
				}

				retval.push(this._children_ids[document_id]);
			}
		}

		return(retval);
	}

	parent() {
		const document = this.document();

		if (!document.superdocument) {
			return(undefined);
		}

		const document_id = document.superdocument.document_id;

		if (!document_id) {
			return(undefined);
		}

		if (!this._parent_info) {
			this._parent_info = new Document(this._ka_context, {
				id: document_id
			});
		}

		return(this._parent_info);
	}

	async upgrade_template() {
		throw(new Error('not implemented'));
	}

	getName() {
		const document = this.document();
		return(document.name);
	}

	setName(name) {
		const document = this._assert_document();
		document.name = name;
		this._changed['name'] = true;
		return(document.name);
	}

	variables(options = {}) {
		options = {
			typeWritableOnly: true,
			...options
		};

		const document = this.document();
		if (!document) {
			return({});
		}

		const template = this.template();
		let template_variables = {};
		if (template) {
			template_variables = template.variables();
		}

		let variable_values = document.variables;
		if (!document.variables) {
			variable_values = {};
		}

		const variables = {};
		for (const variable_name in template_variables) {
			const variable_info = template_variables[variable_name];

			let writable = true;
			if (variable_info.type === 'expression') {
				writable = false;
			}

			variables[variable_name] = {
				...variable_info,
				value: variable_values[variable_name],
				'$typeWritable': writable
			};
		}

		if (options.typeWritableOnly === false) {
			this.body().forEach(function(element) {
				switch (element.type) {
					case 'switch':
					case 'loop':
						{
							const name = element.contents.name;
							variables[name] = {
								'$element_id': element.id,
								'$typeWritable': false
							};
						}
						break;
					default:
						/* Nothing to do for most elements */
						break;
				}
			});
		}

		if (options.typeWritableOnly !== false) {
			const to_delete = [];
			for (const variable_name in variables) {
				const variable_info = variables[variable_name];

				if (variable_info['$typeWritable'] === false) {
					to_delete.push(variable_name);
				}
			}

			for (const variable_name of to_delete) {
				delete variables[variable_name];
			}
		}

		return(variables);
	}

	getVariable(name, options) {
		const variables = this.variables(options);
		// lookup variable key case insensitively
		const variableKey = Object.keys(variables).find(variable => variable.match(new RegExp(name, "i")));
		const variableInfo = variables[variableKey];
		if (!variableInfo) {
			return(undefined);
		}
		return(variableInfo.value);
	}

	setVariable(name, value) {
		const document = this._assert_document();
		if (!document.variables) {
			document.variables = {};
		}

		document.variables[name] = value;
		this._changed['variables'] = true;

		return(value);
	}

	comments(options = {}) {
		options = {
			raw: false,
			...options
		};

		let comments;

		if (!this._comments) {
			comments = {};
		} else {
			comments = JSON.parse(JSON.stringify(this._comments));
		}

		/*
		 * Support processing the comments into a more useful
		 * breakdown.  This breakdown has not just "elements"
		 * and "variables" but also "comments" and "documents".
		 *
		 * This can help with figuring out which comments are
		 * meaningful in a given context.
		 */
		if (!options.raw) {
			const seen_comment_ids = {};
			for (const part of ['elements', 'variables']) {
				if (!comments[part]) {
					comments[part] = {};
				}

				for (const check_comment_on_id in comments[part]) {
					for (const check_comment of comments[part][check_comment_on_id]) {
						seen_comment_ids[check_comment.id] = { part, key: check_comment_on_id, id: check_comment.id };
					}
				}
			}

			/*
			 * Convert comments which are "on" another comment ID
			 * into children of the "comments" property
			 */
			const elements_delete = [];
			for (const check_comment_on_id in comments.elements) {
				const seen_info = seen_comment_ids[check_comment_on_id];
				if (seen_info === undefined) {
					continue;
				}

				if (!comments.comments) {
					comments.comments = {};
				}

				/*
				 * Find the parent comment, if any and add it
				 * as a "$parent" property to the existing
				 * comment -- we can get rid of this if
				 * noone uses it
				 */
				comments.comments[check_comment_on_id] = comments.elements[check_comment_on_id];
				for (const comment of comments.comments[check_comment_on_id]) {
					comment['$parent'] = comments[seen_info.part][seen_info.key].find(function(compare_comment) {
						if (compare_comment.id === seen_info.id) {
							return(true);
						}

						return(false);
					});
				}
				elements_delete.push(check_comment_on_id);
			}

			for (const comment_on_id of elements_delete) {
				delete comments.elements[comment_on_id];
			}

			// /*
			//  * Convert comments which are "on" the document into
			//  * children of the "documents" property
			//  */
			// if (comments.elements[document_id]) {
			// 	if (!comments.documents) {
			// 		comments.documents = {};
			// 	}
			// 	comments.documents[document_id] = comments.elements[this.id()];
			// 	delete comments.elements[document_id];
			// }
		}

		return(comments);
	}

	static _parseCommentOn(on, document_id = undefined) {
		const type = on.type;
		let member = type;
		let id;
		switch (type) {
			case 'document':
				member = 'elements';
				if (document_id === undefined) {
					id = on['id'];
					if (id === undefined) {
						throw(new Error('Must specify document ID'));
					}
				} else {
					id = document_id;
				}
				break;
			case 'comments':
			case 'elements':
				member = 'elements';
				id = on['id'];
				break;
			case 'variables':
				id = on['name'];
				break;
			default:
				throw(new Error(`Invalid type specified: ${type}, must be one of comments, document, elements, variables from ${JSON.stringify(on)}`));
		}

		if (id === undefined) {
			throw(new Error('Invalid name or ID specified'));
		}

		return({ member, id });
	}

	getComments(on) {
		const { member, id } = Document._parseCommentOn(on, this.id());

		const comments = this.comments({ raw: true });
		if (!comments || !comments[member] || !comments[member][id]) {
			return([]);
		}

		return(comments[member][id]);
	}

	_completeComment(comment) {
		const ret_val = ({
			id: uuid.v4(),
			author: this._user_id,
			date: (new Date()).toISOString(),
			version: this.document().version,
			state: 'IN REVIEW',
			deleted: false,
			...comment
		});
		delete ret_val['$document_id'];
		return(ret_val);
	}

	async addComment(on, comment) {

		/* Verify document state and write access permission */
		const is_action_allowed =  await this.can_user_write_comment();
		if (!is_action_allowed){
			throw(new Error('Action not allowed'));
		}

		if (!comment || !comment.comment) {
			throw(new Error(`comment must contain at least "comment" property: ${JSON.stringify(comment)}`));
		}

		const newComments = [...this.getComments(on)];
		const newComment = this._completeComment(comment);
		newComments.push(newComment);

		const { member, id } = Document._parseCommentOn(on, this.id());

		if (this._comments === undefined) {
			this._comments = {};
		}

		if (this._comments[member] === undefined) {
			this._comments[member] = {};
		}

		this._comments[member][id] = newComments;

		await kaialpha.lib.commentAPI.addComment('document', this.id(), { comment, elementId:on.id});
	}

	/**
	 * Verify if document is in approved state or user has comment write access
	 * @returns {Promise<boolean>} - True - action allowed / false - action not allowed
	 */
	async can_user_write_comment(){
		const document = this._assert_document();

		const expandedPermissions = await this.getExpandedPermissions();
		const userHasCommentWriteAccess = await kaialpha.lib.versions_utils.verify_permissions(this._user_id,
			'comments:write',
			expandedPermissions // Pass expanded permission to avoid unwanted db permission fetch
		);
		/*
		* If user has no comment write access - do not allow action
		*/
		if (!userHasCommentWriteAccess){
			return false;
		}

		/**
		* If document is in approved state - do not allow action
		*/
		if (document.state === 'Approved'){
			return false;
		}

		return true;
	}
	/**
	 * Validate user action on existing comment if its allowed/not-allowed
	 * @param {KaiAlphaCommentEntry} existing_comment
	 * @param {object} comment_area - all comments
	 * @param {object} options
	 * @returns {Promise<boolean>} return true/false
	 * **/
	async isCommentActionAllowed(existing_comment, comment_area, options={}) {
		if (!existing_comment) {
			// if here, invalid comment
			return false;
		}

		// defaults to avoid null exceptions
		comment_area = comment_area || {};

		/* Verify document state and write access permission */
		const result =  await this.can_user_write_comment();
		if (!result){
			return false;
		}

		/**
		* If comment is already resolved - do not allow action
		*/
		if (existing_comment.state === 'RESOLVED'){
			return false;
		}

		const comment_has_replies = comment_area && comment_area[existing_comment.id];

		switch(options.action){
			case 'DELETE':
				{
					// If comment has replies - do not allow deletion
					if (comment_has_replies) {
						return false;
					}
					const expandedPermissions = await this.getExpandedPermissions();
					const reviewers = expandedPermissions.roles.reviewers;
					const owners = expandedPermissions.owners;
					const isUserOwner = owners && owners.includes(this._user_id);
					const isUserAuthorOfComment = (existing_comment.author === this._user_id);

					/**
					 * If user is author of comment
					 * 				- Allow to delete owm comment
					 * If user is not author of comment but user is owner
					 * 				- Allow to delete comment if comment is reviewer's comment
					 * */

					if (isUserAuthorOfComment){
						return true;
					} else if (isUserOwner && reviewers.includes(existing_comment.author)){
						return true;
					} else {
						return false;
					}
				}
			case 'EDIT':
				{
					/**
					* Allow edit only
					* If new comment state is requested to mark resolved or if user is author of comment
					* TODO - Currently comment can be resolved by anyone (Verify security model)
					**/
					if (options.saveResolved) {
						/** If reply is missing on comment tagged for response required - do not allow action */
						if (existing_comment.tag === 'Response Required' && !comment_area[existing_comment.id]){
							return false;
						} else {
							return true;
						}
					} else if (existing_comment.author && (existing_comment.author === this._user_id)) {
						// If comment has replies - do not allow editing comment text
						if (comment_has_replies) {
							return false;
						}
						return true;
					}

					// if here, not this comment author
					return false;
				}
			default:
				kaialpha.lib.debug.log('document-object', 'Comment action is missing ', options);
				return false;
		}
	}

	async updateComment(id, comment, options ={}) {

		let commentOnInfo;

		const comment_areas = this.comments({ raw: true });
		for (const member in comment_areas) {
			const commentsOn = comment_areas[member];
			for (const onId in commentsOn) {
				const comments = commentsOn[onId];
				const foundComment = comments.find(function(check_comment) {
					if (check_comment.id === id) {
						return(true);
					}

					return(false);
				});

				if (foundComment) {
					const newComments = comments.map((existing_comment) => {
						if (existing_comment.id !== id) {
							return(existing_comment);
						}

						return({
							...existing_comment,
							...this._completeComment(comment),
							id: existing_comment.id
						});
					});
					commentOnInfo = {
						id: onId,
						member: member,
						newComments: newComments
					};
					break;
				}
			}

			if (commentOnInfo) {
				break;
			}
		}

		if (!commentOnInfo) {
			throw(new Error('No such comment'));
		}

		const existing_comment = this._comments[commentOnInfo.member][commentOnInfo.id].filter( comment => comment.id === id)[0];
		const parentComments =this._comments[commentOnInfo.member];

		options = {
			action: 'EDIT',
			saveResolved: comment.state === 'RESOLVED',
			...options
		}

		const proceed = await this.isCommentActionAllowed(existing_comment, parentComments, options);
		if (!proceed){
			throw(new Error('Action not allowed'));
		}

		this._comments[commentOnInfo.member][commentOnInfo.id] = commentOnInfo.newComments;

		await kaialpha.lib.commentAPI.updateComment('document', this.id(), { comment, commentId:id});
	}

	/**
	 * Delete a comment
	 * @param {KaiAlphaElementID} id
	 */
	async deleteComment(id){
		return await this.updateComment( id, { deleted: true }, { action: 'DELETE' })
	}

	/**
	 * Returns the canonical permissions and roles of this document.
	 * The result will include all inherited permissions.
	 * All attributes of the permission object will be expanded to have just user ids,
	 * instead of pointers to roles and user groups.
	 *
	 * @returns {Promise<KaiAlphaDocumentPermissions>}
	 */
	async getExpandedPermissions(options = {}) {

		if (this._canonicalPermissions) {
			return this._canonicalPermissions;
		}

		const document = this._assert_document();

		// Expand item permissions by expansion from inherited ACLs and roles
		let permissions = await kaialpha.lib.versions_utils.canonicalize_permissions_and_roles(document.permissions, options);

		/**
		 * `acl` and `roles` will be undefined if they are not yet set in ACL editor.
		 * setting default attributes for `roles` to avoid duplicate null handling in multiple places.
		 */
		permissions = {
			...permissions,
			roles: {
				reviewers: [],
				readers: [],
				authors: [],
				approvers: [],
				...permissions.roles,
			},
		};

		// avoid manual mutations to permissions
		this._canonicalPermissions = kaialpha.lib.object_utils.freeze_object(permissions);

		return(this._canonicalPermissions);
	}

	get_permissions() {
		throw(new Error('not implemented'));
	}

	set_permissions() {
		throw(new Error('not implemented'));
	}

	forEach(lambda, options = {}) {
		this.body().forEach(lambda, options);
	}

	body() {
		const document = this.document();

		let template;
		if (this._template) {
			template = this._template.template();
		}

		const body = body_serialize(document, template);

		const bodyObject = new DocumentBody(body, this);

		return(bodyObject);
	}

	/**
	 * Function to complete a workflow event. Any changes to the document are saved before posting.
	 * @param {string} event_name - name of workflow event
	 * @param {*} event_info - arguments passed to workflow event
	 * @param {*} [options] - workflow options
	 * @returns {Promise<{result: KaiAlphaCMSItemVersionInfo; state: KaiAlphaWorkflow} | undefined>}
	 * `undefined` if workflow cannot be updated. Updated document and workflow info if success.
	 */
	async completeWorkflowEvent(event_name, event_info, options = {}) {
		options = {
			slot_name: 'default',
			...options,
			get_user_document: async () => {
				return(this.document());
			},
		};

		const workflow = this.getWorkflowSlot(options.slot_name);
		if (!workflow || workflow.status !== 'waiting') {
			return undefined;
		}

		// save any pending changes first
		await this.save();

		// update workflow, this will create new version of document
		const retval = await kaialpha.lib.document.complete_document_workflow_event(this._user_id, this.id(), 'HEAD', event_name, event_info, options);

		// refresh the document to fetch new version and any workflow state changes
		await this.refresh();

		return(retval);
	}

	async updateWorkflowState(document_id, version_id, options){
		return await kaialpha.lib.document.update_document_workflow_state(this._user_id, document_id, version_id, options)
	}

	/**
	 * Get the elements that are viewed by user during review process.
	 * @param {KaiAlphaUserID} [user_id] - user to get the tracking status of, defaults to the current user.
	 * @param {string} [workflow_slot] - name of the review workflow to check tracking data, defaults to `default` workflow.
	 * @returns {string[]} tracked element ids
	 */
	getTrackedElementsOfUser(user_id = this._user_id, workflow_slot = 'default') {
		const workflow = this.getWorkflowSlot(workflow_slot);

		if (!workflow || !workflow.variables || !workflow.variables.tracking) {
			return([]);
		}

		return workflow.variables.tracking[user_id] || [];
	}

	/**
	 * Function to buffer and post elements that are viewed by current user during review process.
	 * @param {string} element_id - element to track
	 * @param {string} [slot_name] - workflow slot to post tracking events to
	 * @returns {boolean} `false` if tracking events cannot be added to workflow
	 */
	trackReview(element_id, slot_name = 'default') {
		const workflow = this.getWorkflowSlot(slot_name);
		if (!workflow || workflow.operation !== 'set_ui_action_review' || workflow.status !== 'waiting') {
			return false;
		}

		if (!this._trackingEventsBuffer[slot_name]) {
			this._trackingEventsBuffer[slot_name] = [];
		}

		if (this._trackingEventsBuffer[slot_name].includes(element_id)) {
			return true;
		}

		this._trackingEventsBuffer[slot_name].push(element_id);
		return true;
	}

	/**
	 * Post buffered tracking events
	 * @returns {Promise<{result: KaiAlphaCMSItemVersionInfo; state: KaiAlphaWorkflow} | undefined>}
	 * `undefined` if nothing to update or workflow cannot be updated. Updated document and workflow info if success.
	 */
	async _postTrackingEvents() {
		// get buffered tracking events of every slot
		const slot_buffers = Object.entries(this._trackingEventsBuffer);

		let retval = undefined;

		// concurrent document state mutations results in transaction errors
		// TODO: this can be changed to Promise.all after API can handle multiple workflow slot mutations at once.
		for (const [slot_name, buffer] of slot_buffers) {
			// copy buffered tracking events and reset the list
			const to_send = buffer.splice(0);
			if (to_send.length === 0) {
				return(retval);
			}

			// check if there are new elements to track
			const tracked_elements = this.getTrackedElementsOfUser(this._user_id, slot_name);
			const untracked_elements = to_send.filter(element_id => !tracked_elements.includes(element_id));
			if (untracked_elements.length === 0) {
				return(retval);
			}

			retval = await this.completeWorkflowEvent('tracking', untracked_elements, {slot_name});
		}

		return(retval);
	}

	/**
	 * Helper to fetch state of a workflow slot
	 * @param {string} slot_name - name of the workflow slot
	 * @returns {Object | undefined}
	 */
	getWorkflowSlot(slot_name) {
		const workflow_slots = this.document().workflow;
		if (!workflow_slots) {
			return;
		}
		const workflow_slot = workflow_slots[slot_name];
		return workflow_slot;
	}

	saveRequired() {
		if (this.version() === '@new') {
			return(true);
		}

		if (this._changed && Object.keys(this._changed).length > 0){
			return(true);
		}

		return(false);
	}

	async save(options = {}) {
		if (!this.saveRequired()) {
			return false;
		}

		try {
			await super._push(options);
		} catch (err) {

			const retryable = this.shouldRetryError(err, options);
			if (retryable) {
				// resolve merge conflicts and retry saving
				options.runAfterResolve = async () => {
					await super._push(options);
				}
				await this.resolveConflictsAndRun(options);
				return;
			}

			// rethrow unrecoverable errors
			throw err;
		}

		return true;
	}

	/**
	 * Resolve any merge conflicts while saving or refreshing document and run the given function after
	 */
	async resolveConflictsAndRun(options = {}) {
		const { runAfterResolve = () => {} } = options;

		const original_content = kaialpha.lib.object_utils.copy_object(this._contents);
		const original_version = this._version;

		const retryFunction = async () => {
			const resolved_doc = await resolve_document_merge_conflicts(this._user_id, original_content, original_version);
			this._contents = resolved_doc;
			this._version = resolved_doc.version;
			await runAfterResolve();
		};

		// retry the function in case there are concurrent changes when this request is inflight
		await kaialpha.lib.error_utils.retry_with_backoff(retryFunction, {
			delay: 1000, // give enough time for api to finish saving any other concurrent changes
			maxAttempts: 20, // retry as many times as possible
			shouldRetryOnError: (err) => {
				return this.shouldRetryError(err, options);
			}
		});
	}

	/**
	 *
	 * @param {Error & {code?: string}} err - Error object
	 * @param {Object} [options]
	 * @param {Function} [options.progressStatus] - Callback function to track progress status. This can be use to show notification to user on UI.
	 * @returns {boolean}
	 */
	shouldRetryError(err, options = {}) {
		const { progressStatus = () => { } } = options;

		if (err && err.code && (err.code === kaialpha.lib.error_utils.NotLatestVersionError.CODE)) {
			// if here, there were concurrent changes made to document in upstream
			// show notification with the progress for better UX
			progressStatus({
				message: `There were concurrent changes made to document. Merging with upstream and retrying.`
			});
			return true;
		}

		return false;
	}
}

/**
 * Interface to a collection KaiAlpha Documents, from the top-level down
 *
 * @implements {GenericDocument}
 */
class Documents {
	constructor(ka_context, toplevelContents, _ignored_options) {
		this._ka_context = ka_context;
		this._user_id = null;
		if (ka_context) {
			this._user_id = ka_context.user_id;
		}

		this._new = true;
		this._changed = {};
		if (toplevelContents) {
			if (toplevelContents instanceof Document) {
				this._new = toplevelContents._new;
				this._toplevel = toplevelContents;
			} else {
				if (toplevelContents.id) {
					this._new = false;

					/*
					 * In case content isn't an exact
					 * instance of Document (due to
					 * reloading)
					 *
					 * XXX:TODO: Maybe this should be a getter ?
					 */
					if (toplevelContents.id instanceof Function) {
						toplevelContents = {
							...toplevelContents.document(),
							id: toplevelContents.id(),
							version: toplevelContents.version()
						};
					}

					if (!toplevelContents.version) {
						throw(new Error('Version must be specified'));
					}

					if (toplevelContents.version.substr(0, 6) === '@date:' || toplevelContents.version === 'HEAD') {
						this._toplevel = new Document(ka_context, toplevelContents);
					} else {
						this._toplevel_promise = (async function() {
							const work = toplevelContents.version.split('@');
							const subdocument_version = work[0];
							const subdocument_id = work[1];

							const subdocument = new Document(ka_context, { id: subdocument_id, version: 'HEAD' });
							const subdocument_versions = await subdocument.versions({ cacheID: null });

							const subdocument_version_matched = subdocument_versions.find(function(version_info) {
								if (version_info.version === subdocument_version) {
									return(true);
								}

								return(false);
							});

							if (!subdocument_version_matched || !subdocument_version_matched.date) {
								throw(new Error(`No version of document ID ${subdocument_id} matched version ${subdocument_version} while looking for version ${toplevelContents.version}`));
							}

							const subdocument_version_date = `@date:${subdocument_version_matched.date.toISOString()}`;

							const newToplevelContents = {
								id: toplevelContents.id,
								version: subdocument_version_date,
								selectedVersion: subdocument_version
							};

							return(new Document(ka_context, newToplevelContents));
						})();
					}
				} else {
					this._toplevel = new Document(ka_context);
				}
			}
		}

		if (this._new) {
			if (!toplevelContents.template) {
				throw(new Error(`Cannot construct new document unless a template is specified`));
			}
		}

		(async () => {
			await this._pull();
		})();

		this._trackingEventsInterval = undefined;
		this.onChange = undefined;
	}

	async clearTrackingEventsInterval() {
		if (!this._trackingEventsInterval) {
			return;
		}

		clearInterval(this._trackingEventsInterval);
		this._trackingEventsInterval = undefined;

		// clear buffer
		await this._postTrackingEvents();
	}

	async _updateVersion(options = {}) {
		const versions = await this.versions(options);
		if (!versions || versions.length === 0) {
			throw(new Error('No versions satisfy this request'));
		}
		this._version = versions.slice(-1)[0].version;
	}

	async _fetchAbbrs() {
		this._abbr_list_entries = undefined;
		try {
			this._abbr_list_entries = await kaialpha.lib.list_utils.get_user_list_entries(this._user_id , 'abbreviation', '@default');
		} catch (_ignored_error) {
			/* We don't do anything if the list cannot be fetched */
		}
	}

	async __fetchComments() {
		await this._toplevel.__fetchComments();
		this._runOnChange();
	}

	async _pull() {
		const pullItem = async function(item) {
			try {
				await item.wait();
			} catch (pull_error) {
				/* XXX:TODO: allow the user some control over missing items */
			}

			const promises = [];
			for (const child of item.children()) {
				promises.push(pullItem(child));
			}

			await Promise.all(promises);
		};

		this._promise = (async () => {

			if (this._toplevel_promise) {
				this._toplevel = await this._toplevel_promise;
				this._toplevel_promise = undefined;
			}

			await Promise.all([
				this._fetchAbbrs(),
				pullItem(this._toplevel)
			]);

			await Promise.all([
				this._updateVersion({ nowait: true }),
				this._updateExpandedPermissions(),
			]);
		})();

		await this.wait();
	}

	_element(element_id) {
		return(this.body().findElement(element_id));
	}

	/**
	 * Callback for processing a document or element
	 *
	 * @callback documentsForEachCallback
	 * @param {Document | KaiAlphaBodySelectionEntry} item - The KaiAlpha element
	 * @param {{name: string}[]} [path] - Path to the document. Only passed if type='document'.
	 * @returns {any} Return value
	 */
	/**
	 * Iterate over every document or element.
	 *
	 * @param {documentsForEachCallback} lambda - Callback to invoke
	 * @param {{ type?: null | string }} options - Options, if type is specified select only that type of element
	 * @returns {any[] | void} Collected return values from lambda
	 */
	forEach(lambda, options = {}) {
		options = {
			type: null,
			...options
		};

		if (options.type === 'document') {
			const processItem = function(item, path = []) {
				lambda(item, path);

				for (const subItem of item.children()) {
					const subItemElement = subItem.metadata.parent_element;
					processItem(subItem, [...path, subItemElement]);
				}
			};

			processItem(this._toplevel, [{ name: 'global' }]);
		} else {
			return(this.body().forEach(lambda, options));
		}
	}

	_idMap() {
		/**
		 * @type {{[id: string]: Document}}
		 */
		const documentIDMap = {};
		this.forEach(function(/** @type {Document} */ document) {
			const document_id = document.id();

			documentIDMap[document_id] = document;
		}, {
			type: 'document'
		});

		const templateIDMap = {};
		for (const document_id in documentIDMap) {
			const document = documentIDMap[document_id];
			const template = document.template();
			if (!template) {
				continue;
			}

			const template_id = template.id();

			templateIDMap[template_id] = template;
		}

		return({
			document: documentIDMap,
			template: templateIDMap
		});
	}

	setDocumentCheckInterval(_ignored_onDetect, _ignored_frequency) {
		throw(new Error('not implemented'));
	}

	setTemplateCheckInterval(_ignored_onDetect, _ignored_frequency) {
		throw(new Error('not implemented'));
	}

	async wait() {
		if (!this._promise) {
			return;
		}

		await this._promise;

		this._promise = undefined;
	}

	async upgrade_template() {
		throw(new Error('not implemented'));
	}

	documentsMap() {
		const { document } = this._idMap();

		return(document);
	}

	id() {
		return(this._toplevel.id());
	}

	version() {
		/* XXX:TODO: Check for new */
		if (this.saveRequired()) {
			return('@new');
		}

		return(this._version);
	}

	body(options = {}) {
		options = {
			template_elements: false,
			...options
		};

		const processBody = function(item, add_depth = 0, flat = false, datasourceQualifiedName= "global") {
			const body = item.body().value;
			const flat_body = get_body_sortly_items(body);
			const new_flat_body = [];

			const children = item.children();

			for (const element of flat_body) {
				if (element.type !== 'template') {
					const extendedElement = {
						...element,
						id: `${element.id}@${item.id()}`,
						depth: element.depth + add_depth,
						contents: {
							...element.contents,
							'$document_id': item.id(),
							/**
							* This would add datasourceQualifiedName property only if the datasource is set on the element
							* otherwise it won't add any property. Also if the datasource already has 'global.' setup ,
							* that means it's a cross template datasource, and therefore should be left as is.
							*/
							...(element.contents.datasource
								&&
								{datasourceQualifiedName:
									element.contents.datasource.substr(0, 7) === 'global.'
										? element.contents.datasource
										: `${datasourceQualifiedName}.${element.contents.datasource}`})
						}
					}
					new_flat_body.push(extendedElement);
					continue;
				}

				const element_documents = children.filter(function(check_item) {
					if (check_item.metadata.parent_element_id === element.id) {
						return(true);
					}

					return(false);
				});

				const element_documents_bodies = element_documents.map(function(sub_item) {
					const retval = processBody(sub_item, element.depth + add_depth, true, datasourceQualifiedName + "." + sub_item.metadata.parent_element.name);

					return(retval);
				});

				if (options.template_elements === true) {
					/*
					 * Insert the "template" element
					 */
					new_flat_body.push({
						...element,
						id: `${element.id}@${item.id()}`,
						depth: element.depth + add_depth,
						contents: {
							...element.contents,
							id: undefined,
							version: undefined
						}
					});

					/*
					 * Make every included element a child of the template element
					 * by increasing depth by 1
					 */
					for (const element_document_body of element_documents_bodies) {
						new_flat_body.push(...element_document_body.map(function(element) {
							return({
								...element,
								depth: element.depth + 1
							});
						}));
					}
				} else {
					for (const element_document_body of element_documents_bodies) {
						new_flat_body.push(...element_document_body);
					}
				}
			}

			if (flat) {
				return(new_flat_body);
			}

			const new_body = assemble_body_sortly_items(new_flat_body, {
				pristine: false,
				template_body: options.template_elements
			});

			return(new_body);
		};

		const body = processBody(this._toplevel);

		const bodyObject = new DocumentBody(body, this);

		return(bodyObject);
	}

	/**
	 * Process variables to render on UI
	 */
	async processVariablesForDocumentRender() {
		const variables = await this.processVariablesBase({
			add_missing_variables: function (variableName) {
				return `<span class='var__value-missing'>${variableName}</span>`;
			},
			document_variable_callback: async function (value, info) {
				if (info.type === "richtextarea") {
					return `<div class="var__value-rte">${value}</div>`;
				}

				if (info.type === 'reference') {
					const replacement_values = {
						value: value.value,
						type: value.type,
						parent_value: "",
					};

					const reference_value = get_reference_value(value, value.id, value.value, replacement_values);

					return(reference_value);
				}

				if (value && value.type === 'image') {
					return (`<img style="max-width: 100%" src="${value.image}">`);
				}

				if (info.type === "datasource") {
					value = await fetch_data_source_value(this._user_id, info.options.source, info.options);
				}
				return value;
			}
		});

		return variables;
	}

	/**
	 * Base function that processes variables of all documents.
	 * This function does not add missing variables.
	 * Use `processVariablesForDocumentRender` to include missing variables.
	 * @param {*} options
	 */
	async processVariablesBase(options = {}) {
		const toplevel_document_info = {
			document_id: this._toplevel.id(),
			version_id: this._toplevel.version()
		};

		const {
			document: documentIDMap,
			template: templateIDMap
		} = this._idMap();

		const variables = await process_document_variables(this._user_id, toplevel_document_info, {
			get_user_document: async function(user_id, document_id, _ignored_version_id) {
				const document_object = documentIDMap[document_id];
				if (!document_object) {
					return {};
				}

				const document = document_object.document();
				if (!document) {
					return {};
				}

				return document;
			},
			get_user_template_from_document: async function(user_id, document) {
				const template_info = document.template;
				if (!template_info) {
					return {};
				}

				const template_id = template_info.id;

				const template_object = templateIDMap[template_id];
				if (!template_object) {
					return {};
				}

				const template = template_object.template();
				if (!template) {
					return {};
				}

				return template;
			},
			get_toplevel_document: async (user_id, document_id) => {
				const toplevel = this._toplevel.document();

				if (toplevel.id !== document_id) {
					throw (new Error('internal error: unexpected toplevel'));
				}

				return toplevel;
			},
			...options,
		});
		return variables;
	}

	/* XXX:TODO: Maybe one day this will be replaced with a sync call */
	async bodyEvaluatedAsync(options = {}) {
		options = {
			throwOnError: false,
			...options
		};

		/*
		 * Cache items for 90 minutes
		 */
		const cache_ttl = 90 * 60 * 1000;

		const body = this.body({
			template_elements: true
		}).value;

		const variables_info = this.variables();

		const cache_arena_name = 'cache_body';
		const body_hash = kaialpha.lib.general_utils.hash(JSON.stringify(body), -1);
		const cached_document_body_key = `document_${body_hash}`;
		const variables_hash = kaialpha.lib.general_utils.hash(JSON.stringify(variables_info), -1);
		let variables_hash_found;

		let body_cache_valid = false;
		const cache_check_variables_hash = await kaialpha.lib.cache_utils.cache_promise(cache_arena_name, cached_document_body_key, async () => {
			return(null);
		}, {
			cache_expires: cache_ttl
		});

		if (cache_check_variables_hash) {
			variables_hash_found = cache_check_variables_hash.variables_hash;
		}

		if (variables_hash_found === variables_hash) {
			body_cache_valid = true;
		}

		if (!body_cache_valid) {
			kaialpha.lib.debug.log('documents-object', 'Deleting top-level document render cache for', cache_arena_name, cached_document_body_key, 'because its variables_hash is not valid.', `(expected ${variables_hash}, found ${variables_hash_found})`);
			await kaialpha.lib.cache_utils.clear(cache_arena_name, cached_document_body_key);
		}

		const constructed_body_info = await kaialpha.lib.cache_utils.cache_promise(cache_arena_name, cached_document_body_key, async () => {
			kaialpha.lib.debug.log('documents-object', 'Recreating top-level document render cache for', cache_arena_name, cached_document_body_key);
			const {
				document: documentIDMap,
				template: templateIDMap
			} = this._idMap();

			const cached_template_body_key = `template_${body_hash}`;
			const constructed_body_template_parts = await kaialpha.lib.cache_utils.cache_promise(cache_arena_name, cached_template_body_key, async () => {
				kaialpha.lib.debug.log('documents-object', 'Recreating top-level template render cache for', cache_arena_name, cached_template_body_key);
				return(await kaialpha.lib.generator_utils.generateNunjucksValueFromBody(body, {
					user_id: this._user_id,
					validate_elements: true,
					get_user_document: async function(document_id, document_version) {
						const documentObject = documentIDMap[document_id];
						if (!documentObject) {
							return({});
						}

						const document = documentObject.document();

						if (document_version !== 'HEAD' && document_version !== document.version) {
							throw(new Error(`Asked to fetch unsupported version of document ${document_version} (we have ${document.version})`));
						}

						return(document);
					},
					get_user_template: async function(template_id, template_version) {
						const templateObject = templateIDMap[template_id];
						if (!templateObject) {
							return({});
						}

						const template = templateObject.template();
						if (template_version !== 'HEAD' && template_version !== template.version) {
							throw(new Error(`Asked to fetch unsupported version of template ${template_version} (we have ${template.version})`));
						}

						return(template);
					},
					get_user_templates: async function() {
						throw(new Error('unsupported'));
					},
					process_document_variables: async (document_id) => {
						if (document_id !== this._toplevel.id()) {
							throw(new Error(`This is only supported on the toplevel document (which has id ${this._toplevel.id()}), not on ${document_id}`));
						}

						return(variables);
					},
					element_error_callback: function(error_message) {
						/* XXX:TODO: Should we insert some kind of error message into the output ? */
						kaialpha.lib.debug.log('generator.error', error_message);
						return([]);
					},
					mapping_function: kaialpha.lib.generator_utils.jsObject_mapping_function
				}));
			}, {
				cache_expires: cache_ttl
			});

			const constructed_body_template = constructed_body_template_parts.join('\n');

			const render_options = {
				abbr_list_entries: this._abbr_list_entries
			};

			const variables = await this.processVariablesForDocumentRender();

			let constructed_body_local;
			try {
				const constructed_body_json_str = kaialpha.lib.nunjucks_utils.renderString(constructed_body_template, variables, render_options);
				constructed_body_local = JSON.parse(constructed_body_json_str).filter(function(check_element) {
					if (Object.keys(check_element).length === 0) {
						return(false);
					}

					return(true);
				});
				// merge html elements
				constructed_body_local = get_merge_indicators_for_html_elements(constructed_body_local);
			} catch (render_error) {
				if (options.throwOnError) {
					throw(render_error);
				} else {
					constructed_body_local = [
						{[uuid.v4()]: {
							type: 'comment',
							text: `Fatal error while rendering document: ${render_error}`
						}}
					];
				}
			}

			return({
				variables_hash: variables_hash,
				body: constructed_body_local
			});
		}, {
			cache_expires: cache_ttl
		});

		const bodyObject = new DocumentBody(constructed_body_info.body, this);

		return(bodyObject);
	}

	async _renderString(string) {
		const variables = await this.processVariablesForDocumentRender();

		return(kaialpha.lib.nunjucks_utils.renderString(string, variables));
	}

	getToplevelName() {
		return(this._toplevel.getName());
	}

	setToplevelName(name) {
		const retval = this._toplevel.setName(name);
		this._runOnChange();
		return(retval);
	}

	async versions(options = {}) {
		options = {
			nowait: false,
			...options
		};

		if (!options.nowait) {
			await this.wait();
		}

		const our_document_id = this._toplevel.id();

		const promises = [];
		this.forEach(function(/** @type {Document} */ document) {
			if (!document) {
				return;
			}

			promises.push((async function() {
				let versions;

				try {
					versions = await document.versions();
					if(versions && versions.length >0) {
						const finalVersions = versions.map((result)=>{
							return {
								...result,
								id: document.id()
							}
						});
						versions = finalVersions;
					}
				} catch (versions_error) {
					/* Ignore this ? XXX:TODO */
				}
				return(versions);
			})());
		}, {
			type: 'document'
		});

		const all_document_versions_statuses = await Promise.allSettled(promises);
		const all_document_versions = all_document_versions_statuses.map(function(result) {
			if (result.status !== 'fulfilled') {
				throw(result.reason);
			}

			return(result.value);
		});

		const unified_version_info = [];
		for (const single_document_versions of all_document_versions) {
			if (!single_document_versions) {
				continue;
			}

			for (const document_version_info of single_document_versions) {
				unified_version_info.push({
					...document_version_info,
					id: our_document_id,
					version: `${document_version_info.version}@${document_version_info.id}`,
					'$document_id': document_version_info.id
				});
			}
		}
		console.log('unified_version_info');
		console.log(unified_version_info);
		const unified_version_info_sorted = unified_version_info.sort(function(a, b) {
			return(a.date - b.date);
		});

		/*
		 * Discard any versions that are prior to the first version of
		 * the toplevel document, these versions don't make sense to
		 * look at in most cases
		 */
		let seen_our_document_id = false;
		const unified_version_info_sorted_filtered = unified_version_info_sorted.filter(function(version_info) {
			const version_info_document = version_info['$document_id'];
			if (version_info_document === our_document_id) {
				seen_our_document_id = true;
			}

			return(seen_our_document_id);
		});

		return(unified_version_info_sorted_filtered);
	}

	variables(options = {}) {
		const variables = {};
		this.forEach(function(/** @type {Document} */ document, path) {
			if (!document) {
				return;
			}

			const document_id = document.id();

			const variableNamePrefix = path.map(function(part) {
				return(part.name);
			});

			const variableData = document.variables(options);
			for (const variableShortName in variableData) {
				const variableInfo = variableData[variableShortName];
				const variableName = [...variableNamePrefix, variableShortName].join('.');

				if (variableInfo['$element_id']) {
					variableInfo['$element_id'] = `${variableInfo['$element_id']}@${document_id}`;
				}

				variables[variableName] = {
					...variableInfo,
					'$document_id': document_id
				};
			}
		}, {
			type: 'document'
		});
		return(variables);
	}

	/**
	 * This method fetches fresh data for the given variable. If no variable is provided then it fetches data for all the datasources
	 * in the current synthetic document. After fetching data from the source, the synthetic document is updated with the latest variable
	 * value, however the backend is not updated. Expectation here is that caller would know that synthetic document has been updated
	 * and at the right time, specifically when user decides to save or publish the document, then updated synthetic document
	 * can be saved directly which would then have updated variable values. This puts control of the save functionality within user's hand
	 * @param {string} [variableName] Name of the variable, optional. If not provided, then all variables would be fetched
	 * @param {any} [variableInfo] VariableInfo, optional, if not provided, then existing synthetic doc variables are traversed for variable info
	 */
	async get_latest_data_for_variables(variableName, variableInfo) {
		let all_variables = {};
		if (variableName) {
			all_variables[variableName] = variableInfo ?? this.getVariableInfo(variableName) ;
		} else {
			all_variables = this.variables();
		}
		for(const variableName in all_variables) {
			const currentVariable = all_variables[variableName];
			if (currentVariable.type === "datasource") {
				const data = await fetch_document_data_source_value(this._toplevel._user_id, this._toplevel.id(), this._toplevel.version(), currentVariable.options)
				this.setVariable(variableName, data);
			}
		}
	}

	/**
	 * Returns the variable info for the fully qualified variable name
	 * @param {string} fullyQualifiedVariableName Fully qualified variable name such as global.subtemplate01.datasource
	 * @returnsvariableInfo associated with the given variable
	 */
	getVariableInfo(fullyQualifiedVariableName, options) {
		const variables = this.variables(options);
		// make sure we are referencing the correct variable, regardless of case.
		const variableKey = Object.keys(variables).find(variable => variable.match(new RegExp(fullyQualifiedVariableName, "i")));
		//Variables are returned in key value format, hence the above search first tries to find the item key
		//and then we use that key to return the actual value
		return variables[variableKey];
	}

	/**
	 * Accepts the variable name and returns value associated. If the variable is part of the subdocument,
	 * then this function would iterate
	 * @param {string} name Fully Qualified variable Name
	 * @param {object} options
	 * @returns Variable value as stored in the document
	 */
	getVariable(name, options = {}) {
		const variableInfo = this.getVariableInfo(name, options);
		if (!variableInfo) return undefined;

		const document_id = variableInfo['$document_id'];
		const name_short = name.split('.').slice(-1)[0];
		const {
			document: documentIDMap,
		} = this._idMap();
		const document = documentIDMap[document_id];
		if (!document) {
			throw(new Error(`Unable to find document that corresponds to variable ${name}`));
		}
		return document.getVariable(name_short, options);
	}

	setVariable(name, value) {
		const variables = this.variables();
		const variableInfo = variables[name];
		const document_id = variableInfo['$document_id'];

		const name_short = name.split('.').slice(-1)[0];

		const {
			document: documentIDMap,
		} = this._idMap();

		const document = documentIDMap[document_id];
		if (!document) {
			throw(new Error(`Unable to find document that corresponds to variable ${name}`));
		}

		return(document.setVariable(name_short, value));
	}

	/**
	 * Computes the given expression using nunjucks and returns the result
	 * @param {string} expression
	 */
	async getExpressionValue(expression) {
		// may be processVariablesBase call can be cached and invalidated on variable change
		const variables = await this.processVariablesForDocumentRender();
		/**
		 * compute_expression only returns string type.
		 * so, stringify the result incase the result is expected to be an object
		 */
		const exp_to_compute = `${expression} | stringify`;
		const expression_value = JSON.parse(kaialpha.lib.nunjucks_utils.compute_expression(exp_to_compute, variables));
		return expression_value;
	}

	/**
	 * Evaluate an expression variable and return result
	 * @param {string} name - name of the expression variable
	 * @param {object} evaluateOptions - optional options
	 * @returns {Promise<any>}
	 */
	async evaluateExpressionVariable(name, evaluateOptions = {}) {
		const { removeInvalidResult = true } = evaluateOptions;

		const variableInfo = this.getVariableInfo(name, { typeWritableOnly: false });
		const { type, options: varOptions } = variableInfo || {};
		const { expression } = varOptions || {};

		if (type !== 'expression' || !expression) {
			return undefined;
		}

		const result = await this.getExpressionValue(expression);
		if (removeInvalidResult) {
			// UI Table element throws if the data is empty
			if (result?.data?.invalid || !result?.data?.length) {
				return undefined;
			}
		}
		return result;
	}

	comments(options = {}) {
		options = {
			mutate: true,
			...options
		}
		const comments = {};

		this.forEach(function(/** @type {Document} */ document, path) {
			const document_id = document.id();
			const document_comments = document.comments(options);
			for (const part in document_comments) {
				const part_comments = document_comments[part];

				for (const comment_on_id in part_comments) {
					const id_comments = [...part_comments[comment_on_id]];
					let new_comment_on_id;
					if (part === 'variables') {
						const variable_name_prefix = path.map(function(path_part) {
							return(path_part.name);
						});
						new_comment_on_id = [...variable_name_prefix, comment_on_id].join('.');
					} else if (part !== 'documents') {
						new_comment_on_id = `${comment_on_id}@${document_id}`;
					} else {
						new_comment_on_id = comment_on_id;
					}

					if (comments[part] === undefined) {
						comments[part] = {};
					}
					/*
					 * We mutate the comment directly because there may be
					 * other references to it within the comments
					 */
					for (const comment of id_comments) {
						comment.id = `${comment.id}@${document_id}`;
						comment['$document_id'] = document_id;
					}

					comments[part][new_comment_on_id] = id_comments;
				}
			}

		}, {
			type: 'document'
		});
		return(comments);
	}

	static _parseVariableName(name) {
		const parts = name.split('.');

		const local = parts.slice(-1)[0];
		const prefix = parts.slice(0, -1).join('.');

		const retval = {
			prefix,
			local
		};

		return(retval);
	}

	/**
	 * Parses given id to separate document and element ids
	 * @param {string} id
	 *
	 * @returns {{
	 * document_id: string;
	 * element_id: string;
	 * lookup_id: string;
	 * extra_info?: { subaddress: string };
	 * }}
	 */
	static _parseElementID(id) {
		const parts = id.split('@');

		let document_id, element_id, extra_info_subaddress;
		if (parts.length === 1) {
			/*
			 * If we are only given a single ID,
			 * it is a comment for the Document ID
			 * which is left as if that were the
			 * element ID
			 */
			document_id = parts[0];
			element_id = parts[0];
		} else {
			document_id = parts.slice(-1)[0];
			element_id = parts.slice(-2, -1)[0];
			extra_info_subaddress = parts.slice(0, -2).join('@');
		}

		const lookup_id = `${element_id}@${document_id}`;

		const retval = {
			/* The document ID */
			document_id,
			/* The element ID */
			element_id,
			/* The ID to lookup in the elements table */
			lookup_id
		};

		if (extra_info_subaddress) {
			retval.extra_info = {
				subaddress: extra_info_subaddress
			};
		}

		return(retval);
	}

	_parseCommentOn(on) {
		const { member, id: canonical_id } = Document._parseCommentOn(on);

		const {
			document: documentIDMap,
		} = this._idMap();

		let document_id;
		let id;
		let idSpecifier;
		let idCanonical = canonical_id;
		let extraCommentData;
		switch (member) {
			case 'variables':
				{
					/*
					 * This currently only allows you to set
					 * variables which exist, but could be
					 * updated in the future to find the
					 * document ID from the prefix to pass
					 * the request on.
					 */
					const variables = this.variables();
					const variable_info = variables[canonical_id];
					if (variable_info) {
						const { local: local_name } = Documents._parseVariableName(canonical_id);
						document_id = variable_info['$document_id'];
						id = local_name;
						idSpecifier = 'name';
					}
				}
				break;
			case 'elements':
				{
					const {
						document_id: element_document_id,
						element_id,
						lookup_id,
						extra_info
					} = Documents._parseElementID(canonical_id);

					id = element_id;
					idCanonical = lookup_id;
					if (Object.keys(documentIDMap).includes(id)) {
						/* Comment on the whole document */
						document_id = id;
						idSpecifier = 'id';
					} else {
						/* A comment on an element or a comment */
						const element = this._element(lookup_id);
						if (element) {
							const found_document_id = element_document_id;
							document_id = found_document_id;
							idSpecifier = 'id';
							extraCommentData = extra_info;
						} else {
							/* Maybe this is a comment on a comment */
							const existing_comments = this.comments({ raw: true });
							let existing_comments_elements = {};
							if (existing_comments && existing_comments.elements) {
								existing_comments_elements = existing_comments.elements;
							}

							const comment_id_map = {};
							for (const existing_comment_on_id in existing_comments_elements) {
								const existing_comments = existing_comments_elements[existing_comment_on_id];
								for (const existing_comment of existing_comments) {
									comment_id_map[existing_comment.id] = existing_comment;
								}
							}

							const existing_comment = comment_id_map[lookup_id];
							if (existing_comment) {
								document_id = existing_comment['$document_id'];
								idSpecifier = 'id';
							}
						}
					}
				}
				break;
			default:
				throw(new Error(`Unsupported kind of comment: ${member} from ${JSON.stringify(on)}`));
		}

		let document;
		if (id === undefined) {
			idCanonical = undefined;
			idSpecifier = undefined;
		} else {
			document = documentIDMap[document_id];
		}

		return({
			member,
			id,
			idSpecifier,
			idCanonical,
			extraCommentData,
			document
		});
	}

	getComments(on) {
		const { member, idCanonical } = this._parseCommentOn(on);
		if (!member || !idCanonical) {
			return(undefined);
		}

		const all_comments = this.comments({raw: true});
		const comments_of_type = all_comments[member];
		if (!comments_of_type) {
			return([]);
		}

		const comments_on = comments_of_type[idCanonical];
		if (!comments_on) {
			return([]);
		}

		return(comments_on);
	}

	async addComment(on, comment) {
		const { member, id, idSpecifier, document, extraCommentData } = this._parseCommentOn(on);
		if (!document) {
			return(undefined);
		}

		await document.addComment({ type: member, [idSpecifier]: id }, {
			...comment,
			...extraCommentData
		});
	}

	/**
	 * Verify if document is in approved state or user has comment write access
	 * @param {KaiAlphaDocumentID} id - id of document
	 * @returns {Promise<boolean>} - True - action allowed / false - action not allowed
	 */
	async can_user_write_comment(id){
		const document = this.documentsMap()[id];
		const actionAllowed = await document.can_user_write_comment();
		return actionAllowed;
	}

	/**
	 * Validate user action on comment if action is allowed/not-allowed
	 * @param {KaiAlphaCommentEntry} comment - The comment to leave
	 * @param {object} comment_area - parenet comment
	 * @returns {Promise<boolean>} true/false
	 */
	async isCommentActionAllowed(comment, comment_area, options){
		const idParts = comment.id.split('@');
		const documentId = /** @type {KaiAlphaDocumentID} */idParts[1];
		const document = this.documentsMap()[documentId];
		const actionAllowed = await document.isCommentActionAllowed(comment, comment_area, options);
		return actionAllowed;
	}

	async updateComment(id, comment) {
		const idParts = id.split('@');
		const commentId = idParts[0];
		const documentId = idParts[1];

		const document = this.documentsMap()[documentId];

		await document.updateComment(commentId, comment);
	}
	/**
	 * Delete comment on a document
	 * @param {KaiAlphaElementID} id
	 */
	async deleteComment(id) {
		const idParts = id.split('@');
		const commentId = /** @type {KaiAlphaElementID} */(idParts[0]);
		const documentId =/** @type {KaiAlphaDocumentID} */idParts[1];

		const document = this.documentsMap()[documentId];

		await document.deleteComment(commentId);
	}

	/**
	 * Wrapper to post a workflow event to a document. Any changes to the document are saved before posting.
	 * @param {string} documentId - document to post the event to
	 * @param {string} event_name - name of workflow event
	 * @param {*} event_info - arguments passed to workflow event
	 * @param {*} [options] - workflow options
	 */
	async completeWorkflowEvent(documentId, event_name, event_info, options = {}) {
		const document = this.documentsMap()[documentId];
		const retval = await document.completeWorkflowEvent(event_name, event_info, options);
		this._runOnChange();
		return(retval);
	}

	/**
	 * Marks the given element as viewed by current user during review.
	 * @param {string} unique_element_id - unique id of this element. ex:`element_id@document_id`
	 * @param {string} [slot_name] - workflow slot to post tracking events to
	 * @returns {boolean} `false` if tracking events cannot be added to workflow
	 */
	trackReview(unique_element_id, slot_name = 'default') {
		/**
		 * No need to track if document is not in review.
		 * Currently only top document's status is changed during workflow.
		 * This check can be moved to individual Document.trackReview() once the subdocument's state also shows 'review'.
		 */
		if (this._toplevel.document().state.toLowerCase() !== 'review') {
			return(false);
		}

		const {
			document_id,
			element_id,
			extra_info: { subaddress } = {}
		} = Documents._parseElementID(unique_element_id);

		// store element id with complete address for easy lookup later. Ex: `foo@loop-1@element_id`
		const tracking_element_id = subaddress ? `${subaddress}@${element_id}` : element_id;

		const document_map = this.documentsMap();
		const document = document_map[document_id];
		const can_add_to_workflow = document.trackReview(tracking_element_id, slot_name);

		// initialize an interval to periodically save the tracking data
		// no need register interval if nothing save
		if (can_add_to_workflow) {
			this._checkInitializeTrackingInterval();
		}

		return(can_add_to_workflow);
	}

	/**
	 * Helper to check if the tracking interval is setup and initialize if not.
	 */
	async _checkInitializeTrackingInterval() {
		if (this._trackingEventsInterval) {
			return;
		}

		this._trackingEventsInterval = setInterval(async () => {
			await this._postTrackingEvents();
		}, 30 * 1000 /* 30 sec */);
	}

	/**
	 * Post buffered tracking events of all documents
	 */
	async _postTrackingEvents() {
		const document_map = this.documentsMap();
		const documents_list = Object.values(document_map);

		let doc_changed = false;
		const promises = documents_list.map(async (document) => {
			const result = await document._postTrackingEvents();
			if (result !== undefined) {
				doc_changed = true;
			}
			return(result);
		});

		// rethrow if any of the promises failed
		const promise_results = await Promise.allSettled(promises);
		promise_results.find(function(result) {
			if (result.status !== 'fulfilled') {
				throw(result.reason);
			}
			return(false);
		});

		if (doc_changed) {
			this._runOnChange();
		}
	}

	_runOnChange() {
		if (this.onChange) {
			this.onChange(this);
		}
	}

	/**
	 * Get the elements that are viewed by user during review process.
	 * @param {string} [user_id] - user to get the tracking status of, defaults to the current user.
	 * @param {string} [workflow_slot] - name of the review workflow to check tracking data, defaults to `default` workflow.
	 * @returns {string[]} tracked element ids
	 */
	getTrackedElementsOfUser(user_id = this._user_id, workflow_slot = 'default') {
		const document_map = this.documentsMap();
		const tracked_elements = [];
		for (const document of Object.values(document_map)) {
			const document_id = document.id();
			const document_tracked_elements = document.getTrackedElementsOfUser(user_id, workflow_slot)
				.map((element_id) => `${element_id}@${document_id}`);
			tracked_elements.push(...document_tracked_elements);
		}
		return tracked_elements;
	}

	saveRequired() {
		let isRequired = false;
		this.forEach(function(/** @type {Document} */ document) {
			if (document.saveRequired()) {
				isRequired = true;
			}
		}, {
			type: 'document'
		});

		return(isRequired);
	}

	async save(options = {}) {
		const promises = [];
		this.forEach(function(/** @type {Document} */ document) {
			promises.push((async function() {
				return(await document.save(options));
			})());
		}, {
			type: 'document'
		});

		const saveResults = await Promise.allSettled(promises);

		await this._updateVersion();

		const changed = saveResults.find(function(result) {
			if (result.status !== 'fulfilled') {
				throw(result.reason);
			}
			return(result.value);
		});

		/* Version changed */
		this._runOnChange();

		return(Boolean(changed));
	}

	async refresh() {
		const promises = [];
		this.forEach(function(/** @type {Document} */ document) {
			promises.push(document.refresh());
		}, {
			type: 'document'
		});

		const refreshResults = await Promise.allSettled(promises);

		await Promise.all([
			this._updateVersion(),
			this._updateExpandedPermissions(),
		]);

		// throw if any promise failed
		const changed = refreshResults.find(function(result) {
			if (result.status !== 'fulfilled') {
				throw(result.reason);
			}
			return(result.value);
		});

		return(Boolean(changed));
	}

	/**
	 * Returns the canonical(expanded) permissions and roles of the top level document.
	 *
	 * @returns {Promise<KaiAlphaDocumentPermissions>}
	 */
	async getExpandedPermissions() {
		return this._toplevel.getExpandedPermissions();
	}

	/**
	 * Fetch and set canonical permissions on all documents.
	 *
	 * All subdocuments inherit same permissions as top level document.
	 * This will set the expanded permissions in each subdoc manually to avoid redundant
	 * db fetches by each sub doc while fetching permissions.
	 */
	async _updateExpandedPermissions() {
		const canonicalPermissions = await this.getExpandedPermissions();
		this.forEach(function (/** @type {Document} */ document) {
			document._canonicalPermissions = canonicalPermissions;
		}, {
			type: 'document'
		});
	}

	destroy() {
		(async () => {
			// first finish any events that depend on this._contents
			await this.clearTrackingEventsInterval();

			const documents = Object.values(this.documentsMap());
			documents.forEach(function(document) {
				document.destroy();
			});
		})();
	}
}

/*
 * A temporary bridge object that has the same shape as the KaiAlpha "document"
 * object, but which represents a collection of documents (class Documents).
 *
 * This will be removed in the near future.
 *
 * @implements {KaiAlphaBody}
 */
class SyntheticDocuments {
	/**
	 * @param {{ user_id: string } | null} ka_context - Session context
	 * @param {{ id: string, version: string, permissions?: object }} toplevelContents - Information for toplevel document
	 */
	constructor(ka_context, toplevelContents) {
		/*
		 * If a version was supplied work with it as a version of the
		 * toplevel document
		 */
		toplevelContents = (function() {
			if (!toplevelContents || !toplevelContents.version) {
				return(toplevelContents);
			}
			if (toplevelContents.version === 'HEAD') {
				return(toplevelContents);
			}
			if (toplevelContents.version.substr(0, 6) === '@date:') {
				return(toplevelContents);
			}
			if (toplevelContents.version.match(/@/)) {
				return(toplevelContents);
			}
			if (toplevelContents.permissions) {
				return(toplevelContents);
			}

			return({
				...toplevelContents,
				version: `${toplevelContents.version}@${toplevelContents.id}`
			});
		})();

		this.__cache_getters = {};

		this.__documents = new Documents(ka_context, toplevelContents);
		this.__documents.onChange = () => {
			this.__runOnChange();
		};

		this.subdocuments = {};
		this.onChange = undefined;
		this.__updateBody();
	}

	async fetchComments(){
		await this.__documents.__fetchComments();
	}

	__updateBody() {
		this.__updateNeeded = true;
		this.__promise = (async () => {
			await this.__documents.wait();
			this.__body = (await this.__documents.bodyEvaluatedAsync()).withComments().withSections();
			this.__updateNeeded = false;

			this.__runOnChange();
		})();
	}

	__runOnChange() {
		this.__cache_getters = {};

		if (this.onChange) {
			this.onChange(this);
		}
	}

	/**
	 * Waits for initialization to be complete, must be done before any of
	 * the non-async methods can be used
	 */
	async wait() {
		if (!this.__promise) {
			return;
		}

		await this.__promise;
		this.__promise = undefined;
	}

	/**
	 * Performs any outstanding changes (variables, comments)
	 *
	 * @param {{ force?: boolean, strongForce?: boolean }} options - Options
	 * @returns {Promise<boolean>} Indicating that it was saved successfully
	 */
	async save(options = {}) {
		if (!this.__documents.saveRequired()) {
			return true;
		}

		const retval = await this.__documents.save(options);
		return(retval);
	}

	/** Check if save is required
	 *
	 * @returns {boolean} returns boolean
	 */
	saveRequired() {
		return this.__documents.saveRequired();
	}

	/**
	 * Refresh local copy of document and subdocuments with their
	 * on-server versions.  If there is a newer version of a document
	 * and there are pending changes it will throw an error
	 *
	 * @returns {Promise<boolean>} Indicating true if something changed
	 */
	async refresh() {
		const retval = await this.__documents.refresh();
		this.__updateBody();
		await this.wait();

		return(retval);
	}

	/**
	 * Escape hatch to access the DocumentBody instance
	 *
	 * @returns {DocumentBody}
	 */
	get _body() {
		if (this.__updateNeeded) {
			throw(new Error('must call wait() first'));
		}

		return(this.__body);
	}

	/**
	 * Returns a KaiAlpha body object
	 *
	 * @returns {any[]} KaiAlpha body object
	 */
	get body_extend() {
		if (this.__updateNeeded) {
			throw(new Error('must call wait() first'));
		}

		return(this.__body.value);
	}

	/**
	 * Gets the state of the top-level document
	 *
	 * @returns {string}
	 */
	get state() {
		return(this.__documents._toplevel.document().state);
	}

	/**
	 * This method would set the state of the top-level document,
	 * but it's not implemented.
	 */
	set state(new_state) {
		throw(new Error('not implemented'));
	}

	/**
	 * Get the name of the top-level document
	 *
	 * @returns {string} Top-level document name
	 */
	get name() {
		return(this.__documents.getToplevelName());
	}

	/**
	 * Sets the name of the top-level document
	 *
	 * @param {string} new_name - New top-level document name
	 */
	set name(new_name) {
		this.__documents.setToplevelName(new_name);
	}

	/**
	 * Get the list of variables and their values.  These variables
	 * will be fully-qualified.  You can change them with the
	 * setVariable method.
	 *
	 * @returns {any} An object whose keys are the variable names and values are their values
	 */
	get variables() {
		if (this.__cache_getters.variables) {
			return(this.__cache_getters.variables);
		}

		const retval = {};
		const variables = this.__documents.variables();
		for (const variable_name in variables) {
			const variable_info = variables[variable_name];
			const variable_value = variable_info.value;

			if (variable_value === undefined) {
				continue;
			}

			retval[variable_name] = variable_value;
		}

		this.__cache_getters.variables = retval;

		return(retval);
	}

	set variables(_ignored_value) {
		throw(new Error('Must use setVariable'));
	}

	_qualifyVarName(name) {
		if (name.substr(0, 7) === 'global.') {
			return(name);
		}

		return(`global.${name}`);
	}

	/**
	 * Set a document variable.  The "name" parameter must come from the
	 * "variables()" method.
	 *
	 * @param {string} name
	 * @param {any} value
	 * @returns {any} The new value that was set
	 */
	setVariable(name, value) {
		name = this._qualifyVarName(name);

		const retval = this.__documents.setVariable(name, value);

		this.__updateBody();

		return(retval);
	}

	/**
	 * Get a document variable's value
	 * @param {string} name
	 * @param {object} [options]
	 * @returns {any} The value of the variable
	 */
	getVariable(name, options) {
		name = this._qualifyVarName(name);

		const retval = this.__documents.getVariable(name, options);

		return(retval);
	}

	/**
	 * Evaluate and get an expression variable value
	 * @param {string} name
	 * @param {object} [options]
	 * @returns {Promise<any>} The value of the expression variable
	 */
	async evaluateExpressionVariable(name, options = {}) {
		name = this._qualifyVarName(name);
		const retval = await this.__documents.evaluateExpressionVariable(name, options);
		return retval;
	}

	/**
	 * The top-level document permissions
	 *
	 * @returns {any} KaiAlpha permissions document
	 */
	get permissions() {
		return(this.__documents._toplevel.document().permissions);
	}

	set permissions(_ignored_value) {
		throw(new Error('set permissions directly'));
	}

	/**
	 * The top-level document's workflow state, used to get the UI
	 *
	 * @returns {any} KaiAlpha workflow UI state
	 */
	get workflow() {
		return(this.__documents._toplevel.document().workflow);
	}

	/**
	 * Get the document ID -- this will be the same as the top-level ID
	 *
	 * @returns {string} Document ID
	 */
	get id() {
		return(this.__documents.id());
	}

	/**
	 * Get the current document version, or "@new" if there are unsaved
	 * changes.
	 *
	 * @returns {string} Document version or "@new"
	 */
	get version() {
		// when testing got a null error on the version object on occasion
		if(this.__documents === undefined) {
			return('@new');
		} else {
			return(this.__documents.version());
		}
	}

	get previous_version() {
		throw(new Error('not implemented'));
	}

	/**
	 * Get the list of comments for a document, for elements and variables
	 *
	 * returns {{ elements: any, variables: any }} Element and variable comments
	 */
	get comments() {
		if (this.__cache_getters && this.__cache_getters.comments) {
			return(this.__cache_getters.comments);
		}
		const raw_comments = this.__documents.comments({ raw: true });

		const retval = {
			elements: {},
			variables: {
				...raw_comments.variables
			}
		};

		/*
		 * We can be more perscriptive here than the default since we
		 * know the body is fully evaluated, this will aid in the
		 * transition
		 */
		if (raw_comments.elements) {
			for (const element_id in raw_comments.elements) {
				const comments_entries = raw_comments.elements[element_id];

				for (const comment of comments_entries) {
					let new_entry_id = `${element_id}`;
					if (comment.subaddress) {
						new_entry_id = `${comment.subaddress}@${element_id}`;
					}

					if (!retval.elements[new_entry_id]) {
						retval.elements[new_entry_id] = [];
					}

					retval.elements[new_entry_id].push(comment);
				}
			}
		}

		this.__cache_getters.comments = retval;

		return(retval);
	}

	set comments(_ignored_value) {
		throw(new Error('use escape hatch'));
	}

	/**
	 * Returns the variable info for the fully qualified variable name
	 * @param {string} variableName Fully qualified variable name such as global.patientNarratives.vitals
	 * @returns {any} variableInfo associated with the given variable
	 */
	getVariableInfo(variableName, options) {
		return this.__documents.getVariableInfo(variableName, options);
	}

	/**
	 * This method fetches fresh data for the given variable. If no variable is provided then it fetches data for all the datasources
	 * in the current synthetic document. After fetching data from the source, the synthetic document is updated with the latest variable
	 * value, however the backend is not updated. Expectation here is that caller would know that synthetic document has been updated
	 * and at the right time, specifically when user decides to save or publish the document, then updated synthetic document
	 * can be saved directly which would then have updated variable values. This puts control of the save functionality within user's hand
	 * @param {string} [variableName] Name of the variable, optional. If not provided, then all variables would be fetched
	 * @param {any} [variableInfo] VariableInfo, optional, if not provided, then existing synthetic doc variables are traversed for variable info
	 */
	async get_latest_data_for_variables(variableName, variableInfo) {
		return await this.__documents.get_latest_data_for_variables(variableName, variableInfo);
	}

	/**
	 * Get all the comments on an element, document, variable, or comment
	 *
	 * @param {Object} on - Which item to leave a comment on
	 * @param {("elements"|"variables"|"document"|"comments")} on.type - Type of item to leave a comment on
	 * @param {string} [on.id] - ID of the element, document, or comment to comment on
	 * @param {string} [on.name] - Name of the variable to leave a comment on (type=variables)
	 * @returns {KaiAlphaCommentEntry[]} The comments on this item
	 */
	getComments(on) {
		return(this.__documents.getComments(on));
	}

	/**
	 * Add a comment on an element, document, variable, or comment
	 *
	 * @param {Object} on - Which item to leave a comment on
	 * @param {("elements"|"variables"|"document"|"comments")} on.type - Type of item to leave a comment on
	 * @param {string} [on.id] - ID of the element, document, or comment to comment on
	 * @param {string} [on.name] - Name of the variable to leave a comment on (type=variables)
	 * @param {KaiAlphaCommentEntry} comment - The comment to leave
	 * @returns {Promise<KaiAlphaCommentEntry>} The new comment
	 */
	async addComment(on, comment) {
		const retval = await this.__documents.addComment(on, comment);

		this.__updateBody();

		this._cache_comments = undefined;

		return(retval);
	}

	/**
	 * Verify if document is in approved state or user has comment write access
	 * @param {KaiAlphaDocumentID} id - Id of document
	 * @returns {Promise<boolean>} - True - action allowed / false - action not allowed
	 */
	async can_user_write_comment(id){
		const actionAllowed = await this.__documents.can_user_write_comment(id);
		return actionAllowed;
	}

	/**
	 * Validate user action on comment if action is allowed/not-allowed
	 * @param {KaiAlphaCommentEntry} comment - The comment to leave
	 * @param {object} comment_area - parenet comment
	 * @param {object} options - Action details
	 * @returns {Promise<boolean>} true/false
	 */
	async isCommentActionAllowed(comment, comment_area, options){
		return await this.__documents.isCommentActionAllowed(comment, comment_area, options)
	}

	/**
	 * Update an existing comment
	 *
	 * @param {string} id - The ID of the comment to update
	 * @param {KaiAlphaCommentEntry} comment - The comment to leave
	 */
	async updateComment(id, comment) {
		await this.__documents.updateComment(id, comment);

		this.__updateBody();

		this._cache_comments = undefined;
	}

	/**
	 * Delete an existing comment
	 * @param {KaiAlphaElementID} id - The ID of the comment to delete
	 */
	async deleteComment(id) {
		await this.__documents.deleteComment(id);

		this.__updateBody();

		this._cache_comments = undefined;
	}

	/**
	 * Escape hatch for incremental upgrades.  You may use this to access
	 * the Documents instance for things which we do not currently support
	 *
	 * @returns {Documents} The instance of the Documents that backs the current instance
	 */
	get _internalObject() {
		return(this.__documents);
	}

	_runOnChange() {
		this.__runOnChange();
	}

	/**
	 * Clean up instances of the Documents
	 */
	destroy() {
		this._internalObject.destroy();
		for (const cleanup of ['_internalObject', '__documents', '_cache_comments', '__cache_getters']) {
			delete this[cleanup];
		}
	}

}

const _to_export_auto = {
	Document,
	Documents,
	SyntheticDocuments,
	Body,
	body_element_ids,
	body_element_map,
	body_serialize,
	document_expand,
	body_templates,
	body_by_element_tag,
	get_body_sortly_items,
	assemble_body_sortly_items,
	process_document_variables,
	get_data_source_matches,
	fetch_data_source_value,
	fetch_document_data_source_value,
	get_toplevel_document,
	get_value_from_element,
	get_reference_value,
	render_document_processing_variables,
	post_document_workflow_event,
	complete_document_workflow_event,
	get_document_workflow_variable,
	update_document_workflow_state,
	variable_info_from_name,
	get_document_workflow_ui,
	compute_content_type,
	generate_numbering,
	_get_workflow_options,
	_get_image_data /* XXX:TODO: Why is this exported if it's underscore'd ? */,
	_testing
};
export default _to_export_auto;
