import {CssFactoryType, ParentCssSelector} from "./model";
import {StyleElementTypes, StyleElementValue} from "../../editor/elements/AddStyleElement";
import {createStyle} from "./styles";
import {StyleAttribute} from "../../editor/elements/styles";

const elementTypeMap: Record<StyleElementTypes, string> = {
	html: "p",
	section: "h2",
	title: "div.title-preview",
	table: "table",
	default: ".preview__element"
}

function factory(value: unknown): string {
	const styleInfo = value as StyleElementValue;

	const styles = createStyle(styleInfo as Partial<Record<StyleAttribute, string>>, ParentCssSelector);
	return styles.map(css => `${ParentCssSelector} ${elementTypeMap[styleInfo.element_type]} ${css}`).join(" ");
}

const elementStyleCssFactory: CssFactoryType = {
	type: "StyleElement",
	valueFactory: factory
}

export {elementStyleCssFactory};