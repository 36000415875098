import {ensureArgs} from "../filterUtilities";
import {NunjucksFilterFactoryProps} from "../model";

function keys(...args: unknown[]): unknown {
	if (!ensureArgs(args)) {
		return [];
	}

	const object = args[0] as any;
	if (object instanceof Object) {
		return(Object.keys(object));
	}

	return [];
}

export function keysFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "keys",
		help: "Filter that takes in an object and converts it to an array of strings representing the names of the properties",
		example: "var | keys",
		execute: keys
	})
}