import { BaseElement } from "../../../interfaces/BaseElement";
import {isElementValueType} from "../../../interfaces/ElementValueType";

const inTextTableDefinition: BaseElement = {
	name: 'Template',
	type: 'intexttable',
	displayName: 'In-Text Table',
	allowsChildren: false,
	description: 'Place an In-Text Table',
	longDescription: 'Places an In-Text Table in a template, which is a table of a static format, which will have its data set when the content editor sets the Study ID',
	contentDescription:  (contents) => {
		return isElementValueType(contents) ? ((contents.data as InTextTableElementValue).tableName ?? "") : (contents as InTextTableElementValue).tableName ?? "";
	},
	toString: (contents) => {
		return isElementValueType(contents) ? ((contents.data as InTextTableElementValue).tableName ?? "") : (contents as InTextTableElementValue).tableName ?? "";
	}
} as const

export type InTextTableElementValue = {
	tableName?: string
}
export type InTextTableDataValue = {
	table: string
}
export type InTextTableVariableValue = InTextTableDataValue & Required<InTextTableElementValue> & {
	studyId: string
}

export function isInTextTableVariable(obj: unknown): obj is InTextTableVariableValue {
	const value = obj as any;
	return obj !== undefined && value["studyId"] !== undefined && value["table"] !== undefined && value["tableName"] !== undefined;
}

export {inTextTableDefinition};