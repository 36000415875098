import { ReadonlyElementProps } from "../../model";
import { useState } from "react";
import { Button, useStatusBar } from "../../../../../../shared/components";
import { useGetTemplate } from "../../../../hooks/api/useGetTemplate";
import { useRunWhenValueChange } from "../../../../../../shared/hooks";
import { computeElements } from "../../../../utilities/elementsFactory";
import { Box } from "@mui/material";
import { ElementView } from "./ElementView";
import { elementDataFactory } from "../../index";
import { PropertyList } from "../../PropertyList";
import { TemplateElementValue } from "../../../../../../shared/definitions/elements/template/model";
import { TemplateElement } from "../../../../../../shared/interfaces/TemplateElement";
import { parseTemplateElements } from "../../../../../../shared/utilities/parseTemplateElements";

const ReadonlyView = (props: ReadonlyElementProps) => {
	const value = props.data as TemplateElementValue;
	const [elements, setElements] = useState<TemplateElement[]>([]);
	const statusBar = useStatusBar();
	const getTemplate = useGetTemplate();

	useRunWhenValueChange(() => {
		if (getTemplate.value) {
			const templateElements = parseTemplateElements(getTemplate.value);
			setElements(computeElements(templateElements));
		}
	}, getTemplate.value);

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({ message: "Error previewing template", detail: getTemplate.error });
	}, getTemplate.error)

	const onHideTemplatePreview = () => setElements([]);
	const onPreviewTemplate = () => getTemplate.execute({ id: value.id! });

	return <PropertyList items={[{ label: "Name", value: value.name }, { label: "Value type", value: value.id ? "ID" : "Expression" }]}>
		{elements.length === 0 && value.id && <Button buttonType={getTemplate.isLoading ? "disabled" : "default"} text={getTemplate.isLoading ? "Loading preview" : "Preview template"} onClick={onPreviewTemplate} sx={{ mt: ".5rem" }} />}
		{elements.length > 0 && <Button text={"Hide preview"} onClick={onHideTemplatePreview} sx={{ mt: ".5rem" }} />}
		{elements.length > 0 && <Box sx={{ mt: "1rem" }}>
			{elements.map(element => <ElementView {...elementDataFactory(element.type, element.id, element.contents, true)} />)}
		</Box>}
	</PropertyList>
}

export { ReadonlyView };