type CommentStateReview = "IN REVIEW";
type CommentStateResolved = "RESOLVED";

export type CommentStatesType = {
	IN_REVIEW: CommentStateReview,
	RESOLVED: CommentStateResolved
}
export const CommentStates: CommentStatesType = {
	IN_REVIEW: "IN REVIEW",
	RESOLVED: "RESOLVED"
};

export interface CommentPayload {
	elementId?: string,
	comment: KaiAlphaCommentEntry
}

// TODO replace this with KaiAlphaCommentEntry when API and OpenSearch are merged in
export const starterComment: KaiAlphaCommentEntry = {
	// comment ID
	id: "",
	// comment version
	// version?: string;

	// user of id of who wrote the comment
	author: "",
	/**
	 * The ISO 8601 format timestamp this comment was created
	 */
	// timestamp in ISO 8601 format for when comment was created
	date: new Date().toISOString(),
	// comment state
	state: CommentStates.IN_REVIEW,
	// actual text of the comment
	comment: "",
	//
	// highlighting
	// element text that is being highlighted
	// text?: string;
	//
	// highlighting
	// textual context
	// text_properties?: {
	//   /**
	//    * Contextual text that contains the comment text the comment belongs to, if applicable
	//    */
	//   context?: string;
	//   /**
	//    * Identifies the occurrence of the context text within the element text, if applicable
	//    */
	//   context_occurrence?: number;
	//   /**
	//    * container element id that contains the text the comment belongs to, if applicable
	//    */
	//   text_container_id?: string;
	//   /**
	//    * variable name this comment was applied against
	//    */
	//   variable_name?: string;
	// };
	// when comment is column
	// column?: string | number;
	// when comment is in row
	// row?: string | number;

	// comment tag
	tag: "Grammar/Cosmetic",

	// highlighting
	// when comment applies to all text in document
	// document_wide?: boolean;

	// highlighting
	// loop and switch address within document
	// subaddress?: string;
}