import {ExternalContent, useGetExternalContent} from "../../../../hooks/api/useGetExternalContent";
import {useRunOnce, useRunWhenValueChange} from "../../../../../../shared/hooks";
import {AsyncComponent} from "../../../../../../shared/components";
import {useState} from "react";
import {ExternalDirectoryBreadcrumbs} from "./ExternalDirectoryBreadcrumbs";
import {EmptyGroup} from "../../../../../../shared/components/layout/groups/EmptyGroup";
import "./styles.scss"
import {ContentListItem} from "./ContentListItem";
import {ContentPickerProps} from "./models";
import {isExternalContent} from "../../../../../../shared/interfaces/SubmissionContent";
import {ExternalFile} from "../../../../../../shared/interfaces/ExternalFile";

function recursivelyFindFolder(folderName: string, root: ExternalContent | null): ExternalContent | null {
	if ((root?.folders.length ?? 0) === 0) {
		return null;
	}

	const folder = root?.folders.find(f => f.name === folderName);
	if (folder !== undefined) {
		return folder;
	}

	for(let i = 0; i < root!.folders.length; i++) {
		const folder = root!.folders[i];
		if (folder.name === folderName) {
			return folder;
		}

		if (folder.folders.length > 0) {
			const subFolder = recursivelyFindFolder(folderName, folder);
			if (subFolder) {
				return subFolder;
			}
		}
	}

	return null;
}

const RootFolder = "Root folder";
const ExternalContentPicker = (props: ContentPickerProps) => {
	const [paths, setPaths] = useState<string[]>([]);
	const [selectedFolder, setSelectedFolder] = useState<ExternalContent | null>(null)
	const contentLoader = useGetExternalContent();
	useRunOnce(() => {
		contentLoader.execute();
	})

	useRunWhenValueChange(() => {
		setSelectedFolder(contentLoader.value);
		setPaths([RootFolder]);
	}, contentLoader.value)

	const onSelectFolder = (folderName: string) => {
		const folder = selectedFolder?.folders.find(f => f.name === folderName) ?? recursivelyFindFolder(folderName, contentLoader.value) ?? contentLoader.value;
		const pathIndex = paths.findIndex(p => p === folderName);
		const newPaths = [...paths];

		if (pathIndex > -1) {
			newPaths.splice(pathIndex);
		} else {
			newPaths.push(folderName);
		}

		if (newPaths.length === 0) {
			newPaths.push(RootFolder)
		}
		setPaths(newPaths);
		setSelectedFolder(folder);

	}

	const onToggleSectionFactory = (file: ExternalFile) => () => props.onContentSelectionToggle({location: file.url, internal: false, name: file.name})
	const contentIsSelected = (content: ExternalFile) => props.selectedItems.some(item => isExternalContent(item) && item.location === content.url);
	const hasFiles = (selectedFolder?.files.length ?? 0) > 0;

	const files = props.filters?.searchText !== undefined ? (selectedFolder?.files.filter(f => f.name.toLowerCase().includes(props.filters?.searchText!.toLowerCase()!))) : selectedFolder?.files;
	return <AsyncComponent
		isLoading={contentLoader.isLoading}
		loadingMessage={"Loading content..."}
		component={<>
			<ExternalDirectoryBreadcrumbs paths={paths} selectedFolder={selectedFolder} onSelectFolderName={onSelectFolder} />
			{hasFiles && <ul className={"ectd__modal--contentpicker-list"}>
				{files?.map(file => <ContentListItem id={file.url} name={file.name} isSelected={contentIsSelected(file)} onToggleSelection={onToggleSectionFactory(file)} />)}
			</ul>}
			{!hasFiles && <EmptyGroup title={"No content"} />}
		</>} />
}

export {ExternalContentPicker}