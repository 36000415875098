import {DialogProps} from "../../../../models/elements";
import {useState} from "react";
import {VariableListing} from "../../VariableListing";
import {Dialog, TextField} from "../../../../../../lib/ui";
import {Box, styled} from "@mui/material";
import { TableElementValue } from "../../../../../../shared/definitions/elements/table/model";

const DialogContainer = styled(Box)({
	height: '45vh',
	overflowY: 'scroll',
	overflowX: 'hidden',
	padding: "0.5em",
	display: "flex",
	gap: "1em",
	flexDirection: "column",
});

const PropertyEditor = (props: DialogProps) => {
	const {
		value
	} = props;

	const [tableInfo, setTableInfo] = useState(value.data as TableElementValue);
	const [error, setError] = useState(false);
	const [showDialog, setShowDialog] = useState(true);

	const validateDatasource = () => {
		if (tableInfo['datasource'] === undefined){
			return true;
		}

		/* Name can't be empty*/
		if (tableInfo['datasource'].length === 0){
			return true;
		}

		return false;
	}

	const handleApply = () => {
		if(validateDatasource()) {
			setError(true);
			return;
		}

		setError(false);
		if (props.onApply) {
			props.onApply({...props.value, data: tableInfo});
		}
	};

	const handleClose = () => {
		setShowDialog(false);

		/* Reset element type to empty */
		if (props.onClose) {
			props.onClose();
		}
	};

	/* Handles all input change*/
	const handleChange = (value: string, item: string) => {
		setTableInfo(prevState => ({...prevState, [item]: value}));
	};


	return (
		<Dialog
			open={showDialog}
			title={'Table Properties'}
			onClose={handleClose}
			style={{ minWidth: "60%" }}
			buttons={{
				Close: handleClose,
				Apply: handleApply
			}}
		>
			<DialogContainer>
				<TextField
					error={error ? true : false}
					id="outlined-basic"
					label={'Datasource'}
					value={tableInfo.datasource}
					defaultValue={tableInfo.datasource}
					onChange={(e) => handleChange(e.target.value, 'datasource')}
					{...(error && { helperText: `table name should be unique and non-empty` })}
				/>
				<TextField
					id="outlined-basic"
					label={'Title'}
					value={tableInfo.title}
					defaultValue={tableInfo.title}
					onChange={(event) => handleChange(event.target.value, 'title')}
				/>
				<TextField
					id="outlined-basic"
					label={'columns'}
					defaultValue={tableInfo.column_headers}
					onChange={(event) => handleChange(event.target.value, 'column_headers')}
				/>
				<TextField
					id="outlined-basic"
					label={'Rows'}
					defaultValue={tableInfo.row_headers}
					onChange={(event) => handleChange(event.target.value, 'row_headers')}
				/>
				<VariableListing />
			</DialogContainer>
		</Dialog>
	);
};

export {PropertyEditor};