import {CloudDownloadRounded, ErrorRounded} from "@mui/icons-material";
import {SubmissionExtractionStatus} from "../../../../models/Submission";

function renderIconForExtractionStatus(status?: SubmissionExtractionStatus) {
	switch(status) {
		case "Completed":
			return <CloudDownloadRounded />
		case "Error":
			return <ErrorRounded />
		default:
			return null
	}
}

export {renderIconForExtractionStatus}