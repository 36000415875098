import { BaseElement } from "../../../interfaces/BaseElement";
import {isElementValueType} from "../../../interfaces/ElementValueType";

const titleDefinition: BaseElement = {
	name: 'Template',
	type: 'title',
	displayName: 'Title',
	allowsChildren: false,
	description: 'Place Sub-Templates',
	longDescription: 'Template elements may be used to include another template at the position where this element is placed',
	contentDescription:  (contents) => {
		return isElementValueType(contents) ? ((contents.data as TitleElementValue).title ?? "") : (contents as TitleElementValue).title ?? "";
	},
	toString: (contents) => {
		return isElementValueType(contents) ? ((contents.data as TitleElementValue).title ?? "") : (contents as TitleElementValue).title ?? "";
	}
} as const

export type TitleElementValue = {
	title?: string
}

export {titleDefinition};