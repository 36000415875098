import {StyleComponentMap} from "./model";
import {DropDown, DropDownItem} from "../../../../../../../../shared/components/input/dropdown";
import {ElementProps} from "../models";

const sectionNumbering: DropDownItem<string>[] = [{title: 'default', value: '1 --> 1.1 --> 1.1.1'}];

type StyleElementValue = {
	numbering_scheme: string,
	[k: string]: string | null | undefined
}

const View = ({option, value, onChange}: ElementProps) => {
	const data = value as StyleElementValue;
	const onChangeValue = (key: string, newValue: string | null) => onChange({...data, [key]: newValue ?? ""});
	const onSelectNumberingScheme = (numberingScheme: DropDownItem<string>) => onChangeValue("numbering_scheme", numberingScheme.value);

	const getValueWithKey = (key: string) => data && data[key] ? data[key] ?? "" : "";
	const getSelectedNumberingScheme = () => sectionNumbering.find(numbering => numbering.value === getValueWithKey("numbering_scheme"));

	return (<DropDown items={sectionNumbering} onSelect={onSelectNumberingScheme} value={getSelectedNumberingScheme()?.value} label={"Section Numbering Scheme"} />)
}

const AddSectionNumbering: StyleComponentMap = {
	type: "AddSectionNumbering",
	component: View,
	valueFactory: (): StyleElementValue => ({
		numbering_scheme: ""
	})
}
export {AddSectionNumbering}