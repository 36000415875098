import {Column, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {TemplateListActions, TemplateListNode} from "../models";
import {FlexBox} from "../../../../../../shared/components";
import {ToggleChildrenButton} from "../../../../../../shared/components/list/tree/ToggleChildrenButton";
import {NameTableCell} from "../../../../../../shared/components/layout/table/styles";

const NameCell = (props: ColumnViewProps) => {
	const item = props.item as TemplateListNode;

	const onToggleExpansion = () => {
		if (props.onAction) {
			props.onAction(TemplateListActions.expand, props.column);
		}

	}

	const onRowClicked = () => {
		if (props.onAction) {
			props.onAction(TemplateListActions.view, props.column);
		}
	}

	const expanded = item.expanded;
	const expandable = item.childrenIds.length > 0;

	return (<NameTableCell depth={props.depth ?? 0} align={"left"} expandable={expandable}>
		<FlexBox boxSizing="border-box" align="center">
			{expandable && <ToggleChildrenButton expanded={expanded} onToggle={onToggleExpansion} />}
			<span className={'row_title'} onClick={expandable ? onToggleExpansion : onRowClicked}>{item[props.column.field]}</span>
		</FlexBox>
	</NameTableCell>)
}
const Name: Column = ({
	field: 'name',
	title: "Name",
	itemAlign: "left",
	width: "25%",
	headerAlign: "left",
	searchable: true,
	sortable: true,
	views: {
		cell: NameCell
	}
})

export {Name};