import {DocumentState, mapBodyToElementWithTemplate} from "../../../../../hooks/useDocumentState";
import {useState, useMemo} from "react";
import {useRunWhenValueChange} from "../../../../../../../shared/hooks";
import { TreeNode } from "../../../../../../../shared/components/list/tree/TreeList.models";
import { search, sortByType } from "../../../../../../../shared/components/list/tree/TreeList.utils";
import { FilterState } from "../../../../../../../shared/components/input/filter/sort/models";
import {DocumentTreeElement} from "../../../../../models/element";
import {SectionElementPreviewValue} from "../../../../elements/section/model";
const clone = require("rfdc/default");

// the elements available to choose from to create a reference.
interface ReferenceState {
	references: TreeNode[],
	searchValue: string,
	sortState: FilterState,
}

const initialState: ReferenceState = {
	references: [],
	searchValue: "",
	sortState: "none",
}

function flatMapComputedDocumentTree(elements: DocumentTreeElement[]): DocumentTreeElement[] {
	return elements.map(e => {
		if (e.type === "section") {
			const sectionData = e.data as SectionElementPreviewValue
			return [e, ...flatMapComputedDocumentTree(mapBodyToElementWithTemplate("", sectionData.body))];
		}

		return [e];
	}).reduce((all, current) => all.concat(current), []);
}

// simplifies readability of DialogView (the modal for selecting references) for reference elements by breaking out
// data required to operate the reference modal and ListView.
const useReference = () => {
	const [state, setState] = useState(initialState);
	const documentState = DocumentState.useContainer();

	const searchedReferences = useMemo(() => state.searchValue === "" ?
		(state.references ?? []) : search(state.searchValue.toLowerCase().trim(), clone(state.references)),
	[state.searchValue, state.references]);

	const getElementName = (element: TreeNode) => {
		if (element.type === 'header' || element.type === 'footer') {
			return element.data?.value ?? "No Element Name";
		} else return (element.type === "html")
			? "(Preview Unavailable)"
			: element.data?.title ?? (element.data?.name ?? "Unknown Element");
	}

	const computedDocumentTree = flatMapComputedDocumentTree(documentState.computedDocumentTree ?? []).map(element => ({...element, children: [], name: getElementName(element as TreeNode)} as TreeNode));

	const filteredReferences = useMemo(() => state.sortState === "none" ?
		(searchedReferences ?? []) : sortByType(state.sortState, clone(searchedReferences)),
	[state.sortState, searchedReferences]);

	useRunWhenValueChange(() => {
		setState(s => ({...s, references: (computedDocumentTree ?? [])
			.filter(element => element.type !== 'reference' && element.type !== 'variable')}));

	}, computedDocumentTree);

	const updateReference = (childReference: TreeNode) => {
		const newReferences = [...state.references];
		const childIndex = newReferences.findIndex(node => node.id === childReference.id);
		newReferences[childIndex] = childReference;
		setState(s => ({...s, references: newReferences}));
	}

	const setSearchValue = (searchValue: string) => {
		setState(s => ({...s, searchValue}));
	}

	const setSortState = (sortState: FilterState) => {
		setState(s => ({...s, sortState}));
	}

	return {
		updateReference,
		filteredReferences,
		setSearchValue,
		setSortState,
		...state
	}
}

export {useReference}