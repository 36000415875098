import {NunjucksFilterFactory} from "./model";
import {jsonParseFilterFactory} from "./transform/jsonParse";
import {columnFilterFactory} from "./search/column";
import {findRowsFilterFactory} from "./search/findRows";
import {getColumnFilterFactory} from "./search/getColumn";
import {rowFilterFactory} from "./search/row";
import {sortRowsFilterFactory} from "./sort/sortRows";
import {arraySliceFilterFactory} from "./transform/arraySlice";
import {extendLengthFilterFactory} from "./transform/extendLength";
import {flattenFilterFactory} from "./transform/flatten";
import {flattenDataSourcesFilterFactory} from "./transform/flattenDataSources";
import {keysFilterFactory} from "./transform/keys";
import {rejectFilterFactory} from "./transform/reject";
import {includesFilterFactory} from "./search/includes";
import {absoluteFilterFactory} from "./transform/absolute";
import {evalFilterFactory} from "./transform/eval";
import {citeFilterFactory} from "./reference/cite";
import {findColumnsFilterFactory} from "./search/findColumns";
import {citeListFilterFactory} from "./internal/citationList";
import {abbreviationMapFilterFactory} from "./internal/mapAbbreviations";
import {abbreviationListFilterFactory} from "./internal/abbreviationList";
import {stringifyFilterFactory} from "./transform/stringify";


export const nunjucksFilterFactories: readonly NunjucksFilterFactory[] = [
	columnFilterFactory,
	findRowsFilterFactory,
	getColumnFilterFactory,
	rowFilterFactory,
	sortRowsFilterFactory,
	arraySliceFilterFactory,
	extendLengthFilterFactory,
	flattenFilterFactory,
	flattenDataSourcesFilterFactory,
	jsonParseFilterFactory,
	keysFilterFactory,
	rejectFilterFactory,
	includesFilterFactory,
	absoluteFilterFactory,
	evalFilterFactory,
	findColumnsFilterFactory,
	abbreviationMapFilterFactory,
	stringifyFilterFactory
] as const;

export const nunjucksGlobalFilterFactories: readonly NunjucksFilterFactory[] = [
	citeFilterFactory,
	citeListFilterFactory,
	abbreviationListFilterFactory,
] as const;
