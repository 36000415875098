import {Box} from "@mui/material";
import {ElementView} from "./ElementView";
import {elementDataFactory} from "../../index";
import { TemplateElement } from "../../../../../../shared/interfaces/TemplateElement";

const ElementCollectionPreview = ({elements}: {elements: TemplateElement[]}) => {
	return <Box>
		{elements.map(element => <ElementView {...elementDataFactory(element.type, element.id, element.contents, true)} />)}
	</Box>
}

export {ElementCollectionPreview}