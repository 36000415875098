import {CsvMapper, CsvParserOptions} from "./model";
import {CsvDataSource} from "../../../interfaces/DataSource";
import csv_parse_lib from "csv-parse/lib/sync";
import {getUniqueHeaders} from "../getUniqueHeaders";

function parse(data: string | Buffer, options: CsvParserOptions): CsvDataSource {
	const parsedData = csv_parse_lib(data, {
		...options,
		columns: false
	});

	const parsedHeaders = parsedData[0];

	/**
	 ** Append unique value to header if header is a duplicate
	 **/
	const headerValues = getUniqueHeaders(parsedHeaders, "columns-rows");
	const headers = headerValues.deduplicatedHeaders;
	const orderedHeaders = headerValues.orderedHeaders;

	orderedHeaders.unshift('');
	let rowCount = 0;
	const finalData: any = {};
	for (const row of parsedData.slice(1)) {
		for (const columnNameIndexStr in orderedHeaders) {
			const columnNameIndex = Number(columnNameIndexStr);
			if (columnNameIndex === 0) {
				if (!finalData["__row"]) {
					finalData["__row"] = [];
				}

				finalData["__row"].push(row[0]);
				continue;
			}

			const columnName = orderedHeaders[columnNameIndex];
			if (!finalData[columnName]) {
				finalData[columnName] = [];
			}

			finalData[columnName].push(row[columnNameIndex]);
		}
		rowCount = rowCount + 1;
	}

	return {
		data: finalData,
		type: "columns-rows",
		'@metadata': {
			headers,
			ordered_headers: orderedHeaders.filter(columnHeader => columnHeader.length > 0)
		}
	};
}
export function columnsRowsMapperFactory(): CsvMapper {
	return ({
		type: "columns-rows",
		parse
	})
}