import { PreviewElementProps } from "../../model";
import "./style.scss";
import { HtmlElementValue } from "../../../../../../shared/definitions/elements/html/model";
import { useRunWhenValueChange } from "../../../../../../shared/hooks";
import { useState } from "react";
import { renderTemplatedString } from "../../../../../../shared/nunjucks/renderTemplatedString";
import { Html } from "./Html";
import { mapVariables } from "../../utilities/mapVariables";
import { mapReferenceElements } from "../../utilities/mapReferenceElements";

const PreviewView = (props: PreviewElementProps) => {
	const value = props.element.data as HtmlElementValue;
	const [html, setHtml] = useState("");

	useRunWhenValueChange(() => {
		const nunjucksVariables = {};
		const refObject = mapReferenceElements(props.body);
		Object.assign(nunjucksVariables, refObject);

		const filteredVariables = props.variables?.filter((variable) => variable !== null && variable.templateId === props.element.source) ?? [];
		if (filteredVariables.length > 0) {
			const variables = mapVariables(filteredVariables, props.body);
			Object.assign(nunjucksVariables, variables);
		}

		setHtml(renderTemplatedString(value.text, nunjucksVariables));

	}, [props.element.data, props.variables]);

	return <Html className={"tm__html-preview"} value={html} />
}
export { PreviewView };