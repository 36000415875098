import {StatusNotificationType} from "../models";
import {InfoIcon} from "./InfoIcon";
import {WarningIcon} from "./WarningIcon";
import {ErrorIcon} from "./ErrorIcon";
import {SuccessIcon} from "./SuccessIcon";

export interface StatusTypeIconProps {
	type: StatusNotificationType
}

type StatusTypeIconMap = Record<StatusNotificationType, React.ElementType>;

const statusTypeIconMap: StatusTypeIconMap = {
	info: InfoIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	success: SuccessIcon
}

const StatusTypeIcon = ({type}: StatusTypeIconProps) => {
	const Icon = statusTypeIconMap[type];
	return <Icon fontSize={"small"} />
}

export {StatusTypeIcon};