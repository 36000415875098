import React, {CSSProperties} from "react";
import {Box} from "@mui/material";
import {Hr, ItemContainer, Body, Footer} from "./EditableControlBox.style";
import {EditButton} from "./EditButton";
import {DeleteButton} from "./DeleteButton";
import {AddButton} from "./AddButton";
import { ExpandButton } from "./ExpandButton";
import { RequestEditButton } from "./RequestEditButton";

type VoidFunction = () => void;

interface EditableControlBoxProps {
	onAdd?: VoidFunction,
	expandable?: {
		onExpand: VoidFunction,
		expanded: boolean,
	},
	onDelete?: VoidFunction,
	onEdit?: VoidFunction,
	onRequestEdit?: VoidFunction,
	// show actions, even when not hovering
	showActions?: boolean,
	footer?: React.ReactElement,
	body: React.ReactElement,
	style?: CSSProperties
}

const EditableControlBox = (props: EditableControlBoxProps) => {

	return <ItemContainer direction="column" style={props.style}>
		<Body showActions={props.showActions ?? false}>
			<Box flex={1}>
				{props.body}
			</Box>
			<Box className={"actions"}>
				{props.onAdd && <AddButton onClick={props.onAdd} />}
				{props.onRequestEdit && <RequestEditButton onClick={props.onRequestEdit} />}
				{props.onEdit && <EditButton onClick={props.onEdit} />}
				{props.onDelete && <DeleteButton onClick={props.onDelete} />}
			</Box>
			<Box>
				{props.expandable && <ExpandButton onClick={props.expandable.onExpand} expanded={props.expandable.expanded}/>}
			</Box>
		</Body>
		{props.footer && <>
			<Hr/>
			<Footer align="center">
				{props.footer}
			</Footer>
		</>}
	</ItemContainer>
}

export {EditableControlBox};