import {Column, ColumnAccessoryViewProps, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {FlexBox} from "../../../../../../shared/components";
import {DeleteButton} from "../../../../../../shared/components/layout/box/editControlBox/DeleteButton";
import {DataTableCell} from "../../../../../../shared/components/layout/table/styles";
import {SubmissionNode, submissionNodeActions} from "../../../../models";
import {ExtractionStatusText} from "./styles";
import {extractionStatusToDisplayTextMap} from "./mapExtractionStatusToText";
import {renderIconForExtractionStatus} from "./iconForExtractionStatus";


const ActionsCell = (props: ColumnViewProps) => {
	const item = props.item as SubmissionNode;
	const column = props.column;

	const onDeleteSubmission = () => {
		if (props.onAction) {
			props.onAction(submissionNodeActions.delete, props.column);
		}
	}

	const onDownloadZip = () => {
		if (props.onAction) {
			props.onAction(submissionNodeActions.download, props.column);
		}
	}

	const onViewError = () => {
		if (props.onAction) {
			props.onAction(submissionNodeActions.viewError, props.column);
		}
	}

	const getOnClickFunction = () => {
		if (item.extractionStatus?.label === "Completed") {
			return onDownloadZip;
		}

		if (item.extractionStatus?.label === "Error") {
			return onViewError;
		}

		return undefined
	}

	return <DataTableCell
		align={column.itemAlign}
		width={column.width}
		key={`${column.title}-${item.id}`}
		// for non-data displaying cells, allow full width of cell
		sx={{...(!column.field && {paddingLeft: 0})}}
	>
		<FlexBox boxSizing="border-box" paddingLeft="0.7rem" paddingRight="0.5rem" justify="space-between">
			<FlexBox align={"center"} sx={{marginRight: "1rem"}} onClick={getOnClickFunction()}>
				<ExtractionStatusText status={item.extractionStatus?.label}>{extractionStatusToDisplayTextMap[item.extractionStatus?.label ?? "Idle"]}</ExtractionStatusText>
				{renderIconForExtractionStatus(item.extractionStatus?.label)}
			</FlexBox>
			<DeleteButton tooltipText={"Delete Submission"} skipConfirmation onClick={onDeleteSubmission}/>
		</FlexBox>
	</DataTableCell>

}

const ActionsHeader = (props: ColumnAccessoryViewProps) => <FlexBox justify={"space-between"}>
	<p style={{width: "140px", marginRight: "1rem"}}>Extract Version Status</p>
	<p>Delete</p>
</FlexBox>


const Actions: Column = ({
	field: "",
	title: "Actions",
	headerAlign: "right",
	itemAlign: "right",
	width: "50%",
	views: {
		cell: ActionsCell,
		header: ActionsHeader
	}
})

export {Actions};