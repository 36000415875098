/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/client/promise.mjs
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../lib/kaialpha';
import api from './api';
export async function get_user_promise(user_id, promise_id) {
	const retval = await api.call('GET', `promise/${promise_id}`, undefined, {
		wait_for_promise: false
	});

	return(retval);
}

const _to_export = {
	get_user_promise
};
export default _to_export;
