import { AsyncComponent } from '../../../../../shared/components';
import { Button } from '../../../../../shared/components/buttons';
import { BaseModal } from '../../../../../shared/components/modals/BaseModal';
import { TaskNode } from '../../../../../shared/interfaces/task';
import { TasksList } from '../../../../../shared/tasks';
import { DaysUntilDue } from '../../../../../shared/tasks/columns/DaysUntilDue';
import { Task } from '../../../../../shared/tasks/columns/Task';
import { useTasks } from '../../../pages/useTasks';
import { Owner } from '../../../../../shared/tasks/columns/Owner';
import { Status } from '../../../../../shared/tasks/columns/Status';

export interface TemplateTaskModalProps {
    onCancel: () => void,
    visible: boolean,
	templateId: string | undefined,
	templateName: string | undefined,
}

const TemplateTaskModal = ({onCancel, visible, templateId, templateName}: TemplateTaskModalProps) => {
	const list = useTasks(templateId);

	const onTaskSelected = (task: TaskNode) => {
		list.onNavigateToTask(task);
	}
	return <BaseModal
		visible={visible}
		title={templateName ? templateName : "Template Tasks"}
		content={
			<AsyncComponent isLoading={list.isRunning} loadingMessage={"Loading tasks"} component={<TasksList disablePaging={true} defaultColumns={[Task, Owner, DaysUntilDue,Status ]}  tasks={list.tasks} title={""} onTaskSelected={onTaskSelected} />} />
		}
		buttonRow={
			<>
				<Button styling="cancel" text="Cancel" onClick={onCancel}/>
			</>
		}
		onClose={onCancel}
	/>

}

export {TemplateTaskModal};