import {PropertyEditorProps} from "./models";
import {useState} from "react";
import {VariableInput} from "../model";
import {useRunWhenValueChange} from "../../../../../../../../shared/hooks";
import {ExpressionBuilder} from "../../../../../../../../shared/components/input/expressionBuilder";
import {AutocompleteConfig} from "../../../../../../../../shared/components/input/expressionBuilder/models";
import {useVariables} from "../../../../../../hooks/useVariables";
import {nunjucksConstants} from "../../../../../../../../shared/nunjucks/models/NunjucksConstants";

type ExpressionValueObject = {expression: string}
type ExpressionOptions = string | ExpressionValueObject;
type ExpressionState = string;

const mapOptionsToState = (options: ExpressionOptions): ExpressionState => {
	if (typeof(options) === "string") {
		return options;
	}

	return (options as ExpressionValueObject).expression;
}
const ExpressionProperties = ({onChange, value, variables}: PropertyEditorProps) => {
	const variableInfo = value?.data as VariableInput;

	const variableUtils = useVariables();
	const expressionState = typeof(variableInfo.options) !== "undefined" ?
		mapOptionsToState(variableInfo.options! as ExpressionOptions) : "";

	const [state, setState] = useState(expressionState);

	const autoCompleteConfiguration: AutocompleteConfig[] = [
		{
			trigger: '|',
			options: nunjucksConstants.filters
		},
		{
			trigger: ' ',
			options: nunjucksConstants.operators,
			includePredecessors: variables.map((variable) => {
				return `${variable.contents.name}`
			}),
			excludePredecessors: [...nunjucksConstants.operators || [], undefined]
		},
		{
			trigger: ' ',
			options: ['='],
			excludePredecessors: ['=', undefined]
		},
		{
			trigger: nunjucksConstants.any,
			options: variableUtils.namespacedVariableNames()
		}
	]

	useRunWhenValueChange(() => {
		onUpdate();
	}, state)

	const onExpressionChange = (e: {target: {value: string}}) => setState(e.target.value);
	const onUpdate = () => {
		onChange({expression: state});
	}

	return <ExpressionBuilder
		className='editor-item-autocomplete-input'
		value={state}
		autoCompleteConfig={autoCompleteConfiguration}
		onChange={onExpressionChange}
		onBlur={onChange}
	/>
}

export {ExpressionProperties};