import './style.scss';
import CircleIcon from '@mui/icons-material/Circle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { CommentState, DocumentComment } from '../../../hooks/useCommentState';
import { MENU_HEIGHT } from './Comments.constants';
import CommentModal from '../../modals/documentComments/CommentModal';
import {useRunOnce, useScroll} from '../../../../../shared/hooks';
import { Chip } from '../../../../../lib/ui';
import { TagsChip } from './Comments.style';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { CommentStates } from "../../modals/documentComments/CommentModal.models";
import { AsyncComponent, Button } from "../../../../../shared/components";
import { DocumentState } from '../../../hooks/useDocumentState';
import { formatPayload, useDeleteCommentAction, useUpdateCommentAction } from '../../../hooks/actions';
import {useUserList} from "../../../../../shared/hooks/useUserList";
import {BadgeButton} from "../../../../../shared/components/buttons/BadgeButton";
import {colors} from "../../../../../theme";

type OptionType = "Resolve" | "Edit" | "Delete" | 'Response' | 'Add';
const options: OptionType[] = [
	'Resolve',
	'Edit',
	'Delete'
];

export interface CommentsIndividualProps {
	comment: DocumentComment,
	readonly: boolean,
	reply?: boolean,
	isCommentReply?: boolean
}

const CommentsIndividual = (props: CommentsIndividualProps) => {

	const {
		tag,
		date,
		state,
		comment,
		author,
		elementId,
		text_properties,
		text,
		replies,
		id,
		entityType
	} = props.comment;

	const commentsManager = CommentState.useContainer();
	const documentManager = DocumentState.useContainer();
	const userList = useUserList();
	const updateCommentAction = useUpdateCommentAction();
	const deleteCommentAction = useDeleteCommentAction();

	const [showModal, setShowModal] = useState(false);
	const [username, setUsername] = useState("");
	const [showReplies, setShowReplies] = useState(false);
	const [option, setOption] = useState<OptionType>("Add");
	const scrollManager = useScroll();

	useRunOnce(async () => {
		const userResponse = await commentsManager.getUserDisplayname(author);
		setUsername(userResponse);
	});

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);


	const handleCommentModalClose = () => {
		setShowModal(false);
	}

	// click event sets the anchor for the menu
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = async (value: OptionType) => {
		setAnchorEl(null);
		setOption(value);

		if (value === 'Resolve') {
			if (comment) {
				updateCommentAction.run({
					editResolve: 'RESOLVE',
					documentId: props.comment.sourceDocumentId!,
					commentPayload: formatPayload(props.comment)
				});
			}
		} else if (value === 'Edit') {
			if (comment) {
				setShowModal(true);
			}
		} else if (value === 'Delete') {
			if (comment) {
				deleteCommentAction.run({ documentId: props.comment.sourceDocumentId!, commentPayload: props.comment });
			}
		}
	};

	const menuClose = () => {
		setAnchorEl(null);
	}

	const scrollToSelectedElement = () => {
		if (elementId) {
			scrollManager.scrollById(elementId);
			commentsManager.commentScroll(props.comment);
		}
	}

	const handleReply = () => {
		setOption("Response");
		setShowModal(true);
	}

	const handleExpandReplies = () => {
		setShowReplies(!showReplies);
	}

	const showOptionMenu = () => {
		if (props.isCommentReply) {
			return (state && state === CommentStates.IN_REVIEW && entityType === 'RESPONSE') ? true : false;
		}else{
			return (state && state === CommentStates.IN_REVIEW) ? true : false
		}
	}

	const addUserNames = (commentText: string) => {
		const regularExpression = /<uid:([\w-]*)>/gm;
		const matches = commentText.match(regularExpression);
		return matches?.reduce((text, match) => {
			const userId = match.replace("<uid:", "").replace(">","");
			const displayName = userList.value?.users.find(u => u.id === userId)?.display_name;
			return text.replace(match, `<span class="comments-individual__actual--recorded-user">${displayName ?? match}</span>`)
		}, commentText) ?? commentText;
	}

	return (<>
		<AsyncComponent
			isLoading={updateCommentAction.isRunning || deleteCommentAction.isRunning}
			component={<div id={id} className={["comments-individual", (props.reply ? "comments-individual__reply-value" : ""), (commentsManager.selectedComment?.id === id ? "comments-individual-selected" : "")].join(" ")} >
				<CircleIcon sx={{ fontSize: "11px", color: "#4D72BD", paddingRight: "18px", paddingTop: "11px" }} />
				<div
					className={`comments-individual__actual ${elementId && elementId !== documentManager!.document!.id ?
						"comments-individual__actual-clickable" : ""}`}
					onClick={scrollToSelectedElement}
				>
					{text_properties && <div>
						<span className={"comments-individual__actual-highlight"}>
							{text}
						</span>
					</div>}
					<div dangerouslySetInnerHTML={{__html: addUserNames(comment)}}></div>
					<TagsChip>
						<LocalOfferIcon className="chipIcon" />
						<Chip
							className="selectedChip"
							label={tag}
							clickable={elementId !== documentManager!.document!.id}
						/>
					</TagsChip>
					<div className="comments-individual__actual--recorded-user">
						@{username}
					</div>
					<div className="comments-individual__actual--recorded-date">
						{new Date(date).toLocaleDateString("nl", { year: "2-digit", month: "2-digit", day: "2-digit" })}
						{" "}
						{new Date(date).toLocaleTimeString('en-US')}
					</div>
					{!props.reply &&
						<div className="comments-individual__reply comments-individual__reply--multiple">
							<BadgeButton badgeCount={replies ? replies.length : 0} icon={<QuestionAnswerIcon fontSize={"small"} sx={{color: (replies?.length ?? 0) > 0 ? colors.darkBlue : "rgba(0,0,0,0.26)"}} />} buttonType={(replies?.length ?? 0) > 0 ? "plain" : "disabled"} text={"Replies"} onClick={handleExpandReplies} />
							<Button text={"Reply"} onClick={handleReply} />
						</div>
					}
					{showReplies && replies && replies.map((value) => {
						return <CommentsIndividual
							key={value.id}
							comment={value}
							readonly={!documentManager.commentable || value.author !== commentsManager.getCurrentUserId()}
							reply={true}
							isCommentReply={true}
						/>;
					})}
				</div>
				{/* Menu was originally copied from https://mui.com/material-ui/react-menu/#max-height-menu */}
				{showOptionMenu() &&
					<div className="comments-individual__menu">
						<Button buttonType={"chromeless"} icon={<MoreVertIcon />} onClick={handleClick} />
						<Menu
							anchorEl={anchorEl}
							open={open}
							PaperProps={{
								style: {
									maxHeight: MENU_HEIGHT * 4.5,
									width: '20ch',
								},
							}}
							onClose={menuClose}
						>
							{options.map((option) => (
								<MenuItem key={option} selected={false} onClick={() => handleClose(option)}>
									{option}
								</MenuItem>
							))}
						</Menu>
					</div>
				}
			</div >} />
		{
			showModal &&
			<CommentModal
				onClose={handleCommentModalClose}
				addEditComment={option}
				showOpen={showModal}
				elementId={id}
				documentId={props.comment.sourceDocumentId}
				initialComment={option.toString() === 'Edit' ? formatPayload(props.comment) : undefined}
			/>
		}
	</>);
};

export { CommentsIndividual }