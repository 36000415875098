import {Popper, Box} from "@mui/material";
import { Button, FlexBox } from "../../../../../shared/components";
import { VariableUploadContainer, FileText } from "./VariableUploadCard.style";
import {useState, useRef} from 'react';
import { LoadingIndicator } from "../../../../../shared/components/progress/LoadingIndicator";
import { FileSelectionButton } from "../../../../../shared/components/buttons/theme";
const csv_parse = require('csv-parse/lib/sync');

export type VariableUploadCardProps = {
	open: boolean,
	anchorEl: HTMLElement | null,
	onData: (data: {[key: string]: string}[]) => void,
	onCancel: () => void
}

/**
 * This component accepts a file and parses that file into a metadata object.
 * @constructor
 */
const VariableUploadCard = ({open, anchorEl, onData, onCancel}: VariableUploadCardProps) => {
	const [file, setSelectedFile] = useState<File | null>(null);
	const [loading, setLoading] = useState(false);
	const inputFile = useRef<HTMLInputElement | null>(null);

	const chooseFileClicked = () => {
		inputFile.current!.click();
	};

	const fileSelected = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setSelectedFile(e.target.files[0]);
	}

	const onUpload = () => {
		const reader = new FileReader();
		reader.readAsText(file!);
		reader.onload = () => {
			//const result = {};

			try {
				const variablesData = csv_parse(reader.result, {
					columns: true
				})
				onData(variablesData);
			} catch (e) {
				console.error('issue parsing metadata', e);
			}
			setLoading(false);
		}
		setLoading(true);
		setSelectedFile(null);
	}

	return <Popper
		id={"metadata-upload-card"}
		open={open}
		anchorEl={anchorEl}
		placement="bottom-start"
		popperOptions={{
			modifiers: [{
				name: "offset",
				options: {
					offset: [-20, 5],
				},
			}],
		}}
	>
		<VariableUploadContainer direction="column">
			<h3>Upload Variables</h3>
			<FlexBox align="center" className="file_display">
				<FileSelectionButton onClick={chooseFileClicked}/>
				<FileText>
					<span className={file !== null ? 'selected' : ''}>{file !== null ? file.name : 'No file chosen'}</span>
				</FileText>
			</FlexBox>
			<input type='file' id='metadata-file' ref={inputFile} style={{display: 'none'}} onChange={fileSelected}/>
			<FlexBox align="center" justify="flex-end">
				<Button text="Upload" disabled={file === null} onClick={onUpload}/>
				<Box margin="0px 5px"/>
				<Button styling="cancel" text="Cancel" onClick={onCancel} />
			</FlexBox>
			{(loading ?? false) && <LoadingIndicator />}
		</VariableUploadContainer>
	</Popper>
}

export {VariableUploadCard}