import {styled, Theme} from "@mui/material";

function colorFromDepth(depth: number, theme: Theme): string | undefined {
	switch(depth) {
		case 0:
			return theme.palette.table?.contrastText;
		default:
			return theme.palette.plain?.hover;
	}
}

type NumberLabelProps = {
	depth: number
}
export const NumberLabel = styled("span")<NumberLabelProps>(({depth,theme}) => ({
	background: `${colorFromDepth(depth, theme)} 0% 0% no-repeat padding-box`,
	color: depth === 0 ? "white" : theme.palette.plain?.contrastText,
	padding: "0.15rem 0.75rem",
	fontSize: "0.7rem",
	borderRadius: "0.25rem",
	marginRight: "0.75rem"
}));