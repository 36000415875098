import {SvgIconComponent} from "@mui/icons-material";
import { FlexBox } from "../../../shared/components";
import { Button } from "../../../shared/components";
import { colors } from '../../../theme/colors';
import { useNavigate } from "react-router-dom";
import { DashboardCardContainer, DashboardCardHeader, DashboardTitle, LaunchIcon} from './DashboardCard.style';
import { Tooltip } from "../../../shared/components/labels/Tooltip";
import { Box } from "@mui/system";
export interface DashboardCardProps {
	transparent?: boolean,
	expandRoute?: string,
	content: JSX.Element,
	header: {
		title: string,
		Icon?: SvgIconComponent,
		iconColored?: boolean
		titleColored?: boolean,
		// custom component for the header.
		component?: JSX.Element,
	}
}

const DashboardCard = ({header, expandRoute, content, transparent} : DashboardCardProps) => {
	const navigate = useNavigate();
	return <DashboardCardContainer transparent={transparent}>
		<DashboardCardHeader align="center" justify="space-between">
			<FlexBox align="center">
				{header.Icon && <header.Icon style={{fontSize: '1.5rem', marginRight: '0.5em', fill: header.iconColored ? colors.otherDarkBlue : colors.almostBlack}}/>}
				<DashboardTitle style={{color: header.titleColored ? colors.otherDarkBlue : colors.almostBlack}}>{header.title}</DashboardTitle>
			</FlexBox>
			{header.component && header.component}
			{expandRoute &&
				<Tooltip title={`Go to ${header.title}`} placement="top">
					<Box>
						<Button
							buttonType="chromeless"
							icon={<LaunchIcon/>}
							sx={{p: 0}}
							onClick={() => navigate(expandRoute!)}
						/>
					</Box>
				</Tooltip>

			}
		</DashboardCardHeader>
		{content}
	</DashboardCardContainer>
}

export {DashboardCard};