import { TemplateElement } from "../../../shared/interfaces/TemplateElement";

const clone = require("rfdc/default");

export function assemblyBodySortlyItems(items: TemplateElement[] | [], parentId?: string): KaiAlphaTemplateBody {
	const itemBody = clone(items);
	let response: KaiAlphaTemplateBody | null= null;
	let lastItemDepth = 0;
	const currentItems: unknown[] = [];
	let currentItem: any = {};

	for (const item of itemBody) {
		if (item.depth > lastItemDepth) {
			if ((item.depth - lastItemDepth) !== 1) {
				throw(new Error(`Skip insert of item detected, lastItemDepth was ${lastItemDepth}, but this item is ${item.depth}; item = ${JSON.stringify(currentItem)}`));
			}

			currentItems.push(currentItem);

			const currentItemWrapper = currentItem['body'].slice(-1)[0];
			currentItem = currentItemWrapper[Object.keys(currentItemWrapper)[0]];
		} else if (item.depth < lastItemDepth) {
			for (let count = (lastItemDepth - item.depth); count > 0; count--) {
				currentItem = currentItems.pop();
			}
		}

		if (item.type === '@meta:value') {
			if (currentItem.type !== 'switch') {
				throw(new Error(`Error "value" item may only be a child of a "switch" item; item = ${JSON.stringify(currentItem)}`));
			}

			if (currentItem['values'] === undefined) {
				currentItem['values'] = {};
			}
			currentItem['values'][item.contents.value] = {
				body: []
			};

			currentItems.push(currentItem);
			currentItem = currentItem['values'][item.contents.value];

			lastItemDepth = item.depth + 1;
			continue;
		} else if (item.type === '@meta:default') {
			if (currentItem.type !== 'switch') {
				throw(new Error(`Error "default" item may only be a child of a "switch" item; item = ${JSON.stringify(currentItem)}`));
			}
			currentItem['default'] = {
				body: []
			};

			currentItems.push(currentItem);
			currentItem = currentItem['default'];

			lastItemDepth = item.depth + 1;
			continue;
		} else if (item.type === '@meta:else') {
			if (currentItem.type !== 'loop') {
				throw(new Error(`Error "else" item may only be a child of a "loop" item; item = ${JSON.stringify(currentItem)}`));
			}
			currentItem['else'] = {
				body: []
			};

			currentItems.push(currentItem);
			currentItem = currentItem['else'];

			lastItemDepth = item.depth + 1;
			continue;
		}

		if (currentItem.type === 'switch') {
			if (item.type.substr(0, 6) !== '@meta:') {
				throw(new Error(`Attempted to create a child of an element with type "${currentItem.type}", new type is not "@meta:*" but instead "${item.type}"`));
			}
		}

		delete item.contents['$from'];
		delete item.contents['$variable_descriptor'];

		const body_item = {
			[item.id]: item.contents
		};

		if (currentItem['body'] === undefined) {
			currentItem['body'] = [];
		}

		currentItem['body'].push(body_item);

		if (response === null) {
			response = currentItem['body'];
		}

		lastItemDepth = item.depth;
	}

	if (response === null) {
		response = [];
	}

	return(response as KaiAlphaTemplateBody);
}