import React from 'react';
import user from '../../api/user';
import { withStyles } from '@mui/styles'; // LEGACY

import {
	Typography,
	defaultStyle,
} from '../../lib/ui';
import { AppChrome } from '../App/Chrome/AppChrome';

function styles(theme) {
	return({
		...defaultStyle(theme),
		greeting: {
			color: theme.palette.secondary.main
		},
		group_info: {
			...theme.typography.subtitle2,
			color: theme.palette.text.primary
		},
		user_info: {
			color: theme.palette.text.primary,
			...theme.typography.subtitle2,
			paddingTop: '2em',
			'& tbody': {
				'& tr': {
					'& th': {
						fontWeight: theme.typography.fontWeightLight,
						paddingRight: '4em',
						textAlign: 'left'
					},
					'& td': {
						fontWeight: theme.typography.fontWeightMedium,
						paddingTop: '0.25em',
						paddingBottom: '0.25em',
					}
				}
			}
		},
		unknown_info: {
			fontStyle: 'italic',
			fontWeight: theme.typography.fontWeightRegular
		}
	});
}

async function init() {
	const user_info = await user.get_user_info();

	return({
		user_id: user_info["id"],
		display_name: user_info["display_name"],
		user_name: user_info["name"]
	});
}

/* XXX:TODO: Move to helper library */
async function initHelper(obj, lambda) {
	const retval = await lambda();

	/* This is an undocumented method! */
	if (obj.updater.isMounted(obj)) {
		obj.setState(retval);
	} else {
		obj.state = {
			...obj.state,
			...retval
		}
	}
}

class Profile extends React.Component {
	constructor(...args) {
		super(...args);
		this.state = {
			user_id: '',
			display_name: '',
			user_name: '',
			groups: [],
			roles: []
		}

		initHelper(this, init);
	}

	get buffer_save_freq() {
		const current_freq = user.get_user_pref(undefined, 'user/buffer_save_freq');

		return(current_freq);
	}

	set buffer_save_freq(new_value) {
		const new_value_sanitized = Number(new_value);
		user.set_user_prefs(undefined, {
			'user/buffer_save_freq': new_value_sanitized
		});

		/* XXX:TODO: I guess really the buffer frequency could be part of this components state... */
		this.forceUpdate();
	}

	renderUserInfo(attribute) {
		if (this.state[attribute]) {
			return(this.state[attribute]);
		}

		return(<span className={this.props.classes.unknown_info}>(unknown)</span>);
	}

	render() {
		let no_groups_message;
		let no_roles_message;
		let display_groups;
		let display_roles;

		if (this.state.groups.length === 0) {
			no_groups_message = <>You are not a member of any groups</>;
		} else {
			display_groups = <div>
				{this.state.groups.map((group) =>
					<p>{group}</p>
				)}
			</div>;
		}

		if (this.state.roles.length === 0) {
			no_roles_message = <>You do not have any roles</>
		} else {
			display_roles = <>
				{this.state.roles.map((role) =>
					<p>{role}</p>
				)}
			</>;
		}

		return(<AppChrome
			center={<div className={this.props.classes.content}>
				<div>
					<Typography variant='h4' className={this.props.classes.greeting}>Hi, {this.state.display_name}</Typography>
					<div className={this.props.classes.group_info}>{no_groups_message}{display_groups}</div>
					<table className={this.props.classes.user_info}>
						<tbody>
							<tr>
								<th>Username</th>
								<td>{this.renderUserInfo('user_name')}</td>
							</tr>
							<tr>
								<th>Email address</th>
								<td>{this.renderUserInfo('email')}</td>
							</tr>
							<tr>
								<th>Phone number</th>
								<td>{this.renderUserInfo('phone')}</td>
							</tr>
							<tr>
								<th>Roles</th>
								<td>{display_roles}{no_roles_message}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>}
		/>);
	}
}

export default withStyles(styles)(Profile);
