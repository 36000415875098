import { Box, styled } from "@mui/material/";

const TabButtonsContainer = styled(Box)({
	margin: "0px 3px",
});

const CenteredTabsContainerBox = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	border: "solid 1px",
	borderRadius: "100px",
});

const TagsChip = styled(Box)(({theme}) => ({
	display:'flex',
	alignItems: 'center',
	border: 'none',
	marginRight: '5px',
	"& .selectedChip": {
		marginLeft: "11px",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.08)"
		}
	},
	"& .chipIcon": {
		color: "#426AB9"
	}
}));

export {
	TabButtonsContainer,
	CenteredTabsContainerBox,
	TagsChip
}