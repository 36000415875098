import {EntityType} from "../../../../interfaces/EntityType";
import {QueryResponse} from "../QueryResponse";
import api from "../../../../../api/api";
import {MongoQuery} from "./MongoQuery";

export type MongoQueryApiProps = {
	type: EntityType,
	query: {body: MongoQuery},
	sort: {field: string, direction: "asc" | "desc"},
	pageSize: number,
	page: number
}

type MongoQueryApiResponse<T> = {
	results: T[],
	total: number
}
export async function mongoQueryApi<T>({type, query, page, pageSize, sort}: MongoQueryApiProps): Promise<QueryResponse<T>> {
	const queryBody = {size: pageSize, query:query.body, page, sort}
	const response: MongoQueryApiResponse<T> = await api.call("POST", `search/${type}`, queryBody);
	return {
		results: response.results,
		pageSize: pageSize,
		total: response.total,
		next: [page+1]
	}
}