import { PreviewElementProps } from "../../model";
import { PreviewViewFactory } from "../../PreviewViewFactory";


const PreviewView = (props: PreviewElementProps) => {
	return <>
		{props.element.children?.map(child => <PreviewViewFactory
			key={child.id}
			noMargin={true}
			state={props.state}
			isLoadingVariables={props.isLoadingVariables}
			metaData={props.metaData}
			body={props.body ?? []}
			variables={props.variables}
			element={child}
		/>)
		}
	</>
}

export { PreviewView };
