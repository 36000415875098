import { useTheme, useMediaQuery, styled, Box } from "@mui/material/";
import { FlexBox } from "../FlexBox";
import { PaneContent } from "./PaneContent";
import { useEffect, useState } from "react";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { Tabs } from "../tabs/Tabs";
import { TabDefinition } from "../tabs/tabDefinition";
import { Button } from "../../buttons";
import { useSessionStorage } from "../../../hooks/persistence/useSessionStorage";
export interface ExpandKeyboardTabIconProps {
	rotated: boolean,
}

const ExpandKeyboardTabIcon = styled(KeyboardTabIcon, { shouldForwardProp: (prop) => prop !== "rotated" })<ExpandKeyboardTabIconProps>(({ rotated }: ExpandKeyboardTabIconProps) => ({
	transform: rotated ? 'rotate(180deg)' : 'none',
}));

export interface TabbedPaneProps {
	orientation: "left" | "right",
	tabs: TabDefinition[],
}

const TabbedPane = ({ orientation, tabs }: TabbedPaneProps) => {
	const sessionKey = `template-${orientation}-selectedIndex`
	const theme = useTheme();
	const sessionStorage = useSessionStorage();
	const [selectedIndex, setSelectedIndex] = useState<number>(sessionStorage.get<number>(sessionKey!, 0)!);
	const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
	const [expanded, setExpanded] = useState(isLargeScreen);
	const onToggleExpansion = () => setExpanded(!expanded);

	const onTabSelected = (tabIndex: number) => {
		if (!expanded) {
			onToggleExpansion();
		}
		sessionStorage.set(sessionKey, tabIndex);
		setSelectedIndex(tabIndex);
	}
	useEffect(() => {
		setExpanded(isLargeScreen);
	}, [isLargeScreen]);


	const selected = tabs[selectedIndex];
	const TabView = selected?.content;

	return <PaneContent expanded={expanded}>
		<FlexBox direction={expanded ? "row" : "column"} align="center" justify={expanded ? "flex-start" : "center"}>
			{((!expanded && orientation === "left") || (orientation === "right")) &&
				<Button buttonType="chromeless" icon={<ExpandKeyboardTabIcon rotated={((orientation === "right") ? !expanded : expanded)} />} onClick={onToggleExpansion} data-testid="expand-right" />
			}
			<Tabs tabs={tabs} onTabSelected={onTabSelected} selectedTabIndex={selectedIndex} direction={expanded ? "row" : "column"} data-testid="expand-left" />
			{(expanded && orientation === "left") &&
				<Button buttonType="chromeless" icon={<ExpandKeyboardTabIcon rotated={true} />} onClick={onToggleExpansion} />
			}
		</FlexBox>
		<Box overflow="hidden auto" height="calc(100% - 40px)" width="100%">
			{expanded && selected && <TabView />}
		</Box>
	</PaneContent>
}

export { TabbedPane }
