import {useTypedAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";
import {ApiPatchResult} from '../../../../shared/interfaces';

type ExecuteProps = {
	buffer: KaiAlphaDocument,
	documentId: string,
	documentVersion: string
}

export function updateBufferApi({buffer, documentId, documentVersion}: ExecuteProps): Promise<ApiPatchResult> {
	const initBuffer: KaiAlphaDocument & {documentVersion: string} = {
		...buffer,
		id: documentId,
		version: buffer.version,
		documentVersion
	}

	return api.call('POST', 'document/buffer', initBuffer);
}

function useSaveBuffer() {
	return useTypedAsync<ExecuteProps,ApiPatchResult>(updateBufferApi, false);
}

export {useSaveBuffer};