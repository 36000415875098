import {CssFactoryType, ParentCssSelector} from "./model";
import {StyleElementValue} from "../../editor/elements/AddStyle";
import {createStyle} from "./styles";
import {StyleAttribute} from "../../editor/elements/styles";

function factory(value: unknown): string {
	const styleInfo = value as StyleElementValue;

	const styles = createStyle(styleInfo as Partial<Record<StyleAttribute, string>>, ParentCssSelector);
	return styles.map(css => `${ParentCssSelector} .${styleInfo.style_name.replace(/\W+/gm, "_")} ${css}`).join(" ");
}

const namedStyleCssFactory: CssFactoryType = {
	type: "AddStyle",
	valueFactory: factory
}

export {namedStyleCssFactory};