import { Input, styled, InputAdornment } from "@mui/material/";
import { Search } from "@mui/icons-material";
import { useDebounce } from "../../hooks";
import {CSSProperties, useEffect, useState} from "react";

type SearchInputProps = {
	onChange?: (searchText: string) => void,
	delay?: number,
	placeholder?: string,
	iconStyle?: CSSProperties,
	value?: string
}
const SearchInput = (props: SearchInputProps) => {

	const onChangeCallback = props.onChange;
	const delay = props.delay ?? 500;
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, delay);

	useEffect(
		() => {
			onChangeCallback && onChangeCallback(debouncedSearchTerm);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[debouncedSearchTerm] // Only call effect if debounced search term changes
	);


	return <Input {...props}
		value={props.value}
		onChange={(e) => setSearchTerm(e.target.value)}
		startAdornment={
			<InputAdornment style={{order: 1, ...(props.iconStyle ?? {})}} position="start">
				<Search />
			</InputAdornment>
		} />
}

const SearchBar = styled(SearchInput)({
	fontSize: "13px",
	width: "100%",
	"& input": {
		height: "45px",
		fontSize: ".875rem",
		boxSizing: "border-box"
	},
	"& label": {
		fontSize: "13px",
		transform: "translate(14px, 16px) scale(1)"
	}
});

export {SearchBar};