import React from 'react';
import Box from '@mui/material/Box';

import { Button } from '../../lib/ui';
import TemplateElementDialog from '../TemplateElementDialog';

import validation_utils from '../../lib/utils/validation_utils';
import data_lib from '../../api/data';

import SharedItems from './SharedItems';

const uuid = require('uuid');

class PromptsDialog extends React.Component {
	variable_types = validation_utils.structure.variable_types;

	constructor(props) {
		super(props);
		this.state = {
			open_dialog: true,
			prompts: [],
			id: '',
			selected_prompt: {},
			document_elements: {},
			datasource_items: []
		}

		this.add_prompt = this.add_prompt.bind(this);
		this.close_dialog = this.close_dialog.bind(this);

		this.shared_items = new SharedItems();
	}

	componentDidMount() {
		if (this.props.id) {
			this.setState({id: this.props.id});
		}

		if (this.props.current_block) {
			this.setState({current_block: this.props.current_block});
		}

		if (this.props.elements) {
			const elements = this.props.elements;

			let node_prompts = [];

			for (const action in elements) {
				const nodes = elements[action]['nodes'];

				const index = nodes.findIndex(x => x.id === this.props.id);
				if (nodes[index]['parameters']['prompts']) {
					node_prompts = node_prompts.concat(nodes[index]['parameters']['prompts']);
				}
			}

			const prompts = [...this.state.prompts];
			const updated_prompts = prompts.concat(node_prompts);
			this.setState({prompts: updated_prompts});
		}
	}

	close_dialog() {
		this.setState({open_dialog: false});

		this.props.close_prompts_dialog();

		this.save_prompts();
	}

	add_prompt() {
		const prompts = [...this.state.prompts];
		prompts.push({
			id: uuid.v4(),
			prompt: '',
			type: '',
			variable_type: '',
			items: ''
		})

		this.setState({prompts: prompts});
	}

	update_prompt_value(attribute, prompt) {
		if (attribute === 'type' && (prompt.type === 'auto' || prompt.type === 'columns' || prompt.type === 'rows' || prompt.type === 'columns-rows')) {
			prompt.data_type = prompt.type;
			prompt.type = 'datasource';
		}

		const retval = {
			defaultValue: prompt[attribute],
			onChange: this.set_prompt_eventhandler(attribute, prompt)
		};

		return(retval);
	}

	set_prompt_eventhandler(name, prompt) {
		return(event, value) => {
			let new_value = event.target.value;

			if (value) {
				new_value = value;
			}

			const prompts = [...this.state.prompts];
			const index = prompts.findIndex(x => x.id === prompt.id);
			prompts[index][name] = new_value;

			this.setState({prompts: prompts});
		}
	}

	save_prompts() {
		this.props.get_prompts(this.state.id, this.state.prompts, this.state.current_block);
	}

	set_selected_prompt(prompt) {
		this.setState({selected_prompt: prompt});
	}

	delete_prompt(prompt) {
		// using [].filter to create the new reference so that render method will be called when using setState().
		const prompts = this.state.prompts.filter(p => p.id !== prompt.id)

		// assigning prompts array to [] (empty array) initially to reset the updated_content in render method.
		// assign the filtered prompts in setState's callback function.
		// it is to generate the new updated_content in render method and to render the UI properly.
		this.setState({prompts: []},()=>{
			this.setState({prompts: prompts});
		});
	}

	async populate_datasource_items() {
		if (!this.datasource_items_populated) {
			this.datasource_items_populated = true;

			const result = await data_lib.list_data();
			this.setState({
				datasource_items: result
			});
		}
	}

	get_prompt_content(prompts, item) {
		for (const prompt of prompts) {
			let input_values = ['text', 'dropdown'];

			if (prompt.variable_type  && prompt.variable_type === 'document') {
				input_values = this.variable_types;
			}

			item.content.push(
				{
					type: 'textfield',
					label: 'Prompt Name',
					attribute: 'prompt',
					defaultValue: prompt.prompt,
					field_overrides: this.update_prompt_value('prompt', prompt)
				},
				{
					type: 'textfield',
					label: 'Variable Name',
					attribute: 'variable_name',
					defaultValue: prompt.variable_name,
					field_overrides: this.update_prompt_value('variable_name', prompt)
				},
				{
					type: 'textfield',
					label: 'Variable Description',
					attribute: 'description',
					defaultValue: prompt.description,
					field_overrides: this.update_prompt_value('description', prompt)
				},
				{
					type: 'dropdown',
					label: 'Variable Type',
					attribute: 'variable_type',
					values: ['document', 'local'],
					defaultValue: prompt.variable_type,
					field_overrides: this.update_prompt_value('variable_type', prompt)
				},
				{
					type: 'dropdown',
					label: 'Input Type',
					attribute: 'type',
					values: input_values,
					defaultValue: prompt.type,
					field_overrides: this.update_prompt_value('type', prompt)
				},
				...this.variable_options_dialog(prompt),
				{
					type: 'raw',
					size: 12,
					contents: <Box mb={2}><Button onClick={() => this.delete_prompt(prompt)}>-</Button></Box>
				}
			)
		}

		return(item);
	}

	variable_options_dialog(prompt) {
		if (!prompt || Object.keys(prompt).length === 0 || !prompt.type) {
			return([]);
		}

		if (this.state.datasource_items.length === 0) {
			this.populate_datasource_items();
		}

		const item_info = {
			type: prompt.type,
			field_overrides: (attribute) => {
				return(this.update_prompt_value(attribute, prompt));
			},
			items: prompt.items
		};

		return(this.shared_items.variable_options_dialog(item_info, this.state.datasource_items, this.props.all_variables));
	}

	render() {
		const prompts = this.state.prompts;

		const item = {
			content: [
				{
					type: 'raw',
					size: 12,
					contents: <Box mb={2}><Button onClick={this.add_prompt}>+</Button></Box>
				}
			]
		};

		const updated_content = this.get_prompt_content(prompts, item);

		const item_props = {
			id: this.state.id,
			data: {
				type: '',
				name: ''
			}
		};

		return(
			<TemplateElementDialog
				item={updated_content}
				item_props={item_props}
				close_dialog={this.close_dialog}
			/>
		);
	}
}

export default PromptsDialog;
