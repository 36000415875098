import {useList} from "./useList";
import {useRunWhenValueChange} from "../../../../shared/hooks";
import {ContentList} from "../../components/list/content/ContentList";
import {AsyncComponent, MessageBox, MessageBoxActions} from "../../../../shared/components";
import {ContextButtonFactory, DefaultContextButtons} from "../../../../shared/components/buttons/theme";
import Masthead from "../../../../shared/components/navigation/masthead";
import {FilterState} from "../../../../shared/components/input/filter/sort/models";
import {ModuleLayout} from "../../../../shared/components/layout/ModuleLayout";
import React, {useRef, useState} from "react";
import {ContentNode} from "../../models/content";
import {MessageBoxActionType} from "../../../../shared/components/modals/messageBox/models";
import {UserManager} from "../../../../shared/hooks/useUser";
import {ApplicationAdminRoleLookup} from "../../../../shared/interfaces/ApplicationAdminRoles";

const List = () => {
	const listManager = useList();
	const [page, setPage] = useState(0);
	const inputFile = useRef<HTMLInputElement | null>(null);
	// delete confirmation //
	const [confirmDelete, setConfirmDelete] = useState<{open: boolean, pendingContent: ContentNode | null}>({open: false, pendingContent: null});
	/** Import and New Icons should be available only if the user has the role for content administration */
	const userData = UserManager.useContainer();
	/** Import and New Icons should be available only if the user has the role for content administration */
	const buttonList = userData.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_contents) ? [{...DefaultContextButtons.Import, label: "Import Content"},{...DefaultContextButtons.New, label: "New Content"}] : [];

	useRunWhenValueChange((prevPage) => {
		if ((prevPage ?? 0) < page && listManager.content.length <= (page * listManager.pageSize)) {
			listManager.setAfter(listManager.after)
		}
	}, page);


	const onDelete = (contentNode: ContentNode) => {
		setConfirmDelete({open: true, pendingContent: contentNode});
	}

	const onMessageBoxAction = (action: MessageBoxActionType) => {
		if (action === MessageBoxActions.Primary) {
			listManager.deleteContent(confirmDelete.pendingContent!);
		}
		setConfirmDelete({open: false, pendingContent: null});
	}

	const onMessageBoxClose = () => onMessageBoxAction(MessageBoxActions.Secondary)


	const onContextButtonClick = (key: string) => {
		switch(key) {
			case DefaultContextButtons.New.key:
				listManager.createNewDocument();
				break;
			case DefaultContextButtons.Import.key:
				inputFile.current?.click();
				break;
		}
	}

	const onPageChange = (event: unknown, newPage: number) => setPage(newPage);
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const pageSize = Number(event.target.value);
		listManager.setPageSize(pageSize);
		setPage(0);
	}
	const onSearchChange = (field?: string, searchValue?: string) => {
		if (searchValue !== undefined && field) {
			const didUpdateFilters = listManager.setFilter({
				field, value: searchValue ?? ""
			});

			if (didUpdateFilters) {
				setPage(0);
			}
		}
	}

	const onSort = (field: string, sortDirection: FilterState) => {
		listManager.setSort(sortDirection === "none" ? undefined : {field, direction: sortDirection === "ascending" ? "asc": "desc"})
		setPage(0);
	}

	const getCurrentSortDirection = (): FilterState => {
		if (listManager.sort?.direction === "asc") {
			return "ascending";
		} else if (listManager.sort?.direction === "desc") {
			return "descending"
		}

		return "none";
	}

	const handleDocumentImport = (event) => {
		const file = event.target.files[0];
		listManager.onUploadFile(file);
	}


	return <AsyncComponent
		isLoading={listManager.isRunning}
		loadingMessage={"Loading contents"}
		fullScreen
		component={<>
			<Masthead
				contextButtons={ContextButtonFactory(buttonList, onContextButtonClick)}
			/>
			<ModuleLayout center={<ContentList
				page={page}
				pageSize={listManager.pageSize}
				filters={(listManager.filters ?? []).reduce((lookup, filter) => {
					if (filter.field === undefined) {
						return lookup;
					}

					lookup[filter.field] = filter.value;
					return lookup;
				}, {})}
				total={listManager.total}
				onDelete={onDelete}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				onColumnSearch={onSearchChange}
				onSort={onSort}
				content={listManager.content ?? []}
				sort={{field: listManager.sort?.field ?? "", sortDirection: getCurrentSortDirection()}} />} />
			<input
				accept={".docx,.json"}
				style={{ display: 'none' }}
				type="file"
				ref={inputFile}
				key="document"
				onChange={handleDocumentImport}
			/>
			<MessageBox
				destructive
				visible={confirmDelete.open}
				message={<>Are you sure you want to delete <b>{confirmDelete.pendingContent?.name}</b>?</>}
				title={"Delete Content"}
				primaryAction={"Delete"}
				secondaryAction={"Cancel"}
				onActionClick={onMessageBoxAction}
				onClose={onMessageBoxClose}
			/>
		</>} />
}

export {List}