import {useState} from "react";
import { Dialog } from "../../../../../../lib/ui";
import { TextField } from "../../../../../../lib/ui";
import {DialogProps} from "../../../../models/elements";
import {ExpressionBuilder} from "../../../../../../shared/components/input/expressionBuilder";
import {Box, DialogContent} from "@mui/material";
import { SwitchElementValue } from "../../../../../../shared/definitions/elements/switch/model";
import {nunjucksConstants} from "../../../../../../shared/nunjucks/models/NunjucksConstants";
import {useAutoCompleteConfigGenerator} from "../../../../../../shared/hooks/useAutoCompleteConfigGenerator";
import {useRunWhenValueChange} from "../../../../../../shared/hooks";
import {
	variableAutoCompleteConfigGenerator
} from "../../../../../../shared/variables/variableAutoCompleteConfigGenerator";
import {AsyncComponent} from "../../../../../../shared/components";

function generateAutoCompleteConfig() {
	return Promise.resolve([
		{
			trigger: ' ',
			options: nunjucksConstants.operators,
			excludePredecessors: [...nunjucksConstants.operators || [], undefined]
		},
		{
			trigger: '|',
			options: nunjucksConstants.filters
		}
	])
}

/*
 * AddLoopOrSwitchDialog component
 */
const PropertyEditor = (props: DialogProps) => {

	const {
		variables,
		value
	} = props;

	const [showDialog, setShowDialog] = useState(true);
	const [error, setError] = useState(false);
	const [dialogContent, setDialogContent] = useState(value.data as SwitchElementValue);
	const autoCompleteConfigGenerator = useAutoCompleteConfigGenerator();

	useRunWhenValueChange(() => {
		autoCompleteConfigGenerator.execute([
			generateAutoCompleteConfig,
			() => variableAutoCompleteConfigGenerator({userVariables: variables.map(v => v.contents.name)})
		])
	}, variables)

	const applyHandler = () => {
		if(dialogContent.name === undefined) {
			setError(true);
			return;
		}

		if (dialogContent.name.length === 0){
			setError(true);
			return;
		}

		setError(false);
		if (props.onApply) {
			props.onApply({...value, data: dialogContent});
		}
	};

	const handleClose = () => {
		setShowDialog(false);
		if (props.onClose) {
			props.onClose();
		}
	};

	const onChangeExpression = (event: {target: {value: string}}) => {
		const copyDialog = {...dialogContent};
		let {value} = event.target;
		if (value.startsWith("cite ")) {
			value = value.replace(/cite\s/gm, "cite");
		} else if(value.indexOf(". ") > 0) {
			value = value.replace(/\.\s/gm, ".");
		}

		copyDialog.expression = value;
		setDialogContent(copyDialog);
	}

	const handleChange = (value: any, item: string) => {
		const copyDialog = {...dialogContent};
		setError(false);
		if(item === "name") {
			copyDialog.name = value;
		}

		setDialogContent(copyDialog);
	};


	return (
		<Dialog
			open={showDialog}
			title={`Loop Properties`}
			onClose={handleClose}
			style={{ minWidth: "60%" }}
			buttons={{
				Close: handleClose,
				Apply: applyHandler
			}}
		>
			<DialogContent>
				<Box sx={{mb: "1rem"}}>
					<TextField
						error={error}
						id="outlined-basic"
						label={`Loop name`}
						defaultValue={dialogContent?.name ?? ''}
						onChange={(event) => handleChange(event.target.value, "name")}
						{...(error && { helperText: `Loop name should be unique and non-empty`, })}
					/>
				</Box>
				<Box>
					<AsyncComponent
						isLoading={autoCompleteConfigGenerator.isLoading}
						component={<ExpressionBuilder
							className='editor-item-autocomplete-input'
							value={dialogContent?.expression ?? ' '}
							autoCompleteConfig={autoCompleteConfigGenerator.value ?? []}
							onChange={onChangeExpression}
							onBlur={(value)=>onChangeExpression({target: {value}})}
						/>} />
				</Box>
			</DialogContent>
		</Dialog>

	);

};

export { PropertyEditor };
