import { Task, TaskBody, TaskButtons, Tasks, TaskSource, TaskTimeout, UserTask } from "../interfaces/task";
import { User } from "../interfaces/User";
import { addDate } from "../utilities/legacyDate";
import { FilterTaskWorkflowResponse } from "../../modules/task/models/workflow";
import { getUserPendingTaskStatus } from "./getUserPendingTaskStatus";
import { filterTaskWorkflowUI } from "../workflow/filterTaskWorkflowUI";
import { SYSTEM_USER_ID } from "../../modules/task/constants/constants";
import { filterTaskWorkflowSystemUser } from "../workflow/filterTaskWorkflowSystemUser";

// workflowstate used in function has properties in both KaiAlphaDocumentWorkflow and KaiAlphaTemplateWorklow so using any
type taskResponse = FilterTaskWorkflowResponse | any;

interface VerifyResult {
	result: taskResponse,
	collectedUsersMap: {}
}

const appendTask = (
	tasks: Task[],
	workflowUI: TaskButtons,
	variables: {},
	operation: string,
	timeout: TaskTimeout,
	collectedUsersMap: {},
	slotName: string,
	taskSource: TaskSource,
	userId: string
) => {
	const collectedUsers = Object.keys(collectedUsersMap);

	const task: TaskBody = {
		slot: slotName,
		operation,
		ui: workflowUI,
		timeout,
		who: collectedUsers,
		status:  'Open',
		source: taskSource
	};

	tasks.push({
		...task,
		status: getUserPendingTaskStatus(userId, variables),
	});
}

const cleanupButtons = (workflowUI: TaskButtons): {} => {
	const collectedMap = {};
	const newButtons: any[] = [];
	for (const button of workflowUI.buttons) {
		// skip buttons that are not shown on tasks page
		if (button.task === false) {
			continue;
		}

		/**
		 * Some edge case yet to be figured out, is causing acl to be undefined for some buttons.
		 * Probably if document review is cancelled or document acl is updated at some point after starting review.
		 * So, use default values when destructuring to avoid error if acl is undefined.
		 */
		const { permissions: { acl: { write: button_write_access = [] } = {} } = {} } = button;
		const buttonUsers = button_write_access;
		for (const buttonUser of buttonUsers) {
			collectedMap[buttonUser] = true;
		}
		newButtons.push(button);
	}
	workflowUI.buttons = newButtons;
	return collectedMap;
}

const verifyFilter = (result: taskResponse): VerifyResult | null => {
	// if status !== waiting then wrokflow is closed
	// if status === undefined it appears that it is closed, based on previous comments
	if (result.state.status !== 'waiting' || result.ui === undefined) {
		// if here, workflow is closed
		return null;
	}

	// get the users who can act on this task
	let collectedUsersMap = {};
	if (result.ui.buttons) {
		collectedUsersMap = cleanupButtons(result.ui);
	}

	if (!result.ui.prompts && (result.ui.buttons && result.ui.buttons.length === 0)) {
		return null;
	}

	// TODO revist the way the addDate functions work
	// we should be using a library rather than manually creating date values
	if (result.state.timeout) {
		result.state.timeout.at = addDate(result.state.timeout.in, result.state.timeout.start);
	}

	return {
		result,
		collectedUsersMap
	}
}

const getUserTaskList = (possibleTasks: UserTask[], user: User): Tasks => {
	const tasks: Task[] = [];
	for (const item of possibleTasks) {
		const taskSource: TaskSource = {
			type: item.type as 'document' | 'template',
			id: item.id,
			version: item.version,
			name: item.name,
			state: item.state
		};

		for(const slotName of Object.keys(item.workflow)){
			const filterResult = filterTaskWorkflowUI(
				user,
				item.workflow[slotName],
				item.permissions,
				slotName
			);

			const verifyResult: VerifyResult | null = verifyFilter(filterResult);
			if(verifyResult !== null) {
				appendTask(
					tasks,
					verifyResult.result.ui,
					verifyResult.result.state.variables,
					verifyResult.result.state.operation,
					verifyResult.result.state.timeout as TaskTimeout,
					verifyResult.collectedUsersMap,
					verifyResult.result.slotName,
					taskSource,
					user.id
				);
			}
		}
	}

	return({
		tasks
	});
}

const getSystemTaskList = (possibleTasks: UserTask[]): Tasks => {
	const tasks: Task[] = [];
	for (const item of possibleTasks) {
		const taskSource: TaskSource = {
			type: item.type as 'document' | 'template',
			id: item.id,
			version: item.version,
			name: item.name,
			state: item.state
		};

		for(const slotName of Object.keys(item.workflow)){
			const filterResult: taskResponse = filterTaskWorkflowSystemUser(
				slotName,
				item.workflow[slotName],
				item.permissions,
			)

			// include cancelled and completed tasks
			if (filterResult.state.completed_tasks){
				filterResult.state.completed_tasks.forEach(completedTask => {
					const result = completedTask.result;
					const status = result === 'Cancelled' ? 'Cancelled' : 'Closed';
					tasks.push({
						source: taskSource,
						operation: completedTask.action,
						result: result,
						who: [completedTask.who],
						status: status,
						comment: completedTask.comment,
					});
				});
			}

			const verifyResult: VerifyResult | null = verifyFilter(filterResult);
			if(verifyResult !== null) {
				const collectedUsers = Object.keys(verifyResult.collectedUsersMap);

				collectedUsers.forEach( user => {
					const task: TaskBody = {
						slot: verifyResult.result.slotName,
						operation: verifyResult.result.state.operation,
						ui: verifyResult.result.ui,
						timeout: verifyResult.result.state.timeout as TaskTimeout,
						who: [user],
						status:  getUserPendingTaskStatus(SYSTEM_USER_ID, verifyResult.result.state.variables),
						source: taskSource
					};

					tasks.push(task);
				});
			}
		}
	}

	return({
		tasks
	});
}

export {
	getUserTaskList,
	getSystemTaskList
}