import {ModuleRoutes} from "../../shared/routing";
import {Route} from "react-router-dom";
import {List} from "./pages/List";
import {ModuleDefinition} from "../index";

const TaskRouter = () => <ModuleRoutes>
	<Route path={"/"} element={<List />} />
</ModuleRoutes>

const TaskModule: ModuleDefinition = {
	path: "/activity/tasks/*",
	basePath: "/activity/tasks",
	component: TaskRouter,
	name: "Tasks"
}

export type TaskName = "tasks";
export {TaskModule};