import {ColumnDefinition} from "../../../../../shared/components/layout/table";
import {cellViewForColumn} from "./columns";
import {BaseTableRow} from "../../../../../shared/components/layout/table/styles";
import {SubmissionListAction, SubmissionNode} from "../../../models";
import {useTheme} from "@mui/material";

export interface ContentListRowProps {
	item: unknown,
	rowIndex: number,
	columns: ColumnDefinition[],
	onDeleteContent: (submissionNode: SubmissionNode) => void,
	onRowSelected: (row: SubmissionNode) => void,
	onDownloadZip: (submissionNode: SubmissionNode) => void,
	onViewError: (submissionNode: SubmissionNode) => void
}

const SubmissionListRow = (props: ContentListRowProps) => {
	const content = props.item as SubmissionNode;
	const theme = useTheme();

	const onRenderTableCell = (column: ColumnDefinition) => {
		const Cell = cellViewForColumn(column.field);
		return Cell ? <Cell
			key={`${column.field}-${content.id}`}
			item={content}
			onAction={onColumnAction}
			column={column}/> : content[column.field];
	}

	const onColumnAction = (action: unknown, column: ColumnDefinition) => {
		const columnAction = action as SubmissionListAction;
		switch(columnAction) {
			case "view":
				props.onRowSelected(content);
				break;
			case "delete":
				props.onDeleteContent(content);
				break;
			case "download":
				props.onDownloadZip(content);
				break;
			case "viewError":
				props.onViewError(content);
				break;
		}
	}

	const backgroundColor = props.rowIndex % 2 !== 0 ? theme.palette?.dashboard?.static : "#fff";

	return <BaseTableRow style={{cursor: "pointer", backgroundColor}}>
		{props.columns.map(column => onRenderTableCell(column))}
	</BaseTableRow>
}

export {SubmissionListRow};