import {
	ModuleColumn,
	ModuleColumnCellViewProps,
	ModuleListActions
} from "../models";
import { FlexBox } from "../../../../../../shared/components";
import {SquareToggle} from "../../../../../../shared/components/input/toggle/SquareToggle";
import {ColumnLabel} from "./ColumnLabel";

const HeaderCell = () => {

	return <FlexBox width={"100%"} justify={"center"}>
		<ColumnLabel value={"Active"} />
	</FlexBox>
}

const Cell = (props: ModuleColumnCellViewProps) => {
	const onToggleActiveState = () => {
		if (!props.onAction) {
			return;
		}

		return props.row.active ? props.onAction(ModuleListActions.deactivate, props.column) : props.onAction(ModuleListActions.activate, props.column)
	}
	return <FlexBox width={"100%"} justify={"center"}>
		<SquareToggle value={props.row.active ?? false} onValueChange={onToggleActiveState} />
	</FlexBox>
}
const ActiveColumn: ModuleColumn = {
	field: "active",
	views: {
		header: HeaderCell,
		cell: Cell
	}
}

export {ActiveColumn}