import { useState } from 'react';

const versionInfo = require('../../../lib/version.js');

const AppVersion = () => {
	const [versionVisible, setVersionVisible] = useState(0)

	const {string: versionString, commit: versionCommit } = versionInfo;

	const toggleVersion = () => {
		let newVersion = versionVisible + 1;

		if(newVersion > 2) newVersion = 0;

		setVersionVisible(newVersion)
	}

	const style: React.CSSProperties = {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		padding: '.5em 0',
		width: '100vw',
		textAlign: 'center',
		fontSize: '.6rem',
		zIndex: 2
	}

	return(
		<div onClick={toggleVersion} style={style}>
			{versionVisible === 0 && <>&nbsp;</>}
			{versionVisible > 0 && `KaiAlpha v${versionString}`}
			{versionVisible === 2 && ` (${versionCommit})`}
		</div>
	);
}

export {AppVersion}