import { SearchBarContainer, SearchIcon } from "./SearchBar.style";
import {CSSProperties, useState} from "react";
import { TextField, InputAdornment } from "@mui/material";
import { useDebounce, useRunWhenValueChange } from "../../../hooks";

export interface SearchBarProps {
	placeholder: string,
	onChange: (searchValue?: string) => void,
	containerStyle?: CSSProperties,
	dense?: boolean,
	searchValue?: string,
	debounceTime?: number,
}

const SearchBar = ({placeholder, onChange, containerStyle, dense, debounceTime, searchValue} : SearchBarProps) => {

	// state is only used to hold the search value prior to it being debounced.
	const [value, setValue] = useState<string | undefined>(searchValue);
	const debouncedSearchValue = useDebounce(value, debounceTime ?? 200);

	useRunWhenValueChange(() => {
		if (debounceTime) {
			onChange(debouncedSearchValue);
		}
	}, debouncedSearchValue)

	const searchValueChanged = (e) => {
		if (debounceTime) {
			setValue(e.target.value);
		} else {
			onChange(e.target.value);
		}
	}

	return (<SearchBarContainer dense={dense ?? false} style={containerStyle}>
		<TextField
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
			}}
			hiddenLabel
			size='small'
			margin="none"
			fullWidth
			// since searchValue receives the debounced value,
			// display value to keep search bar in sync.
			value={value}
			variant="standard"
			placeholder={placeholder}
			onChange={searchValueChanged}
			type="search"/>
	</SearchBarContainer>)
}

export {SearchBar}