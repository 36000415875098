import {styled} from "@mui/material";

const BackButton = styled("div")(({theme}) => ({
	margin: "-10px 0 10px 0",
	cursor: "pointer",
	color: theme.palette.documentState?.inReview.main
}));


export interface VersionNotificationActionProps {
	onClick: () => void
}

const VersionNotificationAction = ({onClick}: VersionNotificationActionProps) => <BackButton onClick={onClick}>Back to current version</BackButton>

export {VersionNotificationAction};