import {ColumnDefinition, Table} from "../../../../../shared/components/layout/table";
import {UserGroupsListRow} from "./UserGroupsListRow";
import {UserGroupsNode} from "../../../models";
import {accessoryForColumn, columns} from "./columns";
import {FilterState} from "../../../../../shared/components/input/filter/sort/models";
import React from "react";

export interface UserGroupListProps {
	userGroups: UserGroupsNode[],
	filters?: {[field: string]: string},
	sort: {field: string, sortDirection: FilterState},
	onPageChange: (event: unknown, newPage: number) => void,
	onPageSizeChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
	onColumnSearch: (field?: string, value?: string) => void,
	onDelete: (contentNode: UserGroupsNode) => void,
	onSort: (field: string, sortDirection: FilterState) => void,
	onRowClick: (userGroup: UserGroupsNode) => void,
	pageSize: number,
	page: number,
	total: number
}

const UserGroupsList = (props: UserGroupListProps) => {
	const {userGroups, sort, page, pageSize, total, filters, onPageChange, onPageSizeChange, onColumnSearch, onDelete, onSort} = props
	const onRenderRow = (item: unknown, rowIndex: number) => {
		return <UserGroupsListRow
			item={item}
			key={rowIndex}
			rowIndex={rowIndex}
			onDeleteContent={onDelete}
			onRowSelected={handleRowSelected}
			columns={columns} />
	}


	const handleColumnAccessoryRender = (column: ColumnDefinition) => {
		const Accessory = accessoryForColumn(column.field);
		return Accessory ? <Accessory column={column} /> : null
	}

	const handleRowSelected = (content: UserGroupsNode) => props.onRowClick(content);

	return <Table
		items={userGroups}
		columns={columns}
		title={"User Groups"}
		sort={sort}
		total={total}
		currentPage={page}
		pageSize={pageSize}
		filters={filters}
		onColumnSearch={onColumnSearch}
		onSort={onSort}
		onRenderRow={onRenderRow}
		onRenderColumnAccessory={handleColumnAccessoryRender}
		onChangePage={onPageChange}
		onChangePageSize={onPageSizeChange}
		disablePaging={true}
	/>
}

export {UserGroupsList}