import {TemplateState} from "../../useTemplateState";
import {useGetTemplate} from "../../api/useGetTemplate";
import {usePatchTemplate} from "../../api/usePatchTemplate";
import {getTemplateDiff, mergeTemplateObjects} from "../../../../../api/template";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {useBuildTemplate} from "./useBuildTemplate";
import {useFormatExpressionElements} from "./useFormatExpressionElements";
import {useState} from "react";
import {PublishTemplateProps} from "./models";
import {getTemplateApi} from "../../../../../shared/hooks/api/getTemplateApi";

interface PublishHandlerState {
	templateToBuild: KaiAlphaTemplate | null,
	templateToPublish?: KaiAlphaTemplate | null,
	id: string,
	version: string,
	mergeOnError: boolean,
	forceSave: boolean,
	error: Error | string | null
}

function usePublishActionHandler() {
	const [state, setState] = useState<PublishHandlerState>({
		templateToBuild: null,
		id: "",
		version: "HEAD",
		mergeOnError: true,
		forceSave: false,
		error: null,
	});

	const templateState = TemplateState.useContainer();
	const getUpdatedTemplateById = useGetTemplate();
	const updateTemplate = usePatchTemplate();
	const buildTemplate = useBuildTemplate();
	const formatExpressionElements = useFormatExpressionElements();

	useRunWhenValueChange(() => {
		if (state.templateToPublish) {
			updateTemplate.execute(state.templateToPublish, state.id, state.version, templateState.template?.lastupdated);
		}
	}, state.templateToPublish);

	useRunWhenValueChange(() => {
		if (state.error) {
			setState(s => ({...s, templateToBuild: null, templateToPublish: null}));
		}
	}, state.error)

	useRunWhenValueChange(() => {
		if (state.templateToBuild) {
			saveTemplate();
		}
	}, [state.templateToBuild, state.forceSave])

	useRunWhenValueChange(() => {
		if (state.mergeOnError) {
			mergeTemplateVersions(state.templateToPublish!);
		}
	}, updateTemplate.error)

	useRunWhenValueChange(() => {
		if (updateTemplate.status === "success") {
			const{id, version} = updateTemplate.value!;
			// template retrieved after publish, since some fields
			// are not returned upon successful publish.
			getUpdatedTemplateById.execute({id, version});
		}
	}, updateTemplate.status)

	const mergeTemplateVersions = async (localTemplate: KaiAlphaTemplate): Promise<void> => {
		const templateId = state.id;
		const currentVersion = state.version;

		if (!templateId) {
			return;
		}
		const templateBase = await getTemplateApi({id: templateId, version: currentVersion});
		const latestTemplate = await getTemplateApi({id: templateId});

		if (latestTemplate?.version === localTemplate.version) {
			setState(s => ({...s, mergeOnError: false, forceSave: false, templateToBuild: null, templateToPublish: null}));
			return;
		}

		/*
		 * If the templates don't actually differ, just update our version (no save required)
		 */
		const diff = getTemplateDiff(localTemplate, latestTemplate);
		if (Object.keys(diff.changed).length === 0 && Object.keys(diff.added).length === 0 && Object.keys(diff.deleted).length === 0) {
			return;
		}

		/*
		 * Construct the new template by merging
		 */
		try {
			const newTemplate = mergeTemplateObjects(localTemplate, latestTemplate, templateBase);
			setState(s => ({...s, templateToBuild: newTemplate, forceSave: true, mergeOnError: false}));
		} catch(mergeError: any) {
			setState(s => ({...s, error: mergeError , templateToBuild: null, templateToPublish: null}))
		}
	}

	const saveTemplate = () => {
		const template = buildTemplate(state.templateToBuild, templateState.variables, templateState.elements);

		if (!template) {
			throw new Error("template is null")
		}

		template.body = formatExpressionElements(template.body ?? []);
		const saveOverVersion = state.forceSave ? "HEAD" : templateState.template!.version;

		setState(s => ({...s, templateToPublish: template, id: templateState.template!.id, version: saveOverVersion}));
	}

	const onPublish = (props?: Partial<PublishTemplateProps>) => {
		setState(s => ({...s, templateToBuild: props?.template ?? templateState.buffer, forceSave: props?.forceSave ?? false, mergeOnError: props?.mergeOnError ?? true}));
	}

	return {
		isLoading: updateTemplate.isLoading || getUpdatedTemplateById.isLoading,
		value: getUpdatedTemplateById.value,
		error: updateTemplate.error ?? getUpdatedTemplateById.error,
		execute: onPublish
	}
}

export {usePublishActionHandler};