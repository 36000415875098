import {BaseModal} from "../../../../../shared/components/modals/BaseModal";
import {AsyncComponent, Button, FlexBox, GroupedContent} from "../../../../../shared/components";
import {useGetCompounds} from "../../../hooks/api/useGetCompounds";
import {useDebounce, useRunWhenValueChange} from "../../../../../shared/hooks";
import {TextField} from "../../../../../shared/components/input";
import {Label} from "../../text/labels/label";
import "./styles.scss";
import {MultiSelectDropDown} from "../../../../../shared/components/input/dropdown/multiSelect/MultiSelectDropDown";
import {Compound} from "../../../../../shared/interfaces/Compound";
import {ChangeEvent, useState} from "react";
import {CheckCircleRounded} from "@mui/icons-material";
import {colors} from "../../../../../theme";
import {EmptyGroup} from "../../../../../shared/components/layout/groups/EmptyGroup";
import {useGetIndications} from "../../../hooks/api/useGetIndications";
import {Indication} from "../../../../../shared/interfaces/Indication";

export type AddCompoundModalProps = {
	visible: boolean,
	onClose: () => void,
	onOK: (compound: Compound, indications: Indication[]) => void
}

const AddCompoundModal = (props: AddCompoundModalProps) => {
	const [selectedCompound, setSelectedCompound] = useState<Compound | undefined>(undefined);
	const [selectedIndications, setSelectedIndications] = useState<Indication[]>([]);
	const [searchText, setSearchText] = useState("");

	const debouncedSearchText = useDebounce(searchText, 300);

	const getIndications = useGetIndications();
	const getCompounds = useGetCompounds();
	useRunWhenValueChange(() => {
		if (props.visible) {
			getCompounds.execute();
			getIndications.execute();
		}
	}, props.visible);


	const onChangeSearchText = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSearchText(e.target.value);
	const onAddCompound = () => props.onOK(selectedCompound!, selectedIndications);
	const isCompoundSelected = (item: Compound) => selectedCompound && selectedCompound.compound === item.compound;
	const onSelectCompoundFactory = (compound: Compound) => () => setSelectedCompound(compound);
	const onToggleIndication = (indication: Indication) =>
		setSelectedIndications(selectedIndications.includes(indication) ?
			selectedIndications.filter(item => item !== indication) :
			[...selectedIndications, indication]);

	const compounds = (getCompounds.value ?? []);
	const filteredCompounds = debouncedSearchText.length > 2 ?
		compounds.filter(item => item.compound.toLowerCase().includes(debouncedSearchText)) : compounds;

	return <BaseModal
		visible={props.visible}
		onClose={props.onClose}
		title={"Select Compound"}
		buttonRow={<FlexBox justify="space-between" align="center" direction="row-reverse" margin="1rem 0 0 0">
			<Button styling="cancel" text="Cancel" onClick={props.onClose}/>
			<Button buttonType={"default"} text={"Assign Compound"} onClick={onAddCompound} disabled={true}/>
		</FlexBox>}
		content={<AsyncComponent isLoading={getCompounds.isLoading || getIndications.isLoading} component={
			<div className={"ectd__modal--addcompound"}>
				<FlexBox>
					<FlexBox direction={"column"} sx={{overflow: "hidden auto", paddingTop: "10px"}}>
						<TextField label={"Search"} value={searchText} onChange={onChangeSearchText} />
						{filteredCompounds.length > 0 && <ul className={"ectd__modal--addcompound-list"}>
							{filteredCompounds.map(item => <li className={["ectd__modal--addcompound-list-item", isCompoundSelected(item) ? "active" : ""].join(" ")} onClick={onSelectCompoundFactory(item)}>{isCompoundSelected(item) && <CheckCircleRounded fontSize={"small"} sx={{color: colors.mountainMeadow}} />}<span>{item.compound}</span></li>)}
						</ul>}
						{filteredCompounds.length === 0 && <EmptyGroup title={"No compounds match your query"} /> }
					</FlexBox>
					<FlexBox direction={"column"} sx={{marginLeft: "4rem", paddingTop: ".5rem"}}>
						<Label style={{marginLeft: ".875rem", marginBottom: "1rem"}}>Compound Properties</Label>
						{selectedCompound && <>
							<GroupedContent title={"Details"} alwaysExpanded={true}>
								<TextField label={"Compound Name"} value={selectedCompound?.compound} sx={{marginTop: "1rem"}} />
								<ul className={"ectd__modal--addcompound-details"}>
									<li className={"ectd__modal--addcompound-details-item"}><Label>Product Code</Label><span>{selectedCompound?.productCode}</span></li>
									<li className={"ectd__modal--addcompound-details-item"}><Label>Year</Label><span>{selectedCompound?.year}</span></li>
								</ul>
							</GroupedContent>
							<GroupedContent title={"Indications"} alwaysExpanded={true}>
								<MultiSelectDropDown
									items={getIndications.value?.map(indication => ({title: indication, value: indication})) ?? []}
									value={selectedIndications}
									valueTextLimit={2}
									onToggleSelection={onToggleIndication}
									label={"Select Indication(s)"}
									placeholder={"Optionally select one or more indications"}
									sx={{marginTop: "1rem"}} />
							</GroupedContent>
						</>}
						{!selectedCompound && <EmptyGroup title={"Please select a compound"} />}
					</FlexBox>
				</FlexBox>
			</div>
		} />} />
}

export {AddCompoundModal}