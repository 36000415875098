import { ModuleRoutes } from "../../shared/routing";
import { Route } from "react-router-dom";
import { List } from "./pages/list/List";
import {ModuleComponentProps, ModuleDefinition} from "../index";
import { ContextWrappedEditor } from './pages/editor';
import { DocumentCreator } from "./pages/creator";
import {Tasks} from "./pages/Tasks";
import {ApplicationAdminRoleLookup} from "../../shared/interfaces/ApplicationAdminRoles";

const DocumentRouter = (props: ModuleComponentProps) => {
	return <ModuleRoutes>
		<Route path={"/"} element={<List />} />
		{props.user.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_contents) && <Route path={"/create"} element={<DocumentCreator />} />}
		<Route path={"/edit"} element={<ContextWrappedEditor />} />
		<Route path={"/edit/:id"} element={<ContextWrappedEditor />} />
		<Route path={"/edit/:id/:version"} element={<ContextWrappedEditor />} />
		<Route path={"/tasks/:id"} element={<Tasks />} />
	</ModuleRoutes>
}


const DocumentModule: ModuleDefinition = {
	path: "/activity/content/*",
	basePath: "/activity/content",
	component: DocumentRouter,
	name: "Contents",
}

export type DocumentName = "content";
export { DocumentModule };
