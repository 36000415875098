import {Box, Paper, TableContainer, Table, TableRow, TableCell, TableBody, styled, TableHead} from "@mui/material";
import {TableElementFootnote} from "../../../../../../../shared/definitions/elements/table/model";
import {TableDataType} from "../../../../../../../shared/interfaces/DataSource";

export interface TableProps {
	data: TableDataType,
	footNotes?: TableElementFootnote[]
}

const EmptyTableBody = styled(Box)({
	display: "flex",
	width: "100%",
	alignItems: "center",
	justifyContent: "center",
	height: "200px"
});

const FootnoteList = styled("ol")({
	margin: "0",
	padding: "1rem 1rem 0",
	lineHeight: "1.2rem",
	fontSize: ".8rem",
	"& li": {
		paddingLeft: ".25rem"
	}
})

export {EmptyTableBody}

const SimpleTable = (props: TableProps) => {

	const getFootnotes = (column?: number, row?: number) => (props.footNotes ?? [])
		.filter(note => note.cellRef?.column === column && note.cellRef?.row === row)
		.map(note => props.footNotes!.findIndex(n => n.id === note.id) + 1)
		.filter(index => index > 0);

	return (
		<Box sx={{mt: 3, mb: 3}}>
			<Paper elevation={0}>
				<TableContainer>
					<Table aria-label="simple table" size={"medium"}>
						<TableHead>
							<TableRow>
								{props.data.columns.map((column, columnIndex) => <TableCell sx={{fontWeight: 700}} key={column.field}>
									{column.displayText}<sup>{getFootnotes(columnIndex).join(",")}</sup>
								</TableCell>)}
							</TableRow>
						</TableHead>
						{props.data.rows.length > 0 && <TableBody>
							{props.data.rows.map((row, rowIndex) => (
								<TableRow key={row.id}>
									{props.data.columns.map((column, columnIndex) => <TableCell
										key={`${row.id}-${column.field}`}
									>{row[column.field] ?? "Not defined"}<sup>{getFootnotes(columnIndex, rowIndex).join(",")}</sup></TableCell>)}
								</TableRow>
							))}
						</TableBody>}
					</Table>
				</TableContainer>
				{props.data.rows.length === 0 && <EmptyTableBody>No results</EmptyTableBody>}
				{props.footNotes && <FootnoteList>
					{props.footNotes.map(note => <li key={note.id}>{note.name}</li>)}
				</FootnoteList>}
			</Paper>
		</Box>
	);
}

export {SimpleTable}