import { Task, Tasks } from "../../../shared/interfaces/task"

export interface TaskGroupLayoutProps {
	tasks: Tasks | null,
}
export interface FilteredTasksState {
	all: Task[],
	due: Task[],
	overdue: Task[],
}

const tabs = [
	{
		type: 'all',
		displayName: 'All',
	},
	{
		type: 'due',
		displayName: 'Due',
	},
	{
		type: 'overdue',
		displayName: 'Overdue',
	}
]

const sourcesMap = {
	"document": "content",
	"template": "template"
}

const initialState = {
	all: [],
	due: [],
	overdue: [],
}

export {
	tabs,
	sourcesMap,
	initialState,
}