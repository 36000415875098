import { useSaveBufferAction } from "./actions";
import { useEntityAutoSave } from "../../../shared/hooks/useEntityAutoSave";

/**
 * Automatically saves Content every 60 seconds
 * @param shouldRun: function to determine if auto save should run
 */
function useDocumentAutoSave(shouldRun: () => boolean) {
	return useEntityAutoSave(useSaveBufferAction, shouldRun);
}

export { useDocumentAutoSave };
