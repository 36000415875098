import React, {useState} from "react";
import {Collapse} from "@mui/material/";
import { makeStyles } from '@mui/styles';
import {FlexBox} from "../FlexBox";
import { GroupedContentContainer,
	Title,
	Hr,
	ContextButtonContainer,
} from "./GroupedContent.style";

import { GroupExpandButton } from '../../buttons/theme/GroupExpandButton';

const useStyles = makeStyles(() => ({
	collapseRoot: {
		width: "100%",
	}
}));
export interface GroupedContentProps {
	title: string,
	contextButtons?: JSX.Element[],
	isLoading?: boolean,
	children: React.ReactNode,
	expanded?: boolean,
	alwaysExpanded?: boolean
}

const GroupedContent = ({title, children, contextButtons, expanded, alwaysExpanded}: GroupedContentProps) => {
	const classes = useStyles();
	const [groupExpanded, setGroupExpanded] = useState((alwaysExpanded ?? false) || (expanded ?? false));
	const onToggleExpansion = () => setGroupExpanded(!groupExpanded);
	const expandGroup = () => setGroupExpanded(true);

	return <GroupedContentContainer>
		<FlexBox direction={"column"}>
			{title && <FlexBox direction={"column"}>
				<FlexBox direction={"row"} align={"center"} justify={"space-between"}>
					<Title onClick={onToggleExpansion}>{title}</Title>
					<FlexBox align={"center"} justify="flex-end">
						{contextButtons && contextButtons.map((contextButton => <ContextButtonContainer key={contextButton.key} onClick={expandGroup}>{contextButton}</ContextButtonContainer>))}
						{!alwaysExpanded && <GroupExpandButton rotated={groupExpanded} onClick={onToggleExpansion} />}
					</FlexBox>
				</FlexBox>
				<Hr />
			</FlexBox>}
			<Collapse in={groupExpanded} timeout="auto" className={classes.collapseRoot} unmountOnExit>
				<FlexBox direction={"column"}>
					{children}
				</FlexBox>
			</Collapse>

		</FlexBox>
	</GroupedContentContainer>
}

export {GroupedContent};