import { LinkIcon } from "./MetadataInput.style";
import {ChangeEvent, useState} from "react";
import { Box } from "@mui/material";
import { TextField } from "../../../input";
import { FlexBox } from "../../FlexBox";
import { Button } from "../../../buttons";
import {MetadataItem} from "../../../../interfaces/Metadata";

export type MetaDataChangeEvent = {
	metadata: MetadataItem,
	previousMetadata: MetadataItem | undefined
}
export interface MetadataInputProps {
	onCancel: () => void,
	onSave: (event: MetaDataChangeEvent) => void,
	initial?: MetadataItem,
}

/**
 * This component adds key/value metadata items to the template.
 * @constructor
 */
const MetadataInput = ({initial, onCancel, onSave}: MetadataInputProps) => {
	const [metadata, setMetadata] = useState<MetadataItem>(initial ?? {
		key: '',
		value: '',
	})

	const onKeyChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setMetadata(s => ({...s, key: e.target.value}));
	const onValueChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setMetadata(s => ({...s, value: e.target.value}));

	const onSaveClick = () => onSave({metadata, previousMetadata: initial});

	return <FlexBox direction="column">
		<Box padding="10px 0px">
			<FlexBox align="center" justify="space-between">
				<TextField id="key" label="Key" value={metadata.key} variant="outlined" onChange={onKeyChange} />
				<LinkIcon/>
				<TextField id="value" label="Value" value={metadata.value} variant="outlined" onChange={onValueChange} />
			</FlexBox>
		</Box>
		<Box padding="10px 0px 20px 0px">
			<FlexBox align="center">
				<Button styling="cancel" text="Cancel" onClick={onCancel} />
				<Box margin="0px 5px"/>
				<Button text="Save" disabled={metadata.key === '' || metadata.value === ''} onClick={onSaveClick}/>
			</FlexBox>
		</Box>
	</FlexBox>
}

export {MetadataInput}