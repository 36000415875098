import React from "react";
import {Box} from "@mui/material";
import "./style.scss";
import {FlexBox} from "../../layout";

export type SlideOverProps = {
	children: React.ReactNode,
	open: boolean,
	title?: string,
	size?: SlideOverSize
	onClose: () => any,
	renderActions: () => React.ReactNode
}

export type SlideOverSize = "xsmall" | "small" | "medium" | "large" | "fullscreen";

const SlideOver = ({children, open, title, onClose, renderActions, size}: SlideOverProps) => {
	return (<>
		<Box className={`sc__modal--slideover-overlay${open ? " visible" : ""}`}>
			<Box className={`sc__modal--slideover-container${open ? " visible" : ""} ${size ?? "small"}`}>
				<FlexBox className={"preview__wrapper"} direction={"column"}>
					{title && <FlexBox className={"preview__wrapper-title"}>{title}</FlexBox>}
					<FlexBox direction={"column"} className={"preview__wrapper-content"}>
						{children}
					</FlexBox>
					<FlexBox align={"center"} justify={"flex-end"} className={"preview__wrapper-footer"}>
						{renderActions()}
					</FlexBox>
				</FlexBox>
			</Box>
		</Box>

	</>)
}

export {SlideOver};