import { useCache } from "..";
import { LoadEntityFunc } from "../../interfaces/LoadEntityFuncType";
import { CacheProvider } from "../cache/cacheProvider";
import { MemoryCacheProvider } from "../cache/memoryCacheProvider";

type UseEntityStoreDependencies<T> = {
	cacheProvider?: CacheProvider,
	getEntity: LoadEntityFunc<T>
}
type UseEntityStoreProps<T> = {
	cacheExpiryMinutes?: number,
	cacheKey: string,
	maxCacheSize?: number,
	dependencies: UseEntityStoreDependencies<T>
}

export type EntityStoreHook<T> = {get: LoadEntityFunc<T>}
function useEntityStore<T>(props: UseEntityStoreProps<T>): EntityStoreHook<T> {
	const cacheProvider = props.dependencies.cacheProvider ?? MemoryCacheProvider;
	const expiryTime = props.cacheExpiryMinutes ?? 5;
	const maxCacheSize = props.maxCacheSize;
	const cachePrefix = props.cacheKey;
	const getEntity = props.dependencies?.getEntity;

	const cache = useCache(expiryTime, maxCacheSize, cacheProvider);
	const get: LoadEntityFunc<T> = ({id, version}) =>
		cache.getItem(`${cachePrefix}-${id}-${version ?? "HEAD"}`, () => getEntity({id, version}));

	return { get };
}

export { useEntityStore }