import {GroupedTab} from "./models";
import {styled} from "@mui/material";

type GroupedTabProps = GroupedTab & {
	onClick: () => void,
	isActive: boolean
}

const TabElement = styled("span")({
	cursor: "pointer",
	margin: "0px 0.3rem 0 0",
	padding: "8px 0",
	boxSizing: "border-box",
	alignItems: "center",
	borderRadius: "0.25rem",
	justifyContent: "center",
	flex: "1",
	textAlign: "center",
	"&.active": {
		fontWeight: "700",
		boxShadow: "rgba(50, 50, 93, 0.25) 0px 0px 10px",
	}
})
export const Tab = (props: GroupedTabProps) => {
	return <TabElement onClick={props.onClick} className={props.isActive ? "active" : ""}>
		{props.title}
	</TabElement>
}