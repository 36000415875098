import { getSystemTaskList } from "../../../shared/tasks/getTaskList";
import { getDocumentTasksByDocumentId } from "../../../shared/tasks/getDocumentTasksByDocumentId";

const retrieveSubdocuments = async (document: any, subdocuments: any = undefined): Promise<any[]> => {
	if (!subdocuments){
		subdocuments = [];
	}

	for(const subdocument in document.subdocuments) {
		const response = await getDocumentTasksByDocumentId(subdocument);
		// when testing some ids had 0 results because of bad data
		// this will likely not happen in prod, but leaving here just in case it potentially could occur
		if(response.results.length > 0) {
			// should only be one record long
			const documentResults: any[] = response.results.map(document => {
				const documentObject = document as Object;
				return {
					...documentObject,
					type: 'document',
				};
			});
			subdocuments.push(documentResults[0]);

			await retrieveSubdocuments(documentResults[0], subdocuments);
		}
	}

	return subdocuments;
}

const getDocumentTasks = async (documentId: string) => {
	const documentTasks = await getDocumentTasksByDocumentId(documentId);

	// should only be one record returned
	const documents: any[] = documentTasks.results.map(document => {
		const documentObject = document as Object;
		return {
			...documentObject,
			type: 'document',
		};
	});

	try {
		if(documents[0].subdocuments) {
			const subdocuments = await retrieveSubdocuments(documents[0]);
			documents.push(...subdocuments);
		}
	} catch (error) {
		console.log(error);
	}

	const possibleUserTasks = documents?.map(item => {
		const fullItem = JSON.parse(item['_external_data']['cache']);
		fullItem.permissions = item['_cached_canonical_permissions'] || item['permissions'];
		fullItem['@workflows_waiting'] = item['@workflows_waiting'];
		fullItem.type = item.type;
		return fullItem;
	}).filter(x => !!x);

	return getSystemTaskList(possibleUserTasks);
}

export {getDocumentTasks}