import { VariableEditor, VariableEditorProps } from "./model";
import {PropertyList} from "../../../elements/PropertyList";
import {InTextTableVariableValue} from "../../../../../../shared/definitions/elements/inTextTable/model";


const Editor = (props: VariableEditorProps) => {
	const value = props.variable.value as InTextTableVariableValue;
	const properties = [
		{label: "Table Name", value: value.tableName},
		{label: "Study ID", value: value.studyId}
	]
	return <PropertyList items={properties} />
}

const InTextTableEditor: VariableEditor = {
	type: "intexttable",
	view: Editor
};

export { InTextTableEditor };