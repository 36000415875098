import { useState } from 'react';
import { Icon } from '../../../../../lib/ui';
import {
	Box,
	FormControl,
	FormLabel,
	Chip,
	Tooltip,
	IconButton
} from '@mui/material';
import { ButtonsBox, Container, TagsBox, BoxRadio, MentionsBox } from '../../../../../shared/components/modals/comments/CommentModal.styles';
import { COMMENT_TAGS } from './CommentModal.constants';
import { useRunOnce, useRunWhenValueChange } from '../../../../../shared/hooks';
import { starterComment } from './CommentModal.models';
import { CommentState } from '../../../hooks/useCommentState';
import "./styles.scss";
import { useAddCommentAction } from '../../../hooks/actions/update/useAddCommentAction';
import { useUpdateCommentAction } from '../../../hooks/actions/update/useUpdateCommentAction';
import {AsyncComponent, Button} from '../../../../../shared/components';
import {UserNameMentions} from "../../../../../shared/components/input/mentions/UserNameMentions";
import {BaseModal} from "../../../../../shared/components/modals/BaseModal";
import { CommentModalProps } from '../../../../../shared/definitions/highlight/CommentModal.props';

const CommentModal = (props: CommentModalProps) => {
	const [commentPayload, setCommentPayload] = useState(starterComment);
	// Document Wide is specific to highlighting
	// const [documentWide, setDocumentWide] = useState(false);
	const [userIds, setUserIds] = useState<any>([]);

	const commentsManager = CommentState.useContainer();

	const addCommentAction = useAddCommentAction();

	const updateCommentAction = useUpdateCommentAction();

	const {
		onClose,
		showOpen,
		selectedText,
		addEditComment,
		initialComment,
		textProperties,
		elementId
	} = props;

	useRunOnce(() => {
		const modalUsers = commentsManager.getUsers(userIds);
		setUserIds(modalUsers);
		const updatedPayload = commentPayload;

		// when element ID is passed in set it
		if (elementId) {
			updatedPayload.elementId = elementId;
		} else {
			updatedPayload.elementId = "";
		}

		// when the initial comment is passed it
		// it means this is an edit
		if (initialComment) {
			setCommentPayload(initialComment);
		} else {
			const author = commentsManager.getCurrentUserId();
			setCommentPayload(prevState => ({ ...prevState, author }));
		}

		if (textProperties) {
			// TODO make this not an underscore
			const text_properties = textProperties;

			setCommentPayload(prevState => ({ ...prevState, text_properties }))
		}

		if (selectedText) {
			const text = selectedText;
			setCommentPayload(prevState => ({ ...prevState, text }));
		}
	});

	const onChipClick = (tag: string) => {
		setCommentPayload(prevState => ({ ...prevState, tag }));
	}

	const handleChangeCommentText = (event: React.ChangeEvent<HTMLInputElement>) => {
		const comment = event.target.value;
		setCommentPayload(prevState => ({ ...prevState, comment }));
	}

	const handleCommentModalAction = (value: KaiAlphaCommentEntry) => {

		if (addEditComment === 'Add') {
			addCommentAction.run(
				{
					comment: value,
					elementId: value.elementId
				});
		} else if (addEditComment === 'Edit') {
			updateCommentAction.run({
				editResolve: "EDIT",
				commentPayload: {
					comment: value,
					elementId: value.elementId
				}
			});
		}
	}

	useRunWhenValueChange((previousState) => {
		if (previousState && !addCommentAction.isRunning) {
			onClose()
		}
	}, [addCommentAction.isRunning]);

	useRunWhenValueChange((previousState) => {
		if (previousState && !updateCommentAction.isRunning) {
			onClose()
		}
	}, [updateCommentAction.isRunning])


	return (
		<div className='comments-view'>
			<Box sx={{ maxWidth: 375 }} style={{ border: 'none', boxShadow: 'none' }}>
				<Container style={{ padding: '0' }}>
					{/* TODO refactor this Dialog component as it is JS and needs to be updated */}
					<BaseModal
						visible={showOpen}
						onClose={onClose}
						title={`${addEditComment} Comment`}
						content={<AsyncComponent isLoading={addCommentAction.isRunning || updateCommentAction.isRunning} loadingMessage={"Saving Comment"} component={<Box width={"100%"}>
							{selectedText &&
								<div className="highlightedTextContainer">
									<span>Highlighted Text:</span>
									<span><em>{selectedText}</em></span>
								</div>
							}
							<MentionsBox>
								{/* TODO refactor User Name Mentions Component */}
								<UserNameMentions
									className="mentionsInput"
									value={commentPayload.comment}
									onChange={handleChangeCommentText}
									ids={userIds}
								/>
								{selectedText &&
									<FormControl>
										<BoxRadio>
											<FormLabel>
												<span className="applyToAllInstancesText">Apply to all instances</span>
												<Tooltip title={`Clicking this will apply/unapply this comment to all instances of the selected text throughout the document`} placement={'top'}>
													<IconButton><Icon className="infoIcon" forUI={'info'} /></IconButton>
												</Tooltip>
											</FormLabel>
										</BoxRadio>
									</FormControl>
								}
							</MentionsBox>
							<ButtonsBox>
								<TagsBox>
									<Icon forUI="tags" className="localOfferIcon" color='primary' />
									{Object.keys(COMMENT_TAGS).map(tag =>
										<Chip
											key={tag}
											className={`tagsChip ${commentPayload.tag === tag ? "selectedChip" : ''}`}
											label={tag}
											clickable={false}
											onClick={() => onChipClick(tag)}
										/>
									)}
								</TagsBox>
							</ButtonsBox>
						</Box>}
						/>}
						buttonRow={<Button
							onClick={() =>
								handleCommentModalAction(commentPayload)}
							color='primary'
							text={`${addEditComment} Comment`}
						/>}
					/>
				</Container>
			</Box>
		</div>
	);
}

export default CommentModal;