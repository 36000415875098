import {DataSourceMapType} from "../../interfaces/DataSource";

type Headers = {
	deduplicatedHeaders: {[k: string]: string},
	orderedHeaders: string[]
}
export function getUniqueHeaders(parsedHeaders: string[], type: DataSourceMapType, removeEmptyHeaders: boolean = true) {
	const header_count: {[k: string]: number} = {};
	const headers: Headers = {
		deduplicatedHeaders: {},
		orderedHeaders: []
	};

	if (type === 'columns-rows') {
		parsedHeaders.splice(0, 1);
	}

	for(const header of parsedHeaders) {
		/*
		 * If header value is a duplicate, increment count in
		 * header_count object and append _<count> to the
		 * header value to make it unique
		 */

		if(!header && removeEmptyHeaders) {
			continue;
		}

		if (header_count[header] === undefined) {
			header_count[header] = 0;
			headers.deduplicatedHeaders[header] = header;
			headers.orderedHeaders.push(header);
		} else {
			header_count[header]++;
			const uniqueHeader = header + `_${header_count[header]}`;
			headers.deduplicatedHeaders[uniqueHeader] = header;
			headers.orderedHeaders.push(uniqueHeader);
		}
	}

	return(headers);
}