import { BaseElement } from "../../../interfaces/BaseElement";

const templateDefinition: BaseElement = {
	name: 'Template',
	type: 'template',
	displayName: 'Template',
	allowsChildren: false,
	description: 'Place Sub-Templates',
	longDescription: 'Template elements may be used to include another template at the position where this element is placed',
	contentDescription: (contents) => {
		const value = contents as TemplateElementValue;
		return value.name ?? "Untitled Template";
	},
	toString: (contents) => {
		const value = contents as TemplateElementValue;
		return value.name ?? "Untitled Template";
	},
} as const

export type TemplateElementValue = {
	latest_approved_version?: string;
	name: string,
	expression?: string,
	id?: string,
	type: "template",
	version?: string,
	state?: string
}

export function isTemplateElementValue(data: unknown): data is TemplateElementValue {
	const value = data as TemplateElementValue;
	return value.type === "template";
}

export { templateDefinition };