import Box from '@mui/material/Box';
import {FlexBox} from "../../../../shared/components";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonAdd from '@mui/icons-material/PersonAdd';
import PersonRemove from '@mui/icons-material/PersonRemove';
import {ListTitle} from './styles';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import {ChangeEvent, useState} from "react";
import {useDebounce} from '../../../../shared/hooks';
import {UserGroupUser} from "../../models";

type SelectUsersListProps = {
    leftItems: UserGroupUser[];
    rightItems: UserGroupUser[];
	onChange: (users: UserGroupUser[]) => void;
}

const SelectUsersList = (props: SelectUsersListProps) => {
	const [searchTextValue, setSearchTextValue] = useState("");
	const searchText = useDebounce(searchTextValue, 300);

	const addUser = (user: UserGroupUser) => {
		if (props.rightItems.find(u => u.id === user.id)) {
			props.onChange(props.rightItems.filter(u => u.id !== user.id));
			return;
		}

		props.onChange([...props.rightItems, user]);
	}

	const removeUser = (user: UserGroupUser) => {
		props.onChange(props.rightItems.filter(u => u.id !== user.id));
	}

	const onSearchChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setSearchTextValue(e.target.value);
	}


	const filteredUsers = searchText.length > 0 ? props.leftItems.filter(user => user.name.toLowerCase().includes(searchText.toLowerCase())) :
		props.leftItems;

	return (
		<FlexBox>
			<div style={{width: '100%'}}>
				<ListTitle>
					<FlexBox>
						<div>Users: {props.leftItems.length}</div>
						<div>
							<input type="text" placeholder="Search..." onChange={onSearchChange} />
						</div>
					</FlexBox>
				</ListTitle>
				<Box sx={{ height: 400, width: '100%' }} overflow="scroll">
					<List dense={true}>
						{
							filteredUsers.map(user => {
								return (
									<ListItem key={user.id}>
										<ListItemText
											primary={user.name}
										/>
										<Tooltip title={`Add ${user.name}`} placement="left" arrow>
											<ListItemIcon>
												<PersonAdd onClick={() => addUser(user)} />
											</ListItemIcon>
										</Tooltip>
									</ListItem>
								)
							})
						}
					</List>
				</Box>
			</div>

			<div style={{paddingRight: '20px', height: '100%'}}><Divider orientation="vertical" /></div>

			<div style={{width: '100%'}}>
				<ListTitle><div>Users In Group: {props.rightItems.length}</div></ListTitle>
				<Box sx={{ height: 400, width: '100%' }} overflow="scroll">
					<List dense={true}>
						{
							props.rightItems.map(user => {
								return (
									<ListItem key={user.id}>
										<ListItemText
											primary={user.name}
										/>
										<Tooltip title={`Remove ${user.name}`} placement="left" arrow>
											<ListItemIcon>
												<PersonRemove onClick={() => removeUser(user)} />
											</ListItemIcon>
										</Tooltip>
									</ListItem>
								)
							})
						}
					</List>
				</Box>
			</div>
		</FlexBox>
	);
}

export default SelectUsersList;