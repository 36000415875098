import api from "../../../../api/api";
import {useTypedAsync} from "../../../../shared/hooks";

function deleteSubmission(submissionId: string) {
	return api.call("DELETE", `ectd/submission/${submissionId}`);
}

function useDeleteSubmission() {
	return useTypedAsync(deleteSubmission)
}

export {useDeleteSubmission}