import {ElasticQuery} from "./QueryRequest";
import api from "../../../../../api/api";
import {QueryResponse} from "../QueryResponse";
import {EntityType} from "../../../../interfaces/EntityType";

export type ElasticQueryApiProps = {
	type: EntityType,
	query: {body: ElasticQuery}
}
export function elasticQueryApi<T>({type, query}: ElasticQueryApiProps): Promise<QueryResponse<T>> {
	const queryBody = {body: {size: 25, ...query.body}}
	return api.call("POST", `search/${type}`, queryBody);
}