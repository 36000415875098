import {useState } from "react";
import { useCache } from ".";
import { AsyncExecution, AsyncState } from "./useAsync";

export type Args = any[];
export interface CachedAsyncExecution<T> {
	withCacheKey: (key: string) => AsyncExecution<T>
}

export type CachedAsyncReturn<T, E> = CachedAsyncExecution<T> & AsyncState<T, E>;
export interface CachedAsyncOptions {
	immediate: boolean,
	defaultExpiryTimeMinutes: number
}

/**
 * Wraps an async method with cache persistence
 * @param asyncFunction {function} the async function to execute
 * @param options {CachedAsyncOptions} options for the async function
 * @returns {CachedAsyncReturn} exposes the "withCacheKey" method
 */
function useCachedAsync<T, E = string>(asyncFunction: (...args: Args) => Promise<T>, options: CachedAsyncOptions = {immediate: false, defaultExpiryTimeMinutes: 10}): CachedAsyncReturn<T, E> {
	const [state, setState] = useState<AsyncState<T, E>>({status: "idle", value: null, error: null, isLoading: false});
	// uses default cache provider with no size limit
	const cache = useCache(options.defaultExpiryTimeMinutes);

	const withCacheKey = (key: string) => {
		return {
			execute: async (...args: Args) => {
				try {
					setState(s => ({...s, status: "pending", value: null, error: null}));
					// check the cache and execute async function if value not found in cache (this adds the value to the cache too)
					const cachedValue = await cache.getItem(key, () => asyncFunction(...args)) as T;
					// set the exposed state for the wrapped async method
					setState(s => ({...s, status: "success", value: cachedValue}));
					return cachedValue;
				} catch(e: any) {
					// set error state
					setState(s => ({...s, status: "error", value: null, error: e}));
					return null;
				}
			}
		}
	}

	return {
		withCacheKey, ...state, isLoading: state.status === "pending"
	};
}

export {useCachedAsync};