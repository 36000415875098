import { PreviewElementProps } from "../model";
import { styled } from "@mui/material";
import { HeaderElementValue } from "../../../../../shared/definitions/elements/header/model";
import { CommentState } from "../../../hooks/useCommentState";
import { HighlightWrapper } from "../../../../../shared/components/input/highlight/HighlightWrapper";
import { CommentModalProps } from "../../../../../shared/definitions/highlight/CommentModal.props";
import CommentModal from "../../modals/documentComments/CommentModal";
import {isInReview} from "../../../../../shared/interfaces/WorkflowState";

const Header = styled("h1")({
	fontWeight: 600,
	fontSize: "2rem",
	margin: 0
})

const PreviewView = (props: PreviewElementProps) => {
	const data = props.element.data as HeaderElementValue;

	const commentsManager = CommentState.useContainer();
	const highlightComments = commentsManager.highlightedCommentsByElementId(props.element.id);

	return <HighlightWrapper
		elementId={props.element.id}
		enabled={isInReview(props.state)}
		highlightComments={highlightComments}
		value={data.value}
		componentToWrap={(highlightedText) => <Header dangerouslySetInnerHTML={{ __html: highlightedText }}/>}
		renderCommentModal={ (values: CommentModalProps) =>
			<CommentModal
				onClose={values.onClose}
				addEditComment={"Add"}
				showOpen={values.showOpen}
				elementId={values.elementId}
				selectedText={values.selectedText}
				textProperties={values.textProperties}
				documentId={commentsManager.documentId}
			/>
		}
	/>
}

export { PreviewView };