import './style.scss'
import { PreviewElementProps } from "../model";
import {useGetList} from "../../../../../shared/hooks/api/useGetList";
import {useRunOnce} from "../../../../../shared/hooks";
import {AsyncComponent} from "../../../../../shared/components";
import {renderString} from "../../../../../shared/nunjucks/renderString";
import {Typography} from "@mui/material";

type Citation = {
	key: string,
	value: string,
	index: number,
	lowerCaseKey: string
}
const PreviewView = (props: PreviewElementProps) => {
	const getCitationList = useGetList("citation");
	useRunOnce(() => {
		getCitationList.execute(undefined)
	});


	const citationList = JSON.parse(renderString({template: "{{__cites()}}", variables: {}}));
	const citationKeys = Object.keys(citationList);
	const citations = (getCitationList.value?.entries ?? []).filter(entry => citationKeys.includes(entry.key.replace(/\s/gm, "").toLowerCase()))
		.map(entry => {
			const lowerCaseKey = entry.key.replace(/\s/gm, "").toLowerCase();
			return ({...entry, lowerCaseKey, index: citationList[lowerCaseKey]}) as Citation
		}).sort((a, b) => a.index - b.index);

	return <AsyncComponent
		isLoading={getCitationList.isLoading}
		loadingMessage={"Loading citations..."}
		component={<>
			<Typography variant={"h6"} sx={{mb: ".5rem"}}>List of Citations</Typography>
			<table>
				<tbody>
					{citations.map(entry => <tr id={`cite_${entry.lowerCaseKey}`} key={entry.key}>
						<td><sup>{entry.index}</sup></td>
						<td><em>{entry.value}</em></td>
					</tr>)}
				</tbody>
			</table>
		</>} />
}

export default PreviewView;
