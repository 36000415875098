import { TextField } from "@mui/material";
import { VariableEditor, VariableEditorProps } from "./model";
import React from "react";
import {Description} from "./DescriptionLabel";


const Editor = (props: VariableEditorProps) => {
	const value = props.variable.value as (string | undefined);
	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (props.onChange) {
			props.onChange(e.target.value);
		}
	}
	return <>
		<TextField label={props.label ?? props.variable.name} value={value ?? ""} onChange={onChange} sx={{width: "100%"}} />
		<Description text={props.variable.description} />
	</>
}

const TextEditor: VariableEditor = {
	type: "text",
	view: Editor
};

export { TextEditor };
