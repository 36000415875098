import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";
import {SectionElementPreviewValue} from "./model";
import {filterElements} from "../utilities/filterElements";

function resetIndex(section: ElementValueType): ElementValueType {
	const data = section.data as SectionElementPreviewValue;
	return {...section, data: {...data, index: "0"}}
}

export function numberSections(sections: ElementValueType[], parentIndex?: string, seed: number = 1) {
	return sections.filter(section => {
		const data = section.data as SectionElementPreviewValue;
		return data.index!.split(".").length === 1;
	}).map((section, idx) => {
		const data = section.data as SectionElementPreviewValue;
		const index = seed + idx;
		const children = filterElements(section.children ?? [], "section", [])
			.map(resetIndex);
		const sortedChildren = numberSections(children, `${index}.`);
		return ({
			...section,
			data: {...data, index: parentIndex ? `${parentIndex}${index}` : `${index}`},
			children: sortedChildren
		})
	})
}