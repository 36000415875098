import * as React from 'react';

import {TextField} from "../../input/TextField"
import {Button} from "../../buttons/Button"
import {BaseModal} from "../BaseModal"

import {ButtonWithComment,WorkflowCommentDialogProps} from "./models"


const WorkflowCommentDialog = ( props:WorkflowCommentDialogProps ) => {
	const initialValue = '';
	const { onConfirm, workflowButton, open, onClose } = props
	const [value, setValue] = React.useState(initialValue)

	const handleChange = (event) => {
		setValue(event.target.value)
	}

	const onSubmitComment = () => {
		const button_with_comment :ButtonWithComment = {
			...workflowButton,
			comment: value
		}
		onConfirm(button_with_comment);
		setValue(initialValue)
	}

	return (
		<BaseModal
			onClose={onClose}
			visible={open}
			title={workflowButton.modalTitle}
			buttonRow={<>
				<Button text={"Cancel"} styling={"cancel"} onClick={onClose} />
				<Button buttonType={"default"} text={workflowButton.buttonTitle} onClick={onSubmitComment} />
			</>}
			content={
				<TextField label={workflowButton.placeholderTitle} value={value} onChange={handleChange} />
			}
		/>

	)
}

export {WorkflowCommentDialog}