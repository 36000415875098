type None = "none";
type Ascending = "ascending";
type Descending = "descending";

export type FilterState = None | Ascending | Descending;

type FilterStateType = {
	None: None,
	Ascending: Ascending,
	Descending: Descending,
}

export const FilterStates: FilterStateType = {
	None: "none",
	Ascending: "ascending",
	Descending: "descending",
}