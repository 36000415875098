import {TableElementFootnote} from "../definitions/elements/table/model";

export type DataSourceType = "csv" | "image" | "blob";

export type TableDataType = {
	columns: TableColumnsType,
	rows: TableCellType[]
}

export type DataDefinition = {
	tableData?: {id: number}
} & {[k: string]: string | number | (string | number)[]}

export type DataSourceObjectType = {
	data?: DataDefinition[] | DataDefinition,
	"@metadata": any,
	type: DataSourceMapType
}

export type DataSource = {
	name?: string,
	id?: string,
	data: unknown,
	type: DataSourceType,
	metadata?: any
} | null;

export type CsvDataSource = {
	data: any,
	type: DataSourceMapType,
	'@metadata': {
		headers: {[p: string]: string},
		ordered_headers: string[]
	}
}

export type TableColumnType = {
	field: string,
	displayText: string
}
export type TableColumnsType = TableColumnType[];
export type TableCellType = {
	id: string
} & Record<string, string | number>;

export type TableCellReference = "abs" | "named" | "row" | "col";
export type DataSourceMapType = "columns" | "auto" | "columns-rows" | "rows";
export type TableCellEvent = {row: TableCellType, columns: ({index: number} & TableColumnType)[]};

export type ConsumableDataSource = {
	data: TableDataType;
	"@metadata": any;
	type: DataSourceMapType;
	footNotes?: TableElementFootnote[]
}


export function isDataSourceObjectType(obj: unknown): obj is DataSourceObjectType {
	return obj !== undefined && (obj as DataSourceObjectType)["@metadata"] !== undefined;
}

export function isDataSource(obj: unknown): obj is DataSource {
	return obj !== undefined && (obj as DataSource)?.type !== undefined && (obj as DataSource)?.data !== undefined;
}
