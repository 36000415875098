import {
	Publish,
	Save,
	ImportExport,
	CheckCircle,
	Cancel,
	Pageview,
	ThumbUp,
	ThumbDown,
	Explore,
	CancelScheduleSend,
	Visibility, Update, Edit, Delete,
	Add
} from "@mui/icons-material";
import { Context } from "./ContextButton";

type ContextButtonTypes = {
	Publish: Context,
	Save: Context,
	View: Context,
	Update: Context,
	Export: Context,
	Import: Context,
	Edit: Context,
	Delete: Context,
	New: Context,
	RequestReview: Context,
	CompleteReview: Context,
	CancelReview: Context,
	Approve: Context,
	Reject: Context,
	CancelApproval: Context,
	RequestApproval: Context,
}

const DefaultContextButtons: ContextButtonTypes = {
	Publish: {Icon: Publish, label: "Publish", key: "publish"},
	Save: {Icon: Save, label: "Save", key: "save"},
	View: {Icon: Visibility, label: "View", key: "view"},
	New: {Icon: Add, label: "New", key: "new"},
	Edit: {Icon: Edit, label: "Edit", key: "edit"},
	Delete: {Icon: Delete, label: "Delete", key: "delete"},
	Update: {Icon: Update, label: "Update", key: "update"},
	Export: {Icon: ImportExport, label: "Export", key: "export"},
	Import: {Icon: ImportExport, label: "Import", key: "import"},
	RequestReview: {Icon: Pageview, label: "Request Review", key: "request_review"},
	RequestApproval: {Icon: Explore, label: "Request Approval", key: "request_approval"},
	CompleteReview: {Icon: CheckCircle, label: "Complete Review", key: "complete_review"},
	CancelReview: {Icon: Cancel, label: "Cancel Review", key: "cancel_review_cycle"},
	Approve: {Icon: ThumbUp, label: "Approve", key: "final_review_approve"},
	Reject: {Icon: ThumbDown, label: "Reject", key: "final_review_reject"},
	CancelApproval: {Icon: CancelScheduleSend, label: "Cancel Approval", key: "cancel_approval_process"},
}

export {DefaultContextButtons}