import {useEffect, useRef} from "react";
import equal from 'fast-deep-equal';

function useRunWhenValueChange<T>(fn: (prevValue: T | null) => any, value: T) {
	const valueRef = useRef<T | null>(null);
	const prevValueRef = useRef<T | null>(null);
	const hasRun = useRef(false);


	useEffect(() => {
		if (!equal(value, valueRef.current)) {
			prevValueRef.current = valueRef.current;
			valueRef.current = value;
			hasRun.current = false;
		}
	}, [value]);

	useEffect(() => {
		if(!hasRun.current && valueRef.current !== null) {
			fn(prevValueRef.current);
			hasRun.current = true;
		}
	});
}

export {useRunWhenValueChange};