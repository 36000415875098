import {EctdModule} from "../../../../../../shared/interfaces/Module";
import {ExpandablePane} from "../../../../../../shared/components/layout/pane/ExpandablePane";
import {Toc} from "../../../../components/list/toc/Toc";
import "./styles.scss";

export type LeftPaneProps = {
	modules: EctdModule[],
	selectedSection?: EctdModule,
	activeModule: EctdModule,
	onSelectSection: (section: EctdModule) => void,
	onSelectModule: (module: EctdModule) => void
}

const LeftPane = (props: LeftPaneProps) => {

	const onSelectSection = (module: EctdModule) => {
		props.onSelectSection(module);
	}

	return <ExpandablePane
		orientation={"left"}
		titleComponent={<span className={"ectd__sd--lp-title"}>Table of Contents</span>}
		contents={(expanded) => <Toc onSelectModule={props.onSelectModule} onSelectSection={onSelectSection} showTitle={expanded} activeModules={props.modules} selectedModule={props.activeModule} selectedSection={props.selectedSection} />} />
}

export {LeftPane}