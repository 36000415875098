function validateVariableNames(allVariables: {[k: string]: unknown}) {
	const result: {hasDuplicate: boolean, allVariables: string[]} = {
		hasDuplicate: false,
		allVariables: []
	};

	if (!allVariables){
		return result;
	}

	// all values
	const variableKeys = Object.keys(allVariables).map((value) => {
		return value.toLowerCase();
	});
	// all values without duplicates
	const variableSet = new Set(variableKeys);

	if(variableKeys.length !== variableSet.size) {
		result.hasDuplicate = true;
	}
	return result;
}

export {validateVariableNames};