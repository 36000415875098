import {TypedVariableView, TypedVariableViewProps} from "./model";
import {FlexBox, Label} from "../../../../../../../shared/components";
import {LabelValue} from "../../../../../../../shared/components/labels/LabelValue";

type CheckboxValueType = {
	items: string[]
}

const CheckboxView = (props: TypedVariableViewProps) => {
	const data = props.options as CheckboxValueType;
	return <FlexBox>
		<Label>Checkbox items:</Label>
		<LabelValue>{data?.items?.join(", ") ?? "No options set"}</LabelValue>
	</FlexBox>
};

const CheckboxVariable: TypedVariableView = {
	type: "checkbox",
	view: CheckboxView
}
export {CheckboxVariable}