import {useState} from "react";
import {useRunOnce, useRunWhenValueChange} from "../../../shared/hooks";
import {useDashboardApi} from '../hooks/useDashboardApi';
import { QueryResponse } from "../../../shared/interfaces";
import { useNavigate } from "react-router-dom";
//import { moduleLinkGenerator } from "../../moduleNavigation";
import { useLoadTasksAction } from "../../task/hooks/actions/load/useLoadTasksAction";
import {moduleLinkGenerator} from "../../moduleNavigation";
import {NotSuperDocumentFilter} from "../../../shared/hooks/api/search/filters/notSuperDocument";

const dateToEpoch = (daysAgo: number) => {
	const date = new Date();
	date.setDate(date.getDate() - daysAgo);
	date.setHours(0, 0, 0, 0);
	return date.getTime();
}

export interface CardDataState {
	documents: QueryResponse<KaiAlphaDocument>[],
	templates: QueryResponse<KaiAlphaTemplate>[],
	documentBuffers: QueryResponse<KaiAlphaDocument>[],
	templateBuffers: QueryResponse<KaiAlphaTemplate>[],
}

const initialState = {
	documents: [],
	templates: [],
	documentBuffers: [],
	templateBuffers: [],
}

const useLandingPage = () => {
	const dashboardApi = useDashboardApi();
	const loadTasks = useLoadTasksAction();
	const navigate = useNavigate();
	const [cards, setCardData] = useState<CardDataState>(initialState);

	const [displayCreateTemplateModal, setDisplayCreateTemplateModal] = useState<boolean>(false);
	const invertCreateTemplateModal = () => setDisplayCreateTemplateModal(s => !s);

	useRunOnce(() => {
		const date = dateToEpoch(120);
		const query = {
			filters: [{field: "@date", expression: ">=", value:date}],
			pageSize: 10,
		}
		dashboardApi.getDocuments.execute({
			...query,
			filters: [{field: "@date", expression: ">=", value:date}, NotSuperDocumentFilter],
		});
		dashboardApi.getTemplates.execute(query);
		dashboardApi.getDocumentBuffers.execute({
			fields: ["name", "id", "version", "@date", "state"],
		});
		dashboardApi.getTemplateBuffers.execute({
			fields: ["name", "id", "version", "@date", "state"],
		});
		loadTasks.run();
	})

	const validAsyncResponse = (value, status) => {
		if (value === null || status === 'error') {
			// handle error (status bar manager)
			return false;
		}
		return true;
	}

	useRunWhenValueChange(() => {
		const {value, status} = dashboardApi.getDocuments;
		if (!validAsyncResponse(value, status)) return;
		setCardData(s => ({...s, documents: value?.results ?? []}));
	}, dashboardApi.getDocuments.value);

	useRunWhenValueChange(() => {
		const {value, status} = dashboardApi.getTemplates;
		if (!validAsyncResponse(value, status)) return;
		setCardData(s => ({...s, templates: value?.results ?? []}));
	}, dashboardApi.getTemplates.value);

	useRunWhenValueChange(() => {
		const {value, status} = dashboardApi.getDocumentBuffers;
		if (!validAsyncResponse(value, status)) return;
		setCardData(s => ({...s, documentBuffers: (value as QueryResponse<KaiAlphaDocument>[])!}));
	}, dashboardApi.getDocumentBuffers.value);

	useRunWhenValueChange(() => {
		const {value, status} = dashboardApi.getTemplateBuffers;
		if (!validAsyncResponse(value, status)) return;
		setCardData(s => ({...s, templateBuffers: (value as QueryResponse<KaiAlphaTemplate>[])!}));
	}, dashboardApi.getTemplateBuffers.value);

	const onCreateTemplate = () => {
		invertCreateTemplateModal();
	}

	const onCreateContent = () => {
		navigate(moduleLinkGenerator("content", "create"));
	}

	const onCreateWorkflow = () => {
		navigate("/activity/workfloweditor");
	}

	return {
		documentBuffersLoading: dashboardApi.getDocumentBuffers.isLoading,
		templateBuffersLoading: dashboardApi.getTemplateBuffers.isLoading,
		onCreateTemplate,
		onCreateContent,
		onCreateWorkflow,
		invertCreateTemplateModal,
		displayCreateTemplateModal,
		documentsLoading: dashboardApi.getDocuments.isLoading,
		templatesLoading: dashboardApi.getTemplates.isLoading,
		tasks: loadTasks.value,
		cards,
	}
}

export {useLandingPage};