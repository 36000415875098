import React from "react";
import { styled } from "@mui/material";
import { Label } from "../../../../shared/components";
import { LabelValue } from "../../../../shared/components/labels/LabelValue";

export type PropertyListData = {
	label: string,
	value?: string
}[];

export type PropertyListProps = {
	items: PropertyListData,
	children?: React.ReactNode
}

const Container = styled("div")(({ theme }) => ({
	margin: ".5rem 1rem 1rem 0",
	"& p": {
		fontSize: "1rem"
	},
	"& ul": {
		listStyle: "none",
		padding: 0,
		"& li": {
			display: "flex",
			"& label": {
				fontSize: "1rem",
				marginRight: ".5rem",
				color: theme.palette.text.secondary
			},
			"& p": {
				margin: 0
			}
		}
	}
}))

const NotSetValue = "Not set";

type ValueType = ({ length?: number }) | undefined;
function getPropValueOrDefault<T extends ValueType>(defaultValue: T, propValue?: T) {
	if (!propValue || (propValue?.length !== undefined && propValue?.length === 0)) {
		return defaultValue;
	}

	return propValue;
}

const PropertyList = ({ items, children }: PropertyListProps) => <Container>
	<ul>
		{items.map(item => <li key={item.label}>
			<Label>{item.label}</Label>
			<LabelValue>{getPropValueOrDefault(NotSetValue, item.value)}</LabelValue>
		</li>)}
	</ul>
	{children}
</Container>

export { PropertyList }