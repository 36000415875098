import {FlexBox} from "../../../../../../shared/components";
import {SquareToggle} from "../../../../../../shared/components/input/toggle/SquareToggle";
import {WorkflowChip} from "../../../../../../shared/components/labels/WorkflowChip";
import {MetaTags} from "./MetaTags";
import {UpgradeRounded} from "@mui/icons-material";
import {EntityMetaData} from "../../../../../../shared/interfaces/EntityMetaData";
import "./styles.scss";

type ContentListItemProps = {
	state?: string,
	id: string,
	name: string,
	isSelected: boolean,
	version?: string,
	metaData?: EntityMetaData,
	updated?: string,
	onToggleSelection: () => void
}
const ContentListItem = (props: ContentListItemProps) => <li className={"ectd__modal--contentpicker-list_item"} onClick={props.onToggleSelection} key={props.id}>
	<FlexBox width={"100%"} align={"center"}>
		<FlexBox align={"center"} width={"100%"}>
			<span><SquareToggle value={props.isSelected} onValueChange={() => {}} /></span>
			<span className={"ectd__modal--contentpicker-list_item__title"}>{props.name}</span>
		</FlexBox>
		{props.state && <WorkflowChip state={props.state} label={props.state} />}
	</FlexBox>
	<FlexBox className={"ectd__modal--contentpicker-list_item__meta"} width={"auto"} justify={"space-between"} align={"center"}>
		<FlexBox width={"auto"} align={"center"} className={"ectd__modal--contentpicker-list_item__meta-tags"}>
			{props.version && <span className={"ectd__modal--contentpicker-list_item__meta-tags__tag version"}>{props.version}</span>}
			{props.metaData && <MetaTags metadata={props.metaData} />}
		</FlexBox>
		{props.updated && <FlexBox width={"auto"} align={"center"}>
			<UpgradeRounded fontSize={"small"} sx={{color: "rgba(0, 0, 0, 0.5)"}}/>
			<span>{props.updated}</span>
		</FlexBox>}
	</FlexBox>
</li>

export {ContentListItem}