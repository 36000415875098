import { styled } from "@mui/material";
import { FlexBox } from "../../../shared/components";
import { Star } from "@mui/icons-material";
export interface DashboardContentListItemProps {
    lastItem: boolean,
}

const DashboardContentListItem = styled(FlexBox, {shouldForwardProp: (prop) => prop !== "lastItem"})<DashboardContentListItemProps>((props) => ({
	padding: "10px 10px",
	boxSizing: "border-box",
	borderRadius: '0',
	cursor: 'pointer',
	transition: 'all 150ms ease',
	borderBottom: props.lastItem ? "" : `1px solid ${props.theme.palette.dashboard?.border}`,
	':hover': {
		backgroundColor: props.theme.palette?.dashboard?.static,
	}
}));

export interface ListContainerProps {
	maxHeight?: string,
}

const ListContainer = styled(FlexBox)<ListContainerProps>(({theme, maxHeight}) => ({
	maxHeight: maxHeight ?? "15.8rem",
	minHeight: maxHeight ?? "15.8rem",
	overflowY: 'scroll',
	overflowX: 'hidden',
	'&::-webkit-scrollbar': {
		width: '5px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: theme.palette.dashboard?.border,
		borderRadius: "30px",
		height: "10px",
	},
}));

const ItemText = styled(FlexBox)(({theme}) => ({
	paddingLeft: "10px",
	marginRight: "120px",
	'& .title_text': {
		color: theme?.palette?.primary.main,
		fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
		fontWeight: 500,
		fontSize: '1.0rem',
		whiteSpace: 'nowrap',
		maxWidth: '40rem',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	'& .subtext': {
		color: theme?.palette?.dashboard?.contrastText,
		fontWeight: 'bold',
		fontSize: '0.8rem',
		margin: '0.4em 0 0 0',
		whiteSpace: 'nowrap',
	}
}));
export interface FavoriteProps {
	selected: boolean,
}

const Favorite = styled(Star, {shouldForwardProp: (prop) => prop !== "selected"})<FavoriteProps>(({selected, theme}) => ({
	fontSize: "1.3rem",
	cursor: "pointer",
	transition: 'all 150ms ease',
	color: selected ? theme.palette.dashboard?.hover : theme?.palette?.dashboard?.main,
	'&:hover': {
		color: theme.palette.dashboard?.hover,
	},
}));

export {
	Favorite,
	ItemText,
	ListContainer,
	DashboardContentListItem,
}