import {styled} from "@mui/material";

type DescriptionLabelProps = {
	text?: string
}

const DescriptionLabel = styled("span")(({theme}) => ({
	margin: "0.25rem 0",
	display: "block",
	fontSize: ".875rem",
	color: theme.palette.text.secondary,
	fontStyle: "italic"
}))
export const Description = ({text}: DescriptionLabelProps) => {
	return text ? <DescriptionLabel>{text}</DescriptionLabel> : null
}