import api from "../../../../api/api";
import {useAsync} from "../../../../shared/hooks";
import {ExternalFile} from "../../../../shared/interfaces/ExternalFile";

export type EctdExternalContent = ExternalFile & {
	directories?: string[]
}
type EctdExternalContentApiResponse = {
	items: EctdExternalContent[]
};

export type ExternalContent = {
	name: string,
	folders: ExternalContent[],
	files: ExternalFile[]
}

function getExternalContentApi(): Promise<EctdExternalContentApiResponse> {
	return api.call("GET", "ectd/submission/externalcontent/data");
}

function getDirectoriesFromUrl(url: string): string[] {
	const paths = url.replace("ka://", "").split("/");
	paths.splice(paths.length-1, 1);
	return paths;
}

function normalizeFileName(fileName: string, folderName: string) {
	return fileName.replace(`${folderName}/`, "")
}
function buildExternalContentList(items: EctdExternalContent[]): ExternalContent {
	const itemsWithDirectories = items.reduce((allItems, item) => {
		if (item.url.indexOf(".") < 0) {
			return allItems;
		}

		allItems.push(({...item, directories: getDirectoriesFromUrl(item.url)}));
		return allItems;
	}, [] as EctdExternalContent[]);

	return itemsWithDirectories.reduce((folderTree, fileEntry) => {
		const directories = fileEntry.directories ?? [];
		let currentFolder = folderTree;
		for (let i = 0; i < directories.length; i++) {
			const directory = directories[i];
			const folder = currentFolder.folders.find(folder => folder.name === directory);
			if (folder) {
				currentFolder = folder;
			} else {
				const folder: ExternalContent = {name: directory, folders: [], files: []};
				currentFolder.folders.push(folder);
				currentFolder = folder;
			}

			if (i === directories.length - 1) {
				currentFolder.files.push({name: normalizeFileName(fileEntry.name, directory), url: fileEntry.url});
			}
		}

		if (directories.length === 0) {
			currentFolder.files.push({name: normalizeFileName(fileEntry.name, currentFolder.name), url: fileEntry.url})
		}

		return folderTree;
	}, {name: "", folders: [], files: []} as ExternalContent);
}

function useGetExternalContent() {
	const getExternalContent = async () => {
		const externalContent = await getExternalContentApi();
		return buildExternalContentList(externalContent.items);
	}
	return useAsync(getExternalContent, false);
}

export {useGetExternalContent};