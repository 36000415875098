import { FlexBox } from "../../../../../shared/components"
import {PictureAsPdf, DataObjectRounded} from "@mui/icons-material";
import {WorkflowChip} from "../../../../../shared/components/labels/WorkflowChip";
import "./styles.scss"
import {DeleteButton} from "../../../../../shared/components/layout/box/editControlBox/DeleteButton";
import {moduleNumberForContent} from "../../../utilities/moduleNumberForContent";
import {EctdModule} from "../../../../../shared/interfaces/Module";
import {NumberLabel} from "../../text/labels/numberLabel";
import {isInternalContent, SubmissionContent} from "../../../../../shared/interfaces/SubmissionContent";

type ContentItemProps = {
	content: SubmissionContent,
	moduleNumber: string,
	selectedModule?: EctdModule,
	onDelete: (entity: SubmissionContent & {moduleNumber: string}) => void
}

const ContentItem = (props: ContentItemProps) => {
	const tags = props.selectedModule ? moduleNumberForContent(props.selectedModule!, props.moduleNumber) : [];

	const onHandleDelete = () => props.onDelete({...props.content, moduleNumber: props.moduleNumber});

	return <FlexBox direction={"column"} className={"ectd__tabs--content-item"} align={"center"} justify={"center"}>
		<FlexBox className={"ectd__tabs--content-item--title"} align={"center"} justify={"center"}>
			{props.content.internal ? <PictureAsPdf fontSize={"small"} /> : <DataObjectRounded fontSize={"small"} /> }
			<span className={"content-title"}>{props.content.name}</span>
			{isInternalContent(props.content) && props.content.versionNumber && <NumberLabel depth={0}>{props.content.versionNumber}</NumberLabel>}
			{isInternalContent(props.content) && props.content.status_when_added && <WorkflowChip size={"small"} state={props.content.status_when_added} label={props.content.status_when_added} />}
		</FlexBox>
		<FlexBox className={"ectd__tabs--content-item--tags"} align={"center"} justify={"space-between"}>
			<span>
				{tags.map((tag, idx) => <NumberLabel depth={idx}>{tag}</NumberLabel>)}
				{!tags.includes(props.moduleNumber) && <NumberLabel depth={tags.length}>{props.moduleNumber}</NumberLabel>}
			</span>
			<DeleteButton onClick={onHandleDelete} />
		</FlexBox>
	</FlexBox>
}

export {ContentItem}