import { Button, FlexBox, GroupedContent } from "../../../../../../shared/components";
import { Container } from "./style";
import { TextField } from "../../../../../../shared/components/input";
import { DocumentState } from "../../../../hooks/useDocumentState";
import AuditLog from "../../../../../../components/AuditLog";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { moduleLinkGenerator } from "../../../../../moduleNavigation";

// const truncateName = (name) => name.length > 20 ? name.substring(0, 20) + "..." : name;

/**
 * This component displays basic document details: name, the subtemplate it was originated from
 * @constructor
 */
const DocumentDetails = () => {
	const documentManager = DocumentState.useContainer();
	const navigate = useNavigate();
	const [showAuditLog, setShowAuditLog] = useState(false);
	const invertShowAuditLog = () => setShowAuditLog(!showAuditLog);

	// when document name changes update the buffer state
	const onChangeDocumentName = (e) => {
		documentManager.setBuffer(buffer => ({ ...buffer, name: e.target.value }));
	}

	const templateName = documentManager.template?.name ?? "Untitled Template";

	return <GroupedContent title={"Content Details"}>
		<Container>
			{!documentManager.editable && <FlexBox direction={"column"}>
				<span className="surTitle">Content Name</span>
				<label className="documentTitle" title={documentManager.buffer?.name}>{documentManager.buffer?.name}</label>
			</FlexBox>}
			{documentManager.editable &&
				<TextField
					label={"Content Name"}
					value={documentManager?.buffer?.name}
					fullWidth
					onChange={onChangeDocumentName}
				/>}
			{/* TODO DOCEDITOR: this needs to be turned into a link */}
			<FlexBox direction={"column"}>
				<span className="surTitle">Original Template</span>
				<label className="templateTitle" title={templateName} onClick={() => navigate(moduleLinkGenerator("template", "edit", documentManager.template!.id))}>{templateName}</label>
			</FlexBox>
			{documentManager.editable && <FlexBox direction={"column"}>
				<Button onClick={invertShowAuditLog} text="Audit Log" />
			</FlexBox>}
		</Container>
		<AuditLog
			type="document"
			id={documentManager.document?.id}
			visible={showAuditLog}
			onCancel={invertShowAuditLog} />
	</GroupedContent>
}

export { DocumentDetails }