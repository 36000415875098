import {isString} from "../../guards/isString";

export type SortMode = "human" | "int" | "float";
const sortModes: Record<SortMode, SortMode> = {
	human: "human",
	int: "int",
	float: "float"
}
export function isSortMode(obj: unknown): obj is SortMode {
	return isString(obj) && sortModes[obj] !== undefined;
}