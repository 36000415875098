import {styled} from "@mui/material";
import {TemplateInformation} from "../../../hooks/api/useGetTemplates";
import {useState} from "react";
import {ToggleChildrenButton} from "./ToggleChildrenButton";
import {FlexBox} from "../../../../../shared/components";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {BarLoader} from "react-spinners";
import { UserList } from "../../../../../shared/hooks/useUserList";
//import { NameBadge } from "../../../../../shared/components/labels/NameBadge";
import { TemplateUpgradeButton } from "../../../../../shared/components/layout/box/editControlBox/TemplateUpgradeButton";
import { WorkflowChip } from "../../../../../shared/components/labels/WorkflowChip";
import { useGetTemplatesSpecificVersion } from "../../../hooks/api/useGetTemplatesSpecificVersion";
export interface LiProps {
	depth: number,
	expandable?: boolean,
}

const Li = styled(FlexBox, {shouldForwardProp: (prop) => prop !== "depth" && prop !== "expandable"})<LiProps>(({depth, expandable, theme}) => ({
	paddingLeft: `${2 + (depth * 1.2)}rem`,
	color: depth > 0 ? theme.palette.table?.contrastText : theme.palette.table?.main,
	background: depth > 0 ? theme.palette.paperDialog?.main : "transparent",
	fontWeight: 600,
	padding: "19px 0",
	boxSizing: "border-box",
	minHeight: "70px",
	'& .row_title': {
		textDecoration: 'underline',
		textDecorationThickness: '0.13rem',
		textOverflow: 'ellipsis',
		cursor: 'pointer',
		width: "15rem",
		whiteSpace: 'nowrap',
		display: 'block',
		overflow: 'hidden',
		marginLeft: expandable ? "7px" : "0",
	},
	"& svg, & span": {
		cursor: "pointer",
	},
}))

export interface ListItemProps {
	template: TemplateInformation,
	userList: UserList,
	depth: number,
	onUpgrade?: () => void,
	onTemplatesLoaded: (templates: TemplateInformation[]) => void,
	onNavigateToTemplate: (template: TemplateInformation) => void
}

const ListItem = ({template, onTemplatesLoaded, onNavigateToTemplate, userList, onUpgrade, depth}: ListItemProps) => {
	const [state, setState] = useState({expanded: false});
	const getChildren = useGetTemplatesSpecificVersion();
	useRunWhenValueChange(() => {
		if (state.expanded && (getChildren.value?.length ?? 0) === 0) {
			const children = template.children?.filter(template => template.id !== "");
			getChildren.execute(children);
		}
	}, state.expanded);

	useRunWhenValueChange(() => {
		if (getChildren.value) {
			onTemplatesLoaded(getChildren.value!)
		}
	}, getChildren.value);
	const onLinkClick = () => onNavigateToTemplate(template);

	const onToggleExpansion = () => setState(s => ({...s, expanded: !s.expanded}));
	return <>
		<Li depth={depth}>
			<FlexBox align={"center"}>
				{template.children.length > 0 && <ToggleChildrenButton expanded={state.expanded} onToggle={onToggleExpansion} />}
				{state.expanded && getChildren.isLoading && <BarLoader />}
				{!getChildren.isLoading && <FlexBox justify="space-between" align="center">
					<FlexBox align="center" flex={".7"}>
						<span title={template.name} className={'row_title'} onClick={onLinkClick} style={{marginLeft: template.children.length === 0 ? "20px" : 0}}>{template.name}</span>
					</FlexBox>
					<FlexBox direction="row-reverse" flex={".3"} boxSizing={"border-box"} padding={"0 0 0 15px"} align={"center"}>
						{onUpgrade && <TemplateUpgradeButton onClick={onUpgrade}/>}
						{/* name badge is too large, so we will be removing this for now */}
						<WorkflowChip size="small" state={template.state} label={`${template.state ?? "N/A"}`}/>
						{/* <NameBadge name={userList?.map[template.permissions.owners?.[0]]?.display_name ?? "N/A"} size="small" /> */}
					</FlexBox>
				</FlexBox>
				}
			</FlexBox>
		</Li>
		{state.expanded && !getChildren.isLoading && <>
			{getChildren.value?.map(template =>
				<ListItem
					depth={depth + 1}
					template={template}
					// on upgrade will never be defined for sub sub templates, since the getTemplate api does not provide this information.
					onUpgrade={undefined}
					userList={userList} key={template.id}
					onTemplatesLoaded={onTemplatesLoaded}
					onNavigateToTemplate={onNavigateToTemplate}
				/>)}
		</>}
	</>
}

export {ListItem};