import {Column, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {SubmissionNode} from "../../../../models";
import {FlexBox} from "../../../../../../shared/components";
import {IndicationTag} from "./styles";
import {BaseTableCell} from "../../../../../../shared/components/layout/table/styles";

const IndicationCell = (props: ColumnViewProps) => {
	const item = props.item as SubmissionNode;

	return (<BaseTableCell>
		<FlexBox boxSizing="border-box" align="center" sx={{flexWrap: "wrap"}}>
			{item[props.column.field].map(indication => <IndicationTag key={`${item.id}-${indication}`} className={'row_title'}>{indication}</IndicationTag>)}
		</FlexBox>
	</BaseTableCell>)
}

const Indication: Column = ({
	field: 'indications',
	title: "Indications",
	itemAlign: "left",
	width: "25%",
	headerAlign: "left",
	searchable: true,
	sortable: false,
	views: {
		cell: IndicationCell
	}
})

export {Indication};