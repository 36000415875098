import { TreeNode } from "../../../components/list/tree/TreeList.models";
import { BaseElement } from "../../../interfaces/BaseElement";
import {ElementValueType} from "../../../interfaces/ElementValueType";

const switchDefinition: BaseElement = {
	name: 'Template',
	type: 'switch',
	displayName: 'Switch',
	description: 'Place Sub-Templates',
	allowsChildren: true,
	longDescription: 'Template elements may be used to include another template at the position where this element is placed',
	contentDescription: (contents) => {
		const treeNode = contents as TreeNode;
		if ('data' in treeNode) return treeNode.data?.name ?? "";
		return (contents as SwitchElementValue)?.name ?? "";
	},
	toString: (contents) => {
		return (contents as SwitchElementValue)?.name ?? "Untitled switch";
	}
} as const

export type SwitchElementValue = {
	name?: string,
	expression?: string,
	values?: {
		[k: string]: {
			body: KaiAlphaTemplateBody
		}
	}
	default?: {
		body: KaiAlphaTemplateBody
	}
}

export type SwitchDataValue = Omit<SwitchElementValue, "values" | "default"> & {
	values: {
		[k: string]: ElementValueType[]
	},
	default: ElementValueType[]
}
export {switchDefinition};