import { TreeNode } from "../../../components/list/tree/TreeList.models";
import { BaseElement } from "../../../interfaces/BaseElement";
import {ElementValueType} from "../../../interfaces/ElementValueType";

const loopDefinition: BaseElement = {
	name: 'Template',
	type: 'loop',
	displayName: 'Loop',
	allowsChildren: true,
	description: 'Place Sub-Templates',
	longDescription: 'Template elements may be used to include another template at the position where this element is placed',
	contentDescription: (contents) => {
		const treeNode = contents as TreeNode;
		if ('data' in treeNode) return treeNode.data?.name ?? "";
		return (contents as LoopElementValue)?.name ?? "";
	},
	toString: (contents) => {

		return (contents as LoopElementValue)?.name ?? "Untitled Loop";
	}
} as const

export type LoopElementValue = {
	name: string,
	expression?: string,
	body?: KaiAlphaTemplateBody,
	else?: {
		body: KaiAlphaTemplateBody
	}
}

export type LoopDataValue = Omit<LoopElementValue, "body" | "else"> & {
	body: ElementValueType[],
	else: ElementValueType[]
}

export { loopDefinition };