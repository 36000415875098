import {TableCellType} from "./TableRowCells";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

export type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key,
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string },
) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function sort(data: TableCellType[], order: Order, orderBy?: string): TableCellType[] {
	if (!orderBy) {
		return data;
	}
	const dataToSort = [...data];
	return dataToSort.sort(getComparator(order, orderBy!));
}

function search(data: TableCellType[], text: string): TableCellType[] {
	return data.filter(datum => Object.values(datum).some(value => value.toString().includes(text)));
}

export {sort, search}
