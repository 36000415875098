import { DefaultButton } from "./DefaultButton"
import { DestructiveButton } from "./DestructiveButton";
import { DisabledButton } from "./DisabledButton";
import {ChromelessButton} from "./ChromelessButton";
import {PlainButton} from "./PlainButton";
import React from "react";
import { FlexBox } from "../layout";
import {ButtonProps} from "./model";
import {Box} from "@mui/material";


const Button = React.forwardRef(({buttonType = "default", text, icon, children, ...rest}: ButtonProps, ref) => {
	let Component: React.ElementType;
	switch(buttonType) {
		case "plain":
			Component = PlainButton;
			break;
		case "chromeless":
			Component = ChromelessButton;
			break;
		case "disabled":
			Component = DisabledButton;
			break;
		case "destructive":
			Component = DestructiveButton;
			break;
		default:
			Component = DefaultButton;
	}

	// TODO: create properly spaced horizontal / vertical button when icon + text
	return <Component {...rest} ref={ref} disabled={buttonType === "disabled"}>
		<FlexBox align="center">
			{icon && <Box marginLeft={text ? `8px` : '0px'}>
				{icon}
			</Box>}
			{text && <Box padding={icon ? '14px 8px 14px 4px' : `14px 8px`}>
				{text}
			</Box>}
			{!icon && !text && children}
		</FlexBox>

	</Component>
})

export {Button};