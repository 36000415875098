import {EditableElementProps, ElementPropertyData} from "../../model";
import React, {useState} from "react";
import {TextField} from "../../../../../../shared/components/input";
import { TitleElementValue } from "../../../../../../shared/definitions/elements/title/model";
import {useDebounce, useRunWhenValueChange} from "../../../../../../shared/hooks";
import {DebounceDelay} from "../../utilities/editSettings";

const EditableView = (props: EditableElementProps) => {
	const value = props.data as TitleElementValue;
	const [title, setTitle] = useState(value.title);
	const debouncedTitle = useDebounce(title, DebounceDelay);

	useRunWhenValueChange(() => {
		props.onChange({
			...(props as ElementPropertyData),
			data: {...value, title: debouncedTitle}
		})
	}, debouncedTitle);

	const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setTitle(event.target.value);
	}
	return <TextField placeholder={"Enter title here"} value={title} onChange={onChange} />
}

export {EditableView}