import {PreviewElementProps} from "../../../model";
import {useState} from "react";
import {useLoadNestedElementsAction} from "../../../../../hooks/actions/load/useLoadNestedElementsAction";
import {useRunWhenValueChange} from "../../../../../../../shared/hooks";
import {sectionDefinition} from "../../../../../../../shared/definitions/elements/section/model";
import {SectionElementPreviewValue} from "../../../section/model";

type TocItemEvents = {
	onClick: (itemId: string) => void;
}
export type TocItemProps = PreviewElementProps & TocItemEvents;

const TocItem = (props: TocItemProps) => {
	const data = props.data as SectionElementPreviewValue;
	const [sections, setSections] = useState<PreviewElementProps[]>([]);
	const getSections = useLoadNestedElementsAction(sectionDefinition.type);

	useRunWhenValueChange(() => {
		if (data.body) {
			getSections.run(data.body);
		}
	}, data.body);

	useRunWhenValueChange(() => {
		if (getSections.value) {
			setSections([...getSections.value]);
		}
	}, getSections.value);

	const onSectionClick = () => props.onClick(props.id);


	return data.name ? <li key={props.id} onClick={onSectionClick} style={{cursor: "pointer"}}>
		{data.name}
		{sections.length > 0 && <ul className={"tm__toc-preview"}>
			{sections.map(section => <TocItem {...section} onClick={props.onClick} />)}
		</ul>}
	</li> : null
}

export {TocItem}