import {ApplicationAdminRole} from "../interfaces/ApplicationAdminRoles";
import {useLocalStorage} from "./persistence/useLocalStorage";
import api from "../../api/api";
import {AsyncReturn, useAsync} from "./useAsync";
import {useStatusBar} from "../components";
import {TryAsync} from "../interfaces/Either";
import {isEmptyObject} from "../utilities/isEmptyObject";

function getUserGroupsApi(): Promise<Record<ApplicationAdminRole, string>> {
	return api.call("GET", "users/groupNames");
}

const LocalStorageKey = "kaialpha_usergroups";
export function useUserGroupsLookup(): AsyncReturn<Record<ApplicationAdminRole, string>> {
	const localStorage = useLocalStorage();
	const statusBar = useStatusBar();

	const loadGroups = async (): Promise<Record<ApplicationAdminRole, string> | null> => {
		const groups = localStorage.get<Record<ApplicationAdminRole, string>>(LocalStorageKey, undefined);
		if (groups) {
			return Promise.resolve(groups);
		}

		const groupsEither = await TryAsync(getUserGroupsApi);
		return groupsEither.matchWith({
			left: e => {
				statusBar.sendErrorNotification({message: "Failed to load user groups", detail: e});
				return null;
			}, right: groups => {
				if (!isEmptyObject(groups)) {
					localStorage.set(LocalStorageKey, groups);
				}

				return groups;
			}
		});

	}

	return useAsync(loadGroups, false);
}