import {
	ElasticBoolQuery, ElasticFuzzyMatch, ElasticMatch,
	ElasticQuery, ExistsQuery,
	isBoolQuery, isExistsQuery, isFuzzyMatch,
	isMatchQuery, isMustNotQuery,
	isMustQuery, isShouldQuery, MustNotQuery,
	MustQuery, ShouldQuery
} from "../elastic/QueryRequest";
import {MongoQuery} from "./MongoQuery";

function fromElastic(elasticQuery: ElasticQuery): MongoQuery | undefined {
	const {query} = elasticQuery;
	if (isBoolQuery(query)) {
		return {is_previous_version: {$ne: true}, ...unwrapBoolQuery(query)};
	}

	return undefined;
}

function unwrapBoolQuery(query: ElasticBoolQuery): MongoQuery | undefined {
	const {bool} = query;
	if (isMustQuery(bool)) {
		return unwrapMustQuery(bool);
	} else if(isShouldQuery(bool)) {
		return unwrapShouldQuery(bool);
	} else if (isMustNotQuery(bool)) {
		return unwrapMustNotQuery(bool);
	} else if(isExistsQuery(bool)) {
		return unwrapExists(bool);
	}

	return undefined;
}

function unwrapShouldQuery(query: ShouldQuery) {
	const {should} = query;
	return should.reduce((conditions, condition) => {
		if (isMatchQuery(condition)) {
			conditions.$or.push(unwrapMatch(condition));
		} else if(isBoolQuery(condition)) {
			const unwrappedBool = unwrapBoolQuery(condition) ?? {};
			conditions.$or = [...conditions.$or, ...[unwrappedBool]];
		} else if(isExistsQuery(condition)) {
			conditions.$or.push(unwrapExists(condition));
		} else if(isFuzzyMatch(condition)) {
			conditions.$or.push(unwrapFuzzyMatch(condition));
		}

		return conditions;
	}, {"$or": []} as {"$or": MongoQuery[]});
}
function unwrapMustQuery(query: MustQuery) {
	const {must} = query;
	return must.reduce((conditions, condition) => {
		if (isMatchQuery(condition)) {
			return {...conditions, ...unwrapMatch(condition)};
		} else if(isBoolQuery(condition)) {
			return {...conditions, ...unwrapBoolQuery(condition)}
		} else if(isFuzzyMatch(condition)) {
			return {...conditions, ...unwrapFuzzyMatch(condition)};
		}


		return conditions;
	}, {} as MongoQuery)
}

function unwrapMustNotQuery(query: MustNotQuery) {
	const {must_not} = query;
	return must_not.reduce((conditions, condition) => {
		if (isMatchQuery(condition)) {
			return {...conditions, ...unwrapMatch(condition, true)};
		} else if(isBoolQuery(condition)) {
			return {...conditions, ...unwrapBoolQuery(condition)}
		} else if(isExistsQuery(condition)) {
			return {...conditions, ...unwrapExists(condition, true)};
		} else if(isFuzzyMatch(condition)) {
			return {...conditions, ...unwrapFuzzyMatch(condition, true)};
		}

		return conditions;
	}, {} as MongoQuery);
}

function unwrapFuzzyMatch(query: ElasticFuzzyMatch, negate: boolean = false) {
	const {wildcard} = query;
	const field = Object.keys(wildcard)[0];
	const value = wildcard[field].value.replace(/\*/gm, "");
	return negate ? {[field]: {$not: stringToRegex(value)}} : {[field]: stringToRegex(value)}
}

function unwrapMatch(query: ElasticMatch, negate: boolean = false) {
	const {match} = query;
	const field = Object.keys(match)[0];
	return negate ? {[field]: {$not: stringToRegex(match[field])}} : {[field]: stringToRegex(match[field])}
}

function stringToRegex(str: string) {
	return {$regex: `.*${str.replace(/\./gm, "\\.")}.*`, $options: "i"}
}

function unwrapExists(query: ExistsQuery, negate: boolean = false) {
	const {exists} = query;
	const {field} = exists;
	return negate ? {[field]: {$not: {$exists: true}}} : {[field]: {$exists: true}};
}

export const MongoAdapter = {
	fromElastic
}