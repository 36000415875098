import {Action} from "../../../../../shared/interfaces/ActionRun";
import {getTemplateApi} from "../../api/useGetTemplate";
import {
	reconcileDocumentCollection,
	ReconcileReturn
} from "../../../pages/editor/utilities/ReconcileDocumentCollection";
import {selectSubtemplatesFromTemplate} from "../../../pages/editor/utilities/SelectSubTemplatesFromTemplate";
import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";
import {Subdocuments, usePublishDocumentAction} from "../";
import {DocumentRecord} from "../../../models/documentCollection";
import {DocumentState} from "../../useDocumentState";
import {useAsync, useRunWhenValueChange} from "../../../../../shared/hooks";
import { useStatusBar } from "../../../../../shared/components";
import {constructDocumentTree} from "../load/constructDocumentTree";

/**
 * Compares the templates tied to content (documents and subdocuments included) and updates based on changes
 * when updates are finished a publish occurs to persist the work
 */
function useTemplateUpgradeAction(): Action {
	const documentManager = DocumentState.useContainer();
	const publishDocument = usePublishDocumentAction();
	const statusBar = useStatusBar();
	const upgradeTemplate = async (): Promise<boolean> => {
		if(documentManager.template === null) {
			return false;
		}

		// reconcile variables
		const topLevelTemplate: KaiAlphaTemplate = await getTemplateApi(documentManager.template!.id);

		const contentUpdate: ReconcileReturn = await reconcileDocumentCollection(documentManager.documentCollection, documentManager.variables, topLevelTemplate);
		const elementsUpdate = await constructDocumentTree(contentUpdate.documentCollection);

		documentManager.setDocumentCollection(contentUpdate.documentCollection);
		documentManager.setElements(elementsUpdate);
		documentManager.setVariables(contentUpdate.documentVariables);

		// pull out the document collection for more updates
		const {documentCollection} = contentUpdate;

		// (1) case when top level template has deleted a subtemplate
		if(topLevelTemplate.version !== documentManager.template.version) {
			// compare subtemplates in the new version to what the document was created for
			const originalSubtemplates = await selectSubtemplatesFromTemplate(documentManager.template);
			const newSubtemplates = await selectSubtemplatesFromTemplate(topLevelTemplate);

			// tally the subtemplates to be deleted
			const deletedSubtemplates: ElementValueType[] = [];
			originalSubtemplates.forEach((sValue) => {
				if(newSubtemplates.find((nValue) => nValue.id === sValue.id) === undefined) {
					deletedSubtemplates.push(sValue);
				}
			})

			// now lets find the documents associated with this template
			const deletedDocuments: string[] = [];
			const deletedSubDocuments: Subdocuments[] = [];
			deletedSubtemplates.forEach((dValue) => {
				const templateData = dValue.data as {id: string, name: string, state: string, type: string, version: string};
				const findDC: DocumentRecord  | undefined= documentCollection.find((dcol) => dcol!.template!.id === templateData.id);
				if(findDC) {
					deletedSubDocuments.push(findDC!.subDocuments!);

					// append document
					deletedDocuments.push(findDC.id);
					// append subdocuments
					for (const property in findDC.subDocuments) {
						const documentArray: string[] = findDC.subDocuments[property].document_id;
						documentArray.forEach((value) => {
							deletedDocuments.push(value);
						})
					}
				}
			});

			const cleanedCollection = documentCollection.filter((value) => !deletedDocuments.find((dValue) => dValue === value.id));

			documentManager.setDocumentCollection(cleanedCollection);
			documentManager.setTemplate(topLevelTemplate);
			return true;
		}

		return false;
	}

	const templateUpgrader = useAsync(upgradeTemplate, false);

	useRunWhenValueChange(() => {
		if (templateUpgrader.status === "success" && templateUpgrader.value) {
			// reload content only after publish
			publishDocument.run();
			return;
		}

		if (templateUpgrader.status === "error") {
			statusBar.sendErrorNotification({message: "Error upgrading template!", detail: templateUpgrader.error});
		} else {
			statusBar.sendInfoNotification({message: "No Changes to save!"});
		}

	}, templateUpgrader.status)


	return {
		run: templateUpgrader.execute,
		isRunning: templateUpgrader.isLoading
	}
}

export {useTemplateUpgradeAction}