import {AsyncComponent, FlexBox} from "../../../../shared/components";
import { DocumentState } from "../../hooks/useDocumentState";
import { Typography } from "@mui/material";
import {useEventListener, useRunWhenValueChange, useScroll} from "../../../../shared/hooks";
import { PreviewViewFactory } from "../elements/PreviewViewFactory";

const EditorCenterPane = () => {
	const documentManager = DocumentState.useContainer();
	const scrollManager = useScroll();
	useRunWhenValueChange(() => {
		if (documentManager.activeSectionId !== null) {
			scrollManager.scrollById(documentManager.activeSectionId);
		}
	}, documentManager.activeSectionId);


	const referenceClick = (event: Event) => {
		if (event.target instanceof HTMLAnchorElement && (event.target.dataset.refelement || event.target.dataset.citation)) {
			const elementId = event.target.getAttribute('href');
			if (elementId !== null) {
				scrollManager.scrollById(elementId);
			}
		}
		return false;
	};

	useEventListener("click", referenceClick);

	return (
		<FlexBox direction={"column"} height={"100%"} padding={"1rem"} boxSizing={"border-box"}>
			<FlexBox>
				<Typography variant="h4">{documentManager.buffer?.name}</Typography>
			</FlexBox>
			<AsyncComponent
				isLoading={documentManager.isLoadingVariables}
				loadingMessage={"Loading content..."}
				component={<FlexBox id="preview-doc-content" style={{ scrollBehavior: "auto", overflow: "auto" }}>
					<div style={{ width: "100%", scrollBehavior: "auto", overflow: "auto" }}>
						{documentManager.elements && documentManager.elements.map(element =>
							<PreviewViewFactory
								key={element.id}
								body={documentManager.elements ?? []}
								variables={documentManager.variables}
								metaData={documentManager.buffer?.metadata ?? documentManager.document?.metadata}
								isLoadingVariables={documentManager.isLoadingVariables}
								element={element}
								state={documentManager.document?.state} />

						)}
					</div>
				</FlexBox>} />
		</FlexBox>
	);
}

export { EditorCenterPane };