import api from '../../../../../api/api';
import {useAsync} from "../../../../../shared/hooks";

const getAllComments = (documentId: string, documentVersion: string): Promise<KaiAlphaCommentsDocument | null> => {
	return api.call('GET', `comment/document/${documentId}/version/${documentVersion}`);
}

/**
 * get all comments based on a templateId
 * the return value is of KaiAlphaCommentsDocument type
 * Note this API call will have to happen
 * (1) for the top level document
 * (2) for each element within the document
 * @returns
 */
const useGetAllComments = () => {
	return useAsync<KaiAlphaCommentsDocument>(getAllComments, false);
}

export {useGetAllComments, getAllComments};