import {CsvMapper, CsvParserOptions} from "./model";
import csv_parse_lib from "csv-parse/lib/sync";
import {getUniqueHeaders} from "../getUniqueHeaders";
import {CsvDataSource} from "../../../interfaces/DataSource";
import {CsvOptions, parseCsv} from "../parseCsv";


function parse(data: string | Buffer, options: CsvParserOptions): CsvDataSource {
	const parsedData = csv_parse_lib(data, {
		...options,
		columns: false
	});
	/*
	 * Look for hints that this is an "exploded" view, which has
	 * column names like "Column", "Row", and "Value"
	 */
	const parsedHeaders = parsedData[0];

	/**
	 ** Append unique value to header if header is a duplicate
	 **/
	const headerValues = getUniqueHeaders(parsedHeaders, "auto", false);

	// if first header is an empty string then we likely have columns-rows data
	if(headerValues.orderedHeaders.length > 0 && headerValues.orderedHeaders[0] === "") {
		return parseCsv(data, {type: "columns-rows"} as CsvOptions);
	} else if(headerValues.orderedHeaders.length > 0) {
		return parseCsv(data, {type: "columns"} as CsvOptions);
	}

	throw new Error(`Data type could not be determined, please set explicitly`)
}

export function autoMapperFactory(): CsvMapper {
	return ({
		type: "auto",
		parse
	})
}