import {useRunOnce, useRunWhenValueChange} from "../../../shared/hooks";
import {useState} from "react";
import {Context, DefaultContextButtons} from "../../../shared/components/buttons/theme";
import { moduleLinkGenerator } from "../../moduleNavigation";
import { useNavigate } from "react-router-dom";
import {SearchTemplatesQuery, useGetTemplates} from "../../../shared/hooks/api/useGetTemplates";
import { useUserList } from "../../../shared/hooks/useUserList";
import { useDeleteTemplate } from "../hooks/api/useDeleteTemplate";
import { useImportTemplate } from "../hooks/api/useImportTemplate";
import {TemplateListNode, TemplateTypes } from "../components/list/templates/models";
import { computeTemplateList } from "../components/list/templates/utils";
import { useGetOrphanTemplates } from "../hooks/api/useGetOrphanTemplates";
import {SearchFilter, SearchSort} from "../../../shared/hooks/api/searchApi";
import { UserManager } from "../../../shared/hooks/useUser";
import { ApplicationAdminRoleLookup } from "../../../shared/interfaces/ApplicationAdminRoles";

type SearchState = {
	page: number,
	pageSize: number,
	templates: TemplateListNode[],
	sort?: SearchSort,
	after?: string[] | number[],
	filters: SearchFilter[]
}

const initialSearchState: SearchState = {
	page: 0,
	pageSize: 25,
	filters: [],
	templates: [],
	after: undefined,
	sort: undefined
}

function useList() {
	const navigate = useNavigate();
	const getTemplates = useGetTemplates();
	const getOrphanTemplates = useGetOrphanTemplates();
	const userList = useUserList();
	const deleteTemplateById = useDeleteTemplate();
	const uploadTemplateFile = useImportTemplate();
	const userData = UserManager.useContainer();
	const [searchState, setSearchState] = useState<SearchState>({...initialSearchState});
	/** Import and New Icons should be visible only if the user has the role for template administration */
	const buttonList = userData.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_templates) ? [DefaultContextButtons.New, DefaultContextButtons.Import] : [];

	// used to dynamically provide buttons to the context menu
	const [contextButtons, setContextButtons] = useState<Context[]>(
		buttonList
	);

	// template selected in the list
	const [selectedTemplate, setSelectedTemplate] = useState<TemplateListNode | null>(null);

	// create template modal state //
	const [displayCreateTemplateModal, setDisplayCreateTemplateModal] = useState<boolean>(false);
	const invertCreateTemplateModal = () => setDisplayCreateTemplateModal(s => !s);

	// create template modal state //
	const [displayTemplateTaskModal, setdisplayTemplateTaskModal] = useState<boolean>(false);
	const invertTemplateTaskModal = () => setdisplayTemplateTaskModal(s => !s);


	// available lists to be displayed in the table for the user
	const [topLevelTemplates, setTopLevelTemplates] = useState<TemplateListNode[] | []>([]);
	const [orphanTemplates, setOrphanTemplates] = useState<TemplateListNode[] | []>([]);

	// lookup for currently displayed type
	const templateLists = {
		'toplevel': topLevelTemplates,
		'orphan': orphanTemplates,
	}

	// switch state -- which templates are displayed in the list
	const [displayedTemplateType, setSelectedTemplateType] = useState<TemplateTypes>("toplevel");
	const changeDisplayedTemplates = (type: TemplateTypes) => {
		setSelectedTemplateType(type);
	}

	const updateDisplayedTemplatesList = (templates: TemplateListNode[]) => {
		if (displayedTemplateType === "toplevel") {
			setTopLevelTemplates(templates);
		} else {
			setOrphanTemplates(templates);
		}
	}

	// generic error / loading message states
	const [error, setError] = useState<string | null>(null);
	const [loadingMessage, setLoadingMessage] = useState<string | null>("Loading Templates");

	const loadTemplates = () => {
		const topLevelFilter = displayedTemplateType === "toplevel" ? {field: "metadata.toplevel", expression: "|", value:"metadata.system.toplevel=true"} : {field: "!metadata.toplevel", expression: "|", value:"metadata.system.toplevel=false"}
		const options:SearchTemplatesQuery = {
			// get all top-level templates in the system.
			filters: [...searchState.filters, topLevelFilter],
			sort: searchState.sort,
			pageSize: searchState.pageSize,
			after: searchState.after
		}
		getTemplates.execute(options);
	}
	useRunWhenValueChange(() => loadTemplates(), [searchState.filters, searchState.sort, searchState.pageSize, searchState.after]);

	// load template List
	useRunOnce(() => {
		loadTemplates();
		userList.execute(['name', 'display_name'])
	})


	// upon successful retrieval of user list and templates, transform into list objects
	useRunWhenValueChange(() => {
		if (getTemplates.status === "success" && userList.status === "success") {
			const newTemplates = computeTemplateList(getTemplates.value?.results ?? [], userList.value!);
			setSearchState(s => ({...s, templates: s.page === 0 ? newTemplates : [...s.templates, ...newTemplates]}));
			//setTopLevelTemplates(searchState.templates.length === 0 ? newTemplates : [...topLevelTemplates, ...newTemplates]);
		}
	}, [getTemplates.status, userList.status]);

	// if orphans are required, retrieve orphans.
	useRunWhenValueChange(() => {
		if (searchState.after !== undefined || searchState.filters.length > 0) {
			setSearchState(s => ({...s, page: 0, after: undefined}));
		} else {
			loadTemplates();
		}
	}, displayedTemplateType);

	// upon successful retrieval of user list and orphan templates, transform into list objects
	useRunWhenValueChange(() => {
		if (getOrphanTemplates.status === "success" && userList.status === "success") {
			const newTemplates = computeTemplateList(getOrphanTemplates.value?.results ?? [], userList.value!);
			setSearchState(s => ({...s, templates: s.page === 0 ? newTemplates : [...s.templates, ...newTemplates]}));
			//setOrphanTemplates(computeTemplateList(getOrphanTemplates.value?.results ?? [], userList.value!))
		}
	}, [getOrphanTemplates.status, userList.status]);

	const createNewTemplate = () => {
		// allow user to go through template modal to select creation options.
		setDisplayCreateTemplateModal(true);
	}

	const editTemplate = () => {
		navigate(moduleLinkGenerator("template", "edit", selectedTemplate!.id));
	}

	const viewTasks = (template: TemplateListNode) => {
		//navigate(moduleLinkGenerator("template", "tasks", template.id));
		setdisplayTemplateTaskModal(true);
	}

	// when list items retrieve their children, add them to the tree structure at
	// their supplied parent template.
	const addChildren = (newTemplate: TemplateListNode) => {
		const newTemplates = [...templateLists[displayedTemplateType]];
		const templateIndex = newTemplates.findIndex(template => template.id === newTemplate.id);
		newTemplates[templateIndex] = newTemplate;
		updateDisplayedTemplatesList(newTemplates);
	}

	const onTemplateSelected = (template: TemplateListNode) => {
		setSelectedTemplate(template);
	}

	const deleteTemplate = (template?: TemplateListNode) => {
		setLoadingMessage('Deleting Templates');
		deleteTemplateById.execute(template?.id ?? selectedTemplate?.id);
	}

	// since children arent in a flat map, this is a slow process (O(n)).
	// could be improved by keeping indexes of tree traversal.
	const findAndRemoveTemplate = (id: string, templates: TemplateListNode[]) => {
		return templates.reduce((result : TemplateListNode[], template: TemplateListNode) => {
			if (template.id !== id) {
				if (template.children.length > 0) {
					template.children = findAndRemoveTemplate(id, template.children);
				}
				result.push(template);
			}
			return result;
		}, [])
	}

	useRunWhenValueChange(() => {
		// if deletion succeeded, find child and remove from the list
		if (deleteTemplateById.status === "success")  {
			const newTemplates = findAndRemoveTemplate(deleteTemplateById.value!, [...templateLists[displayedTemplateType]]);
			updateDisplayedTemplatesList(newTemplates);
		// if deletion failed, post in status bar.
		} else if (deleteTemplateById.status === "error")  {
			setError(`Error deleting template: ${deleteTemplateById.error}`);
		}
	}, deleteTemplateById.status);

	const importTemplate = (file: File) => {
		setLoadingMessage('Uploading Template');
		uploadTemplateFile.execute(file);
	}

	useRunWhenValueChange(() => {
		// if upload succeeded, navigate to the editor
		if (uploadTemplateFile.status === "success")  {
			navigate(moduleLinkGenerator("template", "edit", uploadTemplateFile.value!));
		// if upload failed, post in status bar.
		} else if (uploadTemplateFile.status === "error")  {
			setError(`Error uploading template: ${uploadTemplateFile.error}`);
		}
	}, uploadTemplateFile.status);

	useRunWhenValueChange(() => {
		// if selected template changes, calculate new buttons
		if(userData.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_templates)){
			setContextButtons([
				...(selectedTemplate !== null ? [DefaultContextButtons.Edit, DefaultContextButtons.Delete] : []),
				...[DefaultContextButtons.New, DefaultContextButtons.Import]
			]);
		}
	}, selectedTemplate);

	const setSort = (sort?: SearchSort) => {
		setSearchState(s => ({...s, sort, after: undefined, page: 0}));
	}

	const setPageSize = (pageSize: number) => {
		setSearchState(s => ({...s, pageSize, after: [], page: 0}));
	}

	const setAfter = (after?: string[] | number[]) => setSearchState(s => ({...s, after, page: s.page + 1}));
	const setFilter = (filter: SearchFilter): boolean => {
		const existingFilter = searchState.filters?.find(f => f.field === filter.field);
		if (existingFilter?.value === filter.value) {
			return false;
		}

		const filters = [...(searchState.filters ?? [])].filter(f => f.field !== filter.field);
		if (filter.value.toString().length > 0) {
			filters.push(filter);
		}

		setSearchState(s => ({...s, filters, page: 0, after: undefined}));
		return true;
	}
	return {
		createNewTemplate,
		importTemplate,
		editTemplate,
		addChildren,
		selectedTemplate,
		onTemplateSelected,
		deleteTemplate,
		...searchState,
		after: getTemplates.value?.next,
		total: getTemplates?.value?.total ?? 0,
		viewTasks,
		displayedTemplateType,
		error,
		changeDisplayedTemplates,
		displayCreateTemplateModal,
		invertCreateTemplateModal,
		contextButtons,
		setSort,
		setAfter,
		setFilter,
		setPageSize,
		isLoading: deleteTemplateById.isLoading || getTemplates.isLoading || userList.isLoading || uploadTemplateFile.isLoading || getOrphanTemplates.isLoading,
		loadingMessage,
		displayTemplateTaskModal,
		invertTemplateTaskModal
	}
}

export {useList};
