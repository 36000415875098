import {TemplateElementDefinition} from "../model";
import {TemplateOutlineIcon} from "../../../../../shared/definitions/elements/template/icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {PropertyEditor} from "./views/PropertyEditor";
import {PreviewView} from "./views/PreviewView";
import {
	templateDefinition as baseTemplateDefinition
} from "../../../../../shared/definitions/elements/template/model";

const templateDefinition: TemplateElementDefinition = {
	...baseTemplateDefinition,
	icon: TemplateOutlineIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		propertyEditor: {
			view: PropertyEditor,
			requiredOnCreate: true
		},
		preview: PreviewView
	}
} as const

export {templateDefinition};