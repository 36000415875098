import {ensureArgs} from "../filterUtilities";
import {Try} from "../../../interfaces/Either";
import {NunjucksFilterFactoryProps} from "../model";
import {isObject} from "../../../guards/isObject";

function stringify(...args: unknown[]): unknown {
	if (!ensureArgs(args)) {
		return "";
	}

	if (!isObject(args[0])) {
		return undefined;
	}

	return Try(() => JSON.stringify(args[0])).matchWith({left: _ => undefined, right: obj => obj});
}
export function stringifyFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "stringify",
		help: "Convert an object into its JSON string representation",
		example: "var | stringify",
		execute: stringify
	})
}