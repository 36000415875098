import {Variable} from "../../../../../../../../shared/interfaces/Variable";
import {VariableMapper} from "./model";
import {renderMissingVariable} from "../renderMissingVariable";

function mapper(variable: Variable) {
	const items = variable.value as (string[] | undefined) ?? [];
	return items.length === 0 ? renderMissingVariable(variable.name) : items.join(", ");
}

const CheckboxMapper: VariableMapper = {
	type: "checkbox",
	mapper
}

export {CheckboxMapper};