import {useRunWhenValueChange, useTypedAsync} from "../../../../../shared/hooks";
import {
	ExternalContent,
	InternalContent,
	isExternalContent,
	isInternalContent
} from "../../../../../shared/interfaces/SubmissionContent";
import {TryAsync} from "../../../../../shared/interfaces/Either";
import {removeExternalContentApi} from "../../api/removeExternalContentApi";
import {SubmissionState} from "../../useSubmissionState";
import {useStatusBar} from "../../../../../shared/components";
import {addExternalContentApi} from "../../api/addExternalContentApi";
import {useSaveSubmissionAction} from "./useSaveSubmissionAction";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

type RunProps = {
	external: {
		add: ExternalContent[],
		remove: ExternalContent[]
	},
	internal: {
		add: InternalContent[],
		remove: InternalContent[]
	},
	moduleNumber?: string
}
export function useAddAndRemoveContentAction(): TypedAction<RunProps> {
	const {submission, replaceModuleContents, needsSaving, findModule} = SubmissionState.useContainer();
	const saveSubmissionAction = useSaveSubmissionAction();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if (needsSaving) {
			saveSubmissionAction.run();
		}
	}, needsSaving)

	const run = useTypedAsync(async (props: RunProps) => {
		const selectedModule = props.moduleNumber === undefined ? undefined : findModule(props.moduleNumber);
		if (selectedModule === undefined) {
			statusBar.sendErrorNotification({message: "Error adding content", detail: "Could not find active module"});
			return;
		}

		const {id, version} = submission;
		// remove all external content
		const removeContentResult = await TryAsync(() => Promise.all(props.external.remove.map(content => removeExternalContentApi({...content, id, version: version ?? "HEAD", moduleNumber: props.moduleNumber!}))));
		const removeContentResponse = removeContentResult.matchWith({
			left: e => {
				statusBar.sendErrorNotification({message: "Error removing content", detail: e});
				return null;
			}, right: records => records[records.length-1]
		})

		if (removeContentResponse === null) {
			return;
		}

		// add all external content
		const addContentResult = await TryAsync(() => Promise.all(props.external.add.map(content => addExternalContentApi({...content, id, version: version ?? "HEAD", moduleNumber: props.moduleNumber!}))));
		const addContentResponse = addContentResult.matchWith({
			left: e => {
				statusBar.sendErrorNotification({message: "Error adding content", detail: e});
				return null;
			}, right: records => records[records.length-1]
		})

		if (addContentResponse === null) {
			return;
		}


		// remove and add content from submission
		const contents = [...(selectedModule!.content ?? []).filter(c =>
			(isExternalContent(c) && !props.external.remove.some(rc => rc.location === c.location)) ||
			(isInternalContent(c) && !props.internal.remove.some(rc => rc.id === c.id))
		), ...props.external.add, ...props.internal.add];


		const responses = [removeContentResponse, addContentResponse].filter(r => r !== undefined);
		const lastUpdated = responses[responses.length-1]?.lastupdated;

		replaceModuleContents(contents, selectedModule, lastUpdated)

	}, false);

	return {
		run: run.execute,
		isRunning: run.isLoading || saveSubmissionAction.isRunning
	}
}