import {ElementProps} from "../models";
import {ChangeEvent} from "react";
import {TextField} from "@mui/material";
import {StyleComponentMap} from "./model";

type StyleElementValue = {
	symbol_name: string,
	glyph: string
}

const View = ({option, value, onChange}: ElementProps) => {
	const data = value as StyleElementValue;
	const onChangeValue = (key: string, newValue: string | null) => onChange({...data, [key]: newValue ?? ""});
	const onSymbolNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeValue("symbol_name", e.target.value);
	const onGlyphChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeValue("glyph", e.target.value);

	const getValueWithKey = (key: string) => data && data[key] ? data[key] ?? "" : "";
	return (<>
		<TextField label={"Symbol name"} value={getValueWithKey("symbol_name")} onChange={onSymbolNameChange} />
		<TextField label={"Glyph"} value={getValueWithKey("glyph")} onChange={onGlyphChange} />

	</>)
}

const AddSymbol: StyleComponentMap = {
	type: "AddSymbol",
	component: View,
	valueFactory: (): StyleElementValue => ({
		symbol_name: "",
		glyph: ""
	})
}
export {AddSymbol}