import {useCreateNew} from "../../api/useCreateNew";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {useNavigate} from "react-router-dom";
import {useStatusBar} from "../../../../../shared/components";
import {moduleLinkGenerator} from "../../../../moduleNavigation";
import { CreateTemplateOptions } from "../../../components/modals/create";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

function useCreateTemplateAction(): TypedAction<CreateTemplateOptions> {
	const createApi = useCreateNew();
	const navigate = useNavigate();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		const {value} = createApi;
		navigate(moduleLinkGenerator("template", "edit", value!.id));
		statusBar.sendSuccessNotification({message: "Template created"})
	}, createApi.value);

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error creating template", detail: createApi.error});
	}, createApi.error);

	const run = (options: CreateTemplateOptions) => createApi.execute(options);
	return {run, isRunning: createApi.isLoading};
}

export {useCreateTemplateAction};