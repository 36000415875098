import {TryAsync} from "../../../interfaces/Either";
import {NunjucksDataSourceType} from "../../../nunjucks/models/NunjucksDataSourceType";
import {parseCsv} from "../../../utilities/data/parseCsv";
import {DataSourceType} from "../../../interfaces/DataSource";
import {getMetaDataForImage} from "../../../utilities/data/getMetaDataForImage";
//import {getMetaDataForImage, ImageMetaData} from "../../../utilities/data/getMetaDataForImage";

export type FetchDataSourceDependencies = {
	fetch: (url: string) => Promise<Response>
}
export type FetchDataSourceProps = {
	url: string,
	options: unknown,
	dependencies?: FetchDataSourceDependencies
}

type FetchDataSourceReturn = {
	type: DataSourceType,
	data: Blob | NunjucksDataSourceType | string
}
export async function fetchSource({url, options, dependencies}: FetchDataSourceProps): Promise<FetchDataSourceReturn> {
	/*
	 * Convert "ka://" URLs into relevant ones
	 */
	url = url.replace(/^ka:\/\//, "/api/v1/data/");
	/*
	 * Fetch the resource
	 */
	const fetcher = dependencies?.fetch ?? fetch;
	const responseEither = await TryAsync(() => fetcher(url));
	const response = responseEither.matchWith({
		left: e => {
			return undefined;
		}, right: v => v
	});

	if (response === undefined) {
		throw(new Error(`Failed to fetch ${url}`));
	}


	const blob = await response.blob();
	const rawType = response.headers.get('content-type');
	const type = rawType?.split(';')[0];
	switch (type) {
		case "text/csv":
		{
			const bytes = await (new Response(blob)).arrayBuffer();
			const body = new TextDecoder("iso-8859-1").decode(bytes);
			const data = parseCsv(body, options);
			return {
				type: "csv",
				data
			}
		}
		case 'image/png':
		case 'image/jpeg':
		case 'image/svg+xml':
		{
			const data = await getMetaDataForImage(blob, url, options);
			return {
				type: "image",
				data: data.image as string
			}
		}
		default: {
			const data = window.URL.createObjectURL(await response.blob());

			return {
				type: "blob",
				data
			}
		}
	}

}