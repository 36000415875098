import { useVersions } from "../../../hooks/useVersions";
import { useGetVersions } from "../../../hooks/api/useGetVersions";
import { useState } from "react";
import { VersionType } from "../../input/dropdown/versionSelector";
import { useRunWhenValueChange } from "../../../hooks";

interface VersionSelectorState {
	selectedValue: VersionType & { found: boolean } | null,
	viewingOlderVersion: boolean
}

const useVersionSelector = (type: 'document' | 'template', versionId?: string) => {

	const [state, setState] = useState<VersionSelectorState>({
		selectedValue: null,
		viewingOlderVersion: false
	});

	const versions = useVersions(useGetVersions(type));

	useRunWhenValueChange(() => {
		if (!versions.isLoading && versions.versionList) {
			let viewingOlderVersion = false;
			let selectedValue;
			if (versions.versionList) {
				selectedValue = versions.findMatchingVersion(versionId);
				if (!selectedValue) {
					// differentiate between undefined (versions still loading) and version not found
					selectedValue = {
						found: false
					};
				} else {
					viewingOlderVersion = !selectedValue.isCurrent;
					selectedValue = { ...selectedValue, found: true };
				}
			}

			setState(s => ({ ...s, viewingOlderVersion, selectedValue }));
		}
	}, versions.versionList);

	return {
		...versions,
		...state
	};
}

export { useVersionSelector };