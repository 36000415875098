import {TemplateElementDefinition} from "../../model";
import {MetaDefaultIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {
	metaDefaultDefinition as baseMetaDefaultDefinition
} from "../../../../../../shared/definitions/elements/meta/default/model";

const metaDefaultDefinition: TemplateElementDefinition = {
	...baseMetaDefaultDefinition,
	icon: MetaDefaultIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView
	},
} as const

export {metaDefaultDefinition};