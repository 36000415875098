import api from '../../../../../api/api';
import {useAsync} from "../../../../../shared/hooks";

/**
 * Create a comment with the template ID
 * requires Comment ID in payload
 * @returns
 */
const useUpdateComment = () => {
	const updateComment = async (templateId: string, commentEntry: KaiAlphaCommentEntry): Promise<KaiAlphaCommentsTemplate | null> => {
		return api.call('PUT', `comment/template/${templateId}`, {
			comment: commentEntry,
			commentId: commentEntry.id
		});
	}
	return useAsync<KaiAlphaCommentsTemplate>(updateComment, false);
}

export {useUpdateComment};