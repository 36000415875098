import React from "react";
import {Route} from "react-router-dom";
import {ApplicationUser, UserManager} from "../shared/hooks/useUser";
import {ModuleRoutes} from "../shared/routing";
import {TemplateModule, TemplateName} from "./template";
import {DashboardModule} from "./dashboard";
import {StatusBarContext} from "../shared/components";
import {ModuleRedirect, moduleRedirects} from "./ModuleRedirect";
import {TaskModule, TaskName} from "./task";
import {DocumentModule, DocumentName} from "./document";
import {SubmissionModule, SubmissionName} from "./ectd";
import {UserGroupsModule, UserGroupsName} from "./usergroups";
import {ApplicationAdminRole, ApplicationAdminRoleLookup} from "../shared/interfaces/ApplicationAdminRoles";

export type ModuleComponentProps = {
	user: ApplicationUser
}
export interface ModuleDefinition {
    basePath: string,
    path: string,
    component: React.FC<ModuleComponentProps>,
	superUserRequired?: boolean,
	name: string,
	requriedGroups?: ApplicationAdminRole[]
}

export interface ModuleRouterProps {
    children: React.ReactNode
}


export type RouteLookupKey = TemplateName | TaskName | DocumentName | SubmissionName | UserGroupsName | string;
type RouteLookup = Record<RouteLookupKey, string | undefined>;
const routeLookup: RouteLookup = {
	template: TemplateModule.basePath,
	tasks: TaskModule.basePath,
	submission: SubmissionModule.basePath,
	content: DocumentModule.basePath,
	usergroups: UserGroupsModule.basePath,
}

const modules: ModuleDefinition[] = [
	DashboardModule,
	TaskModule,
	SubmissionModule,
	TemplateModule,
	DocumentModule,
	UserGroupsModule
];

export function routesForUser(user: ApplicationUser): Record<string, string> {
	const routesRecord = modules.reduce((lookup, module) => {
		const hasGroups = (user.groups?.length ?? 0) > 0;
		const isModuleAdmin = module.requriedGroups === undefined || (hasGroups && module.requriedGroups.every(user.hasApplicationAdminRole));

		if (!(module.superUserRequired ?? false)) {
			if (isModuleAdmin) {
				lookup.baseRoutes[module.name] = module.basePath;
			}
		} else {
			lookup.restrictedRoutes[module.name] = module.basePath;
		}

		return lookup
	}, {
		baseRoutes: {},
		restrictedRoutes: {}
	} as RoutesRecord);

	if (user?.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_templates)) {
		routesRecord.baseRoutes = {
			...routesRecord.baseRoutes,
			"Workflows": "/activity/workflows",
			"Citations & Abbreviations": "/activity/lists"
		};
	}

	return user.isSuperUser ?
		{...routesRecord.baseRoutes, ...routesRecord.restrictedRoutes} :
		routesRecord.baseRoutes;
}

const ModuleRouter = ({children}: ModuleRouterProps) => {
	const user = UserManager.useContainer();

	const userRoutes = routesForUser(user);
	return <ModuleRoutes>
		{children}
		{modules.filter(module => userRoutes[module.name] !== undefined).map(module => <Route key={module.path} path={module.path} element={<module.component user={user}/>}/>)}
		{moduleRedirects.map(redirect => <Route key={redirect.from} path={redirect.from}
			element={<ModuleRedirect to={redirect.to()}/>}/>)}
	</ModuleRoutes>
}

const Store = ({children}) => {
	return <StatusBarContext.Provider>
		<UserManager.Provider>
			{children}
		</UserManager.Provider>
	</StatusBarContext.Provider>
}

/**
 * Placed in the .tsx file so we could use contexts and such to build conditionally,
 * e.g. only superusers should see the UserGroups button/route.
 */
type RoutesRecord = {
	baseRoutes: Record<string,string>,
	restrictedRoutes: Record<string, string>
}


export {ModuleRouter, Store, routeLookup};
