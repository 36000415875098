import {VariableEditor, VariableEditorProps} from "../model";
import {RichTextEditor as RteEditor} from "../../../../../../../shared/components/input/editor";
import {FlexBox} from "../../../../../../../shared/components";
import "./style.scss";
import { useState } from "react";
import { useDebounce, useRunWhenValueChange } from "../../../../../../../shared/hooks";

const Editor = (props: VariableEditorProps) => {
	const value = props.variable.value as (string | undefined);
	const onChange = (event: {target: {value: string}}) => {
		setRteValue(event.target.value)
	}

	const [rteValue, setRteValue] = useState(value);
	const updatedValue = useDebounce(rteValue,500);
	useRunWhenValueChange(() => {
		props.onChange(props.variable, updatedValue);
	}, updatedValue)

	return <FlexBox direction={"column"} className={"tm__preview--editors"}>
		{props.variable.name && <label className={"label"}>{props.variable.name}</label>}
		<div className={"rte__wrapper"}>
			<RteEditor
				placeholder="Type here"
				onChange={onChange}
				defaultValue={rteValue}
				variables={null}
			/>
		</div>
		{props.variable.description && <label className={"description"}>{props.variable.description}</label>}
	</FlexBox>
}

const RichTextEditor: VariableEditor = {
	type: "richtextarea",
	view: Editor
};

export {RichTextEditor};