import { BaseElement } from "../../interfaces/BaseElement";
import { abbreviationsDefinition } from "./abbreviations/model";
import {citationsDefinition} from "./citations/model";
import {footerDefinition} from "./footer/model";
import {headerDefinition} from "./header/model";
import {htmlDefinition} from "./html/model";
import {imageDefinition} from "./image/model";
import {instructionalTextDefinition} from "./instructionalText/model";
import {inTextTableDefinition} from "./inTextTable/model";
import {loopDefinition} from "./loop/model";
import {metaDefaultDefinition} from "./meta/default/model";
import {metaElseDefinition} from "./meta/else/model";
import {metaValueDefinition} from "./meta/value/model";
import {referenceDefinition} from "./reference/model";
import {sectionDefinition} from "./section/model";
import {styleDefinition} from "./style/model";
import {switchDefinition} from "./switch/model";
import {tableDefinition} from "./table/model";
import {templateDefinition} from "./template/model";
import {titleDefinition} from "./title/model";
import {tocDefinition} from "./toc/model";
import {variableDefinition} from "./variable/model";

const allElements: BaseElement[] = [
	abbreviationsDefinition,
	citationsDefinition,
	footerDefinition,
	headerDefinition,
	htmlDefinition,
	imageDefinition,
	instructionalTextDefinition,
	inTextTableDefinition,
	loopDefinition,
	metaDefaultDefinition,
	metaElseDefinition,
	metaValueDefinition,
	referenceDefinition,
	sectionDefinition,
	styleDefinition,
	switchDefinition,
	tableDefinition,
	templateDefinition,
	titleDefinition,
	tocDefinition,
	variableDefinition
]; // all element definitions

export function displayNameForElementType(elementType: string) {
	return allElements.find(element => element.type === elementType)?.displayName
		?? `${elementType.charAt(0).toUpperCase()}${elementType.slice(1)}`;
}

export function findElementDefinitionForType(type: string): BaseElement | undefined {
	return allElements.find(element => element.type === type);
}
