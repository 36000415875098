import api from "../../../api/api";
import {generateListingQueryString} from "../../routing/generateListingQueryString";

export type SearchSort = {field: string, direction: "asc" | "desc"};
export type SearchFilter = {field?: string, value: string | number, expression?: string}
export type SearchApiProps = {
	filters?: SearchFilter[],
	fields: string[],
	pageSize?: number,
	sort?: SearchSort,
	after?: string[] | number[],
	searchType: string
}

export type SearchApiResponse<T> = {
	pageSize: number,
	total: number,
	next: string[] | number[],
	results: T[]
}

export function searchApi<T>({filters, fields, pageSize, sort, after, searchType}: SearchApiProps): Promise<SearchApiResponse<T>> {
	const query = generateListingQueryString({
		filter: [...(filters ?? []).map(filter => `${filter.field ?? ""}${filter.expression ?? "="}${filter.value}`)],
		fields,
		count: pageSize ?? 25,
		orderby: sort?.field,
		order: sort?.direction,
		after
	});

	const apiPath = `search/${searchType}${query}`;

	return api.call('GET', apiPath);
}