import {Chip, ChipLabel, Autocomplete} from "./UserSelector.style";
import { Close } from "@mui/icons-material";
import { TextField } from "@mui/material";
import {User} from '../../../interfaces/User';

export interface UserSelectorProps {
	options: User[],
	id: string,
	validation?: {
		condition: boolean,
		text: string,
	},
	isDisabled:boolean,
	label: string,
	selected: User[],
	onSelection: (key: string, newValue: User[]) => void,
}

const UserSelector = ({options, id, label, selected, onSelection, validation,isDisabled}: UserSelectorProps) =>
	<Autocomplete
		fullWidth
		multiple
		disableClearable
		value={selected}
		forcePopupIcon={false}
		options={options}
		isOptionEqualToValue={(option, value) => (option as User)?.id === (value as User)?.id}
		getOptionLabel={(option) => `${(option as User)?.display_name ?? "N/A"} (${(option as User).name ?? "N/A"})`}
		filterSelectedOptions
		renderInput={(params) => (
			<TextField
				{...params}
				label={label}
				placeholder="Type to search users"
				error={validation?.condition}
				helperText={validation?.condition ? validation?.text : undefined}
			/>
		)}
		renderTags={(tagValue, getTagProps) => {
			return tagValue.map((value, index) => {
				return <Chip {...getTagProps({ index })}
					disabled={isDisabled}
					label={<ChipLabel>{(value as User)?.display_name ?? "N/A"}</ChipLabel>}
					deleteIcon={<Close sx={{fontSize: "1rem !important"}}/>}
				/>
			});
		}}
		onChange={(event, newValue) => onSelection(id, newValue as User[])}
	/>

export {UserSelector}