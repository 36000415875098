function checkOrdinalNameAndFormat(name: string) {

	if (name.includes("{{") && name.includes("}}")) {
		const string = name.substring(
			name.lastIndexOf("{{") + 1,
			name.lastIndexOf("}}")
		);
		if (!(string.length > 2)) {
			name = name + "_{{ordinal}}";
		}
	} else {
		name = name + "_{{ordinal}}";
	}
	return name;
}

export {checkOrdinalNameAndFormat}