import {FlexBox} from "../../../../../shared/components";
import {iconForElementType} from "../../elements";
import {
	ElementDescription,
	ElementDetailContainer,
	ElementIconContainer,
	ElementTitle,
	VariableTitle,
} from './style';
export interface ElementDetailProps {
	type: string,
	title: string,
	description: string,
	isChild: boolean
}

const ElementDetail = ({type, title, description, isChild}: ElementDetailProps) => {
	const Icon = iconForElementType(type);
	return <ElementDetailContainer isChild={isChild}>
		<ElementIconContainer isChild={isChild}>
			<Icon color={"white"} />
		</ElementIconContainer>
		<FlexBox direction="column">
			{type === "variable" ? <VariableTitle title={`${title}`}>{title}</VariableTitle> : <ElementTitle title={`${title}`}>{title}</ElementTitle>}
			{description && <ElementDescription  title={`${description}`}>{description}</ElementDescription>}
		</FlexBox>
	</ElementDetailContainer>


}

export {ElementDetail};