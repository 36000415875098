import {ElementProps} from "../models";
import {StyleAttribute, styleParameters} from "./styles";
import {Autocomplete} from "@mui/lab";
import {ChangeEvent} from "react";
import {TextField} from "../../../../../../../../shared/components/input";
import {StyleComponentMap} from "./model";

export type StyleElementValue = {
	style_name: string
} & Partial<Record<StyleAttribute, string | null | undefined>>

const View = ({option, value, onChange}: ElementProps) => {
	const data = value as StyleElementValue;
	const onChangeValue = (key: string, newValue: string | null) => onChange({...data, [key]: newValue ?? ""});
	const onStyleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChangeValue("style_name", e.target.value);

	const getValueWithKey = (key: string) => data && data[key] ? data[key] ?? "" : "";
	return (<>
		<TextField label={"Style name"} value={getValueWithKey("style_name")} onChange={onStyleNameChange} />
		{styleParameters.map(styleParam => <Autocomplete
			freeSolo={true}
			clearOnBlur={false}
			onChange={(event, newValue) => onChangeValue(styleParam.attribute, newValue)}
			value={getValueWithKey(styleParam.attribute)}
			options={styleParam.values && styleParam.values.map(style => style.value)}
			getOptionLabel={function(item) {
				return(item);
			}}
			renderInput={(params) => {
				return (<TextField
					label={styleParam.title}
					onChange={(event) => onChangeValue(styleParam.attribute, event.target.value)}
					{...params}
					margin="normal"
				/>);
			}} />)
		}
	</>)
}

const AddStyle: StyleComponentMap = {
	type: "AddStyle",
	component: View,
	valueFactory: (): StyleElementValue => ({
		style_name: ""
	})
}

export {AddStyle}