import { StyleElementValue } from "../../../../../../shared/definitions/elements/style/model";
import {ReadonlyElementProps} from "../../model";
import {PropertyList} from "../../PropertyList";
import {typedViewForStyleType} from "./typed";

const ReadonlyView = (props: ReadonlyElementProps) => {
	const data = props.data as StyleElementValue;

	const TypedView = typedViewForStyleType(data.option);
	return <PropertyList items={[{label: "Options", value: data.option}]}>
		{TypedView && <TypedView value={data.value} /> }
	</PropertyList>
}

export {ReadonlyView}