import { TabDefinition } from "../../../../../shared/components/layout/tabs/tabDefinition";
import { useMemo, useState } from "react";
import { VariableList } from "../../list/VariableList";
import { AsyncComponent, FlexBox } from "../../../../../shared/components";
import { SearchBar } from "../../../../../shared/components/input/search/SearchBar";
import "./style.scss";
import { useVariables } from "../../../hooks/useVariables";
import { EmptyGroup } from "../../../../../shared/components/layout/groups/EmptyGroup";
import { DocumentState } from "../../../hooks/useDocumentState";
import { PropertyEditor } from "./PropertyEditor";
import { Variable } from "../../../../../shared/interfaces/Variable";

interface VariableListState {
	selectedVariable: Variable | null,
	searchValue: string,
	downloadCsv: boolean
}
const TabView = () => {
	const documentManager = DocumentState.useContainer();
	const variablesManager = useVariables();
	const [state, setState] = useState<VariableListState>({ selectedVariable: null, searchValue: "", downloadCsv: false });

	// event handlers
	// this could be debounced.
	const onSearchValueChange = (searchValue) => setState(s => ({ ...s, searchValue }));
	const onEditVariable = (variable: Variable) => setState(s => ({ ...s, selectedVariable: variable }));
	const onClosePropertyEditor = () => setState(s => ({ ...s, selectedVariable: null }));

	const onChangeVariableValue = (newValue?: unknown) => {
		if (newValue !== undefined) {
			const newVariable = { ...state.selectedVariable!, [state.selectedVariable?.type === "datasource" ? "dataSources" : "value"]: newValue };
			variablesManager.updateVariable(newVariable);
			setState(s => ({ ...s, selectedVariable: newVariable }));
		}

		onClosePropertyEditor();
	}

	/**
	 * We use useMemo to memoize a potentially expensive object.
	 *
	 * We are not including an expression variable editor since expressions are evaluated inside the
	 * center pane. So to accomodate we hide any expression variables from the variables tab.
	 */
	const variables = useMemo(() => variablesManager.filterVariables(state.searchValue).filter((value: Variable) => value.type !== 'expression'),
		[state.searchValue, variablesManager]);

	return <div className="rp__variable--tab">
		<FlexBox style={{ margin: "0.5rem 0 1rem 0" }} justify={"space-between"} align={"center"}>
			<SearchBar placeholder="Search" onChange={onSearchValueChange} containerStyle={{ margin: "0 1rem", flex: 1 }} />
		</FlexBox>
		{(!variables || variables.length === 0) && state.searchValue.length > 0 && <EmptyGroup title="No variables match your search"></EmptyGroup>}
		<AsyncComponent isLoading={documentManager.isLoadingVariables} loadingMessage={"Loading variables"} component={<VariableList
			variables={variables}
			onEdit={documentManager.editable ? onEditVariable : undefined} />
		} />
		{state.selectedVariable && <PropertyEditor
			variable={state.selectedVariable}
			type={state.selectedVariable.type}
			variables={variablesManager.allVariables}
			onSubmit={onChangeVariableValue}
			onClose={onClosePropertyEditor} />}
	</div>
}

const VariablesTab: TabDefinition = {
	name: "Variables",
	content: TabView
}

export { VariablesTab };