import {SectionIcon} from "../../../../shared/definitions/elements/section/icon";
import {
	EditableViewType,
	TemplateElementDefinition, ElementPropertyData,
	elements, PreviewViewType,
	ReadonlyViewType
} from "./model";
import React from "react";
import {DialogProps} from "../../models/elements";


const findElement = (type: string) => elements.find(element => element.type === type);
const iconForElementType = (type?: string) => elements.find(element => element.type === type)?.icon ?? SectionIcon;
const editableViewForElementType = (type?: string): EditableViewType | undefined => elements.find(element => element.type === type)?.views?.editable;
const readonlyViewForElementType = (type?: string): ReadonlyViewType | undefined => elements.find(element => element.type === type)?.views?.readonly;
const propertyEditorForElementType = (type?: string, forEditing?: boolean): React.FC<DialogProps> | undefined => {
	const element = elements.find(element => element.type === type);
	return !((element?.views?.propertyEditor?.requiredOnCreate ?? false) || (forEditing ?? false)) ? undefined : element?.views?.propertyEditor?.view;
}
const previewViewForElementType = (type?: string): PreviewViewType | undefined => elements.find(element => element.type === type)?.views?.preview;
const elementWithTypeHasCustomAddElement = (type?: string): boolean => {
	const element =  elements.find(element => element.type === type);
	return element?.addElementType !== undefined;
}
const elementWithTypeHasPropertyEditor = (type?: string): boolean => {
	const element =  elements.find(element => element.type === type);
	return element?.views?.propertyEditor !== undefined && (element?.views?.propertyEditor?.requiredOnCreate ?? false)
}
const definitionForElementType = (type?: string): TemplateElementDefinition | undefined => elements.find(element => element.type === type);
const elementDataFactory = (type: string, id: string, contents: unknown, readonly: boolean): ElementPropertyData => ({
	id: id,
	data: contents,
	elementInformation: {
		type: type,
		writable: !readonly
	}
});


export {
	findElement,
	iconForElementType,
	editableViewForElementType,
	propertyEditorForElementType,
	readonlyViewForElementType,
	definitionForElementType,
	elementDataFactory,
	previewViewForElementType,
	elementWithTypeHasPropertyEditor,
	elementWithTypeHasCustomAddElement
};