import {SnackBarContent, SnackBarContentMessage} from "./SnackBarContent";
import {Snackbar as MuiSnackbar} from "@mui/material";
import React from "react";

export interface SnackBarProps {
	content?: React.ReactNode,
	action?: React.ReactNode,
	open: boolean
}

const SnackBar = ({open, content, action}: SnackBarProps) => <MuiSnackbar
	anchorOrigin={{vertical: "top", horizontal: "center"}}
	open={open}
	autoHideDuration={null}
>
	<SnackBarContent message={<SnackBarContentMessage content={content} />} action={action} />
</MuiSnackbar>

export {SnackBar};