import React, {useMemo} from "react";
import {CheckBoxOutlineBlankRounded, CheckBoxRounded, CheckCircle, RadioButtonUnchecked} from "@mui/icons-material";
import {Checkbox, FormControlLabel} from "@mui/material/";
import {useStyles} from "./CheckBox.style";

type VariantType = "circle" | "square";

export interface CheckBoxProps {
	variant: VariantType,
	checked: boolean,
	readonly?: boolean,
	label?: string,
	onChange: () => void
}

type VariantIconMap = {
	variant: VariantType,
	checked: React.ElementType,
	unChecked: React.ElementType
}

const variantIcons: VariantIconMap[] = [{
	variant: "circle",
	checked: CheckCircle,
	unChecked: RadioButtonUnchecked
},{
	variant: "square",
	checked: CheckBoxRounded,
	unChecked: CheckBoxOutlineBlankRounded
}]
const CheckBox = ({variant, checked, onChange, label, readonly}: CheckBoxProps) => {
	const iconForVariant = useMemo(() => variantIcons.find(icons  => icons.variant === variant), [variant]);
	const UnCheckedComponent = iconForVariant!.unChecked;
	const CheckedComponent = iconForVariant!.checked;

	const classes = useStyles({readonly: readonly ?? false});

	return <FormControlLabel
		label={label}
		control={<Checkbox
			disabled={readonly}
			icon={<UnCheckedComponent className={classes.unselected_icon} />}
			checkedIcon={<CheckedComponent className={classes.selected_icon} />}
			checked={checked}
			onChange={onChange}
		/>}
	/>
}

export {CheckBox}