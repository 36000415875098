import {ensureArgs} from "../filterUtilities";
import {isNumber} from "../../../guards/isNumber";
import {isArray} from "../../../guards/isArray";
import {isObject} from "../../../guards/isObject";
import {NunjucksFilterFactoryProps} from "../model";

function arraySlice(...args: unknown[]): unknown {
	if (!ensureArgs(args, 3)) {
		return [];
	}

	if (args[0] === null) {
		return [];
	}
	let arrayCopy: any[] = [];
	if (isArray(args[0])) {
		arrayCopy = [...args[0]]
	} else if(isObject(args[0])) {
		arrayCopy = Object.values(args[0]);
	}

	if (arrayCopy.length === 0) {
		return [];
	}

	if (!isNumber(args[1]) && !isNumber(args[2])) {
		return arrayCopy;
	}

	const start = args[1] as number;
	const end = args[2] as number;

	return start > arrayCopy.length ? arrayCopy : arrayCopy.slice(start, end < start ? undefined : end);
}

export function arraySliceFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "array_slice",
		help: "Filter that accepts an array and produces a subset of that array",
		example: "var | array_slice",
		execute: arraySlice
	})
}