import api from "../../../../api/api";
import {useAsync} from "../../../../shared/hooks";
import {ApiPatchRequest, ApiPatchResult} from "../../../../shared/interfaces";
import {getTemplateApi} from "../../../../shared/hooks/api/getTemplateApi";
import { validateVariableNames } from "../../utilities/validateVariableNames";

function usePatchTemplate() {
	const updateTemplate = async (template: KaiAlphaTemplate, id: string, version: string, lastupdated?: number): Promise<ApiPatchResult> => {
		const headTemplate = await getTemplateApi({id, version: "HEAD"})
		const payload: ApiPatchRequest<KaiAlphaTemplate> = {
			id,
			version,
			change: {},
			delete: {}
		}

		if (template.variables) {
			const variableValidationResult = validateVariableNames(template.variables);
			if (variableValidationResult && variableValidationResult.hasDuplicate){
				throw new Error(`Variable names should be unique, found duplicate entries: ${JSON.stringify(variableValidationResult.allVariables)}`);
			}
		}

		if (template.name !== undefined) {
			payload.change.name = template.name;
		}

		if (template.model_document !== undefined) {
			payload.change.model_document = template.model_document;
		}


		if (template.body !== undefined) {
			payload.change.body = template.body;
			payload.delete.body = undefined;
		}

		const variablesToDelete = Object.keys(headTemplate?.variables ?? {})
			.filter(key => template.variables === undefined || template.variables[key] === undefined)
			.reduce((all, key) => {
				all[key] = null;
				return all;
			}, {});

		if (template.variables !== undefined) {
			payload.change.variables = template.variables;
		}

		if (Object.keys(variablesToDelete).length > 0) {
			payload.delete.variables = variablesToDelete
		}

		// this is assuming system metadata cannot be deleted.
		const metaDataToDelete = Object.keys(headTemplate?.metadata?.user ?? {})
		// for every metadata key, if the metadata isnt in the new template, filter it out.
			.filter(key => template.metadata?.user === undefined || template.metadata?.user[key] === undefined)
			.reduce((all, key) => {
				all[key] = null;
				return all;
			}, {});

		if (template.metadata !== undefined) {
			payload.change.metadata = template.metadata;
		}

		if (Object.keys(metaDataToDelete).length > 0) {
			payload.delete.metadata = {
				system: {},
				user: metaDataToDelete,
			}
		}

		if (template.permissions !== undefined) {
			payload.change.permissions = template.permissions;
			payload.delete.permissions = undefined;
		}

		payload.publish = true;
		payload.change.lastupdated = lastupdated;

		return api.call("PATCH", `template/${id}`, payload);
	}

	return useAsync<ApiPatchResult>(updateTemplate, false);
}

export {usePatchTemplate};