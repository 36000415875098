import { Grid, Box, styled } from "@mui/material";
import { FlexBox } from "../../../shared/components";
import { DashboardCardGroup } from "../components/DashboardCardGroup";
import { Description, TabUnselected, Add, LinearScale } from "@mui/icons-material";
import { DashboardContentList } from "../components/DashboardContentList";
import { DashboardCardGroupTitle } from "../components/DashboardCardGroup.style";
import { BufferLayout } from '../components/BufferLayout';
import Masthead from '../../../shared/components/navigation/masthead';
import { CreationButton } from "../components/CreationButton";
import { useLandingPage } from "./useLandingPage";
import { moduleLinkGenerator } from "../../moduleNavigation";
import { CreateTemplateModal } from "../../template/components/modals/create";
import { TaskGroupLayout } from "../components/TaskGroupLayout";
import { UserManager } from "../../../shared/hooks/useUser";
import {ApplicationAdminRoleLookup} from "../../../shared/interfaces/ApplicationAdminRoles";
const LandingPageContainer = styled(Box)(() => ({
	height: "100%",
	margin: "0 25px",
	width: 'auto',
	overflowX: 'hidden',
}));

const LandingPage = () => {
	const landingPage = useLandingPage();
	const userData = UserManager.useContainer();
	return <>
		<Masthead />
		<LandingPageContainer>
			<Grid container spacing={0} columns={15} height="97%">

				{/* dashboard cards */}
				<Grid item xs={15} xl={11} zeroMinWidth>
					<FlexBox flexWrap="wrap">

						{/* TODO: Status tracker and Task summary */}
						{/* <DashboardCardGroup
							title="Status Tracker"
							cards={[{
								header: {
									Icon: QueryBuilder,
									iconColored: true,
									title: 'Content Status'
								},
								content: <Box marginTop="11rem"></Box>
							},
							{
								header: {
									Icon: FactCheck,
									iconColored: true,
									title: 'Task Summary'
								},
								expandRoute: '/activity/tasks',
								content: <Box marginTop="11rem"></Box>
							}]}
						/> */}
						{(userData.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_templates) || userData.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_contents))  && <DashboardCardGroup
							flex="initial"
							title="Create"
							cards={[{
								header: {
									Icon: Add,
									iconColored: true,
									titleColored: true,
									title: 'Create New'
								},
								transparent: true,
								content: <FlexBox justify="space-around" align="center" padding="1.2rem 0 1.4rem 0">
									{userData.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_templates) && <CreationButton label="Template" Icon={TabUnselected} onClick={landingPage.onCreateTemplate} />}
									{userData.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_contents) && <CreationButton label="Content" Icon={Description} onClick={landingPage.onCreateContent} />}
									{userData.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_templates) && <CreationButton label="Workflow" Icon={LinearScale} onClick={landingPage.onCreateWorkflow} />}
								</FlexBox>
							}]}
						/>}
						{/* nest in flexbox to force 'second row' */}
						<FlexBox flexWrap="wrap" justify="center">
							<DashboardCardGroup
								title="Recent Activity"
								cards={[{
									header: {
										Icon: TabUnselected,
										title: 'Templates'
									},
									expandRoute: moduleLinkGenerator("template", "list"),
									content: <DashboardContentList
										list={landingPage.cards.templates}
										contentType={"published"}
										loading={landingPage.templatesLoading}
										route={moduleLinkGenerator("template", "edit")}
										onFavorite={() => { }}
									/>
								}, {
									header: {
										Icon: Description,
										title: 'Content'
									},
									expandRoute: moduleLinkGenerator("content", "list"),
									content: <DashboardContentList
										list={landingPage.cards.documents}
										contentType={"published"}
										loading={landingPage.documentsLoading}
										route={moduleLinkGenerator("content", "edit")}
										onFavorite={() => { }}
									/>,
								}]}
							/>
						</FlexBox>
						{/* nest in flexbox to force 'third row' */}
						<FlexBox flexWrap="wrap" justify="center">
							<TaskGroupLayout tasks={landingPage.tasks} />
						</FlexBox>
					</FlexBox>
				</Grid>
				{/* Content and Template Buffer lists */}
				<Grid item xs={15} xl={4} zeroMinWidth>
					<Box position="relative" width="100%" height="100%">
						<DashboardCardGroupTitle>Continue Working...</DashboardCardGroupTitle>
						<BufferLayout
							// we do not need the full buffer, instead just take template / document id for routing.
							documentList={{
								loading: landingPage.documentBuffersLoading,
								list: landingPage.cards.documentBuffers.map((item => ({ ...item, id: item.id?.split('_')[1] })))
							}}
							templateList={{
								loading: landingPage.templateBuffersLoading,
								list: landingPage.cards.templateBuffers.map((item => ({ ...item, id: item.id?.split('_')[1] })))
							}}
						/>
					</Box>
				</Grid>
			</Grid>
			<CreateTemplateModal
				visible={landingPage.displayCreateTemplateModal}
				onCancel={landingPage.invertCreateTemplateModal}
			/>
		</LandingPageContainer>
	</>
}

export { LandingPage };