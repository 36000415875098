import {Column, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {OwnerCell} from "../../../../../../shared/components/layout/table/cells/OwnerCell";
import {ContentNode} from "../../../../models/content";

const Cell = (props: ColumnViewProps) => {
	const item = props.item as ContentNode;
	return <OwnerCell column={props.column} owners={item.owners} id={item.id} />
}


const Owner: Column = ({
	field: "owner",
	title: "Owner",
	headerAlign: "left",
	itemAlign: "left",
	width: "25%",
	sortable: true,
	searchable: true,
	views: {
		cell: Cell
	}
})

export {Owner};