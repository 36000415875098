import { useState } from 'react';
import {Icon } from '../../../../../lib/ui';
import {
	Box,
	Chip,
	FormControl,
	FormLabel,
	Tooltip,
	IconButton
} from '@mui/material';
import { ButtonsBox, Container, TagsBox, BoxRadio, MentionsBox } from '../../../../../shared/components/modals/comments/CommentModal.styles';
import { COMMENT_TAGS } from './CommentModal.constants';
import { useRunWhenValueChange } from '../../../../../shared/hooks';
import { starterComment } from './CommentModal.models';
import { CommentState } from '../../../hooks/useCommentState';
import { AsyncComponent, Button } from '../../../../../shared/components';
import { useAddCommentAction, useUpdateCommentAction } from '../../../hooks/actions';
import "./styles.scss";
import {BaseModal} from "../../../../../shared/components/modals/BaseModal";
import {UserNameMentions} from "../../../../../shared/components/input/mentions/UserNameMentions";
import { CommentModalProps } from '../../../../../shared/definitions/highlight/CommentModal.props';

const uuid = require('uuid');

const CommentModal = (props: CommentModalProps) => {
	const {
		onClose,
		showOpen,
		selectedText,
		addEditComment,
		initialComment,
		textProperties
	} = props;

	const commentsManager = CommentState.useContainer();

	const commentPayload: KaiAlphaCommentEntry =
	{
		...starterComment, id: uuid.v4(),
		author: commentsManager.getCurrentUserId(),
		elementId: props.elementId!,
		...(initialComment ?? {})
	};

	const [tag, setTag] = useState(initialComment?.tag ?? starterComment.tag);
	const [text, setText] = useState(initialComment?.comment ?? "");

	const addCommentAction = useAddCommentAction();
	const updateCommentAction = useUpdateCommentAction();

	const userIds = commentsManager.getUsers();

	const onChipClick = (chipTag: string) => {
		setTag(chipTag);
	}

	const handleChangeCommentText = (event: React.ChangeEvent<HTMLInputElement>) => {
		const comment = event.target.value;
		setText(comment);
	}

	const handleCommentModalAction = () => {

		commentPayload.comment = text;
		commentPayload.tag = tag;
		if(textProperties) {
			commentPayload.text_properties = textProperties;
		}
		if(selectedText) {
			commentPayload.text = selectedText;
		}


		if (addEditComment === 'Add') {
			addCommentAction.run(
				{
					comment: commentPayload,
					elementId: commentPayload.elementId,
					documentId: props.documentId
				});
		} else if (addEditComment === 'Edit') {
			updateCommentAction.run({
				editResolve: "EDIT",
				documentId: props.documentId!,
				commentPayload
			});
		} else if (addEditComment === 'Response') {
			addCommentAction.run(
				{
					comment: commentPayload,
					elementId: props.elementId!,
					documentId: props.documentId
				});
		}
	}

	useRunWhenValueChange((previousState) => {
		if (previousState && !addCommentAction.isRunning) {
			onClose();
		}
	}, [addCommentAction.isRunning]);

	useRunWhenValueChange((previousState) => {
		if (previousState && !updateCommentAction.isRunning) {
			onClose();
		}
	}, [updateCommentAction.isRunning]);

	return (
		<div className='comments-view'>
			<Box sx={{ maxWidth: 375 }} style={{ border: 'none', boxShadow: 'none' }}>
				<Container style={{ padding: '0' }}>
					<BaseModal
						visible={showOpen}
						onClose={onClose}
						title={`${addEditComment} Comment`}
						content={<AsyncComponent isLoading={addCommentAction.isRunning || updateCommentAction.isRunning} loadingMessage={"Saving Comment"} component={<Box width={"100%"}>
							{selectedText &&
								<div className="highlightedTextContainer">
									<span>Highlighted Text:</span>
									<span><em>{selectedText}</em></span>
								</div>
							}
							<MentionsBox>
								{/* TODO refactor User Name Mentions Component */}
								<UserNameMentions
									className="mentionsInput"
									value={text}
									onChange={handleChangeCommentText}
									ids={userIds}
								/>
								{selectedText &&
									<FormControl>
										<BoxRadio>
											{/* <Checkbox checked={documentWide} onChange={handleChangeDocumentWideCheckbox} /> */}
											<FormLabel>
												<span className="applyToAllInstancesText">Apply to all instances</span>
												<Tooltip title={`Clicking this will apply/unapply this comment to all instances of the selected text throughout the document`} placement={'top'}>
													<IconButton><Icon className="infoIcon" forUI={'info'} /></IconButton>
												</Tooltip>
											</FormLabel>
										</BoxRadio>
									</FormControl>
								}
							</MentionsBox>
							<ButtonsBox>
								<TagsBox>
									<Icon forUI="tags" className="localOfferIcon" color='primary' />
									{Object.keys(COMMENT_TAGS).map(vTag =>
										<Chip
											key={vTag}
											className={`tagsChip ${tag === vTag ? "selectedChip" : ''}`}
											label={vTag}
											clickable={false}
											onClick={() => onChipClick(vTag)}
										/>
									)}
								</TagsBox>
							</ButtonsBox>
						</Box>}
						/>}
						buttonRow={<Button
							onClick={handleCommentModalAction}
							color='primary'
							text={`${addEditComment} Comment`}
						/>}
					/>

				</Container>
			</Box>
		</div>
	);
}

export default CommentModal;