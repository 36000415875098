import { Variable } from "../../../../../shared/interfaces/Variable";
import { FlexBox } from "../../../../../shared/components";
import { variableEditorForType } from "./variables";
import { styled, Typography } from "@mui/material";

export interface VariableFormProps {
	variables: Variable[],
	onVariableChange: (variable: Variable) => void
}

const FormControl = styled("div")(({ theme }) => ({
	width: "100%",
	margin: "1rem 0",
	"& label.description": {
		color: theme.palette.text.secondary,
		fontSize: ".875rem",
		marginTop: ".5rem",
		marginLeft: ".5rem"
	}
}));

const Form = styled(FlexBox)(({ theme }) => ({
	"& p": {
		fontSize: "1rem",
		color: theme.palette.text.primary
	}
}))
const VariableForm = ({ variables, onVariableChange }: VariableFormProps) => {

	const onChangeVariableValue = (variable: Variable, newValue: unknown) => {
		onVariableChange({ ...variable, value: newValue });
	}

	const editorForVariable = (variable: Variable) => {
		const Editor = variableEditorForType(variable.type);
		return Editor ? <FormControl key={variable.id}>
			<Editor variable={variable} onChange={onChangeVariableValue} />
		</FormControl> : null;

	}

	return <Form direction={"column"}>
		<Typography variant={"h4"}>Variable values</Typography>
		<p>In order to generate a preview of your template we will require values for the following variables</p>
		{variables.map(editorForVariable)}
	</Form>
}

export { VariableForm };