import {TemplateState} from "../../useTemplateState";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {usePublishActionHandler} from "./usePublishActionHandler";
import {useStatusBar} from "../../../../../shared/components";
import {Action} from "../../../../../shared/interfaces/ActionRun";

function usePublishAction(): Action {
	const publishHandler = usePublishActionHandler();
	const editorState = TemplateState.useContainer();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if (!publishHandler.value) {
			return;
		}

		const {version, state, workflow, lastupdated} = publishHandler.value;
		editorState.setBuffer(b => ({...b, version, state, workflow, lastupdated}));
		editorState.setTemplate(t => ({...t, version, state, workflow, lastupdated}));
		statusBar.sendSuccessNotification({message: "Template published"});
	}, publishHandler.value);

	// show publish error if one exists
	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error publishing template", detail: publishHandler.error});
	}, publishHandler.error)

	const run = () => publishHandler.execute();
	return {run, isRunning: publishHandler.isLoading};
}

export {usePublishAction};