
import { SectionIcon } from "../../../../shared/definitions/elements/section/icon";
import { DocumentElement } from "../../models/element";
import {
	elements,
	DocumentElementDefinition,
} from "./model";

const definitionForElementType = (type?: string): DocumentElementDefinition | undefined => elements.find(element => element.type === type);

const findElement = (type: string) => elements.find(element => element.type === type);

const iconForElementType = (type?: string) => elements.find(element => element.type === type)?.icon ?? SectionIcon;

function mapBodyToElement(body?: KaiAlphaDocumentBodyExtend, templateId?: string): DocumentElement[] {
	return body?.map(element => {
		const id = Object.keys(element)[0];
		const values = element[id]
		return ({ id, type: values.type as string, data: { ...values }, source: templateId });
	}) ?? [];
}


export {
	definitionForElementType,
	findElement,
	iconForElementType,
	mapBodyToElement,
};