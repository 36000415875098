import { VariableEditor, VariableEditorProps } from "./model";
import React from "react";
import { TextArea } from "../../../../../../shared/components/input/TextArea";
import {Description} from "./DescriptionLabel";


const Editor = (props: VariableEditorProps) => {
	const value = props.variable.value as (string | undefined);
	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (props.onChange) {
			props.onChange(e.target.value);
		}
	}
	return <>
		<TextArea label={props.label ?? props.variable.name} value={value ?? ""} onChange={onChange} />
		<Description text={props.variable.description} />
	</>

}

const TextAreaEditor: VariableEditor = {
	type: "textarea",
	view: Editor
}

export { TextAreaEditor };