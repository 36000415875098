export function defaultStyle(theme) {
	return({
		content: {
			margin: '2rem',
			width: 'calc(100% - 4rem)'
		},
		header: {
			...theme.typography.h4,
			textAlign: 'center',
			marginBottom: '0.25em'
		},
		subheader: {
			...theme.typography.h6,
			marginBottom: '0.25em',
			marginTop: '0.25em'
		},
		tree: {
			overflowX: 'auto',
			overflowY: 'unset',
			background: theme.palette.grey['200'],
			paddingTop: '0.5em',
			paddingBottom: '0.5em',
			paddingLeft: '0.25em',
			paddingRight: '0.25em',
			boxShadow: theme.shadows[4]
		}
	});
}

export default defaultStyle;
