import React from "react";
import {Mention, MentionsInput} from "react-mentions";
import {useUserList} from "../../../hooks/useUserList";
import {useRunOnce} from "../../../hooks";
import {AsyncComponent} from "../../progress";

type UserNameMentionsProps = {
	className: string,
	ids: string[],
	value: string,
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const UserNameMentions = (props: UserNameMentionsProps) => {
	const {
		ids,
		className,
		...pass
	} = props;
	const userList = useUserList();
	useRunOnce(() => {
		userList.execute(["display_name"]);
	});

	const getDisplayName = (id: string) => `@${(userIdList.find(item => item.id === id)?.display ?? `?${id}?`)}`
	const userIdList = userList.value === null ? [] : userList.value.users.filter(user => ids.includes(user.id))
		.map(user => ({id: user.id, display: user.display_name}));

	return <AsyncComponent
		isLoading={userList.isLoading}
		component={<MentionsInput {...pass} className={["commentsview-mentionsinput", className].join(' ')}>
			<Mention
				markup="<uid:__id__>"
				trigger="@"
				data={userIdList}
				displayTransform={getDisplayName}
			/>
		</MentionsInput>}/>
}

export {UserNameMentions}