import {Variable} from "../../interfaces/Variable";
import {nunjucksVariableFactory} from "../../nunjucks/nunjucksVariableFactory";
import {compareExpressions} from "../../nunjucks/expressions/compareExpressions";
import {NunjucksVariableAdapter} from "../../nunjucks/nunjucksVariableAdapter";
import {VariableAdapter} from "../variableAdapter";
import {NunjucksVariables} from "../../interfaces/Nunjucks";

function compareExpression(leftHandSide: string, rightHandSide: string, variables?: Variable[]): boolean {
	const nunjuckVariables = NunjucksVariableAdapter.toTree(nunjucksVariableFactory(variables));
	const flatVariables = VariableAdapter.flattenTree(VariableAdapter.toTree(variables ?? [])) as NunjucksVariables;
	return compareExpressions({lhs:leftHandSide, rhs:rightHandSide, variables:nunjuckVariables, options: {rhsQuoteDefault: true, variables: flatVariables}});
}

export {compareExpression};