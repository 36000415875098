import {useAsync} from "../../../../shared/hooks";
import {UserManager} from "../../../../shared/hooks/useUser";
import {AsyncReturn} from "../../../../shared/hooks/useAsync";
import {Tasks} from "../../../../shared/interfaces/task";
import {User} from "../../../../shared/interfaces/User";
import { getUserTasks } from "../../utilities/getUserTasks";

function getTasks(userId: string, name, groups: string[]): Promise<Tasks> {
	const user: User = {
		id: userId,
		name: name,
		groups: groups
	};
	return getUserTasks(user);
}

function useGetTasks(): AsyncReturn<Tasks> {
	const userManager = UserManager.useContainer();
	const getTasksAsync = useAsync(getTasks, false);
	const execute = () => getTasksAsync.execute(userManager.id, userManager.name, userManager.groups);

	return {
		...getTasksAsync,
		execute
	}
}

export {useGetTasks}