import { VariableEditor, VariableEditorProps } from "./model";
import { FlexBox } from "../../../../../../shared/components";
import { DropDown, DropDownItem } from "../../../../../../shared/components/input/dropdown";
import {Description} from "./DescriptionLabel";

type ListEditorOptions = {
    items: string[]
}
const Editor = (props: VariableEditorProps) => {
	const value = props.variable.value as (string | undefined);
	const onChange = (event: DropDownItem<string>) => {
		if (props.onChange) {
			props.onChange(event.value);
		}
	}

	const options = props.variable.options as ListEditorOptions;
	const listItems = options.items.map(item => ({ title: item, value: item }) as DropDownItem<string>)
	return (
		<FlexBox direction={"column"} width={"100%"}>
			<DropDown label={props.label ?? props.variable.name} items={listItems} sx={{ width: "100%" }} onSelect={onChange} value={value ?? ""} />
			<Description text={props.variable.description} />
		</FlexBox>
	)
}

const DropDownEditor: VariableEditor = {
	type: "dropdown",
	view: Editor
};

export { DropDownEditor };