import {VariableEditor, VariableEditorProps} from "../model";
import {TextField} from "../../../../../../../shared/components/input";
import {FlexBox} from "../../../../../../../shared/components";
import React from "react";

const Editor = (props: VariableEditorProps) => {
	const value = props.variable.value as (string | undefined);
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange(props.variable, event.target.value);
	}
	return <FlexBox direction={"column"}>
		<TextField label={props.variable.name} value={value ?? ""} onChange={onChange}  />
		{props.variable.description && <label className={"description"}>{props.variable.description}</label>}
	</FlexBox>
}

const TextEditor: VariableEditor = {
	type: "text",
	view: Editor
};

export {TextEditor};