import {EditableElementProps, ElementPropertyData} from "../../../model";
import {ExpressionBuilder} from "../../../../../../../shared/components/input/expressionBuilder";
import {useVariables} from "../../../../../hooks/useVariables";
import { MetaValueElementValue } from "../../../../../../../shared/definitions/elements/meta/value/model";
import {useState} from "react";
import {useDebounce, useRunWhenValueChange} from "../../../../../../../shared/hooks";
import {DebounceDelay} from "../../../utilities/editSettings";
import {nunjucksConstants} from "../../../../../../../shared/nunjucks/models/NunjucksConstants";
import {useAutoCompleteConfigGenerator} from "../../../../../../../shared/hooks/useAutoCompleteConfigGenerator";
import {
	variableAutoCompleteConfigGenerator
} from "../../../../../../../shared/variables/variableAutoCompleteConfigGenerator";
import {AsyncComponent} from "../../../../../../../shared/components";

function generateAutoCompleteConfig() {
	return Promise.resolve([{
		trigger: '|',
		options: nunjucksConstants.filters
	},
	{
		// match all
		trigger: nunjucksConstants.any,
		options: nunjucksConstants.operators,
		excludePredecessors: [...nunjucksConstants.operators]
	}])
}
const EditableView = (props: EditableElementProps) => {
	const data = props.data as MetaValueElementValue;
	const variablesManager = useVariables();
	const [expression, setExpression] = useState(data.value);
	const debouncedExpression = useDebounce(expression, DebounceDelay);
	const autoCompleteConfigGenerator = useAutoCompleteConfigGenerator();

	useRunWhenValueChange(() => {
		props.onChange({
			...(props as ElementPropertyData),
			data: {...data, value: debouncedExpression}
		})
	}, debouncedExpression);

	useRunWhenValueChange(() => {
		autoCompleteConfigGenerator.execute([
			() => variableAutoCompleteConfigGenerator({userVariables: variablesManager.allVariables.map(v => v.contents.name), trigger: " "}),
			generateAutoCompleteConfig
		])
	}, variablesManager.allVariables)

	const onChange = (event: {target: {value: string}}) => {
		setExpression(event.target.value);
	}

	return <AsyncComponent
		isLoading={autoCompleteConfigGenerator.isLoading}
		component={<ExpressionBuilder onChange={onChange} autoCompleteConfig={autoCompleteConfigGenerator.value ?? []} value={expression} />}
	/>
}

export {EditableView}