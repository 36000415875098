import api from '../../../api/api';
import {useTypedAsync} from "../../../shared/hooks";
import { QueryResponse } from '../../../shared/interfaces';
import {generateListingQueryString, QueryStringOptions} from "../../../shared/routing/generateListingQueryString";

export type BufferQueryResponse = QueryResponse<KaiAlphaTemplate>[] | QueryResponse<KaiAlphaDocument>[];

export type BufferType = 'document' | 'template';

// gets all user's buffers for the given type, filtered by the provided query parameters.
const useGetBuffers = (type: BufferType) => {
	const getBuffers = async (queryParams: QueryStringOptions): Promise<BufferQueryResponse | null> => {
		const query = generateListingQueryString(queryParams);
		return (await api.call('GET', `${type}/buffer${query}`))[`${type}s`] ?? null;
	}
	return useTypedAsync(getBuffers, false);
}

export {useGetBuffers};