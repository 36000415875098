import { styled } from "@mui/material";
import { FlexBox } from "../../index";

const ItemContainer = styled(FlexBox)(({theme}) => ({
	boxSizing: "border-box",
	margin: "10px 0px",
	borderRadius: "2px",
	backgroundColor: theme?.palette.variable?.background,
}));

interface BodyProps {
	showActions: boolean,
}

const Body = styled(FlexBox)<BodyProps>(({theme, showActions}) => ({
	boxSizing: "border-box",
	padding: "8px 4px 8px 8px",
	'& .title_text': {
		color: theme?.palette?.variable?.main,
		fontWeight: 'bold',
		fontSize: '15px',
	},
	"& .actions": {
		display: showActions ? "flex" : "none",
		flex: 1,
		justifyContent: "flex-end"
	},
	"&:hover": {
		"& .actions": {
			display: "flex"
		}
	}
}));

const Footer = styled(FlexBox)(({theme}) => ({
	boxSizing: "border-box",
	padding: "4px 10px 6px 15px",
	'& .value_text': {
		color: theme?.palette?.variable?.main,
		fontSize: '13px',
	}
}));


const Hr = styled("hr")(({theme}) =>({
	height: "1px",
	background: theme.palette.primary?.contrastText,
	margin: "2px 0px",
	width: "100%",
	border: 0,
}));

export {
	ItemContainer,
	Body,
	Footer,
	Hr
}