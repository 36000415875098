import {DocumentCollection} from "../../../models/documentCollection";
import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";
import {SectionElementValue} from "../../../../../shared/definitions/elements/section/model";
import {isEmptyString} from "../../../../../shared/utilities/isEmptyString";
import {TemplateElementValue} from "../../../../../shared/definitions/elements/template/model";
import {isKaiAlphaTemplateWithBody} from "../../../../../shared/guards/isKaiAlphaTemplate";
import {LoopElementValue} from "../../../../../shared/definitions/elements/loop/model";
import {SwitchDataValue, SwitchElementValue} from "../../../../../shared/definitions/elements/switch/model";

function getNextSectionId(sectionIndex: string, isChild: boolean = false) {
	const indexParts = sectionIndex.split(".");
	const lastIndex = parseInt(indexParts[indexParts.length - 1]);
	return indexParts.length === 1 ? `${lastIndex+1}` :
		`${indexParts.slice(0, indexParts.length - 1).reduce((indexString, part) => `${(indexString.length === 0 ? indexString : indexString + ".")}${part}`, "")}.${lastIndex+1}`;
}

function getParentSectionIndex(index: string) {
	const parts = index.split(".");
	return parts.length === 1 ? parts[0] : parts.slice(0, parts.length - 1).reduce((indexString, part) => `${(indexString.length === 0 ? indexString : indexString + ".")}${part}`, "")
}

export function mapTemplateBody(body: KaiAlphaTemplateBody, documentCollection: DocumentCollection, sectionIndex: string, templateId?: string) {
	return {elements: body.map(bodyObj => {
		const id = Object.keys(bodyObj)[0];
		const {type, ...rest} = bodyObj[id];
		const element: ElementValueType = {id, type: type as string, data: {...rest}, source: templateId};
		if (type === "section") {
			const data = element.data as SectionElementValue;
			sectionIndex = getNextSectionId(isEmptyString(sectionIndex) ? "0" : `${sectionIndex}`);
			data.index = sectionIndex;
			element.data = {...data};
			const mappedBody = mapTemplateBody((rest["body"] as KaiAlphaTemplateBody) ?? [], documentCollection, isEmptyString(sectionIndex) ? "0" : `${sectionIndex}.0`, templateId);
			element.children = mappedBody.elements;
			sectionIndex = getParentSectionIndex(mappedBody.sectionIndex);
		} else if (type === "switch") {
			const data = element.data as SwitchElementValue;
			const elementData: SwitchDataValue = {...data, values: {}, default: []}
			if (rest["values"]) {
				const values = rest["values"] as {[k: string]: {body: KaiAlphaTemplateBody}}
				elementData.values = Object.keys(values)
					.map(valueKey => ({ expression: valueKey, elements: mapTemplateBody(values[valueKey].body, documentCollection, sectionIndex, templateId).elements}))
					.reduce((switchValues, value) => ({...switchValues, [value.expression]: value.elements}), {});
			}
			if (rest["default"]) {
				const defaultBody = rest["default"] as {body: KaiAlphaTemplateBody}
				const mappedBody = mapTemplateBody(defaultBody.body, documentCollection, sectionIndex, templateId);
				elementData.default = mappedBody.elements;
				sectionIndex = getParentSectionIndex(mappedBody.sectionIndex);
			}
			element.data = elementData;
		} else if (type === "template") {
			const templateValue = element.data as TemplateElementValue;
			const template = documentCollection.find(dc => dc.template?.id === templateValue.id)?.template;
			if (template !== undefined && isKaiAlphaTemplateWithBody(template)) {
				const mappedBody = mapTemplateBody(template.body ?? [], documentCollection, sectionIndex, template.id);
				element.children = mappedBody.elements;
				sectionIndex = mappedBody.sectionIndex;
			}
		} else if (type === "loop") {
			const loopData = element.data as LoopElementValue;
			const loopBody = mapTemplateBody(loopData.body ?? [], documentCollection, sectionIndex, templateId);
			const loopElse = mapTemplateBody(loopData.else?.body ?? [], documentCollection, sectionIndex, templateId);
			element.data = {...loopData, body: loopBody.elements, else: loopElse.elements}
		}

		return element;
	}), sectionIndex}
}