import {useStatusBar} from "../components";

type CopyFn = (text: string) => Promise<boolean> // Return success

function useCopyToClipboard(): {copy: CopyFn} {
	const statusBar = useStatusBar();
	const copy: CopyFn = async text => {
		if (!navigator?.clipboard) {
			statusBar.sendWarningNotification({message: 'Clipboard not supported'})
			return false
		}

		// Try to save to clipboard then save it in the state if worked
		try {
			await navigator.clipboard.writeText(text)
			statusBar.sendSuccessNotification({message: "Copied to clipboard"});
			return true
		} catch (error: any) {
			statusBar.sendErrorNotification({message:'Copy failed', detail: error})
			return false
		}
	}

	return {
		copy
	}
}

export default useCopyToClipboard