import React from 'react';

import {
	NavLink,
	Link
} from 'react-router-dom';
import { withStyles } from '@mui/styles'; // LEGACY

import general_utils from '../../lib/utils/general_utils';

function style(theme) {
	return ({
		link: {
			color: theme.palette.primary.main,
			textDecoration: 'underline 0.5px dashed'
		}
	});
}

class KALink extends React.Component {
	static computeURL(info) {
		return (general_utils.get_url_path_any_type(info));
	}

	render() {
		const pathname = KALink.computeURL(this.props.to);
		const {
			classes,
			componentType,
			className,
			...pass_props
		} = this.props;

		const combinedClassNames = [classes.link];
		if (className) {
			combinedClassNames.push(className);
		}

		const new_props = {
			className: combinedClassNames.join(' '),
			...pass_props,
			to: pathname
		};

		if (new_props.LinkClasses) {
			new_props.classes = new_props.LinkClasses;
			delete new_props['LinkClasses'];
		}

		let NewComponent;
		switch (componentType) {
			case undefined:
			case 'link':
				NewComponent = Link;
				break;
			case 'navlink':
				NewComponent = NavLink;
				break;
			default:
				throw (new Error(`Unsupported componentType: ${componentType}`));
		}

		return (<NewComponent {...new_props} />);
	}
}

export default withStyles(style)(KALink);
