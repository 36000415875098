import {useUpdateWorkflow} from "../../api/useUpdateWorkflow";
import {TemplateState} from "../../useTemplateState";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {useStatusBar} from "../../../../../shared/components";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

const DEFAULT_TEMPLATE_WORKFLOW_SLOT = 'default';
type RunProps = {
	id: string,
	comment?: string,
	lastupdated?: number
}
function useUpdateWorkflowAction(): TypedAction<RunProps> {
	const updateWorkflow = useUpdateWorkflow();
	const templateState = TemplateState.useContainer();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if (updateWorkflow.status === "error") {
			// send status bar message
			statusBar.sendErrorNotification({message: "Error updating workflow", detail: updateWorkflow.error})
			return;
		}

		if (updateWorkflow.status === "success") {
			const {item_state, state} = updateWorkflow.value!;
			templateState.setTemplate(s => ({
				...s,
				state: item_state,
				lastupdated: updateWorkflow.value?.result.lastupdated,
				workflow: {
					...s.workflow,
					[DEFAULT_TEMPLATE_WORKFLOW_SLOT]: state,
				}
			}));
			statusBar.sendSuccessNotification({message: "Workflow updated"});
		}
	}, updateWorkflow.status);

	const onUpdateWorkflow = (event: RunProps) => updateWorkflow.execute(
		event,
		'button_press',
		templateState.template?.id,
		DEFAULT_TEMPLATE_WORKFLOW_SLOT
	);

	return {run: onUpdateWorkflow, isRunning: updateWorkflow.isLoading};
}

export {useUpdateWorkflowAction, DEFAULT_TEMPLATE_WORKFLOW_SLOT};