import {EntityRecord} from "../../../../shared/interfaces/Entity";
import api from "../../../../api/api";
import {useTypedAsync} from "../../../../shared/hooks";

function extractApi({id, version}: Required<EntityRecord>): Promise<boolean> {
	return api.call("POST", "ectd/submission/extract", {id, version});
}

function useExtractApi() {
	return useTypedAsync(extractApi, false);
}

export {useExtractApi}