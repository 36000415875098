import { TemplateElement } from "../../../../../shared/interfaces/TemplateElement";
import {VariableElement} from "../../../models/elements";
import {buildTemplateBody} from "./buildTemplateBody";

const clone = require("rfdc/default");

function useBuildTemplate() {

	return (template: KaiAlphaTemplate | null, variables: VariableElement[], elements: TemplateElement[] | null): KaiAlphaTemplate | null => {

		if (template === null) {
			return null;
		}

		template.variables = variables.filter(variable => !variable.template).map(variable => {
			const contents = clone(variable.contents);
			delete contents['name'];
			delete contents['$from'];
			delete contents['$variable_descriptor'];
			return {name: variable.contents.name, contents, description: variable.description ?? variable.contents.description};
		}).reduce((variables, currentVariable) => {
			variables[currentVariable.name] = {...currentVariable.contents, description: currentVariable.description};
			return variables;
		}, {});

		template.body = buildTemplateBody(elements);
		return clone(template);
	};
}

export {useBuildTemplate};