import {Storage, StorageItem} from "./models";

function useSessionStorage(): Storage {
	function set(key: string, value: unknown) {
		sessionStorage.setItem(key, JSON.stringify({value}))
	}
	function get<T>(key: string, defaultValue: T | undefined): T | undefined {
		const rawItem = sessionStorage.getItem(key);
		if (rawItem === null) {
			return defaultValue;
		}

		return (JSON.parse(rawItem) as StorageItem<T>).value;
	}

	function remove(key: string) {
		sessionStorage.removeItem(key);
	}

	return {
		get,
		set,
		remove
	}
}

export {useSessionStorage}