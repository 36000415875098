type CommentStateReview = "IN REVIEW";
type CommentStateResolved = "RESOLVED";

export type CommentViewMode = "section" | "all";

export type CommentStatesType = {
	IN_REVIEW: CommentStateReview,
	RESOLVED: CommentStateResolved
}
export const CommentStates: CommentStatesType = {
	IN_REVIEW: "IN REVIEW",
	RESOLVED: "RESOLVED"
};

export type AddCommentAPIResponse = {
	// {
	// 	"author": "889eb73d-0b8f-ac95-6e24-7a495edc0d32",
	// 	"version": "0f4b89db-0c51-4186-ab73-10b60e5b9599",
	// 	"state": "IN REVIEW",
	// 	"deleted": false,
	// 	"id": "44284ba3-4694-444a-9545-45bf23eb6527",
	// 	"date": "2022-10-10T20:21:33.082Z",
	// 	"comment": "hello",
	// 	"tag": "Grammar/Cosmetic",
	// 	"elementId": "520c873e-617b-4d19-a593-b70d784a0e51"
	// }
		"author": string,
		"version": string,
		"state": CommentStatesType,
		"deleted": boolean,
		"id": string,
		"date": string,
		"comment": string,
		"tag": string,
		"elementId": string
}