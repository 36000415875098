import {DocumentElementDefinition} from "../model";
import PreviewView from "./PreviewView";
import {
	titleDefinition as baseTitleDefinition
} from "../../../../../shared/definitions/elements/title/model";

const titleDefinition: DocumentElementDefinition = {
	...baseTitleDefinition,
	views: {
		preview: PreviewView
	},
} as const

export {titleDefinition};