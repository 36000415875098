import { styled, Box } from "@mui/material";
import { FlexBox } from "../../../../../shared/components";

const VariableUploadContainer = styled(FlexBox)(({theme}) => ({
	padding: '25px',
	borderRadius: '8px',
	background: theme?.palette?.primary?.contrastText,
	boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
	'& h3':{
		fontSize:"20px",
		margin: "0px 0px 10px",
		whiteSpace: 'nowrap',
		color: theme.palette.primary?.main,
	},
	'& p':{
		fontSize:"14px",
		margin: "0px 0px 10px",
		whiteSpace: 'nowrap',
		color: theme.palette.tabs?.main,
	},
	'& .Mui-checked':{
		color: `${theme.palette.checkbox?.main} !important`,
	},
	'& .file_display':{
		margin: "20px 0px 30px",
	},
	'& .MuiTypography-body1': {
		fontSize: '16px !important',
		color: theme.palette.tabs?.main,
		'& .selected_action': {
			color: theme.palette.secondary?.main,
		}
	},
}));

const FileText = styled(Box)(({theme}) => ({
	fontSize:"14px",
	margin: "10px 10px",
	color: theme.palette.destructive?.main,
	'& .selected':{
		color: theme.palette.checkbox?.main,
	},
}));

export {
	VariableUploadContainer,
	FileText,
}