import { ColumnDefinition } from "../models";
import { DataTableCell } from "../styles";
import { NameBadge } from "../../../labels/NameBadge";
import { FlexBox } from "../../FlexBox";
export interface OwnerCellProps {
	column: ColumnDefinition,
	owners: string[] | undefined,
	id: string
}
const OwnerCell = (props: OwnerCellProps) => {
	const column = props.column;

	return <DataTableCell
		align={column.itemAlign}
		width={column.width}
		key={`${column.title}-${props.id}`}
		// for non-data displaying cells, allow full width of cell
		sx={{ ...(!column.field && { paddingLeft: 0 }) }}
	>
		<FlexBox sx={{ flexWrap: "wrap" }}>
			{props.owners && props.owners.map(owner =>
				<NameBadge key={`${owner}-${new Date().getTime() + ''}`} name={owner} />
			)}
		</FlexBox>
	</DataTableCell>
}

export { OwnerCell }