import {FlexBox} from '../../layout';
import {styled} from "@mui/material/styles";

interface TreeListRowProps {
	depth: number,
    expandable: boolean,
}
const TreeListRow = styled(FlexBox, {shouldForwardProp: (prop) => prop !== "depth"})<TreeListRowProps>(({depth, expandable, theme}) => ({
	boxSizing: 'border-box',
	padding: `20px 4px`,
	background: depth > 0 ? theme.palette.paperDialog?.main : "transparent",
	fontWeight: 600,
	borderBottom: `1px solid ${theme.palette.tagsChip?.main}`,
	transition: 'all 150ms ease',
	':hover': {
		backgroundColor: theme.palette?.dashboard?.static,
	},
	cursor: 'pointer',
	'.row_selected': {
		backgroundColor: theme.palette?.dashboard?.static,
	},
	'& .row_primary': {
		paddingLeft: `${20 * depth}px`,
	},
	'& .row_title': {
		textDecoration: 'underline',
		textDecorationThickness: '0.13rem',
		textUnderlineOffset: '0.2rem',
		paddingBottom: "2px",
		marginLeft: expandable ? "10px" : "0",
		color: depth > 0 ? theme.palette.table?.contrastText : theme.palette.table?.main,
	},
	'& .row_description': {
		color: theme.palette.tabs?.hover,
	}
}));


export {TreeListRow};
