import {useRef, useState} from "react";
import './style.scss';
import {Popover, Typography} from "@mui/material";
import {useRunWhenValueChange} from "../../../hooks";
import escapeStringRegexp from "escape-string-regexp";
import {HighlightWrapperProps, TextProperties} from "./model";
import {highlightElementFactory} from "./highlightElementFactory";

const CONTEXT_TEXT_LENGTH = 5 //A constant to define how much context text to grab ahead and behind the selected/highlighted text.
const HighlightWrapper = (props: HighlightWrapperProps) => {

	const { elementId, highlightComments, renderCommentModal } = props;
	//const [commentContext, setCommentContext] = useState("");
	const [highlightedText, setHighlightedText] = useState<string | null>(null);
	const [selectedText, setSelectedText] = useState("");
	const [selectedProperties, setSelectedProperties] = useState<TextProperties>();
	const [showCommentModal, setShowCommentModal] = useState(false);
	const [showPopover, setShowPopover] = useState(false);
	const [pageX, setPageX] = useState(0);
	const [pageY, setPageY] = useState(0);

	const refContainer = useRef<HTMLDivElement>(null);

	// TODO popover click happens whenever you click anywhere on screen
	// need to find out how to disable it if the user does not actually click the popover directly
	const handlePopoverClick = (event) => {
		setShowCommentModal(true);
		setShowPopover(false);
	};

	const handlePopoverClose = (event) => {
		setShowPopover(false);
	};

	const handleCommentModalClose = () => {
		setShowCommentModal(false);
	}

	// when values change update the DOM accordingly
	useRunWhenValueChange(() => {
		if (refContainer.current?.children && highlightComments.length > 0) {
			let markedContent = typeof(props.value) === 'string' ? props.value : `${props.value}`;
			highlightComments.forEach((comment) => {
				/**
				 * In case a comment is added without a text_container_id the condition needs to fail.
				 *
				 * if there is no text_container_id this will add the comment to on all occurrences of the text within the children of this container currently.
				 * if comment.text_properties.text_container_id === containerId we are certain that we are putting the comment in one right place.
				 */
				if (comment.text_properties?.text_container_id && comment.text_properties?.text_container_id !== elementId) {
					return;
				}

				const commentText = comment.text ?? "";
				const commentTextRE = escapeStringRegexp(commentText);
				const searchValue = new RegExp(commentTextRE);
				const replaceValue = `[HIGHLIGHT_DELIMITER][HIGHLIGHT_TEXT]${comment.id}=${commentText}[HIGHLIGHT_TEXT][HIGHLIGHT_DELIMITER]`

				const commentTextIn = comment.text_properties?.context;
				if (!commentTextIn) {
					markedContent = markedContent.replace(searchValue, replaceValue);
					return;
				}

				const commentTextInRE = escapeStringRegexp(commentTextIn);
				const replacedHighlightedText = commentTextIn.replace(searchValue, replaceValue);
				const commentTextMatches = [...markedContent.matchAll(new RegExp(commentTextInRE, 'g'))];

				/**
				 * If the highlighted text is duplicated multiple times within the same element/div the textInOccurrence is used to determine if the highlighted text was the 1st, 2nd or nth occurrence of the duplicate text.
				 * The > check is more of a safety check that the occurrence we have isn't more than the number of matches we found.
				 */
				const isOccurrenceOutOfBounds = (comment.text_properties?.context_occurrence ?? 0) > commentTextMatches.length
				const textInOccurrence = isOccurrenceOutOfBounds ? commentTextMatches.length : comment.text_properties?.context_occurrence;

				if ((textInOccurrence ?? -1) > 0) {
					const match = commentTextMatches[textInOccurrence! - 1];
					markedContent = `${markedContent.substring(0, match.index)}${replacedHighlightedText}${markedContent.substring((match.index ?? 0) + commentTextIn.length)}`;
				}
			});

			const markedParts = markedContent.split('[HIGHLIGHT_DELIMITER]');

			const highlightedContent = markedParts.map((contentPart, index) => {
				if (!contentPart.includes('[HIGHLIGHT_TEXT]')) {
					return contentPart;
				}
				const content = contentPart.replace(/.HIGHLIGHT_TEXT./g, '');
				const highlightedTextParts = content.split('=');
				const highlightedTextId = highlightedTextParts[0]; // Same as comment id
				const highlightedText = highlightedTextParts[1];

				const key = `${elementId}_highlighted_${highlightedTextId}_${index}`;
				return highlightElementFactory({key, commentId: highlightedTextId, text: highlightedText});
			});

			setHighlightedText(highlightedContent.join(""));
		}
	}, highlightComments);

	// https://stackoverflow.com/questions/43184603/select-text-highlight-selection-or-get-selection-value-react
	const handleSelect = (event) => {
		// we set the X and Y so it positions the popover right where user clicked
		setPageX(event.pageX);
		setPageY(event.pageY);
		if (!showCommentModal) {
			//this.setState({ selectedText, selectedTextContext, selectedTextContextOccurrence: occurrence, selectedTextContainerId: containerId, selectedTextVariableName });
			const windowSelection = window.getSelection();

			if (windowSelection && windowSelection.toString() !== "") {
				const selectedText = windowSelection!.toString();
				const selectedRange = windowSelection!.getRangeAt(0);
				const textContent = selectedRange.commonAncestorContainer?.textContent!;
				const startOffset = Math.max(0, selectedRange.startOffset - CONTEXT_TEXT_LENGTH);
				const endOffset = Math.min(textContent?.length, selectedRange.startOffset + selectedText.length + CONTEXT_TEXT_LENGTH);
				const selectedTextContext = textContent.substring(startOffset, endOffset);

				const matches = [...textContent.matchAll(new RegExp(escapeStringRegexp(selectedTextContext), 'g'))];

				const closestMatch = matches.find(({ index }) => {
					//Allow a max index delta of our text context length
					const delta = Math.abs(index! - startOffset);

					return delta <= CONTEXT_TEXT_LENGTH;
				})

				const occurrence = closestMatch === undefined ? 0 : matches.indexOf(closestMatch) + 1;
				setShowPopover(true);
				const textProperties: TextProperties = {
					context: selectedTextContext,
					context_occurrence: occurrence
				};
				setSelectedProperties(textProperties);
				setSelectedText(windowSelection.toString());

			}
		}
	}

	return !props.enabled && props.componentToWrap ? <div className={props.className}>{props.componentToWrap!(props.value ?? "", "")}</div> :  <>
		<div onMouseUp={handleSelect} className="mtpl__comment-highlight" ref={refContainer}>
			{props.componentToWrap && <div className={props.className}>{props.componentToWrap!(highlightedText ?? props.value ?? "", "")}</div>}
			<Popover
				open={showPopover}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: pageY,
					horizontal: pageX
				}}

				PaperProps={{ sx: { backgroundColor: "transparent", borderRadius: "100px" } }}
				transformOrigin={{
					vertical: "center",
					horizontal: "center"
				}}
				onClick={handlePopoverClick}
			>
				<Typography style={{
					backgroundColor: "#24272b",
					color: "white",
					padding: "10px",
					borderRadius: "100px",
					fontFamily: "Lato",
					fontSize: "18px",
					cursor: "pointer"
				}}>Add Comment</Typography>
			</Popover>
			{showCommentModal &&
				renderCommentModal({
					onClose:handleCommentModalClose,
					addEditComment:"Add",
					showOpen:showCommentModal,
					elementId:elementId,
					selectedText:selectedText,
					textProperties:selectedProperties
				})
			}
		</div>
	</>;
}

export { HighlightWrapper };