import {Variable} from "../interfaces/Variable";

export const systemNamespaces = ["ittvariables"] as const;
export type SystemNamespace = typeof systemNamespaces[number];

export const SystemNamespaceLookup: Record<SystemNamespace, SystemNamespace> = {
	ittvariables: "ittvariables"
}

export function isSystemVariable(variable: Variable): boolean {
	return systemNamespaces.some(namespace => variable.namespace?.includes(`${namespace}.`));
}