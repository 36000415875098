import { styled, Box } from "@mui/material";

export interface DashboardCardGroupContainerProps {
	flex?: string,
}

const DashboardCardGroupContainer = styled(Box)<DashboardCardGroupContainerProps>(({flex}) => ({
	flex: flex ?? 'auto',
	height: "100%",
}));

const DashboardCardGroupTitle = styled('h1')(({theme}) => ({
	margin: "35px 0px 35px 3.3rem",
	width: 'auto',
	fontSize: '1.6rem',
	fontWeight: 500,
	color: theme.palette.dashboard?.contrastText,
	fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
}));

export {
	DashboardCardGroupContainer,
	DashboardCardGroupTitle,
}