import {Icon, KALink} from "../../../../lib/ui";
import {routesForUser} from "../../../../modules";
import React, {useState} from "react";
import {UserManager} from "../../../hooks/useUser";
import {ListItem} from "@mui/material";
import {Link} from "react-router-dom";
import {LogoSmall} from "./LogoSmall";
import {BarContainer} from "./style";

export type ButtonBarProps = {
	onRenderContextButtons: () => React.ReactNode
}
export const ButtonBar = (props: ButtonBarProps) => {
	const [state, setState] = useState({
		displayRoutes: false,
		displayContextualButtons: false
	});

	const user = UserManager.useContainer();
	const userRoutes = routesForUser(user);
	const allowedRoutes = Object.keys(userRoutes);

	const onToggleContextButtons = () => setState(s => ({...s, displayContextualButtons: !s.displayContextualButtons, displayRoutes: false}));
	const onToggleRoutes = () => setState(s => ({...s, displayContextualButtons: false, displayRoutes: !s.displayRoutes}))
	const verticalRouteContainerClass = state.displayContextualButtons ? 'vertical-context-menu' : "verticalRouteContainerHidden";
	const horizontalIconClass = state.displayRoutes ? "arrowIconExpanded" : "arrowIcon";
	const verticalIconClass = state.displayContextualButtons ? "arrowIconExpanded" : "arrowIcon";

	return(
		<BarContainer className={'masthead__global-orb'}>
			<div className={"logoContainer"}>
				<KALink to={{ page: 'dashboard' }}><LogoSmall /></KALink>
				<div className={"expandDownButton"} onClick={onToggleContextButtons} data-testid="expand-icon">
					<Icon forUI={'expand-down'} className={verticalIconClass} />
				</div>
				<div className={"expandRightButton"} onClick={onToggleRoutes}>
					<Icon forUI={'expand-right'} className={horizontalIconClass} />
				</div>
			</div>
			{/* Vertical scroll out */}
			<div className={["routeContainer", verticalRouteContainerClass].join(' ')}>
				<div>
					{props.onRenderContextButtons()}
				</div>
			</div>
			{/* Horizontal scroll out */}
			{allowedRoutes.length > 0 && state.displayRoutes &&
				<div className={["routeContainer", 'horizontal-action-menu'].join(' ')}>
					<div className={"routesScrollContainer"}>
						{allowedRoutes.map(routeName => <Link to={userRoutes[routeName]} className={"customLink"} key={`link_to_${routeName}`}>
							<ListItem key={`link_to_${routeName}`} className={"customLinkListItem"}>
								<div className={"customLinkListItemContents"}>
									<Icon forAction={routeName} className={"icon"} />
									<span className={"customLinkText"}>{routeName}</span>
								</div>
							</ListItem>
						</Link>)}
					</div>
				</div>
			}
		</BarContainer>
	);
}