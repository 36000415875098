import {isNunjucksDataSource, NunjucksDataSourceType} from "../../models/NunjucksDataSourceType";
import {ensureArgs} from "../filterUtilities";
import {isString} from "../../../guards/isString";
import {isArray} from "../../../guards/isArray";
import {compareExpressions} from "../../expressions/compareExpressions";
import {NunjucksFilterFactoryProps} from "../model";

export type FindRowsReturn = NunjucksDataSourceType & {
	invalid: boolean
}
function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args, 3)) {
		return {
			data: [],
			type: "columns",
			invalid: true
		} as FindRowsReturn
	}

	const base = isNunjucksDataSource(args[0]) ? args[0] : undefined;
	const columnName = isString(args[1]) ? args[1] : undefined;
	const columnValues = isArray(args[2]) ? args[2] : (isString(args[2]) ? [args[2]] : undefined);

	if (base === undefined || base.data === undefined || columnName === undefined || columnValues === undefined) {
		return {
			data: [],
			type: "columns",
			invalid: true
		} as FindRowsReturn
	}

	return findRows({base, columnName, columnValues});
}

type FindRowsProps = {
	base: NunjucksDataSourceType,
	columnName: string,
	columnValues: string | any[]
}

function cellValueMatches(cellValue: string, checkValue: string) {
	const check = compareExpressions({lhs:'__cell_value', rhs: checkValue, variables:{
		'__cell_value': cellValue
	}, options: {
		rhsQuoteDefault: true
	}});

	return check
}
function findRows({base, columnName, columnValues}: FindRowsProps): FindRowsReturn {
	const valuesToMatch = typeof columnValues === "string" ? [columnValues] : columnValues;
	const retval: FindRowsReturn = {
		type: base.type,
		data: [],
		invalid: false
	}

	const data = base.data;
	switch(base.type) {
		case 'columns-rows':
		{
			const matchinRowNames: string[] = [];
			retval.data = {};

			for (const rowName in data[columnName]) {
				if (valuesToMatch.some(value => cellValueMatches(data[columnName][rowName], value))) {
					matchinRowNames.push(rowName);
				}
			}

			for (const rowName of matchinRowNames) {
				for (const checkColumnName in data) {
					const cell_value = data[checkColumnName][rowName];
					if (retval.data[checkColumnName] === undefined) {
						retval.data[checkColumnName] = {};
					}

					retval.data[checkColumnName][rowName] = cell_value;
				}
			}
		}
			break;
		case 'columns':
			for (const row of data) {
				if (valuesToMatch.some(value => cellValueMatches(row[columnName], value))) {
					retval.data.push(row);
				}
			}
			break;
		case 'rows':
		/* XXX:TODO: Support this when we add support for row-lar data */
		// eslint-disable-next-line
		default:
			throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
	}

	return retval;
}
export function findRowsFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "find_rows",
		help: "Finds all rows that match at least one of the given values in one of its cells",
		example: 'var | find_rows("col", ["value", "value1", "value2"])',
		execute: execute
	})
}