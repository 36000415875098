import { TemplateElement } from "../../../../../../shared/interfaces/TemplateElement";
import {ElementMapper} from "./models";
const clone = require("rfdc/default");

const templateMapper: ElementMapper = (element: TemplateElement) => {
	if (element.contents.version === undefined) {
		const elementClone = clone(element);
		elementClone.contents.version = "HEAD";
		return elementClone;
	}

	return element;
}

export {templateMapper};