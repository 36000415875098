import { Box } from "@mui/system";
import { WorkflowChip } from "../../../../../shared/components/labels/WorkflowChip";
import { TreeListItem } from "../../../../../shared/components/list/tree/TreeListItem";

export interface TemplateListItemProps {
	template: KaiAlphaTemplate,
	selected: boolean,
	onSelect: (template: any) => void,
}

const TemplateListItem = ({template, selected, onSelect}: TemplateListItemProps) => {
	const onItemSelect = () => onSelect(template);
	return <TreeListItem
		title={template.name ?? "Untitled"}
		description={template.model_document ?? "None Specified"}
		onSelected={onItemSelect}
		badge={<Box margin={"0 0.5rem"}>
			<WorkflowChip size="small" state={template.state ?? "N/A"} label={`${(template.state?.length ?? 0) > 0 ? template.state : "N/A"}`}/>
		</Box>}
		depth={0}
	/>
}

export {TemplateListItem}