import {useAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";

// sends a message to a notification API
// requests that the template id provided is edited for the given requestMessage
function useCreateTemplateEditNotification() {
	const createTemplateEditNotification = async (id: string, request: string): Promise<void> => {
		const notification_parameters = {
			requestDetails: {request},
			type: 'template',
			id,
			event_type: 'TEMPLATE_EDIT_REQUEST',
		}
		await api.call('POST', `template/${id}/notification`, notification_parameters);
	}

	return useAsync<void>(createTemplateEditNotification, false);
}

export {useCreateTemplateEditNotification}