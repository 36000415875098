import {Action} from "../../../../../shared/interfaces/ActionRun";
import {DataSourceOptions} from "../../../../../shared/hooks/api/data/useGetDataSource";
import {DocumentState} from "../../useDocumentState";
import {getAllDataSources} from "../../api/useGetDataSources";
import {useAsync, useRunWhenValueChange} from "../../../../../shared/hooks";
import {useStatusBar} from "../../../../../shared/components";
import {Variable} from "../../../../../shared/interfaces/Variable";
import {getInTextTableApi} from "../../api/useGetIttTable";
import {InTextTableVariableValue} from "../../../../../shared/definitions/elements/inTextTable/model";
import {DataSource} from "../../../../../shared/interfaces/DataSource";
const clone = require("rfdc/default");

function useFetchAllDataSourcesActions(): Action {
	const documentManager = DocumentState.useContainer();
	const statusBar = useStatusBar();

	const loadInTextTable = async (inTextTable: Variable): Promise<Variable> => {
		const tableInfo = inTextTable.value as InTextTableVariableValue;
		const newData = await getInTextTableApi({tableName: tableInfo.tableName, studyId: tableInfo.studyId});
		return {...inTextTable, value: {...tableInfo, table: newData.table}};
	}
	const loadInTextTables = async (inTextTables: Variable[]) => {
		return Promise.all(inTextTables.map(loadInTextTable))
	}
	const loadAllDataSources = (dataSources: Variable[], inTextTables: Variable[]) => {
		return Promise.all([loadDataSources(dataSources), loadInTextTables(inTextTables)])
	}


	const fetchAllSources = useAsync(loadAllDataSources, false);

	// when load hook is called, update the variable information in document state accordingly
	useRunWhenValueChange(() => {
		const [dataSources, inTextTableData] = fetchAllSources.value!;
		const sources = dataSources.filter(source => source !== null);

		const hasData = (sources?.length ?? 0) > 0 || (inTextTableData?.length ?? 0) > 0;
		let documentVariables: Variable[] = [];
		if (hasData) {
			// had to use clone here force update render with fresh data
			documentVariables = clone(documentManager.variables);
		}
		if ((sources?.length ?? 0) > 0) {
			sources?.forEach(source => {
				const variable = documentVariables?.find(v =>
					v.id === source![0]!.id);
				if (variable) {
					variable.dataSources = source!;
				}
			});
		}

		if ((inTextTableData?.length ?? 0) > 0) {
			inTextTableData.forEach(inTextTable => {
				const variable = documentVariables?.find(v =>
					v.id === inTextTable.id);
				if (variable) {
					variable.value = inTextTable.value;
				}
			})
		}

		if (documentVariables.length > 0) {
			documentManager.setVariables(documentVariables);
		}
		statusBar.sendSuccessNotification({message: "Fetch all datasources success!"})
	}, fetchAllSources.value);

	useRunWhenValueChange(() => {
		if (fetchAllSources.error) {
			statusBar.sendErrorNotification({message: "Error fetching all datasources", detail: fetchAllSources.error});
		}
	}, fetchAllSources.error)

	const fetch = () => {
		const documentVariables = documentManager.variables;
		if ((documentVariables?.length ?? 0) > 0) {
			const datasources =
				documentVariables!.filter(
					variable => variable.type === "datasource");

			const intextTableVariables = documentVariables!.filter(variable => variable.type === "intexttable");
			fetchAllSources.execute(datasources, intextTableVariables);
		}
	}

	const loadDataSources = async (dataSources: Variable[]): Promise<(DataSource[] | null)[]> => {
		const sources = await getAllDataSources(dataSources.map(variable => (
			{...(variable.options as DataSourceOptions),
				name: variable.name,
				id: variable.id
			})));

		return sources.filter(source => source !== null) as DataSource[][]
	}


	return {
		run: fetch,
		isRunning: fetchAllSources.isLoading
	}
}

export {useFetchAllDataSourcesActions}