import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {useStatusBar} from "../../../../../shared/components";
import { useCreateTemplateEditNotification } from "../../api/useCreateTemplateEditNotification";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

type RunProps = {
	templateId: string,
	requestMessage: string
}
function useRequestEditAction(): TypedAction<RunProps> {
	const sendEditNotification = useCreateTemplateEditNotification();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if (sendEditNotification.status === 'success') {
			statusBar.sendSuccessNotification({message: "Edit request successfully sent."});
		}

	}, sendEditNotification.status);

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error sending edit request", detail: sendEditNotification.error});
	}, sendEditNotification.error)

	const run = ({templateId, requestMessage}: RunProps) => {
		statusBar.sendInfoNotification({message: "Sending edit request..."});
		sendEditNotification.execute(templateId, requestMessage);
	}

	return {run, isRunning: sendEditNotification.isLoading};
}

export {useRequestEditAction}