import {DataSourcePreview, PreviewProps} from "./model";

type BlobDataType = string

const Preview = (props: PreviewProps) => {
	const blobUrl = props.dataSource?.data as BlobDataType;
	return blobUrl === null ? null :
		<div style={{marginTop: "1rem"}}>
			<a download href={blobUrl}>Download {props.dataSource?.name ?? "data"}</a>
		</div>;
}

const BlobPreview: DataSourcePreview = {
	type: "blob",
	view: Preview
}
export {BlobPreview};