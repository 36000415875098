import {TabDefinition} from "../../../../../shared/components/layout/tabs/tabDefinition";
import {GroupedContent} from "../../../../../shared/components";
import {SubmissionState} from "../../../hooks";
import {SubmissionName} from "../../../components/tabs/properties/SubmissionName";

const TabView = () => {
	const {submission, setSubmission} = SubmissionState.useContainer();
	const onNameChange = (name: string) => setSubmission(s => ({...s, name}));
	return <GroupedContent title={"Submission Details"} expanded={true}>
		<SubmissionName name={submission.name} onNameChange={onNameChange} />
	</GroupedContent>
}

const PropertiesTab: TabDefinition = {
	name: "Properties",
	content: TabView
}

export {PropertiesTab};