import {Button, FlexBox} from "../../../../../shared/components"
import {SearchBar} from "../../../../../shared/components/input/search/SearchBar";
import {AddRounded} from "@mui/icons-material";

type ContentToolbarProps = {
	onUpload: () => void,
	onSearch: (searchText?: string) => void
}

const ContentToolbar = (props: ContentToolbarProps) => <FlexBox>
	<SearchBar placeholder={"Search"} onChange={props.onSearch} />
	<Button sx={{marginLeft: "1rem"}} text={"Add"} icon={<AddRounded />} onClick={props.onUpload} />
</FlexBox>

export {ContentToolbar}