import {NumberedHeaderBlue, ColumnBox, SubText} from './styles';
import {TextField} from "../../../../shared/components/input";
import {colors} from "../../../../theme";
import {ChangeEvent} from "react";
import {TextArea} from "../../../../shared/components/input/TextArea";
import {FormControl} from "@mui/material";
import SelectUsersList from '../selectuserslist/SelectUsersList';
import {UserGroupsNode, UserGroupUser} from "../../models";

type EditUserGroupProps = {
	userGroup:  UserGroupsNode;
	users: UserGroupUser[];
	onChange: (userGroupNode: UserGroupsNode) => void;
}

const EditUserGroup = (props: EditUserGroupProps) => {
	const users = props.users ?? [];
	const userGroupName = props.userGroup.groupName ?? "";
	const usersAddedToGroup = props.userGroup.users;
	const description = props.userGroup.description ?? "";

	const onDescriptionChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		props.onChange({...props.userGroup, description: e.target.value});
	}

	const onUserChange = (users: UserGroupUser[]) => {
		props.onChange({...props.userGroup,users: users});
	}

	return (
		<>
			<FormControl fullWidth>
				<ColumnBox>
					<NumberedHeaderBlue>
						<div>
							<span className="scl__ncb--header--number">{1}</span>
							<h2 className={"scl__ncb--header--title enabled"}>{'Details'}</h2>
						</div>
					</NumberedHeaderBlue>
					<div>
						<TextField InputProps={{style: {background: colors.paperWhite, borderColor: colors.fadedGrey, fontSize: '20px', height: '60px'}}}
							contentEditable={false}
							disabled={true}
							label={"User Group Name"}
							value={userGroupName}
							sx={{marginTop: "1rem"}}/>
						<SubText><span>User Group names can not be edited</span></SubText>
					</div>
					<div>
						<TextArea label={"Description"}
							value={description}
							onChange={onDescriptionChange}/>
					</div>
					<NumberedHeaderBlue>
						<div>
							<span className="scl__ncb--header--number">{2}</span>
							<h2 className={"scl__ncb--header--title enabled"}>{'Add Users'}</h2>
						</div>
					</NumberedHeaderBlue>
				</ColumnBox>
			</FormControl>
			<SelectUsersList leftItems={users} rightItems={usersAddedToGroup} onChange={onUserChange}/>

		</>
	)
}

export default EditUserGroup;