import { TabDefinition } from "../../../../shared/components/layout/tabs/tabDefinition";
import { TabbedPane } from "../../../../shared/components/layout/pane/TabbedPane";
// TODO DOCEDITOR: need a comments tab / variables tab, mocked by the template version.
import { VariablesTab } from "../tabs/variables";
import { CommentsTab } from "../tabs/comments";


const tabs: TabDefinition[] = [VariablesTab, CommentsTab];
const EditorRightPane = () => {
	return <TabbedPane orientation="right" tabs={tabs} />
}

export { EditorRightPane }
