import {TemplateElementDefinition} from "../../model";
import {MetaValueIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {
	metaValueDefinition as baseMetaValueDefinition
} from "../../../../../../shared/definitions/elements/meta/value/model";

const metaValueDefinition: TemplateElementDefinition = {
	...baseMetaValueDefinition,
	icon: MetaValueIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView
	},
} as const

export {metaValueDefinition};