import { Variable, VariableType } from "../../../../../shared/interfaces/Variable";
import { getTemplateApi } from "../../../hooks/api/useGetTemplate";
import { DocumentCollection } from "../../../models/documentCollection";
const uuid = require('uuid');

export interface ReconcileReturn {
	documentCollection: DocumentCollection,
	documentVariables: Variable[]
}

export const reconcileDocumentCollection = async (documentCollection: DocumentCollection, documentVariables: Variable[], topLevelTemplate: KaiAlphaTemplate) : Promise<ReconcileReturn> => {
	// scroll through documentCollection and compare the templates
	const updatedCollection: DocumentCollection = await Promise.all(documentCollection.map(async (document) => {
		let documentTemplate: KaiAlphaTemplate;

		if(document.topLevel) {
			documentTemplate = topLevelTemplate;
		} else {
			documentTemplate = await getTemplateApi(document.template!.id);
		}

		if(documentTemplate.version !== document.template!.version) {
			document.variables =
				Object.keys(documentTemplate.variables!).map(key => {
					const variable = documentTemplate.variables![key];
					const existingVariable = documentVariables.find(v => v.templateId === documentTemplate.id! && v.name === key);
					const namespace = document.topLevel ? undefined : `${(documentTemplate.name ?? "").replace(/\W/gmi, "")}.${key}`
					return ({...variable, type: variable.type as VariableType,
						name: key,
						namespace,
						templateId: documentTemplate.id,
						value: existingVariable?.value})
				}).reduce((variables, current) => {
					variables[current.name] = current;
					return variables;
				}, {});
			document.template = documentTemplate;
			document.templateUpdated = true;
		}

		return document;
	}));

	const upgradedVariables = updatedCollection.filter(document => document.variables !== undefined)
		.map(document => Object.values(document.variables!).map(value => ({id: uuid.v4(), ...value}) as Variable))
		.reduce((all, current) => all.concat(current), []);

	return({
		documentCollection: updatedCollection,
		documentVariables: upgradedVariables
	});
}