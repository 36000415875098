import {styled} from "@mui/material";

export interface StatusIndicatorProps {
	backgroundColor?: string
}
const StatusIndicator = styled("div")<StatusIndicatorProps>(({theme, backgroundColor}) => ({
	width: "10px",
	height: "10px",
	borderRadius: "50%",
	marginRight: "10px",
	padding: "0 !important",
	backgroundColor: backgroundColor ?? "white"
}));

export {StatusIndicator}