import {ElementMappers} from "./models";
import {variableMapper} from "./variable";
import {templateMapper} from "./template";

const elementMapper: ElementMappers = {
	variable: variableMapper,
	template: templateMapper,
	default: element => element
};

export {elementMapper};