import {DataSourceMapType} from "../components/elements/variable/views/editor/types/models";
import {TableDataType} from "../components/list/table/Table";
import {TableCellType} from "../components/list/table/TableRowCells";
const uuid = require("uuid");

export type DataDefinition = {
	tableData?: {id: number}
} & {[k: string]: string | number | (string | number)[]}

export type DatasourceType = {
	data?: DataDefinition[] | DataDefinition,
	"@metadata": any,
	type: DataSourceMapType
}

type TypedData = {
	columns: any[],
	rows: any[]
}

function getDataFromDataSourceType(datasource: DatasourceType | null, dataType?: DataSourceMapType): TableDataType {
	let tableData: TypedData = {columns: [], rows: []};

	switch(dataType) {
		case "columns": {
			const data = datasource?.data as (DataDefinition[] | undefined);
			tableData = {
				columns: (datasource?.["@metadata"]?.ordered_headers ?? [...(new Set(data?.map(Object.keys)?.reduce((all, current) => all.concat(current))) ?? [])])
					.map(column => ({field: column, displayText: column})),
				rows: data?.map(datum => {
					const {tableData, ...cell} = datum;
					return {...cell, id: uuid.v4()};
				}) ?? []
			};
		}break;
		case "columns-rows": {
			const data = datasource?.data as (DataDefinition | undefined);

			if (data === undefined) {
				break;
			}

			const columns = (datasource?.["@metadata"]?.ordered_headers ?? (data ? Object.keys(data!) : []))
				.map(field => ({field, displayText: field === "__row" ? "" : field}));

			if(data.__row) {
				columns.splice(0, 0, {field: "__row", displayText: ""});
			}

			if (!Array.isArray(data![columns[0].field])) {
				break
			}
			const firstRow = data![columns[0].field] as (string | number)[];
			const rows: TableCellType[] = [];
			for(let r = 0; r < firstRow.length; r++) {
				for(let c = 0; c < columns.length; c++) {
					const rowData = data![columns[c].field] as (string | number)[];
					if(rows[r] === undefined) {
						rows[r] = {[columns[c].field]: rowData[r], id: uuid.v4()};
					} else {
						rows[r] = {...rows[r], [columns[c].field]: rowData[r]};
					}
				}
			}

			tableData = {
				columns,
				rows
			}
		} break;
		default:
			tableData = {columns: [], rows: []};
			break;
	}

	return {...tableData, rows: tableData.rows.filter(row => {
		const {id, ...rest} = row;
		return Object.keys(rest).map(key => row[key]?.toString()?.trim() ?? "").join("").length > 0;
	})}

}

export {getDataFromDataSourceType}