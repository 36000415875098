import { useStatusBar } from "../../../../../shared/components";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import { usePublishDocument } from "../../api/usePublishDocument";
import { DocumentState } from "../../useDocumentState";
import {Action} from "../../../../../shared/interfaces/ActionRun";
import {EntityMetaData} from "../../../../../shared/interfaces/EntityMetaData";
import {Variable} from "../../../../../shared/interfaces/Variable";
import {calculateDataSourceValue} from "../../../../../shared/utilities/variableAdapter";
import {resetNunjucksEnvironment} from "../../../../../shared/nunjucks/models/NunjucksEnvironmentOptions";
import {isApproved} from "../../../../../shared/interfaces/WorkflowState";
import {isSystemVariable} from "../../../../../shared/variables/SystemNamespaces";

export interface Subdocuments {
	[elementId: string]: {document_id: string[]}
}
export interface PublishCollection {
	documentId: string,
	documentVersion: string,
	documentName?: string,
	lastupdated?: number,
	variables?: KaiAlphaVariables,
	metaData?: EntityMetaData,
	permissions?: KaiAlphaDocumentPermissions,
	templateVersion?: string
	subdocuments?: Subdocuments
	deletedSubdocuments?: Subdocuments
}

function usePublishDocumentAction(): Action {
	const documentManager = DocumentState.useContainer();
	const publisher = usePublishDocument();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {

		resetNunjucksEnvironment();
		const documentCollection = documentManager.documentCollection.map(collection => {
			const newVersion = publisher.value!.find(publishedDocument => publishedDocument.id === collection.id);
			return {...collection, version: newVersion?.version ?? collection.version, lastupdated: newVersion?.lastupdated};
		});
		documentManager.setDocumentCollection(documentCollection);
		const topLevelDocumentVersion = documentCollection.find(collection => collection.topLevel);
		const actualDocument = documentManager.document;
		if(actualDocument !== null && documentManager.buffer !== null) {
			actualDocument.version = topLevelDocumentVersion?.version ?? actualDocument.version;
			actualDocument.lastupdated = topLevelDocumentVersion?.lastupdated ?? actualDocument.lastupdated;

			const state = isApproved(actualDocument.state) ? "in process" : actualDocument.state;
			documentManager.setDocument(d => ({...d, ...actualDocument, state}));
			documentManager.setBuffer({...documentManager.buffer, version: actualDocument.version, state});
		}

		statusBar.sendSuccessNotification({message: "Content Published"});
	}, publisher.value);

	useRunWhenValueChange(() => {
		resetNunjucksEnvironment();
		statusBar.sendErrorNotification({message: `Error publishing content ${publisher.error}`});
	}, publisher.error)

	const publishDocument = () => {
		const publishCollection: PublishCollection[] = [];

		const documentCollection = documentManager.documentCollection;

		documentCollection.forEach((collection) => {
			const filteredVariables: Variable[] = documentManager.variables.filter((value) => value.templateId === collection.template!.id);
			if(filteredVariables.length > 0) {
				const documentVariables = collection.variables;
				if(documentVariables !== undefined) {
					// determine if a change has occured
					let saveDocument = false;
					for(let i =0; i < filteredVariables.length; i++) {
						const documentValue = documentVariables[filteredVariables[i].namespace ?? filteredVariables[i].name];
						if((documentValue !== undefined || filteredVariables[i].type === "intexttable" || ["system", "datasource"].includes(filteredVariables[i].type)) && (documentValue?.value !== filteredVariables[i].value)) {
							// change has occurred so lets save it
							saveDocument = true;
							break;
						}
					}

					if(saveDocument) {
						const objectVariables = new Map<string, any>();
						filteredVariables.forEach((variable) => {
							objectVariables.set(isSystemVariable(variable) ? variable.namespace! : variable.name, (variable.value === undefined && variable.dataSources !== undefined ? calculateDataSourceValue(variable.dataSources) : variable.value));
						});
						publishCollection.push({
							documentId: collection.id,
							documentVersion: collection!.version!,
							documentName: documentManager.buffer?.name,
							variables: Object.fromEntries(objectVariables),
							metaData: documentManager.buffer?.metadata as EntityMetaData,
							permissions: documentManager.buffer?.permissions,
							templateVersion: collection.templateUpdated ? collection.template?.version : undefined,
							lastupdated: collection.lastupdated,
							subdocuments: collection.subDocuments,
							// TODO use this field when covering situation when
							// a subtemplate is deleted and a
							// template upgrade is to be done
							// deletedSubdocuments: collection.subdocumentsDeleted
						})
					}
				}


			}
		})

		// always save at the top level
		const topLevel = documentManager.documentCollection.find((value) =>
			value.template?.id === documentManager.template?.id);

		const topLevelFilteredVariables: Variable[] = documentManager.variables.filter((value) => value.templateId === documentManager!.template!.id || value.type === "system");

		const topLevelObjectVariables = topLevelFilteredVariables.reduce((variableLookup, variable) => {
			variableLookup[isSystemVariable(variable) ? variable.namespace! : variable.name] = variable.value === undefined && variable.dataSources !== undefined ? calculateDataSourceValue(variable.dataSources) : variable.value;
			return variableLookup
		}, {});

		if(topLevel !== undefined && !publishCollection.find(c => c.documentId === topLevel.id)) {
			publishCollection.push({
				documentId: documentManager.document!.id,
				documentVersion: topLevel.version!,
				documentName: documentManager.buffer?.name,
				variables: topLevelObjectVariables,
				metaData: documentManager.buffer?.metadata,
				permissions: documentManager.buffer?.permissions,
				templateVersion: topLevel.templateUpdated ? topLevel.template?.version : undefined,
				lastupdated: topLevel.lastupdated,
				subdocuments: topLevel.subDocuments,
				// TODO use this field when covering situation when
				// a subtemplate is deleted and a
				// template upgrade is to be done
				// deletedSubdocuments: topLevel.subdocumentsDeleted
			})
		}

		publisher.execute(publishCollection);
	}

	return {run: publishDocument, isRunning: publisher.isLoading};
}

export {usePublishDocumentAction}