import {useStatusBar} from "../../../../../../shared/components";
import {useUserList} from "../../../../../../shared/hooks/useUserList";
import {DocumentState} from '../../../../hooks/useDocumentState';
import {useRunWhenValueChange, useRunOnce} from "../../../../../../shared/hooks";
import {useSetPermissionsAction} from "../../../../hooks/actions";
import { ContributorsGroup } from "../../../../../../shared/components/layout/groups/contributors";

/**
 * This component displays the contributors associated with the document. It also allows you to edit using the ACL Editor.
 * @constructor
 */
const Contributors = () => {
	const documentManager = DocumentState.useContainer();
	const statusBar = useStatusBar();
	const userList = useUserList();
	const setPermissionsAction = useSetPermissionsAction();
	useRunOnce(() => userList.execute(['name', 'display_name']));

	const updatePermissions = (permissions: KaiAlphaDocumentPermissions) => {
		documentManager.setPermissions(permissions);
		setPermissionsAction.run({documentId: documentManager.document!.id, permissions, lastupdated: documentManager.document?.lastupdated});
	}

	useRunWhenValueChange(async () => {
		if (userList.status === "error") {
			statusBar.sendErrorNotification({message: "Failed to get contributor list.", detail: userList.error});
		}
	}, userList.status);

	return <ContributorsGroup
		permissions={documentManager.buffer?.permissions}
		userList={userList.status === "success" ? (userList.value ?? undefined) : undefined}
		onUpdatePermissions={updatePermissions}
		userRoles={documentManager.computedUserRoles ?? undefined}
		isLoading={setPermissionsAction.isRunning || userList.isLoading}
		contentState={documentManager?.document?.state}
	/>
}

export {Contributors}
