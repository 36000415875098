import {useRunWhenValueChange} from "../../../../shared/hooks";
import {TaskNode} from "../../../../shared/interfaces/task";
import {moduleLinkGenerator} from "../../../moduleNavigation";
import {useNavigate} from "react-router-dom";
import {
	useGetAllDocuments
} from "../../hooks/api/useGetAllDocuments";
import {useState} from "react";
import {ContentNode} from "../../models/content";
import {usePostFileToS3} from "../../../../shared/hooks/api/s3/usePostFileToS3";
import {useStatusBar} from "../../../../shared/components";
import lib_document from "../../../../api/document";
import {useDeleteDocument} from "../../hooks/api/useDeleteDocument";
import {SearchApiProps, SearchFilter, SearchSort} from "../../../../shared/hooks/api/searchApi";

type State = Omit<SearchApiProps, "fields" | "searchType"> & {
	content: ContentNode[],
	page: number
}
function useList() {
	const getAllDocuments = useGetAllDocuments();
	const navigate = useNavigate();
	const fileUploader = usePostFileToS3('temporary_upload');
	const statusBar = useStatusBar();
	const deleteDocument = useDeleteDocument();

	const [state, setState] = useState<State>({sort: undefined, page: 0, pageSize: 25, content: []})
	useRunWhenValueChange(() => loadDocuments(), [state.filters, state.sort, state.pageSize, state.after]);
	useRunWhenValueChange(() => {
		const contentNodes = (getAllDocuments.value?.results ?? []).map(result => ({...result, "@date": result.metadata?.system === undefined ? "" : result.metadata.system["@date"]}) as ContentNode);
		const content = state.page === 0 ? (contentNodes ?? []) : [...state.content].concat(contentNodes ?? []);
		setState(s => ({...s, content}));

	}, getAllDocuments.value);

	useRunWhenValueChange(() => {
		if (deleteDocument.value) {
			loadDocuments();
		}
	}, deleteDocument.value);

	useRunWhenValueChange(async () => {
		if (fileUploader.value) {
			const key = fileUploader.value.key.split('/').splice(-1)[0];
			try {
				const conversionResponse = await lib_document.convertUploadedDocToDocument(key);
				if (!conversionResponse) {
					return;
				}
				statusBar.sendSuccessNotification({message: "Document imported"});
				navigate(moduleLinkGenerator("content", "edit", conversionResponse.id));
			} catch(conversionErorr) {
				statusBar.sendErrorNotification({message: "Failed to import document", detail: conversionErorr as Error});
			}
		}

	}, fileUploader.value);

	useRunWhenValueChange(() => {
		if (fileUploader.error) {
			statusBar.sendErrorNotification({message: "Failed to upload document", detail: fileUploader.error});
		}
	}, fileUploader.error);

	const loadDocuments = () => {
		getAllDocuments.execute({filters: state.filters, sort: state.sort, pageSize: state.pageSize, after:state.after});
	}

	const deleteContent = (contentNode: ContentNode) => {
		deleteDocument.execute(contentNode.id);
	}

	const onUploadFile = (file) => {
		statusBar.sendInfoNotification({message: "Starting document import"});
		fileUploader.execute({file, type: "file"});
	}

	const createNewDocument = () => {
		const url = moduleLinkGenerator("content", "create");
		navigate(url);
	}


	const onNavigateToTask = (task: TaskNode) => {
		const taskUrl = moduleLinkGenerator(task.source.type, "edit", task.source.id);
		navigate(taskUrl);
	}

	const setFilter = (filter: SearchFilter): boolean => {
		const existingFilter = state.filters?.find(f => f.field === filter.field);
		if (existingFilter?.value === filter.value) {
			return false;
		}

		const filters = [...(state.filters ?? [])].filter(f => f.field !== filter.field);
		if (filter.value.toString().length > 0) {
			filters.push(filter);
		}

		setState(s => ({...s, filters, page: 0, after: undefined}));
		return true;
	}

	const setSort = (sort?: SearchSort) => {
		setState(s => ({...s, sort, after: undefined, page: 0}));
	}

	const setPageSize = (pageSize: number) => {
		setState(s => ({...s, pageSize, after: undefined, page: 0}));
	}

	const setAfter = (after?: string[] | number[]) => setState(s => ({...s, after, page: s.page + 1}));

	return {
		loadDocuments,
		createNewDocument,
		deleteContent,
		isRunning: getAllDocuments.isLoading || deleteDocument.isLoading,
		onNavigateToTask,
		content: state.content,
		setFilter,
		setSort,
		setPageSize,
		setAfter,
		onUploadFile,
		sort: state.sort,
		filters: state.filters,
		pageSize: state.pageSize ?? 10,
		total: getAllDocuments.value?.total ?? 0,
		after: getAllDocuments.value?.next ?? undefined
	}
}

export {useList}