import './style.scss'
import { PreviewElementProps } from "../model";
import { TitleElementValue } from '../../../../../shared/definitions/elements/title/model';
import { CommentState } from '../../../hooks/useCommentState';
import { HighlightWrapper } from '../../../../../shared/components/input/highlight/HighlightWrapper';
import { CommentModalProps } from '../../../../../shared/definitions/highlight/CommentModal.props';
import CommentModal from '../../modals/documentComments/CommentModal';
import {isInReview} from "../../../../../shared/interfaces/WorkflowState";

const PreviewView = (props: PreviewElementProps) => {
	const data = props.element.data as TitleElementValue;

	const commentsManager = CommentState.useContainer();
	const highlightComments = commentsManager.highlightedCommentsByElementId(props.element.id);

	return <HighlightWrapper
		enabled={isInReview(props.state)}
		elementId={props.element.id}
		highlightComments={highlightComments}
		value={data.title}
		componentToWrap={(highlightedText) => <div className={"cm-title-preview"} dangerouslySetInnerHTML={{ __html: highlightedText }}/>}
		renderCommentModal={ (values: CommentModalProps) =>
			<CommentModal
				onClose={values.onClose}
				addEditComment={"Add"}
				showOpen={values.showOpen}
				elementId={values.elementId}
				selectedText={values.selectedText}
				textProperties={values.textProperties}
				documentId={commentsManager.documentId}
			/>
		}
	/>
}

export default PreviewView;