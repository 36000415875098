import {createContainer} from "unstated-next";
import {useState} from "react";
import {Submission} from "../models/Submission";
import {EctdModule} from "../../../shared/interfaces/Module";
import {recursivelyFindModule} from "../utilities/recursivelyFindModule";
import {
	InternalContent,
	isExternalContent,
	isInternalContent,
	SubmissionContent
} from "../../../shared/interfaces/SubmissionContent";
import {recursivelyMap} from "../utilities/recursivelyMap";

const clone = require("rfdc/default");

const newSubmission: Submission = {
	name: "",
	compound: undefined,
	indications: undefined,
	regulator: [],
	ectdModules: [],
	id: "",
	extraction_status: "Idle",
	extract_location: ""
}

type SubmissionStateType = {
	submission: Submission,
	selectedModule?: EctdModule,
	needsSaving: boolean,
	didReset: boolean
}

type SubmissionStateMutator = (submission: Submission) => Submission;
function useSubmissionState() {
	const [state, setState] = useState<SubmissionStateType>({submission: newSubmission, needsSaving: false, didReset: false})
	const setSubmission = (submission: Submission | SubmissionStateMutator) => {
		if (typeof submission === "function") {
			const submissionMutator = submission as SubmissionStateMutator;
			setState(s => ({...s, submission: submissionMutator(state.submission), didReset: false}));
		} else {
			setState(s => ({...s, submission, didReset: false}));
		}
	}

	const setSelectedModule = (module?: EctdModule) => {
		if (module === undefined) {
			setState(s => ({...s, selectedModule: module}));
		} else {
			const activeModule = recursivelyFindModule(state.submission.ectdModules, m => m.moduleNumber === module.moduleNumber);
			setState(s => ({...s, selectedModule: activeModule}));
		}
	}
	const addContentsToModule = (contents: SubmissionContent[], module?: EctdModule) => {
		const activeModule = module ?? state.selectedModule;
		if (!activeModule) {
			return;
		}

		const modules = clone(state.submission.ectdModules);
		const matchingModule = recursivelyFindModule(modules, m => m.moduleNumber === activeModule.moduleNumber);

		if (matchingModule === undefined) {
			return;
		}

		matchingModule.content = [...(matchingModule.content ?? []), ...contents]
		setState(s => ({...s, submission: ({...s.submission, ectdModules: modules}), selectedModule: matchingModule, needsSaving: true}));

	}

	const replaceModuleContents = (contents: SubmissionContent[], module?: EctdModule, lastupdated?: number) => {
		const activeModule = module ?? state.selectedModule;
		if (!activeModule) {
			return;
		}

		const modules = clone(state.submission.ectdModules);
		const matchingModule = recursivelyFindModule(modules, m => m.moduleNumber === activeModule.moduleNumber);

		if (matchingModule === undefined) {
			return;
		}

		matchingModule.content = [...contents];
		setState(s => ({...s, submission: ({...s.submission, ectdModules: modules, lastupdated: (lastupdated ?? s.submission.lastupdated)}), selectedModule: matchingModule, needsSaving: true}));

	}

	const findModule = (moduleNumber: string): EctdModule | undefined => {
		const modules = clone(state.submission.ectdModules);
		return recursivelyFindModule(modules, m => m.moduleNumber === moduleNumber);
	}

	const contentForSelectedModule = (): {content: SubmissionContent[], moduleNumber: string}[] => {
		if (state.selectedModule === undefined) {
			return [];
		}

		const contents = recursivelyMap([state.selectedModule], m => ({content: m.content ?? [], moduleNumber: m.moduleNumber}));
		return contents.reduce((flatMap, current) => flatMap.concat(current), [] as {content: SubmissionContent[], moduleNumber: string}[]);
	}

	const updateContentVersion = (entity: SubmissionContent, module?: EctdModule) => {
		const content = entity as InternalContent;
		const activeModule = module ?? state.selectedModule;
		if (!activeModule) {
			return;
		}

		const modules = clone(state.submission.ectdModules);
		const matchingModule = recursivelyFindModule(modules, m => m.moduleNumber === activeModule.moduleNumber);

		if (matchingModule === undefined) {
			return;
		}

		matchingModule.content = [...(matchingModule.content?.filter(c => c.internal && (c as InternalContent).id !== content.id) ?? []), content]
		setState(s => ({...s, submission: ({...s.submission, ectdModules: modules}), selectedModule: matchingModule, needsSaving: true}));
	}

	const onRemoveContent = (props: SubmissionContent & {moduleNumber: string}) => {
		const modules = clone(state.submission.ectdModules);
		const matchingModule = recursivelyFindModule(modules, m => m.moduleNumber === props.moduleNumber);
		if (matchingModule === undefined) {
			return;
		}

		matchingModule.content = [...(matchingModule.content ?? []).filter(content => {
			if (isInternalContent(content) && isInternalContent(props)) {
				return content.id !== props.id && content.version !== props.version;
			} else if (isExternalContent(content) && isExternalContent(props)) {
				return content.location !== props.location;
			}
			return true;
		})];

		setState(s => ({...s, submission: {...s.submission, ectdModules: modules}, selectedModule: matchingModule.moduleNumber === s.selectedModule?.moduleNumber ? matchingModule : s.selectedModule, needsSaving: true}))
	}
	const reset = () => setState(s => ({...s, submission: {...newSubmission}, didReset: true}));
	const resetNeedsSaving = () => setState(s => ({...s, needsSaving: false}))
	return {
		setSubmission,
		submission: state.submission,
		selectedModule: state.selectedModule,
		addContentsToModule,
		replaceModuleContents,
		setSelectedModule,
		findModule,
		contentForSelectedModule,
		updateContentVersion,
		onRemoveContent,
		reset,
		didReset: state.didReset,
		needsSaving: state.needsSaving,
		resetNeedsSaving: resetNeedsSaving
	}
}

const SubmissionState = createContainer(useSubmissionState);
export {SubmissionState}