import { DocumentState } from "../../../../hooks/useDocumentState";
import {MetaDataChangeEvent, MetadataGroup} from "../../../../../../shared/components/layout/groups/metadata";
import {useState} from "react";
import {MetaDataInput} from "./MetaDataInput";
import {displayTextForMetaData} from "./typedValueEditors";
import {RenderEditorEvent} from "../../../../../../shared/components/layout/groups/metadata/MetadataList";

/**
 * This component displays the *user* metadata of all templates within the document (template.metadata.user).
 * @constructor
 */
const Metadata = () => {
	const [addMetaData, setAddMetaData] = useState(false);
	const documentManager = DocumentState.useContainer();

	const onCreateMetaData = () => setAddMetaData(true);
	const onCancelMetaData = () => setAddMetaData(false);
	const onSaveMetaData = (event: MetaDataChangeEvent) => {
		const { [(event.previousMetadata?.key ?? event.metadata.key)]: _, ...items } = documentManager.buffer?.metadata?.user ?? {};
		setUserMetadata({
			...items,
			[event.metadata.key.replace(/\W/gmi, "")]: event.metadata.value,
		})
		setAddMetaData(false)
	}

	const onUpdateMetaDataFactory = (event: RenderEditorEvent) => (changeEvent: MetaDataChangeEvent) => {
		onSaveMetaData(changeEvent);
		event.onCancel();
	}

	const onDeleteMetaData = (key: string) => {
		const { [key]: _, ...items } = documentManager.buffer!.metadata!.user ?? {};
		setUserMetadata(items);
	}

	const setUserMetadata = (metadata: {[k: string] : string}) => {
		documentManager.setBuffer(s => ({
			...s,
			metadata: {
				user: metadata,
				system: s.metadata?.system ?? {},
			}
		}));
	}

	return <>
		<MetadataGroup
			metadata={documentManager.buffer?.metadata?.user}
			editable={true}
			renderTopView={() => addMetaData ? <MetaDataInput onCancel={onCancelMetaData} onSave={onSaveMetaData} /> : undefined}
			renderEditorForMetadata={(event: RenderEditorEvent) => <MetaDataInput metaData={event.metadata} onCancel={event.onCancel} onSave={onUpdateMetaDataFactory(event)} /> }
			renderValue={displayTextForMetaData}
			onNewMetadata={onCreateMetaData}
			emptyMessage={"No metadata in this content"}
			onDeleteMetadata={onDeleteMetaData}
		/>
	</>
}

export { Metadata }