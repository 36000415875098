import {styled} from '@mui/material';
import { FlexBox } from '../../../../../../../shared/components';

const SelectedElementContainer = styled(FlexBox)(({theme}) =>({
	boxSizing: "border-box",
	padding: "5px 10px",
	margin: "25px 0px 0px",
	fontSize: "1.1rem",
	backgroundColor: theme.palette.tagsChip?.main,
}));

export {SelectedElementContainer};