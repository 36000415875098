import {useSaveBuffer} from "../../api/useSaveBuffer";
import { DocumentState } from "../../useDocumentState";
import { Action } from "../../../../../shared/interfaces/ActionRun";
import { useAsync, useRunWhenValueChange } from "../../../../../shared/hooks";
import { useStatusBar } from "../../../../../shared/components";
import {VariableAdapter} from "../../../../../shared/utilities/variableAdapter";
import {
	resetNunjucksEnvironment
} from "../../../../../shared/nunjucks/models/NunjucksEnvironmentOptions";
const clone = require("rfdc/default");

function useSaveBufferAction(): Action {
	const documentManager = DocumentState.useContainer();
	const saveBuffer = useSaveBuffer();
	const statusBar = useStatusBar();

	const saveDocument = () => {
		const document = clone(documentManager.buffer);
		if (!document) {
			throw new Error("document is null");
		}
		document.variables = VariableAdapter.toTree(documentManager.variables);
		return saveBuffer.execute({buffer: document, documentId: documentManager.document!.id, documentVersion: documentManager.document!.version});
	}

	const actionRunner = useAsync(saveDocument, false);

	useRunWhenValueChange(() => {
		resetNunjucksEnvironment();
		documentManager.setBuffer(buffer => ({...buffer, version: actionRunner!.value!.version}));
		statusBar.sendSuccessNotification({message: "Document saved"});
	}, actionRunner.value);

	// show save buffer error if one exists
	useRunWhenValueChange(() => {
		resetNunjucksEnvironment();
		statusBar.sendErrorNotification({message: "Error saving document", detail: saveBuffer.error});
	}, saveBuffer.error);


	const run = () => actionRunner.execute();
	return {run, isRunning: actionRunner.isLoading};
}

export {useSaveBufferAction};