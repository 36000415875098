import {csvMapperForType} from "./csvMappers";
import {CsvParserOptions} from "./csvMappers/model";
import {DataSourceMapType} from "../../interfaces/DataSource";

export type CsvOptions = {
	type: DataSourceMapType
}

function isCsvOptions(obj: unknown): obj is CsvOptions {
	return obj !== undefined && (obj as CsvOptions).type !== undefined;
}
export function parseCsv(data: string | Buffer, options?: unknown) {
	const parserOptions: CsvParserOptions = {
		trim: true,
		skip_empty_lines: true,
		relax_column_count: true,
		columns: false
	};

	const csvOptions: CsvOptions = options === undefined || !isCsvOptions(options) ? {type: "auto"} : options as CsvOptions

	const mapper = csvMapperForType(csvOptions.type);
	if (mapper === undefined) {
		throw new Error(`${csvOptions.type} is currently not supported`);
	}

	return mapper.parse(data, parserOptions);
}