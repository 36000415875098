import {AsyncComponent, FlexBox} from "../../../../shared/components";
import Masthead from "../../../../shared/components/navigation/masthead";
import {ContextButtonFactory, DefaultContextButtons} from "../../../../shared/components/buttons/theme";
import {moduleLinkGenerator} from "../../../moduleNavigation";
import {ModuleLayout} from "../../../../shared/components/layout/ModuleLayout";
import {LeftPane} from "./layout/left";
import {useEditor} from "./useEditor";
import {CenterPane} from "./layout/center";
import {EctdModule} from "../../../../shared/interfaces/Module";
import {RightPane} from "./layout/right";

const Editor = () => {
	const editor = useEditor();
	const breadCrumbs = [
		{title: "Submissions", url: moduleLinkGenerator("submission", "list")},
		{title: editor.submission.name}
	]
	const onContextButtonClick = (key: string) => {
		switch (key) {
			case DefaultContextButtons.Publish.key:
				editor.extract();
				break;
			case DefaultContextButtons.Save.key:
				editor.save();
				break;
		}
	}

	const onSelectModule = (module: EctdModule) => editor.selectModule(module);
	const onSelectSection = (section: EctdModule) => editor.selectSection(section);

	return <AsyncComponent
		loadingMessage={"Loading eCTD Submission"}
		isLoading={editor.isLoading}
		component={<FlexBox direction={"column"} height={"100%"}>
			<Masthead
				contextButtons={ContextButtonFactory(
					[
						DefaultContextButtons.Save,
						{...DefaultContextButtons.Publish, label: "Extract"}
					],
					onContextButtonClick)}
				breadcrumbs={breadCrumbs}
			/>
			<ModuleLayout
				center={<CenterPane onSelectSection={onSelectSection} onSelectModule={onSelectModule} modules={editor.submission.ectdModules} selectedModule={editor.selectedModule ?? editor.submission.ectdModules[0]} selectedSection={editor.selectedSection}  />}
				left={<LeftPane onSelectModule={onSelectModule} onSelectSection={onSelectSection} modules={editor.submission.ectdModules} selectedSection={editor.selectedSection} activeModule={editor.selectedModule ?? editor.submission.ectdModules[0]} />}
				right={<RightPane />}
			/>
		</FlexBox>}/>
}

export {Editor}