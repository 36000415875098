import {Route} from "react-router-dom"
import {ModuleComponentProps, ModuleDefinition} from "../"
import { ModuleRoutes } from "../../shared/routing"
import { List } from "./pages/list/List"
import {CreatePage} from "./pages/create/Create";
import {pageActionLookup} from "../moduleNavigation";
import { SubmissionState } from "./hooks";
import {TocBuilder} from "./pages/tocBuilder/TocBuilder";
import {Editor} from "./pages/editor/Editor";
import {ApplicationAdminRoleLookup} from "../../shared/interfaces/ApplicationAdminRoles";

const SubmissionRouter = (props: ModuleComponentProps) => {
	return <SubmissionState.Provider>
		<ModuleRoutes>
			<Route path={"/"} element={<List />} />
			{props.user.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_eCTD) && <Route path={pageActionLookup.create} element={<CreatePage />} />}
			<Route path={`${pageActionLookup.create}/toc`} element={<TocBuilder />} />
			<Route path={`${pageActionLookup.edit}/:contentId`} element={<Editor />} />
			<Route path={`${pageActionLookup.edit}/:contentId/:versionId`} element={<Editor />} />
		</ModuleRoutes>
	</SubmissionState.Provider>


}

const SubmissionModule: ModuleDefinition = {
	path: "/activity/submissions/*",
	basePath: "/activity/submissions",
	component: SubmissionRouter,
	name: "Submissions",
	requriedGroups: [ApplicationAdminRoleLookup.administer_eCTD]
}

export type SubmissionName = "submission";

export {SubmissionModule};