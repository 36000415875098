import { FlexBox } from "../../../../../../shared/components";
import {ModuleColumn, ModuleColumnHeaderViewProps, ModuleColumnCellViewProps} from "../models";
import {NumberLabel} from "../../../text/labels/numberLabel";


const Header = (props: ModuleColumnHeaderViewProps) => {
	return <span>Section Title</span>
}

const Cell = (props: ModuleColumnCellViewProps) => {
	const onClick = () => {
		if (props.onAction) {
			props.onAction("click", props.column);
		}
	}

	return <FlexBox sx={{paddingLeft: `${(props.depth ?? 0) * 3.75}rem`}} onClick={onClick}>
		<NumberLabel depth={props.depth ?? 0}>{props.row.moduleNumber}</NumberLabel>
		<span>{props.row.moduleTitle}</span>
	</FlexBox>
}
const NameColumn: ModuleColumn = {
	field: "moduleTitle",
	width: "100%",
	views: {
		header: Header,
		cell: Cell
	}
}

export {NameColumn}