import {ArrowForwardIos} from "@mui/icons-material";
import {styled} from "@mui/material/styles";

interface RotatableForwardArrowProps {
	rotated: boolean
}
const RotatableForwardArrow = styled(ArrowForwardIos, {shouldForwardProp: (prop) => prop !== "rotated"})<RotatableForwardArrowProps>((props) => ({
	transform: props.rotated ? 'rotate(90deg)' : '',
	transition: 'transform 281ms ease',
	marginRight: ".5rem",
	color: props.theme.palette.plain?.contrastText
}));

const ToggleChildrenButton = ({expanded, onToggle}) => <RotatableForwardArrow rotated={expanded} style={{fontSize: ".75rem"}} onClick={onToggle} />


export {ToggleChildrenButton};
