import {PreviewElementProps} from "../../../model";
import {useLoadNestedElementsAction} from "../../../../../hooks/actions/load/useLoadNestedElementsAction";
import {useRunWhenValueChange, useScroll} from "../../../../../../../shared/hooks";
import {useState} from "react";
import "./style.scss";
import {LoadingView} from "../../../LoadingView";
import {TocItem} from "./TocItem";
import {sectionDefinition} from "../../../../../../../shared/definitions/elements/section/model";


const PreviewView = (props: PreviewElementProps) => {
	const [sections, setSections] = useState<PreviewElementProps[]>([]);
	const getSections = useLoadNestedElementsAction(sectionDefinition.type);
	const scrollManager = useScroll();

	useRunWhenValueChange(() => {
		if (props.body) {
			getSections.run(props.body);
		}
	}, props.body);

	useRunWhenValueChange(() => {
		if (getSections.value) {
			setSections([...getSections.value]);
		}
	}, getSections.value);

	const onSectionClick = (sectionId: string) => scrollManager.scrollById(sectionId);


	return getSections.isRunning ? <LoadingView message={"Loading table of contents..."} /> :
		sections.length > 0 ? <ul className={"tm__toc-preview"}>
			{sections.map(section => <TocItem {...section} onClick={onSectionClick} />)}
		</ul> : null;

}

export {PreviewView};