import {useEffect, useLayoutEffect, useRef} from "react";

function useInterval(callback: () => void, delay: number | null, shouldRun: () => boolean) {
	const savedCallback = useRef(callback)
	const savedShouldRunCallback = useRef(shouldRun);

	// Remember the latest callback if it changes.
	useLayoutEffect(() => {
		savedCallback.current = callback
	}, [callback]);

	useLayoutEffect(() => {
		savedShouldRunCallback.current = shouldRun
	}, [shouldRun])

	// Set up the interval.
	useEffect(() => {
		// Don't schedule if no delay is specified.
		// Note: 0 is a valid value for delay.
		if (!delay && delay !== 0) {
			return
		}

		const id = setInterval(() => {
			const canRun = savedShouldRunCallback.current ? savedShouldRunCallback.current() : null;
			if ((canRun === null || canRun) && savedCallback.current) {
				savedCallback.current();
			}
		}, delay);

		return () => clearInterval(id)
	}, [delay])
}

export {useInterval}