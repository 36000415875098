import { FlexBox } from "../../../shared/components";
import { EmptyGroup } from "../../../shared/components/layout/groups/EmptyGroup";
import { StateIcon } from './StateIcon';
import { KeyboardArrowDown } from "@mui/icons-material";
import {WorkflowState, WorkflowStates} from '../../../shared/interfaces/WorkflowState';
import { useNavigate } from "react-router-dom";
import {
	Favorite,
	ItemText,
	ListContainer,
	DashboardContentListItem,
} from './DashboardContentList.style';
import { Box } from "@mui/material";
import { LoadingIndicator } from "../../../shared/components/progress/LoadingIndicator";
export interface DashboardContentListProps {
	list: {
		name?: string,
		state?: string,
		id?: string,
	}[],
	// the root path for the given items (if applicable)
	route?: string,
	maxHeight?: string,
	loading?: boolean,
	transparent?: boolean,
	onFavorite?: (id: string) => void,
	contentType?: ContentType
}

export type ContentType = "published" | "buffer"

const DashboardContentList = ({list, route, maxHeight, loading = false, transparent, onFavorite, contentType = "published"} : DashboardContentListProps) => {
	const navigate = useNavigate();
	return <ListContainer direction="column" maxHeight={maxHeight}>
		<Box position="relative" width="100%" height="100%">
			{loading &&  <LoadingIndicator transparent={transparent}/>}
			{list.length > 0 ? list.map((item, idx) =>
				<DashboardContentListItem key={idx} align="center"
					justify="space-between"

					lastItem={list.length - 1 === idx}
					onClick={() => navigate(`${route}/${item.id}`, {state: {isBuffer: contentType === "buffer"}})}>
					<FlexBox align="center" flex="1 1 auto" width="auto">
						<StateIcon state={item.state ? item.state.toLowerCase() as WorkflowState : WorkflowStates.InProcess}/>
						<ItemText direction="column" justify="space-around" width="auto" minWidth={0}>
							<span className="title_text">{item.name ?? "Untitled"}</span>
							{/* TODO: states for this subtext need to be derived */}
							{/* <span className="subtext">Placeholder</span> */}
						</ItemText>
					</FlexBox>
					<FlexBox align="center" justify="flex-end" flex="0 1 auto" minWidth={0} width="auto">
						{/* TODO: favoriting functionality */}
						{onFavorite ? <Favorite selected={false}  onClick={(e) => {e.stopPropagation(); console.log('star clicked');}}/> :
							<KeyboardArrowDown sx={{transform: 'rotate(-90deg)'}}/>
						}
					</FlexBox>
				</DashboardContentListItem>
			) : <EmptyGroup title="No items to display"/>}
		</Box>
	</ListContainer>
}

export {DashboardContentList};