import { EditableElementProps, ElementPropertyData } from "../../model";
import React, { useState } from "react";
import { TextField } from "../../../../../../shared/components/input";
import { Button, useStatusBar } from "../../../../../../shared/components";
import { useGetTemplate } from "../../../../hooks/api/useGetTemplate";
import { useDebounce, useRunWhenValueChange } from "../../../../../../shared/hooks";
import { computeElements } from "../../../../utilities/elementsFactory";
import { ElementCollectionPreview } from "./ElementCollectionPreview";
import { PropertyList } from "../../PropertyList";
import { TemplateElementValue } from "../../../../../../shared/definitions/elements/template/model";
import { DebounceDelay } from "../../utilities/editSettings";
import { TemplateElement } from "../../../../../../shared/interfaces/TemplateElement";
import { parseTemplateElements } from "../../../../../../shared/utilities/parseTemplateElements";

const EditableView = (props: EditableElementProps) => {
	const value = props.data as TemplateElementValue;
	const [elements, setElements] = useState<TemplateElement[]>([]);
	const [name, setName] = useState(value.name);
	const statusBar = useStatusBar();
	const getTemplate = useGetTemplate();
	const debouncedName = useDebounce(name, DebounceDelay);

	useRunWhenValueChange(() => {
		props.onChange({
			...(props as ElementPropertyData),
			data: { ...value, name: debouncedName }
		})
	}, debouncedName);

	// since name can change from property editor,
	// we need to watch it and set it here too.
	useRunWhenValueChange(() => {
		setName(value.name);
	}, value.name);

	useRunWhenValueChange(() => {
		if (getTemplate.value) {
			const templateElements = parseTemplateElements(getTemplate.value);
			setElements(computeElements(templateElements));
		}
	}, getTemplate.value);

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({ message: "Error previewing template", detail: getTemplate.error });
	}, getTemplate.error)

	const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setName(event.target.value);
	}

	const onHideTemplatePreview = () => setElements([]);
	const onPreviewTemplate = () => getTemplate.execute({ id: value.id!, version: value.version });

	return <PropertyList items={[{ label: "Value type", value: value.id ? "ID" : "Expression" }]}>
		<TextField placeholder={"Enter name here"} value={name} onChange={onChange} />
		{elements.length === 0 && value.id && <Button buttonType={getTemplate.isLoading ? "disabled" : "default"} text={getTemplate.isLoading ? "Loading preview" : "Preview template"} onClick={onPreviewTemplate} sx={{ mt: ".5rem" }} />}
		{elements.length > 0 && <Button text={"Hide preview"} onClick={onHideTemplatePreview} sx={{ mt: ".5rem" }} />}
		{elements.length > 0 && <ElementCollectionPreview elements={elements} />}
	</PropertyList>
}

export { EditableView };