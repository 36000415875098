import {TemplateElementDefinition} from "../model";
import {SwitchIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {PropertyEditor} from "./views/PropertyEditor";
import { metaValueDefinition } from "../../elements/meta/value/model";
import {
	switchDefinition as baseSwitchDefinition
} from "../../../../../shared/definitions/elements/switch/model";
import { PreviewView } from "./views/PreviewView";

const switchDefinition: TemplateElementDefinition = {
	...baseSwitchDefinition,
	addElementType: metaValueDefinition.type,
	icon: SwitchIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		preview: PreviewView,
		propertyEditor: {
			view: PropertyEditor,
			requiredOnCreate: true
		},
	},
} as const

export {switchDefinition};