import {DataSourcePreview, PreviewProps} from "./model";
import {styled} from "@mui/material";

const Container = styled("div")({
	padding: "10px",
	"& img": {
		width: "100%"
	}
})
const Preview = (props: PreviewProps) => {
	const imageData = props.dataSource?.data as string | null;
	return imageData === null ? null : (<Container>
		<p>{props.dataSource!.name}</p>
		<img src={imageData} alt={props.dataSource!.name} />
	</Container>);
}

const ImagePreview: DataSourcePreview = {
	type: "image",
	view: Preview
}
export {ImagePreview};