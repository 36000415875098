import {NunjucksVariables} from "../../interfaces/Nunjucks";
import {NunjucksEnvironmentOptions} from "../models/NunjucksEnvironmentOptions";
import {NunjucksFilterDocumentation} from "./help/model";

export type NunjucksFilter = NunjucksFilterDocumentation & {
	execute: (...args: unknown[]) => unknown
}

export type AsyncNunjucksFilter = NunjucksFilterDocumentation & {
	execute: (...args: unknown[]) => Promise<unknown>
}

export type NunjucksFilterDependencyFunc = (options: NunjucksEnvironmentOptions, ...args: unknown[]) => unknown;

export type NunjucksFilterDependencies = {
	incrementInput: NunjucksFilterDependencyFunc,
	incrementId: NunjucksFilterDependencyFunc,
	getIds: NunjucksFilterDependencyFunc
}

export type NunjucksFilterFactoryProps = {
	variables: NunjucksVariables,
	options?: NunjucksEnvironmentOptions,
	dependencies?: NunjucksFilterDependencies
}
export type NunjucksFilterFactory = (props?: NunjucksFilterFactoryProps) => NunjucksFilter;
export type AsyncNunjucksFilterFactory = (props?: NunjucksFilterFactoryProps) => AsyncNunjucksFilter;
export type AsyncNunjucksFilterCallback = (error: any, result: unknown) => void;
export function isNunjucksFilterDependencies(obj: unknown): obj is NunjucksFilterDependencies {
	return obj !== undefined && (obj as NunjucksFilterDependencies).incrementId !== undefined && (obj as NunjucksFilterDependencies).incrementInput !== undefined;
}
