import { Variable } from "../../../../../../../shared/interfaces/Variable";
import { ReferenceElementContents, ReferenceVariableValue } from "../../../../../../../shared/definitions/elements/reference/model";
import { formatReference } from "../../../utilities/formatReference";
import { VariableMapper } from "./model";
import { definitionForElementType } from "../../../index";
import { findElementsInBody } from "../../../utilities/findElementsInBody";
import {ElementValueType} from "../../../../../../../shared/interfaces/ElementValueType";


function mapper(variable: Variable, body?: ElementValueType[]) {
	const referencedElement = (variable.value ?? {}) as ReferenceElementContents;
	const element = findElementsInBody(body ?? [], referencedElement.element_id);
	const elementDefinition = definitionForElementType(element?.type);
	const reference = {
		...variable,
		value: {
			...variable.value as ReferenceElementContents,
			name: elementDefinition?.toString(element) ?? "No Element Name",
			value: elementDefinition?.contentDescription(element) ?? "Value Unavailable",
		}
	} as ReferenceVariableValue;
	return Promise.resolve(formatReference(reference));
}

const ReferenceMapper: VariableMapper = {
	type: "reference",
	mapper
};

export { ReferenceMapper }