import { makeStyles } from "@mui/styles";
import { theme, colors } from "../../theme";

const useStyles = makeStyles({
	checkbox: {
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		"& .MuiTypography-root": {
			marginLeft: "0.75rem",
			fontSize: "0.75rem",
			fontWeight: 400
		}
	},
	text: {
		color: theme.palette.text.primary
	},
	icon:{
		width: "1rem",
		height: "1rem",
	},
	selected_icon: {
		width: "1rem",
		height: "1rem",
		color: colors.mountainMeadow
	},
	unselected_icon:{
		width: "1rem",
		height: "1rem",
	}
});

export {useStyles};