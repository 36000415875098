import {Task, TaskNode} from "../../interfaces/task";
import {useUserList} from "../../hooks/useUserList";
const uuid = require("uuid");

export type TaskState = "Review" | "Approval" | "Unknown";
function mapOperationToState(operation: string): TaskState {
	switch (operation) {
		case 'set_ui_action_review':
			return "Review";
		case 'set_ui_action_approve':
			return "Approval";
		default:
			return "Unknown";
	}
}

export type TaskStatus = "Closed - Approved" | "Closed - Rejected" | "Cancelled" | "Closed";
function mapStatusFromResult(result?: string): TaskStatus {
	switch (result) {
		case "Approved":
			return "Closed - Approved";
		case "Rejected":
			return "Closed - Rejected";
		case "Cancelled":
			return "Cancelled";
		default:
			return "Closed";
	}
}

function useTaskNodeAdapter() {
	const userList = useUserList()

	const adapt = async (task: Task): Promise<TaskNode> => {
		const list = await userList.getUserNames();
		const matchedUsers = list?.users.filter(u => task.who.includes(u.id)) ?? [];
		const taskNode = {...task} as TaskNode;
		taskNode.name = task.source.name;
		taskNode.state = mapOperationToState(task.operation ?? "");
		taskNode.daysUntil = task.timeout?.in;
		taskNode.due = task.timeout?.at;
		taskNode.status = task.status?.toLowerCase() === "closed" ? mapStatusFromResult(task.result) : task.status;
		taskNode.owner = matchedUsers.map(user => user?.display_name ?? user?.name ?? "Unknown");
		taskNode.id = uuid.v4();
		return taskNode;
	}

	return {adapt};
}

export {useTaskNodeAdapter};
