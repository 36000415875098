import {Variable} from "../../../../../../../shared/interfaces/Variable";
import {VariableMapper} from "./model";
import {renderMissingVariable} from "../../../utilities/renderMissingVariable";

function mapper(variable: Variable) {
	return Promise.resolve(variable?.options?.data ?? variable.value ?? renderMissingVariable(variable.name));
}

const UndefinedMapper: VariableMapper = {
	type: undefined,
	mapper
}

export {UndefinedMapper};