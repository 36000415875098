import { styled, Box } from "@mui/material";
import { Photo } from "@mui/icons-material";
import {WorkflowState, paletteForWorkflowState} from '../../../shared/interfaces/WorkflowState';

export interface StateIconProps {
	state: WorkflowState,
}

const ItemState = styled(Box, {shouldForwardProp: (prop) => prop !== "state"})<StateIconProps>(({theme, state}) => ({
	padding: "10px 10px 7px 10px",
	borderRadius: '4px',
	backgroundColor: paletteForWorkflowState(state, theme)?.light,
}));

const ContentStateIcon = styled(Photo,{shouldForwardProp: (prop) => prop !== "state"})<StateIconProps>(({theme, state}) => ({
	fontSize: '1.4rem',
	color: paletteForWorkflowState(state, theme)?.main,
}));

const StateIcon = ({state} : StateIconProps) => {
	return <ItemState state={state}>
		<ContentStateIcon state={state}/>
	</ItemState>
}

export {StateIcon};