import { TasksList } from "../../../shared/tasks";
import Masthead from "../../../shared/components/navigation/masthead";
import { ContextButtonFactory } from "../../../shared/components/buttons/theme";
import { AsyncComponent, FlexBox } from "../../../shared/components";
import { ModuleLayout } from "../../../shared/components/layout/ModuleLayout";
import { useList } from "./useList";
import { TaskNode } from "../../../shared/interfaces/task";
import { ListPageWrapper } from "../../../shared/components/layout/ListPageWrapper";

const List = () => {
	const list = useList();

	const onTaskSelected = (task: TaskNode) => {
		list.onNavigateToTask(task);
	}
	const onContextButtonClick = (key: string) => {
	}
	return <AsyncComponent
		isLoading={list.isRunning}
		loadingMessage={"Loading..."}
		fullScreen
		component={<FlexBox direction={"column"} height={"100%"}>
			<Masthead
				contextButtons={ContextButtonFactory([],
					onContextButtonClick)}
			/>
			<ModuleLayout center={(<ListPageWrapper><TasksList tasks={list.tasks} title={"My Tasks"} onTaskSelected={onTaskSelected} /></ListPageWrapper>)} />
		</FlexBox>
		} />
}

export { List }