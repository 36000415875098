import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";
import {Variable} from "../../../../../shared/interfaces/Variable";
import {SwitchDataValue} from "../../../../../shared/definitions/elements/switch/model";
import {getBodyFromEvaluatedExpression} from "../switch/getBodyFromEvaluatedExpression";

const elementsWithChildren = ["template", "section", "switch", "loop"];
export function filterElements(body: ElementValueType[], type: string, variables: Variable[]): ElementValueType[] {
	const elements = body.filter(element => element.type === type);
	const nestableElements = body.filter(element => elementsWithChildren.includes(element.type));
	const nestedElements = nestableElements
		.map(element => {
			if (element.type === "switch") {
				const switchData = element.data as SwitchDataValue;
				const localVariables = variables.filter(v => v.templateId === element.source)
					.map(v => ({...v, namespace: undefined}));
				return filterElements(getBodyFromEvaluatedExpression(switchData, localVariables), type, variables);
			}

			return filterElements(element.children ?? [], type, variables);
		})
		.reduce((all, current) => all.concat(current), []);

	return [...elements, ...nestedElements];
}