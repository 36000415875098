import {DocumentElementDefinition} from "../model";
import {PreviewView} from "./PreviewView";
import {
	tocDefinition as baseTocDefinition
} from "../../../../../shared/definitions/elements/toc/model";

const tocDefinition: DocumentElementDefinition = {
	...baseTocDefinition,
	views: {
		preview: PreviewView
	},
} as const

export {tocDefinition};