import {SubmissionExtractionStatus} from "../../../../models/Submission";

export const backgroundColorMap: Record<SubmissionExtractionStatus, string> = {
	Completed: "#E3FCEF",
	Error: "#FDF1F3",
	Idle: "transparent",
	Requested: "#F1F3FA",
	Started: "#EDF8FF"
};

export const titleColorMap: Record<SubmissionExtractionStatus, string> = {
	Completed: "#00000066",
	Error: "#00000066",
	Idle: "transparent",
	Requested: "#00000066",
	Started: "#00000066"
};

export const iconColorMap: Record<SubmissionExtractionStatus, string> = {
	Completed: "#426AB9",
	Error: "#EA6854",
	Idle: "transparent",
	Requested: "transparent",
	Started: "transparent"
};

export const subTitleColorMap: Record<SubmissionExtractionStatus, string> = {
	Completed: "#006644",
	Error: "#EA6854",
	Idle: "transparent",
	Requested: "#98A1BE",
	Started: "#426AB9"
};