import {routeLookup, RouteLookupKey} from "./index";

type PageActionType = "edit" | "list" | "create" | "tasks";
type PageActionLookupType = Record<PageActionType, string>;
type ModuleLinkGenerator = (module: RouteLookupKey | string, action?: PageActionType, ...params: string[]) => string;

/**
 * map between action types
 */
const pageActionLookup: PageActionLookupType = {
	edit: "/edit",
	create: "/create",
	tasks: "/tasks",
	list: ""
};

const routePathJoin = (basePath: string | undefined, action?: PageActionType, ...params: string[]) =>
	basePath === undefined ? undefined : `${basePath}${action ? pageActionLookup[action!] : ""}${params?.reduce((path, current) => `${path}/${current}`, "")}`

/**
 * Dynamically maps to a module or redirects to the 404 page
 * @param module
 * @param action
 * @param params
 */
const moduleLinkGenerator: ModuleLinkGenerator = (module: RouteLookupKey | string, action?: PageActionType, ...params: string[]) =>
	routePathJoin(routeLookup[module], action, ...params) ?? `/404?module=${module}&action=${action}`;


export {moduleLinkGenerator, pageActionLookup}