import React from "react";
import {FormControl, InputLabel, MenuItem, Select as MuiSelect, styled, Theme} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {SxProps} from "@mui/system";

export interface DropDownItem<T extends string | number> {
	title: string,
	value: T
}

export interface DropDownProps<T extends string | number> {
	items: DropDownItem<T>[],
	label?: string,
	onSelect: (item: DropDownItem<T>) => void,
	value: T | undefined,
	sx?: SxProps<Theme>
}

const Select = styled(MuiSelect)({
	fontWeight: 700
});

const DropDown = <T extends string | number>(props: DropDownProps<T>) => {

	const onChange = (e: SelectChangeEvent<unknown>, child: React.ReactNode) => {
		const item = props.items.find(item => item.value === e.target.value);
		props.onSelect(item!);
	}

	return <FormControl variant="outlined" sx={props.sx}>
		<InputLabel htmlFor="outlined-age-native-simple">{props.label}</InputLabel>
		<Select onChange={onChange} value={props.value} variant={"outlined"} label={props.label}>
			{props.items.map(item => <MenuItem key={item.value} value={item.value} title={item.title}>{item.title}</MenuItem>)}
		</Select>
	</FormControl>
}

export {DropDown};