import {TextField} from "../../../../../../shared/components/input";
import {colors} from "../../../../../../theme";
import "./styles.scss";

export type CompoundLabelProps = {
	compound: string
}
const CompoundLabel = ({compound}: CompoundLabelProps) => <div className={"ectd__tabs--pcdl-compound"}>
	<TextField disabled={true} InputProps={{style: {background: colors.paperWhite, borderColor: colors.fadedGrey}}} label={"Compound"} onChange={undefined} value={compound} sx={{marginTop: "1rem"}} />
</div>

export {CompoundLabel}