import { styled, TableCell, TableRow, Table, TablePagination, TableHead, TableContainer } from "@mui/material";
interface BaseTableRowProps {
	depth?: number,
	enableHover?: boolean
}

interface NameTableCellProps {
	depth: number,
    expandable: boolean,
}

interface DescriptionTableCellProps {
	depth: number,
	expandable: boolean,
}

interface ExpandableRowProps extends BaseTableRowProps {
    selected: boolean,
}

const headerStyles = {paddingBottom: "0.2rem", fontSize: ".7rem"};

const MuiTable = styled(Table)(({theme}) => ({
	borderCollapse: 'separate',
}))

const MuiTablePagination = styled(TablePagination)(({theme}) => ({
	borderBottom: "transparent",
	color: theme.palette.dashboard?.contrastText,
	"& .MuiTablePagination-selectLabel" : {
		fontSize: ".8rem",
		fontWeight: 500,
	},
	"& .MuiTablePagination-displayedRows" : {
		fontSize: ".8rem",
		fontWeight: 500,
	},
	"& .MuiTablePagination-select": {
		marginTop: ".2rem",
	}
}))

const MuiTableHead = styled(TableHead)(({theme}) => ({
	position: "sticky",
	top: 0,
}))

const MuiTableContainer = styled(TableContainer)(({theme}) => ({
	overflow: "auto",
	height: "100%"
}))

const BaseTableRow = styled(TableRow, {shouldForwardProp: (prop) => prop !== "depth" && prop !== "enableHover"})<BaseTableRowProps>(({depth, enableHover, theme}) => ({
	boxSizing: 'border-box',
	background: (depth ?? 0) > 0 ? theme.palette.paperDialog?.main : "#fff",
	borderBottom: `1px solid ${theme.palette.tagsChip?.main}`,
	":hover": !enableHover ? null : {
		background: theme.palette?.dashboard?.static
	},
	"& button": {
		position: "unset",
	}
}));

const ExpandableRow = styled(BaseTableRow, {shouldForwardProp: (prop) => prop !== "selected"})<ExpandableRowProps>(({selected, theme}) => ({
	transition: 'all 150ms ease',
	':hover': {
		backgroundColor: theme.palette?.dashboard?.static,
	},
	cursor: 'pointer',
	...(selected && {
		backgroundColor: theme.palette?.dashboard?.static,
	})
}));

const BaseTableCell = styled(TableCell)(({theme}) => ({
	boxSizing: "border-box",
	fontSize: ".8rem",
	fontWeight: 400,
	padding: ".6rem",
	color: theme.palette.dashboard?.contrastText,
	borderBottom: `1px solid ${theme.palette.table?.border}`
}))

const DataTableCell = styled(BaseTableCell)(({theme}) => ({
	paddingLeft: "2rem",
}))

const HeaderTableCell = styled(BaseTableCell)(({theme}) => ({
	fontWeight: 600,
	position: "sticky",
	borderBottom: `2px solid ${theme.palette.variable?.background}`
}))

const HeaderTitle = styled('label')(({theme}) => ({
	color: theme.palette.table?.contrastText,
}))

const TableTitle = styled('h1')(({theme}) => ({
	fontWeight: 500,
	paddingLeft: "2.5rem",
	fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
}))


// for the increasing indentation
const NameTableCell = styled(BaseTableCell, {shouldForwardProp: (prop) => prop !== "depth" && prop !== "expandable"})<NameTableCellProps>(({depth, expandable, theme}) => ({
	paddingLeft: `${2 + (depth * 1.2)}rem`,
	color: depth > 0 ? theme.palette.table?.contrastText : theme.palette.table?.main,
	fontWeight: 600,
	'& .row_title': {
		textDecoration: 'underline',
		textDecorationThickness: '0.13rem',
		textUnderlineOffset: '0.2rem',
		cursor: 'pointer',
		marginLeft: expandable ? "7px" : "0",
	},
}))

// for the increasing indentation
const DescriptionTableCell = styled(BaseTableCell, {shouldForwardProp: (prop) => prop !== "depth" && prop !== "expandable"})<DescriptionTableCellProps>(({depth, expandable, theme}) => ({
	paddingLeft: `${2 + (depth * 1.2)}rem`,
	color: depth > 0 ? theme.palette.table?.contrastText : theme.palette.table?.main,
	fontWeight: 600,
	'& .row_title': {
		textDecoration: 'underline',
		textDecorationThickness: '0.13rem',
		textUnderlineOffset: '0.2rem',
		cursor: 'pointer',
		marginLeft: expandable ? "7px" : "0",
	},
}))

export {
	BaseTableCell,
	BaseTableRow,
	ExpandableRow,
	NameTableCell,
	DescriptionTableCell,
	headerStyles,
	HeaderTableCell,
	MuiTable,
	DataTableCell,
	HeaderTitle,
	TableTitle,
	MuiTablePagination,
	MuiTableHead,
	MuiTableContainer
}