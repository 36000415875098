import { formatDistance } from "date-fns";
import {Column, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {DataTableCell} from "../../../../../../shared/components/layout/table/styles";
import {ContentNode} from "../../../../models/content";

export function renderDate(time: string) {
	const date = new Date(parseInt(time, 10));
	const currentTime = new Date();
	return `${formatDistance(date, currentTime)} ago`;
}

const LastModifiedCell = (props: ColumnViewProps) => {
	const item = props.item as ContentNode;
	const column = props.column;
	return <DataTableCell
		align={column.itemAlign}
		width={column.width}
		key={`${column.title}-${item.id}`}
	>
		<p>{renderDate(item["@date"])}</p>
	</DataTableCell>

}

const LastModified: Column = {
	field: "@date",
	title: "Last Modified",
	headerAlign: "left",
	itemAlign: "left",
	width: "10%",
	sortable: true,
	views: {
		cell: LastModifiedCell
	}
}

export {LastModified};