import {StyleComponentMap} from "./model";
import {AddStyleElement} from "./AddStyleElement";
import {AddStyle} from "./AddStyle";
import {AddSymbol} from "./AddSymbol";
import React from "react";
import {ElementProps} from "../models";
import {AddSectionNumbering} from "./AddSectionNumbering";
import { StyleType } from "../../../../../../../../shared/definitions/elements/style/model";

const styleComponents: ReadonlyArray<StyleComponentMap> = [
	AddStyleElement,
	AddStyle,
	AddSymbol,
	AddSectionNumbering
] as const;

export function styleComponentMapFromType(type: StyleType): StyleComponentMap | undefined {
	return styleComponents.find(component => component.type === type);
}

export function styleComponentFromType(type: StyleType): React.FC<ElementProps> | undefined {
	return styleComponents.find(component => component.type === type)?.component;
}