import { styled } from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";
import { BaseButton } from "../../../shared/components/buttons/BaseButton";
export interface CreationButtonProps {
	Icon: SvgIconComponent,
	label: string,
	onClick: () => void,
}

const BaseCreationButton = styled(BaseButton)(({theme}) => ({
	borderRadius: "1.4rem",
	display: 'flex',
	flexDirection: 'column',
	flex:"0 1 auto",
	backgroundColor: theme.palette.primary.main,
	fontWeight: 500,
	padding: "1rem 1rem 1.5rem 1rem",
	margin: ".6rem",
	minWidth: "8rem",
	color: theme.palette.primary.contrastText,
	boxShadow: "rgb(0 0 0 / 30%) 0px 2px 3px",
	fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
	textTransform: 'none',
	transition: "all 150ms ease",
	"&:hover": {
		backgroundColor: 'transparent',
		color: theme.palette.primary.main,
	},
	"& .creation_button_label": {
		paddingTop: "1.0rem",
		fontSize: "1.0rem",
		lineHeight: '1.2',
		wordSpacing: '100vw',
	},
	"& .creation_button_icon": {
		fontSize: '2.0rem',
		paddingTop: '0.7rem'
	}
}));

const CreationButton = ({Icon, label, onClick}: CreationButtonProps) => <BaseCreationButton onClick={onClick}>
	<Icon className="creation_button_icon"/>
	<span className="creation_button_label">{label}</span>
</BaseCreationButton>


export {CreationButton}
