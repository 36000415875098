import {AsyncComponent, FlexBox} from "../../../../shared/components";
import Masthead from "../../../../shared/components/navigation/masthead";
import {ContextButtonFactory, DefaultContextButtons} from "../../../../shared/components/buttons/theme";
import {moduleLinkGenerator} from "../../../moduleNavigation";
import {ModuleLayout} from "../../../../shared/components/layout/ModuleLayout";
import {CenterPane} from "./layout/center";
import {useTocBuilder} from "./useTocBuilder";
import {LeftPane} from "./layout/left";
import {EctdModule} from "../../../../shared/interfaces/Module";
import {RightPane} from "./layout/right";


const TocBuilder = () => {
	const breadCrumbs = [
		{title: "Submissions", url: moduleLinkGenerator("submission", "list")},
		{title: "Create", url: moduleLinkGenerator("submission", "create")},
		{title: "Select eCTD Structure"}
	]
	const tocBuilder = useTocBuilder();
	const onContextButtonClick = (key: string) => {
		switch(key) {
			case DefaultContextButtons.Save.key:
				tocBuilder.onCreateSubmission();
				break;
		}
	}

	const onToggleSectionActive = (section: EctdModule) => {
		tocBuilder.toggleActiveState(section);
	}

	const onSelectSection = (section: EctdModule) => {
		tocBuilder.selectSection(section);
	}

	const onSelectModule = (module: EctdModule) => tocBuilder.selectModule(module);

	return <AsyncComponent
		loadingMessage={tocBuilder.isRunning ? "Loading table of contents builder" : "Creating submission..."}
		isLoading={tocBuilder.isRunning || tocBuilder.isSaving}
		component={<FlexBox direction={"column"} height={"100%"}>
			<Masthead
				contextButtons={ContextButtonFactory(
					[DefaultContextButtons.Save],
					onContextButtonClick)}
				breadcrumbs={breadCrumbs}
			/>
			<ModuleLayout
				center={<CenterPane onSelectModule={onSelectModule} onToggleSectionActive={onToggleSectionActive} modules={tocBuilder.modules} selectedModule={tocBuilder.selectedModule} selectedSection={tocBuilder.selectedSection} />}
				left={<LeftPane onSelectSection={onSelectSection} onToggleSectionActive={onToggleSectionActive} selectedModule={tocBuilder.selectedModule} selectedSection={tocBuilder.selectedSection} />}
				right={<RightPane activeModules={tocBuilder.activeModules()} selectedSection={tocBuilder.selectedSection} selectedModule={tocBuilder.selectedModule} />}
			/>
		</FlexBox>}/>
}

export {TocBuilder}