import {TemplateState} from "../../useTemplateState";

function useSetBufferFromTemplate() {
	const state = TemplateState.useContainer();
	// remove values that are not present on the buffer model.
	return (template?: KaiAlphaTemplate) => {
		const {child_resources, workflow, previous_version, ...buffer} = template ?? state.template!;
		state.setElements(null);
		state.setBuffer(buffer, true);
	};

}

export {useSetBufferFromTemplate};