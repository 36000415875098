import {DocumentElementDefinition} from "../model";
import {
	switchDefinition as baseSwitchDefinition
} from "../../../../../shared/definitions/elements/switch/model";
import { PreviewView } from "./PreviewView";

const switchDefinition: DocumentElementDefinition = {
	...baseSwitchDefinition,
	views: {
		preview: PreviewView
	},
} as const

export {switchDefinition};