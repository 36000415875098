import {useAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";
import { WorkflowApiResponse } from "../../../../shared/interfaces";

// updates a template workflow with an event
function useUpdateWorkflow() {
	const updateWorkflow = async (workflowEvent: object, eventType: string, templateId: string, workflowSlot: string) => {
		const payload = {
			id: templateId,
			version: "HEAD",
			event: eventType,
			args: workflowEvent,
		}

		return api.call('PUT', `template/${templateId}/workflow/${workflowSlot}`, payload);
	}

	return useAsync<WorkflowApiResponse>(updateWorkflow, false);
}

export {useUpdateWorkflow}