import {useStatusBar} from "../../../../../../shared/components";
import {useUserList} from "../../../../../../shared/hooks/useUserList";
import {TemplateState} from "../../../../hooks";
import {useRunWhenValueChange, useRunOnce} from "../../../../../../shared/hooks";
import {useSetPermissionsAction} from "../../../../hooks/actions";
import { AclEditorRoleTypes } from "../../../../../../shared/components/modals/aclEditor/models";
import { ContributorsGroup } from "../../../../../../shared/components/layout/groups/contributors";

/**
 * This component displays the contributors associated with the template. It also allows you to edit using the ACL Editor.
 * @constructor
 */
const Contributors = () => {
	const templatesStore = TemplateState.useContainer();
	const statusBar = useStatusBar();
	const userList = useUserList();
	const setPermissionsAction = useSetPermissionsAction();
	useRunOnce(() => userList.execute(['name', 'display_name']));

	const updatePermissions = (permissions: KaiAlphaTemplatePermissions) => {
		templatesStore.setPermissions(permissions as KaiAlphaTemplatePermissions);
		setPermissionsAction.run({templateId: templatesStore.template!.id, permissions, lastupdated: templatesStore.template?.lastupdated});
	}

	useRunWhenValueChange(async () => {
		if (userList.status === "error") {
			statusBar.sendErrorNotification({message: "Failed to get contributor list.", detail: userList.error});
		}
	}, userList.status);

	return <ContributorsGroup
		permissions={templatesStore.buffer?.permissions}
		userList={userList.status === "success" ? (userList.value ?? undefined) : undefined}
		onUpdatePermissions={updatePermissions}
		userRoles={templatesStore.computedUserRoles ?? undefined}
		isLoading={setPermissionsAction.isRunning || userList.isLoading}
		editableAclRoles={["owners", "authors", "readers", ...(!templatesStore.isTopLevel ? ["reviewers", "approvers"] : []) as AclEditorRoleTypes[]]}
	/>
}

export {Contributors}
