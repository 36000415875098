import {ExtractionStatusLabelTypeProps} from "./model";
import {LabelPaper} from "./LabelPaper";
import {LabelTitle} from "./LabelTitle";
import {FlexBox, MessageBox} from "../../../../../../shared/components";
import {LabelSubTitle} from "./LabelSubTitle";
import {PriorityHigh} from "@mui/icons-material";
import {iconColorMap} from "./colorMaps";
import {useState} from "react";

export const ErrorExtractionLabel = (props: ExtractionStatusLabelTypeProps) => {
	const [showError, setShowError] = useState(false);
	const onClick = () => setShowError(true);
	const onCloseErrorMessage = () => setShowError(false);

	return <>
		<LabelPaper status={"Error"} justify={"space-between"} align={"center"} onClick={onClick}>
			<FlexBox align={"center"}>
				<LabelTitle status={"Error"}>Extraction Error</LabelTitle>
			</FlexBox>
			<FlexBox align={"center"} justify={"flex-end"}>
				<LabelSubTitle status={"Error"}>Error</LabelSubTitle>
				<PriorityHigh fontSize={"small"} style={{color: iconColorMap["Error"]}} />
			</FlexBox>
		</LabelPaper>
		<MessageBox visible={showError && props.error !== undefined} message={props.error!} primaryAction={"Ok"} onActionClick={onCloseErrorMessage} onClose={onCloseErrorMessage} />
	</>
}
