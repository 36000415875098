import {Column} from "../../components/layout/table";
import {Owner} from "./Owner";
import {Status} from "./Status";
import {Name} from "./Name";
import {DaysUntilDue} from "./DaysUntilDue";
import {DueDate} from "./DueDate";
import {Task} from "./Task";


const columns: Column[] = [ Name, Task, Owner, DueDate, DaysUntilDue,Status ]
function cellViewForColumn(field: string) {
	return columns.find(column => column.field === field)?.views?.cell;
}

export {columns, cellViewForColumn}