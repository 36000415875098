import { CheckCircle } from '@mui/icons-material';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { useNavigate, createSearchParams } from "react-router-dom";
import { Button } from '../../../../../shared/components';
import { BaseModal } from '../../../../../shared/components/modals/BaseModal';
import { moduleLinkGenerator } from '../../../../moduleNavigation';
import { CreateTemplateContainer } from './style';

export interface CreateTemplateOptions {
    toplevel: string
}

export interface CreateTemplateModalProps {
    onCancel: () => void,
    visible: boolean,

}

const CreateTemplateModal = ({onCancel, visible}: CreateTemplateModalProps) => {
	const navigate = useNavigate();
	const [templateOptions, setTemplateOptions] = useState<CreateTemplateOptions>({
		toplevel: "false",
	});

	// provide options to the template editor to publish newly created template.
	const onCreate = () => {
		navigate({
			pathname: moduleLinkGenerator("template", "edit"),
			search: createSearchParams({
				toplevel: templateOptions.toplevel,
			}).toString(),
		});
	}

	const setTemplateType = (type: string) => {
		setTemplateOptions(s => ({
			...s,
			toplevel: type,
		}))
	}

	return <BaseModal
		visible={visible}
		title={"New Template"}
		content={<CreateTemplateContainer direction="column">
			<h3>What type of template would you like to create?</h3>
			<FormControl>
				<RadioGroup name='toplevel-template-selection' value={templateOptions.toplevel} onChange={(event) => setTemplateType(event.target.value)}>
					<FormControlLabel className={templateOptions.toplevel === "true" ? 'selected_action' : ''} value={"true"} control={<Radio value={"true"} disableRipple color='primary' checkedIcon={<CheckCircle/>}/>} label='Wrapper Template' />
					<FormControlLabel className={templateOptions.toplevel === "false" ? 'selected_action' : ''} value={"false"} control={<Radio value={"false"} disableRipple color='primary' checkedIcon={<CheckCircle/>}/>} label='Template' />
				</RadioGroup>
			</FormControl>
		</CreateTemplateContainer>}
		buttonRow={	<>
			<Button styling="cancel" text="Cancel" onClick={onCancel}/>
			<Button buttonType={"default"} text={"Create"} onClick={onCreate}/>
		</>}
		onClose={onCancel}
	/>

}

export {CreateTemplateModal};