import './style.scss'
import { PreviewElementProps } from "../../../model";
import { Label } from '../../../../../../../shared/components';
import { LabelValue } from '../../../../../../../shared/components/labels/LabelValue';
import { citationsValue } from '../../model';

const PreviewView = (props: PreviewElementProps) => {
	return <div className={"citations-preview"}>
		<Label style={{ color: "#333333" }}>{citationsValue.label}</Label>
		<LabelValue>{citationsValue.labelValue}</LabelValue>
	</div>
}

export default PreviewView;
