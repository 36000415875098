import {BaseButton} from "./BaseButton";
import {styled} from "@mui/material";
import {ButtonProps} from "./model";

const PlainButton = styled(BaseButton)<ButtonProps>(({theme, styling}) => ({
	backgroundColor: styling === 'inverted' ? theme.palette.primary?.main : theme.palette.plain?.main,
	color:  styling === 'inverted' ? theme.palette.primary?.contrastText : theme.palette.plain?.contrastText,
	padding: "4px",
	textTransform: "none",
	border: `1px solid ${theme.palette.plain?.border}`,
	'&:hover': {
		backgroundColor: styling === 'inverted' ? theme.palette.primary.contrastText : theme.palette.plain?.hover,
		color: styling === 'inverted' ? theme.palette.primary.main : theme.palette.cancelButton?.contrastText
	}
}));

export {PlainButton};