// import { Close } from "@mui/icons-material";
import {FlexBox, GroupedContent} from "../../../../../../shared/components";
import {TemplateState} from "../../../../hooks/useTemplateState";
import {Autocomplete, Container, AutocompleteOption, Cancel, CheckCircle} from  "./style";
import { TextField } from "../../../../../../shared/components/input";
import { useGetModelDocumentList } from "../../../../hooks/api/useGetModelDocumentList";


/**
 * Truncates the template name if it is longer than 20 characters
 * @param templateName: {string} the name of the template
 * @returns {string}
 */
const truncateTemplateName = (templateName) => templateName.length > 20 ? templateName.substring(0, 20) + "..." : templateName;

/**
 * This component displays basic template details: name, toplevel state, and content type
 * @constructor
 */
const TemplateDetails = () => {
	const templateManager = TemplateState.useContainer();
	const modelDocuments = useGetModelDocumentList();

	// when template name changes update the template state
	const onChangeTemplateName = (e) =>{
		const templateName = e.target.value;
		templateManager.setBuffer(t => ({...t, name: templateName}));
	}

	const onChangeModelDocument = (value: string) => {
		templateManager.setBuffer(t => ({...t, model_document: value}));
	}

	const templateName = templateManager.buffer?.name ?? "";
	const canBuildDocuments = templateManager.canBuildDocuments ? "true" : "false";
	const isTopLevel = templateManager.isTopLevel ? "true" : "false";

	return  <GroupedContent title={"Template Details"}>
		<Container>
			{!templateManager.editable && <FlexBox direction={"column"}>
				<span className="surTitle">Template Name</span>
				<label className="templateTitle" title={templateName}>{truncateTemplateName(templateName)}</label>
			</FlexBox>}
			{templateManager.editable &&
			<TextField
				label={"Template Name"}
				value={templateName}
				fullWidth
				onChange={onChangeTemplateName}
			/>}
			{templateManager.editable && <Autocomplete
				fullWidth
				freeSolo
				disableClearable
				value={templateManager.buffer?.model_document}
				forcePopupIcon={false}
				options={modelDocuments.value ?? []}
				renderOption={(props, option) =>  <AutocompleteOption {...props}>{option as string}</AutocompleteOption>}
				renderInput={(params) => (
					<TextField
						{...params}
						onFocus={modelDocuments.execute}
						label={"Model Document"}
						fullWidth
						placeholder="Type to search Model Documents"
						onBlur={(e) => onChangeModelDocument(e.target.value)}
					/>
				)}
				onChange={(event, newValue) => onChangeModelDocument(newValue as string)}
			/>}
			<FlexBox boxSizing={'border-box'} padding={"10px 0"} align={"center"}>
				{canBuildDocuments === "true" ? <CheckCircle/> : <Cancel/>}
				<span className="check_label">This template can be used to build content</span>
			</FlexBox>
			<FlexBox boxSizing={'border-box'} padding={"10px 0 20px"} align={"center"}>
				{isTopLevel === "true" ? <CheckCircle/> :  <Cancel/>}
				<span className="check_label">This template is a wrapper template</span>
			</FlexBox>
			<FlexBox direction={"column"}>
				<span className="surTitle">Template Overall Content Type</span>
				<label>Static</label>
			</FlexBox>
		</Container>
	</GroupedContent>
}

export {TemplateDetails}