import {TypedVariableView, TypedVariableViewProps} from "./model";
import {FlexBox, Label} from "../../../../../../../shared/components";
import {LabelValue} from "../../../../../../../shared/components/labels/LabelValue";

type DropDownValueType = {
	items: string[]
}

const ListView = (props: TypedVariableViewProps) => {
	const data = props.options as DropDownValueType;
	return <FlexBox>
		<Label>List items:</Label>
		<LabelValue>{data?.items?.join(", ") ?? "No options set"}</LabelValue>
	</FlexBox>
};

const ListVariable: TypedVariableView = {
	type: "list",
	view: ListView
}
export {ListVariable}