import {StorageItem, Storage} from "./models";

/*
 * Wraps browser local storage.
 */
function useLocalStorage(): Storage {

	const get = <T>(key: string, defaultValue: T | undefined): T | undefined => {
		const value = localStorage.getItem(key);
		if (value) {
			return (JSON.parse(value) as StorageItem<T>).value;
		}
		return defaultValue;
	}

	const set = (key: string, value: unknown): void => localStorage.setItem(key, JSON.stringify({value}));

	const remove = (key: string) : void => localStorage.removeItem(key);

	return {
		get,
		set,
		remove,
	};
}

export {useLocalStorage};