import { styled } from "@mui/material/";
import {ButtonBase} from "../layout/box/editControlBox/ButtonBase";

const DisabledButton = styled(ButtonBase)(({theme}) => ({
	backgroundColor: theme.palette.disabled?.main,
	color: theme.palette.disabled?.contrastText,
	border: `1px solid ${theme.palette.disabled?.contrastText}`,
	padding: "4px",
	textTransform: "none",
	'&:hover': {
		backgroundColor: theme.palette.disabled?.main,
		color: theme.palette.disabled?.contrastText,
	},
	cursor: "default"
}));

export {DisabledButton};