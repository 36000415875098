import {TypedVariableView, TypedVariableViewProps} from "../model";
import React, {useState} from "react";
import {Button, FlexBox, Label, useStatusBar} from "../../../../../../../../shared/components";
import {useRunWhenValueChange} from "../../../../../../../../shared/hooks";
import {PreviewForDataSourceType} from "./previews";
import {Dialog} from "../../../../../../../../lib/ui";
import {Box, DialogContent} from "@mui/material";
import {TextField} from "../../../../../../../../shared/components/input";
import DebugConsole from "../../../../../../../../components/DebugConsole";
import {LabelValue} from "../../../../../../../../shared/components/labels/LabelValue";
import {DataSource} from "../../../../../../../../shared/interfaces/DataSource";
import {DataSourceOptions, useGetDataSource} from "../../../../../../../../shared/hooks/api/data/useGetDataSource";

type DatasourceValueType = DataSourceOptions

interface DataSourceViewState {
	showData: boolean,
	data: DataSource[] | null,
	variables: {[k: string]: string} | undefined,
	nunjucksContext: any | undefined
}

const DatasourceView = (props: TypedVariableViewProps) => {
	const data = props.options as DatasourceValueType;
	const dataSourceLoader = useGetDataSource();
	const [state, setState] = useState<DataSourceViewState>({showData: false, data: [], variables: undefined, nunjucksContext: undefined});
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if (dataSourceLoader.status === "success") {
			setState(s => ({...s, showData: true, data: dataSourceLoader.value ?? []}))
			return;
		}

		if (dataSourceLoader.status === "error") {
			statusBar.sendErrorNotification({message: "Error previewing data", detail: dataSourceLoader.error});
		}

	}, dataSourceLoader.status);

	useRunWhenValueChange(() => {
		if ((state.data?.length ?? 0) > 0 && data.source) {
			computeNunjucksContext();
		} else {
			setState(s => ({...s, nunjucksContext: undefined}));
		}
	}, state.data)

	const computeNunjucksContext = () => {
		const variableValue = {};
		if (!data.source!.includes('*')) {
			if (state.data![0] === undefined || state.data![0] === null) {
				return;
			}
			if (state.data![0].type === 'image') {
				Object.assign(variableValue, state.data![0]);
			} else {
				Object.assign(variableValue, state.data![0].data);
			}
		} else {
			for (const subset of state.data!) {
				if (subset === null || subset?.name === undefined) {
					continue;
				}

				if (subset.type === 'image') {
					variableValue[subset.name] = { metadata: subset.metadata };
				} else {
					variableValue[subset.name] = subset.data;
				}
			}
		}

		setState(s => ({...s, nunjucksContext: {[props.name]: variableValue}}))
	}
	const loadDatasource = () => {
		if (!data.source) {
			statusBar.sendErrorNotification({message: "Error: datasource is empty", detail: "The source property of the datasource variable can not be empty"});
			return;
		}

		if (!sourceHasVariables()) {
			dataSourceLoader.execute({...data, name: props.name});
		}

	}

	const sourceHasVariables = () => {
		if (data.source?.includes('{{')) {
			const variableName = new RegExp('{{(.*?)}}', 'g');
			const variables = data.source!.match(variableName)?.reduce((lookup, current) => {
				lookup[current] = "";
				return lookup;
			}, {});
			setState(s => ({...s, variables }));
			return variables && Object.keys(variables).length > 0;
		}

		return false;
	}

	const onCloseVariableDialog = () => setState(s => ({...s, variables: undefined}));
	const onHidePreview = () => setState(s => ({...s, showData: false, data: []}));
	const onSetVariableValueFactory = (variableKey: string) =>
		(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
			setState(s => ({...s, variables: {...s.variables, [variableKey]: e.target.value}}));

	const onLoadDatasourceWithVariables = () => {
		if (!data.source) {
			statusBar.sendErrorNotification({message: "Error: datasource is empty", detail: "The source property of the datasource variable can not be empty"});
			return;
		}

		if (state.variables) {
			let source = data.source;
			Object.keys(state.variables).forEach(variableKey => {
				source = source.replace(variableKey, state.variables![variableKey]);
			})
			dataSourceLoader.execute({...data, source, name: props.name});
		}

		onCloseVariableDialog();
	}
	return <>
		<FlexBox sx={{mb: 2}}>
			<Label>Datasource:</Label>
			<LabelValue>{data.source}</LabelValue>
		</FlexBox>
		{!state.showData && !props.readonly && (dataSourceLoader.isLoading ? <Button buttonType={"disabled"} text={"Loading datasource..."} disabled={true} /> : <Button buttonType={"default"} text={"Preview datasource"} onClick={loadDatasource} />)}
		{state.showData && !props.readonly && <>
			<Button buttonType={dataSourceLoader.isLoading ? "disabled" : "default"} text={"Hide preview"} onClick={onHidePreview} disabled={dataSourceLoader.isLoading} sx={{mr:".5rem"}} />
			<Button buttonType={dataSourceLoader.isLoading ? "disabled" : "default"} text={dataSourceLoader.isLoading ? "Updating datasource..." : "Update datasource"} disabled={dataSourceLoader.isLoading} onClick={loadDatasource} />
		</>}
		<Dialog
			open={state.variables && Object.keys(state.variables).length > 0}
			onClose={onCloseVariableDialog}
			title={"Variable values"}
			buttons={{
				Close: onCloseVariableDialog,
				Apply: onLoadDatasourceWithVariables
			}}
		>
			<DialogContent>
				<p>In order to preview the data, the following variables require values</p>
				<>
					{state.variables && Object.keys(state.variables!).map(variable =>
						<TextField
							key={variable}
							label={variable.replace("{{", "").replace("}}", "")}
							value={state.variables![variable]}
							onChange={onSetVariableValueFactory(variable)}
						/>)}
				</>
			</DialogContent>
		</Dialog>
		{state.data?.map(datasource => datasource === null ? null : <PreviewForDataSourceType dataSource={datasource} options={data} type={datasource.type} key={datasource.name} />)}
		{state.nunjucksContext && !props.readonly && <Box sx={{mt: "1rem"}}>
			<DebugConsole
				nunjucksContext={state.nunjucksContext}/>
		</Box>}
	</>
}

const DatasourceVariable: TypedVariableView = {
	type: "datasource",
	view: DatasourceView
}
export {DatasourceVariable}
