import {SnackbarContent, styled} from "@mui/material";
import "./snackBar.scss";
import React from "react";


const SnackBarContent = styled(SnackbarContent)({
	minWidth: "0",
	flexDirection: "column",
	"& .MuiSnackbarContent-action": {
		paddingLeft: "0",
		margin: "0"
	}
});

export interface SnackBarContentProps {
	content: React.ReactNode
}
const SnackBarContentMessage = ({content}: SnackBarContentProps) => {
	return (<div className="notification__snackbar__content">
		<div className="notification__snackbar__content--message">
			{content}
		</div>
	</div>)
}

export {SnackBarContent, SnackBarContentMessage}