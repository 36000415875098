import { PropertyList } from "../../PropertyList";
import { EditableElementProps } from "../../model";
import { useElements } from "../../../../hooks";
import { metaElseDefinition } from "../../meta/else/model";
import { Button } from "../../../../../../shared/components";
import { LoopElementValue } from "../../../../../../shared/definitions/elements/loop/model";


const EditableView = (props: EditableElementProps) => {
	const elementManager = useElements();
	const value = props.data as LoopElementValue;
	const properties = [{ label: "Name", value: value.name }, { label: "Expression", value: value.expression }];

	const onAddElement = () => {
		const thisElement = elementManager.findById(props.id);
		if (!thisElement) {
			return;
		}

		elementManager.addElement({...elementManager.createElement(metaElseDefinition.type), id: props.id, depth: thisElement.depth+1}, props.id);

	}

	return <PropertyList items={properties}>
		<Button text={"Add else"} onClick={onAddElement} />
	</PropertyList>
}

export { EditableView }