import React from 'react';
import TemplateDocSelector from '../TemplateDocSelector';
import workflow from '../../api/workflow';
import { AppChrome } from '../App/Chrome/AppChrome';
import { MessageBox, MessageBoxActions } from "../../shared/components";
import { ContextButtonFactory, DefaultContextButtons } from "../../shared/components/buttons/theme";

class WorkflowSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedWorkflow: undefined,
			isDeleting: false
		};
		this.onContextButtonClick = this.onContextButtonClick.bind(this);
		this.onDeleteWorkflow = this.onDeleteWorkflow.bind(this);
		this.onSelectedItem = this.onSelectedItem.bind(this);
		this.onConfirmDelete = this.onConfirmDelete.bind(this);
		this.onCancelDelete = this.onCancelDelete.bind(this);
		this.onMessageBoxAction = this.onMessageBoxAction.bind(this);
	}

	async get_workflows() {
		const workflows = await workflow.get_user_workflows(null, {
			fields: ['name', 'permissions']
		});

		return (workflows.workflows);
	}

	async deleteWorkflow(workflow_id) {
		const deleted_workflow = await workflow.delete_workflow(null, workflow_id);
		return (deleted_workflow);
	}

	onDeleteWorkflow() {
		this.setState({ isDeleting: true });
	}

	onCancelDelete() {
		this.setState({ isDeleting: false });
	}

	onMessageBoxAction(action) {
		if (action === MessageBoxActions.Primary) {
			this.onConfirmDelete();
			return;
		}
		this.onCancelDelete();
	}
	async onConfirmDelete() {
		await workflow.delete_workflow(null, this.state.selectedWorkflow);
		this.props.history.push("/activity/workflows");
	}

	onSelectedItem(id) {
		this.setState({ selectedWorkflow: id })
	}

	async onContextButtonClick(key) {
		switch (key) {
			case DefaultContextButtons.New.key:
				return this.props.history.push("/activity/workfloweditor");
			case DefaultContextButtons.Edit.key:
				return this.props.history.push("/activity/workfloweditor/" + this.state.selectedWorkflow);
			case DefaultContextButtons.Delete.key:
				this.onDeleteWorkflow();
				break;
			default:
				break;

		}
	}

	render() {

		let show_buttons = true;
		if (this.props.show_buttons !== undefined) {
			show_buttons = this.props.show_buttons;
		}

		return (<AppChrome
			center={<div className="edit-document">
				<TemplateDocSelector
					action='Edit'
					display_type='Workflow'
					content_type='workflows'
					header_type='Workflow'
					getChildren={this.get_workflows}
					onSelectItem={this.onSelectedItem}
					show_buttons={show_buttons}
				/>
				<MessageBox
					type={"question"}
					visible={this.state.isDeleting}
					message={"Are you sure you wish to delete this workflow"}
					primaryAction={"Yes"}
					secondaryAction={"No"}
					onActionClick={this.onMessageBoxAction}
					onClose={this.onCancelDelete} />
			</div>}
			mastHeadContextButtons={ContextButtonFactory([
				{ ...DefaultContextButtons.Edit, label: "Edit Workflow", disabled: this.state.selectedWorkflow === undefined },
				{ ...DefaultContextButtons.Delete, label: "Delete Workflow", disabled: this.state.selectedWorkflow === undefined },
				{ ...DefaultContextButtons.New, label: "New Workflow" }
			], this.onContextButtonClick)}
		/>)
	}
}

export default WorkflowSelector;
