import {ElementValueType} from "../interfaces/ElementValueType";
import {TypedActionWithValue} from "../interfaces/ActionRun";
import {mapBodyToElementValue} from "../utilities/data/mapBodyToElementValue";
import {getTemplateApi} from "./api/getTemplateApi";
import {useAsync} from "./useAsync";
import {LoadEntityFunc} from "../interfaces/LoadEntityFuncType";

function useLoadNestedElements(elementType: string, templateLoader: LoadEntityFunc<KaiAlphaTemplate> = getTemplateApi): TypedActionWithValue<ElementValueType[], ElementValueType[] | null> {

	function filterTemplateBodyToElementType(template: KaiAlphaTemplate | null, type: string): Promise<ElementValueType[]> {
		return new Promise((resolve, reject) => {
			if (template === null) {
				resolve([]);
				return;
			}
			try {

				const elements = mapBodyToElementValue(template.body)
					.filter(element => element.type === type);

				resolve(elements);
			} catch(e) {
				reject(e);
			}
		})
	}
	function mapAndFilterTemplate(template: KaiAlphaTemplate | null): Promise<ElementValueType[]> {
		return filterTemplateBodyToElementType(template, elementType);
	}

	function extractSubTemplateElements(template: KaiAlphaTemplate | null): Promise<ElementValueType[]> {
		return filterTemplateBodyToElementType(template, "template");
	}

	async function filterBody(elements?: ElementValueType[]): Promise<ElementValueType[]> {
		if (elements) {
			const validElements = await Promise.all(elements
				.filter(item => item.type === elementType || item.type === "template")
				.map(async element => {
					if (element.type === elementType) {
						return Promise.resolve([element]);
					}

					const templateInfo = element.data as {id: string, version?: string};
					const template = await templateLoader({id: templateInfo.id, version: templateInfo.version});

					const validTemplateElements = await mapAndFilterTemplate(template);

					if (validTemplateElements.length > 0) {
						return validTemplateElements;
					}

					const subTemplates = await extractSubTemplateElements(template);
					return subTemplates.length === 0 ? [] : filterBody(subTemplates);
				}));

			return validElements.reduce((all, current) => all.concat(current), []);
		}

		return [];
	}

	const findElements = useAsync(filterBody, false);

	return {
		run: findElements.execute,
		isRunning: findElements.isLoading,
		value: findElements.value
	}
}

export {useLoadNestedElements};