import {
	areQuotesBalanced,
	didExcludePredecessors,
	didIncludePredecessors,
	didTriggerMatch,
	getPreviousWord,
	getCurrentWord
} from "./utils";
import {AutocompleteConfig} from "./models";
import {nunjucksConstants} from "../../../nunjucks/models/NunjucksConstants";


const defaultConfig: AutocompleteConfig[] = [
	{
		trigger: ' ',
		options: nunjucksConstants.operators,
		excludePredecessors: [...nunjucksConstants.operators, undefined]
	},
	{
		trigger: '|',
		options: nunjucksConstants.filters!
	}
];

export interface UseExpressionBuilderProps {
	autoCompleteConfig: AutocompleteConfig[]
}

const useExpressionBuilder = (props: UseExpressionBuilderProps) => {

	const suggestItems = (value: string): string[] | undefined => {
		/*
		 * Do not suggest when quotes are unbalanced,
		 * indicates that the user is typing a string,
		 */
		if (areQuotesBalanced(value)) {
			const previousWord = getPreviousWord(value);
			let autoCompleteConfig = props.autoCompleteConfig;

			if (autoCompleteConfig === undefined) {
				autoCompleteConfig = defaultConfig;
			}

			value = value.length > 1 ? value.replace(/\s/gm, "") : value;

			for (const config of autoCompleteConfig) {
				if (didTriggerMatch(config.trigger, value, previousWord)
					&& didExcludePredecessors(config.excludePredecessors, previousWord)
					&& didIncludePredecessors(config.includePredecessors, previousWord)
					&& config.options !== undefined) {
					return config.options;
				}
			}
			/**
			 * when no suggestions are available
			 */
			return [];
		}

		return undefined
	}

	const filterOptions = (options, { inputValue }: {inputValue: string}) => {
		let items = suggestItems(inputValue);
		if (items === undefined) {
			return([]);
		}

		items = items.map(item => item.toLowerCase());

		const currentWord = getCurrentWord(inputValue);

		const nunjuckWordFilter = items.filter((char) => {
			return char.indexOf(currentWord.toLowerCase()) !== -1;
		});

		/*
		If No Word matches Nunjucks Expressions Determine AND Not Trigger Operator
		Grab everything before user open parenthesis
		 */
		const isTriggerOperator = nunjucksConstants.operators.some( operator => currentWord === operator)
		if (nunjuckWordFilter.length === 0 && !isTriggerOperator) {
			// Split And grab only initial word before open parenthesis
			nunjuckWordFilter.push(currentWord.split('(')[0])
		}

		return nunjuckWordFilter
	}


	return {
		filterOptions
	}
}

export {useExpressionBuilder}