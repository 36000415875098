import {ElementPropertyData} from "../../model";
import {iconForElementType, readonlyViewForElementType} from "../../index";
import {FlexBox} from "../../../../../../shared/components";
import {Box, styled} from "@mui/material";
import {ForwardedRef, forwardRef} from "react";


export type ReadonlyViewProps = ElementPropertyData &
	{className?: string, depth?: number} & {renderFallback?: () => JSX.Element}

const Container = styled(FlexBox)({
	"& .buttons": {
		display: "none",
	},
	"&:hover .buttons": {
		display: "flex",
		margin: ".5rem 0 -.5rem 0"
	}
})

const View = (props: ReadonlyViewProps, ref: ForwardedRef<HTMLDivElement>) => {
	const View = readonlyViewForElementType(props.elementInformation?.type);
	const Icon = iconForElementType(props.elementInformation?.type);

	const classNames = [props.className, "template-editor__elements-preview"].join(" ");

	return View ? <Container ref={ref} direction={"column"} sx={{boxSizing: "border-box"}}  className={classNames}>
		<FlexBox className={"template-editor__elements-view"}>
			<Box sx={{mr: "20px"}}>
				<Icon />
			</Box>
			<Box width={"100%"} id={props.id}>
				<View {...props} />
			</Box>
		</FlexBox>
	</Container> : props.renderFallback ? props.renderFallback() : null
}

const ElementView = forwardRef<HTMLDivElement, ReadonlyViewProps>(View)
export {ElementView}