import {StylePropertyFactory} from "./model";
import {
	HangingIndent,
	LineHeight,
	PaddingBottom,
	PaddingLeft,
	PaddingRight,
	PaddingTop,
	StyleAttribute
} from "../../../editor/elements/styles";

type SupportedAttributes = PaddingRight | PaddingTop | PaddingBottom | PaddingLeft | LineHeight | HangingIndent;
type MappedAttributes = SupportedAttributes

const attributeMap: Record<MappedAttributes, (value: Record<SupportedAttributes, string>) => string> = {
	line_height: value => `line-height: ${value.line_height} !important`,
	padding_left: value => `padding-left: ${value.padding_left}in !important`,
	padding_right: value => `padding-right: ${value.padding_right}in !important`,
	padding_top: value => `padding-top: ${value.padding_top}pt !important`,
	padding_bottom: value => `padding-bottom: ${value.padding_bottom}pt !important`,
	hanging_indent: value =>
		`padding-left: ${(value.padding_left ? Number(value.padding_left) + Number(value.hanging_indent) : value.hanging_indent)}in !important;
		 text-indent: -${value.hanging_indent}in !important`
}
function factory(styleMap: Partial<Record<StyleAttribute, string>>): string {
	const css = Object.keys(styleMap).map(key => {
		if(styleMap[key] !== undefined) {
			const valueFunc = attributeMap[key];
			return valueFunc ? valueFunc(styleMap) : "";
		}
		return "";
	}).filter(value => value.length > 0).join(";");
	return css.length === 0 ? "" : "{" + css + ";}";
}

const spacingStyleFactory: StylePropertyFactory = factory;
export {spacingStyleFactory};