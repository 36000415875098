import { Box } from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";
import { ThemeButtonProps } from "./ThemeButton.models";
import { BaseButton } from "../BaseButton";
import { CenteredContainer } from "../../layout"
export interface Context {
	Icon: SvgIconComponent,
	label: string,
	key: string, // for onClick identification,
	disabled?: boolean,
	active?: boolean
}

export interface ContextButtonProps extends ThemeButtonProps, Context {}

const ContextButton = ({Icon, label, onClick, disabled, active}: ContextButtonProps) => {
	return (
		<CenteredContainer>
			<Box className={`context-menu-item`}>
				<BaseButton onClick={onClick} disabled={disabled ?? false} className={`${active ? " active" : ""}`}>
					<Icon style={{fontSize: '30px'}}/>
					<span>{label}</span>
				</BaseButton>
			</Box>
		</CenteredContainer>)
}

const ContextButtonFactory = (buttons: Context[], onClick) =>
	<>
		{buttons.map((button) => <ContextButton key={button.key} Icon={button.Icon} active={button.active} label={button.label} disabled={button.disabled} onClick={() => onClick(button.key)}/>)}
	</>

export {ContextButton, ContextButtonFactory}
