import { LoopElementValue } from "../../../../../../shared/definitions/elements/loop/model";
import {ReadonlyElementProps} from "../../model";
import {PropertyList} from "../../PropertyList";

const ReadonlyView = (props: ReadonlyElementProps) => {
	const value = props.data as LoopElementValue;
	const properties = [{label: "Name", value: value.name}, {label: "Expression", value: value.expression}];
	return <PropertyList items={properties} />
}

export {ReadonlyView};