import {VersionDropDown} from "../../input";
import { useVersionSelector} from "./useVersionSelector";
import {useRunWhenValueChange} from "../../../hooks";
import {VersionType} from "../../input/dropdown/versionSelector";
import {SnackBar} from "../../notifications/snackbar/SnackBar";
import {VersionNotification} from "./VersionNotification";
import {VersionNotificationAction} from "./VersionNotificationAction";
import {EntityRecord} from "../../../interfaces/Entity";

export interface VersionSelectorProps {
	id?: string,
	versionId?: string,
	loaded: boolean,
	type: 'document'| 'template',
	refresh?: boolean,
	onSelectVersion: (entity: Required<EntityRecord>) => void
}

const VersionSelector = ({id, versionId, loaded, type, refresh, onSelectVersion}: VersionSelectorProps) => {
	const {
		loadMajorVersions,
		isLoading,
		versionList,
		viewingOlderVersion,
		selectedValue } = useVersionSelector(type, versionId);

	useRunWhenValueChange(() => {
		if ((id && loaded && versionId !== "@new") || refresh) {
			loadMajorVersions(id!)
		}
	}, [ versionId, loaded, id, refresh]);

	const onNavigateToHead = () => navigateToVersion("HEAD");

	const navigateToVersion = (version: "HEAD" | string) => onSelectVersion({id: id!, version: version ?? "HEAD"})

	const onVersionSelect = (version: VersionType) => {
		navigateToVersion(version.version)
	}

	return (!id && !versionId) ? null : (
		<div>
			{selectedValue && loaded && <SnackBar open={viewingOlderVersion} content={<VersionNotification version={selectedValue} />} action={<VersionNotificationAction onClick={onNavigateToHead} />} />}
			<VersionDropDown isLoading={isLoading || !loaded} versions={versionList ?? []} value={selectedValue} onVersionSelect={onVersionSelect} />
		</div>
	);
}

export {VersionSelector};