import { styled, Box } from "@mui/material";
import { FlexBox } from "../../../../components";

const ContributorListItem = styled(FlexBox)(({theme}) => ({
	padding: "5px 0px 11px 0px",
	margin: "7px 0px",
	borderBottom: `1px solid ${theme.palette.variable?.background}`,
}));

const ContributorListItemName = styled(Box)(({theme}) => ({
	fontSize: ".95rem",
	color: theme.palette.tabs?.hover,
}));

const ContributorListItemRole = styled(Box)(({theme}) => ({
	fontSize: ".85rem",
	color: theme.palette.destructive?.main,
	textAlign: 'right',
}));

export {
	ContributorListItem,
	ContributorListItemName,
	ContributorListItemRole
}