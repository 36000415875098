import {useEffect, useRef} from "react";
import {NunjucksEvents} from "../nunjucks/nunjucksEvents";
import {ElementEvents} from "../definitions/elements/events";

function useEventListener<
	KW extends keyof WindowEventMap,
	KH extends keyof HTMLElementEventMap,
	T extends HTMLElement | void = void,
> (eventName: KW | KH | NunjucksEvents | ElementEvents, handler: (
	event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event,
) => void, element: Window | T = window)  {
	// Create a ref that stores handler
	const savedHandler = useRef<(
		event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event,
	) => void>();

	// Update ref.current value if handler changes.
	// This allows our effect below to always get latest handler ...
	// ... without us needing to pass it in effect deps array ...
	// ... and potentially cause effect to re-run every render.
	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);
	useEffect(
		() => {
			// Make sure element supports addEventListener
			// On
			const isSupported = element && element.addEventListener;
			if (!isSupported) return;
			// Create event listener that calls handler function stored in ref
			let eventListener: EventListenerOrEventListenerObject;
			if (savedHandler.current) {
				eventListener = (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event) => savedHandler.current!(event);
				// Add event listener
				element.addEventListener(eventName, eventListener);
			}
			// Remove event listener on cleanup
			return () => {
				if (eventListener) {
					element.removeEventListener(eventName, eventListener);
				}
			};
		},
		[eventName, element] // Re-run if eventName or element changes
	);
}

export {useEventListener}