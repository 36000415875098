import { KeyboardArrowDown, Schedule } from "@mui/icons-material";
import { formatDistance } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FlexBox } from "../../../shared/components";
import { WorkflowChip } from "../../../shared/components/labels/WorkflowChip";
import { FilterTabs } from "../../../shared/components/layout/filterTabs/FilterTabs";
import { useRunWhenValueChange } from "../../../shared/hooks";
import { Task } from "../../../shared/interfaces/task";
import { moduleLinkGenerator } from "../../moduleNavigation";
import { DashboardCardGroup } from "./DashboardCardGroup";
import { FilteredTasksState, initialState, sourcesMap, tabs, TaskGroupLayoutProps } from "./TaskGroupLayout.models";
import { ItemText, TasksExpiringHeader, TasksExpiringList, TasksExpiringListItem, ItemDateText } from "./TaskGroupLayout.style";

const TaskGroupLayout = ({ tasks }: TaskGroupLayoutProps) => {
	const [selectedTab, setSelectedTab] = useState(0);
	const [currentTime] = useState(new Date()); // why is this state?
	const navigate = useNavigate();

	const [totals, setTotals] = useState<FilteredTasksState>(initialState);

	useRunWhenValueChange(() => {
		if (!tasks) {
			return;
		}

		// since server returns random order, we need to sort
		const sortedTasks = tasks.tasks.filter(task => task.timeout?.at !== undefined)
			.sort((a: Task, b: Task) => b.timeout!.at!.getTime() - a.timeout!.at!.getTime());

		const filteredTasks = sortedTasks.reduce((totalsObject: FilteredTasksState, task: Task) => {
			task.timeout!.at! < currentTime ? totalsObject.overdue.push(task) : totalsObject.due.push(task);
			return totalsObject;
		}, {
			all: [],
			due: [],
			overdue: [],
		} as FilteredTasksState)

		const overduePriority = filteredTasks.overdue.reverse();
		setTotals({
			due: filteredTasks.due,
			// overdue tasks need to show the most overdue first.
			overdue: overduePriority,
			// all tasks need to show overdue first, then due after.
			all: overduePriority.concat(filteredTasks.due),
		});
	}, tasks)

	return <DashboardCardGroup
		title="Tasks"
		cards={[{
			header: {
				Icon: Schedule,
				title: 'Tasks Due',
				component: <FilterTabs
					maxWidth="20rem"
					selectedTabIndex={selectedTab}
					tabs={tabs.map((tab) => ({
						...tab,
						total: totals[tab.type].length,
					}))}
					onTabSelected={setSelectedTab}
				/>
			},
			content: <FlexBox boxSizing="border-box" padding=".7rem" direction="column">
				<TasksExpiringHeader>
					<FlexBox flex={0.3} boxSizing="border-box" paddingLeft="1.6rem">
						<span>Days until due</span>
					</FlexBox>
					<FlexBox flex={0.7}>
						<span>Task</span>
					</FlexBox>
				</TasksExpiringHeader>
				<TasksExpiringList>
					{tasks && totals[tabs[selectedTab].type].sort((a, b) => a.timeout.at - b.timeout.at).map((task, idx) => {
						const overdue = task.timeout.at < currentTime;
						return <TasksExpiringListItem
							className="single_item"
							key={`${task.source.id}-${idx}`}
							onClick={() => navigate(moduleLinkGenerator(sourcesMap[task.source.type], "edit", task.source.id))}
							id={task.source.id}
						>
							<FlexBox flex={0.3} boxSizing="border-box" paddingLeft="1.6rem">
								<ItemDateText overdue={overdue}>
									{`${formatDistance(task.timeout.at, currentTime)} ${overdue ? 'ago' : 'from now'}`}
								</ItemDateText>
							</FlexBox>
							<FlexBox flex={0.7} justify="space-between" align={"center"}>
								<ItemText>
									<span className="title_text">{task.source.name}</span>
								</ItemText>
								<FlexBox width={"auto"} align={"center"} paddingX={"15px"}>
									<FlexBox align="center" paddingX="20px">
										<WorkflowChip state={task.source.state ?? "N/A"} label={`${(task.source.state?.length ?? 0) > 0 ? task.source.state : "N/A"}`} />
									</FlexBox>
									<KeyboardArrowDown sx={{transform: 'rotate(-90deg)'}}/>
								</FlexBox>
							</FlexBox>
						</TasksExpiringListItem>
					})}
				</TasksExpiringList>
			</FlexBox>
		}]}
	/>
}

export { TaskGroupLayout };