import React from 'react';
import './ElementsDialogBox.css';
import { Dialog } from '../../lib/ui';
import Grid from '@mui/material/Grid';
import validation_utils from '../../lib/utils/validation_utils';

const images = require.context('../../images', true);

// TODO remove and refactor this
class ElementsDialogBox extends React.Component {
	elements = validation_utils.structure.element_info;

	constructor(props) {
		super(props);
		this.state = {
			dialog_open: true,
		};
	}

	handle_close = () => {
		this.props.close_dialog();
		this.setState({ dialog_open: false });
	};

	add_component = () => {
		let element_name = this.state.current_element;
		if (element_name === 'instructional text') {
			element_name = 'comment';
		} else {
			element_name = element_name.replaceAll(" ", "_");
		}

		this.props.add_element(element_name);
		this.setState({ dialog_open: false });
		this.props.close_dialog();
	};

	render() {
		return (
			<>
				<Dialog
					open={this.state.dialog_open}
					onClose={this.handle_close}
					title={'Element Library'}
					buttons={{
						'Close': this.handle_close,
						'Add': this.add_component,
					}}
				>
					<Grid container spacing={2}>
						{this.elements.map((element) =>
							<Grid item xs={3} key={element.name}>
								<div id={element.name} data-testid={element.name} className={'dialog-element-container' + (this.state.current_element === element.type.toLowerCase() ? ' highlight' : '')} onClick={() => this.setState({ current_element: element.type.toLowerCase() })}>
									<div className='dialog-element-image-container'>
										<div className='dialog-element-image'>
											{element.icon !== undefined && <img alt="dialog-icon" src={images(`./${element.icon}`).default} />}
										</div>
									</div>
									<div className='dialog-element-detail-container'>
										<div className='dialog-element-title'>{element.name}</div>
										<div className='dialog-element-sub-title'>{element.description}</div>
									</div>
								</div>
							</Grid>
						)}
					</Grid>
				</Dialog>

			</>
		);
	}
}

export default ElementsDialogBox;
