import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {useGetAllComments} from "../../api/comments/useGetAllComments";
import {CommentState} from "../../useCommentState";
import {useStatusBar} from "../../../../../shared/components";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";
import {EntityRecord} from "../../../../../shared/interfaces/Entity";

const useGetAllCommentsAction = (): TypedAction<EntityRecord> => {
	const commentManager = CommentState.useContainer()
	const statusBar = useStatusBar();

	const getAllComments = useGetAllComments();

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error retrieving template comments", detail: getAllComments.error});
	}, getAllComments.error);

	useRunWhenValueChange(() => {
		if (getAllComments.value) {
			// when comments are deleted they are just given a true for the deleted flag
			// meaning we always have to filter on them when doing the GET call
			commentManager.setComments(getAllComments.value.filter((value) => !value.deleted));
		}
	}, getAllComments.value);

	const run = ({id, version}: EntityRecord) => getAllComments.execute(id, version);
	return {run, isRunning: getAllComments.isLoading}
}

export {useGetAllCommentsAction};