import { Box, Typography } from "@mui/material";
import {DragDropArea, DragDropResult} from "../../../../../shared/components";
import {useElements, TemplateState} from "../../../hooks";
import {EditableView} from "../../elements/EditableView";
import {EditableElementChangeEvent} from "../../elements/model";
import {ReadonlyView} from "../../elements/ReadonlyView";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import './style.scss';

interface ElementToDivMap {
	[k: string]: HTMLDivElement | null
}

const EditorCenterPane = () =>{

	const templateManager = TemplateState.useContainer();
	const elementsManager = useElements();

	const writable = templateManager.editable;
	const template = templateManager.buffer;
	const templateName = template?.name ?? "";
	const elementRefs: ElementToDivMap = {};

	useRunWhenValueChange(() => {
		if (templateManager.selectedElement === null) {
			return;
		}

		elementRefs[templateManager.selectedElement.id]?.scrollIntoView({
			behavior: "smooth",
		});
	}, templateManager.selectedElement);


	const onChangeElement = (event: EditableElementChangeEvent) =>
		elementsManager.changeElement(event, event.id);

	const onDeleteElement = (elementId: string) => {
		const element = elementsManager.findById(elementId);
		if (!element) {
			return;
		}

		elementsManager.onRemoveElement(element!);
	}

	const onDragEnd = (result: DragDropResult) => {
		elementsManager.onDragDrop(result, elementsManager.elements);
	}

	return (
		<Box sx={{pb: "20px"}} className={"template-editor"}>
			<Box style={{padding: '2.5rem 1rem 1rem', marginLeft: "3rem"}}>
				<Typography variant="h4">{templateName}</Typography>
			</Box>
			<DragDropArea onDragEnd={onDragEnd} isDragDisabled={!writable}>
				{elementsManager.elements && elementsManager.elements.map(element => {
					return writable ? <EditableView
						id={element.id}
						depth={element.depth}
						key={element.id}
						ref={el => (elementRefs[element.id] = el)}
						elementInformation={{type: element.type, writable}}
						data={element.contents}
						onChange={onChangeElement}
						onDelete={onDeleteElement}
					/> : <ReadonlyView
						id={element.id}
						depth={element.depth}
						key={element.id}
						ref={el => (elementRefs[element.id] = el)}
						elementInformation={{type: element.type, writable}}
						data={element.contents} />
				})}
			</DragDropArea>
		</Box>
	)
}

export { EditorCenterPane }