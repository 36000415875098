import React from 'react';
import MaterialTable from 'material-table'; // TODO: Get rid of this at some point.

/*
 * Icons for MaterialTable
 */
import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from '@mui/icons-material'

const tableIcons = {
	Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: React.forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function MaterialTableStyled(props) {
	if (props.data !== undefined) {
		if (!Array.isArray(props.data) && typeof(props.data) !== 'function') {
			console.error('[ERROR] MaterialTable invoked with incorrect props.data type:', {props});
			return(null);
		}
	}
	return <MaterialTable icons={tableIcons} {...props} />
}

export default MaterialTableStyled;
