import {DocumentElementDefinition} from "../model";
import {
	styleDefinition as baseStyleDefinition
} from "../../../../../shared/definitions/elements/style/model";
import {PreviewView} from "./views/preview/PreviewView";

const styleDefinition: DocumentElementDefinition = {
	...baseStyleDefinition,
	views: {
		preview: PreviewView
	},
} as const

export {styleDefinition};