import { FlexBox } from "../../../shared/components";
import {DashboardCard, DashboardCardProps} from './DashboardCard';
import {DashboardCardGroupContainer, DashboardCardGroupTitle} from './DashboardCardGroup.style';
export interface DashboardCardGroupProps {
	title: string,
	cards: DashboardCardProps[],
	flex?: string,
}

const DashboardCardGroup = ({title, cards, flex} : DashboardCardGroupProps) => {
	return <DashboardCardGroupContainer flex={flex}>
		<DashboardCardGroupTitle>{title}</DashboardCardGroupTitle>
		<FlexBox flexWrap="wrap">
			{cards.map(c => <DashboardCard {...c} key={c.header.title}/>)}
		</FlexBox>
	</DashboardCardGroupContainer>
}

export {DashboardCardGroup};