import {Box, styled } from "@mui/material";
import { alpha } from '@mui/material/styles';

const GroupedContentContainer = styled(Box)({
	padding: "10px 15px",
	boxSizing: "border-box",
	width: "100%",
	position: "relative"
});

const EmptyGroupContainer = styled(Box)(({theme}) => ({
	width: '100%',
	color:  alpha(theme.palette.plain?.border ?? "", 0.8),
	paddingTop: "25px",
	fontSize: '1.4em',
}));

const ContextButtonContainer = styled(Box)({
	margin: "0px 5px",
});

const Title = styled('h3')(({theme}) => ({
	fontSize: "18px",
	margin: "5px 0px",
	whiteSpace: 'nowrap',
	color: theme.palette.variableHighlight?.main,
	cursor: 'pointer',
}));

const Hr = styled("hr")(({theme}) =>({
	height: "2px",
	background: alpha(theme.palette.plain?.border ?? "", 0.6),
	width: "100%",
	border: 0,
}));

export {
	GroupedContentContainer,
	ContextButtonContainer,
	EmptyGroupContainer,
	Title,
	Hr,
}
