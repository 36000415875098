import {ElementIconProps, IconDefaults} from "../model";

const ReferenceIcon = ({color}: ElementIconProps) => {
	const style = {
		fill: "none",
		stroke: color ?? IconDefaults.color!,
		"stroke-linecap": "round",
		"stroke-line-join": "round",
		"stroke-width": "1.5px"
	}
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="9.258" viewBox="0 0 17.5 9.258">
			<defs>
				<style>{`.a{fill:none;stroke:${color ?? IconDefaults.color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}`}</style>
			</defs>
			<g transform="translate(-0.75 -9.75)">
				<path style={style} d="M11.682,10.5h2.182A3.763,3.763,0,0,1,17.5,14.379a3.763,3.763,0,0,1-3.636,3.879H11.682m-4.364,0H5.136A3.763,3.763,0,0,1,1.5,14.379,3.763,3.763,0,0,1,5.136,10.5H7.318" transform="translate(0 0)"/>
				<path style={style} d="M12,18h8" transform="translate(-6.5 -3.621)"/>
			</g>
		</svg>
	)
}

export {ReferenceIcon}