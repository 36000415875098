import {PreviewElementProps} from "../../model";
import {LoopElementValue} from "../../../../../../shared/definitions/elements/loop/model";
import {useState} from "react";

import {PreviewView as PreviewViewFactory} from "./../../PreviewView";
import {mapBodyToPreviewElementProps} from "../../../modals/preview/PreviewSlideOver";
import {Variable} from "../../../../../../shared/interfaces/Variable";
import {useRunWhenValueChange} from "../../../../../../shared/hooks";
import {DataDefinition, DataSourceObjectType} from "../../../../../../shared/interfaces/DataSource";
import {executeExpression} from "../../../../../../shared/utilities/data/executeExpression";

type ExpressionResultType = DataSourceObjectType | string[] | number[];

const PreviewView = (props: PreviewElementProps) => {
	const loopValue = props.data as LoopElementValue;
	const [expressionResult, setExpressionResult] = useState<string[] | number[] | DataDefinition[]>([]);
	const [body, setBody] = useState<PreviewElementProps[]>([]);

	useRunWhenValueChange(() => {
		const expression = loopValue.expression ? `${loopValue.expression} | stringify` : "";
		const executionResult = executeExpression(expression, props.variables);

		const jsonResult = JSON.parse(executionResult.result) as ExpressionResultType;
		const mappableValue = Array.isArray(jsonResult) ? jsonResult : jsonResult.data !== undefined && Array.isArray(jsonResult.data) ? jsonResult.data : [];
		setExpressionResult(mappableValue);

	}, [props.data, props.variables]);

	useRunWhenValueChange(() => {
		setBody(expressionResult.length > 0 ?
			mapBodyToPreviewElementProps(loopValue.body) :
			mapBodyToPreviewElementProps(loopValue.else?.body));
	}, expressionResult);

	if (expressionResult.length === 0) {
		return <>{body.map(bodyItem => <PreviewViewFactory type={bodyItem.type} id={bodyItem.id} data={bodyItem.data} body={props.body} variables={props.variables} />)}</>;
	}

	return <>
		{expressionResult.map((item) => {
			const variable = {name: loopValue.name, value: item} as Variable;
			const variables = [...(props.variables ?? []), variable];
			return <>
				{body.map(bodyElement => <PreviewViewFactory type={bodyElement.type} id={bodyElement.id} data={bodyElement.data} body={props.body} variables={variables} />)}
			</>
		})}
	</>;
}

export {PreviewView};