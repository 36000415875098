import {ColumnDefinition, Table} from "../../../../../shared/components/layout/table";
import {SubmissionListRow} from "./SubmissionListRow";
import {SubmissionNode} from "../../../models";
import {accessoryForColumn, columns} from "./columns";
import {FilterState} from "../../../../../shared/components/input/filter/sort/models";
import React from "react";


export interface SubmissionListProps {
	submissions: SubmissionNode[],
	filters?: {[field: string]: string},
	sort: {field: string, sortDirection: FilterState},
	onPageChange: (event: unknown, newPage: number) => void,
	onPageSizeChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
	onColumnSearch: (field?: string, value?: string) => void,
	onDelete: (contentNode: SubmissionNode) => void,
	onSort: (field: string, sortDirection: FilterState) => void,
	onRowSelect: (contentNode: SubmissionNode) => void,
	onDownloadZip: (contentNode: SubmissionNode) => void,
	onViewError: (contentNode: SubmissionNode) => void,
	pageSize: number,
	page: number,
	total: number
}

const SubmissionList = (props: SubmissionListProps) => {
	const {submissions, sort, page, pageSize, total, filters, onPageChange, onPageSizeChange, onColumnSearch, onDelete, onSort, onDownloadZip, onViewError} = props
	const onRenderRow = (item: unknown, rowIndex: number) => {
		return <SubmissionListRow
			item={item}
			key={(item as SubmissionNode).id}
			rowIndex={rowIndex}
			onDeleteContent={onDelete}
			onDownloadZip={onDownloadZip}
			onViewError={onViewError}
			onRowSelected={props.onRowSelect}
			columns={columns} />
	}


	const handleColumnAccessoryRender = (column: ColumnDefinition) => {
		const Accessory = accessoryForColumn(column.field);
		return Accessory ? <Accessory column={column} /> : null
	}

	return <Table
		items={submissions}
		columns={columns}
		title={"Submissions"}
		sort={sort}
		total={total}
		currentPage={page}
		pageSize={pageSize}
		filters={filters}
		onColumnSearch={onColumnSearch}
		onSort={onSort}
		onRenderRow={onRenderRow}
		onRenderColumnAccessory={handleColumnAccessoryRender}
		onChangePage={onPageChange}
		onChangePageSize={onPageSizeChange}
	/>
}

export {SubmissionList}