import {NunjucksHelp} from "../filters/help/model";
import {compileDocumentation} from "../filters/help/compileDocumentation";
import {nunjucksFilterFactories, nunjucksGlobalFilterFactories} from "../filters";

export type NunjucksConstants = {
	help: NunjucksHelp,
	operators: string[],
	filters: string[],
	customFilters: string[],
	any: string
}

const helpDocumentation = compileDocumentation();
export const nunjucksConstants: NunjucksConstants = {
	help: helpDocumentation,
	operators: Object.keys(helpDocumentation.operators),
	filters: Object.keys(helpDocumentation.filters),
	customFilters: [...nunjucksFilterFactories, ...nunjucksGlobalFilterFactories].map(f => f().name),
	any: "any_a3d9a401ff07cff669c0ca18636b3fec"
}