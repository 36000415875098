import React from "react";
import {GenericEditor} from "./GenericEditor";
import {CompoundEditor} from "./CompoundEditor";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material";
import {IndicationsEditor} from "./IndicationsEditor";
import {SectionEditor} from "./SectionEditor";
import {SubstanceEditor} from "./SubstanceEditor";
import {ProductEditor} from "./ProductEditor";
import {StudyIdEditor} from "./StudyIdEditor";

export type ValueEditorProps = {
	data: {
		value: string,
		options?: unknown
	}
	onChange: (value: string) => void,
	sx?: SxProps<Theme>
}
export type ValueEditor = {
	type: string,
	view: React.FC<ValueEditorProps>,
	toString: (value: unknown) => string
}

export type MetaDataValueEditor = {
	view: React.FC<ValueEditorProps>,
	displayTextGenerator: (value: string) => string,
	defaultValueFactory: () => string,
}
export const metaDataKeys = ["Compound", "Indication", "eCTDSection", "StudyId", "StudyTitle", "SiteId", "Substance", "Product", "MaterialId", "Container", "Excipient", "Facility", "Component"] as const;
export const valueEditorMap: Record<typeof metaDataKeys[number], MetaDataValueEditor> = {
	Compound: CompoundEditor,
	Indication: IndicationsEditor,
	eCTDSection: SectionEditor,
	"StudyId": StudyIdEditor,
	"StudyTitle": GenericEditor,
	"SiteId": GenericEditor,
	Substance: SubstanceEditor,
	Product: ProductEditor,
	"MaterialId": GenericEditor,
	Container: GenericEditor,
	Excipient: GenericEditor,
	Facility: GenericEditor,
	Component: GenericEditor
};


/*
Compound: value (input/dropdown) restricted to list of compounds
Indication: value (input/dropdown) restricted to list of indications
eCTD Section: value (input/dropdown) restricted to eCTD sections
Study Id: value (input) no restriction
Site Id: value (input) no restriction
Substance: value**
Product: value***
Material Id: value (input) no restriction
Container: value (input) no restriction
Excipient: value (input) no restriction
Facility: value (input) no restriction
Component: value (input) no restriction
 */