import {styled} from "@mui/material";

const ListTitle = styled('div')({
	fontWeight: "700",
	padding: "5px",
	backgroundColor: "lightgrey",

	div: {
		width: "100%"
	},

	input: {
		width: "90%"
	}
})

const BoxPadding = styled('span')({
	padding: "5px"
})

export {ListTitle, BoxPadding}