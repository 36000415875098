import {createContainer} from "unstated-next";
import { useState } from "react";
import { useRunWhenValueChange } from "../../../shared/hooks";
import { computeUserRoles, hasAccess, hasRoles, NegatedRole, Role, Roles } from "../../../shared/interfaces/Permissions";
import { UserManager } from "../../../shared/hooks/useUser";
import { DocumentElement, DocumentTreeElement } from "../models/element";
import { ElementMaps } from "../components/elements/model";
import { Variable } from "../../../shared/interfaces/Variable";
import {DocumentCollection} from "../models/documentCollection";
import { isEmptyObject } from "../../../shared/utilities/isEmptyObject";
import { isApproved, isInReview } from "../../../shared/interfaces/WorkflowState";
import {resetNunjucksEnvironment} from "../../../shared/nunjucks/models/NunjucksEnvironmentOptions";
import {getIttVariablesApi} from "../../../shared/variables/itt/getIttVariablesApi";
import {SystemNamespaceLookup, systemNamespaces} from "../../../shared/variables/SystemNamespaces";
import {ElementValueType} from "../../../shared/interfaces/ElementValueType";
import {numberSections} from "../components/elements/section/numberSections";
import {filterElements} from "../components/elements/utilities/filterElements";
const clone = require("rfdc/default");
const uuid = require("uuid");

type DocumentMutator = (d: KaiAlphaDocument) => KaiAlphaDocument

type SetVariable = {
	isPublishableUpdate ?: boolean
 }

interface UseDocumentState {
	activeSectionId: string | null;
	windowSectionId: string | null;
	scrollingIntoActiveSection: boolean | null;
	disableActiveHighlighting: boolean | null;
	documentEditorView: "default" | "semantic" | null;
	template: KaiAlphaTemplate | null;
	document: KaiAlphaDocument | null;
	buffer: KaiAlphaDocument | null;
	computedUserRoles: Role[] | null;
	variables: Variable[],
	elements: ElementValueType[],
	selectedElement: ElementScroll | null,
	editable: boolean,
	didReset: boolean,
	documentBody: DocumentElement[] | null,
	computedDocumentTree: DocumentTreeElement[] | null,
	commentable: boolean,
	tableOfContents?: ElementValueType[],
	documentCollection: DocumentCollection,
	isLoadingVariables: boolean,
	tempBuffer: KaiAlphaDocument | undefined,
	didChange: boolean,

}

const initialState: UseDocumentState = {
	activeSectionId: null,
	windowSectionId: null,
	scrollingIntoActiveSection: false,
	documentBody: null,
	disableActiveHighlighting: false,
	documentEditorView: "default",
	template: null,
	document: null,
	computedDocumentTree: null,
	buffer: null,
	didReset: true,
	computedUserRoles: [],
	variables: [],
	elements: [],
	selectedElement: null,
	editable: false,
	commentable: false,
	documentCollection: [],
	isLoadingVariables: false,
	tempBuffer: undefined,
	didChange: false
};

interface ElementScroll {
	id: string
}

type PermissionsMutator = (p: KaiAlphaTemplatePermissions) => KaiAlphaTemplatePermissions

export function mapBodyToElementWithTemplate(templateId: string, body?: KaiAlphaDocumentBodyExtend): DocumentTreeElement[] {
	return body?.map(element => {
		const id = Object.keys(element)[0];
		const values = element[id]
		return ({ id, type: values.type as string, data: { ...values, templateId, source: "toplevel" }, source: templateId });
	}) ?? [];
}

export function mapBodyToElement(body?: KaiAlphaDocumentBodyExtend): DocumentElement[] {
	return body?.map(element => {
		const id = Object.keys(element)[0];
		const values = element[id]
		return ({ id, type: values.type as string, data: { ...values, } });
	}) ?? [];
}


function useDocument() {
	const [state, setState] = useState<UseDocumentState>(initialState);
	const user = UserManager.useContainer();

	useRunWhenValueChange(() => {
		if (state.template) {
			const templateElements = mapBodyToElementWithTemplate(state.template.id, state.template!.body);
			// DocumentTree (section object, with 'children')
			// Remap to DocumentTreeItem[], that includes 'children' of the section (if its a template).
			const computedDocumentTree = templateElements.map((element) => element);
			// set both of them in state.
			setState(s => ({ ...s, documentBody: templateElements, computedDocumentTree }));
		}
	}, state.template)

	// populate variables from buffer when updated
	useRunWhenValueChange(() => {
		if (!state.buffer?.variables) {
			return;
		}

		resetNunjucksEnvironment();
		let documentVariables = clone(state.variables) as Variable[];

		if (!isEmptyObject(state!.buffer!.variables)) {
			// means buffer not loaded but we still need to see variables
			const variableNames = Object.keys(state.buffer.variables);
			if(variableNames !== null && variableNames.length > 0) {
				documentVariables.filter(v => v.type !== "datasource" && v.type !== "intexttable").forEach((computedVariable: Variable) => {
					const value = state.buffer!.variables![computedVariable.namespace ?? computedVariable.name];
					if(value !== undefined && value !== null) {
						computedVariable.value = value;
					}
				});

				// system variables will be in buffer but possibly not in document collection
				if (state.buffer?.metadata?.user?.StudyId) {
					const systemVariables = systemNamespaces.map(systemNamespce => variableNames
						.filter(name => name.includes(systemNamespce) && documentVariables
							.filter(dv => dv.namespace === name).length === 0)
						.map(variableName => {
							const nameParts = variableName.split(".");
							return ({
								id: uuid.v4(),
								name: nameParts[nameParts.length - 1],
								namespace: variableName,
								templateId: state.template?.id ?? "",
								type: "system",
								value: state!.buffer!.variables![variableName]
							}) as Variable
						})
					).reduce((all, collection) => all.concat(collection), []);

					documentVariables = documentVariables.concat(systemVariables);
				}
			}

			setState(prevState => ({ ...prevState, variables: documentVariables}));
		}
	}, state.buffer?.variables);

	// update computed userRoles on permissions change.
	useRunWhenValueChange(() => {
		if (state.document === null) {
			return;
		}
		setState(s => ({
			...s,
			computedUserRoles: computeUserRoles(user, state.document!.permissions),
		}));
	}, state.document?.permissions);

	// when metadata changes update variables
	useRunWhenValueChange((prevValue) => {
		if (state.buffer?.metadata?.user) {
			const oldStudyId = prevValue?.StudyId ?? state.document?.metadata?.user?.StudyId;
			if (oldStudyId !== state.buffer?.metadata?.user?.StudyId && oldStudyId !== undefined) {
				updateVariablesWhenStudyIdChanges(oldStudyId, state.buffer?.metadata?.user?.StudyId);
			} else if (oldStudyId === undefined) {
				updateVariablesWhenStudyIdChanges(oldStudyId, state.buffer?.metadata?.user?.StudyId);
			}
		}
	}, state.buffer?.metadata?.user)

	// permissions/visibility calculations
	useRunWhenValueChange(() => {
		if (state.computedUserRoles === null) {
			setState(s => ({
				...s,
				editable: false,
				commentable: false,
			}));
			return;
		}
		// if user has general write access
		if(hasAccess(state.computedUserRoles as Role[], state.document?.permissions.acl?.write as (Role & NegatedRole)[] ?? [])
			|| hasRoles(state.computedUserRoles, [Roles.Owner])) {
			setState(s => ({
				...s,
				editable: true,
			}));
		} else {
			setState(s => ({
				...s,
				editable: false,
			}));
		}
		// if user has comment write access
		if (hasAccess(state.computedUserRoles as Role[], state.document?.permissions.acl?.["comments:write"] as (Role & NegatedRole)[] ?? [])
					|| hasRoles(state.computedUserRoles, [Roles.Owner, Roles.Author])) {
			setState(s => ({
				...s,
				commentable: true,
			}));
		} else {
			setState(s => ({
				...s,
				commentable: false,
			}));
		}
	}, [state.computedUserRoles]);

	useRunWhenValueChange(() => {
		const sectionElements = numberSections(filterElements(state.elements, "section", state.variables));
		setTableOfContents(sectionElements);
	}, state.elements)

	const updateVariablesWhenStudyIdChanges = async (oldStudyId?: string, newStudyId?: string) => {
		if (newStudyId !== undefined) {
			let newVariables: Variable[] = []
			try {
				const ittVariables = await getIttVariablesApi(newStudyId);
				newVariables = Object.keys(ittVariables).map(key => ({
					id: uuid.v4(),
					type: "system",
					value: ittVariables[key].value,
					name: key.toLowerCase(),
					templateId: state.template?.id,
					namespace: `${SystemNamespaceLookup.ittvariables}.${key.toLowerCase()}`
				}) as Variable);

			} catch {
				// do nothing
			}

			if (oldStudyId !== undefined || newStudyId !== undefined) {
				const variables = oldStudyId === undefined ? [...state.variables] : [...state.variables.filter(variable => !variable.name.endsWith(oldStudyId.replace(/-/g, "")) && variable.type !== "system")];
				setVariables([...variables, ...newVariables]);
			}
		} else {
			const variables = state.variables.filter(v => !v.namespace?.includes(SystemNamespaceLookup.ittvariables));
			setVariables([...variables])
		}
	}

	const updateDocumentElements = (mappings: ElementMaps) => {
		const newDocumentTree = [...(state.computedDocumentTree ?? [])];
		const topLevelElementIndex = newDocumentTree.map((element) => element.id).indexOf(mappings.child.id);
		newDocumentTree[topLevelElementIndex] = mappings.child;
		setState(s => ({
			...s,
			computedDocumentTree: newDocumentTree
		}));
	}

	const setComputedUserRoles = (userRoles: any) => {
		setState(prevState => ({...prevState, computedUserRoles: userRoles}));
	}

	const setSyntheticDocument = (syntheticDocument: any) => {
		setState(prevState => ({...prevState, syntheticDocument}));
	}

	const setActiveSectionId = (activeSectionId: string | null) => {
		setState(prevState => ({
			...prevState, activeSectionId
		}))
	}

	const setWindow = (	activeSectionID: string | null,
		windowSectionId: string | null, commentElement: string | null) => {
		setState(prevState =>
			({...prevState,
				activeSectionID,
				windowSectionId,
				commentElement}));
	}

	const setAuditLogOpen = (auditLogOpen: boolean) => {
		setState(prevState => ({...prevState, auditLogOpen}));
	}

	const setDocumentEditorView = (documentEditorView: any) => {
		setState(prevState => ({...prevState, documentEditorView}));
	}

	const setTemplateId = (templateId: string) => {
		setState(prevState => ({...prevState, templateId}));
	}

	const setTempBuffer = (buffer: KaiAlphaDocument) => setState(s => ({...s, tempBuffer: buffer}));

	const setDocumentName = (documentName: string) => {
		const savedDocument = state.document;
		savedDocument!.name = documentName;
		setState(prevState => ({...prevState, document: savedDocument}));

		const savedBuffer = state.buffer;
		if(savedBuffer !== null) {
			savedBuffer!.name = documentName;
			setState(prevState => ({...prevState, buffer: savedBuffer}));
		}
	}

	const setTemplate = (template: any) => {
		setState(prevState => ({...prevState, template}));
	}

	const setDocumentVersion = (version: string) => {
		const document = state.document;
		document!.version = version;
		setState(prevState => ({...prevState, document}));
	}

	const setVariables = (variables: Variable[], {isPublishableUpdate} : SetVariable = {}) => {
		// if isPublishableUpdate is false then setVariables is called on page load
		if(isPublishableUpdate ?? true){
			setState(prevState => ({ ...prevState, variables, didChange:true }));
		}else{
			setState(prevState => ({ ...prevState, variables}));
		}
	}

	const setElements = (elements: ElementValueType[]) => {
		setState(prevState => ({ ...prevState, elements }))
	}

	const setSelectedElementById = (selectedElement: ElementScroll) => {
		setState(s => ({ ...s, selectedElement }));
	}

	/**
	 * sets permissions state on buffer
	 * @param permissionsState {KaiAlphaDocumentPermissions | PermissionsMutator}
	 */
	const setPermissions = (permissionsState: PermissionsMutator | KaiAlphaDocumentPermissions) => {
		const buffer = state.buffer;
		if (!buffer) {
			throw new Error('Permissions cannot be set on a null buffer.');
		}
		const permissions = typeof (permissionsState) === "object" ? permissionsState : permissionsState(buffer!.permissions);
		if (permissions) {
			setState(s => ({
				...s,
				buffer: {
					...buffer,
					permissions,
				},
			}));
		}
	}

	const setDocument = (documentState: DocumentMutator | KaiAlphaDocument) => {
		const document = typeof (documentState) === "object" ? documentState : documentState(state.document!);
		if (document) {
			setState(s => ({ ...s, document, didReset: false }));
		}
	}

	const setBuffer = (documentState: DocumentMutator | KaiAlphaDocument) => {
		const buffer = state.buffer ?? state.document;
		const newBuffer = typeof (documentState) === "object" ? documentState : documentState(buffer!);
		const didChange = (typeof (documentState) !== "object") && newBuffer ? true : false;
		if (newBuffer) {
			setState(s => ({ ...s, buffer: {...newBuffer}, didChange}));
		}
	}

	const reset = () => setState(s => ({ ...initialState, didReset: true }));
	const setTableOfContents = (toc: ElementValueType[]) => setState(s => ({...s, tableOfContents: toc}));
	const setDocumentCollection = (collection: DocumentCollection) => setState(s => ({...s, documentCollection: collection}));
	const setIsLoadingVariables = (isLoading: boolean) => setState(s => ({...s, isLoadingVariables: isLoading}));
	return {
		...state,
		setDocument,
		setSyntheticDocument,
		setActiveSectionId,
		updateDocumentElements,
		setWindow,
		reset,
		setDocumentEditorView,
		setTemplateId,
		setAuditLogOpen,
		setDocumentName,
		setTemplate,
		setDocumentVersion,
		setComputedUserRoles,
		setVariables,
		setElements,
		setBuffer,
		setSelectedElementById,
		setTableOfContents,
		setPermissions,
		setDocumentCollection,
		setIsLoadingVariables,
		setTempBuffer,
		isInReview: isInReview(state.document?.state),
		isApproved: isApproved(state.document?.state)
	}
}

const DocumentState = createContainer(useDocument);
export {DocumentState};