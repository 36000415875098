import { NoteAdd, Add } from "@mui/icons-material";
import { MetaDataChangeEvent, MetadataInput } from "./MetadataInput";
import { useRef, useState } from "react";
import { EmptyGroup } from "../EmptyGroup";
import { MetadataUploadCard, UploadAction } from "./MetadataUploadCard";
import React from "react";
import {MetadataList, RenderEditorEvent} from "./MetadataList";
import { GroupedContent } from "../GroupedContent";
import { Button } from "../../../buttons";
import { FlexBox } from "../..";
import {MetadataItem} from "../../../../interfaces/Metadata";

export interface MetadataProps {
	metadata: {[k: string] : string} | undefined,
	// if the component allows for CRUD ops on the metadata list.
	editable?: boolean,
	// if no data is present, display this message in the list.
	emptyMessage?: string,
	onUpdateMetadata?: (event: MetaDataChangeEvent) => void,
	onDeleteMetadata?: (key: string) => void,
	onUploadedMetadata?: (action: UploadAction, data) => void,
	renderEditorForMetadata?: (event: RenderEditorEvent) => React.ReactNode,
	onNewMetadata?: () => void,
	renderTopView?: () => React.ReactNode,
	renderValue?: (metadata: MetadataItem) => string
}

/**
 * This component edits/displays/searches the *user* metadata embedded in a content/template/submission
 * @constructor
 */
const MetadataGroup = ({metadata, onUpdateMetadata, onDeleteMetadata, onUploadedMetadata, editable = true, emptyMessage, renderValue, onNewMetadata, renderTopView, renderEditorForMetadata}: MetadataProps) => {
	const uploadBtnRef = useRef<HTMLButtonElement>(null);
	const [displayUploadCard, setDisplayUploadCard] = useState(false);
	const invertUploadCardDisplay = () => setDisplayUploadCard(!displayUploadCard);

	const uploadButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
		invertUploadCardDisplay();
	};

	const [displayMetadataInput, setMetadataInputDisplayed] = useState(false);
	const invertMetadataInputDisplay = () => setMetadataInputDisplayed(!displayMetadataInput);

	const addMetadata = (event: MetaDataChangeEvent) => {
		if (onUpdateMetadata) {
			onUpdateMetadata(event);
		}
		invertMetadataInputDisplay();
	}

	const onCreateMetadata = () => {

		// if consumer of this component is going to handle
		// meta data add.
		if (onNewMetadata) {
			onNewMetadata();
			return;
		}

		if (!displayMetadataInput) {
			invertMetadataInputDisplay()
		}
	}

	const contextButtons = onUploadedMetadata ? [
		<Button ref={uploadBtnRef} buttonType="default" key={"metadata-upload"} icon={<NoteAdd fontSize="small" />} text="Upload" onClick={uploadButtonClicked} />,
		<Button buttonType="plain" styling="inverted" key={"metadata-add"} icon={<Add fontSize="small" />} onClick={onCreateMetadata} />
	] : [<Button buttonType="plain" styling="inverted" key={"metadata-add"} icon={<Add fontSize="small" />} onClick={onCreateMetadata} />]

	return <>
		<GroupedContent
			title={"Metadata"}
			contextButtons={editable ? contextButtons : undefined}>
			<FlexBox direction="column">
				{renderTopView && renderTopView()}
				{displayMetadataInput &&
					<MetadataInput
						onCancel={invertMetadataInputDisplay}
						onSave={addMetadata}
					/>
				}
				{Object.keys(metadata ?? {}).length > 0 ?
					<MetadataList
						editable={editable}
						onEdit={onUpdateMetadata}
						onDelete={onDeleteMetadata}
						renderValue={renderValue}
						renderEditorForMetadata={renderEditorForMetadata}
						metadata={metadata!}
					/>
					: <EmptyGroup title={`${emptyMessage ?? "No metadata defined"}`}></EmptyGroup>}
			</FlexBox>
		</GroupedContent>
		<MetadataUploadCard open={displayUploadCard} anchorEl={uploadBtnRef.current} onCancel={invertUploadCardDisplay} onData={onUploadedMetadata} />
	</>
}

export { MetadataGroup }