import { PreviewElementProps } from "../model";
import {useEventListener, useRunWhenValueChange} from "../../../../../shared/hooks";
import "./style.scss";
import { TocItem } from "./TocItem";
import { useState } from "react";
import { DocumentTreeElement } from "../../../models/element";
import { useDocumentService } from "../../../hooks/useDocumentService";
import {ElementEventTypes} from "../../../../../shared/definitions/elements/events";
import {filterElements} from "../utilities/filterElements";
import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";
import {Variable} from "../../../../../shared/interfaces/Variable";
import {SectionElementValue} from "../../../../../shared/definitions/elements/section/model";

function getSectionElements(body?: ElementValueType[], variables?: Variable[]) {
	const elements = filterElements(body ?? [], "section", variables ?? []);
	elements.sort((e1, e2) => {
		const e1Data = e1.data as SectionElementValue;
		const e2Data = e2.data as SectionElementValue;
		return Number(e1Data.index ?? "1") - Number(e2Data.index ?? "1");
	});
	return elements
}
const PreviewView = (props: PreviewElementProps) => {
	const [sections, setSections] = useState<DocumentTreeElement[]>([]);
	const documentService = useDocumentService();

	useRunWhenValueChange(() => {
		if (props.body) {
			setSections(getSectionElements(props.body, props.variables))
		}
	}, props.body);

	useEventListener(ElementEventTypes.update, (event: CustomEventInit) => {
		if (event.detail.type === "switch" && props.body) {
			setSections(getSectionElements(props.body, props.variables))
		}
	})

	const onSectionClick = (sectionId: string) => {
		documentService.updateActiveSection(sectionId);
	};

	return sections.length > 0 ? (
		<ul className={"tm__toc-preview"}>
			{sections.map((section) => (
				<TocItem {...section} onClick={onSectionClick} key={section.id} />
			))}
		</ul>
	) : null;
};

export { PreviewView };
