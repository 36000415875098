import {CssFactoryType} from "./model";
import {namedStyleCssFactory} from "./namedStyleCssFactory";
import {StyleType} from "../../../../../../../../shared/definitions/elements/style/model";
import {elementStyleCssFactory} from "./elementStyleCssFactory";

const factories: CssFactoryType[] = [namedStyleCssFactory, elementStyleCssFactory];
function factoryForStyleType(type?: StyleType): CssFactoryType | undefined {
	return factories.find(factory => factory.type === type);
}

export {factoryForStyleType};