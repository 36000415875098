import React from 'react';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles'; // LEGACY
import MaterialTableStyled from '../MaterialTableStyled';
import FancyTablePagination from './FancyTablePagination';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

function styles(theme) {
	return({
		root: {
			'& .MuiTable-root': {
				'& .MuiTableHead-root': {
					'& .MuiTableCell-head': {
						fontWeight: theme.typography.fontWeightBold,
					},
				},
				'& .MuiTableBody-root': {
					'& .MuiTableRow-root': {
						'&:nth-of-type(even)': {
							backgroundColor: theme.palette.background.level1,
						},
						'&:nth-of-type(odd)': {
							backgroundColor: theme.palette.background.level1_contrast,
						}
					},
				},
			},
		},
	});
}

// title used to indicate chip column
const CHIP_TITLE = '_chip';

/**
 * Table Props: https://material-table.com/#/docs/all-props
 */
class FancyTable extends React.Component {
	static propTypes = {
		columns: PropTypes.array.isRequired,
		data: PropTypes.array.isRequired,
		title: PropTypes.string.isRequired,
		options: PropTypes.any,
	};

	chipColumn = undefined;

	setChipColumn(columnsProp) {
		const hasChipColumn = columnsProp.some(col => col.title === CHIP_TITLE);
		if (!hasChipColumn) {
			return columnsProp;
		}

		// copy the prop to mutate
		// using shallow copy to preserve the `render` function
		const columns = columnsProp.map(col => {
			return({ ...col });
		});

		const chipColumn = columns.find(col => col.title === CHIP_TITLE);

		const showChipField = chipColumn.field;
		chipColumn.render = (rowData) => {
			const showChip = rowData?.[showChipField];
			if (!showChip) {
				return null;
			}

			return(
				<FiberManualRecordIcon
					fontSize='small'
					color='primary'
				/>
			);
		};

		const smallColumn = {
			width: '1%',
			maxWidth: '1%',
		};
		chipColumn.width = smallColumn.width;
		chipColumn.headerStyle = smallColumn;
		chipColumn.cellStyle = smallColumn;
		chipColumn.export = false;
		chipColumn.filtering = false;
		chipColumn.title = '';

		// save chip column for easy lookup later
		this.chipColumn = chipColumn;
		return(columns);
	}

	getTitle(title) {
		if (typeof title === 'string') {
			return<Typography variant="h4">{title}</Typography>;
		}
		return title;
	}

	getRowStyle(rowData) {
		const showChipField = this.chipColumn?.field;
		if (!showChipField || !rowData[showChipField]) {
			return({});
		}
		return({
			fontWeight: 'bold'
		});
	}

	render() {
		const {
			classes,
			columns: columnsProp,
			data,
			title,
			options,
			components,
			...others
		} = this.props;

		const columns = this.setChipColumn(columnsProp);

		return(
			<Box className={classes.root}>
				<MaterialTableStyled
					columns={columns}
					data={data}
					title={this.getTitle(title)}
					options={{
						filtering: true,
						search: true,
						pageSize: 10, // initial page size
						pageSizeOptions: [10, 20, 50, 100],
						emptyRowsWhenPaging: false, // don't show empty rows to fill page size
						rowStyle: (rowData) =>  this.getRowStyle(rowData),
						...options,
					}}
					components={{
						Container: (containerProps) => {
							return(
								<Box {...containerProps}/>
							);
						},
						Pagination: (paginationProps) => {
							const {classes, ...others} = paginationProps;
							return(
								<FancyTablePagination
									{...others}
								/>
							);
						},
						...components,
					}}
					{...others}
				/>
			</Box>
		);
	}
}


export default withStyles(styles)(FancyTable);
