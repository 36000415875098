import { ElementValueType } from "../../../../../shared/interfaces/ElementValueType";
import { mapBodyToElementValue } from "../../../../../shared/utilities/data/mapBodyToElementValue";

export function selectSubtemplatesFromTemplate(template: KaiAlphaTemplate): Promise<ElementValueType[]> {
	return new Promise((resolve, reject) => {
		try {
			const elements = mapBodyToElementValue(template.body)
				.filter(element => element.type === "template");

			resolve(elements);
		} catch(e) {
			reject(e);
		}
	})
}