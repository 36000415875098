import {TemplateElementDefinition} from "../model";
import {TitleIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import PreviewView from "./views/preview/PreviewView";
import {
	titleDefinition as baseTitleDefinition
} from "../../../../../shared/definitions/elements/title/model";

const titleDefinition: TemplateElementDefinition = {
	...baseTitleDefinition,
	icon: TitleIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		preview: PreviewView
	},
} as const

export {titleDefinition};