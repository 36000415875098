import {AsyncComponent, FlexBox} from "../../../../../shared/components";
import {TabDefinition} from "../../../../../shared/components/layout/tabs/tabDefinition";
import {SearchBar} from "../../../../../shared/components/input/search/SearchBar";
import {TemplateState} from "../../../hooks";
import {useRunOnce, useRunWhenValueChange} from "../../../../../shared/hooks";
import {TemplateInformation} from "../../../hooks/api/useGetTemplates";
import {EmptyGroup} from "../../../../../shared/components/layout/groups/EmptyGroup";
import {ListItem} from "./ListItem";
import {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {moduleLinkGenerator} from "../../../../moduleNavigation";
import { useUserList } from "../../../../../shared/hooks/useUserList";
import { List } from "../../../../../shared/components/list/tree/TreeList.style";
import Tooltip from "@mui/material/Tooltip";
import { Badge } from "@mui/material";
import { useGetTemplatesSpecificVersion } from "../../../hooks/api/useGetTemplatesSpecificVersion";
import { TemplateElementValue } from "../../../../../shared/definitions/elements/template/model";
import { TemplateElement } from "../../../../../shared/interfaces/TemplateElement";

const search = (searchValue: string, templates: TemplateInformation[]): TemplateInformation[] => {
	if (searchValue === "") {
		return templates;
	}

	const substring = searchValue.toLowerCase().trim();
	return templates.filter(template => template.name.toLowerCase().includes(substring)).map(template => ({...template, children: []}));
}

const filterTemplateElements = (elements: TemplateElement[] | null) => elements?.filter(element => element.type === "template") ?? []

const Decorator = () => {
	// get template state, filter through elements and check tbd fields on whether or not the subtemplate
	// element is the latest version.
	const templateState = TemplateState.useContainer();

	return (templateState.templateElements && Object.keys(templateState.templateElements.upgradable).length > 0) ? <Tooltip placement="top" title={"New template versions available"}>
		<Badge
			color="primary"
			variant="dot"
			overlap="rectangular"
			style={{  transform: 'translate(96px, -9px)'}}
			invisible={false}
		/>
	</Tooltip> : null;
}

const TabView = () => {
	const [templates, setTemplates] = useState<TemplateInformation[]>([]);
	const [searchValue, setSearchValue] = useState("");
	const templateState = TemplateState.useContainer();
	const userList = useUserList();
	const getChildren = useGetTemplatesSpecificVersion();
	const navigate = useNavigate();

	const getTemplateElements = () => getChildren.execute(filterTemplateElements(templateState.elements));

	useRunOnce(() => {
		userList.execute(["name", "display_name"]);
	});

	useRunWhenValueChange(() => {
		getTemplateElements();
	}, templateState.template?.id);

	useRunWhenValueChange(() => {
		getTemplateElements();
	}, templateState.elements);

	useRunWhenValueChange(() => {
		if (getChildren.value) {
			setTemplates(getChildren.value!)
		}
	}, getChildren.value)

	const onTemplatesLoaded = (templateInformationCollection: TemplateInformation[]) => {
		setTemplates([...templates, ...templateInformationCollection.filter(templateInfo => !templates.some(template => template.id === templateInfo.id))]);
	}
	const onSearchValueChange = (value?: string) => setSearchValue(value ?? "");
	const onNavigateToTemplate = (template: TemplateInformation) => {
		//get the index of the template
		const findElementIndex = templateState?.elements?.findIndex((element) => element.type === "template" && element.contents.id === template.id) ?? 0;
		//get the contents of the template
		const filteredElement = templateState.elements![findElementIndex].contents;
		//use templates contents latest_approved_version to show the latest template preview.
		navigate(moduleLinkGenerator("template", "edit", template.id, template.version === "HEAD" ? "" : filteredElement.version));
	};
	const upgradeTemplate = (template: TemplateElementValue) => {
		//get the index of the template
		const findElement = templateState?.elements?.findIndex((element) => element.type === "template" && element.contents.id === template.id && element.contents.version === template.version) ?? -1
		if (findElement > -1) {
			const totalElements = templateState.elements!;
			totalElements[findElement].contents = {
				...totalElements[findElement].contents,
				version: totalElements[findElement].contents.latest_approved_version,
			};
			templateState.setElements([...totalElements]);
		}
	}

	const filteredTemplates = useMemo(() => searchValue === "" ? (getChildren.value ?? []) : search(searchValue, templates),
		[templates, searchValue, getChildren.value]);


	const noTemplates = (filteredTemplates.length ?? 0) === 0 && searchValue === "";
	const noSearchResults = (filteredTemplates.length ?? 0) === 0 && searchValue !== "";
	return <FlexBox direction={"column"} width={"100%"}>
		<AsyncComponent loadingMessage={"Loading templates"} isLoading={getChildren.isLoading} component={<>
			<SearchBar placeholder="Search" onChange={onSearchValueChange} containerStyle={{margin: "0 1rem", flex: 1}}/>
			{noTemplates && <EmptyGroup title={"No templates"} />}
			{noSearchResults && <EmptyGroup title={"No templates match your search"} />}
			{(filteredTemplates.length ?? 0) > 0 && userList.value &&  <List>
				{filteredTemplates.map(template =>
					<ListItem
						depth={0}
						key={`${template.id}-${template.version}-${searchValue}`}
						onUpgrade={templateState.templateElements?.upgradable[`${template.id}-${template.version}`] ? () => upgradeTemplate(templateState.templateElements?.upgradable[`${template.id}-${template.version}`]!) : undefined}
						userList={userList.value!}
						template={template}
						onTemplatesLoaded={onTemplatesLoaded}
						onNavigateToTemplate={onNavigateToTemplate}
					/>)}
			</List>}
		</>} />
	</FlexBox>
}

const TemplatesTab: TabDefinition = {
	name: "Templates",
	content: TabView,
	decorator: Decorator,
}

export {TemplatesTab};