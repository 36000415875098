import {DataSourcePreview, PreviewProps} from "./model";
import {Table, TableDataType} from "../../../../../../list/table/Table";
import {DatasourceType, getDataFromDataSourceType} from "../../../../../../../utilities/getDataFromDataSourceType";

const Preview = (props: PreviewProps) => {
	const datasource = props.dataSource?.data as DatasourceType | null;
	const tableData: TableDataType = getDataFromDataSourceType(datasource, datasource?.type)
	return tableData.columns.length === 0 ? null :
		<Table
			name={props.dataSource?.name ?? "UNKNOWN"}
			type={datasource?.type ?? ""}
			data={tableData}
			pageSize={5} />
}
const TablePreview: DataSourcePreview = {
	type: "csv",
	view: Preview
}
export {TablePreview};