import {
	Route,
} from "react-router-dom";
import Profile from '../Profile';
import WorkflowEditor from '../WorkflowEditor';
import WorkflowSelector from '../WorkflowSelector';
import { withRouter } from "../../lib/legacy/withRouter";
import { CircularProgress } from '../../lib/ui';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import '@fontsource/lato';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import AppVersion from './Version';
import { theme } from "../../theme";
import { ModuleRouter, ModuleDefinition } from '../../modules';
import { UserManager } from '../../shared/hooks/useUser';
import ListsSelector from '../Lists/Selector';
import ListsEditor from '../Lists/Editor';
import ListsPage from '../Lists/Page';
import './style.scss';
import React from "react";
import StatusBar from '../StatusBar';

const globalStyles = <GlobalStyles styles={(theme) => ({
	"*::-webkit-scrollbar": {
		width: 0,
	},
	".commentsview-mentionsinput__control": {
		fontSize: theme.typography.fontSize,
		fontWeight: "normal",
	},
	".commentsview-mentionsinput--multiLine .commentsview-mentionsinput__control": {
		minHeight: 63,
	},
	".commentsview-mentionsinput--multiLine .commentsview-mentionsinput__input": {
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.primary.light}`,
	},
	".commentsview-mentionsinput--multiLine .commentsview-mentionsinput__highlighter": {
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.primary.main}`,
	},
	".commentsview-mentionsinput__suggestions__list": {
		border: "1px solid rgba(0,0,0,0.15)",
		fontSize: theme.typography.fontSize,
		maxHeight: 100,
		overflowY: "auto",
	},
	".commentsview-mentionsinput__suggestions__item": {
		padding: "5px 15px",
		borderBottom: "1px solid rgba(0,0,0,0.15)",
	},
	".commentsview-mentionsinput__suggestions__item--focused": {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.contrastText
	},
	body: {
		fontFamily: theme.typography.fontFamily,
		backgroundColor: theme.palette.background.default
	}
})} />

const routeMap: (Omit<ModuleDefinition, "component"> & {component: React.ElementType})[] = [
	{
		path: "/activity/profile",
		component: Profile,
		name: "Profile",
		basePath: "/activity/profile"
	},
	{
		path: "/activity/workflows",
		component: WorkflowSelector,
		name: "Workflow selector",
		basePath: "/activity/workflows"
	},
	{
		path: "/activity/workfloweditor/:workflow_id",
		component: WorkflowEditor,
		name: "Workflow Editor",
		basePath: "/activity/workfloweditor"
	},
	{
		path: "/activity/workfloweditor",
		component: WorkflowEditor,
		name: "Workflow Editor",
		basePath: "/activity/workfloweditor"
	},
	{
		path: "/activity/lists",
		component: ListsPage,
		name: "Lists",
		basePath: "/activity/lists"
	},
	{
		path: "/activity/listsselector/:type",
		component: ListsSelector,
		name: "Lists Selector",
		basePath: "/activity/listsselector"
	},
	{
		path: "/activity/listseditor/:type/:id",
		component: ListsEditor,
		name: "Lists Editor",
		basePath: "/activity/listseditor"
	}
];

const App = () => {
	const userData = UserManager.useContainer();

	if (!userData.isAuthenticated || userData.checkingUserFeatures) return (<CircularProgress size="3em" style={{ margin: '2em auto', display: 'block' }} />); // If user data not fetched, user will eventually be redirected to external Cognito login

	return (
		<ThemeProvider theme={theme}>
			{globalStyles}
			<AppVersion />
			<StatusBar />
			<ModuleRouter>
				{routeMap.map((route) => {
					const ComponentWrapper = function (props) {
						const { component: Component } = route;
						return (<Component {...props} />);
					};
					const RoutedComponentWrapper = withRouter(ComponentWrapper);

					return <Route key={`route_${route.path}`} path={route.path} element={<RoutedComponentWrapper />} />;
				})}
			</ModuleRouter>
		</ThemeProvider>
	);
}

export default withRouter(App);
