import { UserList } from "../../../../../shared/hooks/useUserList";
import { QueryResponse } from "../../../../../shared/interfaces";
import { modelDocumentMetadataKey, TemplateListNode } from "./models";

export function search(searchValue: string, field: string, templates: TemplateListNode[]): TemplateListNode[] {
	searchValue = searchValue.toLowerCase();
	return templates.reduce((result : TemplateListNode[], template: TemplateListNode) => {
		if(field === 'owner' ){
			template['owners'].forEach((item) => {
				if (item?.toLowerCase().includes(searchValue)) {
					result.push(template);
				}
			});
		} else if (field === 'metadata' && template[modelDocumentMetadataKey]?.toLowerCase().includes(searchValue)) {
			// if we are sorting on metadata column, we are sorting on model_document.
			result.push(template);
		} else if (field !== 'metadata' && template[field]?.toLowerCase().includes(searchValue)) {
			result.push(template);
		} else if (template.children.length > 0) {
			const childSearch = search(searchValue, field, template.children);
			if (childSearch.length > 0) {
				template.children = childSearch;
				result.push(template);
			}
		}
		return result;
	}, [])

}

export function computeTemplateList(templates: QueryResponse<KaiAlphaTemplate>[], userList: UserList): TemplateListNode[] {
	return templates.map((template) => ({
		childrenIds: template["@subtemplates"] ?? [],
		date: template['@date']!,
		id: template.id!,
		state: template.state!,
		model_document: template.model_document ?? 'None Specified',
		name: template.name!,
		metadata: template.metadata?.user ?? {},
		owners: template.permissions?.owners?.map(owner => userList.map[owner ?? ""]?.display_name ?? 'No Owner') ?? [],
		version: template.version!,
		children: [],
		hasTask: (template.state?.toLowerCase() === 'review' || (template.workflow?.default?.completed_tasks?.length ?? 0) > 0) ? true : false
	}))
}