import {ensureArgs} from "../filterUtilities";
import {isArray} from "../../../guards/isArray";
import {NunjucksDataSourceType} from "../../models/NunjucksDataSourceType";
import {NunjucksFilterFactoryProps} from "../model";

function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args) || typeof(args[0]) !== "object" || isArray(args[0])) {
		return {}
	}

	const object = args[0] as any;
	const filterType = args[1] as (string | undefined);
	return flattenDataSources({object, filterType});
}

type FlattenDataSourcesProps = {
	object: any,
	filterType?: string
}
function flattenDataSources({object, filterType}: FlattenDataSourcesProps): Partial<NunjucksDataSourceType> {
	let result: Partial<NunjucksDataSourceType> = {};
	for (const key of Object.keys(object)) {
		const datasource = object[key];

		if (filterType !== undefined) {
			if (datasource.type !== filterType) {
				continue;
			}
		}

		if (result.type !== undefined && result.type !== datasource.type) {
			continue;
		}

		if (result.data === undefined) {
			result = datasource;
		} else {
			result = mergeDataSources([result, datasource]);
		}
	}

	return(result);
}

function mergeDataSources(tuple: any[]) {
	const a = tuple[0];
	const b = tuple[1];

	const retval: Partial<NunjucksDataSourceType> = {
		type: a.type
	}

	switch (a.type) {
		case 'columns-rows':
		{
			retval['data'] = {};

			const keys = new Set([...Object.keys(a.data), ...Object.keys(b.data)]);
			for (const key of keys) {
				retval.data[key] = {
					...a.data[key],
					...b.data[key]
				};
			}
		}
			break;
		case 'columns':
			retval.data = [
				...a.data,
				...b.data
			];
			break;
		case 'rows':
		/* XXX:TODO: Support this when we add support for row-lar data */
		// eslint-disable-next-line
		default:
			throw(new Error(`Data Type "${a.type}" not supported`));
	}

	return(retval);
}

export function flattenDataSourcesFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "flatten_datasources",
		help: "Filter that takes in an array of datasources and produces a single combined datasource",
		example: "[var] | flatten_datasources",
		execute: execute
	});
}