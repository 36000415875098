import {useState} from "react";
import {moduleLinkGenerator} from "../../../moduleNavigation";
import {useNavigate} from "react-router-dom";
import {useGetAllSubmissions} from "../../hooks/api/useGetAllSubmissions";
import {SubmissionNode} from "../../models";
import {useRunWhenValueChange} from "../../../../shared/hooks";
import {Submission} from "../../models/Submission";
import {computeCompletion} from "../../utilities/computeCompletion";
import {SearchApiProps, SearchFilter, SearchSort} from "../../../../shared/hooks/api/searchApi";
import {useDeleteSubmission} from "../../hooks/api/useDeleteSubmission";
import {useDownloadSubmissionWithIdAction} from "../../hooks/actions/data/useDownloadSubmissionWithIdAction";


type State = Omit<SearchApiProps, "searchType" | "fields"> & {
	content: SubmissionNode[],
	page: number
}

function calculateSubmissionCompletion(submission: Submission): string {
	const completionNumber = submission.ectdModules.map(computeCompletion).reduce((total, current) => total + current, 0);
	if (completionNumber <= 0) {
		return "-";
	}

	return Math.floor((completionNumber / (submission.ectdModules.length * 100)) * 100) + "%";
}
function mapSubmissionToNode(submission: Submission): SubmissionNode {
	return {
		name: submission.name,
		id: submission.id,
		compound: submission.compound ?? "",
		indications: submission.indications ?? [],
		completionStatus: calculateSubmissionCompletion(submission),
		extractionError: submission.extraction_error,
		extractionStatus: {
			label: submission.extraction_status ?? "Idle",
			action: submission.extract_location
		}
	}
}
function useList() {
	const navigate = useNavigate();
	const getAllSubmissions = useGetAllSubmissions();
	const deleteSubmission = useDeleteSubmission();
	const downloadAction = useDownloadSubmissionWithIdAction();

	const [state, setState] = useState<State>({
		sort: {field: "name", direction: "desc"}, page: 0, pageSize: 25, content: []
	})

	useRunWhenValueChange(() => loadSubmissions(), [state.filters, state.sort, state.pageSize, state.after]);
	useRunWhenValueChange(() => {
		const contentNodes = ((getAllSubmissions.value?.results ?? [])).map(mapSubmissionToNode);
		const content = state.page === 0 ? (contentNodes ?? []) : [...state.content].concat(contentNodes ?? []);
		setState(s => ({...s, content}));

	}, getAllSubmissions.value);

	useRunWhenValueChange(() => {
		if (deleteSubmission.status === "success") {
			loadSubmissions();
		}
	}, deleteSubmission.status)

	const createNewSubmission = () => {
		const url = moduleLinkGenerator("submission", "create");
		navigate(url);
	}

	const downloadSubmission = (contentNode: SubmissionNode) => downloadAction.run({id: contentNode.id, name: contentNode.name});

	const loadSubmissions = () => {
		getAllSubmissions.execute({filters: state.filters, sort: state.sort, pageSize:state.pageSize, after:state.after});
	}

	const setFilter = (filter: SearchFilter): boolean => {
		const existingFilter = state.filters?.find(f => f.field === filter.field);
		if (existingFilter?.value === filter.value) {
			return false;
		}

		const filters = [...(state.filters ?? [])].filter(f => f.field !== filter.field);
		if (filter.value.toString().length > 0) {
			filters.push(filter);
		}

		setState(s => ({...s, filters, page: 0, after: undefined}));
		return true;
	}

	const setSort = (sort?: SearchSort) => {
		setState(s => ({...s, sort, after: undefined, page: 0}));
	}

	const setPageSize = (pageSize: number) => {
		setState(s => ({...s, pageSize, after: undefined, page: 0}));
	}

	const setAfter = (after?: string[] | number[]) => setState(s => ({...s, after, page: s.page + 1}));

	const onNavigateToSubmission = (submission: SubmissionNode) => navigate(moduleLinkGenerator("submission", "edit", submission.id));

	const onDelete = (submission: SubmissionNode) => deleteSubmission.execute(submission.id);
	return {
		loadSubmissions,
		createNewSubmission,
		isRunning: getAllSubmissions.isLoading || deleteSubmission.isLoading || downloadAction.isRunning,
		onNavigateToSubmission,
		content: state.content,
		downloadSubmission,
		setFilter,
		setSort,
		setPageSize,
		setAfter,
		onDelete,
		sort: state.sort,
		filters: state.filters,
		pageSize: state.pageSize ?? 10,
		total: getAllSubmissions.value?.total ?? 0,
		after: getAllSubmissions.value?.next ?? undefined
	}
}

export {useList};
