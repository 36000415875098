import {AutoCompleteConfig} from "../interfaces/AutoCompleteConfig";
import {nunjucksConstants} from "../nunjucks/models/NunjucksConstants";
import {IttAutoCompleteConfigGenerator} from "./itt/ittAutoCompleteConfigGenerator";
import {VariableAutoCompleteConfigGenerator} from "./autoCompleteConfigGenerators";

const generators: VariableAutoCompleteConfigGenerator[] = [IttAutoCompleteConfigGenerator]
export type VariableAutoCompleteConfigGeneratorProps = {
	userVariables: string[] | null,
	trigger?: string
}
export async function variableAutoCompleteConfigGenerator(props: VariableAutoCompleteConfigGeneratorProps): Promise<AutoCompleteConfig[]> {
	if (props.userVariables === null) {
		return [];
	}

	const userVariableTrigger: AutoCompleteConfig = {
		trigger: props.trigger ?? nunjucksConstants.any,
		options: props.userVariables
	}

	try {
		const systemVariableCollection = await Promise.all(generators.map(generator => generator.generate()));
		const systemVariableConfigs = systemVariableCollection.reduce((configs, current) => configs.concat(current), []);
		return [...systemVariableConfigs, {...userVariableTrigger, options: [...props.userVariables, ...generators.map(generator => generator.name.toLowerCase())]}];
	} catch(e) {
		return [userVariableTrigger];
	}
}