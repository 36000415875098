import {EditableElementProps, ElementPropertyData} from "../../model";
import MaterialTableStyled from "../../../../../../components/MaterialTableStyled";
import {columns} from "./models";
import {PropertyList} from "../../PropertyList";
import { TableElementFootnote, TableElementValue } from "../../../../../../shared/definitions/elements/table/model";
const uuid = require("uuid");


const EditableView = (props: EditableElementProps) => {
	const value = props.data as TableElementValue;

	// returning a promise because material table callbacks require a promise
	const onChangeValue = (newValue: TableElementValue) => new Promise<void>((resolve) => {
		props.onChange({
			...(props as ElementPropertyData),
			data: newValue
		});

		resolve();
	})

	const onAddFootnote = (footnote: TableElementFootnote) => onChangeValue({...value, footnotes: [...(value.footnotes ?? []), {...footnote, id: uuid.v4()}]})

	const onDeleteFootnote = (footnote: TableElementFootnote) => {
		const footnotes = (value.footnotes ?? []).filter(f => f.id !== footnote.id);
		return onChangeValue({...value, footnotes});
	}

	const onUpdateFootnote = (footnote: TableElementFootnote, oldFootnote: TableElementFootnote) => {
		const footnoteIndex = (value.footnotes ?? []).findIndex(f => f.id === footnote.id);
		if (footnoteIndex < 0) {
			return Promise.resolve() as Promise<void>
		}
		const footnotes = [...(value.footnotes ?? [])];
		footnotes[footnoteIndex] = footnote;
		return onChangeValue({...value, footnotes});
	}

	const editableEvents = {
		onRowAdd: onAddFootnote,
		onRowDelete: onDeleteFootnote,
		onRowUpdate: onUpdateFootnote
	}

	const properties = [{label: "Table title", value: value.title}, {label: "Datasource", value: value.datasource}, {label: "Column headers", value: value.column_headers}, {label: "Row headers", value: value.row_headers}];
	return <PropertyList items={properties}>
		<p>If entering a variable name, values for the row/column/cell or name/description fields are not required. They will be entered in the document editor.</p>
		<MaterialTableStyled
			columns={columns}
			data={value.footnotes ?? []}
			title='Footnotes'
			editable={editableEvents}
		/>
	</PropertyList>
}

export {EditableView};