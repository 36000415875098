import {MetaDataValueEditor, ValueEditorProps} from "./model";
import {DropDownItem} from "../../../../../../../shared/components/input/dropdown";
import {AsyncComponent} from "../../../../../../../shared/components";
import {DropdownValueEditor} from "./DropdownValueEditor";
import {useAsync, useRunOnce} from "../../../../../../../shared/hooks";
import {getStudyIdsApi} from "../../../../../../../shared/metaData/api/getStudyIdsApi";

const Editor = (props: ValueEditorProps) => {
	const loadStudyIds = useAsync(getStudyIdsApi, false);
	useRunOnce(() => {
		loadStudyIds.execute();
	});

	const dropDownItems = (loadStudyIds.value?.map(id => ({title: id, value: id})) ?? []) as DropDownItem<string>[];

	return <AsyncComponent
		isLoading={false}
		component={<DropdownValueEditor {...props} data={{...props.data, options: {items: dropDownItems}}} />} />
}
export const StudyIdEditor: MetaDataValueEditor = {
	view: Editor,
	displayTextGenerator: (value: string) => value,
	defaultValueFactory: () => ""
}
