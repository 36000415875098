import { useRunWhenValueChange } from "../../../../../../../shared/hooks";
import { TreeListItem } from "../../../../../../../shared/components/list/tree/TreeListItem";
import { TreeNode } from "../../../../../../../shared/components/list/tree/TreeList.models";
import { useState } from 'react';
import { mapTemplateElementsToTreeNode } from "../../../../../../../shared/components/list/tree/mapTemplateElementsToTreeNode";
import { formatTooltipTreeNodeId } from "../../../../../../../shared/components/list/tree/formatTooltipTreeNodeId";
import {displayNameForElementType} from "../../../../../../../shared/definitions/elements";
import {useTemplateDetail} from "../../../../../hooks/useTemplateDetail";

export interface ReferenceListItemProps {
	reference: TreeNode,
	depth: number,
	onChildTemplateLoaded: (reference: TreeNode) => void,
	onReferenceSelected: (reference: TreeNode) => void,
}

const ReferenceListItem = ({ reference, onReferenceSelected, onChildTemplateLoaded, depth }: ReferenceListItemProps) => {
	const getChildren = useTemplateDetail();
	const [expanded, setExpanded] = useState(reference.children.length > 0);

	useRunWhenValueChange(() => {
		if (expanded && (reference.children.length === 0)) {
			getChildren.execute(reference.data?.id ?? reference.data?.template_id);
		}
	}, expanded);

	useRunWhenValueChange(() => {
		if (reference.children.length > 0) {
			setExpanded(true);
		}
	}, reference.children);

	const onToggleExpansion = () => setExpanded(!expanded);

	// after getting the template value, parse the template and let the parent know.
	useRunWhenValueChange(() => {
		if (getChildren.value) {
			const template = getChildren.value;
			const referenceCopy = {...reference, children: mapTemplateElementsToTreeNode(template)};
			onChildTemplateLoaded(referenceCopy);
		}
	}, getChildren.value);

	// if a child has been loaded (another sub-template), define it as a child to this reference
	// and pass it up to the next one (or the top level)
	const onChildLoaded = (childReference: TreeNode) => {
		const referenceCopy = {...reference};
		// put child in reference, pass reference up to next nesting
		const childIndex = referenceCopy.children.findIndex(node => node.id === childReference.id);
		referenceCopy.children[childIndex] = childReference;
		onChildTemplateLoaded(referenceCopy);
	}

	// calls top level selection method with references id.
	const onSelected = () => {
		onReferenceSelected(reference);
	}


	return <TreeListItem
		loading={getChildren.isLoading}
		expandable={reference.type === 'template' ? {
			onToggleExpansion,
			expanded,
			children: reference.children.map((child) => <ReferenceListItem
				key={child.id}
				depth={depth + 1}
				reference={child}
				onChildTemplateLoaded={onChildLoaded}
				onReferenceSelected={onReferenceSelected}
			/>)
		} : undefined}
		title={reference.name}
		tooltip={formatTooltipTreeNodeId(reference)}
		description={displayNameForElementType(reference.type)}
		onSelected={reference.type === 'template' ? undefined : onSelected}
		depth={depth}
	/>
}

export { ReferenceListItem };