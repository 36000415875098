export const isJSONObject = (inputObject: unknown) => {
	if (typeof inputObject !== 'string') {
		return false;
	}

	try {
		const json = JSON.parse(inputObject);
		// make sure this is an object and not another type like int or boolean.
		const isObject = typeof json === 'object';
		return isObject;
	} catch (error) {
		// if here, not a json string
		return false;
	}
}