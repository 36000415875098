import { GroupAdd } from "@mui/icons-material";
import {useState} from "react";
import {GroupedContent, AsyncComponent, Button, FlexBox} from "../../../../components";
import { AclEditor } from "../../../../components/modals/aclEditor";
import {UserList} from "../../../../hooks/useUserList";
import {getAclRole} from "../../../../interfaces/ACLRoles";
import {ContributorListItem, ContributorListItemName, ContributorListItemRole} from './style';
import {useRunWhenValueChange} from "../../../../hooks";
import { Roles } from "../../../../interfaces/Permissions";
import { allUsers } from "../../../../interfaces/User";
import { AclEditorRoleTypes } from "../../../../components/modals/aclEditor/models";
import { Role } from "../../../../interfaces/Permissions";

export interface Contributor {
	name: string,
	roles: string[],
	id: string,
}

export interface ContributorsGroupProps {
	// the overall permissions object associated with the item being displayed
	permissions?: KaiAlphaTemplatePermissions | KaiAlphaDocumentPermissions,
	// the UserList object, which allows for lookup information
	userList?: UserList,
	// upon permissions being updated in the ACL editor.
	onUpdatePermissions: (permissions: KaiAlphaDocumentPermissions) => void,
	// the current users' roles.
	userRoles?: Role[],
	// should display loading overlay
	isLoading: boolean,
	// the roles that should be editable within the ACL editor.
	editableAclRoles?: AclEditorRoleTypes[],
	contentState?:string
}

const formatContributorName = (name, id) => `${name ?? "N/A"} (${id ?? "N/A"})`;

/**
 * This component displays the contributors associated with the template/document. It also triggers ACL Editor events.
 * @constructor
 */
const ContributorsGroup = ({permissions, userList, onUpdatePermissions, userRoles, isLoading, editableAclRoles,contentState}: ContributorsGroupProps) => {
	const [contributors, setContributors] = useState<Contributor[]>([]);

	useRunWhenValueChange(async () => {
		const newContributors: {[k: string]: Contributor} = {};
		if(!permissions || !userList) {
			return;
		}
		const list: UserList = userList;
		list.map[allUsers.id] = allUsers;
		permissions.owners.forEach((owner) => {
			newContributors[owner] = {
				name: formatContributorName(list.map[owner]?.display_name, list.map[owner]?.name),
				roles: [getAclRole('owners')],
				id: owner,
			}
		})

		Object.keys(permissions.roles ?? {}).forEach((role) => {
			permissions.roles![role].forEach((id) => {
				if (list.map[id]) {
					// to handle cases like @role:_completed_reviewers, etc -- more 'system'-esque roles
					// that do not make sense to show to user in this list.
					if (!getAclRole(role)) {
						return;
					}
					if(newContributors[id]) {
						newContributors[id].roles.push(getAclRole(role));
					} else {
						newContributors[id] = {
							name: formatContributorName(list.map[id]?.display_name, list.map[id]?.name),
							roles: [getAclRole(role)],
							id
						}
					}
				}
			})
		})
		setContributors(Object.keys(newContributors).map((c) => newContributors[c]));
	}, [permissions, userList]);

	const [showAclEditor, setShowAclEditor] = useState(false);
	const invertAclEditorDisplay = () => setShowAclEditor(!showAclEditor);
	const onAclEditorSave = (permissions: KaiAlphaDocumentPermissions) => {
		onUpdatePermissions(permissions);
		invertAclEditorDisplay();
	}

	return <>
		<GroupedContent
			title={"Contributors"}
			contextButtons={userRoles?.includes(Roles.Owner) ? [<Button buttonType="default" icon={<GroupAdd fontSize="small" key={"contributors-assign"}/>}
				text="Assign"
				onClick={invertAclEditorDisplay} />] : undefined}
		>
			<AsyncComponent isLoading={isLoading} loadingMessage={"Updating contributors"} component={
				<FlexBox direction="column">
					{contributors.map((contributor) =>
						<ContributorListItem align="center" justify="space-between" key={contributor.id}>
							<ContributorListItemName title={contributor.name}>{contributor.name}</ContributorListItemName>
							<ContributorListItemRole>{contributor.roles?.map((role, idx) => contributor.roles.length - 1 === idx ? `${role}` :`${role}, `)}</ContributorListItemRole>
						</ContributorListItem>)
					}
				</FlexBox>}
			/>
		</GroupedContent>
		<AclEditor
			visible={showAclEditor}
			visibleRoles={editableAclRoles ?? ['owners','authors', 'readers', 'reviewers', 'approvers']}
			onSave={onAclEditorSave}
			onCancel={invertAclEditorDisplay}
			permissions={permissions ?? null}
			contentState={contentState}
		/>
	</>
}

export {ContributorsGroup}
