import {FlexBox} from "./FlexBox";
import {Pane} from "./pane/Pane";
import {styled, Box} from "@mui/material/";

export interface ModuleLayoutProps {
	left?: JSX.Element,
	center: JSX.Element,
	right?: JSX.Element,
}

interface PaneContainerProps {
	flex: number
}
const PaneContainer = styled(Box)(({flex}: PaneContainerProps) => ({
	flex,
	height: "100%",
	margin: "0 auto",
	boxSizing: "border-box",
	padding: "0 0 30px 0",
	display: "flex",
	justifyContent: "center",
}));

const ModuleContainer = styled(FlexBox)({
	height: "100%",
	boxSizing: 'border-box',
	padding: "0 15px 10px",
	overflow: "hidden auto",
	width: "100%"
})

const ModuleLayout = ({left, right, center}: ModuleLayoutProps) => {
	return <ModuleContainer width="auto">
		{left && <PaneContainer flex={0}>
			<Pane sx={{padding: "15px"}} secondary>
				{left}
			</Pane>
		</PaneContainer>}
		<PaneContainer flex={1} sx={{overflow: "hidden", maxWidth: (left || right) ? "100vw" : "80vw"}}>
			<Pane>
				{center}
			</Pane>
		</PaneContainer>
		{right && <PaneContainer flex={0}>
			<Pane sx={{padding: "15px"}} secondary>
				{right}
			</Pane>
		</PaneContainer>}
	</ModuleContainer>
}

export {ModuleLayout}