import {styled} from "@mui/material";
import {SubmissionExtractionStatus} from "../../../../models/Submission";

const IndicationTag = styled('p')({
	background: "#E7EBF7C1",
	border: "1px solid #E7EBF7C1",
	borderRadius: "100px",
	padding: ".5rem .75rem",
	margin: ".5rem .25rem",
	fontSize: ".75rem"
});

const CompoundLabel = styled("p")({
	color: "#333333B3"
})

export type ExtractionStatusLabelProps = {
	status?: SubmissionExtractionStatus
}

const mapExtractionStyles = (status?: SubmissionExtractionStatus) => {
	switch(status) {
		case "Started":
			return ({
				color: "#6F8CC6",
				fontStyle: "italic",
				fontWeight: "bold"
			});
		case "Completed":
			return ({
				color: "#0000004D"
			});
		default:
			return ({
				color: "#F4A5AE"
			})
	}
}
const ExtractionStatusText = styled("p")<ExtractionStatusLabelProps>(({status}) => ({
	...mapExtractionStyles(status),
	"textAlign": "right",
	"width": "100%",
	"paddingRight": "1rem",
	"margin": "0px"
}));

export {IndicationTag, CompoundLabel, ExtractionStatusText}