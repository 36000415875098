import {useLoadTasksAction} from "../hooks/actions/load/useLoadTasksAction";
import {useRunOnce} from "../../../shared/hooks";
import {TaskNode} from "../../../shared/interfaces/task";
import {moduleLinkGenerator} from "../../moduleNavigation";
import {useNavigate} from "react-router-dom";


const sourcesMap = {
	"document": "content",
	"template": "template"
}
function useList() {
	const loadList = useLoadTasksAction();
	const navigate = useNavigate();
	useRunOnce(() => {
		loadList.run();
	})

	const onNavigateToTask = (task: TaskNode) => {
		const taskUrl = moduleLinkGenerator(sourcesMap[task.source.type], "edit", task.source.id);
		navigate(taskUrl);
	}

	return {
		tasks: loadList.value,
		isRunning: loadList.isRunning,
		onNavigateToTask
	}
}

export {useList}
