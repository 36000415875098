import {TemplateElementDefinition} from "../model";
import {StyleIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {PropertyEditor} from "./views/editor/PropertyEditor";
import {ReadonlyView} from "./views/ReadonlyView";
import {
	styleDefinition as baseStyleDefinition
} from "../../../../../shared/definitions/elements/style/model";
import {PreviewView} from "./views/preview/PreviewView";

const styleDefinition: TemplateElementDefinition = {
	...baseStyleDefinition,
	icon: StyleIcon,
	views: {
		editable: EditableView,
		propertyEditor: {
			view: PropertyEditor,
			requiredOnCreate: true
		},
		readonly: ReadonlyView,
		preview: PreviewView
	},
} as const

export {styleDefinition};