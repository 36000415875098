import {useTypedAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";
import { DownloadLink } from "../../models/content";
import {EntityRecord} from "../../../../shared/interfaces/Entity";

function getDocumentRenderPDFApi({id, version}: Required<EntityRecord>): Promise<DownloadLink> {
	const apiPath = `document/${id}/versions/${version}/render/pdf`;
	return api.call("GET", apiPath);
}
function useGetDocumentRenderPDF() {
	return useTypedAsync<Required<EntityRecord>,DownloadLink>(getDocumentRenderPDFApi, false);
}

export {useGetDocumentRenderPDF, getDocumentRenderPDFApi}