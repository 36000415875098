import {EditableElementProps, ElementPropertyData} from "../../model";
import {TextField} from "../../../../../../shared/components/input";
import React, {useState} from "react";
import { SectionElementValue } from "../../../../../../shared/definitions/elements/section/model";
import {useDebounce, useRunWhenValueChange} from "../../../../../../shared/hooks";
import {DebounceDelay} from "../../utilities/editSettings";

const EditableView = (props: EditableElementProps) => {
	const value = props.data as SectionElementValue;
	const [name, setName] = useState(value.name);
	const debouncedName = useDebounce(name, DebounceDelay);

	useRunWhenValueChange(() => {
		props.onChange({
			...(props as ElementPropertyData),
			data: {...value, name: debouncedName}
		})
	}, debouncedName);

	const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setName(event.target.value);
	}

	return <TextField value={name} onChange={onChange} />
}

export {EditableView}