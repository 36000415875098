import api from "../../../../api/api";
import {useAsync} from "../../../../shared/hooks";
import {Regulator} from "../../models/Regulator";


function getRegulatorsApi(): Promise<Regulator[]> {
	return api.call("GET", "ectd/list/regulators");
}

function useGetRegulators() {
	return useAsync(getRegulatorsApi, false);
}

export {useGetRegulators};