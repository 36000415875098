import React from 'react';
import { withRouter } from "../../lib/legacy/withRouter";
import {
	Box,
	defaultStyle,
} from '../../lib/ui';
import LazyTreeView from '../LazyTreeView';
import template from '../../api/template.js';
import { push_status } from '../StatusBar';
import { withStyles } from '@mui/styles';
import {TextField} from "../../shared/components/input";

function style(theme) {
	const retval = {
		...defaultStyle(theme),
		iconStyle: {
			fill: theme.palette.primary.main,
			'&:hover': { fill: theme.palette.primary.contrastText }
		},
		nameStyle: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			maxWidth: '200px',
		}
	};

	for (const state in theme.palette.documentState) {
		retval[`state_${state}`] = {
			backgroundColor: theme.palette.documentState[state].main,
			color: theme.palette.documentState[state].contrastText
		};
	}

	return (retval);
}

class OldTemplateDocSelector extends React.Component {
	static defaultProps = {
		content_type: '', /* documents, templates or workflows */
		action: '',       /* create or edit */
		header_type: '',
		selected_template: {}
	};

	constructor(props) {
		super(props);

		this.state = {
			name_search_val: '',
			owner_search_val: '',
			content_id: ''
		};
	}

	notifyUserError(id, action, type, errorMsg) {
		let notification = `${action} ${type} failed`;
		if (errorMsg) {
			notification += `: ${errorMsg}`;
		}

		push_status('error', notification, id);
	}

	notifyUserMessage(id, message) {
		push_status('progress', message, id);
	}

	notifyUserSuccess(id, action, type, message) {
		let notification = `${action} ${type} succeeded`;
		if (message) {
			notification += `: ${message}`;
		}

		push_status('ok', notification, id);
	}

	setSearch(type, value) {
		this.setState({
			[`${type}_search_val`]: value
		});
	}

	async setContentId(id) {
		this.setState({ content_id: id });

		if (this.props.content_type === 'workflows' && this.props.set_selected_workflow) {
			this.props.set_selected_workflow(id);
		}

		if (this.props.content_type === 'templates') {
			const selected_template = await template.getTemplateById(id);
			this.setState({ selected_template: selected_template });
		}

		if (this.props.onSelectItem) {
			this.props.onSelectItem(id);
		}
	}

	filterItem(item) {
		if (!this.state) {
			return true;
		}

		if (!(this.state.name_search_val || this.state.owner_search_val)) {
			return true;
		}

		if (this.state.name_search_val === "" && this.state.owner_search_val === "") {
			return (true);
		}

		if (
			item &&
			item.name && item.owner &&
			item.name.toLowerCase().includes(this.state.name_search_val.toLowerCase().trim()) &&
			item.owner.toLowerCase().includes(this.state.owner_search_val.toLowerCase().trim())
		) {
			return (true);
		}

		if (
			item &&
			item.name &&
			this.state.name_search_val &&
			item.name.toLowerCase().includes(this.state.name_search_val.toLowerCase().trim())
		) {
			return (true);
		}

		if (
			item &&
			item.owner &&
			this.state.owner_search_val &&
			item.owner.toLowerCase().includes(this.state.owner_search_val.toLowerCase().trim())
		) {
			return (true);
		}

		return (false);
	}

	render() {
		const content_id = this.state.content_id;
		const header_type = this.props.header_type;
		const content_type = this.props.content_type;
		const action = this.props.action;

		const button_to_args = {};
		switch (action) {
			case 'Create':
				button_to_args.state = {
					id: content_id,
					type: content_type,
					action: action
				};
				break;
			default:
				break;
		}

		let content_view, filter_area;
		if (content_type && header_type) {
			content_view =
				<Box height={'30em'} className={this.props.classes.tree}>
					<LazyTreeView
						onClick={(id) => {
							this.setContentId(id);
						}}
						filterItem={(...args) => { return (this.filterItem(...args)); }}
						getChildren={this.props.getChildren}
						searchable={true}
					/>
				</Box>;

			/* XXX:TODO: This is temporary until searching is added */
			filter_area = (
				<>
					<div>&nbsp;</div>
					<Box className={this.props.classes.tree}>
						<table width="100%">
							<thead>
								<tr width="100%">
									<td colSpan={2} align="center"><b>Filter</b></td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td align="right" style={{ width: '5em' }}>Name:</td>
									<td align="left"><TextField placeholder="Name" style={{ width: '90%' }} onChange={(event) => { const val = event.target.value; this.setState({ name_search_val: val }); }} /></td>
								</tr>
								<tr>
									<td align="right" style={{ width: '5em' }}>Owner:</td>
									<td align="left"><TextField placeholder="Owner" style={{ width: '90%' }} onChange={(event) => { const val = event.target.value; this.setState({ owner_search_val: val }); }} /></td>
								</tr>
							</tbody>
						</table>
					</Box>
				</>
			);
		}

		return (
			<div className={this.props.classes.content}>
				<div className={this.props.classes.header}>{action} {header_type}</div>
				{content_view}
				{filter_area}
			</div>
		);
	}
}

function TemplateDocSelector(props) {
	return (<OldTemplateDocSelector {...props} />);
}

export default withStyles(style)(withRouter(TemplateDocSelector));
