import {Column} from "../../../../../../shared/components/layout/table";

import {Name} from "./Name";
import {Compound} from "./Compound";
import {Indication} from "./Indication";
import {CompletionStatus} from "./CompletionStatus";
import {Actions} from "./Actions";

const columns: Column[] = [Name, Compound, Indication, CompletionStatus, Actions]
function cellViewForColumn(field: string) {
	return columns.find(column => column.field === field)?.views?.cell;
}

function accessoryForColumn(field: string) {
	return columns.find(column => column.field === field)?.views?.header;
}


export {columns, cellViewForColumn, accessoryForColumn}