import {ensureArgs} from "../filterUtilities";
import {isArrayWithLength, isEmptyArray} from "../../../guards/isArray";
import {isString} from "../../../guards/isString";
import {NunjucksFilterFactoryProps} from "../model";

function column(...args: unknown[]): unknown {
	if (!ensureArgs(args) || args.length < 2) {
		return [];
	}

	if (isEmptyArray(args[0]) || typeof(args[0]) !== "object" || !isString(args[1])) {
		return [];
	}

	const columnName = args[1] as string;

	let columnValues: unknown[] = []
	if (isArrayWithLength(args[0])) {
		const object = args[0] as any[];
		columnValues = object.map((el) => el[columnName])
	} else if(args[0] !== undefined) {
		const object = args[0] as any;

		if (object[columnName] === undefined) {
			return [];
		}

		const keys = Object.keys(object[columnName]);
		columnValues = keys.map((key) => object[columnName][key])
	}

	return columnValues.filter(c => c !== undefined && c !== null);
}

export function columnFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "column",
		help: "Filter that gets all the values from a specific column",
		example: "var | column('columnName')",
		execute: column
	})
}