import {
	useNavigate,
	useLocation,
	useParams
} from 'react-router-dom';

export const withRouter = function(Component) {
	const RoutedWrapper = function(props) {
		const navigate = useNavigate();
		const location = useLocation();
		const params = useParams();

		return(<Component {...{
			...props,
			location,
			match: {
				/*
				 * This is technically incorrect, since it will
				 * be different if there are parameters, but
				 * we only use this when there are no
				 * parameters
				 */
				path: location.pathname,
				params
			},
			history: {
				replace: function(url) {
					navigate(url, {
						replace: true
					});
				},
				push: function(url, options) {
					navigate(url, options);
				},
				go: function() {
					throw(new Error('NOT SUPPORTED'));
				}
			}
		}} />);
	};

	return(RoutedWrapper);
};
