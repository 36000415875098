import { PreviewElementProps } from "../model";
import { SectionElementPreviewValue } from "./model";
import { styled } from "@mui/material";
import { PreviewViewFactory } from "../PreviewViewFactory";
import { CommentState } from "../../../hooks/useCommentState";
import { CommentModalProps } from "../../../../../shared/definitions/highlight/CommentModal.props";
import CommentModal from "../../modals/documentComments/CommentModal";
import { HighlightWrapper } from "../../../../../shared/components/input/highlight/HighlightWrapper";
import {isInReview} from "../../../../../shared/interfaces/WorkflowState";
import {DocumentState} from "../../../hooks/useDocumentState";
import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";

const SectionHeader = styled("h2")({
	fontWeight: 600,
	fontSize: "20px",
	position: "relative",
	border: "1px solid transparent",
	minHeight: "24px",
	padding: "10px 2px 2px",
	margin: 0
})

function findSection(id: string, sections: ElementValueType[]) {
	const section = sections.find(e => e.id === id);
	if (section) {
		return section;
	}

	const children = sections.map(section => section.children ?? [])
		.reduce((all, current) => all.concat(current), []);
	return children.length > 0 ? findSection(id, children) : undefined;
}

const PreviewView = (props: PreviewElementProps) => {
	const {tableOfContents} = DocumentState.useContainer();
	const elements = props.element.children ?? [];

	const commentsManager = CommentState.useContainer();
	const highlightComments = commentsManager.highlightedCommentsByElementId(props.element.id);

	const tocSection = findSection(props.element.id, tableOfContents ?? []);
	const sectionData = tocSection?.data as SectionElementPreviewValue ?? props.element.data as SectionElementPreviewValue;
	const name = sectionData.name;
	const index = sectionData.index;

	return <>
		{name && <HighlightWrapper
			elementId={props.element.id}
			enabled={isInReview(props.state)}
			highlightComments={highlightComments}
			value={`${index}. ${name}`}
			componentToWrap={(highlightedText) =>
				<SectionHeader dangerouslySetInnerHTML={{ __html: highlightedText }} />
			}
			renderCommentModal={ (values: CommentModalProps) =>
				<CommentModal
					onClose={values.onClose}
					addEditComment={"Add"}
					showOpen={values.showOpen}
					elementId={values.elementId}
					selectedText={values.selectedText}
					textProperties={values.textProperties}
					documentId={commentsManager.documentId}
				/>
			}
		/>}
		{elements.map(element => <PreviewViewFactory
			key={element.id}
			noMargin={true}
			state={props.state}
			isLoadingVariables={props.isLoadingVariables}
			metaData={props.metaData}
			body={props.body ?? []}
			variables={props.variables}
			element={element}
		/>)}
	</>
}

export { PreviewView };
