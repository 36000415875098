import {nunjucksConstants} from "../../../nunjucks/models/NunjucksConstants";

const getCurrentWord = (text) => {
	const words = text.split(" ");
	return words[words.length - 1];
}

const areQuotesBalanced = (text: string) => {
	const stack: string[] = [];
	for (let i = 0; i < text.length; ++i){
		switch (text.charAt(i)) {
			case '"': case "'":
				if (stack.length === 0 || stack[stack.length - 1] !== text.charAt(i)) {
					stack.push(text.charAt(i));
				} else {
					stack.pop();
				}
				break;
			default:
				break;
		}
	}
	return stack.length === 0;
}

const getPreviousWord = (text: string) => {
	if (text.length > 0) {
		const words = text.split(' ');
		if (words.length > 1) {
			return words[words.length - 2];
		}
	}

	return undefined;
}

const toLowerCaseArray = (array: string[]) => array?.map((item) => item?.toLowerCase());

const didTriggerMatch = (trigger, value, previousWord) => {
	return (trigger === previousWord || (trigger === ' ' && value.endsWith(' ')) || trigger === nunjucksConstants.any);
}

/*
 * Checks if the previous word is not in the list of predecessors to exclude
 */
const didExcludePredecessors = (excludePredecessors, previousWord) => {
	excludePredecessors = toLowerCaseArray(excludePredecessors);
	return !(excludePredecessors !== undefined && excludePredecessors.indexOf(previousWord) !== -1);
}

/*
 * Checks if the previous words matches any one of the predecessors to include
 */
const didIncludePredecessors = (includePredecessors, previousWord) => {
	includePredecessors = toLowerCaseArray(includePredecessors);
	return !(includePredecessors !== undefined && includePredecessors.indexOf(previousWord) === -1);
}

export {
	didExcludePredecessors,
	didIncludePredecessors,
	didTriggerMatch,
	getPreviousWord,
	areQuotesBalanced,
	getCurrentWord
}