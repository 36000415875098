import {Button} from "../../buttons";
import { FlexBox } from "../../layout";
import {Alert, Content, MessageBoxTitle, ContentMessage, NoteBox} from "./style";
import {MessageBoxActionsType, MessageBoxAction} from './models';
import {Box} from '@mui/material';

export interface MessageBoxProps {
	title?: string,
	note?: JSX.Element | string,
	visible: boolean,
	message: JSX.Element | string,
	primaryAction: string,
	secondaryAction?: string,
	onActionClick: MessageBoxAction,
	onClose: () => void,
	destructive?: boolean,
}

const MessageBoxActions: MessageBoxActionsType = {
	Primary: "primary",
	Secondary: "secondary"
}

const MessageBox = (props: MessageBoxProps) => {
	const onSecondaryActionClick = () => props.onActionClick(MessageBoxActions.Secondary);
	const onPrimaryActionClick = () => props.onActionClick(MessageBoxActions.Primary);

	return (
		<Alert
			className={"alert"}
			open={props.visible}
		>
			<Content direction="column">
				{props.title && <MessageBoxTitle>{props.title}</MessageBoxTitle>}
				{props.message && <ContentMessage><Box>{props.message}</Box></ContentMessage>}
				{props.note && <NoteBox><Box>{props.note}</Box></NoteBox>}
				<FlexBox justify="space-between" align="center" direction="row-reverse" margin="1rem 0 0 0">
					{props.secondaryAction &&
					<Button styling="cancel" text={props.secondaryAction} onClick={onSecondaryActionClick}/>}
					<Button buttonType={props.destructive ? "destructive" : "default"} text={props.primaryAction} onClick={onPrimaryActionClick}/>
				</FlexBox>
			</Content>
		</Alert>
	);
}

export {MessageBox, MessageBoxActions}