import {Variable} from "../../../../../shared/interfaces/Variable";
import {mapperForVariableType} from "../html/views/variableMappers";
import {renderMissingVariable} from "./renderMissingVariable";
import {DocumentTreeElement} from "../../../models/element";
import {renderTemplatedString} from "../../../../../shared/nunjucks/renderTemplatedString";
import {LoadEntityFunc} from "../../../../../shared/interfaces/LoadEntityFuncType";
import {isSystemVariable} from "../../../../../shared/variables/SystemNamespaces";

async function mapVariables(variables: Variable[] | undefined, body?: DocumentTreeElement[], templateLoader?: LoadEntityFunc<KaiAlphaTemplate>): Promise<{} | undefined> {
	const variableCollection = await Promise.all((variables ?? []).filter(v => v.type !== "multi_input").map(async variable => {
		const key = isSystemVariable(variable) ? variable.namespace!.toLowerCase() : variable.name.toLowerCase();
		const variableMapper = mapperForVariableType(variable.type);

		const mappedVariable = {}

		if (variableMapper) {
			mappedVariable[key] = await variableMapper(variable, body, templateLoader);
		} else {
			mappedVariable[key] = renderMissingVariable(key);
		}

		if (mappedVariable[key]?.image) {
			mappedVariable[key] = `<img src="${mappedVariable[key].image}" alt="${variable.name}" />`;
		}

		if (mappedVariable[key] === undefined || mappedVariable[key] === "") {
			mappedVariable[key] = renderMissingVariable(key);
		}

		return mappedVariable;
	}))

	const mappedVariables = variableCollection.reduce((lookup, mappedVariable) => ({...lookup, ...mappedVariable}), {});

	if (mappedVariables) {
		Object.keys(mappedVariables).forEach(key => {
			if(typeof(mappedVariables[key]) === "string" && mappedVariables[key].indexOf("{{") > -1) {
				mappedVariables[key] = renderTemplatedString(mappedVariables[key], mappedVariables);
			}
		});
	}

	return mappedVariables
}

export {mapVariables}