import { BaseElement } from "../../../../interfaces/BaseElement";

const metaElseDefinition: BaseElement = {
	name: '@meta:else',
	type: '@meta:else',
	displayName: 'Branch [else]',
	description: '',
	allowsChildren: true,
	longDescription: '',
	contentDescription: () => "",
	toString: () => "Meta:Else (Preview Unavailable)"
} as const

export {metaElseDefinition};