import { useRunWhenValueChange } from "../hooks";
import { ColumnDefinition, Table } from "../components/layout/table";
import { useMemo, useState } from "react";
import { FilterState } from "../components/input/filter/sort/models";
import { TaskListRow } from "./TaskListRow";
import { sortAndSearch, useTaskFilter } from "./hooks/useTaskFilter";
import { AsyncComponent } from "../components";
import { columns } from "./columns";
import { SearchableTaskListFields } from "./model";
import { TaskNode, Tasks } from "../interfaces/task";

// import { filterTasks } from "../../../pages/useList";
export interface TaskListProps {
	disablePaging?: boolean | false,
	defaultColumns?: ColumnDefinition[],
	tasks: Tasks | null,
	title?: string,
	onTaskSelected: (task: TaskNode) => void
}
const TasksList = ({ disablePaging, defaultColumns, tasks, title, onTaskSelected }: TaskListProps) => {
	const taskFilter = useTaskFilter()
	const [sorting, setSorting] = useState<{ field: string, sortDirection: FilterState } | undefined>(undefined);
	const [searchValues, setSearchValues] = useState<SearchableTaskListFields>({
		name: "",
		state: "",
		owner: "",
	});

	useRunWhenValueChange(async () => {
		taskFilter.execute(tasks?.tasks ?? [], sorting);
	}, tasks);

	const onSearchValueChange = (field?: string, value?: string) => {
		if (field) {
			setSearchValues(s => ({
				...s,
				[field]: value ?? "",
			}))
		}
	};

	const handleTableSort = (field: string, sortDirection: FilterState) => {
		setSorting({ field, sortDirection });
	}

	const handleTaskSelected = (task: TaskNode) => onTaskSelected(task);

	const handleRowRender = (item: unknown, rowIndex: number) => <TaskListRow
		item={item}
		rowIndex={rowIndex}
		onTaskSelected={handleTaskSelected}
		columns={defaultColumns ?? columns} />

	const tableItems = useMemo(() => {
		if (!taskFilter.value) {
			return [] as TaskNode[]
		}

		return sortAndSearch(taskFilter.value, sorting, searchValues) as TaskNode[];
	}, [sorting, searchValues, taskFilter.value]);

	return <AsyncComponent
		isLoading={taskFilter.isLoading}
		fullScreen
		loadingMessage={"Filtering tasks..."}
		component={<Table
			items={tableItems ?? []}
			pageSize={disablePaging ? tableItems.length : 25}
			disablePaging={disablePaging}
			columns={defaultColumns ?? columns}
			title={title}
			sort={sorting}
			onColumnSearch={onSearchValueChange}
			onSort={handleTableSort}
			onRenderRow={handleRowRender} />}  />
}

export { TasksList };