import api from '../../../../api/api';
import {useAsync, useCache} from "../../../../shared/hooks";

/**
 * get all Table Types for to display in the In Text Table element in the Template editor
 * returns an array with the names of the tables.
 */
type TableListItem = {
	label: string;
	value: string;
}

type MasterConfig = {
	[k: string]: {
		metadata: {table_name: string},
		type: string
	}[]
}
function getTableMasterConfigApi(): Promise<MasterConfig> {
	return api.call('GET', "itt/masterconfig");
}


const useGetTableNames = () => {
	const cache = useCache(5);
	const loadAndMapTableNames = async (): Promise<TableListItem[]> => {
		const masterConfig = await cache.getItem("itt_masterconfig", () => getTableMasterConfigApi());
		if (masterConfig === null) {
			return [];
		}

		return Object.keys(masterConfig)
			.map(key => masterConfig[key]
				.map(item => ({label: item.metadata.table_name, value: `${key}_${item.type}`}) as TableListItem))
			.reduce((all, item) => all.concat(item), [] as TableListItem[])
	}

	return useAsync(loadAndMapTableNames, false)
}

export {useGetTableNames};