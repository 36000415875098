import {Column} from "../../../../../../shared/components/layout/table";
import {LastModified} from "./LastModified";
import {Name} from "./Name";
import {Status} from "./Status";
import {Owner} from "./Owner";
import {Actions} from "./Actions";

const columns: Column[] = [Name, Status, Owner, LastModified, Actions]
function cellViewForColumn(field: string) {
	return columns.find(column => column.field === field)?.views?.cell;
}

function accessoryForColumn(field: string) {
	return columns.find(column => column.field === field)?.views?.header;
}


export {columns, cellViewForColumn, accessoryForColumn}