import { useState, useEffect } from 'react';
import { ChildSectionProps } from './ChildSection.props';
import {Section} from "../../../models/Section";
import {CommentIcon} from "./CommentIcon";
import {FlexBox} from "../../../../../shared/components";

const ChildSection = (props: ChildSectionProps) => {
	const {
		section,
		activeSectionId,
		windowSectionId,
		classes,
		onSectionClicked,
		isExpanded,
		depth,
		comments
	} = props;

	const [indentMargin, setIndentMargin] = useState(0);
	const [sectionClasses, setSectionClasses] = useState([]);
	const [windowActive, setWindowActive] = useState(false);

	useEffect(() => {
		// useEffect may create a race condition here with the state parameters
		// running with local variables to ensure the calculations etc. are all done with the same values
		const shiftedDepth = Math.max(0, depth - 1);
		const localIndentMargin = shiftedDepth > 0 ? 10 * shiftedDepth : 0;
		setIndentMargin(localIndentMargin);
		const selected = activeSectionId === section.id;
		const localWindowActive = windowSectionId === section.id;
		setWindowActive(localWindowActive);

		let localSectionClasses: any = [depth === 0 ? classes.sectionTitle : classes.subSectionTitle];
		if (selected) {
			localSectionClasses = [
				...localSectionClasses,
				classes.activeSection,
			];
		}
		if (windowActive) {
			localSectionClasses = [
				...localSectionClasses,
				classes.windowSection,
			];
		}
		setSectionClasses(localSectionClasses);
	}, [
		activeSectionId,
		classes,
		depth,
		section.id,
		windowActive,
		windowSectionId,
	]);

	const sections = props.section.sections;

	return (
		<div
			id={`toc_${section.id}`}
			key={`toc_${section.id}`}
			style={{ marginLeft: indentMargin }}
			className={classes.subsectionItem}
		>
			<div style={{width: "100%"}}>
				<div
					onClick={() => onSectionClicked(section)}
					className={classes.sectionTitleContainer}
				>
					<div className={sectionClasses.join(' ')}>
						{depth === 0 && <>
							<span className={classes.sectionNumber}>{section.number}</span>
							<div style={{flex: 1}}>{section.name}</div>
						</>}
						{depth > 0 && <FlexBox style={{flex: 1}}>
							<span>{section.number}</span>
							<div style={{flex: 1}}>{section.name?.replace(section.number, "")}</div>
						</FlexBox>}
						<CommentIcon
							document={document}
							comments={comments}
							elementId={section.id}
							section={section}
							classes={classes}
							child={false}
						/>
					</div>
				</div>

				{sections && isExpanded &&
					sections.map((nestedSection: any) => {
						return (
							// React allows for recursive rendering of the same component
							// we are doing this here since we are not aware of how many nested elements
							// will be shown at the time of rendering
							// we may be able to have a precomputed depth value at some point in the future
							<ChildSection
								comments={comments}
								section={nestedSection}
								isExpanded={true}
								depth={depth+1}
								activeSectionId={activeSectionId}
								windowSectionId={windowSectionId}
								classes={classes}
								onSectionClicked={(nestedSection: Section) =>
									onSectionClicked(nestedSection)
								}
							/>
						);
					})}
			</div>
		</div>
	);
};

export { ChildSection };
