import { styled } from "@mui/styles";
import {Theme} from "@mui/material";
import { FlexBox } from "../FlexBox";

interface TabItemProps {
	active: boolean,
}

const TabContainer = styled(FlexBox)(({theme}) => ({
	padding: "5px 1px",
	alignItems: 'center',
	justifyContent: "space-around",
	borderRadius: "100px",
	boxShadow: "rgba(50, 50, 93, 0.15) 2px 2px 2px 1px inset, rgba(0, 0, 0, .05) 0px 3px 5px 1px inset"
}));

const TabItem = styled(FlexBox)<Theme, TabItemProps>(({active, theme}) => ({
	boxSizing: "border-box",
	padding: "0",
	margin: "0 .3rem",
	alignItems: 'center',
	justifyContent: "center",
	borderRadius: "100px",
	cursor: 'pointer',
	boxShadow: active ? "0 0 10px rgba(50, 50, 93, 0.25)" : "",
	opacity: !active ? "50%" : "",
}));

const TabItemName = styled(FlexBox)(({theme}) => ({
	margin: "0.3rem",
	alignItems: 'center',
	width: "auto",
	boxSizing: "border-box",
	justifyContent: "center",
	borderRadius: "50%",
	fontWeight: 600,
	fontSize: "0.75rem",
}));

const TabItemCount = styled(FlexBox)(({theme}) => ({
	margin: "0.3rem",
	padding: "0.15rem",
	alignItems: 'center',
	boxSizing: "border-box",
	width: "1.2rem",
	justifyContent: "center",
	borderRadius: "100%",
	fontSize: "0.7rem",
	fontWeight: 600,
	backgroundColor: theme.palette.primary.main,
	color:  theme.palette.primary.contrastText,
}));

export {
	TabContainer,
	TabItem,
	TabItemCount,
	TabItemName,
}