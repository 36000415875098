import {Column, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {SubmissionNode, submissionNodeActions} from "../../../../models";
import {FlexBox} from "../../../../../../shared/components";
import {NameTableCell} from "../../../../../../shared/components/layout/table/styles";

const NameCell = (props: ColumnViewProps) => {
	const item = props.item as SubmissionNode;


	const onRowClicked = () => {
		if (props.onAction) {
			props.onAction(submissionNodeActions.view, props.column);
		}
	}

	return (<NameTableCell depth={props.depth ?? 0} align={"left"} expandable={false}>
		<FlexBox boxSizing="border-box" align="center">
			<span className={'row_title'} onClick={onRowClicked}>{item[props.column.field]}</span>
		</FlexBox>
	</NameTableCell>)
}

const Name: Column = ({
	field: 'name',
	title: "Name",
	itemAlign: "left",
	width: "25%",
	headerAlign: "left",
	searchable: true,
	sortable: true,
	views: {
		cell: NameCell
	}
})

export {Name};