import React from 'react';
import {SquareCheckbox} from "./Variants/SquareCheckbox";
import {CircularCheckbox} from "./Variants/CircularCheckbox";
import {Box} from "@mui/material";
import {useStyles} from "./Checkbox.styles";


/*
 * Checkbox component
 * @param {string} label - optional label to show to the right of the checkbox
 * @param {string} value - Checkbox value
 * @param {boolean} checked - is the checkbox checked or unchecked
 * @param {function} onChange - event handler to handle on change event
 * @param {string} variant - checkbox variant (circle|square)
 */
const Checkbox = ({label, value, checked,  onChange, variant}) => {
	const classes = useStyles();
	const renderCheckboxVariant = () => {
		switch(variant) {
			case "square":
				return <SquareCheckbox label={label} checked={checked} onChange={onChangeHandler}/>
			case "circle":
			default:
				return <CircularCheckbox label={label} checked={checked} onChange={onChangeHandler}/>
		}
	}

	const onChangeHandler = () => {
		if (onChange) {
			onChange(!value);
		}
	}
	return <Box className={classes.checkbox}>
		{renderCheckboxVariant()}
	</Box>

}

export {Checkbox};