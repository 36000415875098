import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box } from '@mui/material';
import React from "react";
import "./style.scss";

export interface DragDropResultArea {
	index: number
}
export interface DragDropResult {
	destination: DragDropResultArea | null,
	source: DragDropResultArea | null
}
interface DragDropAreaProps {
	children: React.ReactNode,
	onDragEnd: (result: DragDropResult) => void
	isDragDisabled?: boolean,
}

/**
 * Reusable wrapper for drag and drop
 * @param children
 * @param onDragEnd
 * @constructor
 */
const DragDropArea = ({children, onDragEnd, isDragDisabled = false}: DragDropAreaProps) => {
	return(isDragDisabled ? <Box width={"100%"} className={"drag-drop__items"}>{children}</Box> : <Box width={"100%"} className={"drag-drop__items"}>
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="items">
				{(provided) => (
					<div  {...provided.droppableProps} ref={provided.innerRef}>
						{React.Children.map(children, (item, index) => {
							return (
								<Draggable key={`draggable-${index}`} draggableId={`draggable-${index}`} index={index}>
									{(provided) => (
										<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
											{item}
										</div>
									)}
								</Draggable>
							);
						})}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	</Box>
	)
}

export {DragDropArea};