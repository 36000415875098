// import { VariableElementValue } from "../../../shared/definitions/elements/variable/model";
import {Variable, VariableType} from "../../../shared/interfaces/Variable";
import { DocumentState } from "./useDocumentState";

// simple search
const search = (searchValue: string, elements: Variable[]): any => {
	if (searchValue === "") {
		return elements;
	}

	const substring = searchValue.toLowerCase().trim();
	return elements.filter(element => element!.name!.toLowerCase().includes(substring))
}

const excludedRequiredVariableTypes: VariableType[] = ["datasource", "image", "reference"];

function useVariables() {
	const documentState = DocumentState.useContainer();

	const allVariables = documentState.variables;

	const namespacedVariableNames = () => allVariables.map(variable => variable.namespace);
	const requiredVariablesWithNoValue = () => allVariables.filter(variable => variable.required &&
		variable.value === undefined && !excludedRequiredVariableTypes.includes(variable.type));

	const filterVariables = (searchValue: string) => search(searchValue, allVariables);

	const updateVariable = (variable: Variable) => {
		if (variable.id === undefined) {
			return;
		}

		const variables = [...allVariables];
		const variableIndex = variables.findIndex(v => v.id === variable.id);
		if (variableIndex < 0) {
			variables.push(variable);
		} else {
			variables[variableIndex] = {...variables[variableIndex], ...variable};
		}
		documentState.setVariables(variables);
	}

	const setVariables = (variables: Variable[]) => {
		documentState.setVariables(variables);
	}


	const bulkUpdateVariables = (variables: Variable[]) => {
		const updateableVariables = variables.filter(variable => variable.id !== undefined);
		if (updateableVariables.length === 0) {
			return;
		}

		const updatedVariables = [...allVariables].map(variable => {
			const updatedVariable = updateableVariables.find(v => v.id === variable.id);
			return updatedVariable ? ({...variable, value: updatedVariable.value}) : variable;
		});

		documentState.setVariables(updatedVariables);

	}

	return {namespacedVariableNames, filterVariables, allVariables, updateVariable, requiredVariablesWithNoValue, bulkUpdateVariables, setVariables}
}

export {useVariables};