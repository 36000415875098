import {DocumentCollection} from "../../../models/documentCollection";
import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";
import {mapTemplateBody} from "./mapTemplateBody";


export function constructDocumentTree(documentCollection: DocumentCollection): Promise<ElementValueType[]> {
	const topLevelTemplate = documentCollection.find(record => record.topLevel)?.template;
	if (topLevelTemplate === undefined || topLevelTemplate["body"] === undefined) {
		return Promise.resolve([]);
	}

	return new Promise((resolve) => {
		const templateBody = (topLevelTemplate as KaiAlphaTemplate).body;
		const topLevelElements = mapTemplateBody(templateBody ?? [], documentCollection, "", topLevelTemplate.id);
		resolve(topLevelElements.elements);
	})


}