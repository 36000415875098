import {Popper, Box, FormControl, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import {CheckCircle} from "@mui/icons-material";
import { MetadataUploadContainer, FileText } from "./MetadataUploadCard.style";
import {useState, useRef} from 'react';
import { FlexBox } from "../..";
import { FileSelectionButton } from "../../../buttons/theme";
import { Button } from "../../../buttons";
import { LoadingIndicator } from "../../../progress/LoadingIndicator";
const csv_parse = require('csv-parse/lib/sync');

export type UploadAction = 'add' | 'replace';

/**
 * This component accepts a file and parses that file into a metadata object.
 * @constructor
 */
const MetadataUploadCard = ({open, anchorEl, onData, onCancel}) => {
	const [uploadAction, setUploadAction] = useState<UploadAction>('add');
	const [file, setSelectedFile] = useState<File | null>(null);
	const [loading, setLoading] = useState(false);
	const inputFile = useRef<HTMLInputElement | null>(null);

	const chooseFileClicked = () => {
		inputFile.current!.click();
	};

	const fileSelected = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setSelectedFile(e.target.files[0]);
	}

	const onUpload = () => {
		const reader = new FileReader();
		reader.readAsText(file!);
		reader.onload = () => {
			const result = {};

			try {
				const metadata = csv_parse(reader.result, {
					columns: true
				})
				for (const m of metadata) {
					// object.keys doesnt presume order, why was this like this?
					const [keyName, valueName] = Object.keys(m);
					const key = m[keyName];
					const value = m[valueName];
					result[key] = value;
				}
				onData(uploadAction, result);
			} catch (e) {
				console.error('issue parsing metadata', e);
			}
			setLoading(false);
		}
		setLoading(true);
		setSelectedFile(null);
	}

	return <Popper
		id={"metadata-upload-card"}
		open={open}
		anchorEl={anchorEl}
		placement="bottom-start"
		popperOptions={{
			modifiers: [{
				name: "offset",
				options: {
					offset: [0, 5],
				},
			}],
		}}
	>
		<MetadataUploadContainer direction="column">
			<h3>Upload Metadata</h3>
			<p>Please select how you would like metadata to be imported:</p>
			<FormControl>
				<RadioGroup defaultValue='add' aria-label='metadata' name='metadata-upload-action' value={uploadAction} onChange={(event) => setUploadAction(event.target.value as UploadAction)}>
					<FormControlLabel className={uploadAction === 'add' ? 'selected_action' : ''} value='add' control={<Radio value='add' color='primary' checkedIcon={<CheckCircle/>}/>} label='Add as new metadata' />
					<FormControlLabel className={uploadAction === 'replace' ? 'selected_action' : ''} value='replace' control={<Radio value='replace' color='primary' checkedIcon={<CheckCircle/>}/>} label='Replace existing metadata' />
				</RadioGroup>
			</FormControl>
			<FlexBox align="center" className="file_display">
				<FileSelectionButton onClick={chooseFileClicked}/>
				<FileText>
					<span className={file !== null ? 'selected' : ''}>{file !== null ? file.name : 'No file chosen'}</span>
				</FileText>
			</FlexBox>
			<input type='file' id='metadata-file' ref={inputFile} style={{display: 'none'}} onChange={fileSelected}/>
			<FlexBox align="center" justify="flex-end">
				<Button text="Upload" disabled={file === null} onClick={onUpload}/>
				<Box margin="0px 5px"/>
				<Button styling="cancel" text="Cancel" onClick={onCancel} />
			</FlexBox>
			{(loading ?? false) && <LoadingIndicator />}
		</MetadataUploadContainer>
	</Popper>
}

export {MetadataUploadCard}