import {PreviewElementProps} from "../../model";
import {SectionElementPreviewValue} from "../model";
import {styled} from "@mui/material";
import {PreviewView as PreviewViewFactory} from "./../../PreviewView"
import {mapBodyToElementValue} from "../../../../../../shared/utilities/data/mapBodyToElementValue";

const SectionHeader = styled("h2")({
	fontWeight: 600,
	fontSize: "20px",
	position: "relative",
	border: "1px solid transparent",
	minHeight: "24px",
	padding: "10px 2px 2px",
	margin: 0
})

const PreviewView = (props: PreviewElementProps) => {
	const data = props.data as SectionElementPreviewValue;
	const body = data.body ? mapBodyToElementValue(data.body) : null;

	return <>
		<SectionHeader>{data.name}</SectionHeader>
		{body && body.map(element => <PreviewViewFactory key={element.id} noMargin={true} body={props.body} variables={props.variables} type={element.type} id={element.id} data={element.data} />)}
	</>
}

export {PreviewView};