import api from '../../../../../api/api';
import {useAsync} from "../../../../../shared/hooks";
import { CommentPayload } from '../../../components/modals/templateComments/CommentModal.models';

/**
 * Create a comment
 * requires Template ID and payload with comment information
 * Element ID field is optional within the KaiAlphaCommentEntry in the payload
 * @returns
 */
const useAddComment = () => {
	const addComment = async (templateId: string, commentPayload: CommentPayload): Promise<KaiAlphaCommentEntry | null> => {
		return api.call('POST', `comment/template/${templateId}`, commentPayload);
	}
	return useAsync<KaiAlphaCommentEntry>(addComment, false);
}

export {useAddComment};