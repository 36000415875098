import {NunjucksFilterDocumentation} from "./model";

export const nativeFilterDocumentationCollection: readonly NunjucksFilterDocumentation[] = [
	{
		"name": "abs",
		"help": "Get Absolute Value of an argument",
		"example": "var | abs"
	},
	{
		"name": "batch",
		"help": "Return list with given number of items",
		"example": "[var] | batch(n)"
	},
	{
		"name": "capitalize",
		"help": "Make first letter capital, the rest lower case",
		"example": "\"var\" | capitalize"
	},
	{
		"name": "center",
		"help": "Center value in field of a given width",
		"example": "var | center"
	},
	{
		"name": "dictsort",
		"help": "Sort a dict and yield (key,value) pairs",
		"example": "{var} | dictsort"
	},
	{
		"name": "dump",
		"help": "Call stringify on Object and dump results into template",
		"example": "[var] | dump"
	},
	{
		"name": "escape",
		"help": "Convert special characters in strings to HTML-safe sequences",
		"example": "\"<html>\" | escape"
	},
	{
		"name": "first",
		"help": "Get first item in an array or first letter of string ",
		"example": "[var] | first"
	},
	{
		"name": "float",
		"help": "Convert a value into a floating point number",
		"example": "var | float"
	},
	{
		"name": "indent",
		"help": "Indent a string using spaces",
		"example": "var | indent"
	},
	{
		"name": "groupby",
		"help": "Group a sequence of objects by a common attribute",
		"example": "[{var}] | groupby(\"key\")"
	},
	{
		"name": "int",
		"help": "Convert value to integer",
		"example": "var | int"
	},
	{
		"name": "join",
		"help": "Return a string which is the concatenation of strings in a sequence",
		"example": "[var] | join"
	},
	{
		"name": "last",
		"help": "Get last item in array or string",
		"example": "[var] | last"
	},
	{
		"name": "length",
		"help": "Return the length of an array or string, or number of keys in an object",
		"example": "[var] | length"
	},
	{
		"name": "list",
		"help": "Convert value into a list",
		"example": "'var' | list"
	},
	{
		"name": "lower",
		"help": "Convert string to all lower case",
		"example": "var | lower"
	},
	{
		"name": "nl2br",
		"help": "Replace new lines with html br element",
		"example": "'var\nvar' | striptags(true) | escape | nl2br"
	},
	{
		"name": "random",
		"help": "Select a random value from array",
		"example": "[var] | random"
	},
	{
		"name": "reject",
		"help": "Filter a sequence applying a test to each object and rejecting the objects with the test succeeding",
		"example": "[var] | reject(\"odd\") | join"
	},
	{
		"name": "rejectattr",
		"help": "Filter a sequence of objects by applying a test and rejecting the objects with test succeeding",
		"example": "[{var}] | rejectattr(\"test\")"
	},
	{
		"name": "replace",
		"help": "Replace one item with another.",
		"example": "var | replace(\"\",\".\")"
	},
	{
		"name": "reverse",
		"help": "Reverse a string",
		"example": "var | reverse"
	},
	{
		"name": "round",
		"help": "Round a number",
		"example": "var | round"
	},
	{
		"name": "safe",
		"help": "Mark value as safe from automatic escapes",
		"example": "var | urlize | safe"
	},
	{
		"name": "select",
		"help": "Filter sequence of objects and only selecting objects with test succeeding",
		"example": "[var] | select(\"odd\")"
	},
	{
		"name": "selectattr",
		"help": "Filter a sequence of objects by applying a test to the specified attribute of each object, selecting the objects with the test succeeding",
		"example": "[{var}] | selectattr(\"test\")"
	},
	{
		"name": "slice",
		"help": "Slice an iterator and return a list of lists containing items",
		"example": "[var] | slice(n)"
	},
	{
		"name": "sort",
		"help": "Sort with Javascripts arr.sort function",
		"example": "var | string | list"
	},
	{
		"name": "string",
		"help": "Convert an object to a string",
		"example": "var | string"
	},
	{
		"name": "striptags",
		"help": " Analog of jinjas striptags",
		"example": "[var] | striptags(var,preserve_linebreaks)"
	},
	{
		"name": "sum",
		"help": "Output the sum of items in an array",
		"example": "[var] | sum"
	},
	{
		"name": "title",
		"help": "Make the first letter of the string uppercase",
		"example": "\"var\" | title"
	},
	{
		"name": "trim",
		"help": "Strip leading and trailing whitespace",
		"example": "\" var \" | trim"
	},
	{
		"name": "truncate",
		"help": "Return a truncated copy of string. Specifying length",
		"example": "\"var\" | truncate(n)"
	},
	{
		"name": "upper",
		"help": "Convert the string to upper case",
		"example": "\"var\" | upper"
	},
	{
		"name": "urlencode",
		"help": "Escape strings for use in URLS",
		"example": "\"var\" | urlencode"
	},
	{
		"name": "urlize",
		"help": "Convert URLs in plain text into clickable links",
		"example": "\"var\" | urlize | safe"
	},
	{
		"name": "wordcount",
		"help": "Count and output number of words in a string",
		"example": "\"var\" | wordcount"
	}
] as const;