import {useNavigate} from "react-router-dom";
import {useRunOnce} from "../../../shared/hooks";
import {TaskNode} from "../../../shared/interfaces/task";
import {moduleLinkGenerator} from "../../moduleNavigation";
import {useGetDocumentTasks} from "../hooks/api/useGetDocumentTasks";

function useTasks(documentId?: string) {
	const getTasks = useGetDocumentTasks();
	const navigate = useNavigate();
	useRunOnce(() => {
		getTasks.loadTasks(documentId);
	})

	const onNavigateToTask = (task: TaskNode) => {
		const taskUrl = moduleLinkGenerator("content", "edit", task.source.id);
		navigate(taskUrl);
	}

	return {
		tasks: getTasks.value,
		isRunning: getTasks.isLoading,
		onNavigateToTask
	}
}

export {useTasks}