import { DocumentTreeElement } from "../../../../modules/document/models/element";
import { BaseElement } from "../../../interfaces/BaseElement";
import {TreeNode} from "../../../components/list/tree/TreeList.models";

const sectionDefinition: BaseElement = {
	name: 'Template',
	type: 'section',
	displayName: 'Section',
	description: 'Place Sub-Templates',
	allowsChildren: true,
	longDescription: 'Template elements may be used to include another template at the position where this element is placed',
	contentDescription: (contents) => {
		const treeNode = contents as TreeNode;
		if ('data' in treeNode) return treeNode.data?.name ?? "";
		return (contents as SectionElementValue)?.name ?? "";
	},
	toString: (contents) => {
		const e = contents as DocumentTreeElement;
		if ('data' in e) {
			return (e.data as SectionElementValue).name as string;
		}
		return (contents as SectionElementValue).name ?? "Untitled Section";
	}
} as const

export type SectionElementValue = {
	name?: string,
	index?: string
}

export {sectionDefinition};