import { useStatusBar } from "../../../../../shared/components";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import { useUpdatePermissions } from "../../api/useUpdatePermissions";
import { DocumentState } from "../../useDocumentState";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

type RunProps = {
	documentId: string,
	lastupdated?: number,
	permissions: KaiAlphaDocumentPermissions
}
function useSetPermissionsAction(): TypedAction<RunProps> {
	const documentManager = DocumentState.useContainer();
	const updatePermissions = useUpdatePermissions();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		const res = updatePermissions.value;
		if (res?.documentPermissions && res.version) {
			documentManager.setDocument(s => ({
				...s,
				permissions: res.documentPermissions,
				version: res.version,
				lastupdated: res.lastupdated
			}));

			statusBar.sendSuccessNotification({message: "Permissions updated"});
		}
	}, updatePermissions.value);

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: `Error updating permissions ${updatePermissions.error}`});
	}, updatePermissions.error)

	const run = ({documentId, permissions, lastupdated}: RunProps) =>
		updatePermissions.execute(documentId, permissions, lastupdated);


	return {run, isRunning: updatePermissions.isLoading};
}

export {useSetPermissionsAction}