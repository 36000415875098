import {useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import {DocumentState} from "../../hooks/useDocumentState";
import {DynamicFeed} from "@mui/icons-material";
import {useRunWhenValueChange} from "../../../../shared/hooks";
import {Context, DefaultContextButtons} from "../../../../shared/components/buttons/theme";
import { getUserContextButtons } from "../../utilities/workflow";
import {useStatusBar} from "../../../../shared/components";
import {EntityRecord} from "../../../../shared/interfaces/Entity";
import {moduleLinkGenerator} from "../../../moduleNavigation";
import { useDocumentAutoSave } from "../../hooks/useDocumentAutoSave";
import {
	useCreateDocumentAction,
	useGetDocumentRenderPDFAction,
	useFetchAllDataSourcesActions,
	useLoadAllCommentsAction,
	useSetBufferFromTempBuffer,
	useLoadDocumentCollectionAction,
	usePublishDocumentAction,
	useLoadDocumentAction,
	useSaveBufferAction,
	useTemplateUpgradeAction,
	DEFAULT_DOCUMENT_WORKFLOW_SLOT,
	useUpdateDocumentWorkflowAction
} from "../../hooks/actions";

import { useAutoTemplateUpgrade } from "../../hooks/useAutoTemplateUpgrade";

function useEditor() {
	const {id, version} = useParams();
	const documentManager = DocumentState.useContainer();
	const statusBar = useStatusBar();
	const navigate = useNavigate();
	// Actions
	const saveBufferAction = useSaveBufferAction();
	const loadDocumentAction = useLoadDocumentAction();
	const loadDocumentCollectionAction = useLoadDocumentCollectionAction();
	const publishDocumentAction = usePublishDocumentAction();
	const updateDocumentWorkflowAction = useUpdateDocumentWorkflowAction();
	const setBufferFromDocument = useSetBufferFromTempBuffer();
	const loadAllCommentsAction = useLoadAllCommentsAction();
	const getDocumentRenderPDFAction = useGetDocumentRenderPDFAction();
	const templateUpgradeAction = useTemplateUpgradeAction();
	const fetchAllDataSourcesAction = useFetchAllDataSourcesActions();
	const createDocumentAction = useCreateDocumentAction();

	const [error, setError] = useState<string | null>(null);
	const [contextButtons, setContextButtons] = useState<Context[]>([]);
	// question user if they would like to keep their buffer changes or start from last published template
	const [displayBufferMessageBox, setBufferMessageBoxDisplay] = useState(false);
	// question user if they would like to upgrade the document's template
	const [displayUpgradeTemplateMessageBox, setDisplayUpgradeTemplateMessageBox] = useState(false);
	// require user to provide reasoning (comment) for rejecting a workflow state
	const [workflowCommentModalState, setWorkflowCommentModalState] = useState({display: false, pendingEventId: ''});

	const canAutoSave = !(saveBufferAction.isRunning ||
		publishDocumentAction.isRunning ||
		updateDocumentWorkflowAction.isRunning ||
		loadDocumentAction.isRunning ||
		createDocumentAction.isRunning ||
		documentManager.isApproved ||
		documentManager.template?.id === undefined ||
		documentManager.buffer === null ||
		!documentManager.editable
	);

	// start auto saving interval
	useDocumentAutoSave(() => canAutoSave);

	// start auto template upgrade
	useAutoTemplateUpgrade(() => canUpgradeTemplate, () => {
		setDisplayUpgradeTemplateMessageBox(true);
	});

	// when editor loads, get the associated document (triggers below effect)
	useRunWhenValueChange(() => {
		documentManager.reset();
	}, [id, version]);

	useRunWhenValueChange(() => {
		if (documentManager.didReset && id) {
			loadDocumentAction.run({id, version});
			loadDocumentCollectionAction.run({id, version});
		}
	}, documentManager.didReset);

	// do this when value change because we need the document collection compiled to get all of the comments
	useRunWhenValueChange(() => {
		loadAllCommentsAction.run();
	}, documentManager.documentCollection)

	useRunWhenValueChange(() => {
		// if template id has changed, we have a buffer, and we're not in review
		// prompt the user to see if they wish to load the buffer
		if (documentManager.tempBuffer && !documentManager.isInReview) {
			setBufferMessageBoxDisplay(true);
		} else if(documentManager.document) {
			setBufferFromDocument(documentManager.document!);
		}
	}, documentManager.document?.id);

	useRunWhenValueChange(() => {
		const isUpdating = loadDocumentCollectionAction.isRunning || publishDocumentAction.isRunning || saveBufferAction.isRunning || templateUpgradeAction.isRunning || fetchAllDataSourcesAction.isRunning;
		setContextButtons([
			{ ...DefaultContextButtons.Publish, disabled: !documentManager.didChange || isUpdating || !documentManager.editable},
			{...DefaultContextButtons.Save, disabled: !documentManager.editable || isUpdating },
			{ ...DefaultContextButtons.View, disabled: id === undefined },
			{ ...DefaultContextButtons.Update, label: "Template Upgrade", disabled: id === undefined || !documentManager.editable || isUpdating },
			...(isUpdating ? [] : getUserContextButtons(
				documentManager.document?.workflow?.[DEFAULT_DOCUMENT_WORKFLOW_SLOT]?.ui?.buttons ?? [],
				documentManager.computedUserRoles ?? []
			)),
			{ label: "Fetch All Datasources", key: "fetch", Icon: DynamicFeed, disabled: id === undefined || !documentManager.editable || isUpdating }
		]);
	}, [
		documentManager.document?.workflow,
		documentManager.computedUserRoles,
		documentManager.editable,
		documentManager.document?.state,
		loadDocumentCollectionAction.isRunning,
		publishDocumentAction.isRunning,
		saveBufferAction.isRunning,
		templateUpgradeAction.isRunning,
		fetchAllDataSourcesAction.isRunning,
		documentManager.didChange
	]);

	// // Check if document template version is out of synce with top level template
	//
	// useRunWhenValueChange(async () => {
	// 	const {template} = documentManager;
	//
	// 	if (template && canUpgradeTemplate) {
	// 		setDisplayUpgradeTemplateMessageBox(await shouldUpgradeTemplate(documentManager.template))
	// 	}
	// }, [documentManager.template]);

	// Start template upgrade check interval

	const canUpgradeTemplate = !(saveBufferAction.isRunning ||
		publishDocumentAction.isRunning ||
		updateDocumentWorkflowAction.isRunning ||
		loadDocumentAction.isRunning ||
		createDocumentAction.isRunning ||
		documentManager.isInReview ||
		documentManager.isApproved ||
		// Don't run interval if message box is already open
		displayUpgradeTemplateMessageBox
	);


	const onWorkflowUpdate = (event: {id: string, comment?: string}) => updateDocumentWorkflowAction.run({...event, lastupdated: documentManager.document?.lastupdated});
	const updateWorkflowIfRolePresent = (role: Array<string>, error: string, id: string) => {
		if (role.length < 1) {
			setError(error);
		} else {
			onWorkflowUpdate({id});
		}
	}

	/**
	 * called by the content editor to actually do a publish
	 */
	const publishDocument = () => {
		statusBar.sendInfoNotification({message: "Publishing content..."});
		publishDocumentAction.run();
	}

	const saveBuffer = () => {
		statusBar.sendInfoNotification({message: "Saving content..."});
		saveBufferAction.run()
	}

	/**
	 * Compares the templates tied to content (documents and subdocuments included) and updates based on changes
	 * when updates are finished a publish occurs to persist the work
	 */
	const templateUpgrade = () => templateUpgradeAction.run();

	const downloadPDF = async () => {
		getDocumentRenderPDFAction.run(
			{
				id: documentManager.document!.id,
				version: documentManager.document!.version
			});
	}

	const fetchDataSources = () => fetchAllDataSourcesAction.run();
	const onNavigateToVersion = ({id, version}: Required<EntityRecord>) => navigate(moduleLinkGenerator("content", "edit", id, version === "HEAD" ? "" : version));

	return {
		displayBufferMessageBox,
		setBufferMessageBoxDisplay,
		displayUpgradeTemplateMessageBox,
		setDisplayUpgradeTemplateMessageBox,
		setBufferFromDocument,
		saveBuffer,
		updateWorkflowIfRolePresent,
		onWorkflowUpdate,
		workflowCommentModalState,
		setWorkflowCommentModalState,
		contextButtons,
		error,
		isLoading:
			loadDocumentAction.isRunning || updateDocumentWorkflowAction.isRunning ||
			getDocumentRenderPDFAction.isRunning,
		isUpdating: publishDocumentAction.isRunning || saveBufferAction.isRunning || fetchAllDataSourcesAction.isRunning || templateUpgradeAction.isRunning,
		setBuffer: documentManager.setBuffer,
		location: {id, version},
		document: documentManager.document,
		publishDocument,
		templateUpgrade,
		downloadPDF,
		fetchDataSources,
		onNavigateToVersion
	}
}

export {useEditor};