import {ElementPropertyData} from "./model";
import {definitionForElementType, iconForElementType, readonlyViewForElementType} from "./index";
import {FlexBox} from "../../../../shared/components";
import {Box, styled} from "@mui/material";
import {CommentButton} from "../../../../shared/components/layout/box/editControlBox/CommentButton";
import {ForwardedRef, forwardRef, useState} from "react";
import CommentModal from "../modals/templateComments/CommentModal";
import {CommentState} from "../../hooks/useCommentState";


export type ReadonlyViewProps = ElementPropertyData &
	{className?: string, depth?: number} & {renderFallback?: () => JSX.Element}

const Container = styled(FlexBox)({
	"& .buttons": {
		display: "none",
	},
	"&:hover .buttons": {
		display: "flex",
		margin: ".5rem 0 -.5rem 0"
	}
})

const View = (props: ReadonlyViewProps, ref: ForwardedRef<HTMLDivElement>) => {
	const commentManager = CommentState.useContainer();
	const [showCommentModal, setShowCommentModal] = useState(false);

	const handleCommentModalClose = () => {
		setShowCommentModal(false);
	}

	const handleCommentClick = () => {
		setShowCommentModal(true);
	}

	const View = readonlyViewForElementType(props.elementInformation?.type);
	const Icon = iconForElementType(props.elementInformation?.type);
	const elementDefinition = definitionForElementType(props.elementInformation?.type);

	const classNames = [props.className, "template-editor__elements",
		(elementDefinition?.allowsChildren ?? false) || (props.depth ?? 0) > 0 ? "template-editor__elements-nested" : "",
		(props.depth ?? 0) > 0 ? "template-editor__elements-nested-nm" : "",
		commentManager.selectedComment?.elementId === props.id ? "template-editor__elements--comment-selected" : ""
	].join(" ");

	const padding = (props.depth ?? 0) > 0 ? "0 2rem" : "0 2rem";
	const margin = ((elementDefinition?.allowsChildren ?? false) && props.depth === 0) || (props.depth ?? 0) === 0 ? ".5rem 0 0" : null;

	return View ? <Container ref={ref} direction={"column"} sx={{p: padding, m: margin, boxSizing: "border-box"}}  className={classNames}>
		<FlexBox justify={"flex-end"} sx={{mb: ".5rem"}} className={"button-container"}>
			<Box className={"buttons"}>
				{!commentManager.disableComments && <CommentButton onClick={handleCommentClick} />}
			</Box>
		</FlexBox>
		<FlexBox className={"template-editor__elements-view"}>
			<Box sx={{mr: "20px"}}>
				<Icon />
			</Box>
			<Box width={"100%"} id={props.id}>
				<View {...props} />
			</Box>
		</FlexBox>
		{showCommentModal &&
			<CommentModal
				onClose={handleCommentModalClose}
				addEditComment={"Add"}
				showOpen={showCommentModal}
				elementId={props.id}
			/>}
	</Container> : props.renderFallback ? props.renderFallback() : null
}

const ReadonlyView = forwardRef<HTMLDivElement, ReadonlyViewProps>(View)
export {ReadonlyView}