import { Box, styled } from "@mui/material/";

export interface PaneProps {
	transparent?: boolean,
	secondary?: boolean,
}

const Pane = styled(Box, {shouldForwardProp: (prop) => prop !== "transparent"})<PaneProps>(({theme, transparent, secondary}) => (({
	marginLeft: "5px",
	marginRight: "5px",
	height: '100%',
	boxSizing: "border-box",
	width: "100%",
	overflow: "hidden",
	borderRadius: "40px",
	background: transparent ? theme.palette.background.default : secondary ? theme.palette.tabs?.static : theme.palette.primary.contrastText,
	boxShadow: `rgba(0, 0, 0, 0.09) 0px 2px 6px, rgba(0, 0, 0, 0.02) 0px 0px 0px 2px`,
	position: "relative",
})));

export {Pane};