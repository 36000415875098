import {EctdMetaData} from "../../models/MetaData";
import {ElasticHelper} from "../../../../shared/hooks/api/search/elastic/QueryBuilder";
import {isEmptyString} from "../../../../shared/utilities/isEmptyString";
import {useRunWhenValueChange, useTypedAsync} from "../../../../shared/hooks";
import {QueryResponse} from "../../../../shared/hooks/api/search/QueryResponse";
import {findMatchingVersionFromVersionList, sortVersionListByDateAndMap} from "../../../../shared/hooks/useVersions";
import {TypeAsyncReturn} from "../../../../shared/hooks/useAsync";
import {invokeHealthCheck} from "../../../../shared/hooks/useBackendHealthCheck";
import {SearcherType, useSearcher} from "../../../../shared/hooks/api/search/useSearcher";
import {ElasticQueries} from "../../../../shared/hooks/api/search/elastic/QueryRequest";

/*
Builds an elastic query making sure to match on compound, on at least one indication and on the eCTD section number.
Will also filter on document name if searchText has a value
 */
function buildMetaDataQuery(tags: EctdMetaData, searchText?: string) {

	const query = ElasticHelper.must(ElasticHelper.match("metadata.user.Compound", tags.compound!));

	if (tags.indications.length > 0) {
		const shouldQuery = ElasticHelper.should(1, ...tags.indications.map(indication => ElasticHelper.match("metadata.user.Indication", indication)));
		// also match when content doesn't have an indication set.
		shouldQuery.bool.should.push(ElasticHelper.mustNot(ElasticHelper.exists("metadata.user.Indication")))
		query.bool.must.push(shouldQuery);
	}

	if (!isEmptyString(searchText)) {
		query.bool.must.push(ElasticHelper.fuzzyMatch("name", searchText!));
	}

	query.bool.must.push(ElasticHelper.match("metadata.user.eCTDSection", tags.section));
	return query;
}

export type InternalContentSearchProps = {
	searchText?: string,
	tags?: EctdMetaData,
	after?: string[] | number[]
}

const defaultSort: {field: string, direction: "asc" | "desc"} = {field: "@date", direction: "desc"}

function useDocumentVersionMapper() {
	const mapDocumentVersion = (document: KaiAlphaDocument) => {
		const sortedVersionList = sortVersionListByDateAndMap(document["all_versions"] ?? []);
		const versionNumber = findMatchingVersionFromVersionList(document.version, sortedVersionList)?.number;
		return {...document, metadata: {...document.metadata, system: {...(document.metadata?.system ?? {}), versionNumber: versionNumber ?? "N/a"}}};
	}
	const mapVersions = (queryResponse: QueryResponse<KaiAlphaDocument>): Promise<QueryResponse<KaiAlphaDocument>> => {
		queryResponse.results = queryResponse.results.map(mapDocumentVersion);
		return Promise.resolve(queryResponse);
	}

	return useTypedAsync(mapVersions, false);
}
export function useGetInternalContent(pageSize: number): TypeAsyncReturn<(InternalContentSearchProps | undefined), QueryResponse<KaiAlphaDocument>> {
	const searcher = useSearcher<KaiAlphaDocument>("document")
	const versionMapper = useDocumentVersionMapper();

	useRunWhenValueChange(() => {
		if (searcher.value) {
			versionMapper.execute(searcher.value);
		}
	}, searcher.value);

	const search = async (props?: InternalContentSearchProps) => {
		// temporary check to see which version of the server we are running and determine the search stack
		const healthCheck = await invokeHealthCheck();
		const searcherType: SearcherType = healthCheck.status === "healthy" ? "mongo" : "elastic";
		defaultSort.field = healthCheck.status === "healthy" ? "lastupdated" : "@date";

		const mustQuery = ElasticHelper.must(ElasticHelper.mustNot(ElasticHelper.exists("superdocument")));
		// if no filters, return all results (document must be toplevel)
		if (props === undefined || (isEmptyString(props.searchText) && props.tags?.compound === undefined)) {
			return executeQuery(searcherType, mustQuery, props?.after);
		}

		const {searchText, tags} = props;
		// if no compound try running a search against the search text. Otherwise build a search based on meta data
		const query = tags?.compound === undefined ? ElasticHelper.should(1,
			ElasticHelper.fuzzyMatch("name", searchText!),
			ElasticHelper.match("metadata.user.Compound", searchText!),
			ElasticHelper.match("metadata.user.Indication", searchText!)
		) : buildMetaDataQuery(tags!, props.searchText); // filter to submission meta data and optionally filter document name to searchText

		// append the query to the "master" query
		mustQuery.bool.must.push(query);

		// execute query
		return executeQuery(searcherType, mustQuery, props?.after)
	}

	// this function ensures we have the correct page size, sort, and "after" parameter
	const executeQuery = (searcherType: SearcherType, query: ElasticQueries, after?: string[] | number[]) =>
		searcher.execute({query: ElasticHelper.build(query, ElasticHelper.sort(defaultSort), pageSize, after), type: searcherType})


	const mapCorrectStatus = () => {
		if (searcher.status === "error" || versionMapper.status === "error") {
			return "error";
		}

		if (searcher.status === "pending" || versionMapper.status === "pending") {
			return "pending";
		}

		if (searcher.status === "success" || versionMapper.status === "success") {
			return "success";
		}

		return "idle";
	}

	return {
		execute: search,
		error: searcher.error || versionMapper.error,
		value: versionMapper.value,
		isLoading: searcher.isLoading || versionMapper.isLoading,
		status: mapCorrectStatus()
	}
}