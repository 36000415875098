import { ExpandableTableItem } from "../../../../../shared/components/layout/table";
import {FilterState} from "../../../../../shared/components/input/filter/sort/models";

export type TemplateTypes = "orphan" | "toplevel";

export const templateListSearchableFields = ['name', 'metadata', 'state', 'owner'] as const;

export type TemplateListSearchableFieldTypes = typeof templateListSearchableFields[number];

// the field, and the string value searched for the given field
export type SearchableTemplateListFields = Record<TemplateListSearchableFieldTypes, string>;

export interface TemplateListNode extends ExpandableTableItem {
	hasTask?: boolean;
	children: TemplateListNode[],
	date: string,
	state: string,
	name: string,
	expanded?: boolean,
	model_document: string,
	id: string,
	// list of metadata values, starting with model document.
	metadata: {
		[k: string]: string,
	},
	owners: string[],
	version: string,
}

export type TemplateListAction = "expand" | "view" | "delete";
export type TemplateListActionLookup = Record<TemplateListAction, TemplateListAction>;
export const TemplateListActions: TemplateListActionLookup = {
	expand: "expand",
	view: "view",
	delete: "delete"
}

export type SortableColumn = {
	field: string,
	sorter: (items: TemplateListNode[], state: FilterState) => TemplateListNode[]
}
// property of model_document in
const modelDocumentMetadataKey = "model_document";

export {modelDocumentMetadataKey};