import {useAsync} from "../../../../shared/hooks";
import {getTemplateTasksApi} from "./getTemplateTasksApi";

function useGetTemplateTasks() {
	const api = useAsync(getTemplateTasksApi, false);
	const loadTasks = (templateId?: string) => api.execute(templateId);

	return {
		loadTasks,
		isLoading: api.isLoading,
		value: api.value,
		error: api.error
	}
}

export {useGetTemplateTasks}
