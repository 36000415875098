import { useStatusBar } from "../../../../../shared/components";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import {TypedActionWithValue} from "../../../../../shared/interfaces/ActionRun";
import { useLoadImageFromS3KeyApi } from "../../../../../shared/hooks/api/s3/useLoadImageFromS3KeyApi";
import { S3PostFileValue } from "../../../../../shared/hooks/api/s3/models";

type RunProps = {
	fileValue: S3PostFileValue
}
function useLoadImageFromS3Action(): TypedActionWithValue<RunProps, { data: string; } | null> {
	const statusBar = useStatusBar();
	const loadImage = useLoadImageFromS3KeyApi();

	useRunWhenValueChange(() => {
		statusBar.sendSuccessNotification({message: "Image was retrieved sucessfully"});
	}, loadImage.value)

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Image download failed", detail: loadImage.error});
	}, loadImage.error)

	const run = ({fileValue}: RunProps) =>
		loadImage.execute(fileValue);

	return {run, isRunning: loadImage.isLoading, value: loadImage.value};
}

export {useLoadImageFromS3Action}