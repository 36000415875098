import {NunjucksVariables} from "../../interfaces/Nunjucks";
import {compareExpressionsFactory, CompareExpressionsFactoryOptions} from "./compareExpressionsFactory";
import {renderString} from "../renderString";

type CompareExpressionsProps = {
	lhs?: string,
	rhs: string,
	variables: NunjucksVariables,
	options?: CompareExpressionsFactoryOptions
}

const trueValue = 'fe946d50-a7e1-48b2-8a4b-6228ed5bdf51';
export function compareExpressions({lhs, rhs, variables, options}: CompareExpressionsProps) {
	/*
	 * Ensure that variables is an object
	 */
	variables = {
		...variables
	};

	const template_info = compareExpressionsFactory({
		...(options ?? {}),
		lhs: lhs,
		rhs: rhs,
		true: trueValue
	});
	const template = `${template_info.lhsTemplate}${template_info.rhsTemplate}`

	const result = renderString({template, variables});
	return result === trueValue;

}