import {TryAsync} from "../interfaces/Either";
import {useAsync} from "./useAsync";

export type HealthCheckResponse = {
	status: "healthy" | "down"
}
export async function invokeHealthCheck(): Promise<HealthCheckResponse> {
	const result = await TryAsync(async () => {
		const response = await fetch("/api/v1/health");
		return response.json();
	});
	return result.matchWith({
		left: e => ({status: "down"}) as HealthCheckResponse,
		right: v => v as HealthCheckResponse
	});
}
export function useBackendHealthCheck() {
	return useAsync(invokeHealthCheck, false);
}