import {StylePropertyFactory} from "./model";
import {BorderCollapse, BorderColor, BorderStyle, BorderWidth, StyleAttribute} from "../../../editor/elements/styles";

type SupportedAttributes = BorderCollapse | BorderColor | BorderWidth | BorderStyle
type MappedAttributes = SupportedAttributes

const attributeMap: Record<MappedAttributes, (value: Record<SupportedAttributes, string>) => string> = {
	border_color: value => `border-color: ${value.border_color} !important`,
	border_width: value => `border-width: ${value.border_width}pt !important`,
	border_style: value => `border-style: ${value.border_style} !important`,
	border_collapse: value => `border-collapse: ${value.border_collapse} !important`
}
function factory(styleMap: Partial<Record<StyleAttribute, string>>): string {
	const css = Object.keys(styleMap).map(key => {
		if(styleMap[key] !== undefined) {
			const valueFunc = attributeMap[key];
			return valueFunc ? valueFunc(styleMap) : "";
		}
		return "";
	}).filter(value => value.length > 0).join(";");

	return css.length === 0 ? "" : "{" + css + ";}";
}

const borderStyleFactory: StylePropertyFactory = factory;
export {borderStyleFactory};