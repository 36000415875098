import {useAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";
import { PublishCollection, Subdocuments } from "../actions";
import {ApiPatchResult} from "../../../../shared/interfaces";
import { isEmptyObject } from "../../../../shared/utilities/isEmptyObject";
import {EntityMetaData} from "../../../../shared/interfaces/EntityMetaData";
import {NunjucksVariableAdapter} from "../../../../shared/nunjucks/nunjucksVariableAdapter";

const uuid = require('uuid');

function usePublishDocument() {
	const callAPI = (
		documentId: string,
		documentVersion: string,
		documentName?: string,
		variables?: KaiAlphaVariables,
		metaData?: EntityMetaData,
		permissions?: KaiAlphaDocumentPermissions,
		templateVersion?: string,
		lastupdated?: number,
		subdocuments?: Subdocuments,
		deletedSubdocuments?: Subdocuments
	): Promise<ApiPatchResult> => {
		const toChange = {
			variables: {},
			name: "",
			template: {},
			subdocuments: {},
			metadata: {},
			lastupdated
		}
		const toDelete = {
			subdocuments: {},
			metadata: {}
		};

		if(deletedSubdocuments){
			if(!isEmptyObject(deletedSubdocuments)) {
				toDelete.subdocuments = deletedSubdocuments;
			}
		}

		// if(subdocuments) {
		// 	toChange.subdocuments = subdocuments;
		// }

		if (variables !== undefined) {
			toChange.variables = NunjucksVariableAdapter.toTree(variables);
		}

		if (metaData !== undefined) {
			toChange.metadata = metaData;
		}

		if (documentName !== undefined) {
			toChange.name = documentName;
		}

		if(templateVersion) {
			toChange.template = {
				version: templateVersion
			}
		}

		const payload = {
			id: documentId,
			version: documentVersion,
			summary: null,
			change: toChange,
			delete: toDelete,
			"_options": {
				"force":false,
				"strongForce":false,
				"mergeVersionsOnError":true,
				"forceSave":false,
				"topLevel":true,
				"action_id": uuid.v4(),
				"publish": true
			}
		};

		return api.call('PATCH', `document/${documentId}`, payload);
	}

	const publishDocument = async (publishCollection: PublishCollection[]): Promise<ApiPatchResult[]> => {
		return Promise.all(publishCollection.map((document) => {
			return callAPI(
				document.documentId,
				document.documentVersion,
				document.documentName,
				document.variables,
				document.metaData,
				document.permissions,
				document.templateVersion,
				document.lastupdated,
				document.subdocuments,
				document.deletedSubdocuments);
		}))
	}

	return useAsync<ApiPatchResult[]>(publishDocument, false);
}

export {usePublishDocument}