import {computeExpressionFactory} from "./computeExpressionFactory";
import {NunjucksVariables} from "../../interfaces/Nunjucks";
const uuid = require('uuid');

export type CompareExpressionsFactoryOptions = {
	lhs?: string,
	rhs?: string,
	rhsQuoteDefault?: boolean,
	variable?: string,
	variables?: NunjucksVariables,
	hasElseClause?: boolean,
	ifStatement?: string,
	true?: string,
	false?: string
}

type CompareExpressions = {
	lhsTemplate: string,
	rhsTemplate: string
}
export function compareExpressionsFactory(props: CompareExpressionsFactoryOptions): CompareExpressions {
	const options: CompareExpressionsFactoryOptions = {
		lhs: undefined,
		rhs: undefined,
		rhsQuoteDefault: false,
		variable: undefined,
		hasElseClause: true,
		ifStatement: 'if',
		...props
	};

	if (options.variable === undefined && options.lhs === undefined) {
		throw(new Error('Both parameters "variable" and "lhs" cannot be undefined, and they are'));
	}

	if (options.rhs === undefined) {
		throw(new Error('Parameter "rhs" must not be undefined'));
	}

	if (options.hasElseClause && options.true === undefined && options.false === undefined) {
		throw(new Error('Parameters "true" and "false" are required'));
	}

	const token = uuid.v4().replace(/-/g, "");

	if (options.variable === undefined) {
		options.variable = `__test_${token}`;
	}

	const compareExpressions: CompareExpressions = {
		lhsTemplate: "",
		rhsTemplate: ""
	}

	if (options.lhs !== undefined) {
		compareExpressions.lhsTemplate = `{%- set ${options.variable} = ${computeExpressionFactory(options.lhs)} -%}`;
	}

	const caseTypes = new RegExp('^(<=|>=|==|!=|~=|<|>)(.*$)');
	const rhsMatched = options.rhs.match(caseTypes);
	let op = '==';
	if (rhsMatched) {
		op = rhsMatched[1];
		options.rhs = rhsMatched[2]
	}

	options.rhs = options.rhs?.replace(/\.\s/gm, ".");
	if (options.variables && options.variables[options.rhs.trim()]) {
		options.rhs = options.variables[options.rhs.trim()]?.toString() ?? options.rhs;
	}
	/*
	 * If quoting is enabled by default and an operation is being performed
	 * where quoting makes sense, quote the RHS to treat it as a string.
	 */
	if (options.rhsQuoteDefault) {
		switch (op) {
			case '==':
			case '!=':
				/* XXX:TODO: This doesn't deal with RHS that have quotes in them already */
				options.rhs = `"${options.rhs}"`;
				break;
			default:
				/* Nothing to do by default */
				break;
		}
	}

	const rhsTemplateParts: string[] = [];
	switch (op) {
		case '~=':
			rhsTemplateParts.push(`{%- ${options.ifStatement} (r/(${options.rhs})/).test(${options.variable}) -%}`);
			break;
		default:
			rhsTemplateParts.push(`{%- ${options.ifStatement} ${options.variable} ${op} ${options.rhs} -%}`);
			break;
	}

	if (options.hasElseClause) {
		rhsTemplateParts.push(options.true!)
		rhsTemplateParts.push('{%- else -%}');
		rhsTemplateParts.push(options.false!)
		rhsTemplateParts.push('{%- endif -%}');
	}

	compareExpressions.rhsTemplate = rhsTemplateParts.join('');

	return compareExpressions;
}