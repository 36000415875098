import {DocumentCollection} from "../../../models/documentCollection";
import {getVersionsForEntityType} from "../../../../../shared/hooks/api/useGetVersions";
import {isEmptyObject} from "../../../../../shared/utilities/isEmptyObject";
import {Variable, VariableTree} from "../../../../../shared/interfaces/Variable";
import {VariableAdapter} from "../../../../../shared/utilities/variableAdapter";
import {
	InTextTableVariableValue,
	isInTextTableVariable
} from "../../../../../shared/definitions/elements/inTextTable/model";
import {systemNamespaces} from "../../../../../shared/variables/SystemNamespaces";
import {LoadEntityFunc} from "../../../../../shared/interfaces/LoadEntityFuncType";

type RecursivelyLoadAllDocumentsProps = {
	documentInfo: {
		id: string,
		version?: string,
		versionIndex?: number,
		topLevel: boolean,
		latestTemplate?: boolean
	},
	loadTemplate: LoadEntityFunc<KaiAlphaTemplate>,
	loadDocument: LoadEntityFunc<KaiAlphaDocument>
}

export async function recursivelyLoadAllDocuments({documentInfo, loadTemplate, loadDocument}: RecursivelyLoadAllDocumentsProps): Promise<DocumentCollection> {
	const documents: DocumentCollection = [];
	const {id: documentId, version, topLevel, latestTemplate} = documentInfo;
	let document = await loadDocument({id: documentId, version});

	if (document === null) {
		return [];
	}

	const versions = await getVersionsForEntityType("document", document.id);
	// figure out which version number (index) we are loading
	if (documentInfo.versionIndex !== undefined && documentInfo.versionIndex > -1) {
		const versionToLoad = documentInfo.versionIndex >= versions.length ? versions[versions.length-1] : versions[documentInfo.versionIndex];
		if (versionToLoad.version !== document.version) {
			document = await loadDocument({id: documentId, version: versionToLoad.version});
			if (document === null) {
				return []
			}
		}
	} else if (documentInfo.versionIndex === undefined) {
		documentInfo.versionIndex = documentInfo.version === undefined ? versions.length-1 : versions.findIndex(v => v.version === documentInfo.version);
	}


	const subDocuments = document.subdocuments;

	let loadedTemplate: KaiAlphaTemplate | null;
	if(latestTemplate) {
		loadedTemplate = await loadTemplate({id: document.template!.id});
	} else {
		loadedTemplate = await loadTemplate({id: document.template!.id, version: document.template!.version});
	}

	if (loadedTemplate === null) {
		return [];
	}

	const template = loadedTemplate as KaiAlphaTemplate;
	const variables = isEmptyObject(template.variables) ? {} : Object.keys(template.variables!).reduce((obj, current) => {
		obj[topLevel ? current : `${(template.name ?? "").replace(/\W/gmi, "")}.${current}`] = {
			name: current,
			namespace: topLevel ? undefined : `${(template.name ?? "").replace(/\W/gmi, "")}.${current}`,
			type: template.variables![current].type,
			options: template.variables![current].options,
			value: undefined,
			description: template.variables![current].description ?? "",
			required: template.variables![current].required ?? false,
			templateId: template.id
		} as Variable;
		return obj
	}, {});

	if (!isEmptyObject(document.variables)) {
		const flattenedVariables = VariableAdapter.flattenTree(document.variables as VariableTree);
		Object.keys(flattenedVariables).forEach(key => {
			const variableKey = topLevel ? key : `${(template.name ?? "").replace(/\W/gmi, "")}.${key}`;
			const keyWithoutGlobalNamespace = variableKey.replace("global.", "");
			const documentVariable = flattenedVariables[key];
			if (variables[keyWithoutGlobalNamespace] === undefined && isInTextTableVariable(documentVariable)) {
				const intextTableVariable = documentVariable as InTextTableVariableValue;
				// make sure study id matches what is in metadata object
				if (intextTableVariable.studyId === document!.metadata?.user?.StudyId) {
					variables[key] = {
						type: "intexttable",
						templateId: template.id,
						name: keyWithoutGlobalNamespace,
						namespace: keyWithoutGlobalNamespace
					}
				}
			} else if (variables[variableKey] === undefined && systemNamespaces.filter(systemNamespace => variableKey.includes(systemNamespace)).length > 0) {
				const nameParts = key.split(".");
				variables[variableKey] = {
					type: "system",
					templateId: template.id,
					name: nameParts[nameParts.length - 1],
					namespace: key
				}
			}

			if(variables[variableKey] !== undefined && documentVariable !== null) {
				if (variables[variableKey].type === "datasource") {
					variables[variableKey].dataSources = documentVariable["data"] === undefined ? Object.keys(documentVariable).map(k => documentVariable[k]) : [documentVariable];
				} else {
					variables[variableKey].value = documentVariable
				}
			}
		});
	}
	documents.push({
		variables,
		versions,
		template,
		subDocuments: document.subdocuments,
		lastupdated: document.lastupdated,
		id: document.id,
		version: document.version,
		topLevel
	});

	if (!isEmptyObject(subDocuments)) {
		const subDocumentCollection = (await Promise.all(Object.keys(subDocuments!)
			.map(key => subDocuments![key])
			.map(subDocument => subDocument.document_id)
			.reduce((ids, current) => ids.concat(current), [])
			.map(id => recursivelyLoadAllDocuments({documentInfo: {id, topLevel: false, versionIndex: documentInfo.versionIndex}, loadDocument, loadTemplate}))))
			.reduce((collection, current) => collection.concat(current), [])
			.map(subDocument => ({...subDocument, parentId: document!.id}));
		documents.push(...subDocumentCollection)
	}

	return documents;

}