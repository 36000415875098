import { styled } from "@mui/styles";
import {Theme} from "@mui/material";
import { Box } from "@mui/material/";

interface TabItemProps {
	active: boolean,
}

export interface FlexBoxProps {
	direction?: "row" | "column" | "row-reverse",
	align?: "center" | "flex-start" | "flex-end" | "baseline" | "stretch",
	justify?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly"
	width?: string,
}

export const FlexBox = styled(Box)(({ align, justify, direction, width }: FlexBoxProps) => ({
	display: "flex",
	width: width ?? "100%",
	flexDirection: direction ?? "row",
	alignItems: align ?? "flex-start",
	justifyContent: justify ?? "flex-start"
}));


const TabContainer = styled(FlexBox)(({theme}) => ({
	padding: "5px 1px",
	alignItems: 'center',
	justifyContent: "space-around",
	borderRadius: "100px",
	boxShadow: "rgba(50, 50, 93, 0.15) 2px 2px 2px 1px inset, rgba(0, 0, 0, .05) 0px 3px 5px 1px inset"
}));

const TabItem = styled(FlexBox)<Theme, TabItemProps>(({active, theme}) => ({
	boxSizing: "border-box",
	padding: "0",
	margin: "0 .3rem",
	alignItems: 'center',
	justifyContent: "center",
	borderRadius: "100px",
	cursor: 'pointer',
	boxShadow: active ? "0 0 10px rgba(50, 50, 93, 0.25)" : "",
	opacity: !active ? "50%" : "",
}));

const TabItemName = styled(FlexBox)(({theme}) => ({
	margin: "0.3rem",
	alignItems: 'center',
	width: "auto",
	boxSizing: "border-box",
	justifyContent: "center",
	borderRadius: "50%",
	fontWeight: 600,
	fontSize: "0.75rem",
}));

export {
	TabContainer,
	TabItem,
	TabItemName,
}