import {styled} from "@mui/material";
import {ButtonBase} from "./ButtonBase";
import {ButtonProps} from "./Button.model";
import {CenteredContainer} from "../../CenteredContainer";
import {Settings} from "@mui/icons-material";

const SettingsButtonContainer = styled(ButtonBase)(({theme}) => ({
	backgroundColor: theme.palette.tabs?.border,
	color: theme.palette.primary.main,
	"&:hover": {
		backgroundColor: theme.palette.primary?.contrastText,
		color: theme.palette.tabs?.border
	}
}));

const SettingsButton = ({onClick}: ButtonProps) => <SettingsButtonContainer onClick={onClick}>
	<CenteredContainer>
		<Settings style={{fontSize: '19px'}}/>
	</CenteredContainer>
</SettingsButtonContainer>

export {SettingsButton}