import {Dialog} from "../../../../../../lib/ui";
import {DialogProps} from "../../../../models/elements";
import {DropDown, DropDownItem} from "../../../../../../shared/components/input/dropdown";
import {useState} from "react";
import {DialogContent} from "@mui/material";
import { HtmlElementValue } from "../../../../../../shared/definitions/elements/html/model";

type MergeOptions = "start" | "end" | "table" | "none"
const mergeSettings: DropDownItem<MergeOptions>[] = [
	{ title: "None", value: "none" },
	{ title: "Merge with preceding block", value: "start" },
	{ title: "Merge with following block", value: "end" },
	{ title: "Show as single table", value: "table" }
]

const parseMergeOption = (merge: {start?: boolean | undefined, end?: boolean | undefined, table?: boolean | undefined} | undefined): MergeOptions =>
	merge?.start ? "start" : merge?.end ? "end" : merge?.table ? "table" : "none";

const convertMergeOptionToObject = (option: MergeOptions): Record<Exclude<MergeOptions, "none">, boolean> => ({
	end: option === "end",
	start: option === "start",
	table: option === "table"
})
const PropertyEditor = (props: DialogProps) => {
	const {merge} = props.value.data as HtmlElementValue;
	const [options, setOptions] = useState<{merge: MergeOptions}>({merge: parseMergeOption(merge)})

	const onHandleClose = () => props.onClose();
	const onHandleApply = () => {
		const payload = {...props.value, data: {...(props.value.data as HtmlElementValue), merge: convertMergeOptionToObject(options.merge)}};
		props.onApply(payload);
	}

	const onMergeOptionChange = (item: DropDownItem<MergeOptions>) => setOptions({merge: item.value})

	return (<Dialog
		open={true}
		title={'Content Settings'}
		onClose={onHandleClose}
		style={{ minWidth: "60%" }}
		buttons={{
			Close: onHandleClose,
			Apply: onHandleApply
		}}>
		<DialogContent>
			<DropDown items={mergeSettings} onSelect={onMergeOptionChange} value={options.merge} label={"Merge options"} sx={{width: "100%"}} />
		</DialogContent>
	</Dialog>)
}

export {PropertyEditor}