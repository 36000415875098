import {Box, styled} from "@mui/material";

const EmptyTableBody = styled(Box)({
	display: "flex",
	width: "100%",
	alignItems: "center",
	justifyContent: "center",
	height: "200px"
});

export {EmptyTableBody}