import {NunjucksFilterFactoryProps} from "../model";
import {ensureArgs} from "../filterUtilities";
import {isNunjucksEnvironmentOptions, NunjucksEnvironmentOptions} from "../../models/NunjucksEnvironmentOptions";
import {isString} from "../../../guards/isString";
import {NunjucksEventTypes} from "../../nunjucksEvents";

const escape_string_regexp = require('escape-string-regexp');
function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args, 4) || !isString(args[0]) || !isString(args[1]) || !isNunjucksEnvironmentOptions(args[2])) {
		return "";
	}

	const text = args[0] as string;
	const abbreviations = JSON.parse(decodeURI(args[1]))
	const options = args[2] as NunjucksEnvironmentOptions;
	// get default abbreviations
	if (abbreviations === undefined || abbreviations.length === 0) {
		return text
	}

	const {seen} = options.sharedContext.abbreviations
	const abbreviationText = abbreviations.reduce((html, {key, value}) => {
		const firstUse = seen[key] === undefined;
		const valueRegex = new RegExp(`\\b${escape_string_regexp(value)}\\b`, 'gi');
		html = html.replace(valueRegex, `${key}`);
		if (!firstUse) {
			return html;
		}

		const keyRegex = new RegExp(`\\b${key}\\b`);

		const newHtml = html.replace(keyRegex, `${value} (${key})`);
		if (newHtml === html) {
			return html;
		}

		seen[key] = value;
		return newHtml
	}, text);

	const updateEvent = new CustomEvent(NunjucksEventTypes.abbreviationUpdate, {detail: seen})
	window.dispatchEvent(updateEvent);
	return abbreviationText;
}

export function abbreviationMapFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "__abbreviations",
		help: "Map a list of abbreviations",
		example: "var | __abbreviations",
		execute: (...args: unknown[]) => execute(...args, props?.options, props?.dependencies)
	})
}