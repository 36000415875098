import React, {CSSProperties, useState} from "react";
import {useRunWhenValueChange} from "../../../hooks";

export type FixedPositionedBoxProps<T extends HTMLElement> = {
	attachToElement: React.RefObject<T>,
	children: React.ReactNode,
	style?: CSSProperties
}

const FixedPositionedBox = <T extends HTMLElement>({attachToElement, children, style}: FixedPositionedBoxProps<T>) => {
	const [menuDimensions, setMenuDimensions] = useState({width: 0, top: 0});
	useRunWhenValueChange(() => {
		setMenuDimensions({width: attachToElement.current!.clientWidth, top: attachToElement.current!.getBoundingClientRect().top + attachToElement.current!.offsetHeight});
	}, attachToElement.current?.clientWidth);

	const containerStyle = {
		position: "fixed",
		width: `${menuDimensions.width}px`,
		top: `${menuDimensions.top}px`,
		maxHeight: `calc(100vh - ${menuDimensions.top + 40}px)`,
		overflow: "hidden auto",
		zIndex: 1,
		...(style ?? {})
	};
	// @ts-ignore
	return <div style={containerStyle}>
		{children}
	</div>
}

export {FixedPositionedBox};