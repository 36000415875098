import { BaseElement } from "../../../interfaces/BaseElement";

const instructionalTextDefinition: BaseElement = {
	name: 'Template',
	type: 'comment',
	displayName: 'Instructional Text',
	description: 'Place Sub-Templates',
	allowsChildren: false,
	longDescription: 'Template elements may be used to include another template at the position where this element is placed',
	contentDescription:  () => "",
	toString: () => "comment"
} as const

export type InstructionalTextElementValue = {
	text?: string
}

export {instructionalTextDefinition};