import {ensureArgs} from "../filterUtilities";
import {isNunjucksDataSource, NunjucksDataSourceType} from "../../models/NunjucksDataSourceType";
import {isString} from "../../../guards/isString";
import {SortDirection} from "../../models/SortDirection";
import {isSortMode, SortMode} from "../../models/SortMode";
import {sortFunction} from "./sortFunction";
import {NunjucksFilterFactoryProps} from "../model";
const clone = require("rfdc/default");

function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args, 2) || !isNunjucksDataSource(args[0]) || args[0].data === undefined || !isString(args[1])) {
		return {
			type: 'columns',
			data: []
		} as NunjucksDataSourceType
	}

	const base = args[0] as NunjucksDataSourceType;
	const columnName = args[1] as string;

	const direction = args[2] !== undefined && isString(args[2]) ? args[2] : "asc";
	const mode = args[3] !== undefined && isSortMode(args[3]) ? args[3] : "human";

	return sortRows({base, columnName, direction, mode});
}

type SortRowsProps = {
	base: NunjucksDataSourceType,
	columnName: string,
	direction: string,
	mode: SortMode
}

const validDirectionStrings = ["asc", "ascending", "increasing", "desc", "descending", "decreasing"] as const;
const directionMap: Record<typeof validDirectionStrings[number], SortDirection> = {
	asc: "asc",
	ascending: "asc",
	increasing: "asc",
	decreasing: "desc",
	desc: "desc",
	descending: "desc"
}
function sortRows({base, columnName, direction, mode}: SortRowsProps): NunjucksDataSourceType {
	const data = clone(base.data);
	const sortDirection: SortDirection = directionMap[direction] ?? "asc";

	if (base.type !== "columns") {
		throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
	}

	return {type: base.type, data: data.sort((a, b) => sortFunction(a, b, columnName, {direction: sortDirection, mode}))};

}

export function sortRowsFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "sort_rows",
		help: "Filter that sorts the rows in increasing/acsending or decreasing/descending order",
		example: 'var | sort_rows("colName", "asc", "int")',
		execute
	})
}