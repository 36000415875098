import api from "../../../../api/api";
import {useCachedAsync} from "../../../../shared/hooks";

const cacheKey = "model_document_cache"

function useGetModelDocumentList() {

	const getModelDocumentList = async (): Promise<string[] | null> => {
		return api.call("GET", `template/modeldocuments`);
	}

	const asyncFunction = useCachedAsync(getModelDocumentList, {immediate: false, defaultExpiryTimeMinutes: 1});

	return {
		...asyncFunction,
		execute: asyncFunction.withCacheKey(cacheKey).execute,
	}
}

export {useGetModelDocumentList};