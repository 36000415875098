import {TextField} from "../../../../../../../shared/components/input";
import {ValueEditorProps} from "./model";
import {ChangeEvent} from "react";

const TextValueEditor = (props: ValueEditorProps) => {
	const value = props.data.value as string;
	const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => props.onChange(e.target.value);
	return <TextField label={"Value"} value={value} onChange={onChange} />
}

export {TextValueEditor}