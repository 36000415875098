/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/lib/cache_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import object_utils from './object_utils';
// @ts-check-strict

const promise_object_id = 'promise_73059e2a-7427-4525-9ade-ec7f4d07ffb8';
const error_marker_id = 'error_84f83708-13b0-44d2-992b-86454f514b9a';
const _testing = undefined;

const cache_store = {};

let debug_printout;

let useStorageProvider = false;
let storageProvider;
(async function() {
	if (!useStorageProvider) {
		return;
	}
	if (window.caches && window.caches.open) {
		const cacheHandle = await window.caches.open('kaialpha-cache_utils');
		const urlKeyPrefix = '/_internal_cache/?uuid=5f259e1f-f7ea-415c-aad6-35bc23d238bc&key=';

		const urlFromKey = function(key) {
			return(`${urlKeyPrefix}${key}`);
		};

		const keyFromURL = function(url) {
			const offset = url.indexOf(urlKeyPrefix);
			if (offset < 0) {
				return(null);
			}
			const length = urlKeyPrefix.length;
			return(decodeURIComponent(url.slice(offset + length)));
		}

		storageProvider = {
			getItem: async function(key) {
				const url = urlFromKey(key);
				const response = await cacheHandle.match(url)

				if (!response) {
					return(null);
				}

				if (!response.ok) {
					return(null);
				}

				return(await response.text());
			},
			setItem: async function(key, value) {
				const url = urlFromKey(key);
				await cacheHandle.put(url, new Response(value));
			},
			removeItem: async function(key) {
				const url = urlFromKey(key);
				await cacheHandle.delete(url);
			},
			listItems: async function() {
				const keys = [];
				for (const request of await cacheHandle.keys()) {
					const url = request.url;
					const key = keyFromURL(url);
					if (key === null) {
						continue;
					}
					keys.push(key);
				}

				return(keys);
			}
		};

		useStorageProvider = true;
	}
})();

/**
 * Print known caches and sizing information
 *
 * @param {Object} [options] - Options
 * @param {function} [options.log] - Function to call to log things
 * @returns {void} Nothing
 */
function _cache_log_stats(options = {}) {
	options = {
		log: kaialpha.log.debug,
		...options
	};

	if (!options.log) {
		return;
	}

	options.log('[cache_utils] Known caches:');
	for (const debug_cache_object_name in cache_store) {
		const debug_cache_object = cache_store[debug_cache_object_name];
		let debug_cache_pending_promises = 0;
		if (debug_cache_object[promise_object_id] instanceof Object) {
			debug_cache_pending_promises = Object.keys(debug_cache_object[promise_object_id]).length;
		}

		options.log(`[cache_utils]     ${debug_cache_object_name}: ${String(JSON.stringify(debug_cache_object)).length} bytes, ${debug_cache_pending_promises} pending promises`)
	}
}

/**
 * Check to see if we are dealing with a cached error and if so, throw it
 *
 * @params {any} value
 * @returns {void} Nothing
 */
function _check_value_for_error_and_throw(value) {
	if (value && value instanceof Object && value[error_marker_id]) {
		const exception = value[error_marker_id];
		let resolved_error;

		switch (exception.type) {
			case 'error':
				if (exception.reference instanceof Error) {
					resolved_error = exception.reference;
				} else {
					resolved_error = new Error(exception.message);
				}
				break;
			case 'string':
				resolved_error = exception.reference;
				break;
			default:
				throw(new Error(`internal error: unsupported type: ${exception.type}`));
		}

		throw(resolved_error);
	}
}

/**
 * Convert an exception into a sentinel value that "_check_value_for_error_and_throw"
 * can understand and rethrow
 *
 * @param {any} exception - The exception value to encode
 * @returns {Object} The encoded object
 */
function _convert_error_to_cache_value(exception) {
	const error_info = {
		reference: exception
	};

	if (exception instanceof Error) {
		error_info.type = 'error';
		error_info.message = exception.message;
	} else if (typeof exception === 'string' || exception instanceof String) {
		error_info.type = 'string';
	} else {
		error_info.type = 'unknown';
	}

	return({
		[error_marker_id]: error_info
	});
}

const serialization_expiration_field_length = 30;
function deserialize_value(value) {
	if (value === null || value === undefined) {
		return({
			expiration: Infinity,
			value: value
		});
	}

	const expiration = value.slice(0, serialization_expiration_field_length);
	const rest = value.slice(serialization_expiration_field_length);

	return({
		expiration: Number(expiration),
		value: rest
	});
}

function serialize_value(expiration, value) {
	const padded_expiration = (' '.repeat(serialization_expiration_field_length) + String(expiration)).slice(-1 * serialization_expiration_field_length);
	const result = padded_expiration + value;
	return(result);
}

/**
 * Return an object from cache, failing that resolve it asynchronously
 * once and then store and return that value.
 *
 * @param {string} cache_name - Namespace for cache variable
 * @param {string | null | undefined} cache_id - ID for the cache variable (null to disable caching)
 * @param {function} promise_lambda - Promise whose resolved value to store in cache_name:cache_id
 * @param {Object} [options] - Options
 * @param {number} [options.cache_expires] - Expiration for this entry
 * @param {boolean} [options.cache_errors] - Cache errors ?
 * @returns {Promise<any>} The return value from "promise_lambda"
 */
async function cache_promise(cache_name, cache_id, promise_lambda, options = {}) {
	/*
	 * Default options
	 */
	options = {
		cache_expires: -1,
		cache_errors: false,
		...options
	};

	let now = Date.now();

	/*
	 * If the "cache_id" is null, do not cache, just return the value
	 */
	if (cache_id === null || cache_id === undefined) {
		const retval = await promise_lambda();
		return(retval);
	}

	/*
	 * In debug mode, periodically print out all known caches
	 */
	/* istanbul ignore if */
	if (kaialpha && kaialpha.debug) {
		if (!debug_printout) {
			debug_printout = setInterval(_cache_log_stats, 30000);
		}
	}

	/*
	 * Locate cache storage for this cache area
	 */
	if (cache_store[cache_name] === undefined) {
		cache_store[cache_name] = {};
	}

	const cache_object = cache_store[cache_name];

	/*
	 * Check storage provider (if enabled) for cache first
	 */
	let lsk_cache_id;
	if (useStorageProvider) {
		lsk_cache_id = [cache_name, cache_id].join('|');
	}

	if (useStorageProvider) {
		const check_cached_value_raw = await storageProvider.getItem(lsk_cache_id);
		if (check_cached_value_raw !== null) {
			/*
			 * Check to see if this entry is expired
			 */
			const { expiration, value: check_cached_value } = deserialize_value(check_cached_value_raw);

			if (expiration >= now) {
				/* XXX:TODO: Re-cache with a longer value */
				const check_resolved_value = JSON.parse(check_cached_value);
				_check_value_for_error_and_throw(check_resolved_value);
				return(check_resolved_value);
			} else {
				storageProvider.removeItem(lsk_cache_id);
			}
		}
	}

	/*
	 * If the value is already cached, return a copy
	 */
	if (cache_object[cache_id]) {
		const check_resolved_value_raw = cache_object[cache_id];
		const {
			expiration,
			value: check_resolved_value
		} = check_resolved_value_raw;

		if (expiration >= now) {
			/* XXX:TODO: Re-cache with a longer value */
			_check_value_for_error_and_throw(check_resolved_value);
			return(object_utils.copy_object(check_resolved_value));
		} else {
			delete cache_object[cache_id];
		}
	}

	/*
	 * Use a subobject to keep promises in, so they are managed
	 * together with caches.
	 */
	if (!cache_object[promise_object_id]) {
		cache_object[promise_object_id] = {};
	}

	const promises = cache_object[promise_object_id];

	/*
	 * Check for a cached promise, if one is not found, create
	 * one.
	 */
	if (!promises[cache_id]) {
		promises[cache_id] = promise_lambda();
	}

	/*
	 * Wait for the cached promise to resolve
	 */
	let resolved_value;
	try {
		resolved_value = await promises[cache_id];
	} catch (resolved_error) {
		if (options.cache_errors) {
			resolved_value = _convert_error_to_cache_value(resolved_error);
		} else {
			throw(resolved_error);
		}
	} finally {
		delete promises[cache_id];
	}

	/*
	 * Update local copy of the clock
	 */
	now = Date.now();
	let item_expiration = Infinity;
	if (options.cache_expires > 0) {
		item_expiration = now + options.cache_expires;
	}

	/*
	 * Store the value in the appropriate cache
	 */
	if (!lsk_cache_id) {
		cache_object[cache_id] = {
			expiration: item_expiration,
			value: resolved_value
		};
	} else {
		try {
			await storageProvider.setItem(lsk_cache_id, serialize_value(item_expiration, JSON.stringify(resolved_value)));
		} catch (lsk_error) {
			kaialpha.lib.debug.log('cache_utils', 'Failed to save item to storage provider, will fallback to in-memory storage:', lsk_error)
			cache_object[cache_id] = {
				expiration: item_expiration,
				value: resolved_value
			};
		}
	}

	_check_value_for_error_and_throw(resolved_value);

	/*
	 * Return a copy of the object
	 */
	return(object_utils.copy_object(resolved_value));
}

/**
 * Return an object from cache, failing that resolve it asynchronously
 * once and then store and return that value.
 *
 * @param {string} [cache_name] - Namespace for cache variable
 * @param {string} [cache_id] - ID for the cache variable
 * @returns {Promise<void>} Nothing
 */
async function clear(cache_name = undefined, cache_id = undefined) {
	if (cache_name === undefined && cache_id !== undefined) {
		throw(new Error('Invalid combination of arguments, if cache_id is specified cache_name must also be specified'));
	}

	/* istanbul ignore if */
	if (kaialpha && kaialpha.debug) {
		kaialpha.log.debug(`[cache_utils] Clearing ${cache_name} (key = ${cache_id})`);
	}

	if (useStorageProvider) {
		const lsk_to_remove = [];
		if (cache_id !== undefined) {
			lsk_to_remove.push([cache_name, cache_id].join('|'));
		} else {
			for (const check_lsk_cache_id of await storageProvider.listItems()) {
				const check_lsk_cache_name = check_lsk_cache_id.split('|')[0];

				/*
				 * Only discriminate based on the subindex
				 * (cache_name) if one was specified
				 */
				if (cache_name !== undefined) {
					if (check_lsk_cache_name !== cache_name) {
						continue;
					}
				}

				lsk_to_remove.push(check_lsk_cache_id);
			}
		}

		const lsk_remove_promises = [];
		for (const lsk_cache_id of lsk_to_remove) {
			lsk_remove_promises.push(storageProvider.removeItem(lsk_cache_id));
		}
		await Promise.allSettled(lsk_remove_promises);
	}

	/*
	 * If asked to clear all caches, do that at the top-level
	 */
	if (cache_name === undefined) {
		for (const clean_key in cache_store) {
			delete cache_store[clean_key];
		}

		return;
	}

	const cache_object = cache_store[cache_name];
	if (!cache_object) {
		return;
	}

	if (cache_id !== undefined) {
		delete cache_object[cache_id];

		return;
	}

	for (const clean_key in cache_object) {
		delete cache_object[clean_key];
	}
}

if (_testing) {
	_testing.cache_clear_all = async function() {
		const from_cache_1_before = await cache_promise('clear_local_cache_1', 'test', async function() {
			return(42);
		});

		const from_cache_2_before = await cache_promise('clear_local_cache_2', 'test', async function() {
			return(43);
		});

		await clear();

		const from_cache_1_after = await cache_promise('clear_local_cache_1', 'test', async function() {
			return(44);
		});

		const from_cache_2_after = await cache_promise('clear_local_cache_2', 'test', async function() {
			return(45);
		});

		/* istanbul ignore if */
		if (from_cache_1_before !== 42 || from_cache_2_before !== 43) {
			throw(new Error(`Invalid base cache behavior: Before: ${from_cache_1_before}/${from_cache_2_before}`));
		}

		/* istanbul ignore if */
		if (from_cache_1_after !== 44 || from_cache_2_after !== 45) {
			throw(new Error(`Invalid cache value after clearing: Before: ${from_cache_1_before}/${from_cache_2_before} - After ${from_cache_1_after}/${from_cache_2_after}`));
		}

		return(true);
	}

	_testing.cache_promise_and_clear = async function() {
		const from_cache_1 = await cache_promise('local_cache_1', 'test', async function() {
			return(42);
		});

		const from_cache_2 = await cache_promise('local_cache_1', 'test', async function () {
			/* istanbul ignore next */
			/* As cache id is same for both the promise functions previous function is been used */
			return(43);
		});

		/* istanbul ignore if */
		if (from_cache_1 !== from_cache_2) {
			throw(new Error(`Cached lookup failed !  Value 1 = ${from_cache_1}, Value 2 = ${from_cache_2}`));
		}

		await clear('local_cache_1');

		const from_cache_3 = await cache_promise('local_cache_1', 'test', async function() {
			return(44);
		});

		/* istanbul ignore if */
		if (from_cache_3 !== 44) {
			throw(new Error(`Cache invalidation lookup failed !`));
		}

		return(true);
	}

	_testing.cache_promise_and_expire = async function() {
		const from_cache_1 = await cache_promise('local_cache_2', 'test', async function() {
			return(42);
		}, {
			cache_expires: 1
		});

		await kaialpha.lib.general_utils.async_sleep(10);

		const from_cache_2 = await cache_promise('local_cache_2', 'test', async function() {
			return(43);
		}, {
			cache_expires: 1
		});

		/* istanbul ignore if */
		if (from_cache_1 === from_cache_2) {
			throw(new Error(`Cache expiration lookup failed !  Value 1 = ${from_cache_1}, Value 2 = ${from_cache_2}`));
		}

		return(true);
	};

	_testing.cache_promise_with_cache_id_null = async function () {
		const from_cache_1 = await cache_promise('local_cache_3', null, async function () {
			return(42);
		});

		/* istanbul ignore if */
		if (from_cache_1 !== 42) {
			throw(new Error(`Cache without cache id failed ! As output expected is 42 but found Value 2 = ${from_cache_1}`));
		}
		return true;
	}

	_testing.cache_promise_with_error = async function() {
		for (const cache_errors of [true, false]) {
			let from_cache_error_1;
			try {
				await cache_promise('local_cache_4', `test_${cache_errors}`, async function () {
					throw(new Error('test'));
				}, {
					cache_errors: cache_errors
				});
			} catch (_error) {
				from_cache_error_1 = _error;
			}

			/* istanbul ignore if */
			if (from_cache_error_1 === undefined) {
				throw(new Error(`Expected to get an error from first lambda, but got: ${from_cache_error_1}`));
			}

			let from_cache_error_2;
			let from_cache_value_2;
			try {
				from_cache_value_2 = await cache_promise('local_cache_4', `test_${cache_errors}`, async function () {
					return('ok');
				}, {
					cache_errors: cache_errors
				});
			} catch (_error) {
				from_cache_error_2 = _error;
			}

			if (cache_errors) {
				/* istanbul ignore if */
				if (from_cache_error_2 === undefined) {
					throw(new Error(`Expected to get an error from second lambda, but got: ${from_cache_error_2}`));
				}

				/* istanbul ignore if */
				if (from_cache_error_1.toString() !== from_cache_error_2.toString()) {
					throw(new Error(`Cache with error failed ! Error 1: ${from_cache_error_1}; Error 2: ${from_cache_error_2}`));
				}
			} else {
				/* istanbul ignore if */
				if (from_cache_error_2 !== undefined) {
					throw(new Error(`Expected to get a value from second lambda, but got an error: ${from_cache_error_2} (value: ${from_cache_value_2})`));
				}

				/* istanbul ignore if */
				if (from_cache_value_2 !== 'ok') {
					throw(new Error(`Expected to get a value from second lambda, but got an error: ${from_cache_error_2} (value: ${from_cache_value_2})`));
				}
			}
		}

		return(true);
	}
}

const _to_export_auto = {
	cache_promise,
	clear,
	_internals: {
		get storageProvider() { return(storageProvider); }
	},
	_testing
}
export default _to_export_auto;
