import {useTypedAsync} from '../../../shared/hooks';
import {useTemplateStore} from "./useTemplateStore";
import {TypeAsyncReturn} from "../../../shared/hooks/useAsync";


const useTemplateDetail = (): TypeAsyncReturn<string, KaiAlphaTemplate> => {
	const templateStore = useTemplateStore();
	const getTemplateById = (id: string) => templateStore.get({id});


	return useTypedAsync(getTemplateById, false);
}

export {useTemplateDetail};