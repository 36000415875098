import {alpha, createTheme} from "@mui/material/styles";
import {colors} from "./colors";

/*
 * Set an application theme
 */
const theme = createTheme({
	palette: {
		action: {
			hoverOpacity: .8
		},
		primary: {
			main: colors.trueBlue,
			contrastText: colors.white,
		},
		secondary: {
			main: colors.bitterSweet,
			contrastText: colors.richBlack,
		},
		plain: {
			main: colors.white,
			contrastText: colors.mineShaft,
			border: colors.dimGrey,
			hover: colors.highlightGrey
		},
		background: {
			default: colors.cultured, // root
			level1: colors.white, // main component
			level2: colors.highlightGrey, // selected element in main component
			level1_contrast: colors.whiteLilac, // ex: show stripped rows on table
			level3: colors.maximumGreenYellow, //selected text in comment card
			level4: colors.lavender, // reply section in comment card
			level5: colors.ultraLightBlue, // active background color
			level6: colors.mintFaded, // resolved comment background color
			level7: colors.lightBlue, //Hover over state
			level8: colors.lightYellow, //highlighted comment card
		},
		text: {
			primary: colors.almostBlack,
			secondary: colors.darkGrey,
			muted: colors.fadedGrey,
		},
		info: {
			main: colors.white,
			contrastText: colors.almostBlack,
		},
		warning: {
			main: colors.lemonMeringue,
			contrastText: colors.richBlack,
		},
		error: {
			main: colors.softRed,
			contrastText: colors.richBlack,
		},
		success: {
			main: colors.maximumGreenYellow,
			contrastText: colors.richBlack,
		},
		// custom config
		title: {
			main: colors.white,
			contrastText: colors.trueBlue,
		},
		mainMenu: {
			main: colors.trueBlue,
			contrastText: colors.white,
			hover: colors.richBlack,
		},
		secondaryMenu: {
			main: colors.trueBlue,
			contrastText: colors.white,
			hover: colors.richBlack,
		},
		destructive: {
			main: colors.softRed,
			contrastText: colors.richBlack,
		},
		cancelButton: {
			main: colors.greenishGrey,
			contrastText: colors.richBlack,
			hover: colors.white,
		},
		tabs: {
			contrastText: colors.tabGrey,
			main: colors.tabDarkGrey,
			hover: colors.subtextGrey,
			border: colors.lilacUnderscore,
			static: colors.ghostWhite,
		},
		scrollbar: {
			main: colors.dimGrey,
			track: colors.cultured,
			hover: colors.rainyGrey,
		},
		fancyTable_pagination: {
			main: colors.lavender,
		},
		ui_closeicon: {
			main: colors.white,
			contrastText: alpha(colors.richBlack, 0.60)
		},
		documentReview: {
			inactiveSection: {
				contrastText: alpha(colors.richBlack, 0.60),
			},
			activeSection: {
				main: colors.floralWhite,
				border: colors.mistyRose,
			},
			activeTitle: {
				main: colors.lightRed,
			},
		},
		documentState: {
			'inProcess': {
				main: colors.goldenSprinkles,
				light: colors.whiteGold,
				contrastText: colors.richBlack
			},
			'inReview': {
				main: colors.platonicBlue,
				light: colors.blueWhite,
				contrastText: colors.richBlack
			},
			'approved': {
				main: colors.auroraGreen,
				light: colors.whiteGreen,
				contrastText: colors.richBlack,
			},
			default: {
				main: colors.dimGrey,
				contrastText: colors.richBlack
			}
		},
		checkbox: {
			main: colors.green,
			contrastText: colors.green,
		},
		disabled: {
			main: colors.white,
			contrastText: colors.lightGrey,
			border: colors.cultured,
		},
		fileButton: {
			main: colors.pastelGrey,
			contrastText: colors.charcoal,
			border: colors.charcoal,
		},
		table: {
			main: colors.richBlack,
			contrastText: colors.paynesGrey,
			border: alpha(colors.lavender, 0.3),
		},
		variable: {
			main: colors.fadedBlack,
			background: alpha(colors.lavender, 0.5),
		},
		paperDialog: {
			main: colors.paperWhite
		},
		tagsChip: {
			main: colors.lavender,
			selectedChipBackground: colors.darkBlue,
			selectedChipColor: colors.white
		},
		variableHighlight: {
			main: colors.otherDarkBlue,
			static: colors.coolGrey,
			datasource: colors.black
		},
		comment: {
			resolved: {
				date: colors.mint,
				border: colors.mintDark,
			}
		},
		dashboard: {
			hover: colors.orangeHighlight,
			border: alpha(colors.darkGrey, 0.2),
			contrastText: alpha(colors.black, 0.5),
			main: alpha(colors.darkGrey, 0.5),
			static: alpha(colors.trueBlue, 0.06),
		},
		statusNotifications: {
			success: colors.auroraGreen,
			info: colors.platonicBlue,
			warning: colors.orangeYellowCrayola,
			error: colors.softRed
		}
	},
	typography: {
		fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1200,
			lg: 1536,
			xl: 1920,
		},
	},
});

export {colors, theme};
