import {EditableElementProps, ElementPropertyData} from "../../model";
import {PropertyList} from "../../PropertyList";
import React, {useState} from "react";
import {TextField} from "../../../../../../shared/components/input";
import { ReferenceElementValue } from "../../../../../../shared/definitions/elements/reference/model";
import {useDebounce, useRunWhenValueChange} from "../../../../../../shared/hooks";
import {DebounceDelay} from "../../utilities/editSettings";
import {displayNameForElementType} from "../../../../../../shared/definitions/elements";

const EditableView = (props: EditableElementProps) => {
	const data = props.data as ReferenceElementValue;
	const properties = [{label: "Referenced Element Type:", value: data.value?.type ? displayNameForElementType(data.value?.type!) : ""}];
	const [name, setName] = useState(data.name);
	const debouncedName = useDebounce(name, DebounceDelay);

	useRunWhenValueChange(() => {
		props.onChange({
			...(props as ElementPropertyData),
			data: {...data, name: debouncedName}
		})
	}, debouncedName);

	const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setName(event.target.value?.toLowerCase());
	}

	return <PropertyList items={properties}>
		<TextField placeholder={"Enter title here"} value={name} onChange={onChange} />
	</PropertyList>
}

export {EditableView};