import {SearchBar} from "../../input/SearchBar";
import {FlexBox} from "../../layout";
import React from "react";
import {colors} from "../../../../theme";

type ToolbarProps = {
	children?: React.ReactNode,
	onSearchChange?: (searchText: string) => void,
	searchText?: string,
	searchTextUpdateDelay?: number
}
const Toolbar = (props: ToolbarProps) => {
	return <FlexBox align={"center"} sx={{background: colors.otherDarkBlue, color: "white", padding: "0 1rem", borderRadius: ".5rem", boxSizing: "border-box", minHeight: "45px"}}>
		{props.onSearchChange && <SearchBar delay={props.searchTextUpdateDelay} value={props.searchText} sx={{flex:1, color: "white"}} iconStyle={{color: "white"}} onChange={props.onSearchChange} />}
		<FlexBox align={"center"} justify={"flex-end"} width={props.onSearchChange ? "auto" : "100%"}>
			{props.children}
		</FlexBox>
	</FlexBox>
}

export {Toolbar}