import {fontStyleFactory} from "./fontStyleFactory";
import {StylePropertyFactory} from "./model";
import {StyleAttribute} from "../../../editor/elements/styles";
import {spacingStyleFactory} from "./spacingStyleFactory";
import {borderStyleFactory} from "./borderStyleFactory";
import {tableStyleFactory} from "./tableStyleFactory";

const stylePropertyFactories: StylePropertyFactory[] = [fontStyleFactory, spacingStyleFactory, borderStyleFactory, tableStyleFactory];
function createStyle(styleDefinition: Partial<Record<StyleAttribute, string>>, parentCssSelector?: string): string[] {
	return stylePropertyFactories.map(factory => factory(styleDefinition, parentCssSelector)).filter(css => css.length > 0);
}

export {createStyle};