import {fetchSource} from "../../hooks/api/data/fetchSource";
import {TryAsync} from "../../interfaces/Either";
import {DataSourceType} from "../../interfaces/DataSource";
import {isNunjucksDataSource, NunjucksDataSourceType} from "../../nunjucks/models/NunjucksDataSourceType";
import {CsvOptions} from "./parseCsv";

function blobImageToBase64(blob?: Blob | null): Promise<string | ArrayBuffer | null> {
	if (blob) {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		return new Promise(function (resolve, reject) {
			reader.onloadend = function () {
				resolve(reader.result);
			};

			reader.onerror = function (error) {
				reject(error);
			}
		});
	}

	return Promise.reject("Blob is null");
}

async function fetchImageMetaDataCsv(source: string, options: CsvOptions): Promise<NunjucksDataSourceType | undefined> {
	const imageMetaDataSource = `${source}.csv`;
	try {
		const data = await fetchSource({url: imageMetaDataSource, options});
		if (data && isNunjucksDataSource(data)) {
			return data;
		}
		return undefined;
	} catch (err) {
		throw new Error(`Image ${source} with the metadata source value ${imageMetaDataSource} is not found`);
	}
}

export type ImageMetaData = {
	name: string,
	image: string | ArrayBuffer | null,
	type: DataSourceType,
	metadata?: NunjucksDataSourceType
}

type GetMetaDataForImageOptions = {
	short_name: string
}

function isGetMetaDataForImageOptions(obj?: unknown): obj is GetMetaDataForImageOptions {
	return obj !== undefined && (obj as GetMetaDataForImageOptions).short_name !== undefined;
}
export async function getMetaDataForImage(data: Blob, source: string, options?: unknown) {
	const imageOptions: GetMetaDataForImageOptions = !isGetMetaDataForImageOptions(options) ? {short_name: ""} : options;
	const imageToBase64Result = await TryAsync(() => blobImageToBase64(data))
	const imageObject: ImageMetaData = {
		name: imageOptions.short_name,
		image: imageToBase64Result.matchWith({left: _ => null, right: v => v as string}),
		type: "image",
	};

	const metadata = await fetchImageMetaDataCsv(source, {type: "auto"});
	if (metadata && metadata.data) {
		imageObject.metadata = metadata;
	}
	return imageObject;
}