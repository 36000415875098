import {TemplateState} from "./index";
import {VariableElement} from "../models/elements";
import {useState} from "react";
import {useRunWhenValueChange} from "../../../shared/hooks";
//import {useMemo} from "react";

interface ValidateNamesResult {
	hasDuplicate: boolean,
	allVariables: string[]
}

// simple search
const search = (searchValue: string, elements: VariableElement[]): VariableElement[] => {
	if (searchValue === "") {
		return elements;
	}

	const substring = searchValue.toLowerCase().trim();
	return elements.filter(element => element.contents.name.toLowerCase().includes(substring))
}

function useVariables() {
	const [allLocalVariables, setAllLocalVariables] = useState<{[key: string]: any} | null>(null);

	const templateState = TemplateState.useContainer();

	useRunWhenValueChange(() => {
		setAllLocalVariables(templateState.elements?.filter(variable => variable.type === "variable").map(variable => variable as VariableElement).filter(v => v.template === undefined).reduce((allVariables, currentVariable) => {
			allVariables[currentVariable.contents.name] = currentVariable;
			return allVariables;
		}, {}) ?? null);
	}, templateState.elements);

	const namespacedVariableNames = () => templateState.variables.filter(variable => (variable.contents.name?.length ?? 0) > 0).map(variable => `${(variable.template ? variable.template + "." : "")}${variable.contents.name}`);
	const validateNames = ({excludeName, nameToValidate}: {excludeName?: string, nameToValidate: string}): ValidateNamesResult => {
		const result: ValidateNamesResult = {
			hasDuplicate: false,
			allVariables: []
		};

		if (!templateState.variables){
			return result;
		}

		let allVariableNames = namespacedVariableNames();

		if (excludeName) { //If existing variable is being edited
			allVariableNames = allVariableNames.filter((variable) => variable.toLowerCase() !== excludeName);
		}

		result.allVariables = allVariableNames;
		const variableCountMap =  new Map<string, number>();

		if (nameToValidate){ //If inserting/updating a variable name
			variableCountMap.set(nameToValidate.toLowerCase(), 1)
		}
		for (let i = 0; i< allVariableNames.length; i++){
			const variableName = allVariableNames[i].toLowerCase().trim();
			if (variableCountMap.has(variableName)){
				variableCountMap.set(variableName, variableCountMap.get(variableName)!+1)
			} else {
				variableCountMap.set(variableName, 1);
			}
		}

		const dupliateNames: string[] = []
		variableCountMap.forEach((val, key) => {
			if (key === nameToValidate && val > 1) {
				dupliateNames.push(key)
			}
		});

		result.hasDuplicate = dupliateNames.length > 0;

		return result;
	}

	const getCsvData = (filterString: string, templateName?: string) => {
		const headers = [
			{label: "Name", key: "name"},
			{label: "Type", key: "type"},
			{label: "Description", key: "description"}
		];

		const data = filterVariables(filterString)
			.map(variable => ({
				name: `${variable.template ? variable.template : templateName ?? "This template"}.${variable.contents.name}`,
				type: variable.contents.type,
				description: variable.contents.description ?? ""
			}));

		return {headers, data}
	}
	const filterVariables = (searchValue: string) => search(searchValue, templateState.variables);

	return {validateNames, namespacedVariableNames, filterVariables, getCsvData, allLocalVariables, allVariables: templateState.variables}
}

export {useVariables};