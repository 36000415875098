/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/client/folder.mjs
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../lib/kaialpha';
import api from './api';
function _get_resource(folder_id) {
	let resource;
	if (folder_id === undefined || folder_id === '') {
		resource = `folder`
	} else {
		resource = `folder/${folder_id}`
	}

	return(resource);
}

export async function get_user_folder(user_id, folder_id = undefined) {
	const resource = _get_resource(folder_id);

	const retval = await api.call('GET', resource);

	delete retval['child_resources'];

	return(retval);
}

export async function apply_diff_user_folder(user_id, folder_id, version_id_head, summary, diff) {
	if (diff === undefined) {
		diff = {};
	}

	const payload = {
		id: folder_id,
		version: version_id_head,
		summary: summary,
		change: diff.change,
		delete: diff.delete
	};

	const retval = await api.call('PATCH', `folder/${folder_id}`, payload);

	return(retval);
}

export async function delete_user_folder(user_id, folder_id = undefined) {
	const resource = _get_resource(folder_id);

	const retval = await api.call('DELETE', resource);

	return(retval);
}

export async function new_user_folder(user_id, parent_folder_id, new_folder) {
	const resource = _get_resource(parent_folder_id);

	const retval = await api.call('POST', resource, new_folder);

	return(retval);
}

export async function new_user_folder_child(user_id, parent_folder_id, child) {
	const resource = `folder/${parent_folder_id}/children`;

	const retval = await api.call('PATCH', resource, {
		add: [child]
	});

	return(retval);
}

export async function delete_user_folder_child(user_id, parent_folder_id, child_id) {
	/* XXX:TODO: Maybe the @root resource should be hidden ? */
	if (parent_folder_id === undefined) {
		parent_folder_id = '@root';
	}
	const resource = `folder/${parent_folder_id}/children`;

	const child = {
		id: child_id
	}

	const retval = await api.call('PATCH', resource, {
		delete: [child]
	});

	return(retval);
}

export async function get_user_folder_canonical_permissions(user_id, folder_id) {
	/* XXX:TODO: Cache */
	return(await api.call('GET', `folder/${folder_id}/permissions`));
}

// TODO Add New Folder DIRECTORY route functions here for call

const _to_export = {
	get_user_folder,
	new_user_folder,
	apply_diff_user_folder,
	delete_user_folder,
	new_user_folder_child,
	delete_user_folder_child,
	get_user_folder_canonical_permissions
};
export default _to_export;
