import { ExtractionStatusLabelTypeProps } from "./model";
import { LabelPaper } from "./LabelPaper";
import { DownloadDoneRounded, DownloadRounded } from "@mui/icons-material";
import { LabelTitle } from "./LabelTitle";
import { AsyncComponent, FlexBox } from "../../../../../../shared/components";
import { LabelSubTitle } from "./LabelSubTitle";
import { useDownloadSubmissionAction } from "../../../../hooks/actions/data/useDownloadSubmissionAction";

export const CompletedExtractionLabel = (props: ExtractionStatusLabelTypeProps) => {
	const downloadZip = useDownloadSubmissionAction();
	const downloadFile = () => downloadZip.run();

	return <LabelPaper status={"Completed"} justify={"space-between"} align={"center"}>
		<AsyncComponent isLoading={downloadZip.isRunning} component={
			<>
				<FlexBox align={"center"}>
					<DownloadDoneRounded fontSize={"small"} />
					<LabelTitle status={"Completed"}>Extraction Complete</LabelTitle>
				</FlexBox>
				<FlexBox align={"center"} justify={"flex-end"}>
					<LabelSubTitle status={"Completed"}>Download Zip</LabelSubTitle>
					{props.location && <DownloadRounded fontSize={"small"} onClick={downloadFile} />}
				</FlexBox>
			</>
		} />
	</LabelPaper>
}
