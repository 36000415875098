import { TabDefinition } from "../../../../../../shared/components/layout/tabs/tabDefinition";
import { AsyncComponent, FlexBox } from "../../../../../../shared/components";
import { ContentToolbar } from "../../../../components/tabs/content/ContentToolbar";
import { useState } from "react";
import { ContentPicker } from "../../../../components/modals/pickers/content";
import { SubmissionState } from "../../../../hooks";
import {
	InternalContent,
	ExternalContent,
	isExternalContent,
	isInternalContent,
	SubmissionContent
} from "../../../../../../shared/interfaces/SubmissionContent";
import { useRunWhenValueChange } from "../../../../../../shared/hooks";
import { ContentItem } from "../../../../components/tabs/content/ContentItem";
import { EctdMetaData } from "../../../../models/MetaData";
import { useAddAndRemoveContentAction } from "../../../../hooks/actions/update/useAddAndRemoveContentAction";

type State = {
	showContentPicker: boolean
}
const TabView = () => {
	const [state, setState] = useState<State>({ showContentPicker: false });
	const [internalContent, setInternalContent] = useState<InternalContent[]>([]);
	const [externalContent, setExternalContent] = useState<ExternalContent[]>([]);
	const [internalContentToRemove, setInternalContentToRemove] = useState<InternalContent[]>([]);
	const [externalContentToRemove, setExternalContentToRemove] = useState<ExternalContent[]>([]);
	const submissionState = SubmissionState.useContainer();
	const addRemoveContentAction = useAddAndRemoveContentAction();

	const contentCollection = submissionState.contentForSelectedModule();
	const selectedModuleContent = submissionState.selectedModule?.content ?? []

	const selectedInternalContent: InternalContent[] = selectedModuleContent.filter(c => isInternalContent(c)) as InternalContent[];
	const selectedExternalContent: ExternalContent[] = selectedModuleContent.filter(c => isExternalContent(c)) as ExternalContent[];

	useRunWhenValueChange((prevValue) => {
		if (state.showContentPicker || !prevValue) {
			return;
		}

		if (internalContentToRemove.length > 0 || internalContent.length > 0 || externalContentToRemove.length > 0 || externalContent.length > 0) {
			addRemoveContentAction.run({
				external: {
					add: [...externalContent],
					remove: [...externalContentToRemove]
				},
				internal: {
					add: [...internalContent],
					remove: [...internalContentToRemove]
				},
				moduleNumber: submissionState.selectedModule?.moduleNumber
			});

			setInternalContent([]);
			setInternalContentToRemove([]);
			setExternalContentToRemove([]);
			setExternalContent([]);
		}

	}, state.showContentPicker);

	const onUploadClick = () => setState(s => ({ ...s, showContentPicker: true }));

	const toggleInternalContentSelection = (content: InternalContent) => {
		// content was selected and then deselected
		if (internalContent.some(c => c.id === content.id)) {
			setInternalContent([...internalContent.filter(c => c.id !== content.id)]);
			return;
		}

		const index = selectedInternalContent.findIndex(c => c.id === content.id);
		// content hasn't been added before, so add it
		if (index < 0) {
			setInternalContent([...internalContent, content]);
			return;
		}

		// check content version
		const selectedContent = selectedInternalContent[index];
		if (selectedContent.version !== content.version) {
			// remove old content and add this version
			setInternalContentToRemove([...internalContentToRemove, content]);
			setInternalContent([...internalContent, content]);
			return;
		}

		// remove content
		setInternalContentToRemove([...internalContentToRemove, content]);
	}

	const toggleExternalContentSelection = (content: ExternalContent) => {
		// content was selected and then deselected
		if (externalContent.some(c => c.location === content.location)) {
			setExternalContent([...externalContent.filter(c => c.location !== content.location)]);
			return;
		}

		const index = selectedExternalContent.findIndex(c => c.location === content.location);
		// content hasn't been added before, so add it
		if (index < 0) {
			setExternalContent([...externalContent, content]);
			return;
		}

		// remove content
		setExternalContentToRemove([...externalContentToRemove, content]);
	}
	const onToggleContentSelection = (content: SubmissionContent) => {
		// if the content item is already selected, find the index
		if (isInternalContent(content)) {
			toggleInternalContentSelection(content);
		} else if (isExternalContent(content)) {
			toggleExternalContentSelection(content);
		}
	}

	const onClosePicker = () => {
		setState(s => ({ ...s, showContentPicker: false }));
	}

	const onRemoveContent = (entityRecord: SubmissionContent & { moduleNumber: string }) => addRemoveContentAction.run({
		external: {
			add: [],
			remove: isExternalContent(entityRecord) ? [entityRecord] : []
		},
		internal: {
			add: [],
			remove: isInternalContent(entityRecord) ? [entityRecord] : []
		},
		moduleNumber: entityRecord.moduleNumber
	})
	const onSearch = (text?: string) => { }

	const submissionMetaData: EctdMetaData = {
		compound: submissionState.submission.compound!,
		indications: submissionState.submission.indications ?? [],
		section: submissionState.selectedModule?.moduleNumber ?? ""
	};

	const selectedContent = [
		...selectedModuleContent.filter(c => {
			if (isInternalContent(c)) {
				return !internalContentToRemove.some(rc => rc.id === c.id && rc.version === c.version);
			} else if (isExternalContent(c)) {
				return !externalContentToRemove.some(rc => rc.location === c.location);
			}

			return true
		}),
		...internalContent.filter(c => !internalContentToRemove.some(rc => rc.id === c.id && rc.version === c.version)),
		...externalContent.filter(c => !externalContentToRemove.some(rc => rc.location === c.location))
	];

	return <FlexBox direction={"column"} sx={{ padding: "1rem", boxSizing: "border-box" }}>
		<AsyncComponent isLoading={addRemoveContentAction.isRunning} component={<>
			<ContentToolbar onUpload={onUploadClick} onSearch={onSearch} />
			<div style={{ marginTop: ".5rem", width: "100%" }}>
				{(contentCollection ?? []).map(contentCollection => contentCollection.content.map(content => <ContentItem content={content} moduleNumber={contentCollection.moduleNumber} selectedModule={submissionState.selectedModule} onDelete={onRemoveContent} />))}
			</div>
			<ContentPicker metaData={submissionMetaData} onToggleContentSelection={onToggleContentSelection} open={state.showContentPicker} onClose={onClosePicker} selectedContent={selectedContent} />
		</>} />
	</FlexBox>
}
const ContentsTab: TabDefinition = {
	name: "Contents",
	content: TabView
}

export { ContentsTab }