import {Order} from "./tableSort";
import React, {useState} from "react";
import {Box, TableCell, TableHead as MuiTableHead, TableRow, TableSortLabel} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import {TableColumnsType, TableColumnType} from "./TableRowCells";
import {ContentPaste, Menu} from "@mui/icons-material";
import {useMousePosition} from "../../../../../shared/hooks/useMousePosition";
import {ContextMenu, ContextMenuItemType} from "../../../../../shared/components/menu/contextMenu";

interface TableHeadProps {
	columns: TableColumnsType,
	orderBy?: string,
	order?: Order,
	onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void,
	onCellReferenceClick: (column: TableColumnType) => void,
	renderContextMenu?: boolean
}

const contextMenuItems: ContextMenuItemType<string>[] = [{
	label: "Column reference",
	value: "named",
	icon: ContentPaste
}]
const TableHead = (props: TableHeadProps) => {
	const mousePosition = useMousePosition();
	const [selectedColumn, setSelectedColumn] = useState<{column: TableColumnType, x: number, y:number} | undefined>(undefined);
	const sortHandlerFactory = (property: string) => (event: React.MouseEvent<unknown>) =>
		props.onRequestSort(event, property);

	const onContextMenuClickFactory = (column: TableColumnType) => () => setSelectedColumn({column, x: mousePosition.x, y: mousePosition.y});

	const onResetSelection = () => setSelectedColumn(undefined);
	const onCellReferenceClick = () => {
		if (selectedColumn !== undefined) {
			props.onCellReferenceClick(selectedColumn.column);
			onResetSelection();
		}
	}
	return <MuiTableHead>
		<TableRow>
			<TableCell></TableCell>
			{props.columns.map(column => <TableCell sx={{fontWeight: 700}} key={column.field} sortDirection={props.orderBy === column.field ? props.order : false}>
				<TableSortLabel
					active={props.orderBy === column.field}
					direction={props.orderBy === column.field ? props.order : 'asc'}
					onClick={sortHandlerFactory(column.field)}
				>
					{column.displayText}
					{props.orderBy === column.field ? (
						<Box component="span" sx={visuallyHidden}>
							{props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
						</Box>
					) : null}
				</TableSortLabel>
			</TableCell>)}
		</TableRow>
		{props.renderContextMenu && <TableRow>
			<TableCell></TableCell>
			{props.columns.map(column => <TableCell key={`menu-${column.field}`}>
				<Menu fontSize={"small"} sx={{cursor: "pointer"}} onClick={onContextMenuClickFactory(column)}/>
			</TableCell>)}
			<ContextMenu
				menuItems={contextMenuItems}
				open={selectedColumn !== undefined}
				onClose={onResetSelection} position={{x: selectedColumn?.x ?? 0, y: selectedColumn?.y ?? 0}}
				onMenuItemSelect={onCellReferenceClick} />
		</TableRow>}
	</MuiTableHead>;
}

export {TableHead};