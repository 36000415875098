import {Variable} from "../interfaces/Variable";
import {NunjucksVariables} from "../interfaces/Nunjucks";


/*
{
	"var1": "top level value",
	"childTemplate.var1": "other value"
}

<p>{{subTemplate.var1}}</p>
 */
function nunjucksVariableFactory(variables?: Variable[]): NunjucksVariables {
	return variables?.reduce((lookup, variable) => {
		const dataSources = variable.dataSources;
		if (dataSources !== undefined) {
			if (dataSources.length === 1) {
				lookup[variable.name.toLowerCase()] = dataSources[0]!.data
			} else if (dataSources.length > 1) {
				lookup[variable.name] = dataSources.reduce((dictionary, current) => {
					if (current?.name === undefined) {
						return dictionary;
					}
					dictionary[current.name.toLowerCase()] = current.data;
					return dictionary;
				}, {});
			}
		} else {
			const key = variable.namespace ?? variable.name;
			lookup[key.toLowerCase()] = variable.value ?? undefined
		}

		return lookup;
	}, {}) ?? {};
}

export {nunjucksVariableFactory}