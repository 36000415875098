import {isNunjucksFilterDependencies, NunjucksFilterFactoryProps} from "../model";
import {ensureArgs} from "../filterUtilities";
import {isNunjucksEnvironmentOptions, NunjucksEnvironmentOptions} from "../../models/NunjucksEnvironmentOptions";

function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args, 2) || !isNunjucksEnvironmentOptions(args[0]) || !isNunjucksFilterDependencies(args[1])) {
		return "";
	}
	const options = args[0] as NunjucksEnvironmentOptions;

	const abbreviations = options.sharedContext.abbreviations.seen;
	return JSON.stringify(abbreviations);
}

export function abbreviationListFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "__abbreviationlist",
		help: "Create a list of abbreviations",
		example: "var | __abbreviationList",
		execute: (...args: unknown[]) => execute(props?.options, props?.dependencies)
	})
}