export interface ElementIconProps {
	color?: string,
	strokeColor?: string
}

const IconDefaults: ElementIconProps = {
	color: "#403294",
	strokeColor: "#3d368f"
}

export {IconDefaults};