import {
	ReferenceVariableValue
} from "../../../../../shared/definitions/elements/reference/model";
import {NunjucksVariables} from "../../../../../shared/interfaces/Nunjucks";
import {renderTemplatedString} from "../../../../../shared/nunjucks/renderTemplatedString";

function formatReference(reference: ReferenceVariableValue): string | undefined {
	if (reference === undefined) {
		return undefined;
	}

	if (reference.value?.type === "table" || reference.value?.format === "{{link}}") {
		return `<a href="${reference.value.element_id}" data-refelement="true" onclick="return false;">${reference.value.name}</a>`;
	} else {
		return renderTemplatedString(reference.value?.format ?? "", reference?.value as NunjucksVariables)
	}
}

export {formatReference};