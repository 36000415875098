import React from 'react';

import { CircularProgress, defaultStyle, Icon } from '../../../lib/ui';
import MaterialTable from '../../MaterialTableStyled';
import { push_status_task } from '../../StatusBar';
import { withStyles } from '@mui/styles'; // LEGACY

import list from '../../../api/list.js';
import object_utils from '../../../lib/utils/object_utils.js';
import { AppChrome } from '../../App/Chrome/AppChrome';
import {ContextButtonFactory, DefaultContextButtons} from "../../../shared/components/buttons/theme";
import list_utils from "../../../lib/utils/list_utils";
import {AclEditor} from "../../../shared/components/modals/aclEditor";
const csv = {
	parse: require('csv-parse/lib/sync')
};

class ListsEditor extends React.Component {
	constructor(props, ...args) {
		super(props, ...args);

		this.state = {
			list: {},
			save_needed: false,
			loaded: false,
			save_in_progress: false
		};

		this.onContextMenuClick = this.onContextMenuClick.bind(this);
		this.input = React.createRef();
		this.mounted = false;
		this.load_list();

	}

	async load_list() {
		let list_value;
		try {
			list_value = await list.get_user_list(null, this.type, this.id, this.version);
		} catch (load_error) {
			console.error('Failed to load list:', load_error);
			list_value = {};
		}

		const new_state = {
			save_needed: false,
			save_in_progress: false,
			loaded: true,
			list: list_value
		};

		if (this.mounted) {
			this.setState(new_state);
		} else {
			this.state = {
				...this.state,
				...new_state
			};
		}
	}

	componentDidMount() {
		this.mounted = true;
	}

	_get_param(type) {
		let value;

		if (this.state && this.state.list) {
			value = this.state.list[type];
		}

		if (value === undefined) {
			value = this.props[type];
		}

		if (value === undefined) {
			if (this.props.match && this.props.match.params) {
				value = this.props.match.params[type];
			}
		}

		return (value);
	}

	get id() {
		return (this._get_param('id'));
	}

	get version() {
		return (this._get_param('version'));
	}

	set version(value) {
		this.setState(function (state) {
			return ({
				list: {
					...state.list,
					version: value
				}
			});
		});
	}

	get type() {
		return (this._get_param('type'));
	}

	get name() {
		const name = this._get_param('name');
		if (name === undefined) {
			return ('Unknown');
		}

		return (name);
	}

	set name(value) {
		if (!this.state.list) {
			return;
		}

		this.setState(function (state) {
			return ({
				list: {
					...state.list,
					name: value
				}
			});
		});
	}

	_filter_row(entries, row) {
		const new_entries = entries.filter(function (check_row) {
			if (check_row.key !== row.key) {
				return (true);
			}
			if (check_row.value !== row.value) {
				return (true);
			}
			return (false);
		});

		return (new_entries);
	}

	add_row(row) {
		this.setState(function (state) {
			return ({
				...this.save_needed({ return_change_only: true }),
				list: {
					...state.list,
					entries: [
						...state.list.entries,
						row
					]
				}
			});
		});
	}

	update_row(row, old_row) {
		this.setState((state) => {
			const entries = object_utils.copy_object(state.list.entries);
			const existing_row = entries.find(function (check_row) {
				if (check_row.key !== old_row.key) {
					return (false);
				}
				if (check_row.value !== old_row.value) {
					return (false);
				}
				return (true);
			});

			existing_row.key = row.key;
			existing_row.value = row.value;

			return ({
				...this.save_needed({ return_change_only: true }),
				list: {
					...state.list,
					entries: entries
				}
			});
		});
	}

	remove_row(row) {
		this.setState((state) => {
			return ({
				...this.save_needed({ return_change_only: true }),
				list: {
					...state.list,
					entries: this._filter_row(state.list.entries, row)
				}
			});
		});
	}

	update_permissions(new_permissions, new_id_info) {
		this.version = new_id_info.version;
		this.setState(function (state) {
			return ({
				list: {
					...state.list,
					permissions: new_permissions
				}
			});
		});
	}


	save_start() {
		this.setState({
			save_in_progress: true
		});
	}

	save_complete() {
		this.setState({
			save_in_progress: false
		});
	}

	save_needed(options = {}) {
		options = {
			return_change_only: false,
			save_needed: true,
			...options
		};

		const change = {
			save_needed: options.save_needed
		};

		if (options.return_change_only) {
			return (change);
		}

		this.setState(change);
	}

	save_not_needed(options = {}) {
		options = {
			...options,
			save_needed: false
		};

		return (this.save_needed(options));
	}

	async save_list() {
		await push_status_task('saving', async () => {
			this.save_start();

			const new_list_info = await list.update_user_list(null, this.type, this.id, this.state.list);

			if (new_list_info) {
				this.version = new_list_info.version;
				this.save_not_needed();
			} else {
				throw (new Error('Unknown error'));
			}
		}, {
			finally: (() => {
				this.save_complete();
			})
		});
	}

	load_acl_editor() {
		this.setState({
			acl_editor_visible: true
		});
	}

	async complete_acl_editor(new_permissions) {
		this.setState({
			acl_editor_visible: false
		});

		/* This indicates the "cancel" button was pressed */
		if (new_permissions === undefined) {
			return;
		}

		await push_status_task('updating ACL', async () => {
			this.save_start();

			const new_info = await list.apply_diff_user_list(null, this.type, this.id, this.version, 'Updating ACL', {
				change: {
					permissions: new_permissions
				},
				delete: {
					permissions: null
				}
			});

			if (new_info !== undefined) {
				this.update_permissions(new_permissions, new_info);
			} else {
				throw (new Error('Unknown error'));
			}
		}, {
			finally: (() => {
				this.save_complete();
			})
		});
	}

	rename_list(new_name) {
		this.name = new_name;
		this.save_needed();
	}

	onContextMenuClick(buttonKey) {
		switch(buttonKey) {
			case DefaultContextButtons.Save.key:
				this.save_list();
				break;
			case "acl_editor":
				this.load_acl_editor();
				break;
			default:
				this.input.current?.click();
		}
	}

	async upload_list(file) {
		const csv_data = await file.text();
		const data = csv.parse(csv_data, {/* No options right now */});

		let existing_list_info = undefined;
		let is_default = true;
		try {
			if (this.props.to === '@default' || this.props.to === undefined) {
				/*
				 * Get the default list ID and version
				 */
				existing_list_info = await list_utils.get_user_default_list(null, this.props.type, {
					fields: ['id', 'version', 'permissions']
				});
			} else {
				existing_list_info = await list.get_user_list(null, this.props.type, this.props.to);
				is_default = existing_list_info.default;
			}
		} catch (ignored_error) {
			/* This error is ignored and we assume it means there is no existing list */
		}

		if (existing_list_info === undefined) {
			existing_list_info = {};
		}

		const new_list = {
			name: `Master ${this.props.type} list`,
			default: is_default,
			permissions: {
				owners: [await this.current_uid],
				acl: {read: ['@role:readers']},
				roles: {
					readers: ['@all']
				}
			},
			...existing_list_info,
			id: existing_list_info.id,
			version: existing_list_info.version,
			type: this.props.type,
			entries: []
		}

		for (const row of data) {
			new_list.entries.push({
				key: row[0],
				value: row[1]
			});
		}

		let retval;
		if (existing_list_info.id === undefined) {
			retval = await list.new_user_list(null, this.type, new_list);
		} else {
			retval = await list.update_user_list(null, this.state.type, existing_list_info.id, new_list,`Added New ${this.state.type}`);
		}

		return(retval);
	}

	render() {
		if (!this.state.loaded) {
			return (
				<div>
					<CircularProgress /> Loading...
				</div>
			);
		}

		let save_button_disabled = true;

		if (this.state.save_needed === true) {
			save_button_disabled = false;
		}

		if (this.state.save_in_progress) {
			save_button_disabled = true;
		}

		return (<AppChrome
			center={<div className={this.props.className}>
				<AclEditor
					visible={this.state.acl_editor_visible}
					permissions={object_utils.copy_object(this.state.list.permissions)}
					onSave={(...args) => { this.complete_acl_editor(...args); }}
					onCancel={(...args) => { this.complete_acl_editor(...args); }}
				/>
				<input
					accept='.csv'
					style={{ display: 'none' }}
					id={this.state.id}
					type="file"
					ref={this.input}
					onChange={async (event) => {
						const files = event.target.files;

						this.save_start();

						let new_info;
						await push_status_task('uploading list', async () => {
							new_info = await this.upload_list(files[0]);
						}, {
							finally: () => {
								if (new_info === undefined) {
									this.save_complete('Failed', 'Unknown error');
									return;
								}

								if (new_info.id !== this.id) {
									console.error('[ERROR] Internal consistency error, expected ID', this.id, 'but got:', new_info);
								}

								this.version = new_info.version;
								this.load_list();
							}
						});
					}}
				/>
				<div className={this.props.classes.header}>Editing List {this.name}</div>
				<MaterialTable
					columns={[
						{ title: 'Key', field: 'key' },
						{ title: 'Value', field: 'value' },
					]}
					title={''}
					data={object_utils.copy_object(this.state.list.entries)}
					editable={{
						onRowAdd: async (...args) => { this.add_row(...args); return (true); },
						onRowUpdate: async (...args) => { this.update_row(...args); return (true); },
						onRowDelete: async (...args) => { this.remove_row(...args); return (true); }
					}}
				/>
			</div>}
			mastHeadContextButtons={ContextButtonFactory([
				{...DefaultContextButtons.Save, disabled: save_button_disabled},
				{key: "acl_editor", label: "ACL Editor", Icon: Icon.withName('Security')},
				{key: "upload_list", label: "Upload New List", Icon: Icon.withName("ImportExport")}
			], this.onContextMenuClick)}
		/>);
	}
}

export default withStyles(defaultStyle)(ListsEditor);
