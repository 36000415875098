import { useAsync, useRunWhenValueChange } from "../../../../../shared/hooks";
import {Action} from "../../../../../shared/interfaces/ActionRun";
import { getAllComments } from "../../api/comments/useGetAllComments";
import { CommentState, DocumentComment } from "../../useCommentState";
import { DocumentState } from "../../useDocumentState";
import {DocumentCollection} from "../../../models/documentCollection";

export const sortComments = (commentCollection: DocumentComment[]): DocumentComment[] => {
	// sort so that the newest appear first
	return commentCollection.sort(function(a, b) {
		if (a.date > b.date) {
			return -1;
		}
		if (a.date < b.date) {
			return 1;
		}
		return 0;
	});
}

export const addCommentWithCollection = (
	comment: DocumentComment,
	commentCollection: DocumentComment[],
	documentId: string
) => {

	// determine what type of comment this is
	if(comment.elementId === documentId) {
		comment.entityType = "TOP_LEVEL";
		comment.replies = [];
	} else if (commentCollection.find((value) => value.id === comment.elementId) !== undefined) {
		comment.entityType = 'RESPONSE';
	} else {
		comment.entityType = 'ELEMENT';
		comment.replies = [];
	}

	if(comment.entityType === 'RESPONSE') {
		const findValue = commentCollection.findIndex((value) => value.id === comment.elementId);
		commentCollection[findValue]!.replies!.push(comment);
	} else {
		commentCollection.push(comment);
	}

	return sortComments(commentCollection);
}

const collectComments = async ({documentCollection, documentId}: {documentCollection: DocumentCollection, documentId: string}): Promise<DocumentComment[]> => {

	const collectedComments = await Promise.all(
		documentCollection.filter(documentRecord => documentRecord.version !== undefined).map((documentValue) => {
			return getAllComments(documentValue.id, documentValue.version!);
		}));

	return new Promise((resolve, reject) => {
	// gather the comments for each document
		const totalComments: DocumentComment[] = [];
		const responseComments: DocumentComment[] = [];

		collectedComments.forEach((cComments, cIndex) => {
		// combine comments into array
			const combinedComments: DocumentComment[] = [];

			if(collectedComments !== null && cComments?.elements !== undefined) {
				Object.keys(cComments!.elements!).map((key) => {
					const commentGroup = cComments!.elements![key];
					return commentGroup.forEach((commentValue) => {
					// do not include soft deletes
						if(commentValue.deleted) {
							return;
						}

						combinedComments.push({
							...commentValue,
							sourceDocumentId: documentCollection[cIndex].id,
							replies: [],
							elementId: key
						})
					});
				});
			}

			// iterate through combined comments to determine what kind of entity it is
			combinedComments.forEach((combinedValue) => {
			// TOP LEVEL
				if(combinedValue.elementId === documentId) {
					totalComments.push({
						...combinedValue,
						entityType: 'TOP_LEVEL',
					});
				} else {
				// check to see if this is a response to an existing comment
					if(combinedComments.find((value) => value.id === combinedValue.elementId) !== undefined) {
						responseComments.push({
							...combinedValue,
							entityType: 'RESPONSE',
						});
					} else {
					// means this is an element
						totalComments.push({
							...combinedValue,
							entityType: 'ELEMENT',
						});
					}
				}
			});

			// now map the values in the total comments to pick up any responses
			totalComments.forEach((totalComment) => {
				totalComment.replies = responseComments.filter((value) => value.elementId === totalComment.id);
			})
		});

		// sort so that the newest appear first
		resolve(sortComments(totalComments));
	});
};

function useLoadAllCommentsAction(): Action {

	const documentManager = DocumentState.useContainer();
	const commentsManager = CommentState.useContainer();

	const asyncAction = useAsync(collectComments, false);

	// comments have been loaded
	useRunWhenValueChange(() => {
		const documentComments = asyncAction.value ?? [];
		commentsManager.setComments(documentComments);
	}, asyncAction.value);

	return {
		run: () => {
			// commentsManager.setLoading(true);
			asyncAction.execute({
				documentCollection: documentManager.documentCollection,
				documentId: documentManager.document?.id
			});
		},
		isRunning: asyncAction.isLoading,
	}
}

export {useLoadAllCommentsAction};