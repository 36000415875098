import { Button } from "../../../../shared/components";
import { CommentButtonProps } from "./CommentButton.props";
import { AddComment } from "@mui/icons-material";

const CommentButton = (props: CommentButtonProps) => {

	return (
		<Button
			buttonType="plain"
			styling="inverted"
			style={{
				lineHeight: 0,
				minWidth: "auto",
				padding: "0px",
				borderRadius: '3px',
				margin: '0 3px'}}
			icon={<AddComment fontSize="small" />}
			onClick={() => props.onClick()}
			disabled={props?.disabled}
		/>
	);
};


export { CommentButton }