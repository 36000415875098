import { Box, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles'; // LEGACY
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';

function styles(theme) {
	return({
		navIcon: {
			borderRadius: '4px',
			fontSize: theme.typography.fontSize,
			color: theme.palette.primary.main,
			margin: '5px',
			backgroundColor: theme.palette.fancyTable_pagination.main,
			'&.Mui-disabled': {
				backgroundColor: theme.palette.fancyTable_pagination.main,
			}
		},
	});
}


function labelDisplayedRows({ count, page, rowsPerPage }) {
	const from = getFrom({ count, page, rowsPerPage });
	const to = getTo({ count, page, rowsPerPage });
	return(`Showing ${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`);
}

function getFrom({ count, page, rowsPerPage }) {
	if (count === 0) {
		return 0;
	}
	return page * rowsPerPage + 1;
}

function getTo({ count, page, rowsPerPage }) {
	if (count !== -1) {
		// show total number items in a full page or current number of items if partial page
		return(Math.min(count, (page + 1) * rowsPerPage));
	}
	// if here, we dont know total count of items
	return((page + 1) * rowsPerPage);
}

function isLastPage({ count, page, rowsPerPage }) {
	if (count !== -1) {
		// check if the current page has last item
		return page >= Math.ceil(count / rowsPerPage) - 1;
	}
	// if here, we dont know total count of items
	return false;
}

function FancyTablePaginationActions(props) {
	const {
		classes,
		count: countProp,
		onChangePage = () => {},
		onPageChange = () => {},
		page,
		rowsPerPage,
		icons,
	} = props;

	const count = countProp === undefined ? -1 : countProp;

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
		onPageChange(event, page + 1);
	};


	return(
		<Box>
			<Typography variant="body2" component="span">
				{labelDisplayedRows({
					count,
					page,
					rowsPerPage,
				})}
			</Typography>
			<Tooltip title="Previous Page">
				<span>
					<IconButton
						onClick={handleBackButtonClick}
						disabled={page === 0}
						className={classes.navIcon}
						disableRipple
						size="small"
					>
						<icons.PreviousPage/>
					</IconButton>
				</span>
			</Tooltip>
			<Tooltip title="Next Page">
				<span>
					<IconButton
						onClick={handleNextButtonClick}
						disabled={isLastPage({
							count,
							page,
							rowsPerPage,
						})}
						className={classes.navIcon}
						disableRipple
						size="small"
					>
						<icons.NextPage/>
					</IconButton>
				</span>
			</Tooltip>
		</Box>
	);
}

export default withStyles(styles)(FancyTablePaginationActions);