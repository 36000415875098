import {TemplateElementDefinition} from "../model";
import {TocIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {PreviewView} from "./views/preview/PreviewView";
import {
	tocDefinition as baseTocDefinition
} from "../../../../../shared/definitions/elements/toc/model";

const tocDefinition: TemplateElementDefinition = {
	...baseTocDefinition,
	icon: TocIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		preview: PreviewView
	},
} as const

export {tocDefinition};