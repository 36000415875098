import {useAsync} from "../../../../shared/hooks";
import { useGetTemplate } from './useGetTemplate';
import api from "../../../../api/api";


interface CreateTemplateOptions {
	name: string,
	toplevel: string,
}

// creates a new template
function useCreateNew() {
	const get = useGetTemplate();

	const createTemplate = ({name, toplevel}: CreateTemplateOptions) => {
		if (name === undefined) {
			name = "Undefined";
		}

		const payload = {
			name,
			metadata: {
				system: {
					toplevel,
				},
			},
			variables: {},
			body: []
		}

		return api.call('POST', 'template', payload);
	}

	const createNewTemplate = async (options: Partial<CreateTemplateOptions>) => {
		const template = await createTemplate({
			name: "New Template",
			toplevel: options.toplevel ?? "false",
		});
		return get.execute({id:template.id});
	}

	return useAsync<KaiAlphaTemplate>(createNewTemplate, false);
}

export {useCreateNew}