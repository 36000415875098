import { styled } from "@mui/styles";
import {Box, Theme} from "@mui/material";

interface TabContainerProps {
	direction: "row" | "column"
}

interface TabItemProps extends TabContainerProps {}

const TabContainer = styled("ul")(({direction}: TabContainerProps) => ({
	display: "flex",
	padding: 0,
	margin: direction === 'row' ? 5 : "25px 5px",
	flexDirection: direction,
	textAlign: 'center',
	justifyContent: "space-around",
	listStyle: "none"
}));

const TabItem = styled("li")<Theme, TabItemProps>(({theme, direction}) => ({
	margin: "5px 10px",
	fontSize: direction === 'row' ? "18px" : "16px",
	whiteSpace: direction === 'row' ? "nowrap" : "normal",
	fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
	fontWeight: 500,
	padding: direction === 'row' ? "0px" : "15px 0px",
	cursor: "pointer",
	color: direction === 'row' ? theme.palette.tabs?.main : theme.palette.tabs?.contrastText,
	'&:hover': {
		textShadow: `-0.01ex 0 ${theme.palette.secondary.contrastText}, 0.01ex 0 ${theme.palette.secondary.contrastText}`,
		color: theme.palette.secondary.contrastText,
	},
	'&.active': {
		textShadow: `-0.01ex 0 ${theme.palette.secondary.contrastText}, 0.01ex 0 ${theme.palette.secondary.contrastText}`,
		color: theme.palette.secondary.contrastText,
	},
}));

const TabButtonsContainer = styled(Box)({
	margin: "0px 3px",
});

export {
	TabContainer,
	TabItem,
	TabButtonsContainer
}