import { styled } from "@mui/material";
import { Link } from "@mui/icons-material";

const LinkIcon = styled(Link)(({theme}) => ({
	padding: '5px',
	color: theme?.palette?.secondary?.contrastText,
	fontSize: '16px',
	transform: 'rotate(90deg)',
}));

export {
	LinkIcon,
}