import {EctdModule} from "../../../shared/interfaces/Module";
import {EntityRecord} from "../../../shared/interfaces/Entity";

export type SubmissionExtractionStatus = "Requested" | "Started" | "Completed" | "Error" | "Idle"
export type Submission = EntityRecord & {
	name: string,
	compound?: string,
	indications?: string[],
	lastupdated?: number,
	regulator?: string[],
	ectdModules: EctdModule[],
	extraction_status: SubmissionExtractionStatus,
	extraction_error?: string,
	extract_location: string
}

export function isSubmission(data: unknown): data is Submission {
	return (data as Submission).ectdModules !== undefined;
}