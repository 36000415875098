import {nullLoaderEnvironmentFactory} from "./NunjucksEnvironment";
import {RenderStringProps} from "./models/RenderStringProps";


export function renderString({template, variables, options}: RenderStringProps): string {
	if (template === undefined || template === null) {
		return template
	}

	const env = nullLoaderEnvironmentFactory(variables, options);
	return env.renderString(template, variables);
}