import {StylePropertyFactory} from "./model";
import {FontColor, FontFamily, FontSize, FontStyle, FontStyles, FontUnits, StyleAttribute} from "../../../editor/elements/styles";

type SupportedAttributes = FontFamily | FontSize | FontStyle | FontColor | FontUnits;
type MappedAttributes = FontFamily | FontSize | FontStyle | FontColor

function fontStyleMap(value: FontStyles): string {
	if (value === "Bold") {
		return "font-weight: bold !important"
	} else if (value === "Italic") {
		return "font-style: oblique !important"
	}

	return "text-decoration: underline !important";
}
const attributeMap: Record<MappedAttributes, (value: Record<SupportedAttributes, string>) => string> = {
	font_color: value => `color: ${value.font_color} !important`,
	font_family: value => `font-family: ${value.font_family} !important`,
	font_style: value => fontStyleMap(value.font_style as FontStyles),
	font_size: value => `font-size: ${value.font_size}${value.font_units} !important`
}
function factory(styleMap: Partial<Record<StyleAttribute, string>>): string {
	const css = Object.keys(styleMap).map(key => {
		if(styleMap[key] !== undefined) {
			const valueFunc = attributeMap[key];
			return valueFunc ? valueFunc(styleMap) : "";
		}
		return "";
	}).filter(value => value.length > 0).join(";");
	return css.length === 0 ? "" : "{" + css + ";}";
}

const fontStyleFactory: StylePropertyFactory = factory;
export {fontStyleFactory};