import { Theme, styled as muiStyled } from "@mui/material";
import { styled } from "@mui/styles";
import { FlexBox } from "../../../shared/components";
import { ListContainer } from "./DashboardContentList.style";

interface ItemDateTextProps {
    overdue: boolean,
}

const TasksExpiringHeader = styled(FlexBox)(({theme}) => ({
	boxSizing: 'border-box',
	padding: '0 0 10px 0',
	borderBottom: `2px solid ${theme.palette.dashboard?.border}`,
	'& span': {
		color: theme.palette.dashboard?.contrastText,
		fontSize: '.8rem',
		fontWeight: 600,
	}
}));

const TasksExpiringList = muiStyled(ListContainer)(({theme}) => ({
	boxSizing: 'border-box',
	flexDirection: 'column',
	'.single_item:nth-of-type(even)': {
		backgroundColor: theme.palette.paperDialog?.main,
	}
}));

const TasksExpiringListItem = muiStyled(FlexBox)(({theme}) => ({
	boxSizing: 'border-box',
	alignItems: "center",
	padding: "1rem 0",
	cursor: "pointer",
	borderBottom: `1px solid ${theme.palette.dashboard?.border}`,
	':hover': {
		backgroundColor: `${theme.palette?.dashboard?.static} !important`,
	}
}));

const ItemText = styled(FlexBox)(({theme}) => ({
	paddingLeft: "10px",
	marginRight: "70px",
	alignItems: "center",
	'& .title_text': {
		color: theme?.palette?.primary.main,
		fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
		fontWeight: 500,
		fontSize: '1.0rem',
		whiteSpace: 'nowrap',
		maxWidth: '40rem',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));


const ItemDateText = styled('span')<Theme, ItemDateTextProps>(({overdue, theme}) => ({
	color: overdue ? theme.palette.destructive?.main : theme.palette.dashboard?.contrastText,
	fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
	fontWeight: 500,
	fontSize: '1.0rem',
	whiteSpace: 'nowrap',
	maxWidth: '40rem',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));

export {
	TasksExpiringHeader,
	TasksExpiringList,
	TasksExpiringListItem,
	ItemText,
	ItemDateText
}