import {ColumnDefinition} from "../../../../../shared/components/layout/table";
import {cellViewForColumn} from "./columns";
import {BaseTableRow} from "../../../../../shared/components/layout/table/styles";
import {ContentNode} from "../../../models/content";
import {ContentListAction} from "./models";

export interface ContentListRowProps {
	item: unknown,
	rowIndex: number,
	columns: ColumnDefinition[],
	onTaskSelected: (contentId: string) => void,
	onDeleteContent: (contentNode: ContentNode) => void,
	onRowSelected: (row: ContentNode) => void
}

const ContentListRow = (props: ContentListRowProps) => {
	const content = props.item as ContentNode;
	const onRenderTableCell = (column: ColumnDefinition) => {
		const Cell = cellViewForColumn(column.field);
		return Cell ? <Cell
			key={`${column.field}-${content.id}`}
			item={content}
			onAction={onColumnAction}
			column={column}/> : content[column.field];
	}

	const onColumnAction = (action: unknown, column: ColumnDefinition) => {
		const columnAction = action as ContentListAction;
		switch(columnAction) {
			case "view":
				if (column.field === "name") {
					props.onRowSelected(content);
				} else {
					props.onTaskSelected(content.id);
				}
				break;
			case "delete":
				props.onDeleteContent(content);
				break;
		}
	}

	return <BaseTableRow>
		{props.columns.map(column => onRenderTableCell(column))}
	</BaseTableRow>
}

export {ContentListRow};