import {NumberedHeaderBlue, ColumnBox, SubText} from './styles';
import {TextField} from "../../../../shared/components/input";
import {colors} from "../../../../theme";
import {ChangeEvent} from "react";
import {TextArea} from "../../../../shared/components/input/TextArea";
import {FormControl} from "@mui/material";
import SelectUsersList from '../selectuserslist/SelectUsersList';
import {UserGroupsNode, UserGroupUser, UserGroupValidation} from "../../models";

type UsersProps = {
	users: UserGroupUser[],
	userGroup: UserGroupsNode & UserGroupValidation,
	onChange:(userGroupNode: UserGroupsNode) => void
}

const CreateUserGroup = (props: UsersProps) => {

	const onValueChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: "groupName" | "description") => {
		props.onChange({...props.userGroup, [field]: e.target.value.trim()});
	}

	const onUserChange = (users: UserGroupUser[]) => {
		props.onChange({...props.userGroup,users: users});
	}

	return (
		<>
			<FormControl fullWidth>
				<ColumnBox>
					<NumberedHeaderBlue>
						<div>
							<span className="scl__ncb--header--number">{1}</span>
							<h2 className={"scl__ncb--header--title enabled"}>{'Details'}</h2>
						</div>
					</NumberedHeaderBlue>
					<div>
						<TextField
							InputProps={{style: {
								background: colors.paperWhite,
								borderColor: colors.fadedGrey,
								fontSize: '20px',
								height: '60px'
							}}}
							label={"User Group Name"}
							onChange={e => onValueChange(e, 'groupName')}
							value={props.userGroup.groupName}
							sx={{marginTop: "1rem"}} />
						<SubText><span style={{color: (props.userGroup.isGroupNameValid ? colors.fadedGrey : colors.errorRed)}}>Max 128 chars, upper/lowercase letters, numbers 0-9, + = . @ - symbols, no spaces</span></SubText>
					</div>
					<div>
						<TextArea label={"Description"}
							value={props.userGroup.description}
							onChange={e => onValueChange(e, 'description')} />
					</div>
					<NumberedHeaderBlue>
						<div>
							<span className="scl__ncb--header--number">{2}</span>
							<h2 className={"scl__ncb--header--title enabled"}>{'Add Users'}</h2>
						</div>
					</NumberedHeaderBlue>
				</ColumnBox>
			</FormControl>

			<SelectUsersList leftItems={props.users} rightItems={props.userGroup.users} onChange={onUserChange}/>
		</>
	)
}

export default CreateUserGroup;