type StyleValue = {
	value: string
}

type StyleValues = StyleValue[];

export type FontBold = "Bold";
export type FontItalic = "Italic";
export type FontUnderline = "Underline";

export type FontStyles = FontUnderline | FontItalic | FontBold;

const fontSizes: StyleValues = [{value: '5'}, {value: '5.5'}, {value: '6.5'},
	{value: '7.5'}, {value: '8'}, {value: '9'},
	{value: '10'}, {value: '10.5'}, {value: '11'},
	{value: '12'}, {value: '14'}, {value: '16'},
	{value: '18'}, {value: '20'}, {value: '22'},
	{value: '24'}, {value: '26'}, {value: '28'},
	{value: '36'}, {value: '48'}, {value: '72'}];

const fontStyles: StyleValues = [{value: 'Bold'}, {value: 'Italic'}, {value: 'Underline'}];
const fontFamilies: StyleValues = [{value: 'Arial'}, {value: 'Helvetica'}, {value: 'Avenir'}, {value: 'Times'},
	{value: 'Calibri'}, {value: 'Courier'}, {value: 'Tahoma'}, {value: 'Verdana'}];
const fontUnits: StyleValues = [{value: 'px'}, {value: 'em'}, {value: 'pt'}];

const fixedInAndPtSizes: StyleValues = [{value: '.5'}, {value: '1'}, {value: '1.5'},
	{value: '2'}, {value: '2.5'}, {value: '3'},
	{value: '3.5'}, {value: '4'}, {value: '4.5'},
	{value: '5'}, {value: '5.5'}, {value: '6'},
	{value: '6.5'}, {value: '7'}, {value: '7.5'},
	{value: '8'}, {value: '8.5'}, {value: '9'},
	{value: '9.5'}, {value: '10'}, {value: '10.5'}];

const colors: StyleValues = [{value: 'Black'}, {value: 'White'}, {value: 'Blue'}, {value: 'Red'}, {value: 'Green'},
	{value: 'Yellow'}, {value: 'Pink'}, {value: 'Orange'}, {value: 'Brown'}];

const borderStyles: StyleValues = [{value: 'solid'}, {value: 'none'}, {value: 'dotted'},
	{value: 'dashed'}];

const cellSizes: StyleValues = [{value: '10'}, {value: '20'}, {value: '30'},
	{value: '40'}, {value: '50'}, {value: '60'},
	{value: '70'}, {value: '80'}, {value: '90'},
	{value: '100'}, {value: '110'}, {value: '120'}];

const textAlignment: StyleValues = [ {value: 'left'}, {value: 'center'}, {value: 'right'} ];
const lineHeights: StyleValues = [ {value: 'normal'} ];

export type FontSize = "font_size";
export type FontUnits = "font_units";
export type FontFamily = "font_family";
export type FontStyle = "font_style";
export type FontColor = "font_color";
export type LineHeight = "line_height";
export type PaddingLeft = "padding_left";
export type PaddingRight = "padding_right";
export type PaddingTop = "padding_top";
export type PaddingBottom = "padding_bottom";
export type HangingIndent = "hanging_indent";
export type BorderWidth = "border_width";
export type BorderColor = "border_color";
export type BorderStyle = "border_style";
export type BorderCollapse = "border_collapse";
export type CellWidth = "cell_width";
export type CellHeight = "cell_height";
export type HeaderWidth = "header_width";
export type HeaderHeight = "header_height";
export type CellTextAlign = "cell_text_align";
export type HeaderTextAlign = "header_text_align"

export type StyleAttribute = FontStyle | FontSize | FontFamily | FontUnits | LineHeight | PaddingLeft |
	PaddingRight | PaddingTop | PaddingBottom | HangingIndent | FontColor | CellWidth | CellHeight |
	HeaderHeight | HeaderWidth | CellTextAlign | HeaderTextAlign | BorderWidth | BorderStyle |
	BorderColor | BorderCollapse

interface StyleParameters {
	title: string,
	attribute: StyleAttribute,
	values: StyleValues
}


const styleParameters: StyleParameters[] = [
	{
		title: 'Font Size',
		attribute: 'font_size',
		values: fontSizes
	},
	{
		title: 'Font Units',
		attribute: 'font_units',
		values: fontUnits
	},
	{
		title: 'Font Family',
		attribute: 'font_family',
		values: fontFamilies
	},
	{
		title: 'Font Style',
		attribute: 'font_style',
		values: fontStyles
	},
	{
		title: 'Line Height',
		attribute: 'line_height',
		values: lineHeights
	},
	{
		title: 'Left Indent (inches)',
		attribute: 'padding_left',
		values: fixedInAndPtSizes
	},
	{
		title: 'Right Indent (inches)',
		attribute: 'padding_right',
		values: fixedInAndPtSizes
	},
	{
		title: 'Space Before (pt)',
		attribute: 'padding_top',
		values: fixedInAndPtSizes
	},
	{
		title: 'Space After (pt)',
		attribute: 'padding_bottom',
		values: fixedInAndPtSizes
	},
	{
		title: 'Hanging Indent (inches)',
		attribute: 'hanging_indent',
		values: fixedInAndPtSizes
	},
	{
		title: 'Font Color',
		attribute: 'font_color',
		values: colors
	}
];

const tableStyleParameters: StyleParameters[] = [
	{
		title: 'Cell Width (px)',
		attribute: 'cell_width',
		values: cellSizes
	},
	{
		title: 'Cell Height (px)',
		attribute: 'cell_height',
		values: cellSizes
	},
	{
		title: 'Header Width (px)',
		attribute: 'header_width',
		values: cellSizes
	},
	{
		title: 'Header Height (px)',
		attribute: 'header_height',
		values: cellSizes
	},
	{
		title: 'Cell Text Alignment',
		attribute: 'cell_text_align',
		values: textAlignment
	},
	{
		title: 'Header Text Alignment',
		attribute: 'header_text_align',
		values: textAlignment
	},
	{
		title: 'Border Width (pt)',
		attribute: 'border_width',
		values: fixedInAndPtSizes
	},
	{
		title: 'Border Color',
		attribute: 'border_color',
		values: colors
	},
	{
		title: 'Border Style',
		attribute: 'border_style',
		values: borderStyles
	},
	{
		title: 'Collapse Borders',
		attribute: 'border_collapse',
		values: [{value: 'collapse'}]
	}
];

export {styleParameters, tableStyleParameters}
