import { FlexBox } from "../../../../../../shared/components";
import { Switch, SwitchTitle } from "./styles";

const TemplateInputSwitch = ({
	checked,
	onChange,
}) => {
	return (
		<FlexBox boxSizing="border-box" padding="0.3rem">
			<SwitchTitle>Constant</SwitchTitle>
			<Switch checked={checked} onChange={onChange}/>
			<SwitchTitle>Expression</SwitchTitle>
		</FlexBox>
	)

}

export {TemplateInputSwitch}