import {
	definitionForElementType,
	editableViewForElementType,
	iconForElementType,
	propertyEditorForElementType
} from "./index";
import {EditableElementEvents, ElementPropertyData} from "./model";
import {FlexBox} from "../../../../shared/components";
import {Box, styled} from "@mui/material";
import {DeleteButton} from "../../../../shared/components/layout/box/editControlBox/DeleteButton";
import {ForwardedRef, forwardRef, useState} from "react";
import {TemplateState} from "../../hooks";
import {SettingsButton} from "../../../../shared/components/layout/box/editControlBox/SettingsButton";
import {CommentState} from "../../hooks/useCommentState";

export type EditableViewProps = ElementPropertyData & EditableElementEvents &
	{className?: string, depth?: number} & {onDelete?: (elementId: string) => void}

const Container = styled("div")({
	display: "flex",
	flexDirection: "column",
	"& .buttons": {
		display: "none",
	},
	"&:hover .buttons": {
		display: "flex",
		margin: ".5rem 0 -.5rem 0"
	}
})
const View = (props: EditableViewProps, ref: ForwardedRef<HTMLDivElement>) => {
	const templateManager = TemplateState.useContainer();
	const commentManager = CommentState.useContainer();

	const [state, setState] = useState({editProperty: false});
	const View = editableViewForElementType(props.elementInformation?.type);
	const Icon = iconForElementType(props.elementInformation?.type);
	const PropertyEditor = propertyEditorForElementType(props.elementInformation?.type, true);
	const elementDefinition = definitionForElementType(props.elementInformation?.type);


	const onEditProperty = () => setState({editProperty: true});
	const onClosePropertyEditor = () => setState({editProperty: false});
	const onUpdateElementProperty = (elementData: ElementPropertyData) => {
		setState({editProperty: false});
		props.onChange(elementData);
	}

	const classNames = [props.className, "template-editor__elements",
		(elementDefinition?.allowsChildren ?? false) || (props.depth ?? 0) > 0 ? "template-editor__elements-nested" : "",
		(props.depth ?? 0) > 0 ? "template-editor__elements-nested-nm" : "",
		commentManager.selectedComment?.elementId === props.id ? "template-editor__elements--comment-selected" : ""
	].join(" ");

	const padding = (props.depth ?? 0) > 0 ? "0 2rem" : "0 2rem";
	const margin = ((elementDefinition?.allowsChildren ?? false) && props.depth === 0) || (props.depth ?? 0) === 0 ? ".5rem 0 0" : null;
	const onDelete = () => props.onDelete!(props.id);
	return View ? <Container ref={ref} sx={{p: padding, m: margin, boxSizing: "border-box"}} className={classNames}>
		<FlexBox justify={"flex-end"} className={"button-container"}>
			<Box className={"buttons"}>
				{ PropertyEditor && <SettingsButton onClick={onEditProperty} /> }
				{ props.onDelete && <DeleteButton onClick={onDelete} /> }
			</Box>
		</FlexBox>
		<FlexBox className={"template-editor__elements-view"}>
			<Box sx={{mr: "1rem"}}>
				<Icon />
			</Box>
			<Box width={"calc(100% - 2rem)"}>
				<View {...props} />
			</Box>
		</FlexBox>
		{state.editProperty && PropertyEditor && <PropertyEditor
			value={{
				id: props.id,
				elementInformation: props.elementInformation,
				data: props.data
			}}
			onClose={onClosePropertyEditor}
			onApply={onUpdateElementProperty}
			type={props.elementInformation?.type ?? ""}
			variables={templateManager.variables}
			dataSourcesList={templateManager.datasources?.items} />}
	</Container> : null;
}

const EditableView = forwardRef<HTMLDivElement, EditableViewProps>(View);
export {EditableView}