import {TabDefinition} from "../../../../../../shared/components/layout/tabs/tabDefinition";
import {FlexBox, GroupedContent} from "../../../../../../shared/components";
import {SubmissionState} from "../../../../hooks";
import {SubmissionName} from "../../../../components/tabs/properties/SubmissionName";
import {CompoundLabel} from "../../../../components/tabs/properties/compoundDetail/CompoundLabel";
import {Indications} from "../../../../components/tabs/properties/compoundDetail/Indications";
import {ExtractionStatus} from "../../../../components/tabs/properties/ExtractionStatus";

const TabView = () => {
	const {submission, setSubmission} = SubmissionState.useContainer();
	const onNameChange = (name: string) => setSubmission(s => ({...s, name}));
	return <FlexBox direction={"column"}>
		<ExtractionStatus status={submission.extraction_status ?? "Idle"} location={submission.extract_location} error={submission.extraction_error} />
		<GroupedContent title={"Submission Details"} expanded={true}>
			<SubmissionName name={submission.name} onNameChange={onNameChange} />
			{submission.compound && <CompoundLabel compound={submission.compound} />}
			{submission.indications && submission.indications.length > 0 && <Indications indications={submission.indications} />}
		</GroupedContent>
	</FlexBox>
}

const PropertiesTab: TabDefinition = {
	name: "Properties",
	content: TabView
}

export {PropertiesTab};