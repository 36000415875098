import {DocumentElementDefinition} from "../model";
import PreviewView from "./views/PreviewView";
import {
	inTextTableDefinition as baseInTextTableDefinition
} from "../../../../../shared/definitions/elements/inTextTable/model";

const inTextTableDefinition: DocumentElementDefinition = {
	...baseInTextTableDefinition,
	views: {
		preview: PreviewView
	},
} as const

export {inTextTableDefinition};