import {DocumentElementDefinition} from "../model";
import {PreviewView} from "./views/PreviewView";
import {
	templateDefinition as baseTemplateDefinition
} from "../../../../../shared/definitions/elements/template/model";

const templateDefinition: DocumentElementDefinition = {
	...baseTemplateDefinition,
	views: {
		preview: PreviewView
	}
} as const

export {templateDefinition};