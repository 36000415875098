/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/client/list.mjs
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../lib/kaialpha';
import api from './api';
import cache_utils from '../lib/utils/cache_utils.js';
import versions_utils from '../lib/utils/versions_utils.js';
const cached_list_short_ttl = 30 * 1000;
const cached_list_list_ttl = 2 * 60 * 1000;
const cached_list_perms_ttl = 10 * 60 * 1000;

export async function get_user_list(user_id, list_type, list_id, list_version) {
	if (list_type === undefined) {
		throw(new Error('list_type must be supplied'));
	}

	if (list_id === undefined) {
		throw(new Error('list_id must be supplied'));
	}

	/* XXX:TODO: Handle other symbolic names */
	let uncachable = false;
	if (list_version === 'HEAD') {
		list_version = undefined;
		uncachable = true;
	}

	if (list_id === '@default') {
		uncachable = true;
	}

	if (list_version === undefined) {
		uncachable = true;
	}

	const cache_id = [list_type, list_id, list_version].join('|');
	const cache_options = {};
	let cache_object_name;
	if (uncachable) {
		cache_object_name = 'cached_list_short';
		cache_options.cache_expires = cached_list_short_ttl;
	} else {
		cache_object_name = 'cached_list';
	}

	const retval = await cache_utils.cache_promise(cache_object_name, cache_id, async function() {
		let retval;

		if (list_version === undefined) {
			retval = await api.call('GET', `list/${list_type}/${list_id}`);
		} else {
			retval = await api.call('GET', `list/${list_type}/${list_id}/versions/${list_version}`);
		}

		return(retval);
	}, cache_options);

	delete retval['child_resources'];
	delete retval['versions'];

	return(retval);
}

export async function get_user_lists(user_id, list_type, options = {}) {
	options = {
		fields: [],
		filter: undefined,
		count: undefined,
		...options
	}

	if (options.auto_paginate === true) {
		throw(new Error('Lists currently do not support pagination'));
	}

	if (list_type === undefined) {
		throw(new Error('list_type must be supplied'));
	}

	const query_add = versions_utils.list_generate_query_from_options(options);

	const path = `list/${list_type}${query_add}`;

	const retval = await cache_utils.cache_promise('cached_list_list', path, async function() {
		const retval = await api.call('GET', path);

		delete retval['child_resources'];

		return(retval);
	}, {
		cache_expires: cached_list_list_ttl
	});

	return(retval);
}

export async function update_user_list(user_id, list_type, list_id, new_list, summary = undefined) {
	if (list_type === undefined) {
		throw(new Error('list_type must be supplied'));
	}

	if (list_id === undefined) {
		throw(new Error('list_id must be supplied'));
	}

	delete new_list['previous_version'];

	const retval = await api.call('PUT', `list/${list_type}/${list_id}`, new_list)

	await cache_utils.clear('cached_list_short');
	await cache_utils.clear('cached_list_list');
	await cache_utils.clear('cached_list_perms');

	return(retval);
}

export async function new_user_list(user_id, list_type, new_list) {
	if (list_type === undefined) {
		throw(new Error('list_type must be supplied'));
	}

	delete new_list['version'];
	delete new_list['previous_version'];

	const retval = await api.call('POST', `list/${list_type}`, new_list);

	await cache_utils.clear('cached_list_list');

	return(retval);
}

export async function delete_user_list(user_id, list_type, list_id) {
	if (list_type === undefined) {
		throw(new Error('list_type must be supplied'));
	}

	if (list_id === undefined) {
		throw(new Error('list_id must be supplied'));
	}

	const retval = await api.call('DELETE', `list/${list_type}/${list_id}`);

	await cache_utils.clear('cached_list_list');
	await cache_utils.clear('cached_list_perms');

	return(retval);
}

export async function apply_diff_user_list(user_id, list_type, list_id, version_id_head, summary, diff) {
	if (list_type === undefined) {
		throw(new Error('list_type must be supplied'));
	}

	if (list_id === undefined) {
		throw(new Error('list_id must be supplied'));
	}

	const payload = {
		id: list_id,
		version: version_id_head,
		summary: summary,
		change: diff.change,
		delete: diff.delete
	};

	const retval = await api.call('PATCH', `list/${list_type}/${list_id}`, payload);

	await cache_utils.clear('cached_list_list');
	await cache_utils.clear('cached_list_short');
	await cache_utils.clear('cached_list_perms');

	return(retval);
}

export async function get_user_list_canonical_permissions(user_id, list_type, list_id) {
	const cache_id = [list_type, list_id].join('|');

	const retval = await cache_utils.cache_promise('cached_list_perms', cache_id, async function() {
		return(await api.call('GET', `list/${list_type}/${list_id}/permissions`));
	}, {
		cache_expires: cached_list_perms_ttl
	});

	return(retval);
}

const _to_export = {
	get_user_list,
	get_user_lists,
	update_user_list,
	new_user_list,
	delete_user_list,
	apply_diff_user_list,
	get_user_list_canonical_permissions
};
export default _to_export;
