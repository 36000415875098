import {DocumentElementDefinition} from "../model";
import PreviewView from "./PreviewView";
import {
	footerDefinition as baseFooterDefinition
} from "../../../../../shared/definitions/elements/footer/model";

const footerDefinition: DocumentElementDefinition = {
	...baseFooterDefinition,
	views: {
		preview: PreviewView
	},
} as const

export {footerDefinition};