import {TypedAction} from "../../../../../shared/interfaces/ActionRun";
import {useDownloadZipApi} from "../../api/useDownloadZipApi";
import {useRunWhenValueChange, useTypedAsync} from "../../../../../shared/hooks";
import {useStatusBar} from "../../../../../shared/components";
import {EntityRecord} from "../../../../../shared/interfaces/Entity";
import {downloadFile} from "./downloadZip";
import {useState} from "react";

function useDownloadSubmissionWithIdAction(): TypedAction<EntityRecord & {name: string}> {
	const [state, setState] = useState<EntityRecord & {name: string} | undefined>(undefined);
	const downloadZipApi = useDownloadZipApi();
	const downloadFileAsync = useTypedAsync(downloadFile, false);
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if (state?.id) {
			downloadZipApi.execute({id: state.id, version: state.version});
		}
	}, state)
	useRunWhenValueChange(() => {
		if (downloadZipApi.value && state?.name) {
			downloadFileAsync.execute({path: downloadZipApi.value.link, fileName: `${state.name.replace(/\W/gmi, "_")}`});
		}

	}, downloadZipApi.value);

	useRunWhenValueChange(() => {
		if (downloadFileAsync.value) {
			if (downloadFileAsync.value.success) {
				statusBar.sendSuccessNotification({message: "Submission downloaded"});
			} else {
				statusBar.sendErrorNotification({message: "Submission downloaded", detail: downloadFileAsync.value.error});
			}
		}
	}, downloadFileAsync.value)
	const run = (props: EntityRecord & {name: string}) => setState(props);

	return {
		run,
		isRunning: downloadZipApi.isLoading || downloadFileAsync.isLoading
	}
}

export {useDownloadSubmissionWithIdAction}