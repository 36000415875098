import {BreadcrumbDefinition} from "./models";
import "./style.scss";
import {ArrowBackIos} from "@mui/icons-material";
export interface BreadcrumbsProps {
	items: BreadcrumbDefinition[],
	onClick: (item: BreadcrumbDefinition) => void
}
const Breadcrumbs = ({items, onClick}: BreadcrumbsProps) => {
	const onItemClickFactory = (item) => item.url ? () => onClick(item) : undefined;
	return (
		<nav className={"tn__breadcrumbs"}>
			<ul>
				{items.map(item => <li data-url={item.url} key={`${item.title}-breadcrumb`} onClick={onItemClickFactory(item)}>
					<ArrowBackIos style={{fontSize: "1rem", color: "black", marginRight: ".8rem"}} />
					{item.title}
				</li>)}
			</ul>
		</nav>
	)
}

export {Breadcrumbs};