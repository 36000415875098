
import { WorkflowButton } from "../../modules/task/models/workflow";
import { canonicalizePermissions } from "../permissions/canonicalizePermissions";

const filterButtonsSystemUser = (
	buttons: WorkflowButton[],
	itemPermissions: KaiAlphaDocumentPermissions
) => {
	const response: unknown[] = [];

	if (!buttons) {
		return (response);
	}

	for (const button of buttons) {
		const buttonPermissionsStatement = {
			...itemPermissions,
			acl: button.permissions.acl
		};

		const buttonPermissions = canonicalizePermissions(buttonPermissionsStatement);

		response.push({
			...button,
			permissions: buttonPermissions
		});
	}

	return (response);
}

export {filterButtonsSystemUser}