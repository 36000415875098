/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/lib/validation_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import document_utils from './document_utils';
import workflow_utils from './workflow_utils';
import object_utils from './object_utils';
const ajv = require('ajv');
const json_schema = ajv.default;
const _testing = undefined;

const variable_types = ['text', 'dropdown', 'checkbox', 'list', 'multi_input', 'datasource', 'image', 'textarea', 'richtextarea', 'reference', 'expression']
const element_types = ["section", "html", "title", "table_of_contents", "template", "variable", "reference", "switch", "image", "table", "header", "footer", "style", "comment", "loop", "citations_list", "abbreviations_list", "intexttable"];
const comment_states = ['IN REVIEW', 'RESOLVED'];

const element_info = [
	{
		name: 'Template',
		type: 'template',
		description: 'Place Sub-Templates',
		long_description: 'Template elements may be used to include another template at the position where this element is placed',
		icon: 'template-outline.svg'
	},
	{
		name: 'Content Text',
		type: 'html',
		description: 'Place Editable Text',
		long_description: 'HTML elements allow for rich text to be written in templates, any Nunjucks code within the HTML element will be evaluated when being rendered',
		icon: 'text-filled.svg'
	},
	{
		name: 'Variable',
		type: 'variable',
		description: 'Access Dynamic Data',
		long_description: `Variable elements allow template authors to specify that some input should be provided from a document instance. Variables have a few different types: ${variable_types.join(', ')}. The datasource variable type will pull data from an external system.`,
		icon: 'variable-outline.svg'
	},
	{
		name: 'Table',
		type: 'table',
		description: 'Add Data',
		long_description: 'Table elements render a datasource in tabular form',
		icon: 'table-filled.svg'
	},
	{
		name: 'In-Text Table',
		type: 'intexttable',
		description: 'Add In-Text Table',
		long_description: 'add an in-text table to the template',
		icon: 'table-filled.svg'
	},
	{
		name: 'Title',
		type: 'title',
		description: 'Add Heading',
		icon: 'title-filled.svg'
	},
	{
		name: 'Reference',
		type: 'reference',
		description: 'Add Reference',
		icon: 'link-outline.svg'
	},
	// {
	// 	name: 'Image',
	// 	type: 'image',
	// 	description: 'Place Image',
	// 	icon: 'image-outline.svg'
	// },
	{
		name: 'Section',
		type: 'section',
		description: 'Container',
		icon: 'section-outline.svg'
	},
	{
		name: 'Table of Contents',
		type: 'table_of_contents',
		description: 'Add TOC of sections',
		icon: 'toc-outline.svg'
	},
	{
		name: 'Switch',
		type: 'switch',
		description: 'Decide the case',
		icon: 'decision.svg'
	},
	{
		name: 'Instructional Text',
		type: 'comment',
		description: 'Instructional Text',
		icon: 'instruction-outline.svg'
	},
	{
		name: 'Style',
		type: 'style',
		description: 'Styles the elements',
		icon: 'instruction-outline.svg'
	},
	{
		name: 'Header',
		type: 'header',
		description: 'Top of Page Header',
		icon: 'header-outline.svg'
	},
	{
		name: 'Footer',
		type: 'footer',
		description: 'Bottom of Page Footer',
		icon: 'footer-outline.svg'
	},
	{
		name: 'Loop',
		type: 'loop',
		description: 'Loop through expressions',
		icon: 'footer-outline.svg'
	},
	{
		name: 'Citations List',
		type: 'citations_list',
		description: 'Add document citations',
		long_description: 'Add a list of used citations',
		icon: 'footer-outline.svg'
	},
	{
		name: 'Abbreviations List',
		type: 'abbreviations_list',
		description: 'Add document abbreviations',
		long_description: 'Add a list of used abbreviations',
		icon: 'footer-outline.svg'
	}
];

const validation_config = {
	/*
	 * Strict validation here means that the validator will only accept
	 * documents that KaiAlpha itself produces.  Additionally, it enables
	 * checks for other situations that are not forbidden, but are probably
	 * a bad idea.
	 */
	strict_validation: true,

	/*
	 * Extra validation performs validation beyond just the syntax validation
	 */
	extra_validation: true
};

function init(options = {}) {
	Object.assign(validation_config, options);

	/*
	 * Allow an option to disable validation
	 */
	if (validation_config.disable_validation_enforcement === undefined) {
		validation_config.disable_validation_enforcement = kaialpha.configuration.disable_validation_enforcement;
	}

	if (validation_config.disable_validation_enforcement === true) {
		validation_config.extra_validation = false;
	}

	return (validation_config);
}

function generate_schemas() {
	const ka_name_format = '^[A-Za-z0-9_]+$';
	let ka_id_format = '^[A-Za-z0-9@_-]+$';
	if (validation_config.strict_validation) {
		/*
		 * We only use UUIDs as identifiers, so for strict validation
		 * ensure we got an ID that we would have generated
		 */
		ka_id_format = '^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$';
	}

	const element_id_format_regexp = ka_id_format;
	const item_id_format_regexp = ka_id_format;
	const item_version_format_regexp = ka_id_format;
	/* XXX const user_id_format_regexp = ka_id_format; */
	const variable_name_format_regexp = ka_name_format;
	const permissions_role_name_format_regexp = variable_name_format_regexp;
	const permissions_acl_name_format_regexp = '^[A-Za-z0-9_:-]+$';
	const workflow_slot_name_format_regexp = ka_name_format;
	const workflow_action_name_format_regexp = ka_name_format;
	const workflow_switch_name_format_regexp = ka_name_format;
	const document_variable_names = "^[^\\s]*$";

	const permissions_schema = {
		type: 'object',
		properties: {
			'owners': {
				type: 'array',
				minItems: 1,
				items: {
					type: 'string'
				}
			},
			'roles': {
				description: 'Symbolic names for roles within this item, these can be used in the "acl" section of the permissions document',
				type: 'object',
				patternProperties: {
					[permissions_role_name_format_regexp]: {
						type: 'array',
						items: {
							type: 'string'
						}
					}
				},
				additionalProperties: false
			},
			'acl': {
				description: 'ACL Entries for this item, each key describes a permission',
				type: 'object',
				patternProperties: {
					[permissions_acl_name_format_regexp]: {
						type: 'array',
						items: {
							type: 'string'
						}
					}
				},
				additionalProperties: false
			},
			'inherit_from': {
				description: 'Inherit ACLs (null means explicitly do not inherit)',
				oneOf: [
					{
						type: 'null'
					},
					{
						type: 'array',
						items: {
							type: 'object',
							properties: {
								'id': {
									type: 'string',
									description: 'ID of the item to inherit ACLs from'
								},
								'type': {
									type: 'string',
									description: 'Type of item (with the specified ID) to inherit ACLs from'
								}
							},
							additionalProperties: false,
							required: ['id', 'type']
						}
					}
				]
			}
		},
		additionalProperties: false,
		required: ['owners']
	};

	const body_schema_elements = {
		template: {
			properties: {
				'id': {
					type: 'string',
					description: 'Template ID for which this element should include.',
					pattern: item_id_format_regexp,
				},
				'version': {
					type: 'string',
					description: 'Version of the template ID for which this element should include, specify "HEAD" for the latest version.',
					oneOf: [
						{ pattern: item_version_format_regexp },
						{ const: 'HEAD' }
					]
				},
				//removed pattern: variable_name_format_regexp
				'name': {
					type: 'string',
					description: 'Name of the template element within the structure of documents.  This name must be in the same format as variable names, since it will be used to form the variable tree structure.',
				},
				//removed pattern: variable_name_format_regexp
				'global_name': {
					type: 'string',
					description: 'Name of the template element within the structure of documents.  This name must be in the same format as variable names, since it will be used to form the variable tree structure.  This name will be created as a reference from the top-level of the tree of documents.',
				},
				'expression': {
					type: 'string',
					description: 'Expression that can be evaluated to find the respective templates'
				},
				'state': {
					type: 'string',
					description: 'State of template for selected template (id and version)',
				},
				'latest_approved_version': {
					type: 'string',
					description: 'Template latest approved version'
				}
			},
			required: ['name'],
			oneOf: [
				{
					required: ['expression']
				},
				{
					required: ['id', 'version']
				},
			],
			description: 'The "template" element specifies that another template should be included at this point in the document.  When instantiated to a Document, the referenced template will also be converted to a Document and referred to as a "subdocument".'
		},
		variable: {
			properties: {
				'name': {
					type: 'string',
					pattern: variable_name_format_regexp
				},
				'required': {
					type: 'boolean',
					description: 'A flag indicating whether the variable is mandatory during document creation.'
				},
			},
			required: ['name'],
			description: 'The "variable" element specifies a variable should be created with a given name, additionally it specifies that at the position within the body it should be prompted for.  The variable information, such as type of variable and description are stored in the "variables" section of the template.'
		},
		html: {
			properties: {
				'text': { type: 'string' },
				'merge': {
					type: 'object',
					properties: {
						'start': { type: 'boolean' },
						'end': { type: 'boolean' },
					},
					'description': 'Whether to merge with whitespace to start/end of this block'
				}
			},
			required: ['text'],
			description: 'The "html" element specifies a rich text which is rendered in the final output.  Any Nunjucks expression in the "html" element is replaced when rendering the Document.'
		},
		section: {
			properties: {
				'name': { type: 'string' },
				'body': {
					'$ref': '#/definitions/body'
				}
			},
			required: ['name'],
			description: 'The "section" element specifies a new numbered section within a document.  Its name is the title of that section.  The "body" attribute is a sub-body and processed the same way as the rest of the "body".'
		},
		title: {
			properties: {
				"title": { type: 'string' }
			},
			required: ['title'],
			description: 'The "title" element specifies a document title should be inserted at this position within the document.'
		},
		comment: {
			properties: {
				"text": { type: 'string' }
			},
			description: 'The "comment" element specifies a comment should be inserted at this position in the document'
		},
		header: {
			properties: {
				"value": { type: 'string' }
			},
			description: 'The "header" element specifies a document header should be set or changed at this position within the document.'
		},
		footer: {
			properties: {
				"value": { type: 'string' }
			},
			description: 'The "footer" element specifies a document footer should be set or changed at this position within the document.'
		},
		reference: {
			properties: {
				'name': {
					type: 'string',
					minLength: 1
				},
				// value of reference element itself
				'value': {
					type: 'object',
					properties: {
						'element_id': {
							type: 'string',
							pattern: element_id_format_regexp,
							description: 'The element ID to which this reference is meant to refer to'
						},
						// type of referenced element
						'type': {
							type: 'string',
						},
						'template_id': {
							type: 'string',
							pattern: item_id_format_regexp,
							description: 'The unique ID of a Template.  The template will contain the element_id.  If it is not supplied, the reference is to the current template.'
						},
						'template_version': {
							type: 'string',
							pattern: item_version_format_regexp,
							description: 'The specific version of the ID of a Template.  The template will contain the element_id.  If it is not supplied, the current version of the template will used.'
						},
						'format': {
							type: 'string',
							description: 'The format that the reference should be rendered in documents'
						}
					},
					additionalProperties: false,
					required: ['element_id']
				}
			},
			required: ['name'],
			description: 'The "reference" element specifies a reference to another element in the current template or one of its parent/child templates'
		},
		switch: {
			properties: {
				'name': {
					type: 'string',
					pattern: variable_name_format_regexp
				},
				'expression': { type: 'string' },
				'values': {
					type: 'object',
					patternProperties: {
						'^.*$': {
							type: 'object',
							properties: {
								'body': {
									'$ref': '#/definitions/body'
								}
							},
							additionalProperties: false
						}
					},
					additionalProperties: false
				},
				'default': {
					type: 'object',
					properties: {
						'body': {
							'$ref': '#/definitions/body'
						}
					},
					additionalProperties: false
				}
			},
			description: 'The "switch" element allows the user to insert logic into the document.  It evaluates the "expression" parameter at rendering time, and based on the result then uses the "body" of the matching "values" tree.  If no matching key is found, the "default" tree will be used.'
		},
		loop: {
			properties: {
				'name': {
					type: 'string',
					pattern: variable_name_format_regexp,
				},
				'expression': {
					type: 'string'
				},
				'body': {
					'$ref': '#/definitions/body'
				},
				'else': {
					type: 'object',
					properties: {
						'body': {
							'$ref': '#/definitions/body'
						}
					},
					additionalProperties: false
				}
			},
			required: ['name'],
			description: 'The "loop" element allows the user to insert logic into the document.  It evaluates the "expression" parameter at rendering time, and based on the result then uses the "body" of the element.  If no matching key is found, the "else" tree will be used.'
		}
	};

	const body_schema = {
		type: 'array',
		items: {
			type: 'object',
			patternProperties: {
				[element_id_format_regexp]: {
					type: 'object',
					properties: {
						'type': {
							type: 'string',
							enum: element_types
						},
					},
					allOf: element_types.map(function (element_type) {
						let element_schema = body_schema_elements[element_type];
						if (!element_schema) {
							element_schema = {};
						} else {
							element_schema['properties']['type'] = {
								type: 'string',
								const: element_type
							}
							element_schema['additionalProperties'] = false;
						}

						return ({
							if: {
								properties: { 'type': { const: element_type } }
							},
							then: element_schema
						});
					}),
					required: ['type']
				}
			},
			minProperties: 1,
			maxProperties: 1,
			additionalProperties: false
		},
		description: 'The "body" of a template is an ordered set of elements.  These elements will be presented to the Document author in this order, as well as rendered in the final Content following the flow of the order of this array.'
	};

	/*
	 * Comments Schema
	 */
	/**
	 ** An individual comment
	 **/
	const comment_entry_schema = {
		type: 'object',
		description: 'An individual comment entry',
		properties: {
			id: {
				type: 'string',
				description: 'The ID of the comment (not to be confused with the ID the element being referenced by the comment)',
				pattern: element_id_format_regexp
			},
			version: {
				type: 'string',
				description: 'The ID of version this comment relates to',
				pattern: item_version_format_regexp
			},
			author: {
				type: 'string',
				description: 'The User ID of the author of this comment',
				/* XXX:TODO: This is currently broken: pattern: user_id_format_regexp */
			},
			date: {
				type: 'string',
				description: 'The ISO 8601 format timestamp this comment was created',
			},
			state: {
				type: 'string',
				description: 'The state of the comment',
				enum: comment_states
			},
			lastupdated: {
				type: 'number'
			},
			comment: {
				type: 'string',
				description: 'The text of the comment'
			},
			text: {
				type: 'string',
				description: 'Element text, if any, the comment belongs to'
			},
			text_properties: {
				type: 'object',
				description: 'Properties related to the text, if any, the comment belongs to',
				properties: {
					context: {
						type: 'string',
						description: 'Contextual text that contains the comment text the comment belongs to, if applicable'
					},
					context_occurrence: {
						type: 'number',
						description: 'Identifies the occurrence of the context text within the element text, if applicable'
					},
					text_container_id: {
						type: 'string',
						description: 'container element id that contains the text the comment belongs to, if applicable'
					},
					variable_name: {
						type: 'string',
						description: 'variable name this comment was applied against'
					},
				},
				additionalProperties: false
			},
			column: {
				type: ['string', 'number'],
				description: 'Cell column, if any, the comment belongs to'
			},
			row: {
				type: ['string', 'number'],
				description: 'Cell row, if any, the comment belongs to'
			},
			tag: {
				type: 'string',
				description: 'Tag of comment'
			},
			document_wide: {
				type: 'boolean',
				description: 'A flag indicating whether the comment this text belongs to should be applied to the entire document.'
			},
			subaddress: {
				type: 'string',
				description: 'Subaddress if the comment was left on a loop iteration'
			},
			deleted: {
				type: 'boolean',
				description: 'A flag indicating comment is marked for deletion'
			},
			elementId: {
				type: 'string',
				description: '(Template only) id of element where comment was added '
			},
			parentId: {
				type: 'string',
				description: '(Template only) id of parrent where comment was added as reply '
			},
		},
		required: ['id', 'author', 'date', 'state', 'comment'],
		additionalProperties: false
	};

	/**
	 ** The comments property of a document or template (templates lack variable comments)
	 **/
	const comments_schema_document = {
		type: 'object',
		description: 'Comments on the item',
		properties: {
			variables: {
				type: 'object',
				description: 'Comments left on variables',
				patternProperties: {
					[variable_name_format_regexp]: {
						type: 'array',
						description: 'Comments left on a specific variable',
						items: comment_entry_schema
					}
				},
				additionalProperties: false
			},
			elements: {
				type: 'object',
				description: 'Comments left on elements',
				patternProperties: {
					[element_id_format_regexp]: {
						type: 'array',
						description: 'Comments left on a element (comment or document)',
						items: comment_entry_schema
					}
				},
				additionalProperties: false
			}
		},
		additionalProperties: false
	};

	const comments_schema_template = {
		type: 'array',
		description: 'Comments on the template',
		items: comment_entry_schema
	}

	/*
	 * Workflow State :  When a workflow is executed, its state (between
	 * Slot Schema    :  waiting portions as well as after exiting) is
	 *                   written to a state slot.
	 */
	const workflow_schema_slot = {
		type: 'object',
		description: 'The state for a given named slot',
		properties: {
			id: {
				type: 'string',
				pattern: item_id_format_regexp,
				description: 'The unique ID of the workflow being run in this slot.'
			},
			version: {
				type: 'string',
				pattern: item_version_format_regexp,
				description: 'The unique version ID of the workflow being run in this slot.  This plus the id uniquely identifies a concrete version of a workflow being run.'
			},
			status: {
				type: 'string',
				enum: ['running', 'exited', 'waiting'],
				description: 'The state of this workflow'
			},
			timeout: {
				type: 'object',
				description: 'If waiting, when a timeout event should be generated to terminate this operation',
				properties: {
					'start': {
						type: 'string',
						description: 'Time (in ISO8601 format) the timeout counter started'
					},
					'in': {
						type: 'string',
						description: 'How long the timeout counter lasts'
					}
				},
				additionalProperties: false
			},
			pc: {
				type: 'number',
				description: 'Which step the workflow is currently executing'
			},
			operation: {
				type: 'string',
				description: 'Name of instruction currently waiting on'
			},
			saved_context: {
				type: 'object',
				description: 'Context for the event currently waiting on',
				properties: {
					'event': {
						type: 'string',
						description: 'The name of the event which initiated this workflow execution'
					},
					'args': {
						type: 'object',
						description: 'The arguments that were passed in when initiating this workflow execution'
					}
				},
				additionalProperties: false
			},
			variables: {
				type: 'object',
				patternProperties: {
					[variable_name_format_regexp]: {
						type: ['object', 'array', 'string'],
					}
				},
				description: 'Variables and their values',
				additionalProperties: false
			},
			ui: {
				type: 'object',
				description: 'User Interface request, if waiting',
				properties: {
					'buttons': {
						type: 'array',
						description: 'Array of buttons, one of which may be clicked',
						items: {
							type: 'object'
							/* XXX:TODO */
						}
					},
					'prompts': {
						type: 'array',
						description: 'Array of prompts the user must supply values for',
						items: {
							type: 'object'
							/* XXX:TODO */
						}
					}
				},
				additionalProperties: false
			},
			previous_record: {
				type: 'array',
				description: 'List of Previous Records/Completed Tasks for Document Workflow'
			},
			completed_tasks: {
				type: 'array',
				description: 'List of all completed actions',
				items: {
					type: 'object',
					properties: {
						'who': {
							type: 'string',
							description: 'Id of User who performed operation/action'
						},
						'when': {
							type: 'string',
							description: 'Time in ISO format'
						},
						'action': {
							type: 'string',
							description: 'Name of operation/action that was completed'
						},
						'result': {
							type: 'string',
							description: 'Action result'
						},
						'review_cycle_number': {
							type: 'number',
							description: 'Review cycle when this task occurred',
							minimum: 1
						},
						'approval_cycle_number': {
							type: 'number',
							description: 'Approval cycle when this task occurred',
							minimum: 1
						},
						'comment': {
							type: 'string',
							description: 'Comment left when operation/action was completed'
						},
					},
					required: ['who', 'when', 'action'],
					additionalProperties: false
				},
			},
		},
		required: ['status'], /* XXX:TODO: id, version, and pc are required unless status is exited, so its more complicated; timeout is only applicable if waiting */
		additionalProperties: false
	};

	const workflow_state_schema = {
		type: 'object',
		description: 'Workflow slot storage',
		patternProperties: {
			[workflow_slot_name_format_regexp]: workflow_schema_slot,
		},
		additionalProperties: false
	};

	const body_extend_schema = { ...body_schema };

	const template_schema = {
		'$schema': 'http://json-schema.org/schema#',
		'$id': 'uuid:15c2ec2d-a361-4c6e-b3fc-c676f0e26c34',
		type: 'object',
		definitions: {
			'body': body_schema,
			'comments': comments_schema_template,
			'permissions': permissions_schema,
			'workflow': workflow_state_schema,
		},
		properties: {
			'id': {
				type: 'string',
				pattern: item_id_format_regexp,
				description: 'The unique ID of a Template.  This will be the primary key for accessing this template.'
			},
			'version': {
				type: 'string',
				pattern: item_version_format_regexp,
				description: 'The unique version ID of a template.  This uniquely identifies a concrete version of a template.'
			},
			'previous_version': {
				type: 'string',
				pattern: item_version_format_regexp,
				description: 'If there is a previous version ID of a template, this will identify it.  This is only used in responses, and if provided in a request to store a template will be ignored.'
			},
			'name': {
				type: 'string',
				minLength: 1,
				description: 'Human-readable name for this template.'
			},
			"lastupdated": {
				type: "number"
			},
			'state': {
				type: 'string',
				description: 'The current "State" of the document, this is used to manage reviews and the lifecycle of the document.'
			},
			'model_document': {
				type: 'string',
				description: 'Model document name',
			},
			'metadata': {
				type: 'object',
				properties: {
					'system': {
						type: 'object',
						description: 'System generated metadata',
						patternProperties: {
							[variable_name_format_regexp]: {
								type: 'string'
							}
						},
						additionalProperties: false,
					},
					'user': {
						type: 'object',
						description: 'User defined metadata',
						patternProperties: {
							[variable_name_format_regexp]: {
								type: 'string'
							}
						},
						additionalProperties: false,
					},
				},
				additionalProperties: false,
				required: ['system'],
				description: 'Metadata for this template.  Metadata is treated the same as variables, except the values are supplied at Template editing time, instead of Document editing time.'
			},
			'variables': {
				type: 'object',
				patternProperties: {
					[variable_name_format_regexp]: {
						type: 'object',
						properties: {
							'type': {
								type: 'string',
								enum: variable_types,
								description: 'Type of variable'
							},
							'description': {
								type: 'string',
								description: 'A description of this variable'
							},
							'default': {
								type: ['string', 'object'],
								description: 'A default value for this variable, must be a string unless the type is datasource' /* XXX:TODO Encode this as a validation rule */
							},
							'options': {
								type: 'object'
							},
							'required': {
								type: 'boolean',
								description: 'A flag indicating whether the variable is mandatory during document creation.'
							},
						},
						required: ['type'],
						additionalProperties: false
					}
				},
				additionalProperties: false,
				description: 'Variables are the parameters of the Template that are not known when creating it.  Instead they are supplied for a particular instance of a Document.'
			},
			'body': {
				'$ref': '#/definitions/body'
			},
			'comments': {
				'$ref': '#/definitions/comments'
			},
			'permissions': {
				'$ref': '#/definitions/permissions'
			},
			'child_resources': {
				type: 'array',
				items: {
					type: 'string',
				}
			},
			'workflow': {
				'$ref': '#/definitions/workflow'
			},
			'_archived': {
				description: 'If true, means document has been marked for deletion',
				type: 'boolean'
			}
		},
		additionalProperties: false,
		required: ['id', 'version', 'permissions']
	};

	const document_schema = {
		'$schema': 'http://json-schema.org/schema#',
		'$id': 'uuid:6d5c96c5-7c94-4caa-93ad-7fd0ea24c3f7',
		type: 'object',
		definitions: {
			'comments': comments_schema_document,
			'permissions': permissions_schema,
			'workflow': workflow_state_schema,
			'body_extend': body_extend_schema,
			'body': body_schema
		},
		properties: {
			'id': {
				type: 'string',
				pattern: item_id_format_regexp,
				description: 'The unique ID of a Document.  This will be the primary key for accessing this document.'
			},
			'version': {
				type: 'string',
				pattern: item_version_format_regexp,
				description: 'The unique version ID of a Document.  This uniquely identifies a concrete version of a document.'
			},
			'previous_version': {
				type: 'string',
				description: 'If there is a previous version ID of a document, this will identify it.  This is only used in responses, and if provided in a request to store a document will be ignored.'
			},
			'state': {
				type: 'string',
				description: 'The current "State" of the document, this is used to manage reviews and the lifecycle of the document.'
			},
			'name': {
				type: 'string',
				minLength: 1,
				description: 'Human-readable name for this document.'
			},
			'metadata': {
				type: 'object',
				properties: {
					'system': {
						type: 'object',
						description: 'System generated metadata',
						patternProperties: {
							[variable_name_format_regexp]: {
								type: 'string'
							}
						},
						additionalProperties: false,
					},
					'user': {
						type: 'object',
						description: 'User defined metadata',
						patternProperties: {
							[variable_name_format_regexp]: {
								type: 'string'
							}
						},
						additionalProperties: false,
					},
				},
				additionalProperties: false,
				description: 'Metadata for this document.'
			},
			"lastupdated": {
				type: "number"
			},
			'variables': {
				type: 'object',
				patternProperties: {
					[document_variable_names]: {
						type: ['string', 'object', 'array'],
						description: 'A default value for this variable, must be a string unless the type is datasource or type is multi_input'
					}
				},
				additionalProperties: false,
				description: 'Variables hold the mapping of variable names to values'
			},
			'template': {
				type: 'object',
				properties: {
					'id': {
						type: 'string',
						pattern: item_id_format_regexp
					},
					'version': {
						type: 'string',
						pattern: item_version_format_regexp
					},
				},
				additionalProperties: false,
				required: ['id', 'version'],
				description: 'The "template" element indicates which template ID, and which version, the document is built from.'
			},
			'superdocument': {
				type: 'object',
				properties: {
					'document_id': {
						type: 'string',
						pattern: item_id_format_regexp
					}
				},
				additionalProperties: false,
				description: 'The "superdocument" element, if present, indicates that some larger document includes the current document.'
			},
			'subdocuments': {
				type: 'object',
				patternProperties: {
					[element_id_format_regexp]: {
						type: 'object',
						properties: {
							'document_id': {
								type: 'array',
								items: {
									type: 'string',
									pattern: item_id_format_regexp
								}
							}
						},
						additionalProperties: false,
						required: ['document_id']
					}
				},
				additionalProperties: false,
				description: 'Specify the mapping of Element IDs from the "body" which contains a "template" element to a the Document ID which has been instantiated for this document tree'
			},
			'comments': {
				'$ref': '#/definitions/comments'
			},
			'permissions': {
				'$ref': '#/definitions/permissions'
			},
			'body_extend': {
				'$ref': '#/definitions/body_extend'
			},
			'workflow': {
				'$ref': '#/definitions/workflow'
			},
			'_archived': {
				description: 'If true, means document has been marked for deletion',
				type: 'boolean'
			}
		},
		additionalProperties: false,
		required: ['id', 'version', 'permissions']
	};

	const list_schema = {
		'$schema': 'http://json-schema.org/schema#',
		'$id': 'uuid:c850acea-41c6-4d70-9fe5-fcb6358d750c',
		type: 'object',
		definitions: {
			'permissions': permissions_schema
		},
		properties: {
			'id': {
				type: 'string',
				pattern: item_id_format_regexp,
				description: 'The unique ID of a List.  This will be the primary key for accessing this list.'
			},
			'version': {
				type: 'string',
				pattern: item_version_format_regexp,
				description: 'The unique version ID of a List.  This uniquely identifies a concrete version of a list.'
			},
			'previous_version': {
				type: 'string',
				description: 'If there is a previous version ID of a list, this will identify it.  This is only used in responses, and if provided in a request to store a list will be ignored.'
			},
			'name': {
				type: 'string',
				minLength: 1,
				description: 'Human-readable name for this list.'
			},
			'default': {
				type: 'boolean',
				description: 'An indication on whether this is the default list for this type or not'
			},
			'type': {
				type: 'string',
				description: 'The type of list this list belongs to -- this is internal to the system'
			},
			'entries': {
				type: 'array',
				items: {
					type: 'object',
					properties: {
						'key': {
							type: 'string',
							description: 'Key for the list item'
						},
						'value': {
							type: 'string',
							description: 'Value for the list item'
						}
					},
					additionalProperties: false,
					required: ['key', 'value']
				},
				description: 'List items'
			},
			'permissions': {
				'$ref': '#/definitions/permissions'
			}
		},
		additionalProperties: false,
		required: ['id', 'version', 'permissions', 'name', 'type', 'entries']
	};

	const workflow_schema = {
		'$schema': 'http://json-schema.org/schema#',
		'$id': 'uuid:70160e03-d7f2-476b-a363-d00f4dbf3c4b',
		type: 'object',
		definitions: {
			'permissions': permissions_schema
		},
		properties: {
			'id': {
				type: 'string',
				pattern: item_id_format_regexp,
				description: 'The unique ID of a Workflow.  This will be the primary key for accessing this workflow.'
			},
			'version': {
				type: 'string',
				pattern: item_version_format_regexp,
				description: 'The unique version ID of a Workflow.  This uniquely identifies a concrete version of a Workflow.'
			},
			'previous_version': {
				type: 'string',
				description: 'If there is a previous version ID of a workflow, this will identify it.  This is only used in responses, and if provided in a request to store a workflow will be ignored.'
			},
			'default': {
				type: 'array',
				description: 'Type of actions for which this workflow will be evaluated by default',
				items: {
					type: 'string',
					enum: kaialpha.lib.workflow_utils.constants.default_for
				}
			},
			'name': {
				type: 'string',
				minLength: 1,
				description: 'Human-readable name for this workflow.'
			},
			'actions': {
				type: 'object',
				patternProperties: {
					[workflow_action_name_format_regexp]: {
						type: 'array',
						items: {
							type: 'object',
							properties: {
								'id': {
									type: 'string'
								},
								'operation': {
									type: 'string',
									enum: workflow_utils.constants.workflow_operations
								},
								'parameters': {
									type: 'object',
									/* XXX:TODO Validate parameters based on specific operation */
								},
								'metadata': {
									type: 'object',
									description: 'Metadata for this operation',
									properties: {
										'position': {
											type: 'object',
											description: "Position of this node in workflow editor UI",
											properties: {
												x: {
													type: 'integer'
												},
												y: {
													type: 'integer'
												}
											},
											required: ['x', 'y'],
											additionalProperties: false,
										}
									}
								}
							},
							required: ['operation'],
							additionalProperties: false
						},
						description: 'A specific set of actions to perform for this named function'
					}
				},
				description: 'The actions attribute contains the names and bodies of functions contained in this workflow'
			},
			'switch': {
				type: 'object',
				patternProperties: {
					[workflow_switch_name_format_regexp]: {
						type: 'object',
						patternProperties: {
							'.*': {
								type: 'string',
								description: 'When the item associated with this workflow has met this condition, call this named action'
							}
						}
					}
				}
			},
			'permissions': {
				'$ref': '#/definitions/permissions'
			}
		},
		additionalProperties: false,
		required: ['id', 'version', 'permissions', 'name', 'actions']
	};

	const variable_definitions_schemas = {
		'datasource': {
			type: 'object',
			properties: {
				'type': {
					type: 'string'
				},
				'description': {
					type: 'string'
				},
				'required': {
					type: 'boolean'
				},
				'options': {
					type: 'object',
					description: 'Additional properties can be defined for datasource variables in templates',
					properties: {
						'source': { type: 'string' },
						'column_headers': { type: 'array' },
						'row_headers': { type: 'array' },
						'type': { type: 'string' }
					},
					required: ['source'],
					additionalProperties: false
				}
			},
			additionalProperties: false,
			required: ['type']
		},
		'dropdown': {
			type: 'object',
			properties: {
				'type': {
					type: 'string'
				},
				'description': {
					type: 'string'
				},
				'required': {
					type: 'boolean'
				},
				'options': {
					type: 'object',
					properties: {
						'items': {
							type: 'array',
							description: 'Array of items included in dropdown or list variables'
						}
					},
					additionalProperties: false
				}
			},
			additionalProperties: false,
			required: ['type']
		},
		'checkbox': {
			type: 'object',
			properties: {
				'type': {
					type: 'string'
				},
				'description': {
					type: 'string'
				},
				'required': {
					type: 'boolean'
				},
				'options': {
					type: 'object',
					properties: {
						'items': {
							type: 'array',
							description: 'Array of items included in checkbox list'
						}
					},
					additionalProperties: false
				}
			},
			additionalProperties: false,
			required: ['type']
		},
		'list': {
			type: 'object',
			properties: {
				'type': {
					type: 'string'
				},
				'description': {
					type: 'string'
				},
				'required': {
					type: 'boolean'
				},
				'options': {
					type: 'object',
					properties: {
						'items': {
							type: 'array',
							description: 'Array of items included in dropdown or list variables'
						}
					},
					additionalProperties: false
				}
			},
			additionalProperties: false,
			required: ['type']
		},
		'expression': {
			type: 'object',
			description: 'An expression can be defined for expression variables in templates',
			properties: {
				'type': {
					type: 'string'
				},
				'description': {
					type: 'string'
				},
				'required': {
					type: 'boolean'
				},
				'options': {
					type: 'object',
					properties: {
						'expression': {
							type: 'string'
						}
					}
				}
			},
			additionalProperties: false,
			required: ['type']
		},
		'default': {
			type: 'object',
			properties: {
				'description': {
					type: 'string'
				},
				'type': {
					type: 'string'
				},
				'required': {
					type: 'boolean'
				}
			},
			additionalProperties: false,
			required: ['type']
		}
	}

	const variable_values_schemas = {
		'checkbox': {
			type: 'array',
			description: 'checkbox variable values are stored as an array of strings',
			items: {
				type: 'string'
			}
		},
		'multi_input': {
			type: 'array',
			description: 'Multi_input variable values are stored as an array of objects',
			items: {
				type: 'object',
				properties: {
					'value': {
						type: 'string'
					},
					'name': {
						type: 'string'
					}
				},
				required: ['value', 'name'],
				additionalProperties: false
			}
		},
		'datasource': {
			type: 'object',
			description: 'Datasource variable values are stored as an object',
			properties: {
				'type': {
					type: 'string'
				},
				'last_updated': {
					type: 'number'
				},
				'data': {
					type: ['array', 'object'],
					items: {
						type: 'object'
					}
				},
				'@metadata': {
					type: 'object',
					properties: {
						'headers': {
							type: 'object'
						},
						'ordered_headers': {
							type: 'array'
						},
						'size': {
							description: 'If wildcard is present in datasource path, size indicates total number of matched files',
							type: 'number'
						}
					},
					additionalProperties: false
				}
			}
		},
		'reference': {
			type: 'object',
			description: 'Reference variable values are stored as an object',
			properties: {
				'format': {
					type: 'string'
				},
				'element_id': {
					type: 'string'
				},
				'template_id': {
					type: 'string'
				},
				'template_version': {
					type: 'string'
				},
				'name': {
					type: 'string'
				},
				'type': {
					type: 'string',
					enum: element_types
				},
				'value': {
					type: 'string'
				}
			},
			additionalProperties: false
		},
		'default': {
			type: 'string'
		},
		'image': {
			type: 'object',
			properties: {
				'type': {
					type: 'string'
				},
				'key': {
					type: 'string'
				}
			}
		},
	}

	const submissionContent_Schema = {
		type: 'object',
		properties: {
			'id': {
				type: 'string',
				description: 'ID of the internal content'
			},
			'version': {
				type: 'string',
				description: 'Version of the internal content'
			},
			'status': {
				type: 'string',
				description: 'Current Status of the content'
			},
			'newVersionId': {
				type: 'string',
				description: 'New Version of the internal content'
			},
			'name': {
				type: 'string',
				description: 'Name of the content item'
			},
			'versionNumber': {
				type: 'string',
				description: 'The major/minor version number of the internal content'
			},
			'status_when_added': {
				type: 'string',
				description: 'Status of the content when this was added to submission'
			},
			'internal': {
				type: 'boolean',
				description: 'Whether the content is internal or external'
			},
			'location': {
				type: 'string',
				description: 'The location of the content if external'
			}
		},
		"if": {
			"properties": {
				"internal": { "const": true }
			},
			"required": ["internal"]
		},
		"then": { "required": ["id", "version", "status_when_added"] },
		"else": {
			"if": {
				"properties": {
					"internal": { "const": false }
				},
				"required": ["internal"]
			},
			"then": { "required": ["location"] }
		},
		additionalProperties: false
	}

	const ectdModule_schema = {
		type: 'object',
		properties: {
			'moduleNumber': {
				type: 'string',
				description: 'The ectd module number'
			},
			'moduleTitle': {
				type: 'string',
				description: 'The ectd module Title'
			},
			"folderName": {
				type: 'string',
				description: 'The folder name as it appears in the extracted version'
			},
			'modules': {
				type: 'array',
				items: {
					"$ref": "#/definitions/ectdModule"
				}
			},
			'content': {
				type: 'array',
				items: {
					'$ref': '#/definitions/submissioncontent'
				}
			}
		},
		required: ['moduleNumber', 'moduleTitle'],
		additionalProperties: false
	};

	const submission_schema = {
		'$schema': 'http://json-schema.org/schema#',
		'$id': 'uuid:f7841972-ec3b-41f4-b8dd-6b493722dd3f',
		type: 'object',
		definitions: {
			'permissions': permissions_schema,
			'workflow': workflow_state_schema,
			'ectdModule': ectdModule_schema,
			'submissioncontent': submissionContent_Schema
		},
		properties: {
			'id': {
				type: 'string',
				pattern: item_id_format_regexp,
				description: 'The unique ID of a submission.  This will be the primary key for accessing this submission.'
			},
			'version': {
				type: 'string',
				pattern: item_version_format_regexp,
				description: 'The unique version ID of a submission.  This uniquely identifies a concrete version of a submission.'
			},
			"lastupdated": {
				type: "number"
			},
			'state': {
				type: 'string',
				description: 'The current "State" of the submission, this is used to manage reviews and the lifecycle of the submission.'
			},
			'name': {
				type: 'string',
				minLength: 1,
				description: 'Human-readable name for this submission.'
			},
			'extraction_error': {
				type: 'string',
				description: 'errors thrown during extraction, stringified json'
			},
			'extraction_status': {
				type: 'string',
				enum: ["Requested", "Started", "Completed", "Error"]
			},
			'extract_location': {
				type: 'string',
				description: 'location of extraction zip'
			},
			'compound': {
				type: 'string',
				minLength: 1,
				description: 'compound associated with this submission.'
			},
			'indications': {
				type: 'array',
				minItems: 0,
				items: {
					type: 'string'
				}
			},
			'regulator': {
				type: 'array',
				minItems: 1,
				items: {
					type: 'string'
				}
			},
			'ectdModules': {
				type: 'array',
				items: {
					'$ref': '#/definitions/ectdModule'
				}
			},
			'permissions': {
				'$ref': '#/definitions/permissions'
			},
			'_archived': {
				description: 'If true, means document has been marked for deletion',
				type: 'boolean'
			}
		},
		additionalProperties: false,
		required: ['id', 'version', 'permissions', 'name', 'compound', 'indications']
	}

	return ({
		document: document_schema,
		template: template_schema,
		list: list_schema,
		workflow: workflow_schema,
		workflow_slot: workflow_schema_slot,
		variable_definitions: variable_definitions_schemas,
		variable_values: variable_values_schemas,
		comment_entry: comment_entry_schema,
		comments_document: comments_schema_document,
		comments_template: comments_schema_template,
		submission: submission_schema,
		body: {
			'$schema': 'http://json-schema.org/schema#',
			'$id': 'uuid:8f3180cf-affe-434c-b44f-d7ef00e2017d',
			definitions: {
				'body': body_schema,
			},
			...body_schema
		}
	});
}

function generate_schema(type) {
	const schemas = generate_schemas();
	const schema = schemas[type];

	return (schema);
}

/*
 * Helper functions for common things
 */
/**
 ** Determine if an array has any duplicate elements
 **/
function array_has_duplicates(array) {
	const new_array = [...(new Set(array))];
	if (array.length === new_array.length) {
		return (false);
	}

	return (true);
}

/*
 * Cache the generated validators for the various types of known schemas
 */
const validators = {};
const validation_options = {
	allowUnionTypes: true
};

function validate_against_object_of_schemas(type, options) {
	if (!validators[type]) {
		validators[type] = {};
	}

	const schema = generate_schema(type);

	let key = options.schema_key;
	if (!schema[options.schema_key]) {
		key = 'default';
	}

	if (!validators[type][key]) {

		const validator = new json_schema(validation_options);
		validators[type][key] = validator.compile(schema[key]);
	}

	const schema_validator = validators[type][key];
	return (schema_validator);
}

function validate_against_schema(type, record, options = {}) {
	options = {
		debug: true,
		schema_key: undefined,
		...options
	}

	if (validation_config.disable_validation_enforcement === true) {
		return ([]);
	}

	let schema_validator;
	if (options.schema_key) {
		/*
		 * If an object of schemas is passed in, we need use the object's key to
		 * get the schema for being validated against
		 */
		schema_validator = validate_against_object_of_schemas(type, options);
	} else {
		if (!validators[type]) {
			/*
			 * Generate the schema for this type of record
			 */
			const schema = generate_schema(type);

			const validator = new json_schema(validation_options);
			validators[type] = validator.compile(schema);
		}

		schema_validator = validators[type];
	}

	const result = schema_validator(record);

	if (!result) {
		const schema = generate_schema(type);

		if (options.debug === true) {
			kaialpha.log.debug(`Validation Schema for ${type}: ${JSON.stringify(schema, undefined, 4)}`);
			kaialpha.log.debug(`Validation of ${type} record failed:`, JSON.stringify(record, undefined, 4), '; because:', schema_validator.errors);
		}

		return (schema_validator.errors);
	}

	return ([]);
}

async function validate_template(user_id, check_template, options = {}) {
	options = {
		get_user_template: kaialpha.lib.template.get_user_template,
		...options
	}

	/*
	 * Validate that the syntax is correct
	 */
	const syntax_correct = validate_against_schema('template', check_template, options);
	if (syntax_correct.length !== 0) {
		return (syntax_correct);
	}

	if (validation_config.extra_validation) {
		/*
		 * Validate that no body element IDs are duplicated
		 */
		const all_element_ids = document_utils.body_element_ids(check_template.body);
		if (array_has_duplicates(all_element_ids)) {
			return ([`Duplicate Body Element IDs: ${JSON.stringify(all_element_ids)}`]);
		}

		/*
		 * Validate that variable values are acceptable for each variable and their type
		 */
		const template_variables = check_template.variables;
		for (const variable_name in template_variables) {
			const variable_definition = template_variables[variable_name];
			const type = variable_definition.type;

			const syntax_correct = validate_against_schema('variable_definitions', variable_definition, { schema_key: type });

			if (syntax_correct.length !== 0) {
				return ([`Error validating structure of variable ${variable_name}. Error: ${JSON.stringify(syntax_correct)}`]);
			}
		}

		/*
		 * Validate that no variable elements are duplicated -- or do we care ?
		 * It's not strictly forbidden, but it's probably a bad idea right now
		 */
		if (validation_config.strict_validation) {
			const all_variable_elements = document_utils.body_by_element_tag('variable', check_template.body);
			const all_variable_names = []
			for (const element of all_variable_elements) {
				all_variable_names.push(element.contents.name.toLowerCase());
			}
			if (array_has_duplicates(all_variable_names)) {
				return ([`Duplicate variable Body Elements found: ${JSON.stringify(all_variable_elements)}`]);
			}
		}

		/*
		 * Validate that every template specified exists
		 */
		const all_template_elements = document_utils.body_by_element_tag('template', check_template.body);
		for (const element of all_template_elements) {
			if (element.contents.id !== undefined && element.contents.version !== undefined) {
				const template_id = element.contents.id;
				const template_version = element.contents.version;
				const template = await options.get_user_template(user_id, template_id, template_version);
				if (template.id !== template_id) {
					return ([`Fetched referenced template and got differing ID expected: ${template_id}, got: ${JSON.stringify(template)}`]);
				}
			}
		}
	}

	/*
	 * Validate the rendered version of this template
	 */
	if (options.skip_parse_check !== true) {
		const template_body = kaialpha.lib.document_utils.body_serialize(undefined, check_template);
		const template_nunjucks = await kaialpha.lib.generator_utils.generateNunjucksValueFromBody(template_body, {
			validate_elements: false,
			recurse_into_templates: false,
			user_id: user_id,
			section_map: document_utils.generate_numbering(
				"0",
				{},
				template_body,
				0,
				false
			)
		}, kaialpha.lib.generator.mapping_function.default);
		const template_nunjucks_str = template_nunjucks.join('\n');
		const template_nunjucks_parseable = kaialpha.lib.nunjucks_utils.validateString(template_nunjucks_str);
		if (!template_nunjucks_parseable) {
			return ([`Failed to parse rendered template`]);
		}
	}

	return ([]);
}

async function validate_document(user_id, check_document, options = {}) {
	options = {
		get_user_template_from_document: kaialpha.lib.document.get_user_template_from_document,
		...options
	};

	/*
	 * Validate that the syntax is correct
	 */
	const syntax_correct = validate_against_schema('document', check_document, options);
	if (syntax_correct.length !== 0) {
		return (syntax_correct);
	}

	if (validation_config.extra_validation) {
		/*
		 * Validate that template specified exists
		 */
		let template = null;
		if (check_document.template) {
			template = await options.get_user_template_from_document(user_id, check_document);

			if (!template) {
				return ([`Template could not be found from document`]);
			}
		}
		/*
		 * Validate that no body element IDs are duplicated
		 */
		const body = document_utils.body_serialize(check_document, template);
		const all_element_ids = document_utils.body_element_ids(body);
		if (array_has_duplicates(all_element_ids)) {
			return ([`Duplicate Body Element IDs: ${JSON.stringify(all_element_ids)}`]);
		}

		/*
		 * Validate that subdocuments map to elements that exist
		 */
		const all_template_elements = document_utils.body_by_element_tag('template', body);
		const all_template_elements_map = {};
		for (const element of all_template_elements) {
			all_template_elements_map[element.id] = element.contents;
		}
		if (check_document.subdocuments) {
			for (const element_id of Object.keys(check_document.subdocuments)) {
				if (!all_template_elements_map[element_id]) {
					return ([`Subdocument with element ID ${element_id} not found in body: ${JSON.stringify(all_template_elements_map, undefined, 4)}`]);
				}
			}
		}

		/*
		 * Validate that all template elements have subdocument entries,
		 * if it's an expression, be more relaxed on that requirement
		 */
		for (const element of all_template_elements) {
			if (!check_document.subdocuments[element.id]) {
				if (element.contents.expression !== undefined) {
					continue;
				}
				return ([`Template Element with element ID ${element.id} not found in subdocuments: ${JSON.stringify(all_template_elements, undefined, 4)}`]);
			}
		}

	}

	return ([]);
}

async function validate_list(user_id, check_list) {
	if (check_list.type === undefined) {
		return ([`Invalid type (no type)`]);
	}

	if (!kaialpha.lib.list_utils.known_lists.includes(check_list.type)) {
		return ([`Invalid type: ${check_list.type}`]);
	}

	const syntax_correct = validate_against_schema('list', check_list);
	if (syntax_correct.length !== 0) {
		return (syntax_correct);
	}

	return ([]);
}

async function validate_workflow(user_id, check_workflow) {
	const syntax_correct = validate_against_schema('workflow', check_workflow);
	if (syntax_correct.length !== 0) {
		return (syntax_correct);
	}

	return ([]);
}

async function validate_document_comment(user_id, check_comment) {
	const syntax_correct = validate_against_schema('comments_document', check_comment);
	if (syntax_correct.length !== 0) {
		return (syntax_correct);
	}

	return ([]);
}

async function validate_template_comment(user_id, check_comment) {
	const syntax_correct = validate_against_schema('comments_template', check_comment);
	if (syntax_correct.length !== 0) {
		return (syntax_correct);
	}

	return ([]);
}

if (_testing) {
	const datasource_variable_definition = {
		type: 'datasource',
		options: {
			source: 'ka://test/data_one.csv',
			type: 'auto',
			column_headers: ['a'],
			row_headers: ['b']
		}
	};

	const text_variable_definition = {
		description: 'This is a text variable.',
		type: 'text'
	};

	const multi_input_variable_definition = {
		type: 'multi_input'
	};

	const expression_variable_definition = {
		type: 'expression',
		description: 'This is an expression variable',
		options: {
			expression: '1 + 1'
		}
	}

	const reference_variable_definition = {
		type: 'reference'
	}

	const template = {
		"name": "Demo Template ",
		"id": "8c6ab078-c592-43bb-a62b-aa6dc291b96a",
		"version": "9ce2b4cb-af90-4cba-8218-453c854b35fd",
		"variables": {
			"DSVar1": datasource_variable_definition,
			"TextVar1": text_variable_definition,
			"MIVar1": multi_input_variable_definition,
			"ExpVar1": expression_variable_definition,
			"RefVar1": reference_variable_definition
		},
		"body": [
			{
				"f8fbf596-ae3b-4c3e-bcd8-8e577a2952dc": {
					"type": "section",
					"name": "This is a section ",
					"body": [
						{
							"e54f09e1-a020-4ab5-b795-a807f7958157": {
								"type": "html",
								"text": "<p>This is a html</p>"
							}
						}
					]
				}
			},
			{
				"87d4d4c6-9a13-4344-81af-ef56433c9f3e": {
					"type": "html",
					"text": "test"
				}
			}
		],
		"permissions": {
			"owners": [
				"f620bdb1-4721-418a-8bd3-734d9cab1499"
			]
		},
		"previous_version": "2f75a454-5767-4b26-85de-a2f3bbe65f55"
	}

	const test_document = {
		id: "757f2de9-f930-47ff-abdb-b3bf9e5dbc1c",
		version: "cac2bf0d-0f0d-4ce6-b2e7-b0f7ef381e63",
		template: {
			id: template.id,
			version: template.version
		},
		"permissions": {
			"owners": [
				"f620bdb1-4721-418a-8bd3-734d9cab1499"
			]
		},
	};

	const multi_input_variable_value = [
		{
			name: 'Multi input name 1',
			value: 'Multi input value 1'
		},
		{
			name: 'Multi input name 2',
			value: 'Multi input value 2'
		}
	]

	const text_variable_value = 'some_value';

	const reference_variable_value = {
		element_id: 'd6b980b7-1204-4630-b26c-adfa48feb64a',
		format: '{{value}}',
		type: 'html',
		value: '<p>This is a test</p>'
	}

	_testing.validate_variable_definition_with_options = async function () {
		const syntax_correct = validate_against_schema('variable_definitions', datasource_variable_definition, { schema_key: 'datasource' });

		if (syntax_correct.length !== 0) {
			throw new Error("Validation Error: Error validating variable definition with 'options' object");
		}

		return true;
	}

	_testing.validate_variable_definition_default = async function () {
		const syntax_correct = validate_against_schema('variable_definitions', text_variable_definition, { schema_key: 'default' });

		if (syntax_correct.length !== 0) {
			throw new Error("Validation Error: Error validating default variable definition");
		}

		return true;
	}

	_testing.validate_variable_value = async function () {
		const syntax_correct = validate_against_schema('variable_values', multi_input_variable_value, { schema_key: 'multi_input' });

		if (syntax_correct.length !== 0) {
			throw new Error("Validation Error: Error validating non-default variable value");
		}

		return true;
	}

	_testing.validate_variable_value_default = async function () {
		const syntax_correct = validate_against_schema('variable_values', text_variable_value, { schema_key: 'default' });

		if (syntax_correct.length !== 0) {
			throw new Error("Validation Error: Error validating default variable value");
		}

		return true;
	}

	_testing.validate_reference_value = async function () {
		const syntax_correct = validate_against_schema('variable_values', reference_variable_value, { schema_key: 'reference' });

		if (syntax_correct.length !== 0) {
			throw new Error("Validation Error: Error validating reference variable value");
		}

		return true;
	}

	_testing.validate_against_schema_document_variables = async function () {
		const checks = [
			{
				/*
				 * This should fail validation because there's no variable named "foo"
				 */
				variables: {
					"foo": "bar"
				},
				valid: false
			},
			{
				/*
				 * This should pass validation because there's a variable named "TextVar1"
				 * and it is of type string
				 */
				variables: {
					"TextVar1": "example"
				},
				valid: true
			},
			{
				/*
				 * This should fail validation because there's a variable named "TextVar1"
				 * and it is of type string (not array)
				 */
				variables: {
					"TextVar1": []
				},
				valid: false
			},
			{
				/*
				 * This should pass validation because there's a variable named "DSVar1"
				 * and it is of type object
				 */
				variables: {
					"DSVar1": {
						"type": "columns",
						"data": [{}],
						"@metadata": {
							"headers": {},
							'ordered_headers': []
						}
					}
				},
				valid: true
			},
			{
				/*
				 * This should fail validation because there's a variable named "DSVar1"
				 * and it is of type object (not string)
				 */
				variables: {
					"DSVar1": 'example'
				},
				valid: false
			},
			{
				/*
				 * This should pass validation because there's a variable named "DSVar1"
				 * and it is of type object but should not have additional properties
				 */
				variables: {
					"DSVar1": {
						"type": "columns",
						"data": [{}],
						"@metadata": {
							"headers": {},
							"ordered_headers": []
						},
						"additional_property": "example"
					}
				},
				valid: true
			},
			{
				/*
				 * This should fail validation because there's a variable named "DSVar1"
				 * and its "@metadata" property is of type object (not array)
				 */
				variables: {
					"DSVar1": {
						"type": "columns",
						"data": [{}],
						"@metadata": []
					}
				},
				valid: false
			},
			{
				/*
				 * This should pass validation because there's a variable named "DSVar1"
				 * and its data property can be an array or object
				 */
				variables: {
					"DSVar1": {
						"type": "columns",
						"data": {},
						"@metadata": {
							"headers": {},
							'ordered_headers': []
						}
					}
				},
				valid: true
			},
			{
				/*
				 * This should pass validation because there's a variable named "MIVar1"
				 * and its type is an array of objects
				 */
				variables: {
					"MIVar1": [{
						"name": "multi_input_name_one",
						"value": "multi_input_value_one"
					}]
				},
				valid: true
			},
			{
				/*
				 * This should fail validation because there's a variable named "MIVar1"
				 * and it is of type array (not object)
				 */
				variables: {
					"MIVar1": {}
				},
				valid: false
			},
			{
				/*
				 * This should fail validation because there's a variable named "MIVar1"
				 * and it is an array of objects and each object requires a "name" and
				 * "value" key
				 */
				variables: {
					"MIVar1": [{
						"name": "multi_input_name_one",
					}]
				},
				valid: false
			},
			{
				/*
				 * This should pass validation because there's a variable named "ExpVar1"
				 * and it is a string
				 */
				variables: {
					"ExpVar1": "1 + 1"
				},
				valid: true
			},
			{
				/*
				 * This should pass validation because there's a variable named "RefVar1"
				 * and it is an object
				 */
				variables: {
					"RefVar1": {}
				},
				valid: true
			},
			{
				/*
				 * This should fail validation because there's a variable named "RefVar1"
				 * and it is an object
				 */
				variables: {
					"RefVar1": []
				},
				valid: false
			},
			{
				/*
				 * This should pass validation because there's a variable named "RefVar1"
				 * and it is an object with the following properties
				 */
				variables: {
					"RefVar1": {
						format: '{{value}}',
						element_id: 'element_id',
						type: 'section',
						value: 'Section 1.1'
					}
				},
				valid: true
			},
			{
				/*
				 * This should fail validation because there's a variable named "RefVar1"
				 * and its type parameter should be a valid element type
				 */
				variables: {
					"RefVar1": {
						format: '{{value}}',
						element_id: 'element_id',
						type: 'tableofcontents',
						value: 'Section 1.1'
					}
				},
				valid: false
			},
			{
				/*
				 * This should fail validation because there's a variable named "RefVar1"
				 * and it should not have additional properties
				 */
				variables: {
					"RefVar1": {
						format: '{{value}}',
						element_id: 'element_id',
						type: 'section',
						value: 'Section 1.1',
						additional_property: false
					}
				},
				valid: false
			}
		];

		for (const check of checks) {
			const document = {
				...test_document,
				variables: check.variables
			};

			const result = await validate_document('@system', document, {
				get_user_template_from_document: async function (_ignored_user_id, document) {
					if (document.template.id === template.id && document.template.version === template.version) {
						return (template);
					}
				}
			});

			if (!Array.isArray(result)) {
				throw (new Error(`When validating document we got: ${JSON.stringify(result)} which is a ${typeof (result)} but expected an Array`));
			}

			let is_valid = false;
			if (result.length === 0) {
				is_valid = true;
			}

			/* istanbul ignore if */
			if (is_valid !== check.valid) {
				throw (new Error(`When validating a document with variables "${JSON.stringify(check.variables)}" against schema, we got the validity as ${is_valid} but expected ${check.valid}: ${JSON.stringify(result)}`));
			}
		}

		return true;
	};

	_testing.validate_against_schema_template = async function () {
		const result = await validate_template('@system', template);

		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error(`Validation Error please add valid template: ${JSON.stringify(result)}`);
		}

		return true;
	}

	_testing.validate_template_parse_errors = async function () {
		const temp_template = object_utils.copy_object(template);
		const html_element = temp_template.body.find(function (element_info) {
			const element_id = Object.keys(element_info)[0];
			const element = element_info[element_id];
			if (element.type !== 'html') {
				return (false);
			}

			return (true);
		});

		html_element[Object.keys(html_element)[0]].text = 'Parse error: {%-';

		const result = await validate_template('@system', temp_template);

		/* istanbul ignore if */
		if (result.length !== 1) {
			throw (new Error('When validating template with parse error, we did not get any errors'));
		}

		return (true);
	}

	_testing.validate_against_schema_template_without_id = function () {
		const temp_template = object_utils.copy_object(template);
		delete temp_template['id'];

		const result = validate_against_schema('template', temp_template);

		/* istanbul ignore if */
		if (result[0].params.missingProperty !== 'id') {
			throw new Error("Id not specified should be thrown")
		}

		return true;
	}

	_testing.validate_against_schema_document = function () {
		const document = object_utils.copy_object(template);
		document['body_extend'] = template.body;
		delete document['body'];
		delete document['metadata'];
		const result = validate_against_schema('document', document);

		/* istanbul ignore if */
		if (result.length !== 0) {
			throw new Error("Validation Error please add valid document");
		}

		return true;
	}

	_testing.validate_against_schema_document_without_id = function () {
		const document = object_utils.copy_object(template);
		document['body_extend'] = template.body;
		delete document['body'];
		delete document['metadata'];
		delete document['id']
		const result = validate_against_schema('document', document);

		/* istanbul ignore if */
		if (result[0].params.missingProperty !== 'id') {
			throw new Error("Id not specified should be thrown")
		}

		return true;
	}

	_testing.init_test = function () {
		const result = init({ 'something': true });

		/* istanbul ignore if */
		if (!(result.strict_validation && result.extra_validation && result.something)) {
			throw new Error("Expected params in validation config which is missing");
		}
		return true;
	}

	_testing.array_has_duplicates = function () {
		const arr = ['a', 'b', 'c'];
		const result = array_has_duplicates(arr);

		/* istanbul ignore if */
		if (result) {
			throw new Error("Array does not have duplicates");
		}
		return true;
	}

	_testing.array_has_duplicates_with_duplicates = function () {
		const arr = ['a', 'b', 'b'];
		const result = array_has_duplicates(arr);

		/* istanbul ignore if */
		if (!result) {
			throw new Error("Array has duplicated but not found in logic");
		}
		return true;
	}
}

const _to_export_auto = {
	init,
	validate_document,
	validate_template,
	validate_list,
	validate_workflow,
	validate_document_comment,
	validate_template_comment,
	validate_against_schema,
	generate_schemas,
	generate_schema,
	structure: {
		variable_types,
		element_types,
		element_info,
		element_have_bodies: [
			'section',
			'switch',
			'loop'
		]
	},
	_testing
}
export default _to_export_auto;
