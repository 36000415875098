import { withStyles } from '@mui/styles'; // LEGACY
import React from 'react';
import FancyTablePaginationActions from './FancyTablePaginationActions';
import FancyTablePaginationRowsPerPage from './FancyTablePaginationRowsPerPage';

function styles() {
	return {
		root: {
			display: 'flex',
			boxSizing: 'border-box',
			borderBottom: '0px',
			color: '#7F7F7F',
			padding: '1rem 0'
		}
	};
}

function FancyTablePagination(props) {
	const {
		count,
		onChangePage,
		onPageChange,
		onChangeRowsPerPage: onChangeRowsPerPageProp,
		onRowsPerPageChange: onRowsPerPageChangeProp,
		page,
		rowsPerPage,
		rowsPerPageOptions,
		icons,
		classes,
	} = props;

	const onChangeRowsPerPage = onChangeRowsPerPageProp || onRowsPerPageChangeProp;

	return(
		<footer className={classes.root}>
			<FancyTablePaginationRowsPerPage
				onChangeRowsPerPage={onChangeRowsPerPage}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={rowsPerPageOptions}
			/>
			<FancyTablePaginationActions
				count={count}
				onChangePage={onChangePage}
				onPageChange={onPageChange}
				page={page}
				rowsPerPage={rowsPerPage}
				icons={icons}
			/>
		</footer>
	);
}

export default withStyles(styles)(FancyTablePagination);