import {Variable} from "../../../../../../shared/interfaces/Variable";
import {useState} from "react";
import {BaseModal} from "../../../../../../shared/components/modals/BaseModal";
import {Button, FlexBox} from "../../../../../../shared/components";
import {variableEditorForType} from "../editor";

type RequiredVariablesModalProps = {
	variables: Variable[],
	onUpdateVariables: (variables: Variable[]) => void,
	onClose: () => void,
}
type UpdatedVariableRecord = {
	namespace: string,
	value: unknown
}


function generateNamespace(variable: Variable) {
	return `${variable.namespace}.${variable.name}`;
}
const RequiredVariablesModal = (props: RequiredVariablesModalProps) => {
	const [updatedVariables, setUpdatedVariables] = useState<UpdatedVariableRecord[]>([]);
	const variableEditorLookup = props.variables
		.map(variable => ({value: variable, Editor: variableEditorForType(variable.type)}))

	const onChangeFactory = (variable: Variable) => (newValue: unknown) => {
		const newCollection = [...updatedVariables];
		const variableNamespace = generateNamespace(variable);
		const existingIndex = updatedVariables.findIndex(uv => uv.namespace === variableNamespace);
		if (existingIndex > -1) {
			newCollection[existingIndex].value = newValue;
		} else {
			newCollection.push({namespace: variableNamespace, value: newValue});
		}

		setUpdatedVariables(newCollection);
	}

	const getUpdatedValueOrDefault = (variable: Variable) => {
		const variableNamespace = generateNamespace(variable);
		const updatedVariable = updatedVariables.find(uv => uv.namespace === variableNamespace);
		return updatedVariable?.value ?? variable.value;
	}
	const onSubmit = () => {
		const variableCollection = props.variables.map(variable => ({...variable, value: getUpdatedValueOrDefault(variable)}));
		props.onUpdateVariables(variableCollection);
	}

	return <BaseModal visible={true} onClose={props.onClose} title={"Required Variables"} buttonRow={<>
		<Button text={"Cancel"} styling={"cancel"} onClick={props.onClose} />
		<Button buttonType={"default"} text={"Submit"} onClick={onSubmit} />
	</>} content={<FlexBox direction={"column"} width={"100%"}>
		{variableEditorLookup.map(variable => variable.Editor ? <div style={{margin: "1rem 0", width: "100%"}}><variable.Editor label={variable.value.namespace} variable={{...variable.value, value: getUpdatedValueOrDefault(variable.value)}} onChange={onChangeFactory(variable.value)} onClose={props.onClose} /></div> : undefined)}
	</FlexBox>} />
}

export {RequiredVariablesModal}