import {styled} from "@mui/material";
import {FlexBox} from "../../../../../../shared/components";
import {backgroundColorMap} from "./colorMaps";
import {LabelProps} from "./model";


export const LabelPaper = styled(FlexBox)<LabelProps>((props) => ({
	background: backgroundColorMap[props.status],
	padding: "1rem",
	boxSizing: "border-box",
	borderRadius: ".5rem",
	boxShadow: "0px 1px 3px #42918280",
	width: "calc(100% - 4px)",
	margin: "1.5rem 2px",
	cursor: "pointer"
}))