import {StateLabel} from "./StateLabel";
import {WorkflowStates} from "../../../../interfaces/WorkflowState";
import DateFnsUtils from "@date-io/date-fns";
import {mainThemeColorForDocumentState} from "./style";
import {useTheme} from "@mui/material";
import {VersionType} from "./models";
import { StatusIndicator } from "./StatusIndicator";
import {FlexBox} from "../../../layout";

const dateAdapter = new DateFnsUtils();
export interface  ListItemTitleProps {
	version: VersionType | null,
	majorVersionNumber: number,
	minorVersionNumber: number,
	onClick: (version: VersionType) => void
}
const ListItemTitle = ({version, majorVersionNumber, minorVersionNumber, onClick}: ListItemTitleProps) => {
	const theme = useTheme();
	const backgroundColor = mainThemeColorForDocumentState(theme, version?.state);
	const labelText = version?.state == null ? "Unpublished" : version!.state!.substring(0, 1).toUpperCase() + version!.state!.substring(1);


	const onHandleClick = () => onClick(version!);
	return <div className="list__item" onClick={onHandleClick} data-value={version?.version}>
		<div className="list__item-title">
			{version?.state !== WorkflowStates.Approved && <StatusIndicator backgroundColor={backgroundColor} />}
			<span className="number">{(majorVersionNumber || 0)}.{minorVersionNumber}</span>
			<StateLabel backgroundColor={backgroundColor}>{labelText}</StateLabel>
		</div>
		{version?.date && <FlexBox direction={"column"} align={"flex-end"} width={"auto"}>
			<span className={"date"}>{dateAdapter.formatByString(version.date, "MM dd yyyy")}</span>
			<span className={"date"}>{dateAdapter.formatByString(version.date, "HH:mm")}</span>
		</FlexBox>}
	</div>
}

export {ListItemTitle};