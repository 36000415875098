import React from "react";
import "./styles.scss";

export type NumberedControlBoxProps = {
	number: number,
	title: string,
	children: React.ReactNode,
	enabled: boolean
}
const NumberedControlBox = (props: NumberedControlBoxProps) => {
	return <div className={`scl__ncb ${props.enabled ? "enabled" : ""}`}>
		<div className={"scl__ncb--header"}>
			<span className={"scl__ncb--header--number"}>{props.number}</span>
			<h2 className={"scl__ncb--header--title"}>{props.title}</h2>
		</div>
		<div className={"scl__ncb--children"}>
			{props.children}
		</div>
	</div>
}

export {NumberedControlBox}