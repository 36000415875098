import {Button, FlexBox, useStatusBar} from "../../../../../../shared/components"
import {Autocomplete, Box} from "@mui/material";
import {TextField} from "../../../../../../shared/components/input";
import {metaDataKeys} from "./typedValueEditors/model";
import {ChangeEvent, SyntheticEvent, useState} from "react";
import {defaultValueForMetaData, valueEditorViewForMetaData} from "./typedValueEditors";
import {LinkIcon} from "../../../../../../shared/components/layout/groups/metadata/MetadataInput.style";
import {MetaDataChangeEvent} from "../../../../../../shared/components/layout/groups/metadata";
import {MetadataItem} from "../../../../../../shared/interfaces/Metadata";

type MetaDataInputProps = {
	onCancel: () => void,
	metaData?: MetadataItem,
	onSave: (event: MetaDataChangeEvent) => void
}
const MetaDataInput = (props: MetaDataInputProps) => {
	const [key, setKey] = useState<string | undefined>(props.metaData?.key);
	const [value, setValue] = useState<string | undefined>(props.metaData?.value);
	const statusBar = useStatusBar();

	const onChangeKey = (event: SyntheticEvent<Element, Event>, newKey: string, reason: string) => {
		setKey(newKey);
		setValue(undefined);
	}

	// enables the user to enter a key that doesn't exist in the auto suggest list
	const onManualKeyChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setKey(event.target.value);
		setValue(undefined);
	}
	const onChangeValue = (newValue: string) => setValue(newValue)
	const ValueEditor = valueEditorViewForMetaData(key ?? "");
	const defaultEditorValue = defaultValueForMetaData(key ?? "");

	const onSaveClick = () => {
		if (key && value) {
			props.onSave({previousMetadata: props.metaData, metadata: {key, value}});
			return;
		}

		statusBar.sendErrorNotification({message: "Error: unable to save metadata", detail: "Metadata items must have a key and a value"});

	}

	return <FlexBox direction="column" width={"100%"}>
		<Box padding="10px 0px" width={"100%"}>
			<FlexBox align="center" justify="space-between" width={"100%"}>
				<Autocomplete sx={{width: "100%"}} value={key} disableClearable onChange={onChangeKey} freeSolo={true} renderInput={params => <TextField label={"Key"} {...params} onChange={onManualKeyChange} />} options={metaDataKeys} />
				<LinkIcon/>
				<ValueEditor sx={{width: "100%"}} data={{value: value ?? defaultEditorValue}} onChange={onChangeValue} />
			</FlexBox>
		</Box>
		<Box padding="10px 0px 20px 0px">
			<FlexBox align="center">
				<Button styling="cancel" text="Cancel" onClick={props.onCancel} />
				<Box margin="0px 5px"/>
				<Button text="Save" disabled={key === ''} onClick={onSaveClick}/>
			</FlexBox>
		</Box>
	</FlexBox>
}

export {MetaDataInput}