import {Button, FlexBox, NumberedControlBox} from "../../../../../shared/components";
import {Add} from "@mui/icons-material";

export type EctdSelectionBoxProps = {
	enabled: boolean,
	onAddEctdStructure: () => void
}
const EctdSelectionBox = (props: EctdSelectionBoxProps) => {
	return <NumberedControlBox number={3} title={"Select Required eCTD Sections"} enabled={props.enabled}>
		<FlexBox align={"center"} justify={"center"} sx={{margin: "1rem 0 3rem 0"}}>
			<Button buttonType={props.enabled ? "default" : "disabled"} icon={<Add fontSize="small" key={"select-ectd"}/>} text="Select from eCTD Builder" onClick={props.onAddEctdStructure} />
		</FlexBox>
	</NumberedControlBox>
}

export {EctdSelectionBox}