import React from 'react';
import nunjucks_utils from '../../lib/utils/nunjucks_utils';
import {Box, Typography} from "@mui/material";

class DebugConsole extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {};
	}

	compute_debug_console_output() {
		const input = this.state.debug_console_input;
		let output;
		let error = false;

		if (this.state.debug_console_input === undefined) {
			return({
				output: '',
				error
			});
		}

		try {

			// nunjucks uses lowercase variables
			const variables = Object.keys(this.props.nunjucksContext ?? {}).reduce((all, current) => {
				all[current.toLowerCase()] = this.props.nunjucksContext[current];
				return all;
			}, {});

			output = nunjucks_utils.renderString(input, variables);

			if (output.length === 0) {
				output = <pre>Expression returned no results</pre>;
			}
		} catch (render_error) {
			output = <pre>ERROR: {String(render_error)}</pre>;
			error = true;
		}

		return({
			output,
			error
		});
	}

	render() {
		const debug_console_output_info = this.compute_debug_console_output();
		const debug_console_output = debug_console_output_info.output;

		return(<Box display={"flex"} flexDirection={"column"}>
			<Box display={"flex"} flexDirection={"column"}>
				<Typography variant={"subtitle1"} sx={{mb: ".5rem", fontSize: ".875rem", color: "#7F7F7F"}}>Debug input:</Typography>
				<textarea style={{width: "100%", borderColor: "#7070702f", borderRadius: ".375rem"}} cols={60} rows={10} name='debug_code' onChange={(event) => {
					this.debug_console_input = event.target.value;
					if (this.debug_console_input_update !== undefined) {
						clearTimeout(this.debug_console_input_update);
					}

					this.debug_console_input_update = setTimeout(() => {
						this.debug_console_input_update = undefined;
						this.setState({
							debug_console_input: this.debug_console_input
						});
					}, 1000);
				}}/>
			</Box>
			<Box display={"flex"} flexDirection={"column"}>
				<Typography variant={"subtitle1"} sx={{mb: ".5rem", mt: ".5rem", fontSize: ".875rem", color: "#7F7F7F"}}>Debug output:</Typography>
				<Box sx={{wordBreak: "break-all", width: "100%"}}>
					{debug_console_output}
				</Box>
			</Box>
		</Box>);
	}
}

export default DebugConsole;