import {TemplateElementDefinition} from "../model";
import {ImageIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {
	imageDefinition as baseImageDefinition
} from "../../../../../shared/definitions/elements/image/model";

const imageDefinition: TemplateElementDefinition = {
	...baseImageDefinition,
	icon: ImageIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView
	},
} as const

export {imageDefinition};