import {StylePropertyFactory} from "./model";
import {
	CellHeight,
	CellTextAlign,
	CellWidth,
	HeaderHeight, HeaderTextAlign,
	HeaderWidth,
	StyleAttribute
} from "../../../editor/elements/styles";

type SupportedAttributes = CellWidth | CellHeight | HeaderWidth | HeaderHeight | CellTextAlign | HeaderTextAlign
type CellStyles = CellWidth | CellHeight | CellTextAlign;
type HeaderStyles = HeaderHeight | HeaderWidth | HeaderTextAlign;
type MappedAttributes = {
	cellStyles: CellStyles,
	headerStyles: HeaderStyles
}

function mapCellStyles(cellStyleMap: Partial<Record<CellStyles, string>>): string {
	const styles: string[] = [];
	if(cellStyleMap.cell_width) {
		styles.push(`width: ${cellStyleMap.cell_width}px !important`);
	}

	if(cellStyleMap.cell_height) {
		styles.push(`height: ${cellStyleMap.cell_height}px !important`);
	}

	if(cellStyleMap.cell_text_align) {
		styles.push(`text-align: ${cellStyleMap.cell_text_align.toLowerCase()} !important`);
	}

	return `tbody tr td {${styles.join(";")}}`
}

function mapHeaderStyles(headerStyleMap: Partial<Record<HeaderStyles, string>>): string {
	const styles: string[] = [];
	if(headerStyleMap.header_width) {
		styles.push(`width: ${headerStyleMap.header_width}px !important`);
	}

	if(headerStyleMap.header_height) {
		styles.push(`height: ${headerStyleMap.header_height}px !important`);
	}

	if(headerStyleMap.header_text_align) {
		styles.push(`text-align: ${headerStyleMap.header_text_align.toLowerCase()} !important`);
	}

	return `thead tr th {${styles.join(";")}}`
}
const attributeMap: Record<keyof MappedAttributes, (value: Partial<Record<SupportedAttributes, string>>) => string> = {
	cellStyles: value => mapCellStyles(value),
	headerStyles: value => mapHeaderStyles(value)
}
function factory(styleMap: Partial<Record<StyleAttribute, string>>): string {
	return [attributeMap.cellStyles(styleMap), attributeMap.headerStyles(styleMap)]
		.filter(value => value.length > 0).join("");
}

const tableStyleFactory: StylePropertyFactory = factory;
export {tableStyleFactory};