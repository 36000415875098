import {styled} from "@mui/material";
import {ButtonBase} from "./ButtonBase";
import {ButtonProps} from "./Button.model";
import {CenteredContainer} from "../../CenteredContainer";
import {AddComment} from "@mui/icons-material";

const CommentButtonContainer = styled(ButtonBase)(({theme}) => ({
	backgroundColor: theme.palette.tabs?.border,
	color: theme.palette.primary.main,
	"&:hover": {
		backgroundColor: theme.palette.primary?.contrastText,
		color: theme.palette.tabs?.border
	}
}));

const CommentButton = ({onClick}: ButtonProps) => <CommentButtonContainer onClick={onClick}>
	<CenteredContainer>
		<AddComment style={{fontSize: '19px'}}/>
	</CenteredContainer>
</CommentButtonContainer>

export {CommentButton}