import { getTemplateTreeItem } from "../../../../api/template";
import { useCachedAsync } from "../../../../shared/hooks";
import { useGetTemplate } from "./useGetTemplate";

export interface TemplateInformation {
	id: string,
	version: string,
	name: string,
	model_document?: string,
	state: string,
	permissions: KaiAlphaTemplatePermissions,
	children: KaiAlphaBodySelection,
	archived: boolean;
}

const cacheKeyPrefix = "9d00c8f6bd5841f9bc896560946755779";

function useGetTemplatesSpecificVersion() {
	const getTemplate = useGetTemplate();

	const getTemplateChildren = async (subTemplates: KaiAlphaBodySelection): Promise<TemplateInformation[] | null> => {
		const templates = await Promise.all(subTemplates.map(template => getTemplate.execute({id:template.contents.id!, version: template.contents.version})));
		const templateInfoCollection = await Promise.all(templates.map(getTemplateTreeItem));
		const templateCollection = templateInfoCollection.filter(templateInfo => templateInfo !== null) as TemplateInformation[];
		return templateCollection.length === 0 ? null : templateCollection;
	}

	const asyncFunction = useCachedAsync(getTemplateChildren, { immediate: false, defaultExpiryTimeMinutes: 5 });
	const executeAsync = (templates: any[]) => {
		const ids = templates.map(template => template.contents.id);
		const versions = templates.map(template => template.contents.version);
		const cacheKey = `${cacheKeyPrefix}-${ids.join("-")}-${versions.join("-")}`;
		return asyncFunction.withCacheKey(cacheKey).execute(templates);
	}

	return {
		...asyncFunction,
		execute: executeAsync
	}
}

export { useGetTemplatesSpecificVersion };