import React from 'react';
import MUIButton from '@mui/material/Button';
import { withStyles } from '@mui/styles'; // LEGACY

function styles(theme){
	return({
		default: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			border: `1px solid ${theme.palette.primary.main}`,
			'&:hover': {
				backgroundColor: theme.palette.primary.contrastText,
				color: theme.palette.primary.main,
			},
		},
		cancel: {
			backgroundColor: theme.palette.cancelButton?.main,
			color: theme.palette.cancelButton?.contrastText,
			'&:hover': {
				backgroundColor: theme.palette.cancelButton?.hover,
				color: theme.palette.cancelButton?.contrastText,
				boxShadow: `1px 1px 3px ${theme.palette.cancelButton?.contrastText}`,
			},
		},
		destructive: {
			backgroundColor: theme.palette.destructive?.main,
			color: theme.palette.destructive?.contrastText,
			'&:hover': {
				backgroundColor: theme.palette.destructive?.contrastText,
				color: theme.palette.destructive?.main,
			},
		},
		disabled: {
			backgroundColor: theme.palette.disabled?.main,
			color: theme.palette.disabled?.contrastText,
			border: `1px solid ${theme.palette.disabled?.contrastText}`,
		}
	});
}

const Button = ({type, children, classes, icon = null, ...props}) => {
	// MUIButton component doesn't accept custom css rules we defined in classes object
	if (props.disabled) {
		return(<MUIButton className={classes.disabled} {...props}>{icon}{children}</MUIButton>);
	}

	switch (type) {
		case "cancel":
			return(<MUIButton className={classes.cancel} {...props}>{icon}{children}</MUIButton>);
		case "destructive":
			return(<MUIButton className={classes.destructive} {...props}>{icon}{children}</MUIButton>);
		case "default":
		default:
			return(<MUIButton className={classes.default} {...props}>{icon}{children}</MUIButton>);
	}
}

export default withStyles(styles)(Button);