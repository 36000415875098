import {DocumentElementDefinition} from "../model";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {
	imageDefinition as baseImageDefinition
} from "../../../../../shared/definitions/elements/image/model";

const imageDefinition: DocumentElementDefinition = {
	...baseImageDefinition,
	views: {
		editable: EditableView,
		readonly: ReadonlyView
	},
} as const

export {imageDefinition};