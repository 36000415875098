import { useStyles } from './TableOfContents.style';
import { useState, useEffect, useRef } from 'react';
import { useDebounce, useScroll } from '../../../../../shared/hooks';
import { TableOfContentsProps } from './TableOfContents.props';
import { Section } from "../../../models/Section";
import { ListItem } from "@mui/material";
import { ChildSection } from "./ChildSection";
import { Icon } from "../../../../../lib/ui";
import {CommentState} from "../../../hooks/useCommentState";

const TableOfContents = (props: TableOfContentsProps) => {
	const {
		windowSectionId,
		onSectionClicked,
		disableScrolling,
		activeSectionId,
		sections,
	} = props;

	const commentsManager = CommentState.useContainer();
	const classes = useStyles();
	const [expandedSections, setExpandedSections] = useState<string[]>([]);
	// create an instance of a custom hook that wraps the UseLookup hook
	//const subSectionLookup = useSubSectionLookup();

	// this is what is passed into the child components
	// we are using useCallback here to memoize the values and optimize performance
	// const onLookupSectionContainsId = useCallback(
	// 	(section: any, searchId: string) =>
	// 		subSectionLookup.sectionContains(section, searchId),
	// 	[subSectionLookup]
	// );

	const debouncedScrollWindowSectionId = useDebounce(windowSectionId, 250);

	const [scrollWindowSectionId, setScrollWindowSectionId] =
		useState(windowSectionId);

	const tocRef = useRef<HTMLDivElement>(null);

	// custom hook for scrolling
	const sectionScroll = useScroll();

	useEffect(() => {
		if (debouncedScrollWindowSectionId) {
			if (
				!disableScrolling &&
				scrollWindowSectionId &&
				windowSectionId !== scrollWindowSectionId
			) {
				setScrollWindowSectionId(windowSectionId);
				sectionScroll.scrollById(`toc_${windowSectionId}`, tocRef);
			}
		}
	}, [windowSectionId, disableScrolling, scrollWindowSectionId, debouncedScrollWindowSectionId, sectionScroll]);

	const onSectionClick = (section: Section) => {
		const expandedItems = expandedSections.includes(section.id) ? [...expandedSections.filter(id => id !== section.id)] : [...expandedSections, section.id];
		setExpandedSections(expandedItems);
	}

	const comments = commentsManager.comments ?? [];
	return (
		<div
			className={[
				classes.container,
				classes.expandedContainer,
			].join(' ')}
		>
			<div className={classes.headerContainer}>
			</div>
			<div ref={tocRef}>
				{sections &&
					sections.map((parentSection: Section) => {
						return (
							<div style={{ display: 'flex' }} key={parentSection.id}>
								<div className={classes.collapseIconSize}>
									{parentSection.sections.length > 0 && <Icon
										forUI={
											(expandedSections.includes(parentSection.id))
												? 'collapse-down'
												: 'collapse-right'
										}
										onClick={() => onSectionClick(parentSection)}
									/>}
								</div>

								<ListItem
									id={`toc_${parentSection.id}`}
									key={`toc_${parentSection.id}`}
									style={{ marginLeft: 0 }}
									className={classes.sectionItem}
								>
									<ChildSection
										depth={0}
										comments={comments}
										isExpanded={expandedSections.includes(parentSection.id)}
										section={parentSection}
										activeSectionId={activeSectionId}
										windowSectionId={windowSectionId}
										classes={classes}
										onSectionClicked={onSectionClicked}
									/>
								</ListItem>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default TableOfContents;
