import { useStatusBar } from "../../../../../shared/components";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";
import { useGetDocumentAndBuffer } from "../../api/useGetDocumentAndBuffer";
import { useGetTemplate } from "../../api/useGetTemplate";
import { DocumentState } from "../../useDocumentState";
import { useSetBufferFromDocument } from "../";
import {EntityRecord} from "../../../../../shared/interfaces/Entity";


// loads the document, the user's buffer, and the template used to build the document.
function useLoadDocumentAction(): TypedAction<EntityRecord> {
	const state = DocumentState.useContainer();
	const getDocumentAndBuffer = useGetDocumentAndBuffer();
	const getTemplate = useGetTemplate();
	const setBufferFromDocument = useSetBufferFromDocument();
	const statusBar = useStatusBar();

	// if passed a template id, watch for the response
	useRunWhenValueChange(() => {
		const {value} = getDocumentAndBuffer;
		if (!value) {
			return;
		}

		if (value.buffer !== null) {
			// set buffer and template
			state.setTempBuffer(value!.buffer)
			state.setDocument(value!.document);
			setBufferFromDocument(value!.document);
		} else {
			state.setDocument(value.document);
			setBufferFromDocument(value.document);
		}

		getTemplate.execute({id: value.document!.template!.id, version: value.document!.template!.version});
	}, getDocumentAndBuffer.value);

	useRunWhenValueChange(() => {
		const {value} = getTemplate;

		if (!value) {
			return;
		}

		state.setTemplate(getTemplate.value);

		statusBar.sendSuccessNotification({message: "Content loaded"});
	}, getTemplate.value)

	useRunWhenValueChange(() => {
		if (getDocumentAndBuffer.error ?? getTemplate.error) {
			statusBar.sendErrorNotification({
				message: "Error loading document",
				detail: getDocumentAndBuffer.error ?? getTemplate.error
			});
		}
	}, [getDocumentAndBuffer.error, getTemplate.error])

	const run = ({id, version}: EntityRecord) => getDocumentAndBuffer.execute(id, version);
	return {run, isRunning: getDocumentAndBuffer.isLoading};
}

export {useLoadDocumentAction};