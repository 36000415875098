import {useParams} from "react-router-dom";
import {TaskNode} from "../../../shared/interfaces/task";
import {AsyncComponent, FlexBox} from "../../../shared/components";
import Masthead from "../../../shared/components/navigation/masthead";
import {ContextButtonFactory} from "../../../shared/components/buttons/theme";
import {ModuleLayout} from "../../../shared/components/layout/ModuleLayout";
import {TasksList} from "../../../shared/tasks";
import {useTasks} from "./useTasks";
import {ListPageWrapper} from "../../../shared/components/layout/ListPageWrapper";

const Tasks = () => {
	const { id } = useParams();
	const taskManager = useTasks(id);

	const onTaskSelected = (task: TaskNode) => {
		taskManager.onNavigateToTask(task);
	}
	const onContextButtonClick = (key: string) => {
	}

	const getListTitle = () => (taskManager.tasks?.tasks?.length ?? 0) === 0 ? "Template Tasks" : taskManager.tasks!.tasks[0].source.name + " Tasks";

	return <AsyncComponent
		isLoading={taskManager.isRunning}
		loadingMessage={"Loading..."}
		fullScreen
		component={<FlexBox direction={"column"} height={"100%"}>
			<Masthead
				contextButtons={ContextButtonFactory([],
					onContextButtonClick)}
			/>
			<ModuleLayout center={<ListPageWrapper><TasksList tasks={taskManager.tasks} title={getListTitle()} onTaskSelected={onTaskSelected} /></ListPageWrapper>} />
		</FlexBox>
		} />
}

export {Tasks}