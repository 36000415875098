import {TemplateState} from "../../useTemplateState";
import {useGetTemplateAndBuffer} from "../../api/useGetTemplateAndBuffer";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {useSetBufferFromTemplate} from "./useSetBufferFromTemplate";
import {useStatusBar} from "../../../../../shared/components";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";
import {EntityRecord} from "../../../../../shared/interfaces/Entity";

function useLoadTemplateAction(): TypedAction<EntityRecord> {
	const state = TemplateState.useContainer();
	const getTemplateAndBuffer = useGetTemplateAndBuffer();
	const setBufferFromTemplate = useSetBufferFromTemplate();
	const statusBar = useStatusBar();

	// if passed a template id, watch for the response
	useRunWhenValueChange(() => {
		const {value} = getTemplateAndBuffer;
		if (!value) {
			return;
		}

		if (value.buffer !== null && !state.isInReview) {
			// set buffer and template
			state.setBuffer(value!.buffer)
			state.setTemplate(value!.template);
		} else {
			state.setTemplate(value.template);
			setBufferFromTemplate(value.template);
		}

		statusBar.sendSuccessNotification({message: "Template loaded"});

	}, getTemplateAndBuffer.value);

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error loading template", detail: getTemplateAndBuffer.error});
	}, getTemplateAndBuffer.error)

	const run = ({id, version}: EntityRecord) => getTemplateAndBuffer.execute(id, version);
	return {run, isRunning: getTemplateAndBuffer.isLoading};
}

export {useLoadTemplateAction};