import { useGetBuffers } from "./useGetBuffers";
import { useGetDocuments } from "../../../shared/hooks/api/useGetDocuments";
import { useGetTemplates } from "../../../shared/hooks/api/useGetTemplates";


const useDashboardApi = () => {
	const getDocumentBuffers = useGetBuffers('document');
	const getTemplateBuffers = useGetBuffers('template');
	const getTemplates = useGetTemplates();
	const getDocuments = useGetDocuments();

	const loading = getDocumentBuffers.isLoading || getTemplateBuffers.isLoading ||
	getTemplates.isLoading || getDocuments.isLoading;
	return {getDocumentBuffers, getTemplateBuffers, getTemplates, getDocuments, loading};
}

export {useDashboardApi}