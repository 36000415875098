import React from 'react';
import {
	Icon,
	Typography,
	commonMetrics,
	Dialog,
} from '../../lib/ui';
import date_utils from '../../lib/utils/date_utils';
import { titleCase } from "title-case";

import { withStyles } from '@mui/styles'; // LEGACY

function style(theme) {
	/*
	 * Get information from the IdentityBar component, so we align to it
	 */
	const ourMetrics = commonMetrics.getComponentMetrics('StatusBar', theme);

	return({
		message_type_ok: {
			color: theme.palette.success.contrastText,
		},
		message_type_error: {
			'& > span:first-child': {
				color: '#ED4428 !important',
				textDecoration: 'underline',
				fontWeight: 600
			}
		},
		message_type_warning: {
			color: theme.palette.warning.contrastText,
		},
		message_type_progress: {
			color: theme.palette.info.contrastText,
		},
		message_area: {
			maxWidth: ourMetrics.width,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			cursor: 'pointer',
			display: 'block'
		},
	});
}

const uuid = require('uuid');

let ref;

export function push_status(...args) {
	if (!ref) {
		console.error('Asked update status without the StatusBar!');
		return;
	}

	return(ref.push_status(...args));
}

function format_task_message_string(message, attributes = {}) {
	for (const attribute_name in attributes) {
		const attribute_value = attributes[attribute_name];
		const replacer = new RegExp(`%{${attribute_name.toUpperCase()}}`, 'g');
		message = message.replace(replacer, attribute_value);
	}

	return(message);
}

export async function push_status_task(task_name, lambda, options = {}) {
	options = {
		activity_id: options.activity_id || uuid.v4(),
		progress_msg: '%{TASK_NAME}',
		ok_msg: 'Finished %{TASK_NAME}',
		error_msg: `Failed %{TASK_NAME}: %{ERROR_MESSAGE}`,
		throw: false,
		finally: undefined,
		...options
	};

	const message_format_attributes = {
		task_name: titleCase(task_name)
	};

	push_status('progress', format_task_message_string(options.progress_msg, message_format_attributes), options.activity_id);
	let retval, activity_error;
	let failed = false;
	try {
		retval = await lambda();
	} catch (_activity_error) {
		failed = true;
		activity_error = _activity_error;
	}

	/*
	 * ESLint currently erroneously thinks the next line
	 * is using the Promise.finally method, it's not.
	 */
	// eslint-disable-next-line
	if (options.finally) {
		try {
			// eslint-disable-next-line
			await (options.finally());
		} catch (_ignored_error) {
			/* We ignore finally errors */
		}
	}

	if (failed) {
		message_format_attributes['error_message'] = String(activity_error);
		const error_msg = format_task_message_string(options.error_msg, message_format_attributes);

		push_status('error', error_msg, options.activity_id);

		if (options.throw) {
			throw(activity_error);
		}

		return;
	}

	push_status('ok', format_task_message_string(options.ok_msg, message_format_attributes), options.activity_id);

	return(retval);
}

class StatusBar extends React.Component {
	constructor(props, ...args) {
		super(props, ...args);

		ref = this;

		this.state = {
			messages: {
				id_list: [],
				items: {}
			},
			display_message_dialog: undefined
		};

		this._check_progress_overruns();
	}

	static _filter_id_list(list, items) {
		const new_list = [...list].filter(function(item_id) {
			if (items[item_id] === undefined) {
				return(false);
			}
			return(true);
		});

		return(new_list);
	}

	_check_progress_overruns() {
		setInterval(() => {
			/*
			 * Keep track of messages which have been displayed for too long
			 */
			const now = new Date();
			for (const item_id of this.state.messages.id_list) {
				const item = this.state.messages.items[item_id];

				if (item === undefined) {
					continue;
				}

				if (item.type !== 'progress') {
					continue;
				}

				const display_time = now - item.timestamp;

				if (display_time > 30000) {
					console.error("Status item has been displaying for", display_time, "ms:", item);
				}
			}
		}, 30000);
	}

	push_status(type, message, id = undefined) {
		if (type === undefined && id === undefined) {
			throw(new Error('May not push an undefined type and an undefined id message'));
		}

		if (id === undefined) {
			id = uuid.v4();
		}

		switch (type) {
			case 'progress':
			case 'ok':
			case 'error':
			case 'warning':
			case undefined:
			case null:
				break;
			default:
				console.error('Invalid type specified:', type);
				break;
		}

		if (type !== 'error' && type !== 'warning') {
			setTimeout(() => {
				this.delete_status_by_id(id);
			}, 10000);
		}

		this.setState(function(newState) {
			const new_items = {...newState.messages.items};
			const new_id_list = StatusBar._filter_id_list(newState.messages.id_list, new_items);

			if (type !== undefined) {
				/*
				 * Add or Update an item
				 */
				const timestamp = new Date();
				const new_item = { id, type, message, timestamp };

				if (new_items[id] === undefined) {
					/*
					 * Add a new item
					 */
					new_id_list.push(id);
				}

				new_items[id] = new_item;
			} else {
				/*
				 * Delete an item by ID
				 */
				delete new_items[id];
			}

			return({
				messages: {
					id_list: new_id_list,
					items: new_items
				}
			});
		});
	}

	delete_status_by_id(id) {
		this.setState(function(newState) {
			const new_items = {...newState.messages.items};
			delete new_items[id];

			const new_id_list = StatusBar._filter_id_list(newState.messages.id_list, new_items);

			return({
				messages: {
					id_list: new_id_list,
					items: new_items
				}
			});
		});
	}

	render_message(message) {
		if(undefined === message) return null;

		const dismiss_message = () => this.delete_status_by_id(message.id);

		const show_message = (() => {
			this.show_messages_dialog([message]);
		});

		return(
			<div className={this.props.classes[`message_type_${message.type}`]} style={{display: 'inline-flex'}} key={`message_${message.id}`}>
				<span onClick={show_message} className={this.props.classes.message_area}>{message.message}</span>
				{('error' === message.type || 'warning' === message.type) && <span onClick={dismiss_message} style={{cursor: 'pointer', color: "#ED4428"}}>&nbsp;✕</span>}
			</div>
		);
	}

	show_messages_dialog(messages) {
		this.setState({
			display_message_dialog: messages
		});
	}

	render_message_dialog_contents(messages) {
		const render_line = function(title, line, style = {}) {
			return(<div><strong>{title}:</strong><Typography variant='inherit' style={style}><Icon forUI={`status-${line.type}`}/> {line.message} ({date_utils.format_date(line.timestamp)})</Typography></div>);
		};

		return(
			<>
				{messages.map((message) => {
					const message_style = {};

					const updated_message = this.state.messages.items[message.id];

					let dismissed = false;
					if (!updated_message) {
						dismissed = true;
					}

					if (dismissed) {
						message_style['textDecoration'] = 'line-through';
					}

					let updated_message_line = null;
					if (updated_message) {
						if (JSON.stringify(message) !== JSON.stringify(updated_message)) {
							updated_message_line = render_line('Updated', updated_message, message_style);
						}
					}

					return(
						<>
							{render_line('Message', message, message_style)}
							{updated_message_line}
						</>
					);
				})}
			</>
		);
	}

	render_message_dialog() {
		const messages = this.state.display_message_dialog;

		if (!messages) {
			return(null);
		}

		if (messages.length === 0) {
			return(null);
		}

		const action_close = (() => {
			this.setState({
				display_message_dialog: undefined
			});
		});

		const action_dismiss = (() => {
			for (const message of messages) {
				this.delete_status_by_id(message.id);
			}

			action_close();
		});

		let all = '';
		if (messages.length > 1) {
			all = ' All';
		}

		return(
			<Dialog
				open={true}
				onClose={action_close}
				title={'Notification Message'}
				buttons={{
					[`Dismiss${all}`]: action_dismiss,
					'Close': action_close,
				}}
			>
				{this.render_message_dialog_contents(messages)}
			</Dialog>
		);
	}

	render() {
		const end = this.state.messages.id_list.length - 1
		const latestMessage = this.state.messages.items[this.state.messages.id_list[end]]

		return(
			<>
				{this.render_message_dialog()}
				{this.render_message(latestMessage)}
			</>
		);
	}
}

export default withStyles(style)(StatusBar);
