import { CommentIconProps } from './CommentIcon.props';
import {DocumentComment} from "../../../hooks/useCommentState";
import {Section} from "../../../models/Section";
import {ModeCommentRounded} from "@mui/icons-material";
import {colors} from "../../../../../theme";

function recursivelyMatchSections(section: Section, comment: DocumentComment) {
	if (section.id === comment.elementId) {
		return true;
	}

	if (section.sections.length === 0) {
		return false;
	}

	for(let i = 0; i < section.sections.length; i++) {
		if (recursivelyMatchSections(section.sections[i], comment)) {
			return true;
		}
	}

	return false;
}
const CommentIcon = (props: CommentIconProps) => {
	const { document, section } = props;

	if (!document) {
		return null;
	}


	const matchingComment = props.comments?.find(comment => recursivelyMatchSections(section, comment));
	/* If there is no comment on section and/ on it's subsection - return null */
	if (matchingComment === undefined) {
		return null;
	}

	return (
		<ModeCommentRounded fontSize={"small"} style={{color: colors.darkBlue}} />
	);
};

export { CommentIcon };
