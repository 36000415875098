import './style.scss';
import CircleIcon from '@mui/icons-material/Circle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { CommentState } from '../../../hooks/useCommentState';
import { MENU_HEIGHT } from './Comments.constants';
import CommentModal from '../../modals/templateComments/CommentModal';
import { useRunOnce } from '../../../../../shared/hooks';
import { Chip } from '../../../../../lib/ui';
import { TagsChip } from './Comments.style';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import {CommentStates} from "../../modals/templateComments/CommentModal.models";
import { useUpdateCommentAction } from '../../../hooks/actions/update/useUpdateCommentAction';
import {AsyncComponent, Button} from "../../../../../shared/components";
import {useDeleteCommentAction} from "../../../hooks/actions/update/useDeleteCommentAction";
import {useUserList} from "../../../../../shared/hooks/useUserList";

// we can add more options to this popup
// only adding Resolve since this is what we need right now
type OptionType = "Resolve" | "Edit" | "Delete";
const options: OptionType[] = [
	'Resolve',
	'Edit',
	'Delete'
];

export interface CommentsIndividualProps {
	comment: KaiAlphaCommentEntry,
	readonly: boolean,
	templateId?: string
}

const CommentsIndividual = (props: CommentsIndividualProps) => {

	const {
		tag,
		date,
		state,
		comment,
		author,
		elementId,
		text_properties,
		text
	} = props.comment;

	const commentsManager = CommentState.useContainer();
	const userList = useUserList();
	const updateCommentAction = useUpdateCommentAction();
	const deleteCommentAction = useDeleteCommentAction();

	const [showModal, setShowModal] = useState(false);
	const [username, setUsername] = useState("");

	useRunOnce(async () => {
		userList.execute(["display_name"]);
		const userResponse = await commentsManager.getUserDisplayname(author);
		setUsername(userResponse);
	});

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);


	const handleCommentModalClose = () => {
		setShowModal(false);
	}

	// click event sets the anchor for the menu
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (value: OptionType) => {
		setAnchorEl(null);

		if(value === 'Resolve') {
			if(comment) {
				updateCommentAction.run({
					editResolve:'RESOLVE',
					commentPayload:{
						comment: props.comment,
						elementId: props.comment.elementId
					}
				});
			}
		} else if (value === 'Edit') {
			if(comment) {
				setShowModal(true);
			}
		} else if (value ==='Delete') {
			if(comment) {
				deleteCommentAction.run(props.comment);
			}
		}
	};

	const menuClose = () => {
		setAnchorEl(null);
	}

	const scrollToSelectedElement = () => {
		if (elementId) {
			commentsManager.commentScroll(props.comment);
		}
	}

	const addUserNames = (commentText: string) => {
		const regularExpression = /<uid:([\w-]*)>/gm;
		const matches = commentText.match(regularExpression);
		return matches?.reduce((text, match) => {
			const userId = match.replace("<uid:", "").replace(">","");
			const displayName = userList.value?.users.find(u => u.id === userId)?.display_name;
			return text.replace(match, `<span class="comments-individual__actual--recorded-user">${displayName ?? match}</span>`)
		}, commentText) ?? commentText;
	}

	return(<>
		<AsyncComponent isLoading={updateCommentAction.isRunning || deleteCommentAction.isRunning || userList.isLoading} component={<div className={`comments-individual ${commentsManager?.selectedElement === elementId && elementId !== commentsManager.templateId ? "comments-individual-selected" : ""}`}>
			<CircleIcon sx={{fontSize: "11px", color: "#4D72BD", paddingRight: "18px", paddingTop: "11px"}} />
			<div className={`comments-individual__actual ${elementId && elementId !== props.templateId ? "comments-individual__actual-clickable" : ""}`} onClick={scrollToSelectedElement}>
				{text_properties && <div>
					<span className={"comments-individual__actual-highlight"}>
						{text}
					</span>
				</div>}
				<div dangerouslySetInnerHTML={{__html: addUserNames(comment)}}></div>
				<TagsChip>
					<LocalOfferIcon className="chipIcon"/>
					<Chip
						className="selectedChip"
						label={tag}
						clickable={elementId !== props.templateId}
					/>
				</TagsChip>
				<div className="comments-individual__actual--recorded-user">
					@{username}
				</div>
				<div className="comments-individual__actual--recorded-date">
					{new Date(date).toLocaleDateString("nl",{year:"2-digit",month:"2-digit", day:"2-digit"})}
					{" "}
					{new Date(date).toLocaleTimeString('en-US')}
				</div>
			</div>
			{/* Menu was originally copied from https://mui.com/material-ui/react-menu/#max-height-menu */}

			{!props.readonly && state && state !== CommentStates.RESOLVED &&
				<div className="comments-individual__menu">
					<Button buttonType={"chromeless"} icon={<MoreVertIcon />} onClick={handleClick} />
					<Menu
						anchorEl={anchorEl}
						open={open}
						PaperProps={{
							style: {
								maxHeight: MENU_HEIGHT * 4.5,
								width: '20ch',
							},
						}}
						onClose={menuClose}
					>
						{options.map((option) => (
							<MenuItem key={option} selected={false} onClick={() => handleClose(option)}>
								{option}
							</MenuItem>
						))}
					</Menu>
				</div>
			}
		</div>} />
		{showModal &&
			<CommentModal
				onClose={handleCommentModalClose}
				addEditComment={"Edit"}
				showOpen={showModal}
				initialComment={props.comment}
			/>
		}
	</>);
};

export {CommentsIndividual}