import {useState} from "react";
import {useInteractionObserver} from "../../../shared/hooks/useInteractionObserver";

export function useActiveSection(root: string) {
	const [activeSectionId, setActiveSectionId] = useState<string>();
	useInteractionObserver((entry) => {
		if (entry.isIntersecting) {
			setActiveSectionId(entry.target.id);
		}

	}, {
		root,
		observedNodeSelector: "[data-type=section]",
		threshold: 1
	});

	return activeSectionId;
}