import {useAsync, useCache} from "../../../../../shared/hooks";
import {DataSource} from "../../../../../shared/interfaces/DataSource";
import {TypedActionWithValue} from "../../../../../shared/interfaces/ActionRun";
import {DataSourceOptions, getDataSource} from "../../../../../shared/hooks/api/data/useGetDataSource";

type Namespace = {namespace: string, name: string}
type DataSourceCollection = {datasources: DataSource[]}
export type LoadDataSourceProps = DataSourceOptions & Namespace
type RunProps = {
	sources: LoadDataSourceProps[]
}
function useLoadDataSources(): TypedActionWithValue<RunProps, (DataSourceCollection & Namespace)[] | null> {
	const cache = useCache(10);
	function loadDatasources(sources: LoadDataSourceProps[]): Promise<(DataSourceCollection & Namespace | undefined)[] | null> {
		const cacheKey = sources.map(data => `${data.namespace}.${data.name}-${data.source}`).join("");

		return cache.getItem(cacheKey, () => Promise.all(sources.map(async option => {

			try {
				const datasource = await getDataSource(option);
				return {
					namespace: option.namespace,
					name: option.name,
					datasources: datasource
				} as (DataSourceCollection & Namespace);
			} catch {
				return undefined;
			}
		})));
	}

	const run = ({sources}: RunProps) => loadData.execute(sources);
	const loadData = useAsync(loadDatasources, false);
	return {
		run,
		isRunning: loadData.isLoading,
		value: loadData.value?.filter(dataSource => dataSource !== undefined) as (DataSourceCollection & Namespace)[] | null
	}

}

export {useLoadDataSources};