import { DocumentState } from "../../useDocumentState";

function useSetBufferFromDocument() {
	const state = DocumentState.useContainer();
	// remove values that are not present on the buffer model.
	return (document?: KaiAlphaDocument) => {
		const {workflow, previous_version, ...buffer} = document ?? state.document!;
		state.setBuffer(buffer);
	};

}

export {useSetBufferFromDocument};