import {Variable} from "../../../../../../../../shared/interfaces/Variable";
import {VariableMapper} from "./model";
import {TableElementFootnote} from "../../../../../../../../shared/definitions/elements/table/model";

function mapper(variable: Variable) {
	return ((variable.value ?? []) as TableElementFootnote[]).map(f => `{{${f.value}}} ${f.name}`).join("<br />");
}

const MultiInputMapper: VariableMapper = {
	type: "multi_input",
	mapper
}

export {MultiInputMapper};