import {MetaDataValueEditor, ValueEditorProps} from "./model";
import {useRunOnce, useAsync} from "../../../../../../../shared/hooks";
import {getCompoundsApi} from "../../../../../../../shared/metaData/api/getCompoundsApi";
import {AsyncComponent} from "../../../../../../../shared/components";
import {DropdownValueEditor} from "./DropdownValueEditor";
import {DropDownItem} from "../../../../../../../shared/components/input/dropdown";

const Editor = (props: ValueEditorProps) => {
	const loadCompounds = useAsync(getCompoundsApi, false);
	useRunOnce(() => {
		loadCompounds.execute();
	});

	const dropDownItems = (loadCompounds.value?.map(c => ({title: c.compound, value: c.compound})) ?? []) as DropDownItem<string>[];
	return <AsyncComponent
		isLoading={loadCompounds.isLoading}
		component={<DropdownValueEditor {...props} data={{...props.data, options: {items: dropDownItems}}} />} />
}
export const CompoundEditor: MetaDataValueEditor = {
	view: Editor,
	displayTextGenerator: (value: string) => value,
	defaultValueFactory: () => ""
}