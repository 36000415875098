import { useState } from 'react';
import TemplateDocSelector from '../../../../components/TemplateDocSelector';
import { AppChrome } from '../../../../components/App/Chrome/AppChrome';
import { ContextButtonFactory, DefaultContextButtons } from "../../../../shared/components/buttons/theme";
import { AsyncComponent, useStatusBar } from '../../../../shared/components';
import {useRunWhenValueChange} from '../../../../shared/hooks';
import { UserManager } from '../../../../shared/hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { moduleLinkGenerator } from '../../../moduleNavigation';
import {searchTemplatesApi} from "../../../../shared/hooks/api/useGetTemplates";
import {TryAsync} from "../../../../shared/interfaces/Either";
import {useGetTemplate} from "../../hooks/api/useGetTemplate";
import {useCreateDocument} from "../../hooks/api/useCreateDocument";

const DocumentCreator = () => {

	const [selectedTemplate, setSelectedTemplate] = useState("");

	const navigate = useNavigate();
	const statusBar = useStatusBar();
	const getTemplate = useGetTemplate();
	const createDocumentApi = useCreateDocument();
	const user = UserManager.useContainer();

	useRunWhenValueChange(() => {
		if (getTemplate.value) {
			statusBar.sendInfoNotification({ message: "Creating content" });
			createDocumentApi.execute("Unnamed Content",user.id,getTemplate.value);
		}
	}, getTemplate.value);

	useRunWhenValueChange(() => {
		if (createDocumentApi.value?.id) {
			navigate(
				moduleLinkGenerator(
					"content",
					"edit",
					createDocumentApi.value?.id)
			);
		}
	}, createDocumentApi.value);

	const getDocumentTemplates = async () => {
		const templatesEither = await TryAsync(async () => {
			const searchResponse = await searchTemplatesApi({filters: [{field: "metadata.toplevel", expression: "|", value:"metadata.system.toplevel=true"}], pageSize: 2000});
			return searchResponse.results;
		});

		return templatesEither.matchWith({
			left: _ => [],
			right: templates => templates
		})
	}

	const onSelectedItem = (id: string) => {
		setSelectedTemplate(id);
	}

	const onContextButtonClick = (key: string) => {
		switch (key) {
			case DefaultContextButtons.New.key:
				// send notificaiton to show document is being created
				statusBar.sendInfoNotification({ message: "creating document" });
				// pass in undefined to retrieve the latest template
				getTemplate.execute({id: selectedTemplate});
				break;
			default:
				break;
		}
	}

	return (
		<AsyncComponent
			isLoading={getTemplate.isLoading || createDocumentApi.isLoading}
			loadingMessage={"Creating content..."}
			fullScreen
			component={
				<AppChrome
					center={
						<div className="edit-document">
							<TemplateDocSelector
								action='Create'
								display_type='Template'
								content_type='templates'
								header_type='Content'
								onSelectItem={onSelectedItem}
								getChildren={getDocumentTemplates}
								show_buttons={true}
							></TemplateDocSelector>
						</div>}
					mastHeadContextButtons={ContextButtonFactory([
						{ ...DefaultContextButtons.New, label: "Create Content", disabled: selectedTemplate === undefined }
					], onContextButtonClick)}
				/>}
		/>);

}

export { DocumentCreator };
