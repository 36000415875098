import {MetaDataValueEditor, valueEditorMap, ValueEditorProps} from "./model";
import React from "react";
import {GenericEditor} from "./GenericEditor";
import {MetadataItem} from "../../../../../../../shared/interfaces/Metadata";
export function valueEditorForMetaData(metaDataKey: string): MetaDataValueEditor {
	return valueEditorMap[metaDataKey] ?? GenericEditor
}

export function valueEditorViewForMetaData(metaDataKey: string): React.FC<ValueEditorProps> {
	return valueEditorForMetaData(metaDataKey).view;
}

export function defaultValueForMetaData(metaDataKey: string): string {
	return valueEditorForMetaData(metaDataKey).defaultValueFactory();
}

export function displayTextForMetaData(metadata: MetadataItem): string {
	return valueEditorForMetaData(metadata.key).displayTextGenerator(metadata.value);
}