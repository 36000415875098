import {EctdModule} from "../../../../../shared/interfaces/Module";
import {ModuleColumnDefinition, ModuleListAction} from "./models";
import {ModuleColumnHeader} from "./columns/ModuleColumnHeader";
import {Row} from "./Row";
import "./styles.scss"
export type ModuleTableProps = {
	module: EctdModule,
	columns: ModuleColumnDefinition[],
	onToggleSectionActive?: (module: EctdModule) => void,
	onRowClick?: (module: EctdModule) => void
}

const ModuleTable = (props: ModuleTableProps) => {
	const onRowAction = (action: ModuleListAction, row: EctdModule) => {
		switch(action) {
			case "activate":
			case "deactivate":
				if (props.onToggleSectionActive) {
					props.onToggleSectionActive(row);
				} break;
			case "click":
				if (props.onRowClick) {
					props.onRowClick(row);
				}
		}
	}

	const onHeaderAction = (action: unknown, column: ModuleColumnDefinition) => {
		const headerAction = action as ModuleListAction;
		onRowAction(headerAction, props.module);
	}
	return <table className={"ectd__list--moduleTable"}>
		<thead>
			<tr>
				{props.columns.map(column => <th key={column.field} style={{width: column.width ?? "auto"}}>
					<ModuleColumnHeader module={props.module} column={column} onAction={onHeaderAction} />
				</th>)}
			</tr>
		</thead>
		<tbody>
			<Row onRowAction={onRowAction} module={props.module} columns={props.columns} depth={0} />
		</tbody>
	</table>
}

export {ModuleTable}