import {DocumentElementDefinition} from "../model";
import {PreviewView} from "./views/preview/PreviewView";
import {
	tableDefinition as baseTableDefinition
} from "../../../../../shared/definitions/elements/table/model";

const tableDefinition: DocumentElementDefinition = {
	...baseTableDefinition,
	views: {
		preview: PreviewView
	},
} as const

export {tableDefinition};