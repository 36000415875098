import {styled, Chip as MuiChip, Autocomplete as MuiAutocomplete} from "@mui/material";

const Autocomplete = styled(MuiAutocomplete)(({
	margin: "1rem 0",
	boxSizing: "border-box",
}));

const Chip = styled(MuiChip)(({theme}) => ({
	backgroundColor: theme.palette.tagsChip?.main,
}));

const ChipLabel = styled('span')(({theme}) => ({
	fontSize: ".8rem",
	fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
	fontWeight: 500,
}));

export {Chip, Autocomplete, ChipLabel}