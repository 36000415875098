import React from 'react';
import PropTypes from 'prop-types';
import * as MaterialIcons from '@mui/icons-material';
import { CircularProgress } from '../../lib/ui';

const VARIANT_SUFFIX_MAPPING = {
	'special': 'Special',
	'outlined': 'Outlined',
	'special_outlined': 'SpecialOutlined',
	'rounded': 'Rounded',
	'special_rounded': 'SpecialRounded'
};

const VALID_VARIANTS = Object.keys(VARIANT_SUFFIX_MAPPING);

class Icon extends React.Component {
	static propTypes = {
		forType: PropTypes.string,
		forAction: PropTypes.string,
		forUI: PropTypes.string,
		forStatus: PropTypes.string,
		variant: PropTypes.oneOf(VALID_VARIANTS),
		fill: PropTypes.string,
	}

	static notFound() {
		return(MaterialIcons.NotInterested);
	}

	static withName(name, variant) {
		let iconName = name;

		if (iconName && variant) {
			if (!VALID_VARIANTS.includes(variant)) {
				throw new Error(`Invalid variant value of '${variant}', expected one of ${VALID_VARIANTS}`);
			}

			iconName = `${iconName}${VARIANT_SUFFIX_MAPPING[variant]}`;
		}

		if (!iconName || !MaterialIcons[iconName]) {
			console.error('No known icon for name and variant', name, variant);
			return(Icon.notFound());
		}

		return MaterialIcons[iconName];
	}

	static forType(type, variant) {
		type = type.toLowerCase();

		switch (type) {
			case 'templates':
			case 'template':
				return Icon.withName('ViewAgenda', variant);
			case 'documents':
			case 'document':
			case 'contents':
			case 'content':
				return Icon.withName('InsertDriveFile', variant);
			case 'folder':
				return Icon.withName('Folder', variant);
			case 'submissions':
				return Icon.withName('FileOpen', variant);
			case 'ectd':
				return Icon.withName('Folder', variant);
			case 'task':
				return Icon.withName('PlaylistAddCheck', variant);
			case 'list':
				return Icon.withName('List', variant);
			case 'workflow':
				return Icon.withName('LinearScale', variant);
			case 'citations_&_abbreviation':
				return Icon.withName('FontDownload', variant);
			case 'submission':
				return Icon.withName('FileOpen', variant);
			default:
				console.error('No known icon for type', type);
				return(Icon.notFound());
		}
	}

	static forAction(action, variant) {
		action = action ? action.toLowerCase().replace(/ /g,'_') : '';
		/*
		 * These actions share the icons for their respective types
		 */
		if (['templates', 'documents', 'contents', 'folders', 'submissions', 'tasks', 'lists', 'workflows', "citations_&_abbreviations", "usergroups"].includes(action)) {
			const type = action.slice(0, -1);

			//variant parameter takes precedence
			const isOutlinedAction = ['templates', 'submissions', 'documents', 'contents', "usergroups"].includes(action)
			if (!variant && isOutlinedAction) {
				variant = 'outlined'
			}
			return(this.forType(type, variant));
		}

		switch (action) {
			case 'dashboard':
				return Icon.withName('Dashboard', variant);
			case 'logout':
				return Icon.withName('ExitToApp', variant);
			case 'profile':
				return Icon.withName('Person', variant);
			case 'add_user':
				return Icon.withName('Add', variant);
			case 'users':
				return Icon.withName('Person', variant || 'outlined');
			case 'person_search':
				return Icon.withName('Person', variant);
			case 'new_group':
				return Icon.withName('GroupAdd', variant || 'outlined');
			case 'groups':
				return Icon.withName('Groups', variant || 'outlined');
			case 'search':
				return Icon.withName('Search', variant);
			case 'filter':
				return Icon.withName('FilterList', variant);
			case 'edit':
				return Icon.withName('Edit', variant);
			case 'import':
			case 'export':
				return Icon.withName('ImportExport', variant);
			case 'save':
				return Icon.withName('Save', variant);
			case 'publish':
				return Icon.withName('Publish', variant);
			case 'preview':
				return Icon.withName('Pageview', variant);
			case 'view':
				return Icon.withName('Visibility', variant);
			case 'upgrade':
				return Icon.withName('Update', variant);
			case 'create':
				return Icon.withName('Add', variant);
			case 'delete':
				return Icon.withName('Delete', variant);
			case 'acl_editor':
				return Icon.withName('Security', variant);
			case 'audit_logs':
				return Icon.withName('ListAlt', variant);
			case 'check':
				return Icon.withName('Check', variant);
			case 'uncheck':
				return Icon.withName('CheckBoxOutlineBlank', variant);
			case 'buffer':
				return Icon.withName('Storage', variant);
			case 'associate_workflow':
				return Icon.withName('AccountTree', variant);
			case 'disassociate_workflow':
				return Icon.withName('AccountTree', variant || 'outlined');
			case 'fetch_datasource':
				return Icon.withName('SystemUpdateAlt', variant);
			case 'fetch_all_datasources':
				return Icon.withName('DynamicFeed', variant);
			case 'rename':
				return Icon.withName('BorderColor', variant);
			case 'add_comment':
				return Icon.withName('AddComment', variant);
			case 'arrow_up':
				return Icon.withName('ArrowUpward', variant);
			case 'arrow_down':
				return Icon.withName('ArrowDownward', variant);
			case 'dragindicator':
				return Icon.withName('DragIndicator', variant);
			case 'user_groups':
				return Icon.withName('Groups', variant);
			default:
				console.error('No known icon for action', action);
				return(Icon.notFound());
		}
	}

	static forWorkflow(workflow, variant) {
		workflow = workflow.toLowerCase().replace(/ /g,'_');

		switch (workflow) {
			case 'resume':
				return Icon.withName('PlayArrow', variant);
			case 'set_owner':
				return Icon.withName('PersonPin', variant);
			case 'set_ui_action_review':
				return Icon.withName('RateReview', variant);
			case 'set_ui_action_prompts':
				return Icon.withName('Announcement', variant);
			case 'if':
				return Icon.withName('AccountTree', variant);
			case 'set_ui_counter_button':
				return Icon.withName('PlusOne', variant);
			case 'named_code_block':
				return Icon.withName('Code', variant);
			case 'instantiate_template':
				return Icon.withName('FileCopy', variant);
			case 'plus':
				return Icon.withName('Add', variant);
			case 'minus':
				return Icon.withName('Remove', variant);
			case 'done':
				return Icon.withName('Done', variant);
			case 'request_review':
				return Icon.withName('Pageview', variant)
			case 'cancel_review_cycle':
				return Icon.withName('Cancel', variant)
			case 'complete_review':
				return Icon.withName('CheckCircle', variant)
			case 'final_review_approve':
				return Icon.withName('ThumbUp', variant)
			case 'final_review_reject':
				return Icon.withName('ThumbDown', variant)
			case 'cancel_approval_process':
				return Icon.withName('CancelScheduleSend', variant)
			case 'request_approval':
				return Icon.withName('Explore', variant)
			default:
				console.error('No known icon for workflow', workflow);
				return(Icon.notFound());
		}
	}

	static forStatus(status, variant) {
		status = status.toLowerCase();
		if (status.match(/^in /)) {
			status = status.slice(3);
		}
		switch (status) {
			case 'draft':
				return Icon.withName('AccessTime', variant);
			case 'review':
				return Icon.withName('Search', variant);
			case 'task':
			case 'tasks':
			case 'task remaining':
				return(Icon.forType('task', variant));
			default:
				console.error('No known icon for status', status);
				return(Icon.notFound());
		}
	}

	static forFolderAction(action, variant) {
		action = action ? action.toLowerCase().replace(/ /g,'_') : ''

		/*
		 * These actions share the icons for their respective types
		 */
		if (['edit', 'rename', 'delete', 'acl_editor'].includes(action)) {
			return(this.forAction(action, variant));
		}

		switch (action) {
			case 'assign':
				return Icon.withName('FolderShared', variant);
			case 'unassign':
				return Icon.withName('Folder', variant);
			case 'new_child_folder':
				return Icon.withName('SubdirectoryArrowRight', variant);
			case 'new_top_folder':
				return Icon.withName('CreateNewFolder', variant);
			default:
				console.error('No known icon for folder action', action);
				return(Icon.notFound());
		}
	}

	static forUI(element, variant) {
		element = element.toLowerCase();
		switch (element) {
			case 'expand-up':
				return Icon.withName('ArrowDropUp', variant);
			case 'expand-down':
				return Icon.withName('ArrowDropDown', variant);
			case 'collapse-down':
				return Icon.withName('KeyboardArrowDown', variant);
			case 'expand-right':
				return Icon.withName('ArrowRight', variant);
			case 'collapse-left':
				return Icon.withName('ChevronLeft', variant);
			case 'expand-left':
				return Icon.withName('ArrowLeft', variant);
			case 'collapse-right':
				return Icon.withName('ChevronRight', variant);
			case 'close':
				return Icon.withName('Cancel', variant);
			case 'status-ok':
				return Icon.withName('CheckCircleOutline', variant);
			case 'checkcircle':
				return Icon.withName('CheckCircle', variant);
			case 'status-progress':
				return(CircularProgress);
			case 'status-error':
				return Icon.withName('ErrorOutline', variant);
			case 'status-warning':
				return Icon.withName('Warning', variant);
			case 'info':
				return Icon.withName('InfoOutlined', variant);
			case 'tags':
				return Icon.withName('LocalOffer', variant);
			case 'feedback': /* XXX:TODO: This is not a UI element */
				return Icon.withName('Feedback', variant);
			case 'textformat': /* XXX:TODO: This is not a UI element */
				return Icon.withName('TextFormat', variant);
			case 'wraptext': /* XXX:TODO: This is not a UI element */
				return Icon.withName('WrapText', variant);
			case 'shorttext': /* XXX:TODO: This is not a UI element */
				return Icon.withName('ShortText', variant);
			case 'content': /* XXX:TODO: This is not a UI element */
				return Icon.withName('Subject', variant);
			case 'comment':
				return Icon.withName('ChatBubbleOutline', variant);
			case 'thread':
				return Icon.withName('QuestionAnswer', variant);
			case 'textfields': /* XXX:TODO: This is not a UI element */
				return Icon.withName('TextFields', variant);
			case 'chat': /* XXX:TODO: This is not a UI element */
				return Icon.withName('ModeComment', variant);
			case 'actionmenu':
				return Icon.withName('MoreVert', variant);
			case 'radiobuttonunchecked':
				return Icon.withName('RadioButtonUnchecked', variant);
			case 'checkboxrounded':
				return Icon.withName('CheckBoxRounded', variant);
			case 'blankcheckboxrounded':
				return Icon.withName('CheckBoxOutlineBlankRounded', variant);
			default:
				console.error('No known icon for UI element', element);
				return(Icon.notFound());
		}
	}

	render() {
		const { style: propsStyle = { }, fill, ...restProps } = this.props
		const new_props = { ...restProps };

		//fill prop takes precedence
		let style = propsStyle
		if (fill) {
			style = { ...propsStyle, fill }
		}

		let IconToUse = Icon.notFound();

		for (const method of ['forType', 'forAction', 'forUI', 'forStatus', 'forWorkflow', 'forFolderAction']) {
			if (this.props[method] === undefined) {
				continue;
			}

			delete new_props[method];

			IconToUse = Icon[method](this.props[method], this.props.variant);
		}

		return(<IconToUse style={style} {...new_props}/>);
	}
}

export default Icon;
