import { Route, Routes } from "react-router-dom"
import { NotFound } from "./NotFound";

export interface ModuleRoutesProps {
	children: React.ReactNode,
	notFound?: React.ReactNode | null
}

const ModuleRoutes = ({children, notFound}: ModuleRoutesProps) => {
	return <Routes>
		{children}
		<Route path={"/*"} element={notFound ?? <NotFound/>} />
	</Routes>
}

export {ModuleRoutes};