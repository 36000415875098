import {CsvMapper, CsvParserOptions} from "./model";
import {CsvDataSource} from "../../../interfaces/DataSource";
import csv_parse_lib from "csv-parse/lib/sync";
import {getUniqueHeaders} from "../getUniqueHeaders";
import {getColumnData} from "./getColumnData";

function parse(data: string | Buffer, options: CsvParserOptions): CsvDataSource {
	const parsedData = csv_parse_lib(data, {
		...options,
		columns: false
	});

	const parsedHeaders = parsedData[0];
	const headerValues = getUniqueHeaders(parsedHeaders, "columns");
	const headers = headerValues.deduplicatedHeaders;
	const orderedHeaders = headerValues.orderedHeaders;

	return {
		data: getColumnData(parsedData, orderedHeaders),
		type: "columns",
		'@metadata': {
			headers,
			ordered_headers: orderedHeaders
		}
	};
}

export function columnsMapperFactory(): CsvMapper {
	return ({
		type: "columns",
		parse
	})
}