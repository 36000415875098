import {Variable} from "../../../../../../../../shared/interfaces/Variable";
import {VariableMapper} from "./model";
import {renderMissingVariable} from "../renderMissingVariable";

function mapper(variable: Variable) {
	return variable?.options?.data ?? variable.value ?? renderMissingVariable(variable.name);
}

const UndefinedMapper: VariableMapper = {
	type: undefined,
	mapper
}

export {UndefinedMapper};