import React from 'react';
import MUIDialog from '@mui/material/Dialog';
import { withStyles } from '@mui/styles'; // LEGACY

import { Button, Icon } from '../../lib/ui';
import { Avatar, Paper } from '@mui/material';
import Draggable from 'react-draggable';

function styles(theme){
	return({
		default: {
			color: theme.palette.primary.contrastText,
			border: `1px solid ${theme.palette.primary.main}`,
		},
		dialogHeaderContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		titleContainer: function(props) {
			let fontSize = '2rem';
			let fontWeight = undefined;

			if (props.compactTitle === true) {
				fontSize = '1.25rem';
				fontWeight = 'bold';
			}

			return({
				color: theme.palette.title.contrastText,
				display: 'flex',
				flex: 1,
				alignItems: 'center',
				fontWeight: fontWeight,
				fontSize: fontSize
			});
		},
		closeIcon: {
			color: theme.palette.primary.contrastText,
			fill: theme.palette.scrollbar.main,
			fontWeight: 'bold',
			fontSize: '1.75rem',
			cursor: 'pointer'
		},
		avatar: {
			marginRight: 10,
			width: 40,
			height: 40,
			borderRadius: 40,
		},
		dialogContainer: {
			padding: 20,
		},
		dialogBody: {
			marginTop: '1rem',
		}
	})
}

function PaperComponent(props) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	);
}

class Dialog extends React.Component {
	constructor(...args) {
		super(...args);

		this.state = {
			dialog_open: false
		};
	}

	getDialogContentRef = () => {
		return this.dialogContentRef?.current;
	}

	onCancel = () => {
		if (this.props.onClose) {
			this.props.onClose(false);
		}
	}

	getButtonViews = () => {
		if (this.props.hideButtons) {
			return null;
		}

		if (this.props.buttons && React.isValidElement(this.props.buttons)) {
			return this.props.buttons;
		}

		let button_info = this.props.buttons;
		if (button_info === undefined) {
			button_info = {
				"Cancel": this.onCancel,
				"Okay": () => {
					if (this.props.onClose) {
						this.props.onClose(true);
					}
				}
			};
		}

		const buttons = [];

		for (const button_title of Object.keys(button_info)) {
			const button_action = button_info[button_title];
			let sub_button_type = "default";

			if (button_title === "Cancel") {
				sub_button_type = "cancel";
			}

			if (button_title === "Delete") {
				sub_button_type = "destructive";
			}

			buttons.push(<Button key={`button_${button_title}`} onClick={button_action} type={sub_button_type}>{button_title}</Button>);
		}

		return buttons;
	}

	render() {
		const { classes, dialogClasses, children, showAvatar, avatarSource, open, title, hideButtons, dialogContentRef, disableClose, ...restProps } = this.props;
		const buttons = this.getButtonViews();

		return(
			<MUIDialog
				{...restProps}
				PaperComponent={PaperComponent}
				classes={dialogClasses}
				open={open}
				onClose={disableClose ? undefined : this.onCancel}
				onBackdropClick={disableClose ? undefined: this.onCancel}
				aria-labelledby="draggable-dialog-title"
			>
				<div ref={dialogContentRef} className={classes.dialogContainer}>
					<div id="draggable-dialog-title">
						<div className={classes.dialogHeaderContainer}>
							<div className={classes.titleContainer}>
								{showAvatar && <Avatar className={classes.avatar} src={avatarSource} /> }
								{title}
							</div>
							{!disableClose &&
								<div onClick={this.onCancel} >
									<Icon className={classes.closeIcon} forUI={'close'} />
								</div>
							}
						</div>
					</div>
					<div className={classes.dialogBody}>
						{children}
					</div>
					{buttons && <footer style={{marginTop: '1rem', alignSelf: 'flex-end', display: 'flex', gap: '0.5em', justifyContent: 'flex-end'}}>
						{buttons}
					</footer>}
				</div>
			</MUIDialog>
		);
	}
}

export default withStyles(styles)(Dialog);