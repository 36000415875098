import {DocumentElementDefinition} from "../model";
import {PreviewView} from "./PreviewView";
import {
	sectionDefinition as baseSectionDefinition
} from "../../../../../shared/definitions/elements/section/model";

const sectionDefinition: DocumentElementDefinition = {
	...baseSectionDefinition,
	views: {
		preview: PreviewView
	},
} as const

export type SectionElementPreviewValue = {
	name?: string,
	index?: string
	body: KaiAlphaTemplateBody
}

export {sectionDefinition};