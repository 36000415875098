import { Task } from "../interfaces/task";

export function search(searchValue: string,field: string,tasks: Task[]): Task[] {
	const taskArray:any = [];
	tasks.forEach((taskItem) => {
		if (typeof taskItem[field] === "string") {
			if(taskItem[field]?.toLowerCase().includes(searchValue)){
				taskArray.push(taskItem);
			}
		} else {
			taskItem.owner && taskItem?.owner.forEach((item) => {
				if (item?.toLowerCase().includes(searchValue)) {
					taskArray.push(taskItem);
				}
			});
		}
	});
	return taskArray;
}
