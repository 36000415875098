import { FilterState } from "../../input/filter/sort/models";

export function sort(sortState: FilterState, field: string, items: any[]): any[] {
	const sorted = items.sort((a, b) => {
		if (sortState === "ascending") {
			if(a[field] < b[field]) { return -1; }
			if(a[field] > b[field]) { return 1; }
			return 0;
		} else {
			if(a[field] > b[field]) { return -1; }
			if(a[field] < b[field]) { return 1; }
			return 0;
		}
	})
	return sorted;
}