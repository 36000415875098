import { TemplateElement } from "../../../../../shared/interfaces/TemplateElement";
import { assemblyBodySortlyItems } from "../../../utilities/assembleBodySortlyItems";
import {elementMapper} from "./mappers/elementMapper";

function mapElements(elements: TemplateElement[]) {
	return elements.map(element => {
		const mapper = elementMapper[element.type] ?? elementMapper.default;
		return mapper(element);
	});
}

function buildTemplateBody(elements: TemplateElement[] | null): KaiAlphaTemplateBody {
	return assemblyBodySortlyItems(mapElements(elements ?? []));
}

export {buildTemplateBody};