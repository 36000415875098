import { FlexBox } from "../../../layout";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {useState} from "react";
import { FilterStates, FilterState } from "./models";
import { FilterLayout } from "./style";

const NextState = {
	[FilterStates.None]: FilterStates.Ascending,
	[FilterStates.Ascending]: FilterStates.Descending,
	[FilterStates.Descending]: FilterStates.None
}
interface SortFilterProps {
	title: string,
	onStateChange: (FilterState) => void,
	justify?: "flex-start" | "center",
	// parent controlled state
	parentState?: FilterState,
}

const SortFilter = ({title, onStateChange, justify, parentState} : SortFilterProps) => {
	const [state, setFilterState] = useState<FilterState>(FilterStates.None);

	// if parent wants to control state, use their state.
	const displayState = parentState ?? state;

	const onClick = () => () => {
		if (!parentState) {
			setFilterState(NextState[displayState]);
		}
		onStateChange(NextState[displayState]);
	}

	return (
		<FilterLayout width="auto" justify={justify ?? "center"} align="center" onClick={onClick()}>
			<FlexBox direction="column" justify="center" width="auto">
				<ExpandLess className={`icon  ${displayState === FilterStates.Ascending ? "active" : ""}`} sx={{marginBottom: "-3px"}}/>
				<ExpandMore className={`icon  ${displayState === FilterStates.Descending ? "active" : ""}`} sx={{marginTop: "-3px"}}/>
			</FlexBox>
			<FlexBox direction="column" justify="center" width="auto" marginLeft="0.4rem" boxSizing="border-box">
				<div
					className={`filter_title ${displayState === FilterStates.Ascending || displayState === FilterStates.Descending ? "active" : ""}`
					}>{title}</div>
			</FlexBox>
		</FilterLayout>
	)

}

export {SortFilter}