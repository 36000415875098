import {useTypedAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";
import {LoadEntityFunc} from "../../../../shared/interfaces/LoadEntityFuncType";
import {EntityRecord} from "../../../../shared/interfaces/Entity";

function getDocumentApi({id, version}: EntityRecord): Promise<KaiAlphaDocument> {
	const apiPath = version ? `document/${id}/versions/${version}` : `document/${id}`
	return api.call("GET", apiPath);
}
function useGetDocument() {
	const loadDocument: LoadEntityFunc<KaiAlphaDocument> = getDocumentApi
	return useTypedAsync(loadDocument, false);
}

export {useGetDocument, getDocumentApi}