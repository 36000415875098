import {MenuItem, styled} from "@mui/material";

const ContextMenuItem = styled(MenuItem)(({theme}) => ({
	display: "flex",
	justifyContent: "space-between",
	"& svg": {
		marginLeft: ".5rem",
		color: theme.palette.primary.main
	}
}))

export {ContextMenuItem}