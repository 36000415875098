import {ButtonProps} from "./model";
import React from "react";
import {Button} from "./Button";
import {styled} from "@mui/material";
import {colors} from "../../../theme";

const NotificationBubbleWrapper = styled("div")({
	position: "relative",
})

const Badge = styled("div")({
	position: "absolute",
	right: "-10%",
	left: "85%",
	bottom: "70%",
	top: "-30%",
	zIndex: "999",
	background: colors.darkBlue,
	color: "white",
	boxSizing: "border-box",
	borderRadius: "50%",
	fontSize: ".75rem",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
})

type NotifiedButtonProps = ButtonProps & {
	badgeCount: number
}

export const BadgeButton = React.forwardRef((props: NotifiedButtonProps, ref) => {
	return <NotificationBubbleWrapper>
		{props.badgeCount > 0 && <Badge>{props.badgeCount}</Badge>}
		<Button {...props} ref={ref} />
	</NotificationBubbleWrapper>
})