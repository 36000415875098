import {EctdModule} from "../../../../../shared/interfaces/Module";
import "./styles.scss";

export type TocItemProps = {
	module: EctdModule,
	selectedModule?: EctdModule,
	depth?: number,
	showTitle: boolean,
	onSelectSection?: (section: EctdModule) => void
}
const TocItem = (props: TocItemProps) => {
	const depth = props.depth ?? 0;

	const onClickFactory = (module: EctdModule) => props.onSelectSection ? () => {
		props.onSelectSection!(module)
	} : undefined

	return <ul className={`ectd__list--tocItem ${depth === 0 ? "inset" : ""}`}>
		{props.module.modules.filter(subSection => subSection.active).map((subSection) =>
			<li className={`ectd__list--tocItem-item`} key={`subSection-${subSection.moduleNumber}`}>
				<span className={`ectd__list--tocItem-item__number ${props.selectedModule?.moduleNumber === subSection.moduleNumber ? "ectd__list--tocItem-item__number--active" : ""}`} onClick={onClickFactory(subSection)}>{subSection.moduleNumber}</span>
				{props.showTitle && <span className={`ectd__list--tocItem-item__title ${props.selectedModule?.moduleNumber === subSection.moduleNumber ? "ectd__list--tocItem-item__title--active" : ""}`} onClick={onClickFactory(subSection)}>{subSection.moduleTitle}</span>}
				{subSection.modules.length > 0 && <TocItem onSelectSection={props.onSelectSection}  showTitle={props.showTitle} module={subSection} depth={depth+1} selectedModule={props.selectedModule} />}
			</li>)}
	</ul>
}

export {TocItem}