import { BaseElement } from "../../../interfaces/BaseElement";

const citationsDefinition: BaseElement = {
	name: 'Template',
	type: 'citations_list',
	displayName: 'Citations List',
	description: '',
	contentDescription: () => '',
	allowsChildren: false,
	longDescription: '',
	toString: () =>  "(Preview Unavailable)",
} as const

export {citationsDefinition};