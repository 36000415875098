import {getIttVariablesApi} from "./getIttVariablesApi";
import {AutoCompleteConfig} from "../../interfaces/AutoCompleteConfig";
import {VariableAutoCompleteConfigGenerator} from "../autoCompleteConfigGenerators";
import {SystemNamespaceLookup} from "../SystemNamespaces";

const ittName = SystemNamespaceLookup.ittvariables;
const ittTrigger = ittName + ".";
async function ittAutoCompleteConfigGenerator(): Promise<AutoCompleteConfig[]> {
	try {
		const variables = await getIttVariablesApi();
		return [{
			trigger: ittTrigger.toLowerCase(),
			options: Object.keys(variables)
		}]
	} catch {
		return []
	}
}

export const IttAutoCompleteConfigGenerator: VariableAutoCompleteConfigGenerator = {
	name: ittName,
	generate: ittAutoCompleteConfigGenerator
}