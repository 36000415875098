import {Tasks} from "../../../../shared/interfaces/task";
import { getTemplateTasks } from "../../utilities/getTemplateTasks";

async function getTemplateTasksApi(templateId?: string): Promise<Tasks> {
	if (templateId === undefined) {
		return Promise.resolve({tasks: []});
	}

	return getTemplateTasks(templateId);
}

export {getTemplateTasksApi};