import {DocumentElementDefinition} from "../model";
import {
	loopDefinition as baseLoopDefinition
} from "../../../../../shared/definitions/elements/loop/model";
import {PreviewView} from "./PreviewView";

const loopDefinition: DocumentElementDefinition = {
	...baseLoopDefinition,
	views: {
		preview: PreviewView
	},
} as const

export {loopDefinition};