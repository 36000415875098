/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/lib/general_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import object_utils from './object_utils';
// @ts-check

async function _apply_diff_any_type(user_id, type, item_id, item_version, summary, diff, options = {}) {
	let retval;

	switch (type) {
		case 'template':
			retval = await kaialpha.lib.template.apply_diff_user_template(user_id, item_id, item_version, summary, diff);
			break;
		case 'document':
			retval = await kaialpha.lib.document.apply_diff_user_document(user_id, item_id, item_version, summary, diff, options);
			break;
		case 'workflow':
			retval = await kaialpha.lib.workflow.apply_diff_user_workflow(user_id, item_id, item_version, summary, diff);
			break;
		case 'folder':
			retval = await kaialpha.lib.folder.apply_diff_user_folder(user_id, item_id, item_version, summary, diff);
			break;
		default:
			throw(new Error(`Unable to apply diff on item with type ${type}, unsupported type`));
	}

	return(retval);
}

async function rename_item_any_type(user_id, type, item_id, item_version, new_name, options = {}) {
	options = {
		summary: 'Renaming',
		...options
	};

	return(await _apply_diff_any_type(user_id, type, item_id, item_version, options.summary, {
		change: {
			name: new_name
		}
	}));
}

async function update_permissions_any_type(user_id, type, item_id, item_version, new_permissions, options = {}) {
	options = {
		summary: 'Changing permissions',
		...options
	};

	return(await _apply_diff_any_type(user_id, type, item_id, item_version, options.summary, {
		change: {
			permissions: new_permissions
		},
		delete: {
			permissions: null
		}
	}));
}

async function update_permissions_any_type_add_inherit_parent(user_id, type, item_id, new_parent, options = {}) {
	options = {
		summary: 'Changing permissions (adding parent)',
		...options
	};

	const item = await get_item_any_type(user_id, type, item_id, 'HEAD', {
		fields: ['permissions']
	});

	const item_version = item.version;

	let new_permissions = item.permissions;
	if (new_permissions === null || new_permissions === undefined) {
		new_permissions = /** @type {any} */ ({});
	}

	/*
	 * If the item is explicitly not inheriting permissions,
	 * do not add any
	 */
	let inherit_from = new_permissions.inherit_from;
	if (inherit_from === null) {
		return({
			id: item_id,
			version: item_version
		});
	}

	if (inherit_from === undefined) {
		inherit_from = [];
	}

	inherit_from.push({
		type: new_parent.type,
		id: new_parent.id
	});

	new_permissions['inherit_from'] = inherit_from;

	return(await _apply_diff_any_type(user_id, type, item_id, item_version, options.summary, {
		change: {
			permissions: new_permissions
		},
		delete: {
			permissions: null
		}
	}));
}

async function update_permissions_any_type_delete_inherit_parent(user_id, type, item_id, old_parent, options = {}) {
	options = {
		summary: 'Changing permissions (deleting parent)',
		...options
	};

	const item = await get_item_any_type(user_id, type, item_id, 'HEAD', {
		fields: ['permissions']
	});

	const item_version = item.version;

	const new_permissions = item.permissions;
	if (new_permissions === null || new_permissions === undefined) {
		return({
			id: item_id,
			version: item_version
		});
	}

	let inherit_from = new_permissions.inherit_from;
	if (inherit_from === null || inherit_from === undefined) {
		return({
			id: item.id,
			version: item.version
		});
	}

	inherit_from = inherit_from.filter(function(item) {
		if (item.type !== old_parent.type) {
			return(true);
		}

		if (item.id !== old_parent.id) {
			return(true);
		}

		return(false);
	});

	new_permissions['inherit_from'] = inherit_from;

	return(await _apply_diff_any_type(user_id, type, item_id, item_version, options.summary, {
		change: {
			permissions: new_permissions
		},
		delete: {
			permissions: null
		}
	}));
}

async function delete_item_any_type(user_id, type, item_id) {
	let retval;

	switch (type) {
		case 'template':
			retval = await kaialpha.lib.template.delete_template(user_id, item_id);
			break;
		case 'document':
		case 'content':
			retval = await kaialpha.lib.document.delete_document(user_id, item_id);
			break;
		case 'workflow':
			retval = await kaialpha.lib.workflow.delete_workflow(user_id, item_id);
			break;
		case 'folder':
			/* XXX:TODO: rename delete_user_folder -> delete_folder */
			retval = await kaialpha.lib.folder.delete_user_folder(user_id, item_id);
			break;
		default:
			throw(new Error(`Unable to delete item with type ${type}, unsupported type`));
	}

	return(retval);
}

/**
 * Get item of any type
 * @param {string} user_id
 * @param {string} type
 * @param {string} id
 * @param {string} version
 * @param {*} options
 * @returns {Promise<KaiAlphaCMSItem>}
 */
async function get_item_any_type(user_id, type, id, version, options = {}) {
	options = {
		fields: undefined,
		/**
		 * If `true`, ignore requested version and fetch the latest version if the cms type doesn't support versioning.
		 *
		 * If `false`, this will throw if version is requested for unsupported types (default - for backwards compatibility).
		 */
		resolve_unsupported_to_head: false,
		...options
	};

	let item;
	switch (type) {
		case 'template':
			item = await kaialpha.lib.template.get_user_template(user_id, id, version, options);
			break;
		case 'document':
			item = await kaialpha.lib.document.get_user_document(user_id, id, version, options);
			break;
		case 'workflow':
			item = await kaialpha.lib.workflow.get_user_workflow(user_id, id, version, options);
			break;
		case 'folder':
			if (options.resolve_unsupported_to_head !== true &&
				version !== undefined && version !== 'HEAD') {
				throw(new Error(`May only get the latest version of folders, requested: ${version}`));
			}

			item = await kaialpha.lib.folder.get_user_folder(user_id, id, options);
			break;
		case 'list':
			item = await kaialpha.lib.list.get_user_list(user_id, id, version, options);
			break;
		default:
			throw(new Error(`Unable to get item with type ${type}, unsupported type`));
	}

	let retval = item;

	/* XXX:TODO: It would be better if we could just request certain fields */
	if (options.fields !== undefined) {
		retval = {
			id: item.id,
			version: item.version,
		};

		for (const field of options.fields) {
			retval[field] = item[field];
		}
	}

	return(retval);
}

async function get_item_versions_any_type(user_id, type, id, options = {}) {
	options = {
		fields: undefined,
		...options
	};

	let versions;
	switch (type) {
		case 'template':
			versions = await kaialpha.lib.template.get_user_template_versions(user_id, id);
			break;
		case 'document':
			versions = await kaialpha.lib.document.get_user_document_versions(user_id, id);
			break;
		case 'workflow':
			versions = await kaialpha.lib.workflow.get_user_workflow_versions(user_id, id);
			break;
		case 'folder':
		case 'list':
			/* XXX:TODO */
			throw(new Error(`Unable to get item versions with type ${type}, unsupported type (options = ${JSON.stringify(options)}`));
		default:
			throw(new Error(`Unable to get item with type ${type}, unsupported type`));
	}

	return(versions);
}

async function get_canonical_permissions_any_type(user_id, type, id, options = {}) {
	options = {
		list_type: undefined,
		...options
	};

	switch (type) {
		case 'template':
			return(await kaialpha.lib.template.get_user_template_canonical_permissions(user_id, id));
		case 'document':
			return(await kaialpha.lib.document.get_user_document_canonical_permissions(user_id, id));
		case 'workflow':
			return(await kaialpha.lib.workflow.get_user_workflow_canonical_permissions(user_id, id));
		case 'folder':
			return(await kaialpha.lib.folder.get_user_folder_canonical_permissions(user_id, id));
		case 'list':
			/* XXX:TODO */
			throw(new Error(`Unable to get item with type ${type}, unsupported type (options = ${JSON.stringify(options)}`));
		default:
			throw(new Error(`Unable to get item with type ${type}, unsupported type`));
	}
}

async function get_canonical_permissions_and_roles_any_type(user_id, type, id, options = {}) {
	options = {
		list_type: undefined,
		...options
	};

	const item = await get_item_any_type(user_id, type, id, 'HEAD', options);
	const permissions_and_roles = await kaialpha.lib.versions_utils.canonicalize_permissions_and_roles(item.permissions);

	// returning same structure as `get_canonical_permissions_any_type` to make this a drop in replacement for it
	return({
		id: item.id,
		version: item.version,
		permissions: permissions_and_roles
	});
}

/**
 * Function to compute url path for a given type
 * @returns {string}
 */
function get_url_path_any_type(info) {
	if (!info) {
		return(info);
	}

	if (info.type === undefined) {
		if (info.page !== undefined) {
			let page = info.page;

			switch (page) {
				case 'projects':
					page = 'folders';
					break;
				default:
					/* make ESLint happy */
					break;
			}

			switch (page) {
				case 'dashboard':
					return('/');
				case 'tasks':
				case 'folders':
				case 'workflows':
					return(`/activity/${page}`);
				case 'templates':
					/* XXX:TODO: Maybe these should be renamed ? */
					return('/activity/templates');
				case 'documents':
				case 'contents':
					/* XXX:TODO: Maybe these should be renamed ? */
					return('/activity/docselector');
				default:
					throw(new Error(`Unknown page: ${info.page}`));
			}
		}

		if (info.pathname !== undefined) {
			return(info.pathname);
		}

		return(info);
	}

	if (info.id === 'new_state') {
		if (!['content', 'document'].includes(info.type)) {
			throw(new Error(`Only documents can be created from new state not ${info.type}`));
		}
	}

	let retval;
	switch (info.type) {
		case 'document':
		case 'content':
		case 'template':
			{
				let prefix;
				switch (info.type) {
					case 'document':
					case 'content':
						prefix = 'doc';
						break;
					default:
						prefix = info.type;
				}

				if (!info.id) {
					retval = `/${prefix}selector`;
					break;
				}

				if (info.id === 'new_state') {
					if (info.type === 'document' || info.type === 'content') {
						retval = '/doceditor';
						break;
					}
					throw(new Error('Unexpected error.'));
				}

				if (info.id === 'new') {
					switch (info.type) {
						case 'document':
						case 'content':
							retval = '/doccreator';
							break;
						case 'template':
							retval = '/templateeditor';
							break;
						default:
							throw(new Error(`Unhandled sub-case ${info.type}`));
					}
					break;
				}

				retval = `/${prefix}editor/${info.id}`;

				/* XXX:TODO: This really only works for the document editor for now */
				if (info.version && info.version !== 'HEAD') {
					retval += `/version/${info.version}`;
				}
			}

			break;
		case 'workflow':
			if (!info.id) {
				retval = '/workflows';
				break;
			}

			if (info.id === 'new') {
				retval = '/workfloweditor';
				break;
			}

			retval = `/workfloweditor/${info.id}`;
			break;
		case 'list':
			if (!info.list_type) {
				retval = '/lists';
				break;
			}

			if (!info.id) {
				retval = `/listsselector/${info.list_type}`;
				break;
			}

			if (info.id === 'new') {
				throw(new Error('No support for creating new lists'));
			}

			retval = `/listseditor/${info.list_type}/${info.id}`;
			break;
		case 'folder':
			if (!info.id) {
				retval = '/folders';
				break;
			}

			if (info.id === 'new') {
				throw(new Error('No support for creating new folders'));
			}

			retval = `/folders/${info.id}`;
			break;
		case 'tasks':
			if (!info.id || !info.task_type) {
				retval = '/tasks';
				break;
			}
			retval = `/tasks/${info.task_type}/${info.id}`;
			break;
		default:
			throw(new Error(`Invalid type: ${info.type}`));
	}

	return(`/activity${retval}`);
}

/**
 * Returns the options passed to CMS during initialization of this type
 * @param {string} type
 */
function get_cms_options_any_type(type) {

	let cms_options;
	switch (type) {
		case 'template':
			cms_options = kaialpha.lib.template.get_cms_options();
			break;
		case 'document':
			cms_options = kaialpha.lib.document.get_cms_options();
			break;
		case 'workflow':
			cms_options = kaialpha.lib.workflow.get_cms_options();
			break;
		case 'folder':
			cms_options = kaialpha.lib.folder.get_cms_options();
			break;
		case 'list':
			cms_options = kaialpha.lib.list.get_cms_options();
			break;
		default:
			throw (new Error(`Unable to get cms options for type ${type}, unsupported type`));
	}

	return cms_options;
}

/**
 * Computes searchable record from given full record.
 * This is used for recomputing dependant items when an item's permissions changes.
 * @param {string} type
 * @param {KaiAlphaCMSItem} full_record
 * @param {Object} external_data
 */
async function compute_searchable_item_any_type(type, full_record, external_data) {

	let cms_options;
	switch (type) {
		case 'template':
			cms_options = await kaialpha.lib.template.compute_searchable_item(full_record, external_data);
			break;
		case 'document':
			cms_options = await kaialpha.lib.document.compute_searchable_item(full_record, external_data);
			break;
		case 'workflow':
			cms_options = await kaialpha.lib.workflow.compute_searchable_item(full_record, external_data);
			break;
		case 'folder':
			cms_options = await kaialpha.lib.folder.compute_searchable_item(full_record, external_data);
			break;
		case 'list':
			cms_options = await kaialpha.lib.list.compute_searchable_item(full_record, external_data);
			break;
		default:
			throw (new Error(`Unable to computed searchable item for type ${type}, unsupported type`));
	}

	return cms_options;
}

/**
 * Sleep Function
 * @param {number} time_ms - How much time to sleep
 */
async function async_sleep(time_ms) {
	const resolve_promise = new Promise(function(resolve) {
		setTimeout(() => {
			resolve();
		}, time_ms);
	});
	await resolve_promise;
}

/**
 * Truncate string and append ellipsis
 * @param {*} str input string
 * @param {*} length max length of string
 * @returns
 */
function truncate_string(str, length = 20){
	return((str.length > length) ? str.substr(0, length) + '...' : str);
}

/**
 * Interface to the CMS
 */
class GeneralCMS {
	/**
	 * @param {('document'|'template')} type - Type of KaiAlpha object
	 * @param {{ user_id: 'string' } | null} ka_context - Session information
	 * @param {any} contents - Information to seed creation
	 * @param {any} metadata - Information to store in the "metadata" property
	 */
	constructor(type, ka_context, contents, metadata) {
		this.metadata = metadata;
		this._ka_context = ka_context;

		this._user_id = null;
		if (ka_context) {
			this._user_id = ka_context.user_id;
		}

		let need_pull = false;

		this._new = true;
		this._changed = {};
		this._contents = {};
		if (contents && contents.id) {
			need_pull = true;
			this._new = false;
			this._id = contents.id;
			if (!contents.version || contents.version === 'HEAD') {
				this._writable = true;
			} else {
				this._writable = false;
			}

			if (contents.version) {
				this._version = contents.version;
				if (contents.permissions) {
					need_pull = false;
					this._contents = contents;
				}
			}
		}

		this._type = type;

		if (this._new) {
			throw(new Error('not implemented: building new items'));
		}

		if (need_pull && this._pull) {
			this._pull();
		}
	}

	/**
	 * Clean-up an instance of an object
	 */
	destroy() {
		for (const cleanup of ['metadata', '_ka_context', '_user_id', '_new', '_changed', '_contents', '_id', '_version', '_type', '_promise']) {
			delete this[cleanup];
		}
	}

	async _pull() {
		this._promise = (async () => {
			let version = 'HEAD';
			if (this._version) {
				version = this._version;
			}

			if (version.substr(0, 6) === '@date:') {
				/*
				 * Fetch the version of an item that existed at the specified
				 * date.  This should ultimately be done by the backend,
				 * but for now we will just do it on the frontend
				 */
				const check_date_str = version.substr(6);
				const check_date = new Date(check_date_str);
				if (isNaN(check_date.valueOf())) {
					throw(new Error(`Unable to parse date string ${check_date_str}`));
				}

				const existing_versions = await this.versions({ cacheID: null, allVersions: true });
				const existing_versions_reverse_sorted = existing_versions.sort(function(version_a, version_b) {
					return(version_b.date - version_a.date);
				});

				let found_version;
				for (const version_info of existing_versions_reverse_sorted) {
					if (version_info.date <= check_date) {
						found_version = version_info.version;

						break;
					}
				}

				if (!found_version) {
					const newest_version = existing_versions_reverse_sorted[0];
					if (check_date > newest_version.date) {
						found_version = 'HEAD';
					}
				}

				if (!found_version) {
					throw(new Error(`No such version exists for ${this._type} ID ${this._id} on date ${check_date_str} (${check_date})`));
				}

				version = found_version;

				/* XXX:TODO: If the found version is HEAD, should we mark it as writable ? */
			}

			const contents = await get_item_any_type(this._user_id, this._type, this._id, version);

			this._version = contents.version;
			this._contents = contents;
			this._changed = {};
		})();
	}

	/**
	 * Refresh the contents of the instance from the latest version
	 * available
	 *
	 * @returns {Promise<boolean>} Boolean indicating whether or not a change was required
	 */
	async refresh() {
		await this.wait();

		const contents = await get_item_any_type(this._user_id, this._type, this._id, 'HEAD');

		if (this._contents.version === contents.version) {
			return(false);
		}

		if (!this._writable) {
			// don't update the content if we are looking at a specific (or older) version
			return false;
		}

		if (this.version() === '@new') {
			throw(new Error('Cannot refresh while there are unsaved changes'));
		}

		this._contents = contents;
		this._version = contents.version;

		/* XXX:TODO: Notify relevant holders of change */

		return(true);
	}

	async _push(options = {}) {
		options = {
			force: false,
			strongForce: false,
			...options
		};

		/*
		 * If there is a save on-going, wait for it to complete -- we
		 * could have the promise completed but a new one replace it
		 * before our await finishes so we must repeatedly wait until
		 * we are ready to atomically take over (i.e., have no more
		 * yieldable sections before the critical section).
		 */
		while (this._save_promise) {
			await this._save_promise;
		}

		if (Object.keys(this._changed).length === 0) {
			return;
		}

		if (!this._writable) {
			/*
			 * If we have loaded an out-dated version, do not allow writing
			 * even if forced... unless REALLY forced
			 */
			if (!options.force || !options.strongForce) {
				throw(new Error('May not write to an out-dated version'));
			}
		}

		if (this._new) {
			/* Create */
			console.log(`Create new ${this._type}`);
			throw(new Error('not implemented'));
		} else {
			/*
			 * Perform an incremental change
			 */
			const diff = {
				delete: {},
				change: {}
			};

			const saved_changes = object_utils.copy_object(this._changed);
			const to_save = Object.keys(saved_changes);

			for (const key of to_save) {
				const new_value = this._contents[key];

				if (new_value === undefined) {
					diff.delete[key] = null;
				} else {
					diff.change[key] = new_value;
				}
			}

			let version_to_apply_over = this._version;
			if (options.force === true) {
				version_to_apply_over = 'HEAD';
			}

			/*
			 * Make a copy so we can continue to accept
			 * modifications while saving is occuring
			 */
			const diff_ro = object_utils.copy_object(diff);
			this._changed = {};

			this._save_promise = (async () => {
				const new_info = await _apply_diff_any_type(this._user_id, this._type, this._id, version_to_apply_over, null, diff_ro, options);

				this._contents.version = new_info.version;
				this._version = new_info.version;
			})();

			try {
				await this._save_promise;
			} catch (save_failed_error) {
				/*
				 * Re-add the things we would save back to the
				 * list of things to save
				 */
				this._changed = {
					...saved_changes,
					...this._changed
				};
				throw(save_failed_error);
			} finally {
				this._save_promise = undefined;
			}
		}

		this._new = false;
	}

	/**
	 * Assert that the item has already been waited for (throw an error if
	 * this is not the case) and return it, or a copy of it.
	 *
	 * @params {Object} [options] - Options for returning the item
	 * @params {boolean|undefined} [options.mutable] - Return a mutable (true - copy), immutable (false - read-only), or writable instance (undefined - changes will change the base object)
	 */
	_assert_contents(options = {}) {
		options = {
			mutable: undefined,
			...options
		};

		if (this._contents === undefined) {
			throw(new Error('must call wait() first'));
		}

		// State should be derived automatically and should not be undefined
		// TODO - Find better way to set content's state if state is undefined
		if (this._contents.state === undefined){
			this._contents.state = 'In Process'
		}

		if (options.mutable === true) {
			return(object_utils.copy_object(this._contents));
		}

		if (options.mutable === false) {
			return(Object.freeze({
				...this._contents
			}));
		}

		return(this._contents);
	}

	_get_contents() {
		return(this._contents);
	}

	/**
	 * Wait for all async initialization activity, must be called before
	 * the non-async methods may be called.
	 *
	 * @returns {Promise<void>} Nothing, but as a promise
	 */
	async wait() {
		if (!this._promise) {
			return;
		}

		await this._promise;

		this._promise = undefined;
	}

	/**
	 * Get the ID of the item, or "@new" if no item has yet be constructed
	 *
	 * @returns {('@new') | string} Item ID
	 */
	id() {
		if (this._new) {
			return('@new');
		}

		const contents = this._get_contents();

		return(contents.id);
	}

	/**
	 * Get the version of the item, or "@new" if there are outstanding
	 * changes that have not been pushed.
	 *
	 * @returns {('@new') | string} Version ID
	 */
	version() {
		if (Object.keys(this._changed).length > 0 || this._new) {
			return('@new');
		}

		const contents = this._get_contents();

		return(contents.version);
	}

	/**
	 * Get an array of all previous versions of the object, from the
	 * current version backwards
	 *
	 * @params {{cacheID?: string, allVersion?: boolean}} options - Options
	 * @returns {Promise<any[]>} Array of version information
	 */
	async versions(options = {}) {
		options = {
			cacheID: undefined,
			allVersions: false,
			...options
		};

		let cacheID = options.cacheID;
		if (cacheID === undefined) {
			await this.wait();

			cacheID = this._get_contents().version;
			if (this._versions_cache) {
				if (this._versions_cache.cacheID === cacheID) {
					return(this._versions_cache.cacheData);
				}
			}
		}

		const versionsInfo = await get_item_versions_any_type(this._user_id, this._type, this._id);
		if (!versionsInfo || !versionsInfo.versions) {
			/* XXX:TODO: There really should always be a version... */
			return([]);
		}

		const all_versions = versionsInfo.versions.map(function(versionInfo) {
			return({
				...versionInfo,
				date: new Date(versionInfo.date)
			});
		});

		let versions;
		if (options.allVersions) {
			versions = all_versions;
		} else {
			/*
			 * Limit the list of versions to the current version or older
			 */
			await this.wait();

			const current_version = this._get_contents().version;
			const current_version_info = all_versions.find(function(version_info) {
				if (version_info.version === current_version) {
					return(true);
				}

				return(false);
			});

			const current_version_date = current_version_info.date;

			versions = all_versions.filter(function(version_info) {
				if (version_info.date > current_version_date) {
					return(false);
				}

				return(true);
			});
		}

		if (cacheID !== null && !options.allVersions) {
			this._versions_cache = {
				cacheID: cacheID,
				cacheData: versions
			};
		}

		return(versions);
	}
}

function hash(str, len = 16) {
	/*
	 * Since we use a pre-processor to disable the Node-only "require"
	 * eslint thinks we should use const, but that would be broken when
	 * the preprocessor removes the assignment.
	 */
	// eslint-disable-next-line
	let crypto;

	let hash_function;
	if (crypto) {
		hash_function = crypto.createHash('sha256');
	} else {
		const shajs = require('sha.js');
		hash_function = shajs('sha256');
	}

	hash_function.update(str);
	const hash = hash_function.digest('hex');

	if (len > 0) {
		return(hash.slice(0, len));
	}

	return(hash);
}

const _to_export_auto = {
	GeneralCMS,
	rename_item_any_type,
	update_permissions_any_type,
	update_permissions_any_type_add_inherit_parent,
	update_permissions_any_type_delete_inherit_parent,
	delete_item_any_type,
	get_item_any_type,
	get_item_versions_any_type,
	get_canonical_permissions_any_type,
	get_canonical_permissions_and_roles_any_type,
	get_url_path_any_type,
	get_cms_options_any_type,
	compute_searchable_item_any_type,
	async_sleep,
	truncate_string,
	hash
};
export default _to_export_auto;
