import { Context, DefaultContextButtons } from "../../../shared/components/buttons/theme";
import { hasAccess, Role } from "../../../shared/interfaces/Permissions";

const WorkflowContextButtons = {
	request_review: DefaultContextButtons.RequestReview,
	request_approval: DefaultContextButtons.RequestApproval,
	complete_review: DefaultContextButtons.CompleteReview,
	cancel_review_cycle: DefaultContextButtons.CancelReview,
	final_review_approve: DefaultContextButtons.Approve,
	final_review_reject: DefaultContextButtons.Reject,
	cancel_approval_process: DefaultContextButtons.CancelApproval
}

// buttons does not have a strict schema
export function getUserContextButtons(buttons, userRoles: string[]) : Context[] {
	const contextButtons: Context[] = [];
	buttons.forEach((button) => {
		if (hasAccess(userRoles as Role[], button.permissions?.acl?.write ?? [])) {
			const contextButton = workflowContextButton(button);
			if (contextButton) {
				contextButtons.push(contextButton);
			} else {
				console.error('Button provided that is not defined', button);
			}
		}
	})

	return contextButtons;
}

export function workflowContextButton(button): Context {
	return WorkflowContextButtons[button.id];
}