import {styled} from "@mui/material";
import { FlexBox } from "../../layout";
import { BasePopup } from "../BasePopup";

const Alert = styled(BasePopup)(({
	margin: "0 auto",
	"&.alert .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded ": {
		minWidth: "0 !important"
	},
}));

const Content = styled(FlexBox)(({
	padding: "1.5rem 2rem",
	boxSizing: "border-box",
}));

const MessageBoxTitle = styled(FlexBox)(({theme}) => ({
	justifyContent: 'center',
	alignItems: 'center',
	marginBottom: "1rem",
	boxSizing: "border-box",
	color: theme.palette.primary.main,
	fontSize: "1.4rem",
	fontWeight: 800,
}));

const NoteBox = styled(FlexBox)(({theme}) => ({
	justifyContent: 'center',
	alignItems: 'center',
	margin: "0.4rem 0",
	padding: "0.7rem",
	boxSizing: "border-box",
	textAlign: "center",
	backgroundColor: theme.palette.tagsChip?.main,
	color: theme.palette.tabs?.main,
	fontSize: ".95rem",
	'& b': {
		fontWeight: 800,
	}
}));

const ContentMessage = styled(FlexBox)(({
	justifyContent: 'center',
	alignItems: 'center',
	margin: "0.8rem 0",
	padding: "0.5rem 1.7rem",
	boxSizing: "border-box",
	textAlign: "center",
	fontWeight: 400,
	fontSize: "1.2rem",
	'& b': {
		fontWeight: 800,
	}
}));

export {Alert, Content, ContentMessage, NoteBox, MessageBoxTitle}