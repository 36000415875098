import {TabDefinition} from "../../../../../shared/components/layout/tabs/tabDefinition";
import {TemplateState, useElements} from "../../../hooks";
import {VariableElement} from "../../../models/elements";
import {PropertyEditor} from "../../modals";
import {useMemo, useRef, useState} from "react";
import {VariableList} from "../../list/VariableList";
import {Button, FlexBox} from "../../../../../shared/components";
import {SearchBar} from "../../../../../shared/components/input/search/SearchBar";
import {Add, CloudDownload, CloudUpload} from "@mui/icons-material";
import { CSVLink } from "react-csv";
import "./style.scss";
import {useVariables} from "../../../hooks/useVariables";
import {EmptyGroup} from "../../../../../shared/components/layout/groups/EmptyGroup";
import {ElementPropertyData} from "../../elements/model";
import {VariableUploadCard} from "./VariableUploadCard";
import { TemplateElement } from "../../../../../shared/interfaces/TemplateElement";
const uuid = require("uuid");
const clone = require("rfdc/default");

const createVariable = (): VariableElement => ({
	id: uuid.v4(),
	contents: {
		type: "text"
	},
	type: "variable",
	index: 0,
	depth: 0,
});

interface VariableListState {
	selectedVariable: TemplateElement | null,
	searchValue: string,
	downloadCsv: boolean
}
const TabView = () => {
	const templateManager = TemplateState.useContainer();
	const variablesManager = useVariables();
	const elementsManager = useElements();
	const uploadBtnRef = useRef<HTMLButtonElement>(null);
	const [state, setState] = useState<VariableListState>({selectedVariable: null, searchValue: "", downloadCsv: false});
	const [displayUploadCard, setDisplayUploadCard] = useState(false);
	const isReadonly = !templateManager.editable;

	// event handlers
	// this could be debounced.
	const onSearchValueChange = (searchValue) => setState(s => ({...s, searchValue}));
	const onAddElement = () => setState(s => ({...s, selectedVariable: createVariable()}));
	const onDownload = () => setState(s => ({...s, downloadCsv: true}));
	const onEditVariable = (variable: VariableElement) => setState(s => ({...s, selectedVariable: variable}));
	const onClosePropertyEditor = () => setState(s => ({...s, selectedVariable: null}));
	const onDeleteVariable = (variable: VariableElement) => elementsManager.onRemoveElement(variable);
	const onUpload = () => setDisplayUploadCard(true);
	const onCancelUpload = () => setDisplayUploadCard(false);

	const onUploadData = (data: {[key: string]: string}[]) => {
		data.map(datum => Object.keys(datum).reduce((variables, key) => {
			variables[key.replace(/\W+/gm, "_").toLowerCase()] = datum[key];
			return variables;
		}, {} as {[key: string]: string})).map(variableData => {
			const variable = createVariable();
			variable.contents.type = variableData.type;
			variable.contents.name = variableData.name;
			variable.contents.description = variableData.description;
			variable.contents.required = variableData.required ?? false;

			if (variableData.type === "datasource") {
				variable.contents.options = {
					source: variableData.source,
					type: variableData.datasource_type,
					row_headers: variableData.row_headers?.split(",") ?? [],
					column_headers: variableData.column_headers?.split(",") ?? []
				}
			} else {
				variable.contents.options = {
					items: variableData.options?.split(",") ?? []
				}
			}

			return variable;
		})
			.filter(variable => !variablesManager.allVariables.some(v => v.contents.name === variable.contents.name))
			.forEach(variable => elementsManager.addElement(variable, null));
		onCancelUpload();
	}

	const onUpdateElementData = (updatedContent: ElementPropertyData) => {
		if (!state.selectedVariable) {
			return;
		}

		let element = state.selectedVariable;
		if (!elementsManager.findById(element.id)) {
			element = elementsManager.addElement({...element, contents: clone(updatedContent.data)}, null);
		} else {
			elementsManager.changeElement(updatedContent, element.id);
		}

		onClosePropertyEditor();
	}

	// memorized potentially expensive object
	const variables = useMemo(() => variablesManager.filterVariables(state.searchValue),
		[state.searchValue, variablesManager]);

	const csvData = useMemo(() =>
		variablesManager.getCsvData(state.searchValue, templateManager.template?.name),
	[state.searchValue, variablesManager, templateManager.template?.name])

	return <div className="rp__variable--tab">
		<FlexBox style={{margin: "0.5rem 0 1rem 0"}} justify={"space-between"} align={"center"}>
			<SearchBar placeholder="Search" onChange={onSearchValueChange} containerStyle={{margin: "0 1rem", flex: 1}}/>
			{!isReadonly && <>
				<div className={"button-wrapper"}>
					<Button buttonType="plain" styling="inverted" key={"variable-add"} icon={<Add fontSize={"small"} />}
						onClick={onAddElement} />
				</div>
				<div className={"button-wrapper"}>
					<CSVLink data={csvData.data} headers={csvData.headers} filename={(templateManager.template?.name ?? "bulk_create") + ".csv"}>
						<Button buttonType="plain" styling="inverted" key={"variable-download"} icon={<CloudDownload fontSize={"small"} />}
							onClick={onDownload} />
					</CSVLink>
				</div>
				<div className={"button-wrapper"}>
					<Button ref={uploadBtnRef} buttonType="plain" styling="inverted" key={"variable-download"} icon={<CloudUpload fontSize={"small"} />}
						onClick={onUpload} />
				</div>
			</>}
		</FlexBox>
		{(!variables || variables.length === 0) && state.searchValue.length > 0 && <EmptyGroup title="No variables match your search"></EmptyGroup> }
		{(!variables || variables.length === 0) && state.searchValue.length === 0 && <EmptyGroup title="Please add variables"></EmptyGroup> }
		<VariableList
			variables={variables}
			onEdit={!isReadonly ? onEditVariable : undefined}
			onDelete={!isReadonly ? onDeleteVariable : undefined} />
		{state.selectedVariable && <PropertyEditor
			value={{
				id: state.selectedVariable.id,
				elementInformation: {type: state.selectedVariable.type, writable: true},
				data: {...state.selectedVariable.contents, description: state.selectedVariable.description}
			}}
			type={state.selectedVariable.type}
			variables={templateManager.variables}
			onApply={onUpdateElementData}
			dataSourcesList={templateManager.datasources?.items ?? []}
			onClose={onClosePropertyEditor} />}
		<VariableUploadCard onData={onUploadData} open={displayUploadCard} onCancel={onCancelUpload} anchorEl={uploadBtnRef.current}/>
	</div>
}

const VariablesTab: TabDefinition = {
	name: "Variables",
	content: TabView
}

export {VariablesTab};