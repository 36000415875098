import { VariableEditor, VariableEditorProps } from "./model";
import {DataSourceOptions} from "../../../../../../shared/hooks/api/data/useGetDataSource";
import {PropertyList} from "../../../elements/PropertyList";
import {Description} from "./DescriptionLabel";


const Editor = (props: VariableEditorProps) => {
	const value = props.variable.options as (DataSourceOptions | undefined);
	const properties = [
		{label: "Source", value: value?.source ?? "N/A"},
		{label: "Columns", value: value?.column_headers?.join(", ") ?? "N/A"},
		{label: "Rows", value: value?.row_headers?.join(", ") ?? "N/A"},
		{label: "Type", value: value?.type ?? "N/A"}
	]
	return <>
		<PropertyList items={properties} />
		<Description text={props.variable.description} />
	</>
}

const DataSourceEditor: VariableEditor = {
	type: "datasource",
	view: Editor
};

export { DataSourceEditor };