import {useState} from 'react';
import { MessageBoxActions, MessageBox } from "../../../index";
import { MessageBoxActionType } from "../../../modals/messageBox/models";
import { styled } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { CenteredContainer } from "../../CenteredContainer";
import {ButtonProps} from "./Button.model";
import {ButtonBase} from "./ButtonBase";
import { Tooltip } from '../../../labels/Tooltip';

const DeleteButtonContainer = styled(ButtonBase)(({theme}) => ({
	backgroundColor: theme.palette.destructive?.main,
	color: theme.palette.primary?.contrastText,
	"&:hover": {
		backgroundColor: theme.palette.secondary?.contrastText,
		color: theme.palette.destructive?.main,
	}
}));

const DeleteButton = ({onClick, skipConfirmation, tooltipText}: ButtonProps & {skipConfirmation?: boolean, tooltipText?: string}) => {
	const [confirmDelete, setConfirmDelete] = useState(false);

	const onDelete = () => {
		if (skipConfirmation) {
			onClick();
			return;
		}
		setConfirmDelete(true);
	}

	const onMessageBoxAction = (action: MessageBoxActionType) => {
		if (action === MessageBoxActions.Primary) {
			onClick!();
		}
		setConfirmDelete(false);
	}

	const onMessageBoxClose = () => onMessageBoxAction(MessageBoxActions.Secondary)

	return <>
		<Tooltip title={tooltipText ?? "Delete Item"} placement="top">
			<DeleteButtonContainer disableRipple onClick={onDelete}>
				<CenteredContainer>
					<DeleteOutline style={{fontSize: '19px'}}/>
				</CenteredContainer>
			</DeleteButtonContainer>
		</Tooltip>
		<MessageBox
			visible={confirmDelete}
			message={"Are you sure you wish to remove this item?"}
			primaryAction={"Yes"}
			secondaryAction={"Cancel"}
			onActionClick={onMessageBoxAction}
			onClose={onMessageBoxClose}
		/>
	</>
}

export {DeleteButton}