import {Box} from "@mui/material";
import { FlexBox } from "../../layout";
import {StatusBarContext, StatusNotificationWithId} from "./useStatusBar";
import "./style.scss";
import {useState} from "react";
import {MessageBox} from "../../modals";
import {StatusTypeIcon} from "./icons/StatusTypeIcon";
import {NotificationErrorType} from "./models";

interface StatusBarState {
	showDialog: boolean,
	selectedNotification: StatusNotificationWithId | null
}

const getErrorMessage = (error: NotificationErrorType): string => {
	if (!error) {
		return "";
	}

	return error instanceof Error ? (error as Error).message : error.toString();

}
const StatusBar = () => {
	const statusBarContext = StatusBarContext.useContainer();
	const [state, setState] = useState<StatusBarState>({showDialog: false, selectedNotification: null})

	const lastNotification = statusBarContext.getLastMessage();

	const onNotificationClickFactory = (notification: StatusNotificationWithId | null) => () => {
		if (notification?.detail) {
			setState(s => ({...s, showDialog: true, selectedNotification: notification!}));
		} else if (notification?.id) {
			statusBarContext.onRemoveNotification(notification!.id)
		}
	}

	const onCloseNotificationDetail = () => {
		if (state.selectedNotification) {
			statusBarContext.onRemoveNotification(state.selectedNotification!.id)
		}

		setState(s => ({...s, showDialog: false, selectedNotification: null}));
	}

	return (<>
		<div className={"mh__statusbar"} onClick={onNotificationClickFactory(lastNotification)}>
			{lastNotification && <Box className={"mh__statusbar-container"}>
				<FlexBox align={"center"} justify={"center"} className={lastNotification.type}>
					<StatusTypeIcon type={lastNotification.type} />
					<p>{lastNotification.message}</p>
				</FlexBox>
			</Box>}
		</div>
		<MessageBox visible={state.showDialog} message={getErrorMessage(state.selectedNotification?.detail)} primaryAction={"Ok"} onActionClick={onCloseNotificationDetail} onClose={onCloseNotificationDetail} />
	</>)
}

export {StatusBar};