import {TemplateState} from "../../useTemplateState";
import {useSaveBuffer} from "../../api/useSaveBuffer";
import {useAsync, useRunWhenValueChange} from "../../../../../shared/hooks";
import {useBuildTemplate} from "./useBuildTemplate";
import {useFormatExpressionElements} from "./useFormatExpressionElements";
import {useStatusBar} from "../../../../../shared/components";
import {Action} from "../../../../../shared/interfaces/ActionRun";

function useSaveAction(): Action {
	const state = TemplateState.useContainer();
	const saveBuffer = useSaveBuffer();
	const buildTemplate = useBuildTemplate();
	const formatExpressionElements = useFormatExpressionElements();
	const statusBar = useStatusBar();

	const saveTemplate = () => {
		const template = buildTemplate(state.buffer, state.variables, state.elements);

		if (!template) {
			throw new Error("template is null")
		}

		template.body = formatExpressionElements(template.body ?? []);
		return saveBuffer.execute(template, state.template!.id, state.template!.version);
	}

	const actionRunner = useAsync(saveTemplate, false);

	useRunWhenValueChange(() => {
		statusBar.sendSuccessNotification({message: "Template saved"});
	}, actionRunner.value);

	// show save buffer error if one exists
	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error saving template", detail: saveBuffer.error});
	}, saveBuffer.error);


	const run = () => actionRunner.execute();
	return {run, isRunning: actionRunner.isLoading};
}

export {useSaveAction};