import {Submission} from "../../models/Submission";
import api from "../../../../api/api";
import {useTypedAsync} from "../../../../shared/hooks";
import {createSaveSubmissionPayload, SaveSubmissionPayload} from "./createSubmissionPayload";

export type LegacyCreateSubmissionResponse = {
	contentId: string,
	versionId: string
}

export type CreateSubmissionResponse = {
	id: string,
	version: string
}

export function isLegacyCreateResponse(obj: LegacyCreateSubmissionResponse | CreateSubmissionResponse): obj is LegacyCreateSubmissionResponse {
	return obj["contentId"] !== undefined;
}

export function isCreateResponse(obj: LegacyCreateSubmissionResponse | CreateSubmissionResponse): obj is CreateSubmissionResponse {
	return obj["id"] !== undefined;
}
function createSubmissionApi(submission: SaveSubmissionPayload): Promise<LegacyCreateSubmissionResponse | CreateSubmissionResponse> {
	return api.call("POST", "ectd/submission", submission);
}

function useCreateSubmission() {
	const createSubmission = (submission: Submission) => createSubmissionApi(createSaveSubmissionPayload(submission));
	return useTypedAsync(createSubmission, false);
}

export {useCreateSubmission}