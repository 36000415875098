import { styled } from "@mui/material";
import { RateReview } from "@mui/icons-material";
import { CenteredContainer } from "../../CenteredContainer";
import {ButtonProps} from "./Button.model";
import {ButtonBase} from "./ButtonBase";

const AddButtonContainer = styled(ButtonBase)(({theme}) => ({
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.tabs?.border,
	"&:hover": {
		backgroundColor: theme.palette.primary?.contrastText,
		color: theme.palette.primary.main
	}
}));

const RequestEditButton = ({onClick}: ButtonProps) => <AddButtonContainer onClick={onClick}>
	<CenteredContainer>
		<RateReview style={{fontSize: '19px'}}/>
	</CenteredContainer>
</AddButtonContainer>

export {RequestEditButton}