/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/client/api.mjs
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../lib/kaialpha';
/* XXX:TODO: Move this to somewhere shared between the client and server */
const MAX_REQUEST_SIZE = (5 * 1024 * 1024);

async function proxy_payload(payload_string, _this = undefined) {
	/*
	 * Request a token+endpoint to proxy the request
	 */
	const proxy_info = await call.call(_this, 'POST', 'proxy/api', {}, {
		may_proxy: false
	});

	if (!(proxy_info instanceof Object)) {
		throw(new Error('Failed to get endpoint to proxy large payload body: invalid object type'));
	}

	if (proxy_info.endpoint === undefined) {
		throw(new Error('Failed to get endpoint to proxy large payload body: missing endpoint'));
	}

	if (proxy_info.proxy === undefined) {
		throw(new Error('Failed to get endpoint to proxy large payload body: missing proxy token'));
	}

	/*
	 * Upload the data to the endpoint
	 */
	const endpoint = proxy_info.endpoint;
	const upload_body = new FormData();

	for (const field of Object.keys(endpoint.fields)) {
		upload_body.append(field, endpoint.fields[field]);
	}
	upload_body.append('file', payload_string);

	const response = await fetch(endpoint.url, {
		method: 'POST',
		body: upload_body
	});

	if (!response.ok) {
		throw(new Error('Failed to proxy large payload body'));
	}

	/*
	 * Return the token, which can be used in place of the
	 * payload
	 */
	const token = proxy_info.proxy;
	return(JSON.stringify(token));
}

export async function call(method, path, payload, options = {}) {
	const default_options = {
		retryable: false,
		may_proxy: true,
		wait_for_promise: true,
		auth_callback: function() {
			/* XXX:TODO: Force authentication */
			/* Right now this will only work with Web-based client */
			document.location.href = `/cognito?redirectBackTo=${encodeURIComponent(document.location.href)}`;
		}
	};

	let site = '';
	if (this !== undefined && this._site !== undefined) {
		site = this._site;
		site = site.replace(/\/$/, "");
	}

	options = Object.assign(default_options, options);
	const url = `${site}/api/v1/${path}`;
	const fetch_options = {
		method,
		headers: {}
	};

	if (['POST', 'PUT', 'PATCH'].includes(method)) {
		const payload_string = JSON.stringify(payload);
		fetch_options.headers['Content-Type'] = 'application/json';

		if (payload_string) {
			if (options.may_proxy !== true || payload_string.length < MAX_REQUEST_SIZE) {
				fetch_options['body'] = payload_string;
			} else {
				fetch_options['body'] = await proxy_payload(payload_string, this);
			}
		}
	}

	if (this !== undefined && this._cookies !== undefined) {
		fetch_options.headers['Cookie'] = this._cookies;
	}

	let response;
	for (let retries = 0;; retries++) {
		try {
			response = await fetch(url, fetch_options);
		} catch (fetch_error) {
			/*
			 * Detect when API calls cannot be completed because
			 * user is not authenticated and redirect the user to
			 * the authentication page
			 */
			/**
			 ** XXX:TODO: This doesn't actually check that the
			 **           issue is related to authentication so
			 **           isn't that great of a check
			 **/
			if (options.auth_callback) {
				options.auth_callback();
			}
			throw(fetch_error);
		}

		if (!response) {
			response = {
				ok: false,
				status: 500,
				json: async function() {
					return({});
				},
				text: async function() {
					return('{}');
				},
				headers: {
					entries: function() {
						return([]);
					}
				}
			};
		}

		if (options.wait_for_promise === true) {
			response = await kaialpha.lib.promise_utils.wait_for_promise(response);
		}

		if (!response.ok) {
			/*
			 * User Errors and Resource Not Found errors are not retryable, determine if this is the case
			 */
			if (response.status === 500) {
				const error_info = await response.json();
				if (error_info.user_error) {
					/*
					 * Pass this up to the user
					 */
					/** XXX:TODO **/

					/*
					 * Throw this error
					 */
					throw(new Error(error_info.error));
				}
			}
			if (response.status === 404) {
				const error_info = await response.json();
				if (error_info.resource_not_found_error) {
					/*
					 * Pass this up to the user
					 */
					/*
					 * Throw this error
					 */
					throw(new Error(error_info.error));
				}
			}

			// version conflict errors
			if (response.status === 409) {
				const error_info = await response.json();
				/*
				* Pass this up to the caller
				*/
				/** @type {Error & { code?: string }} */
				const error = new Error(error_info.error);
				error.code = error_info.code;
				throw error;
			}

			const should_retry = true;
			let backoff_multiplier = 1;
			if (response.status === 504) {
				/*
				 * If we got an HTTP error 504 (Gateway Timeout), chances of retrying
				 * working are low if we do it right now, back off slowly.
				 */
				backoff_multiplier = 5;
			}

			if (should_retry && options.retryable && retries < 20) {
				const sleep_base = 50;
				let sleep_time = sleep_base;
				if (backoff_multiplier > 1) {
					sleep_time = sleep_base * retries * backoff_multiplier;
				}

				await kaialpha.lib.general_utils.async_sleep(sleep_time);

				continue;
			}

			throw(new Error(`Failed to make API call to ${url}: ${JSON.stringify(response)}; Headers: ${JSON.stringify(response.headers.entries())}`));
		}
		break;
	}

	let retval;
	const response_content_type = response.headers.get('content-type');
	if (response_content_type.match(/^[^/]*\/json($|;)/)) {
		retval = await response.json();
	} else {
		retval = await response.text();
	}

	return(retval);
}

const _to_export = {
	call
};
export default _to_export;
