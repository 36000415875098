import React from 'react';
import {
	Box,
	defaultStyle,
} from '../../../lib/ui';
import LazyTreeView from '../../LazyTreeView';
import { push_status_task } from '../../StatusBar';
import { withStyles } from '@mui/styles'; // LEGACY

import list from '../../../api/list.js';
import list_utils from '../../../lib/utils/list_utils.js';
import { AppChrome } from '../../App/Chrome/AppChrome';
import { ContextButtonFactory, DefaultContextButtons } from "../../../shared/components/buttons/theme";
import { Check, CheckBoxOutlineBlankRounded } from "@mui/icons-material";
import { MessageBoxActions } from "../../../shared/components";
import user from '../../../api/user.js';
import { withRouter } from "../../../lib/legacy/withRouter";
const csv = {
	parse: require('csv-parse/lib/sync')
};

class ListsSelector extends React.Component {
	constructor(props, ...args) {
		super(props, ...args);

		this.onContextButtonClick = this.onContextButtonClick.bind(this);
		this.onConfirmDelete = this.onConfirmDelete.bind(this);
		this.onCancelDelete = this.onCancelDelete.bind(this);
		this.onMessageBoxAction = this.onMessageBoxAction.bind(this);
		this.onUpload = this.onUpload.bind(this);

		this.input = React.createRef();

		let type = props.type;
		if (type === undefined) {
			if (props.match && props.match.params && props.match.params.type) {
				type = props.match.params.type;
			}
		}

		if (type) {
			this.state = {
				type: type,
			};
		}

		this.tree = React.createRef();

		this.mounted = false;
		this.load_lists();

		/*
		 * Find the current user ID
		 */
		this.current_uid = user.get_user_id();
	}

	componentDidMount() {
		this.mounted = true;
	}

	onContextButtonClick(buttonKey) {
		switch (buttonKey) {
			case DefaultContextButtons.Edit.key:
				return this.props.history.push(`/activity/listseditor/${this.state.type}/${this.state.selected_id}`);
			case DefaultContextButtons.Delete.key:
				return this.setState({ isDeleting: true });
			case "makeDefault":
				return this.make_list_default(this.state.selected_id);
			case "makeNotDefault":
				return this.make_list_not_default(this.state.selected_id);
			case DefaultContextButtons.Import.key:
				this.input.current?.click();
				break;
			default:
				break;
		}
	}

	onCancelDelete() {
		this.setState({ isDeleting: false });
	}

	onMessageBoxAction(action) {
		if (action === MessageBoxActions.Primary) {
			this.onConfirmDelete();
			return;
		}
		this.onCancelDelete();
	}
	async onConfirmDelete() {
		this.delete_list(this.state.selected_id);
	}

	async load_lists() {
		const lists_info = await list.get_user_lists(null, this.state.type, {
			fields: ['name', 'permissions', 'default']
		});

		const lists = lists_info[this.state.type];

		if (this.mounted) {
			this.setState({
				lists: lists
			});
		} else {
			this.state.lists = lists;
		}
	}

	async refresh_lists() {
		await this.load_lists();

		if (this.tree.current) {
			this.tree.current.load_toplevel_items();
		}
	}

	async delete_list(id) {
		await push_status_task('deleting list', async () => {
			await list.delete_user_list(null, this.state.type, id)

			this.setState({
				selected_id: undefined
			});

			await this.refresh_lists();
		});
	}

	async make_list_default(id) {
		await push_status_task('setting default', async () => {
			try {
				const current_default_info = await list_utils.get_user_default_list(null, this.state.type, {
					fields: ['id', 'version']
				});

				if (current_default_info !== undefined) {
					/*
					 * If the current default is already this one, do nothing
					 */
					if (current_default_info.id === id) {
						return;
					}

					const current_default = await list.get_user_list(null, this.state.type, current_default_info.id, current_default_info.version);

					delete current_default['default'];

					await list.update_user_list(null, this.state.type, current_default.id, current_default, 'Removing the default');
				}
			} catch (ignored_error) {
				/* We ignore this error, we will catch the setting error */
				console.error('[IGNORED ERROR]', ignored_error);
			}

			const new_default = await list.get_user_list(null, this.state.type, id);
			new_default.default = true;

			await list.update_user_list(null, this.state.type, new_default.id, new_default, 'Setting as default');

			await this.refresh_lists();
		});
	}

	async make_list_not_default(id) {
		await push_status_task('unsetting default', async () => {
			const old_default = await list.get_user_list(null, this.state.type, id);
			delete old_default['default'];

			await list.update_user_list(null, this.state.type, old_default.id, old_default, 'Removing default');

			await this.refresh_lists();
		});
	}

	async upload_list(file) {
		const csv_data = await file.text();
		const data = csv.parse(csv_data, {/* No options right now */ });

		let existing_list_info = undefined;
		const is_default = true;
		try {
			/*
				 * Get the default list ID and version
				 */
			existing_list_info = await list_utils.get_user_default_list(null, this.state.type, {
				fields: ['id', 'version', 'permissions', 'lastupdated']
			});
		} catch (ignored_error) {
			/* This error is ignored and we assume it means there is no existing list */
		}

		if (existing_list_info === undefined) {
			existing_list_info = {};
		}

		const new_list = {
			name: `Master ${this.state.type} list`,
			default: is_default,
			permissions: {
				owners: [await this.current_uid],
				acl: { read: ['@role:readers'] },
				roles: {
					readers: ['@all']
				}
			},
			...existing_list_info,
			id: existing_list_info.id,
			version: existing_list_info.version,
			type: this.state.type,
			entries: []
		}

		for (const row of data) {
			new_list.entries.push({
				key: row[0],
				value: row[1]
			});
		}

		let retval;
		if (existing_list_info.id === undefined) {
			retval = await list.new_user_list(null, this.state.type, new_list);
		} else {
			retval = await list.update_user_list(null, this.state.type, existing_list_info.id, new_list,`Added New ${this.state.type}`);
		}

		return retval;
	}

	async onUpload(event) {
		const files = event.target.files;
		await push_status_task('Uploading list', async () => {
			await this.upload_list(files[0]);
		}, {
			finally: () => {
				this.refresh_lists();
			}
		});
	}

	render() {
		let tree;
		if (this.state.lists !== undefined) {
			tree = <LazyTreeView
				ref={this.tree}
				computeItem={async function (item) {
					const new_item = await LazyTreeView.define_item(item);
					new_item.default = item.default;
					return (new_item);
				}}
				renderItemLabel={function (item) {
					let suffix = '';
					if (item.default === true) {
						suffix = ' [Default]';
					}
					const label = `${item.name} [Owner: ${item.owner}]${suffix}`;

					return (label);
				}}
				getChildren={async (id, version) => {
					/* Currently no children are supported */
					if (id !== undefined) {
						return ([]);
					}

					return (this.state.lists);
				}}
				onClick={(id) => {
					this.setState({
						selected_id: id
					});
				}}
			/>;
		}

		return (<AppChrome
			center={<div className={this.props.classes.content}>
				<div className={this.props.classes.header}>Select List</div>
				<Box height='30em' className={this.props.classes.tree}>{tree}</Box>
				<input
					accept='.csv'
					style={{ display: 'none' }}
					id={this.state.id}
					type="file"
					ref={this.input}
					onChange={this.onUpload}
				/>
			</div>}
			mastHeadContextButtons={ContextButtonFactory([
				{ ...DefaultContextButtons.Edit, disabled: !this.state.selected_id },
				{ ...DefaultContextButtons.Delete, disabled: !this.state.selected_id },
				{ label: "Make Default", key: "makeDefault", Icon: Check, disabled: !this.state.selected_id },
				{ label: "Make Not Default", key: "makeNotDefault", Icon: CheckBoxOutlineBlankRounded, disabled: !this.state.selected_id },
				{ ...DefaultContextButtons.Import, label: "Import List" },
			], this.onContextButtonClick)}
		/>);
	}
}

export default withRouter(withStyles(defaultStyle)(ListsSelector));
