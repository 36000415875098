import {Column, ColumnAccessoryViewProps, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {FlexBox} from "../../../../../../shared/components";
import {TemplateListActions, TemplateListNode} from "../models";
import {DeleteButton} from "../../../../../../shared/components/layout/box/editControlBox/DeleteButton";
import {TasksButton} from "../../../../../../shared/components/layout/box/editControlBox/TasksButton";
import {DataTableCell} from "../../../../../../shared/components/layout/table/styles";


const ActionsCell = (props: ColumnViewProps) => {
	const item = props.item as TemplateListNode;
	const column = props.column;

	const onViewTasks = () => {
		if (props.onAction) {
			props.onAction(TemplateListActions.view, props.column);
		}
	}

	const onDeleteTemplate = () => {
		if (props.onAction) {
			props.onAction(TemplateListActions.delete, props.column);
		}
	}

	return <DataTableCell
		align={column.itemAlign}
		width={column.width}
		key={`${column.title}-${item.id}`}
		// for non-data displaying cells, allow full width of cell
		sx={{...(!column.field && {paddingLeft: 0})}}
	>
		<FlexBox boxSizing="border-box" paddingLeft="0.7rem" paddingRight="0.5rem" justify="space-between">
			<TasksButton tooltipText={item.hasTask ? "View Tasks" : "No Tasks"} onClick={onViewTasks} disabled={!item.hasTask}/>
			<DeleteButton tooltipText={"Delete Template"} skipConfirmation onClick={onDeleteTemplate}/>
		</FlexBox>
	</DataTableCell>

}

const ActionsHeader = (props: ColumnAccessoryViewProps) => <FlexBox justify={"space-between"}>
	<p>Tasks</p>
	<p>Delete</p>
</FlexBox>


const Actions: Column = ({
	field: "",
	title: "Actions",
	headerAlign: "left",
	itemAlign: "left",
	width: "10%",
	views: {
		cell: ActionsCell,
		header: ActionsHeader
	}
})

export {Actions};