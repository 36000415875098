import { styled} from "@mui/material";
import {BaseButton} from "../BaseButton";
import { ThemeButtonProps } from "./ThemeButton.models";
import { CenteredContainer } from "../../layout";

const FileSelectionButtonBase = styled(BaseButton)(({theme}) => ({
	backgroundColor: theme.palette.fileButton?.main,
	color: theme.palette.fileButton?.contrastText,
	textTransform: 'none',
	fontWeight: 'bold',
	padding: "15px",
	border: `1px solid ${theme.palette.fileButton?.contrastText}`,
	"&:hover": {
		backgroundColor: theme.palette.scrollbar?.hover,
		color: theme.palette.fileButton?.main
	}
}));


const FileSelectionButton = ({onClick}: ThemeButtonProps) => <FileSelectionButtonBase onClick={onClick}>
	<CenteredContainer>
		Choose File...
	</CenteredContainer>
</FileSelectionButtonBase>

export {FileSelectionButton}