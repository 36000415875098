import {styled} from "@mui/material";
import {ReadonlyElementProps} from "../../model";
import {EmptyView} from "../../EmptyView";
import { FooterElementValue } from "../../../../../../shared/definitions/elements/footer/model";

const Footer = styled("p")({
	fontWeight: 600,
	fontSize: "2rem",
	margin: 0
})
const ReadonlyView = (props: ReadonlyElementProps) => {
	const data = props.data as FooterElementValue;
	return data.value ? <Footer>{data.value}</Footer> : <EmptyView message={"No footer has been added"} />
}

export {ReadonlyView};