import { FilterState } from "../../input/filter/sort/models";
import { TreeNode } from "./TreeList.models";


export function search(searchValue: string, nodes: TreeNode[]): TreeNode[] {
	return nodes.reduce((result : TreeNode[], node) => {
		if (node.name.toLowerCase().includes(searchValue)) {
			result.push(node);
		} else if (node.children.length > 0) {
			const childSearch = search(searchValue, node.children);
			if (childSearch.length > 0) {
				node.children = childSearch;
				result.push(node);
			}
		}
		return result;
	}, [])

}

export function sortByType(sortState: FilterState, nodes: TreeNode[]): TreeNode[] {
	const sorted = nodes.sort((a, b) => {
		if (sortState === "ascending") {
			if(a.type < b.type) { return -1; }
			if(a.type > b.type) { return 1; }
			return 0;
		} else {
			if(a.type > b.type) { return -1; }
			if(a.type < b.type) { return 1; }
			return 0;
		}
	})

	sorted.forEach((node) => {
		if (node.children.length > 0) {
			sortByType(sortState, node.children);
		}
	})

	return sorted;
}