import {Theme, styled, Box} from "@mui/material";
import {WorkflowStates} from "../../../../interfaces/WorkflowState";

export interface ContainerProps {
	backgroundColor: string | null
}
const Container = styled(Box)<ContainerProps>(({theme, backgroundColor}) => ({
	backgroundColor: backgroundColor ?? theme.palette.background.level1,
	display: 'flex',
	alignItems:'center',
	gap: '0.75rem',
	paddingLeft: '1rem',
	paddingRight: '1rem',
	position: "relative",
	boxShadow: theme.shadows[2],
	height: '2.5rem',
	borderRadius: '1.25rem',
	justifyContent: "space-between",
	cursor: "pointer",
	"& svg": {
		color: "#00000066"
	},
	"& .chip__label": {
		display: 'flex',
		gap: '4rem',
		fontSize:'0.875rem', // Close to 14px
		color: theme.palette.text.secondary,
		alignItems: "center",
		width: "100%",
		justifyContent: "space-between",
		"& svg": {
			color: "#00000066"
		},
		"& span": {
			fontSize: ".65rem"
		},
		"&-title": {
			marginLeft: "10px",
			marginRight: "5px",
			fontWeight: 700,
			fontSize: ".875rem",
			color: "#1C1C1C"
		}
	}
}));

const DropDown = styled("ul")(({theme}) => ({
	width: "100%",
	position: "absolute",
	right: "0",
	top: "calc(0px + 38px)",
	margin: 0,
	padding: "5px 0",
	maxHeight: "50vh",
	background: "white",
	boxSizing: "border-box",
	boxShadow: theme.shadows[4],
	borderRadius: "4px",
	overflow: "hidden auto",
	"& li": {
		fontSize: ".5rem",
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column",
		boxSizing: "border-box",
		"& div.list__item": {
			justifyContent: "space-between",
			display: "flex",
			alignItems: "center",
			minHeight: "48px",
			padding: "0 10px 0 0",
			boxSizing: "border-box",
			borderTop: "1px solid #EEEEF0",
			width: "100%",
			"&-title": {
				display: "flex",
				alignItems: "center",
				padding: "0 15px"
			}
		},
		"& .number": {
			fontWeight: 700,
			fontSize: ".875rem",
			marginRight: "10px"
		},
		"& .date": {
			fontWeight: 400,
			fontSize: ".65rem",
			color: "#707071"
		},
		"& ul": {
			backgroundColor: "#FBFBFD",
			padding: "0 0 0 0px",
			listStyle: "none",
			margin: 0,
			width: "100%",
			borderTop: "1px solid #FAFAFA",
			boxSizing: "border-box",
			"& li": {
				"& div.list__item": {
					borderTop: "none",
				},
				flexDirection: "row",
				"& .number": {
					fontWeight: 400
				}
			}
		},
		"&:first-child": {
			"& div.list__item": {
				borderTop: "none"
			}
		}
	}
}));

const Overlay = styled("div")({
	position: "fixed",
	width: "100%",
	height: "100%",
	top: 0,
	left: 0
})

/**
 * Get CSS theme entry key from document state
 * @param {string} state
 * @returns
 */
const documentThemeStateFromState = (state?: string) => {
	switch(state?.toLowerCase() ?? "") {
		case WorkflowStates.InProcess:
			return "inProcess";
		case WorkflowStates.Review:
		case WorkflowStates.FinalReview:
			return "inReview";
		case WorkflowStates.Approved:
			return "approved";
		default:
			return "";
	}
}

/**
 * Get the main hex color value for the document state
 * @param {Theme} theme
 * @param {string?} state
 * @returns {string}
 */
const mainThemeColorForDocumentState = ( theme: Theme, state?: string) => {
	if (!theme.palette.documentState) {
		return "black";
	}
	const themeState = documentThemeStateFromState(state);
	return theme.palette.documentState[themeState]?.main ?? "black";
}

/**
 * Get the light hex color value for the document state
 * @param {Theme} theme
 * @param {string?} state
 * @returns {string}
 */
const lightThemeColorForWorkflowState = (theme: Theme, state?: string) => {
	if (!theme.palette.documentState) {
		return "white";
	}

	const themeState = documentThemeStateFromState(state);
	return theme.palette.documentState[themeState]?.light ?? "white";
}

export {Container, DropDown, Overlay, mainThemeColorForDocumentState, lightThemeColorForWorkflowState};