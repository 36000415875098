import {Column, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {FlexBox} from "../../../../../../shared/components";
import {DeleteButton} from "../../../../../../shared/components/layout/box/editControlBox/DeleteButton";
import {DataTableCell} from "../../../../../../shared/components/layout/table/styles";
import {UserGroupsNode, userGroupsNodeActions} from "../../../../models";


const ActionsCell = (props: ColumnViewProps) => {
	const item = props.item as UserGroupsNode;
	const column = props.column;

	const onDeleteUserGroup = () => {
		if (props.onAction) {
			props.onAction(userGroupsNodeActions.delete, props.column);
		}
	}

	return <DataTableCell
		align={column.itemAlign}
		width={column.width}
		key={`${column.title}-${item.id}`}
		// for non-data displaying cells, allow full width of cell
		sx={{...(!column.field && {paddingLeft: 0})}}
	>
		<FlexBox boxSizing="border-box" paddingLeft="0.7rem" paddingRight="0.5rem" justify="flex-end">
			<DeleteButton tooltipText={"Delete User Group"} skipConfirmation onClick={onDeleteUserGroup}/>
		</FlexBox>
	</DataTableCell>

}

const ActionsHeader = () => <FlexBox justify={"flex-end"}>
	<p style={{width: "50px", marginRight: "1rem"}}>Delete</p>
</FlexBox>


const Actions: Column = ({
	field: "",
	title: "Actions",
	headerAlign: "right",
	itemAlign: "right",
	width: "50%",
	views: {
		cell: ActionsCell,
		header: ActionsHeader
	}
})

export {Actions};