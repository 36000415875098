import {Chip, styled} from "@mui/material";
import {paletteForWorkflowState} from "../../interfaces/WorkflowState";
export interface WorkflowChipProps {
    state: string,
	size?: "small" | "large"
}

const WorkflowChip = styled(Chip, {shouldForwardProp: (prop) => prop !== "state" && prop !== "size"})<WorkflowChipProps>(({theme, state, size}) => ({
	backgroundColor:  paletteForWorkflowState(state ?? "", theme)?.main,
	color: theme.palette.tabs?.main,
	fontWeight: size === "small" ? 600 : 500,
	fontSize: size === "small" ? "0.6rem" : '0.8rem',
	height: size === "small" ? "1rem" : '1.7rem',
}));

export {WorkflowChip}