import {TemplateElementDefinition} from "../model";
import {SectionIcon} from "../../../../../shared/definitions/elements/section/icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {PreviewView} from "./views/PreviewView";
import {
	sectionDefinition as baseSectionDefinition
} from "../../../../../shared/definitions/elements/section/model";

const sectionDefinition: TemplateElementDefinition = {
	...baseSectionDefinition,
	icon: SectionIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		preview: PreviewView
	},
} as const

export type SectionElementPreviewValue = {
	name?: string,
	body: KaiAlphaTemplateBody
}

export {sectionDefinition};