import {ensureArgs} from "../filterUtilities";
import {isString} from "../../../guards/isString";
import {Try} from "../../../interfaces/Either";
import {NunjucksFilterFactoryProps} from "../model";

function jsonParse(...args: unknown[]): unknown {
	if (!ensureArgs(args)) {
		return undefined;
	}

	if (!isString(args[0])) {
		return undefined;
	}

	return Try(() => JSON.parse(args[0] as string)).matchWith({left: _ => undefined, right: obj => obj});
}
export function jsonParseFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "json_parse",
		help: "Parse a JSON string into an object",
		example: "var | json_parse",
		execute: jsonParse
	})
}