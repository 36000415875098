import React from 'react';

import { KALink } from '../../../lib/ui';
import list_utils from '../../../lib/utils/list_utils.js';
import { titleCase } from "title-case";
import { AppChrome } from '../../App/Chrome/AppChrome';

class ListsPage extends React.Component {
	render() {
		return(<AppChrome
			center={<ul>
				{list_utils.known_lists.map(function(list_type) {
					return(
						<li key={`edit_list_${list_type}`}><KALink to={{
							type: 'list',
							list_type: list_type
						}}>Edit {titleCase(list_type)} Lists</KALink></li>
					);
				})}
			</ul>}
		/>);
	}
}

export default ListsPage;
