import {TemplateState} from "../../useTemplateState";
import {useGetDataSources} from "../../api/useGetDataSources";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {Action} from "../../../../../shared/interfaces/ActionRun";


function useGetDatasourcesAction(): Action {
	const state = TemplateState.useContainer();
	const getDatasources = useGetDataSources();

	// update datasources when loaded
	useRunWhenValueChange(() => {
		state.setDatasources(getDatasources?.value);
	}, getDatasources.value);

	const run = () => getDatasources.execute();
	return {run, isRunning: getDatasources.isLoading};
}

export {useGetDatasourcesAction};