import { styled } from "@mui/material/";
import {BaseButton} from "./BaseButton";

const ChromelessButton = styled(BaseButton)((props) => ({
	backgroundColor: "transparent",
	color: props.theme.palette.secondary.contrastText,
	borderRadius: "50%",
	padding: "8px",
	"&:hover": {
		backgroundColor: "rgba(0,0,0,0.08)",
		color: props.theme.palette.cancelButton?.contrastText
	}
}));

export {ChromelessButton};