import {IttVariableMap} from "../../interfaces/IttVariableMap";
import api from "../../../api/api";
import {cacheFactory} from "../../hooks/cache/useCache";

const cache = cacheFactory(60);
export async function getIttVariablesApi(studyId?: string): Promise<IttVariableMap> {
	if (studyId === undefined) {
		const cachedVariables = await cache.getItem("system-variable-lookup", () => api.call("GET", "itt/variables", undefined, {retryable: false}) as Promise<IttVariableMap>);
		if (cachedVariables !== null) {
			return cachedVariables;
		}
	}

	return api.call("GET", studyId ? `itt/variables/${studyId}` : "itt/variables");
}