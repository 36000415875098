import { BaseElement } from "../../../interfaces/BaseElement";
import {isElementValueType} from "../../../interfaces/ElementValueType";

const htmlDefinition: BaseElement = {
	name: 'Template',
	type: 'html',
	displayName: 'Content Text',
	allowsChildren: false,
	longDescription: '',
	description: '',
	contentDescription: (element) => {
		return isElementValueType(element) && isHtmlElementValue(element.data) && element.data.text ? element.data.text : "";
	},
	toString: () => "(Preview Unavailable)",
} as const

export type HtmlElementValue = {
	text: string,
	merge?: {
		start?: boolean,
		end?: boolean,
		table?: boolean
	}
}

export function isHtmlElementValue(obj: unknown): obj is HtmlElementValue {
	const value = obj as HtmlElementValue;
	return value.text !== undefined;
}

export { htmlDefinition };