import { TableBody } from "@mui/material";
import React, {useState } from "react";
import { SortFilter } from "../../input/filter/sort";
import { FilterState, FilterStates } from "../../input/filter/sort/models";
import { FlexBox } from "../FlexBox";
import { EmptyGroup } from "../groups/EmptyGroup";
import {ColumnDefinition} from "./models";
import {
	BaseTableCell,
	BaseTableRow,
	headerStyles,
	HeaderTableCell,
	HeaderTitle,
	MuiTable,
	MuiTableContainer,
	MuiTableHead,
	MuiTablePagination,
	TableTitle
} from "./styles";
import { TablePaginationActions } from "./TablePaginationActions";
import {SearchBar} from "../../input/search/SearchBar";

export interface TableProps {
    items: (unknown & {id: string})[],
    columns: ColumnDefinition[],
    title?: string,
	pageSize?: number,
	currentPage?: number,
	total?: number,
	filters?: {[field: string]: string},
	sort: { field: string, sortDirection: FilterState} | undefined,
	onColumnSearch: (field?: string, searchValue?: string) => void,
	onSort: (field: string, sortDirection: FilterState) => void,
	onRenderRow: (item: unknown, rowIndex: number) => React.ReactNode,
	onRenderColumnAccessory?: (column: ColumnDefinition) => React.ReactNode,
	onChangePage?: (event: unknown, newPage: number) => void,
	onChangePageSize?: (event: React.ChangeEvent<HTMLInputElement>) => void,
	titleComponent?: JSX.Element,
	noItemsText?: string,
	disablePaging?: boolean | false,
}

const Table = (
	{
		items,
		columns,
		title,
		sort,
		pageSize,
		currentPage,
		total,
		titleComponent,
		noItemsText,
		onRenderRow,
		onRenderColumnAccessory,
		onColumnSearch,
		filters,
		onSort,
		onChangePage,
		onChangePageSize,
		disablePaging
	}: TableProps) => {
	const [page, setPage] = useState(currentPage ?? 0);
	const [rowsPerPage, setRowsPerPage] = useState(pageSize ?? 25);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleColumnSearchFactory = (column: ColumnDefinition) => (searchValue?: string) => onColumnSearch(column.field, searchValue);

	const onSortingChanged = (field: string, state: FilterState) => {
		onSort(field, state);
	};

	return (
		<FlexBox maxHeight={"100%"} direction="column" boxSizing="border-box" >
			<FlexBox justify={"space-between"}>
				{title && <TableTitle>{title}</TableTitle>}
				{titleComponent}
			</FlexBox>
			<MuiTableContainer>
				<MuiTable>
					<MuiTableHead>
						<BaseTableRow depth={0}>
							{columns.map((column) =>
								<HeaderTableCell
									key={`${String(column.field)}-first-header-row`}
									width={column.width}
									sx={headerStyles}
									align={column.headerAlign}
								>
									{column.sortable ?
										<SortFilter
											title={column.title}
											justify="flex-start"
											parentState={sort?.field === column.field ? sort!.sortDirection : FilterStates.None}
											onStateChange={(state) => onSortingChanged(String(column.field), state)}
										/> :
										<HeaderTitle>{column.title}</HeaderTitle>
									}
								</HeaderTableCell>
							)}
						</BaseTableRow>
						<BaseTableRow depth={1}>
							{columns.map((column) =>
								<HeaderTableCell
									key={`${String(column.field)}-second-header-row`}
									width={column.width}
									align={column.headerAlign}
								>
									{(column.searchable ?? false) ? <SearchBar placeholder={"Search Column"} searchValue={filters ? filters[column.field] : undefined} dense debounceTime={300} onChange={handleColumnSearchFactory(column)} /> :
										(onRenderColumnAccessory ? onRenderColumnAccessory(column) : null)
									}
								</HeaderTableCell>
							)}
						</BaseTableRow>
					</MuiTableHead>
					<TableBody>
						{items.length > 0 ? items
							// pagination
							.slice((currentPage ?? page) * (pageSize ?? rowsPerPage), (currentPage ?? page) * (pageSize ?? rowsPerPage) + (pageSize ?? rowsPerPage))
							.map((item, idx) => onRenderRow(item, idx)) : <BaseTableRow depth={0}>
							<BaseTableCell colSpan={columns.length + 1}>
								<EmptyGroup title={noItemsText ?? `No matching ${title}`}/>
							</BaseTableCell>
						</BaseTableRow>}
					</TableBody>
				</MuiTable>
			</MuiTableContainer>
			{/* hide pagination */}
			<FlexBox sx={{flexDirection: 'row-reverse'}}>
				<table>
					<tbody>
						<tr>
							{!(disablePaging ?? false) && <MuiTablePagination
								rowsPerPageOptions={[25, 50, 100]}
								count={total ?? items.length}
								SelectProps={{
									variant: 'outlined',
									sx: {
										fontSize: "0.8rem",
									}
								}}
								rowsPerPage={onChangePageSize && pageSize ? pageSize : rowsPerPage}
								page={currentPage ?? page}
								onPageChange={onChangePage ?? handleChangePage}
								width="100%"
								ActionsComponent={TablePaginationActions}
								labelRowsPerPage={"Items per page"}
								onRowsPerPageChange={onChangePageSize ?? handleChangeRowsPerPage}
							/>}
						</tr>
					</tbody>
				</table>
			</FlexBox>
		</FlexBox>
	)

}

export {Table}