import {ModuleColumn, ModuleColumnHeaderViewProps} from "../models";

const Header = (props: ModuleColumnHeaderViewProps) => {
	return <span style={{marginRight: "1rem"}}></span>
}
const NumberColumn: ModuleColumn = {
	field: "moduleNumber",
	views: {
		header: Header,
		cell: props => null
	}
}

export {NumberColumn}