import useMediaQuery from '@mui/material/useMediaQuery';

const withScreenMetrics = function(Component) {
	return(function(props) {
		const mediaQuery = useMediaQuery('(min-width:1000px)');
		return(<Component isMobileDevice={!mediaQuery} {...props} />);
	});
};

export default withScreenMetrics;
