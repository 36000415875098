import {useAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";

export type DataSourcesType = {
	items: {name: string, url: string}[]
}
function useGetDataSources() {
	const getDataSources = () => api.call('GET', 'data');
	return useAsync<DataSourcesType>(getDataSources, false);
}

export {useGetDataSources};