import { FlexBox } from "../FlexBox"
import {FilterTabDefinition} from "./filterTabDefinition";
import { TabItem, TabContainer, TabItemName, TabItemCount } from "./FilterTabs.style";
export interface FilterTabsProps {
	tabs: FilterTabDefinition[],
	selectedTabIndex: number,
	onTabSelected: (tabIndex: number) => void
	maxWidth?: string,
}

const FilterTabs = ({tabs, onTabSelected, selectedTabIndex, maxWidth}: FilterTabsProps) => {

	return <FlexBox justify="center" maxWidth={maxWidth}>
		<TabContainer>
			{tabs.map((tab, index) =>
				<TabItem
					active={selectedTabIndex === index}
					onClick={() =>{onTabSelected(index)}}
					key={tab.displayName}
				>
					<TabItemName>{tab.displayName}</TabItemName>
					<TabItemCount>{tab.total ?? "0"}</TabItemCount>
				</TabItem>
			)}
		</TabContainer>
	</FlexBox>
};

export {FilterTabs}