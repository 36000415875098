import React from "react";

import TextField from "../../../components/TextField";

export interface TextAreaProps {
	label: string,
	value: string,
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}
const TextArea = (props: TextAreaProps) => {
	return <TextField
		label={props.label}
		value={props.value}
		onChange={props.onChange}
		multiline
		rows={2}
		maxRows={4}
	/>
}

export {TextArea}