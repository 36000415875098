import {useState} from "react";
import {StatusNotification, StatusNotificationManager, StatusNotificationMessage} from "./models";
import {createContainer} from "unstated-next";
import {useRunWhenValueChange} from "../../../hooks";
import {useLocation} from "react-router-dom";
const uuid = require("uuid");

export interface StatusNotificationWithId extends StatusNotification {
	id: string
}

const useStatusBarContext = () => {
	const [notifications, setNotifications] = useState<StatusNotificationWithId[]>([]);
	const location = useLocation();

	useRunWhenValueChange(() => {
		setNotifications([]);
	}, location.pathname);

	useRunWhenValueChange(() => {
		const lastNotification = getLastMessage();
		if (lastNotification && lastNotification.type !== "error" && lastNotification.type !== "warning") {
			setTimeout(() => {
				if (notifications[0]?.type === "success" || notifications[0]?.type === "info") {
					setNotifications([]);
				}
			}, 5000);
		}
	}, [notifications])

	const getLastMessage = () => notifications.length > 0 ? notifications[notifications.length-1] : null;
	const onAddNotification = (notification: StatusNotification) => setNotifications([{...notification, id: uuid.v4()}]);
	const onRemoveNotification = (id: string) => setNotifications([...notifications.filter(notification => notification.id !== id)]);

	return {
		getLastMessage,
		onAddNotification,
		onRemoveNotification
	}
}

const StatusBarContext = createContainer(useStatusBarContext);

const useStatusBar = (): StatusNotificationManager => {
	const context = StatusBarContext.useContainer();
	const onSendNotification = (notification: StatusNotification) => context.onAddNotification(notification);
	const onSendInfoNotification = (message: StatusNotificationMessage) => onSendNotification({type: "info", ...message});
	const onSendSuccessNotification = (message: StatusNotificationMessage) => onSendNotification({type: "success", ...message});
	const onSendWarningNotification = (message: StatusNotificationMessage) => onSendNotification({type: "warning", ...message});
	const onSendErrorNotification = (message: StatusNotificationMessage) => onSendNotification({type: "error", ...message});

	return {
		sendNotification: onSendNotification,
		sendErrorNotification: onSendErrorNotification,
		sendInfoNotification: onSendInfoNotification,
		sendSuccessNotification: onSendSuccessNotification,
		sendWarningNotification: onSendWarningNotification
	}
}

export {useStatusBar, StatusBarContext};