import { BaseElement, ElementPropertyDataValueType } from "../../../../shared/interfaces/BaseElement";
import {Variable, VariableType} from "../../../../shared/interfaces/Variable";
import { DocumentElement, DocumentTreeElement } from "../../models/element";
import { abbreviationsDefinition } from "./abbreviations/model";
import { citationsDefinition } from "./citations/model";
import { footerDefinition } from "./footer/model";
import { headerDefinition } from "./header/model";
import { htmlDefinition } from "./html/model";
import { imageDefinition } from "./image/model";
// TODO find out if we need instructional text on content
import { instructionalTextDefinition } from "./instructionalText/model";
import { inTextTableDefinition } from './inTextTable/model';
import { loopDefinition } from "./loop/model";
import { sectionDefinition } from "./section/model";
import { styleDefinition } from "./style/model";
import { switchDefinition } from "./switch/model";
import { tableDefinition } from "./table/model";
import { templateDefinition } from "./template/model";
import { titleDefinition } from "./title/model";
import { tocDefinition } from "./toc/model";
import {EditorModalProps} from "../tabs/variables/modals";
import {EntityMetaData} from "../../../../shared/interfaces/EntityMetaData";
import {ElementValueType} from "../../../../shared/interfaces/ElementValueType";

// currently, Document elements have no additional properties, so they directly use BaseElement.
// this will change when the document editor is rebuilt.
export interface DocumentElementDefinition extends BaseElement {
	readonly icon?: ElementIcon,
	readonly views?: ElementViews,
	readonly addElementType?: string,
}

export const elements: Readonly<DocumentElementDefinition[]> = [
	abbreviationsDefinition,
	citationsDefinition,
	instructionalTextDefinition,
	inTextTableDefinition,
	footerDefinition,
	headerDefinition,
	htmlDefinition,
	imageDefinition,
	loopDefinition,
	sectionDefinition,
	styleDefinition,
	switchDefinition,
	tableDefinition,
	templateDefinition,
	titleDefinition,
	tocDefinition
] as const;

type ElementIcon = React.FC<ElementIconProps>;

export type EditableViewType = React.FC<EditableElementProps>;
export type ReadonlyViewType = React.FC<ReadonlyElementProps>;
export type PreviewViewType = React.FC<PreviewElementProps>;

export interface ElementPropertyData {
	id: string,
	elementInformation: {
		type: string,
		writable: boolean
	} | null,
	data: ElementPropertyDataValueType
}

export type EditableElementChangeEvent = ElementPropertyData;

type EditableElementEvents = {
	onChange: (event: EditableElementChangeEvent) => void
}

// so that we dont have to recompute, we pass up two versions of the children:
// one that is nested in a tree structure, and the other that is a flat array.
export interface ElementMaps {
	child: DocumentTreeElement,
	elements: DocumentElement[],
}

export type ReadonlyElementProps = ElementPropertyData;
export type EditableElementProps = ElementPropertyData & EditableElementEvents;
export type PreviewElementProps = {
	element: ElementValueType,
	variables?: Variable[],
	state?: string,
	body?: ElementValueType[],
	metaData?: EntityMetaData
	isLoadingVariables?: boolean
}

export type DialogProps = {
	type: VariableType,
	variables: Variable[],
	onClose: () => void,
} & EditorModalProps

export interface ElementPropertyData {
	id: string,
	elementInformation: {
		type: string,
		writable: boolean
	} | null,
	data: ElementPropertyDataValueType
}

interface ElementViews {
	editable?: EditableViewType,
	propertyEditor?: {
		view?: React.FC<DialogProps>,
		requiredOnCreate?: boolean
	},
	preview?: PreviewViewType,
	readonly?: ReadonlyViewType
}
export interface DocumentElementDefinition extends BaseElement {
	readonly icon?: ElementIcon,
	readonly views?: ElementViews,
	readonly addElementType?: string,
}

type ViewType = React.FC<PreviewElementProps>;

export interface ElementIconProps {
	color?: string,
	strokeColor?: string
}

export const IconDefaults: ElementIconProps = {
	color: "#403294",
	strokeColor: "#3d368f"
}

export type DocumentBodyRenderViewProps = {
	type: string,
	id: string,
	data: unknown,
	variables?: Variable[],
	body?: KaiAlphaDocumentBodyExtend
}

export const documentViewForElementType = (type?: string): ViewType | undefined => elements.find(element => element.type === type)?.views?.preview;