/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/lib/object_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const equal = require('fast-deep-equal');
const clone = require("rfdc/default");

const _testing = undefined;

/*
 * Duplicate an object, this prevents modifications from affecting the source
 */
function copy_object(object) {
	/*
	 * If the value passed in isn't derived from an Object, return it
	 * without trying to serialize/deserialize it.
	 */
	if (!(object instanceof Object)) {
		return(object);
	}

	return(clone(object));
}

function freeze_object(object) {
	/*
	 * If the value passed in isn't derived from an Object, return it
	 * without trying to serialize/deserialize it.
	 */
	if (!(object instanceof Object)) {
		return(object);
	}

	for (const key in object) {
		freeze_object(object[key]);
	}

	Object.freeze(object);

	return(object);
}

function try_parse_json(value) {
	try {
		const object = JSON.parse(value);
		return object;
	} catch (error) {
		// if here, not a json string
		return value;
	}
}

function is_json_object(text) {
	if (typeof text !== 'string') {
		return false;
	}

	try {
		const json = JSON.parse(text);

		// make sure this is an object and not another type like int or boolean.
		const is_object = typeof json === 'object';
		return is_object;
	} catch (error) {
		// if here, not a json string
		return false;
	}
}

/**
 * Returns true if there is deep strict equality between val1 and val2. Otherwise, returns false
 */
function object_equals(val1, val2) {
	return equal(val1, val2);
}

if (_testing) {
	_testing.copy_object = function () {
		const obj = { testing: 'something' };

		let result;

		result = copy_object(obj);

		/* istanbul ignore if */
		if (obj === result) {
			throw new Error("Both objects should not be same but found a same reference ");
		}

		result = copy_object(["Something"]);

		/* istanbul ignore if */
		if (!Array.isArray(result)) {
			throw new Error("Result should have some have an array but found nothing");
		}

		return true;
	};

	_testing.is_json_object = function () {
		let input, result;

		input = '["foo","bar",{"foo":"bar"}]';
		result = is_json_object(input);
		kaialpha.lib.testing_utils.assert(result === true, 'is_json_object should return true for', input);

		input = '{"a":1,"b":"1","c":{"d":"e"}}';
		result = is_json_object(input);
		kaialpha.lib.testing_utils.assert(result === true, 'is_json_object should return true for', input);

		input = 'This is not a JSON string.';
		result = is_json_object(input);
		kaialpha.lib.testing_utils.assert(result === false, 'is_json_object should return false for', input);

		input = {};
		result = is_json_object(input);
		kaialpha.lib.testing_utils.assert(result === false, 'is_json_object should return false for', input);

		input = null;
		result = is_json_object(input);
		kaialpha.lib.testing_utils.assert(result === false, 'is_json_object should return false for', input);

		return true;
	};

	_testing.object_equals = function () {
		const test_object = { 'key1': 'value' };
		let result = object_equals(test_object, test_object);
		kaialpha.lib.testing_utils.assert(result === true, 'object_equals should return true for', { object_1: test_object, object_2: test_object });

		result = object_equals(test_object, { 'key1': 'true' });
		kaialpha.lib.testing_utils.assert(result === false, 'object_equals should return false for', { object_1: test_object, object_2: { 'key1': 'true' } });

		const subObject1 = {
			sub: { 'key1': 'false' }
		};

		const subObject2 = {
			sub: test_object
		};

		result = object_equals(subObject1, subObject2);
		kaialpha.lib.testing_utils.assert(result === false, 'object_equals should return false for', { object_1: subObject1, object_2: subObject2 });

		result = object_equals(subObject1, ['something']);
		kaialpha.lib.testing_utils.assert(result === false, 'object_equals should return false for', { object_1: subObject1, object_2: ['something'] });

		const array1 = [1, 'a', { x: 1, y: 'a', z: { a: 'z' } }];
		const array2 = [1, 'a', { x: 1, y: 'a', z: { a: 'z' } }];
		result = object_equals(array1, array2);
		kaialpha.lib.testing_utils.assert(result === true, 'object_equals should return true for', { object_1: array1, object_2: array2 });

		result = object_equals(null, null);
		kaialpha.lib.testing_utils.assert(result === true, 'object_equals should return true for', { object_1: null, object_2: null });

		result = object_equals(undefined, undefined);
		kaialpha.lib.testing_utils.assert(result === true, 'object_equals should return true for', { object_1: undefined, object_2: undefined });

		result = object_equals(undefined, test_object);
		kaialpha.lib.testing_utils.assert(result === false, 'object_equals should return false for', { object_1: undefined, object_2: test_object });

		// allow strings comparison
		result = object_equals("test", "test");
		kaialpha.lib.testing_utils.assert(result === true, 'object_equals should allow primitive type string', { object_1: "test", object_2: "test" });

		// allow numbers comparison
		result = object_equals(123.123, 123.123);
		kaialpha.lib.testing_utils.assert(result === true, 'object_equals should allow primitive type number', { object_1: 123.123, object_2: 123.123 });

		// dont allow type coercion
		result = object_equals(123.123, "123.123");
		kaialpha.lib.testing_utils.assert(result === false, 'object_equals should not allow type coercion', { object_1: 123.123, object_2: "123.123" });
		result = object_equals(null, undefined);
		kaialpha.lib.testing_utils.assert(result === false, 'object_equals should not allow type coercion', { object_1: null, object_2: undefined });

		// allow date objects
		result = object_equals(new Date('2021-01-01T00:00:00.000Z'), new Date('2021-01-01T00:00:00.000Z'));
		kaialpha.lib.testing_utils.assert(result === true, 'object_equals should allow date comparison');

		return true;
	};
}

const _to_export_auto = {
	copy_object,
	freeze_object,
	is_json_object,
	object_equals,
	try_parse_json,
	_testing
};
export default _to_export_auto;
