import {TableRow, TableCell as MuiTableCell} from "@mui/material";
import {useState} from "react";
import {useMousePosition} from "../../../../../shared/hooks/useMousePosition";
import {ContentPaste, Menu} from "@mui/icons-material";
import {ContextMenu, ContextMenuItemType} from "../../../../../shared/components/menu/contextMenu";

export type TableColumnType = {
	field: string,
	displayText: string
}
export type TableColumnsType = TableColumnType[];
export type TableCellType = {
	id: string
} & Record<string, string | number>;

export type TableCellEvent = {row: TableCellType, columns: ({index: number} & TableColumnType)[]};

export interface TableCellProps {
	row: TableCellType,
	columns: TableColumnsType,
	onCellReferenceSelected: (event: TableCellEvent, referenceType: TableCellReference) => void;
}

export type TableCellReference = "abs" | "named" | "row" | "col";

const contextMenuItems: ContextMenuItemType<TableCellReference>[] = [{
	label: "Absolute Reference",
	value: "abs",
	icon: ContentPaste
}, {
	label: "Cell reference (named cell)",
	value: "named",
	icon: ContentPaste
}, {
	label: "Find matching rows",
	value: "row",
	icon: ContentPaste
}, {
	label: "Find matching columns",
	value: "col",
	icon: ContentPaste
}]

const TableRowCells = ({row, columns, onCellReferenceSelected}: TableCellProps) => {
	const mousePosition = useMousePosition();
	const [selection, setSelection] = useState<{row: TableCellType, columns: TableColumnType[], x: number, y: number} | undefined>(undefined);
	const cellClickFactory = (row: TableCellType, column: TableColumnType) => () => setSelection({row, columns: [column], x: mousePosition.x, y: mousePosition.y});
	const onRowClick = () => setSelection({row, columns, x: mousePosition.x, y: mousePosition.y});

	const onResetSelection = () => setSelection(undefined);
	const isSelectedCell = (id: string, column: TableColumnType) => id === selection?.row.id && columns.some(col => col.field === column.field);

	const onContextMenuItemClick = (menuItem: ContextMenuItemType<TableCellReference>) => {
		const selectedColumns = selection!.columns.map(col => ({index: columns.findIndex(column => column.field === col.field), ...col}));
		onCellReferenceSelected({row: selection!.row, columns: selectedColumns} , menuItem.value);
		setSelection(undefined);
	}


	return <TableRow
		key={row.id}
		sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
	>
		<MuiTableCell>
			<Menu fontSize={"small"} sx={{cursor: "pointer"}} onClick={onRowClick} />
		</MuiTableCell>
		{columns.map((column, colIndex) => <MuiTableCell
			key={`${row.id}-${column.field}`}
			sx={{backgroundColor: isSelectedCell(row.id, column) ? "#F3F5FB" : "initial", cursor: "pointer"}}
			onClick={cellClickFactory(row, column)}
		>{row[column.field] ?? "Not defined"}</MuiTableCell>)}
		<ContextMenu
			menuItems={contextMenuItems}
			open={selection !== undefined && selection.columns.length === 1}
			onClose={onResetSelection} position={{x: selection?.x ?? 0, y: selection?.y ?? 0}}
			onMenuItemSelect={onContextMenuItemClick} />

		<ContextMenu
			menuItems={[{
				label: "Row reference",
				value: "named",
				icon: ContentPaste
			}]}
			open={selection !== undefined && selection.columns.length > 1}
			onClose={onResetSelection} position={{x: selection?.x ?? 0, y: selection?.y ?? 0}}
			onMenuItemSelect={onContextMenuItemClick} />
	</TableRow>
}

export {TableRowCells}