import {isNunjucksFilterDependencies, NunjucksFilterDependencies, NunjucksFilterFactoryProps} from "../model";
import {ensureArgs} from "../filterUtilities";
import {isString} from "../../../guards/isString";
import {isNunjucksEnvironmentOptions, NunjucksEnvironmentOptions} from "../../models/NunjucksEnvironmentOptions";

function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args, 3) || !isString(args[0]) || !isNunjucksEnvironmentOptions(args[1]) || !isNunjucksFilterDependencies(args[2])) {
		return "";
	}

	const key = args[0] as string;
	const dependencies = args[2] as NunjucksFilterDependencies;
	const options = args[1] as NunjucksEnvironmentOptions;

	const normalizedKey = normalizeHtmlId(key).toLowerCase();
	const citationId = dependencies.incrementId(options, key, "citation");

	return citationId === undefined ? "" : `<sup><a data-citation='true' onclick='return false;' href='cite_${normalizedKey}'>${citationId}</a></sup>`;
}

function normalizeHtmlId(html: string): string {
	return(html.replace(/[^A-Za-z0-9-]/g, '_'));
}

export function citeFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "cite",
		help: "Create a citation markup (e.g. cite('AA 2015')",
		example: "var | cite('citation')",
		execute: (...args: unknown[]) => execute((args.length > 0 ? args[0] : undefined),props?.options, props?.dependencies)
	})
}