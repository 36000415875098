import { AsyncComponent, FlexBox } from "../../../../../../../shared/components";
import { FileSelectionButton } from "../../../../../../../shared/components/buttons/theme";
import { ImageContainer, FileText } from "./ImageEditor.style";
import { VariableEditor, VariableEditorProps } from "../model";
import { useRef, useState } from "react";
import { useRunWhenValueChange } from "../../../../../../../shared/hooks";
import { usePostFileToS3 } from "../../../../../../../shared/hooks/api/s3/usePostFileToS3";
import { useStatusBar } from "../../../../../../../shared/components";
import { useLoadImageFromS3Action } from "../../../../../hooks/actions";
import {Description} from "../DescriptionLabel";

const Editor = (props: VariableEditorProps) => {

	const imageLoader = useLoadImageFromS3Action();
	const statusBar = useStatusBar();
	const inputFile = useRef<HTMLInputElement | null>(null);
	const [file, setFile] = useState<File | null>();
	const [fileName, setFileName] = useState<String | null>();
	const fileUploader = usePostFileToS3("images");

	useRunWhenValueChange(() => {
		if (props.onChange && imageLoader.value) {
			props.onChange({ image: `data:file;base64,${imageLoader.value.data}`, type: "image" });
		}
	}, imageLoader.value)

	useRunWhenValueChange(() => {
		if (file) {
			fileUploader.execute({ file, type: "file" });
			setFileName(file.name);
		}
	}, file);

	useRunWhenValueChange(() => {
		if (fileUploader.value) {
			imageLoader.run(
				{
					fileValue:
					{
						key: fileUploader.value!.key,
						type: fileUploader.value!.type
					}
				});
			setFile(null);
		}
	}, fileUploader.value);

	useRunWhenValueChange(() => {
		if (fileUploader.error) {
			statusBar.sendErrorNotification({ message: "Image upload failed", detail: fileUploader.error });
		}
	}, fileUploader.error);

	const inputClick = (event) => {
		inputFile!.current!.click();
	}

	const imageChange = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setFile(event.target.files[0]);
	}

	return (
		<AsyncComponent isLoading={fileUploader.isLoading || imageLoader.isRunning} component={
			<ImageContainer>
				<FlexBox align="center">
					<><FileSelectionButton onClick={inputClick} />
						<FileText>
							<span className={fileName !== null ? 'selected' : ''}>{fileName !== null ? fileName : 'NULL FILENAME'}</span>
						</FileText></>
				</FlexBox>
				<input type='file' id='metadata-file' style={{ display: 'none' }} ref={inputFile} onChange={imageChange} data-testid={"image-variable"} />
				<Description text={props.variable.description} />
			</ImageContainer>
		} loadingMessage='Uploading image...' />
	);
}

const ImageEditor: VariableEditor = {
	type: "image",
	view: Editor
};

export { ImageEditor };