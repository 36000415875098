import { checkOrdinalNameAndFormat } from "../../../utilities/checkOrdinalNameAndFormat";

function formatExpressionElements(elements: KaiAlphaTemplateBody) {
	if (!elements || !Array.isArray(elements)) {
		return elements;
	}

	elements.forEach(element => {
		const key = Object.keys(element)[0];
		const bodyElement = element[key];
		if (bodyElement.type === "template" && bodyElement.expression) {
			bodyElement.name = checkOrdinalNameAndFormat(bodyElement.name as string);
		} else if (bodyElement.type === "section" && bodyElement.body) {
			bodyElement.body = formatExpressionElements(bodyElement.body as KaiAlphaTemplateBody);
		}
		element[key] = bodyElement;
	});

	return elements;
}

const useFormatExpressionElements = () => {
	return formatExpressionElements
}

export {useFormatExpressionElements};