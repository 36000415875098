//import {getTemplateBuffer} from '../../../../api/template.js';
import {useAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";
import {ApiPatchResult} from '../../../../shared/interfaces';

// gets a new template (and the user's associated buffer) by id
function useSaveBuffer() {

	// get the published template and template buffer
	const updateBuffer = async (buffer: KaiAlphaTemplate, templateId: string, templateVersion: string): Promise<ApiPatchResult> => {
		const initBuffer: KaiAlphaTemplate & {templateVersion: string} = {
			...buffer,
			id: templateId,
			templateVersion,
			version: buffer.version,
			metadata: buffer.metadata ?? {
				system: {},
			},
			variables: buffer.variables ?? {}
		}

		// create new buffer for template
		return api.call('POST', 'template/buffer', initBuffer);
	}

	return useAsync<ApiPatchResult>(updateBuffer, false);
}

export {useSaveBuffer};