import api from "../../../../api/api";
import {useAsync} from "../../../../shared/hooks";

function deleteDocument(documentId: string) {
	return api.call("DELETE", `document/${documentId}`);
}

function useDeleteDocument() {
	return useAsync(deleteDocument, false);
}

export {useDeleteDocument, deleteDocument};