import { FlexBox } from "../layout";
import { Close } from "@mui/icons-material";
import { BasePopup } from "./BasePopup";
import {styled} from "@mui/material";
import { LoadingIndicator } from "../progress/LoadingIndicator";

const Title = styled(FlexBox)(({theme}) => ({
	marginBottom: "2rem",
	boxSizing: "border-box",
	fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
	color: theme.palette.primary.main,
	fontSize: "2rem",
	fontWeight: 500,
}));

const ErrorBox = styled(FlexBox)(({theme}) => ({
	fontSize: "2rem",
	textAlign: "center",
	fontWeight: 500,
	color: theme.palette.error.main,
	backgroundColor: "rgba(255, 255, 255, 0.75)",
	zIndex: 10,
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	position: "absolute"

}));

export interface BaseModalProps {
	visible: boolean,
    onClose: () => void,
    loading?: boolean,
    error?: {
        condition: boolean,
        message: string
    }
    title: string,
    buttonRow: JSX.Element,
    content: JSX.Element,
	wide?: boolean,
}

const BaseModal = ({visible, onClose, loading, error, title, content, buttonRow, wide}: BaseModalProps) => {
	return (
		<BasePopup open={visible} onClose={onClose} wide={wide}>
			<FlexBox padding="0.5rem" boxSizing="border-box">
				<FlexBox direction="column">
					<FlexBox justify="flex-end" align="center">
						<Close sx={{fontSize: "1.3rem", cursor: 'pointer', zIndex: 11}} onClick={onClose}></Close>
					</FlexBox>
					{loading &&  <LoadingIndicator/>}
					{error && error.condition && <ErrorBox justify="center" align="center">{error?.message ?? "Error"}</ErrorBox>}
					<FlexBox direction="column" padding="0.5rem 4rem 1rem 4rem" boxSizing="border-box">
						<Title>{title}</Title>
						{content}
						<FlexBox boxSizing="border-box" padding="2rem 0 1rem 0" direction="row-reverse" gap="1rem">
							{buttonRow}
						</FlexBox>
					</FlexBox>
				</FlexBox>
			</FlexBox>
		</BasePopup>
	);
}

export {BaseModal}