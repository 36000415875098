import { styled } from "@mui/material/styles";
import { BaseButton } from "./BaseButton";
import {ButtonProps} from "./model";

const DefaultButton = styled(BaseButton)<ButtonProps>(({theme, styling, disabled}) => ({
	backgroundColor: styling === 'cancel' ? theme.palette.plain?.hover : disabled ? theme.palette.primary.contrastText : theme.palette.primary.main,
	color: styling === 'cancel' ? theme?.palette?.tabs?.hover : theme.palette.primary.contrastText,
	fontWeight: "bold",
	padding: "4px 12px",
	textTransform: "none",
	border: styling === 'cancel' ? `1px solid ${theme.palette.plain?.hover}` : disabled ? `1px solid ${theme.palette.scrollbar?.track}` : `1px solid ${theme.palette.primary.main}`,
	'&:hover': {
		backgroundColor: theme.palette.primary.contrastText,
		color: styling === 'cancel' ? theme.palette.cancelButton?.contrastText : theme.palette.primary.main,
		boxShadow: styling ===  'cancel' ? `2px 4px ${theme.palette.plain?.hover}` : ''
	}
}));

export {DefaultButton};