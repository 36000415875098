import {Column} from "../../../../../../shared/components/layout/table";
import {ModelDocument, ModelDocumentSorter} from "./ModelDocument";
import {Name} from "./Name";
import {Status} from "./Status";
import {Owner} from "./Owner";
import {LastModified} from "./LastModified";
import {Actions} from "./Actions";
import {SortableColumn} from "../models";

const columns: Column[] = [Name, ModelDocument, Status, Owner, LastModified, Actions]
function cellViewForColumn(field: string) {
	return columns.find(column => column.field === field)?.views?.cell;
}

function accessoryForColumn(field: string) {
	return columns.find(column => column.field === field)?.views?.header;
}

const sortableColumns: SortableColumn[] = [ModelDocumentSorter];
function sortableColumn(field: string) {
	return sortableColumns.find(sortableColumn => sortableColumn.field === field)?.sorter
}

export {columns, cellViewForColumn, sortableColumn, accessoryForColumn}