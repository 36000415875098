import {Action} from "../../../../../shared/interfaces/ActionRun";
import {useExtractApi} from "../../api/useExtractApi";
import {SubmissionState} from "../../useSubmissionState";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {useLoadSubmissionAction} from "../load/useLoadSubmissionAction";
import {useStatusBar} from "../../../../../shared/components";

function useExtractAction(): Action {
	const {submission} = SubmissionState.useContainer();
	const extractApi = useExtractApi();
	const loadSubmission = useLoadSubmissionAction();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if (extractApi.value) {
			loadSubmission.run({id: submission.id, version: submission.version});
			statusBar.sendSuccessNotification({message: "Extraction requested"});
		}
	}, extractApi.value);

	useRunWhenValueChange(() => {
		if (extractApi.status === "pending") {
			statusBar.sendInfoNotification({message: "Requesting extraction"});
		} else if(extractApi.status === "error" && extractApi.error) {
			statusBar.sendErrorNotification({message: "Error requesting extraction", detail: extractApi.error});
		}
	}, extractApi.status)

	const run = () => extractApi.execute({id: submission.id, version: submission.version ?? "HEAD"});
	return {
		run,
		isRunning: extractApi.isLoading
	}
}

export {useExtractAction}
