import api from "../../../../api/api";
import { useTypedAsync } from "../../useAsync";
import { S3PostFileValue } from "./models";


function loadImageFromS3ApiKey({key}: S3PostFileValue): Promise<{data: string} | null>{
	// retrieve the image from S3 and persist to document, this stops
	// the image from (needlessly) being retrieved each time the document is loaded
	try {
		return api.call('GET', `document/variable/${key}`);
	} catch (err) {
		throw new Error('Image Download Failed');
	}
}
function useLoadImageFromS3KeyApi() {
	return useTypedAsync(loadImageFromS3ApiKey, false);
}

export {useLoadImageFromS3KeyApi, loadImageFromS3ApiKey};