import { getTemplateApi } from "./api/useGetTemplate";
import { DocumentState } from "./useDocumentState";
import {useIntervalAsync} from "../../../shared/hooks/useIntervalAsync";

/**
 * Fetches the latest version of the template to check if a new version exists.
 * @param template
 * @returns boolean - true if the versions are different and false if not
 */
export async function shouldUpgradeTemplate(template: KaiAlphaTemplate | null): Promise<boolean> {
	if (template) {
		const {version} = template;
		const latestTemplate: KaiAlphaTemplate = await getTemplateApi(template.id);
		const latestVersion = latestTemplate.version;

		return version !== latestVersion;
	}

	return false;
}

/**
 * On an interval (defaults to every minute), checks if the current document template is out of sync with the top level template.
 * Invokes a React State setter with the result of the version check.
 * @param shouldRun: function to determine if the template check should run
 * @param upgradeAvailable: call back when upgrade is available
 * @param interval: how often to run the check
 */
function useAutoTemplateUpgrade(shouldRun: () => boolean, upgradeAvailable: () => void, interval = 60000) {
	const documentManager = DocumentState.useContainer();

	return useIntervalAsync(
		async () => {
			try {
				const canUpgrade = await shouldUpgradeTemplate(
					documentManager.template
				);

				if (canUpgrade) {
					upgradeAvailable();
				}
			} catch {
				// Do nothing
			}
		},
		interval,
		shouldRun
	);
}

export { useAutoTemplateUpgrade };