import { User } from "../interfaces/User";
import { FilterTaskWorkflowResponse } from "../../modules/task/models/workflow";
import { filterButtonsWorkflowUI } from "./filterButtonsWorkflowUI";

const defaultWorkflowStatus = 'waiting';

const filterTaskWorkflowUI = (
	user: User,
	state: KaiAlphaDocumentWorkflow | KaiAlphaTemplateWorkflow | any,
	permissions: KaiAlphaDocumentPermissions,
	slotName: string
): FilterTaskWorkflowResponse  => {
	if ( (!state || !state.status) || (state.status.toString() !== defaultWorkflowStatus) || (!state.ui)) {
		return {
			state,
			ui: {},
			slotName: slotName
		};
	}

	const response = {
		state,
		ui: {},
		slotName
	};

	for (const part in state.ui) {
		if(part === 'buttons') {
			response.ui[part] = filterButtonsWorkflowUI(
				user,
				state.ui[part],
				permissions
			);
		} else {
			response.ui[part] = state.ui[part];
		}
	}

	return response;
}

export {filterTaskWorkflowUI}