import {Variable} from "../../../../../../../shared/interfaces/Variable";
import {mapperForVariableType} from "./variableMappers";
import {renderMissingVariable} from "./renderMissingVariable";
import {renderTemplatedString} from "../../../../../../../shared/nunjucks/renderTemplatedString";

function mapVariables(variables: Variable[] | undefined, body: KaiAlphaBody | undefined) {
	const mappedVariables = variables?.reduce((lookup, variable) => {
		const key = variable.name.toLowerCase();
		const variableMapper = mapperForVariableType(variable.type);
		if (variableMapper) {
			lookup[key] = variableMapper(variable, body);
		} else {
			lookup[key] = renderMissingVariable(key);
		}

		if (lookup[key]?.image) {
			lookup[key] = `<img src="${lookup[key].image}" alt="${variable.name}" />`;
		}

		if (lookup[key] === undefined) {
			lookup[key] = renderMissingVariable(key);
		}
		return lookup;
	}, {});

	if (mappedVariables) {
		Object.keys(mappedVariables).forEach(key => {
			if(typeof(mappedVariables[key]) === "string" && mappedVariables[key].indexOf("{{") > -1) {
				mappedVariables[key] = renderTemplatedString(mappedVariables[key], mappedVariables);
			}
		});
	}

	return mappedVariables
}

export {mapVariables}