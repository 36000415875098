import {PropertyEditorProps} from "./models";
import {ChangeEvent, useState} from "react";
import {VariableInput} from "../model";
import {TextField} from "../../../../../../../../lib/ui";
import {useRunWhenValueChange} from "../../../../../../../../shared/hooks";

type ListOptions = {
	items: string[]
}
type ListState = string;

const mapOptionsToState = (options: ListOptions): ListState => options?.items?.join(", ") ?? "";
const ListProperties = ({onChange, value}: PropertyEditorProps) => {
	const variableInfo = value?.data as VariableInput;

	const listState = typeof(variableInfo.options) !== "undefined" ?
		mapOptionsToState(variableInfo.options! as ListOptions) : "";

	const [state, setState] = useState(listState);

	useRunWhenValueChange(() => {
		onUpdate();
	}, state)

	const onListValueChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setState(e.target.value);
	const onUpdate = () => {
		const items = state.split(",").map(item => item.trim()).filter(item => item.length > 0);
		onChange({items});
	}
	return <TextField onChange={onListValueChange} value={state} label={"Items"} placeholder={"Items"} />
}

export {ListProperties};