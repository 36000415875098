export type ElementValueType = {
	type: string,
	id: string,
	data: unknown,
	source?: string,
	children?: ElementValueType[]
}

export function isElementValueType(obj: unknown): obj is ElementValueType {
	const elementValueType = obj as ElementValueType;
	return elementValueType.type !== undefined && elementValueType.id !== undefined && elementValueType.data !== undefined
}