import {ColumnDefinition} from "../components/layout/table";
import {TaskNode} from "../interfaces/task";
import {cellViewForColumn} from "./columns";
import {BaseTableRow} from "../components/layout/table/styles";
import {useTheme} from "@mui/material";

export interface TaskListRowProps {
	item: unknown,
	rowIndex: number,
	columns: ColumnDefinition[],
	onTaskSelected: (task: TaskNode) => void
}

const TaskListRow = (props: TaskListRowProps) => {
	const task = props.item as TaskNode;
	const theme = useTheme();
	const onRowSelected = () => props.onTaskSelected(task);

	const onRenderTableCell = (column: ColumnDefinition) => {
		const Cell = cellViewForColumn(column.field);
		return Cell ? <Cell
			key={`${column.field}-${task.id}`}
			item={task}
			column={column}/> : task[column.field];
	}

	const backgroundColor = props.rowIndex % 2 !== 0 ? theme.palette?.dashboard?.static : "#fff";

	return <BaseTableRow onClick={onRowSelected} style={{cursor: "pointer", backgroundColor}}>
		{props.columns.map(column => onRenderTableCell(column))}
	</BaseTableRow>
}

export {TaskListRow};