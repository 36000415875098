import {PreviewElementProps} from "../../../model";
import {SimpleTable} from "./SimpleTable";
import {Box} from "@mui/material";
import {TableElementValue} from "../../../../../../../shared/definitions/elements/table/model";
import {useTablePreview} from "./useTablePreview";

const PreviewView = (props: PreviewElementProps) => {
	const dataInfo = props.data as TableElementValue;
	const tablePreview = useTablePreview(dataInfo, props.variables);

	return <Box sx={{ mt: 2 }}>
		{dataInfo.title && <h2>{dataInfo.title}</h2>}
		{tablePreview.data?.map(data => <SimpleTable data={data.data} footNotes={data.footNotes} />)}
	</Box>
}

export { PreviewView };