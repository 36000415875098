import { useState } from "react";
import { Button, FlexBox } from "../../../../../../../shared/components";
import { DocumentState } from "../../../../../hooks/useDocumentState";
import { ReferencePropertyEditor } from "./ReferencePropertyEditor"
import { VariableEditor, VariableEditorProps } from "../model";
import {ReferenceElementContents} from "../../../../../../../shared/definitions/elements/reference/model";
import {displayNameForElementType} from "../../../../../../../shared/definitions/elements";
import {Description} from "../DescriptionLabel";

interface State {
	openModal: boolean,
	editItemProps: any,
	selectedReference: any
}
const initialState: State = {
	openModal: false,
	editItemProps: {},
	selectedReference: {}
}


const Editor = (props: VariableEditorProps) => {
	// const referenceManager = useReference();
	const reference = props.variable.value as (ReferenceElementContents | undefined);
	const [state, setState] = useState<State>(initialState);
	const documentManager = DocumentState.useContainer();

	// event is the payload returned from the Modal that shows the elements users want to select for references
	const onApply = (event) => {
		if (props.onChange) props.onChange(event.data.value);
		setState(s => ({ ...s, openModal: false }));
	}

	const clickOpen = () => {
		setState(s => ({ ...s, openModal: true }));
	}

	const closeDialog = () => {
		setState(s => ({ ...s, openModal: false }));
	}

	return (
		<>
			<FlexBox direction={"column"} className={"tm__preview--editors"}>
				<label className={"label"}>{props?.variable?.name}</label>
				{reference && <p className={"preview-value"}>Referenced Element Type: {reference.type ? displayNameForElementType(reference.type) : ""}</p>}
				<Button buttonType={"default"} text={"Choose Reference"} onClick={clickOpen} />
			</FlexBox>
			<Description text={props.variable.description} />
			{state.openModal &&
				<ReferencePropertyEditor
				// value={{data: props.variable} as ElementPropertyData}
					onClose={closeDialog}
					onChange={onApply}
					type={'reference'}
					variables={documentManager.variables}
					variable={props.variable}
					onSubmit={function (newValue: unknown): void {
						throw new Error("Function not implemented.");
					} }					// variable={documentManager.variables} />
				// dataSourcesList={documentManager.datasources?.items}
				/>
			}
		</>
	)

}

const ReferenceEditor: VariableEditor = {
	type: "reference",
	view: Editor
};

export { ReferenceEditor }