import {useGetTasks} from "../../api/useGetTasks";
import {Tasks} from "../../../../../shared/interfaces/task";
import {ActionWithValue} from "../../../../../shared/interfaces/ActionRun";

function useLoadTasksAction(): ActionWithValue<Tasks> {
	const getTasks = useGetTasks();
	const run = () => getTasks.execute();

	return {
		run,
		isRunning: getTasks.isLoading,
		value: getTasks.value
	}
}

export {useLoadTasksAction}