import { PreviewElementProps } from "../model";
import {LoopDataValue} from "../../../../../shared/definitions/elements/loop/model";
import { useState } from "react";
import { Variable } from "../../../../../shared/interfaces/Variable";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import { PreviewViewFactory } from "../PreviewViewFactory";
import {executeExpression} from "../../../../../shared/utilities/data/executeExpression";
import {DataDefinition, DataSourceObjectType} from "../../../../../shared/interfaces/DataSource";

type ExpressionResultType = DataSourceObjectType | string[] | number[];

const PreviewView = (props: PreviewElementProps) => {
	const loopValue = props.element.data as LoopDataValue;
	const [expressionResult, setExpressionResult] = useState<string[] | number[] | DataDefinition[]>([]);

	useRunWhenValueChange(() => {
		const expression = loopValue.expression ? `${loopValue.expression} | stringify` : "";
		const executionResult = executeExpression(expression, props.variables);
		if (executionResult.result && executionResult.result.length > 0) {
			const jsonResult = JSON.parse(executionResult.result) as ExpressionResultType;
			const mappableValue = Array.isArray(jsonResult) ? jsonResult : jsonResult.data !== undefined && Array.isArray(jsonResult.data) ? jsonResult.data : [];
			setExpressionResult(mappableValue);
		}

	}, [props.element.data, props.variables]);

	if (expressionResult.length === 0) {
		return <>{loopValue.else.map(bodyItem => <PreviewViewFactory state={props.state} isLoadingVariables={props.isLoadingVariables} metaData={props.metaData} element={bodyItem} body={props.body} variables={props.variables} />)}</>;
	}

	return <>
		{expressionResult.map((item) => {
			const variable = { name: loopValue.name, value: item } as Variable;
			const variables = [...(props.variables ?? []), variable];
			return <>
				{loopValue.body.map(bodyElement => <PreviewViewFactory state={props.state} isLoadingVariables={props.isLoadingVariables} metaData={props.metaData} element={bodyElement} body={props.body} variables={variables} />)}
			</>
		})}
	</>;
}

export { PreviewView };