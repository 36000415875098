import {Action} from "../../../../../shared/interfaces/ActionRun";
import {SubmissionState} from "../../useSubmissionState";
import {isEctdSaveResponse, isLegacySaveResponse, useSaveSubmission} from "../../api/useSaveSubmission";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {useStatusBar} from "../../../../../shared/components";
import {runActionAcrossCollection} from "../../../pages/tocBuilder/utilities/runActionAcrossCollection";
import {Submission} from "../../../models/Submission";
import {createSaveSubmissionPayload} from "../../api/createSubmissionPayload";
const clone = require("rfdc/default");
function useSaveSubmissionAction(): Action {
	const saveSubmissionApi = useSaveSubmission();
	const {submission, setSubmission, resetNeedsSaving} = SubmissionState.useContainer();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if (saveSubmissionApi.value) {
			const legacyResponse = isLegacySaveResponse(saveSubmissionApi.value!) ? saveSubmissionApi.value! : undefined;
			const response = isEctdSaveResponse(saveSubmissionApi.value!) ? saveSubmissionApi.value! : undefined;
			setSubmission(s => ({...s, id: legacyResponse?.contentId ?? response!.id, version: legacyResponse?.versionId ?? response!.version, lastupdated: legacyResponse?.lastupdated ?? response?.lastupdated}));
			resetNeedsSaving();
			statusBar.sendSuccessNotification({message: "Updated submission"});
		}
	}, saveSubmissionApi.value);

	useRunWhenValueChange(() => {
		if (saveSubmissionApi.error) {
			statusBar.sendErrorNotification({message: "Error saving submission", detail: saveSubmissionApi.error})
		}
	}, saveSubmissionApi.error)

	useRunWhenValueChange(() => {
		if (saveSubmissionApi.isLoading) {
			statusBar.sendInfoNotification({message: "Updating submission"});
		}
	}, saveSubmissionApi.isLoading)

	const run = () => {
		const clonedSubmission = clone(submission) as Submission
		clonedSubmission.ectdModules = runActionAcrossCollection(submission.ectdModules, module => {
			const {active, ...rest} = module;
			return {...rest};
		});
		delete clonedSubmission["child_resources"];
		saveSubmissionApi.execute(createSaveSubmissionPayload(clonedSubmission));
	}
	return {
		run,
		isRunning: saveSubmissionApi.isLoading
	}
}

export {useSaveSubmissionAction};