import {ToggleProps} from "./model";
import {ToggleOnRounded, ToggleOffRounded} from "@mui/icons-material";
import {colors} from "../../../../theme";

const Switch = (props: ToggleProps) => {
	const onToggle = () => {
		if (props.onValueChange) {
			props.onValueChange(!props.value);
		}
	}
	return props.value ? <ToggleOnRounded fontSize={props.size} sx={{color: colors.trueBlue, cursor: "pointer"}} onClick={onToggle} /> : <ToggleOffRounded fontSize={props.size} sx={{color: colors.rainyGrey, cursor: "pointer"}} onClick={onToggle} />
}

export {Switch};