import {Variable} from "../../../../../shared/interfaces/Variable";
import {templateDefinition} from "../../../../../shared/definitions/elements/template/model";
import {EntityRecord} from "../../../../../shared/interfaces/Entity";
import {getTemplateApi} from "../../../../../shared/hooks/api/getTemplateApi";
import {LoadEntityFunc} from "../../../../../shared/interfaces/LoadEntityFuncType";
import {TemplateElement} from "../../../../../shared/interfaces/TemplateElement";
import { parseTemplateElements } from "../../../../../shared/utilities/parseTemplateElements";

const uuid = require("uuid");

function mapVariablesFromTemplate(template: KaiAlphaTemplate | null, topLevelName?: string) {
	return Object.keys(template?.variables ?? {}).map(key => ({id: uuid.v4(), name: key, namespace: template!.name === topLevelName ? "" : `${template!.name?.replace(/\W+/gmi, "")}`, templateId: template!.id, ...template!.variables![key]}) as Variable)
}
export async function loadAllVariablesFromTemplates(body: TemplateElement[], variables: Variable[], templateLoader: LoadEntityFunc<KaiAlphaTemplate> = getTemplateApi): Promise<Variable[]> {
	const entityRecords = body.reduce((templateElements, current) => {
		if (current.type !== templateDefinition.type) {
			return templateElements;
		}

		const info = current.contents;
		templateElements.push({id: info.id, version: info.version});
		return templateElements;
	}, [] as EntityRecord[]);

	if (entityRecords.length === 0) {
		return [...variables];
	}
	const allElements = await Promise.all(entityRecords.map(async entityRecord => {
		const template = await templateLoader({id:entityRecord.id, version: entityRecord.version});
		const templateVariables = mapVariablesFromTemplate(template);
		const templateChildren = await loadAllVariablesFromTemplates(parseTemplateElements(template!), templateVariables, templateLoader);
		return [...templateChildren.reduce((all, current) => all.concat([current]), [] as Variable[])];
	}));

	return [...variables].concat(allElements.reduce((all, current) => all.concat(current), []));


}