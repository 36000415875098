export type SharedContext = {
	counters: {[k: string]: {
			current: number,
			entries: {[k: string]: number}
		}},
	incrCounters: {[key: string]: number},
	abbreviations: {
		seen: {[key: string]: string}
	}

}

export function sharedContextFactory(): SharedContext {
	return ({
		counters: {},
		incrCounters: {},
		abbreviations: {
			seen: {}
		}
	});
}