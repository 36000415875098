import {ExtractionStatusLabelType, ExtractionStatusLabelTypeProps} from "./model";
import {CompletedExtractionLabel} from "./CompletedExtractionLabel";
import {SubmissionExtractionStatus} from "../../../../models/Submission";
import {RequestedExtractionLabel} from "./RequestedExtractionLabel";
import {StartedExtractionLabel} from "./StartedExtractionLabel";
import {ErrorExtractionLabel} from "./ErrorExtractionLabel";

export type ExtractionStatusLabelProps = {
	status: SubmissionExtractionStatus
} & ExtractionStatusLabelTypeProps

const labelMap: Record<SubmissionExtractionStatus, ExtractionStatusLabelType | null> = {
	Completed: CompletedExtractionLabel,
	Idle: null,
	Requested: RequestedExtractionLabel,
	Started: StartedExtractionLabel,
	Error: ErrorExtractionLabel
}
const ExtractionStatusLabel = (props: ExtractionStatusLabelProps) => {
	const Component = labelMap[props.status];
	return Component ? <Component location={props.location} error={props.error} /> : null;
}

export {ExtractionStatusLabel}