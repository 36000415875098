import {useRef} from "react";
import {useAsync, useInterval, useRunWhenValueChange} from "../../../shared/hooks";
import {createContainer} from "unstated-next";
import {DocumentState} from "./useDocumentState";
import {InTextTableVariableValue} from "../../../shared/definitions/elements/inTextTable/model";
import {Variable} from "../../../shared/interfaces/Variable";
const uuid = require("uuid");

export type IttDataPromise = InTextTableVariableValue & {source?: string};
type PromiseCollection = {
	promise: Promise<IttDataPromise>,
	metaData: InTextTableVariableValue
}
function useIttPromiseQueue() {
	const promises = useRef<PromiseCollection[]>([]);
	const {variables, setVariables} = DocumentState.useContainer();
	const promiseAll = useAsync((promiseCollection: PromiseCollection[]) => {
		// eslint-disable-next-line promise/prefer-await-to-then
		return Promise.all(promiseCollection.map(p => p.promise.catch(_ => ({
			...p.metaData,
			table: "<div>Error loading table</div>"
		}) as IttDataPromise)));
	}, false);

	useInterval(() => {
		const promisesToRun = promises.current.splice(0);
		promiseAll.execute(promisesToRun);
	}, 1000, () => promises.current.length > 0);

	const enqueue = (promise: Promise<IttDataPromise>, variableData: InTextTableVariableValue) => {
		promises.current = promises.current.concat([{promise, metaData: variableData}]);
	}

	useRunWhenValueChange(() => {
		if (promiseAll.value) {
			const variablesToAdd = promiseAll.value!.map(value => {
				const variableName = `${value.tableName.replace(/_/g, "")}_${value.studyId.replace(/-/g, "")}`
				return ({
					id: uuid.v4(),
					templateId: value.source,
					type: "intexttable",
					value: value,
					name: variableName,
					namespace: variableName}) as Variable;
			});

			setVariables([...variables, ...variablesToAdd]);

		}
	}, promiseAll.value);


	return {
		enqueue,
		isLoading: promiseAll.isLoading
	}
}

export const IttPromiseQueue = createContainer(useIttPromiseQueue);