import {useGetEctdMaster} from "../../hooks/api/useGetEctdMaster";
import {useRunOnce, useRunWhenValueChange} from "../../../../shared/hooks";
import {EctdModule} from "../../../../shared/interfaces/Module";
import {useState} from "react";
import {computeActiveState} from "./utilities/computeActiveState";
import { runActionAcrossCollection } from "./utilities/runActionAcrossCollection";
import {SubmissionState} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {moduleLinkGenerator} from "../../../moduleNavigation";
import {useCreateSubmissionAction} from "../../hooks/actions/load/useCreateSubmissionAction";
import {extractActiveModules} from "./utilities/extractActiveModules";

function useTocBuilder() {
	const getEctdMaster = useGetEctdMaster();
	const [ectdModules, setEctdModules] = useState<EctdModule[]>([])
	const [selectedModule, setSelectedModule] = useState<EctdModule | undefined>(undefined);
	const [selectedSection, setSelectedSection] = useState<EctdModule | undefined>(undefined);
	const {submission} = SubmissionState.useContainer();
	const navigate = useNavigate();

	// actions
	const createSubmissionAction = useCreateSubmissionAction();

	useRunOnce(() => {
		if (!submission.compound || (!submission.regulator || submission.regulator.length === 0)) {
			navigate(moduleLinkGenerator("submission", "create"));
		}

		getEctdMaster.execute();
	});

	useRunWhenValueChange(() => {
		if (getEctdMaster.value) {
			const modules = runActionAcrossCollection(getEctdMaster.value, (module) => ({...module, active: true}));
			setEctdModules(modules);
			const firstModule = modules[0];
			setSelectedModule(firstModule);
			selectFirstSection(firstModule);
		}
	}, getEctdMaster.value);

	const selectFirstSection = (module: EctdModule) => {
		if ((module.modules ?? []).length === 0) {
			return;
		}
		selectSection(module.modules[0]);
	}

	const selectSection = (module: EctdModule) => setSelectedSection(module);

	const selectModule = (module: EctdModule) => {
		setSelectedModule(module);
		selectFirstSection(module);
	}

	const toggleActiveState = (module: EctdModule) => {
		module.active = !module.active;
		module.modules = runActionAcrossCollection(module.modules, (m) => ({...m, active: module.active}));
		computeActiveState(ectdModules, module);
		setEctdModules([...ectdModules]);
		setSelectedModule(selectedModule);
		setSelectedSection(selectedSection);
	}

	const activeModules = () => ectdModules.filter(module => module.active);

	const sectionsForModule = (moduleNumber: string) => ectdModules.filter(module => module.parent === moduleNumber);

	const onCreateSubmission = () => {
		const modules = runActionAcrossCollection(extractActiveModules(ectdModules),
			m => ({moduleNumber: m.moduleNumber, moduleTitle: m.moduleTitle, modules: m.modules, folderName: m.folderName}));
		createSubmissionAction.run({...submission, ectdModules: modules});
	}

	return {
		modules: ectdModules,
		onCreateSubmission,
		selectedModule,
		sectionsForModule,
		selectedSection,
		selectModule,
		selectSection,
		toggleActiveState,
		activeModules,
		isRunning: getEctdMaster.isLoading,
		isSaving: createSubmissionAction.isRunning
	}

}

export {useTocBuilder}