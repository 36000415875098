import { TabItem, TabContainer, TabItemName, FlexBox } from "./DocumentCommentsFilterTabs.style";

export interface FilterTabsProps {
	tabs: FilterTabDefinition[],
	selectedTabIndex: number,
	onTabSelected: (tabIndex: number) => void
	maxWidth?: string,
}

export interface FilterTabDefinition {
	type: string,
	displayName: string
	// the number of items for the bubble.
	total?: number,
}

const DocumentCommentsFilterTabs = ({ tabs, onTabSelected, selectedTabIndex, maxWidth }: FilterTabsProps) => {

	return <FlexBox justify="center" maxWidth={maxWidth}>
		<TabContainer>
			{tabs.map((tab, index) =>
				<TabItem
					active={selectedTabIndex === index}
					onClick={() => { onTabSelected(index) }}
					key={tab.displayName}
				>
					<TabItemName>{tab.displayName}</TabItemName>
				</TabItem>
			)}
		</TabContainer>
	</FlexBox>
};

export { DocumentCommentsFilterTabs }