import {useDeleteComment} from "../../api/comments/useDeleteComment";
import {CommentState} from "../../useCommentState";
import {TemplateState} from "../../useTemplateState";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {useStatusBar} from "../../../../../shared/components";
import {useState} from "react";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

const useDeleteCommentAction = (): TypedAction<KaiAlphaCommentEntry> => {
	const [commentToDelete, setCommentToDelete] = useState<KaiAlphaCommentEntry|undefined>(undefined);
	const deleteCommentApi = useDeleteComment();
	const commentManager = CommentState.useContainer();
	const statusBar = useStatusBar();
	const templateManager = TemplateState.useContainer();

	const deleteComment = async (commentPayload: KaiAlphaCommentEntry) => setCommentToDelete(commentPayload)

	useRunWhenValueChange(() => {
		if(commentToDelete) {
			deleteCommentApi.execute(templateManager.template?.id, commentToDelete);
		}
	}, commentToDelete);

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error deleteing comment", detail: deleteCommentApi.error});
	}, deleteCommentApi.error);

	useRunWhenValueChange(() => {
		// on success make call to get all comments
		if(templateManager?.template?.id && commentToDelete) {
			// remove comment from existing state
			commentManager.setComments([...commentManager.comments?.filter(comment => comment.id !== commentToDelete!.id) ?? []])
		}
		statusBar.sendSuccessNotification({message: "Comment deleted"});
	}, deleteCommentApi.value);

	return {run: deleteComment, isRunning: deleteCommentApi.isLoading};

}

export {useDeleteCommentAction}