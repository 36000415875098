import {ToolBarButton, ToolbarButtonProps} from "./ToolbarButton";

export type ToolbarToggleItemEvents = {
	onToggle: (toggleValue: boolean) => void
}
export type ToolbarToggleItemProps = ToolbarButtonProps & ToolbarToggleItemEvents & {
	active: boolean,
	disabled?: boolean
}

const activeStyles = {
	boxShadow: "inset 0 2px 4px 0 rgb(0 0 0 / 5%)",
	background: "#3A5DA2"
}

const disabledStyles = {
	color: "rgba(255,255,255,0.3)",
	boxShadow: "none",
	background: "transparent"
}
const ToolBarToggleButton = (props: ToolbarToggleItemProps) => {
	const onClick = () => props.onToggle(!props.active);

	const isActive = props.active && !(props.disabled ?? false);
	return <ToolBarButton text={props.text} icon={props.icon} style={{...(props.style ?? {}), ...(isActive ? activeStyles : {}), ...((props.disabled ?? false) ? disabledStyles : {})}} onClick={onClick} />
}

export {ToolBarToggleButton}