import {VariableListing} from "../../../VariableListing";
import validation_utils from "../../../../../../../lib/utils/validation_utils";
import {DropDown, DropDownItem} from "../../../../../../../shared/components/input/dropdown";
import {useState} from "react";
import {useVariables} from "../../../../../hooks/useVariables";
import {DataSourceProperties} from "./types/DataSourceProperties";
import {ListProperties} from "./types/ListProperties";
import {ExpressionProperties} from "./types/ExpressionProperties";
import {VariableInput} from "./model";
import {DialogProps} from "../../../../../models/elements";
import {Dialog, TextField} from "../../../../../../../lib/ui";
import {Checkbox} from "../../../../../../../components/Checkbox/Checkbox";
import {Box, styled} from "@mui/material";

const DialogContainer = styled(Box)({
	height: '45vh',
	overflowY: 'scroll',
	overflowX: 'hidden',
	padding: "0.5em",
	display: "flex",
	gap: "1em",
	flexDirection: "column",
});

interface VariablePropertyEditorMap {
	type: string,
	component: React.ElementType
}
const variablePropertyEditors: VariablePropertyEditorMap[] = [{
	type: "datasource",
	component: DataSourceProperties
}, {
	type: "dropdown",
	component: ListProperties
}, {
	type: "checkbox",
	component: ListProperties
}, {
	type: "list",
	component: ListProperties
},{
	type: "expression",
	component: ExpressionProperties
}];

const menuItems: DropDownItem<string>[] = validation_utils.structure.variable_types.map((menuItem: any) => {
	return {
		title: menuItem,
		value: menuItem
	}
});

const initialVariables: VariableInput = {
	name: "",
	description: "",
	type: menuItems[0].value,
	required: false
}

const getType = (type: string) => menuItems.find(item => item.value === type)?.value;
/*
 * AddVariableDialog component
 */
const PropertyEditor = (props: DialogProps) => {
	const {
		onClose,
		onApply,
		value
	} = props;

	/* set initial state based on props value */
	const variableState = value !== undefined ? value.data as VariableInput : initialVariables
	const variableUtils = useVariables();

	const [variableInfo, setVariableInfo] = useState({
		...variableState,
		type: (variableState.type?.length ?? 0) === 0 ? menuItems[0].value : variableState.type
	});

	const [error, setError] = useState<boolean>(false); // To show validation error
	const [showDialog, setShowDialog] = useState<boolean>(true);

	const applyHandler = () => {
		const result = variableUtils.validateNames({excludeName: value === undefined ? undefined : variableState.name?.toLowerCase() ?? "", nameToValidate: variableInfo.name?.toLowerCase() ?? ""})
		if (result.hasDuplicate || (variableInfo?.name?.length ?? 0) === 0) {
			setError(true);
		} else {
			setError(false);
			onApply({...props.value, data: variableInfo});
		}

	};

	const handleClose = () => {
		setShowDialog(false);
		if (onClose) {
			onClose();
		}
	};

	const handleChange = (value, item) => {
		setVariableInfo(prevState => ({...prevState, [item]: value}));
	};

	const onPropertyEditorChange = (value: unknown) => setVariableInfo(vi => ({...vi, options: value}));
	const PropertyEditor = variablePropertyEditors.find(editor => editor.type === variableInfo.type)?.component;

	return (
		<Dialog
			open={showDialog}
			title={'Variable Properties'}
			onClose={handleClose}
			style={{ minWidth: "60%" }}
			buttons={{
				Close: handleClose,
				Apply: applyHandler,
			}}
		>
			<DialogContainer>
				<TextField
					error={error}
					id="outlined-basic"
					label={'Variable Name'}
					defaultValue={''}
					value={variableInfo.name}
					onChange={(event) => handleChange(event.target.value, 'name')}
					{...(error && { helperText: 'Variable should be unique and non-empty' })}
				/>
				<TextField
					id="outlined-basic"
					label={'Variable Description'}
					defaultValue={''}
					value={variableInfo.description}
					onChange={(event) => handleChange(event.target.value, 'description')}
				/>
				<DropDown
					items={menuItems}
					onSelect={(event) => handleChange(event.value, 'type')}
					value={getType(variableInfo.type)}
					label={"Select"} />
				<Checkbox
					checked={variableInfo.required}
					value={variableInfo.required}
					label={'Required Variable'}
					onChange={(newValue) => handleChange(newValue, "required")}
					variant="square"
				/>
				{PropertyEditor && <PropertyEditor {...props} onChange={onPropertyEditorChange} />}
				<VariableListing />
			</DialogContainer>
		</Dialog>
	);
};

export { PropertyEditor };
