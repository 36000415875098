import {Column, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {TemplateListNode} from "../models";
import {OwnerCell} from "../../../../../../shared/components/layout/table/cells/OwnerCell";

const Cell = (props: ColumnViewProps) => {
	const item = props.item as TemplateListNode;
	const column = props.column;
	return <OwnerCell column={column} owners={item.owners} id={item.id} />
}


const Owner: Column = ({
	field: "owner",
	title: "Owner",
	headerAlign: "left",
	itemAlign: "left",
	width: "25%",
	sortable: true,
	searchable: true,
	views: {
		cell: Cell
	}
})

export {Owner};