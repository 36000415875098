import {ElementIconProps, IconDefaults} from "../model";

const TocIcon = ({color}: ElementIconProps) => {

	const lineStyle = {
		fill: "none",
		stroke: color ?? IconDefaults.color!,
		"stroke-linecap": "round",
		"stroke-linejoin": "round",
		"stroke-width": "2px"
	}

	const fillStyle = {
		stroke: color ?? IconDefaults.color!,
		fill: color ?? IconDefaults.color!
	}

	const noFill = {
		fill: "none"
	}

	const noStroke = {
		stroke: "none"
	}

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16.889" height="12" viewBox="0 0 16.889 12">
			<g transform="translate(-855 -514.2)">
				<g transform="translate(860 515.2)">
					<path style={lineStyle} d="M15.389,15H4.5" transform="translate(-4.5 -10)"/>
					<path style={lineStyle} d="M15.389,9H4.5" transform="translate(-4.5 -9)"/>
					<path style={lineStyle} d="M15.389,21H4.5" transform="translate(-4.5 -11)"/>
				</g>
				<g style={fillStyle} transform="translate(855 514.2)">
					<circle style={noStroke} cx="1" cy="1" r="1"/>
					<circle style={noFill} cx="1" cy="1" r="0.5"/>
				</g>
				<g style={fillStyle} transform="translate(855 519.2)">
					<circle style={noStroke} cx="1" cy="1" r="1"/>
					<circle style={noFill} cx="1" cy="1" r="0.5"/>
				</g>
				<g style={fillStyle} transform="translate(855 524.2)">
					<circle style={noStroke} cx="1" cy="1" r="1"/>
					<circle style={noFill} cx="1" cy="1" r="0.5"/>
				</g>
			</g>
		</svg>

	)
}

export {TocIcon}