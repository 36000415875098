import {CircularProgress} from "./CircularProgress";
import {styled} from "@mui/material";
import {FlexBox} from "../layout";

export interface LoadingIndicatorProps {
	transparent?: boolean,
}

const Container = styled(FlexBox, {shouldForwardProp: (prop) => prop !== "transparent"})<LoadingIndicatorProps>(({theme, transparent}) => (({
	background: transparent ? theme.palette.background.default : theme.palette.background.paper,
	zIndex: 10,
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	position: "absolute"
})))

const LoadingIndicator = ({transparent}: LoadingIndicatorProps) => {
	return <Container justify={"center"} align={"center"} transparent={transparent}>
		<CircularProgress />
	</Container>
}

export {LoadingIndicator};