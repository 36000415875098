import {VariableType} from "../../../../../../shared/interfaces/Variable";
import React from "react";
import {VariableEditorProps} from "../editor/model";
import {GenericEditorModal} from "./GenericEditorModal";
import {DataSourceEditorModal} from "./DataSourceEditorModal";
import {InTextTableEditorModal} from "./InTextTableEditorModal";

export type EditorModalProps = VariableEditorProps & {Component?: React.FC<VariableEditorProps>, onSubmit: (newValue: unknown) => void}
export type EditorModalDefinition = {
	type: VariableType | undefined,
	view: React.FC<EditorModalProps>
}
const modals = [GenericEditorModal, DataSourceEditorModal, InTextTableEditorModal];

export function editorModalForType(type: VariableType): React.FC<EditorModalProps> | undefined {
	return modals.find(editor => editor.type === type)?.view ?? GenericEditorModal.view;
}