import {useTypedAsync} from "..";
import {searchApi, SearchApiProps, SearchApiResponse} from "./searchApi";
import {TypeAsyncReturn} from "../useAsync";
import {getDocumentFromSearchResult} from "../../utilities/data/getDocumentFromSearchResult";
import {SearchResult} from "../../interfaces/SearchResult";

export type SearchDocumentsQuery = Omit<SearchApiProps, "searchType" | "fields">
export async function searchDocumentsApi(queryParams: SearchDocumentsQuery): Promise<SearchApiResponse<KaiAlphaDocument>> {
	const searchResponse = await searchApi<SearchResult>({...queryParams, searchType: "document", fields: ["_external_data", "_s3Metadata"]});
	const results = searchResponse.results
		.map(getDocumentFromSearchResult)
		.filter(e => e.isRight)
		.map(e => e.value as KaiAlphaDocument);

	return {
		...searchResponse,
		results
	}
}

// gets all documents visible to the user, filtered by the provided query parameters.
function useGetDocuments(): TypeAsyncReturn<Omit<SearchApiProps, "searchType" | "fields">, SearchApiResponse<KaiAlphaDocument>> {
	return useTypedAsync(searchDocumentsApi, false);
}

export {useGetDocuments};