import {NunjucksVariables} from "../interfaces/Nunjucks";
import {
	defaultEnvironmentOptions,
	NunjucksEnvironmentOptions
} from "./models/NunjucksEnvironmentOptions";
import {NullLoader} from "./models/NullLoader";
import {nunjucksFilterFactories, nunjucksGlobalFilterFactories} from "./filters";
import {NunjucksFilterDependencies} from "./filters/model";
import {getIds, incrementId, incrementInput} from "./filters/dependencies";
const nunjucks = require('nunjucks');

const filterDependencies: NunjucksFilterDependencies = {
	incrementInput: incrementInput,
	incrementId: incrementId,
	getIds: getIds
}

function loadFilters(env: any, variables: NunjucksVariables, options: NunjucksEnvironmentOptions) {
	nunjucksFilterFactories.map(factory => factory({variables, options, dependencies: filterDependencies})).forEach(filter => env.addFilter(filter.name, filter.execute))
}

function loadGlobalFilters(env: any, variables: NunjucksVariables, options: NunjucksEnvironmentOptions) {
	nunjucksGlobalFilterFactories.map(factory => factory({variables, options, dependencies: filterDependencies})).forEach(filter => env.addGlobal(filter.name, filter.execute))
}

function setupEnvironment(loader: any, variables: NunjucksVariables, options?: NunjucksEnvironmentOptions): any {
	const env = new nunjucks.Environment(loader, { autoescape: false });
	const environmentOptions = options ?? defaultEnvironmentOptions;
	loadFilters(env, variables, environmentOptions);
	loadGlobalFilters(env, variables, environmentOptions);
	return env;
}

export function nullLoaderEnvironmentFactory(variables: NunjucksVariables, options?: NunjucksEnvironmentOptions) {
	return setupEnvironment(new NullLoader('/'), variables, options);
}

export function fileSystemLoaderEnvironmentFactory(variables: NunjucksVariables, options?: NunjucksEnvironmentOptions) {
	return setupEnvironment(new nunjucks.FileSystemLoader('/'), variables, options);
}
