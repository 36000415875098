import {MetaDataValueEditor, ValueEditorProps} from "./model";
import {useRunOnce, useAsync} from "../../../../../../../shared/hooks";
import {AsyncComponent} from "../../../../../../../shared/components";
import {DropDownItem} from "../../../../../../../shared/components/input/dropdown";
import {MultiSelectValueEditor} from "./MultiSelectValueEditor";
import {getIndicationsApi} from "../../../../../../../shared/metaData/api/getIndicationsApi";

const Editor = (props: ValueEditorProps) => {
	const loadIndications = useAsync(getIndicationsApi, false);
	useRunOnce(() => {
		loadIndications.execute();
	})

	const dropDownItems = (loadIndications.value?.map(indication => ({title: indication, value: indication})) ?? []) as DropDownItem<string>[];
	return <AsyncComponent
		isLoading={loadIndications.isLoading}
		component={<MultiSelectValueEditor {...props} data={{value: props.data.value, options: {items: dropDownItems}}} />} />
}
export const IndicationsEditor: MetaDataValueEditor = {
	view: Editor,
	displayTextGenerator: (value: string) => {
		const indications = JSON.parse(value) as string[];
		return indications.join(", ");
	},
	defaultValueFactory: () => JSON.stringify([])
}