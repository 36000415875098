import {TypedStyleView, TypedViewProps} from "./model";
import {PropertyList} from "../../../PropertyList";

type AddStyleType = {
	style_name: string
}


const View = (props: TypedViewProps) => {
	const value = props.value as AddStyleType;
	return <PropertyList items={[{label: "Style name", value: value.style_name}]} />
}

const AddStyleView: TypedStyleView = {
	type: "AddStyle",
	view: View
}
export {AddStyleView}