import {NunjucksVariables} from "../../interfaces/Nunjucks";
import {computeExpressionFactory} from "./computeExpressionFactory";
import {renderString} from "../renderString";

type ComputeExpressionOptions = {
	throwErrors: boolean
}
type ComputeExpressionProps = {
	expression: string,
	variables: NunjucksVariables,
	options?: ComputeExpressionOptions
}
export function computeExpression({expression, variables, options}: ComputeExpressionProps) {
	const opts: ComputeExpressionOptions = {
		throwErrors: false,
		...(options ?? {})
	}

	expression = computeExpressionFactory(expression);
	let result;
	try {
		result = renderString({template: `{{${expression}}}`, variables});
	} catch (render_error) {
		if (opts.throwErrors) {
			throw(render_error);
		}
		result = '';
	}

	return result;
}