import { ContextButtonFactory, DefaultContextButtons } from '../../../shared/components/buttons/theme'
import { ModuleLayout } from "../../../shared/components/layout/ModuleLayout";
import { FlexBox, MessageBox, MessageBoxActions, useStatusBar } from "../../../shared/components";
import { AsyncComponent } from "../../../shared/components";
import { useList } from "./useList";
import Masthead from "../../../shared/components/navigation/masthead";
import React, { useRef, useState } from 'react';
import { TemplatesList } from '../components/list/templates/TemplatesList';
import { useRunWhenValueChange } from '../../../shared/hooks';
import { MessageBoxActionType } from '../../../shared/components/modals/messageBox/models';
import {TemplateListNode, TemplateTypes} from '../components/list/templates/models';
import { CreateTemplateModal } from '../components/modals/create';
import { TemplateTaskModal } from '../components/modals/templateTasks';
import {FilterState} from "../../../shared/components/input/filter/sort/models";

const List = () => {
	const listManager = useList();
	const [page, setPage] = useState(0);
	const input = useRef<HTMLInputElement | null>(null);
	const statusBarManager = useStatusBar();

	useRunWhenValueChange((prevPage) => {
		if ((prevPage ?? 0) < page && listManager.templates.length <= (page * listManager.pageSize)) {
			listManager.setAfter(listManager.after)
		}
	}, page);

	// delete confirmation //
	const [confirmDelete, setConfirmDelete] = useState<{ open: boolean, pendingTemplate: TemplateListNode | null }>({ open: false, pendingTemplate: null });

	const onDelete = (template: TemplateListNode) => {
		setConfirmDelete({ open: true, pendingTemplate: template });
	}

	const onMessageBoxAction = (action: MessageBoxActionType) => {
		if (action === MessageBoxActions.Primary) {
			listManager.deleteTemplate(confirmDelete.pendingTemplate!);
		}
		setConfirmDelete({ open: false, pendingTemplate: null });
	}

	const onMessageBoxClose = () => onMessageBoxAction(MessageBoxActions.Secondary)


	useRunWhenValueChange(() => {
		if (listManager.error) {
			statusBarManager.sendErrorNotification({ message: listManager.error });
		}
	}, listManager.error);


	const importTemplate = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target?.files![0];
		listManager.importTemplate(file);
	}

	const onContextButtonClick = (key: string) => {
		switch (key) {
			case DefaultContextButtons.New.key:
				listManager.createNewTemplate();
				break;
			case DefaultContextButtons.Import.key:
				input.current?.click();
				break;
			case DefaultContextButtons.Edit.key:
				listManager.editTemplate();
				break;
			case DefaultContextButtons.Delete.key:
				onDelete(listManager.selectedTemplate!);
				break;
			default:
				break;
		}
	}

	const onPageChange = (event: unknown, newPage: number) => setPage(newPage);
	const onSearchChange = (field?: string, searchValue?: string) => {
		if (searchValue !== undefined && field) {
			const didUpdateFilters = listManager.setFilter({
				field, value: searchValue ?? ""
			});

			if (didUpdateFilters) {
				setPage(0);
			}
		}
	}

	const getCurrentSortDirection = (): FilterState => {
		if (listManager.sort?.direction === "asc") {
			return "ascending";
		} else if (listManager.sort?.direction === "desc") {
			return "descending"
		}

		return "none";
	}

	const onSort = (field: string, sortDirection: FilterState) => {
		listManager.setSort(sortDirection === "none" ? undefined : {field, direction: sortDirection === "ascending" ? "asc": "desc"})
		setPage(0);
	}

	const onChangeDisplayType = (type: TemplateTypes) => {
		setPage(0);
		listManager.changeDisplayedTemplates(type)
	}

	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const pageSize = Number(event.target.value);
		listManager.setPageSize(pageSize);
		setPage(0);
	}

	return <>
		<AsyncComponent
			isLoading={listManager.isLoading}
			loadingMessage={listManager.loadingMessage ?? undefined}
			fullScreen
			component={<FlexBox direction={"column"} height={"100%"}>
				<Masthead
					contextButtons={ContextButtonFactory(
						listManager.contextButtons,
						onContextButtonClick)}
				/>
				<ModuleLayout
					center={<TemplatesList
						filters={(listManager.filters ?? []).reduce((lookup, filter) => {
							if (filter.field === undefined) {
								return lookup;
							}

							lookup[filter.field] = filter.value;
							return lookup;
						}, {})}
						templates={listManager.templates}
						onError={(message) => statusBarManager.sendErrorNotification({ message })}
						selectedTemplate={listManager.selectedTemplate?.id ?? null}
						displayedTemplatesType={listManager.displayedTemplateType}
						onRowSelected={listManager.onTemplateSelected}
						onDeleteTemplate={(template) => onDelete(template)}
						onViewTasks={listManager.viewTasks}
						onTemplateDisplayChange={onChangeDisplayType}
						page={page}
						onPageChange={onPageChange}
						pageSize={listManager.pageSize}
						onColumnSearch={onSearchChange}
						onPageSizeChange={onPageSizeChange}
						onSort={onSort}
						total={listManager.total}
						sort={{field: listManager.sort?.field ?? "", sortDirection: getCurrentSortDirection()}}
					/>}
				/>
			</FlexBox>} />
		<input
			accept={".docx,.json"}
			style={{ display: 'none' }}
			ref={input}
			type="file"
			key="document"
			onChange={importTemplate}
			data-test-id={"template-import"}
		/>
		<MessageBox
			destructive
			visible={confirmDelete.open}
			message={<>Are you sure you want to delete <b>{confirmDelete.pendingTemplate?.name}</b>?</>}
			title={"Delete Template"}
			note={<><b>Note</b>: all dependent Sub-templates of this Template will also be deleted (independent sub-templates will remain).</>}
			primaryAction={"Delete"}
			secondaryAction={"Cancel"}
			onActionClick={onMessageBoxAction}
			onClose={onMessageBoxClose}
		/>
		<CreateTemplateModal
			visible={listManager.displayCreateTemplateModal}
			onCancel={listManager.invertCreateTemplateModal}
		/>
		<TemplateTaskModal
			visible={listManager.displayTemplateTaskModal}
			onCancel={listManager.invertTemplateTaskModal}
			templateId={listManager.selectedTemplate?.id ?? undefined}
			templateName={listManager.selectedTemplate?.name ?? undefined}
		/>
	</>

}

export { List };