import {styled} from "@mui/material";

const NumberedHeaderBlue = styled('span')({
	div: {
		display: "flex",
		flexDirection: "row",
	},
	h2: {
		color: "#426AB9"
	},
	span: {
		background: "#426AB9"
	}
});

const ColumnBox = styled('div')({
	display: "flex",
	flexDirection: "column",
	div: {
		paddingBottom: "20px"
	}
});

const GreyBorder = styled('div')({
	border: "1px solid lightgrey",
	borderRadius: "7px"
});

const SubText = styled('span')({
	fontSize: '10px',
	color: 'grey',
	padding: '0px',
	position: 'relative',
	top: '-20px'
});

export {NumberedHeaderBlue, ColumnBox, GreyBorder, SubText}