import {DocumentElementDefinition} from "../model";
import {PreviewView} from "./views/PreviewView";
import {
	htmlDefinition as baseHtmlDefinition
} from "../../../../../shared/definitions/elements/html/model";

const htmlDefinition: DocumentElementDefinition = {
	...baseHtmlDefinition,
	views: {
		preview: PreviewView
	},
} as const

export {htmlDefinition};