import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';

import nunjucks_utils from '../../lib/utils/nunjucks_utils';
import { TextField } from '@mui/material';

const keywordsToIgnore = ['|'];

class ExpressionBuilder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			inputValue: this.props.value ? this.props.value : '',
			fakeValue: '',
			isOpen: true
		}

		this.defaultConfig = [
			{
				trigger: ' ',
				options: nunjucks_utils.constants.operators,
				excludePredecessors: [...nunjucks_utils.constants.operators, undefined]
			},
			{
				trigger: '|',
				options: nunjucks_utils.constants.filters
			}
		];

		this.filterOptions = this.filterOptions.bind(this);
		this.suggestItems = this.suggestItems.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	isStackEmpty(stack) {
		return stack.length === 0;
	}

	peek(stack) {
		return stack[stack.length-1];
	}

	areQuotesBalanced(text) {
		const stack = [];
		for (let i = 0; i < text.length; ++i){
			switch (text.charAt(i)) {
				case '"': case "'":
					if (this.isStackEmpty(stack) || this.peek(stack) !== text.charAt(i)) {
						stack.push(text.charAt(i));
					} else {
						stack.pop();
					}
					break;
				default:
					break;
			}
		}
		return this.isStackEmpty(stack);
	}

	toLowerCaseArray(array) {
		if (array !== undefined) {
			array = array.map(function(item) {
				if (item !== undefined) {
					return item.toLowerCase();
				} else {
					return item;
				}
			});
		}
		return array;
	}

	getPreviousWord(text, options = {}) {
		if (text.length > 0) {
			const words = text.split(' ');
			if (words.length > 1) {
				const previousWord = words[words.length - 2];
				/*
				 * Option can be used, when searching for previous words,
				 * and if we want to ignore certain keywords
				 */
				if (options.ignoreKeyWords === true) {
					if (keywordsToIgnore.indexOf(previousWord) === -1) {
						return previousWord;
					} else {
						words.pop();
						return this.getPreviousWord(words.join(' '));
					}
				} else {
					return previousWord;
				}
			}
		}

		return undefined;
	}

	didTriggerMatch(trigger, value, previousWord) {
		return !!(trigger === previousWord || (trigger === ' ' && value.endsWith(' ')) || trigger === nunjucks_utils.constants.any);
		// return !!(trigger === previousWord || (trigger === ' ' && value.endsWith(' ')) || trigger === nunjucks_utils.constants.any || trigger === nunjucks_utils.constants.filters_help.regex);
	}

	/*
	 * Checks if the previous word is not in the list of predecessors to exclude
	 */
	didExcludePredecessors(excludePredecessors, previousWord) {
		excludePredecessors = this.toLowerCaseArray(excludePredecessors);
		return !(excludePredecessors !== undefined && excludePredecessors.indexOf(previousWord) !== -1);
	}

	/*
	 * Checks if the previous words matches any one of the predecessors to include
	 */
	didIncludePredecessors(includePredecessors, previousWord) {
		includePredecessors = this.toLowerCaseArray(includePredecessors);
		return !(includePredecessors !== undefined && includePredecessors.indexOf(previousWord) === -1);
	}

	suggestItems(value) {
		/*
		 * Do not suggest when quotes are unbalanced,
		 * indicates that the user is typing a string,
		 */
		if (this.areQuotesBalanced(value.toString())) {
			const previousWord = this.getPreviousWord(value);
			let autoCompleteConfig = this.props.autoCompleteConfig;

			if (autoCompleteConfig === undefined) {
				autoCompleteConfig = this.defaultConfig;
			}

			for (const config of autoCompleteConfig) {
				if (this.didTriggerMatch(config.trigger, value, previousWord)
		&& this.didExcludePredecessors(config.excludePredecessors, previousWord)
		&& this.didIncludePredecessors(config.includePredecessors, previousWord)
		&& config.options !== undefined) {
					return config.options;
				}
			}
			/**
	 * when no suggestions are available
	 */
			return [];
		} else {
			return undefined;
		}
	}

	getCurrentWord(text) {
		const words = text.split(" ");
		return words[words.length - 1];
	}

	filterOptions(options, { inputValue }) {
		let items = this.suggestItems(inputValue);
		if (items === undefined || JSON.stringify(items) === "{}") {
			return([]);
		}

		items = items.map(item => item.toLowerCase());

		const currentWord = this.getCurrentWord(inputValue);

		const nunjuckWordFilter = items.filter((char) => {
			return char.indexOf(currentWord.toLowerCase()) !== -1;
		});

		/*
		If No Word matches Nunjucks Expressions Determine AND Not Trigger Operator
		Grab everything before user open parenthesis
		 */
		const isTriggerOperator = Object.keys(nunjucks_utils.constants.operators_help).some( operator => currentWord === operator)
		if (nunjuckWordFilter.length === 0 && !isTriggerOperator) {
			// Split And grab only initial word before open parenthesis
			nunjuckWordFilter.push(currentWord.split('(')[0])
		}

		return nunjuckWordFilter
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			inputValue: value
		});

		/*
		 * Small hack to reset the internal state of the material autocompleter,
		 * to allow it to suggest the previously suggested value,
		 * if the input in totally wiped,
		 */
		// if (value === '') {
		// 	this.setState({
		// 		fakeValue: ''
		// 	});
		// }
	}

	handleBlur() {
		const changeEvent = {
			target: {
				value: this.state.inputValue
			}
		}
		/*
		 * update parent state only on onBlur,
		 * otherwise just maintain state locally,
		 */
		this.props.onChange(changeEvent);
		const { onBlur } = this.props;
		if (onBlur) {
			onBlur(this.state.inputValue);
		}
	}

	/**
	 * GetOptionalLabelText
	 * Get help string for Custom Filters
	 * @param event
	 * @returns {string|*}
	 */
	getOptionalLabelText(event){
		let optionalLabel;
		// Find Reference
		const helpObject = nunjucks_utils.constants.filters_help[event]

		// Determine if Helper exists
		if (helpObject) {
			optionalLabel = helpObject?.example ? ` ${helpObject.example}` : ` ${helpObject.help}`
		}

		// Attach Helper Text label or Return Event String
		return optionalLabel ? `${event} : ${optionalLabel}` : event
	}

	count = 0;

	render() {
		//TODO: Do this in a better way
		// if (this.props.value !== this.state.inputValue && this.count === 0 && this.props.is_tiny_mce === true) {
		// 	this.setState({inputValue: this.props.value});
		// 	this.count = 1;
		// }

		return(
			<Autocomplete
				id="expression-builder"
				disableClearable
				sx={{width: "100%"}}
				options={nunjucks_utils.constants.filters}
				getOptionLabel={this.getOptionalLabelText}
				autoHighlight={true}
				value={this.state.inputValue}
				inputValue={this.state.inputValue}
				filterOptions={this.filterOptions}
				onOpen={() => {
					this.setState({
						isOpen: true
					});
				}}
				onClose={() => {
					this.setState({
						isOpen: false
					});
				}}
				onChange={(event, newInputValue) => {
					/**
					 * This event is triggered when the user presses enter,
					 * so append the chosen string to the existing input,
					 * instead of replacing it, only if the popup is open.
					 */
					// if (this.state.isOpen) {
					// 	const words = this.state.inputValue.split(" ");
					// 	words.pop();
					// 	words.push(newInputValue);
					// 	const newWord = words.join(" ");
					// 	this.setState({
					// 		inputValue: newWord
					// 	});
					// }
					const words = this.state.inputValue.split(" ");
					words.pop();
					words.push(newInputValue);
					const newWord = words.join(" ");
					this.setState({
						inputValue: newWord
					});
				}}
				freeSolo={true}
				renderInput={(params) => {
					return <TextField {...params} autoFocus label='Expression' value={this.state.inputValue} onChange={this.handleChange} onBlur={this.handleBlur}/>
				}}
			/>
		);
	}
}

export default ExpressionBuilder;
