/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/lib/date_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const _testing = undefined;

function _construct_date(date) {
	if (date instanceof Date) {
		return(date);
	}

	if (date === undefined) {
		date = new Date();
	} else {
		date = new Date(date);
	}

	if (isNaN(date.valueOf())) {
		throw(new Error(`Invalid date: ${date}`));
	}

	return(date);
}

/*
 * Format a date for human consumption
 */
function format_date(date, options = {}) {
	options = {
		use_relative: true,
		...options
	};

	/*
	 * Normalize the input
	 */
	date = _construct_date(date);

	if (options.use_relative) {
		const now = new Date();

		const difference_date = now.valueOf() - date.valueOf();
		let fudge = 0;
		if (Math.abs(difference_date) > 0) {
			fudge = -0.1;
		} else {
			fudge = 0.1;
		}

		const difference = Math.ceil((difference_date / (1000 * 60 * 60 * 24)) + fudge);
		let difference_plural = 's';
		if (Math.abs(difference) === 1) {
			difference_plural = '';
		}

		if (difference > 1) {
			return `${difference} day${difference_plural} ago`;
		} else if (difference < 0) {
			return `In ${-1 * difference} day${difference_plural}`;
		} else {
			if (date.getDay() === now.getDay()) {
				return `Today ${date.toLocaleString().split(',')[1]}`;
			} else {
				return `Yesterday ${date.toLocaleString().split(',')[1]}`;
			}
		}
	}

	return(date.toISOString());
}

if (_testing) {
	_testing.format_date = function() {
		let result;

		result = format_date(Date.now() - (1000 * 60 * 30));

		/* istanbul ignore if */
		if (!result.match(/Today /) && !result.match(/Yesterday /)) {
			throw(new Error(`Test 1 failed: ${result} should have contained "Today" or "Yesterday"`));
		}

		result = format_date(Date.now() - (1000 * 60 * 60 * 48));
		/* istanbul ignore if */
		if (result.match(/Today /) || result.match(/Yesterday /)) {
			throw(new Error(`Test 2 failed: ${result} should NOT have contained "Today" or "Yesterday"`));
		}

		result = format_date(Date.now() + (1000 * 60 * 30));
		/* istanbul ignore if */
		if (!result.match(/Today /) && !result.match(/Yesterday /)) {
			throw(new Error(`Test 3 failed: ${result} should have contained "Today" or "Yesterday"`));
		}

		result = format_date(Date.now() + (1000 * 60 * 60 * 48));
		/* istanbul ignore if */
		if (!result.match(/In [0-9] days/)) {
			throw(new Error(`Test 4 failed: ${result} should have contained "In X days"`));
		}

		const date_now = Date.now();
		result = format_date(date_now, { use_relative: false });
		/* istanbul ignore if */
		if (result !== new Date(date_now).toISOString()) {
			throw(new Error(`Test 5 failed: ${result} should have contained ${new Date(date_now).toISOString()}`));
		}

		return(true);
	};
}
/**
 * input - date string
 * output - local date time  string
 */
function get_local_datetime(dateString) {
	const isoDateTime = new Date(format_date(dateString, { use_relative: false }));
	/** @type {Intl.DateTimeFormatOptions} */
	const timeFormat = {
		year: 'numeric',
		month: '2-digit',
		day:'2-digit',
		hour: '2-digit',
		minute: '2-digit',
		hour12: true
	};
	return isoDateTime.toLocaleString('en-US', timeFormat);
}

if (_testing){
	_testing.format_time = function(){
		let result;
		const date_now = Date.now();
		result =  format_date(date_now, { use_relative: false });

		result = get_local_datetime(result);
		/* istanbul ignore if */
		if (!result.match(/AM/) && !result.match(/PM/)) {
			throw(new Error(`Test 6 failed: ${result} should have contained AM or PM`));
		}

		return(true);
	}
}

function sort_dates_compare(version1, version2, direction, object_key) {
	let sortOrder = 1;
	if (direction === 'desc') {
		sortOrder = -1;
	}

	let version1_key, version2_key;
	if (object_key === undefined) {
		version1_key = version1;
		version2_key = version2;
	} else {
		version1_key = version1[object_key];
		version2_key = version2[object_key];
	}

	const version1_date = new Date(version1_key).getTime();
	const version2_date = new Date(version2_key).getTime();

	if (version2_date > version1_date) {
		return(-1 * sortOrder);
	} else if (version2_date < version1_date) {
		return(1 * sortOrder);
	}

	return(0);
}

if (_testing) {
	_testing.sort_dates_compare_1 = function() {
		const checks = [
			{
				version1: '2021-05-25T15:00:00.000Z',
				version2: '2021-06-25T15:00:00.000Z',
				direction: 'asc',
				result: -1
			},
			{
				version1: '2021-06-25T15:00:00.000Z',
				version2: '2021-05-25T15:00:00.000Z',
				direction: 'asc',
				result: 1
			},
			{
				version1: '2021-06-25T15:00:00.000Z',
				version2: '2021-06-25T15:00:00.000Z',
				direction: 'asc',
				result: 0
			},
			{
				version1: '2021-05-25T15:00:00.000Z',
				version2: '2021-06-25T15:00:00.000Z',
				direction: 'desc',
				result: 1
			},
		];

		for (const check of checks) {
			const check_result = sort_dates_compare({
				key: check.version1
			}, {
				key: check.version2
			}, check.direction, 'key');

			/* istanbul ignore if */
			if (check_result !== check.result) {
				const dir_humans_map = {
					'asc': 'increasing',
					'desc': 'decreasing'
				};
				const dir_human = dir_humans_map[check.direction];

				throw(new Error(`While comparing date ${check.version1} vs. ${check.version2} (${dir_human}) we got ${check_result} but expected ${check.result}`));
			}
		}

		return(true);
	};

	_testing.sort_dates_compare_2 = function() {
		const result = '2021-04-25T15:00:00.000Z';
		const check = [
			'2021-05-25T15:00:00.000Z',
			'2021-04-25T15:00:00.000Z',
			'2021-06-25T15:00:00.000Z'
		];

		/*
		 * Validate that arrays of objects and strings can be sorted
		 */
		/**
		 ** Object
		 **/
		(function() {
			const check_copy = check.map(function(item) {
				return({key: item});
			});

			const output = check_copy.sort(function(version1, version2) {
				return(sort_dates_compare(version1, version2, 'asc', 'key'));
			});

			/* istanbul ignore if */
			if (output[0].key !== result) {
				throw(new Error(`While sorting ${JSON.stringify(check)} as Objects we got ${output[0].key} but expected the first value to be ${result}`));
			}
		})();

		/**
		 ** String
		 **/
		(function() {
			const check_copy = kaialpha.lib.object_utils.copy_object(check);

			const output = check_copy.sort(sort_dates_compare);

			/* istanbul ignore if */
			if (output[0] !== result) {
				throw(new Error(`While sorting ${JSON.stringify(check)} as Strings we got ${output[0]} but expected the first value to be ${result}`));
			}
		})();

		return(true);
	};
}

const _units = {};
_units.seconds = 1000;
_units.second = _units.seconds;
_units.minutes = 60 * _units.seconds;
_units.minute = _units.minutes;
_units.hours = 60 * _units.minutes;
_units.hour = _units.hours;
_units.days = 24 * _units.hours;
_units.day = _units.days;
_units.weeks = 7 * _units.days;
_units.week = _units.weeks;
_units.s = _units.seconds;
_units.min = _units.minutes;
_units.h = _units.hours;
_units.d = _units.days;
_units.w = _units.weeks;
function add_date(when, base = undefined) {
	base = _construct_date(base);

	let new_date = base.valueOf();

	/*
	 * If the input is a simple string, treat it as an array of one item
	 */
	if (!Array.isArray(when)) {
		when = [when];
	}

	/*
	 * Process each part
	 */
	for (const add of when) {
		const work = add.match(/^([0-9.-]+)(.+)$/);
		if (!work) {
			throw(new Error(`Unable to parse string as date: ${add}`));
		}

		const value = Number(work[1]);
		const unit = work[2].trim().toLowerCase();

		/*
		 * Some units are of fixed size, while others vary, process them accordingly
		 */
		switch (unit) {
			case 'mo':
			case 'mon':
			case 'month':
			case 'months':
				{
					const tmp_date = new Date(new_date);
					tmp_date.setUTCMonth(tmp_date.getUTCMonth() + value);
					new_date = tmp_date.valueOf();
				}
				break;
			case 'y':
			case 'year':
			case 'years':
				{
					const tmp_date = new Date(new_date);
					tmp_date.setUTCFullYear(tmp_date.getUTCFullYear() + value);
					new_date = tmp_date.valueOf();
				}
				break;
			default:
				if (_units[unit] === undefined) {
					throw(new Error(`Invalid unit ${unit} in when ${add}`));
				}

				new_date += value * _units[unit];
				break;
		}
	}

	const retval = new Date(new_date);

	return(retval);
}

if (_testing) {
	_testing.add_date = function() {
		const start_dates = [
			new Date('2021-01-01T00:00:00.000Z'),
			'2021-01-01T00:00:00.000Z'
		];
		const checks = [
			{ in: '1 second', out: '2021-01-01T00:00:01.000Z' },
			{ in: '1 minute', out: '2021-01-01T00:01:00.000Z' },
			{ in: '1 hour', out: '2021-01-01T01:00:00.000Z' },
			{ in: '72 hours', out: '2021-01-04T00:00:00.000Z' },
			{ in: '1 day', out: '2021-01-02T00:00:00.000Z' },
			{ in: '2 days', out: '2021-01-03T00:00:00.000Z' },
			{ in: '30 days', out: '2021-01-31T00:00:00.000Z' },
			{ in: '90 days', out: '2021-04-01T00:00:00.000Z' },
			{ in: '1 week', out: '2021-01-08T00:00:00.000Z' },
			{ in: '1 month', out: '2021-02-01T00:00:00.000Z' },
			{ in: '2 months', out: '2021-03-01T00:00:00.000Z' },
			{ in: '6 months', out: '2021-07-01T00:00:00.000Z' },
			{ in: '24 months', out: '2023-01-01T00:00:00.000Z' },
			{ in: '1 year', out: '2022-01-01T00:00:00.000Z' },
			{ in: ['1 month', '1 day'], out: '2021-02-02T00:00:00.000Z' },
			{ in: ['1 day', '1 month'], out: '2021-02-02T00:00:00.000Z' },
			{ in: ['30 days', '1 month'], out: '2021-03-03T00:00:00.000Z' },
			{ in: ['1 month', '30 days'], out: '2021-03-03T00:00:00.000Z' },
			{ in: ['27 days', '1 month'], out: '2021-02-28T00:00:00.000Z' },
			{ in: ['1 month', '27 days'], out: '2021-02-28T00:00:00.000Z' },
		];

		for (const start_date of start_dates) {
			for (const check of checks) {
				const check_out = add_date(check.in, start_date).toISOString();

				/* istanbul ignore if */
				if (check_out !== check.out) {
					throw(new Error(`When adding ${check.in} to ${start_date} we got ${check_out} but expected ${check.out}`));
				}
			}
		}

		return(true);
	};
}

const _to_export_auto = {
	format_date,
	get_local_datetime,
	sort_dates_compare,
	add_date,
	_testing
};
export default _to_export_auto;
