import {KeyboardArrowDown, Restore} from "@mui/icons-material";
import {useState} from "react";
import {useTheme} from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
import {StateLabel} from "./StateLabel";
import {Container, DropDown, lightThemeColorForWorkflowState, mainThemeColorForDocumentState, Overlay} from "./style";
import {ListItem} from "./ListItem";
import {MajorVersionLookupType, VersionType} from "./models";
import {FlexBox} from "../../../layout";

export interface DropDownProps {
	isLoading: boolean,
	value: VersionType & {found: boolean} | null,
	versions: MajorVersionLookupType[],
	onVersionSelect: (version: VersionType) => void
}

const dateAdapter = new DateFnsUtils();
const VersionDropDown = ({isLoading, value, versions, onVersionSelect}: DropDownProps) => {
	const [state, setState] = useState({dropDownOpen: false});
	const theme = useTheme();
	const onToggleDropdown = () => {
		setState(s => ({...s, dropDownOpen: !s.dropDownOpen}));
	}

	const formatStateLabel = () => {
		if (value?.state == null) {
			return "Unpublished";
		}

		return value!.state!.substring(0, 1).toUpperCase() + value!.state!.substring(1);
	}

	const formatVersionLabel = () => {
		if (value?.found) {
			return value.state === null ? "Unpublished version" : `Version ${value.number}`
		}

		return "Unknown Version";
	}

	const versionLabel = formatVersionLabel();
	const documentStateLabel = formatStateLabel();

	return (<Container onClick={onToggleDropdown} backgroundColor={!value?.isCurrent ? lightThemeColorForWorkflowState(theme, value?.state) : "white"}>
		<div className={"chip__label"}>
			<FlexBox align={"center"} width={"auto"}>
				<Restore />
				<label className={"chip__label-title"}>
					{value && !isLoading ? versionLabel : "Loading..."}
				</label>
				{(value?.found && !value.isCurrent) && <StateLabel backgroundColor={mainThemeColorForDocumentState(theme, value?.state)}>
					{documentStateLabel}
				</StateLabel>}
			</FlexBox>
			{value?.found && <FlexBox direction={"column"} align={"flex-end"} width={"auto"}>
				{value.isCurrent && <span>current</span>}
				{!value.isCurrent && <>
					<span>{dateAdapter.formatByString(value.date, "MM dd yyyy")}</span>
					<span>{dateAdapter.formatByString(value.date, "HH:mm")}</span>
				</>}
			</FlexBox>}
		</div>
		<KeyboardArrowDown />
		{state.dropDownOpen && <>
			<Overlay>&nbsp;</Overlay>
			<DropDown>
				{versions.map(version => <ListItem version={version} onSelect={onVersionSelect} /> )}
			</DropDown>
		</>}
	</Container>)
}

export {VersionDropDown}