import { TabDefinition } from "../../../../../shared/components/layout/tabs/tabDefinition";
import { Section } from "../../../models/Section";
import { useDocumentService } from "../../../hooks/useDocumentService";
import { DocumentState } from "../../../hooks/useDocumentState";
import { TableOfContents } from "../../list/toc";
import { EmptyGroup } from "../../../../../shared/components/layout/groups/EmptyGroup";
import {useActiveSection} from "../../../hooks/useActiveSection";
import {CenterPaneHtmlId} from "../../../models/constants";
import {SectionElementValue} from "../../../../../shared/definitions/elements/section/model";
import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";
import {useState} from "react";
import {useEventListener, useRunWhenValueChange} from "../../../../../shared/hooks";
import {ElementEventTypes} from "../../../../../shared/definitions/elements/events";
import {numberSections} from "../../elements/section/numberSections";
import {filterElements} from "../../elements/utilities/filterElements";

function mapElementValueTypeToSection(section: ElementValueType): Section {
	const data = section.data as SectionElementValue;
	return {
		name: data.name!,
		number: data.index!,
		depth: data.index!.split(".").length,
		id: section.id,
		sections: (section.children ?? []).map(mapElementValueTypeToSection)
	}
}
const TabView = () => {
	const [sections, setSections] = useState<Section[]>([]);
	const documentManager = DocumentState.useContainer();
	const documentService = useDocumentService();

	useRunWhenValueChange(() => {
		setSections(documentManager.tableOfContents?.map(mapElementValueTypeToSection) ?? [])
	}, documentManager.tableOfContents);

	useEventListener(ElementEventTypes.update, (event: CustomEventInit) => {
		if (event.detail.type === "switch") {
			const sectionElements = numberSections(filterElements(documentManager.elements, "section", documentManager.variables));
			documentManager.setTableOfContents(sectionElements);
		}
	})

	const activeSectionId =
		useActiveSection(CenterPaneHtmlId);

	const onSectionClicked = (section: Section) => documentService.updateActiveSection(section.id);

	return sections.length === 0 ? <EmptyGroup title={"No content"} /> : <TableOfContents
		sections={sections}
		activeSectionId={activeSectionId ?? ""}
		windowSectionId={documentManager.windowSectionId ?? ""}
		onSectionClicked={onSectionClicked}
		onCollapsed={() => { /* this shouldnt have control over collapse, the tab container should */ }}
		disableScrolling={documentManager.scrollingIntoActiveSection ?? false}
	/>
}

const TableOfContentsTab: TabDefinition = {
	name: "Table of Contents",
	content: TabView,
}

export { TableOfContentsTab };