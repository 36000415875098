import {TemplateElementDefinition} from "../model";
import {CitationsIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import PreviewView from "./views/preview/PreviewView";
import {ReadonlyView} from "./views/ReadonlyView";
import {
	citationsDefinition as baseCitationsDefinition
} from "../../../../../shared/definitions/elements/citations/model";

const citationsDefinition: TemplateElementDefinition = {
	...baseCitationsDefinition,
	icon: CitationsIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		preview: PreviewView
	},
} as const

const citationsValue = {
	label: "List",
	labelValue: "Default Citations"
}

export {citationsDefinition, citationsValue};