import {useAsync} from "../../../../shared/hooks";
import {PermissionsResponse} from "./models";
import api from "../../../../api/api";
import {ApiPatchResult} from "../../../../shared/interfaces";

// updates the permissions for the given templateId
function useUpdatePermissions() {
	const updatePermissions = async (id: string, permissions: KaiAlphaTemplatePermissions, lastupdated?: number): Promise<PermissionsResponse> => {
		const payload = {
			"id": id,
			"version": "HEAD",
			"change": { permissions, lastupdated },
			"delete": {}
		}

		const template: ApiPatchResult = await api.call('PATCH', `template/${id}`, payload);
		return {
			permissions,
			version: template.version,
			lastupdated: template.lastupdated
		};
	}

	return useAsync<PermissionsResponse>(updatePermissions, false);
}

export {useUpdatePermissions}