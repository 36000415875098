import {Variable} from "../../interfaces/Variable";
import {ExpressionResult} from "../../interfaces/Expression";
import {nunjucksVariableFactory} from "../../nunjucks/nunjucksVariableFactory";
import {computeExpression} from "../../nunjucks/expressions/computeExpression";
import {NunjucksVariableAdapter} from "../../nunjucks/nunjucksVariableAdapter";


function executeExpression(expression: string, variables?: Variable[]): ExpressionResult {

	if (variables === undefined) {
		return {
			expression,
			result: ""
		}
	}

	const variableLookup = NunjucksVariableAdapter.toTree(nunjucksVariableFactory(variables));
	const result = computeExpression({expression: expression.trim(), variables: variableLookup});
	return {
		expression,
		result
	}
}

export {executeExpression};