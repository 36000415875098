import {useAsync} from "../../useAsync";
import {S3UploadParams} from "./models";


function useUploadToS3() {
	const upload = async ({url, payload}: S3UploadParams) => {

		const response = await fetch(url, payload);
		/* If response failed throw error */
		if (!response.ok || (response.status !== 200 && response.status !== 204)) {
			throw(new Error('Failed to upload image to S3: ' + response.json()));
		}

		return response;
	}

	return useAsync(upload, false);
}

export {useUploadToS3};