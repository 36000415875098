import template_lib from '../../src/api/template.js';
import document_lib from '../../src/api/document.js';
import template_utils from '../lib/utils/template_utils';
import document_utils from '../lib/utils/document_utils';
import object_utils from '../lib/utils/object_utils';

let document_elements = {};
let template_elements = [];

async function get_document_elements(document_id) {
	/* Get top level document */
	const top_level_document_info =  await document_utils.get_toplevel_document(null, document_id, 'HEAD');
	const top_level_document = await document_lib.getDocumentById(top_level_document_info.id);

	document_elements = {id: top_level_document_info.id, name: top_level_document.name};
	const elements = await construct_document_tree(top_level_document);
	document_elements.children = elements;
	return(document_elements);
}

async function construct_document_tree(top_level_document) {
	/* Get template from document */
	const template = await template_lib.getTemplateById(top_level_document.template.id);

	/*
	 * Map body to tree data so it is correctly formatted
	 * to be displayed in the tree view.
	 */
	const elements = [];
	const parent_value = '';
	const body_elements = await map_body_to_tree_data(elements, parent_value, template);

	/* Recurse through document hierarchy */
	const subdocuments = await document_lib.getDocumentSubdocuments(top_level_document.id);

	await Promise.all(subdocuments.map(async (subdocument) => {
		const subdocument_info = await document_lib.getDocumentById(subdocument.id);
		const nested_elements = await construct_document_tree(subdocument_info);
		body_elements.push({id: subdocument.id, type: 'top_level', name: subdocument.name, children: nested_elements});
	}))

	return(body_elements);
}

async function get_referenceable_elements(template_id, template_name, items) {
	if (template_id === undefined) {
		/*
		 * Display items in template editor in the tree view if template
		 * has not yet been created
		 */
		const elements = await map_items_to_tree_data(template_id, template_name, items);
		return(elements);
	} else {
		const top_level_templates = await template_utils.get_template_tops(null, template_id);

		for (const template of top_level_templates) {
			const template_info = await template_lib.getTemplateById(template.id);

			/*
			 * Recurse into template hierarchy starting from top level template
			 * to get all elements from all child templates
			 */
			const children = await construct_template_tree(template_info, template_info.id, items);
			if (template_elements.length < top_level_templates.length) {
				template_elements.push(
					{id: template.id, name: template_info.name, children: children}
				)
			}
		}
	}

	return(template_elements);
}

async function construct_template_tree(top_level_template, template_id, items) {
	let body_elements = [];

	/*
	 * If top level template is the current template, display its
	 * items in the tree view, otherwise display body elements
	 */
	if (top_level_template.id === template_id && items !== undefined) {
		const items_as_tree = map_items_to_tree_data(top_level_template.id, top_level_template.name, items);
		body_elements = items_as_tree.children;
	} else {
		const elements = [];
		const parent_value = '';
		body_elements = await map_body_to_tree_data(elements, parent_value, top_level_template);
	}

	/*
	 * Recurse through template hierarchy and add each child's
	 * body elements as children to be displayed in tree view
	 */
	if (body_elements === undefined) {
		return;
	}

	const children = await template_lib.getTemplateChildren(top_level_template.id);
	for(const child of children) {
		const child_info = await template_lib.getTemplateById(child.id);
		const nested_elements = await construct_template_tree(child_info, template_id, items);
		body_elements.push({id: child.id, type: 'top_level', name: child.name, children: nested_elements});
	}

	return(body_elements);
}

async function map_body_to_tree_data(elements, parent_value, template) {
	const template_body = template.body;

	for (const element of template_body) {
		const element_info = Object.values(element)[0];
		const id = Object.keys(element)[0];
		const type = element_info.type;
		const value = document_utils.get_value_from_element(element_info);

		elements.push({id: id, type: type, template_id: template.id,
			template_version: template.version,  parent: parent_value,
			value: value, name: `Value: ${value}, Type: ${type}`, children: []});

		/*
		 * If element has nested elements, add the nested elements
		 * as children of the current element in the array
		 */
		if (element_info.body !== undefined && element_info.body.length > 0) {
			const nested_element_info = object_utils.copy_object(element_info);
			nested_element_info.id = template.id;
			nested_element_info.version = template.version;
			const subelements = await map_body_to_tree_data([], value, nested_element_info);
			const current_index = elements.length - 1;
			elements[current_index]['children'] = subelements;
		}
	}

	return(elements);
}

function map_items_to_tree_data(template_id, template_name, items) {
	let name = 'Template';
	let top_level_id = 'root';
	const children = [];

	if (template_id !== undefined) {
		name = template_name;
	}

	if (template_name !== undefined) {
		top_level_id = template_id;
	}

	if (items === undefined || items === null) {
		return([]);
	}

	let current_index = 0;
	items.forEach(async (item) => {
		const item_id = item.id;
		const type = item.contents.type;
		const value = document_utils.get_value_from_element(item.contents);

		/* If item has children nested, add parent value */
		if (item.depth > 0) {
			const previous_item = children[current_index - 1];
			let parent_value;

			if (previous_item !== undefined) {
				if (previous_item.parent === undefined) {
					parent_value = previous_item.value;
				} else {
					parent_value = previous_item.parent;
				}
			}

			children.push({id: item_id, parent: parent_value, type: type, value: value, name: `Value: ${value}, Type: ${type}`})
		} else if (item.type === 'template') {
			/*
			 * If item is a template and template hasn't been
			 * created yet, fetch its children
			 */
			const template = await template_lib.getTemplateById(item.contents.id);
			const template_children = await construct_template_tree(template, template.id);
			children.push({id: item_id, type: type, value: value, name: `Value: ${value}, Type: ${type}`, children: template_children})
		} else {
			children.push({id: item_id, type: type, value: value, name: `Value: ${value}, Type: ${type}`})
		}

		template_elements = {id: top_level_id, name: name, children: children};
		current_index++;
		return(template_elements);
	})

	return(template_elements);
}

function filter_reference_data(search_type, value) {
	let elements;

	if (search_type === 'document') {
		elements = document_elements;
	}

	if (search_type === 'template') {
		elements = template_elements;
	}

	if (value === '') {
		return(elements);
	} else {
		const search_elements = Object.assign({}, elements);
		const search_results = search(search_elements, value, search_type, []);
		search_elements.children = search_results;
		return(search_elements);
	}
}

function search(tree_items, search_term, search_type, results) {

	if (tree_items.children === undefined) {
		return;
	}

	tree_items.children.forEach((item) => {
		if (item.type !== undefined && item.type !== 'top_level' &&
			(item.type.toLowerCase().includes(search_term.toLowerCase()) || item.value.toLowerCase().includes(search_term.toLowerCase()))) {
			results.push(item);
		}

		if (item.children !== undefined && item.children.length > 0 &&
			(item.type === 'top_level' || (!item.type.toLowerCase().includes(search_term.toLowerCase())
			&& !item.name.toLowerCase().includes(search_term.toLowerCase())))) {
			const top_level_item = Object.assign({}, item);
			top_level_item.children = search(item, search_term, search_type, []);
			if (top_level_item.children.length > 0) {
				results.push(top_level_item);
			}
		}
	});

	return(results);
}

const _to_export = {
	get_document_elements,
	get_referenceable_elements,
	filter_reference_data,
	search
};
export default _to_export;
