import { ReadonlyElementProps } from "../../model";
import { TableElementValue } from "../../../../../../shared/definitions/elements/table/model";
import MaterialTableStyled from "../../../../../../components/MaterialTableStyled";
import { styled } from "@mui/material";
import { columns } from "./models";
import { PropertyList } from "../../PropertyList";
import { HighlightWrapper } from "../../../../../../shared/components/input/highlight/HighlightWrapper";
import { CommentState } from "../../../../hooks/useCommentState";
import { CommentModalProps } from "../../../../../../shared/definitions/highlight/CommentModal.props";
import CommentModal from "../../../modals/templateComments/CommentModal";


const Container = styled("div")(({ theme }) => ({
	margin: ".5rem 1rem 1rem 0"
}))

const ReadonlyView = (props: ReadonlyElementProps) => {
	const value = props.data as TableElementValue;

	const commentsManager = CommentState.useContainer();
	const highlightComments = commentsManager.highlightedCommentsByElementId(props.id);

	const properties = [{ label: "Datasource", value: value.datasource }];
	return <Container>
		{value.title && <HighlightWrapper
			elementId={props.id}
			enabled={true}
			highlightComments={highlightComments}
			value={value.title}
			componentToWrap={(highlightedText) => <h2 dangerouslySetInnerHTML={{ __html: highlightedText }} />}
			renderCommentModal={ (values: CommentModalProps) =>
				<CommentModal
					onClose={values.onClose}
					addEditComment={"Add"}
					showOpen={values.showOpen}
					elementId={values.elementId}
					selectedText={values.selectedText}
					textProperties={values.textProperties}
				/>
			}
		/>}
		<PropertyList items={properties}>
			<MaterialTableStyled
				columns={columns}
				data={value.footnotes || []}
				title='Footnotes'
				editable={null}
			/>
		</PropertyList>
	</Container>
}

export { ReadonlyView };