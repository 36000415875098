import {useCallback, useEffect, useRef} from "react"
import {useRunWhenValueChange} from "./useRunWhenValueChange";

type UseInteractionObserverProps = Omit<IntersectionObserverInit, "root"> & {
	root: string,
	observedNodeSelector: string
}
export function useInteractionObserver(fn: (entry: IntersectionObserverEntry) => void, props: UseInteractionObserverProps) {
	const {observedNodeSelector, root, rootMargin, threshold} = props;
	const callback = useCallback(([entry]: IntersectionObserverEntry[]) => fn(entry), [fn]);
	const nodes = document.querySelectorAll(observedNodeSelector);
	const rootNode = document.querySelector(root);

	const nodesRef = useRef<NodeListOf<Element>>();
	useRunWhenValueChange(() => {
		nodesRef.current = nodes;
	}, nodes);
	useEffect(() => {

		const hasIOSupport = !!window.IntersectionObserver

		if (!hasIOSupport || !nodesRef.current || !root || nodesRef.current.length === 0) return

		const observerParams = { threshold, root: rootNode, rootMargin }
		const observer = new IntersectionObserver(callback, observerParams)

		nodesRef.current.forEach(node => observer.observe(node));

		return () => observer.disconnect()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(threshold), root, rootMargin, observedNodeSelector, nodesRef.current])
}