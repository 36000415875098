export function isArrayWithLength(arg: unknown): arg is any[] {
	return Array.isArray(arg) && (arg as any[]).length > 0;
}

export function isArray(arg: unknown): arg is any[] {
	return Array.isArray(arg);
}

export function isEmptyArray(arg: unknown): arg is any[] {
	return Array.isArray(arg) && (arg as any[]).length === 0;
}