import {TemplateElementDefinition} from "../model";
import {ReferenceIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {PropertyEditor} from "./views/editor/PropertyEditor";
import {
	referenceDefinition as baseReferenceDefinition
} from "../../../../../shared/definitions/elements/reference/model";

const referenceDefinition: TemplateElementDefinition = {
	...baseReferenceDefinition,
	icon: ReferenceIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		propertyEditor: {
			view: PropertyEditor,
			requiredOnCreate: true
		},
	}
} as const

export {referenceDefinition};