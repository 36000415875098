/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /codebuild/output/src366102835/src/src/kaialpha/lib/workflow_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const _testing = undefined;
const uuid = require('uuid');
const workflow_default_for = ['new_document', 'new_template'];
const workflow_operations = {};
/**
 * Default workflow state for Waiting on User Input
 */
const default_workflow_status = 'waiting';

const workflow_actions = {
	final_review_approve: 'Approve',
	final_review_reject: 'Reject',
	cancel_review_cycle: 'Cancel Review Cycle',
	request_review: 'Request Review',
	request_approval: 'Request Approval',
	complete_review: 'Complete Review',
	cancel_approval_process: 'Cancel Approval',
};

const default_states = {
	in_process: 'In Process',
	in_review: 'Review',
	finalized: 'Finalized',
	in_approval: 'Final Review',
	approved: 'Approved'
};

const default_task_states = {
	rejected: 'Rejected',
	cancelled: 'Cancelled',
	approved: 'Approved'
};

const VALID_WORKFLOW_TYPES = ['document', 'template'];
const VALID_VARIABLE_TYPES = ['local', ...VALID_WORKFLOW_TYPES];

function debug_log(component, options, ...args) {
	kaialpha.lib.debug.log(component, `[${options.log_id}]`, ...args);
}

/* XXX:TODO: Move to general_utils */
function _dedupe_list(...args) {
	return ([...new Set([...args])]);
}

async function _item_permissions(user_id, object_info) {
	/*
	 * Get permissions of the current item
	 */
	const item_permissions_info = await object_info.get_canonical_permissions(user_id, object_info.id);
	const item_permissions = item_permissions_info.permissions;
	if (!item_permissions.acl) {
		item_permissions.acl = {};
	}

	if (!item_permissions.acl.write) {
		item_permissions.acl.write = [];
	}

	if (!item_permissions.acl['comments:write']) {
		item_permissions.acl['comments:write'] = [];
	}

	item_permissions.roles = item_permissions.roles || {};
	item_permissions.roles.authors = item_permissions.roles.authors || [];
	item_permissions.roles.reviewers = item_permissions.roles.reviewers || [];
	item_permissions.roles.approvers = item_permissions.roles.approvers || [];

	return (item_permissions);
}

async function load_workflow_state(user_id, slot_name) {

	/*
		* Start running the workflow if one is not already running
		*/
	let workflow_id;
	switch (slot_name) {
		case '@default_new_document':
		case '@default_new_template':
			try {
				workflow_id = await kaialpha.lib.workflow.resolve_workflow_symbolic_name(user_id, slot_name);
			} catch (_ignored_error) {
				/* XXX:TODO: This can be called on the client -- ignore it there */
			}
			break;
		default:
			/* Nothing to do */
			break;
	}

	if (!workflow_id) {
		/*
			* If there is no workflow, indicate that it's already finished so this process
			* does not need to get repeated
			*/
		return ({
			status: null
		});
	}

	/*
		* Resolve the workflow version to an exact version
		*/
	const workflow_info = await kaialpha.lib.workflow.get_user_workflow(user_id, workflow_id, 'HEAD');
	const workflow_version = workflow_info.version;

	return ({
		id: workflow_id,
		version: workflow_version
	});
}

async function workflow_ui_filter_buttons(user_id, buttons, item_permissions) {
	const retval = [];

	if (!buttons) {
		return (retval);
	}

	for (const button of buttons) {
		let button_matches = false;
		if (!button_matches && user_id === kaialpha.lib.versions_utils.special_uids.system_user_id) {
			button_matches = true;
		}

		if (!button_matches && kaialpha.configuration && kaialpha.configuration.disable_acl_enforcement === true) {
			button_matches = true;
		}

		if (item_permissions === undefined || item_permissions.owners.length === 0) {
			throw (new Error('Missing permissions'));
		}

		const button_permissions_statement = {
			...item_permissions,
			acl: button.permissions.acl
		};

		const button_permissions = await kaialpha.lib.versions_utils.canonicalize_permissions(button_permissions_statement);

		// The user object should be passed into this method, but look it up for now
		// And since this is used by both the UI and the server, do the "double check"
		let curUser = null;
		try {
			curUser = await kaialpha.lib.user.get_user_info_by_id("", user_id);
		} catch (exc) {
			console.log("Library not found. Attempting the UI library");
		}
		if (!curUser) {
			try {
				curUser = await kaialpha.lib.user.get_user_info(user_id);
			} catch (exc) {
				console.log("Library not found. Reconstructing a blank user");
				curUser = {
					id: user_id,
					groups: []
				};
			}
		}

		if (!button_matches && button_permissions.acl.write.includes(user_id)) {
			button_matches = true;
		} else if (!button_matches && curUser) {
			for (const curGroup of curUser.groups) {
				if (button_permissions.acl.write.includes(curGroup)) {
					button_matches = true;
					break;
				}
			}
		}

		if (!button_matches && button_permissions.acl.write.includes(kaialpha.lib.versions_utils.special_uids.system_everyone_id)) {
			button_matches = true;
		}

		if (button_matches) {
			retval.push({
				...button,
				permissions: button_permissions
			});
		}
	}

	return (retval);
}

async function _assert_button_access(user_id, item, context, button_id) {
	/*
	 * Verify that the user may press this button
	 */
	const buttons = await workflow_ui_filter_buttons(user_id, context['@ui'].buttons, item.permissions);

	const button_info = buttons.find(function (check_button) {
		if (check_button.id === button_id) {
			return (true);
		}

		return (false);
	});

	if (!button_info) {
		throw (new kaialpha.UserError(`No access to the button or no such button. Button: ${button_id}`));
	}

	return (true);
}

async function _handle_button_events(user_id, item, context, button_functions, options = {}) {
	const event = context['@event'];
	if (event.name !== 'button_press') {
		return (undefined);
	}

	const args = event.args;

	const button_id = args.id;

	/*
	 * Validate access to the button
	 */
	await _assert_button_access(user_id, item, context, button_id);

	let method = button_functions[button_id];

	/*
	 * Allow the user to specify a default button handler
	 */
	if (!method) {
		method = button_functions['@default'];
	}
	/*
	 * Otherwise throw an user error
	 */
	if (!method) {
		debug_log('workflow', options, 'Unknown button event:', { event, args });

		throw (new kaialpha.UserError(`No button handler for ${button_id} button`));
	}

	const pre_method = button_functions['@pre'];
	if (pre_method) {
		await pre_method();
	}

	let retval;
	let method_error, method_failed = false;
	try {
		retval = await method(button_id);
	} catch (_method_error) {
		method_error = _method_error;
		method_failed = true;
	}

	if (!method_failed) {
		const post_method = button_functions['@post'];
		if (post_method) {
			retval = await post_method(retval);
		}
	}

	const final_method = button_functions['@finally'];
	if (final_method) {
		retval = await final_method(retval, method_error, method_failed);
	}

	if (method_failed) {
		throw (method_error);
	}

	return (retval);
}

/**
* UiButtonObject
* @param {string} itemStateId
* @param {string[]} writePermission - Remaining task owners list
* @param {boolean} [task]  Workflow ui task - true if task requires user to perform an action to complete workflow task
* @returns {{task: boolean, permissions: {acl: {write}}, label: *, id}} - Returns pending workflow ui buttons (action) details
*/
function uiButtonObject(itemStateId, writePermission, task = true) {
	return ({
		label: workflow_actions[itemStateId],
		id: itemStateId,
		task,
		permissions: {
			acl: {
				write: writePermission
			}
		},
	});
}

const isValidType = (options) => {
	return VALID_WORKFLOW_TYPES.includes(options.type);
};

const isValidVariableType = (type) => {
	return VALID_VARIABLE_TYPES.includes(type);
};

/**
 * Determine Authors and Reviewers Based On UserId and Options Passed in
 * @param {string} search_user_id
 * @param {Object} user_options
 * @param {Object} cached_permissions
 * @returns {Promise<{item_reviewers: string[], item_authors: string[]}>}
 */
async function get_all_authors_and_reviewers(search_user_id, user_options, cached_permissions) {
	const expanded_item_permissions = cached_permissions || await _item_permissions(search_user_id, user_options);
	const item_authors = _dedupe_list(...expanded_item_permissions.owners, ...expanded_item_permissions.roles.authors);
	const item_reviewers = expanded_item_permissions.roles.reviewers || [];

	return ({
		item_authors,
		item_reviewers
	});
}

workflow_operations.restart_workflow = async function (user_id, state, options) {
	const retval = {
		variables: {},
		completed_tasks: [],
	};

	/* XXX:TODO: this should be copied from a parameter from the workflow operation */
	const states = { ...default_states };

	let item;
	if (isValidType(options)) {
		item = await options.get_user_item(user_id, options.id, options.version);
	}

	const reset_state = states.in_process;
	retval.restart_options = {
		event: 'workflow_restart',
		args: {},
		from_operation: 'set_ui_action_approve',
		to_operation: 'set_ui_action_review',
	};
	item.state = reset_state;
	/**
	 * Reset the counters so that the related UI logic work seamlessly.
	 * The previous reviews and approvals are still tracked and that information is not lost.
	*/
	retval.variables.reviews_completed_count = "0";
	retval.variables.approvals_completed_count = "0";
	await reset_item_state_and_permissions(item, `Workflow : Reset by ${user_id}`, {
		state: reset_state,
		apply_diff: options.apply_diff,
		skip_acl_notifications: true,
		user_id: user_id,
	});

	return (retval);
};

workflow_operations.set_ui_action_approve = async function (user_id, state, context, options) {
	const retval = {
		variables: {},
		completed_tasks: [],
	};

	/* XXX:TODO: this should be copied from a parameter from the workflow operation */
	const states = { ...default_states };

	// avoid losing required data when states are merged
	// copy prior completed tasks
	const {
		completed_tasks = [],
	} = state;
	retval.completed_tasks = kaialpha.lib.object_utils.copy_object(completed_tasks);

	let approvals_completed_count = 0;
	if (state.variables.approvals_completed_count) {
		approvals_completed_count = Number(state.variables.approvals_completed_count);
	}
	const current_approval_cycle_number = approvals_completed_count + 1;

	let item;
	if (isValidType(options)) {
		item = await options.get_user_item(user_id, options.id, options.version);
	}

	const item_canonical_permissions = await _item_permissions(user_id, options);

	const get_all_approvers = function () {

		const { approvers = [] } = item_canonical_permissions.roles;

		return (approvers);
	};

	if (item.state === states.finalized) {
		// if here item review finished, starting approval
		const item_approvers = await get_all_approvers();

		/*
		 * If there are no approvers, do not permit this to move forward
		 */
		if (item_approvers.length === 0) {
			throw (new kaialpha.UserError('Cannot request approval while there are no approvers'));
		}

		/*
		 * Update item to indicate it is now submitted for approval
		 */
		item.state = states.in_approval;
		await options.apply_diff(user_id, 'Requesting approval', {
			change: {
				state: item.state,
				permissions: {
					roles: {
						'_completed_approvers': [],
					},
					acl: {
						'comments:write': _dedupe_list(...item.permissions.acl['comments:write'], '@role:approvers', '!@role:_completed_approvers')
					}
				}
			}
		},
		// options
		{
			skip_acl_notifications: true,
		});

		// notify approvers
		retval._task_notifications = item_approvers;
	}

	if (item.state === states.in_approval) {
		// if here, approval cycle started

		const send_finished_notification = async function (task_state) {
			/*
			* Determine the type of event from the new state
			*/
			let event_type;
			switch (task_state) {
				case (default_task_states.approved):
					event_type = 'APPROVAL_COMPLETED_SUCCESS';
					break;
				case (default_task_states.rejected):
					event_type = 'APPROVAL_COMPLETED_REJECT';
					break;
				case (default_task_states.cancelled):
					event_type = 'APPROVAL_PROCESS_CANCELED';
					break;
				default:
					debug_log('workflow', options, `Got completion notification for state ${task_state} state (id = ${item.id}, version = ${item.version}), but only accept ${states.approved} and ${states.in_process}`);
					return;
			}

			/*
			* Determine who to notify
			*/
			const item_approvers = await get_all_approvers();
			const { item_authors } = await get_all_authors_and_reviewers(user_id, options, item_canonical_permissions);

			const to_notify = _dedupe_list(...item_approvers, ...item_authors);
			let notification_parameters = {
				type: options.type,
				id: options.id,
				event_type: event_type,
			};
			if (event_type === 'APPROVAL_COMPLETED_REJECT') {
				const user = await kaialpha.lib.user.get_user_info_by_id("", user_id);
				const workflow_status = {
					rejectionComment: context['@event']?.args?.comment,
					rejectedBy: user?.display_name
				};
				notification_parameters = { ...notification_parameters, ...workflow_status };
			}
			/*
			* Send notification
			*/
			await kaialpha.lib.notification.generate_notifications(to_notify, 'EVENT', notification_parameters);
		};

		/**
		 * Add Task to Completed Tasks for Workflow Slot
		 * @param {string} task_result
		 */
		const add_to_completed_tasks = (task_result) => {
			retval.completed_tasks = retval.completed_tasks || [];
			const is_cancelled = [default_task_states.cancelled, default_task_states.rejected].includes(task_result);

			if (is_cancelled) {

				// show cancelled status for all approvers on tasks list page
				const all_approver_user_ids = get_all_approvers();
				const now = new Date().toISOString();
				for (const approver of all_approver_user_ids) {
					let result = default_task_states.cancelled;
					const comment = context['@event']?.args?.comment;

					if (task_result === default_task_states.rejected && approver === user_id) {
						// if cancelled due to rejection, show reject status for the user that rejected
						result = default_task_states.rejected;
					}

					retval.completed_tasks.push({
						who: approver,
						when: now,
						action: 'set_ui_action_approve',
						result: result,
						approval_cycle_number: current_approval_cycle_number,
						comment,
					});
				}
			} else {
				retval.completed_tasks.push({
					who: user_id,
					when: (new Date()).toISOString(),
					action: 'set_ui_action_approve',
					result: task_result,
					approval_cycle_number: current_approval_cycle_number,
				});
			}

		};

		/**
		 * Helper to complete approval
		 * @returns
		 */
		const complete_user_approval = async function () {

			add_to_completed_tasks(default_task_states.approved);

			const new_permissions = kaialpha.lib.object_utils.copy_object(item.permissions);

			/**
			 * remove comment:write access to the approver after completing approval.
			 * approver will still have read access from @role:approvers
			 */
			new_permissions.roles._completed_approvers = new_permissions.roles._completed_approvers || [];
			new_permissions.roles._completed_approvers = _dedupe_list(...new_permissions.roles._completed_approvers, user_id);

			const all_approver_user_ids = await get_all_approvers();
			const all_approvers_completed = all_approver_user_ids.length > 0 && all_approver_user_ids.length === new_permissions.roles._completed_approvers.length;

			if (all_approvers_completed) {
				item.state = states.approved;
				await reset_item_state_and_permissions(item, 'Final Review Result: ' + default_task_states.approved, {
					state: item.state,
					apply_diff: options.apply_diff,
				});
				retval.variables.workflow_state = workflow_actions.final_review_approve;

				/**
				 * since there can be multiple approval cycles on an item,
				 * store approval cycle number for tracking completed_tasks against it
				 */
				retval.variables.approvals_completed_count = String(current_approval_cycle_number);

				await send_finished_notification(default_task_states.approved);
			} else {
				await options.apply_diff('@system', `User Final Review Result: ${default_task_states.approved}`, {
					change: {
						permissions: {
							roles: {
								_completed_approvers: new_permissions.roles._completed_approvers
							}
						}
					}
				},
				{
					skip_acl_notifications: true,
				});
			}

			return ({ all_approvers_completed, new_permissions });
		};

		/**
		 * Complete User Kickback Cycle
		 * Cancellation or Rejection
		 * @param {string} result_task_state
		 * @return {Promise<{variables: {}, completed_tasks: *[]}>}
		 */
		const complete_user_kickback = async function (result_task_state) {
			const reset_state = states.in_process;
			retval.timeout = undefined;
			retval.restart_options = {
				event: 'approval_cancelled', // this event name is not really needed, setting it for easy debugging
				args: {},
				from_operation: 'set_ui_action_approve',
				to_operation: 'set_ui_action_review',
			};

			// Reset all Completed Tasks and Reviews
			add_to_completed_tasks(result_task_state);

			// Reset item state
			const reset_by = result_task_state === default_task_states.cancelled ? 'Owner' : 'Approver';

			// Approvers may not have write access to update the item. Update as @system for rejections.
			const diff_user_id = result_task_state === default_task_states.cancelled ? user_id : '@system';
			item.state = reset_state;
			await reset_item_state_and_permissions(item, `${result_task_state} Approval Cycle : Reset by ${reset_by}`, {
				state: reset_state,
				apply_diff: options.apply_diff,
				skip_acl_notifications: true,
				user_id: diff_user_id,
			});

			await send_finished_notification(result_task_state);
			return retval;
		};

		await _handle_button_events(user_id, item, context, {
			'final_review_approve': async function () {
				await complete_user_approval();
			},
			'final_review_reject': async function () {
				await complete_user_kickback(default_task_states.rejected);
			},
			'cancel_approval_process': async function () {
				await complete_user_kickback(default_task_states.cancelled);
			}
		}, options);
	}

	retval.status = default_workflow_status;
	retval.ui = {
		buttons: []
	};

	switch (item.state) {
		case states.in_approval:
			retval.ui.buttons.push(uiButtonObject('final_review_approve', ['@role:approvers', '!@role:_completed_approvers'], true));
			retval.ui.buttons.push(uiButtonObject('final_review_reject', ['@role:approvers', '!@role:_completed_approvers'], true));
			retval.ui.buttons.push(uiButtonObject('cancel_approval_process', ['@owners'], false));
			break;
		case states.approved:
		case states.in_process:
		default:
			delete retval['ui'];
			delete retval['status'];
			delete retval['_task_notifications'];
			break;
	}
	retval.item_state = item.state;
	return (retval);
};

workflow_operations.set_ui_action_review = async function (user_id, state, context, options) {
	/* XXX:TODO: This should be moved up in workflow.js and passed as parameter */
	const item_states = { ...default_states };

	// return changes to the workflow state, this will be merged with current state
	const retval = {
		variables: {},
		completed_tasks: [],
	};

	if (!isValidType(options)) {
		debug_log('workflow', options, `Only currently handled for the following type(s) ${VALID_WORKFLOW_TYPES.join(',')}, type = `, options.type);
		return (retval);
	}

	// avoid losing required data when states are merged
	// copy prior completed tasks
	const {
		variables: { tracking = {} } = {},
		completed_tasks = [],
	} = state;
	retval.completed_tasks = kaialpha.lib.object_utils.copy_object(completed_tasks);

	// copy prior tracking data
	retval.variables.tracking = kaialpha.lib.object_utils.copy_object(tracking);

	// check if this is a tracking event
	const event = context['@event'];
	if (event.name === 'tracking') {
		const current_user_tracking = tracking[user_id] || [];
		retval.variables.tracking = {
			...tracking,
			[user_id]: _dedupe_list(...current_user_tracking, ...event.args),
		};
	}

	let reviews_completed_count = 0;
	if (state.variables.reviews_completed_count) {
		reviews_completed_count = Number(state.variables.reviews_completed_count);
	}

	const current_review_cycle_number = reviews_completed_count + 1;

	let item;
	if (isValidType(options)) {
		item = await options.get_user_item(user_id, options.id, options.version);
	}

	const { item_authors, item_reviewers } = await get_all_authors_and_reviewers(user_id, options);

	await _handle_button_events(user_id, item, context, {
		'request_review': async function () {

			/*
			 * If there are no reviewers, do not permit this to move forward
			 */
			if (item_reviewers.length === 0) {
				throw (new kaialpha.UserError('Cannot request review while there are no reviewers'));
			}

			retval.timeout = {
				in: '2 days', /* XXX:TODO: this should be copied from a parameter from the workflow operation */
				start: new Date().toISOString()
			};

			item.state = item_states.in_review;

			let commentsWrite = [];

			if (item.permissions.acl) {
				commentsWrite = item.permissions.acl['comments:write'] ? [...item.permissions.acl['comments:write']] : [];
			}

			await options.apply_diff(user_id, 'Review requested', {
				change: {
					state: item.state,
					permissions: {
						// reset completed reviewers list
						roles: {
							_completed_reviewers: [],
						},
						// completed reviewers should not be able to leave any more comments
						acl: {
							'comments:write': _dedupe_list(...commentsWrite, '@role:reviewers', '!@role:_completed_reviewers')
						}
					}
				}
			});

			retval.variables.workflow_state = workflow_actions.request_review;
			retval._task_notifications = item_reviewers;
		},
		'cancel_review_cycle': async function () {
			const orig_permissions = get_original_permissions(item);

			debug_log('workflow-detailed', options, 'Restoring permissions:', orig_permissions);

			retval.timeout = undefined;

			// Remove any prior completed reviews for this review cycle
			retval.completed_tasks = retval.completed_tasks
				.filter(
					// keep non review related tasks
					task => task.action !== 'set_ui_action_review' ||
						// keep tasks from other review cycles
						(task.action === 'set_ui_action_review' && task.review_cycle_number !== current_review_cycle_number)
				);

			// show cancelled status on tasks list page for this review cycle
			// NOTE: if reviewers has user groups, this will show 'cancelled' status for group instead of each user in the group.
			// this can to be changed to list each user in the reviewer role if required.
			const { roles: { reviewers: current_cycle_reviewers } } = orig_permissions;
			for (const reviewer of current_cycle_reviewers) {
				retval.completed_tasks.push({
					who: reviewer,
					when: (new Date()).toISOString(),
					action: 'set_ui_action_review',
					result: default_task_states.cancelled,
					review_cycle_number: current_review_cycle_number,
				});
			}

			item.state = item_states.in_process;
			await reset_item_state_and_permissions(item, 'Review Cycle Canceled', {
				state: item.state,
				apply_diff: options.apply_diff,
			});

			// Generate Notification for Reviews and Authors
			const notification_list = [...item_authors, ...item_reviewers];

			await kaialpha.lib.notification.generate_notifications(notification_list, 'EVENT', {
				type: options.type,
				id: options.id,
				event_type: 'REVIEW_CANCELED'
			});
		},
		'complete_review': async function () {

			// check if user already finished review in this review cycle
			const user_already_completed_review = retval.completed_tasks
				.some(
					task => task.action === 'set_ui_action_review' &&
						task.who === user_id &&
						task.result === 'Completed' &&
						task.review_cycle_number === current_review_cycle_number
				);

			if (user_already_completed_review) {
				debug_log('workflow-detailed', options,
					'Duplicate action: Review is already completed by this user:',
					{ user_id, current_review_cycle_number });
				return;
			}

			/**
				 * remove comment:write access to the reviewer after completing review.
				 * reviewer will still have read access from @role:reviewers
				 */
			const new_permissions = kaialpha.lib.object_utils.copy_object(item.permissions);

			// define `_completed_reviewers` role if not present
			new_permissions.roles._completed_reviewers = new_permissions.roles._completed_reviewers || [];

			// add this user to completed_review role
			new_permissions.roles._completed_reviewers = _dedupe_list(...new_permissions.roles._completed_reviewers, user_id);

			// show completed status in tasks list
			retval.completed_tasks.push({
				who: user_id,
				when: (new Date()).toISOString(),
				action: 'set_ui_action_review',
				result: 'Completed',
				review_cycle_number: current_review_cycle_number,
			});

			// _completed_reviewers role will only contain user ids so we dont need to canonicalize it to compare it with reviewers list
			const all_reviewers_completed = item_reviewers.length > 0 && item_reviewers.length === new_permissions.roles._completed_reviewers.length;
			if (all_reviewers_completed) {
				const orig_permissions = get_original_permissions(item);

				debug_log('workflow-detailed', options, 'Restoring permissions:', orig_permissions);

				retval.timeout = undefined;

				/* XXX:TODO: Add support for an "on_behalf_of" option for the user_id to versions.js */
				item.state = item_states.in_process;
				await reset_item_state_and_permissions(item, 'Review Complete', {
					state: item.state,
					apply_diff: options.apply_diff,
				});

				reviews_completed_count++;
				retval.variables.reviews_completed_count = String(reviews_completed_count);
				retval.variables.workflow_state = workflow_actions.complete_review;

				// Generate Notification for Reviews and Authors
				const to_notify = [...item_authors, ...item_reviewers];
				await kaialpha.lib.notification.generate_notifications(to_notify, 'EVENT', {
					type: options.type,
					id: options.id,
					event_type: 'REVIEW_COMPLETE'
				});
			} else {
				/* XXX:TODO: Add support for an "on_behalf_of" option for the user_id to versions.js */
				await options.apply_diff('@system', 'User review completed',
					// changes
					{
						change: {
							permissions: {
								roles: {
									_completed_reviewers: new_permissions.roles._completed_reviewers
								}
							}
						}
					},
					// options
					{
						skip_acl_notifications: true,
					}
				);
			}
		},
		'request_approval': async function () {
			retval.timeout = {
				in: '2 days', /* XXX:TODO: this should be copied from a parameter from the workflow operation */
				start: new Date().toISOString()
			};
			item.state = item_states.finalized;
			await options.apply_diff(user_id, 'Finalizing for approval', {
				change: {
					state: item.state,
				}
			});
		}
	}, options);

	if (!item.state) {
		item.state = item_states.in_process;

		await options.apply_diff(user_id, 'Set initial state for review', {
			change: {
				state: item.state
			}
		});

		retval.variables.reviews_completed_count = '0';
	}

	retval.status = default_workflow_status;
	retval.ui = {
		buttons: []
	};

	switch (item.state) {
		case (item_states.in_process):
			{
				retval.ui.buttons.push(uiButtonObject('request_review', ['@owners'], false));
				if (reviews_completed_count > 0) {
					retval.ui.buttons.push(uiButtonObject('request_approval', ['@owners'], false));
				}
				break;
			}
		case (item_states.in_review):
			{
				retval.ui.buttons.push(uiButtonObject('complete_review', ['@role:reviewers', '!@role:_completed_reviewers']));
				retval.ui.buttons.push(uiButtonObject('cancel_review_cycle', ['@owners'], false));
				break;
			}
		case (item_states.finalized):
			break;
		default:
			debug_log('workflow', options, 'Item in unknown state:', item.state);
			break;
	}

	if (retval.ui && retval.ui.buttons && retval.ui.buttons.length === 0) {
		delete retval['ui'];
		delete retval['status'];
	}
	retval.item_state = item.state;
	return (retval);
};

workflow_operations.if = async function (_ignore_user_id, _ignore_state, _ignore_context) {
	/* XXX:TODO */
};

workflow_operations.set_owner = async function (_ignore_user_id, _ignore_state, _ignore_context) {
	/* XXX:TODO */
};

workflow_operations.set_ui_action_prompts = async function (user_id, state, context, options) {
	const event = context['@event'];

	if (event.name === 'prompt_response') {
		const args = event.args;

		do {
			let missingVariables = false;

			/*
			 * Compute a map of variable values for quick checking
			 */
			const variable_values = {
				[options.type]: {},
				local: {}
			};

			for (const prompt of args) {
				const variable_type = prompt.variable_type;
				const variable_name = prompt.variable_name;
				const variable_value = prompt.value;
				variable_values[variable_type][variable_name] = variable_value;
			}

			/*
			 * Ensure all prompt values have been supplied
			 */
			for (const prompt of context.prompts) {
				const variable_type = prompt.variable_type;
				const variable_name = prompt.variable_name;

				if (variable_values[variable_type][variable_name] === undefined) {
					debug_log('workflow', options, 'Missing variable:', { variable_type, variable_name }, 'in variables:', variable_values);
					missingVariables = true;
					break;
				}

				/* XXX:TODO: Validate schema */
			}

			if (missingVariables) {
				break;
			}

			/*
			 * Process all the supplied values
			 */
			const item_variables_diff = {
				change: {
					variables: {}
				},
				delete: {
					variables: {}
				}
			};

			for (const prompt of args) {
				const variable_type = prompt.variable_type;
				const variable_name = prompt.variable_name;
				const variable_value = prompt.value;

				if (!isValidVariableType(variable_type)) {
					throw (new Error(`Invalid workflow variable type "${variable_type}"`));
				}

				switch (variable_type) {
					case 'local':
						if (state.variables === undefined) {
							state.variables = {};
						}

						state.variables[variable_name] = String(variable_value);
						break;
					default:
						if (options.type !== variable_type) {
							throw (new Error(`Tried to set variable type "${variable_type}" on item type "${options.type}"`));
						}
						item_variables_diff.change.variables[variable_name] = variable_value;
						item_variables_diff.delete.variables[variable_name] = null;
						break;
				}
			}

			if (Object.keys(item_variables_diff.change.variables).length > 0) {
				debug_log('workflow-detailed', options, 'Setting variables from workflow:', item_variables_diff);

				await options.apply_diff(user_id, 'Set variables from workflow', item_variables_diff);
			}

			return ({});
		} while (workflow_operations.set_ui_action_prompts === undefined /* false */);
	}

	/*
	 * If there is a prompt with no actual prompts, the user would get
	 * an empty prompt -- just skip this in that case
	 */
	if (Object.keys({ ...context.prompts }).length === 0) {
		return ({});
	}

	return ({
		status: default_workflow_status,
		ui: {
			prompts: context.prompts
		}
	});
};

/*
 * Example stateful workflow
 */
workflow_operations.set_ui_counter_button = async function (_ignore_user_id, state, context) {
	/** @type {number | string} */
	let old_x = '0';
	if (state && state.variables && state.variables.x !== undefined) {
		old_x = state.variables.x;
	}

	old_x = Number(old_x);

	const event = context['@event'];
	if (event.name === 'button_press') {
		const args = event.args;
		switch (args.id) {
			case 'plus':
				old_x++;
				break;
			case 'minus':
				old_x--;
				break;
			case 'done':
				return ({});
			default:
				return ({});
		}
	}

	return ({
		status: default_workflow_status,
		ui: {
			buttons: [
				{
					label: '+',
					id: 'plus'
				},
				{
					label: '-',
					id: 'minus'
				},
				{
					label: `Done (${old_x})`,
					id: 'done'
				}
			]
		},
		variables: {
			x: String(old_x)
		}
	});
};

/**
 * Reset item state and permissions.
 * @param {KaiAlphaCMSItem} item
 * @param {string} summary
 * @param {{ apply_diff: Function, state?: string, skip_acl_notifications?: boolean, user_id?: string }} options
 */
async function reset_item_state_and_permissions(item, summary, options) {
	// get the original permissions before review started
	const orig_permissions = get_original_permissions(item);

	// reset completed reviewers list
	// this will give back comment write access to completed reviewers
	if (orig_permissions && orig_permissions.roles && orig_permissions.roles._completed_reviewers) {
		orig_permissions.roles._completed_reviewers = [];
	}

	// reset completed approvers list
	// this will give back comment write access to completed approvers
	if (orig_permissions && orig_permissions.roles && orig_permissions.roles._completed_approvers) {
		orig_permissions.roles._completed_approvers = [];
	}

	const reset_to_state = options.state || default_states.in_process;

	// no need to notify users while resetting to their original access
	let skip_acl_notifications = true;
	if (options.skip_acl_notifications !== undefined) {
		skip_acl_notifications = options.skip_acl_notifications;
	}

	debug_log('workflow-detailed', 'Resetting item state:', { item_id: item.id, reset_to_state, orig_permissions });

	// Approvers/reviewers may not have write access to update the item. Update as @system for Approval rejections.
	const user_id = options.user_id || '@system';
	const retval = await options.apply_diff(user_id, summary, {
		change: {
			state: reset_to_state,
			permissions: orig_permissions
		},
		delete: {
			permissions: null
		}
	},
	{
		skip_acl_notifications: skip_acl_notifications,
	});

	return retval;
}

/**
 * Checks and returns the original permissions of item
 * @param {KaiAlphaCMSItem} item
 * @returns {KaiAlphaCMSItemPermissions}
 */
function get_original_permissions(item) {
	const current_permissions = kaialpha.lib.object_utils.copy_object(item.permissions);
	let orig_permissions = current_permissions;

	/**
	 * NOTE: _save_workflow_set_ui_action_review and _save_workflow_set_ui_action_approve are no longer used.
	 * Leaving this here for backwards compatibility.
	 */
	const { roles: {
		_save_workflow_set_ui_action_review = null,
		_save_workflow_set_ui_action_approve = null,
	} = {} } = current_permissions;

	// Original permissions are copied to _save_workflow_set_ui_action_review on request_review operation
	if (_save_workflow_set_ui_action_review) {
		orig_permissions = JSON.parse(_save_workflow_set_ui_action_review[0]);
	}

	//  Original permissions are copied to _save_workflow_set_ui_action_approve on request_final_approve operation
	if (_save_workflow_set_ui_action_approve) {
		orig_permissions = JSON.parse(_save_workflow_set_ui_action_approve[0]);
	}

	return orig_permissions;
}

async function _post_workflow_event(user_id, state, event, args = {}, options = {}) {
	const workflow = await options.get_workflow(user_id, state, options);
	debug_log('workflow-detailed', options, 'Found workflow:', workflow);

	let restart_options;
	do {
		let requiresRerun = false;
		if (state.status === 'exited') {
			if (options.rerun !== true) {
				throw (new Error(`Asked to execute a workflow that has exited: ${JSON.stringify({ event, args, state })}`));
			} else {
				options.rerun = false;
				state.status = undefined;
				state.pc = undefined;
				state.ui = undefined;
				state.operation = undefined;
				requiresRerun = true;
			}
		}

		if (!workflow) {
			break;
		}

		if (!workflow.actions) {
			break;
		}

		let steps;

		if (options._completing) {
			steps = workflow.actions[state.saved_context.event];
		} else {
			steps = workflow.actions[event];
			state.saved_context = {
				event,
				args
			};
		}

		if (!steps) {
			break;
		}

		if (requiresRerun || options.rerun) {
			const method = workflow_operations["restart_workflow"];
			const merge_state = await method(user_id, state, options);
			restart_options = merge_state?.restart_options;
			if (merge_state !== undefined) {
				delete merge_state['pc'];
				delete merge_state['operation'];
				delete merge_state['restart_options'];

				state = {
					...state,
					...merge_state,
					variables: {
						...state.variables,
						...merge_state.variables
					}
				};
			}
			options.rerun = false;
			requiresRerun = false;
		}

		/*
		 * Determine where to start this round of execution
		 */
		let start_pc = 0;
		if (restart_options) {
			/**
			 * If there are Restart Options Available we are Canceling or Rejecting an Approval Cycle
			 * Restart from Initial Review Cycle (Which allows push straight to Approval Option)
			 */
			debug_log('workflow', options, 'Restarting Workflow : ', restart_options);
			// eslint thinks restart_options is undefined even though it's clearly not
			// eslint-disable-next-line no-loop-func
			start_pc = steps.findIndex(step => { return step.operation === restart_options.to_operation; });
			event = restart_options.event;
			args = restart_options.args;

			// remove restart options to prevent accidental loops
			restart_options = undefined;
		} else if (state.pc === undefined) {
			/**
			 ** If there is no last instruction, then we must be starting.
			 **/
			debug_log('workflow', options, 'Workflow starting', state);
		} else {
			/**
			 ** Resume execution from the last instruction
			 **/
			debug_log('workflow', options, 'Workflow resuming from instruction', state.pc);
			start_pc = state.pc;
		}

		/*
		 * Execute each instruction in the workflow until we
		 * must wait on I/O
		 */
		for (let pc = start_pc; pc < steps.length; pc++) {
			const step = steps[pc];

			const operation = step.operation;
			const parameters = step.parameters;

			let method = options.operation_map[operation];

			if (!method) {
				method = workflow_operations[operation];
			}

			if (!method) {
				debug_log('workflow', options, 'Failed to lookup method for operation', operation, 'from', { ...workflow_operations, ...options.operation_map });

				throw (new Error(`Unable to lookup method for operation ${operation}`));
			}

			const context = {
				...parameters,
				'@event': {
					name: event,
					args: args
				},
				'@ui': state.ui
			};

			state.pc = pc;
			state.status = 'running';
			state.operation = operation;
			state.ui = undefined;
			state._task_notifications = undefined;
			if (!state.variables) {
				state.variables = {};
			}

			debug_log('workflow-detailed', options, 'Executing', { operation, state, context }, 'on behalf of', user_id);

			const merge_state = await method(user_id, state, context, options);
			restart_options = merge_state?.restart_options;

			if (merge_state !== undefined) {
				delete merge_state['pc'];
				delete merge_state['operation'];
				delete merge_state['restart_options'];

				state = {
					...state,
					...merge_state,
					variables: {
						...state.variables,
						...merge_state.variables
					}
				};
			}

			// Check for Restart of Workflow
			if (restart_options) {
				// break out of this for loop and restart the while loop
				break;
			}

			if (state.status === default_workflow_status) {
				debug_log('workflow', options, 'Waiting on user input');
				return (state);
			}

			/*
			 * It's possible we were acting to complete a workflow,
			 * thus "event" and "args" passed in were from the
			 * completion request, reset them to the saved context
			 * for the next operation
			 */
			event = state.saved_context.event;
			args = state.saved_context.args;
		}
	} while (restart_options !== undefined);

	debug_log('workflow', options, 'Workflow exited');

	state.status = 'exited';
	state.operation = undefined;
	state.pc = undefined;

	return (state);
}

function _set_options(options) {
	const apply_diff = options.apply_diff;

	if (!options.get_user_item) {
		debug_log('workflow', options, 'Workflow options missing required get_user_item value');

		throw new Error('get_user_item is required was not provided');
	}

	options = {
		type: undefined,
		id: undefined,
		version: undefined,
		permissions: undefined,
		slot_name: undefined,
		operation_map: {},
		load_workflow_state: undefined,
		save_workflow_state: undefined,
		_completing: false,
		get_user_workflow: async function (user_id, id, version) {
			return (await kaialpha.lib.workflow.get_user_workflow(user_id, id, version));
		},
		get_workflow: async function (user_id, state, options = {}) {
			const id = state.id;
			const version = state.version;

			if (id === undefined) {
				return undefined;
			}

			return (await options.get_user_workflow(user_id, id, version));
		},
		get_canonical_permissions: async function (user_id, id, gcp_options) {
			return (await kaialpha.lib.general_utils.get_canonical_permissions_and_roles_any_type(user_id, options.type, id, gcp_options));
		},
		...options,
		// when the version changes, auto set the options.version to the changed version
		apply_diff: async (...args) => {
			if (apply_diff) {
				const retVal = await apply_diff(...args);
				options.version = retVal.version;
			}
		},
	};

	if (options.log_id === undefined) {
		options.log_id = uuid.v4();
	}

	return (options);
}

async function post_workflow_event(user_id, event, args = {}, options = {}) {
	options = _set_options(options);

	debug_log('workflow-detailed', options, 'Posting workflow event:', { event, args });

	const state = kaialpha.lib.object_utils.copy_object(await options.load_workflow_state(user_id));

	if (options._completing) {
		if (state.status !== default_workflow_status) {
			throw (new Error('Asked to complete workflow while not waiting'));
		}
	} else {
		if (state.status === default_workflow_status) {
			throw (new Error('May not execute more workflow operations until current operation completes'));
		}
	}

	/*
	 * Post the event
	 */

	const new_state = await _post_workflow_event(user_id, state, event, args, options);

	/*
	 * Make a note of anyone to notify, but don't record this in the state
	 * record
	 */
	const to_notify = new_state._task_notifications;
	delete new_state['_task_notifications'];
	const item_state = new_state.item_state;
	delete new_state['item_state']; //We don't want to save this alongside workflow, hence deleting. We need item_state only to return to client.
	/*
	 * Save the state
	 */
	const saved_info = await options.save_workflow_state(user_id, new_state);

	const retval = {
		result: saved_info,
		state: new_state,
		item_state
	};

	if (new_state.status === default_workflow_status && options.type && options.id && options.slot_name) {
		/*
		 * If we are waiting, notify anyone relevant of the task
		 */
		if (to_notify) {
			await kaialpha.lib.notification.generate_notifications(to_notify, 'TASK', {
				type: options.type,
				id: options.id,
				workflow_slot: options.slot_name,
				requested_by: user_id
			});
		}
	}

	debug_log('workflow-detailed', options, 'Result:', retval);

	// clear tasks cache on completing task
	if (event !== 'tracking') {
		await kaialpha.lib.cache_utils.clear('cache_tasks');
	}

	return (retval);
}

async function complete_workflow_event(user_id, event, args = {}, options = {}) {
	return (await post_workflow_event(user_id, event, args, {
		...options,
		_completing: true
	}));
}

/* --- */
async function get_workflow_variable(user_id, name, options = {}) {
	options = _set_options(options);

	const state = kaialpha.lib.object_utils.copy_object(await options.load_workflow_state(user_id));

	if (!state || !state.variables) {
		return undefined;
	}

	return (state.variables[name]);
}

async function get_workflow_status(user_id, options = {}) {
	options = _set_options(options);

	const state = kaialpha.lib.object_utils.copy_object(await options.load_workflow_state(user_id));

	if (!state || !state.status) {
		/* XXX:TODO: What should we do here ? */
		return undefined;
	}

	return (state.status);
}

/**
 * Filter workflow UI state
 *
 * @param {KaiAlphaUserID} user_id - User ID
 * @param {Object} state - State of the workflow
 * @param {Object} options - Options
 * @param {Object} options.permissions - Permissions to use to filter
 */
async function workflow_ui_filter_state(user_id, state, options) {
	if (!state || !state.status) {
		return (state);
	}

	if (state.status !== default_workflow_status) {
		return (state);
	}

	if (!state.ui) {
		return (state);
	}

	const retval = {
		...state,
		ui: {}
	};

	for (const part in state.ui) {
		let part_value = state.ui[part];

		switch (part) {
			case 'buttons':
				part_value = await workflow_ui_filter_buttons(user_id, part_value, options.permissions);
				break;
			default:
				/* The linter wants a default case... */
				break;
		}

		retval.ui[part] = part_value;
	}

	return (retval);
}

async function get_workflow_ui(user_id, options = {}) {
	const retval = {};

	options = _set_options(options);

	const state = kaialpha.lib.object_utils.copy_object(await options.load_workflow_state(user_id));

	if (!state || !state.status) {
		return (retval);
	}

	if (state.status !== default_workflow_status) {
		return (retval);
	}

	if (!state.ui) {
		return (retval);
	}

	return (state.ui);
}

const _to_export_auto = {
	post_workflow_event,
	complete_workflow_event,
	workflow_ui_filter_state,
	workflow_ui_filter_buttons,
	get_workflow_variable,
	get_workflow_status,
	get_workflow_ui,
	load_workflow_state,
	constants: {
		workflow_operations: Object.keys(workflow_operations),
		default_for: workflow_default_for,
		workflowStates: default_states,
		default_workflow_action_states: { ...workflow_actions, ...default_states }
	},
	_testing
};
export default _to_export_auto;
