import {useSaveAction} from "./actions";
import { useEntityAutoSave } from "../../../shared/hooks/useEntityAutoSave";

/**
 * Automatically saves a Template every 60 seconds
 * @param shouldRun: function to determine if auto save should run
 */
function useAutoSave(shouldRun: () => boolean) {
	return useEntityAutoSave(useSaveAction, shouldRun);

}

export {useAutoSave};