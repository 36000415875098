import { styled } from "@mui/material";
import { Create } from "@mui/icons-material";
import { CenteredContainer } from "../../CenteredContainer";
import {ButtonProps} from "./Button.model";
import {ButtonBase} from "./ButtonBase";

const EditButtonContainer = styled(ButtonBase)(({theme}) => ({
	backgroundColor: theme.palette.tabs?.border,
	color: theme.palette.primary.main,
	"&:hover": {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.tabs?.border
	}
}));

const EditButton = ({onClick}: ButtonProps) => <EditButtonContainer onClick={onClick}>
	<CenteredContainer>
		<Create style={{fontSize: '19px'}}/>
	</CenteredContainer>
</EditButtonContainer>

export {EditButton}