import { useState } from "react";
import { Button, FlexBox } from "../../../../../../../shared/components";
import { useReference } from "./useReference";
import { DialogProps } from "../../../../elements/model";
import { BaseModal } from "../../../../../../../shared/components/modals/BaseModal";
import { Box, TextField } from "@mui/material";
import { List } from "../../../../../../../shared/components/list/tree/TreeList.style";
import { TreeNode } from "../../../../../../../shared/components/list/tree/TreeList.models";
import { EmptyGroup } from "../../../../../../../shared/components/layout/groups/EmptyGroup";
import { ReferenceListItem } from "./ReferenceListItem";
import { SortFilter } from "../../../../../../../shared/components/input/filter/sort";
import { FilterState } from "../../../../../../../shared/components/input/filter/sort/models";
import { DropDown, DropDownItem } from "../../../../../../../shared/components/input/dropdown";
import { formatOptions } from "../../../../../../../shared/definitions/elements/reference/formatOptions";
import { Tooltip } from "../../../../../../../shared/components/labels/Tooltip";
import { formatTooltipTreeNodeId } from "../../../../../../../shared/components/list/tree/formatTooltipTreeNodeId";
import {displayNameForElementType} from "../../../../../../../shared/definitions/elements";
import {ReferenceNodeData} from "../../../../../../../shared/interfaces/ReferenceNodeData";
import {Hr} from "../../../../../../../shared/components/layout/HorizontalRule";
import {SelectedElementContainer} from "./SelectedElementContainer";
interface State {
	open: boolean,
	selectedNode: TreeNode | null
}

const ReferencePropertyEditor = (props: DialogProps) => {
	const referenceManager = useReference();
	const [state, setState] = useState<State>({
		open: true,
		// selectedNode: null,
		selectedNode: props.variable ? {
			id: props.variable.id as string,
			// TreeNode needs this, but it will not be displayed in the 'selected node'.
			name: 'N/A',
			children: [],
			format: "",
			// format: props.variable.value.format,
			type: props.variable.type,
			data: {
				template_id: props.variable.templateId,
			}
		} : null,
	});

	// when a child reference is loaded, update the reference array
	const onReferenceLoaded = (reference: TreeNode) => {
		referenceManager.updateReference(reference);
	}

	const onClose = () => {
		setState(s => ({ ...s, open: false }));
		props.onClose();
	}

	const onChange = () => {
		setState(s => ({ ...s, open: false }));
		const data = state.selectedNode?.data as (ReferenceNodeData | null);
		if (props.onChange) {
			props.onChange({
				...props.variable,
				data: ({
					...props.variable.value as object,
					value: ({
						element_id: state.selectedNode?.id,
						template_id: data?.template_id,
						// template_version: data?.template_version,
						type: state.selectedNode?.type,
						// if a format wasnt selected, default to link.
						format: state.selectedNode?.format ?? "{{link}}",
					})
				})
			});
		}
	}

	const onReferenceSelected = (node: TreeNode) => {
		setState(s => ({ ...s, selectedNode: node }));
	}

	const onFormatSelected = (item: DropDownItem<string>) => {
		setState(s => ({
			...s,
			selectedNode: s.selectedNode === null ? null : {
				...s.selectedNode,
				format: item.value
			}
		}));
	}

	const onSortingChanged = (state: FilterState) => referenceManager.setSortState(state);

	return (
		<BaseModal
			visible={state.open}
			onClose={onClose}
			title="Add Element: Reference"
			content={<FlexBox direction="column">
				<FlexBox justify="space-between" align="stretch">
					<FlexBox boxSizing="border-box" flex="0.7">
						<TextField
							label="Search"
							placeholder="Type to search elements"
							fullWidth
							variant="outlined"
							onChange={(e) => referenceManager.setSearchValue(e.target.value)}
						/>
					</FlexBox>
					<FlexBox justify="center" align="center" width="auto" flex="0.3" boxSizing="border-box">
						<SortFilter title="Type" onStateChange={onSortingChanged} />
					</FlexBox>
				</FlexBox>
				<Hr />
				{(referenceManager.filteredReferences?.length ?? 0) !== 0 ? <List maxHeight="25rem">
					{referenceManager.filteredReferences?.map((reference) => <ReferenceListItem reference={reference as TreeNode} key={reference.id} onChildTemplateLoaded={onReferenceLoaded} onReferenceSelected={onReferenceSelected} depth={0} />)}
				</List> : <EmptyGroup title="No elements found" />}
				<SelectedElementContainer>
					<b>Selected Element Type:</b> {state.selectedNode?.type &&
						<Tooltip title={formatTooltipTreeNodeId(state.selectedNode)} placement={"top"}>
							<Box marginLeft="0.2rem">
								{displayNameForElementType(state.selectedNode?.type)}
							</Box>
						</Tooltip>
					}
				</SelectedElementContainer>
				<Box sx={{ my: "1rem", width: "100%" }}>
					<DropDown items={formatOptions} onSelect={onFormatSelected} value={state.selectedNode?.format ?? "{{link}}"} label={"Reference format"} sx={{ width: "100%" }} />
				</Box>
			</FlexBox>}
			buttonRow={<>
				<Button styling="cancel" text="Cancel" onClick={onClose} />
				<Button buttonType={"default"} text={"Add"} onClick={onChange} disabled={!state.selectedNode?.id} />

				{/* <FlexBox>
					<Button buttonType={"default"} text={"Back to Elements"} disabled={true} onClick={() => { }} />
				</FlexBox> */}
			</>}
		/>

	)

}

export { ReferencePropertyEditor }