import {ValueEditorProps} from "./model";
import {DropDown, DropDownItem} from "../../../../../../../shared/components/input/dropdown";

type DropdownValueEditorOptions = {
	items: DropDownItem<string>[],
}

const DropdownValueEditor = (props: ValueEditorProps) => {
	const options = props.data.options as DropdownValueEditorOptions
	const value = props.data.value as string;

	const onOptionSelect = (option: DropDownItem<string>) => props.onChange(option.value);
	return <DropDown sx={props.sx} items={options.items} onSelect={onOptionSelect} value={value ?? options.items[0].value} />
}

export {DropdownValueEditor}