import { EditableElementProps } from "../../model";
import { PropertyList } from "../../PropertyList";
import { Button } from "../../../../../../shared/components";
import { useElements } from "../../../../hooks";
import { metaValueDefinition } from "../../meta/value/model";
import { metaDefaultDefinition } from "../../meta/default/model";
import { SwitchElementValue } from "../../../../../../shared/definitions/elements/switch/model";
import { TemplateElement } from "../../../../../../shared/interfaces/TemplateElement";

const EditableView = (props: EditableElementProps) => {
	const elementManager = useElements();
	const value = props.data as SwitchElementValue;
	const properties = [{ label: "Name", value: value.name }, { label: "Expression", value: value.expression }];

	const onAddCase = () => onAddElement(elementManager.createElement(metaValueDefinition.type));
	const onAddDefaultCase = () => onAddElement(elementManager.createElement(metaDefaultDefinition.type))

	const onAddElement = (element: TemplateElement) => {
		const thisElement = elementManager.findById(props.id);
		if (!thisElement) {
			return;
		}

		elementManager.addElement({...element, id: props.id, depth: thisElement.depth+1}, props.id);
	}

	const hasDefaultCase = elementManager.childrenForElementWithId(props.id).find(element => element.type === metaDefaultDefinition.type);

	return <PropertyList items={properties}>
		<Button text={"Add case"} onClick={onAddCase} sx={{ mr: ".5rem" }} />
		<Button buttonType={hasDefaultCase ? "disabled" : "default"} text={"Add default case"} onClick={onAddDefaultCase} />
	</PropertyList>
}

export { EditableView }