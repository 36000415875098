import {TemplateElementDefinition} from "../model";
import {FooterIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import PreviewView from "./views/preview/PreviewView";
import {ReadonlyView} from "./views/ReadonlyView";
import {
	footerDefinition as baseFooterDefinition
} from "../../../../../shared/definitions/elements/footer/model";

const footerDefinition: TemplateElementDefinition = {
	...baseFooterDefinition,
	icon: FooterIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		preview: PreviewView
	},
} as const

export {footerDefinition};