import { Column, ColumnViewProps } from "../../components/layout/table";
import { TaskNode } from "../../interfaces/task";
import DateFnsUtils from "@date-io/date-fns";
import { DataTableCell } from "../../components/layout/table/styles";

const dateAdapter = new DateFnsUtils();
const Cell = (props: ColumnViewProps) => {
	const item = props.item as TaskNode;
	const column = props.column;
	return <DataTableCell
		align={column.itemAlign}
		width={column.width}
		key={`${column.title}-${item.id}`}
	>
		<p>{item.timeout?.at ? dateAdapter.formatByString(item.timeout.at, "MM dd yyyy") : ''}</p>
	</DataTableCell>
}
const DueDate: Column = ({
	field: 'due',
	title: "Due Date",
	itemAlign: "left",
	headerAlign: "left",
	searchable: false,
	sortable: true,
	views: {
		cell: Cell
	}
})

export { DueDate };