import {searchApi, SearchApiProps, SearchApiResponse} from "../../../../shared/hooks/api/searchApi";

const submissionFields = ['_external_data'] as const;
type SubmissionFieldTypes = typeof submissionFields[number];
export type SubmissionExternalData = {cache: string}
type SubmissionItemFields = Record<SubmissionFieldTypes, SubmissionExternalData>
export type SubmissionSearchItem = SubmissionItemFields & {id: string};

export type SearchSubmissionsApiFunc = ({filters, pageSize, sort, after}: Omit<SearchApiProps, "searchType" | "fields">) => Promise<SearchApiResponse<SubmissionSearchItem>>;
export function searchSubmissionsApi({filters, pageSize, sort, after}: Omit<SearchApiProps, "searchType" | "fields">): Promise<SearchApiResponse<SubmissionSearchItem>> {
	return searchApi<SubmissionSearchItem>({filters, fields: [...submissionFields], pageSize, sort, after, searchType: "submission"});
}