import {PropertiesTab} from "../tabs/properties";
import {TabDefinition} from "../../../../shared/components/layout/tabs/tabDefinition";
import {TabbedPane} from "../../../../shared/components/layout/pane/TabbedPane";
import {ElementsTab} from "../tabs/elements";
import {TemplatesTab} from "../tabs/templates";

const tabs: TabDefinition[] = [ElementsTab, PropertiesTab, TemplatesTab];
const EditorLeftPane = () => {
	return <TabbedPane orientation="left" tabs={tabs} />
}

export {EditorLeftPane}
