import {DataSourcePreview, PreviewProps} from "./model";
import {TablePreview} from "./TablePreview";
import {ImagePreview} from "./ImagePreview";
import {BlobPreview} from "./BlobPreview";
import {DataSourceType} from "../../../../../../../../../shared/interfaces/DataSource";

const previews: DataSourcePreview[] = [TablePreview, ImagePreview, BlobPreview]
export type PreviewForDataSourceTypeProps = PreviewProps & {type: DataSourceType}
export function PreviewForDataSourceType(props: PreviewForDataSourceTypeProps) {
	const Preview = previews.find(preview => preview.type === props.type)?.view;
	return Preview ? <>
		<Preview {...props} key={props.dataSource?.name} />
	</> : null;
}