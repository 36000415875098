import { BaseElement } from "../../../../interfaces/BaseElement";

const metaValueDefinition: BaseElement = {
	name: '@meta:value',
	type: '@meta:value',
	displayName: 'Case [value]',
	description: '',
	allowsChildren: true,
	longDescription: '',
	contentDescription: () => "",
	toString: () => "Meta:Value (Preview Unavailable)"
} as const

export type MetaValueElementValue = {
	value?: string
}

export {metaValueDefinition};