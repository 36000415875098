
import { useStatusBar } from "../../../../../shared/components";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import {useUpdateWorkflow} from "../../api/useUpdateWorkflow";
import { DocumentState } from "../../useDocumentState";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

const DEFAULT_DOCUMENT_WORKFLOW_SLOT = 'default';

type RunProps = {id: string, comment?: string, lastupdated?: number}
function useUpdateDocumentWorkflowAction(): TypedAction<RunProps> {
	const updateWorkflow = useUpdateWorkflow();
	const documentManager = DocumentState.useContainer();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		if (updateWorkflow.status === "error") {
			// send status bar message
			statusBar.sendErrorNotification({message: `Error updating workflow ${updateWorkflow.error}`});
			return;
		}

		if (updateWorkflow.status === "success") {
			const {item_state, state} = updateWorkflow.value!;

			documentManager.setDocument({
				...documentManager.document!,
				lastupdated: updateWorkflow.value?.result.lastupdated,
				state: item_state,
				workflow: {
					...documentManager.document!.workflow,
					[DEFAULT_DOCUMENT_WORKFLOW_SLOT]: state,
				}});
			statusBar.sendSuccessNotification({message: "Workflow updated"});
		}
	}, updateWorkflow.status);

	const onUpdateWorkflow = (event: RunProps) => updateWorkflow.execute(
		event,
		'button_press',
		documentManager.document?.id,
		DEFAULT_DOCUMENT_WORKFLOW_SLOT
	);

	return {run: onUpdateWorkflow, isRunning: updateWorkflow.isLoading};
}

export {useUpdateDocumentWorkflowAction, DEFAULT_DOCUMENT_WORKFLOW_SLOT};