import {BaseModal} from "../../../../../../shared/components/modals/BaseModal";
import {Button, FlexBox} from "../../../../../../shared/components";
import {EditorModalDefinition, EditorModalProps} from "./index";
import {useState} from "react";

const Modal = (props: EditorModalProps) => {
	const [value, setValue] = useState(props.variable.value);

	const onChange = (newValue: unknown) => setValue(newValue);
	const onSubmit = () => props.onSubmit(value);
	const {Component} = props;


	return <BaseModal visible={true} onClose={props.onClose} title={`Edit ${props.variable.name}`} buttonRow={<>
		<Button text={"Cancel"} styling={"cancel"} onClick={props.onClose} />
		<Button buttonType={"default"} text={"Submit"} onClick={onSubmit} />
	</>} content={<FlexBox direction={"column"} width={"100%"}>
		{Component ? <Component variable={{...props.variable, value}} onClose={props.onClose} onChange={onChange} /> : null}
	</FlexBox>} />
}

const GenericEditorModal: EditorModalDefinition = {
	type: undefined,
	view: Modal
}

export {GenericEditorModal}