import {VariableMapper, VariableMappingFunction} from "./model";
import {ReferenceMapper} from "./referenceMapper";
import {DataSourceMapper} from "./datasourceMapper";
import {UndefinedMapper} from "./undefinedMapper";
import {MultiInputMapper} from "./multiInputMapper";
import {ImageMapper} from "./imageMapper";
import {CheckboxMapper} from "./checkboxMapper";
import { RichTextMapper } from "./richTextMapper";
import {VariableType} from "../../../../../../../shared/interfaces/Variable";

const mappers: VariableMapper[] = [ReferenceMapper, DataSourceMapper, MultiInputMapper, ImageMapper, CheckboxMapper, RichTextMapper];
function mapperForVariableType(type: VariableType): VariableMappingFunction | undefined {
	return mappers.find(mapper => mapper.type === type)?.mapper ?? UndefinedMapper.mapper;
}

export {mapperForVariableType};