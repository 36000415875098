const elementTypesWithChildren = ['switch', 'loop', 'template', 'section'];

// TODO: this needs to be improved to retrieve templates in order to traverse them.
// outside of that, there are likely edge cases here with switch & loop element formats.
export function findElementsInBody(body: KaiAlphaBody, elementId: string) {
	const foundElements: any[] = [];
	body?.forEach(e => {
		const id = Object.keys(e)[0];
		const element = e[id];
		if (elementId === id) {
			foundElements.push({...element, id});
		}
		if (elementTypesWithChildren.includes(element.type)) {
			foundElements.push(...findElementsInBody(element.body!, elementId));
		}
	});
	return foundElements;
}