import {searchApi, SearchApiProps} from "../../../../shared/hooks/api/searchApi";

const documentFields = [
	'id',
	'version',
	'name',
	'state',
	'workflow',
	'permissions',
	'@date',
	'_cached_canonical_permissions',
	'@workflows_waiting',
	'_external_data',
	'subdocuments'] as const;

type DocumentFieldTypes = typeof documentFields[number];
export type DocumentPermissions = {owners: string[]}
type DocumentItemFields = Record<DocumentFieldTypes, string | DocumentPermissions>
export type DocumentSearchItem = DocumentItemFields & DocumentPermissions & {id: string, hasTask: boolean};

export function searchDocumentsApi({filters, pageSize, sort, after}: Omit<SearchApiProps, "searchType" | "fields">) {
	return searchApi<DocumentSearchItem>({filters, fields: [...documentFields], pageSize, sort, after, searchType: "document"});
}