import {ContentNode} from "../../../models/content";
import {ColumnDefinition, Table} from "../../../../../shared/components/layout/table";
import {accessoryForColumn, columns} from "./columns";
import {FilterState} from "../../../../../shared/components/input/filter/sort/models";
import {ContentListRow} from "./ContentListRow";
import React from "react";
import {moduleLinkGenerator} from "../../../../moduleNavigation";
import {useNavigate} from "react-router-dom";
import { ListPageWrapper } from "../../../../../shared/components/layout/ListPageWrapper";

export interface ContentListProps {
	content: ContentNode[],
	filters?: {[field: string]: string},
	sort: {field: string, sortDirection: FilterState},
	onPageChange: (event: unknown, newPage: number) => void,
	onPageSizeChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
	onColumnSearch: (field?: string, value?: string) => void,
	onDelete: (contentNode: ContentNode) => void,
	onSort: (field: string, sortDirection: FilterState) => void,
	pageSize: number,
	page: number,
	total: number
}
const ContentList = ({content, sort, page, pageSize, total, filters, onPageChange, onPageSizeChange, onColumnSearch, onDelete, onSort}: ContentListProps) => {
	const navigate = useNavigate();
	const onRenderRow = (item: unknown, rowIndex: number) => {
		return <ContentListRow
			key={(item as any).id}
			item={item}
			rowIndex={rowIndex}
			onDeleteContent={onDelete}
			onRowSelected={handleRowSelected}
			onTaskSelected={handleTaskSelected}
			columns={columns} />
	}

	const handleColumnAccessoryRender = (column: ColumnDefinition) => {
		const Accessory = accessoryForColumn(column.field);
		return Accessory ? <Accessory column={column} /> : null
	}

	const handleTaskSelected = (documentId: string) => {
		const link = moduleLinkGenerator("content", "tasks", documentId);
		navigate(link);
	}

	const handleRowSelected = (content: ContentNode) => {
		const link = moduleLinkGenerator("content", "edit", content.id);
		navigate(link);
	}

	return (<ListPageWrapper><Table
		items={content}
		columns={columns}
		title={"Contents"}
		sort={sort}
		total={total}
		currentPage={page}
		pageSize={pageSize}
		filters={filters}
		onColumnSearch={onColumnSearch}
		onSort={onSort}
		onChangePage={onPageChange}
		onChangePageSize={onPageSizeChange}
		onRenderColumnAccessory={handleColumnAccessoryRender}
		onRenderRow={onRenderRow} /></ListPageWrapper>)
}

export {ContentList}