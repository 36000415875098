import { CacheProvider, CacheEntryType } from "./cacheProvider";
import DateFnsUtils from "@date-io/date-fns";

export interface Cache {
	[key: string]: CacheEntryType
}

const dateAdapter = new DateFnsUtils();

/**
 * Factory method for the in-memory cache provider
 * @returns an in-memory based cache that implements the {CacheProvider} interface
 */
const MemoryCacheProviderFactory = (): CacheProvider => {
	const cache: Cache = {}

	// last time the garbage was collected
	let lastGarbageCollection: Date | null = null;

	/**
	 * Gets the @type CacheEntryType from the cache
	 * @param key {string}
	 * @returns the @type CacheEntryType
	 */
	function getItem(key: string): CacheEntryType | undefined {
		return cache[key];
	}

	/**
	 * Removes the @type CacheEntryType with the given key
	 * @param key {string}
	 */
	function removeItem(key: string): void {
		if(cache[key]) {
			delete cache[key];
		}
	}

	/**
	 * Retrieves a list of all @type CacheEntryType
	 * @returns @type CacheEntryType[]
	 */
	function allItems(): CacheEntryType[] {
		return Object.values(cache);
	}

	/**
	 * Adds the @type CacheEntryType to the cache with the specified key
	 * @param key {string}
	 * @param item {CacheEntryType}
	 */
	function setItem(key: string, item: CacheEntryType): void {
		cache[key] = item;
	}

	/**
	 * Cleans the cache of expired items and enforces the limit (if there is one)
	 * @param maximumSize {number} maximum cache size - zero means no max limit
	 */
	function cleanCache(maximumSize: number): void {
		// get all cache items
		let items = allItems();

		// get a list of values that have expired
		const valuesToRemove = items.filter(cacheItem => {

			// make sure we don't have any null values
			if(typeof (cacheItem) === "undefined" || cacheItem === null) {
				return true;
			}

			// check to see if item has expired
			const cacheLifetime = dateAdapter.getDiff(cacheItem.created, new Date(), "minutes").valueOf();
			return cacheLifetime > cacheItem.expiryTimeMinutes;

		});

		// remove any items that have expired
		if(valuesToRemove.length > 0) {
			valuesToRemove.forEach(value => removeItem(value.key));
		}

		// check to see cache should have a limited size
		if(maximumSize > 0) {
			// get all items - this is necessary as we may have just updated the cache
			items = allItems();
			// if we are within the max size
			// set the last garbage collection date and return
			if(items.length < maximumSize) {
				lastGarbageCollection = new Date();
				return;
			}

			// sort the cache by access count and then by created date (asc)
			// we only want to remove the oldest, least accessed cache item
			// this means we can keep frequently accessed items in the cache until they expire
			items.sort((item1, item2) => (item1.accessCount - item2.accessCount) || (item1.created!.getTime() - item2.created!.getTime()));
			removeItem(items[0].key);
		}

		// set the last garbage collection date
		lastGarbageCollection = new Date();
	}

	/**
	 * Invokes the cleanCache function if necessary
	 * @param timeIntervalMinutes {number} how often garbage collection should occur (in minutes)
	 * @param maximumSize {number} maximum cache size - zero means no max limit
	 */
	function garbageCollect(timeIntervalMinutes: number, maximumSize: number): void {

		// if the cache has never been cleaned or we have a max limit that has been exceeded
		// clean the cache
		if(!lastGarbageCollection || (maximumSize > 0 && allItems().length > maximumSize)) {
			cleanCache(maximumSize)
		}

		// if the clean interval hasn't passed since the last clean
		// return
		if(dateAdapter.getDiff(lastGarbageCollection!, new Date(), "minutes").valueOf() < timeIntervalMinutes) {
			return;
		}

		cleanCache(maximumSize);
	}

	return {
		getItem,
		setItem,
		removeItem,
		allItems,
		garbageCollect
	}
}

const MemoryCacheProvider = MemoryCacheProviderFactory();
export {MemoryCacheProvider, MemoryCacheProviderFactory}