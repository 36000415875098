import { FlexBox, Button, AsyncComponent } from "../../../../../shared/components";
import { TabDefinition } from "../../../../../shared/components/layout/tabs/tabDefinition";
import { AddComment, Person, Refresh } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { CommentState, DocumentComment } from "../../../hooks/useCommentState";
import { TabButtonsContainer } from "./Comments.style";
import './style.scss';
import { CommentsIndividual } from "./CommentsIndividual";
import { Tooltip } from "../../../../../shared/components/labels/Tooltip";
import CommentModal from "../../modals/documentComments/CommentModal";
import { DocumentCommentsFilterTabs } from "./DocumentCommentsFilterTabs";
import { DocumentState } from "../../../hooks/useDocumentState";
import { SearchBar } from "../../../../../shared/components/input/search/SearchBar";
import { useDeleteCommentAction, useLoadAllCommentsAction } from "../../../hooks/actions";
import {GroupedTabs} from "../../../../../shared/components/layout/tabs/grouped/GroupedTabs";
import {CommentViewModeGroupTabs, CommentViewModeTitleMap, CommentViewTitleModeMap} from "./models";
import {GroupedTab} from "../../../../../shared/components/layout/tabs/grouped/models";
import {useActiveSection} from "../../../hooks/useActiveSection";
import {CenterPaneHtmlId} from "../../../models/constants";
import {EmptyGroup} from "../../../../../shared/components/layout/groups/EmptyGroup";
import {useEventListener, useScroll} from "../../../../../shared/hooks";

type CommentType = "comments" | "openComments" | "resolvedComments";
type CommentTab = {
	displayName: string,
	totalFunction: string
}
type CommentTabMap = (CommentTab & { type: CommentType })[]
const commentTabMap: CommentTabMap = [
	{
		type: "comments",
		displayName: "All Comments",
		totalFunction: "getTotalCommentsLength"
	},
	{
		type: "openComments",
		displayName: "Open",
		totalFunction: "getOpenCommentsLength"
	},
	{
		type: "resolvedComments",
		displayName: "Resolved",
		totalFunction: "getResolvedCommentsLength"
	},
]


const TabView = () => {

	const documentManager = DocumentState.useContainer();
	const commentsManager = CommentState.useContainer();

	const loadAllCommentsAction = useLoadAllCommentsAction();
	const deleteCommentAction = useDeleteCommentAction();
	const activeSectionId = useActiveSection(CenterPaneHtmlId);

	const [selectedTab, setSelectedTab] = useState<number>(0);
	const [searchQuery, setSearchQuery] = useState("");
	const [showModal, setShowModal] = useState(false);
	const scrollManager = useScroll();
	const onCommentedElementClick = (event: Event) => {
		if (event.target instanceof HTMLElement && event.target.dataset.commentid ) {
			const commentId = event.target.dataset.commentid;
			if (commentId !== null) {
				scrollManager.scrollById(commentId);
			}
		}
		return false;
	}

	useEventListener("click", onCommentedElementClick)
	const handleCommentModalClose = () => {
		setShowModal(false);
	}

	const onSearchValueChange = (value?: string) => {
		setSearchQuery(value ?? "");
	}


	const filterComments = useMemo(() => {
		const tabType = commentTabMap[selectedTab].type;
		let comments: DocumentComment[] = []
		if (tabType === "comments") {
			comments = commentsManager.comments?.filter(value => value?.comment?.includes(searchQuery)) ?? []
		} else if (tabType === 'openComments') {
			comments = commentsManager.openComments().filter(value => value?.comment?.includes(searchQuery)) ?? [];
		} else if (tabType === "resolvedComments") {
			comments = commentsManager.resolvedComments().filter(value => value?.comment?.includes(searchQuery)) ?? [];
		}

		return commentsManager.viewMode === "all" ? comments : comments.filter(comment => comment.elementId === activeSectionId)
	}, [searchQuery, selectedTab, commentsManager, activeSectionId]);

	const onRefreshClick = () => {
		loadAllCommentsAction.run();
	};

	const onCommentViewModeChange = (tab: GroupedTab) => commentsManager.setViewMode(CommentViewTitleModeMap[tab.title]);

	return (
		<article className="comments-tab">
			<GroupedTabs tabs={CommentViewModeGroupTabs} activeIndex={CommentViewModeGroupTabs.findIndex(t => t.title === CommentViewModeTitleMap[commentsManager.viewMode])} onTabSelect={onCommentViewModeChange} />
			<FlexBox align="center" justify="center" flex={0}>
				<SearchBar placeholder="Search" onChange={onSearchValueChange}
					containerStyle={{ margin: "0 1rem", flex: 1, paddingTop: "5px" }} />
				<TabButtonsContainer>
					<Tooltip title={"Refresh comments"} placement={"top"}>
						<Button
							buttonType={(!documentManager.commentable) ? "disabled" : "plain"}
							disabled={!documentManager.commentable}
							styling="inverted"
							icon={<Refresh fontSize="small" />}
							onClick={onRefreshClick}
						/>
					</Tooltip>
				</TabButtonsContainer>
				<TabButtonsContainer>
					<Tooltip title={"Add a comment"} placement={"top"}>
						<span>
							<Button
								buttonType={(!documentManager.commentable) ? "disabled" : "plain"}
								disabled={!documentManager.commentable}
								styling="inverted"
								icon={<AddComment fontSize="small" />}
								onClick={() => setShowModal(true)} />
						</span>
					</Tooltip>
				</TabButtonsContainer>
				<TabButtonsContainer>
					<Button buttonType="plain" icon={<Person fontSize="small" />} onClick={() => { }} />
				</TabButtonsContainer>
			</FlexBox>
			<AsyncComponent
				isLoading={loadAllCommentsAction.isRunning || deleteCommentAction.isRunning}
				loadingMessage={"Refreshing Comments"}
				component={
					<>
						<FlexBox boxSizing="border-box" paddingY="15px" minWidth="25rem" flex={0}>
							<DocumentCommentsFilterTabs
								selectedTabIndex={selectedTab}
								tabs={commentTabMap.map((tab) => ({
									...tab,
								}))}
								onTabSelected={setSelectedTab}
							/>
						</FlexBox>
						<FlexBox flex={1} direction="column" justifyContent="space-between" overflow="hidden">
							<article className={"comments-tab__selected-view"}>
								{documentManager.document && documentManager.document.id && filterComments && filterComments.map((value: DocumentComment) => {
									return <CommentsIndividual
										key={value.id}
										comment={value}
										readonly={!documentManager.commentable || value.author !== commentsManager.getCurrentUserId()}
									/>;
								})}
								{filterComments.length === 0 && <EmptyGroup title={"No comments" + (commentsManager.viewMode === "all" ? "" : " for this section")} />}
							</article>
						</FlexBox>
					</>}
			/>
			{
				showModal &&
				<CommentModal
					onClose={handleCommentModalClose}
					addEditComment={"Add"}
					showOpen={showModal}
					elementId={documentManager?.document?.id}
					documentId={documentManager?.document?.id}
				/>
			}
		</article >
	);
}


const CommentsTab: TabDefinition = {
	name: "Comments",
	content: TabView
}

export { CommentsTab };