import { Column, ColumnViewProps } from "../../components/layout/table";
import { TaskNode } from "../../interfaces/task";
import { OwnerCell } from "../../components/layout/table/cells/OwnerCell";

const Cell = (props: ColumnViewProps) => {
	const item = props.item as TaskNode;
	return <OwnerCell column={props.column} owners={item.owner} id={item.id} />
}


const Owner: Column = ({
	field: "owner",
	title: "Owner",
	headerAlign: "left",
	itemAlign: "left",
	width: "25%",
	sortable: true,
	searchable: true,
	views: {
		cell: Cell
	}
})

export { Owner };