import { BaseElement } from "../../../interfaces/BaseElement";

const tocDefinition: BaseElement = {
	name: 'Template',
	type: 'table_of_contents',
	displayName: 'Table of Contents',
	allowsChildren: false,
	description: 'Place Sub-Templates',
	longDescription: 'Template elements may be used to include another template at the position where this element is placed',
	contentDescription: () => "",
	toString: () => "(Preview Unavailable)"
} as const

export {tocDefinition};