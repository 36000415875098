import {TemplateElementDefinition} from "../model";
import {VariableIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {PropertyEditor} from "./views/editor/PropertyEditor";
import {ReadonlyView} from "./views/ReadonlyView";
import {
	variableDefinition as baseVariableDefinition
} from "../../../../../shared/definitions/elements/variable/model";

const variableDefinition: TemplateElementDefinition = {
	...baseVariableDefinition,
	icon: VariableIcon,
	views: {
		editable: EditableView,
		propertyEditor: {
			view: PropertyEditor,
			requiredOnCreate: true
		},
		readonly: ReadonlyView
	},
} as const

export {variableDefinition};