import {TypedVariableView} from "./model";
import {DatasourceVariable} from "./datasource/DatasourceView";
import {DropDownVariable} from "./DropDownView";
import {CheckboxVariable} from "./CheckboxView";
import {ExpressionVariable} from "./ExpressionView";
import {ListVariable} from "./ListView";
import {VariableType} from "../../../../../../../shared/interfaces/Variable";
const typedVariables: TypedVariableView[] = [DatasourceVariable, DropDownVariable, CheckboxVariable, ListVariable, ExpressionVariable]
export function typedViewForVariableType(type?: VariableType) {
	return typedVariables.find(variable => variable.type === type)?.view;
}
