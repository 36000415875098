import {EditableElementProps, ElementPropertyData} from "../../model";
import React, {useState} from "react";
import {TextField} from "../../../../../../shared/components/input";
import { FooterElementValue } from "../../../../../../shared/definitions/elements/footer/model";
import {useDebounce, useRunWhenValueChange} from "../../../../../../shared/hooks";
import {DebounceDelay} from "../../utilities/editSettings";

const EditableView = (props: EditableElementProps) => {
	const data = props.data as FooterElementValue;
	const [footer, setFooter] = useState(data.value);
	const debouncedFooter = useDebounce(footer, DebounceDelay);

	useRunWhenValueChange(() => {
		props.onChange({
			...(props as ElementPropertyData),
			data: {...data, value: debouncedFooter}
		})
	}, debouncedFooter);

	const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setFooter(event.target.value);
	}
	return <TextField placeholder={"Enter footer here"} value={footer} onChange={onChange} />
}

export {EditableView}