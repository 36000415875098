import {NunjucksFilterDocumentation, NunjucksHelp} from "./model";
import {nunjucksFilterFactories, nunjucksGlobalFilterFactories} from "../index";
import {nativeFilterDocumentationCollection} from "./nativeFilters";
import {nativeOperatorsDocumentationCollection} from "./nativeOperators";

export function compileDocumentation(): NunjucksHelp {
	const filters = [...nunjucksFilterFactories, ...nunjucksGlobalFilterFactories].reduce((filters, factory) => {
		const {name, help, example} = factory();
		return filters.concat([{name, help, example}]);
	}, [] as NunjucksFilterDocumentation[]);

	return {
		filters: nativeFilterDocumentationCollection.concat(filters)
			.reduce((lookup, filterDocumentation) => {
				lookup[filterDocumentation.name] = {...filterDocumentation};
				return lookup;
			}, {}),
		operators: nativeOperatorsDocumentationCollection.reduce((lookup, operator) => {
			lookup[operator.name] = operator.help;
			return lookup;
		}, {})
	}
}