import { TreeNode } from "../../../components/list/tree/TreeList.models";
import { BaseElement } from "../../../interfaces/BaseElement";

const headerDefinition: BaseElement = {
	name: 'Template',
	type: 'header',
	displayName: 'Header',
	allowsChildren: false,
	description: '',
	longDescription: '',
	contentDescription: (contents) => {
		if ('data' in (contents as TreeNode)) {
			return (contents as TreeNode).data?.value ?? "";
		}
		return (contents as HeaderElementValue)?.value ?? "";
	},
	toString: (contents) => {
		if ('data' in (contents as TreeNode)) {
			return (contents as TreeNode).data?.value ?? "Empty Header";
		}
		return (contents as HeaderElementValue).value ?? "Empty Header";
	}
} as const

export type HeaderElementValue = {
	value?: string
}

export {headerDefinition};