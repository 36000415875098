import {useAsync} from "../../../../shared/hooks";
import { TemplateWithBuffer } from './models.js';
import { useGetTemplate } from './useGetTemplate';
import { useGetTemplateBuffer } from './useGetTemplateBuffer';

// gets a new template (and the user's associated buffer) by id
function useGetTemplateAndBuffer() {

	const getTemplate = useGetTemplate();
	const getBuffer = useGetTemplateBuffer();
	// get the published template and template buffer
	const getTemplateAndBuffer = async (id, version?: string): Promise<TemplateWithBuffer> => {
		const template = await getTemplate.execute({id, version});
		if (!template) {
			throw new Error('No template with the provided id found');
		}
		const buffer = await getBuffer.execute(template?.id, template?.version);

		return {buffer, template};
	};

	return useAsync<TemplateWithBuffer>(getTemplateAndBuffer, false);
}

export {useGetTemplateAndBuffer};