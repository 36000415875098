import {useAsync} from "../../../../shared/hooks";
import {UserManager} from "../../../../shared/hooks/useUser";
import api from "../../../../api/api";

// gets a new template (and the user's associated buffer) by id
function useGetTemplateBuffer() {
	const user = UserManager.useContainer();
	const getBuffer = async (templateId, templateVersion): Promise<KaiAlphaTemplate | null> => {
		try {
			const id = `${user.id}_${templateId}_${templateVersion}`;
			return api.call('GET', `template/buffer/${id}`);
		} catch(ignoredError) {
			// ignore error, buffer is valid if null.
		}
		return null;
	}
	return useAsync<KaiAlphaTemplate>(getBuffer, false);
}

export {useGetTemplateBuffer};