import { Route } from "react-router-dom"
import { ModuleDefinition } from "../"
import { ModuleRoutes } from "../../shared/routing"
import { LandingPage } from "./pages/LandingPage"

const DashboardRouter = () => {
	return <ModuleRoutes>
		<Route path={"/"} element={<LandingPage />} />
	</ModuleRoutes>
}

const DashboardModule: ModuleDefinition = {
	path: "/*",
	component: DashboardRouter,
	basePath: "/",
	name: "Dashboard"
}

export {DashboardModule};