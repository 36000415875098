import {useRunOnce, useRunWhenValueChange} from "../../../shared/hooks";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {TemplateState} from "../hooks";
import React, {useState} from "react";
import {Context, DefaultContextButtons} from "../../../shared/components/buttons/theme";
import {getUserContextButtons} from "../utilities";
import {
	DEFAULT_TEMPLATE_WORKFLOW_SLOT,
	useCreateTemplateAction,
	useGetDatasourcesAction,
	useLoadTemplateAction,
	usePublishAction,
	useSaveAction, useSetBufferFromTemplate,
	useUpdateWorkflowAction
} from "../hooks/actions";
import {TemplateSetAction} from "../hooks/useTemplateState";
import {isApproved, isInReview} from "../../../shared/interfaces/WorkflowState";
import {useAutoSave} from "../hooks/useAutoSave";
import { useExportAction } from "../hooks/actions/data/useExportAction";
import {useGetAllCommentsAction} from "../hooks/actions/load/useGetAllCommentsAction";
import {useLoadAllVariablesAction} from "../hooks/actions/load/useLoadAllVariables";
import {hasRoles, Roles} from "../../../shared/interfaces/Permissions";
import {ActionRun} from "../../../shared/interfaces/ActionRun";
import { TemplateElement } from "../../../shared/interfaces/TemplateElement";
import {EntityRecord} from "../../../shared/interfaces/Entity";
import {moduleLinkGenerator} from "../../moduleNavigation";

interface Location {
	id?: string,
	version?: string
}

interface WorkflowModalState {display: boolean, pendingEventId: string}
export interface UseEditorReturn {
	displayBufferMessageBox: boolean,
	setBufferMessageBoxDisplay: React.Dispatch<React.SetStateAction<boolean>>,
	displayPublishMessageBox: boolean,
	setPublishMessageBoxDisplay: React.Dispatch<React.SetStateAction<boolean>>,
	setBufferFromTemplate: (template?: KaiAlphaTemplate) => void,
	saveBuffer: ActionRun,
	updateWorkflowIfRolePresent: (role: Array<string>, error: string, id: string) => void,
	onToggleEditMode: () => void,
	onWorkflowUpdate: (event: {id: string, comment?: string}) => void,
	workflowCommentModalState: WorkflowModalState,
	setWorkflowCommentModalState: React.Dispatch<React.SetStateAction<WorkflowModalState>>,
	contextButtons: Context[],
	error: string | null,
	hasBeenReviewed: boolean,
	isInReview: boolean,
	isInEditMode: boolean,
	buffer: KaiAlphaTemplate | null,
	template: KaiAlphaTemplate | null,
	isLoading: boolean,
	isUpdating: boolean,
	isLoadingRightPane: boolean
	setBuffer: TemplateSetAction,
	publish: ActionRun,
	export: ActionRun,
	location: Location,
	onNavigateToVersion: (entity: Required<EntityRecord>) => void
}

function useEditor(): UseEditorReturn {
	const {id, version} = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	// Actions
	const publishAction = usePublishAction();
	const saveBufferAction = useSaveAction();
	const exportAction = useExportAction();
	const updateWorkflowAction = useUpdateWorkflowAction();
	const getVariablesAction =  useLoadAllVariablesAction();//useGetVariablesAction();
	const getDatasourcesAction = useGetDatasourcesAction();
	const createTemplateAction = useCreateTemplateAction();
	const loadTemplateAction = useLoadTemplateAction();
	const getAllCommentsAction = useGetAllCommentsAction();
	// End Actions

	const [error, setError] = useState<string | null>(null);
	// used to dynamically provide buttons to the context menu
	const [contextButtons, setContextButtons] = useState<Context[]>([]);
	// require user to provide reasoning (comment) for rejecting a workflow state
	const [workflowCommentModalState, setWorkflowCommentModalState] = useState({display: false, pendingEventId: ''});
	// question user if they would like to keep their buffer changes or start from last published template
	const [displayBufferMessageBox, setBufferMessageBoxDisplay] = useState(false);
	// require user to acknowledge that changes will notify other contributors
	const [displayPublishMessageBox, setPublishMessageBoxDisplay] = useState(false);
	const templateState = TemplateState.useContainer();
	const setBufferFromTemplate = useSetBufferFromTemplate();
	// conditions to allow auto save to run
	const canAutoSave = !(saveBufferAction.isRunning ||
		publishAction.isRunning ||
		updateWorkflowAction.isRunning ||
		loadTemplateAction.isRunning ||
		createTemplateAction.isRunning ||
		templateState.isInReview ||
		isApproved(templateState.template?.state) ||
		templateState.template?.id === undefined ||
		templateState.buffer === null ||
		!templateState.editable
	)
	// start auto saving interval
	useAutoSave(() => canAutoSave);

	// when editor loads, get the associated template (triggers below effect)
	useRunWhenValueChange(() => {
		templateState.reset();
	}, [id, version]);

	useRunWhenValueChange(() => {
		if (templateState.didReset) {
			if (id) {
				// retrieve already created template
				loadTemplateAction.run({id, version});
			} else {
				// generate new template on behalf of the user
				createTemplateAction.run({
					toplevel: searchParams.get('toplevel') ?? "false",
				});
			}
		}
	}, templateState.didReset)

	// load data sources
	useRunOnce(() => {
		getDatasourcesAction.run();
	})

	useRunWhenValueChange(() => {
		// if template id has changed, we have a buffer, and we're not in review
		// prompt the user to see if they wish to load the buffer
		if (templateState.buffer !== undefined && templateState.bufferExists && !templateState.isInReview) {
			setBufferMessageBoxDisplay(true);
		}

		if(templateState.template?.id) {
			getAllCommentsAction.run(templateState.template);
		}
	}, templateState.template?.id);


	// reload variables from server if the buffer's variables array has changed
	useRunWhenValueChange((prevValue) => {
		const prevElements = (prevValue === null ? [] : prevValue[2] ?? []) as TemplateElement[];
		const subTemplateAdded = prevElements.filter(element => element.type === "template").length !==
			(templateState.elements?.filter(element => element.type === "template")?.length ?? 0)

		if (templateState.buffer && templateState.template?.id && (subTemplateAdded || templateState.variables.length === 0)) {
			getVariablesAction.run({body: templateState.elements});
		}
	}, [templateState.buffer?.variables, templateState.template?.id, templateState.elements]);

	const isOwner = hasRoles(templateState.computedUserRoles ?? [], [Roles.Owner]);
	useRunWhenValueChange(() => {
		setContextButtons([
			...(isInReview(templateState.template?.state) && isOwner ? [{...DefaultContextButtons.Edit, active: templateState.isInEditMode}] : []),
			...(templateState.editable ? [{
				...DefaultContextButtons.View,
				label: !templateState.previewMode ? "Preview" : 'leave preview',
				key: "preview"
			}, DefaultContextButtons.Publish, DefaultContextButtons.Save, DefaultContextButtons.Export] : [DefaultContextButtons.Export]),
			...getUserContextButtons(
				templateState.template?.workflow?.[DEFAULT_TEMPLATE_WORKFLOW_SLOT]?.ui?.buttons ?? [],
				templateState.computedUserRoles ?? []
			)
		]);
	}, [templateState.template?.workflow, templateState.computedUserRoles, templateState.editable, templateState.template?.state, templateState.isInEditMode, templateState.previewMode]);


	const onWorkflowUpdate = (event: {id: string, comment?: string}) => updateWorkflowAction.run({...event, lastupdated: templateState.template?.lastupdated});
	const updateWorkflowIfRolePresent = (role: Array<string>, error: string, id: string) => {
		if (role.length < 1) {
			setError(error);
		} else {
			onWorkflowUpdate({id});
		}
	}

	const onToggleEditMode = () => templateState.setEditMode(!templateState.isInEditMode);
	const onNavigateToVersion = ({id, version}: Required<EntityRecord>) => navigate(moduleLinkGenerator("template", "edit", id, version === "HEAD" ? "" : version));

	return {
		displayBufferMessageBox,
		displayPublishMessageBox,
		setPublishMessageBoxDisplay,
		setBufferMessageBoxDisplay,
		setBufferFromTemplate,
		saveBuffer: saveBufferAction.run,
		updateWorkflowIfRolePresent,
		onWorkflowUpdate,
		workflowCommentModalState,
		onToggleEditMode,
		setWorkflowCommentModalState,
		contextButtons,
		error,
		hasBeenReviewed: parseInt(templateState.template?.workflow?.[DEFAULT_TEMPLATE_WORKFLOW_SLOT]?.variables?.reviews_completed_count as string ?? "0") > 0,
		isInReview: templateState.isInReview,
		isInEditMode: templateState.isInEditMode,
		buffer: templateState.buffer,
		template: templateState.template,
		isLoading: createTemplateAction.isRunning || loadTemplateAction.isRunning || updateWorkflowAction.isRunning || exportAction.isRunning,
		isUpdating: saveBufferAction.isRunning || publishAction.isRunning,
		isLoadingRightPane: getVariablesAction.isRunning,
		setBuffer: templateState.setBuffer,
		publish: publishAction.run,
		export: exportAction.run,
		location: {id, version},
		onNavigateToVersion
	}
}

export {useEditor};