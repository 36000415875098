import {makeStyles} from "@mui/styles";
import { colors } from "../../../../theme";
import {Theme} from "@mui/material";

export interface CheckBoxStyleProps {
	readonly: boolean
}
const useStyles = makeStyles<Theme,CheckBoxStyleProps>((theme) => ({
	checkbox: {
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		"& .MuiTypography-root": {
			marginLeft: "0.5rem",
			fontSize: "0.75rem",
			fontWeight: 400
		}
	},
	text: {
		color: theme.palette.text.primary
	},
	icon:{
		width: "1rem",
		height: "1rem",
	},
	selected_icon: {
		width: "1rem",
		height: "1rem",
		color: props => props.readonly ? colors.almostBlack :  colors.mountainMeadow
	},
	unselected_icon:{
		width: "1rem",
		height: "1rem",
	}
}));

export {useStyles};