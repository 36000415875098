import { styled } from "@mui/material";
import Box from '@mui/material/Box';

const Container = styled(Box)(({theme}) => ({
	"documentWideToggleContainer": {
		display: 'flex',
		alignItems: 'center',
		marginLeft: -10,
	},
}));

const MentionsBox = styled(Box)(({theme}) => ({
	display: 'flex',
	flexDirection: 'column',
	"& .mentionsInput": {
		borderRadius: '5px',
		height: '150px'
	},
}));

const BoxRadio = styled(Box)(({theme}) => ({
	display: 'flex',
	alignItems: 'center',
	"& .infoIcon": {
		height: 15,
		width: 15,
	},
	"& .applyToAllInstancesText": {
		marginRight: '5px'
	},
}));

const ButtonsBox = styled(Box)(({theme}) => ({
	display:'flex',
	justifyContent: 'space-between',
	marginTop: '20px',
}));

const TagsBox = styled(Box)(({theme}) => ({
	display:'flex',
	alignItems: 'center',
	"& .tagsChip": {
		backgroundColor: theme?.palette?.tagsChip?.main,
		border: 'none',
		marginRight: '5px',
		cursor: 'pointer'
	},
	"& .selectedChip": {
		background: theme.palette?.tagsChip?.selectedChipBackground,
		'& .MuiChip-label': {
			color: theme.palette?.tagsChip?.selectedChipColor
		}
	},
	"& .localOfferIcon": {
		marginRight: '5px'
	},
}));

export {Container, ButtonsBox, TagsBox, BoxRadio, MentionsBox};
