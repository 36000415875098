import {TemplateElementDefinition} from "../model";
import {CommentIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {
	instructionalTextDefinition as baseInstructionalTextDefinition
} from "../../../../../shared/definitions/elements/instructionalText/model";

const instructionalTextDefinition: TemplateElementDefinition = {
	...baseInstructionalTextDefinition,
	icon: CommentIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView
	},
} as const

export {instructionalTextDefinition};