import {ensureArgs} from "../filterUtilities";
import {isArrayWithLength} from "../../../guards/isArray";
import {NunjucksFilterFactoryProps} from "../model";

function rejectFilter(...args: unknown[]): unknown {
	if (!ensureArgs(args)) {
		return [];
	}

	if (!isArrayWithLength(args[0])) {
		return [];
	}

	const items = args[0] as any[];
	return items.filter(function(item) {
		return !!item;
	});
}

export function rejectFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "reject",
		help: "Filter that removes all false-y values",
		example: "var | reject",
		execute: rejectFilter
	})
}