import {NunjucksFilterFactoryProps} from "../model";
import {ensureArgs} from "../filterUtilities";
import {isNunjucksDataSource, NunjucksDataSourceType} from "../../models/NunjucksDataSourceType";
import {isString} from "../../../guards/isString";
import {isArray} from "../../../guards/isArray";
import {isNumber} from "../../../guards/isNumber";

function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args, 3)) {
		return {
			data: [],
			type: "columns",
		} as NunjucksDataSourceType
	}

	const base = isNunjucksDataSource(args[0]) ? args[0] : undefined;
	const rowName = isString(args[1]) ? args[1] : (isNumber(args[1]) ? args[1] : undefined);
	const rowValues = isArray(args[2]) ? args[2] : (isString(args[2]) ? [args[2]] : undefined);

	if (base === undefined || base.data === undefined || rowName === undefined || rowValues === undefined) {
		return {
			data: [],
			type: "columns",
		} as NunjucksDataSourceType
	}

	return findColumns({base, rowName, rowValues});
}

type FindColumnsProps = {
	base: NunjucksDataSourceType,
	rowName: string | number,
	rowValues: string | string[]
}
function findColumns({base, rowName, rowValues}: FindColumnsProps): NunjucksDataSourceType {
	const valuesToMatch = typeof rowValues === "string" ? [rowValues] : rowValues;
	const retval: NunjucksDataSourceType = {
		type: base.type,
		data: [],
	}

	const data = base.data;
	switch(base.type) {
		case 'columns-rows':
		{
			const matchingColumnNames: string[] = [];
			retval.data = {};

			for (const columnName in data) {
				if (valuesToMatch.some(value => String(data[columnName][rowName]) === String(value))) {
					matchingColumnNames.push(columnName);
				}
			}

			for (const columnName of matchingColumnNames) {
				for (const checkColumnName in data[columnName]) {
					const cell_value = data[columnName][checkColumnName];
					if (retval.data[columnName] === undefined) {
						retval.data[columnName] = {};
					}

					retval.data[columnName][checkColumnName] = cell_value;
				}
			}
		}
			break;
		case 'columns': {
			const matchingColumnNames: string[] = [];
			for (const columnHeader in data[rowName]) {
				if (valuesToMatch.some(value => String(data[rowName][columnHeader]) === String(value))) {
					matchingColumnNames.push(columnHeader);
				}
			}

			retval.data = matchingColumnNames.reduce((result, columnName) =>
				result.concat(data.map(row => ({[columnName]: row[columnName]}))), [])
		}
			break;
		default:
			throw(new kaialpha.UserError(`Data Type "${base.type}" not supported`));
	}

	return retval;
}

export function findColumnsFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "find_columns",
		help: "Finds all columns that match at least one of the given values in one of its cells",
		example: 'var | find_columns("rowName",["rowVal", "rowVal2"])',
		execute: execute
	})
}