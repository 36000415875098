import {EditableElementProps} from "../../model";
import {typedViewForVariableType} from "./typed";
import {PropertyList} from "../../PropertyList";
import { VariableElementValue } from "../../../../../../shared/definitions/elements/variable/model";


const EditableView = (props: EditableElementProps) => {
	const value = props.data as VariableElementValue;

	const TypedView = typedViewForVariableType(value.type);

	const properties = [{label: "Name", value: value.name}, {label: "Description", value: value.description}, {label: "Type", value: value.type}];
	return <PropertyList items={properties}>
		{TypedView && <TypedView options={value.options} name={value.name} readonly={false} />}
	</PropertyList>
}

export {EditableView};