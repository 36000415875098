import React from "react";
import { TemplateElementValue } from "../../../../../shared/definitions/elements/template/model";

interface TemplateSelectCallback {
    (template: KaiAlphaTemplate): void
}

interface TemplateRenderer {
    (template: KaiAlphaTemplate | null): React.ReactElement
}

export type TemplateListProps = {
    onTemplateSelect: TemplateSelectCallback,
    onRenderPreview: TemplateRenderer,
	selectedTemplate: TemplateElementValue | null,
}

export interface TemplateListState {
	templates: any[],
	options: object,
	selectedTemplate: any | null,
	templatePreview: any | null,
}

const TemplateListInitialState: TemplateListState = {
	templates: [],
	options: {},
	selectedTemplate: null,
	templatePreview: null,
}

export {TemplateListInitialState}