import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";
import {isEmptyString} from "../../../../../shared/utilities/isEmptyString";

// const elementTypesWithChildren = ['switch', 'loop', 'template', 'section'];
const EmptyElement: ElementValueType = {
	type: "",
	id: "",
	data: {},
	children: []
};
// TODO: this needs to be improved to retrieve templates in order to traverse them.
// outside of that, there are likely edge cases here with switch & loop element formats.
export function findElementsInBody(body: ElementValueType[] | undefined, elementId: string): ElementValueType | undefined {
	if (body === undefined || body.length === 0) {
		return {...EmptyElement};
	}

	const element = body.find(e => e.id === elementId);
	if (element) {
		return element;
	}

	// check in section elements
	const sections = body.filter(e => e.type === "section");
	if (sections.length > 0) {
		for (let i = 0; i < sections.length; i++) {
			const foundElement = findElementsInBody(sections[i].children, elementId)
			if (foundElement && !isEmptyString(foundElement.id)) {
				return foundElement;
			}
		}
	}

	const subTemplateElements = body.filter(e => e.type === "template")
		.map(template => template.children ?? [])
		.reduce((allTemplateElements, templateElements) => allTemplateElements.concat(templateElements), []);

	if (subTemplateElements.length > 0) {
		return findElementsInBody(subTemplateElements, elementId);
	}
	return {...EmptyElement};
}