import {isNunjucksFilterDependencies, NunjucksFilterDependencies, NunjucksFilterFactoryProps} from "../model";
import {ensureArgs} from "../filterUtilities";
import {isNunjucksEnvironmentOptions, NunjucksEnvironmentOptions} from "../../models/NunjucksEnvironmentOptions";

function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args, 2) || !isNunjucksEnvironmentOptions(args[0]) || !isNunjucksFilterDependencies(args[1])) {
		return "";
	}

	const dependencies = args[1] as NunjucksFilterDependencies;
	const options = args[0] as NunjucksEnvironmentOptions;

	const citations = dependencies.getIds(options, "citation");
	return JSON.stringify(citations);
}

export function citeListFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "__cites",
		help: "Create a list of citations",
		example: "var | __cites",
		execute: (...args: unknown[]) => execute(props?.options, props?.dependencies)
	})
}