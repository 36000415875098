import {styled, Typography} from "@mui/material";

export interface StateLabelProps {
	backgroundColor: string | null
}
const StateLabel = styled(Typography)<StateLabelProps>(({theme, backgroundColor}) => ({
	backgroundColor: backgroundColor ?? "white",
	height: "20px",
	padding: "0 10px",
	display: "flex",
	alignItems: "center",
	fontSize: ".65rem",
	color: "#1C1C1C",
	borderRadius: "2rem",
	"&.approved": {
		fontWeight: 700
	}
}))

export {StateLabel}