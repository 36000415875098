import {DocumentElementDefinition} from "../model";
import {PreviewView} from "./PreviewView";
import {
	headerDefinition as baseHeaderDefinition
} from "../../../../../shared/definitions/elements/header/model";

const headerDefinition: DocumentElementDefinition = {
	...baseHeaderDefinition,
	views: {
		preview: PreviewView
	},
} as const

export {headerDefinition};