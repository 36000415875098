import { styled } from "@mui/material";
import { FlexBox } from "../../../../../shared/components";

const RequestEditPopupContainer = styled(FlexBox)(({theme}) => ({
	padding: '25px',
	borderRadius: '8px',
	background: theme?.palette?.primary?.contrastText,
	boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
	'& h3':{
		fontSize:"1.2rem",
		margin: "0px 0px 10px",
		whiteSpace: 'nowrap',
		color: theme.palette.primary?.main,
	},
	'& p':{
		fontSize:"0.8rem",
		margin: "0px 0px 15px",
		whiteSpace: 'nowrap',
		color: theme.palette.tabs?.main,
	},
}));

export {
	RequestEditPopupContainer,
}