import { StyleElementValue } from "../../../../../../shared/definitions/elements/style/model";
import {EditableElementProps} from "../../model";
import {PropertyList} from "../../PropertyList";
import {typedViewForStyleType} from "./typed";

const EditableView = (props: EditableElementProps) => {
	const data = props.data as StyleElementValue;

	const TypedView = typedViewForStyleType(data.option);
	return <PropertyList items={[{label: "Options", value: data.option}]}>
		{TypedView && <TypedView value={data.value} /> }
	</PropertyList>
}

export {EditableView}