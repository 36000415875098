import {FlexBox, Label} from "../../../../../../shared/components";
import "./styles.scss"
type IndicationsProps = {
	indications: string[]
}

const Indications = ({indications}: IndicationsProps) => <FlexBox direction={"column"} className={"ectd__tabs--pcdl-indications"}>
	<div><Label title={"Indications"}>Indications</Label></div>
	<div className={"ectd__tabs--pcdl-indications__container"}>
		{indications.map(indication => <span className={"ectd__tabs--pcdl-indications__container-indication"} key={indication}>{indication}</span>)}
	</div>
</FlexBox>

export {Indications}