import {MetaDataValueEditor, ValueEditorProps} from "./model";
import {useRunOnce, useAsync} from "../../../../../../../shared/hooks";
import {AsyncComponent} from "../../../../../../../shared/components";
import {DropdownValueEditor} from "./DropdownValueEditor";
import {getEctdMasterApi} from "../../../../../../../shared/metaData/api/getEctdMasterApi";
import {EctdModule} from "../../../../../../../shared/interfaces/Module";

function flattenEctdModuleList(modules: EctdModule[], prefix?: string): {title: string, value: string}[] {
	return modules.map(module => {
		const titles = [{title: `${(prefix ? `${prefix} -> ` : "")}${module.moduleNumber}`, value: module.moduleNumber}];
		return (module.modules?.length ?? 0) > 0 ? titles.concat(flattenEctdModuleList(module.modules, `${(prefix ? `${prefix} -> ${module.moduleNumber}` : module.moduleNumber)}`)) : titles;
	}).reduce((all, current) => all.concat(current), []);
}
const Editor = (props: ValueEditorProps) => {
	const loadSections = useAsync(getEctdMasterApi, false);
	useRunOnce(() => {
		loadSections.execute();
	})

	const dropDownItems = loadSections.value ? flattenEctdModuleList(loadSections.value) : [];
	return <AsyncComponent
		isLoading={loadSections.isLoading}
		component={<DropdownValueEditor {...props} data={{...props.data, options: {items: dropDownItems}}} />} />
}
export const SectionEditor: MetaDataValueEditor = {
	view: Editor,
	displayTextGenerator: (value: string) => value as string,
	defaultValueFactory: () => ""
}