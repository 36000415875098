import {ElasticQuery} from "./elastic/QueryRequest";
import {EntityType} from "../../../interfaces/EntityType";
import {useElasticQuery} from "./elastic/useElasticQuery";
import {useMongoQuery} from "./mongo/useMongoQuery";
import {MongoAdapter} from "./mongo/MongoAdapter";
import {States, TypeAsyncReturn} from "../../useAsync";
import {QueryResponse} from "./QueryResponse";
import {isNumber} from "../../../guards/isNumber";

export type SearcherType = "elastic" | "mongo"
export type SearcherProps = {
	query: ElasticQuery,
	type: SearcherType
}
export function useSearcher<T>(entityType: EntityType): TypeAsyncReturn<SearcherProps, QueryResponse<T>> {
	const elasticSearcher = useElasticQuery<T>(entityType);
	const mongoSearcher = useMongoQuery<T>(entityType);
	const search = ({query, type}: SearcherProps) => {
		if (type === "mongo") {
			const mongoQuery = MongoAdapter.fromElastic(query);
			if (mongoQuery === undefined) {
				throw new Error("Failed to adapt query");
			}
			const page = isNumber((query.search_after ?? ["NaN"])[0]) ? query.search_after![0] as number : 0;
			const sortField = query.sort === undefined ? "name" : Object.keys(query.sort)[0];
			const sortDirection = query.sort === undefined ? "desc" : query.sort[sortField].order;
			return mongoSearcher.execute({query: {body: mongoQuery}, page, pageSize: query.size ?? 25, sort: {field: sortField, direction: sortDirection}});
		}

		return elasticSearcher.execute(query);
	}

	const mapCorrectStatus = (): States => {
		if (elasticSearcher.status === "error" || mongoSearcher.status === "error") {
			return "error";
		}

		if (elasticSearcher.status === "pending" || mongoSearcher.status === "pending") {
			return "pending";
		}

		if (elasticSearcher.status === "success" || mongoSearcher.status === "success") {
			return "success";
		}

		return "idle";
	}

	return {
		execute: search,
		value: mongoSearcher.value ?? elasticSearcher.value,
		error: mongoSearcher.error ?? elasticSearcher.error,
		status: mapCorrectStatus(),
		isLoading: mongoSearcher.isLoading || elasticSearcher.isLoading
	}
}