import {TemplateState} from "../../useTemplateState";
import { useGetExport } from "../../api/useGetExport";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import { useStatusBar } from "../../../../../shared/components";
import {Action} from "../../../../../shared/interfaces/ActionRun";

function saveFile(linkString: string, templateInfo ) {
	const link = document.createElement('a');
	link.href = linkString;
	link.download = `template_${templateInfo.id}_${templateInfo.version}.json`;
	link.click();
}

function useExportAction(): Action {
	const state = TemplateState.useContainer();
	const getExport = useGetExport();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		saveFile(getExport.value?.link!, state.template);
		statusBar.sendSuccessNotification({message: "Template Exported"});
	}, getExport.value);


	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error exporting template", detail: getExport.error});
	}, getExport.error);

	const run = () => getExport.execute(state.template?.id, state.template?.version);

	return {run, isRunning: getExport.isLoading};
}

export {useExportAction};