import {ModuleRoutes} from "../../shared/routing";
import { List } from "./pages/list/List";
import {ModuleDefinition} from "../index";
import { Route } from 'react-router-dom';
import {UserManager} from "../../shared/hooks/useUser";
import {NotFound} from "../../shared/routing/NotFound";

/** SuperUser only, this is the pattern for v6 react-router that's recommended */
const SuperUserOnlyRoute = () => {
	const user = UserManager.useContainer();
	return (user.isSuperUser ? <List /> : <NotFound />);
}

const UserGroupsRouter = () => <ModuleRoutes>
	<Route path={'/'} element={<SuperUserOnlyRoute />} />
</ModuleRoutes>

const UserGroupsModule: ModuleDefinition = {
	path: "/activity/usergroups/*",
	basePath: "/activity/usergroups",
	component: UserGroupsRouter,
	superUserRequired: true,
	name: "User Groups"
}

export type UserGroupsName = "usergroups";
export { UserGroupsModule };