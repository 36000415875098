import { Column, ColumnViewProps } from "../../components/layout/table";
import { TaskNode } from "../../interfaces/task";
import { StatusCell } from "../../components/layout/table/cells/StatusCell";

const Cell = (props: ColumnViewProps) => {
	const item = props.item as TaskNode;
	return <StatusCell column={props.column} id={item.id} state={item.status ?? "N/A"} />
}

const Status: Column = ({
	field: "status",
	title: "Status",
	headerAlign: "left",
	itemAlign: "left",
	width: "15%",
	sortable: true,
	searchable: true,
	views: {
		cell: Cell
	}
})

export { Status };