import {EditableElementProps, ElementPropertyData} from "../../model";
import { InstructionalTextElementValue } from "../../../../../../shared/definitions/elements/instructionalText/model";
import {useState} from "react";
import {useDebounce, useRunWhenValueChange} from "../../../../../../shared/hooks";
import {DebounceDelay} from "../../utilities/editSettings";
import { RichTextEditor } from "../../../../../../shared/components/input/editor";

const EditableView = (props: EditableElementProps) => {
	const value = props.data as InstructionalTextElementValue;
	const [html, setHtml] = useState(value.text);
	const debouncedHtml = useDebounce(html, DebounceDelay);

	useRunWhenValueChange(() => {
		props.onChange({
			...(props as ElementPropertyData),
			data: {...value, text: debouncedHtml}
		})
	}, debouncedHtml);

	const onChange = (event: {target: {value: string}}) => {
		setHtml(event.target.value);
	}

	return <RichTextEditor
		placeholder="Enter instruction text here"
		defaultValue={html}
		onChange={onChange}
		variables={null}
	/>
}

export {EditableView};