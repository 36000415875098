import {useCallback, useEffect, useLayoutEffect, useRef} from "react";

export type UseIntervalAsyncDependencies = {
	setTimeout: (fn: () => void, delay?: number) => NodeJS.Timeout
}
export function useIntervalAsync(callback: () => Promise<void>, delay: number | null, shouldRun: () => boolean, onError?: (error: any) => void, dependencies?: UseIntervalAsyncDependencies) {
	const savedShouldRunCallback = useRef(shouldRun);
	const savedCallback = useRef(callback)
	const timeoutId = useRef<NodeJS.Timeout | null>(null);
	const timeout = dependencies?.setTimeout ?? setTimeout;
	const invokeCallback = useCallback(async () => {
		const timerShouldRun = () => {
			const canRun = savedShouldRunCallback.current ? savedShouldRunCallback.current() : null;
			return (canRun === null || canRun) && savedCallback.current;
		}

		if (timerShouldRun()) {
			try {
				await savedCallback.current();
				if ((delay ?? -1) > -1 && timerShouldRun()) {
					timeoutId.current = timeout(() => {
						invokeCallback()
					}, delay!)
				}
			} catch (e: any) {
				if (onError) {
					onError(e);
				}
			}
		}
	}, [onError, delay, timeout]);

	// Remember the latest callback if it changes.
	useLayoutEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the timeout.
	useEffect(() => {
		// Don't schedule if no delay is specified.
		// Note: 0 is a valid value for delay.
		if (!delay && delay !== 0) {
			return
		}

		timeoutId.current = timeout(() => {
			invokeCallback()
		}, delay)

		return () => {
			if (timeoutId.current) {
				clearTimeout(timeoutId.current);
			}
		}
	}, [delay, invokeCallback, timeout])

}