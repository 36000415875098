import { ReadonlyElementProps } from "../../model";
import { InstructionalTextElementValue } from "../../../../../../shared/definitions/elements/instructionalText/model";
import { EmptyView } from "../../EmptyView";
import { Html } from "../../../text/Html";
import { CommentState } from "../../../../hooks/useCommentState";
import { HighlightWrapper } from "../../../../../../shared/components/input/highlight/HighlightWrapper";
import { CommentModalProps } from "../../../../../../shared/definitions/highlight/CommentModal.props";
import CommentModal from "../../../modals/templateComments/CommentModal";

const ReadonlyView = (props: ReadonlyElementProps) => {
	const value = props.data as InstructionalTextElementValue;

	const commentsManager = CommentState.useContainer();

	const highlightComments = commentsManager.highlightedCommentsByElementId(props.id);

	const renderHighlight = (highlightedText, originalText) => {
		if (originalText.length === 0) {
			return <Html value={highlightedText} />
		}

		const html = value.text!.replace(originalText, highlightedText)
		return <Html value={html} />
	}
	return value.text ? <HighlightWrapper
		elementId={props.id}
		highlightComments={highlightComments}
		value={value.text!}
		enabled={true}
		componentToWrap={renderHighlight}
		renderCommentModal={ (values: CommentModalProps) =>
			<CommentModal
				onClose={values.onClose}
				addEditComment={"Add"}
				showOpen={values.showOpen}
				elementId={values.elementId}
				selectedText={values.selectedText}
				textProperties={values.textProperties}
			/>
		}
	/> : <EmptyView message={"No content text has been added"} />
}

export { ReadonlyView }