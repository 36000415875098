import {ExpandablePane} from "../../../../../../shared/components/layout/pane/ExpandablePane";
import "./styles.scss"
import {EctdModule} from "../../../../../../shared/interfaces/Module";
import {Toc} from "../../../../components/list/toc/Toc";

export type RightPaneProps = {
	activeModules: EctdModule[],
	selectedSection?: EctdModule,
	selectedModule?: EctdModule
}
const RightPane = (props: RightPaneProps) => {
	return <ExpandablePane
		orientation={"right"}
		titleComponent={<span className={"ectd__tbrp--title"}>Build Table of Contents</span>}
		contents={(expanded) => <Toc showTitle={expanded} activeModules={props.activeModules} selectedSection={props.selectedSection} selectedModule={props.selectedModule} />} />
}

export {RightPane}