import {PropertiesTab} from "../tabs/properties";
import {TabDefinition} from "../../../../shared/components/layout/tabs/tabDefinition";
import {TabbedPane} from "../../../../shared/components/layout/pane/TabbedPane";
import { TableOfContentsTab } from "../tabs/toc";

const tabs: TabDefinition[] = [TableOfContentsTab, PropertiesTab];
const EditorLeftPane = () => {
	return <TabbedPane orientation="left" tabs={tabs} />
}

export {EditorLeftPane}
