import React from 'react';

import TextField from '../TextField';

/**
 * Wrapper component for dropdown input.
 *
 * This is a simple wrapper around TextField component with `select={true}`
 *
 * @param {import('@mui/material/TextField').TextFieldProps} props
 * @returns {JSX.Element}
 */
export default function Select(props) {
	return <TextField {...props} select={true} />;
}