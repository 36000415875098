import { Navigate, useParams } from "react-router-dom";
import { moduleLinkGenerator } from "./moduleNavigation";

const moduleRedirects = [
	{ from: "/activity/templateeditor/:id", to: () => moduleLinkGenerator("template", "edit") },
	{ from: "/activity/docselector", to: () => moduleLinkGenerator("content", "list") }
];

export interface ModuleRedirectProps {
	to: string
}
const ModuleRedirect = ({ to }) => {
	const params = useParams();
	const paramsPath = Object.keys(params)
		.map(k => params[k])
		.filter(value => value !== undefined)
		.reduce((path, currentValue) => `${path}/${currentValue}`, "");

	return <Navigate replace to={`${to}${paramsPath}`} />

}
export { ModuleRedirect, moduleRedirects };