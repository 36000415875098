import {ensureArgs} from "../filterUtilities";
import {isArrayWithLength, isEmptyArray} from "../../../guards/isArray";
import {isNumber} from "../../../guards/isNumber";
import {NunjucksFilterFactoryProps} from "../model";

function row(...args: unknown[]): unknown {
	if (!ensureArgs(args) || args.length < 2) {
		return [];
	}

	if (isEmptyArray(args[0]) || typeof(args[0]) !== "object" || !isNumber(args[1])) {
		return [];
	}

	const rowNumber = args[1] as number;

	if (rowNumber < 0) {
		return [];
	}

	if (isArrayWithLength(args[0])) {
		const object = args[0] as any[];

		if (rowNumber >= object.length) {
			return [];
		}

		const keys = Object.keys(object[rowNumber]);
		const values = keys.map((key) => {
			return(object[rowNumber][key]);
		});
		return(values);
	} else {
		const object = args[0] as any;
		const keys = Object.keys(object);
		const result = keys.map((key) => {
			return(object[key][rowNumber]);
		});
		return(result.filter(r => r !== undefined && r !== null));
	}
}

export function rowFilterFactory(props?: NunjucksFilterFactoryProps) {
	return ({
		name: "row",
		help: "Filter that gets all the values from a specific row",
		example: "var | row('rowName')",
		execute: row
	})
}