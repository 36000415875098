import {ColumnDefinition} from "../models";
import {DataTableCell} from "../styles";
import {WorkflowChip} from "../../../labels/WorkflowChip";

export interface StatusCellProps {
	column: ColumnDefinition,
	id: string,
	state: string
}
const StatusCell = (props: StatusCellProps) => {
	const column = props.column;
	return <DataTableCell
		align={column.itemAlign}
		width={column.width}
		key={`${column.title}-${props.id}`}
		// for non-data displaying cells, allow full width of cell
		sx={{...(!column.field && {paddingLeft: 0})}}
	>
		<WorkflowChip state={props.state} label={`${(props.state?.length ?? 0) > 0 ? props.state : "N/A"}`} />
	</DataTableCell>
}

export {StatusCell}