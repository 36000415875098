import { useStatusBar } from "../../../../../shared/components";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import { CommentStates } from "../../../components/modals/documentComments/CommentModal.models";
import {UpdateCommentResponse, useUpdateComment} from "../../api/comments/useUpdateComment";
import {CommentState} from "../../useCommentState";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";

type EditResolve = 'EDIT' | 'RESOLVE';

type RunProps = {
	editResolve: EditResolve,
	documentId: string,
	commentPayload: KaiAlphaCommentEntry
}

function unWrapUpdateResponse(response: UpdateCommentResponse): KaiAlphaCommentEntry | undefined {
	const key = Object.keys(response.elements)[0];
	return response.elements[key].length > 0 ? response.elements[key][0] : undefined
}
function useUpdateCommentAction(): TypedAction<RunProps> {
	const updateComment = useUpdateComment();
	const statusBar = useStatusBar();
	const commentManager = CommentState.useContainer();

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error updating comment", detail: updateComment.error});
	}, updateComment.error);

	useRunWhenValueChange(() => {
		if(updateComment.status === "success") {
			const modifiedComments = commentManager.comments;
			const updatedComment = unWrapUpdateResponse(updateComment.value);
			if(modifiedComments !== null && updatedComment) {

				const commentIndex: number = modifiedComments.findIndex((value) => value.id === updatedComment.id.split('@')[0]);
				if(commentIndex !== -1) {
					modifiedComments[commentIndex]!.comment = updatedComment.comment;
					modifiedComments[commentIndex]!.tag = updatedComment.tag;
					modifiedComments[commentIndex]!.state = updatedComment.state;
					modifiedComments[commentIndex]!.lastupdated = updatedComment.lastupdated
					commentManager.setComments(modifiedComments);
				}
			}

			statusBar.sendSuccessNotification({message: "Comment updated"});
		}
	}, updateComment.value);

	const run = ({editResolve, commentPayload, documentId}: RunProps) => {
		if(editResolve === 'RESOLVE') {
			commentPayload.state = CommentStates.RESOLVED;
			updateComment.execute(documentId, commentPayload);
		} else if (editResolve === 'EDIT') {
			updateComment.execute(documentId, commentPayload);
		}
	}

	return {run, isRunning: updateComment.isLoading};
}

export {useUpdateCommentAction};