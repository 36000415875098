import { PreviewElementProps } from "../../model";
import "./style.scss";
import { HtmlElementValue } from "../../../../../../shared/definitions/elements/html/model";
import {useAsync, useRunWhenValueChange} from "../../../../../../shared/hooks";
import { useState } from "react";
import { mapVariables } from "../../utilities/mapVariables";
import { mapReferenceElements } from "../../utilities/mapReferenceElements";
import { Html } from "../../../text/Html";
import {useTemplateStore} from "../../../../hooks/useTemplateStore";
import {renderTemplatedString} from "../../../../../../shared/nunjucks/renderTemplatedString";
import {useGetList} from "../../../../../../shared/hooks/api/useGetList";
import {useStatusBar} from "../../../../../../shared/components";
import { CommentState } from "../../../../hooks/useCommentState";
import { HighlightWrapper } from "../../../../../../shared/components/input/highlight/HighlightWrapper";
import { CommentModalProps } from "../../../../../../shared/definitions/highlight/CommentModal.props";
import CommentModal from "../../../modals/documentComments/CommentModal";
import {isSystemVariable} from "../../../../../../shared/variables/SystemNamespaces";
import {isInReview} from "../../../../../../shared/interfaces/WorkflowState";

const PreviewView = (props: PreviewElementProps) => {
	const value = props.element.data as HtmlElementValue;
	const [html, setHtml] = useState(value.text);
	const [nunjucksHtml, setNunjucksHtml] = useState("");
	const templateStore = useTemplateStore();
	const mappedVariablesAsync = useAsync(mapVariables, false);
	const getAbbreviationList = useGetList("abbreviation");
	const statusBar = useStatusBar();

	const commentsManager = CommentState.useContainer();
	const highlightComments = commentsManager.highlightedCommentsByElementId(props.element.id);
	useRunWhenValueChange(() => {
		const filteredVariables = props.variables?.filter((variable) => variable !== null && (variable.templateId === props.element.source || isSystemVariable(variable))) ?? [];
		if (filteredVariables.length > 0) {
			mappedVariablesAsync.execute(filteredVariables, props.body, templateStore.get);
		} else {
			mapReferences();
		}

	}, [props.element.data, props.variables]);

	useRunWhenValueChange(() => {
		if (mappedVariablesAsync.status === "success" || mappedVariablesAsync.status === "error") {
			mapReferences();
		}
	}, mappedVariablesAsync.status)

	const mapReferences = () => {
		const mappedReferences = mapReferenceElements(props.body);
		const nunjucksVariables = {...(mappedVariablesAsync.value ?? {}), ...(mappedReferences ?? {})};
		setNunjucksHtml(renderTemplatedString(value.text, nunjucksVariables));
	}

	// useRunWhenValueChange(() => {
	// 	setHtml(nunjucksHtml);
	// }, nunjucksHtml)

	// we have to run the async version after the sync version due to the way nunjucks handles async filters.
	// TODO: pass in abbreviations to the filter and then we don't have to worry about async filters
	useRunWhenValueChange(() => {
		if (nunjucksHtml) {
			//renderTemplateAsync.execute("{{__html | __abbreviations}}", {__html: nunjucksHtml});
			getAbbreviationList.execute(undefined);
		}
	}, nunjucksHtml);

	useRunWhenValueChange(() => {
		if (getAbbreviationList.value) {
			const abbreviationValue = JSON.stringify(getAbbreviationList.value.entries.map(entry => ({key: entry.key.trim(), value: entry.value.trim()})));
			try {
				setHtml(renderTemplatedString(`{{__html | __abbreviations("${encodeURI(abbreviationValue)}")}}`, {__html: nunjucksHtml}));
			} catch(e: any) {
				setHtml(nunjucksHtml);
				statusBar.sendErrorNotification({message: "Abbreviation list is corrupted", detail: e})
			}
		} else {
			setHtml(nunjucksHtml);
		}
	}, getAbbreviationList.status)

	const renderHighlight = (highlightedText, originalText) => {
		if (originalText.length === 0) {
			return <Html value={highlightedText} />
		}

		const newHtml = html.replace(originalText, highlightedText)
		return <Html value={newHtml} />
	}

	return <HighlightWrapper
		elementId={props.element.id}
		highlightComments={highlightComments}
		enabled={isInReview(props.state)}
		className={"tm__html-preview"}
		value={html}
		componentToWrap={renderHighlight}
		renderCommentModal={ (values: CommentModalProps) =>
			<CommentModal
				onClose={values.onClose}
				addEditComment={"Add"}
				showOpen={values.showOpen}
				elementId={values.elementId}
				selectedText={values.selectedText}
				textProperties={values.textProperties}
				documentId={commentsManager.documentId}
			/>
		}
		htmlText={nunjucksHtml}
	/>
}
export { PreviewView };