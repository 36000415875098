import {Route} from "react-router-dom"
import {ModuleComponentProps, ModuleDefinition} from "../"
import { ModuleRoutes } from "../../shared/routing"
import {ContextWrappedEditor} from "./pages/ContextWrappedEditor"
import { List } from "./pages/List";
import {Tasks} from "./pages/Tasks";
import {ApplicationAdminRoleLookup} from "../../shared/interfaces/ApplicationAdminRoles";
const TemplateRouter = (props: ModuleComponentProps) => {
	return <ModuleRoutes>
		<Route path={"/"} element={<List />} />
		{props.user.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_templates) && <Route path={"/edit"} element={<ContextWrappedEditor />} />}
		<Route path={"/edit/:id"} element={<ContextWrappedEditor />} />
		<Route path={"/edit/:id/:version"} element={<ContextWrappedEditor />} />
		<Route path={"/tasks/:id"} element={<Tasks />} />
	</ModuleRoutes>
}

const TemplateModule: ModuleDefinition = {
	path: "/activity/templates/*",
	basePath: "/activity/templates",
	component: TemplateRouter,
	name: "Templates",
}

export type TemplateName = "template";

export {TemplateModule};