import {TabbedPane} from "../../../../../../shared/components/layout/pane/TabbedPane";
import {TabDefinition} from "../../../../../../shared/components/layout/tabs/tabDefinition";
import {PropertiesTab} from "../../tabs/properties/PropertiesTab";
import {ContentsTab} from "../../tabs/content/ContentsTab";

const tabs: TabDefinition[] = [ContentsTab, PropertiesTab];

const RightPane = () => {
	return <TabbedPane orientation={"right"} tabs={tabs} />
}

export {RightPane}