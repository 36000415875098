import { FlexBox, Button, AsyncComponent } from "../../../../../shared/components";
import { TabDefinition } from "../../../../../shared/components/layout/tabs/tabDefinition";
import { AddComment, Person, Refresh } from "@mui/icons-material";
import { useMemo, useState } from "react";
import CommentModal from "../../modals/templateComments/CommentModal";
import { CommentState } from "../../../hooks/useCommentState";
import { TabButtonsContainer } from "./Comments.style";
import './style.scss';
import { CommentsIndividual } from "./CommentsIndividual";
import { SearchBar } from "../../../../../shared/components/input/search/SearchBar";
import { Tooltip } from "../../../../../shared/components/labels/Tooltip";
import {useGetAllCommentsAction} from "../../../hooks/actions/load/useGetAllCommentsAction";
import { FilterTabs } from "../../../../../shared/components/layout/filterTabs/FilterTabs";

/*
1. highlighting (user should be able to highlight a section and then have a popup shown asking if they want to create a comment)
2. tagging users (I figured out how to get the owners ID, but I need help identifying how to lookup users to get the actual ID instead of just the UUID)
*/

type CommentType = "comments" | "openComments" | "resolvedComments";
type CommentTab = {
	displayName: string,
	totalFunction: string
}
type CommentTabMap = (CommentTab & { type: CommentType })[]
const commentTabMap: CommentTabMap = [
	{
		type: "comments",
		displayName: "All Comments",
		totalFunction: "getTotalCommentsLength"
	},
	{
		type: "openComments",
		displayName: "Open",
		totalFunction: "getOpenCommentsLength"
	},
	{
		type: "resolvedComments",
		displayName: "Resolved",
		totalFunction: "getResolvedCommentsLength"
	},
]

const TabView = () => {

	const commentsManager = CommentState.useContainer();
	const getAllCommentsAction = useGetAllCommentsAction()
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const [searchQuery, setSearchQuery] = useState("");
	const [showModal, setShowModal] = useState(false);

	const handleReply = () => {
		setShowModal(true);
	}

	const onSearchValueChange = (value?: string) => {
		setSearchQuery(value ?? "");
	}

	const filterComments = useMemo(() =>
		commentsManager[commentTabMap[selectedTab].type]?.filter(value => value?.comment?.includes(searchQuery)) ?? [], [
		searchQuery,
		selectedTab,
		commentsManager
	]);

	const handleCommentModalClose = () => {
		setShowModal(false);
	}

	const onRefreshClick = () => {
		if (commentsManager.templateId) {
			getAllCommentsAction.run({id: commentsManager.templateId, version: commentsManager.versionId});
		}
	}

	return (
		<article className="comments-tab">
			<FlexBox align="center" justify="center" flex={0}>
				<SearchBar placeholder="Search" onChange={onSearchValueChange} containerStyle={{ margin: "0 1rem", flex: 1, paddingTop: "5px" }} />
				{!commentsManager.disableComments && <TabButtonsContainer>
					<Tooltip title={"Refresh comments"} placement={"top"}>
						<Button buttonType={"plain"} styling="inverted" icon={<Refresh fontSize="small"/>} onClick={onRefreshClick} />
					</Tooltip>
				</TabButtonsContainer>}
				<TabButtonsContainer>
					<Tooltip title={"Add a comment"} placement={"top"}>
						<Button buttonType={commentsManager.disableComments ? "disabled" : "plain"} disabled={commentsManager.disableComments} styling="inverted" icon={<AddComment fontSize="small" />} onClick={() => setShowModal(true)} />
					</Tooltip>
				</TabButtonsContainer>
				<TabButtonsContainer>
					<Button buttonType="plain" icon={<Person fontSize="small" />} onClick={() => { }} />
				</TabButtonsContainer>
			</FlexBox>
			<AsyncComponent
				isLoading={getAllCommentsAction.isRunning}
				loadingMessage={"Refreshing Comments"}
				component={
					<>
						<FlexBox boxSizing="border-box" paddingY="15px" minWidth="25rem" flex={0}>
							<FilterTabs
								selectedTabIndex={selectedTab}
								tabs={commentTabMap.map((tab) => ({
									...tab,
									total: commentsManager[tab.totalFunction](),
								}))}
								onTabSelected={setSelectedTab}
							/>
						</FlexBox>
						<FlexBox flex={1} direction="column" justifyContent="space-between" overflow="hidden">
							<article className={"comments-tab__selected-view"}>
								{filterComments.map((value: KaiAlphaCommentEntry) => {
									return <CommentsIndividual key={value.id} comment={value} readonly={commentsManager.disableComments || value.author !== commentsManager.getCurrentUserId()} templateId={commentsManager.templateId} />;
								})}
							</article>
						</FlexBox>
						{filterComments.length > 0 && !commentsManager.disableComments && !getAllCommentsAction.isRunning &&
							<div className="comments-tab__reply">
								<button onClick={handleReply}>Reply</button>
							</div>
						}
					</>}
			/>
			{showModal &&
				<CommentModal
					onClose={handleCommentModalClose}
					addEditComment={"Add"}
					showOpen={showModal}
				/>
			}
		</article>
	);
}


const CommentsTab: TabDefinition = {
	name: "Comments",
	content: TabView
}

export { CommentsTab };