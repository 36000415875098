import {Avatar, styled} from '@mui/material';
import {UserManager, useUserLogout} from "../../../hooks/useUser";
import {useState} from "react";
import {Icon} from "../../../../lib/ui";
import {useNavigate} from "react-router-dom";
import {useRunWhenValueChange} from "../../../hooks";

const Container = styled("div")(({theme}) => ({
	display: 'flex',
	borderRadius: "93px",
	background: `${theme.palette.secondaryMenu?.main} 0% 0% no-repeat padding-box`,
	boxShadow: theme.shadows[1],
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '.5em',
	gap: '0.5em',
	boxSizing: 'border-box',
	zIndex: 2,
	marginLeft: "1rem",
	"& .avatar": {
		cursor: 'pointer',
		width: 35,
		height: 35
	},
	"& .name": {
		marginLeft: 5,
		color: theme.palette.secondaryMenu?.contrastText,
		cursor: 'pointer',
		maxWidth: 200,
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	"& .logout": {
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: '#fff',
		marginRight: 5
	}
}));

export const IdentityBar = () => {
	const [expanded, setExpanded] = useState(false);
	const navigate = useNavigate();
	const user = UserManager.useContainer();
	const { display_name } = user;

	const logUserOut = useUserLogout();

	useRunWhenValueChange(() => {
		if (logUserOut.value) {
			window.location.href = logUserOut.value.endpoint;
		}
	}, logUserOut.value)
	const openProfile = (e) => {
		e.stopPropagation();
		setExpanded(false);
		navigate('/activity/profile');
	}

	const onLogout = () => logUserOut.execute(undefined);

	return(
		<Container onClick={() => setExpanded(!expanded)} onBlur={() => setExpanded(false)}>
			<Avatar variant="circular" className={"avatar"} />
			{expanded && (<><div onClick={openProfile} title={display_name} className={"name"}>{display_name}</div>
				<div className={"logout"} onClick={onLogout}>
					<Icon forAction='logout'/>
				</div></>)}
		</Container>
	);
}