import {User} from "../../../interfaces/User";

export const aclEditorRoles = ['owners', 'authors', 'readers', 'approvers', 'reviewers'] as const;

export type AclEditorRoleTypes = typeof aclEditorRoles[number];

export type AclEditorContent = Record<AclEditorRoleTypes, User[]>;

export enum ACLRoles {
	reviewers = 'reviewers',
	approvers = 'approvers',
	authors = 'authors',
	readers = 'readers',
	owners = 'owners'
}

export function getAclEditorRoles(aclType: string): AclEditorRoleTypes {
	return ACLRoles[aclType];
}