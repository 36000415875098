import MuiTextField from '@mui/material/TextField';
import React from 'react';
import { withStyles } from '@mui/styles'; // LEGACY

function styles(theme) {
	return({
		input: {
			'&:not(:focus)': {
				fontWeight: theme.typography.fontWeightBold,
			}
		}
	});
}

/**
 * Wrapper component for text input.
 */
class TextField extends React.Component {

	getPlaceholder() {
		const { placeholder, label } = this.props;
		if (placeholder) {
			return placeholder;
		} else if (label) {
			return label;
		}
		return '';
	}

	showFullWidth() {
		const { fullWidth } = this.props;
		if (fullWidth !== undefined) {
			return fullWidth;
		}
		// show full width by default
		return true;
	}

	render() {
		const {
			classes,
			InputLabelProps,
			InputProps,
			...others
		} = this.props;

		return(<MuiTextField
			{...others}
			classes={{
				root: classes.root,
			}}
			placeholder={this.getPlaceholder()}
			variant="outlined"
			fullWidth={this.showFullWidth()}
			InputLabelProps={{
				...InputLabelProps,
				shrink: true,
			}}
			InputProps={{
				...InputProps,
				classes: {
					input: classes.input,
				}
			}}
		/>);
	}
}

export default withStyles(styles)(TextField);
