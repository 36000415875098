import {EditableElementProps, ElementPropertyData} from "../../model";
import {TextField} from "../../../../../../shared/components/input";
import React, {useState} from "react";
import { HeaderElementValue } from "../../../../../../shared/definitions/elements/header/model";
import {useDebounce, useRunWhenValueChange} from "../../../../../../shared/hooks";
import {DebounceDelay} from "../../utilities/editSettings";

const EditableView = (props: EditableElementProps) => {
	const editableValue = props.data as HeaderElementValue;
	const [header, setHeader] = useState(editableValue.value);
	const debouncedHeader = useDebounce(header, DebounceDelay);

	useRunWhenValueChange(() => {
		props.onChange({
			...(props as ElementPropertyData),
			data: {...editableValue, value: debouncedHeader}
		})
	}, debouncedHeader);

	const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setHeader(event.target.value);
	}

	return <TextField placeholder={"Enter value here"} value={header} onChange={onChange} />
}

export {EditableView}