import { FilterTaskWorkflowResponse } from "../../modules/task/models/workflow";
import { filterButtonsSystemUser } from "./filterButtonsSystemUser";

const defaultWorkflowStatus = 'waiting';

const filterTaskWorkflowSystemUser = (
	slotName: string,
	state: KaiAlphaDocumentWorkflow | any,
	permissions: KaiAlphaDocumentPermissions
): FilterTaskWorkflowResponse => {
	if ((!state || !state.status) || (state.status.toString() !== defaultWorkflowStatus) || (!state.ui)) {
		return {
			state,
			ui: {},
			slotName: slotName
		};
	}

	const response = {
		state,
		ui: {},
		slotName
	};


	for (const part in state.ui) {
		if(part === 'buttons') {
			response.ui[part]  = filterButtonsSystemUser(state.ui[part], permissions);
		} else {
			response.ui[part]  = state.ui[part];
		}
	}

	return response;
}

export {filterTaskWorkflowSystemUser}