export interface User {
	display_name?: string,
	name: string,
	id: string,
	groups: string[],
	isSuperUser?: false,
}

export const allUsers: User = {
	id: '@all',
	display_name: 'All Users',
	name: '@all',
	groups: [],
	isSuperUser: false,
}