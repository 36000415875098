import {alpha, styled} from "@mui/material";
import { FlexBox } from "../../../../../shared/components";
import { TemplateElement } from "../../../../../shared/interfaces/TemplateElement";

// child determines the color of the text
const ElementDetailContainer = styled(FlexBox, {shouldForwardProp: (prop) => prop !== "isChild"})<{isChild: boolean}>(({theme, isChild}) => ({
	color: isChild ? theme.palette.variableHighlight?.static : theme.palette.table?.contrastText,
	boxSizing: "border-box",
	padding: "0.4rem 0.2rem",
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}))


// child determines the color of the icon background
const ElementIconContainer = styled("div", {shouldForwardProp: (prop) => prop !== "isChild"})<{isChild: boolean}>(({theme, isChild}) => ({
	borderRadius: "3px",
	background: isChild ? theme.palette.variableHighlight?.static : theme.palette.table?.contrastText,
	display: "flex",
	alignItems: "center",
	marginRight: ".5rem",
	justifyContent: "center",
	padding: "5px",
	color: "white",
	"& svg": {
		width: "10px",
		height: "10px"
	},
}))

const VariableTitle = styled("p")({
	background: "#E7EBF7",
	color: "#0052CC",
	borderRadius: "4px",
	maxWidth: "14rem",
	overflow: 'hidden',
	textOverflow:'ellipsis',
	fontSize: "1rem",
	padding: "6px",
	margin: "-0.35rem 0 0 0"
})

const ElementTitle = styled("p")({
	fontSize: "1rem",
	fontWeight: 600,
	maxWidth: "12.5rem",
	overflow: 'hidden',
	textOverflow:'ellipsis',
	whiteSpace: 'nowrap',
	padding: 0,
	margin: "0"
})

const ElementDescription = styled("span")({
	fontSize: "0.75rem",
	maxWidth: "12.5rem",
	overflow: 'hidden',
	textOverflow:'ellipsis',
	whiteSpace: 'nowrap',
	fontWeight: 500,
	padding: 0,
	margin: "0.3rem 0 0 0"
})

const getElementStyle = (element: TemplateElement, expanded: boolean) => {
	const paddingLeftValue = 0.5 * element.depth;
	const padding = `0 0 0 ${(paddingLeftValue > 6.25 ? 6.25 : paddingLeftValue)}rem`
	let style : {[k: string]: any} = {
		border: `1px solid #dce1f1`,
	}

	if (element.hasChild) {
		if (expanded) {
			style = {
				...style,
				margin: element.isChild ? 0 : "10px 0 0 0",
				// supports 6 levels of depth (hopefully thats enough 8'-( )
				backgroundColor: alpha("#dce1f1", 1 - (.18 * element.depth)),
				borderRadius: "2px 2px 0 0",
				padding
			}
		} else {
			style = {
				...style,
				margin: element.isChild ? 0 : element.parentId ?? false ? "0 0 10px 0" : "10px 0 10px 0",
				borderRadius: "0 0 2px 2px",
				padding
			}
		}
		return style;
	}

	if (element.isChild) {
		style = {
			...style,
			margin: 0,
			padding
		}
		if (element.lastChild && element.depth === 0) {
			style = {
				...style,
				margin: "0 0 10px 0",
				borderRadius: "0 0 2px 2px",
				padding
			}
		}
	}
	return style;
}


export {
	ElementDescription,
	ElementDetailContainer,
	ElementIconContainer,
	ElementTitle,
	VariableTitle,
	getElementStyle
}