import { verifyPermissionsExpandUserIdList } from "./verifyPermissionsExpandUserIdList";

const canonicalizePermissions = (
	permissions: KaiAlphaDocumentPermissions,
) => {
	if (!permissions) {
		return(permissions);
	}

	// canonicalize owners as well incase owners also include user groups
	permissions.owners = verifyPermissionsExpandUserIdList(permissions.owners, permissions);

	// acl will be undefined no users are set in it.
	permissions.acl = permissions.acl || {};
	const acl = permissions.acl
	const aclTypes = Object.keys(acl);
	for (const aclType of aclTypes) {
		acl[aclType] = verifyPermissionsExpandUserIdList(acl[aclType], permissions);
	}

	delete permissions['roles'];
	delete permissions['inherit_from'];

	return(permissions);
}

export {canonicalizePermissions}