import {SubmissionExtractionStatus} from "./models/Submission";

export type SubmissionListAction = "view" | "delete" | "download" | "viewError";
export type SubmissionListActionLookup = Record<SubmissionListAction, SubmissionListAction>;

export type ExtractionStatus = {
	label: SubmissionExtractionStatus,
	action: string
}
export type SubmissionNode = {
	name: string,
	compound: string,
	indications: string[],
	completionStatus: string,
	extractionStatus?: ExtractionStatus,
	extractionError?: string,
	id: string
}

export const submissionNodeActions: SubmissionListActionLookup = {
	view: "view",
	delete: "delete",
	download: "download",
	viewError: "viewError"
}