
import { TemplateState } from "../../../../hooks";
import { MetadataGroup, UploadAction, MetaDataChangeEvent } from "../../../../../../shared/components/layout/groups/metadata";

/**
 * This component edits/displays/searches the *user* metadata associated embedded in the template (template.metadata.user).
 * @constructor
 */
const Metadata = () => {
	const templateState = TemplateState.useContainer();

	const setUserMetadata = (metadata: {[k: string] : string}) => {
		templateState.setBuffer(s => ({
			...s,
			metadata: {
				user: metadata,
				system: s.metadata?.system ?? {},
			}
		}));
	}

	const updateMetadata = (event: MetaDataChangeEvent) => {
		const { [(event.previousMetadata?.key ?? event.metadata.key)]: _, ...items } = templateState.buffer!.metadata!.user ?? {};
		setUserMetadata({
			...items,
			[event.metadata.key]: event.metadata.value,
		})
	}

	const deleteMetadata = (id: string) => {
		const { [id]: _, ...items } = templateState.buffer!.metadata!.user ?? {};
		setUserMetadata(items);
	}

	const onMetadataUploaded = (action: UploadAction, data) => {
		setUserMetadata(action === 'replace'
			? data
			: {
				...templateState.buffer!.metadata?.user,
				...data,
			});
	}

	return <MetadataGroup
		metadata={templateState.buffer?.metadata?.user}
		editable={templateState.editable}
		emptyMessage={"No metadata in this template"}
		onUpdateMetadata={updateMetadata}
		onDeleteMetadata={deleteMetadata}
		onUploadedMetadata={onMetadataUploaded}
	/>
}

export { Metadata }