import {TableElementFootnote} from "../../definitions/elements/table/model";
import {Variable} from "../../interfaces/Variable";
import {DatasourceContents} from "../../definitions/elements/variable/model";
import {mapFootnotes} from "../../definitions/elements/table/mapFootnotes";
import {ConsumableDataSource, TableDataType} from "../../interfaces/DataSource";
import {ExpressionValueObject} from "../../interfaces/Expression";
import {executeExpression} from "./executeExpression";
import {getDataFromDataSourceType} from "./getDataFromDataSourceType";

function getConsumableDataSources(dataSourceName: string, footnotes: TableElementFootnote[], variables?: Variable[]): ConsumableDataSource[] {
	const dataSourceVariable = variables?.find(variable => variable.name === dataSourceName);
	if (!dataSourceVariable) {
		const data: TableDataType = {columns: [], rows: []};
		return [{data, "@metadata": {ordered_headers: []}, type: "auto"}] as ConsumableDataSource[]
	}

	let mappedSources: DatasourceContents[] = [];

	if(dataSourceVariable.type === "expression") {
		const expressionOptions = dataSourceVariable.options as ExpressionValueObject;
		const expressionResult = executeExpression(`${expressionOptions.expression} | stringify`, variables);
		mappedSources = [JSON.parse(expressionResult.result)];
	} else if(dataSourceVariable.dataSources !== undefined || dataSourceVariable.value !== undefined) {
		mappedSources = dataSourceVariable.dataSources !== undefined ? dataSourceVariable.dataSources!.map(datasource => datasource!.data as DatasourceContents)
			: [(dataSourceVariable.value as DatasourceContents)]
	}

	// convert our datasources to ConsumableDataSources
	return mappedSources?.filter(source => source !== undefined).map(source => ({
		...source,
		data: getDataFromDataSourceType(source, source?.type),
		footNotes: mapFootnotes(source, footnotes, variables)
	})) as ConsumableDataSource[];
}


export {getConsumableDataSources}