import {Variable} from "../../../../../../../../shared/interfaces/Variable";
import {VariableMapper} from "./model";

// check this out for images
// https://placeholder.pics/
// original source copied from
// https://placeholder.pics/svg/100x100/DEDEDE/555555/image
function mapper(variable: Variable) {
	return `<svg xmlns="http://www.w3.org/2000/svg" style="width: 100%; height: 400px"><rect x="2" y="2" width="96" height="96" style="fill:#dedede;stroke-width:2;width: 100%; height: 400px"/><text x="50%" y="50%" font-size="18" text-anchor="middle" alignment-baseline="middle" font-family="monospace, sans-serif" fill="#555555">${variable.name}</text></svg>`;
}

const ImageMapper: VariableMapper = {
	type: "image",
	mapper
}

export {ImageMapper};