import {paletteForWorkflowState} from "../../interfaces/WorkflowState";
import {Box, styled} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";


export interface StatusLabelProps {
	status?: string,
	sx?: SxProps<Theme>
}

export interface StatusStyleProps {
	status: string
}

const LabelContainer = styled(Box)<StatusStyleProps>(({theme, status}) => ({
	display: 'flex',
	alignItems:'center',
	gap: '0.75rem',
	paddingLeft: '1rem',
	paddingRight: '0.5rem',
	boxShadow: theme.shadows[2],
	backgroundColor: theme.palette.background.level1,
	width: '10rem',
	height: '2.5rem',
	borderRadius: '1.25rem',

	"& .color-indicator": {
		borderRadius: '50%',
		width: '1rem',
		height: '1rem',
		backgroundColor: paletteForWorkflowState(status, theme)?.main
	},
	"& .label": {
		display: 'flex',
		gap: '0.25rem',
		fontSize:'0.875rem', // Close to 14px
		color: theme.palette.text.secondary,
	},

	"& .status": {
		fontWeight: theme.typography.fontWeightBold
	}
}));

const StatusLabel = ({status, sx}: StatusLabelProps) => status ? (
	<LabelContainer status={status!} sx={sx}>
		<Box className={"color-indicator"} />
		<Box className={"label"}>
			Status:
			<Box className={"status"}>{status}</Box>
		</Box>

	</LabelContainer>
) : null;

export {StatusLabel};