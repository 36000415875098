import {GroupedTab} from "../../../../../shared/components/layout/tabs/grouped/models";
import {CommentViewMode} from "../../../models/comment";


export const CommentViewModeTitleMap: Record<CommentViewMode, string> = {
	section: "Section",
	all: "Overall Content"
}

export const CommentViewTitleModeMap: Record<string, CommentViewMode> = {
	"Section": "section",
	"Overall Content": "all"
}

export const CommentViewModeGroupTabs: GroupedTab[] = [
	{ title: CommentViewModeTitleMap.section },
	{ title: CommentViewModeTitleMap.all }
]