import { BaseElement } from "../../../interfaces/BaseElement";

const referenceDefinition: BaseElement = {
	name: 'Template',
	type: 'reference',
	displayName: 'Reference',
	allowsChildren: false,
	description: 'Place Sub-Templates',
	longDescription: 'Template elements may be used to include another template at the position where this element is placed',
	contentDescription: () => "",
	toString: (contents) => {
		return (contents as ReferenceElementValue).name ?? "Untitled Reference";
	},
} as const

// element that is being referred to by the reference element
export type ReferenceElementContents = {
	element_id: string,
	template_id?: string,
	template_version?: string,
	format?: string,
	type?: string,
	name: string
}

export function isReferenceElementContents(obj: unknown): obj is ReferenceElementContents {
	const item = obj as any;
	return item !== undefined && item["element_id"] !== undefined && item["type"] !== undefined && item["format"] !== undefined;
}


export type ReferenceVariableContents = {value?: string} & ReferenceElementContents

// base reference element structure
export type ReferenceType<T> = {
	name: string,
	value: T
 }

// reference element itself (for data storage, referring to element)
export type ReferenceElementValue = ReferenceType<ReferenceElementContents>

// reference 'variable' (technially element too, since reference elements are rendered in rich text editors as a variable)
// including two extra properties that designate the value of the referred to element
// used to preview reference elements and variables
export type ReferenceVariableValue = ReferenceType<ReferenceVariableContents>

export {referenceDefinition};