import { TextField as MuiTextField, TextFieldProps } from "@mui/material/";

const TextField = (props: TextFieldProps) => <MuiTextField
	{...props}
	fullWidth
	InputProps={{
		...props.InputProps,
		style: { fontSize: ".825rem", ...(props.InputProps?.style ?? {})}
	}}
	InputLabelProps={{
		...props.InputLabelProps,
		style: { fontSize: ".825rem" }
	}}
/>

export {TextField};