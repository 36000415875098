const getUserPendingTaskStatus = (userId: string, variables: {}) => {
	let userTracking = [];
	if(variables['tracking'] !== undefined) {
		userTracking = variables['tracking'][userId] || [];
	}
	const status = userTracking.length > 0 ? 'In Process' : 'Open';

	return status;
}

export {getUserPendingTaskStatus}