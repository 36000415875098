import {EditableElementProps} from "../../model";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import {useRunOnce, useRunWhenValueChange} from "../../../../../../shared/hooks";
import {ElementPropertyData} from "../../model";
import { useGetTableNames } from "../../../../hooks/api/useGetTableNames";
import { InTextTableElementValue } from "../../../../../../shared/definitions/elements/inTextTable/model";
import {AsyncComponent, useStatusBar} from "../../../../../../shared/components";
import {DropDown, DropDownItem} from "../../../../../../shared/components/input/dropdown";

const EditableView = (props: EditableElementProps) => {
	const value = props.data as InTextTableElementValue;
	const tableNamesApi = useGetTableNames();
	const statusBar = useStatusBar();

	useRunOnce(() => tableNamesApi.execute());
	useRunWhenValueChange(() => {
		if (tableNamesApi.error) {
			statusBar.sendErrorNotification({message: "Error loading table names", detail: tableNamesApi.error});
		}
	}, tableNamesApi.error)

	const onChange = (item: DropDownItem<string>) => {
		props.onChange({
			...(props as ElementPropertyData),
			data: {...value, tableName: item.value}
		})
	}

	return (
		<Box sx={{ minWidth: 120, paddingTop: "1.25rem" }}>
			<FormControl fullWidth>
				<AsyncComponent
					isLoading={tableNamesApi.isLoading}
					component={<DropDown
						label={tableNamesApi.isLoading ? "" : "Table Name"}
						items={tableNamesApi.value?.map(item => ({title: item.label, value: item.value})) ?? []}
						onSelect={onChange}
						value={value.tableName} /> }/>
			</FormControl>
		</Box>
	)
}

export {EditableView}