import { TreeNode } from "./TreeList.models";
import {findElementDefinitionForType} from "../../../definitions/elements";
import {TemplateElement} from "../../../interfaces/TemplateElement";
import {ReferenceNodeData} from "../../../interfaces/ReferenceNodeData";
import { parseTemplateElements } from "../../../utilities/parseTemplateElements";

// when sub-templates are loaded, parse and turn them into reference nodes.
export function mapTemplateElementsToTreeNode(template: KaiAlphaTemplate): TreeNode[] {
	// since this is a raw template response, it needs to be parsed.
	const elements = parseTemplateElements(template);
	return mapTemplateElementToTreeNode(
		elements.filter((element => element.type !== 'reference' && element.type !== 'variable')),
			{template_id: template?.id, template_version: template?.version} as ReferenceNodeData,
	);
}

export function mapTemplateElementToTreeNode(elements: TemplateElement[], templateData: ReferenceNodeData): TreeNode[] {
	return elements.map(item => ({
		id: item.id,
		name: `${findElementDefinitionForType(item.type)?.toString(item.contents) ?? 'Unknown Element'}`,
		// the actual template id associated with this element (if its a template, it is itself)
		// templates themselves cannot be referenced.
		data: {template_id: item.contents.id ?? templateData.template_id, template_version: item.contents.version ?? templateData.template_version} as ReferenceNodeData,
		type: item.type,
		children: []
	}));
}