import { styled, Box } from "@mui/material";
import { Search } from "@mui/icons-material";

export interface SearchBarContainerProps {
	dense: boolean,
}

const SearchBarContainer = styled(Box, {shouldForwardProp: (prop) => prop !== "depth"})<SearchBarContainerProps>(({dense, theme}) => ({
	width: '100%',
	maxWidth: dense ? '10rem' : '',
	margin: "5px 0px",
	'& .MuiInputBase-root': {
		fontSize: dense ? "0.8rem" : '14px',
	},
	'& .MuiInput-underline': {
		'&:before': {
			borderBottom: `1px solid ${dense ? theme?.palette?.tabs?.border : theme?.palette?.variable?.background}`,
		},
		'&:after': {
			borderBottom: `1px solid ${theme?.palette?.variable?.background}`,
		},
		'&:hover > fieldset': {
			borderBottom: `1px solid ${theme?.palette?.variable?.background}`,
		},
	},
}));

const SearchIcon = styled(Search)(({theme}) => ({
	width: '100%',
	color: theme?.palette?.tabs?.hover,
	fontSize: '18px'
}));

export {
	SearchBarContainer,
	SearchIcon,
}