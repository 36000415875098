import {useAsync, useRunWhenValueChange} from "../../../../../shared/hooks";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";
import {VariableElement} from "../../../models/elements";
import {Variable} from "../../../../../shared/interfaces/Variable";
import {TemplateState} from "../../useTemplateState";
import {variableDefinition} from "../../../../../shared/definitions/elements/variable/model";
import {loadAllVariablesFromTemplates} from "./loadAllVariablesFromTemplates";
import { TemplateElement } from "../../../../../shared/interfaces/TemplateElement";

type RunProps = {
	body: TemplateElement[] | null
}

function useLoadAllVariablesAction(): TypedAction<RunProps> {
	const state = TemplateState.useContainer();
	const loadNestedElements = useAsync(loadAllVariablesFromTemplates, false);
	const run = ({body}: RunProps) => {
		const elements = body ?? [];
		loadNestedElements.execute(elements, elements.filter(element => element.type === variableDefinition.type)
			.map(element => {
				const variable = element as VariableElement;
				return {
					id: variable.id,
					namespace: "",
					...variable.contents,
					description: variable.contents.description
				} as Variable;
			}));
	}

	useRunWhenValueChange(() => {
		if (loadNestedElements.value) {
			state.setVariables(loadNestedElements.value.map(variable => {
				return ({
					name: variable.name,
					template: variable.namespace,
					type: "variable",
					index: 0,
					depth: 0,
					id: variable.id,
					description: variable.description,
					templateId: variable.templateId,
					contents: {
						name: variable.name,
						type: variable.type,
						options: variable.options
					}
				}) as VariableElement
			}));
		}
	}, loadNestedElements.value)

	return {
		run,
		isRunning: loadNestedElements.isLoading,
	}

}

export {useLoadAllVariablesAction}