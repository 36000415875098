import { User } from "./User";

// assignable roles for a template/document to a user within the system
type Reviewer = "@role:reviewers";
type Approver = "@role:approvers";
type Author = "@role:authors";
type Reader = "@role:readers";
type Owner = "@owners";
type CompletedApprover = "@role:_completed_approvers";
type CompletedReviewer = "@role:_completed_reviewers";

export type Role = Reviewer | Approver | Author | Reader | Owner | CompletedApprover | CompletedReviewer;

type RolesType = {
	Reviewer: Reviewer,
	Approver: Approver,
	Author: Author,
	Reader: Reader,
    Owner: Owner,
    CompletedApprover: CompletedApprover,
    CompletedReviewer: CompletedReviewer
}

// used to reference the types
export const Roles: RolesType = {
	Reviewer: "@role:reviewers",
	Approver: "@role:approvers",
	Author: "@role:authors",
	Reader: "@role:readers",
	Owner: "@owners",
	CompletedApprover: "@role:_completed_approvers",
	CompletedReviewer: "@role:_completed_reviewers",
}

// statements that negate the access of an existing role. (for now, a prefix of '!')
type NotReviewer = "!@role:reviewers";
type NotApprover = "!@role:approvers";
type NotAuthor = "!@role:authors";
type NotReader = "!@role:readers";
type NotOwner = "!@owners";
type NotCompletedApprover = "!@role:_completed_approvers";
type NotCompletedReviewer = "!@role:_completed_reviewers";

export type NegatedRole = NotReviewer | NotApprover | NotAuthor | NotReader | NotOwner | NotCompletedApprover | NotCompletedReviewer;

type NegatedRolesType = {
	Reviewer: NotReviewer,
	Approver: NotApprover,
	Author: NotAuthor,
	Reader: NotReader,
    Owner: NotOwner,
    CompletedApprover: NotCompletedApprover,
    CompletedReviewer: NotCompletedReviewer
}

export const NegatedRoles: NegatedRolesType = {
	Reviewer: "!@role:reviewers",
	Approver: "!@role:approvers",
	Author: "!@role:authors",
	Reader: "!@role:readers",
	Owner: "!@owners",
	CompletedApprover: "!@role:_completed_approvers",
	CompletedReviewer: "!@role:_completed_reviewers",
}

const RoleMapping = {
	[Roles.Reviewer]: NegatedRoles.Reviewer,
	[Roles.Approver]: NegatedRoles.Approver,
	[Roles.Author]: NegatedRoles.Author,
	[Roles.Reader]: NegatedRoles.Reader,
	[Roles.Owner]: NegatedRoles.Owner,
	[Roles.CompletedApprover]: NegatedRoles.CompletedApprover,
	[Roles.CompletedReviewer]: NegatedRoles.CompletedReviewer,
}

// ensures that every role provided in the second parameter is in the role array.
export function hasRoles(rolesArray: string[], rolesToMatch: Role[]): boolean {
	return rolesToMatch.some((role) => rolesArray.includes(role));
}

// takes into account negated user roles being present in a given access array.
export function hasAccess(userRoles: Role[], accessArray: (Role & NegatedRole)[]): boolean {
	const negatedUserRoles = userRoles.map((role) => RoleMapping[role]);
	if (accessArray.some((role) => negatedUserRoles.includes(role))) {
		return false;
	}

	if (accessArray.some((role) => userRoles.includes(role))) {
		return true;
	}

	// no roles matched
	return false;
}


export function computeUserRoles(user: User, permissions: KaiAlphaTemplatePermissions): Role[] {
	const userRoles: Role[] = [];

	if (permissions.owners.includes(user.id) || permissions.owners.includes("@all")) {
		userRoles.push(Roles.Owner);
	} else if (user.groups) {
		for(const group of user.groups) {
			if (permissions.owners.includes(group)) {
				userRoles.push(Roles.Owner);
				break;
			}
		}
	}

	Object.keys(permissions.roles ?? {}).forEach((role) => {
		if (permissions.roles![role].includes(user.id) || permissions.roles![role].includes("@all")) {
			userRoles.push(`@role:${role}` as Role);
		} else if (user.groups) {
			for(const group of user.groups) {
				if (permissions.roles![role].includes(group)) {
					userRoles.push(`@role:${role}` as Role);
					break;
				}
			}
		}
	})

	return userRoles;
}

export function documentComputeUserRoles(userId: string, permissions: KaiAlphaDocumentPermissions): Role[] {
	const userRoles: Role[] = [];

	if (permissions.owners.includes(userId) || permissions.owners.includes("@all")) {
		userRoles.push(Roles.Owner);
	}

	Object.keys(permissions.roles ?? {}).forEach((role) => {
		if (permissions.roles![role].includes(userId) || permissions.roles![role].includes("@all")) {
			userRoles.push(`@role:${role}` as Role);
		}
	})

	return userRoles;
}