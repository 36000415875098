import {TemplateElementDefinition} from "../model";
import {HtmlIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {PropertyEditor} from "./views/PropertyEditor";
import {ReadonlyView} from "./views/ReadonlyView";
import {PreviewView} from "./views/preview/PreviewView";
import {
	htmlDefinition as baseHtmlDefinition
} from "../../../../../shared/definitions/elements/html/model";

const htmlDefinition: TemplateElementDefinition = {
	...baseHtmlDefinition,
	icon: HtmlIcon,
	views: {
		editable: EditableView,
		propertyEditor: {
			view: PropertyEditor,
			requiredOnCreate: false
		},
		readonly: ReadonlyView,
		preview: PreviewView
	},
} as const

export {htmlDefinition};