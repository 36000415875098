import {executeExpression} from "../../../../../shared/utilities/data/executeExpression";
import {compareExpression} from "../../../../../shared/utilities/data/compareExpression";
import {SwitchDataValue} from "../../../../../shared/definitions/elements/switch/model";
import {Variable} from "../../../../../shared/interfaces/Variable";

export function getBodyFromEvaluatedExpression(data: SwitchDataValue, variables: Variable[]) {
	if (data.expression) {
		const expressionResult = executeExpression(data.expression, variables);
		const values = Object.keys(data.values ?? {});
		const matchingValue = values.find(value => compareExpression(expressionResult.expression.trim(), value, variables));
		return matchingValue ? data.values![matchingValue] : data.default;
	}

	return []
}