import {useAsync} from "../../../../shared/hooks";
import {UserManager} from "../../../../shared/hooks/useUser";
import api from "../../../../api/api";
import {VariableAdapter} from "../../../../shared/utilities/variableAdapter";

function useGetDocumentBuffer() {
	const user = UserManager.useContainer();
	const getBuffer = async (documentId, documentVersion): Promise<KaiAlphaDocument | null> => {
		try {
			const id = `${user.id}_${documentId}_${documentVersion}`;
			const bufferResponse = await api.call('GET', `document/buffer/${id}`);
			bufferResponse.variables = VariableAdapter.flattenTree(bufferResponse.variables);
			return bufferResponse;
		} catch(ignoredError) {
			// ignore error, buffer is valid if null.
		}
		return null;
	}
	return useAsync<KaiAlphaDocument>(getBuffer, false);
}

export {useGetDocumentBuffer};