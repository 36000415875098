import React from 'react';
import { Button, Dialog } from '../../lib/ui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import {
	TextField,
	CircularProgress
} from '../../lib/ui';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import { getVersionsOfTheTemplate } from '../../api/template.js';
import { getVersionsOfTheDocument } from '../../api/document.js';
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
import audit_utils from '../../lib/utils/audit_utils';
import object_utils from '../../lib/utils/object_utils';
import date_utils from '../../lib/utils/date_utils';
import lib_document from '../../api/document';
import lib_user from '../../api/user';
import TablePagination from '@mui/material/TablePagination';

const uuid = require('uuid');

class AuditLog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			auditLogs: [],
			fromDate: null,
			toDate: null,
			isLoading: false,
			showNextButton: false,
			currentPage: 0,
			pageLimit: 5,
			users: {},
			versionsList: [],
			filterEvent: '',
			export: false,
			exportValue: 'pdf',
			exportLoading: false,
		};
	}

	componentDidMount() {
		this.fetchAuditLogs();
	}

	async processAuditLogsForVersions(versions, parentName, templateActualName) {
		const versionsWithinLimit = audit_utils.get_versions_within_date_limit(versions, this.state.fromDate, this.state.toDate);
		const externalOptions = {
			parent_name: parentName,
			item_name: templateActualName,
			type: this.props.type
		};
		return await audit_utils.get_audit_logs_from_versions(versionsWithinLimit, this.props.id, externalOptions);
	}

	async getAuditForTheTemplateVersions(id, get_versions_callback) {
		const stateUpdate = {};
		try {
			const version_info = await get_versions_callback(id);
			let finalLogs = await this.processAuditLogsForVersions(version_info.versions);
			if (this.props.type === 'document') {
				finalLogs = finalLogs.concat(await this.processSubDocuments(id, get_versions_callback));
			}
			const sortedAuditLogs = finalLogs.sort(function (version1, version2) {
				return (date_utils.sort_dates_compare(version1, version2, 'desc', 'timestamp_date'));
			});

			stateUpdate.auditLogs = sortedAuditLogs;
		} catch (get_audit_logs_error) {
			console.error('Error updating audit logs:', get_audit_logs_error);

			stateUpdate.auditLogsError = get_audit_logs_error;
		}

		this.setState({
			isLoading: false,
			...stateUpdate
		});
	}

	async fetchAuditLogs() {
		this.setState({ isLoading: true });

		if (!this.props.id) {
			return;
		}

		let get_versions_callback;
		if (this.props.type === 'template') {
			get_versions_callback = getVersionsOfTheTemplate;
		} else {
			get_versions_callback = getVersionsOfTheDocument;
		}

		await this.getAuditForTheTemplateVersions(this.props.id, get_versions_callback);
	}

	async processSubDocuments(id, getVersions, parentName) {
		let subDocumentAuditLogs = [];
		if (this.props.type === 'document') {
			const subDocuments = await lib_document.getDocumentSubdocuments(id);
			if (subDocuments && Array.isArray(subDocuments)) {
				for (const subDocument of subDocuments) {
					const result = await getVersions(subDocument.id);
					const name = audit_utils.add_parent_name_if_any(subDocument.name, parentName);
					const subDocumentLogs = await this.processAuditLogsForVersions(result.versions, name);
					if (subDocument.subdocuments) {
						for (const childDocument in subDocument.subdocuments) {
							const childId = subDocument.subdocuments[childDocument].document_id;
							subDocumentAuditLogs = subDocumentAuditLogs.concat(await this.processSubDocuments(childId, getVersions, name));
						}
					}
					subDocumentAuditLogs = subDocumentAuditLogs.concat(subDocumentLogs);
				}
			}
		}
		return subDocumentAuditLogs;
	}

	buttonCancel = () => {
		if (this.props.onCancel) {
			this.props.onCancel();
		}
	};

	handleSearchByEvent(text) {
		this.setState({ filterEvent: text });
	}

	filterByEvent(log) {
		if (this.state.filterEvent && log.event) {
			return log.event.includes(this.state.filterEvent);
		} else {
			return true;
		}
	}

	handlePageChange(newPage) {
		this.setState({ currentPage: newPage });
	}

	handlePageLimitChange(event) {
		const latestPageLimit = parseInt(event.target.value, 10);
		this.setState({ pageLimit: latestPageLimit, currentPage: 0 });
	}

	viewVersion(link) {
		this.props.history.push(link);
		this.props.history.go();
	}

	async handleDownload() {
		const id = this.props.id;
		const type = this.props.type;

		this.setState({ exportLoading: true });

		let dispatch_to;
		if (this.state.exportValue === 'pdf') {
			dispatch_to = audit_utils.get_pdf;
		} else {
			dispatch_to = audit_utils.get_csv_file;
		}

		const current_user_id = await lib_user.get_user_id();
		const href = await dispatch_to(current_user_id, this.state.auditLogs, {
			type,
			id
		});

		this.saveFile(href, `auditLog.${this.state.exportValue}`);
		this.setState({ exportLoading: false });
	}

	async saveFile(href, name) {
		const link = document.createElement('a');
		link.href = href;
		link.download = name;
		link.click();
	}

	getCircularProgressButton() {
		return <CircularProgress style={{ marginLeft: '45%', padding: '2%', height: '4%' }}></CircularProgress>;
	}

	getTableCells(auditLogs) {
		if (this.state.isLoading) {
			return;
		}

		const currentPage = this.state.currentPage;
		const pageLimit = this.state.pageLimit;
		let auditLogsArray = object_utils.copy_object(this.state.auditLogs);

		auditLogsArray = auditLogsArray.slice(currentPage * pageLimit, ((currentPage * pageLimit) + pageLimit));

		const retval = auditLogsArray.map((log) => {
			if (!log || !this.filterByEvent(log)) {
				return (undefined);
			}

			let link = '';
			switch (this.props.type) {
				case 'template':
					link = `/activity/templateeditor/${this.props.id}/version/${log.version}`;
					break;
				case 'document':
					link = `/activity/doceditor/${this.props.id}/version/${log.version}`;
					break;
				default:
					throw (new Error('Noticed a different type other than template and document'));
			}

			return (
				<TableRow id={uuid.v4()}>
					<TableCell align="center">{log.templateName} <p>{log.section}</p></TableCell>
					<TableCell align="center">{log.event}</TableCell>
					<TableCell align="center">{log.user}</TableCell>
					<TableCell align="center">
						<Button variant='contained' onClick={() => this.viewVersion(link)}>{date_utils.format_date(log.timestamp, { use_relative: false })}</Button>
					</TableCell>
				</TableRow>
			);
		});

		return (retval);
	}

	closeExportDialog = () => {
		this.setState({ export: false });
	};

	render() {
		let auditLogLoadingStatus = undefined;
		let progressForExport = undefined;

		if (this.state.isLoading && !this.state.auditLogsError) {
			auditLogLoadingStatus = this.getCircularProgressButton();
		}

		if (this.state.auditLogsError) {
			auditLogLoadingStatus = <div>ERROR: {String(this.state.auditLogsError)}</div>;
		}

		if (this.state.exportLoading) {
			progressForExport = this.getCircularProgressButton();
		}


		return <Dialog
			open={this.props.visible}
			maxWidth="xl"
			fullWidth
			onClose={this.buttonCancel}
			title={
				<Grid container alignItems="flex-end" style={{ width: '100%' }}>
					<Grid item style={{ width: '70%' }}>
						<Typography style={{ marginLeft: '2%' }}>Audit Logs</Typography>
					</Grid>
					<Grid item style={{ width: '30%' }}>
						<Grid container spacing={1} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 10, }}>
							<Grid item>
								<Button
									onClick={() => { this.setState({ export: true }); }}
								>Export</Button>
							</Grid>
							<Grid item>
								<Button
									onClick={() => { this.fetchAuditLogs(); }}
								>Reload</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			}
		>
			<>
				<Dialog open={this.state.export} onClose={this.closeExportDialog} hideButtons>
					<RadioGroup value={this.state.exportValue} onChange={(event) => this.setState({ exportValue: event.target.value })}>
						<FormControlLabel value="pdf" control={<Radio />} label="PDF" />
						<FormControlLabel value="csv" control={<Radio />} label="CSV" />
						<Grid conatiner spacing={2} style={{ marginTop: '2%' }}>
							<Button onClick={() => this.handleDownload()}>Download</Button>
							<Button type="cancel" onClick={this.closeExportDialog} >Cancel</Button>
						</Grid>
						{progressForExport}
					</RadioGroup>
				</Dialog>
				<Grid container spacing={5} alignItems="flex-end" style={{ width: '100%' }}>
					<Grid item style={{ width: '30%' }}>
						<Grid container spacing={1} alignItems="flex-end" style={{ padding: '2%' }}>
							<Grid item>
								<SearchOutlined />
							</Grid>
							<Grid item style={{ width: '80%' }}>
								<TextField
									id="event-search"
									label="Search Event"
									onChange={(event) => this.handleSearchByEvent(event.target.value)}
									style={{ width: '100%' }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<LocalizationProvider dateAdapter={DateFnsUtils}>
							<Grid container spacing={3} justify="space-around">
								<Grid item>
									<DatePicker
										autoOk
										disableToolbar
										variant="inline"
										format="dd/MM/yyyy"
										margin="normal"
										id="date-picker-start-date"
										label="Start Date"
										value={this.state.fromDate}
										renderInput={(props) => <TextField {...props} />}
										onChange={(date) => {
											this.setState({ fromDate: date });
										}}
									/>
								</Grid>
								<Grid item>
									<DatePicker
										autoOk
										disableToolbar
										variant="inline"
										format="dd/MM/yyyy"
										margin="normal"
										id="date-picker-to-date"
										label="End Date"
										renderInput={(props) => <TextField {...props} />}
										value={this.state.toDate}
										onChange={(date) => this.setState({ toDate: date })}
									/>
								</Grid>
							</Grid>
						</LocalizationProvider>
					</Grid>
				</Grid>
				<Grid style={{ height: '40%' }} container>
					<TableContainer component={Paper} style={{ maxHeight: 440 }}>
						<Table className='audit-table' stickyHeader>
							<TableHead>
								<TableCell align="center">Section</TableCell>
								<TableCell align="center">Event</TableCell>
								<TableCell align="center">User</TableCell>
								<TableCell align="center">Timestamp</TableCell>
							</TableHead>
							<TableBody>
								{
									this.getTableCells(this.state.auditLogs)
								}
							</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[5, 10]}
							count={this.state.auditLogs.length}
							rowsPerPage={this.state.pageLimit}
							page={this.state.currentPage}
							onChangePage={(event, newPage) => this.handlePageChange(newPage)}
							onChangeRowsPerPage={(event) => this.handlePageLimitChange(event)}
							component="div"
						>
						</TablePagination>
					</TableContainer>
				</Grid>
				<Grid conatiner style={{ width: '100%' }}>
					{auditLogLoadingStatus}
				</Grid>
			</>
		</Dialog>;
	}
}

export default AuditLog;
