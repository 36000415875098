import {ElementIconProps, IconDefaults} from "../model";

function SectionIcon({color}: ElementIconProps) {
	const pathStyle = {
		fill: color ?? IconDefaults.color!,
	}

	const lineStyle = {
		fill: "none",
		stroke: color ?? IconDefaults.color!
	}
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
			<defs>
				<style>{`.a{fill:${color ?? IconDefaults.color};}.b{fill:none;stroke:${color ?? IconDefaults.color};stroke-width:2px;}`}</style>
			</defs>
			<g transform="translate(-548 -499)">
				<path style={pathStyle} d="M18.722,6.278V18.722H6.278V6.278H18.722m0-1.778H6.278A1.783,1.783,0,0,0,4.5,6.278V18.722A1.783,1.783,0,0,0,6.278,20.5H18.722A1.783,1.783,0,0,0,20.5,18.722V6.278A1.783,1.783,0,0,0,18.722,4.5Z" transform="translate(543.5 494.5)"/>
				<g transform="translate(-0.5 -1.5)">
					<line style={lineStyle} x2="10" transform="translate(551.5 505.5)"/>
					<line style={lineStyle} x2="10" transform="translate(551.5 508.5)"/>
					<line style={lineStyle} x2="10" transform="translate(551.5 511.5)"/>
				</g>
			</g>
		</svg>
	)
}

export {SectionIcon}