import {ModuleColumn} from "../models";
import {NameColumn} from "./NameColumn";
import {NumberColumn} from "./NumberColumn";
import {ActiveColumn} from "./ActiveColumn";
import {CompletionColumn} from "./CompletionColumn";


export const columns: ModuleColumn[] = [NumberColumn, NameColumn, ActiveColumn, CompletionColumn]
export function cellViewForColumn(field: string) {
	return columns.find(column => column.field === field)?.views?.cell;
}

export function headerViewForColumn(field: string) {
	return columns.find(column => column.field === field)?.views?.header;
}

export {NumberColumn, NameColumn, ActiveColumn, CompletionColumn};