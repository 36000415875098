import {SvgIcon} from "@mui/material";
import {FlexBox} from "../../../layout";
import {ToolbarItemProps} from "./model";
import {styled} from "@mui/styles";
import {colors} from "../../../../../theme";


export type ToolbarButtonProps = ToolbarItemProps & {
	icon?: typeof SvgIcon,
	text?: string,
}

export type ToolbarButtonEvents = {
	onClick: () => void
}

const ButtonContainer = styled(FlexBox)({
	width: "auto",
	padding: "0.5rem 1rem",
	borderRadius: "0.5rem",
	background: colors.otherDarkBlue,
	cursor: "pointer",
	boxSizing: "border-box",
	height: "36px",
	margin: "0 0.05rem",
	"& span": {
		margin: "0 .25rem"
	}
})

const ToolBarButton = (props: ToolbarButtonProps & ToolbarButtonEvents) => {
	return <ButtonContainer align={"center"} style={props.style} onClick={props.onClick}>
		{props.icon && <props.icon fontSize={"small"} />}
		{props.text && <span>{props.text}</span>}
	</ButtonContainer>
}

export {ToolBarButton}