import { useState } from "react";
import {createContainer} from "unstated-next";
import { UserManager } from '../../../shared/hooks/useUser';
import {CommentStates, CommentViewMode} from "../models/comment";
import { DocumentState } from "./useDocumentState";

export type CommentEntity = null | 'TOP_LEVEL' | 'RESPONSE' | 'ELEMENT';

export interface DocumentComment extends KaiAlphaCommentEntry {
	sourceDocumentId?: string,
	replies?: DocumentComment[],
	entityType?: CommentEntity
}

interface UseCommentState {
	comments: DocumentComment[] |null,
	selectedComment: DocumentComment | null,
	loading: boolean,
	viewMode: CommentViewMode,
}

const filterDeleted = (comments: DocumentComment[]) => {
	return comments.filter((value) => {
		if(value.deleted === false) {
			return true;
		} else {
			return false;
		}
	})
}

const filterInReview = (comments: DocumentComment[]) => {
	return comments.filter((value) => {
		if(value.state === CommentStates.IN_REVIEW) {
			return true;
		} else {
			return false;
		}
	})
}

const filterResolved = (comments: DocumentComment[]) => {
	return comments.filter((value) => {
		if(value.state === CommentStates.RESOLVED) {
			return true;
		} else {
			return false;
		}
	})
}

const initialState: UseCommentState = {
	comments: [],
	selectedComment: null,
	loading: false,
	viewMode: "all"
};

const useComment = () => {
	const [state, setState] = useState(initialState);
	const documentManager = DocumentState.useContainer();
	const userManager = UserManager.useContainer();

	const getUsers = () => {
		// we pull the permissions from the buffer
		const permissions = documentManager?.buffer?.permissions;

		let retrievedIds: any[] = [];

		if(permissions) {
			retrievedIds = permissions?.owners;
		}

		return retrievedIds;
	}

	const getCurrentUserId = () => {
		return userManager.id;
	}

	const getUserDisplayname = async (userId: string) => {
		return await userManager.getUserDisplayname(userId);
	}

	const comments = state.comments;

	const setViewMode = (viewMode: CommentViewMode) => setState(s => ({...s, viewMode}));
	const openComments = () => {
		if(state.comments) {
			return filterInReview(state.comments);
		} else {
			return [];
		}
	};

	const resolvedComments = () => {
		if(state.comments) {
			return filterResolved(state.comments);
		} else {
			return [];
		}
	};

	const setComments = (comments: DocumentComment[]) => {
		setState( prevState => ({...prevState, comments, loading: false}));
	}

	const setLoading = (loading: boolean) => {
		setState((s) => ({...s, loading}))
	};

	const selectedElement = documentManager.selectedElement?.id;

	const commentScroll = (comment: DocumentComment) => {
		setState(s => ({...s, selectedComment: comment}));
	}

	const highlightedCommentsByElementId = (elementId: string) => {
		return state.comments?.filter((value) => value.elementId === elementId && value.text_properties !== undefined) ?? [];
	}

	return {
		getUsers,
		comments,
		openComments,
		resolvedComments,
		setComments,
		getUserDisplayname,
		commentScroll,
		selectedElement,
		getCurrentUserId,
		selectedComment: state.selectedComment,
		filterDeleted,
		filterInReview,
		filterResolved,
		setLoading,
		loading: state.loading,
		highlightedCommentsByElementId,
		documentId: documentManager.document ? documentManager.document.id : "",
		setViewMode,
		viewMode: state.viewMode
	}
}
const CommentState = createContainer(useComment);
export {CommentState};