import {useGetTemplates} from "../../../../../shared/hooks/api/useGetTemplates";
import {useAsync} from "../../../../../shared/hooks";
import {useUserList} from "../../../../../shared/hooks/useUserList";
import {TableChildrenAction} from "../../../../../shared/components/layout/table";
import {TemplateListNode} from "./models";
import {computeTemplateList} from "./utils";
import {QueryResponse} from "../../../../../shared/interfaces";

// TODO: use a different api for this (hacky)
function useGetTemplatesListChildren(): TableChildrenAction<TemplateListNode[]>  {
	const getTemplate = useGetTemplates();
	const userList = useUserList();
	const getTemplateChildren = async (ids: string[]): Promise<TemplateListNode[]> => {
		const templates = (await Promise.all(ids.map(id => {
			const options = {
				filters: [{field: "id", value: id}],
				pageSize: 1000
			}
			return getTemplate.execute(options);
		}))).map(response => response?.results ?? []).flat().filter(template => template !== null);

		const list = await userList.getUserNames();
		if (!list || !templates) {
			throw new Error(`Failed retrieving children`);
		}

		return computeTemplateList(templates as QueryResponse<KaiAlphaTemplate>[], list);
	}


	// this needs to be wrapped, because its possible we useAsync, or useCachedAsync, which have different return types.
	const hook = useAsync<TemplateListNode[]>(getTemplateChildren, false);

	return {
		execute: hook.execute,
		value: hook.value,
		isLoading: hook.isLoading,
		error: hook.error,
	};
}

export {useGetTemplatesListChildren};