import {ElementIconProps, IconDefaults} from "../model";

const VariableIcon = ({color}: ElementIconProps) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="13.491" viewBox="0 0 20 13.491">
		<g transform="translate(-4.5 -9.914)">
			<path style={{fill:color ?? IconDefaults.color}} d="M23,10.014a.337.337,0,0,0-.229-.1.327.327,0,0,0-.229.1l-.769.774a.353.353,0,0,0,0,.5l5.341,5.367-5.341,5.367a.353.353,0,0,0,0,.5l.769.774a.327.327,0,0,0,.229.1.317.317,0,0,0,.229-.1l6.367-6.393a.353.353,0,0,0,0-.5Z" transform="translate(-4.969)"/>
			<path style={{fill:color ?? IconDefaults.color}} d="M12.306,11.04a.355.355,0,0,0-.106-.252l-.769-.774a.337.337,0,0,0-.229-.1.327.327,0,0,0-.229.1L4.606,16.407a.353.353,0,0,0,0,.5l6.367,6.393a.337.337,0,0,0,.229.1.317.317,0,0,0,.229-.1l.769-.774a.353.353,0,0,0,0-.5L6.858,16.659,12.2,11.292A.355.355,0,0,0,12.306,11.04Z"/>
		</g>
	</svg>

)

export {VariableIcon}