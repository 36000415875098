import { ReadonlyElementProps } from "../../model";
import { HtmlElementValue } from "../../../../../../shared/definitions/elements/html/model";
import { EmptyView } from "../../EmptyView";
import { Html } from "../../../text/Html";
import { CommentState } from "../../../../hooks/useCommentState";
import CommentModal from "../../../modals/templateComments/CommentModal";
import { CommentModalProps } from "../../../../../../shared/definitions/highlight/CommentModal.props";
import { HighlightWrapper } from "../../../../../../shared/components/input/highlight/HighlightWrapper";


const ReadonlyView = (props: ReadonlyElementProps) => {
	const value = props.data as HtmlElementValue;
	const commentsManager = CommentState.useContainer();

	const highlightComments = commentsManager.highlightedCommentsByElementId(props.id);

	const renderHighlight = (highlightedText, originalText) => {
		if (originalText.length === 0) {
			return <Html value={highlightedText} />
		}

		const html = value.text!.replace(originalText, highlightedText)
		return <Html value={html} />
	}
	return value.text ? <HighlightWrapper
		elementId={props.id}
		enabled={true}
		highlightComments={highlightComments}
		value={value.text!}
		componentToWrap={renderHighlight}
		renderCommentModal={ (values: CommentModalProps) =>
			<CommentModal
				onClose={values.onClose}
				addEditComment={"Add"}
				showOpen={values.showOpen}
				elementId={values.elementId}
				selectedText={values.selectedText}
				textProperties={values.textProperties}
			/>
		}
	/> : <EmptyView message={"No content text has been added"}/>
}

export { ReadonlyView }