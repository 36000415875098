import {useGetSubmission} from "../../api/useGetSubmission";
import {useRunWhenValueChange} from "../../../../../shared/hooks";
import {SubmissionState} from "../../useSubmissionState";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";
import {runActionAcrossCollection} from "../../../pages/tocBuilder/utilities/runActionAcrossCollection";
import {EntityRecord} from "../../../../../shared/interfaces/Entity";


function useLoadSubmissionAction(): TypedAction<EntityRecord> {
	const getSubmission = useGetSubmission();
	const {setSubmission} = SubmissionState.useContainer();

	const run = ({id, version}: EntityRecord) => getSubmission.execute(id, version);
	useRunWhenValueChange(() => {
		const submission = getSubmission.value!;
		submission.ectdModules = runActionAcrossCollection(submission.ectdModules, module => ({...module, active: true}));
		setSubmission(submission);
	}, getSubmission.value);

	return {
		run,
		isRunning: getSubmission.isLoading
	}
}

export {useLoadSubmissionAction}