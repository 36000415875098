import {useAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";
import {ApiPatchResult} from "../../../../shared/interfaces";

type UpdatePermissionsResponse = {
	documentPermissions: KaiAlphaDocumentPermissions,
	version: string,
	lastupdated: number
}
// updates the permissions for the given templateId
function useUpdatePermissions() {
	const updatePermissions = async (id: string, documentPermissions: KaiAlphaDocumentPermissions, lastupdated?: number): Promise<UpdatePermissionsResponse> => {
		const payload = {
			"id": id,
			"version": "HEAD",
			"change": {permissions: documentPermissions, lastupdated},
			"delete": {}
		}

		const document: ApiPatchResult =  await api.call('PATCH', `document/${id}`, payload);
		return {
			documentPermissions,
			version: document.version,
			lastupdated: document.lastupdated
		};
	}

	return useAsync<UpdatePermissionsResponse>(updatePermissions, false);
}

export {useUpdatePermissions}