import { PreviewElementProps } from "../../../model";
import { Html } from "../../../../text/Html";
import "./style.scss";
import { HtmlElementValue } from "../../../../../../../shared/definitions/elements/html/model";
import { useRunWhenValueChange } from "../../../../../../../shared/hooks";
import { useState } from "react";
import { mapVariables } from "./mapVariables";
import { mapReferenceElements } from "./mapReferenceElements";
import {renderTemplatedString} from "../../../../../../../shared/nunjucks/renderTemplatedString";


const PreviewView = (props: PreviewElementProps) => {
	const value = props.data as HtmlElementValue;
	const [html, setHtml] = useState("")

	useRunWhenValueChange(() => {
		const filteredVariables = props.variables?.filter(v => v.templateId === props.templateId) ?? [];
		const variables = mapVariables(filteredVariables, props.body as KaiAlphaBody);
		const refObject = mapReferenceElements(props.body);

		const nunjucksVariables = { ...variables, ...refObject };
		setHtml(renderTemplatedString(value.text, nunjucksVariables));

	}, [props.data, props.variables]);

	return <Html className={"tm__html-preview"} value={html} />
}
export { PreviewView };