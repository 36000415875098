import { Button, DragDropResult, FlexBox } from "../../../../../shared/components";
import { TabDefinition } from "../../../../../shared/components/layout/tabs/tabDefinition";
import { DragDropArea } from "../../../../../shared/components";
import { TemplateState } from "../../../hooks";
import { Element } from "./Element"
import { SearchBar } from "../../../../../shared/components/input/search/SearchBar";
import { Add } from "@mui/icons-material";
import ElementsDialogBox from "../../../../../components/ElementsDialogBox";
import { useMemo, useState } from "react";
import { useElements } from "../../../hooks";
import { EmptyGroup } from "../../../../../shared/components/layout/groups/EmptyGroup";
import { PropertyEditor } from '../../modals';
import { TemplateElementContent } from "../../../models/elements";
import { ElementPropertyData } from "../../elements/model";
import { elementWithTypeHasPropertyEditor } from "../../elements";
import { definitionForElementType, elementWithTypeHasCustomAddElement } from "../../elements";
import { useRequestEditAction } from "../../../hooks/actions/update/useRequestEditAction";
import { TemplateElementValue } from "../../../../../shared/definitions/elements/template/model";
import { TemplateElement } from "../../../../../shared/interfaces/TemplateElement";

interface InitialState {
	addElement: boolean,
	insertAtId: string | null,
	searchValue: string,
	selectedElement: TemplateElement | null,
	expanded: {
		[k: string]: boolean,
	}
}

const initialState: InitialState = {
	addElement: false,
	insertAtId: null,
	selectedElement: null,
	searchValue: "",
	expanded: {}
}

/**
 * This component represents the "Elements" tab
 * @constructor
 */
const TabView = () => {
	const templateManager = TemplateState.useContainer();
	const elementsManager = useElements();
	const requestEditAction = useRequestEditAction();
	const [state, setState] = useState(initialState);

	const onExpandElement = (element) => {
		if (state.expanded[element.id]) {
			// get all children (that have children) and hide those
			const expandedElements = elementsManager.getAllChildren(element)
				.reduce((obj, e) => {
					if (e.hasChild) {
						obj[e.id] = false;
					}
					return obj
				}, { [element.id]: false })
			setState(s => ({
				...s,
				expanded: {
					...s.expanded,
					...expandedElements
				}
			}));
			return;
		}
		setState(s => ({
			...s,
			expanded: {
				...s.expanded,
				[element.id]: true,
			}
		}));
	}

	// event handlers
	// this could be debounced.
	const onSearchValueChange = (searchValue) => setState(s => ({ ...s, searchValue }));
	const onAddElement = () => setState(s => ({ ...s, addElement: true }));
	const onCloseAddElement = () => setState(s => ({ ...s, addElement: false }));
	const onInsertElement = (type: string, contents: any = {}) => {
		const element: TemplateElement = { type, contents, id: "", index: 0, depth: 0 };
		if (!elementWithTypeHasPropertyEditor(type)) {
			elementsManager.addElement(element, state.insertAtId);
			setState(s => ({ ...s, insertAtId: null, selectedElement: null }));
			return;
		}
		setState(s => ({ ...s, selectedElement: element }));
	}

	const onAddChildElement = (parentElement: TemplateElement) => {
		if (elementWithTypeHasCustomAddElement(parentElement.type)) {
			elementsManager.addElement(
				{
					...elementsManager.createElement(definitionForElementType(parentElement.type)!.addElementType!),
					id: parentElement.id,
					depth: parentElement.depth + 1
				},
				parentElement.id);
		} else {
			setState(s => ({ ...s, addElement: true, insertAtId: parentElement.id }));
		}
	}

	const onEditElement = (element: TemplateElement) => {
		if (elementWithTypeHasPropertyEditor(element.type)) {
			setState(s => ({ ...s, selectedElement: element }));
		} else {
			elementsManager.onElementClicked(element);
		}
	}

	const onDragEnd = (result: DragDropResult) => {
		elementsManager.onDragDrop(result, elements);
	}

	const onClosePropertyEditor = () => setState(s => ({ ...s, insertAtId: null, selectedElement: null }));
	const onUpdateElementProperty = (updatedContent: ElementPropertyData) => {
		if (state.selectedElement!.id === "") {
			elementsManager.addElement({ ...state.selectedElement, type: state.selectedElement!.type, id: "", index: 0, depth: 0, contents: (updatedContent.data as TemplateElementContent) }, state.insertAtId);
		} else {
			elementsManager.changeElement(updatedContent, state.selectedElement!.id);
		}
		onClosePropertyEditor();
	}

	const onRequestTemplateEdit = (element: TemplateElement, requestMessage: string) => {
		const { id } = (element.contents as TemplateElementValue);
		requestEditAction.run({templateId: id!, requestMessage});
	}

	// memorized potentially expensive object
	const searchedElements = useMemo(() => elementsManager.filterElements(state.searchValue),
		[state.searchValue, elementsManager]);

	// only show elements that are top level, or their parents are expanded
	const elements = useMemo(() => {
		const matchingElements = searchedElements.filter((element) => (!(element.isChild ?? false) || state.expanded[element.parentId!]));
		const childElements = matchingElements.filter(e => e.isChild);
		const displayedElements = matchingElements.filter(e => childElements.every(childElement => childElement.id !== e.id));
		Object.keys(state.expanded).forEach(parentId => {
			const childrenForParent = matchingElements.filter(e => e.parentId === parentId);
			const parentIndex = displayedElements.findIndex(e => e.id === parentId);
			if (parentIndex === displayedElements.length - 1) {
				displayedElements.push(...childrenForParent)
			} else {
				displayedElements.splice(parentIndex + 1, 0, ...childrenForParent);
			}
		})

		return displayedElements;
	}, [searchedElements, state.expanded])

	return <FlexBox direction={"column"}>
		<FlexBox style={{ margin: "0.5rem 0 1rem 0" }} justify={"space-between"} align={"center"} data-testid={"add-elements-template-editor"}>
			<SearchBar placeholder="Search" onChange={onSearchValueChange} containerStyle={{ margin: "0 1rem", flex: 1 }} />
			{templateManager.editable && <Button buttonType="default" icon={<Add fontSize={"small"} />}
				text="Add Element"
				onClick={onAddElement} />}
		</FlexBox>
		<DragDropArea onDragEnd={onDragEnd} isDragDisabled={!templateManager.editable || state.searchValue !== ""}>
			{elements && elements.map(element => <Element
				key={element.id}
				element={element}
				expandable={element.hasChild ? {
					onExpandElement,
					expanded: state.expanded[element.id] ?? false
				} : undefined}
				onAddElement={templateManager.editable ? onAddChildElement : undefined}
				onRequestTemplateEdit={onRequestTemplateEdit}
				onEditElement={templateManager.editable ? onEditElement : undefined}
				onDeleteElement={templateManager.editable ? elementsManager.onRemoveElement : undefined}
				onElementClicked={elementsManager.onElementClicked}
			/>)}
		</DragDropArea>
		{(!elements || elements.length === 0) && state.searchValue.length > 0 && <EmptyGroup title="No elements match your search"></EmptyGroup>}
		{(!elements || elements.length === 0) && state.searchValue.length === 0 && <EmptyGroup title="Please add elements"></EmptyGroup>}
		{state.addElement && <ElementsDialogBox close_dialog={onCloseAddElement} add_element={onInsertElement} />}
		{state.selectedElement && <PropertyEditor
			value={{
				id: state.selectedElement.id,
				elementInformation: { type: state.selectedElement.type, writable: true },
				data: state.selectedElement.contents
			}}
			onClose={onClosePropertyEditor}
			onApply={onUpdateElementProperty}
			type={state.selectedElement.type}
			variables={templateManager.variables}
			dataSourcesList={templateManager.datasources?.items}
		/>}
	</FlexBox>;
};

const ElementsTab: TabDefinition = {
	name: "Elements",
	content: TabView
}

export { ElementsTab };
