import { Box, styled } from '@mui/material';

const ImageContainer = styled("div")(({ theme }) => ({
	margin: "20px 0px 30px",
}));

const FileText = styled(Box)(({theme}) => ({
	fontSize:".9rem",
	margin: "10px 10px",
	color: theme.palette.destructive?.main,
	'& .selected':{
		color: theme.palette.checkbox?.main,
	},
}));

export { ImageContainer, FileText };