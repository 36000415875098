import { FlexBox } from "../../../../../shared/components";
import { TemplateTypes } from "./models";
import { Switch, SwitchTitle } from "./styles";

export interface DisplayedTemplatesSwitchProps {
	displayedTemplatesType: TemplateTypes,
	// what template list should be shown
	onTemplateDisplayChange: (type: TemplateTypes) => void,
}

const DisplayedTemplatesSwitch = ({
	onTemplateDisplayChange,
	displayedTemplatesType,
}: DisplayedTemplatesSwitchProps) => {
	return (
		<FlexBox direction="row-reverse" boxSizing="border-box" padding="1rem" width={"auto"}>
			<SwitchTitle>Sub-templates</SwitchTitle>
			<Switch checked={displayedTemplatesType === "orphan"} onChange={(e) => onTemplateDisplayChange(e.target.checked ? "orphan" : "toplevel")}/>
			<SwitchTitle>Wrappers</SwitchTitle>
		</FlexBox>
	)

}

export {DisplayedTemplatesSwitch}