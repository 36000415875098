import {styled} from '@mui/material';
import {FlexBox} from '../../layout/FlexBox';

// base list wrapper for any Tree Style list.
const List = styled(FlexBox)(({theme}) => ({
	boxSizing: "border-box",
	"& svg": {
		cursor: "pointer"
	},
	flexDirection: "column",
	overflowY: 'scroll',
	overflowX: 'hidden',
	'&::-webkit-scrollbar': {
		width: '5px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: theme.palette.dashboard?.border,
		borderRadius: "30px",
		height: "10px",
	},
}));

export {List};