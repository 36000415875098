import React from "react";
import {  Checkbox, FormControlLabel} from "@mui/material/";
import {
	Icon,
} from '../../../lib/ui';
import { useStyles } from "../Checkbox.styles";

/*
 * Circular checkbox component
 * @param {string} label - optional label displayed to right of checkbox
 * @param {boolean} checked - is the check box checked/unchecked
 */
const CircularCheckbox = ({label, checked, onChange}) => {
	const classes = useStyles();
	const handleOnChange =()=>{
		if (onChange){
			onChange();
		}
	}
	return <>
	 <FormControlLabel
			label={label}
			control={<Checkbox
				icon={<Icon forUI="radiobuttonunchecked" className={classes.unselected_icon}/>}
				checkedIcon={<Icon forUI="checkcircle" className={classes.selected_icon} />}
				checked={checked}
				onChange={handleOnChange}
			/>}
		/>
	</>
}

export {CircularCheckbox}