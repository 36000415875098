import {Box} from "@mui/material";
import {AssignCompoundBox} from "../../../../components/layout/box/assignCompound";
import {RegulatoryBodyBox} from "../../../../components/layout/box/RegulatoryBodyBox";
import {EctdSelectionBox} from "../../../../components/layout/box/EctdSelectionBox";
import "./styles.scss"
import {useState} from "react";
import {AddCompoundModal} from "../../../../components/modals/addCompound";
import {Compound} from "../../../../../../shared/interfaces/Compound";
import {Indication} from "../../../../../../shared/interfaces/Indication";
import {SubmissionState} from "../../../../hooks/useSubmissionState";
import {useNavigate} from "react-router-dom";
import {moduleLinkGenerator} from "../../../../../moduleNavigation";
import {MessageBox} from "../../../../../../shared/components";

const CenterPane = () => {
	const submissionState = SubmissionState.useContainer();
	const navigate = useNavigate();
	const [addingCompound, setAddingCompound] = useState(false);
	const [alertMissingName, setAlertMissingName] = useState(false);
	const onAddCompoundClick = () => setAddingCompound(true);
	const onCloseCompoundList = () => setAddingCompound(false);

	const onAddCompoundAndIndications = (compound: Compound, indications: Indication[]) => {
		submissionState.setSubmission(submission => ({...submission, compound: compound.compound, indications}));
		onCloseCompoundList();
	}

	const onRemoveCompound = () => submissionState.setSubmission(submission => ({...submission, compound: undefined}));
	const onEditCompound = () => setAddingCompound(true);
	const onToggleRegulator = (regulator: string) => {
		const regulators = submissionState.submission.regulator;
		if (regulators?.includes(regulator)) {
			submissionState.setSubmission(submission => ({...submission, regulator: [...regulators.filter(r => r !== regulator)]}));
			return;
		}
		submissionState.setSubmission(submission => ({...submission, regulator: [...(regulators ?? []), regulator]}));
	}

	const onAddEctdStructure = () => {
		if (submissionState.submission.name.length === 0) {
			setAlertMissingName(true);
			return;
		}

		navigate(moduleLinkGenerator("submission", "create", "toc"));
	}

	const onCloseAlertMessage = () => setAlertMissingName(false);

	const canAddRegulator = submissionState.submission.compound !== undefined;
	const canAddEctdSelection = canAddRegulator && (submissionState.submission.regulator?.length ?? 0) > 0
	return <Box className={"ectd__ccp"}>
		<h1>New Submission</h1>
		<AssignCompoundBox onAddCompoundClick={onAddCompoundClick} onEditCompound={onEditCompound} onRemoveCompound={onRemoveCompound} />
		<RegulatoryBodyBox enabled={canAddRegulator} onToggleRegulator={onToggleRegulator} regulators={submissionState.submission.regulator ?? []} />
		<EctdSelectionBox enabled={canAddEctdSelection} onAddEctdStructure={onAddEctdStructure} />
		<AddCompoundModal visible={addingCompound} onClose={onCloseCompoundList} onOK={onAddCompoundAndIndications} />
		<MessageBox visible={alertMissingName} message={"Please add a submission name before proceeding"} primaryAction={"OK"} onActionClick={onCloseAlertMessage} onClose={onCloseAlertMessage} />
	</Box>
}

export {CenterPane}