import {EditableControlBox, FlexBox} from "../../../../../shared/components";
import {ElementDetail} from "./ElementDetail";
import {DragIndicator} from "@mui/icons-material";
import {elements} from "../../elements/model";
import { findElement } from "../../elements";
import { getElementStyle } from "./style";
import { RequestEditPopup } from "./RequestEditPopup";
import { useRef, useState } from "react";
import {displayNameForElementType} from "../../../../../shared/definitions/elements";
import { TemplateElement } from "../../../../../shared/interfaces/TemplateElement";
import {VariableElementValue} from "../../../../../shared/definitions/elements/variable/model";

export interface ElementProps {
	element: TemplateElement,
	onEditElement?: (element: TemplateElement) => void,
	onDeleteElement?: (element: TemplateElement) => void,
	onAddElement?: (element: TemplateElement) => void,
	// this functionality is particular to 'template' elements.
	onRequestTemplateEdit?: (element: TemplateElement, requestMessage: string) => void,
	onElementClicked: (element: TemplateElement) => void,
	expandable?: {
		onExpandElement: (element: TemplateElement) => void,
		expanded: boolean,
	}
}

// sections. templates, and variables display their name directly, everything else is a description.
const getElementTitle = (element: TemplateElement) => {
	return element.type === 'variable' ?
		(element.contents as VariableElementValue).name ??
	displayNameForElementType(element.type) :
		displayNameForElementType(element.type)
}

const getElementDescription = (element: TemplateElement) => {
	return `${findElement(element.type)?.contentDescription(element.contents)}` ?? "";
}

const elementTypesWithChildren = elements.filter(element => element.allowsChildren).map(element => element.type);

const Element = ({element, onEditElement, onAddElement, onDeleteElement, onElementClicked, onRequestTemplateEdit, expandable}: ElementProps) => {
	const controlBoxRef = useRef(null);
	const [displayRequestEditPopup, setDisplayRequestEditPopup] = useState(false);
	const invertRequestEditPopupDisplay = () => setDisplayRequestEditPopup(!displayRequestEditPopup);

	const onEditElementFactory = (element) => () => {
		if (onEditElement) {
			onEditElement(element);
		}
	}
	const onAddElementFactory = (element) => () => {
		if (onAddElement) {
			onAddElement(element);
		}
	}
	const onDeleteElementFactory = (element) => () => {
		if (onDeleteElement) {
			onDeleteElement(element);
		}
	}

	const onExpandElementFactory = (element) => () => {
		if (expandable) {
			expandable.onExpandElement(element);
		}
	}

	const onRequestTemplateEditFactory = (requestMessage: string) => {
		if (onRequestTemplateEdit) {
			onRequestTemplateEdit(element, requestMessage);
			invertRequestEditPopupDisplay();
		}
	}

	const onRequestTemplateEditClicked = () => {
		invertRequestEditPopupDisplay();
	}

	const elementClicked = (element) => () => onElementClicked(element);

	return <FlexBox align={"center"} overflow={"hidden"}>
		<DragIndicator style={{color: "#7f7f7f69", fontSize: "1.25rem", margin: element.depth === 0 && expandable?.expanded ? "10px 0 0 0" : ""}} />
		<FlexBox direction={"column"} overflow={"hidden"} onClick={elementClicked(element)} ref={controlBoxRef}>
			<EditableControlBox style={getElementStyle(element, expandable ? expandable.expanded : false)}
				showActions={displayRequestEditPopup}
				onAdd={elementTypesWithChildren.includes(element.type) && onAddElement ? onAddElementFactory(element) : undefined}
				onRequestEdit={element.type === "template" && onRequestTemplateEdit ? onRequestTemplateEditClicked : undefined}
				onEdit={onEditElement && onEditElementFactory(element)}
				onDelete={onDeleteElement && onDeleteElementFactory(element)}
				expandable={expandable ? {
					onExpand: onExpandElementFactory(element),
					expanded: expandable.expanded,
				} : undefined}
				body={
					<ElementDetail
						type={element.type}
						description={getElementDescription(element)}
						isChild={(element.isChild ?? false) && !expandable}
						title={getElementTitle(element)}
					/>}
			/>
		</FlexBox>
		{element.type === "template" && <RequestEditPopup anchorEl={controlBoxRef.current} open={displayRequestEditPopup} onCancel={invertRequestEditPopupDisplay} onSubmit={onRequestTemplateEditFactory}/>}
	</FlexBox>
}
export {Element};