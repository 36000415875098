import {ensureArgs} from "../filterUtilities";
import {isString} from "../../../guards/isString";
import {NunjucksFilter, NunjucksFilterFactoryProps} from "../model";

function execute(...args: unknown[]): unknown {
	if (!ensureArgs(args, 2)) {
		return false;
	}

	const input = isString(args[0]) ? args[0] : undefined;
	const stringToMatch = isString(args[1]) ? args[1] : undefined;

	return input !== undefined && stringToMatch !== undefined && input.includes(stringToMatch);
}

export function includesFilterFactory(props?: NunjucksFilterFactoryProps): NunjucksFilter {
	return ({
		name: "includes",
		help: "Checks if a string includes another string as a substring",
		example: 'var | includes("test")',
		execute
	})
}