import {DropDown, DropDownItem} from "../../../../../../../../shared/components/input/dropdown";
import {Autocomplete} from "@mui/lab";
import {TextField} from "../../../../../../../../lib/ui";
import {ChangeEvent, useState} from "react";
import {DataSourceMapType, PropertyEditorProps} from "./models";
import {useRunWhenValueChange} from "../../../../../../../../shared/hooks";
import {VariableInput} from "../model";


interface DataSourceState {
	source?: string,
	type?: DataSourceMapType,
	columnHeaders?: string,
	rowHeaders?: string
}
interface DataSourceOptions {
	source?: string,
	type?: DataSourceMapType,
	column_headers?: string[],
	row_headers?: string[]
}

const initialState: DataSourceState = {
	source: undefined,
	type: "auto",
	columnHeaders: undefined,
	rowHeaders: undefined
}

const formatOptions: DropDownItem<DataSourceMapType>[] = [{
	title: "Automatically Detect",
	value: "auto"
}, {
	title: "Columns",
	value: "columns"
}, {
	title: "Rows",
	value: "rows"
}, {
	title: "Columns x Rows",
	value: "columns-rows"
}];

const mapOptionsToState = (options: DataSourceOptions): DataSourceState => {
	return ({
		source: options.source,
		rowHeaders: options.row_headers?.join(", ") ?? "",
		columnHeaders: options.column_headers?.join(", ") ?? "",
		type: options.type
	})
}

const DataSourceProperties = ({onChange, dataSourcesList, value}: PropertyEditorProps) => {
	const variableInfo = value?.data as VariableInput;

	const dataSourceState = typeof(variableInfo.options) !== "undefined" ? mapOptionsToState(variableInfo.options! as DataSourceOptions) :
		initialState;

	const [state, setState] = useState(dataSourceState);
	useRunWhenValueChange(() => {
		onUpdate();
	}, state)
	const onFormatChange = (format: DropDownItem<DataSourceMapType>) => setState(s => ({...s, type: format.value}));
	const onColumnHeadersChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setState(s => ({...s, columnHeaders: e.target.value}));
	const onRowHeadersChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setState(s => ({...s, rowHeaders: e.target.value}));
	const onDataSourceChange = (source: string | null) => setState(s => ({...s, source: source ?? undefined}));
	const onUpdate = () => {
		const options: DataSourceOptions = {
			source: state.source,
			type: state.type,
			column_headers: state.columnHeaders?.split(",").map(header => header.trim()) ?? [],
			row_headers: state.rowHeaders?.split(",").map(header => header.trim()) ?? []
		}
		onChange(options);
	}

	return (
		<>
			<Autocomplete
				freeSolo={true}
				clearOnBlur={false}
				onChange={(event, newValue) => onDataSourceChange(newValue)}
				value={state.source}
				options={dataSourcesList.map(dataSource => dataSource.url)}
				getOptionLabel={function(item) {
					return(item);
				}}
				renderInput={(params) => {
					return (<TextField
						label={"Source"}
						variant={"outlined"}
						onChange={(event) => onDataSourceChange(event.target.value)}
						{...params}
						margin="normal"
					/>);
				}}
			/>
			<TextField onChange={onColumnHeadersChange} value={state.columnHeaders} label={"Columns"} placeholder={"Columns"} />
			<TextField onChange={onRowHeadersChange} value={state.rowHeaders} label={"Rows"} placeholder={"Rows"} />
			<DropDown items={formatOptions} onSelect={onFormatChange} value={state.type} />
		</>
	)
}

export {DataSourceProperties}