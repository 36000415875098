import {DocumentElementDefinition} from "../model";
import PreviewView from "./PreviewView";
import {
	citationsDefinition as baseCitationsDefinition
} from "../../../../../shared/definitions/elements/citations/model";

const citationsDefinition: DocumentElementDefinition = {
	...baseCitationsDefinition,
	views: {
		preview: PreviewView
	},
} as const

const citationsValue = {
	label: "List",
	labelValue: "Default Citations"
}


export {citationsDefinition, citationsValue};