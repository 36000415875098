import { ReadonlyElementProps } from "../../model";
import { HeaderElementValue } from "../../../../../../shared/definitions/elements/header/model";
import { styled } from "@mui/material";
import { EmptyView } from "../../EmptyView";
import { CommentState } from "../../../../hooks/useCommentState";
import { HighlightWrapper } from "../../../../../../shared/components/input/highlight/HighlightWrapper";
import CommentModal from "../../../modals/templateComments/CommentModal";
import { CommentModalProps } from "../../../../../../shared/definitions/highlight/CommentModal.props";

const Header = styled("h1")({
	fontWeight: 600,
	fontSize: "2rem",
	margin: 0
})

const ReadonlyView = (props: ReadonlyElementProps) => {
	const editableValue = props.data as HeaderElementValue;

	const commentsManager = CommentState.useContainer();

	const highlightComments = commentsManager.highlightedCommentsByElementId(props.id);

	return editableValue.value ?
		<HighlightWrapper
			elementId={props.id}
			enabled={true}
			highlightComments={highlightComments}
			value={editableValue.value}
			componentToWrap={(highlightedText) => <Header dangerouslySetInnerHTML={{ __html: highlightedText }} />}
			renderCommentModal={ (values: CommentModalProps) =>
				<CommentModal
					onClose={values.onClose}
					addEditComment={"Add"}
					showOpen={values.showOpen}
					elementId={values.elementId}
					selectedText={values.selectedText}
					textProperties={values.textProperties}
				/>
			}
		/> : <EmptyView message={"No header has been added"} />;
}

export { ReadonlyView };