import {useState} from "react";
import {createContainer} from "unstated-next";
import {useAsync, useTypedAsync} from "./useAsync";
import { User } from '../interfaces/User';
import {ApplicationAdminRole, ApplicationAdminRoleMatcher} from "../interfaces/ApplicationAdminRoles.js";
import {getUserInfoApi} from "./api/getUserInfoApi";
import {useRunOnce} from "./useRunOnce";
import {useRunWhenValueChange} from "./useRunWhenValueChange";
import {TryAsync} from "../interfaces/Either";
import {useCache} from "./cache/useCache";
import {useFeatureFlags} from "./useFeatureFlags";
import {useUserGroupsLookup} from "./useUserGroupsLookup";
import api from "../../api/api";

async function getUserDisplayNameApi(userId: string): Promise<string> {
	const userResponse = await TryAsync(() => getUserInfoApi(userId));
	return userResponse.map(user => user.display_name).matchWith({left: _ => "", right: name => name ?? ""});
}

function logUserOut(): Promise<{endpoint: string}> {
	return api.call('POST', `user/@current/logout`)
}


type ApplicationUserPermissions = {
	hasApplicationAdminRole: ApplicationAdminRoleMatcher,
	userPermissionsEnabled: boolean,
	checkingUserFeatures: boolean,
}

type ApplicationUserGroupsLookup = {
	groupsLookup: Record<ApplicationAdminRole, string> | null
}
export type ApplicationUser = User & ApplicationUserPermissions

function useUser(): ApplicationUser & {getUserDisplayname: (userId: string) => Promise<string>, isAuthenticated: boolean, logout: () => void} {
	const [state, setState] = useState<User & Omit<ApplicationUserPermissions, "hasApplicationAdminRole"> & ApplicationUserGroupsLookup>({
		name: '',
		id: '',
		display_name: "",
		groups: [],
		groupsLookup: null,
		isSuperUser: false,
		userPermissionsEnabled: true,
		checkingUserFeatures: true
	});

	const cache = useCache(10);
	const loadUserInfo = useAsync(getUserInfoApi, false);
	const loadUserGroupLookup = useUserGroupsLookup();

	const checkFeatureFlag = useFeatureFlags();
	useRunOnce(() => {
		loadUserInfo.execute();
		loadUserGroupLookup.execute();
		checkFeatureFlag.execute("userpermissions");
	});

	useRunWhenValueChange(() => {
		if (loadUserInfo.value) {
			setState(s => ({...s,
				id: loadUserInfo.value!.id,
				display_name: loadUserInfo.value!.display_name,
				name: loadUserInfo.value!.name,
				groups: loadUserInfo.value!.groups,
				isSuperUser: loadUserInfo.value!.isSuperUser
			}));
		}
	}, loadUserInfo.value);

	useRunWhenValueChange(() => {
		setState(s => ({...s, groupsLookup: loadUserGroupLookup.value}))
	}, loadUserGroupLookup.value)

	useRunWhenValueChange(() => {
		setState(s => ({...s, userPermissionsEnabled: checkFeatureFlag.value?.enabled ?? true, checkingUserFeatures: false}));
	}, checkFeatureFlag.value);

	useRunWhenValueChange(() => {
		if (checkFeatureFlag.error) {
			setState(s => ({...s, userPermissionsEnabled: true, checkingUserFeatures: false}));
		}
	}, checkFeatureFlag.error)

	const getUserDisplayname = async (userId: string): Promise<string> => {
		const cachedName = await cache.getItem(`displayname-${userId}`, () => getUserDisplayNameApi(userId));
		return cachedName ?? "";
	}

	const getGroupNameForRole = (adminRole: ApplicationAdminRole) => state.groupsLookup === null ? undefined : state.groupsLookup[adminRole];
	const hasApplicationAdminRole = (adminRole: ApplicationAdminRole) => {
		if (!state.userPermissionsEnabled) {
			return true;
		}

		const groupNameForRole = getGroupNameForRole(adminRole);
		return groupNameForRole !== undefined && state.groups.includes(groupNameForRole);
	}

	const logout = () => logUserOut();

	return {...state, isAuthenticated: state.id !== '', getUserDisplayname, hasApplicationAdminRole, logout};
}

export function useUserLogout() {
	return useTypedAsync(logUserOut, false);
}

const UserManager = createContainer(useUser);

export {UserManager};