import {Column, ColumnViewProps} from "../../../../../../shared/components/layout/table";
import {ContentNode} from "../../../../models/content";
import {contentListActions} from "../models";
import { NameTableCell } from "../../../../../../shared/components/layout/table/styles";
import { EllipseBadge } from "../../../../../../shared/components/labels/EllipseBadge";

const Cell = (props: ColumnViewProps) => {
	const item = props.item as ContentNode;

	//initialize oldName and newName to the same
	const oldName = item.name;
	let newName = item.name

	//check the length of the name and reassign newName
	const isNotValidNameLength = item.name.length > 45
	if(isNotValidNameLength){
		newName  = item.name.substring(0,45) ;
	}
	const onRowClicked = () => {
		if (props.onAction) {
			props.onAction(contentListActions.view, props.column);
		}
	}
	return <NameTableCell depth={0} expandable={false}>
		<span className={'row_title'} onClick={onRowClicked}>{newName}</span>{isNotValidNameLength?<EllipseBadge name={oldName}></EllipseBadge>:null}
	</NameTableCell>
}


const Name: Column = ({
	field: 'name',
	title: "Name",
	itemAlign: "left",
	headerAlign: "left",
	width: "25%",
	searchable: true,
	sortable: true,
	views: {
		cell: Cell
	}
})

export {Name};