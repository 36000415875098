import {Box, Toolbar, Typography, InputAdornment} from "@mui/material";
import {Button} from "../../../../../shared/components";
import {TextField} from "../../../../../shared/components/input";
import {Search} from "@mui/icons-material";
import React from "react";

export interface TableTitleProps {
	title?: string,
	onResetSort: () => void,
	onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	isSorting: boolean,
	searchText: string
}
const TableTitle = (props: TableTitleProps) => {
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				justifyContent: "space-between"
			}}
		>
			<Typography
				variant="h6"
				id="tableTitle"
				component="div"
				sx={{fontWeight: 700}}
			>
				{props.title ?? "Table title"}
			</Typography>
			<Box display={"flex"}>
				<TextField value={props.searchText} onChange={props.onSearchChange} InputProps={{endAdornment: (<InputAdornment position={"end"}>
					<Search />
				</InputAdornment>)}} />
				{props.isSorting && <Button text={"Reset sort"} onClick={props.onResetSort} sx={{minWidth: "100px", marginLeft: ".5rem"}} />}
			</Box>
		</Toolbar>
	);
}

export {TableTitle}