import { ContextButtonFactory, DefaultContextButtons } from '../../../../shared/components/buttons/theme'
import {ModuleLayout} from "../../../../shared/components/layout/ModuleLayout";
import {AsyncComponent, FlexBox, MessageBox, MessageBoxActions} from "../../../../shared/components";
import Masthead from "../../../../shared/components/navigation/masthead";
import { useList } from './useList';
import React, {useState} from "react";
import {FilterState} from "../../../../shared/components/input/filter/sort/models";
import {useRunOnce, useRunWhenValueChange} from "../../../../shared/hooks";
import {SubmissionNode} from "../../models";
import {MessageBoxActionType} from "../../../../shared/components/modals/messageBox/models";
import {ListPageWrapper} from "../../../../shared/components/layout/ListPageWrapper";
import {SubmissionList} from "../../components/list/submissions/SubmissionList";
import { UserManager } from '../../../../shared/hooks/useUser';
import {ApplicationAdminRoleLookup} from '../../../../shared/interfaces/ApplicationAdminRoles';

const List = () => {
	const submissionManager = useList();
	const [page, setPage] = useState(0);
	const [submissionWithError, setSubmissionWithError] = useState<SubmissionNode | undefined>(undefined);
	// delete confirmation //
	const [confirmDelete, setConfirmDelete] = useState<{open: boolean, pendingContent: SubmissionNode | null}>({open: false, pendingContent: null});
	/** Submission Menu Item should be visible only if the user has the role for eCTD administration */
	const userData = UserManager.useContainer();
	/** Submission Menu Item should be visible only if the user has the role for eCTD administration */
	const buttonList = userData.hasApplicationAdminRole(ApplicationAdminRoleLookup.administer_eCTD) ? [DefaultContextButtons.New] : [];

	useRunOnce(() => submissionManager.loadSubmissions());
	useRunWhenValueChange((prevPage) => {
		if ((prevPage ?? 0) < page && submissionManager.content.length <= (page * submissionManager.pageSize)) {
			submissionManager.setAfter(submissionManager.after)
		}
	}, page);


	const onDelete = (contentNode: SubmissionNode) => {
		setConfirmDelete({open: true, pendingContent: contentNode});
	}

	const onDownloadSubmission = (contentNode: SubmissionNode) => submissionManager.downloadSubmission(contentNode);
	const onViewSubmissionError = (contentNode: SubmissionNode) => setSubmissionWithError(contentNode);
	const onCloseViewError = () => setSubmissionWithError(undefined);
	const onContextButtonClick = (key: string) => {
		switch(key) {
			case DefaultContextButtons.New.key:
				submissionManager.createNewSubmission();
				break;
			default:
				break;
		}
	}

	const onMessageBoxAction = (action: MessageBoxActionType) => {
		if (action === MessageBoxActions.Primary) {
			submissionManager.onDelete(confirmDelete.pendingContent!);
		}
		setConfirmDelete({open: false, pendingContent: null});
	}

	const onMessageBoxClose = () => onMessageBoxAction(MessageBoxActions.Secondary)

	const onPageChange = (event: unknown, newPage: number) => setPage(newPage);
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const pageSize = Number(event.target.value);
		submissionManager.setPageSize(pageSize);
		setPage(0);
	}
	const onSearchChange = (field?: string, searchValue?: string) => {
		if (searchValue !== undefined && field) {
			const didUpdateFilters = submissionManager.setFilter({
				field, value: searchValue ?? ""
			});

			if (didUpdateFilters) {
				setPage(0);
			}
		}
	}

	const onSort = (field: string, sortDirection: FilterState) => {
		submissionManager.setSort(sortDirection === "none" ? undefined : {field, direction: sortDirection === "ascending" ? "asc": "desc"})
		setPage(0);
	}

	const getCurrentSortDirection = (): FilterState => {
		if (submissionManager.sort?.direction === "asc") {
			return "ascending";
		} else if (submissionManager.sort?.direction === "desc") {
			return "descending"
		}

		return "none";
	}

	return <FlexBox direction={"column"} height={"100%"}>
		<Masthead
			contextButtons={ContextButtonFactory(
				buttonList,
				onContextButtonClick)}
		/>
		<ModuleLayout
			center={<AsyncComponent isLoading={submissionManager.isRunning} component={<ListPageWrapper>
				<SubmissionList
					page={page}
					pageSize={submissionManager.pageSize}
					filters={(submissionManager.filters ?? []).reduce((lookup, filter) => {
						if (filter.field === undefined) {
							return lookup;
						}

						lookup[filter.field] = filter.value;
						return lookup;
					}, {})}
					total={submissionManager.total}
					onDelete={onDelete}
					onPageChange={onPageChange}
					onDownloadZip={onDownloadSubmission}
					onViewError={onViewSubmissionError}
					onPageSizeChange={onPageSizeChange}
					onColumnSearch={onSearchChange}
					onRowSelect={submissionManager.onNavigateToSubmission}
					onSort={onSort}
					submissions={submissionManager.content ?? []}
					sort={{field: submissionManager.sort?.field ?? "", sortDirection: getCurrentSortDirection()}} />
			</ListPageWrapper>} />}
		/>
		<MessageBox
			destructive
			visible={confirmDelete.open}
			message={<>Are you sure you want to delete <b>{confirmDelete.pendingContent?.name}</b>?</>}
			title={"Delete Content"}
			primaryAction={"Delete"}
			secondaryAction={"Cancel"}
			onActionClick={onMessageBoxAction}
			onClose={onMessageBoxClose}
		/>
		<MessageBox visible={submissionWithError?.extractionError !== undefined} message={submissionWithError?.extractionError ?? ""} primaryAction={"Ok"} onActionClick={onCloseViewError} onClose={onCloseViewError} />
	</FlexBox>

}

export {List};