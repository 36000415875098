type GlobToRegexProps = {
	glob: string,
	options?: {
		extended: boolean,
		globstar: boolean,
		flags: string
	}
}
export function globToRegex({glob, options}: GlobToRegexProps) {
	let regex: string = "";
	const extended = options?.extended ?? false;
	const globstar = options?.globstar ?? false;
	const flags = options?.flags ?? "";

	let inGroup = false;
	for (let i = 0, len = glob.length; i < len; i++) {
		const character = glob[i];
		switch (character) {
			case "/":
			case "$":
			case "^":
			case "+":
			case ".":
			case "(":
			case ")":
			case "=":
			case "!":
			case "|":
				regex += "\\" + character;
				break;
			case "?":
				if (extended) {
					regex += ".";
				}
				break;
			case "[":
			case "]":
				if (extended) {
					regex += character;
				}
				break;
			case "{":
				if (extended) {
					inGroup = true;
					regex += "(";
				}
				break;
			case "}":
				if (extended) {
					inGroup = false;
					regex += ")";
				}
				break;
			case ",":
				if (inGroup) {
					regex += "|";
					break;
				}
				regex += "\\" + character;
				break;
			case "*": {
				// Move over all consecutive "*"'s.
				// Also store the previous and next characters
				const prevChar = glob[i - 1];
				let starCount = 1;
				while (glob[i + 1] === "*") {
					starCount++;
					i++;
				}
				const nextChar = glob[i + 1];

				if (!globstar) {
					// globstar is disabled, so treat any number of "*" as one
					regex += ".*";
				} else {
					// globstar is enabled, so determine if this is a globstar segment
					const isGlobstar = starCount > 1                      // multiple "*"'s
						&& (prevChar === "/" || prevChar === undefined)   // from the start of the segment
						&& (nextChar === "/" || nextChar === undefined)   // to the end of the segment

					if (isGlobstar) {
						// it's a globstar, so match zero or more path segments
						regex += "((?:[^/]*(?:/|$))*)";
						i++; // move over the "/"
					} else {
						// it's not a globstar, so only match one path segment
						regex += "([^/]*)";
					}
				}
				break;
			}

			default:
				regex += character;
		}
	}

	// When regexp 'g' flag is specified don't
	// constrain the regular expression with ^ & $
	if (!flags || !~flags.indexOf('g')) {
		regex = "^" + regex + "$";
	}

	return new RegExp(regex, flags);
}