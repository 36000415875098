import {useEffect, useState} from "react";

function useMousePosition() {
	const [position, setPosition] = useState({ x: 0, y: 0 });

	useEffect(() => {
		const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
		window.addEventListener("mousemove", setFromEvent);

		return () => {
			window.removeEventListener("mousemove", setFromEvent);
		};
	}, []);

	return position;
}

export {useMousePosition}