import { FlexBox } from "../FlexBox"
import {TabDefinition} from "./tabDefinition";
import { TabItem, TabContainer } from "./Tabs.style";
export interface TabsProps {
	tabs: TabDefinition[],
	direction: "row" | "column",
	selectedTabIndex: number,
	onTabSelected: (tabIndex: number) => void
}

const Tabs = ({tabs, onTabSelected, direction, selectedTabIndex}: TabsProps) => {

	return <FlexBox justify="flex-start">
		<TabContainer direction={direction}>
			{tabs.map((tab, index) =>

				<TabItem direction={direction}
					className={selectedTabIndex === index ? 'active' : ""}
					onClick={() =>{onTabSelected(index)}}
					key={tab.name}
				>
					{tab.decorator && <tab.decorator/>}
					{tab.name}
				</TabItem>
			)}
		</TabContainer>
	</FlexBox>
};

export {Tabs}