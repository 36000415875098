import {DropDown, DropDownItem} from "../../../../../../../../shared/components/input/dropdown";
import {Autocomplete} from "@mui/lab";
import {StyleAttribute, styleParameters, tableStyleParameters} from "./styles";
import {ElementProps} from "../models";
import {TextField} from "@mui/material";
import {StyleComponentMap} from "./model";

export type StyleElementTypes = "section" | "title" | "html" | "table" | "default"
const elementTypes: DropDownItem<StyleElementTypes>[] = [
	{
		title: "Section",
		value: "section"
	}, {
		title: "Title",
		value: "title"
	}, {
		title: "Html",
		value: "html"
	}, {
		title: "Table",
		value: "table"
	}, {
		title: "Default",
		value: "default"
	}
]

export type StyleElementValue = {
	element_type: StyleElementTypes
} & Partial<Record<StyleAttribute, string | undefined | null>>

const View = ({option, value, onChange}: ElementProps) => {
	const data = value as StyleElementValue ?? {element_type: "section"};

	const onChangeValue = (key: string, newValue: string | null) => onChange({...data, [key]: newValue ?? ""});
	const onSelectElementType = (elementType: DropDownItem<StyleElementTypes>) => onChangeValue("element_type", elementType.value);

	const getValueWithKey = (key: string) => data && data[key] ? data[key] ?? "" : "";
	const getSelectedStyleElement = () => elementTypes.find(elementType => elementType.value === getValueWithKey("element_type"));

	return (<>
		<DropDown items={elementTypes} onSelect={onSelectElementType} value={getSelectedStyleElement()?.value} label={"Select"} />
		{(getSelectedStyleElement()?.value === "table" ? tableStyleParameters : styleParameters).map(styleParam => <Autocomplete
			freeSolo={true}
			clearOnBlur={false}
			onChange={(event, newValue) => onChangeValue(styleParam.attribute, newValue)}
			value={getValueWithKey(styleParam.attribute)}
			options={styleParam.values && styleParam.values.map(style => style.value)}
			getOptionLabel={function(item) {
				return(item);
			}}
			renderInput={(params) => {
				return (<TextField
					label={styleParam.title}
					variant={"outlined"}
					onChange={(event) => onChangeValue(styleParam.attribute, event.target.value)}
					{...params}
					margin="normal"
				/>);
			}} />)
		}
	</>)
}

const AddStyleElement: StyleComponentMap = {
	type: "StyleElement",
	component: View,
	valueFactory: (): StyleElementValue => ({
		element_type: elementTypes[0].value,
	})
}
export {AddStyleElement}