import {EctdModule} from "../../../shared/interfaces/Module";

export function computeCompletion(module: EctdModule): number {
	const sections = numberOfCompleteSections(module);
	return Math.floor((sections.completeSections * 100) / (sections.sectionCount * 100) * 100);
}

function numberOfCompleteSections(module: EctdModule): {sectionCount: number, completeSections: number} {
	const isTopLevelSection = module.moduleNumber.indexOf(".") < 0;
	if ((module.modules?.length ?? 0) === 0) {
		return (module.content?.length ?? 0) > 0 ? {sectionCount: isTopLevelSection ? 0 : 1, completeSections: 1} : {sectionCount: isTopLevelSection ? 0 : 1, completeSections: 0};
	}

	const childSections = module.modules.reduce((all, current) => {
		all.push(numberOfCompleteSections(current));
		return all;
	}, [] as {sectionCount: number, completeSections: number}[]);

	return childSections.reduce((sections, child) => {
		sections.sectionCount += child.sectionCount;
		sections.completeSections += child.completeSections;
		return sections;
	}, {sectionCount: isTopLevelSection ? 0 : 1, completeSections: (module.content?.length ?? 0) > 0 ? 1 : 0});

}