import {EctdModule} from "../../../../../../shared/interfaces/Module";
import {Box} from "@mui/system";
import {ModuleNavigation} from "../../../../components/list/moduleNavigation";
import {ModuleTable} from "../../../../components/list/moduleTable";
import {NameColumn, NumberColumn, CompletionColumn} from "../../../../components/list/moduleTable/columns";
import "./styles.scss"

export type CenterPaneProps = {
	selectedModule: EctdModule,
	selectedSection?: EctdModule,
	modules: EctdModule[],
	onSelectModule: (module: EctdModule) => void,
	onSelectSection: (section: EctdModule) => void
}

const tableColumns = [NumberColumn, NameColumn, CompletionColumn];
const CenterPane = (props: CenterPaneProps) => {
	const onRowClick = (row: EctdModule) => props.onSelectSection(row);
	return <Box className={"ectd__sdcp"}>
		<ModuleNavigation onSelectModule={props.onSelectModule} modules={props.modules} selectedModule={props.selectedModule} />
		<Box className={"ectd__sdcp-table"}>
			{props.selectedSection && <ModuleTable module={props.selectedSection} columns={tableColumns} onRowClick={onRowClick} />}
		</Box>
	</Box>
}

export {CenterPane};