import React from "react";
import {ColumnDefinition, Table} from "../../../../../shared/components/layout/table";
import { ModelDocument } from "./columns/ModelDocument";
import { Owner } from "./columns/Owner";
import { Actions } from "./columns/Actions";
import { TemplateListNode, TemplateTypes } from "./models";
import { LastModified } from "./columns/LastModified";
import { Status } from "./columns/Status";
import { Name } from "./columns/Name";
import { DisplayedTemplatesSwitch } from "./DisplayedTemplatesSwitch";
import {FilterState} from "../../../../../shared/components/input/filter/sort/models";
import {TemplateListRow} from "./TemplateListRow";
import {accessoryForColumn} from "./columns";
import { ListPageWrapper } from "../../../../../shared/components/layout/ListPageWrapper";
export interface TemplatesListProps {
    templates: TemplateListNode[],
    onRowSelected: (row: TemplateListNode) => void,
    selectedTemplate: string | null,
	onViewTasks: (template: TemplateListNode) => void,
	onDeleteTemplate: (template: TemplateListNode) => void,
	onError: (message: string) => void,
	displayedTemplatesType: TemplateTypes,
	// what template list should be shown
	onTemplateDisplayChange: (type: TemplateTypes) => void,
	filters?: {[field: string]: string},
	sort: {field: string, sortDirection: FilterState},
	onPageChange: (event: unknown, newPage: number) => void,
	onPageSizeChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
	onColumnSearch: (field?: string, value?: string) => void,
	onSort: (field: string, sortDirection: FilterState) => void,
	pageSize: number,
	page: number,
	total: number
}

const columns: ColumnDefinition[] = [Name, ModelDocument, Status, Owner, LastModified, Actions]
function TemplatesList({
	templates,
	onRowSelected,
	selectedTemplate,
	onViewTasks,
	onError,
	onDeleteTemplate,
	onTemplateDisplayChange,
	displayedTemplatesType,
	filters,
	sort,
	onPageSizeChange,
	onPageChange,
	onColumnSearch,
	onSort,
	pageSize,
	page,
	total
}: TemplatesListProps) {

	const handleRowSelected = (item: unknown) => {
		const templateNode = item as TemplateListNode;
		onRowSelected(templateNode);
	}

	const handleRowRender = (item: unknown) => {
		return (
			<TemplateListRow
				key={(item as TemplateListNode).id}
				depth={0}
				item={item}
				columns={columns}
				onSelected={handleRowSelected}
				selected={selectedTemplate}
				onDeleteTemplate={onDeleteTemplate}
				onViewTasks={onViewTasks}
				onError={onError}/>);
	}

	const handleColumnAccessoryRender = (column: ColumnDefinition) => {
		const Accessory = accessoryForColumn(column.field);
		return Accessory ? <Accessory column={column} /> : null
	}

	return (
		<ListPageWrapper>
			<Table
				items={templates}
				title={`${displayedTemplatesType === "toplevel" ? "Wrapper " : "Sub-"}Templates`}
				sort={sort}
				total={total}
				currentPage={page}
				pageSize={pageSize}
				filters={filters}
				titleComponent={<DisplayedTemplatesSwitch onTemplateDisplayChange={onTemplateDisplayChange} displayedTemplatesType={displayedTemplatesType}/>}
				columns={columns}
				onColumnSearch={onColumnSearch}
				onSort={onSort}
				onChangePage={onPageChange}
				onChangePageSize={onPageSizeChange}
				onRenderRow={handleRowRender}
				onRenderColumnAccessory={handleColumnAccessoryRender}
			/>
		</ListPageWrapper>
	)

}

export {TemplatesList}