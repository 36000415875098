import {VariableEditor, VariableEditorProps} from "../model";
import MaterialTableStyled from "../../../../../../../components/MaterialTableStyled";
import {TableElementFootnote} from "../../../../../../../shared/definitions/elements/table/model";
const uuid = require("uuid");

const columns = [
	{title: 'Row, Column or Cell', field: 'value'},
	{title: 'Name or Description', field: 'name'},
]
const Editor = (props: VariableEditorProps) => {
	const value = props.variable.value as TableElementFootnote[];
	// returning a promise because material table callbacks require a promise
	const onChangeValue = (newValue: TableElementFootnote[]) => new Promise<void>((resolve) => {
		props.onChange(props.variable, newValue);

		resolve();
	})

	const onAddFootnote = (footnote: TableElementFootnote) => onChangeValue([...(value ?? []), {...footnote, id: uuid.v4()}])

	const onDeleteFootnote = (footnote: TableElementFootnote) => {
		const footnotes = (value ?? []).filter(f => f.id !== footnote.id);
		return onChangeValue(footnotes);
	}

	const onUpdateFootnote = (footnote: TableElementFootnote, oldFootnote: TableElementFootnote) => {
		const footnoteIndex = (value ?? []).findIndex(f => f.id === footnote.id);
		if (footnoteIndex < 0) {
			return Promise.resolve() as Promise<void>
		}
		const footnotes = [...(value ?? [])];
		footnotes[footnoteIndex] = footnote;
		return onChangeValue(footnotes);
	}

	const editableEvents = {
		onRowAdd: onAddFootnote,
		onRowDelete: onDeleteFootnote,
		onRowUpdate: onUpdateFootnote
	}
	return <MaterialTableStyled
		columns={columns}
		data={value ?? []}
		title={props.variable.name}
		editable={editableEvents} />
}

const MultiInputEditor: VariableEditor = {
	type: "multi_input",
	view: Editor
}

export {MultiInputEditor}