import {useTypedAsync} from "../../../../shared/hooks";
import {useUserList} from "../../../../shared/hooks/useUserList";
import {Tasks} from "../../../../shared/interfaces/task";
import {getDocumentTasksApi} from "./getDocumentTasksApi";
import {SearchApiResponse} from "../../../../shared/hooks/api/searchApi";
import {searchDocumentsApi, SearchDocumentsQuery} from "../../../../shared/hooks/api/useGetDocuments";
import {NotSuperDocumentFilter} from "../../../../shared/hooks/api/search/filters/notSuperDocument";

export type DocumentFilter = {
	field: string,
	value: string
}
export type DocumentSort = {
	field: string,
	direction: "asc" | "desc"
}

export type DocumentPermissions = {owners: string[]}

export type GetDocumentsFunction = (query?: SearchDocumentsQuery) => Promise<SearchApiResponse<KaiAlphaDocumentWithTasks>>

type KaiAlphaDocumentWithTasks = (KaiAlphaDocument & {owners: string[], hasTask: boolean})
function useGetAllDocuments() {
	const usersList = useUserList();

	async function loadDocumentTasks (document: KaiAlphaDocument) {
		try {
			return await getDocumentTasksApi(document.id);
		} catch {
			return {tasks: []}
		}
	}

	const loadDocuments: GetDocumentsFunction = async (props?: SearchDocumentsQuery) => {
		const allUsers = await usersList.getUserNames();
		const response = await searchDocumentsApi({...(props ?? {}), filters: [...(props?.filters ?? []), NotSuperDocumentFilter]});
		const tasks: Tasks[] = await Promise.all(response.results.map(loadDocumentTasks));

		/** For Tasks which as review and approved initiated (status is open), result attribute is not present */
		const documentIdsForTasks = [...new Set(tasks.map(collection => collection.tasks.filter(task => (task?.source?.id ?? "").length > 0).map(task => task.source.id)).reduce((all, documentIds) => all.concat(documentIds), []))];

		const results = response.results.map(item => {
			const permissions = item.permissions as DocumentPermissions;
			const owners = permissions.owners?.map(owner => allUsers?.map[owner]?.display_name ?? "Unknown User") ?? [];
			const hasTask = documentIdsForTasks.includes(item.id);
			return {...item, owners, hasTask } as KaiAlphaDocumentWithTasks;
		});

		return {...response, results};

	}

	return useTypedAsync(loadDocuments, false);
}

export {useGetAllDocuments};