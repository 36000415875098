import {TextEditor} from "./TextEditor";
import {VariableType} from "../../../../../../shared/interfaces/Variable";
import {TextAreaEditor} from "./TextAreaEditor";
import React from "react";
import {VariableEditorProps} from "./model";
import {RichTextEditor} from "./RichTextEditor";
import {DataSourceEditor} from "./DataSourceEditor";
import { DropDownEditor } from "./DropDownEditor";
import { CheckboxEditor } from "./CheckboxEditor";
import { ListEditor } from "./ListEditor";
import { ImageEditor } from "./ImageEditor/ImageEditor";
import { ReferenceEditor } from "./ReferenceEditor/ReferenceEditor";
import { MultiInputEditor } from "./MultiInputEditor";
import {InTextTableEditor} from "./InTextTableEditor";

// We are not including an expression variable editor since expressions are evaluated inside the
// center pane. So to accomodate we hide any expression variables from the variables tab.
const editors = [TextEditor, TextAreaEditor, RichTextEditor, DataSourceEditor, DropDownEditor, CheckboxEditor, ListEditor, ImageEditor, MultiInputEditor, ReferenceEditor, InTextTableEditor];
function variableEditorForType(type: VariableType): React.FC<VariableEditorProps> | undefined {
	return editors.find(editor => editor.type === type)?.view;
}

export {variableEditorForType}