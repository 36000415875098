import {EctdModule} from "../../../shared/interfaces/Module";

export function moduleNumberForContent(module: EctdModule, moduleNumber: string): string[] {
	if (module.moduleNumber === moduleNumber) {
		return [module.moduleNumber];
	}

	const matchingChildren = (module.modules ?? []).filter(m => moduleNumber.includes(m.moduleNumber));
	if (matchingChildren.length === 0) {
		return [];
	}


	return [module.moduleNumber, ...matchingChildren.map(m => moduleNumberForContent(m, moduleNumber)).reduce((all, current) => all.concat(current), [])]
}