import {EntityType} from "../../../../interfaces/EntityType";
import {useTypedAsync} from "../../../useAsync";
import {elasticQueryApi} from "./elasticQueryApi";
import {ElasticQuery} from "./QueryRequest";
import {QueryResponse} from "../QueryResponse";


export function useElasticQuery<T>(type: EntityType) {
	const execute = (query: ElasticQuery) => elasticQueryApi<T>({type, query: {body: {...query}}});
	return useTypedAsync<ElasticQuery, QueryResponse<T>>(execute, false);
}