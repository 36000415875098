import {Tooltip as T, TooltipProps, tooltipClasses, styled} from '@mui/material';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
	<T {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.plain?.contrastText,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.plain?.contrastText,
		fontSize: "0.8rem",
		fontWeight: 400,
		borderRadius: '.5rem',
	},
}));

export {Tooltip};