import {styled} from "@mui/material";
import {FlexBox} from "../../../../../shared/components";

const VariableInfoBox = styled(FlexBox)(({theme}) => ({
	fontSize: ".875rem",
	color: "#426AB9",
	justifyContent: "space-between",
	alignItems: "center",
	"& .type": {
		color: "#333333B3"
	}

}))

export {VariableInfoBox};