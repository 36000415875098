import {EctdModule} from "../../../../../shared/interfaces/Module";
import {ModuleColumnDefinition, ModuleListAction} from "./models";
import {ModuleColumCell} from "./columns/ModuleColumCell";

export type ModuleRowProps = {
	module: EctdModule,
	columns: ModuleColumnDefinition[],
	depth: number,
	onRowAction: (action: ModuleListAction, row: EctdModule) => void
}
const Row = (props: ModuleRowProps) => {
	const onAction = (action: unknown, column: ModuleColumnDefinition) => {
		const rowAction = action as ModuleListAction;
		props.onRowAction(rowAction, props.module);
	}

	return <>
		<tr key={props.module.moduleNumber} style={{cursor: "pointer"}}>
			{props.columns.map(column => <td key={`${props.module.moduleNumber}-${column.field}`}>
				<ModuleColumCell row={props.module} column={column} depth={props.depth} onAction={onAction} />
			</td>)}
		</tr>
		{(props.module.modules ?? []).map(module => <Row onRowAction={props.onRowAction} module={module} columns={props.columns} depth={props.depth+1} />)}
	</>
}

export {Row}