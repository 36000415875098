import api from '../../../../api/api';
import {useTypedAsync} from "../../../../shared/hooks";
import {LoadEntityFunc} from "../../../../shared/interfaces/LoadEntityFuncType";
import {useTemplateStore} from "../useTemplateStore";

export function getTemplateApi(id: string, version?: string) {
	return version !== undefined ? api.call("GET", `template/${id}/versions/${version}`) : api.call('GET', `template/${id}`);
}
function useGetTemplate() {
	const templateStore = useTemplateStore();
	const loadTemplate: LoadEntityFunc<KaiAlphaTemplate> = templateStore.get
	return useTypedAsync(loadTemplate, false);
}

export {useGetTemplate}