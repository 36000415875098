import {SortDirection} from "../../models/SortDirection";
import {SortMode} from "../../models/SortMode";
import {NunjucksColumnDataType} from "../../models/NunjucksColumnDataType";

export type SortOptions = {
	mode: SortMode,
	direction: SortDirection
}
export function sortFunction(a: NunjucksColumnDataType, b: NunjucksColumnDataType, columnName: string, options?: Partial<SortOptions>) {
	const a_cell = a[columnName];
	const b_cell = b[columnName];

	const mode = options?.mode ?? "human";
	const reverse = options?.direction === "desc";

	let a_val, b_val;
	let retval;
	switch(mode) {
		case "human":
			a_val = a_cell;
			b_val = b_cell;
			break;
		case "int":
			a_val = Math.trunc(Number(a_cell));
			b_val = Math.trunc(Number(b_cell));
			break;
		case "float":
			a_val = Number(a_cell);
			b_val = Number(b_cell);
			break;
		default:
			throw(new kaialpha.UserError(`Unsupported sorting mode ${mode}, must be one of human, int, float`));
	}

	if (a_val === b_val) {
		retval = 0;
	} else if (a_val < b_val) {
		retval = -1;
	} else {
		retval = 1;
	}

	if (reverse) {
		retval *= -1;
	}

	return(retval);
}