import {EntityMetaData} from "../../../../../../shared/interfaces/EntityMetaData";

type MetaTagsProps = {
	metadata?: EntityMetaData
}
const MetaTags = (props: MetaTagsProps) => {
	const compound = props.metadata?.user?.Compound;
	const indications = JSON.parse(props.metadata?.user?.Indication ?? "[]") as string[];
	return <>
		{compound && <span className={"ectd__modal--contentpicker-list_item__meta-tags__tag compound"}>{compound}</span>}
		{indications.map(indication => <span key={indication} className={"ectd__modal--contentpicker-list_item__meta-tags__tag indication"}>{indication}</span>)}
	</>
}

export {MetaTags}