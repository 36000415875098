import {EditableControlBox, FlexBox} from "../../../../shared/components";
import {VariableInfoBox} from "../layout/box/VariableInfoBox";
import {VariableElement} from "../../models/elements";

type VariableEvent = (variable: VariableElement) => void
export interface VariableListProps {
	onEdit?: VariableEvent,
	onDelete?: VariableEvent,
	variables: VariableElement[]
}
const VariableList = (props: VariableListProps) => {
	const onEditVariableHandlerFactory = (variable: VariableElement) => (variable.template === undefined || variable.template === "") && props.onEdit ? () => props.onEdit!(variable) : undefined
	const onDeleteVariableHandlerFactory = (variable: VariableElement) => (variable.template === undefined || variable.template === "") && props.onDelete ? () => props.onDelete!(variable) : undefined

	return <FlexBox direction={"column"}>
		{props.variables.map(variable => <EditableControlBox
			onEdit={onEditVariableHandlerFactory(variable)}
			onDelete={onDeleteVariableHandlerFactory(variable)}
			key={variable.id}
			body={<FlexBox align={"center"} className="title_text">
				{variable.contents.name}
			</FlexBox>}
			footer={<VariableInfoBox>
				<FlexBox align={"center"} style={{marginRight: "5px"}}>{(variable.template ?? "").length > 0 ? variable.template : "This template"}</FlexBox>
				<FlexBox align={"center"} justify={"flex-end"}><span className={"type"}>{variable.contents.type}</span></FlexBox>
			</VariableInfoBox>} />)}
	</FlexBox>
}

export {VariableList};