export type SubmissionContent = InternalContent | ExternalContent

type Content = {
	internal: boolean,
	name: string
}

export type InternalContent = Content & {
	id: string,
	version: string,
	status_when_added: string,
	versionNumber?: string
}

export type ExternalContent = Content & {
	location: string
}

export function isInternalContent(content: SubmissionContent): content is InternalContent {
	return (content as InternalContent).id !== undefined;
}

export function isExternalContent(content: SubmissionContent): content is ExternalContent {
	return (content as ExternalContent).location !== undefined;
}