import { Column, ColumnViewProps } from "../../components/layout/table";
import { TaskNode } from "../../interfaces/task";
import { TemplateOutlineIcon } from "../../definitions/elements/template/icon";
import { SectionIcon } from "../../definitions/elements/section/icon";
import { FlexBox } from "../../components";
import { NameTableCell } from "../../components/layout/table/styles";

function iconForSourceType(type: "document" | "template") {
	return type === "document" ? SectionIcon : TemplateOutlineIcon
}

const Cell = (props: ColumnViewProps) => {
	const item = props.item as TaskNode;
	const Icon = iconForSourceType(item.source.type);

	// TODO: rename 'expandable' to 'containsIcon' or 'icon' etc.
	return (<NameTableCell depth={props.depth ?? 0} align={"left"} expandable={true}>
		<FlexBox boxSizing="border-box" align="center">
			<Icon />
			<span className={'row_title'}>{item.source.name}</span>
		</FlexBox>
	</NameTableCell>)
}

const Name: Column = ({
	field: 'name',
	title: "Name",
	width: "25%",
	itemAlign: "left",
	headerAlign: "left",
	searchable: true,
	sortable: true,
	views: {
		cell: Cell
	}
})

export { Name };