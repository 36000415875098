import {VariableEditor, VariableEditorProps} from "../model";
import {FlexBox} from "../../../../../../../shared/components";
import {DropDown, DropDownItem} from "../../../../../../../shared/components/input/dropdown";

type ListEditorOptions = {
	items: string[]
}
const Editor = (props: VariableEditorProps) => {
	const value = props.variable.value as (string | undefined);
	const onChange = (event: DropDownItem<string>) => {
		props.onChange(props.variable, event.value);
	}

	const options = props.variable.options as ListEditorOptions;
	const listItems = options.items.map(item => ({title: item, value: item}) as DropDownItem<string>)
	return <FlexBox direction={"column"} width={"100%"}>
		<DropDown label={props.variable.name} items={listItems} sx={{width: "100%"}} onSelect={onChange} value={value ?? ""} />
		{props.variable.description && <label className={"description"}>{props.variable.description}</label>}
	</FlexBox>
}

const ListEditor: VariableEditor = {
	type: "list",
	view: Editor
};

export {ListEditor};