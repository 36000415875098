import { useRunWhenValueChange } from "../../../../../shared/hooks";
import { useStatusBar } from "../../../../../shared/components";
import { CommentState } from "../../useCommentState";
import { CommentPayload } from "../../../components/modals/templateComments/CommentModal.models";
import { useAddComment } from "../../api/comments/useAddComment";
import {TypedAction} from "../../../../../shared/interfaces/ActionRun";
const uuid = require("uuid");

function useAddCommentAction(): TypedAction<CommentPayload> {

	const commentsManager = CommentState.useContainer();
	const addComment = useAddComment();
	const statusBar = useStatusBar();

	useRunWhenValueChange(() => {
		statusBar.sendErrorNotification({message: "Error saving comment", detail: addComment.error});
	}, addComment.error);

	useRunWhenValueChange(() => {
		if(addComment.status === "success"){
			const previousComments = commentsManager.comments;
			previousComments?.push(addComment.value as KaiAlphaCommentEntry);
			commentsManager.setComments(previousComments!);
			statusBar.sendSuccessNotification({message: "Comment saved"});
		}
	}, addComment.value);

	const run = (commentPayload: CommentPayload) => {
		if(commentPayload.elementId === "") {
			//TODO: Is this right? What about a reply to a comment?
			commentPayload.elementId = commentsManager.templateId;
		}

		commentPayload.comment.id = uuid.v4();

		addComment.execute(commentsManager.templateId, commentPayload);
	}

	return {run, isRunning: addComment.isLoading};
}

export {useAddCommentAction};