import { BaseElement } from "../../../../interfaces/BaseElement";

const metaDefaultDefinition: BaseElement = {
	name: '@meta:default',
	type: '@meta:default',
	displayName: 'Case [default]',
	description: '',
	allowsChildren: true,
	longDescription: '',
	contentDescription: () => "",
	toString: () => "Meta:Default (Preview Unavailable)"
} as const

export {metaDefaultDefinition};