import { Box, styled } from '@mui/material';

const AppChromeContainer = styled(Box)((({ theme }) => {
	const pane_common = {
		overflowY: 'scroll',
		display: 'flex',
		background: theme.palette.background.level1,
		boxShadow: `rgba(0, 0, 0, 0.09) 0px 2px 6px, rgba(0, 0, 0, 0.02) 0px 0px 0px 2px`,
		padding: "2rem",
		borderRadius: "2.7rem",
	};

	const side_pane_common = {
		maxWidth: '20vw',
		flexShrink: 0
	};

	return {
		display: 'flex',
		flexDirection: 'row',
		gap: '1em',
		justifyContent: 'center',
		height: "calc(100% - 130px)",
		padding: "0 1.2rem",
		boxSizing: "border-box",
		'& .left_pane': {
			...pane_common,
			...side_pane_common,
			position: 'relative',
			'& > div': {
				flexGrow: 1,
			}
		},
		'& .content_pane': {
			...pane_common,
			flexGrow: 1,
			maxWidth: '80%',
			'& > div': {
				flexGrow: 1,
			}
		},
		'& .right_pane': {
			...pane_common,
			...side_pane_common,
			'& > div': {
				flexGrow: 1
			}
		},
	}
}));

export { AppChromeContainer }