import { VariableEditor, VariableEditorProps } from "./model";
import { CheckBox } from "../../../../../../shared/components/input";
import { FlexBox } from "../../../../../../shared/components";
import {Description} from "./DescriptionLabel";

const Editor = (props: VariableEditorProps) => {
	const options = (props.variable.options as ({items: string[]} | undefined));
	const value = (props.variable.value as (string[] | undefined) ?? [])

	const onCheckChangeFactory = (item: string) => () => {
		const newValue = value.includes(item) ? [...value.filter(v => v !== item)] : [...value, item];
		if (props.onChange) {
			props.onChange(newValue);
		}
	}

	return <FlexBox direction={"column"}>
		<label className={"label"}>{props.label ?? props.variable.name}</label>
		<FlexBox>
			{(options?.items ?? []).map(item => <CheckBox key={item} label={item} checked={value.includes(item)} onChange={onCheckChangeFactory(item)} variant="square" />)}
		</FlexBox>
		<Description text={props.variable.description} />
	</FlexBox>
}

const CheckboxEditor: VariableEditor = {
	type: "checkbox",
	view: Editor
};

export {CheckboxEditor};