import React from "react";
import {EctdModule} from "../../../../../shared/interfaces/Module";


export type ModuleColumnDefinition = {
	field: keyof EctdModule,
	displayTitle?: string,
	width?: string
}

export type ModuleColumnCellViewProps = {
	row: EctdModule,
	depth?: number,
	column: ModuleColumnDefinition,
	onAction?: (action: unknown, column: ModuleColumnDefinition) => void
}

export type ModuleColumnHeaderViewProps = {
	module: EctdModule,
	depth?: number,
	column: ModuleColumnDefinition,
	onAction?: (action: unknown, column: ModuleColumnDefinition) => void
}

export type ModuleColumnViews = {
	views: {
		cell: React.FC<ModuleColumnCellViewProps>,
		header?: React.FC<ModuleColumnHeaderViewProps>
	}
}

export type ModuleColumn = ModuleColumnDefinition & ModuleColumnViews;

export type ModuleListAction = "activate" | "deactivate" | "click";
export type ModuleListActionLookup = Record<ModuleListAction, ModuleListAction>;
export const ModuleListActions: ModuleListActionLookup = {
	activate: "activate",
	deactivate: "deactivate",
	click: "click"
}