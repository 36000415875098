export type Either<R, L = Error> = {
	_tag: "left" | "right",
	value: R | L,
	isRight: boolean,
	isLeft: boolean,
	map: <U>(fn: MapFunc<R, U>) => Either<U, L>,
	mapAsync: <U>(fn: MapFuncAsync<R, U>) => Promise<Either<U, L>>,
	matchWith: <Tout>(pattern: EitherPatterns<R, Tout>) => Tout
}
type MapFunc<Tin, Tout> = (arg: Tin) => Tout
type MapFuncAsync<Tin, Tout> = (arg: Tin) => Promise<Tout>

type EitherPatterns<T, U> = { right: (value: T) => U; left: (e: Error) => U }


function left<T extends Error>(val: T): Either<never, T> {
	return {
		_tag: "left",
		value: val,
		isLeft: true,
		isRight: false,
		map: () => left(val),
		mapAsync: () => Promise.resolve(left(val)),
		matchWith: (pattern) => pattern.left(val)
	}
}
function right<T>(val: T): Either<T> {
	return {
		_tag: "right",
		value: val,
		isRight: true,
		isLeft: false,
		map: <U>(fn) => Try<U>(() => fn(val)),
		mapAsync: <U>(fn) => TryAsync<U>(() => fn(val)),
		matchWith: (pattern) => pattern.right(val)
	}
}

export function Try<T>(fn: () => T): Either<T> {
	try {
		return right(fn());
	} catch(e) {
		if (e instanceof Error) {
			return left(e);
		}

		return left(new Error("Unknown Error"));
	}
}

export async function TryAsync<T>(fn: () => Promise<T>): Promise<Either<T>> {
	try {
		const val = await fn();
		return right(val);
	} catch(e) {
		if (e instanceof Error) {
			return left(e);
		}

		return left(new Error("Unknown Error"));
	}
}

/**
 * Type guard which helps to narrow the Either type.
 */
function isLeft<R, L=Error>(val: Either<R, L>): val is Either<never, L> {
	return '_tag' in val && val["_tag"] === "left";
}

/**
 * Type guard which helps to narrow the Either type.
 */
function isRight<R, L=Error>(val: Either<R, L>): val is Either<R, L> {
	return '_tag' in val && val["_tag"] === "right";
}

export const EitherUtils = {
	isLeft,
	isRight,
	left,
	right
}
