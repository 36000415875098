import {CsvMapper, CsvMapperFactory} from "./model";
import {autoMapperFactory} from "./AutoMapper";
import {columnsMapperFactory} from "./ColumnsMapper";
import {columnsRowsMapperFactory} from "./ColumnsRowsMapper";
import {DataSourceMapType} from "../../../interfaces/DataSource";

const csvMapperFactories: readonly CsvMapperFactory[] = [autoMapperFactory, columnsMapperFactory, columnsRowsMapperFactory] as const;

export function csvMapperForType(type: DataSourceMapType): CsvMapper | undefined {
	return csvMapperFactories.map(factory => factory()).find(mapper => mapper.type === type);
}