import {DocumentElementDefinition} from "../model";
import {PreviewView} from "./views/PreviewView";
import {
	instructionalTextDefinition as baseInstructionalTextDefinition
} from "../../../../../shared/definitions/elements/instructionalText/model";

const instructionalTextDefinition: DocumentElementDefinition = {
	...baseInstructionalTextDefinition,
	views: {
		preview: PreviewView
	},
} as const

export {instructionalTextDefinition};