import {useState} from "react";
import {DocumentFilter, DocumentSort} from "../../../document/hooks/api/useGetAllDocuments";
import {moduleLinkGenerator} from "../../../moduleNavigation";
import {TaskNode} from "../../../../shared/interfaces/task";
import {useNavigate} from "react-router-dom";
import {
	useCreateUserGroup,
	useDeleteUserGroup,
	useGetUserGroups,
	useGetUsers,
	useUpdateUserGroup
} from "../../hooks/api";
import {UserGroupsNode} from "../../models";
import {useRunOnce, useRunWhenValueChange} from "../../../../shared/hooks";
import { useStatusBar } from "../../../../shared/components";

type State = {
	filters?: DocumentFilter[],
	sort?: DocumentSort,
	pageSize?: number,
	page: number,
	after?: string[] | number[],
	content: UserGroupsNode[],
	//clonedContentForFilter is clone of content
	clonedContentForFilter: UserGroupsNode[],
	updatedContent: UserGroupsNode
}

function useList() {
	const deleteUserGroupById = useDeleteUserGroup();
	const navigate = useNavigate();
	const getUserGroupList = useGetUserGroups();
	const updateUserGroup = useUpdateUserGroup();
	const statusBar = useStatusBar();
	const createUserGroup = useCreateUserGroup();
	const usersList = useGetUsers();

	const [state, setState] = useState<State>({
		sort: {field: "name", direction: "desc"}, page: 0, pageSize: 25, content: [],clonedContentForFilter:[],
		updatedContent: {groupName: "", description: "", users: [], id:""}
	})

	useRunOnce(() => usersList.execute());
	useRunWhenValueChange(() => loadUserGroups(), [state.sort, state.pageSize, state.after]);
	useRunWhenValueChange(() => {
		if (deleteUserGroupById.status === "success" && deleteUserGroupById.value)  {
			removeUserGroupFromList(deleteUserGroupById.value);
			statusBar.sendSuccessNotification({message: "User Group deleted successfully!"});
		} else if (deleteUserGroupById.status === "error")  {
			statusBar.sendErrorNotification({message: "Error deleting user group", detail: deleteUserGroupById.error})
		}
	}, deleteUserGroupById.value);

	useRunWhenValueChange(() => {
		const contentNodes = (getUserGroupList.value?.groups ?? []) as UserGroupsNode[];
		const content = state.page === 0 ? (contentNodes ?? []) : [...state.content].concat(contentNodes ?? []);
		// clonedContentForFilter is clone of content
		setState(s => ({...s, content,clonedContentForFilter:content}));

	}, getUserGroupList.value);

	useRunWhenValueChange(() => {
		if (createUserGroup.status === "success" && createUserGroup.value)  {
			const userGroupsList = [...state.content, createUserGroup.value];
			userGroupsList.push(createUserGroup.value);
			setState(s => ({...s,content: userGroupsList}));
			statusBar.sendSuccessNotification({message: "User Group created successfully!"});
		} else if (createUserGroup.error)  {
			statusBar.sendErrorNotification({message: "Error creating user group", detail: createUserGroup.error})
		}
	}, createUserGroup.status);

	useRunWhenValueChange(() => {
		if (updateUserGroup.status === "success" && updateUserGroup.value)  {
			const userGroupsList = state.content;
			const result = userGroupsList.map((item) => item.groupName === updateUserGroup.value?.groupName ? updateUserGroup.value : item);
			setState(s => ({...s,content: result}));
			statusBar.sendInfoNotification({message: "User Group modified successfully!"});
		} else if (updateUserGroup.error)  {
			statusBar.sendErrorNotification({message: "Error updating user group", detail: updateUserGroup.error})
		}
	}, updateUserGroup.status);

	const createNewUserGroup = () => {
		const url = moduleLinkGenerator("usergroup", "create");
		navigate(url);
	}

	const updateModifiedUserGroup = (userGroup: any) => {
		const updatedUserGroup = {updatedContent: userGroup};
		setState(s => ({...s, ...updatedUserGroup}));

	}

	const deleteUserGroup = (userGroup: UserGroupsNode) => {
		deleteUserGroupById.execute(userGroup.groupName);
	}

	const createUserGroups = (userGroup) => {
		createUserGroup.execute(userGroup);
	}

	const loadUserGroups = () => {
		getUserGroupList.execute(state.filters, state.sort, state.pageSize, state.after);
	}

	const updateUserGroups = (userGroup: UserGroupsNode) => {
		updateUserGroup.execute(userGroup);
	}

	const onNavigateToTask = (task: TaskNode) => {
		const taskUrl = moduleLinkGenerator(task.source.type, "edit", task.source.id);
		navigate(taskUrl);
	}

	const setFilter = (filter: DocumentFilter): boolean => {
		const existingFilter = state.filters?.find(f => f.field === filter.field);
		if (existingFilter?.value === filter.value) {
			return false;
		}

		const filters = [...(state.filters ?? [])].filter(f => f.field !== filter.field);

		// if filter value is empty reset content with clonedContentForFilter (clonedContentForFilter is cloned value of content).
		if ((filter.value?.length ?? 0) > 0) {
			filters.push(filter);
		}else{
			setState(s => ({...s,content:s.clonedContentForFilter,filters, page: 0, after: undefined}));
			return true;
		}


		let newContent = state.clonedContentForFilter;
		filters.map((filter)=>{
			newContent = newContent.filter((c)=>{
				return c[filter.field]?.toLowerCase().includes(filter.value?.toLowerCase())?true:false
			})
			return newContent;
		})
		setState(s => ({...s,content:newContent,filters, page: 0, after: undefined}));
		return true;
	}

	const setSort = (sort?: DocumentSort) => {
		setState(s => ({...s, sort, after: undefined, page: 0}));
	}

	const setPageSize = (pageSize: number) => {
		setState(s => ({...s, pageSize, after: undefined, page: 0}));
	}

	const setAfter = (after?: string[] | number[]) => setState(s => ({...s, after, page: s.page + 1}));

	const removeUserGroupFromList = (userGroupName: string): void => {
		const userGroupsList = [...state.content.filter((item) => item.groupName !== userGroupName)];
		setState(s => ({...s, content: userGroupsList}));
	}

	return {
		createNewUserGroup,
		createUserGroups,
		deleteUserGroup,
		updateUserGroups,
		updateModifiedUserGroup,
		isRunning: false,
		onNavigateToTask,
		content: state.content,
		updatedContent: state.updatedContent,
		setFilter,
		setSort,
		setPageSize,
		setAfter,
		sort: state.sort,
		filters: state.filters,
		pageSize: state.pageSize ?? 10,
		total: getUserGroupList.value?.groups?.length ?? 0,
		after: undefined,
		users: usersList.value?.users ?? [],
		isLoading: deleteUserGroupById.isLoading || updateUserGroup.isLoading || getUserGroupList.isLoading || usersList.isLoading
	}
}

export {useList};