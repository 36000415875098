import { PreviewElementProps } from "../../model";
import { SwitchElementValue } from "../../../../../../shared/definitions/elements/switch/model";
import { useState } from "react";
import { useRunWhenValueChange } from "../../../../../../shared/hooks";
import { mapBodyToPreviewElementProps } from "../../../modals/preview/PreviewSlideOver";
import { PreviewView as PreviewViewFactory } from "./../../PreviewView";
import {executeExpression} from "../../../../../../shared/utilities/data/executeExpression";
import {compareExpression} from "../../../../../../shared/utilities/data/compareExpression";

const PreviewView = (props: PreviewElementProps) => {
	const data = props.data as SwitchElementValue;
	const [body, setBody] = useState<PreviewElementProps[]>([]);

	useRunWhenValueChange(() => {
		if (data.expression) {
			const expressionResult = executeExpression(data.expression, props.variables);
			const values = Object.keys(data.values ?? {});
			const matchingValue = values.find(value => compareExpression(expressionResult.expression.trim(), value,props.variables));
			const bodyElements = matchingValue ? data.values![matchingValue].body : data.default?.body;
			setBody(mapBodyToPreviewElementProps(bodyElements));
		}
	}, [props.data, props.variables]);


	return <>
		{body.map(element => <PreviewViewFactory type={element.type} id={element.id} data={element.data} body={props.body} variables={props.variables} />)}
	</>
}

export { PreviewView };