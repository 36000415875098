import api from "../../../api/api";

export type ListType = "citation" | "abbreviation";
export type ListData = {
	name: string,
	default: boolean,
	entries: {key: string, value: string}[]
}

export type Citation = {
	citation: ListData[]
}

export type Abbreviation = {
	abbreviation: ListData[]
}

function unwrapCitation(response: Citation) {
	const citationData = (response?.citation?.length ?? 0) > 0 ? response.citation[0] : undefined;
	if (citationData === undefined) {
		return undefined;
	}

	citationData.entries = citationData.entries.filter(entry => entry.key !== "Short Title");
	return citationData;
}

function unwrapAbbreviations(response: Abbreviation) {
	const abbreviationData = (response?.abbreviation?.length ?? 0) > 0 ? response.abbreviation[0] : undefined;
	if (abbreviationData === undefined) {
		return undefined;
	}

	abbreviationData.entries = abbreviationData.entries.filter(entry => entry.key !== "Short Title");
	return abbreviationData;
}

function isCitationList(obj: unknown): obj is Citation {
	return obj !== undefined && (obj as Citation)?.citation !== undefined;
}

function isAbbreviationList(obj: unknown): obj is Abbreviation {
	return obj !== undefined && (obj as Abbreviation)?.abbreviation !== undefined;
}
export async function getList({id, type}: {id?: string, type: ListType}): Promise<ListData | undefined> {
	const response: Awaited<Citation> = await (id === undefined ?  api.call("GET", `list/${type}`) : api.call("GET", `list/${type}/${id}`));
	if (isCitationList(response)) {
		return unwrapCitation(response);
	}

	if (isAbbreviationList(response)) {
		return unwrapAbbreviations(response);
	}

	return undefined;
}