import { TableElementFootnote } from "./model";
import { DatasourceContents } from "../variable/model";
import { Variable } from "../../../interfaces/Variable";

function getColumnIndex(dataSource: DatasourceContents, columnName?: string): number | undefined {
	if (columnName === undefined) {
		return undefined;
	}

	// this only supports columns or columns-rows
	switch (dataSource.type) {
		case "columns": {
			const columnData = dataSource.data as { [k: string]: string }[];
			if (columnData.length === 0) {
				return undefined;
			}
			const keys = Object.keys(columnData[0]);
			return keys.findIndex(key => key === columnName);
		}
		case "columns-rows": {
			const rowData = dataSource.data as { [k: string]: string[] };
			return Object.keys(rowData).filter(key => key === "__row").findIndex(key => key === columnName);
		}
		default:
			return undefined;
	}

}

function getRowIndex(dataSource: DatasourceContents, rowName?: string): number | undefined {
	if (rowName === undefined) {
		return undefined;
	}

	// this currently only works for column-rows type
	switch (dataSource.type) {
		case "columns-rows": {
			const rowData = dataSource.data as { [k: string]: string[] };
			return rowData.__row?.findIndex(row => row === rowName);
		}
		default:
			return undefined;
	}

}

function footNoteValueToCellRef(footNoteValue: string, data: DatasourceContents): { row?: number, column?: number } {
	let row: number | undefined = undefined;
	let column: number | undefined = undefined;

	if (footNoteValue.includes("| column") || footNoteValue.includes("| row")) {
		const isColumnMatch = footNoteValue.includes("| column");
		const matchRegex = isColumnMatch ? /column\("|'(.*)'|"\)/gm : /row\("|'(.*)'|"\)/gm;
		const matches = footNoteValue.split("|")[1].trim().match(matchRegex)
		const rawValue = (matches?.length ?? 0) > 0 ? matches![0].replace(/'/gmi, "") : undefined;
		const value = Number.isInteger(parseInt(rawValue ?? "")) ? parseInt(rawValue!) :
			(isColumnMatch ? getColumnIndex(data, rawValue) : getRowIndex(data, rawValue));

		column = isColumnMatch ? value : undefined;
		row = !isColumnMatch ? value : undefined;
	} else {
		const stringToMatch = footNoteValue.includes("| absolute") ? footNoteValue.split("|")[1].trim().replace("absolute", "") :
			footNoteValue.replace(".data[", "#").split("#")[1]
		const matches = stringToMatch.match(/([\w -]+)/gmi);
		if (matches !== null && matches.length > 1) {
			const rowMatch = matches[0];
			const columnMatch = matches[1];
			row = Number.isInteger(parseInt(rowMatch)) ? parseInt(rowMatch) : getRowIndex(data, rowMatch);
			column = Number.isInteger(parseInt(columnMatch)) ? parseInt(columnMatch) : getColumnIndex(data, columnMatch);
		}
	}

	return {
		row,
		column
	}
}
function mapFootnotes(data: DatasourceContents, footNotes?: TableElementFootnote[], variables?: Variable[]): TableElementFootnote[] {
	// pull out any footnote that exists in footNotes that has a variable name
	const variableFootnotes = footNotes?.filter(fn => fn.variable_name !== undefined && fn.variable_name.length > 0) ?? [];
	const footNoteVariables = variables?.filter(v => variableFootnotes.some(vf => vf.variable_name === v.name) && v.value !== undefined).map(v => v.value as TableElementFootnote) ?? [];
	const newFootnotes = (footNoteVariables.length > 0)
		? [...(footNotes ?? [])].concat(footNoteVariables[0])
			.filter(note => note.value !== undefined)
			.map(footNote => ({ ...footNote, cellRef: footNoteValueToCellRef(footNote.value!, data) }))
		: [...(footNotes ?? [])]
			.filter(note => note.value !== undefined)
			.map(footNote => ({ ...footNote, cellRef: footNoteValueToCellRef(footNote.value!, data) }));

	newFootnotes.sort((note1, note2) => {
		const row1 = note1.cellRef?.row ?? 0;
		const row2 = note2.cellRef?.row ?? 0;
		const col1 = note1.cellRef?.column ?? 0;
		const col2 = note2.cellRef?.column ?? 0;

		return row1 - row2 || col1 - col2;
	});

	return newFootnotes;
}

export { mapFootnotes }