import { VersionType } from "../../components/input/dropdown/versionSelector";
import { useAsync } from "..";
import api from "../../../api/api";
import {EntityType} from "../../interfaces/EntityType";
import {EntityVersionInfo} from "../../interfaces/EntityVersionInfo";

export async function getVersionsForEntityType(type: EntityType, id: string): Promise<EntityVersionInfo[]> {
	const { versions } = await api.call('GET', `${type}/${id}/versions`)
	return versions.map(version => {
		const {state, ...versionInfo} = version;
		return {...versionInfo, state: state?.toLowerCase()} as EntityVersionInfo
	});
}
function useGetVersions(type: EntityType) {
	const get = async (id: string): Promise<VersionType[]> => {
		const versions = await getVersionsForEntityType(type, id)
		return versions
			.map(version => ({
				...version,
				date: new Date(version.date),
				isCurrent: false,
				isMajor: false,
				summary: null,
				index: 0,
				tags: [],
				number: "0"
			}) as VersionType);
	}

	return useAsync(get, false);
}

export { useGetVersions };