import DateFnsUtils from '@date-io/date-fns';
import { CacheProvider } from './cacheProvider';
import { MemoryCacheProvider } from './memoryCacheProvider';

export interface CacheValueFactory<T> {
	(): Promise<T | null>
}

export interface UseCacheValue {
	getItem: <T>(key: string, valueFactory?: CacheValueFactory<T> | undefined) => Promise<T | null>,
	setItem: <T>(key: string, item: T, expiryTimeMinutes?: number) => void
}

const dateAdapter = new DateFnsUtils();

export function cacheFactory(defaultExpiryTimeMinutes: number, maximumSize?: number, cacheProvider: CacheProvider = MemoryCacheProvider): UseCacheValue {
	/**
	 * Gets an item from the cache with the specified key. If no value is found and valueFactory is set, the valueFactory function is invoked and the returned value is cached
	 * @param key {string} key for cache item
	 * @param valueFactory {CacheValueFactory<T>} function used to generate missing value (optional)
	 * @returns {Promise<T|null>} null is returned if the value is not found.
	 */
	const getItem = async <T>(key: string, valueFactory?: CacheValueFactory<T> | undefined): Promise<T | null> => {
		// attempt to clean the cache first. If garbage collection runs, this will remove expired items
		cleanCache();
		// get the item from the provider
		const cacheEntry = cacheProvider.getItem(key);
		if (typeof(cacheEntry) !== "undefined") {
			// check the item hasn't expired
			const cacheLifetime = dateAdapter.getDiff(cacheEntry.created, new Date(), "minutes").valueOf();
			if (cacheLifetime <= cacheEntry.expiryTimeMinutes) {
				// update the item's access count
				// this is safe as cache is local to user only
				cacheEntry.accessCount++;
				// return cache value
				return cacheEntry.value;
			} else {
				// if expired, remove the item
				cacheProvider.removeItem(key);
			}
		}

		// if item expired/not found and caller has passed value generator function,
		// generate the value
		const value = await (valueFactory ? valueFactory() : Promise.resolve(null));
		if (value !== null && value !== undefined) {
			// set the new value in the cache with the default expiry time
			setItem(key, value, defaultExpiryTimeMinutes);
		}

		// return the value (can be null)
		return value;
	}

	/**
	 * Invokes the cache provider's garbage collection method
	 */
	const cleanCache = (): void => cacheProvider.garbageCollect(defaultExpiryTimeMinutes, maximumSize ?? 0);

	/**
	 * Sets a cache item
	 * @param key {string} cache item key
	 * @param value {T} cache item value
	 * @param expiryTimeMinutes {number} cache item expiry in minutes
	 */
	const setItem = <T>(key: string, value: T, expiryTimeMinutes?: number) => {
		cacheProvider.setItem(key, {
			created: new Date(),
			value,
			accessCount: 0,
			expiryTimeMinutes: expiryTimeMinutes ?? defaultExpiryTimeMinutes,
			key
		});
		cleanCache();
	}

	return {
		getItem,
		setItem
	}
}
/**
 * Cache hook that provides access to an object cache
 * @param defaultExpiryTimeMinutes {number} the default item expiry in minutes (if no value is passed in the setItem method, this value is used)
 * @param maximumSize {number} max cache size (optional) if not present or less than 1, no limit is specified
 * @param cacheProvider {CacheProvider} the cache provider to use, defaults to MemoryCacheProvider
 * @returns {UseCacheValue} allows consumer to get and set cache items
 */
function useCache(defaultExpiryTimeMinutes: number, maximumSize?: number, cacheProvider: CacheProvider = MemoryCacheProvider): UseCacheValue {
	return cacheFactory(defaultExpiryTimeMinutes, maximumSize, cacheProvider);
}

export {useCache}