import { styled } from "@mui/material";

export interface HtmlProps {
	value: string,
	className?: string
}

const HtmlWrapper = styled("div")({
	boxSizing: "border-box",
	padding: ".5rem",
	"& p": {
		fontSize: "1rem"
	}
})
const Html = ({ value, className }: HtmlProps) =>
	<>
		<HtmlWrapper className={className} dangerouslySetInnerHTML={{ __html: value }}></HtmlWrapper>
		<span>(Instructional Text will not be rendered in the final output)</span>
	</>;

export { Html };