import {styled} from "@mui/material";
import {FlexBox} from "../../FlexBox";
import {GroupedTab} from "./models";
import {Tab} from "./GroupedTab";

const Container = styled(FlexBox)({
	padding: "5px 1px",
	boxShadow: "rgba(50, 50, 93, 0.15) 2px 2px 2px 1px inset, rgba(0, 0, 0, 0.05) 0px 3px 5px 1px inset",
	borderRadius: "0.25rem",
	margin: "0.5rem 0 1rem 0"
})

type GroupedTabsProps = {
	tabs: GroupedTab[],
	activeIndex: number,
	onTabSelect: (tab: GroupedTab) => void
}
export const GroupedTabs = (props: GroupedTabsProps) => {
	const onTabClickFactory = (tab: GroupedTab) => () => props.onTabSelect(tab);
	return <Container>
		{props.tabs.map((tab, idx) => <Tab key={tab.title} title={tab.title} onClick={onTabClickFactory(tab)} isActive={idx === props.activeIndex} />)}
	</Container>
}