import {styled} from "@mui/material";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";

export interface ExpandKeyboardTabIconProps {
	rotated: boolean,
}

const ExpandKeyboardTabIcon = styled(KeyboardTabIcon, { shouldForwardProp: (prop) => prop !== "rotated" })<ExpandKeyboardTabIconProps>(({ rotated }: ExpandKeyboardTabIconProps) => ({
	transform: rotated ? 'rotate(180deg)' : 'none',
}));

export {ExpandKeyboardTabIcon};