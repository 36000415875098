import { styled } from "@mui/styles";
import { FlexBox } from "../../../../../shared/components";


const CreateTemplateContainer = styled(FlexBox)(({theme}) => ({
	'& h3':{
		fontSize:"1.2rem",
		margin: "0 0 0.7rem",
		whiteSpace: 'nowrap',
		color: theme.palette.secondary.contrastText,
	},
	'& p':{
		fontSize:"0.7rem",
		margin: "0 0 0.7rem",
		whiteSpace: 'nowrap',
		color: theme.palette.tabs?.main,
	},
	'& .Mui-checked':{
		color: `${theme.palette.checkbox?.main} !important`,
	},
	'& .MuiTypography-body1': {
		fontSize: '0.9rem !important',
		color: theme.palette.tabs?.main,
		'& .selected_action': {
			color: theme.palette.secondary?.main,
		}
	},
}));

export {CreateTemplateContainer}