import {NunjucksVariables} from "../interfaces/Nunjucks";

function adapterFactory() {
	function toTree(variables: NunjucksVariables) {
		return Object.keys(variables).reduce((lookup, current) => {
			const keyParts = current.split(".");
			keyParts.reduce((currentObj, currentKey, index) => {
				if (index === keyParts.length - 1) {
					currentObj[currentKey] = variables[current];
					return currentObj;
				} else {
					if (!currentObj[currentKey]) {
						currentObj[currentKey] = {};
					}
					return currentObj[currentKey];
				}
			}, lookup);
			return lookup;
		}, {})
	}

	return {toTree}
}

export const NunjucksVariableAdapter = adapterFactory();