import { PreviewElementProps } from "../model";
import {SwitchDataValue} from "../../../../../shared/definitions/elements/switch/model";
import { useState } from "react";
import { useRunWhenValueChange } from "../../../../../shared/hooks";
import { PreviewViewFactory } from "../PreviewViewFactory";
import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";
import {ElementEventTypes} from "../../../../../shared/definitions/elements/events";
import {getBodyFromEvaluatedExpression} from "./getBodyFromEvaluatedExpression";

const PreviewView = (props: PreviewElementProps) => {
	const data = props.element.data as SwitchDataValue;
	const [body, setBody] = useState<ElementValueType[]>([]);

	function variablesForElement() {
		return (props.variables?.filter(v => v.templateId === props.element.source)
			?.map(v => ({...v, namespace: undefined}))) ?? [];
	}
	useRunWhenValueChange(() => {
		if (data.expression) {
			setBody(getBodyFromEvaluatedExpression(data, variablesForElement()));
		}
	}, [props.element.data, props.variables]);

	useRunWhenValueChange(() => {
		const updateEvent = new CustomEvent(ElementEventTypes.update, {detail: {type: "switch"}});
		window.dispatchEvent(updateEvent);
	}, body)

	return <>
		{body?.map(element => <PreviewViewFactory state={props.state} isLoadingVariables={props.isLoadingVariables} metaData={props.metaData} element={element} body={props.body} variables={props.variables} />)}
	</>
}

export { PreviewView };