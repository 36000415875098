import { PreviewElementProps } from "../../model";
import { styled } from "@mui/material";
import { HeaderElementValue } from "../../../../../../shared/definitions/elements/header/model";

const Header = styled("h1")({
	fontWeight: 600,
	fontSize: "2rem",
	margin: 0
})

const PreviewView = (props: PreviewElementProps) => {
	const data = props.data as HeaderElementValue;
	return <Header>{data.value}</Header>
}

export { PreviewView };