import { FlexBox } from "../../../shared/components";
import { styled } from "@mui/material";
import { DashboardTitle} from './DashboardCard.style';
import { DashboardContentList, DashboardContentListProps } from "./DashboardContentList";
import {moduleLinkGenerator} from "../../moduleNavigation";

const BufferContentListsContainer = styled(FlexBox)((props) => ({
	padding: "0.75rem 2rem",
	marginLeft: "2rem",
	boxSizing: "border-box",
	borderLeft: `1px solid ${props.theme.palette.dashboard?.border}`
}));

export interface BufferLayoutListProps {
	list: DashboardContentListProps["list"],
	loading: boolean,
}

export interface BufferLayoutProps {
	documentList: BufferLayoutListProps,
	templateList: BufferLayoutListProps,
}

const BufferLayout = ({documentList, templateList} : BufferLayoutProps) => {
	return <FlexBox align="center" direction="column" sx={{mb: "5rem"}}>
		<BufferContentListsContainer align="flex-start" direction="column">
			<DashboardTitle sx={{pb: '1.3rem'}}>Content</DashboardTitle>
			<DashboardContentList
				list={documentList.list}
				loading={documentList.loading}
				transparent
				contentType={"buffer"}
				maxHeight="20rem"
				route={moduleLinkGenerator("content", "edit")}
			/>
			<DashboardTitle sx={{p: '1.8rem 0 1.3rem'}}>Templates</DashboardTitle>
			<DashboardContentList
				list={templateList.list}
				loading={templateList.loading}
				contentType={"buffer"}
				transparent
				maxHeight="20rem"
				route={moduleLinkGenerator("template", "edit")}
			/>
		</BufferContentListsContainer>
	</FlexBox>
}

export {BufferLayout};