import { TemplateElement } from "../../../../../../shared/interfaces/TemplateElement";
import {ElementMapper} from "./models";

const variableMapper: ElementMapper = (variable: TemplateElement) => {
	return({
		id: variable.id,
		type: variable.type,
		contents: {
			name: variable.contents.name,
			required: variable.contents.required,
			type: variable.type
		},
		depth: variable.depth
	});
}

export {variableMapper};