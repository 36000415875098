import React from "react";
import {abbreviationsDefinition} from "./abbreviations/model";
import {citationsDefinition} from "./citations/model";
import {instructionalTextDefinition} from "./instructionalText/model";
import {footerDefinition} from "./footer/model";
import {headerDefinition} from "./header/model";
import {htmlDefinition} from "./html/model";
import {imageDefinition} from "./image/model";
import {inTextTableDefinition} from './inTextTable/model';
import {loopDefinition} from "./loop/model";
import {referenceDefinition} from "./reference/model";
import {styleDefinition} from "./style/model";
import {switchDefinition} from "./switch/model";
import {tableDefinition} from "./table/model";
import {templateDefinition} from "./template/model";
import {titleDefinition} from "./title/model";
import {tocDefinition} from "./toc/model";
import {variableDefinition} from "./variable/model";
import {DialogProps} from "../../models/elements";
import {metaValueDefinition} from "./meta/value/model";
import {metaDefaultDefinition} from "./meta/default/model";
import {metaElseDefinition} from "./meta/else/model";
import {Variable} from "../../../../shared/interfaces/Variable";
import { BaseElement, ElementPropertyDataValueType } from "../../../../shared/interfaces/BaseElement";
import {ElementValueType} from "../../../../shared/interfaces/ElementValueType";
import {sectionDefinition} from "./section/model";

export type ElementIcon = React.FC<ElementIconProps>
interface ElementViews {
	editable: EditableViewType,
	propertyEditor?: {
		view: React.FC<DialogProps>,
		requiredOnCreate?: boolean
	},
	preview?: PreviewViewType,
	readonly?: ReadonlyViewType
}

export interface TemplateElementDefinition extends BaseElement {
	readonly icon?: ElementIcon,
	readonly views?: ElementViews,
	readonly addElementType?: string,
}

export const elements: Readonly<TemplateElementDefinition[]> = [
	abbreviationsDefinition,
	citationsDefinition,
	instructionalTextDefinition,
	footerDefinition,
	headerDefinition,
	htmlDefinition,
	imageDefinition,
	inTextTableDefinition,
	loopDefinition,
	referenceDefinition,
	styleDefinition,
	sectionDefinition,
	switchDefinition,
	tableDefinition,
	templateDefinition,
	titleDefinition,
	tocDefinition,
	variableDefinition,
	metaValueDefinition,
	metaDefaultDefinition,
	metaElseDefinition
] as const;

export interface ElementPropertyData {
	id: string,
	elementInformation: {
		type: string,
		writable: boolean
	} | null,
	data: ElementPropertyDataValueType
}

export interface ElementIconProps {
	color?: string,
	strokeColor?: string
}

const IconDefaults: ElementIconProps = {
	color: "#403294",
	strokeColor: "#3d368f"
}

export {IconDefaults};

export type EditableElementChangeEvent = ElementPropertyData;

export type EditableElementEvents = {
	onChange: (event: EditableElementChangeEvent) => void
}

export type ReadonlyElementProps = ElementPropertyData;
export type EditableElementProps = ElementPropertyData & EditableElementEvents;
export type PreviewElementProps = ElementValueType & {
	variables?: Variable[],
	body?: KaiAlphaTemplateBody,
	templateId?: string
}

export type EditableViewType = React.FC<EditableElementProps>;
export type ReadonlyViewType = React.FC<ReadonlyElementProps>;
export type PreviewViewType = React.FC<PreviewElementProps>;
