import {DocumentElementDefinition} from "../model";
import PreviewView from "./PreviewView";
import {
	abbreviationsDefinition as baseAbbreviationsDefinition
} from "../../../../../shared/definitions/elements/abbreviations/model";


const abbreviationsDefinition: DocumentElementDefinition = {
	...baseAbbreviationsDefinition,
	views: {
		preview: PreviewView
	},
} as const

const abbreviationsValue = {
	label: "List",
	labelValue: "Default Abbreviations"
}

export {abbreviationsDefinition, abbreviationsValue};