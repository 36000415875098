import { TemplateElement } from "../interfaces/TemplateElement";
const clone = require("rfdc/default");

export interface BodySortly {
	id: string,
	type: string,
	index: number,
	parentId?: string,
	contents: unknown,
	depth: number
}

export interface TagResponse {
	type: string,
	id: string,
	parentId?: string,
	contents: any,
	container: string[],
}

export function bodySerialize(template: KaiAlphaTemplate): TemplateElement[] {
	const response: any[] = [];

	for (const elementDescriptor of bodyByElementTag(null, template.body!)) {
		const elementInfo = elementDescriptor.contents;
		elementInfo['$from'] = 'template';

		if (elementDescriptor.type === 'variable') {
			const variableName = elementInfo.name;
			if (template && template.variables && template.variables[variableName]) {
				elementInfo['$variable_descriptor'] = template.variables[variableName];
			}
		}
	}

	if(template.body !== undefined) {
		for (const elementInfo of template.body) {
			response.push(elementInfo);
		}
	}

	return(response);
}

export function bodyByElementTag(elementTag: null | string, body: KaiAlphaTemplateBody | TemplateElement[] | unknown, container: string[] = [], parentId?: string): TagResponse[] {
	const response: TagResponse[] = [];

	if (!(body instanceof Array)) {
		return(response);
	}

	for (const element of body) {
		const elementId = Object.keys(element)[0];
		const elementBody = element[elementId];
		const elementContainer = [...container];
		elementContainer.push(`${elementBody.type} ${elementBody.name}`);

		if (elementBody.type === elementTag || elementTag === null) {
			response.push(({
				type: elementBody.type,
				id: elementId,
				parentId,
				contents: elementBody,
				container: elementContainer
			}));
		}

		switch (elementBody.type) {
			case 'template':
			case 'section':
				if (elementBody.body) {
					response.push(...bodyByElementTag(elementTag, elementBody.body as KaiAlphaTemplateBody, elementContainer, elementId));
				}
				break;
			case 'switch':
				if (elementBody.values instanceof Object) {
					for (const expressionValue in elementBody.values) {
						const valueBody = elementBody.values[expressionValue];
						if (valueBody.body) {
							if (elementTag === null) {
								response.push(({
									type: '@meta:value',
									id: `${elementId}-value-${expressionValue}`,
									parentId: elementId,
									contents:({
										type: '@meta:value',
										value: expressionValue
									}),
									container: [...elementContainer, `Value ${expressionValue}`]
								}));
							}
							response.push(...bodyByElementTag(
								elementTag,
								valueBody.body,
								[...elementContainer, `Value ${expressionValue}`],
								`${elementId}-value-${expressionValue}`));
						}
					}
				}

				if (elementBody.default && elementBody.default['body'] as KaiAlphaTemplateBody) {
					if (elementTag === null) {
						response.push(({
							type: '@meta:default',
							id: `${elementId}-default`,
							parentId: elementId,
							contents:({
								type: '@meta:default'
							}),
							container: [...elementContainer, "Default"]
						}));
					}
					response.push(...bodyByElementTag(
						elementTag,
						elementBody.default['body'],
						[...elementContainer, 'Default'],
						`${elementId}-default`
					));
				}
				break;
			case 'loop':
				if (elementBody.body) {
					response.push(...bodyByElementTag(
						elementTag,
						elementBody.body as KaiAlphaTemplateBody,
						elementContainer,
						elementId
					));
				}

				if (elementBody.else && elementBody.else['body']) {
					if (elementTag === null) {
						response.push(({
							type: '@meta:else',
							id: `${elementId}-else`,
							parentId: elementId,
							contents:({
								type: '@meta:else'
							}),
							container: [...elementContainer, 'Else']
						}));
					}
					response.push(...bodyByElementTag(
						elementTag,
						elementBody.else['body'],
						[...elementContainer, 'Else'],
						`${elementId}-else`
					));
				}
				break;
			default:
				break;
		}
	}

	return(response);
}

export function getBodySortlyItems(body: TemplateElement[] | unknown): BodySortly[] {

	const flattened = bodyByElementTag(null, body);
	return flattened.map((tag, index) => {
		const item = clone(tag);
		switch (item.type) {
			case 'section':
				delete item.contents['body'];
				break;
			case 'switch':
				delete item.contents['values'];
				delete item.contents['default'];
				break;
			case 'loop':
				delete item.contents['body'];
				delete item.contents['else'];
				break;
			case 'template':
				delete item.contents['body'];
				break;
			default:
				break;
		}

		return {
			id: item.id,
			type: item.type,
			parentId: item.parentId,
			contents: item.contents,
			depth: item.container.length - 1,
			index
		} as BodySortly;
	})
}

export function parseTemplateElements(template: KaiAlphaTemplate) : TemplateElement[] {
	let items: TemplateElement[] = [];
	const body: TemplateElement[] = bodySerialize(template);

	if (body && body.length > 0) {
		items = getBodySortlyItems(body) as TemplateElement[];
		for (const itemIndex in items) {
			const item = items[itemIndex];

			if (item.type !== 'variable') {
				continue;
			}

			if (template.variables && template.variables[item.contents.name]) {
				Object.assign(items[itemIndex].contents, template.variables[item.contents.name]);
			}
		}
	}

	return items;
}