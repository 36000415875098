import './style.scss';
import { BreadcrumbDefinition } from "../breadcrumbs/models";
import { Breadcrumbs } from "../breadcrumbs";
import { useNavigate } from "react-router-dom";
import { StatusBar } from "../../notifications/statusbar/StatusBar";
import {ButtonBar} from "../buttonBar/ButtonBar";
import {IdentityBar} from "./IdentityBar";

export interface MastheadProps {
	contextButtons?: JSX.Element,
	customComponent?: JSX.Element,
	breadcrumbs?: BreadcrumbDefinition[]
}
const Masthead = ({ contextButtons, customComponent, breadcrumbs }: MastheadProps) => {
	const navigate = useNavigate();
	const onBreadcrumbClick = (breadcrumb: BreadcrumbDefinition) => navigate(breadcrumb.url!);
	return (
		<header className="masthead__global">
			<div className="masthead__global-breadcrumbs">
				<ButtonBar onRenderContextButtons={() => contextButtons} />
				{breadcrumbs && <Breadcrumbs items={breadcrumbs} onClick={onBreadcrumbClick} />}
			</div>
			<section className="masthead__global-container">
				<StatusBar />
				<div className="masthead__global-customview">
					{customComponent}
					<IdentityBar />
				</div>
			</section>
		</header>
	)
}

export { Masthead }