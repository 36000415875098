import { useState } from "react";
import {createContainer} from "unstated-next";
import { TemplateState } from ".";
import { CommentStates } from "../components/modals/templateComments/CommentModal.models";
import { UserManager } from '../../../shared/hooks/useUser';


interface UseCommentState {
	comments: KaiAlphaCommentsTemplate | null,
	selectedComment: KaiAlphaCommentEntry | null,
}

const initialState: UseCommentState = {
	comments: [],
	selectedComment: null
};

const useComment = () => {
	const [state, setState] = useState(initialState);
	const templateManager = TemplateState.useContainer();
	const userManager = UserManager.useContainer();

	const getUsers = (originalUsers: []) => {
		if (originalUsers?.length) {
			return;
		}

		// we pull the permissions from the buffer
		const permissions = templateManager?.buffer?.permissions;

		let retrievedIds: any[] = [];

		if(permissions) {
			retrievedIds = permissions?.owners;
		}

		return retrievedIds;
	}

	const getCurrentUserId = () => {
		return userManager.id;
	}

	const getUserDisplayname = async (userId: string) => {
		return await userManager.getUserDisplayname(userId);
	}

	const comments = state.comments;
	const openComments = state.comments?.filter((comment) => comment.state === CommentStates.IN_REVIEW);
	const resolvedComments = state.comments?.filter((comment) => (comment.state === CommentStates.RESOLVED));

	const commentsByElementId = (elementId: string) => {
		return state.comments?.filter((value) => value.elementId === elementId) ?? [];
	}

	const highlightedCommentsByElementId = (elementId: string) => {
		return state.comments?.filter((value) => value.elementId === elementId && value.text_properties !== undefined) ?? [];
	}

	const setComments = (comments: KaiAlphaCommentsTemplate) => {
		setState( prevState => ({...prevState, comments}));
	}

	const getTotalCommentsLength = () => {
		return comments?.length;
	}

	const getOpenCommentsLength = () => {
		return comments?.filter((value) => value.state === CommentStates.IN_REVIEW)?.length;
	}

	const getResolvedCommentsLength = () => {
		return comments?.filter((value) => value.state === CommentStates.RESOLVED)?.length;
	}

	// when not in review mode, do not allow comments
	const disableComments = !templateManager.commentable;

	const selectedElement = templateManager.selectedElement?.id;

	const templateId = templateManager.template?.id;
	const versionId = templateManager.template?.version;

	const commentScroll = (comment: KaiAlphaCommentEntry) => {
		setState(s => ({...s, selectedComment: comment}));
		if(comment.elementId && templateManager) {
			templateManager.setSelectedElementById({id: comment.elementId});
		}
	}

	return {
		getUsers,
		comments,
		openComments,
		resolvedComments,
		setComments,
		getTotalCommentsLength,
		getOpenCommentsLength,
		getResolvedCommentsLength,
		disableComments,
		getUserDisplayname,
		commentScroll,
		selectedElement,
		templateId,
		versionId,
		commentsByElementId,
		highlightedCommentsByElementId,
		getCurrentUserId,
		selectedComment: state.selectedComment
	}
}

const CommentState = createContainer(useComment);
export {CommentState};