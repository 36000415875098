import { ContextButtonFactory, DefaultContextButtons } from '../../../../shared/components/buttons/theme'
import {ModuleLayout} from "../../../../shared/components/layout/ModuleLayout";
import {AsyncComponent, Button, FlexBox, MessageBox, MessageBoxActions, SlideOver} from "../../../../shared/components";
import Masthead from "../../../../shared/components/navigation/masthead";
import { useList } from './useList';
import React, {useState} from "react";
import {FilterState} from "../../../../shared/components/input/filter/sort/models";
import {UserGroupsNode, UserGroupValidation} from "../../models";
import {MessageBoxActionType} from "../../../../shared/components/modals/messageBox/models";
import {ListPageWrapper} from "../../../../shared/components/layout/ListPageWrapper";
import {UserGroupsList} from "../../components/list/usergroups/UserGroupsList";
import CreateUserGroup from "../../components/forms/CreateUserGroup";
import EditUserGroup from "../../components/forms/EditUserGroup";
import {useRunWhenValueChange} from "../../../../shared/hooks";

const InitialUserGroup: UserGroupsNode = {
	groupName: "",
	description: "",
	users: [],
	id: ""
}

type ListState = {
	confirmDelete: {
		open: boolean,
		pendingContent?: UserGroupsNode,
	},
	editUserGroup: {
		open: boolean,
		userGroup?: UserGroupsNode & UserGroupValidation,
		buttonTitle: string,
		action?: "create" | "edit"
	}
}

const initialState: ListState = {
	confirmDelete: {
		open: false,
		pendingContent: undefined
	},
	editUserGroup: {
		open: false,
		userGroup: undefined,
		buttonTitle: "Create User Group",
		action: undefined
	}
}

const List = () => {
	const userGroupsManager = useList();
	const [page, setPage] = useState(0);
	const [state, setState] = useState<ListState>({...initialState})

	useRunWhenValueChange((prevPage) => {
		if ((prevPage ?? 0) < page && userGroupsManager.content.length <= (page * userGroupsManager.pageSize)) {
			userGroupsManager.setAfter(userGroupsManager.after)
		}
	}, page);

	const onDelete = (contentNode: UserGroupsNode) => {
		setState(s => ({...s, confirmDelete: {...s.confirmDelete, open: true, pendingContent: contentNode}}));
	}

	const onMessageBoxAction = (action: MessageBoxActionType) => {
		if (action === MessageBoxActions.Primary) {
			userGroupsManager.deleteUserGroup(state.confirmDelete.pendingContent!);
		}
		setState(s => ({...s, confirmDelete: {open: false, pendingContent: undefined}}));
	}

	const onMessageBoxClose = () => onMessageBoxAction(MessageBoxActions.Secondary)

	const onPageChange = (event: unknown, newPage: number) => setPage(newPage);
	const onPageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const pageSize = Number(event.target.value);
		userGroupsManager.setPageSize(pageSize);
		setPage(0);
	}
	const onSearchChange = (field?: string, searchValue?: string) => {
		if (searchValue !== undefined && field) {
			const didUpdateFilters = userGroupsManager.setFilter({
				field, value: searchValue ?? ""
			});

			if (didUpdateFilters) {
				setPage(0);
			}
		}
	}

	const onSort = (field: string, sortDirection: FilterState) => {
		userGroupsManager.setSort(sortDirection === "none" ? undefined : {field, direction: sortDirection === "ascending" ? "asc": "desc"})
		setPage(0);
	}

	const getCurrentSortDirection = (): FilterState => {
		if (userGroupsManager.sort?.direction === "asc") {
			return "ascending";
		} else if (userGroupsManager.sort?.direction === "desc") {
			return "descending"
		}

		return "none";
	}

	const onContextButtonClick = (key: string) => {
		if (key === DefaultContextButtons.New.key) {
			setState(s => ({...s, editUserGroup: {...s.editUserGroup, open: true, buttonTitle: "Create User Group", action: "create", userGroup: {...InitialUserGroup, isGroupNameValid: true}}}))
		}
	}
	const onEditUserGroup = (userGroup: UserGroupsNode) => {
		setState(s => ({...s, editUserGroup: {...s.editUserGroup, open: true, userGroup: {...userGroup, isGroupNameValid: checkGroupNameValid(userGroup.groupName)}, buttonTitle: "Update User Group", action: "edit"}}))
	}
	const onCloseEditor = (action) => {
		if(action === 'create'){
			userGroupsManager.createUserGroups(state.editUserGroup.userGroup!);
		} else if(action === 'edit'){
			userGroupsManager.updateUserGroups(state.editUserGroup.userGroup!);
		}
		setState(s => ({...s, editUserGroup: {open: false, userGroup: undefined, buttonTitle: "Create User Group", action: undefined}}));
	}

	const checkGroupNameValid = (groupName: string) => {
		// user may not start, or end, groupName with spaces. Don't even write them to the field.
		groupName = (groupName + '').trim();
		if ( groupName.length > 128 ) {
			return false;
		}
		// Letters upper/lower, numbers, limited special chars, no spaces
		const regex = /[^A-Za-z0-9+=.@-]/;
		const result = regex.test(groupName);
		// Invert, because we are using the negate operator in the regex expression
		return !result;
	}

	const userGroupOnChange = (userGroup: UserGroupsNode) =>
		setState(s => ({...s, editUserGroup: {...s.editUserGroup, userGroup: {...userGroup, isGroupNameValid: checkGroupNameValid(userGroup.groupName)}}}));

	const filteredUsers = (userGroupsManager.users ?? []).filter(user => !state.editUserGroup.userGroup?.users.find(u => u.id === user.id));
	return <FlexBox direction={"column"} height={"100%"}>
		<Masthead
			contextButtons={ContextButtonFactory(
				[DefaultContextButtons.New],
				onContextButtonClick)}
		/>
		<ModuleLayout
			center={<AsyncComponent isLoading={userGroupsManager.isLoading} component={<ListPageWrapper>
				<UserGroupsList
					page={page}
					pageSize={userGroupsManager.pageSize}
					filters={(userGroupsManager.filters ?? []).reduce((lookup, filter) => {
						lookup[filter.field] = filter.value;
						return lookup;
					}, {})}
					total={userGroupsManager.total}
					onDelete={onDelete}
					onPageChange={onPageChange}
					onPageSizeChange={onPageSizeChange}
					onColumnSearch={onSearchChange}
					onRowClick={onEditUserGroup}
					onSort={onSort}
					userGroups={userGroupsManager.content ?? []}
					sort={{field: userGroupsManager.sort?.field ?? "", sortDirection: getCurrentSortDirection()}} />
			</ListPageWrapper>} />}
		/>
		<MessageBox
			destructive
			visible={state.confirmDelete.open}
			message={<>Are you sure you want to delete <b>{state.confirmDelete.pendingContent?.groupName}</b>?</>}
			title={"Delete User Group"}
			primaryAction={"Delete"}
			secondaryAction={"Cancel"}
			onActionClick={onMessageBoxAction}
			onClose={onMessageBoxClose}
		/>
		<SlideOver open={state.editUserGroup.open} onClose={() => onCloseEditor(state.editUserGroup.action)} size={"medium"} renderActions={() => <>
			<Button text={state.editUserGroup.buttonTitle} sx={{marginRight: ".5rem"}} onClick={() => onCloseEditor(state.editUserGroup.action)}/>
			<Button buttonType={"plain"} text={"Cancel"} onClick={() => onCloseEditor('cancel')}/>
		</>}>
			{state.editUserGroup.action === 'create' && <CreateUserGroup userGroup={state.editUserGroup.userGroup!} users={filteredUsers} onChange={userGroupOnChange}/>}
			{state.editUserGroup.action === 'edit' && state.editUserGroup.userGroup && <EditUserGroup userGroup={state.editUserGroup.userGroup!} users={filteredUsers} onChange={userGroupOnChange}/>}
		</SlideOver>
	</FlexBox>

}

export {List};