import {styled} from "@mui/material";
import {BarLoader, FlexBox} from "../../../../shared/components";

const Container = styled(FlexBox)({
	minHeight: "80px",
	background: "rgba(0,0,0,0.3)",
	borderRadius: ".5rem",
	position: "relative"
})
const LoadingView = ({message}: {message: string}) => {
	return <Container justify={"center"} align={"center"}>
		<BarLoader message={message ?? "Loading..."} />
	</Container>
}

export {LoadingView}