import { TemplateElement } from "../../../shared/interfaces/TemplateElement";


export function computeElements(elements: TemplateElement[]): TemplateElement[] {
	return elements.map((element, index) => ({
		...element,
		isChild: elements.some(e => e.id === element.parentId),
		hasChild: elements.some(e => e.parentId === element.id),
		index
	}))
}
/**
 * This function adds extra props to each element so we can determine the tree structure
 * @param elements
 */
// export function computeElements(elements: TemplateElement[]): TemplateElement[] {
//
// 	let parentIndex = -1;
// 	let zeroLevelNodeIndex = -1
// 	let lastIndex = -1;
// 	const traversedElements = {};
// 	console.log({elements: [...elements]});
// 	elements.forEach((current, index) => {
// 		// get the last node we looked at
// 		const last = lastIndex > -1 ? elements[lastIndex] : null;
// 		// get the parent node (if we have set one)
// 		let parent = (parentIndex > -1 ? elements[parentIndex] : null);
// 		// get the last node that was at depth=0
// 		const zeroLevelParent = zeroLevelNodeIndex > -1 ? elements[zeroLevelNodeIndex] : null
//
// 		// reset node's props
// 		current.hasChild = false;
// 		current.lastChild = false;
// 		current.isChild = false;
// 		current.index = index;
//
// 		if (current.depth === 0) {
// 			zeroLevelNodeIndex = index;
// 		}
// 		// if current node is deeper than the last node then we have a child item
// 		if(current.depth > (last?.depth ?? current.depth)) {
// 			// set the parent index to the last node's index
// 			parentIndex = lastIndex;
// 			// get the parent
// 			parent = elements[parentIndex];
// 			// set the correct props
// 			current.isChild = true;
// 			current.parentId = current.depth === 1 ? zeroLevelParent!.id : parent.id;
// 			parent.hasChild = true;
//
// 		} else if(current.depth < (last?.depth ?? current.depth)) { // if current node is shallower than the last node we are no longer tracking child items
// 			// set the last node to be the last child item
// 			last!.lastChild = true;
// 			// if the element is a child (has a depth > 0), and if its parent also had a parent
// 			if (current.depth > 0 && parent?.parentId) {
// 				// find that parent and make that the new parent index
// 				// add element to history
// 				const parentElement = traversedElements[parent!.parentId];
// 				parentIndex = parentElement.index;
// 				current.isChild = true;
// 				current.parentId = parentElement?.id;
// 			} else {
// 				// reset parentIndex to none (its a top level element)
// 				parentIndex = -1;
// 			}
// 		} else if(parent !== null) {
// 			// if depth levels are the same and we have a parent, set the correct props
// 			current.isChild = true;
// 			current.parentId = parent.id;
// 		}
//
// 		lastIndex = index;
// 		// add element to history
// 		traversedElements[current.id] = current;
// 	});
//
// 	if (elements[elements.length-1]) {
// 		elements[elements.length - 1].lastChild = true;
// 	}
//
// 	return elements;
// }