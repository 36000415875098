import {styled} from "@mui/material";

export const BarContainer = styled("div")(({theme}) => ({
	backgroundColor: theme.palette.mainMenu.main,
	"& div.logoContainer": {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 5,
		"& .arrowIcon": {
			color: theme.palette.mainMenu.contrastText,
			opacity: theme.palette.action.disabledOpacity,
		},
		"& .arrowIconExpanded": {
			color: theme.palette.mainMenu.contrastText
		},
		"& div.expandDownButton": {
			position: 'absolute',
			padding: 0,
			cursor: 'pointer',
			bottom: 0,
			'&:hover svg': {
				opacity: 1
			}
		},
		"& div.expandRightButton": {
			position: 'absolute',
			cursor: 'pointer',
			padding: 0,
			right: 0,
			'&:hover svg': {
				opacity: 1
			}
		}
	},
	"& div.routeContainer": {
		display: 'flex',
		flex: 4,
		position: 'absolute',
		paddingLeft: 85,
		paddingRight: '2em',
		backgroundColor: theme.palette.mainMenu.main,
		borderRadius: 105,
		'& .vertical-context-menu': {
			width: "85px",
			zIndex: 1,
			flexDirection: "column !important",
			padding: "85px 0.5em 2rem !important",
			overflowX: "unset",
			boxSizing: "border-box !important"
		},
		"& .routesScrollContainer": {
			fontSize: '.7rem',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center',
			overflow: 'hidden',
			'&::-webkit-scrollbar': {
				height: '5px',
			},
			"& .customLink": {
				textDecoration: 'none',
				color: theme.palette.mainMenu.contrastText,
				fontWeight: theme.typography.fontWeightMedium,
				"& .customLinkListItem": {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'pointer',
					padding: '.5em',
					"& .customLinkListItemContents": {
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						position: 'relative',
						fontSize: '.7rem',
						padding: '.75em',
						borderRadius: '.6rem',
						"& .customLinkText": {
							fontSize: theme.typography.subtitle2.fontSize,
							textAlign: 'center'
						},
						"& .icon": {
							height: 30,
							width: 30,
							textColor: theme.palette.mainMenu.contrastText,
							marginBottom: 5,
						},
						'&:hover': {
							backgroundColor: '#fff',
							"& .icon": {
								fill: theme.palette.mainMenu.hover,
							},
							"& .customLinkText": {
								color:  theme.palette.mainMenu.hover,
							}
						},
					}
				}
			}
		}
	},
	"& div.verticalRouteContainerHidden": {
		display: 'none'
	}
}));