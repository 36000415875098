import { DialogProps } from "../../elements/model";
import {variableEditorForType} from "./editor";
import {editorModalForType} from "./modals";

const PropertyEditor = (props: DialogProps) => {

	const Component = variableEditorForType(props.type);
	const Modal = editorModalForType(props.type)!;

	return <Modal variable={props.variable} onClose={props.onClose} Component={Component} onSubmit={props.onSubmit} />
};

export { PropertyEditor };
