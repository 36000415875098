import { Column, ColumnViewProps } from "../../components/layout/table";
import { DataTableCell } from "../../components/layout/table/styles";
import { TaskNode } from "../../interfaces/task";
import {formatDistance} from "date-fns";

const Cell = (props: ColumnViewProps) => {
	const item = props.item as TaskNode;
	const column = props.column;
	const currentTime = new Date();

	const overdue = (item.timeout?.at ?? new Date()) < currentTime;
	return <DataTableCell
		align={column.itemAlign}
		width={column.width}
		key={`${column.title}-${item.id}`}
	>
		{(item.timeout?.at !== undefined) && <p>{`${formatDistance(item.timeout.at, currentTime)} ${overdue ? 'ago' : 'from now'}`}</p>}
		{(item.timeout?.at === undefined) && <p>N/A</p>}
	</DataTableCell>
}
const DaysUntilDue: Column = ({
	field: 'daysUntil',
	title: "Days Until Due",
	itemAlign: "left",
	headerAlign: "left",
	searchable: false,
	sortable: true,
	views: {
		cell: Cell
	}
})

export { DaysUntilDue };