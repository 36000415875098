export type ElasticMatch = {
	match: {
		[field: string]: string
	}
}

export type ElasticFuzzyMatch = {
	wildcard: {
		[field: string]: {value: string}
	}
}

export type ElasticMatchAll = {}
export type ShouldQuery = {
	should: ElasticNestableQueries[],
	minimum_should_match?: number
}
export type ElasticMatchingQueries = ElasticMatch | ElasticFuzzyMatch | ExistsQuery
export type ElasticNestableQueries = ElasticMatchingQueries | ElasticBoolQuery
export type ElasticQueries = ElasticNestableQueries | ElasticMatchAll;

export type MustQuery = {
	must: ElasticNestableQueries[]
}

export type ExistsQuery = {
	exists: {
		field: string
	}
}

export type MustNotQuery = {
	must_not: ElasticNestableQueries[]
}
export type ElasticBoolQuery<T = MustQuery | ShouldQuery | MustNotQuery | ExistsQuery> = {
	bool: T
}

export type ElasticSort = {
	[field: string]: {
		order: "desc" | "asc"
	}
}
export type ElasticQuery = {
	query: ElasticQueries,
	sort?: ElasticSort,
	size?: number,
	search_after?: string[] | number[]
}


export type QueryRequest = {
	body: ElasticQuery
}

export function isBoolQuery(obj: ElasticQueries): obj is ElasticBoolQuery {
	return (obj as ElasticBoolQuery).bool !== undefined;
}

export function isMustQuery(obj: MustQuery | ShouldQuery | MustNotQuery | ExistsQuery): obj is MustQuery {
	return (obj as MustQuery).must !== undefined;
}

export function isMustNotQuery(obj: MustQuery | ShouldQuery | MustNotQuery | ExistsQuery): obj is MustNotQuery {
	return (obj as MustNotQuery).must_not !== undefined;
}

export function isExistsQuery(obj: MustQuery | ShouldQuery | MustNotQuery | ExistsQuery | ElasticNestableQueries): obj is ExistsQuery {
	return (obj as ExistsQuery).exists !== undefined;
}

export function isShouldQuery(obj: MustQuery | ShouldQuery | MustNotQuery | ExistsQuery): obj is ShouldQuery {
	return (obj as ShouldQuery).should !== undefined;
}

export function isMatchQuery(obj: ElasticNestableQueries): obj is ElasticMatch {
	return (obj as ElasticMatch).match !== undefined;
}

export function isFuzzyMatch(obj: ElasticNestableQueries): obj is ElasticFuzzyMatch {
	return (obj as ElasticFuzzyMatch).wildcard !== undefined;
}