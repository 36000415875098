import {TemplateElementDefinition} from "../model";
import {HeaderIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import {PreviewView} from "./views/PreviewView";
import {
	headerDefinition as baseHeaderDefinition
} from "../../../../../shared/definitions/elements/header/model";

const headerDefinition: TemplateElementDefinition = {
	...baseHeaderDefinition,
	icon: HeaderIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		preview: PreviewView
	},
} as const

export {headerDefinition};