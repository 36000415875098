import {TypedAction} from "../../../../../shared/interfaces/ActionRun";
import {useAsync, useRunWhenValueChange, useTypedAsync} from "../../../../../shared/hooks";
import {DocumentState} from "../../useDocumentState";
import {LoadEntityFunc} from "../../../../../shared/interfaces/LoadEntityFuncType";
import {Variable} from "../../../../../shared/interfaces/Variable";
import {useState} from "react";
import {useGetDataSources} from "../../api/useGetDataSources";
import {DataSourceOptions} from "../../../../../shared/hooks/api/data/useGetDataSource";
import {EntityRecord} from "../../../../../shared/interfaces/Entity";
import {useTemplateStore} from "../../useTemplateStore";
import {getDocumentApi} from "../../api/useGetDocument";
import {recursivelyLoadAllDocuments} from "./recursivelyLoadAllDocuments";
import {constructDocumentTree} from "./constructDocumentTree";
import {useStatusBar} from "../../../../../shared/components";
import {recursivelyLoadAllTemplates} from "./recursivelyLoadAllTemplates";
import {DocumentRecord} from "../../../models/documentCollection";
import {isKaiAlphaTemplateWithBody} from "../../../../../shared/guards/isKaiAlphaTemplate";

const uuid = require('uuid');


function useLoadDocumentCollectionAction(): TypedAction<EntityRecord> {
	const [variables, setVariables] = useState<Variable[] | undefined>(undefined);
	const documentState = DocumentState.useContainer();
	const asyncAction = useAsync(recursivelyLoadAllDocuments, false);
	const loadMissingTemplates = useAsync(recursivelyLoadAllTemplates, false);
	const mapBodyElements = useTypedAsync(constructDocumentTree, false);
	const loadDataSources = useGetDataSources();
	const templateStore = useTemplateStore();
	const statusBar = useStatusBar();
	const loadTemplate: LoadEntityFunc<KaiAlphaTemplate> = templateStore.get;
	const loadDocument: LoadEntityFunc<KaiAlphaDocument> = getDocumentApi;

	// variables have been loaded
	useRunWhenValueChange(() => {
		const documentCollection = asyncAction.value ?? [];
		const topLevel = documentCollection.find(dc => dc.topLevel);
		if (topLevel?.template && isKaiAlphaTemplateWithBody(topLevel.template)) {
			loadMissingTemplates.execute(topLevel.template.body ?? [], documentCollection, topLevel.template.id);
		}
	}, asyncAction.value);

	useRunWhenValueChange(() => {
		const documentCollection = asyncAction.value ?? [];
		if (loadMissingTemplates.value) {
			documentCollection.push(...loadMissingTemplates.value.map(template => ({
				template,
				id: template.id,
				topLevel: false,
			}) as DocumentRecord))
		}
		const variableCollection = documentCollection.filter(document => document.variables !== undefined)
			.map(document => Object.values(document.variables!).map(value => ({id: uuid.v4(), ...value}) as Variable))
			.reduce((all, current) => all.concat(current), []);

		setVariables(variableCollection);
		mapBodyElements.execute(documentCollection);
	}, loadMissingTemplates.value)


	useRunWhenValueChange(() => {
		const documentCollection = asyncAction.value ?? [];
		documentState.setElements(mapBodyElements.value  ?? []);
		documentState.setDocumentCollection(documentCollection);

	}, mapBodyElements.value)

	// load any datasources
	useRunWhenValueChange(() => {
		if ((variables?.length ?? 0) > 0) {
			const datasources = variables!.filter(variable => variable.type === "datasource" && (variable.dataSources?.length ?? 0) === 0);
			if (datasources.length > 0) {
				loadDataSources.execute(datasources.map(variable => ({...(variable.options as DataSourceOptions), name: variable.name, id: variable.id})))
			} else {
				documentState.setVariables(variables!,{isPublishableUpdate : false});
			}
		}
	}, variables);

	// persist variables (and data sources) to doc state variables prop
	useRunWhenValueChange(() => {
		const sources = loadDataSources.value!.filter(source => source !== null);

		sources?.forEach(source => {
			const variable = variables?.find(v => v.id === source![0]!.id);
			if (variable) {
				variable.dataSources = source!
			}
		});

		if (variables) {
			documentState.setVariables([...variables!]);
		}
	}, loadDataSources.value);

	useRunWhenValueChange(() => {
		statusBar.sendWarningNotification({message: "One or more datasources failed to load", detail: loadDataSources.error});
		if (variables) {
			documentState.setVariables([...variables!]);
		}
	}, loadDataSources.error)


	useRunWhenValueChange(() => {
		documentState.setIsLoadingVariables(asyncAction.isLoading || mapBodyElements.isLoading);
	}, [asyncAction.isLoading, mapBodyElements.isLoading])

	return {
		run: ({id, version}: EntityRecord) => asyncAction.execute({
			documentInfo: {id, version, topLevel: true},
			loadTemplate,
			loadDocument
		}),
		isRunning: asyncAction.isLoading || loadDataSources.isLoading,
	}
}

export {useLoadDocumentCollectionAction};