import { BaseElement } from "../../../interfaces/BaseElement";
import {VariableType} from "../../../interfaces/Variable";

const variableDefinition: BaseElement = {
	name: 'Template',
	displayName: "Variable",
	type: 'variable',
	allowsChildren: false,
	description: 'Place Sub-Templates',
	longDescription: 'Template elements may be used to include another template at the position where this element is placed',
	contentDescription:  (contents) => {
		return (contents as VariableElementValue).description ?? "";
	},
	toString: (contents) => {
		return (contents as VariableElementValue).name ?? "Untitled Variable";
	}
} as const

export type VariableElementValue = {
	type: VariableType,
	name: string,
	options?: unknown,
	description: string
}

export type DatasourceMetaData = {
	[k: string]: string | number | string[] | number[]
}

export type DatasourceDataType = {[k: string]: string}[] | {[k: string]: string[]}

export type DataSourceMapType = "columns" | "auto" | "columns-rows" | "rows";
export type DatasourceContents = {
	"@metadata": {[k: string]: string | number | string[] | number[] | DatasourceMetaData},
	data: DatasourceDataType,
	name: string,
	type: DataSourceMapType
}
export {variableDefinition};