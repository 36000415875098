import { FlexBox } from "../../../../shared/components"
import {styled} from "@mui/material";

export interface EmptyViewProps {
	message?: string
}

const Message = styled("p")(({theme}) => ({
	color: theme.palette.text.secondary,
	fontSize: "1rem",
	fontStyle: "italic",
	margin: 0
}))
const EmptyView = ({message}) => {
	return <FlexBox justify={"flex-start"} align={"center"} width={"100%"}>
		<Message>{message ?? "Empty element"}</Message>
	</FlexBox>
}

export {EmptyView}