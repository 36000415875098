import { Box } from "@mui/material";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import { ArrowButton } from "../box/editControlBox/ArrowButton";


const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps) => {
	const handleBackButtonClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event!, page - 1);
	};

	const handleNextButtonClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event!, page + 1);
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<ArrowButton direction="left" disabled={page === 0} onClick={handleBackButtonClick}/>
			<ArrowButton direction="right" disabled={page >= Math.ceil(count / rowsPerPage) - 1} onClick={handleNextButtonClick}/>
		</Box>
	);
}

export {TablePaginationActions}