import {FlexBox} from "../../../../../shared/components";
import {ChevronRightRounded, KeyboardArrowDownRounded} from "@mui/icons-material";
import {TocItem} from "./TocItem";
import {useState} from "react";
import {EctdModule} from "../../../../../shared/interfaces/Module";
import "./styles.scss";

export type TocProps = {
	activeModules: EctdModule[],
	selectedModule?: EctdModule,
	selectedSection?: EctdModule,
	showTitle: boolean,
	onSelectSection?: (section: EctdModule) => void,
	onSelectModule?: (module: EctdModule) => void
}
const Toc = (props: TocProps) => {
	const [selectedModule, setSelectedModule] = useState<EctdModule | undefined>(undefined);
	const moduleClickHandlerFactory = (module: EctdModule) => () => props.onSelectModule ? props.onSelectModule(module) : setSelectedModule(selectedModule?.moduleNumber === module.moduleNumber ? undefined : module);

	const activeModule = props.selectedModule ?? selectedModule;
	return <div className={"ectd__list--toc"}>
		<ul className={"ectd__list--toc--modules"}>
			{props.activeModules.map(module =>
				<li className={"ectd__list--toc--modules-list"} key={`module-${module.moduleNumber}`}>
					<FlexBox onClick={moduleClickHandlerFactory(module)}>
						{activeModule && activeModule.moduleNumber === module.moduleNumber && <KeyboardArrowDownRounded fontSize={"small"} style={{color: "#EA6854"}} />}
						{(!activeModule || activeModule.moduleNumber !== module.moduleNumber) &&  <ChevronRightRounded fontSize={"small"} style={{color: "#EA6854"}} />}
						<span className={"ectd__list--toc--modules-list__number"}>{module.moduleNumber}</span>
						{props.showTitle && <span className={"ectd__list--toc--modules-list__title"}>{module.moduleTitle}</span>}
					</FlexBox>
					{activeModule && activeModule.moduleNumber === module.moduleNumber && <TocItem onSelectSection={props.onSelectSection} showTitle={props.showTitle} module={activeModule} selectedModule={props.selectedSection ?? activeModule} />}
				</li>)}
		</ul>
	</div>
}

export {Toc}