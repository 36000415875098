import {TabDefinition} from "../../../../shared/components/layout/tabs/tabDefinition";
import {TabbedPane} from "../../../../shared/components/layout/pane/TabbedPane";
import { VariablesTab } from "../tabs/variables";
import { CommentsTab } from "../tabs/comments";

const tabs: TabDefinition[] = [CommentsTab, VariablesTab];
const EditorRightPane = () => {
	return <TabbedPane orientation="right" tabs={tabs} />
}

export {EditorRightPane}
