import {DocumentCollection} from "../../../models/documentCollection";
import {ElementValueType} from "../../../../../shared/interfaces/ElementValueType";
import {TemplateElementValue} from "../../../../../shared/definitions/elements/template/model";
import {getTemplateApi} from "../../../../../shared/hooks/api/getTemplateApi";
import {EntityRecord} from "../../../../../shared/interfaces/Entity";
import {LoopElementValue} from "../../../../../shared/definitions/elements/loop/model";
import {SwitchElementValue} from "../../../../../shared/definitions/elements/switch/model";
import {isKaiAlphaTemplateWithBody} from "../../../../../shared/guards/isKaiAlphaTemplate";
import {SectionElementPreviewValue} from "../../../components/elements/section/model";

export async function recursivelyLoadAllTemplates(body: KaiAlphaTemplateBody, documentCollection: DocumentCollection, sourceId: string): Promise<KaiAlphaTemplate[]> {
	const bodyElements = body.map(bodyObj => {
		const id = Object.keys(bodyObj)[0];
		const {type, ...rest} = bodyObj[id];
		const element: ElementValueType = {id, type: type as string, data: {...rest}, source: sourceId};
		return element;
	})

	const missingTemplates = bodyElements
		.filter(element => element.type === "template")
		.filter(element => {
			const templateValue = element.data as TemplateElementValue;
			const template = documentCollection.find(dc => dc.template?.id === templateValue.id)?.template;
			return template === undefined;
		})
		.map(element => {
			const elementData = element.data as TemplateElementValue
			return {id: elementData.id, version: elementData.version} as EntityRecord;
		});

	const templates = await Promise.all(missingTemplates.map(getTemplateApi));
	const nestedTemplates = (await Promise.all(bodyElements.map(async (element) => {
		const {type, ...rest} = element;
		if (type === "section") {
			const sectionData = rest.data as SectionElementPreviewValue
			return recursivelyLoadAllTemplates(sectionData.body ?? [], documentCollection, sourceId);
		} else if (type === "switch") {
			const templates: KaiAlphaTemplate[] = [];
			const data = rest.data as SwitchElementValue;
			if (data.values) {
				const values = data.values as {[k: string]: {body: KaiAlphaTemplateBody}}
				const valueTemplates = await Promise.all(Object.keys(values)
					.map(valueKey => recursivelyLoadAllTemplates(values[valueKey].body, documentCollection, sourceId)));
				templates.push(...valueTemplates.reduce((all, current) => all.concat(current), []));
			}
			if (data.default) {
				const defaultBody = data.default as {body: KaiAlphaTemplateBody}
				const defaultTemplates = await recursivelyLoadAllTemplates(defaultBody.body, documentCollection, sourceId);
				templates.push(...defaultTemplates);
			}
			return templates;
		} else if (type === "loop") {
			const loopData = element.data as LoopElementValue;
			const loopBody = await recursivelyLoadAllTemplates(loopData.body ?? [], documentCollection, sourceId);
			const loopElse = await recursivelyLoadAllTemplates(loopData.else?.body ?? [], documentCollection, sourceId);
			return [...loopBody, ...loopElse];
		} else if (type === "template") {
			const data = element.data as TemplateElementValue;
			const template = documentCollection.find(record => record.template?.id === data.id && record.template?.version === data.version)?.template ??
				templates.find(record => record?.id === data.id && record?.version === data.version);
			if (isKaiAlphaTemplateWithBody(template)) {
				const children = await recursivelyLoadAllTemplates(template.body ?? [], documentCollection, template.id);
				return [...children];
			}
		}

		return []
	}))).reduce((all, current) => all.concat(current), []);

	const allTemplates = [...templates, ...nestedTemplates].filter(template => template !== null) as KaiAlphaTemplate[];
	const childTemplates = (await Promise.all(allTemplates.map(template => recursivelyLoadAllTemplates(template!.body ?? [], documentCollection, template!.id))))
		.reduce((all, current) => all.concat(current), []);
	return [...allTemplates, ...childTemplates];


}