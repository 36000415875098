const Seperator = '|';
const class_suffix = '_a3d9a401ff07cff669c0ca18636b3fec';
const variableClassNamePrefix = 'var_a3d9a401ff07cff669c0ca18636b3fec';

const temp_element = '<span id="remove">temporary element</span>';

const style_formats = [
	{ title: 'Paragraph', format: 'paragraph' },
	{ title: 'Heading 1', format: 'heading1' },
	{ title: 'Heading 2', format: 'heading2' },
	{ title: 'Heading 3', format: 'heading3' },
	{ title: 'Heading 4', format: 'heading4' },
	{ title: 'Heading 5', format: 'heading5' },
	{ title: 'Heading 6', format: 'heading6' },
	{ title: 'Preformatted', format: 'preformatted' },
]
const formats = {
	paragraph: { selector: 'p' },
	heading1: { block: 'h1' },
	heading2: { block: 'h2' },
	heading3: { block: 'h3' },
	heading4: { block: 'h4' },
	heading5: { block: 'h5' },
	heading6: { block: 'h6' },
	preformatted: { block: 'pre' },
}

const tinyMceOptions = {
	base_url: '/static/tinymce',
	plugins: [
		'table', 'lists', 'image', 'link', 'noneditable',
	].join(' '),
	resize: false,
	content_css: '/tinymce.css',
	toolbar: [
		'undo', 'redo', 'fontselect', 'fontsizeselect', Seperator, 'styleselect', 'symbols',
		'expression_builder', Seperator, 'bold', 'italic', 'forecolor',
		'backcolor', Seperator, 'alignleft',
		'aligncenter', 'alignright', 'alignjustify',
		Seperator, 'bullist', 'numlist', 'outdent',
		'indent', Seperator, 'removeformat', Seperator,
		'table', Seperator, 'comment', 'edit', 'del', Seperator
	].join(' '),
	menubar: false,
	elementpath: false,
	branding: false,
	skin_url: '/skin',
	table_default_styles: {
		width: 'calc(100% - 220px)'
	},
	block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
	font_formats: 'Andale Mono=andale mono,times; Times=times, Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
	formats,
	style_formats,
}

export {
	Seperator,
	class_suffix,
	temp_element,
	tinyMceOptions,
	variableClassNamePrefix
}