export type QueryStringOptions = {
	fields?: readonly string[],
	count?: number,
	filter?: string | string[],
	order?: string,
	orderby?: string,
	after?: string[] | number[]
}
export function generateListingQueryString(options: QueryStringOptions): string {
	const queryParts: string[] = [];

	if (options.fields !== undefined && options.fields.length > 0) {
		const encodedFields = options.fields.map(function(field) {
			return(encodeURIComponent(field));

		});
		queryParts.push(`fields=${encodedFields.join(',')}`);
	}

	if (options.count !== undefined) {
		queryParts.push(`count=${encodeURIComponent(options.count)}`);
	}

	if (options.filter !== undefined && options.filter !== '') {
		const filterString = Array.isArray(options.filter) ? JSON.stringify(options.filter) : options.filter;
		queryParts.push(`filter=${encodeURIComponent(filterString)}`);
	}

	if (options.order) {
		queryParts.push(`order=${encodeURIComponent(options.order)}`);
	}

	if (options.orderby) {
		queryParts.push(`orderby=${encodeURIComponent(options.orderby)}`);
	}

	if (options.after) {
		// if here, we received next page token from server in prior request
		queryParts.push(`after=${encodeURIComponent(JSON.stringify(options.after))}`);
	}

	if (queryParts.length === 0) {
		return('');
	}

	return(`?${queryParts.join('&')}`);
}