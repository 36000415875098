import React from 'react';
import './TemplateElementDialog.css';

/* Dialog Components */
import ExpressionBuilder from '../ExpressionBuilder';

/* TreeView Components */
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

/* Other Components */
import { TextField, Select, Dialog } from '../../lib/ui';
import { SearchBar } from "../../shared/components/input/SearchBar";
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, Checkbox, FormControlLabel } from '@mui/material';

/* Libraries */
import cross_references from '../../lib/cross_references';
import template_lib from '../../api/template.js';

import switch_image from '../../images/decision.svg';
import { TemplateList } from '../../modules/template/components/modals';

class TemplateElementDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dialog_open: true,
			current_case: '',
			template: {},
			templates_map: [],
			dropdown_value: ''
		};
	}

	componentDidMount() {
		this.setState({ dialog_open: true });
	}

	handle_close = () => {
		this.setState({ dialog_open: false });
		this.props.close_dialog();
	};

	apply_changes = () => {
		this.setState({ dialog_open: false });

		/* onChange function is different when handling switch cases */
		if (this.state.overrides !== undefined) {
			const case_to_value = {
				'Case': '@meta:value',
				'Default': '@meta:default'
			};

			this.state.overrides.onClick(case_to_value[this.state.current_case]);
		}

		this.props.close_dialog();
	};

	search(search_value, attribute, values) {
		const final_results = [];

		for (const value of values) {
			if (value[attribute] !== undefined) {
				if (value[attribute].toLowerCase().includes(search_value.toLowerCase().trim())) {
					final_results.push(value);
				}
			}
		}

		return (final_results);
	}

	async get_reference_values(template_id, template_name, items) {
		const values = await cross_references.get_referenceable_elements(
			template_id, template_name, items);
		this.setState({ template_elements: values });
	}

	get_template_preview() {
		let template_preview;
		if (Object.keys(this.state.template).length !== 0) {
			template_preview = <></>;
		}
		return (template_preview);
	}

	get_tree_item(node, item, children) {
		return (
			<TreeItem key={node.id} nodeId={node.id} label={node.name} onBlur={this.notify_changed}
				onLabelClick={async (event) => {
					if (node.id === 'root' || node.template_id === undefined) {
						this.setState({ preview_err_msg: 'Template does not exist. No preview shown.' });
						this.setState({ template: {} });
					} else if (node.type === 'top_level') {
						this.setState({ preview_err_msg: 'Select an element within the template' });
					} else {
						const template = await template_lib.getTemplateById(node.template_id);
						this.setState({ template: template });
						this.setState({ preview_err_msg: '' });
					}

					item.field_overrides(item.props.id, 'value', {
						'element_id': node.id,
						'template_id': node.template_id,
						'template_version': node.template_version,
						'name': node.name
					});
				}}>
				{children}
			</TreeItem>
		);
	}

	async refresh_templates_map() {
		if (this.state.templates_map.length > 0) {
			return;
		}
		if (this.refresh_templates_map_in_progress === true) {
			return;
		}
		this.refresh_templates_map_in_progress = true;

		if (this.cache_templates_map === undefined) {
			try {
				this.cache_templates_map = await template_lib.getTemplates();
			} catch (_ignored_error) {
				/*
				 * This will get re-attempted next time a refresh is needed
				 */
			}
		}

		if (this.cache_templates_map !== undefined) {
			const templates_map = this.cache_templates_map;

			this.setState({ templates_map }, () => {
				this.refresh_templates_map_in_progress = false;
			});
		} else {
			this.refresh_templates_map_in_progress = false;
		}
	}

	construct_content() {
		const content = this.props.item.content;
		const items = [];

		if (content === undefined) {
			return;
		}

		for (const item of content) {
			const props = this.props.item_props;

			if (props === undefined) {
				return;
			}

			const attribute = item.attribute;

			let default_value = '';
			if (attribute !== undefined) {
				switch (typeof attribute) {
					case 'object':
						{
							if (attribute[0] !== undefined) {
								const attribute_one = attribute[0];

								if (props.data[attribute_one] !== undefined) {
									const attribute_two = attribute[1];
									default_value = props.data[attribute_one][attribute_two];
								}
							}
							break;
						}
					case 'string':
						default_value = props.data[attribute];
						break;
					default:
						default_value = '';
						break;
				}
			}

			if (item.defaultValue) {
				default_value = item.defaultValue;
			}

			switch (item.type) {
				case 'checkbox':
					items.push(
						<div className='input-element'>
							{<FormControlLabel control={<Checkbox checked={item.value} value={item.value} />} onChange={(event) => item.field_overrides.onChange(event, !item.value)} label={item.label} />}
						</div>
					);
					break;
				case 'textfield':
					items.push(
						<div className='input-element'>
							<TextField defaultValue={default_value} id="outlined-basic" label={item.label} onChange={(event) => item.field_overrides.onChange(event)} />
						</div>
					);
					break;
				case 'raw':
					items.push(item.contents);
					break;
				case 'dropdown':
					{
						/* Dropdown should handle arrays and objects */
						let is_object;
						if (item.values && typeof item.values[0] === 'object') {
							is_object = true;
						} else {
							is_object = false;
						}

						items.push(
							<div className='input-element'>
								<Select defaultValue={default_value} label='Select' onChange={(event) => {
									this.setState({ [item.attribute]: event.target.value });
									item.field_overrides.onChange(event);
								}
								}>
									{is_object === true && item.values.map((value) =>
										<MenuItem value={value.value}>{value.title}</MenuItem>
									)}
									{is_object === false && item.values.map((value) =>
										<MenuItem value={value}>{value}</MenuItem>
									)}
								</Select>
							</div>
						);
					}
					break;
				case 'autocomplete':
					{
						const param = item.param;
						items.push(
							<Autocomplete
								{...item.field_overrides}
								freeSolo={true}
								clearOnBlur={false}
								options={item.values && item.values.map(function (item) {
									return (item[param]);
								})}
								getOptionLabel={function (item) {
									return (item);
								}}
								renderInput={(params) => {
									return (<TextField
										label={item.label}
										onChange={item.field_overrides.onChange}
										{...params}
										margin="normal"
									/>);
								}}
							/>
						);
						break;
					}
				case 'image':
					items.push(
						<div id={item.label} className={'dialog-element-container' + (this.state.current_case === item.label ? ' highlight' : '')} onClick={() => {
							this.setState({ current_case: item.label });
							this.setState({ overrides: item.field_overrides });
						}}>
							<div className='dialog-element-image-container'>
								<div className='dialog-element-image'>
									<img alt='Switch case' src={switch_image} />
								</div>
							</div>
							<div className='dialog-element-detail-container'>
								<div className='dialog-element-title'>{item.label}</div>
								<div className='dialog-element-sub-title-switch'>{item.description}</div>
							</div>
						</div>
					);
					break;
				case 'expressionbuilder':
					{
						items.push(
							<ExpressionBuilder
								className='editor-item-autocomplete-input'
								value={item.field_overrides.defaultValue}
								variables={this.props.all_variables}
								autoCompleteConfig={item.autoCompleteConfig}
								contextType={item.contextType}
								onChange={item.field_overrides.onChange}
								onBlur={item.field_overrides.onBlur}
								{...item.field_overrides}
							/>
						);
						break;
					}
				case 'treeview':
					{
						let template_elements = [];
						if (this.state.template_elements === undefined) {
							this.get_reference_values(item.template_id, item.template_name, item.items);
						} else {
							template_elements = this.state.template_elements;
						}

						const template_preview = this.get_template_preview();

						const all_children = [];
						const renderTree = (nodes) => {
							let children;
							if (nodes.length === undefined) {
								if (Array.isArray(nodes.children)) {
									children = nodes.children.map((node) => renderTree(node));
								} else {
									children = null;
								}

								return (this.get_tree_item(nodes, item, children));
							}

							for (const node of nodes) {
								let children;
								if (Array.isArray(node.children)) {
									children = node.children.map((child_node) => renderTree(child_node));
								} else {
									children = null;
								}

								all_children.push(this.get_tree_item(nodes, item, children));
							}

							return (all_children);
						};

						let no_elements_found_msg;
						if (this.state.template_elements && Object.keys(this.state.template_elements).length === 0) {
							no_elements_found_msg = <p>No elements found</p>;
						}

						let loading_elements;
						if (!this.state.template_elements) {
							loading_elements =
								<div className='tree-view-progress'>
									<CircularProgress thickness='6' style={{ height: '25px', width: '25px', marginRight: '8px', color: '#969798' }} />
								</div>;
						}

						items.push(
							<div>
								<div style={{ display: 'flex' }}>
									<div style={{ flex: '0.4' }}>
										<div className='dialog-title'>Choose Element</div>
										<div className='all-elements-container'>
											<SearchBar
												className='search-templates'
											// TODO: Update search function
											/>
											{no_elements_found_msg}
											{loading_elements}
											<TreeView
												className='tree-view'
												defaultCollapseIcon={<ExpandMoreIcon />}
												defaultExpandIcon={<ChevronRightIcon />}
											>
												{renderTree(template_elements)}
											</TreeView>
										</div>
									</div>
									<div style={{ flex: '0.6' }}>
										<div className='template-element-title'>{this.state.template.name}</div>
										<Tabs variant="fullWidth">
											<Tab label="Preview" />
										</Tabs>
										{this.state.preview_err_msg !== undefined && this.state.preview_err_msg}
										{template_preview}
									</div>
								</div>
							</div>
						);
						break;
					}
				case 'list':
					{
						items.push(<TemplateList onTemplateSelect={(template) => this.onTemplateSelect(template, item)} onRenderPreview={this.onRenderTemplatePreview} />);
						break;
					}
				default:
					throw (new Error(`Unable to handle item with type "${item.type}" in grid item`));
			}
		}

		return (items);
	}

	onTemplateSelect(template, item) {
		item.field_overrides.onClick(this.props.item_props.id, 'id', template.id);
		item.field_overrides.onClick(this.props.item_props.id, 'name', template.name?.replace(/\W+/gm, "_"));
	}

	onRenderTemplatePreview(template) {
		return template === null ? null : <></>;
	}

	render() {

		const content = this.construct_content();

		return (
			<div>
				<Dialog
					open={this.state.dialog_open}
					onClose={this.handle_close}
					aria-labelledby="form-dialog-title"
					style={{ minWidth: '60%' }}
					buttons={{
						'Close': this.handle_close,
						'Apply': this.apply_changes
					}}
				>
					<div className='template-element-dialog-container'>
						<div>
							<div className='dialog-title'>{this.props.item.title}</div>
							{content}
						</div>
					</div>
				</Dialog>
			</div>
		);
	}
}

export default TemplateElementDialog;
