import { CircularProgress as MuiCircularProgress, CircularProgressProps } from "@mui/material/"


function CircularProgress(props: CircularProgressProps) {
	const new_props: CircularProgressProps = {
		color: "primary",
		size: "3em",
		disableShrink: true,
		...props
	};

	return <MuiCircularProgress {...new_props}/>
}

export { CircularProgress }