import { ReadonlyElementProps } from "../../model";
import { SectionElementValue } from "../../../../../../shared/definitions/elements/section/model";
import { styled } from "@mui/material";
import { HighlightWrapper } from "../../../../../../shared/components/input/highlight/HighlightWrapper";
import { CommentState } from "../../../../hooks/useCommentState";
import { EmptyView } from "../../EmptyView";
import { CommentModalProps } from "../../../../../../shared/definitions/highlight/CommentModal.props";
import CommentModal from "../../../modals/templateComments/CommentModal";

const SectionHeader = styled("h2")({
	fontWeight: 600,
	fontSize: "1.5rem",
	margin: 0
})
const ReadonlyView = (props: ReadonlyElementProps) => {
	const value = props.data as SectionElementValue;

	const commentsManager = CommentState.useContainer();

	const highlightComments = commentsManager.highlightedCommentsByElementId(props.id);

	return value.name ?
		<HighlightWrapper
			elementId={props.id}
			enabled={true}
			highlightComments={highlightComments}
			value={value.name}
			componentToWrap={(highlightedText) => <SectionHeader dangerouslySetInnerHTML={{ __html: highlightedText }} />}
			renderCommentModal={ (values: CommentModalProps) =>
				<CommentModal
					onClose={values.onClose}
					addEditComment={"Add"}
					showOpen={values.showOpen}
					elementId={values.elementId}
					selectedText={values.selectedText}
					textProperties={values.textProperties}
				/>
			}
		/> :
		<EmptyView message={"No section title has been added"} />;
}

export { ReadonlyView }