import {styled} from "@mui/material";

export interface HtmlProps {
	value: string,
	className?: string
}

const HtmlWrapper = styled("div")({
	"& p": {
		fontSize: "1rem"
	}
})
const Html = ({value, className}: HtmlProps) =>
	<HtmlWrapper className={className} dangerouslySetInnerHTML={{__html: value}}></HtmlWrapper>

export {Html};