import api from '../../../../api/api';
import {useTypedAsync} from "../../../../shared/hooks";
import {InTextTableDataValue} from "../../../../shared/definitions/elements/inTextTable/model";

type GetInTextTableProps = {
	tableName: string,
	studyId: string
}

export type GetInTextTableResponse = {
	table: string
}
export function getInTextTableApi({tableName, studyId}: GetInTextTableProps): Promise<InTextTableDataValue> {
	const tableNameElements = tableName.split('_');
	return api.call('GET', `itt/${tableNameElements[0]}/${(tableNameElements.length > 1 ? tableNameElements[1] : tableNameElements[0])}/${studyId}`);
}
/**
 * Get a table by a given table Type and Name
 * Note that this is not complete, there some dependencies that need to be put in place.
 * TODO: Need to figure out where table type, and Study ID, fit in
 */
const useGetIttTable = () => {
	return useTypedAsync(getInTextTableApi, false);
}

export {useGetIttTable};