import {MetaDataValueEditor, ValueEditorProps} from "./model";
import {Button, FlexBox} from "../../../../../../../shared/components";
import {BaseModal} from "../../../../../../../shared/components/modals/BaseModal";
import {ChangeEvent, useState} from "react";
import {TextField} from "../../../../../../../shared/components/input";
import {Create} from "@mui/icons-material";
import {colors} from "../../../../../../../theme";

type Product = {
	name: string,
	manufacturer: string,
	dosageForm: string,
	excipients: string
}

const Editor = (props: ValueEditorProps) => {
	const product: Product = JSON.parse(props.data.value);
	const [addProduct, setAddProduct] = useState(false);

	const [name, setName] = useState(product.name);
	const [manufacturer, setManufacturer] = useState(product.manufacturer);
	const [dosageForm, setDosageForm] = useState(product.dosageForm);
	const [excipients, setExcipients] = useState(product.excipients);

	const onAddSubstance = () => setAddProduct(true)
	const onCancelAdd = () => setAddProduct(false);

	const onSetName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setName(e.target.value);
	const onSetManufacturer = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setManufacturer(e.target.value);
	const onSetDosageForm = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setDosageForm(e.target.value);
	const onSetExcipients = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setExcipients(e.target.value);

	const onSave = () => {
		setAddProduct(false);
		props.onChange(JSON.stringify({name, manufacturer, dosageForm, excipients}));
	}

	const hasValue = (product.name?.length ?? 0) > 0 && (product.manufacturer?.length ?? 0) > 0 && (product.dosageForm?.length ?? 0) > 0;
	return <>
		{hasValue && <FlexBox
			onClick={onAddSubstance}
			align={"center"}
			sx={{...(props.sx ?? {}), cursor: "pointer"}}>
			<span>{product.name} - {product.dosageForm} ({product.manufacturer})</span>
			<Create fontSize={"small"} style={{color: colors.trueBlue, marginLeft: ".25rem"}} />
		</FlexBox>}
		{!hasValue && <Button text={"Add"} onClick={onAddSubstance} />}
		<BaseModal
			visible={addProduct}
			onClose={onCancelAdd}
			title={"Product"}
			buttonRow={<>
				<Button text={"Cancel"} styling={"cancel"} onClick={onCancelAdd} />
				<Button buttonType={"default"} text={"Save"} onClick={onSave} />
			</>}
			content={<FlexBox direction={"column"}>
				<TextField value={name} label={"Name"} onChange={onSetName} />
				<TextField value={manufacturer} sx={{marginTop: "1rem"}} label={"Manufacturer"} onChange={onSetManufacturer} />
				<TextField value={dosageForm} sx={{marginTop: "1rem"}} label={"Dosage form"} onChange={onSetDosageForm} />
				<TextField value={excipients} sx={{marginTop: "1rem"}} label={"Excipients"} onChange={onSetExcipients} />
			</FlexBox>}
		/>
	</>
}

export const ProductEditor: MetaDataValueEditor = {
	view: Editor,
	displayTextGenerator: (value: string) => {
		const product = JSON.parse(value) as Product;
		return `${product.name} - ${product.dosageForm} (${product.manufacturer})`;
	},
	defaultValueFactory: () => JSON.stringify({name: "", manufacturer: "", dosageForm: "", excipients: ""})
}