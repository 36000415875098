import { Box, styled } from "@mui/material/";
import { FlexBox } from "../../../../../shared/components";

const Hr = styled("hr")(({theme}) =>({
	height: "2px",
	background: theme.palette.tagsChip?.main,
	margin: "30px 0px 0px 0px",
	width: "100%",
	border: 0,
}));

const SearchBarContainer = styled(Box)({
	marginBottom: "1rem"
})

const TemplatePreviewContainer = styled(FlexBox)(({theme}) => ({
	boxSizing: "border-box",
	padding: "20px 0rem",
	"& svg": {
		cursor: "pointer"
	},
}));

const ScrollablePreview = styled(FlexBox)(({theme}) => ({
	boxSizing: "border-box",
	border: `2px solid ${theme.palette.dashboard?.border}`,
	margin: "3rem 20px 0",
	padding: "10px",
	overflowY: 'scroll',
	overflowX: 'hidden',
	'&::-webkit-scrollbar': {
		width: '5px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: theme.palette.dashboard?.border,
		borderRadius: "30px",
		height: "5px",
	},
	minHeight: "30rem",
	maxHeight: "25rem",
}));

const HeaderLabel = styled(FlexBox)(({theme}) => ({
	transition: "all .2s ease",
	color: theme.palette.tabs?.hover,
	userSelect: "none",
	'& .icon': {
		fontSize: "1.1rem",
	},
	'& .header_title': {
		fontWeight: 600,
	},
}));

const SelectedTemplateContainer = styled(FlexBox)(({theme}) =>({
	boxSizing: "border-box",
	padding: "5px 10px",
	margin: "25px 0px 0px",
	fontSize: "1.1rem",
	backgroundColor: theme.palette.tagsChip?.main,
}));

export {
	Hr,
	HeaderLabel,
	ScrollablePreview,
	SelectedTemplateContainer,
	SearchBarContainer,
	TemplatePreviewContainer
}
