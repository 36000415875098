import React from 'react';
import './WorkflowEditor.css';


import UserListSelect from '../UserListSelect';
import ExpressionBuilder from '../ExpressionBuilder';
import MaterialTableStyled from '../MaterialTableStyled';
import PromptsDialog from '../PromptsDialog';
import { push_status_task } from '../StatusBar';

import { Button } from '../../lib/ui';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { TextField, Select, Dialog } from '../../lib/ui';

import DialogContentText from '@mui/material/DialogContentText';

import nunjucks_utils from '../../lib/utils/nunjucks_utils';
import workflow_utils from '../../lib/utils/workflow_utils';
import workflow_api from '../../api/workflow';
import kaialpha from '../../lib/kaialpha';
import { AppChrome } from '../App/Chrome/AppChrome';

import ReactFlow, {
	Controls,
	Background,
	Handle,
	ReactFlowProvider
} from 'react-flow-renderer';
import {ContextButtonFactory, DefaultContextButtons} from "../../shared/components/buttons/theme";
import {iconForWorkflow} from "./iconForWorkflow";
import {AclEditor} from "../../shared/components/modals/aclEditor";
const clone = require("rfdc/default");

const DEFAULT_FRIENDLY_NAME = {
	'@none': '(none)',
	'new_document': 'New Contents',
	'new_template': 'New Templates',
};

const uuid = require('uuid');

/* XXX:TODO: Move to workflow utils */
/*
 * There exists a bijective mapping between the generated ReactFlow Graph
 * and the workflow
 */
function rfgraph_to_workflow(rfgraph) {
	const { nodes: block_nodes, edges: block_edges } = rfgraph;
	const actions = [];

	/* Sort nodes in correct order */
	block_nodes.sort((a, b) => (a.position.x >= b.position.x) ? 1 : -1);

	const if_statement_actions = [];
	for (const item in block_nodes) {
		const element_info = block_nodes[item];

		if (element_info.operation === undefined) {
			break;
		}

		const operation = element_info['operation'];
		let parameters = {};

		if (element_info.operation === 'set_ui_action_prompts') {
			const prompt_parameters = {};
			if (element_info['parameters']['prompts']) {
				for (const prompt of element_info['parameters']['prompts']) {
					if (!prompt_parameters['prompts']) {
						prompt_parameters['prompts'] = [];
					}
					prompt_parameters['prompts'].push(prompt);
					parameters = prompt_parameters;
				}
			}
		} else {
			for (const key in element_info['parameters']) {
				parameters[key] = element_info['parameters'][key];
			}
		}

		/* Get all statements following an if statement */
		if (element_info.operation === 'if') {
			/* Get all target nodes for source node */
			const target_element = block_edges.map((x, index) => x.source === element_info.id ? index : '').filter(String);
			target_element.forEach(function (element) {
				const target_id = block_edges[element].target;
				const target_element_index = block_nodes.findIndex(x => x.id === target_id);
				const target_element_info = block_nodes[target_element_index];

				if_statement_actions.push({
					id: item.id,
					operation: target_element_info['operation'],
					parameters: Object.values(target_element_info['parameters'])
				});
			});

			parameters = [Object.values(element_info['parameters']), if_statement_actions];
		}

		// save the position of the node on ui
		const position = element_info.position;

		/* Don't add actions that are a part of the if statement */
		if (element_info.in_if_statement === undefined) {
			actions.push({
				id: element_info.id,
				operation: operation,
				parameters: parameters,
				metadata: {
					position: position
				}
			});
		}
	}

	return (actions);
}

function workflow_to_rfgraph(actions) {
	const nodes = [];
	const edges = [];

	let count = -1;
	let last_action_id;
	for (const action of actions) {
		count++;

		/*
		 * Workflows may include some metadata, including a position.
		 * In the future the position can be computed by a layout
		 * engine.
		 */
		let position = action.metadata?.position;
		if (!position) {
			/**
			 ** Use a naive layout engine otherwise for now
			 **/
			position = { x: 250 + (count * 650), y: 80 + (count * 5) };
		}

		nodes.push({
			id: action.id,
			operation: action.operation,
			parameters: action.parameters,
			position: position
		});

		if (last_action_id) {
			edges.push({
				id: `${last_action_id}_${action.id}`,
				source: last_action_id,
				target: action.id
			});
		}

		last_action_id = action.id;
	}

	const retval = {
		nodes,
		edges
	};

	return (retval);
}

class WorkflowEditor extends React.Component {
	actions = ['set_owner', 'set_ui_action_review', 'set_ui_action_approve', 'set_ui_action_prompts', 'if', 'set_ui_counter_button'];
	defaults = workflow_utils.constants.default_for;
	blocks = ['named_code_block', 'instantiate_template'];
	states = ['In Progress', 'Reviewed Needs Changes', 'Reviewed Accepted'];

	constructor(props) {
		super(props);

		this.state = {
			workflow: {},
			dialog_open: false,
			open_code_block: false,
			blocks: [],
			current_element: '',
			current_block: '',
			elements: {},
			named_code_block: '',
			edges: [],
			workflow_name: '',
			open_prompts_dialog: false,
		};

		this.construct_node = this.construct_node.bind(this);
		this.delete_node = this.delete_node.bind(this);
		this.save_workflow = this.save_workflow.bind(this);
		this.handle_close = this.handle_close.bind(this);
		this.set_block = this.set_block.bind(this);
		this.set_code_block_name = this.set_code_block_name.bind(this);
		this.add_block = this.add_block.bind(this);
		this.select_block = this.select_block.bind(this);
		this.set_workflow_name = this.set_workflow_name.bind(this);
		this.close_prompts_dialog = this.close_prompts_dialog.bind(this);
		this.onContextMenuClick = this.onContextMenuClick.bind(this);
	}

	start_position = { x: 250, y: 80 };

	async componentDidMount() {
		await this.set_workflow_info();
	}

	async set_workflow_info() {
		if (!this.props.match || !this.props.match.params || !this.props.match.params.workflow_id) {
			return;
		}

		const workflow_id = this.props.match.params.workflow_id;
		const workflow = await workflow_api.get_user_workflow(null, workflow_id);

		this.setState({ workflow: workflow });
		this.setState({ workflow_name: workflow.name });
		this.setState({ workflow_default: workflow.default });

		this.set_workflow_actions(workflow);
	}

	set_workflow_actions(workflow) {
		const blocks = workflow.actions;
		if (!blocks) {
			return;
		}

		for (const block in blocks) {
			this.add_block(block);

			const actions = blocks[block];

			const { nodes, edges } = workflow_to_rfgraph(actions);
			for (const node of nodes) {
				this.construct_node(null, node.id, block, node.operation, node.position, node.parameters);
			}

			/*
			 * Set edges -- it would be better to make this abstraction similar to construct_node, but
			 * that abstraction really needs work
			 */
			this.setState(function (prevState) {
				const elements = {
					...prevState.elements,
					[block]: {
						...prevState.elements[block],
						edges: edges
					}
				};

				return ({
					elements
				});
			});
		}
	}

	set_parameters(id, type, block, event) {
		let parameters;

		if (event === undefined) {
			return;
		}

		if (event.target !== undefined) {
			parameters = event.target.value;
		} else {
			parameters = event;
		}

		/* Update parameters for element in array */
		const elements = this.state.elements;
		const nodes = elements[block]['nodes'];
		const index = nodes.findIndex(x => x.id === id);

		if (index !== undefined && index !== null) {
			if (typeof parameters === 'object') {
				parameters = parameters.toString();
			}

			if (!nodes[index]['parameters']) {
				nodes[index]['parameters'] = {};
			}

			nodes[index]['parameters'][type] = parameters;
			this.setState({ elements: elements });
		}
	}

	handle_close() {
		this.setState({ dialog_open: false });
		this.setState({ open_code_block: false });
	}

	select_element(element) {
		this.setState({ dialog_open: true });
		this.setState({ current_element: element });
	}

	select_block(element) {
		if (element === 'named_code_block') {
			this.setState({ open_code_block: true });
			return;
		}

		this.add_block(element);
	}

	add_block(element) {
		const elements = this.state.elements;
		let block = element;

		if (this.state.open_code_block === true) {
			this.setState({ open_code_block: false });
			block = this.state.named_code_block;
		}

		if (this.state.blocks.includes(block)) {
			return;
		}

		/* Add block to workflow editor only if it has not yet been added */
		elements[block] = { nodes: [], edges: [] };
		this.setState({ elements: elements });
		this.setState({ blocks: this.state.blocks.concat(block) });
	}

	set_block(event) {
		if (event.target !== undefined) {
			this.setState({ current_block: event.target.value });
		}
	}

	set_code_block_name(event) {
		this.setState({ named_code_block: event.target.value });
	}

	open_prompts_dialog(item) {
		this.setState({ open_prompts_dialog: true });
		const current_block = item.block;
		this.setState({ current_block: current_block });
		this.setState({ current_id: item.id });
	}

	WorkflowEditor_item(items) {
		const nodes = [];

		for (const index in items) {
			const item = items[index];
			switch (item.type) {
				case 'label':
					nodes.push(
						<div>
							<label>{item.value}</label>
							<br></br>
							<br></br>
						</div>
					);
					break;
				case 'text':
					{
						nodes.push(
							<div>
								<TextField label={item.title} onChange={(args) => item.onChange(args)} />
							</div>
						);
						break;
					}
				case 'button':
					{
						this.setState({ current_id: item.id });
						nodes.push(
							<div>
								<label>{item.title}</label>
								<br></br>
								<Button onClick={() => this.open_prompts_dialog(item)}>Open Dialog</Button>
							</div>
						);
						break;
					}
				case 'table':
					{
						const columns = [
							{ title: 'Name', field: 'name' },
							{ title: 'Surname', field: 'surname', initialEditValue: 'initial edit value' },
							{ title: 'Birth Year', field: 'birthYear', type: 'numeric' },
							{
								title: 'Birth Place',
								field: 'birthCity',
								lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
							},
						];

						const data = [
							{ name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
							{ name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34 },
						];

						nodes.push(
							<MaterialTableStyled
								columns={columns}
								data={data}
								title={'test'}
							/>
						);
						break;
					}
				case 'select':
					{
						nodes.push(
							<Box mt={1}>
								<Select label={item.title} onChange={(event) => this.set_parameters(item.id, item.title, item.block, event)}>
									{item.values.map((value) =>
										<MenuItem value={10}>{value}</MenuItem>
									)}
								</Select>
							</Box>
						);
						break;
					}
				case 'autocomplete':
					{
						nodes.push(
							<Box mt={1}>
								<UserListSelect
									label={item.title}
									multiple={true}
									onChange={(event) => this.set_parameters(item.id, item.title, item.block, event)}
								/>
							</Box>
						);
						break;
					}
				case 'expressionbuilder':
					{
						nodes.push(
							<div>
								<label>{item.title}</label>
								<br></br>
								<ExpressionBuilder
									autoCompleteConfig={item.autoCompleteConfig}
									onChange={(event) => this.set_parameters(item.id, item.title, item.block, event)}
								/>
							</div>
						);
						break;
					}
				case 'port':
					{
						const item_options = {
							position: 'right',
							isConnectable: true,
							...item,
							type: item.port_type,
						};

						nodes.push(<Handle {...item_options} />);
						break;
					}
				default:
					throw (new Error(`Unable to handle item with type "${item.type}" in workflow editor item`));
			}
		}

		return (<div>{nodes}</div>);
	}

	construct_node(event, node_id, block, element, position, parameters = {}) {
		this.handle_close();

		let id = node_id;
		if (!id) {
			id = uuid.v4();
		}

		let current_block = block;
		if (!current_block) {
			current_block = this.state.current_block;
		}

		let current_element = element;
		if (!current_element) {
			current_element = this.state.current_element;
		}

		let data;

		/* Generate input fields for node based on action type */
		/* XXX:TODO: This needs to take into account the value of "parameter" */
		switch (current_element) {
			case 'set_ui_action_approve':
			case 'set_ui_counter_button':
			case 'set_ui_action_review':
				data = this.WorkflowEditor_item([
					{
						type: 'label',
						value: current_element
					}
				]);
				break;
			case 'set_owner':
				data = this.WorkflowEditor_item([
					{
						type: 'label',
						value: current_element
					},
					{
						type: 'autocomplete',
						id: id,
						operation: current_element,
						title: 'owner',
						block: current_block,
					}
				]);
				break;
			case 'set_ui_action_prompts':
				{

					data = this.WorkflowEditor_item([
						{
							type: 'label',
							value: current_element
						},
						{
							type: 'button',
							id: id,
							operation: current_element,
							title: 'Add Prompts',
							block: current_block,
						}
					]);
					break;
				}
			case 'if':
				data = this.WorkflowEditor_item([
					{
						type: 'label',
						value: current_element
					},
					{
						type: 'expressionbuilder',
						id: id,
						operation: current_element,
						title: 'statement',
						block: current_block,
						autoCompleteConfig: [
							{
								trigger: '|',
								options: nunjucks_utils.constants.filters
							},
							{
								trigger: ' ',
								options: nunjucks_utils.constants.operators,
								excludePredecessors: [...nunjucks_utils.constants.operators]
							}
						],
					},
					{
						type: 'port',
						port_type: 'destination',
						position: 'right',
						id: 'then',
						style: { top: 10 }
					},
					{
						type: 'port',
						port_type: 'destination',
						position: 'right',
						id: 'else',
						style: { bottom: 10, top: 'auto' }
					},
				]);
				break;
			default:
				break;
		}

		this.add_node(id, current_block, current_element, data, position, parameters);
	}

	add_node(id, block, element, data, position, parameters = {}) {
		this.setState(function (prevState) {
			const new_node = {
				id: id,
				data: { label: data },
				sourcePosition: 'right',
				targetPosition: 'left',
				position: position || this.start_position,
				operation: element,
				parameters: parameters,
				style: { width: '30%' }
			};

			/* TODO: Check if current block has has node with operations
			 * that can't be reused
			 */
			/* XXX:TODO: There's a helper function to facilitate deep setting like this */
			const elements = {
				...prevState.elements,
				[block]: {
					...prevState.elements[block],
					nodes: [
						...prevState.elements[block].nodes,
						new_node
					]
				}
			};

			return ({
				elements
			});
		});
	}

	connect_nodes(event, block) {
		const source_id = event.source;
		const target_id = event.target;

		const new_edge = {
			id: uuid.v4(),
			source: source_id,
			target: target_id,
			animated: false
		};

		/* Add edge to state */
		const elements = this.state.elements;
		const elements_at_block = elements[block];
		const updated_elements = elements_at_block;
		updated_elements['edges'].push(new_edge);

		/*
		 * Set in_if_statement to true to target node if it is
		 * part of an if statement
		 */
		const source_element_index = elements_at_block['nodes'].findIndex(x => x.id === source_id);
		const source_element = elements_at_block['nodes'][source_element_index];

		if (source_element.operation === 'if') {
			const target_element_index = elements_at_block['nodes'].findIndex(x => x.id === target_id);
			updated_elements['nodes'][target_element_index]['in_if_statement'] = true;
		}

		this.setState({ ...this.state.elements, ...updated_elements });
	}

	delete_node(all_elements, block) {
		const element = all_elements[0];
		const elements = this.state.elements;
		let type;

		/* Delete edge */
		if (element.source !== undefined && element.target !== undefined) {
			type = 'edges';
		}

		/* Delete node */
		if (element.source === undefined && element.target === undefined) {
			type = 'nodes';
		}

		const updated_elements = elements[block][type].map(a => ({ ...a }));

		for (const index in updated_elements) {
			const node = updated_elements[index];
			if (node.id === element.id) {
				updated_elements.splice(index, 1);
				elements[block][type] = updated_elements;
				this.setState({ elements: elements });
			}
		}
	}

	compute_workflow_actions() {
		const actions = {};

		/* Construct actions object with blocks */
		const elements = this.state.elements;

		for (const block in elements) {
			const block_nodes = elements[block]['nodes'];
			const block_edges = elements[block]['edges'];

			actions[block] = rfgraph_to_workflow({
				nodes: block_nodes,
				edges: block_edges
			});
		}

		return (actions);
	}

	async save_workflow(id) {
		const actions = this.compute_workflow_actions();
		let workflow_name = this.state.workflow_name;
		if (this.state.workflow_name === '') {
			workflow_name = 'Unnamed Workflow';
		}

		await push_status_task('Saving workflow', async () => {
			let saved_workflow;
			if (!id) {
				saved_workflow = await workflow_api.new_user_workflow(null, workflow_name, actions, this.state.workflow_default);
			} else {
				saved_workflow = await workflow_api.update_user_workflow(null, id, this.state.workflow.version, workflow_name, actions, this.state.workflow.permissions, this.state.workflow_default);
			}
			const current_workflow = await workflow_api.get_user_workflow(null, saved_workflow.id);
			this.setState({ workflow: current_workflow });
		});
	}

	flatten_elements(elements) {
		const nodes = elements.nodes;
		const edges = elements.edges;

		const formatted_elements = nodes.concat(edges);
		return (formatted_elements);
	}

	on_drag_stop(event, node, block) {
		const x_position = node.position.x;
		const y_position = node.position.y;
		const node_id = node.id;

		/* Get node in block and update its position */
		const elements = this.state.elements;
		const updated_nodes = elements[block]['nodes'];

		const index = updated_nodes.findIndex(x => x.id === node_id);
		updated_nodes[index]['position'] = { x: x_position, y: y_position };
		this.setState({ elements: elements });
	}

	set_workflow_name(event) {
		const name = event.target.value;

		this.setState({ workflow_name: name });
	}

	onContextMenuClick(buttonKey) {
		if (buttonKey.startsWith("ELEMENT-")) {
			this.select_element(buttonKey.replace("ELEMENT-", ""));
			return;
		}

		if (buttonKey.startsWith("BLOCK-")) {
			this.select_block(buttonKey.replace("BLOCK-", ""));
			return;
		}

		switch(buttonKey) {
			case "acl_editor":
				this.setState({
					acl_editor_open: true
				});
				break;
			default:
				this.save_workflow(this.state.workflow.id)
				break;
		}
	}

	set_workflow_default(event) {
		let default_for = event.target.value;

		if (default_for === '@none') {
			default_for = [];
		} else {
			default_for = [default_for];
		}

		this.setState({ workflow_default: default_for });
	}

	close_prompts_dialog() {
		this.setState({ open_prompts_dialog: false });
	}

	get_prompts(id, prompts, current_block) {
		const elements = this.state.elements;
		const nodes = elements[current_block]['nodes'];
		const index = nodes.findIndex(x => x.id === id);

		if (!nodes[index]['parameters']['prompts']) {
			nodes[index]['parameters'] = {
				'prompts': []
			};
		}

		nodes[index]['parameters']['prompts'] = prompts;
		this.setState({ open_prompts_dialog: false });
		this.setState({ elements: elements });

		if (!this.state.workflow.id) {
			this.save_workflow();
		}
	}

	default_for_name(id) {
		const name = DEFAULT_FRIENDLY_NAME[id] || id;

		if (name === id) {
			kaialpha.lib.debug.log('WorkflowEditor', 'No human readable name for Default For of', id);
		}

		return name;
	}

	render() {
		let workflow_default_list = [];
		if (this.state.workflow_default) {
			workflow_default_list = this.state.workflow_default;
		}
		let workflow_default = workflow_default_list[0];
		if (workflow_default === undefined) {
			workflow_default = '@none';
		}

		const select_block_dialog =
			<Dialog
				open={this.state.dialog_open}
				onClose={this.handle_close}
				title={'Add Action'}
				buttons={{
					'Cancel': this.handle_close,
					'Add Action': this.construct_node
				}}
			>
				<>
					<DialogContentText>
						Select a block to add this action to.
					</DialogContentText>
					<Select label="Block" onChange={this.set_block}>
						{this.state.blocks.map(function (block) {
							return (<MenuItem key={block} value={block}>{block}</MenuItem>);
						})}
					</Select>
				</>
			</Dialog>;

		const named_code_block_dialog =
			<Dialog
				open={this.state.open_code_block}
				onClose={this.handle_close}
				title={'Enter a name for the code block.'}
				buttons={{
					'Cancel': this.handle_close,
					'Submit': this.add_block
				}}
			>
				<TextField label="Code Block Name" onChange={this.set_code_block_name} />
			</Dialog>;

		const react_flow = (
			<div>
				{this.state.blocks.map((block) => {
					return (
						<div key={block} className='react-flow-container'>
							<p>{block}</p>
							<ReactFlowProvider>
								<ReactFlow
									key={block}
									elements={this.flatten_elements(this.state.elements[block])}
									style={{ width: '100%', height: '500px' }}
									snapToGrid={true}
									snapGrid={[15, 15]}
									onElementsRemove={(element) => this.delete_node(element, block)}
									onConnect={(event) => this.connect_nodes(event, block)}
									onNodeDragStop={(event, node) => this.on_drag_stop(event, node, block)}
									deleteKeyCode={8}
								>
									<Controls />
									<Background variant="lines" gap={12} size={4} />
								</ReactFlow>
							</ReactFlowProvider>
						</div>
					);
				})}
			</div>
		);

		return (<AppChrome
			center={<div className='workflow-editor'>
				<AclEditor
					visible={this.state.acl_editor_open}
					id={this.state.workflow.id}
					type='workflow'
					permissions={clone(this.state.workflow.permissions)}
					onSave={async (new_perms) => {
						this.setState({
							acl_editor_open: false
						});

						await push_status_task('Saving ACL', async () => {
							const new_workflow_info = await workflow_api.update_user_workflow(null, this.state.workflow.id, this.state.workflow.version, undefined, undefined, new_perms);
							const new_workflow = await workflow_api.get_user_workflow(null, new_workflow_info.id, new_workflow_info.version);

							this.setState({
								workflow: new_workflow
							});
						});
					}}
					onCancel={() => {
						this.setState({
							acl_editor_open: false
						});
					}}
				/>
				<div>
					{this.state.open_prompts_dialog ? <PromptsDialog
						close_prompts_dialog={this.close_prompts_dialog}
						id={this.state.current_id}
						get_prompts={(id, prompts, current_block) => this.get_prompts(id, prompts, current_block)}
						current_block={this.state.current_block}
						elements={this.state.elements}
					/> : null}
				</div>
				<Grid container align="center">
					<Grid item xs={12}>
						<div className='workflow-name'>
							<Grid container>
								<Grid item lg={10} md={9} sm={8} xs={12}>
									<TextField label="Workflow Name" id={this.state.workflow.id} onChange={(event) => { this.set_workflow_name(event); }} value={this.state.workflow_name}></TextField>
								</Grid>
								<Grid item lg={2} md={3} sm={4} xs={12}>
									<Select label="Default For" onChange={(event) => { this.set_workflow_default(event); }} value={workflow_default}>
										<MenuItem key='@none' value={'@none'}>{this.default_for_name('@none')}</MenuItem>
										{this.defaults.map((item) => {
											return (<MenuItem key={`item_${item}`} value={item}>{this.default_for_name(item)}</MenuItem>);
										})}
									</Select>
								</Grid>
							</Grid>
						</div>
						<br></br>
					</Grid>
					<br></br>
				</Grid>
				{react_flow}
				{select_block_dialog}
				{named_code_block_dialog}
			</div>}
			mastHeadContextButtons={ContextButtonFactory([
				...this.actions.map(element => ({
					key: `ELEMENT-${element}`,
					Icon: iconForWorkflow(element),
					label: element.replace(/_/g, " ")
				})),
				...this.blocks.map(element => ({
					key: `BLOCK-${element}`,
					Icon: iconForWorkflow(element),
					label: element.replace(/_/g, " ")
				})),
				{key: "acl_editor", label: "ACL Editor", Icon: iconForWorkflow("acl_editor")},
				{...DefaultContextButtons.Save}
			], this.onContextMenuClick)}
		/>
		);
	}
}

export default WorkflowEditor;
