import {ModuleColumn, ModuleColumnCellViewProps} from "../models";
import {FlexBox} from "../../../../../../shared/components";
import {ColumnLabel} from "./ColumnLabel";
import {computeCompletion} from "../../../../utilities/computeCompletion";

const Header = () => {
	return <ColumnLabel value={"Completion"} />
}

const Cell = (props: ModuleColumnCellViewProps) => {
	const onClick = () => {
		if (props.onAction) {
			props.onAction("click", props.column);
		}
	}

	const completion = computeCompletion(props.row);
	return <FlexBox width={"100%"} justify={"center"} onClick={onClick}>
		<span style={{color: (completion ?? -1) <= 0 ? "#F4A5AE" : "#0F9382" }}>{completion ? `${completion}%` : "No Content"}</span>
	</FlexBox>
}

const CompletionColumn: ModuleColumn = {
	field: "computedCompletion",
	width: "100%",
	views: {
		header: Header,
		cell: Cell
	}
}

export {CompletionColumn}