import {ExtractionStatusLabelTypeProps} from "./model";
import {LabelPaper} from "./LabelPaper";
import {LabelTitle} from "./LabelTitle";
import {FlexBox} from "../../../../../../shared/components";
import {LabelSubTitle} from "./LabelSubTitle";

export const StartedExtractionLabel = (props: ExtractionStatusLabelTypeProps) => {
	return <LabelPaper status={"Started"} justify={"space-between"} align={"center"}>
		<FlexBox align={"center"}>
			<LabelTitle status={"Started"}>Extraction Started</LabelTitle>
		</FlexBox>
		<FlexBox align={"center"} justify={"flex-end"}>
			<LabelSubTitle status={"Started"}>Extracting Data...</LabelSubTitle>
		</FlexBox>
	</LabelPaper>
}