import {DropDown, DropDownItem} from "../../../../../../../shared/components/input/dropdown";
import {useRunWhenValueChange} from "../../../../../../../shared/hooks";
import {useState} from "react";
import {DialogProps} from "../../../../../models/elements";
import {styleComponentFromType, styleComponentMapFromType} from "./elements";
import {Dialog} from "../../../../../../../lib/ui";
import {Box, styled} from "@mui/material";
import { StyleElementValue, StyleType } from "../../../../../../../shared/definitions/elements/style/model";

const DialogContainer = styled(Box)({
	height: '45vh',
	overflowY: 'scroll',
	overflowX: 'hidden',
	padding: "0.5em",
	display: "flex",
	gap: "1em",
	flexDirection: "column",
});

const StyleOptionValues: Record<StyleType, StyleType> = {
	Unknown: "Unknown",
	StyleElement: "StyleElement",
	AddStyle: "AddStyle",
	AddSymbol: "AddSymbol",
	AddSectionNumbering: "AddSectionNumbering"
};

const styleOptions: DropDownItem<StyleType>[] = [{
	title: "Please select",
	value: StyleOptionValues.Unknown
},{
	title: 'Style element',
	value: StyleOptionValues.StyleElement
}, {
	title: 'Add style',
	value: StyleOptionValues.AddStyle
}, {
	title: 'Add symbol',
	value: StyleOptionValues.AddSymbol
}, {
	title: 'Add section numbering',
	value: StyleOptionValues.AddSectionNumbering
}];

/*
 * AddStyleDialog component
 */
const PropertyEditor = (props: DialogProps) => {
	const {option, value} = props.value.data as StyleElementValue;
	const [state, setState] = useState({styleOption: option ?? StyleOptionValues.Unknown, styleValue: value});

	const StyleComponent = styleComponentFromType(state.styleOption ?? "Unknown");
	const styleTypeValueFactory = styleComponentMapFromType(state.styleOption)?.valueFactory

	useRunWhenValueChange((prevValue) => {
		setState(s => ({...s, styleValue: value}));
	}, value);

	const onSelectStyleOption = (option: DropDownItem<StyleType>) =>
		setState(s => ({...s, styleOption: option.value, styleValue: undefined}));
	const onStyleValueChange = (newValue: unknown) => {
		setState(s => ({...s, styleValue: newValue}));
	}
	const onClose = () => {
		if (props.onClose) {
			props.onClose();
		}
	}

	const onApply = () => {
		if (props.onApply) {
			props.onApply({...props.value, data: {value: getStyleValue(), option: state.styleOption ?? "", type: "style"}})
		}
	}

	const getStyleValue = () => state.styleValue ? state.styleValue : (styleTypeValueFactory ? styleTypeValueFactory() : undefined);


	// TODO: Refactor existing Dialog to typescript
	return (<Dialog
		open={true}
		title={"Style Properties"}
		onClose={onClose}
		style={{ minWidth: "60%" }}
		buttons={{
			Close: onClose,
			Apply: onApply
		}}>
		<DialogContainer>
			<DropDown items={styleOptions} onSelect={onSelectStyleOption} value={state.styleOption} label={"Select"} />
			{StyleComponent && <StyleComponent value={state.styleValue ?? (styleTypeValueFactory ? styleTypeValueFactory() : null)} onChange={onStyleValueChange} option={option ?? "StyleElement"} />}
		</DialogContainer>
	</Dialog>)
};

export { PropertyEditor };
