import {TemplateElementDefinition} from "../model";
import {InTextTableIcon} from "./icon";
import {EditableView} from "./views/EditableView";
import {ReadonlyView} from "./views/ReadonlyView";
import PreviewView from "./views/preview/PreviewView";
import {
	inTextTableDefinition as baseInTextTableDefinition
} from "../../../../../shared/definitions/elements/inTextTable/model";

const inTextTableDefinition: TemplateElementDefinition = {
	...baseInTextTableDefinition,
	icon: InTextTableIcon,
	views: {
		editable: EditableView,
		readonly: ReadonlyView,
		preview: PreviewView
	},
} as const

export {inTextTableDefinition};