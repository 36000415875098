import api from '../../../../../api/api';
import {useAsync} from "../../../../../shared/hooks";

const deleteComment = async (documentId: string, comment: KaiAlphaCommentEntry ): Promise<KaiAlphaCommentEntry | null> => {
	return api.call('DELETE', `comment/document/${documentId}/${comment.id}`);
}

/**
 * Delete a comment with the template ID
 * delete requires commentId in payload
 * @returns
 */
const useDeleteComment = () => {
	return useAsync<KaiAlphaCommentEntry>(deleteComment, false);
}

export {useDeleteComment, deleteComment};