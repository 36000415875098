import {useAsync} from "../../../../shared/hooks";
import api from "../../../../api/api";
import { WorkflowApiResponse } from "../../../../shared/interfaces";

// updates a template workflow with an event
function useUpdateWorkflow() {
	const updateWorkflow = async (
		workflowEvent: object,
		eventType: string,
		documentId: string,
		workflowSlot: string
	) => {

		const payload = {
			id: documentId,
			version: "HEAD",
			event: eventType,
			args: workflowEvent,
		}

		return api.call('PUT', `document/${documentId}/workflow/${workflowSlot}`, payload);
	}

	return useAsync<WorkflowApiResponse>(updateWorkflow, false);
}

export {useUpdateWorkflow}